exports.BURLINGAME_LISTINGS = [
		{
			"closePrice": 1180000,
			"daysOnMovoto": 46,
			"id": "7379cbc8-b130-4a70-b076-be5d6d1a94eb",
			"tnImgPath": "https://pi.movoto.com/p/110/421609706_0_f3vrqZ_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Tania Seabock",
			"listPrice": 1150000,
			"lotSize": 0,
			"sqftTotal": 1443,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609706",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Real Estate eBroker",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": null,
			"zipCode": "94010",
			"path": "burlingame-ca/119-primrose-rd-4-burlingame-ca-94010-100_89943228/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -125000,
			"propertyId": "90a823cc-b7db-4eba-9386-9d75a23f95a4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-06 07:21:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.575707,
				"lng": -122.346246,
				"zipcode": "94010",
				"subPremise": "APT 4",
				"address": "119 Primrose Rd #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 95,
			"id": "3ef24320-eda0-498e-b816-9e32c50814c7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861136_0_Y2QyUB_p.jpeg",
			"listDate": "2021-09-03 09:06:05",
			"listingAgent": "Peter Tao",
			"listPrice": 1798000,
			"lotSize": 5641,
			"sqftTotal": 1805,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861136",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1914,
			"zipCode": "94010",
			"path": "burlingame-ca/1038-morrell-ave-burlingame-ca-94010-100_ml81861136/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "9185c3eb-6e0a-4e59-ad7a-86f6515b56e9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-09-03 16:12:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 09:06:05",
			"virtualTourLink": "https://www.cbhometour.com/1038-Morrell-Ave-Burlingame-CA-94010/index.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.584972,
				"lng": -122.355244,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1038 Morrell Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.cbhometour.com/1038-Morrell-Ave-Burlingame-CA-94010/index.html"
		},
		{
			"closePrice": 4040000,
			"daysOnMovoto": 41,
			"id": "deb64a3f-8077-4e04-8cde-bb7a256ef1d5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867995_0_vIuBnq_p.jpeg",
			"listDate": "2021-10-25 09:56:39",
			"listingAgent": "Gilson Team",
			"listPrice": 3950000,
			"lotSize": 5998,
			"sqftTotal": 2876,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867995",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1922,
			"zipCode": "94010",
			"path": "burlingame-ca/1441-balboa-ave-burlingame-ca-94010-100_80915473/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f4eb1e20-d2ea-480d-b0f9-ef39a6d9907a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-25 17:07:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 09:56:39",
			"virtualTourLink": "https://1441balboa.com/#!/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.587407,
				"lng": -122.374357,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1441 Balboa Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4040000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://1441balboa.com/#!/"
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 101,
			"id": "d5d8b125-27ef-4d7f-bcdd-cdfdf7096424",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859325_0_Ie7EBE_p.jpeg",
			"listDate": "2021-08-20 11:55:55",
			"listingAgent": "Janay Michaud",
			"listPrice": 2100000,
			"lotSize": 4352,
			"sqftTotal": 1250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859325",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Heritage Realty",
			"photoCount": 45,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1929,
			"zipCode": "94010",
			"path": "burlingame-ca/1044-paloma-ave-burlingame-ca-94010/pid_agh6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d7da71c-7ddb-4598-98d7-50170b534a46",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-08-20 19:07:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 11:55:55",
			"virtualTourLink": "https://www.tourfactory.com/2909408",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.584676,
				"lng": -122.362669,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1044 Paloma Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2909408"
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 18,
			"id": "20d380f8-5011-4e3d-93bb-992084c08ae2",
			"tnImgPath": "https://pi.movoto.com/p/12/40970948_0_UZAQuN_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Liping Zhang",
			"listPrice": 1999000,
			"lotSize": 4305,
			"sqftTotal": 1300,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970948",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1926,
			"zipCode": "94010",
			"path": "burlingame-ca/1110-grove-ave-burlingame-ca-94010-100_80240977/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 154000,
			"propertyId": "c7e26850-8d63-46ed-962e-34d6b64dfa59",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-10-14 21:11:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.589402,
				"lng": -122.369176,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1110 Grove Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3510000,
			"daysOnMovoto": 27,
			"id": "8a1c9a41-9277-4cd7-a635-ad03ba39850b",
			"tnImgPath": "https://pi.movoto.com/p/110/421603292_0_Uar7VE_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "David Chan",
			"listPrice": 3188000,
			"lotSize": 5750,
			"sqftTotal": 2788,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421603292",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "JODI Group",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2021,
			"zipCode": "94010",
			"path": "burlingame-ca/949-laguna-ave-burlingame-ca-94010/pid_kxd6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c7d9c63-4cdf-4f04-bfe4-3d7025f233f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-10-15 16:06:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.58393,
				"lng": -122.359856,
				"zipcode": "94010",
				"subPremise": "",
				"address": "949 Laguna Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1505000,
			"daysOnMovoto": 60,
			"id": "97449c13-2459-40d6-b74c-4004609091db",
			"tnImgPath": "https://pi.movoto.com/p/12/40963888_0_Y2RiRM_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Faisal Kakar",
			"listPrice": 1499000,
			"lotSize": 4318,
			"sqftTotal": 1490,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963888",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Cal Bay Realty",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2015,
			"zipCode": "94010",
			"path": "burlingame-ca/904-bayswater-ave-apt-5-burlingame-ca-94010-100_ml81440972/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "32ddaefb-c122-45ac-8f77-955a7cf721ff",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-18 00:00:00",
			"createdAt": "2021-08-19 23:51:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": "https://mls.ricohtours.com/84c005cb-6a4e-49b3-b81c-99ac62c1e5b0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.579044,
				"lng": -122.34028,
				"zipcode": "94010",
				"subPremise": "APT 5",
				"address": "904 Bayswater Ave #5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1505000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mls.ricohtours.com/84c005cb-6a4e-49b3-b81c-99ac62c1e5b0"
		},
		{
			"closePrice": 610000,
			"daysOnMovoto": 33,
			"id": "f42907c4-0d87-4394-a88a-1250354b8a3e",
			"tnImgPath": "https://pi.movoto.com/p/110/421589962_0_QaivFI_p.jpeg",
			"listDate": "2021-08-28 00:00:00",
			"listingAgent": "Stephen Lee",
			"listPrice": 599000,
			"lotSize": 22687,
			"sqftTotal": 880,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421589962",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "BarbCo",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94010",
			"path": "burlingame-ca/777-morrell-ave-102-burlingame-ca-94010/pid_sbd9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fe67d25c-2742-41e8-8a16-c4e1b1e32d63",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-09-10 07:16:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.586603,
				"lng": -122.351141,
				"zipcode": "94010",
				"subPremise": "APT 102",
				"address": "777 Morrell Ave #102"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2150000,
			"daysOnMovoto": 177,
			"id": "3792d2be-2502-4b02-bf05-8a33f5c57a92",
			"tnImgPath": "https://pi.movoto.com/p/110/421557451_0_E3YAby_p.jpeg",
			"listDate": "2021-04-06 00:00:00",
			"listingAgent": "Charlene Gueco",
			"listPrice": 1999999,
			"lotSize": 12000,
			"sqftTotal": 2640,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421557451",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Kinoko",
			"photoCount": 10,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1953,
			"zipCode": "94010",
			"path": "burlingame-ca/2944-canyon-rd-burlingame-ca-94010-100_907347/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5b73030-9ba0-4c19-aba7-74006f168993",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-06-09 23:26:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.575444,
				"lng": -122.387731,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2944 Canyon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1720000,
			"daysOnMovoto": 12,
			"id": "81b8c0f7-d1ea-4614-a333-b05f812e396c",
			"tnImgPath": "https://pi.movoto.com/p/110/421589691_0_re3Jjy_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Margaret Shen",
			"listPrice": 1598000,
			"lotSize": 0,
			"sqftTotal": 2059,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421589691",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "JC Property Investment",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2002,
			"zipCode": "94010",
			"path": "burlingame-ca/808-el-camino-real-apt-g-burlingame-ca-94010-110_421589691/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee9498bd-ee15-40a1-b1a1-e1047c4bdb5f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-09-04 06:21:07",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=YnqZmjx8Dg7",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.580737,
				"lng": -122.359711,
				"zipcode": "94010",
				"subPremise": "APT G",
				"address": "808 El Camino Real #G"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=YnqZmjx8Dg7"
		},
		{
			"closePrice": 2350000,
			"daysOnMovoto": 40,
			"id": "a7835f30-f8f5-48c6-9a03-8a23f479772e",
			"tnImgPath": "https://pi.movoto.com/p/110/421584671_0_Ma2bvf_p.jpeg",
			"listDate": "2021-07-09 00:00:00",
			"listingAgent": "Abigail Picache",
			"listPrice": 1898000,
			"lotSize": 5850,
			"sqftTotal": 1200,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421584671",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1916,
			"zipCode": "94010",
			"path": "burlingame-ca/831-acacia-dr-burlingame-ca-94010/pid_bkh6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5c02a4f3-6e68-4fb4-a7fe-bcb617c19a21",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-18 00:00:00",
			"createdAt": "2021-08-13 07:21:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-09 00:00:00",
			"virtualTourLink": "http://www.831Acacia.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.582335,
				"lng": -122.357755,
				"zipcode": "94010",
				"subPremise": "",
				"address": "831 Acacia Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.831Acacia.com"
		},
		{
			"closePrice": 2490000,
			"daysOnMovoto": 77,
			"id": "e3fb33f6-8875-4358-a110-9c0bfcf8598a",
			"tnImgPath": "https://pi.movoto.com/p/12/40952073_0_7NeV2Z_p.jpeg",
			"listDate": "2021-05-28 00:00:00",
			"listingAgent": "Donna Vu",
			"listPrice": 2599000,
			"lotSize": 4477,
			"sqftTotal": 2540,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40952073",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "VU REALTY & FINANCIAL",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1927,
			"zipCode": "94010",
			"path": "burlingame-ca/1337-california-dr-burlingame-ca-94010-100_81236290/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -90000,
			"propertyId": "4e359d4a-16ad-49ca-a907-b801e2b7492e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-13 00:00:00",
			"createdAt": "2021-05-29 04:31:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.589258,
				"lng": -122.368275,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1337 California Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2490000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1320000,
			"daysOnMovoto": 42,
			"id": "bbe7ca49-6f01-4cf1-a0fe-87bf27541b46",
			"tnImgPath": "https://pi.movoto.com/p/110/421573909_0_IFY3Zf_p.jpeg",
			"listDate": "2021-07-01 00:00:00",
			"listingAgent": "Lauren Lai Bellings",
			"listPrice": 1288000,
			"lotSize": 5035,
			"sqftTotal": 1370,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421573909",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2009,
			"zipCode": "94010",
			"path": "burlingame-ca/508-peninsula-ave-2-burlingame-ca-94010-100_80951845/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "333dd688-59d8-4940-99be-a5cfdb7e9c40",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-12 00:00:00",
			"createdAt": "2021-08-01 01:16:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.580299,
				"lng": -122.335161,
				"zipcode": "94010",
				"subPremise": "APT 2",
				"address": "508 Peninsula Ave #2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1320000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3175000,
			"daysOnMovoto": 57,
			"id": "7fef1a85-4018-4afa-990e-d7fbcb8cdd49",
			"tnImgPath": "https://pi.movoto.com/p/110/421560746_0_aifeFq_p.jpeg",
			"listDate": "2021-06-10 00:00:00",
			"listingAgent": "Tanya Dzhibrailova",
			"listPrice": 3295000,
			"lotSize": 19995,
			"sqftTotal": 3320,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421560746",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 99,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1992,
			"zipCode": "94010",
			"path": "burlingame-ca/2888-canyon-rd-burlingame-ca-94010-110_421560746/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -300000,
			"propertyId": "cfadeb06-61eb-4d80-a46f-592ebf143fc2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-06 00:00:00",
			"createdAt": "2021-06-11 06:11:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 00:00:00",
			"virtualTourLink": "https://www.2888canyonroad.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.57761,
				"lng": -122.385627,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2888 Canyon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.2888canyonroad.com/mls"
		},
		{
			"closePrice": 4300000,
			"daysOnMovoto": 92,
			"id": "c8e808bf-c92b-44fa-b37e-4c2ea7224aed",
			"tnImgPath": "https://pi.movoto.com/p/110/421565967_0_jvqefV_p.jpeg",
			"listDate": "2021-05-03 00:00:00",
			"listingAgent": "Tara Burke",
			"listPrice": 4150000,
			"lotSize": 8050,
			"sqftTotal": 2810,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421565967",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1921,
			"zipCode": "94010",
			"path": "burlingame-ca/1612-ralston-ave-burlingame-ca-94010-100_81202573/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba66bd1c-95f7-4ec9-9841-26e252649b0d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-03 00:00:00",
			"createdAt": "2021-08-03 07:21:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.573752,
				"lng": -122.351322,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1612 Ralston Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 905000,
			"daysOnMovoto": 77,
			"id": "92692b10-e6d8-4047-baae-a89710ad5296",
			"tnImgPath": "https://pi.movoto.com/p/110/421531966_0_FbY7E3_p.jpeg",
			"listDate": "2021-03-23 00:00:00",
			"listingAgent": "Carol Kim",
			"listPrice": 874000,
			"lotSize": 19236,
			"sqftTotal": 1052,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421531966",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94010",
			"path": "burlingame-ca/1209-oak-grove-ave-apt-303-burlingame-ca-94010-100_ml80035511/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "772331b5-fa07-4024-9458-7ab43fc21843",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-08 00:00:00",
			"createdAt": "2021-03-29 07:36:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.581631,
				"lng": -122.351322,
				"zipcode": "94010",
				"subPremise": "APT 303",
				"address": "1209 Oak Grove Ave #303"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 905000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3050000,
			"daysOnMovoto": 5,
			"id": "a8c4f810-244b-4047-9921-32b5becc1cf1",
			"tnImgPath": "https://pi.movoto.com/p/110/421553734_0_ZjfrM3_p.jpeg",
			"listDate": "2021-05-18 00:00:00",
			"listingAgent": "Eyal Katz",
			"listPrice": 2488888,
			"lotSize": 9750,
			"sqftTotal": 2200,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421553734",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Brick and Mortar Real Estate Services",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1964,
			"zipCode": "94010",
			"path": "burlingame-ca/2956-dolores-way-burlingame-ca-94010/pid_iqe6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c1574e12-ae16-4528-81c2-741695932320",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-23 00:00:00",
			"createdAt": "2021-05-21 16:43:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.583607,
				"lng": -122.399406,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2956 Dolores Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3888000,
			"daysOnMovoto": 120,
			"id": "0599b5e9-3494-496b-8a71-bca190e3ecbf",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-01-19 00:00:00",
			"listingAgent": "Stanley Lo",
			"listPrice": 3980000,
			"lotSize": 7050,
			"sqftTotal": 4150,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81826241",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1985,
			"zipCode": "94010",
			"path": "burlingame-ca/1509-sanchez-ave-burlingame-ca-94010-100_ml81826241/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cc5f1cc3-1e71-4d82-be0e-041cd1a01277",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-14 15:30:38",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-01-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.581191,
				"lng": -122.361949,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1509 Sanchez Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3888000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4150000,
			"daysOnMovoto": 33,
			"id": "fc254bfe-766e-45e5-8d1e-1a38e52e57c7",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-15 00:00:00",
			"listingAgent": "Karin Riley",
			"listPrice": 3995000,
			"lotSize": 6000,
			"sqftTotal": 3014,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81839114",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1922,
			"zipCode": "94010",
			"path": "burlingame-ca/1220-bernal-ave-burlingame-ca-94010-100_80429089/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4c255313-d244-4b39-bd4e-5a0bafb764b1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-04-23 07:45:06",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.581438,
				"lng": -122.369876,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1220 Bernal Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 51,
			"id": "cbd87182-ca22-4332-97a8-d0a86252ac8d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836255_0_uUEZ6i_p.jpeg",
			"listDate": "2021-03-27 13:21:21",
			"listingAgent": "Gregory Terry",
			"listPrice": 1998000,
			"lotSize": 7440,
			"sqftTotal": 2178,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836255",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1951,
			"zipCode": "94010",
			"path": "burlingame-ca/1644-albemarle-way-burlingame-ca-94010/pid_ahd6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "28cd168d-cdb1-47a0-a3c6-01b8c788b94a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-03-27 20:27:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-27 13:21:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.591831,
				"lng": -122.380252,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1644 Albemarle Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4000000,
			"daysOnMovoto": 16,
			"id": "72e43b4b-4fac-4315-a3ba-211fc127c38a",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-05-01 00:00:00",
			"listingAgent": "Paul Hwang",
			"listPrice": 3499000,
			"lotSize": 10350,
			"sqftTotal": 3613,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81841731",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Skybox Realty Inc",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94010",
			"path": "burlingame-ca/109-los-robles-dr-burlingame-ca-94010-100_80343264/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "89996839-976b-408a-b5b8-528aa47d0521",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-01 04:21:27",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-05-01 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=cptiPzvqU9S&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.577899,
				"lng": -122.387844,
				"zipcode": "94010",
				"subPremise": "",
				"address": "109 Los Robles Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=cptiPzvqU9S&brand=0"
		},
		{
			"closePrice": 3785000,
			"daysOnMovoto": 44,
			"id": "b1094277-f98c-4940-ac7d-ee096aabab78",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-03 00:00:00",
			"listingAgent": "The Sharp Group",
			"listPrice": 3798000,
			"lotSize": 5000,
			"sqftTotal": 2695,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837302",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2002,
			"zipCode": "94010",
			"path": "burlingame-ca/1313-de-soto-ave-burlingame-ca-94010-100_80626320/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "45b9551b-a329-4257-b689-d5c8903d83bc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-04-23 07:47:00",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.581004,
				"lng": -122.373662,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1313 De Soto Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3785000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1840000,
			"daysOnMovoto": 87,
			"id": "28790559-2089-4902-b3d4-c2967e289ab2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828871_0_6N7F3z_p.jpeg",
			"listDate": "2021-02-07 19:56:37",
			"listingAgent": "Richard Beale",
			"listPrice": 1898000,
			"lotSize": 5750,
			"sqftTotal": 1605,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828871",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Beale Properties",
			"photoCount": 44,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1924,
			"zipCode": "94010",
			"path": "burlingame-ca/chula-vista-ave-burlingame-ca-94010-100_80039256/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -101000,
			"propertyId": "987c2f9a-5184-4c06-a4cf-52c8b95be013",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-06 00:00:00",
			"createdAt": "2021-02-08 04:02:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-07 19:56:37",
			"virtualTourLink": "Https://www.1027chulavistaavenue.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.585468,
				"lng": -122.361372,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1027 Chula Vista Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1840000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.1027chulavistaavenue.com/mls"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 18,
			"id": "db60db59-1c55-4366-9d32-63873df655c9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81837291_0_YQaR7f_p.jpeg",
			"listDate": "2021-04-03 11:21:19",
			"listingAgent": "Charles Floyd",
			"listPrice": 1995000,
			"lotSize": 10402,
			"sqftTotal": 4015,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81837291",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1927,
			"zipCode": "94010",
			"path": "burlingame-ca/724-linden-ave-burlingame-ca-94010/pid_74h6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cf389120-01d2-4bbd-8ae2-5ec44e92c960",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-22 00:00:00",
			"createdAt": "2021-04-03 18:27:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-03 11:21:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.583932,
				"lng": -122.35065,
				"zipcode": "94010",
				"subPremise": "",
				"address": "724 Linden Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1320000,
			"daysOnMovoto": 63,
			"id": "3ae92cdd-edd8-44ba-93d2-8928ba9335ef",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830150_0_62zjVV_p.jpeg",
			"listDate": "2021-02-17 10:34:06",
			"listingAgent": "Fred Eftekari",
			"listPrice": 1395000,
			"lotSize": 5502,
			"sqftTotal": 1597,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830150",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1944,
			"zipCode": "94010",
			"path": "burlingame-ca/461-463-rollins-rd-burlingame-ca-94010-100_ml81830150/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "10585aa2-0ae9-494b-809d-d80ccdbb1765",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-22 00:00:00",
			"createdAt": "2021-02-17 18:37:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 10:34:06",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.586383,
				"lng": -122.340201,
				"zipcode": "94010",
				"subPremise": "",
				"address": "461-463 Rollins Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1320000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 66,
			"id": "3648362e-5a84-4367-b962-acfd4d77f8ab",
			"tnImgPath": "https://pi.movoto.com/p/12/40937343_0_QNzBQU_p.jpeg",
			"listDate": "2021-02-15 00:00:00",
			"listingAgent": "ROCHELLE EVANS",
			"listPrice": 2000000,
			"lotSize": 5000,
			"sqftTotal": 1540,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937343",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Better Homes/Mann & Assc",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94010",
			"path": "burlingame-ca/615-trenton-way-burlingame-ca-94010/pid_yoi6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "63fb5be6-2f96-46bd-b66b-e23344a5fcc8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-22 00:00:00",
			"createdAt": "2021-02-15 21:51:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.585996,
				"lng": -122.342747,
				"zipcode": "94010",
				"subPremise": "",
				"address": "615 Trenton Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3800000,
			"daysOnMovoto": 34,
			"id": "73692e07-35c4-409b-a700-1fb86753c7a9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833874_0_ZbznjY_p.jpeg",
			"listDate": "2021-03-12 14:43:19",
			"listingAgent": "Pamela D. Shaffer",
			"listPrice": 3098000,
			"lotSize": 11252,
			"sqftTotal": 2598,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833874",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Kleebauer Properties",
			"photoCount": 67,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "94010",
			"path": "burlingame-ca/2761-summit-dr-burlingame-ca-94010-100_80023412/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "70175db0-3bef-4238-994f-3cc518a077bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-03-12 22:47:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-12 14:43:19",
			"virtualTourLink": "Http://www.2761summit.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.572152,
				"lng": -122.386135,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2761 Summit Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.2761summit.com/?mls"
		},
		{
			"closePrice": 4750000,
			"daysOnMovoto": 91,
			"id": "fcc857d7-4ee9-4a9d-a84f-0efddd288b3d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825687_0_2IUQyb_p.jpeg",
			"listDate": "2021-01-13 17:59:17",
			"listingAgent": "The Sharp Group",
			"listPrice": 4895000,
			"lotSize": 6673,
			"sqftTotal": 3425,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825687",
			"bath": 5.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2021,
			"zipCode": "94010",
			"path": "burlingame-ca/1906-easton-dr-burlingame-ca-94010-100_81235849/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "02fd0937-c8e7-409b-a049-2944e088f7e7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-01-14 02:02:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-13 17:59:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.58295,
				"lng": -122.371884,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1906 Easton Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 39,
			"id": "e2dc0f35-2132-427c-ab8a-72102747ff90",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832698_0_zV66Ia_p.jpeg",
			"listDate": "2021-03-05 11:47:57",
			"listingAgent": "Raziel A. Ungar",
			"listPrice": 1380000,
			"lotSize": 4317,
			"sqftTotal": 1430,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832698",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 45,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2015,
			"zipCode": "94010",
			"path": "burlingame-ca/904-bayswater-ave-4-burlingame-ca-94010-100_81446195/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a9d0bcc8-9e2b-40e6-a680-8030286041f4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-05 19:52:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 11:47:57",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.579044,
				"lng": -122.34028,
				"zipcode": "94010",
				"subPremise": "APT 4",
				"address": "904 Bayswater Ave #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4150000,
			"daysOnMovoto": 148,
			"id": "a1698120-36e9-4ded-a253-315863628715",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81820443_0_zRqVJ2_p.jpeg",
			"listDate": "2020-11-16 11:00:45",
			"listingAgent": "Stanley Lo",
			"listPrice": 4399000,
			"lotSize": 5998,
			"sqftTotal": 2987,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81820443",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2020,
			"zipCode": "94010",
			"path": "burlingame-ca/1425-bernal-ave-burlingame-ca-94010/pid_w9g6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "54b1422a-6fd6-45db-b8b1-9cb19d04233a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2020-11-16 19:07:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-16 11:00:45",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.584422,
				"lng": -122.37617,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1425 Bernal Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1140000,
			"daysOnMovoto": 69,
			"id": "ead39777-26de-48db-b91d-261040852b04",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828149_0_zqmyyf_p.jpeg",
			"listDate": "2021-02-02 11:40:58",
			"listingAgent": "Michele Oravec",
			"listPrice": 1195000,
			"lotSize": null,
			"sqftTotal": 1335,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828149",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94010",
			"path": "burlingame-ca/1210-bellevue-ave-304-burlingame-ca-94010-100_80383943/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aee93f4b-12f4-4817-b559-f7c83658a72c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-02-02 19:47:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-02 11:40:58",
			"virtualTourLink": "Http://www.burlingamecondo.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.580403,
				"lng": -122.348331,
				"zipcode": "94010",
				"subPremise": "APT 304",
				"address": "1210 Bellevue Ave #304"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1140000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.burlingamecondo.com/?mls"
		},
		{
			"closePrice": 3300000,
			"daysOnMovoto": 81,
			"id": "75a46e6d-38de-49c3-b973-d59f4502391e",
			"tnImgPath": "https://pi.movoto.com/p/110/421537675_0_ynnVRq_p.jpeg",
			"listDate": "2021-01-21 00:00:00",
			"listingAgent": "Louis Woo",
			"listPrice": 3300000,
			"lotSize": 8000,
			"sqftTotal": 2474,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421537675",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "JODI Group",
			"photoCount": 5,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1957,
			"zipCode": "94010",
			"path": "burlingame-ca/6-karen-ct-burlingame-ca-94010-100_81446535/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0d10f95f-2040-4832-87bc-10ffa512057a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-12 00:00:00",
			"createdAt": "2021-04-12 22:05:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.585417,
				"lng": -122.389426,
				"zipcode": "94010",
				"subPremise": "",
				"address": "6 Karen Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 37,
			"id": "c77ee7cc-6213-40bd-9de9-2e4ff199bab8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831746_0_Qz3yAy_p.jpeg",
			"listDate": "2021-02-27 13:47:47",
			"listingAgent": "Caroline Huo",
			"listPrice": 940000,
			"lotSize": null,
			"sqftTotal": 1410,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831746",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "94010",
			"path": "burlingame-ca/1500-sherman-ave-1c-burlingame-ca-94010-100_81326480/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "88fca0c2-cbb3-41fe-af61-cf3d5dcd60d5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-27 21:52:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-27 13:47:47",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.585142,
				"lng": -122.368497,
				"zipcode": "94010",
				"subPremise": "APT 1C",
				"address": "1500 Sherman Ave #1C"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2225000,
			"daysOnMovoto": 30,
			"id": "9d5bd496-e492-4967-8597-dd5510193536",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832662_0_EjJ6Qa_p.jpeg",
			"listDate": "2021-03-05 09:33:58",
			"listingAgent": "The Patty Dwyer Group",
			"listPrice": 1750000,
			"lotSize": 5998,
			"sqftTotal": 1360,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832662",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1927,
			"zipCode": "94010",
			"path": "burlingame-ca/1415-capuchino-ave-burlingame-ca-94010-100_81402320/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "978441d7-cc33-4dc1-a970-b0e73dac6542",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-03-05 17:37:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 09:33:58",
			"virtualTourLink": "Https://1415capuchino.com/1789696?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.587906,
				"lng": -122.371247,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1415 Capuchino Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://1415capuchino.com/1789696?idx=1"
		},
		{
			"closePrice": 3248000,
			"daysOnMovoto": 41,
			"id": "0967a10b-a934-4a5e-91e2-d8a8ed54cbe6",
			"tnImgPath": "https://pi.movoto.com/p/12/40938769_0_RjJrBI_p.jpeg",
			"listDate": "2021-02-23 00:00:00",
			"listingAgent": "Margaret Combs",
			"listPrice": 2495000,
			"lotSize": 8000,
			"sqftTotal": 2400,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938769",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94010",
			"path": "burlingame-ca/1812-montecito-way-burlingame-ca-94010/pid_vod6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "144c8822-f494-44b5-9638-f64d64455327",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-02-23 21:11:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2841135",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.586305,
				"lng": -122.393166,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1812 Montecito Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3248000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2841135"
		},
		{
			"closePrice": 3800000,
			"daysOnMovoto": 35,
			"id": "c1f9e86a-93c1-48df-b3d7-07d37ad3d20d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830741_0_qAVReu_p.jpeg",
			"listDate": "2021-02-26 00:00:00",
			"listingAgent": "David Tapper",
			"listPrice": 2959000,
			"lotSize": 7200,
			"sqftTotal": 3000,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830741",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1925,
			"zipCode": "94010",
			"path": "burlingame-ca/1137-oxford-rd-burlingame-ca-94010/pid_2sc6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b9b7a5a1-3feb-4e7d-99a4-e2f914ed18ad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-26 19:52:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.590055,
				"lng": -122.373869,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1137 Oxford Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3200000,
			"daysOnMovoto": 41,
			"id": "a06b5933-7097-45f1-85a2-4479a5191d44",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830602_0_i732RF_p.jpeg",
			"listDate": "2021-02-19 14:04:04",
			"listingAgent": "Sternsmith Group",
			"listPrice": 3195000,
			"lotSize": 5401,
			"sqftTotal": 2538,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830602",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1925,
			"zipCode": "94010",
			"path": "burlingame-ca/1432-vancouver-ave-burlingame-ca-94010-100_81221611/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0136bae3-8081-460f-9cfd-f4a8ef2fa45e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-19 22:12:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 14:04:04",
			"virtualTourLink": "Https://tours.dennismayer.com/public/vtour/display/1783797?idx=1#!/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.584258,
				"lng": -122.37671,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1432 Vancouver Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.dennismayer.com/public/vtour/display/1783797?idx=1#!/"
		},
		{
			"closePrice": 1430000,
			"daysOnMovoto": 108,
			"id": "f2e54d9a-aec9-466a-8c66-75ca55bf6b19",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823182_0_3I6eUM_p.jpeg",
			"listDate": "2020-12-14 10:03:54",
			"listingAgent": "Chi-hwa Shao",
			"listPrice": 1490000,
			"lotSize": null,
			"sqftTotal": 1275,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823182",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2015,
			"zipCode": "94010",
			"path": "burlingame-ca/1800-trousdale-dr-apt-502-burlingame-ca-94010-100_ml81823182/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "a6ffe344-a271-45dc-8498-dd6e4cc1fb3d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2020-12-14 18:07:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-14 10:03:54",
			"virtualTourLink": "Https://my.matterport.com/show/?m=Z9itQJzD3Sp&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.592716,
				"lng": -122.385668,
				"zipcode": "94010",
				"subPremise": "APT 502",
				"address": "1800 Trousdale Dr #502"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1430000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=Z9itQJzD3Sp&brand=0"
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 48,
			"id": "d08f18ff-61f5-4d72-be17-34731d054781",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829388_0_7NnmrA_p.jpeg",
			"listDate": "2021-02-11 10:41:11",
			"listingAgent": "Catherine Lee",
			"listPrice": 3995000,
			"lotSize": 5998,
			"sqftTotal": 3280,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829388",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Catherine H. Lee, Broker",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1914,
			"zipCode": "94010",
			"path": "burlingame-ca/1232-bernal-ave-burlingame-ca-94010/pid_ynd6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba85dd75-25dd-498c-a306-c7ac6c720bbc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-11 18:47:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 10:41:11",
			"virtualTourLink": "Https://1232bernal.com/1779936?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.581681,
				"lng": -122.370372,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1232 Bernal Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://1232bernal.com/1779936?idx=1"
		},
		{
			"closePrice": 2850000,
			"daysOnMovoto": 41,
			"id": "bed5d6c9-5e80-4b58-a475-90f7614dbdc3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830216_0_iiUAZE_p.jpeg",
			"listDate": "2021-02-17 15:44:43",
			"listingAgent": "Larry Franzella",
			"listPrice": 2488000,
			"lotSize": 8198,
			"sqftTotal": 2060,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830216",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Home Services California Realty",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1964,
			"zipCode": "94010",
			"path": "burlingame-ca/2941-dolores-way-burlingame-ca-94010/pid_nye6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cb178172-64ed-40ff-9a7b-61dbd9efcd34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2021-02-17 23:47:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 15:44:43",
			"virtualTourLink": "https://player.vimeo.com/video/475690413",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.583436,
				"lng": -122.398808,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2941 Dolores Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/475690413"
		},
		{
			"closePrice": 985000,
			"daysOnMovoto": 46,
			"id": "be279bc7-d7e2-4c92-9357-c7a9fa0c12cb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829663_0_V2QFM7_p.jpeg",
			"listDate": "2021-02-12 14:59:23",
			"listingAgent": "Joyce and Tatum Real Estate",
			"listPrice": 978000,
			"lotSize": null,
			"sqftTotal": 1193,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829663",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Dwell Realtors, Inc.",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94010",
			"path": "burlingame-ca/550-el-camino-real-apt-102-burlingame-ca-94010-100_ml81829663/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dc68284c-6a2f-4258-a096-43a59c609100",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2021-02-12 23:02:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-12 14:59:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.577968,
				"lng": -122.354285,
				"zipcode": "94010",
				"subPremise": "APT 102",
				"address": "550 El Camino Real #102"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 985000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 31,
			"id": "fba22b9f-f546-40c7-8695-6ae299561791",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831578_0_azURUI_p.jpeg",
			"listDate": "2021-02-26 11:42:14",
			"listingAgent": "Alisa Ruiz-Johnson",
			"listPrice": 2695000,
			"lotSize": 6199,
			"sqftTotal": 2400,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831578",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Dwell Realtors, Inc.",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1925,
			"zipCode": "94010",
			"path": "burlingame-ca/1304-drake-ave-burlingame-ca-94010-100_81017525/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f2deff95-695d-47c9-87a4-74e4c47b20c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-30 00:00:00",
			"createdAt": "2021-02-26 19:47:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 11:42:14",
			"virtualTourLink": "https://www.youtube.com/embed/xerq6qwUC9o",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.583369,
				"lng": -122.37174,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1304 Drake Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/xerq6qwUC9o"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 106,
			"id": "428d40b4-fa0d-4c53-ac6c-e06d0a833096",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823089_0_2m32je_p.jpeg",
			"listDate": "2020-12-12 11:08:39",
			"listingAgent": "Alisa Ruiz-Johnson",
			"listPrice": 2485000,
			"lotSize": 8660,
			"sqftTotal": 2550,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823089",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Dwell Realtors, Inc.",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "94010",
			"path": "burlingame-ca/1501-alturas-dr-burlingame-ca-94010/pid_goe6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -210000,
			"propertyId": "9ff6e2ee-5dd0-4ce2-9ca3-60bf23e7bf3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-29 00:00:00",
			"createdAt": "2020-12-12 19:17:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-12 11:08:39",
			"virtualTourLink": "https://www.youtube.com/embed/ovAtUqryXxo",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.574176,
				"lng": -122.39352,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1501 Alturas Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/ovAtUqryXxo"
		},
		{
			"closePrice": 1260000,
			"daysOnMovoto": 153,
			"id": "fda77515-0fdf-4709-9fad-d19cf9f6af2b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81817396_0_rQfRvI_p.jpeg",
			"listDate": "2020-10-26 08:52:24",
			"listingAgent": "Louie Kappen",
			"listPrice": 1345000,
			"lotSize": null,
			"sqftTotal": 1600,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81817396",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1994,
			"zipCode": "94010",
			"path": "burlingame-ca/518-almer-rd-4-burlingame-ca-94010-100_81239789/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1b1844dc-3c82-45b4-b2a6-eb12bfe931d5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-29 00:00:00",
			"createdAt": "2020-10-26 15:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-26 08:52:24",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.578803,
				"lng": -122.352667,
				"zipcode": "94010",
				"subPremise": "APT 4",
				"address": "518 Almer Rd #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2020000,
			"daysOnMovoto": 64,
			"id": "f64a4388-0157-4119-9c94-885a344f2e8b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826448_0_ryf3mJ_p.jpeg",
			"listDate": "2021-01-20 09:22:41",
			"listingAgent": "Ariel Chen",
			"listPrice": 1998000,
			"lotSize": 5998,
			"sqftTotal": 1330,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826448",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Maxreal",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1927,
			"zipCode": "94010",
			"path": "burlingame-ca/2701-hillside-dr-burlingame-ca-94010/pid_ucg6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "99b6a85a-13ad-4462-80b5-de79b2e0e769",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2021-02-19 03:32:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-20 09:22:41",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.579036,
				"lng": -122.379003,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2701 Hillside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2020000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2800000,
			"daysOnMovoto": 0,
			"id": "cb332a6a-fb71-46cf-a687-87a773ba06ca",
			"tnImgPath": "https://pi.movoto.com/p/102/421522098_0_uEzvRe_p.jpeg",
			"listDate": null,
			"listingAgent": "Angela Fraire",
			"listPrice": 2600000,
			"lotSize": 6000,
			"sqftTotal": 2070,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421522098",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Casa Magna Realty",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1936,
			"zipCode": "94010",
			"path": "burlingame-ca/1420-cabrillo-ave-burlingame-ca-94010/pid_prg6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8054f91e-cfa7-455c-b4c6-94ef3f92b9aa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2021-02-23 06:14:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 06:14:19",
			"virtualTourLink": "Http://www.1420cabrilloave.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.586069,
				"lng": -122.374531,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1420 Cabrillo Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.1420cabrilloave.com/?mls"
		},
		{
			"closePrice": 1235000,
			"daysOnMovoto": 92,
			"id": "49e74f14-70ed-4eea-b60c-6decc2d986a7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823702_0_uzRerY_p.jpeg",
			"listDate": "2020-12-20 11:09:22",
			"listingAgent": "Louie Kappen",
			"listPrice": 1295000,
			"lotSize": null,
			"sqftTotal": 1828,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823702",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1977,
			"zipCode": "94010",
			"path": "burlingame-ca/1515-floribunda-avenue-310-burlingame-ca-94010-100_ml81514119/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -80000,
			"propertyId": "1e0c7c6d-2ff1-40ac-9d94-77e2f8c15d1e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-23 00:00:00",
			"createdAt": "2020-12-20 19:12:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-20 11:09:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.578503,
				"lng": -122.354166,
				"zipcode": "94010",
				"subPremise": "APT 310",
				"address": "1515 Floribunda Ave #310"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1235000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2480000,
			"daysOnMovoto": 111,
			"id": "f3b8a6bb-f7f3-42ba-a20f-0d1d0ad89d3b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81821514_0_JJ76Y6_p.jpeg",
			"listDate": "2020-12-01 00:00:00",
			"listingAgent": "Mark Martinho",
			"listPrice": 1995000,
			"lotSize": 5759,
			"sqftTotal": 1580,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81821514",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vabrato",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1928,
			"zipCode": "94010",
			"path": "burlingame-ca/1457-cortez-ave-burlingame-ca-94010/pid_wpf6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "260a3342-6666-4da7-80bb-023af2a3d103",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-02-06 01:10:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-01 00:00:00",
			"virtualTourLink": "Http://sites.listvt.com/1457cortezave/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.587076,
				"lng": -122.375551,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1457 Cortez Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://sites.listvt.com/1457cortezave/?mls"
		},
		{
			"closePrice": 2750000,
			"daysOnMovoto": 28,
			"id": "266c515a-512d-4c3c-a603-4ff4f136f8fa",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830443_0_AeEENm_p.jpeg",
			"listDate": "2021-02-18 19:32:12",
			"listingAgent": "Elaine White",
			"listPrice": 2295000,
			"lotSize": 10799,
			"sqftTotal": 1930,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830443",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94010",
			"path": "burlingame-ca/2711-burlingview-dr-burlingame-ca-94010-100_81035171/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a2835c7f-e768-4fdf-a92a-74b96f1bb766",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-19 03:37:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 19:32:12",
			"virtualTourLink": "Http://www.2711burlingview.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.573498,
				"lng": -122.383884,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2711 Burlingview Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.2711burlingview.com/?mls"
		},
		{
			"closePrice": 2150000,
			"daysOnMovoto": 36,
			"id": "cd508212-8982-4ef1-b43f-ce3f311091db",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829206_0_ejy7ZZ_p.jpeg",
			"listDate": "2021-02-10 10:37:15",
			"listingAgent": "Raziel A. Ungar",
			"listPrice": 1885000,
			"lotSize": 5750,
			"sqftTotal": 1730,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829206",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1939,
			"zipCode": "94010",
			"path": "burlingame-ca/452-cumberland-dr-burlingame-ca-94010/pid_5ci6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5cd49df0-86e9-458a-a4d5-b49484e297c8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-10 18:42:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-10 10:37:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Burlingame",
				"lat": 37.585322,
				"lng": -122.344341,
				"zipcode": "94010",
				"subPremise": "",
				"address": "452 Cumberland Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]