const austin = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Travis County, TX",
             "short_name": "Travis County",
             "sumlevel": "050",
             "land_area": 2569870978,
             "full_geoid": "05000US48453",
             "total_population": 1273954
          },
          "CBSA": {
             "full_name": "Austin-Round Rock-Georgetown, TX Metro Area",
             "short_name": "Austin-Round Rock-Georgetown, TX",
             "sumlevel": "310",
             "land_area": 10935038512,
             "full_geoid": "31000US12420",
             "total_population": 2227083
          },
          "state": {
             "full_name": "Texas",
             "short_name": "Texas",
             "sumlevel": "040",
             "land_area": 676668210823,
             "full_geoid": "04000US48",
             "total_population": 28995881
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Austin, TX",
          "short_name": "Austin",
          "sumlevel": "160",
          "land_area": 828637538,
          "full_geoid": "16000US4805000",
          "total_population": 979263,
          "sumlevel_name": "Place",
          "short_geoid": "4805000"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 19.51,
                   "CBSA": 22.67,
                   "state": 25.51
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.41,
                   "state": 0.11
                },
                "numerators": {
                   "this": 191066,
                   "CBSA": 504773,
                   "state": 7396631
                },
                "numerator_errors": {
                   "this": 6867.9,
                   "CBSA": 9177,
                   "state": 30463.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 76
                },
                "error_ratio": {
                   "this": 3.6,
                   "CBSA": 1.8,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 70.95,
                   "CBSA": 66.17,
                   "state": 61.6
                },
                "error": {
                   "this": 1.29,
                   "CBSA": 0.67,
                   "state": 0.18
                },
                "numerators": {
                   "this": 694758,
                   "CBSA": 1473646,
                   "state": 1.7860524E7
                },
                "numerator_errors": {
                   "this": 12665.5,
                   "CBSA": 14862,
                   "state": 50963.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 115
                },
                "error_ratio": {
                   "this": 1.8,
                   "CBSA": 1,
                   "state": 0.3
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 9.54,
                   "CBSA": 11.17,
                   "state": 12.89
                },
                "error": {
                   "this": 0.47,
                   "CBSA": 0.34,
                   "state": 0.09
                },
                "numerators": {
                   "this": 93439,
                   "CBSA": 248664,
                   "state": 3738727
                },
                "numerator_errors": {
                   "this": 4595.4,
                   "CBSA": 7593,
                   "state": 27369.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 74
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 3,
                   "state": 0.7
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.22,
                      "CBSA": 12.17,
                      "state": 13.72
                   },
                   "error": {
                      "this": 0.5,
                      "CBSA": 0.29,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 109856,
                      "CBSA": 271093,
                      "state": 3977147
                   },
                   "numerator_errors": {
                      "this": 4862,
                      "CBSA": 6448.7,
                      "state": 21377.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 92,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 4.5,
                      "CBSA": 2.4,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 11.15,
                      "CBSA": 13.41,
                      "state": 14.7
                   },
                   "error": {
                      "this": 0.55,
                      "CBSA": 0.32,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 109232,
                      "CBSA": 298688,
                      "state": 4262136
                   },
                   "numerator_errors": {
                      "this": 5419.5,
                      "CBSA": 7049.5,
                      "state": 23409.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 83,
                      "state": 76
                   },
                   "error_ratio": {
                      "this": 4.9,
                      "CBSA": 2.4,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 19.01,
                      "CBSA": 15.26,
                      "state": 14.23
                   },
                   "error": {
                      "this": 0.67,
                      "CBSA": 0.35,
                      "state": 0.1
                   },
                   "numerators": {
                      "this": 186197,
                      "CBSA": 339958,
                      "state": 4127481
                   },
                   "numerator_errors": {
                      "this": 6604.3,
                      "CBSA": 7896.5,
                      "state": 28236.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 134
                   },
                   "error_ratio": {
                      "this": 3.5,
                      "CBSA": 2.3,
                      "state": 0.7
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 19.77,
                      "CBSA": 17.15,
                      "state": 14.33
                   },
                   "error": {
                      "this": 0.63,
                      "CBSA": 0.31,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 193634,
                      "CBSA": 381941,
                      "state": 4154767
                   },
                   "numerator_errors": {
                      "this": 6124.8,
                      "CBSA": 7004.9,
                      "state": 23295.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 115,
                      "state": 138
                   },
                   "error_ratio": {
                      "this": 3.2,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.97,
                      "CBSA": 14.37,
                      "state": 12.95
                   },
                   "error": {
                      "this": 0.6,
                      "CBSA": 0.3,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 136781,
                      "CBSA": 320042,
                      "state": 3754503
                   },
                   "numerator_errors": {
                      "this": 5869,
                      "CBSA": 6779.4,
                      "state": 22267
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 4.3,
                      "CBSA": 2.1,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 10.54,
                      "CBSA": 11.34,
                      "state": 11.71
                   },
                   "error": {
                      "this": 0.49,
                      "CBSA": 0.22,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 103221,
                      "CBSA": 252540,
                      "state": 3395321
                   },
                   "numerator_errors": {
                      "this": 4756.6,
                      "CBSA": 4820.9,
                      "state": 17165.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 93,
                      "state": 90
                   },
                   "error_ratio": {
                      "this": 4.6,
                      "CBSA": 1.9,
                      "state": 0.5
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.36,
                      "CBSA": 9.22,
                      "state": 9.85
                   },
                   "error": {
                      "this": 0.5,
                      "CBSA": 0.34,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 81868,
                      "CBSA": 205365,
                      "state": 2857277
                   },
                   "numerator_errors": {
                      "this": 4926.7,
                      "CBSA": 7510.2,
                      "state": 25657.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 91,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 6,
                      "CBSA": 3.7,
                      "state": 0.9
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 3.77,
                      "CBSA": 4.71,
                      "state": 5.7
                   },
                   "error": {
                      "this": 0.29,
                      "CBSA": 0.22,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 36874,
                      "CBSA": 104940,
                      "state": 1651359
                   },
                   "numerator_errors": {
                      "this": 2812.4,
                      "CBSA": 4892.2,
                      "state": 16874
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 80,
                      "state": 66
                   },
                   "error_ratio": {
                      "this": 7.7,
                      "CBSA": 4.7,
                      "state": 1.1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.21,
                      "CBSA": 2.36,
                      "state": 2.81
                   },
                   "error": {
                      "this": 0.24,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 21600,
                      "CBSA": 52516,
                      "state": 815890
                   },
                   "numerator_errors": {
                      "this": 2303.7,
                      "CBSA": 3241.4,
                      "state": 13996.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 94,
                      "state": 79
                   },
                   "error_ratio": {
                      "this": 10.9,
                      "CBSA": 6.4,
                      "state": 1.8
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.45,
                      "CBSA": 12.89,
                      "state": 14
                   },
                   "error": {
                      "this": 0.65,
                      "CBSA": 0.42,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 56801,
                      "CBSA": 143433,
                      "state": 2014631
                   },
                   "numerator_errors": {
                      "this": 3270,
                      "CBSA": 4723.7,
                      "state": 15390.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 89,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 5.7,
                      "CBSA": 3.3,
                      "state": 0.8
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 10.65,
                      "CBSA": 13.11,
                      "state": 15.22
                   },
                   "error": {
                      "this": 0.72,
                      "CBSA": 0.44,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 52809,
                      "CBSA": 145854,
                      "state": 2190191
                   },
                   "numerator_errors": {
                      "this": 3620,
                      "CBSA": 4923.5,
                      "state": 16329.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 81,
                      "state": 70
                   },
                   "error_ratio": {
                      "this": 6.8,
                      "CBSA": 3.4,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 19.36,
                      "CBSA": 15.43,
                      "state": 14.67
                   },
                   "error": {
                      "this": 1.02,
                      "CBSA": 0.53,
                      "state": 0.15
                   },
                   "numerators": {
                      "this": 96025,
                      "CBSA": 171671,
                      "state": 2110790
                   },
                   "numerator_errors": {
                      "this": 5119.1,
                      "CBSA": 5874.5,
                      "state": 21486.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 132
                   },
                   "error_ratio": {
                      "this": 5.3,
                      "CBSA": 3.4,
                      "state": 1
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 20,
                      "CBSA": 17.43,
                      "state": 14.58
                   },
                   "error": {
                      "this": 0.89,
                      "CBSA": 0.46,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 99195,
                      "CBSA": 193980,
                      "state": 2097308
                   },
                   "numerator_errors": {
                      "this": 4505.9,
                      "CBSA": 5181.5,
                      "state": 16919.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 115,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 4.4,
                      "CBSA": 2.6,
                      "state": 0.8
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 15.07,
                      "CBSA": 14.85,
                      "state": 13.02
                   },
                   "error": {
                      "this": 0.89,
                      "CBSA": 0.43,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 74753,
                      "CBSA": 165305,
                      "state": 1872877
                   },
                   "numerator_errors": {
                      "this": 4466.3,
                      "CBSA": 4765.4,
                      "state": 16806.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 116
                   },
                   "error_ratio": {
                      "this": 5.9,
                      "CBSA": 2.9,
                      "state": 0.9
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 10.61,
                      "CBSA": 11.4,
                      "state": 11.67
                   },
                   "error": {
                      "this": 0.66,
                      "CBSA": 0.3,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 52594,
                      "CBSA": 126922,
                      "state": 1678531
                   },
                   "numerator_errors": {
                      "this": 3322.4,
                      "CBSA": 3383.7,
                      "state": 12165.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 93,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 6.2,
                      "CBSA": 2.6,
                      "state": 0.7
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 7.94,
                      "CBSA": 8.67,
                      "state": 9.39
                   },
                   "error": {
                      "this": 0.67,
                      "CBSA": 0.44,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 39357,
                      "CBSA": 96530,
                      "state": 1350512
                   },
                   "numerator_errors": {
                      "this": 3342,
                      "CBSA": 4908.1,
                      "state": 17282
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 92,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 8.4,
                      "CBSA": 5.1,
                      "state": 1.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 3.1,
                      "CBSA": 4.38,
                      "state": 5.24
                   },
                   "error": {
                      "this": 0.37,
                      "CBSA": 0.29,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 15367,
                      "CBSA": 48709,
                      "state": 753344
                   },
                   "numerator_errors": {
                      "this": 1862.2,
                      "CBSA": 3196.8,
                      "state": 11285.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 71,
                      "state": 59
                   },
                   "error_ratio": {
                      "this": 11.9,
                      "CBSA": 6.6,
                      "state": 1.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 1.81,
                      "CBSA": 1.84,
                      "state": 2.21
                   },
                   "error": {
                      "this": 0.28,
                      "CBSA": 0.19,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 8973,
                      "CBSA": 20525,
                      "state": 317578
                   },
                   "numerator_errors": {
                      "this": 1395.1,
                      "CBSA": 2067,
                      "state": 8339.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 15.5,
                      "CBSA": 10.3,
                      "state": 2.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 10.98,
                      "CBSA": 11.46,
                      "state": 13.43
                   },
                   "error": {
                      "this": 0.74,
                      "CBSA": 0.39,
                      "state": 0.1
                   },
                   "numerators": {
                      "this": 53055,
                      "CBSA": 127660,
                      "state": 1962516
                   },
                   "numerator_errors": {
                      "this": 3598.1,
                      "CBSA": 4390.1,
                      "state": 14836.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 96,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 6.7,
                      "CBSA": 3.4,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 11.67,
                      "CBSA": 13.72,
                      "state": 14.18
                   },
                   "error": {
                      "this": 0.83,
                      "CBSA": 0.45,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 56423,
                      "CBSA": 152834,
                      "state": 2071945
                   },
                   "numerator_errors": {
                      "this": 4033.2,
                      "CBSA": 5045.2,
                      "state": 16773.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 85,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 7.1,
                      "CBSA": 3.3,
                      "state": 0.8
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 18.65,
                      "CBSA": 15.1,
                      "state": 13.8
                   },
                   "error": {
                      "this": 0.85,
                      "CBSA": 0.47,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 90172,
                      "CBSA": 168287,
                      "state": 2016691
                   },
                   "numerator_errors": {
                      "this": 4172.7,
                      "CBSA": 5276.9,
                      "state": 18319.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 124,
                      "state": 135
                   },
                   "error_ratio": {
                      "this": 4.6,
                      "CBSA": 3.1,
                      "state": 0.9
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 19.54,
                      "CBSA": 16.87,
                      "state": 14.08
                   },
                   "error": {
                      "this": 0.84,
                      "CBSA": 0.42,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 94439,
                      "CBSA": 187961,
                      "state": 2057459
                   },
                   "numerator_errors": {
                      "this": 4148.5,
                      "CBSA": 4714,
                      "state": 16013.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 116,
                      "state": 139
                   },
                   "error_ratio": {
                      "this": 4.3,
                      "CBSA": 2.5,
                      "state": 0.8
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 12.83,
                      "CBSA": 13.89,
                      "state": 12.88
                   },
                   "error": {
                      "this": 0.78,
                      "CBSA": 0.43,
                      "state": 0.1
                   },
                   "numerators": {
                      "this": 62028,
                      "CBSA": 154737,
                      "state": 1881626
                   },
                   "numerator_errors": {
                      "this": 3807.6,
                      "CBSA": 4821.9,
                      "state": 14607.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 92,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 6.1,
                      "CBSA": 3.1,
                      "state": 0.8
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 10.47,
                      "CBSA": 11.27,
                      "state": 11.75
                   },
                   "error": {
                      "this": 0.7,
                      "CBSA": 0.31,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 50627,
                      "CBSA": 125618,
                      "state": 1716790
                   },
                   "numerator_errors": {
                      "this": 3403.9,
                      "CBSA": 3433.8,
                      "state": 12109.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 93,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 6.7,
                      "CBSA": 2.8,
                      "state": 0.7
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.79,
                      "CBSA": 9.77,
                      "state": 10.31
                   },
                   "error": {
                      "this": 0.74,
                      "CBSA": 0.51,
                      "state": 0.13
                   },
                   "numerators": {
                      "this": 42511,
                      "CBSA": 108835,
                      "state": 1506765
                   },
                   "numerator_errors": {
                      "this": 3620,
                      "CBSA": 5684.5,
                      "state": 18964.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 90,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 8.4,
                      "CBSA": 5.2,
                      "state": 1.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.45,
                      "CBSA": 5.05,
                      "state": 6.15
                   },
                   "error": {
                      "this": 0.43,
                      "CBSA": 0.33,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 21507,
                      "CBSA": 56231,
                      "state": 898015
                   },
                   "numerator_errors": {
                      "this": 2107.6,
                      "CBSA": 3703.2,
                      "state": 12544.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 88,
                      "state": 72
                   },
                   "error_ratio": {
                      "this": 9.7,
                      "CBSA": 6.5,
                      "state": 1.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.61,
                      "CBSA": 2.87,
                      "state": 3.41
                   },
                   "error": {
                      "this": 0.38,
                      "CBSA": 0.22,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 12627,
                      "CBSA": 31991,
                      "state": 498312
                   },
                   "numerator_errors": {
                      "this": 1833.2,
                      "CBSA": 2496.9,
                      "state": 11241.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 91,
                      "state": 77
                   },
                   "error_ratio": {
                      "this": 14.6,
                      "CBSA": 7.7,
                      "state": 2.3
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 33.9,
                   "CBSA": 35.2,
                   "state": 35.1
                },
                "error": {
                   "this": 0.4,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 97
                },
                "error_ratio": {
                   "this": 1.2,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 33.8,
                   "CBSA": 34.7,
                   "state": 34.1
                },
                "error": {
                   "this": 0.4,
                   "CBSA": 0.2,
                   "state": 0.2
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 99
                },
                "error_ratio": {
                   "this": 1.2,
                   "CBSA": 0.6,
                   "state": 0.6
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 34.2,
                   "CBSA": 35.8,
                   "state": 36.1
                },
                "error": {
                   "this": 0.5,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 95
                },
                "error_ratio": {
                   "this": 1.5,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 50.64,
                "CBSA": 49.97,
                "state": 49.61
             },
             "error": {
                "this": 0.45,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 495874,
                "CBSA": 1112929,
                "state": 1.4385762E7
             },
             "numerator_errors": {
                "this": 4458,
                "CBSA": 2428,
                "state": 10844
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 102
             },
             "error_ratio": {
                "this": 0.9,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 49.36,
                "CBSA": 50.03,
                "state": 50.39
             },
             "error": {
                "this": 0.45,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 483389,
                "CBSA": 1114154,
                "state": 1.4610119E7
             },
             "numerator_errors": {
                "this": 4412,
                "CBSA": 2428,
                "state": 10844
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 98
             },
             "error_ratio": {
                "this": 0.9,
                "CBSA": 0.2,
                "state": 0.1
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 48.28,
                "CBSA": 52.1,
                "state": 41.95
             },
             "error": {
                "this": 0.35,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 459086,
                "CBSA": 1101527,
                "state": 1.1856336E7
             },
             "numerator_errors": {
                "this": 3289,
                "CBSA": 515,
                "state": 2592
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 115
             },
             "error_ratio": {
                "this": 0.7,
                "CBSA": null,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 7.43,
                "CBSA": 6.93,
                "state": 11.78
             },
             "error": {
                "this": 0.18,
                "CBSA": 0.08,
                "state": 0.02
             },
             "numerators": {
                "this": 70618,
                "CBSA": 146472,
                "state": 3328707
             },
             "numerator_errors": {
                "this": 1696,
                "CBSA": 1696,
                "state": 6308
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 63
             },
             "error_ratio": {
                "this": 2.4,
                "CBSA": 1.2,
                "state": 0.2
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.19,
                "CBSA": 0.18,
                "state": 0.25
             },
             "error": {
                "this": 0.04,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 1776,
                "CBSA": 3903,
                "state": 71081
             },
             "numerator_errors": {
                "this": 357,
                "CBSA": 550,
                "state": 2412
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 76
             },
             "error_ratio": {
                "this": 21.1,
                "CBSA": 16.7,
                "state": 4
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 7.53,
                "CBSA": 5.8,
                "state": 4.74
             },
             "error": {
                "this": 0.18,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 71576,
                "CBSA": 122709,
                "state": 1340554
             },
             "numerator_errors": {
                "this": 1713,
                "CBSA": 1323,
                "state": 4739
             },
             "index": {
                "this": 100,
                "CBSA": 130,
                "state": 159
             },
             "error_ratio": {
                "this": 2.4,
                "CBSA": 1,
                "state": 0.4
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.02,
                "CBSA": 0.04,
                "state": 0.08
             },
             "error": {
                "this": 0.01,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 234,
                "CBSA": 768,
                "state": 21739
             },
             "numerator_errors": {
                "this": 95,
                "CBSA": 220,
                "state": 1006
             },
             "index": {
                "this": 100,
                "CBSA": 50,
                "state": 25
             },
             "error_ratio": {
                "this": 50,
                "CBSA": 25,
                "state": null
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.2,
                "CBSA": 0.18,
                "state": 0.16
             },
             "error": {
                "this": 0.05,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 1875,
                "CBSA": 3793,
                "state": 44465
             },
             "numerator_errors": {
                "this": 472,
                "CBSA": 575,
                "state": 2907
             },
             "index": {
                "this": 100,
                "CBSA": 111,
                "state": 125
             },
             "error_ratio": {
                "this": 25,
                "CBSA": 16.7,
                "state": 6.2
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 2.44,
                "CBSA": 2.35,
                "state": 1.7
             },
             "error": {
                "this": 0.13,
                "CBSA": 0.1,
                "state": 0.03
             },
             "numerators": {
                "this": 23184,
                "CBSA": 49738,
                "state": 481093
             },
             "numerator_errors": {
                "this": 1269,
                "CBSA": 2190,
                "state": 8687
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 144
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 4.3,
                "state": 1.8
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 33.91,
                "CBSA": 32.42,
                "state": 39.34
             },
             "error": {
                "this": 0.31,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 322458,
                "CBSA": 685531,
                "state": 1.1116881E7
             },
             "numerator_errors": {
                "this": 2959,
                "CBSA": 0,
                "state": 247
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 86
             },
             "error_ratio": {
                "this": 0.9,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 46217,
                "CBSA": 41957,
                "state": 32267
             },
             "error": {
                "this": 1279,
                "CBSA": 834,
                "state": 223
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 110,
                "state": 143
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 2,
                "state": 0.7
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 75413,
                "CBSA": 80954,
                "state": 64034
             },
             "error": {
                "this": 1809,
                "CBSA": 1107,
                "state": 500
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 118
             },
             "error_ratio": {
                "this": 2.4,
                "CBSA": 1.4,
                "state": 0.8
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 32.88,
                   "CBSA": 29.46,
                   "state": 38.99
                },
                "error": {
                   "this": 1.72,
                   "CBSA": 1.22,
                   "state": 0.39
                },
                "numerators": {
                   "this": 134764,
                   "CBSA": 241022,
                   "state": 3893424
                },
                "numerator_errors": {
                   "this": 7295.5,
                   "CBSA": 10197.7,
                   "state": 40331.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 84
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 4.1,
                   "state": 1
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 29.33,
                   "CBSA": 30.48,
                   "state": 30.55
                },
                "error": {
                   "this": 1.87,
                   "CBSA": 1.2,
                   "state": 0.31
                },
                "numerators": {
                   "this": 120216,
                   "CBSA": 249312,
                   "state": 3050409
                },
                "numerator_errors": {
                   "this": 7836.2,
                   "CBSA": 10021.9,
                   "state": 32028.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 96
                },
                "error_ratio": {
                   "this": 6.4,
                   "CBSA": 3.9,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 26.08,
                   "CBSA": 28.37,
                   "state": 22.56
                },
                "error": {
                   "this": 1.35,
                   "CBSA": 1.03,
                   "state": 0.28
                },
                "numerators": {
                   "this": 106892,
                   "CBSA": 232074,
                   "state": 2252409
                },
                "numerator_errors": {
                   "this": 5728.4,
                   "CBSA": 8622.2,
                   "state": 28539.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 116
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 3.6,
                   "state": 1.2
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 11.72,
                   "CBSA": 11.7,
                   "state": 7.9
                },
                "error": {
                   "this": 0.81,
                   "CBSA": 0.62,
                   "state": 0.15
                },
                "numerators": {
                   "this": 48031,
                   "CBSA": 95676,
                   "state": 788884
                },
                "numerator_errors": {
                   "this": 3377,
                   "CBSA": 5106,
                   "state": 15472
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 148
                },
                "error_ratio": {
                   "this": 6.9,
                   "CBSA": 5.3,
                   "state": 1.9
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 12.16,
                "CBSA": 10.14,
                "state": 13.63
             },
             "error": {
                "this": 1.11,
                "CBSA": 0.8,
                "state": 0.25
             },
             "numerators": {
                "this": 116046,
                "CBSA": 221105,
                "state": 3865010
             },
             "numerator_errors": {
                "this": 10614,
                "CBSA": 17373,
                "state": 70183
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 89
             },
             "error_ratio": {
                "this": 9.1,
                "CBSA": 7.9,
                "state": 1.8
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 16.4,
                   "CBSA": 12.8,
                   "state": 19.19
                },
                "error": {
                   "this": 2.16,
                   "CBSA": 1.28,
                   "state": 0.34
                },
                "numerators": {
                   "this": 30819,
                   "CBSA": 63468,
                   "state": 1400918
                },
                "numerator_errors": {
                   "this": 4279.9,
                   "CBSA": 6527.8,
                   "state": 26088.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 85
                },
                "error_ratio": {
                   "this": 13.2,
                   "CBSA": 10,
                   "state": 1.8
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 83.6,
                   "CBSA": 87.2,
                   "state": 80.81
                },
                "error": {
                   "this": 0.73,
                   "CBSA": 2.96,
                   "state": 0.08
                },
                "numerators": {
                   "this": 157052,
                   "CBSA": 432519,
                   "state": 5899369
                },
                "numerator_errors": {
                   "this": 6979.4,
                   "CBSA": 10186.1,
                   "state": 37106.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 103
                },
                "error_ratio": {
                   "this": 0.9,
                   "CBSA": 3.4,
                   "state": 0.1
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 9.2,
                   "CBSA": 6.85,
                   "state": 10.65
                },
                "error": {
                   "this": 1.74,
                   "CBSA": 0.9,
                   "state": 0.29
                },
                "numerators": {
                   "this": 8407,
                   "CBSA": 16687,
                   "state": 389061
                },
                "numerator_errors": {
                   "this": 1621.2,
                   "CBSA": 2211.1,
                   "state": 10616.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 86
                },
                "error_ratio": {
                   "this": 18.9,
                   "CBSA": 13.1,
                   "state": 2.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 90.8,
                   "CBSA": 93.15,
                   "state": 89.35
                },
                "error": {
                   "this": 5,
                   "CBSA": 1.84,
                   "state": 0.54
                },
                "numerators": {
                   "this": 82930,
                   "CBSA": 226763,
                   "state": 3264263
                },
                "numerator_errors": {
                   "this": 3202,
                   "CBSA": 2915.2,
                   "state": 12843.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 102
                },
                "error_ratio": {
                   "this": 5.5,
                   "CBSA": 2,
                   "state": 0.6
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 24.52,
                "CBSA": 27.19,
                "state": 26.64
             },
             "error": {
                "this": 0.24,
                "CBSA": 0.18,
                "state": 0.07
             },
             "numerators": {
                "this": 1.215658E7,
                "CBSA": 2.76471E7,
                "state": 3.31801792E8
             },
             "numerator_errors": {
                "this": 142761,
                "CBSA": 234659,
                "state": 983868
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 90,
                "state": 92
             },
             "error_ratio": {
                "this": 1,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 73.74,
                   "CBSA": 76.26,
                   "state": 80.52
                },
                "error": {
                   "this": 0.49,
                   "CBSA": 0.34,
                   "state": 0.08
                },
                "numerators": {
                   "this": 400461,
                   "CBSA": 850278,
                   "state": 1.0560476E7
                },
                "numerator_errors": {
                   "this": 3336,
                   "CBSA": 5136,
                   "state": 17723
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 92
                },
                "error_ratio": {
                   "this": 0.7,
                   "CBSA": 0.4,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 9.11,
                   "CBSA": 9.15,
                   "state": 9.97
                },
                "error": {
                   "this": 0.4,
                   "CBSA": 0.29,
                   "state": 0.11
                },
                "numerators": {
                   "this": 49455,
                   "CBSA": 102055,
                   "state": 1308229
                },
                "numerator_errors": {
                   "this": 2198,
                   "CBSA": 3303,
                   "state": 14605
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 91
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 3.2,
                   "state": 1.1
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 3.49,
                   "CBSA": 2.02,
                   "state": 1.38
                },
                "error": {
                   "this": 0.25,
                   "CBSA": 0.13,
                   "state": 0.03
                },
                "numerators": {
                   "this": 18949,
                   "CBSA": 22570,
                   "state": 181273
                },
                "numerator_errors": {
                   "this": 1357,
                   "CBSA": 1433,
                   "state": 4220
                },
                "index": {
                   "this": 100,
                   "CBSA": 173,
                   "state": 253
                },
                "error_ratio": {
                   "this": 7.2,
                   "CBSA": 6.4,
                   "state": 2.2
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 1.29,
                   "CBSA": 0.76,
                   "state": 0.24
                },
                "error": {
                   "this": 0.11,
                   "CBSA": 0.07,
                   "state": 0.01
                },
                "numerators": {
                   "this": 6979,
                   "CBSA": 8492,
                   "state": 31812
                },
                "numerator_errors": {
                   "this": 599,
                   "CBSA": 785,
                   "state": 1265
                },
                "index": {
                   "this": 100,
                   "CBSA": 170,
                   "state": 538
                },
                "error_ratio": {
                   "this": 8.5,
                   "CBSA": 9.2,
                   "state": 4.2
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 2.41,
                   "CBSA": 1.81,
                   "state": 1.53
                },
                "error": {
                   "this": 0.17,
                   "CBSA": 0.11,
                   "state": 0.03
                },
                "numerators": {
                   "this": 13083,
                   "CBSA": 20222,
                   "state": 200955
                },
                "numerator_errors": {
                   "this": 926,
                   "CBSA": 1260,
                   "state": 4527
                },
                "index": {
                   "this": 100,
                   "CBSA": 133,
                   "state": 158
                },
                "error_ratio": {
                   "this": 7.1,
                   "CBSA": 6.1,
                   "state": 2
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.25,
                   "CBSA": 1.19,
                   "state": 1.31
                },
                "error": {
                   "this": 0.13,
                   "CBSA": 0.1,
                   "state": 0.03
                },
                "numerators": {
                   "this": 6794,
                   "CBSA": 13285,
                   "state": 171554
                },
                "numerator_errors": {
                   "this": 692,
                   "CBSA": 1123,
                   "state": 3909
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 95
                },
                "error_ratio": {
                   "this": 10.4,
                   "CBSA": 8.4,
                   "state": 2.3
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 8.72,
                   "CBSA": 8.8,
                   "state": 5.04
                },
                "error": {
                   "this": 0.33,
                   "CBSA": 0.25,
                   "state": 0.05
                },
                "numerators": {
                   "this": 47330,
                   "CBSA": 98083,
                   "state": 661212
                },
                "numerator_errors": {
                   "this": 1828,
                   "CBSA": 2821,
                   "state": 6933
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 173
                },
                "error_ratio": {
                   "this": 3.8,
                   "CBSA": 2.8,
                   "state": 1
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 42.67,
                "CBSA": 49.16,
                "state": 50.59
             },
             "error": {
                "this": 1,
                "CBSA": 0.76,
                "state": 0.19
             },
             "numerators": {
                "this": 347914,
                "CBSA": 888127,
                "state": 1.1551548E7
             },
             "numerator_errors": {
                "this": 8404.8,
                "CBSA": 13768.5,
                "state": 42841.5
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 84
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 1.5,
                "state": 0.4
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 57.33,
                "CBSA": 50.84,
                "state": 49.41
             },
             "error": {
                "this": 1.36,
                "CBSA": 0.83,
                "state": 0.22
             },
             "numerators": {
                "this": 467442,
                "CBSA": 918399,
                "state": 1.1282406E7
             },
             "numerator_errors": {
                "this": 11485.9,
                "CBSA": 15012.2,
                "state": 50938
             },
             "index": {
                "this": 100,
                "CBSA": 113,
                "state": 116
             },
             "error_ratio": {
                "this": 2.4,
                "CBSA": 1.6,
                "state": 0.4
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 47.68,
                   "CBSA": 40.17,
                   "state": 36.99
                },
                "error": {
                   "this": 1.37,
                   "CBSA": 0.85,
                   "state": 0.23
                },
                "numerators": {
                   "this": 196908,
                   "CBSA": 360880,
                   "state": 4160255
                },
                "numerator_errors": {
                   "this": 6102,
                   "CBSA": 7689,
                   "state": 26496
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 129
                },
                "error_ratio": {
                   "this": 2.9,
                   "CBSA": 2.1,
                   "state": 0.6
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 39.22,
                   "CBSA": 33.43,
                   "state": 30.68
                },
                "error": {
                   "this": 1.66,
                   "CBSA": 0.89,
                   "state": 0.22
                },
                "numerators": {
                   "this": 157818,
                   "CBSA": 303608,
                   "state": 3555431
                },
                "numerator_errors": {
                   "this": 6892,
                   "CBSA": 8084,
                   "state": 25175
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 128
                },
                "error_ratio": {
                   "this": 4.2,
                   "CBSA": 2.7,
                   "state": 0.7
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 42.37,
                   "CBSA": 49.77,
                   "state": 51.53
                },
                "error": {
                   "this": 1.39,
                   "CBSA": 1.06,
                   "state": 0.27
                },
                "numerators": {
                   "this": 174977,
                   "CBSA": 447063,
                   "state": 5794818
                },
                "numerator_errors": {
                   "this": 6080,
                   "CBSA": 9546,
                   "state": 30276
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 82
                },
                "error_ratio": {
                   "this": 3.3,
                   "CBSA": 2.1,
                   "state": 0.5
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 42.98,
                   "CBSA": 48.56,
                   "state": 49.68
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 1.09,
                   "state": 0.26
                },
                "numerators": {
                   "this": 172937,
                   "CBSA": 441064,
                   "state": 5756730
                },
                "numerator_errors": {
                   "this": 5803,
                   "CBSA": 9922,
                   "state": 30311
                },
                "index": {
                   "this": 100,
                   "CBSA": 89,
                   "state": 87
                },
                "error_ratio": {
                   "this": 3.2,
                   "CBSA": 2.2,
                   "state": 0.5
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 8.64,
                   "CBSA": 8.34,
                   "state": 9.19
                },
                "error": {
                   "this": 1,
                   "CBSA": 0.66,
                   "state": 0.18
                },
                "numerators": {
                   "this": 35677,
                   "CBSA": 74959,
                   "state": 1033776
                },
                "numerator_errors": {
                   "this": 4161,
                   "CBSA": 5932,
                   "state": 19796
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 94
                },
                "error_ratio": {
                   "this": 11.6,
                   "CBSA": 7.9,
                   "state": 2
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 12.52,
                   "CBSA": 12.14,
                   "state": 12.04
                },
                "error": {
                   "this": 1.12,
                   "CBSA": 0.75,
                   "state": 0.19
                },
                "numerators": {
                   "this": 50395,
                   "CBSA": 110236,
                   "state": 1394912
                },
                "numerator_errors": {
                   "this": 4539,
                   "CBSA": 6790,
                   "state": 22510
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 104
                },
                "error_ratio": {
                   "this": 8.9,
                   "CBSA": 6.2,
                   "state": 1.6
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.31,
                   "CBSA": 1.72,
                   "state": 2.29
                },
                "error": {
                   "this": 0.38,
                   "CBSA": 0.27,
                   "state": 0.09
                },
                "numerators": {
                   "this": 5422,
                   "CBSA": 15426,
                   "state": 257478
                },
                "numerator_errors": {
                   "this": 1584,
                   "CBSA": 2388,
                   "state": 9916
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 57
                },
                "error_ratio": {
                   "this": 29,
                   "CBSA": 15.7,
                   "state": 3.9
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 5.27,
                   "CBSA": 5.87,
                   "state": 7.6
                },
                "error": {
                   "this": 0.64,
                   "CBSA": 0.41,
                   "state": 0.14
                },
                "numerators": {
                   "this": 21222,
                   "CBSA": 53290,
                   "state": 880554
                },
                "numerator_errors": {
                   "this": 2601,
                   "CBSA": 3728,
                   "state": 16185
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 69
                },
                "error_ratio": {
                   "this": 12.1,
                   "CBSA": 7,
                   "state": 1.8
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 70.33,
                   "CBSA": 76.03,
                   "state": 69.44
                },
                "error": {
                   "this": 2.21,
                   "CBSA": 1.68,
                   "state": 0.67
                },
                "numerators": {
                   "this": 119505,
                   "CBSA": 344678,
                   "state": 4430717
                },
                "numerator_errors": {
                   "this": 5985,
                   "CBSA": 8789,
                   "state": 46479
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 101
                },
                "error_ratio": {
                   "this": 3.1,
                   "CBSA": 2.2,
                   "state": 1
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 7.2,
                   "CBSA": 5.76,
                   "state": 7.08
                },
                "error": {
                   "this": 1.97,
                   "CBSA": 1.23,
                   "state": 0.39
                },
                "numerators": {
                   "this": 12232,
                   "CBSA": 26095,
                   "state": 451907
                },
                "numerator_errors": {
                   "this": 3377,
                   "CBSA": 5588,
                   "state": 24757
                },
                "index": {
                   "this": 100,
                   "CBSA": 125,
                   "state": 102
                },
                "error_ratio": {
                   "this": 27.4,
                   "CBSA": 21.4,
                   "state": 5.5
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 22.48,
                   "CBSA": 18.21,
                   "state": 23.47
                },
                "error": {
                   "this": 3.21,
                   "CBSA": 1.85,
                   "state": 0.6
                },
                "numerators": {
                   "this": 38192,
                   "CBSA": 82545,
                   "state": 1497626
                },
                "numerator_errors": {
                   "this": 5661,
                   "CBSA": 8441,
                   "state": 38822
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 96
                },
                "error_ratio": {
                   "this": 14.3,
                   "CBSA": 10.2,
                   "state": 2.6
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 4.21,
                "CBSA": 4.46,
                "state": 5.47
             },
             "error": {
                "this": 0.9,
                "CBSA": 0.59,
                "state": 0.17
             },
             "numerators": {
                "this": 11842,
                "CBSA": 26820,
                "state": 390925
             },
             "numerator_errors": {
                "this": 2532,
                "CBSA": 3532,
                "state": 12308
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 94,
                "state": 77
             },
             "error_ratio": {
                "this": 21.4,
                "CBSA": 13.2,
                "state": 3.1
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0.4,
                   "CBSA": 0.87,
                   "state": 1.57
                },
                "error": {
                   "this": 0.47,
                   "CBSA": 0.98,
                   "state": 0.27
                },
                "numerators": {
                   "this": 113,
                   "CBSA": 647,
                   "state": 15891
                },
                "numerator_errors": {
                   "this": 133,
                   "CBSA": 728,
                   "state": 2709
                },
                "index": {
                   "this": 100,
                   "CBSA": 46,
                   "state": 25
                },
                "error_ratio": {
                   "this": 117.5,
                   "CBSA": 112.6,
                   "state": 17.2
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 5.14,
                   "CBSA": 3.58,
                   "state": 6.85
                },
                "error": {
                   "this": 3.02,
                   "CBSA": 1.58,
                   "state": 0.52
                },
                "numerators": {
                   "this": 1850,
                   "CBSA": 2714,
                   "state": 66342
                },
                "numerator_errors": {
                   "this": 1097,
                   "CBSA": 1200,
                   "state": 5077
                },
                "index": {
                   "this": 100,
                   "CBSA": 144,
                   "state": 75
                },
                "error_ratio": {
                   "this": 58.8,
                   "CBSA": 44.1,
                   "state": 7.6
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 3.39,
                   "CBSA": 6.08,
                   "state": 10.13
                },
                "error": {
                   "this": 1.52,
                   "CBSA": 1.68,
                   "state": 0.61
                },
                "numerators": {
                   "this": 1836,
                   "CBSA": 5621,
                   "state": 106126
                },
                "numerator_errors": {
                   "this": 830,
                   "CBSA": 1558,
                   "state": 6470
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 33
                },
                "error_ratio": {
                   "this": 44.8,
                   "CBSA": 27.6,
                   "state": 6
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 10.63,
                   "CBSA": 10.47,
                   "state": 10.1
                },
                "error": {
                   "this": 2.91,
                   "CBSA": 2.25,
                   "state": 0.59
                },
                "numerators": {
                   "this": 5251,
                   "CBSA": 9998,
                   "state": 104476
                },
                "numerator_errors": {
                   "this": 1475,
                   "CBSA": 2177,
                   "state": 6209
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 105
                },
                "error_ratio": {
                   "this": 27.4,
                   "CBSA": 21.5,
                   "state": 5.8
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 3.47,
                   "CBSA": 5.74,
                   "state": 6.57
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 1.72,
                   "state": 0.51
                },
                "numerators": {
                   "this": 1563,
                   "CBSA": 5310,
                   "state": 67203
                },
                "numerator_errors": {
                   "this": 612,
                   "CBSA": 1612,
                   "state": 5285
                },
                "index": {
                   "this": 100,
                   "CBSA": 60,
                   "state": 53
                },
                "error_ratio": {
                   "this": 38.6,
                   "CBSA": 30,
                   "state": 7.8
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 3.64,
                   "CBSA": 2.74,
                   "state": 2.15
                },
                "error": {
                   "this": 2.71,
                   "CBSA": 1.22,
                   "state": 0.36
                },
                "numerators": {
                   "this": 1198,
                   "CBSA": 2191,
                   "state": 20345
                },
                "numerator_errors": {
                   "this": 900,
                   "CBSA": 987,
                   "state": 3416
                },
                "index": {
                   "this": 100,
                   "CBSA": 133,
                   "state": 169
                },
                "error_ratio": {
                   "this": 74.5,
                   "CBSA": 44.5,
                   "state": 16.7
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0.09,
                   "CBSA": 0.37,
                   "state": 0.94
                },
                "error": {
                   "this": 0.15,
                   "CBSA": 0.27,
                   "state": 0.18
                },
                "numerators": {
                   "this": 31,
                   "CBSA": 339,
                   "state": 10542
                },
                "numerator_errors": {
                   "this": 53,
                   "CBSA": 242,
                   "state": 2044
                },
                "index": {
                   "this": 100,
                   "CBSA": 24,
                   "state": 10
                },
                "error_ratio": {
                   "this": 166.7,
                   "CBSA": 73,
                   "state": 19.1
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 409903,
                "CBSA": 818084,
                "state": 9985126
             },
             "error": {
                "this": 5829,
                "CBSA": 6935,
                "state": 25440
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 50,
                "state": 4
             },
             "error_ratio": {
                "this": 1.4,
                "CBSA": 0.8,
                "state": 0.3
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.33,
                "CBSA": 2.67,
                "state": 2.84
             },
             "error": {
                "this": 0.04,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 954640,
                "CBSA": 2181633,
                "state": 2.8395188E7
             },
             "numerator_errors": {
                "this": 7806,
                "CBSA": 7576,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 82
             },
             "error_ratio": {
                "this": 1.7,
                "CBSA": 0.7,
                "state": 0.4
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 49.6,
                   "CBSA": 60.25,
                   "state": 60.97
                },
                "error": {
                   "this": 1.5,
                   "CBSA": 1.06,
                   "state": 0.33
                },
                "numerators": {
                   "this": 473527,
                   "CBSA": 1314393,
                   "state": 1.731344E7
                },
                "numerator_errors": {
                   "this": 14866,
                   "CBSA": 23645,
                   "state": 94119
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 81
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 1.8,
                   "state": 0.5
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 6.29,
                   "CBSA": 5.61,
                   "state": 6.94
                },
                "error": {
                   "this": 1.19,
                   "CBSA": 0.8,
                   "state": 0.23
                },
                "numerators": {
                   "this": 60051,
                   "CBSA": 122449,
                   "state": 1970635
                },
                "numerator_errors": {
                   "this": 11407,
                   "CBSA": 17356,
                   "state": 65095
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 91
                },
                "error_ratio": {
                   "this": 18.9,
                   "CBSA": 14.3,
                   "state": 3.3
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 13.5,
                   "CBSA": 13.47,
                   "state": 17.96
                },
                "error": {
                   "this": 1.26,
                   "CBSA": 0.9,
                   "state": 0.28
                },
                "numerators": {
                   "this": 128915,
                   "CBSA": 293970,
                   "state": 5100149
                },
                "numerator_errors": {
                   "this": 12122,
                   "CBSA": 19769,
                   "state": 78254
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 75
                },
                "error_ratio": {
                   "this": 9.3,
                   "CBSA": 6.7,
                   "state": 1.6
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 30.6,
                   "CBSA": 20.66,
                   "state": 14.13
                },
                "error": {
                   "this": 1.23,
                   "CBSA": 0.77,
                   "state": 0.16
                },
                "numerators": {
                   "this": 292147,
                   "CBSA": 450821,
                   "state": 4010963
                },
                "numerator_errors": {
                   "this": 11964,
                   "CBSA": 16946,
                   "state": 44267
                },
                "index": {
                   "this": 100,
                   "CBSA": 148,
                   "state": 217
                },
                "error_ratio": {
                   "this": 4,
                   "CBSA": 3.7,
                   "state": 1.1
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 442388,
                "CBSA": 882659,
                "state": 1.1283892E7
             },
             "error": {
                "this": 4948,
                "CBSA": 3085,
                "state": 954
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 50,
                "state": 4
             },
             "error_ratio": {
                "this": 1.1,
                "CBSA": 0.3,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 92.66,
                   "CBSA": 92.68,
                   "state": 88.49
                },
                "error": {
                   "this": 0.81,
                   "CBSA": 0.72,
                   "state": 0.23
                },
                "numerators": {
                   "this": 409903,
                   "CBSA": 818084,
                   "state": 9985126
                },
                "numerator_errors": {
                   "this": 5829,
                   "CBSA": 6935,
                   "state": 25440
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 105
                },
                "error_ratio": {
                   "this": 0.9,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 7.34,
                   "CBSA": 7.32,
                   "state": 11.51
                },
                "error": {
                   "this": 0.91,
                   "CBSA": 0.71,
                   "state": 0.23
                },
                "numerators": {
                   "this": 32485,
                   "CBSA": 64575,
                   "state": 1298766
                },
                "numerator_errors": {
                   "this": 4048,
                   "CBSA": 6249,
                   "state": 25690
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 64
                },
                "error_ratio": {
                   "this": 12.4,
                   "CBSA": 9.7,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 51.13,
                   "CBSA": 62.71,
                   "state": 67.67
                },
                "error": {
                   "this": 0.48,
                   "CBSA": 0.35,
                   "state": 0.13
                },
                "numerators": {
                   "this": 212175,
                   "CBSA": 520814,
                   "state": 7401058
                },
                "numerator_errors": {
                   "this": 2192.7,
                   "CBSA": 2984.7,
                   "state": 13948.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 76
                },
                "error_ratio": {
                   "this": 0.9,
                   "CBSA": 0.6,
                   "state": 0.2
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 47.39,
                   "CBSA": 32.41,
                   "state": 25.07
                },
                "error": {
                   "this": 0.77,
                   "CBSA": 0.51,
                   "state": 0.14
                },
                "numerators": {
                   "this": 196669,
                   "CBSA": 269137,
                   "state": 2741795
                },
                "numerator_errors": {
                   "this": 3311.1,
                   "CBSA": 4214.8,
                   "state": 15298.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 146,
                   "state": 189
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 1.6,
                   "state": 0.6
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 1.41,
                   "CBSA": 4.76,
                   "state": 7.09
                },
                "error": {
                   "this": 0.13,
                   "CBSA": 0.18,
                   "state": 0.07
                },
                "numerators": {
                   "this": 5833,
                   "CBSA": 39571,
                   "state": 775632
                },
                "numerator_errors": {
                   "this": 532,
                   "CBSA": 1465,
                   "state": 7495
                },
                "index": {
                   "this": 100,
                   "CBSA": 30,
                   "state": 20
                },
                "error_ratio": {
                   "this": 9.2,
                   "CBSA": 3.8,
                   "state": 1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0.08,
                   "CBSA": 0.12,
                   "state": 0.17
                },
                "error": {
                   "this": 0.04,
                   "CBSA": 0.03,
                   "state": 0.01
                },
                "numerators": {
                   "this": 329,
                   "CBSA": 1015,
                   "state": 18541
                },
                "numerator_errors": {
                   "this": 156,
                   "CBSA": 254,
                   "state": 1135
                },
                "index": {
                   "this": 100,
                   "CBSA": 67,
                   "state": 47
                },
                "error_ratio": {
                   "this": 50,
                   "CBSA": 25,
                   "state": 5.9
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 44.42,
                   "CBSA": 57.63,
                   "state": 61.88
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 0.93,
                   "state": 0.27
                },
                "numerators": {
                   "this": 182068,
                   "CBSA": 471480,
                   "state": 6179278
                },
                "numerator_errors": {
                   "this": 6148,
                   "CBSA": 8594,
                   "state": 31366
                },
                "index": {
                   "this": 100,
                   "CBSA": 77,
                   "state": 72
                },
                "error_ratio": {
                   "this": 3.1,
                   "CBSA": 1.6,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 55.58,
                   "CBSA": 42.37,
                   "state": 38.12
                },
                "error": {
                   "this": 1.43,
                   "CBSA": 1.07,
                   "state": 0.25
                },
                "numerators": {
                   "this": 227835,
                   "CBSA": 346604,
                   "state": 3805848
                },
                "numerator_errors": {
                   "this": 6710,
                   "CBSA": 9197,
                   "state": 26478
                },
                "index": {
                   "this": 100,
                   "CBSA": 131,
                   "state": 146
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 2.5,
                   "state": 0.7
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 378300,
                "CBSA": 318400,
                "state": 200400
             },
             "error": {
                "this": 7282,
                "CBSA": 5545,
                "state": 1201
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 119,
                "state": 189
             },
             "error_ratio": {
                "this": 1.9,
                "CBSA": 1.7,
                "state": 0.6
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 3.1,
                   "CBSA": 6.84,
                   "state": 21.11
                },
                "error": {
                   "this": 0.82,
                   "CBSA": 0.78,
                   "state": 0.31
                },
                "numerators": {
                   "this": 5636,
                   "CBSA": 32272,
                   "state": 1304173
                },
                "numerator_errors": {
                   "this": 1510.7,
                   "CBSA": 3709.2,
                   "state": 20455.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 45,
                   "state": 15
                },
                "error_ratio": {
                   "this": 26.5,
                   "CBSA": 11.4,
                   "state": 1.5
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 6.54,
                   "CBSA": 11.42,
                   "state": 28.78
                },
                "error": {
                   "this": 1.18,
                   "CBSA": 0.92,
                   "state": 0.35
                },
                "numerators": {
                   "this": 11901,
                   "CBSA": 53850,
                   "state": 1778664
                },
                "numerator_errors": {
                   "this": 2180.6,
                   "CBSA": 4437.6,
                   "state": 23214.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 23
                },
                "error_ratio": {
                   "this": 18,
                   "CBSA": 8.1,
                   "state": 1.2
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 22.72,
                   "CBSA": 27.95,
                   "state": 22.74
                },
                "error": {
                   "this": 1.95,
                   "CBSA": 1.38,
                   "state": 0.3
                },
                "numerators": {
                   "this": 41359,
                   "CBSA": 131797,
                   "state": 1405305
                },
                "numerator_errors": {
                   "this": 3814.3,
                   "CBSA": 6939,
                   "state": 20021.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 100
                },
                "error_ratio": {
                   "this": 8.6,
                   "CBSA": 4.9,
                   "state": 1.3
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 22.56,
                   "CBSA": 20.52,
                   "state": 12.27
                },
                "error": {
                   "this": 1.68,
                   "CBSA": 1.08,
                   "state": 0.24
                },
                "numerators": {
                   "this": 41066,
                   "CBSA": 96750,
                   "state": 758306
                },
                "numerator_errors": {
                   "this": 3354,
                   "CBSA": 5406,
                   "state": 15195
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 184
                },
                "error_ratio": {
                   "this": 7.4,
                   "CBSA": 5.3,
                   "state": 2
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 15.08,
                   "CBSA": 12.41,
                   "state": 6.21
                },
                "error": {
                   "this": 1.23,
                   "CBSA": 0.84,
                   "state": 0.15
                },
                "numerators": {
                   "this": 27461,
                   "CBSA": 58519,
                   "state": 383802
                },
                "numerator_errors": {
                   "this": 2427,
                   "CBSA": 4079,
                   "state": 9774
                },
                "index": {
                   "this": 100,
                   "CBSA": 122,
                   "state": 243
                },
                "error_ratio": {
                   "this": 8.2,
                   "CBSA": 6.8,
                   "state": 2.4
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 24.12,
                   "CBSA": 16.81,
                   "state": 7.06
                },
                "error": {
                   "this": 1.62,
                   "CBSA": 0.87,
                   "state": 0.17
                },
                "numerators": {
                   "this": 43921,
                   "CBSA": 79248,
                   "state": 436207
                },
                "numerator_errors": {
                   "this": 3301.2,
                   "CBSA": 4360.3,
                   "state": 10621.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 143,
                   "state": 342
                },
                "error_ratio": {
                   "this": 6.7,
                   "CBSA": 5.2,
                   "state": 2.4
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 3.93,
                   "CBSA": 2.56,
                   "state": 0.96
                },
                "error": {
                   "this": 0.86,
                   "CBSA": 0.45,
                   "state": 0.07
                },
                "numerators": {
                   "this": 7154,
                   "CBSA": 12062,
                   "state": 59440
                },
                "numerator_errors": {
                   "this": 1582,
                   "CBSA": 2139,
                   "state": 4245
                },
                "index": {
                   "this": 100,
                   "CBSA": 154,
                   "state": 409
                },
                "error_ratio": {
                   "this": 21.9,
                   "CBSA": 17.6,
                   "state": 7.3
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 182068,
                "CBSA": 471480,
                "state": 6179278
             },
             "error": {
                "this": 6148,
                "CBSA": 8594,
                "state": 31366
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 39,
                "state": 3
             },
             "error_ratio": {
                "this": 3.4,
                "CBSA": 1.8,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 4.65,
                "CBSA": 3.92,
                "state": 7.22
             },
             "error": {
                "this": 0.56,
                "CBSA": 0.36,
                "state": 0.15
             },
             "numerators": {
                "this": 44429,
                "CBSA": 85603,
                "state": 2050096
             },
             "numerator_errors": {
                "this": 5311.5,
                "CBSA": 7768.5,
                "state": 41933.8
             },
             "index": {
                "this": 100,
                "CBSA": 119,
                "state": 64
             },
             "error_ratio": {
                "this": 12,
                "CBSA": 9.2,
                "state": 2.1
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 6.52,
                "CBSA": 7.13,
                "state": 9.09
             },
             "error": {
                "this": 0.76,
                "CBSA": 0.69,
                "state": 0.19
             },
             "numerators": {
                "this": 62230,
                "CBSA": 155622,
                "state": 2581380
             },
             "numerator_errors": {
                "this": 7252.8,
                "CBSA": 15084.1,
                "state": 53365.9
             },
             "index": {
                "this": 100,
                "CBSA": 91,
                "state": 72
             },
             "error_ratio": {
                "this": 11.7,
                "CBSA": 9.7,
                "state": 2.1
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 15.73,
                "CBSA": 19.77,
                "state": 21.45
             },
             "error": {
                "this": 1.43,
                "CBSA": 0.99,
                "state": 0.32
             },
             "numerators": {
                "this": 150176,
                "CBSA": 431243,
                "state": 6090886
             },
             "numerator_errors": {
                "this": 13721.8,
                "CBSA": 21611.1,
                "state": 92205.4
             },
             "index": {
                "this": 100,
                "CBSA": 80,
                "state": 73
             },
             "error_ratio": {
                "this": 9.1,
                "CBSA": 5,
                "state": 1.5
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 15.77,
                "CBSA": 16.6,
                "state": 17.17
             },
             "error": {
                "this": 1.33,
                "CBSA": 0.83,
                "state": 0.3
             },
             "numerators": {
                "this": 150533,
                "CBSA": 362149,
                "state": 4874430
             },
             "numerator_errors": {
                "this": 12779.7,
                "CBSA": 18143.4,
                "state": 83891.2
             },
             "index": {
                "this": 100,
                "CBSA": 95,
                "state": 92
             },
             "error_ratio": {
                "this": 8.4,
                "CBSA": 5,
                "state": 1.7
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 13.03,
                "CBSA": 15.15,
                "state": 13.69
             },
             "error": {
                "this": 1.28,
                "CBSA": 1.01,
                "state": 0.26
             },
             "numerators": {
                "this": 124386,
                "CBSA": 330539,
                "state": 3886300
             },
             "numerator_errors": {
                "this": 12214.9,
                "CBSA": 22062.8,
                "state": 73652.8
             },
             "index": {
                "this": 100,
                "CBSA": 86,
                "state": 95
             },
             "error_ratio": {
                "this": 9.8,
                "CBSA": 6.7,
                "state": 1.9
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 44.3,
                "CBSA": 37.43,
                "state": 31.39
             },
             "error": {
                "this": 2.06,
                "CBSA": 1.27,
                "state": 0.37
             },
             "numerators": {
                "this": 422886,
                "CBSA": 816477,
                "state": 8912096
             },
             "numerator_errors": {
                "this": 19970.1,
                "CBSA": 27948.7,
                "state": 106478.7
             },
             "index": {
                "this": 100,
                "CBSA": 118,
                "state": 141
             },
             "error_ratio": {
                "this": 4.7,
                "CBSA": 3.4,
                "state": 1.2
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 22.09,
                "CBSA": 18.48,
                "state": 14.99
             },
             "error": {
                "this": 1.41,
                "CBSA": 0.89,
                "state": 0.23
             },
             "numerators": {
                "this": 213743,
                "CBSA": 407063,
                "state": 4294700
             },
             "numerator_errors": {
                "this": 13612.8,
                "CBSA": 19662.2,
                "state": 64565.9
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 147
             },
             "error_ratio": {
                "this": 6.4,
                "CBSA": 4.8,
                "state": 1.5
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 77.91,
                "CBSA": 81.52,
                "state": 85.01
             },
             "error": {
                "this": 1.47,
                "CBSA": 0.78,
                "state": 0.24
             },
             "numerators": {
                "this": 753834,
                "CBSA": 1795519,
                "state": 2.4347958E7
             },
             "numerator_errors": {
                "this": 14368,
                "CBSA": 17232,
                "state": 68604
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 92
             },
             "error_ratio": {
                "this": 1.9,
                "CBSA": 1,
                "state": 0.3
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 13.07,
                "CBSA": 9.53,
                "state": 8.46
             },
             "error": {
                "this": 1.08,
                "CBSA": 0.64,
                "state": 0.18
             },
             "numerators": {
                "this": 126443,
                "CBSA": 209861,
                "state": 2422091
             },
             "numerator_errors": {
                "this": 10418,
                "CBSA": 14109,
                "state": 50520
             },
             "index": {
                "this": 100,
                "CBSA": 137,
                "state": 154
             },
             "error_ratio": {
                "this": 8.3,
                "CBSA": 6.7,
                "state": 2.1
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 4.49,
                "CBSA": 5.25,
                "state": 3.83
             },
             "error": {
                "this": 0.66,
                "CBSA": 0.47,
                "state": 0.1
             },
             "numerators": {
                "this": 43408,
                "CBSA": 115725,
                "state": 1095863
             },
             "numerator_errors": {
                "this": 6357,
                "CBSA": 10372,
                "state": 28817
             },
             "index": {
                "this": 100,
                "CBSA": 86,
                "state": 117
             },
             "error_ratio": {
                "this": 14.7,
                "CBSA": 9,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 3.17,
                "CBSA": 2.84,
                "state": 1.95
             },
             "error": {
                "this": 0.51,
                "CBSA": 0.35,
                "state": 0.08
             },
             "numerators": {
                "this": 30690,
                "CBSA": 62502,
                "state": 559661
             },
             "numerator_errors": {
                "this": 4906,
                "CBSA": 7792,
                "state": 22968
             },
             "index": {
                "this": 100,
                "CBSA": 112,
                "state": 163
             },
             "error_ratio": {
                "this": 16.1,
                "CBSA": 12.3,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 1.36,
                "CBSA": 0.86,
                "state": 0.76
             },
             "error": {
                "this": 0.36,
                "CBSA": 0.2,
                "state": 0.06
             },
             "numerators": {
                "this": 13202,
                "CBSA": 18975,
                "state": 217085
             },
             "numerator_errors": {
                "this": 3506,
                "CBSA": 4387,
                "state": 16079
             },
             "index": {
                "this": 100,
                "CBSA": 158,
                "state": 179
             },
             "error_ratio": {
                "this": 26.5,
                "CBSA": 23.3,
                "state": 7.9
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 89.98,
                "CBSA": 90.39,
                "state": 84.65
             },
             "error": {
                "this": 2.01,
                "CBSA": 1.41,
                "state": 0.4
             },
             "numerators": {
                "this": 618061,
                "CBSA": 1362741,
                "state": 1.5890162E7
             },
             "numerator_errors": {
                "this": 14681.4,
                "CBSA": 21341.6,
                "state": 75187
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 106
             },
             "error_ratio": {
                "this": 2.2,
                "CBSA": 1.6,
                "state": 0.5
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 55.05,
                "CBSA": 46.16,
                "state": 30.77
             },
             "error": {
                "this": 1.51,
                "CBSA": 0.89,
                "state": 0.22
             },
             "numerators": {
                "this": 378126,
                "CBSA": 695959,
                "state": 5776533
             },
             "numerator_errors": {
                "this": 10808.7,
                "CBSA": 13537.8,
                "state": 42322.2
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 119,
                "state": 179
             },
             "error_ratio": {
                "this": 2.7,
                "CBSA": 1.9,
                "state": 0.7
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 10.02,
                "CBSA": 9.61,
                "state": 15.35
             },
             "error": {
                "this": 0.88,
                "CBSA": 0.61,
                "state": 0.18
             },
             "numerators": {
                "this": 68818,
                "CBSA": 144966,
                "state": 2882388
             },
             "numerator_errors": {
                "this": 6093.7,
                "CBSA": 9138.9,
                "state": 34014.4
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 65
             },
             "error_ratio": {
                "this": 8.8,
                "CBSA": 6.3,
                "state": 1.2
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 14.24,
                "CBSA": 19.24,
                "state": 25.22
             },
             "error": {
                "this": 0.92,
                "CBSA": 0.73,
                "state": 0.24
             },
             "numerators": {
                "this": 97804,
                "CBSA": 290121,
                "state": 4734422
             },
             "numerator_errors": {
                "this": 6336.5,
                "CBSA": 11090.8,
                "state": 44301.5
             },
             "index": {
                "this": 100,
                "CBSA": 74,
                "state": 56
             },
             "error_ratio": {
                "this": 6.5,
                "CBSA": 3.8,
                "state": 1
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 20.69,
                "CBSA": 24.98,
                "state": 28.65
             },
             "error": {
                "this": 1.1,
                "CBSA": 0.81,
                "state": 0.23
             },
             "numerators": {
                "this": 142131,
                "CBSA": 376661,
                "state": 5379207
             },
             "numerator_errors": {
                "this": 7652.7,
                "CBSA": 12214.1,
                "state": 43580.8
             },
             "index": {
                "this": 100,
                "CBSA": 83,
                "state": 72
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 3.2,
                "state": 0.8
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 33.93,
                "CBSA": 29.55,
                "state": 19.98
             },
             "error": {
                "this": 1.16,
                "CBSA": 0.67,
                "state": 0.17
             },
             "numerators": {
                "this": 233048,
                "CBSA": 445551,
                "state": 3750797
             },
             "numerator_errors": {
                "this": 8197.5,
                "CBSA": 10184.7,
                "state": 32752
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 170
             },
             "error_ratio": {
                "this": 3.4,
                "CBSA": 2.3,
                "state": 0.9
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 21.12,
                "CBSA": 16.61,
                "state": 10.79
             },
             "error": {
                "this": 1.01,
                "CBSA": 0.59,
                "state": 0.14
             },
             "numerators": {
                "this": 145078,
                "CBSA": 250408,
                "state": 2025736
             },
             "numerator_errors": {
                "this": 7044.8,
                "CBSA": 8918.8,
                "state": 26804.5
             },
             "index": {
                "this": 100,
                "CBSA": 127,
                "state": 196
             },
             "error_ratio": {
                "this": 4.8,
                "CBSA": 3.6,
                "state": 1.3
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 19.64,
                "CBSA": 15.72,
                "state": 17.08
             },
             "error": {
                "this": 0.99,
                "CBSA": 0.67,
                "state": 0.18
             },
             "numerators": {
                "this": 192297,
                "CBSA": 350067,
                "state": 4951156
             },
             "numerator_errors": {
                "this": 9716,
                "CBSA": 14916,
                "state": 53285
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 125,
                "state": 115
             },
             "error_ratio": {
                "this": 5,
                "CBSA": 4.3,
                "state": 1.1
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 6.82,
                   "CBSA": 7.03,
                   "state": 4.1
                },
                "error": {
                   "this": 0.45,
                   "CBSA": 0.35,
                   "state": 0.08
                },
                "numerators": {
                   "this": 12219,
                   "CBSA": 22561,
                   "state": 197344
                },
                "numerator_errors": {
                   "this": 856,
                   "CBSA": 1193,
                   "state": 4138
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 166
                },
                "error_ratio": {
                   "this": 6.6,
                   "CBSA": 5,
                   "state": 2
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 31.93,
                   "CBSA": 29.93,
                   "state": 21.99
                },
                "error": {
                   "this": 0.61,
                   "CBSA": 0.41,
                   "state": 0.1
                },
                "numerators": {
                   "this": 57203,
                   "CBSA": 96012,
                   "state": 1058789
                },
                "numerator_errors": {
                   "this": 1759,
                   "CBSA": 2184,
                   "state": 7006
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 145
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 1.4,
                   "state": 0.5
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 4.26,
                   "CBSA": 4.64,
                   "state": 5.16
                },
                "error": {
                   "this": 0.87,
                   "CBSA": 0.69,
                   "state": 0.13
                },
                "numerators": {
                   "this": 7635,
                   "CBSA": 14890,
                   "state": 248298
                },
                "numerator_errors": {
                   "this": 1574,
                   "CBSA": 2222,
                   "state": 6426
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 83
                },
                "error_ratio": {
                   "this": 20.4,
                   "CBSA": 14.9,
                   "state": 2.5
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.41,
                   "CBSA": 0.39,
                   "state": 0.31
                },
                "error": {
                   "this": 0.11,
                   "CBSA": 0.09,
                   "state": 0.02
                },
                "numerators": {
                   "this": 731,
                   "CBSA": 1249,
                   "state": 14696
                },
                "numerator_errors": {
                   "this": 203,
                   "CBSA": 293,
                   "state": 1000
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 132
                },
                "error_ratio": {
                   "this": 26.8,
                   "CBSA": 23.1,
                   "state": 6.5
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 54.91,
                   "CBSA": 56.11,
                   "state": 67.38
                },
                "error": {
                   "this": 1.62,
                   "CBSA": 1.2,
                   "state": 0.25
                },
                "numerators": {
                   "this": 98357,
                   "CBSA": 179986,
                   "state": 3244189
                },
                "numerator_errors": {
                   "this": 3740,
                   "CBSA": 5055,
                   "state": 19564
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 81
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 2.1,
                   "state": 0.4
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 1.66,
                   "CBSA": 1.9,
                   "state": 1.07
                },
                "error": {
                   "this": 0.28,
                   "CBSA": 0.22,
                   "state": 0.05
                },
                "numerators": {
                   "this": 2980,
                   "CBSA": 6082,
                   "state": 51322
                },
                "numerator_errors": {
                   "this": 505,
                   "CBSA": 709,
                   "state": 2465
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 155
                },
                "error_ratio": {
                   "this": 16.9,
                   "CBSA": 11.6,
                   "state": 4.7
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 35.46
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.11
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 9313242
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 30033.2
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 64.82,
                   "CBSA": 70.6,
                   "state": 64.96
                },
                "error": {
                   "this": 3.12,
                   "CBSA": 1.94,
                   "state": 0.53
                },
                "numerators": {
                   "this": 86485,
                   "CBSA": 259866,
                   "state": 3517674
                },
                "numerator_errors": {
                   "this": 5004,
                   "CBSA": 7211,
                   "state": 28884
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 100
                },
                "error_ratio": {
                   "this": 4.8,
                   "CBSA": 2.7,
                   "state": 0.8
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 27.94,
                   "CBSA": 23.98,
                   "state": 30.55
                },
                "error": {
                   "this": 3.75,
                   "CBSA": 2.01,
                   "state": 0.5
                },
                "numerators": {
                   "this": 37273,
                   "CBSA": 88259,
                   "state": 1654300
                },
                "numerator_errors": {
                   "this": 5139,
                   "CBSA": 7415,
                   "state": 27332
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 91
                },
                "error_ratio": {
                   "this": 13.4,
                   "CBSA": 8.4,
                   "state": 1.6
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 2.77,
                   "CBSA": 2.57,
                   "state": 1.62
                },
                "error": {
                   "this": 0.93,
                   "CBSA": 0.52,
                   "state": 0.14
                },
                "numerators": {
                   "this": 3699,
                   "CBSA": 9468,
                   "state": 87522
                },
                "numerator_errors": {
                   "this": 1248,
                   "CBSA": 1923,
                   "state": 7561
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 171
                },
                "error_ratio": {
                   "this": 33.6,
                   "CBSA": 20.2,
                   "state": 8.6
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 4.19,
                   "CBSA": 2.34,
                   "state": 2.01
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 0.54,
                   "state": 0.11
                },
                "numerators": {
                   "this": 5586,
                   "CBSA": 8610,
                   "state": 108786
                },
                "numerator_errors": {
                   "this": 1803,
                   "CBSA": 1986,
                   "state": 6178
                },
                "index": {
                   "this": 100,
                   "CBSA": 179,
                   "state": 208
                },
                "error_ratio": {
                   "this": 32,
                   "CBSA": 23.1,
                   "state": 5.5
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.28,
                   "CBSA": 0.51,
                   "state": 0.86
                },
                "error": {
                   "this": 0.37,
                   "CBSA": 0.3,
                   "state": 0.12
                },
                "numerators": {
                   "this": 377,
                   "CBSA": 1865,
                   "state": 46594
                },
                "numerator_errors": {
                   "this": 500,
                   "CBSA": 1088,
                   "state": 6620
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 33
                },
                "error_ratio": {
                   "this": 132.1,
                   "CBSA": 58.8,
                   "state": 14
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 69.68,
                   "CBSA": 71.82,
                   "state": 64.32
                },
                "error": {
                   "this": 1.04,
                   "CBSA": 0.7,
                   "state": 0.19
                },
                "numerators": {
                   "this": 549189,
                   "CBSA": 1236912,
                   "state": 1.3892786E7
                },
                "numerator_errors": {
                   "this": 9183.3,
                   "CBSA": 12104.7,
                   "state": 40557.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 108
                },
                "error_ratio": {
                   "this": 1.5,
                   "CBSA": 1,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 20.31,
                   "CBSA": 20.13,
                   "state": 28.8
                },
                "error": {
                   "this": 1.06,
                   "CBSA": 0.62,
                   "state": 0.15
                },
                "numerators": {
                   "this": 160057,
                   "CBSA": 346657,
                   "state": 6220553
                },
                "numerator_errors": {
                   "this": 8462.1,
                   "CBSA": 10658.1,
                   "state": 32492.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 71
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 3.1,
                   "state": 0.5
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 4.14,
                   "CBSA": 3.42,
                   "state": 2.58
                },
                "error": {
                   "this": 0.64,
                   "CBSA": 0.37,
                   "state": 0.09
                },
                "numerators": {
                   "this": 32648,
                   "CBSA": 58967,
                   "state": 557894
                },
                "numerator_errors": {
                   "this": 5087.1,
                   "CBSA": 6341.8,
                   "state": 18595.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 121,
                   "state": 160
                },
                "error_ratio": {
                   "this": 15.5,
                   "CBSA": 10.8,
                   "state": 3.5
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 4.85,
                   "CBSA": 3.59,
                   "state": 3.21
                },
                "error": {
                   "this": 0.59,
                   "CBSA": 0.28,
                   "state": 0.08
                },
                "numerators": {
                   "this": 38248,
                   "CBSA": 61865,
                   "state": 693733
                },
                "numerator_errors": {
                   "this": 4632.5,
                   "CBSA": 4877,
                   "state": 16467
                },
                "index": {
                   "this": 100,
                   "CBSA": 135,
                   "state": 151
                },
                "error_ratio": {
                   "this": 12.2,
                   "CBSA": 7.8,
                   "state": 2.5
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.02,
                   "CBSA": 1.04,
                   "state": 1.08
                },
                "error": {
                   "this": 0.44,
                   "CBSA": 0.31,
                   "state": 0.07
                },
                "numerators": {
                   "this": 8055,
                   "CBSA": 17909,
                   "state": 234284
                },
                "numerator_errors": {
                   "this": 3470.4,
                   "CBSA": 5348.9,
                   "state": 14540.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 94
                },
                "error_ratio": {
                   "this": 43.1,
                   "CBSA": 29.8,
                   "state": 6.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 1084,
                   "CBSA": 2237,
                   "state": 35189
                },
                "error": {
                   "this": 262.71,
                   "CBSA": 362.97,
                   "state": 1151.18
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 48,
                   "state": 3
                },
                "error_ratio": {
                   "this": 24.2,
                   "CBSA": 16.2,
                   "state": 3.3
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 2129,
                   "CBSA": 5812,
                   "state": 89228
                },
                "error": {
                   "this": 292.08,
                   "CBSA": 481.06,
                   "state": 2025.01
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 37,
                   "state": 2
                },
                "error_ratio": {
                   "this": 13.7,
                   "CBSA": 8.3,
                   "state": 2.3
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 11251,
                   "CBSA": 34199,
                   "state": 479483
                },
                "error": {
                   "this": 768.85,
                   "CBSA": 1244.61,
                   "state": 4078.22
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 33,
                   "state": 2
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 3.6,
                   "state": 0.9
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 8994,
                   "CBSA": 30448,
                   "state": 372708
                },
                "error": {
                   "this": 813.31,
                   "CBSA": 1602.12,
                   "state": 5369.62
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 30,
                   "state": 2
                },
                "error_ratio": {
                   "this": 9,
                   "CBSA": 5.3,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 10863,
                   "CBSA": 29714,
                   "state": 374227
                },
                "error": {
                   "this": 931.55,
                   "CBSA": 1488.74,
                   "state": 5646.59
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 37,
                   "state": 3
                },
                "error_ratio": {
                   "this": 8.6,
                   "CBSA": 5,
                   "state": 1.5
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 29676,
                   "CBSA": 96838,
                   "state": 1244796
                },
                "error": {
                   "this": 2862,
                   "CBSA": 4953,
                   "state": 15502
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 31,
                   "state": 2
                },
                "error_ratio": {
                   "this": 9.6,
                   "CBSA": 5.1,
                   "state": 1.2
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 3580,
                   "CBSA": 10690,
                   "state": 160263
                },
                "error": {
                   "this": 1232,
                   "CBSA": 1996,
                   "state": 6897
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 33,
                   "state": 2
                },
                "error_ratio": {
                   "this": 34.4,
                   "CBSA": 18.7,
                   "state": 4.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 33256,
                "CBSA": 107528,
                "state": 1405059
             },
             "error": {
                "this": 3094,
                "CBSA": 4997,
                "state": 15978
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 31,
                "state": 2
             },
             "error_ratio": {
                "this": 9.3,
                "CBSA": 4.6,
                "state": 1.1
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 4.22,
                "CBSA": 6.25,
                "state": 6.54
             },
             "error": {
                "this": 0.39,
                "CBSA": 0.29,
                "state": 0.07
             },
             "numerators": {
                "this": 33256,
                "CBSA": 107528,
                "state": 1405059
             },
             "numerator_errors": {
                "this": 3094,
                "CBSA": 4997,
                "state": 15978
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 68,
                "state": 65
             },
             "error_ratio": {
                "this": 9.2,
                "CBSA": 4.6,
                "state": 1.1
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 828637538,
       "awater": 17024681,
       "display_name": "Austin, TX",
       "full_geoid": "16000US4805000",
       "population": 935755,
       "simple_name": "Austin",
       "sumlevel": "160",
       "square_miles": 319.9,
       "population_density": 3060.8
    }
 }

 export default austin