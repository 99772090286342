exports.SAN_LEANDRO_SCHOOLS = [
		{
			"id": 13984,
			"districtId": 1069,
			"districtName": "Alameda County Office of Education",
			"districtCity": "Hayward",
			"levelCode": "e,m,h",
			"lat": 37.716042,
			"lon": -122.118362,
			"name": "Alameda County Juvenile Hall/Court",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "2500 Fairmont Drive",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/13984-Alameda-County-Juvenile-Hall-Court/",
				"reviews": "/california/san-leandro/13984-Alameda-County-Juvenile-Hall-Court/#Reviews",
				"collegeSuccess": "/california/san-leandro/13984-Alameda-County-Juvenile-Hall-Court/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "32%",
				"state_average": "64%"
			},
			"enrollment": 46,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "African American",
					"percentage": 63
				},
				{
					"label": "Hispanic",
					"percentage": 26
				},
				{
					"label": "White",
					"percentage": 11
				}
			],
			"remediationData": {}
		},
		{
			"id": 26676,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.719925,
			"lon": -122.16893,
			"name": "Anchor Education",
			"gradeLevels": "3-12",
			"assigned": null,
			"address": {
				"street1": "Po Box 368",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/26676-Anchor-Education/",
				"reviews": "/california/san-leandro/26676-Anchor-Education/#Reviews",
				"collegeSuccess": "/california/san-leandro/26676-Anchor-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 12,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 8217,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.720776,
			"lon": -122.135941,
			"name": "Assumption School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1851 136th Avenue",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/8217-Assumption-School/",
				"reviews": "/california/san-leandro/8217-Assumption-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/8217-Assumption-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 266,
			"parentRating": 4,
			"numReviews": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 46
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 22511,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.701595,
			"lon": -122.11911,
			"name": "Avenue Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1521 159th Ave",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22511-Avenue-Pre-School/",
				"reviews": "/california/san-leandro/22511-Avenue-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/22511-Avenue-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22512,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.701595,
			"lon": -122.11911,
			"name": "Avenue Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1521-41 159th Ave",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22512-Avenue-Pre-School/",
				"reviews": "/california/san-leandro/22512-Avenue-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/22512-Avenue-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 291,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "m",
			"lat": 37.727669,
			"lon": -122.14772,
			"name": "Bancroft Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1150 Bancroft Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/291-Bancroft-Middle-School/",
				"reviews": "/california/san-leandro/291-Bancroft-Middle-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/291-Bancroft-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "66%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1055,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 66
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 6
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 55
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 5,
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22516,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.725018,
			"lon": -122.146004,
			"name": "Beth Sholom Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "642 Dolores Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22516-Beth-Sholom-Pre-School/",
				"reviews": "/california/san-leandro/22516-Beth-Sholom-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/22516-Beth-Sholom-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22518,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.736431,
			"lon": -122.146767,
			"name": "Broadmoor Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "951 Dowling Boulevard",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22518-Broadmoor-Pre-School/",
				"reviews": "/california/san-leandro/22518-Broadmoor-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/22518-Broadmoor-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26461,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.691635,
			"lon": -122.147186,
			"name": "Bubbly Tots Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "963 Manor Boulevard",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/26461-Bubbly-Tots-Learning-Center/",
				"reviews": "/california/san-leandro/26461-Bubbly-Tots-Learning-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/26461-Bubbly-Tots-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22515,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.726192,
			"lon": -122.147926,
			"name": "Children's Mont Adventure Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "580 Joaquin Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22515-Childrens-Mont-Adventure-Child-Care-Center/",
				"reviews": "/california/san-leandro/22515-Childrens-Mont-Adventure-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/22515-Childrens-Mont-Adventure-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9471,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.686905,
			"lon": -122.142303,
			"name": "Chinese Christian Schools-San Leandro",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "750 Fargo Avenue",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/9471-Chinese-Christian-Schools-San-Leandro/",
				"reviews": "/california/san-leandro/9471-Chinese-Christian-Schools-San-Leandro/#Reviews",
				"collegeSuccess": "/california/san-leandro/9471-Chinese-Christian-Schools-San-Leandro/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 278,
			"parentRating": 3,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 85
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "White",
					"percentage": 1
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 308,
			"districtId": 18,
			"districtName": "San Lorenzo Unified",
			"districtCity": "San Lorenzo",
			"levelCode": "e",
			"lat": 37.69754,
			"lon": -122.150543,
			"name": "Corvallis Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "14790 Corvallis Street",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/308-Corvallis-Elementary-School/",
				"reviews": "/california/san-leandro/308-Corvallis-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/308-Corvallis-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 472,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 62
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Asian",
					"rating": 5,
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 44
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 29106,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.70488,
			"lon": -122.153343,
			"name": "Davis St Cc At Garfield School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3081 Teagarden Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/29106-Davis-St-Cc-At-Garfield-School/",
				"reviews": "/california/san-leandro/29106-Davis-St-Cc-At-Garfield-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/29106-Davis-St-Cc-At-Garfield-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22508,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.70488,
			"lon": -122.153343,
			"name": "Davis Street Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3081 Teagarden Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22508-Davis-Street-Child-Care-Center/",
				"reviews": "/california/san-leandro/22508-Davis-Street-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/22508-Davis-Street-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 309,
			"districtId": 18,
			"districtName": "San Lorenzo Unified",
			"districtCity": "San Lorenzo",
			"levelCode": "e",
			"lat": 37.68784,
			"lon": -122.157776,
			"name": "Dayton Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1500 Dayton Avenue",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/309-Dayton-Elementary-School/",
				"reviews": "/california/san-leandro/309-Dayton-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/309-Dayton-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "60%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 449,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 60
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 34
				},
				{
					"label": "Filipino",
					"percentage": 8
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 292,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.703682,
			"lon": -122.185379,
			"name": "Garfield Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "13050 Aurora Drive",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/292-Garfield-Elementary-School/",
				"reviews": "/california/san-leandro/292-Garfield-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/292-Garfield-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "69%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 355,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 69
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 51
				},
				{
					"label": "Filipino",
					"percentage": 10
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 314,
			"districtId": 18,
			"districtName": "San Lorenzo Unified",
			"districtCity": "San Lorenzo",
			"levelCode": "e",
			"lat": 37.701942,
			"lon": -122.116714,
			"name": "Hillside Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "15980 Marcella Street",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/314-Hillside-Elementary-School/",
				"reviews": "/california/san-leandro/314-Hillside-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/314-Hillside-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "84%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 458,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 84
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 56
				},
				{
					"label": "Asian",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 11423,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.701191,
			"lon": -122.11702,
			"name": "International Bible Baptist Academy",
			"gradeLevels": "K-7",
			"assigned": null,
			"address": {
				"street1": "15963 Marcella Street",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/11423-International-Bible-Baptist-Academy/",
				"reviews": "/california/san-leandro/11423-International-Bible-Baptist-Academy/#Reviews",
				"collegeSuccess": "/california/san-leandro/11423-International-Bible-Baptist-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 17,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 94
				},
				{
					"label": "African American",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 22509,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.703465,
			"lon": -122.179779,
			"name": "International Child Institute",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "13305 Doolittle Drive",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22509-International-Child-Institute/",
				"reviews": "/california/san-leandro/22509-International-Child-Institute/#Reviews",
				"collegeSuccess": "/california/san-leandro/22509-International-Child-Institute/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9639,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.686047,
			"lon": -122.13501,
			"name": "International Christian School",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "562 Lewelling Boulevard",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/9639-International-Christian-School/",
				"reviews": "/california/san-leandro/9639-International-Christian-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/9639-International-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 41,
			"parentRating": 4,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 39
				},
				{
					"label": "White",
					"percentage": 29
				},
				{
					"label": "African American",
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 293,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.713383,
			"lon": -122.13633,
			"name": "Jefferson Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "14300 Bancroft Avenue",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/293-Jefferson-Elementary-School/",
				"reviews": "/california/san-leandro/293-Jefferson-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/293-Jefferson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "74%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 587,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 74
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 62
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 15822,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.7006,
			"lon": -122.184303,
			"name": "Jesus Christ is Lord Academy",
			"gradeLevels": "1-12",
			"assigned": null,
			"address": {
				"street1": "2511 West Avenue 134th",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/15822-Jesus-Christ-Is-Lord-Academy/",
				"reviews": "/california/san-leandro/15822-Jesus-Christ-Is-Lord-Academy/#Reviews",
				"collegeSuccess": "/california/san-leandro/15822-Jesus-Christ-Is-Lord-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 298,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "m",
			"lat": 37.714569,
			"lon": -122.168129,
			"name": "John Muir Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1444 Williams Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/298-John-Muir-Middle-School/",
				"reviews": "/california/san-leandro/298-John-Muir-Middle-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/298-John-Muir-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1003,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 62
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 45
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 7,
					"percentage": 10
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 29778,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.704174,
			"lon": -122.158752,
			"name": "Kids Konnect Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "14062 Corvallis Street",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/29778-Kids-Konnect-Child-Development-Center/",
				"reviews": "/california/san-leandro/29778-Kids-Konnect-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/29778-Kids-Konnect-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22517,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.687462,
			"lon": -122.144501,
			"name": "Li'l Angels Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "890 Fargo Avenue",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22517-Lil-Angels-Day-Care-Center/",
				"reviews": "/california/san-leandro/22517-Lil-Angels-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/22517-Lil-Angels-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30006,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.70739,
			"lon": -122.126686,
			"name": "Lighthouse Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1572 150th Avenue",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/30006-Lighthouse-Montessori-School/",
				"reviews": "/california/san-leandro/30006-Lighthouse-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/30006-Lighthouse-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 294,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "h",
			"lat": 37.707939,
			"lon": -122.159538,
			"name": "Lincoln High (Continuation)",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2600 Teagarden Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/294-Lincoln-High-Continuation-School/",
				"reviews": "/california/san-leandro/294-Lincoln-High-Continuation-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/294-Lincoln-High-Continuation-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "27%",
				"state_average": "64%"
			},
			"enrollment": 187,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 1,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 58
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 67
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 295,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.695019,
			"lon": -122.161476,
			"name": "Madison Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "14751 Juniper Street",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/295-Madison-Elementary-School/",
				"reviews": "/california/san-leandro/295-Madison-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/295-Madison-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "47%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 479,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 47
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 47
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 26
				},
				{
					"label": "Filipino",
					"percentage": 8
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 296,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.71925,
			"lon": -122.14711,
			"name": "McKinley Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2150 East 14th Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/296-McKinley-Elementary-School/",
				"reviews": "/california/san-leandro/296-McKinley-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/296-McKinley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "73%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 507,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 30,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 73
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 53
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 297,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.698593,
			"lon": -122.146111,
			"name": "Monroe Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3750 Monterey Boulevard",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/297-Monroe-Elementary-School/",
				"reviews": "/california/san-leandro/297-Monroe-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/297-Monroe-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "69%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 392,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 69
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 39
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 12926,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.697189,
			"lon": -122.139511,
			"name": "Montessori School At Washington Ave",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "14795 Washington Avenue",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/12926-Montessori-School-At-Washington-Ave/",
				"reviews": "/california/san-leandro/12926-Montessori-School-At-Washington-Ave/#Reviews",
				"collegeSuccess": "/california/san-leandro/12926-Montessori-School-At-Washington-Ave/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 92,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 56
				},
				{
					"label": "White",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 26514,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.696991,
			"lon": -122.139412,
			"name": "Montessori School at Washington Avenue",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "14795 Washington Ave",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/26514-Montessori-School-At-Washington-Avenue/",
				"reviews": "/california/san-leandro/26514-Montessori-School-At-Washington-Avenue/#Reviews",
				"collegeSuccess": "/california/san-leandro/26514-Montessori-School-At-Washington-Avenue/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12997,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.725769,
			"lon": -122.156151,
			"name": "Montessori School Castro Valley",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "103 Callan Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/12997-Montessori-School-Castro-Valley/",
				"reviews": "/california/san-leandro/12997-Montessori-School-Castro-Valley/#Reviews",
				"collegeSuccess": "/california/san-leandro/12997-Montessori-School-Castro-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15459,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.698513,
			"lon": -122.105721,
			"name": "Montessori School Of San Leandro",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "16492 Foothill Boulevard",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/15459-Montessori-School-Of-San-Leandro/",
				"reviews": "/california/san-leandro/15459-Montessori-School-Of-San-Leandro/#Reviews",
				"collegeSuccess": "/california/san-leandro/15459-Montessori-School-Of-San-Leandro/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 120,
			"parentRating": 3,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 33
				},
				{
					"label": "White",
					"percentage": 17
				}
			],
			"remediationData": {}
		},
		{
			"id": 26513,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.698463,
			"lon": -122.10585,
			"name": "Montessori School of San Leandro",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16492 Foothill Blvd.",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/26513-Montessori-School-Of-San-Leandro/",
				"reviews": "/california/san-leandro/26513-Montessori-School-Of-San-Leandro/#Reviews",
				"collegeSuccess": "/california/san-leandro/26513-Montessori-School-Of-San-Leandro/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30393,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.698513,
			"lon": -122.105721,
			"name": "Montessori School-Brentwood",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16492 Foothill Boulevard",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/30393-Montessori-School-Brentwood/",
				"reviews": "/california/san-leandro/30393-Montessori-School-Brentwood/#Reviews",
				"collegeSuccess": "/california/san-leandro/30393-Montessori-School-Brentwood/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22514,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.719925,
			"lon": -122.16893,
			"name": "Noah's Ark Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "Po Box 1088",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22514-Noahs-Ark-Pre-School/",
				"reviews": "/california/san-leandro/22514-Noahs-Ark-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/22514-Noahs-Ark-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 299,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.735477,
			"lon": -122.146751,
			"name": "Roosevelt Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "951 Dowling Boulevard",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/299-Roosevelt-Elementary-School/",
				"reviews": "/california/san-leandro/299-Roosevelt-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/299-Roosevelt-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 541,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 35
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 14237,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e,m,h",
			"lat": 37.6954,
			"lon": -122.160919,
			"name": "San Leandro Adult",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "14735 Juniper Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/14237-San-Leandro-Adult/",
				"reviews": "/california/san-leandro/14237-San-Leandro-Adult/#Reviews",
				"collegeSuccess": "/california/san-leandro/14237-San-Leandro-Adult/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22510,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.713943,
			"lon": -122.135216,
			"name": "San Leandro Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "14311 Lark St",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22510-San-Leandro-Childrens-Center/",
				"reviews": "/california/san-leandro/22510-San-Leandro-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/22510-San-Leandro-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 300,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "h",
			"lat": 37.718071,
			"lon": -122.143356,
			"name": "San Leandro High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2200 Bancroft Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/300-San-Leandro-High-School/",
				"reviews": "/california/san-leandro/300-San-Leandro-High-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/300-San-Leandro-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "70%",
				"state_average": "64%"
			},
			"enrollment": 2652,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 5,
				"College Readiness Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 58
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 17
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 46
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 10159,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.708557,
			"lon": -122.111763,
			"name": "Seneca Family Of Agencies - James Baldwin Academy",
			"gradeLevels": "2-12",
			"assigned": null,
			"address": {
				"street1": "2275 Arlington Drive",
				"street2": null,
				"zip": "94578",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94578",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/10159-Seneca-Family-Of-Agencies---James-Baldwin-Academy/",
				"reviews": "/california/san-leandro/10159-Seneca-Family-Of-Agencies---James-Baldwin-Academy/#Reviews",
				"collegeSuccess": "/california/san-leandro/10159-Seneca-Family-Of-Agencies---James-Baldwin-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 78,
			"parentRating": 4,
			"numReviews": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 42
				},
				{
					"label": "White",
					"percentage": 28
				},
				{
					"label": "Hispanic",
					"percentage": 19
				},
				{
					"label": "Asian",
					"percentage": 6
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22519,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.728882,
			"lon": -122.139992,
			"name": "St James Church Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "993 Estudillo Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/22519-St-James-Church-Pre-School/",
				"reviews": "/california/san-leandro/22519-St-James-Church-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/22519-St-James-Church-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8218,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.690239,
			"lon": -122.158768,
			"name": "St. Felicitas Catholic School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1650 Manor Boulevard",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/8218-St.-Felicitas-Catholic-School/",
				"reviews": "/california/san-leandro/8218-St.-Felicitas-Catholic-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/8218-St.-Felicitas-Catholic-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 275,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 39
				},
				{
					"label": "Hispanic",
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "White",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 8219,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.724461,
			"lon": -122.159317,
			"name": "St. Leander",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "451 Davis Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/8219-St.-Leander/",
				"reviews": "/california/san-leandro/8219-St.-Leander/#Reviews",
				"collegeSuccess": "/california/san-leandro/8219-St.-Leander/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 213,
			"parentRating": 4,
			"numReviews": 13,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 26
				},
				{
					"label": "African American",
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"percentage": 22
				},
				{
					"label": "Asian",
					"percentage": 21
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 24523,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.72657,
			"lon": -122.149086,
			"name": "Stars High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "545 Estudillo Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/24523-Stars-High-School/",
				"reviews": "/california/san-leandro/24523-Stars-High-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/24523-Stars-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 30,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 67
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "White",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 13490,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.72657,
			"lon": -122.149086,
			"name": "Stars High School",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "545 Estudillo Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/13490-Stars-High-School/",
				"reviews": "/california/san-leandro/13490-Stars-High-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/13490-Stars-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 38,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28062,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.740269,
			"lon": -122.14743,
			"name": "Stepping Stones Growth Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "311 MacArthur Boulevard",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/28062-Stepping-Stones-Growth-Center/",
				"reviews": "/california/san-leandro/28062-Stepping-Stones-Growth-Center/#Reviews",
				"collegeSuccess": "/california/san-leandro/28062-Stepping-Stones-Growth-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9909,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.714989,
			"lon": -122.150009,
			"name": "The Principled Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "2305 Washington Avenue, San Leandro, CA 94577",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/9909-The-Principled-Academy/",
				"reviews": "/california/san-leandro/9909-The-Principled-Academy/#Reviews",
				"collegeSuccess": "/california/san-leandro/9909-The-Principled-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 134,
			"parentRating": 5,
			"numReviews": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 28
				},
				{
					"label": "African American",
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 20
				},
				{
					"label": "White",
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 301,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.732697,
			"lon": -122.157127,
			"name": "Washington Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "250 Dutton Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/301-Washington-Elementary-School/",
				"reviews": "/california/san-leandro/301-Washington-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/301-Washington-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "67%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 398,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 67
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 64
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 317,
			"districtId": 18,
			"districtName": "San Lorenzo Unified",
			"districtCity": "San Lorenzo",
			"levelCode": "m",
			"lat": 37.688,
			"lon": -122.148834,
			"name": "Washington Manor Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1170 Fargo Avenue",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/317-Washington-Manor-Middle-School/",
				"reviews": "/california/san-leandro/317-Washington-Manor-Middle-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/317-Washington-Manor-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "52%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 760,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 52
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 25
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 302,
			"districtId": 17,
			"districtName": "San Leandro Unified",
			"districtCity": "San Leandro",
			"levelCode": "e",
			"lat": 37.715694,
			"lon": -122.165779,
			"name": "Wilson Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1300 Williams Street",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/302-Wilson-Elementary-School/",
				"reviews": "/california/san-leandro/302-Wilson-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/302-Wilson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 748,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 71
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 51
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8729,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.68861,
			"lon": -122.054176,
			"name": "Woodroe Woods School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "750 Fargo Ave.",
				"street2": null,
				"zip": "94579",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94579",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/8729-Woodroe-Woods-School/",
				"reviews": "/california/san-leandro/8729-Woodroe-Woods-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/8729-Woodroe-Woods-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 104,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 57
				},
				{
					"label": "African American",
					"percentage": 19
				},
				{
					"label": "Asian",
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 30289,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.724148,
			"lon": -122.146126,
			"name": "Your Child Is Mine Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1600 Bancroft Avenue",
				"street2": null,
				"zip": "94577",
				"city": "San Leandro"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94577",
			"type": "school",
			"links": {
				"profile": "/california/san-leandro/30289-Your-Child-Is-Mine-Pre-School/",
				"reviews": "/california/san-leandro/30289-Your-Child-Is-Mine-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-leandro/30289-Your-Child-Is-Mine-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]