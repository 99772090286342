exports.CASTRO_VALLEY_LISTINGS = [
		{
			"closePrice": 1070000,
			"daysOnMovoto": 0,
			"id": "d3142ead-71dd-484c-8523-0198c45e2b07",
			"tnImgPath": "https://pi.movoto.com/p/102/221154602_0_vvzuUJ_p.jpeg",
			"listDate": null,
			"listingAgent": "James Hood",
			"listPrice": 899000,
			"lotSize": 5390,
			"sqftTotal": 1050,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221154602",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "HomeSmart PV & Associates",
			"photoCount": 54,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94546",
			"path": "castro-valley-ca/4059-somerset-ave-castro-valley-ca-94546-12_40563756/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2680122b-52cd-4588-8ef9-f6a484b282bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-23 23:19:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-23 23:19:23",
			"virtualTourLink": "https://realtour3d.net/3d-model/4059-somerset-ave-castro-valley-ca-94546/skinned/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.701913,
				"lng": -122.072448,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4059 Somerset Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1070000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://realtour3d.net/3d-model/4059-somerset-ave-castro-valley-ca-94546/skinned/"
		},
		{
			"closePrice": 2725000,
			"daysOnMovoto": 47,
			"id": "01ade689-55d6-4d47-abb4-56cb73e74d4b",
			"tnImgPath": "https://pi.movoto.com/p/12/40974242_0_AAfqiI_p.jpeg",
			"listDate": "2021-11-13 00:00:00",
			"listingAgent": "Thomas Westfall",
			"listPrice": 2000000,
			"lotSize": 8165,
			"sqftTotal": 2219,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974242",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94552",
			"path": "castro-valley-ca/5894-highwood-rd-castro-valley-ca-94552-12_40712329/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aac2029c-935b-4575-8177-aa2c3bf6d743",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-14 02:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 00:00:00",
			"virtualTourLink": "https://www.5894highwood.com/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.71515,
				"lng": -122.048163,
				"zipcode": "94552",
				"subPremise": "",
				"address": "5894 Highwood Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.5894highwood.com/unbranded"
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 44,
			"id": "229cbf7c-557e-4f5e-8f30-c4ac221fd83c",
			"tnImgPath": "https://pi.movoto.com/p/12/40974238_0_UJerVJ_p.jpeg",
			"listDate": "2021-11-13 00:00:00",
			"listingAgent": "Terry Martinez",
			"listPrice": 1499000,
			"lotSize": 10010,
			"sqftTotal": 2158,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974238",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Varos Real Estate",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94546",
			"path": "castro-valley-ca/18323-jill-way-castro-valley-ca-94546/pid_odqbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8e99716b-9cde-4e7a-b5a7-d43df6be5197",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-14 01:31:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.711652,
				"lng": -122.097281,
				"zipcode": "94546",
				"subPremise": "",
				"address": "18323 Jill Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1320000,
			"daysOnMovoto": 59,
			"id": "8e1c4d04-6d15-4f7a-8854-9c5057feb68c",
			"tnImgPath": "https://pi.movoto.com/p/12/40972740_0_Nym3mv_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Simon Chan",
			"listPrice": 1298000,
			"lotSize": 6420,
			"sqftTotal": 2212,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972740",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Grobecker Holland Int'l",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "94546",
			"path": "castro-valley-ca/jill-way-castro-valley-ca-94546-12_40627307/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f85a4bec-dce2-44ec-a656-4d4eae98239d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-30 00:51:57",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.711658,
				"lng": -122.097015,
				"zipcode": "94546",
				"subPremise": "",
				"address": "18328 Jill Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1320000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1260000,
			"daysOnMovoto": 44,
			"id": "7dcaeff0-0a3a-4c95-9784-35fe6578489b",
			"tnImgPath": "https://pi.movoto.com/p/12/40973642_0_632uqU_p.jpeg",
			"listDate": "2021-11-08 00:00:00",
			"listingAgent": "Deborah Diaz",
			"listPrice": 1189000,
			"lotSize": 5700,
			"sqftTotal": 2086,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973642",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Best property mgmt., inc.",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94546",
			"path": "castro-valley-ca/3102-sydney-way-castro-valley-ca-94546/pid_lprbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4a304f6b-3916-4966-a21d-218eff153baa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-08 19:46:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.709147,
				"lng": -122.096371,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3102 Sydney Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1190000,
			"daysOnMovoto": 54,
			"id": "7fe9e3df-e5b5-4d73-bb70-7c614d4b4fd0",
			"tnImgPath": "https://pi.movoto.com/p/12/40972649_0_3fjvbi_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Jon Foster",
			"listPrice": 1049000,
			"lotSize": 5110,
			"sqftTotal": 1887,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972649",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94546",
			"path": "castro-valley-ca/4009-stevens-st-castro-valley-ca-94546/pid_rdubvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "285a6d05-d13c-4a66-b430-1dd31d04ed0f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-10-29 18:11:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.699812,
				"lng": -122.071738,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4009 Stevens St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1190000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 85,
			"id": "8a29ebba-04e4-4f6d-bf7c-b018bb631224",
			"tnImgPath": "https://pi.movoto.com/p/12/40968381_0_mReFf3_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Jon Foster",
			"listPrice": 899000,
			"lotSize": 5546,
			"sqftTotal": 1282,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968381",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94546",
			"path": "castro-valley-ca/3196-carleen-dr-castro-valley-ca-94546-12_10588355/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "52759f2d-5555-44eb-8f9c-4435033646c1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-09-24 02:46:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.703293,
				"lng": -122.08674,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3196 Carleen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 0,
			"id": "4a58794f-cd7c-4060-8a82-c9f8dc852d24",
			"tnImgPath": "https://pi.movoto.com/p/102/221139253_0_EY6Fij_p.jpeg",
			"listDate": null,
			"listingAgent": "Brenda Comfort",
			"listPrice": 1295000,
			"lotSize": 6098,
			"sqftTotal": 2034,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221139253",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "California Investment Properties",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1958,
			"zipCode": "94546",
			"path": "castro-valley-ca/4239-nando-ct-castro-valley-ca-94546-12_40227938/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bc2e0c03-7715-4677-b631-8c2c497b6003",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-03 07:54:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 07:54:13",
			"virtualTourLink": "https://my.matterport.com/show/?m=iwWJVKYeFri&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.708433,
				"lng": -122.075333,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4239 Nando Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=iwWJVKYeFri&mls=1"
		},
		{
			"closePrice": 960000,
			"daysOnMovoto": 29,
			"id": "3d003d2d-88ba-4a4e-8c7e-30ff884a7582",
			"tnImgPath": "https://pi.movoto.com/p/12/40973831_0_Qnu6Zr_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Craig Lambton",
			"listPrice": 899000,
			"lotSize": 2090,
			"sqftTotal": 1802,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973831",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1979,
			"zipCode": "94546",
			"path": "castro-valley-ca/2981-wisteria-ln-castro-valley-ca-94546/pid_c0qbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a83db1a6-40bb-4fe8-a470-0786799518de",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-10 20:31:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.700866,
				"lng": -122.086976,
				"zipcode": "94546",
				"subPremise": "",
				"address": "2981 Wisteria Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 960000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1145800,
			"daysOnMovoto": 48,
			"id": "040ce90a-242d-4400-9aee-958a7d3a4e6c",
			"tnImgPath": "https://pi.movoto.com/p/12/40972095_0_Ubbz3r_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Carlos Rodriguez",
			"listPrice": 975000,
			"lotSize": 5670,
			"sqftTotal": 1385,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972095",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94546",
			"path": "castro-valley-ca/4328-circle-ave-castro-valley-ca-94546/pid_05rbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8830ff45-692f-4ba9-9aa6-b6653b16c689",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-23 05:56:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.69988,
				"lng": -122.063259,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4328 Circle Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1145800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 0,
			"id": "c35d5efa-5dd6-40b2-878a-394d89635ce9",
			"tnImgPath": "https://pi.movoto.com/p/102/221134296_0_zFvQe6_p.jpeg",
			"listDate": null,
			"listingAgent": "Richard Torgerson",
			"listPrice": 1575000,
			"lotSize": 4255,
			"sqftTotal": 2358,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221134296",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Your Preferred Real Estate",
			"photoCount": 53,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1992,
			"zipCode": "94552",
			"path": "castro-valley-ca/20163-crow-creek-rd-castro-valley-ca-94552/pid_2azbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "87ffeb01-e1a3-4cef-a38f-0e9e0383bd67",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-19 22:04:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 22:04:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.701229,
				"lng": -122.049808,
				"zipcode": "94552",
				"subPremise": "",
				"address": "20163 Crow Creek Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 845000,
			"daysOnMovoto": 33,
			"id": "e9b8e99b-6368-47d5-b208-bdf706c8b888",
			"tnImgPath": "https://pi.movoto.com/p/12/40971986_0_bbMFjz_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Amanda McDonell",
			"listPrice": 850000,
			"lotSize": 4900,
			"sqftTotal": 1252,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971986",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94546",
			"path": "castro-valley-ca/3729-christensen-ln-castro-valley-ca-94546/pid_vrsbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3e578825-2d9a-47f2-9cf1-1eaaa4d96953",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-22 19:16:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/8167497",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.706246,
				"lng": -122.083345,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3729 Christensen Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 845000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/8167497"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 32,
			"id": "922d490d-c340-4a62-9199-3e688b63eaee",
			"tnImgPath": "https://pi.movoto.com/p/12/40971430_0_Q2JeiI_p.jpeg",
			"listDate": "2021-10-18 00:00:00",
			"listingAgent": "Marilyn Swisher",
			"listPrice": 1050000,
			"lotSize": 8052,
			"sqftTotal": 2182,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971430",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94546",
			"path": "castro-valley-ca/19715-michaels-ct-castro-valley-ca-94546/pid_1opbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "db36d5dd-18e9-4dd4-95ec-d18dcc2d7130",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-19 02:46:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-18 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/2913592",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.699391,
				"lng": -122.092954,
				"zipcode": "94546",
				"subPremise": "",
				"address": "19715 Michaels Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2913592"
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 0,
			"id": "f3038b40-4f93-430a-b29d-fa2f0ab7a974",
			"tnImgPath": "https://pi.movoto.com/p/102/221091713_0_RUmIVF_p.jpeg",
			"listDate": null,
			"listingAgent": "Baljinder Dhesi",
			"listPrice": 1088000,
			"lotSize": 4590,
			"sqftTotal": 1814,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221091713",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Exit Realty Consultants",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1946,
			"zipCode": "94546",
			"path": "castro-valley-ca/1985-170th-castro-valley-ca-94546-102_20009521/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "716670b6-1add-4059-9426-84b8f40ec29c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-07-27 18:19:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-27 18:19:42",
			"virtualTourLink": "https://player.vimeo.com/external/391698039.hd.mp4?s=a5a885f62c89db25b762727ef9a0b1780ee10f1b&profile_id=175",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.69432,
				"lng": -122.101785,
				"zipcode": "94546",
				"subPremise": "",
				"address": "1985 170th"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/external/391698039.hd.mp4?s=a5a885f62c89db25b762727ef9a0b1780ee10f1b&profile_id=175"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 35,
			"id": "2462ca8a-a1a9-42ad-8fac-10b4c5b036f7",
			"tnImgPath": "https://pi.movoto.com/p/12/40969875_0_Amyban_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Bob Frey",
			"listPrice": 1335000,
			"lotSize": 7296,
			"sqftTotal": 2088,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969875",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94546",
			"path": "castro-valley-ca/17966-joseph-dr-castro-valley-ca-94546-12_11175695/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9cbc9bbd-906f-4f05-9a34-5fb18ed85e89",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-06 20:56:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=gTr9T5C9ACo&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.71568,
				"lng": -122.079639,
				"zipcode": "94546",
				"subPremise": "",
				"address": "17966 Joseph Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=gTr9T5C9ACo&brand=0"
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 27,
			"id": "dbcfa7b8-d326-4c5a-bce5-ed59085c9613",
			"tnImgPath": "https://pi.movoto.com/p/12/40966978_0_QnqvMR_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Kathryn Doughty",
			"listPrice": 1999000,
			"lotSize": 43957,
			"sqftTotal": 3533,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966978",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1986,
			"zipCode": "94546",
			"path": "castro-valley-ca/18943-madison-ave-castro-valley-ca-94546/pid_entbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8241f519-f588-4ad9-bc24-3e9b9fae53ec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-10-13 18:56:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.707002,
				"lng": -122.064902,
				"zipcode": "94546",
				"subPremise": "",
				"address": "18943 Madison Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 36,
			"id": "18d6f590-8003-4f0e-8d3d-7ff35ec70afe",
			"tnImgPath": "https://pi.movoto.com/p/12/40969100_0_bzqF6M_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Lisa Ferraris",
			"listPrice": 1428000,
			"lotSize": 5351,
			"sqftTotal": 2539,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969100",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "94552",
			"path": "castro-valley-ca/20206-glenwood-dr-castro-valley-ca-94552/pid_hf1bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cd09e093-d16f-4c40-b9fa-b62b88d14d87",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-30 16:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://4-phase-photographic.seehouseat.com/1912694?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.712983,
				"lng": -122.029249,
				"zipcode": "94552",
				"subPremise": "",
				"address": "20206 Glenwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://4-phase-photographic.seehouseat.com/1912694?idx=1"
		},
		{
			"closePrice": 1020000,
			"daysOnMovoto": 42,
			"id": "3c11e1d3-6008-4c01-95a2-39451093438c",
			"tnImgPath": "https://pi.movoto.com/p/12/40968547_0_ziiaqM_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Judy Grubb",
			"listPrice": 850000,
			"lotSize": 9460,
			"sqftTotal": 1280,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968547",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94546",
			"path": "castro-valley-ca/19982-wisteria-st-castro-valley-ca-94546/pid_cqpbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f9703e63-4202-43fb-b3bf-7a203fbbfb02",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-24 20:31:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://view.paradym.com/idx/19982-Wisteria-St-Castro-Valley-CA-94546/4673223",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.699475,
				"lng": -122.08603,
				"zipcode": "94546",
				"subPremise": "",
				"address": "19982 Wisteria St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1020000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://view.paradym.com/idx/19982-Wisteria-St-Castro-Valley-CA-94546/4673223"
		},
		{
			"closePrice": 1088000,
			"daysOnMovoto": 41,
			"id": "e288bd82-ac37-48d2-bcc8-17fa97532325",
			"tnImgPath": "https://pi.movoto.com/p/12/40968551_0_eiRreY_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Yamin Ayesh",
			"listPrice": 899000,
			"lotSize": 10680,
			"sqftTotal": 1234,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968551",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Synergy Group",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94546",
			"path": "castro-valley-ca/19535-lake-chabot-rd-castro-valley-ca-94546/pid_65ubvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7a7acf25-d01e-47ea-9fe8-ce9bc0e65647",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-24 20:56:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://mediaonere.hd.pics/19535-Lake-Chabot-Rd/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.703008,
				"lng": -122.089403,
				"zipcode": "94546",
				"subPremise": "",
				"address": "19535 Lake Chabot Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1088000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mediaonere.hd.pics/19535-Lake-Chabot-Rd/idx"
		},
		{
			"closePrice": 885000,
			"daysOnMovoto": 42,
			"id": "69242959-31ac-4c99-b9dd-a6fb432de6dc",
			"tnImgPath": "https://pi.movoto.com/p/12/40968189_0_ZjzIeq_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Sanjiv Nayyar",
			"listPrice": 849000,
			"lotSize": 2796,
			"sqftTotal": 1350,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968189",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1986,
			"zipCode": "94552",
			"path": "castro-valley-ca/19939-laurelwood-dr-castro-valley-ca-94552/pid_qy1bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a8df0fe7-63f6-40fd-a590-7a48ed250ed1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-23 18:17:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=1puzixajFMk&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.716248,
				"lng": -122.027473,
				"zipcode": "94552",
				"subPremise": "",
				"address": "19939 Laurelwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 885000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=1puzixajFMk&brand=0"
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 68,
			"id": "298948de-ab56-4bcd-9ef5-6b973d9ac141",
			"tnImgPath": "https://pi.movoto.com/p/12/40964786_0_Ar7nj2_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "David Azimi",
			"listPrice": 899988,
			"lotSize": 0,
			"sqftTotal": 2459,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964786",
			"bath": 2.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94546",
			"path": "castro-valley-ca/19983-santa-maria-ave-301-castro-valley-ca-94546-12_40709965/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0f5ca7c4-723a-462a-8df9-16b172bca1a3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-08-26 21:26:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.699999,
				"lng": -122.079156,
				"zipcode": "94546",
				"subPremise": "APT 301",
				"address": "19983 Santa Maria Ave #301"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 59,
			"id": "c6d5d010-360f-4ae2-b0e7-6bb2b2e8cff9",
			"tnImgPath": "https://pi.movoto.com/p/110/421591330_0_Rb7qyv_p.jpeg",
			"listDate": "2021-09-03 00:00:00",
			"listingAgent": "Jessica Bernardo",
			"listPrice": 1450000,
			"lotSize": 20181,
			"sqftTotal": 1589,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421591330",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Real Estate eBroker",
			"photoCount": 13,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "2 Houses on Lot",
			"yearBuilt": 1925,
			"zipCode": "94546",
			"path": "castro-valley-ca/20378-stanton-ave-castro-valley-ca-94546/pid_r9rbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f73d1aac-fbd8-45fe-bb4f-896c54e2cae4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-09-03 17:51:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.695207,
				"lng": -122.089957,
				"zipcode": "94546",
				"subPremise": "",
				"address": "20378 Stanton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1125000,
			"daysOnMovoto": 34,
			"id": "0981d956-b49e-4888-9b5a-f30ebd9f1851",
			"tnImgPath": "https://pi.movoto.com/p/12/40968201_0_yzEImI_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Kenneth Kremer",
			"listPrice": 988888,
			"lotSize": 8100,
			"sqftTotal": 1340,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968201",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Kremer & Company, REALTORS",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1942,
			"zipCode": "94546",
			"path": "castro-valley-ca/18977-almond-rd-castro-valley-ca-94546-12_28511770/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "99e804d3-111e-43a6-9b49-a6ac5ff75273",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-24 15:01:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/1584044",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.711,
				"lng": -122.084477,
				"zipcode": "94546",
				"subPremise": "",
				"address": "18977 Almond Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1125000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/1584044"
		},
		{
			"closePrice": 630000,
			"daysOnMovoto": 41,
			"id": "a468794c-c1d8-4f11-9da7-d885564c1764",
			"tnImgPath": "https://pi.movoto.com/p/12/40967564_0_rfJ7IV_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Omar Murillo",
			"listPrice": 599950,
			"lotSize": 0,
			"sqftTotal": 1042,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967564",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1992,
			"zipCode": "94546",
			"path": "castro-valley-ca/3017-grove-way-c15-castro-valley-ca-94546/pid_qrbdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13486af6-706a-499a-bcbb-30fb9ae2d111",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-17 17:41:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=an3WAkCsj6Q&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.689676,
				"lng": -122.060801,
				"zipcode": "94546",
				"subPremise": "APT C15",
				"address": "3017 Grove Way #C15"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=an3WAkCsj6Q&brand=0"
		},
		{
			"closePrice": 1525000,
			"daysOnMovoto": 39,
			"id": "3aa62e71-6bf2-4d6b-92d8-c03f7df89ccf",
			"tnImgPath": "https://pi.movoto.com/p/12/40967647_0_Q2EeqJ_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Thomas Stafford",
			"listPrice": 1395000,
			"lotSize": 16878,
			"sqftTotal": 2360,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967647",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Thomas Stafford Prop.",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1972,
			"zipCode": "94546",
			"path": "castro-valley-ca/18057-jaymark-ct-castro-valley-ca-94546/pid_ycwbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af6b3b67-7c08-498d-951f-ac0d7f1f17aa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-17 23:16:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.71602,
				"lng": -122.087073,
				"zipcode": "94546",
				"subPremise": "",
				"address": "18057 Jaymark Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 23,
			"id": "7fe7fcf6-8984-4764-9983-08b4ed21eff0",
			"tnImgPath": "https://pi.movoto.com/p/12/40969034_0_aYmzaQ_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Peter Wardhana",
			"listPrice": 1750000,
			"lotSize": 12372,
			"sqftTotal": 3526,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969034",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2000,
			"zipCode": "94552",
			"path": "castro-valley-ca/25657-crestfield-dr-castro-valley-ca-94552-12_40201299/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c643bab1-bdc8-4534-9c59-f1333d02aa7c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-30 00:21:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.682274,
				"lng": -122.035777,
				"zipcode": "94552",
				"subPremise": "",
				"address": "25657 Crestfield Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 42,
			"id": "ed5e7c76-d9b9-4c02-b35f-08e69f600cf0",
			"tnImgPath": "https://pi.movoto.com/p/12/40966767_0_NYZab7_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Jessica Medina",
			"listPrice": 978000,
			"lotSize": 14076,
			"sqftTotal": 1459,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966767",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Village Properties & Assoc.",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "94546",
			"path": "castro-valley-ca/3486-middleton-ave-castro-valley-ca-94546/pid_iwxbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5f2e6aac-6d5c-47f1-93c6-92cb7c16c220",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-11 01:41:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.711821,
				"lng": -122.092111,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3486 Middleton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1375000,
			"daysOnMovoto": 112,
			"id": "f5d956ce-ae5f-44d7-a556-e94914cbc001",
			"tnImgPath": "https://pi.movoto.com/p/12/40955833_0_IEiEAB_p.jpeg",
			"listDate": "2021-07-02 00:00:00",
			"listingAgent": "Rebecca Mendoza",
			"listPrice": 1495000,
			"lotSize": 20640,
			"sqftTotal": 2350,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955833",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Home Services",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1936,
			"zipCode": "94546",
			"path": "castro-valley-ca/3642-lorena-ave-castro-valley-ca-94546/pid_4sqbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "436dbb09-576d-4c23-ad72-2f5548b1efdc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-07-02 22:26:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-02 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/9zpr9pzpf68",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.700403,
				"lng": -122.076967,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3642 Lorena Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1375000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/9zpr9pzpf68"
		},
		{
			"closePrice": 1025000,
			"daysOnMovoto": 51,
			"id": "4342f236-3f57-415b-9096-fd78977fd801",
			"tnImgPath": "https://pi.movoto.com/p/12/40964904_0_BabaYB_p.jpeg",
			"listDate": "2021-08-31 00:00:00",
			"listingAgent": "Christopher Crevitt",
			"listPrice": 999000,
			"lotSize": 5400,
			"sqftTotal": 1346,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964904",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94546",
			"path": "castro-valley-ca/4235-david-st-castro-valley-ca-94546-12_40699257/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "611747ca-5acb-43ca-8b94-096979dff250",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-08-31 18:21:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.695445,
				"lng": -122.061128,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4235 David St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1025000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1590000,
			"daysOnMovoto": 63,
			"id": "be0f8331-ba2c-4248-bd1c-dc245133d84e",
			"tnImgPath": "https://pi.movoto.com/p/12/40963610_0_ejVZ7Y_p.jpeg",
			"listDate": "2021-08-18 00:00:00",
			"listingAgent": "Carl Medford",
			"listPrice": 1620000,
			"lotSize": 10000,
			"sqftTotal": 3374,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963610",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Benchmark",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94546",
			"path": "castro-valley-ca/3816-denise-ln-castro-valley-ca-94546-12_11194612/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "382045b1-289e-429b-8989-9ee0f6eca945",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-08-18 20:56:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 00:00:00",
			"virtualTourLink": "http://www.3816DeniseLane.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.716546,
				"lng": -122.091245,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3816 Denise Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1590000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.3816DeniseLane.com/?mls"
		},
		{
			"closePrice": 1325000,
			"daysOnMovoto": 28,
			"id": "42a6a928-2037-4f40-a6f5-ee369a049729",
			"tnImgPath": "https://pi.movoto.com/p/12/40967908_0_U2BBNQ_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Matthew Wilhite",
			"listPrice": 1148000,
			"lotSize": 6490,
			"sqftTotal": 1674,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967908",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94546",
			"path": "castro-valley-ca/17734-trenton-dr-castro-valley-ca-94546-12_40146259/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1d835b6b-ac14-4e0b-96b2-089ddf9674f9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-09-21 17:26:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.716503,
				"lng": -122.066456,
				"zipcode": "94546",
				"subPremise": "",
				"address": "17734 Trenton Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 52,
			"id": "0c9e60b8-37d6-4f0c-9fc7-779a4caef692",
			"tnImgPath": "https://pi.movoto.com/p/12/40964398_0_JMuMbY_p.jpeg",
			"listDate": "2021-08-24 00:00:00",
			"listingAgent": "Robert Pace",
			"listPrice": 1199950,
			"lotSize": 5396,
			"sqftTotal": 2244,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964398",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94546",
			"path": "castro-valley-ca/3111-sunshine-pl-castro-valley-ca-94546/pid_pgqbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "db6406c9-ee8e-4107-9fe0-c03126a14fea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-08-24 19:17:06",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.711564,
				"lng": -122.09788,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3111 Sunshine Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 965000,
			"daysOnMovoto": 28,
			"id": "adb77e72-e028-452d-92d4-07e397cd623c",
			"tnImgPath": "https://pi.movoto.com/p/12/40963504_0_JQqN7U_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Margie Lupo",
			"listPrice": 865500,
			"lotSize": 1496,
			"sqftTotal": 1827,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963504",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1981,
			"zipCode": "94552",
			"path": "castro-valley-ca/20390-waterford-pl-castro-valley-ca-94552/pid_vszbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "05e45cac-f5c8-49e2-a336-40d9d536a9a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-09-16 18:21:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/169678",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.701851,
				"lng": -122.048255,
				"zipcode": "94552",
				"subPremise": "",
				"address": "20390 Waterford Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 965000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/169678"
		},
		{
			"closePrice": 1525000,
			"daysOnMovoto": 113,
			"id": "84046d6f-580c-42c1-8ab7-1cf8db49db1c",
			"tnImgPath": "https://pi.movoto.com/p/12/40955573_0_RrJ7JV_p.jpeg",
			"listDate": "2021-06-22 00:00:00",
			"listingAgent": "Aman Giri",
			"listPrice": 1575000,
			"lotSize": 5000,
			"sqftTotal": 3636,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955573",
			"bath": 4,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Kite Hill Real Estate",
			"photoCount": 36,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1959,
			"zipCode": "94546",
			"path": "castro-valley-ca/2780-ganic-st-castro-valley-ca-94546/pid_qkpbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f879c8e4-38fe-47fb-b152-125bb070b1d0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-13 00:00:00",
			"createdAt": "2021-06-23 02:11:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.697912,
				"lng": -122.085209,
				"zipcode": "94546",
				"subPremise": "",
				"address": "2780 Ganic St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 931000,
			"daysOnMovoto": 32,
			"id": "043cc2ec-70a4-48ec-a4e7-718f9efed633",
			"tnImgPath": "https://pi.movoto.com/p/12/40966653_0_QNI3z6_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Helen Kwong",
			"listPrice": 899000,
			"lotSize": 5567,
			"sqftTotal": 1571,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966653",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RED OAK REALTY",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1986,
			"zipCode": "94552",
			"path": "castro-valley-ca/19775-laurelwood-dr-castro-valley-ca-94552-12_40265120/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7c110fc5-558b-4650-912c-4d6248913c7e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-10 19:16:59",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": "https://unbranded.youriguide.com/19775_laurelwood_dr_castro_valley_ca/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.71692,
				"lng": -122.028823,
				"zipcode": "94552",
				"subPremise": "",
				"address": "19775 Laurelwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 931000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://unbranded.youriguide.com/19775_laurelwood_dr_castro_valley_ca/"
		},
		{
			"closePrice": 780000,
			"daysOnMovoto": 33,
			"id": "aced4f5b-cb6c-4a8e-bde7-a7691829fd39",
			"tnImgPath": "https://pi.movoto.com/p/110/421593183_0_aUB77B_p.jpeg",
			"listDate": "2021-09-09 00:00:00",
			"listingAgent": "Tommy Lee",
			"listPrice": 749900,
			"lotSize": 15097,
			"sqftTotal": 1258,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421593183",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1981,
			"zipCode": "94546",
			"path": "castro-valley-ca/20060-lorena-pl-apt-u-11-castro-valley-ca-94546-110_421593183/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6e89a87b-c30f-466d-80c3-11ec1131eac9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-09 21:21:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.699626,
				"lng": -122.075833,
				"zipcode": "94546",
				"subPremise": "APT U-11",
				"address": "20060 Lorena Pl #U-11"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 227,
			"id": "5ef0be7d-abaf-450d-8ace-8e772f6656fa",
			"tnImgPath": "https://pi.movoto.com/p/12/40939280_0_BR7qrY_p.jpeg",
			"listDate": "2021-02-26 00:00:00",
			"listingAgent": "Debbie Burness",
			"listPrice": 2090000,
			"lotSize": 31133,
			"sqftTotal": 4060,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40939280",
			"bath": 6,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "94552",
			"path": "castro-valley-ca/4403-recreation-center-dr-castro-valley-ca-94552-12_40939280/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31342d03-4e15-43e6-83c7-6c79f640d9c3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-11 00:00:00",
			"createdAt": "2021-02-26 20:17:01",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.686791,
				"lng": -122.041681,
				"zipcode": "94552",
				"subPremise": "",
				"address": "4403 Recreation Center Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 8,
			"id": "989bfe5a-bb4f-4704-87b5-5b0809b60975",
			"tnImgPath": "https://pi.movoto.com/p/110/421599644_0_qJu3rA_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Yevgeniy Zinchik",
			"listPrice": 1450000,
			"lotSize": 5724,
			"sqftTotal": 2522,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421599644",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1998,
			"zipCode": "94552",
			"path": "castro-valley-ca/22658-canyon-ridge-pl-castro-valley-ca-94552/pid_t9gdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b392fe85-3508-484a-84cf-4663ca103c66",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-09-30 07:11:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=D5WHuQuYcxh&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.691293,
				"lng": -122.044124,
				"zipcode": "94552",
				"subPremise": "",
				"address": "22658 Canyon Ridge Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=D5WHuQuYcxh&brand=0"
		},
		{
			"closePrice": 999888,
			"daysOnMovoto": 43,
			"id": "0b735690-398f-43e3-868e-82462f3b02bc",
			"tnImgPath": "https://pi.movoto.com/p/12/40964827_0_iNIvAa_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Trish Radovich",
			"listPrice": 998000,
			"lotSize": 6543,
			"sqftTotal": 1462,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964827",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1985,
			"zipCode": "94546",
			"path": "castro-valley-ca/4179-beverly-pl-castro-valley-ca-94546-12_27599377/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1816950e-08c3-4eb4-bb4d-e46006a7a4fc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-08-26 23:31:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.703462,
				"lng": -122.073314,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4179 Beverly Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 999888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 830000,
			"daysOnMovoto": 18,
			"id": "d9a88c9f-8c8e-4db6-a432-38d49bacdad1",
			"tnImgPath": "https://pi.movoto.com/p/12/40967567_0_NjQjVN_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Leslie Peterson",
			"listPrice": 725000,
			"lotSize": 5394,
			"sqftTotal": 1223,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967567",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Evolve Real Estate",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94546",
			"path": "castro-valley-ca/21336-kahlert-st-castro-valley-ca-94546/pid_eycdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "24d5c1a0-f55e-44ba-a862-26201ffeaf2d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-09-17 17:46:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.687805,
				"lng": -122.083989,
				"zipcode": "94546",
				"subPremise": "",
				"address": "21336 Kahlert St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 48,
			"id": "5a498235-5f15-4318-89ad-e644b8d0e400",
			"tnImgPath": "https://pi.movoto.com/p/12/40963059_0_bVfEr3_p.jpeg",
			"listDate": "2021-08-18 00:00:00",
			"listingAgent": "Kenneth Er",
			"listPrice": 1498000,
			"lotSize": 11997,
			"sqftTotal": 3726,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963059",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94546",
			"path": "castro-valley-ca/4205-danielle-way-castro-valley-ca-94546/pid_g2ubvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "326f4cd1-4653-44c0-9fc4-d1cdb4f68334",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-08-18 22:26:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=nh6322pY5Lz&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.720104,
				"lng": -122.089715,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4205 Danielle Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=nh6322pY5Lz&mls=1"
		},
		{
			"closePrice": 905000,
			"daysOnMovoto": 0,
			"id": "0e77f363-ebcc-4cbe-82b7-2f035811cd25",
			"tnImgPath": "https://pi.movoto.com/p/102/221102533_0_Ez2m77_p.jpeg",
			"listDate": null,
			"listingAgent": "Debbie Bridges-Shaw",
			"listPrice": 899999,
			"lotSize": 10193,
			"sqftTotal": 963,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221102533",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty World Classic Fthll Pro",
			"photoCount": 52,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1951,
			"zipCode": "94546",
			"path": "castro-valley-ca/19070-almond-rd-castro-valley-ca-94546-12_40138274/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -49778,
			"propertyId": "1a3e3c3a-5f42-4c8e-9ccf-a876415f494a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-08-14 21:44:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 21:44:20",
			"virtualTourLink": "http://www.19070almondroad.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.709756,
				"lng": -122.0839,
				"zipcode": "94546",
				"subPremise": "",
				"address": "19070 Almond Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 905000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.19070almondroad.com/"
		},
		{
			"closePrice": 1207777,
			"daysOnMovoto": 30,
			"id": "77b23f85-b941-4270-b11b-93c95596c165",
			"tnImgPath": "https://pi.movoto.com/p/12/40965277_0_qQVbVz_p.jpeg",
			"listDate": "2021-08-31 00:00:00",
			"listingAgent": "Mason M Yanowitz",
			"listPrice": 1125000,
			"lotSize": 5000,
			"sqftTotal": 1834,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965277",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Real Estate Source Inc.",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94546",
			"path": "castro-valley-ca/19225-lakeridge-rd-castro-valley-ca-94546/pid_mktbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fd1a30c2-1252-474b-a233-2303a3150ebc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-08-31 16:11:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.705284,
				"lng": -122.086208,
				"zipcode": "94546",
				"subPremise": "",
				"address": "19225 Lakeridge Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1207777,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1270000,
			"daysOnMovoto": 52,
			"id": "e5049c20-5a26-4a97-acad-7b3af34a86f2",
			"tnImgPath": "https://pi.movoto.com/p/12/40962396_0_yEEryq_p.jpeg",
			"listDate": "2021-08-09 00:00:00",
			"listingAgent": "Louise Lovewell",
			"listPrice": 975000,
			"lotSize": 7971,
			"sqftTotal": 2236,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962396",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94546",
			"path": "castro-valley-ca/18801-carlton-ave-castro-valley-ca-94546/pid_qurbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1a9c053b-a70f-4909-91b1-e36ee966afa6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-08-09 18:21:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-09 00:00:00",
			"virtualTourLink": "https://unbranded.youriguide.com/18801_carlton_ave_castro_valley_ca/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.708156,
				"lng": -122.096682,
				"zipcode": "94546",
				"subPremise": "",
				"address": "18801 Carlton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1270000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://unbranded.youriguide.com/18801_carlton_ave_castro_valley_ca/"
		},
		{
			"closePrice": 1680000,
			"daysOnMovoto": 40,
			"id": "7fb39635-d14f-492c-ba64-697cd19b7e49",
			"tnImgPath": "https://pi.movoto.com/p/12/40963759_0_Rnjz2r_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Douglas Korb",
			"listPrice": 1730000,
			"lotSize": 22575,
			"sqftTotal": 3025,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963759",
			"bath": 3,
			"bed": 6,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1935,
			"zipCode": "94546",
			"path": "castro-valley-ca/3236-somerset-ave-castro-valley-ca-94546/pid_vsubvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ecd7e33f-2e89-49fd-a840-8f3c5bcb12eb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-28 00:00:00",
			"createdAt": "2021-08-19 16:46:56",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": "https://4-phase-photographic.seehouseat.com/public/vtour/display/1891261?idx=1#!/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.702176,
				"lng": -122.085498,
				"zipcode": "94546",
				"subPremise": "",
				"address": "3236 Somerset Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1680000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://4-phase-photographic.seehouseat.com/public/vtour/display/1891261?idx=1#!/"
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 34,
			"id": "20402bca-df64-4ae3-bebc-15b1ade46026",
			"tnImgPath": "https://pi.movoto.com/p/12/40964463_0_mevjvv_p.jpeg",
			"listDate": "2021-08-24 00:00:00",
			"listingAgent": "Ralph Watkins III",
			"listPrice": 897000,
			"lotSize": 9730,
			"sqftTotal": 1836,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964463",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Watkins Real Estate",
			"photoCount": 34,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1951,
			"zipCode": "94546",
			"path": "castro-valley-ca/17936-redwood-rd-castro-valley-ca-94546/pid_rtvbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a2a03d5-cf89-42d5-a5c7-06bccb901f3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-27 00:00:00",
			"createdAt": "2021-08-25 01:46:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-24 00:00:00",
			"virtualTourLink": "https://blueskypixs.hd.pics/17936-Redwood-Rd/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.714611,
				"lng": -122.073558,
				"zipcode": "94546",
				"subPremise": "",
				"address": "17936 Redwood Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://blueskypixs.hd.pics/17936-Redwood-Rd/idx"
		},
		{
			"closePrice": 1630000,
			"daysOnMovoto": 35,
			"id": "53eb913d-53d5-400d-9367-d089b2edf5c3",
			"tnImgPath": "https://pi.movoto.com/p/12/40964306_0_rjiE7e_p.jpeg",
			"listDate": "2021-08-23 00:00:00",
			"listingAgent": "Suzanna Chew",
			"listPrice": 1629000,
			"lotSize": 5981,
			"sqftTotal": 2875,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964306",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1999,
			"zipCode": "94552",
			"path": "castro-valley-ca/7787-coolidge-ct-castro-valley-ca-94552/pid_kk3bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "15fb6a18-542a-435f-89f8-a3623c7fce1a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-27 00:00:00",
			"createdAt": "2021-08-23 21:16:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-23 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2907597",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.720687,
				"lng": -122.023854,
				"zipcode": "94552",
				"subPremise": "",
				"address": "7787 Coolidge Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2907597"
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 78,
			"id": "524ddfa5-e655-40eb-a65b-c7549ca75057",
			"tnImgPath": "https://pi.movoto.com/p/12/40956744_0_ZEnvia_p.jpeg",
			"listDate": "2021-07-07 00:00:00",
			"listingAgent": "Richard Vanier",
			"listPrice": 1100000,
			"lotSize": 11016,
			"sqftTotal": 1783,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956744",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1925,
			"zipCode": "94546",
			"path": "castro-valley-ca/19858-forest-ave-castro-valley-ca-94546/pid_lzvbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -150000,
			"propertyId": "f8d159b2-f497-4d0f-8e3d-f8036cbabd3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-23 00:00:00",
			"createdAt": "2021-07-07 22:11:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-07 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/567879308",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.70084,
				"lng": -122.068719,
				"zipcode": "94546",
				"subPremise": "",
				"address": "19858 Forest Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/567879308"
		},
		{
			"closePrice": 1280000,
			"daysOnMovoto": 78,
			"id": "debd1bbc-9721-45ed-acc0-7bf68637a51d",
			"tnImgPath": "https://pi.movoto.com/p/12/40957271_0_j2MAuv_p.jpeg",
			"listDate": "2021-07-06 00:00:00",
			"listingAgent": "Jeffrey Nelson",
			"listPrice": 1288000,
			"lotSize": 8918,
			"sqftTotal": 2990,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957271",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Responsive Real Estate",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94546",
			"path": "castro-valley-ca/16711-cowell-st-castro-valley-ca-94546-12_40957271/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4960723f-32bc-414a-a2c3-d3ed865cdaa1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-22 00:00:00",
			"createdAt": "2021-07-06 17:01:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"zipcode": "94546",
				"subPremise": "",
				"address": "16711 Cowell St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 845000,
			"daysOnMovoto": 33,
			"id": "7aed0962-7217-484d-8445-5608bcc093b6",
			"tnImgPath": "https://pi.movoto.com/p/12/40963719_0_jzV6i2_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Peter Lai",
			"listPrice": 800000,
			"lotSize": 5760,
			"sqftTotal": 1050,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963719",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Wah Hing Corp.",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1949,
			"zipCode": "94546",
			"path": "castro-valley-ca/4659-heyer-ave-castro-valley-ca-94546-12_40190795/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1bb85dd3-c5dc-474e-b993-a95f5339a463",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-21 00:00:00",
			"createdAt": "2021-08-19 07:11:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": "https://my.matterport.com/models/MaqJtP3uNwm?section=media",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Castro Valley",
				"lat": 37.703902,
				"lng": -122.063792,
				"zipcode": "94546",
				"subPremise": "",
				"address": "4659 Heyer Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 845000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/models/MaqJtP3uNwm?section=media"
		}
	]