exports.FREMONT_SCHOOLS = [
    {
        "id": 66,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.563644,
        "lon": -122.017471,
        "name": "American High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "36300 Fremont Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/66-American-High-School/",
            "reviews": "/california/fremont/66-American-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/66-American-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "20%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "83%",
            "state_average": "64%"
        },
        "enrollment": 2448,
        "parentRating": 4,
        "numReviews": 30,
        "studentsPerTeacher": 28,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 10,
            "Equity Overview Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 9,
                "percentage": 20
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 62
            },
            {
                "label": "African American",
                "rating": 7,
                "percentage": 3
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 8
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 13
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 10,
                "percentage": 10
            }
        ],
        "remediationData": []
    },
    {
        "id": 79,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.550362,
        "lon": -121.947609,
        "name": "John Gomes Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "555 Lemos Lane",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/79-John-Gomes-Elementary-School/",
            "reviews": "/california/fremont/79-John-Gomes-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/79-John-Gomes-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "6%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 746,
        "parentRating": 5,
        "numReviews": 32,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 6
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 85
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 83,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "m",
        "lat": 37.54702,
        "lon": -121.942421,
        "name": "William Hopkins Junior High School",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "600 Driscoll Road",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/83-William-Hopkins-Junior-High-School/",
            "reviews": "/california/fremont/83-William-Hopkins-Junior-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/83-William-Hopkins-Junior-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "8%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1060,
        "parentRating": 4,
        "numReviews": 52,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 10,
            "Equity Overview Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 86
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 5
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 93,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.528297,
        "lon": -121.921371,
        "name": "Mission San Jose Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "43545 Bryant Street",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/93-Mission-San-Jose-Elementary-School/",
            "reviews": "/california/fremont/93-Mission-San-Jose-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/93-Mission-San-Jose-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "4%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 535,
        "parentRating": 4,
        "numReviews": 14,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 4
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 88
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Two or more races",
                "percentage": 1
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 94,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.54504,
        "lon": -121.931931,
        "name": "Mission San Jose High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "41717 Palm Avenue",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/94-Mission-San-Jose-High-School/",
            "reviews": "/california/fremont/94-Mission-San-Jose-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/94-Mission-San-Jose-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "7%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "91%",
            "state_average": "64%"
        },
        "enrollment": 2046,
        "parentRating": 3,
        "numReviews": 52,
        "studentsPerTeacher": 27,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 10,
            "Equity Overview Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 8,
                "percentage": 7
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 90
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 104,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.482876,
        "lon": -121.925339,
        "name": "Warm Springs Elementary School",
        "gradeLevels": "3-6",
        "assigned": null,
        "address": {
            "street1": "47370 Warm Springs Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/104-Warm-Springs-Elementary-School/",
            "reviews": "/california/fremont/104-Warm-Springs-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/104-Warm-Springs-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "5%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1054,
        "parentRating": 4,
        "numReviews": 43,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 9
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 8,
                "percentage": 5
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 89
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 2
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 68,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.530972,
        "lon": -121.994171,
        "name": "Joseph Azevada Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "39450 Royal Palm Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/68-Joseph-Azevada-Elementary-School/",
            "reviews": "/california/fremont/68-Joseph-Azevada-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/68-Joseph-Azevada-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "22%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 650,
        "parentRating": 5,
        "numReviews": 19,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 8,
                "percentage": 22
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 60
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 17
            },
            {
                "label": "White",
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 74,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.542168,
        "lon": -121.939957,
        "name": "Joshua Chadbourne Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "801 Plymouth Avenue",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/74-Joshua-Chadbourne-Elementary-School/",
            "reviews": "/california/fremont/74-Joshua-Chadbourne-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/74-Joshua-Chadbourne-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "6%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 734,
        "parentRating": 4,
        "numReviews": 51,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 6
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 88
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 76,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.542076,
        "lon": -121.966888,
        "name": "J. Haley Durham Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "40292 Leslie Street",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/76-J.-Haley-Durham-Elementary-School/",
            "reviews": "/california/fremont/76-J.-Haley-Durham-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/76-J.-Haley-Durham-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "24%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 707,
        "parentRating": 4,
        "numReviews": 37,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 24
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 63
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 19
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": []
    },
    {
        "id": 77,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.561214,
        "lon": -122.05188,
        "name": "Forest Park Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "34400 Maybird Circle",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/77-Forest-Park-Elementary-School/",
            "reviews": "/california/fremont/77-Forest-Park-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/77-Forest-Park-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "5%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1011,
        "parentRating": 4,
        "numReviews": 22,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 8,
                "percentage": 5
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 87
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 81,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.519634,
        "lon": -121.950996,
        "name": "E. M. Grimmer Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "43030 Newport Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/81-E.-M.-Grimmer-Elementary-School/",
            "reviews": "/california/fremont/81-E.-M.-Grimmer-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/81-E.-M.-Grimmer-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "44%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 481,
        "parentRating": 4,
        "numReviews": 45,
        "studentsPerTeacher": 17,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 44
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 27
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 50
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 85,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.522655,
        "lon": -121.969017,
        "name": "Irvington High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "41800 Blacow Road",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/85-Irvington-High-School/",
            "reviews": "/california/fremont/85-Irvington-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/85-Irvington-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "17%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "84%",
            "state_average": "64%"
        },
        "enrollment": 2294,
        "parentRating": 3,
        "numReviews": 39,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 10,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 17
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 71
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 9
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 11
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 2
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 89,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.545406,
        "lon": -121.998428,
        "name": "Tom Maloney Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "38700 Logan Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/89-Tom-Maloney-Elementary-School/",
            "reviews": "/california/fremont/89-Tom-Maloney-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/89-Tom-Maloney-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "20%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 589,
        "parentRating": 4,
        "numReviews": 91,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 20
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 67
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 16
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 7
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 96,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.577049,
        "lon": -121.984459,
        "name": "Niles Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "37141 Second Street",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/96-Niles-Elementary-School/",
            "reviews": "/california/fremont/96-Niles-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/96-Niles-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "13%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 588,
        "parentRating": 4,
        "numReviews": 77,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 13
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 51
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 13
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 25
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 98,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.56023,
        "lon": -121.98468,
        "name": "Parkmont Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "2601 Parkside Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/98-Parkmont-Elementary-School/",
            "reviews": "/california/fremont/98-Parkmont-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/98-Parkmont-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "11%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 885,
        "parentRating": 3,
        "numReviews": 21,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 11
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 72
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 10
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 67,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.572796,
        "lon": -122.052048,
        "name": "Ardenwood Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "33955 Emilia Lane",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/67-Ardenwood-Elementary-School/",
            "reviews": "/california/fremont/67-Ardenwood-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/67-Ardenwood-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "11%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 963,
        "parentRating": 4,
        "numReviews": 57,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 11
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 76
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 6
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 71,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.568768,
        "lon": -122.013992,
        "name": "Brookvale Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "3400 Nicolet Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/71-Brookvale-Elementary-School/",
            "reviews": "/california/fremont/71-Brookvale-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/71-Brookvale-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "13%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 708,
        "parentRating": 3,
        "numReviews": 28,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 13
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 65
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 13
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 11
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 80,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.518135,
        "lon": -121.958099,
        "name": "Harvey Green Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "42875 Gatewood Street",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/80-Harvey-Green-Elementary-School/",
            "reviews": "/california/fremont/80-Harvey-Green-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/80-Harvey-Green-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "31%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 517,
        "parentRating": 5,
        "numReviews": 29,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 31
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 66
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 18
            },
            {
                "label": "White",
                "rating": 6,
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 82,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.528229,
        "lon": -121.962898,
        "name": "O. N. Hirsch Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "41399 Chapel Way",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/82-O.-N.-Hirsch-Elementary-School/",
            "reviews": "/california/fremont/82-O.-N.-Hirsch-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/82-O.-N.-Hirsch-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "23%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 570,
        "parentRating": 4,
        "numReviews": 38,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 23
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 74
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 84,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "m",
        "lat": 37.528538,
        "lon": -121.962952,
        "name": "John M. Horner Junior High School",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "41365 Chapel Way",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/84-John-M.-Horner-Junior-High-School/",
            "reviews": "/california/fremont/84-John-M.-Horner-Junior-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/84-John-M.-Horner-Junior-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "18%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1245,
        "parentRating": 5,
        "numReviews": 23,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 10,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 18
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 76
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 10
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 2
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 86,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.5284,
        "lon": -121.983124,
        "name": "John F. Kennedy High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "39999 Blacow Road",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/86-John-F.-Kennedy-High-School/",
            "reviews": "/california/fremont/86-John-F.-Kennedy-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/86-John-F.-Kennedy-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "43%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "70%",
            "state_average": "64%"
        },
        "enrollment": 1292,
        "parentRating": 3,
        "numReviews": 28,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 8,
            "College Readiness Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 43
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 26
            },
            {
                "label": "African American",
                "rating": 4,
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 34
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 19
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 3
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 12
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 92,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.520061,
        "lon": -121.973969,
        "name": "Steven Millard Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "5200 Valpey Park Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/92-Steven-Millard-Elementary-School/",
            "reviews": "/california/fremont/92-Steven-Millard-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/92-Steven-Millard-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "21%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 577,
        "parentRating": 4,
        "numReviews": 44,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 21
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 60
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 18
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 11
            },
            {
                "label": "Filipino",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 95,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.535789,
        "lon": -121.947411,
        "name": "Mission Valley Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "41700 Denise Street",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/95-Mission-Valley-Elementary-School/",
            "reviews": "/california/fremont/95-Mission-Valley-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/95-Mission-Valley-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "9%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 655,
        "parentRating": 4,
        "numReviews": 118,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 1
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 9
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 84
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 99,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.562248,
        "lon": -122.030853,
        "name": "Patterson Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "35521 Cabrillo Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/99-Patterson-Elementary-School/",
            "reviews": "/california/fremont/99-Patterson-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/99-Patterson-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "23%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 786,
        "parentRating": 4,
        "numReviews": 92,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 23
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 58
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 101,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "m",
        "lat": 37.557125,
        "lon": -122.014542,
        "name": "Thornton Junior High School",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "4357 Thornton Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/101-Thornton-Junior-High-School/",
            "reviews": "/california/fremont/101-Thornton-Junior-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/101-Thornton-Junior-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "17%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1297,
        "parentRating": 4,
        "numReviews": 38,
        "studentsPerTeacher": 27,
        "subratings": {
            "Test Scores Rating": 10,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 17
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 64
            },
            {
                "label": "African American",
                "rating": 5,
                "percentage": 3
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 10
            },
            {
                "label": "Pacific Islander",
                "rating": 7,
                "percentage": 1
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 10
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 7
            }
        ],
        "remediationData": []
    },
    {
        "id": 105,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.576511,
        "lon": -122.030479,
        "name": "Warwick Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "3375 Warwick Road",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/105-Warwick-Elementary-School/",
            "reviews": "/california/fremont/105-Warwick-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/105-Warwick-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "15%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 912,
        "parentRating": 4,
        "numReviews": 36,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 15
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 67
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 12
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 106,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.552082,
        "lon": -121.994843,
        "name": "Washington High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "38442 Fremont Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/106-Washington-High-School/",
            "reviews": "/california/fremont/106-Washington-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/106-Washington-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "30%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "78%",
            "state_average": "64%"
        },
        "enrollment": 1918,
        "parentRating": 4,
        "numReviews": 38,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 9,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 30
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 40
            },
            {
                "label": "African American",
                "rating": 7,
                "percentage": 3
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 23
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 20
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 107,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.507309,
        "lon": -121.928383,
        "name": "Fred E. Weibel Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "45135 South Grimmer Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/107-Fred-E.-Weibel-Elementary-School/",
            "reviews": "/california/fremont/107-Fred-E.-Weibel-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/107-Fred-E.-Weibel-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "8%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 796,
        "parentRating": 4,
        "numReviews": 54,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 8
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 84
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 70,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.539776,
        "lon": -121.984703,
        "name": "Brier Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "39201 Sundale Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/70-Brier-Elementary-School/",
            "reviews": "/california/fremont/70-Brier-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/70-Brier-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "40%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 717,
        "parentRating": 4,
        "numReviews": 52,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 40
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 48
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 30
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 73,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "m",
        "lat": 37.555649,
        "lon": -122.0019,
        "name": "Centerville Junior High",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "37720 Fremont Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/73-Centerville-Junior-High/",
            "reviews": "/california/fremont/73-Centerville-Junior-High/#Reviews",
            "collegeSuccess": "/california/fremont/73-Centerville-Junior-High/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "30%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 972,
        "parentRating": 5,
        "numReviews": 23,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 9,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 30
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 45
            },
            {
                "label": "African American",
                "rating": 4,
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 23
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 18
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 6
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 75,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e,m,h",
        "lat": 37.537964,
        "lon": -121.992058,
        "name": "Circle of Independent Learning School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "4700 Calaveras Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/75-Circle-Of-Independent-Learning-School/",
            "reviews": "/california/fremont/75-Circle-Of-Independent-Learning-School/#Reviews",
            "collegeSuccess": "/california/fremont/75-Circle-Of-Independent-Learning-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "32%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "64%",
            "state_average": "64%"
        },
        "enrollment": 368,
        "parentRating": 5,
        "numReviews": 14,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "College Readiness Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 32
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 40
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 24
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 22
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 78,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.545704,
        "lon": -122.004868,
        "name": "Glenmoor Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "4620 Mattos Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/78-Glenmoor-Elementary-School/",
            "reviews": "/california/fremont/78-Glenmoor-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/78-Glenmoor-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "32%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 663,
        "parentRating": 5,
        "numReviews": 22,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 32
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 46
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 28
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 12
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 91,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.538094,
        "lon": -122.011719,
        "name": "John G. Mattos Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "37944 Farwell Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/91-John-G.-Mattos-Elementary-School/",
            "reviews": "/california/fremont/91-John-G.-Mattos-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/91-John-G.-Mattos-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "18%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 607,
        "parentRating": 4,
        "numReviews": 58,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 18
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 49
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 21
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 19
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 97,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.560394,
        "lon": -122.017708,
        "name": "Oliveira Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "4180 Alder Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/97-Oliveira-Elementary-School/",
            "reviews": "/california/fremont/97-Oliveira-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/97-Oliveira-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "24%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 885,
        "parentRating": 5,
        "numReviews": 24,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 24
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 62
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 16
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 102,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.574493,
        "lon": -121.9599,
        "name": "Vallejo Mill Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "38569 Canyon Heights Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/102-Vallejo-Mill-Elementary-School/",
            "reviews": "/california/fremont/102-Vallejo-Mill-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/102-Vallejo-Mill-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "29%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 519,
        "parentRating": 4,
        "numReviews": 79,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 29
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 35
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 35
            },
            {
                "label": "White",
                "rating": 6,
                "percentage": 17
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 103,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "m",
        "lat": 37.5373,
        "lon": -121.985863,
        "name": "G. M. Walters Junior High School",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "39600 Logan Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/103-G.-M.-Walters-Junior-High-School/",
            "reviews": "/california/fremont/103-G.-M.-Walters-Junior-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/103-G.-M.-Walters-Junior-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "42%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 727,
        "parentRating": 4,
        "numReviews": 14,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 8,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 42
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 30
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 36
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 69,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.527561,
        "lon": -121.977119,
        "name": "John Blacow Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "40404 Sundale Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/69-John-Blacow-Elementary-School/",
            "reviews": "/california/fremont/69-John-Blacow-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/69-John-Blacow-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "44%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 447,
        "parentRating": 3,
        "numReviews": 77,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 44
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 48
            },
            {
                "label": "Asian",
                "percentage": 24
            },
            {
                "label": "White",
                "percentage": 14
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 72,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.550827,
        "lon": -122.023331,
        "name": "Cabrillo Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "36700 San Pedro Drive",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/72-Cabrillo-Elementary-School/",
            "reviews": "/california/fremont/72-Cabrillo-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/72-Cabrillo-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "53%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 418,
        "parentRating": 3,
        "numReviews": 18,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 53
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 27
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 12
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 10865,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "m,h",
        "lat": 37.518631,
        "lon": -121.96492,
        "name": "Vista Alternative School",
        "gradeLevels": "7-12",
        "assigned": null,
        "address": {
            "street1": "4455 Seneca Park Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/10865-Vista-Alternative-School/",
            "reviews": "/california/fremont/10865-Vista-Alternative-School/#Reviews",
            "collegeSuccess": "/california/fremont/10865-Vista-Alternative-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "56%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "44%",
            "state_average": "64%"
        },
        "enrollment": 34,
        "parentRating": 5,
        "numReviews": 11,
        "studentsPerTeacher": 9,
        "subratings": {
            "Test Scores Rating": 4,
            "College Readiness Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 56
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "White",
                "percentage": 47
            },
            {
                "label": "Hispanic",
                "percentage": 18
            },
            {
                "label": "Asian",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            }
        ],
        "remediationData": []
    },
    {
        "id": 100,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.518631,
        "lon": -121.96492,
        "name": "Robertson High (Continuation) School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "4455 Seneca Park Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/100-Robertson-High-Continuation-School/",
            "reviews": "/california/fremont/100-Robertson-High-Continuation-School/#Reviews",
            "collegeSuccess": "/california/fremont/100-Robertson-High-Continuation-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "58%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "32%",
            "state_average": "64%"
        },
        "enrollment": 176,
        "parentRating": 0,
        "numReviews": 2,
        "studentsPerTeacher": 13,
        "subratings": {
            "Test Scores Rating": 2,
            "College Readiness Rating": 3,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 58
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 48
            },
            {
                "label": "White",
                "rating": 2,
                "percentage": 18
            },
            {
                "label": "Filipino",
                "rating": 1,
                "percentage": 6
            },
            {
                "label": "Asian",
                "percentage": 15
            },
            {
                "label": "African American",
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 12297,
        "districtId": 1065,
        "districtName": "California School For The Deaf-Fremont (State Special Schl) School District",
        "districtCity": "Fremont",
        "levelCode": "p,e,m,h",
        "lat": 37.558956,
        "lon": -121.965508,
        "name": "California School For The Deaf-Fremont",
        "gradeLevels": "PK-12",
        "assigned": null,
        "address": {
            "street1": "39350 Gallaudet Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/12297-California-School-For-The-Deaf-Fremont/",
            "reviews": "/california/fremont/12297-California-School-For-The-Deaf-Fremont/#Reviews",
            "collegeSuccess": "/california/fremont/12297-California-School-For-The-Deaf-Fremont/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "100%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "62%",
            "state_average": "64%"
        },
        "enrollment": 372,
        "parentRating": 4,
        "numReviews": 20,
        "studentsPerTeacher": 5,
        "subratings": {
            "Test Scores Rating": 1,
            "Academic Progress Rating": 3,
            "College Readiness Rating": 1,
            "Equity Overview Rating": 1
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 100
            },
            {
                "label": "All students",
                "rating": 1
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 52
            },
            {
                "label": "White",
                "percentage": 23
            },
            {
                "label": "Asian",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 32288,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.547817,
        "lon": -121.975227,
        "name": "BASIS Independent Fremont",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "3300 Kearney Street",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/32288-BASIS-Independent-Fremont/",
            "reviews": "/california/fremont/32288-BASIS-Independent-Fremont/#Reviews",
            "collegeSuccess": "/california/fremont/32288-BASIS-Independent-Fremont/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 49,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 87,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e",
        "lat": 37.486507,
        "lon": -121.923279,
        "name": "James Leitch Elementary School",
        "gradeLevels": "K-3",
        "assigned": null,
        "address": {
            "street1": "47100 Fernald Street",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/87-James-Leitch-Elementary-School/",
            "reviews": "/california/fremont/87-James-Leitch-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/87-James-Leitch-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "5%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 857,
        "parentRating": 5,
        "numReviews": 58,
        "studentsPerTeacher": 22,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 5
            },
            {
                "label": "Asian",
                "percentage": 89
            },
            {
                "label": "Hispanic",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 24949,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.575161,
        "lon": -121.980217,
        "name": "Play 2 Learn",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "255 H St.",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/24949-Play-2-Learn/",
            "reviews": "/california/fremont/24949-Play-2-Learn/#Reviews",
            "collegeSuccess": "/california/fremont/24949-Play-2-Learn/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 32276,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.492329,
        "lon": -121.930222,
        "name": "Safari Kid - Warm Springs",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "46292 Warm Springs Blvd. Suite 670",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/32276-Safari-Kid---Warm-Springs/",
            "reviews": "/california/fremont/32276-Safari-Kid---Warm-Springs/#Reviews",
            "collegeSuccess": "/california/fremont/32276-Safari-Kid---Warm-Springs/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 25835,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.519508,
        "lon": -121.946014,
        "name": "Averroes High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "43174 Osgood Road",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/25835-Averroes-High-School/",
            "reviews": "/california/fremont/25835-Averroes-High-School/#Reviews",
            "collegeSuccess": "/california/fremont/25835-Averroes-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 49,
        "parentRating": 3,
        "numReviews": 50,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 59
            },
            {
                "label": "White",
                "percentage": 33
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 4
            }
        ],
        "remediationData": []
    },
    {
        "id": 25842,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.567001,
        "lon": -122.053276,
        "name": "Genius Kids Inc",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "4966 Paseo Padre Parkway",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/25842-Genius-Kids-Inc/",
            "reviews": "/california/fremont/25842-Genius-Kids-Inc/#Reviews",
            "collegeSuccess": "/california/fremont/25842-Genius-Kids-Inc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 91,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 25846,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.527592,
        "lon": -121.962555,
        "name": "Ilm Academy",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "42412 Albrae Street  Fremont 94538",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/25846-Ilm-Academy/",
            "reviews": "/california/fremont/25846-Ilm-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/25846-Ilm-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 126,
        "parentRating": 4,
        "numReviews": 31,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 93
            },
            {
                "label": "African American",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 25856,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.551056,
        "lon": -121.99398,
        "name": "Stratford School",
        "gradeLevels": "K",
        "assigned": null,
        "address": {
            "street1": "38495 Fremont Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/25856-Stratford-School/",
            "reviews": "/california/fremont/25856-Stratford-School/#Reviews",
            "collegeSuccess": "/california/fremont/25856-Stratford-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 87,
        "parentRating": 4,
        "numReviews": 15,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 26487,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.532562,
        "lon": -121.948456,
        "name": "Palm Academy / Fastrackids",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2856 Washington Blvd.",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/26487-Palm-Academy---Fastrackids/",
            "reviews": "/california/fremont/26487-Palm-Academy---Fastrackids/#Reviews",
            "collegeSuccess": "/california/fremont/26487-Palm-Academy---Fastrackids/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27316,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.555965,
        "lon": -121.984535,
        "name": "Kinder Care Learning Center #461",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "38700 Paseo Padre Parkway",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27316-Kinder-Care-Learning-Center-461/",
            "reviews": "/california/fremont/27316-Kinder-Care-Learning-Center-461/#Reviews",
            "collegeSuccess": "/california/fremont/27316-Kinder-Care-Learning-Center-461/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27373,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.522442,
        "lon": -121.967514,
        "name": "Ymca Y Kids James Leitch",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "41811 Blacow Road",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27373-Ymca-Y-Kids-James-Leitch/",
            "reviews": "/california/fremont/27373-Ymca-Y-Kids-James-Leitch/#Reviews",
            "collegeSuccess": "/california/fremont/27373-Ymca-Y-Kids-James-Leitch/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 1,
        "numReviews": 7,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27374,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.522442,
        "lon": -121.967514,
        "name": "Ymca School Age Pgrm Durham",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "41811 Blacow Road",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27374-Ymca-School-Age-Pgrm-Durham/",
            "reviews": "/california/fremont/27374-Ymca-School-Age-Pgrm-Durham/#Reviews",
            "collegeSuccess": "/california/fremont/27374-Ymca-School-Age-Pgrm-Durham/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27375,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.482758,
        "lon": -121.92305,
        "name": "Ymca School Age Pgrm Warm Springs",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "47370 Warm Springs Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27375-Ymca-School-Age-Pgrm-Warm-Springs/",
            "reviews": "/california/fremont/27375-Ymca-School-Age-Pgrm-Warm-Springs/#Reviews",
            "collegeSuccess": "/california/fremont/27375-Ymca-School-Age-Pgrm-Warm-Springs/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27376,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.507107,
        "lon": -121.927322,
        "name": "Ymca School Age Pgrm Weibel",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "45135 South Grimmer Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27376-Ymca-School-Age-Pgrm-Weibel/",
            "reviews": "/california/fremont/27376-Ymca-School-Age-Pgrm-Weibel/#Reviews",
            "collegeSuccess": "/california/fremont/27376-Ymca-School-Age-Pgrm-Weibel/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27552,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.538113,
        "lon": -121.99202,
        "name": "Hyman State Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4700 Calaveras Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27552-Hyman-State-Pre-School/",
            "reviews": "/california/fremont/27552-Hyman-State-Pre-School/#Reviews",
            "collegeSuccess": "/california/fremont/27552-Hyman-State-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27690,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.55011,
        "lon": -121.984734,
        "name": "Genius Kids Central Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "39132 State Street",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27690-Genius-Kids-Central-Day-Care/",
            "reviews": "/california/fremont/27690-Genius-Kids-Central-Day-Care/#Reviews",
            "collegeSuccess": "/california/fremont/27690-Genius-Kids-Central-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27722,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.480797,
        "lon": -121.919861,
        "name": "Shining Star Montessori School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "47624 Gridley Court",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27722-Shining-Star-Montessori-School/",
            "reviews": "/california/fremont/27722-Shining-Star-Montessori-School/#Reviews",
            "collegeSuccess": "/california/fremont/27722-Shining-Star-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 40,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 100
            }
        ],
        "remediationData": []
    },
    {
        "id": 27726,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.58493,
        "lon": -122.061852,
        "name": "Kindercare Learning Center-Alvarado",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32710 Falcon Drive",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27726-Kindercare-Learning-Center-Alvarado/",
            "reviews": "/california/fremont/27726-Kindercare-Learning-Center-Alvarado/#Reviews",
            "collegeSuccess": "/california/fremont/27726-Kindercare-Learning-Center-Alvarado/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 1,
        "numReviews": 7,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27829,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.542122,
        "lon": -121.939835,
        "name": "Ymca School Age Pgrm Chadbourne",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "801 Plymouth Avenue",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27829-Ymca-School-Age-Pgrm-Chadbourne/",
            "reviews": "/california/fremont/27829-Ymca-School-Age-Pgrm-Chadbourne/#Reviews",
            "collegeSuccess": "/california/fremont/27829-Ymca-School-Age-Pgrm-Chadbourne/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27955,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.550064,
        "lon": -121.947968,
        "name": "Bay Area Child Care Center-Gomez",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "577 Lemos Lane",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27955-Bay-Area-Child-Care-Center-Gomez/",
            "reviews": "/california/fremont/27955-Bay-Area-Child-Care-Center-Gomez/#Reviews",
            "collegeSuccess": "/california/fremont/27955-Bay-Area-Child-Care-Center-Gomez/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27956,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.550064,
        "lon": -121.947968,
        "name": "Bay Area Child Care Center-Azaveda",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "577 Lemos Lane",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27956-Bay-Area-Child-Care-Center-Azaveda/",
            "reviews": "/california/fremont/27956-Bay-Area-Child-Care-Center-Azaveda/#Reviews",
            "collegeSuccess": "/california/fremont/27956-Bay-Area-Child-Care-Center-Azaveda/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27957,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.550064,
        "lon": -121.947968,
        "name": "Bay Area Child Care Center-Patterson",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "577 Lemos Lane",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/27957-Bay-Area-Child-Care-Center-Patterson/",
            "reviews": "/california/fremont/27957-Bay-Area-Child-Care-Center-Patterson/#Reviews",
            "collegeSuccess": "/california/fremont/27957-Bay-Area-Child-Care-Center-Patterson/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28153,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.574692,
        "lon": -121.965317,
        "name": "Abc Magic Moments Ps & Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "38435 Goodrich Way",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28153-Abc-Magic-Moments-Ps--Child-Care-Center/",
            "reviews": "/california/fremont/28153-Abc-Magic-Moments-Ps--Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/fremont/28153-Abc-Magic-Moments-Ps--Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28260,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Almond",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28260-Kidango-Cdc-Almond/",
            "reviews": "/california/fremont/28260-Kidango-Cdc-Almond/#Reviews",
            "collegeSuccess": "/california/fremont/28260-Kidango-Cdc-Almond/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28393,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28393-Kidango-Child-Development-Center/",
            "reviews": "/california/fremont/28393-Kidango-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/fremont/28393-Kidango-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28412,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.554138,
        "lon": -122.057991,
        "name": "After School Adventures Dc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "34552 Willbridge Terrace",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28412-After-School-Adventures-Dc/",
            "reviews": "/california/fremont/28412-After-School-Adventures-Dc/#Reviews",
            "collegeSuccess": "/california/fremont/28412-After-School-Adventures-Dc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28444,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Arbuckle",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28444-Kidango-Cdc-Arbuckle/",
            "reviews": "/california/fremont/28444-Kidango-Cdc-Arbuckle/#Reviews",
            "collegeSuccess": "/california/fremont/28444-Kidango-Cdc-Arbuckle/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28445,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Russo Child Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28445-Kidango-Russo-Child-Day-Care/",
            "reviews": "/california/fremont/28445-Kidango-Russo-Child-Day-Care/#Reviews",
            "collegeSuccess": "/california/fremont/28445-Kidango-Russo-Child-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28447,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Meyer",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28447-Kidango-Cdc-Meyer/",
            "reviews": "/california/fremont/28447-Kidango-Cdc-Meyer/#Reviews",
            "collegeSuccess": "/california/fremont/28447-Kidango-Cdc-Meyer/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28448,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc Linda Vista",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28448-Kidango-Cdc-Linda-Vista/",
            "reviews": "/california/fremont/28448-Kidango-Cdc-Linda-Vista/#Reviews",
            "collegeSuccess": "/california/fremont/28448-Kidango-Cdc-Linda-Vista/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28455,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Carlson",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28455-Kidango-Cdc-Carlson/",
            "reviews": "/california/fremont/28455-Kidango-Cdc-Carlson/#Reviews",
            "collegeSuccess": "/california/fremont/28455-Kidango-Cdc-Carlson/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28468,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Searles",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28468-Kidango-Cdc-Searles/",
            "reviews": "/california/fremont/28468-Kidango-Cdc-Searles/#Reviews",
            "collegeSuccess": "/california/fremont/28468-Kidango-Cdc-Searles/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28470,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Guy Emanuele",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28470-Kidango-Cdc-Guy-Emanuele/",
            "reviews": "/california/fremont/28470-Kidango-Cdc-Guy-Emanuele/#Reviews",
            "collegeSuccess": "/california/fremont/28470-Kidango-Cdc-Guy-Emanuele/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28474,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Kitayama",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28474-Kidango-Cdc-Kitayama/",
            "reviews": "/california/fremont/28474-Kidango-Cdc-Kitayama/#Reviews",
            "collegeSuccess": "/california/fremont/28474-Kidango-Cdc-Kitayama/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28485,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Hillview Crest",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28485-Kidango-Cdc-Hillview-Crest/",
            "reviews": "/california/fremont/28485-Kidango-Cdc-Hillview-Crest/#Reviews",
            "collegeSuccess": "/california/fremont/28485-Kidango-Cdc-Hillview-Crest/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28488,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Owl's Landing",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28488-Kidango-Cdc-Owls-Landing/",
            "reviews": "/california/fremont/28488-Kidango-Cdc-Owls-Landing/#Reviews",
            "collegeSuccess": "/california/fremont/28488-Kidango-Cdc-Owls-Landing/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28520,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28520-Kidango-Cdc/",
            "reviews": "/california/fremont/28520-Kidango-Cdc/#Reviews",
            "collegeSuccess": "/california/fremont/28520-Kidango-Cdc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28542,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Cabello",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28542-Kidango-Cdc-Cabello/",
            "reviews": "/california/fremont/28542-Kidango-Cdc-Cabello/#Reviews",
            "collegeSuccess": "/california/fremont/28542-Kidango-Cdc-Cabello/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28573,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Rix",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28573-Kidango-Cdc-Rix/",
            "reviews": "/california/fremont/28573-Kidango-Cdc-Rix/#Reviews",
            "collegeSuccess": "/california/fremont/28573-Kidango-Cdc-Rix/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28601,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Cureton",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28601-Kidango-Cdc-Cureton/",
            "reviews": "/california/fremont/28601-Kidango-Cdc-Cureton/#Reviews",
            "collegeSuccess": "/california/fremont/28601-Kidango-Cdc-Cureton/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28649,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Ohlone Lab",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28649-Kidango-Cdc-Ohlone-Lab/",
            "reviews": "/california/fremont/28649-Kidango-Cdc-Ohlone-Lab/#Reviews",
            "collegeSuccess": "/california/fremont/28649-Kidango-Cdc-Ohlone-Lab/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28653,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Washington",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28653-Kidango-Cdc-Washington/",
            "reviews": "/california/fremont/28653-Kidango-Cdc-Washington/#Reviews",
            "collegeSuccess": "/california/fremont/28653-Kidango-Cdc-Washington/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28656,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Ohlone Chynoweth",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28656-Kidango-Cdc-Ohlone-Chynoweth/",
            "reviews": "/california/fremont/28656-Kidango-Cdc-Ohlone-Chynoweth/#Reviews",
            "collegeSuccess": "/california/fremont/28656-Kidango-Cdc-Ohlone-Chynoweth/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28659,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Ruschin",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28659-Kidango-Cdc-Ruschin/",
            "reviews": "/california/fremont/28659-Kidango-Cdc-Ruschin/#Reviews",
            "collegeSuccess": "/california/fremont/28659-Kidango-Cdc-Ruschin/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28662,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdc-Marie Kaiser",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28662-Kidango-Cdc-Marie-Kaiser/",
            "reviews": "/california/fremont/28662-Kidango-Cdc-Marie-Kaiser/#Reviews",
            "collegeSuccess": "/california/fremont/28662-Kidango-Cdc-Marie-Kaiser/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28663,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango Cdcvalley Medical",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/28663-Kidango-Cdcvalley-Medical/",
            "reviews": "/california/fremont/28663-Kidango-Cdcvalley-Medical/#Reviews",
            "collegeSuccess": "/california/fremont/28663-Kidango-Cdcvalley-Medical/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 29022,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.516735,
        "lon": -121.955734,
        "name": "Greenhouse For Kids",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4127 Crestwood Street",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/29022-Greenhouse-For-Kids/",
            "reviews": "/california/fremont/29022-Greenhouse-For-Kids/#Reviews",
            "collegeSuccess": "/california/fremont/29022-Greenhouse-For-Kids/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 7,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 29023,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.552475,
        "lon": -121.982018,
        "name": "Tiny Tots Pre-School Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3300 Capitol Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/29023-Tiny-Tots-Pre-School-Center/",
            "reviews": "/california/fremont/29023-Tiny-Tots-Pre-School-Center/#Reviews",
            "collegeSuccess": "/california/fremont/29023-Tiny-Tots-Pre-School-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 6,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 29068,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.560154,
        "lon": -121.974457,
        "name": "Bumble Bee Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "38840 Garibaldi Common",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/29068-Bumble-Bee-Child-Care-Center/",
            "reviews": "/california/fremont/29068-Bumble-Bee-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/fremont/29068-Bumble-Bee-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 29159,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.522442,
        "lon": -121.967514,
        "name": "Ymca Of East Bay Y Kids",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "41811 Blacow Road",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/29159-Ymca-Of-East-Bay-Y-Kids/",
            "reviews": "/california/fremont/29159-Ymca-Of-East-Bay-Y-Kids/#Reviews",
            "collegeSuccess": "/california/fremont/29159-Ymca-Of-East-Bay-Y-Kids/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 29967,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.52792,
        "lon": -121.950813,
        "name": "Best Friends Chinese",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "42080 Osgood Road",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/29967-Best-Friends-Chinese/",
            "reviews": "/california/fremont/29967-Best-Friends-Chinese/#Reviews",
            "collegeSuccess": "/california/fremont/29967-Best-Friends-Chinese/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30031,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.499184,
        "lon": -121.921303,
        "name": "Happy Hearts Academy",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "45958 Omega Drive",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/30031-Happy-Hearts-Academy/",
            "reviews": "/california/fremont/30031-Happy-Hearts-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/30031-Happy-Hearts-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30196,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.567001,
        "lon": -122.053276,
        "name": "Lil Genius Kid Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4966 Paseo Padre Parkway",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/30196-Lil-Genius-Kid-Child-Care-Center/",
            "reviews": "/california/fremont/30196-Lil-Genius-Kid-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/fremont/30196-Lil-Genius-Kid-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30197,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.538605,
        "lon": -121.953453,
        "name": "Enlight Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "40991 Valero Drive",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/30197-Enlight-Pre-School/",
            "reviews": "/california/fremont/30197-Enlight-Pre-School/#Reviews",
            "collegeSuccess": "/california/fremont/30197-Enlight-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30199,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.564522,
        "lon": -122.05674,
        "name": "Safari Kid Child Care Center-Newpark Mall",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "34161 Donahue Terrace",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/30199-Safari-Kid-Child-Care-Center-Newpark-Mall/",
            "reviews": "/california/fremont/30199-Safari-Kid-Child-Care-Center-Newpark-Mall/#Reviews",
            "collegeSuccess": "/california/fremont/30199-Safari-Kid-Child-Care-Center-Newpark-Mall/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30205,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.558426,
        "lon": -122.00959,
        "name": "Learn & Play Mont School-Maple",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "37220 Maple Street",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/30205-Learn--Play-Mont-School-Maple/",
            "reviews": "/california/fremont/30205-Learn--Play-Mont-School-Maple/#Reviews",
            "collegeSuccess": "/california/fremont/30205-Learn--Play-Mont-School-Maple/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30275,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.551666,
        "lon": -122.011421,
        "name": "Learn & Play Mont School-Peralta",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4511 Peralta Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/30275-Learn--Play-Mont-School-Peralta/",
            "reviews": "/california/fremont/30275-Learn--Play-Mont-School-Peralta/#Reviews",
            "collegeSuccess": "/california/fremont/30275-Learn--Play-Mont-School-Peralta/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 31032,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.536385,
        "lon": -121.977058,
        "name": "Little Flowers Montessori",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4343 Stevenson Blvd",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/31032-Little-Flowers-Montessori/",
            "reviews": "/california/fremont/31032-Little-Flowers-Montessori/#Reviews",
            "collegeSuccess": "/california/fremont/31032-Little-Flowers-Montessori/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 31094,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.53812,
        "lon": -121.992012,
        "name": "Young Adult Program",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "4700 Calaveras Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/31094-Young-Adult-Program/",
            "reviews": "/california/fremont/31094-Young-Adult-Program/#Reviews",
            "collegeSuccess": "/california/fremont/31094-Young-Adult-Program/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "17%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 41,
        "parentRating": 5,
        "numReviews": 7,
        "studentsPerTeacher": 13,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 17
            },
            {
                "label": "Asian",
                "percentage": 39
            },
            {
                "label": "Hispanic",
                "percentage": 24
            },
            {
                "label": "White",
                "percentage": 12
            },
            {
                "label": "Filipino",
                "percentage": 12
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 5
            }
        ],
        "remediationData": []
    },
    {
        "id": 31151,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "p",
        "lat": 37.540112,
        "lon": -121.984383,
        "name": "Glankler Early Learning Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "39207 Sundale Drive",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/31151-Glankler-Early-Learning-Center/",
            "reviews": "/california/fremont/31151-Glankler-Early-Learning-Center/#Reviews",
            "collegeSuccess": "/california/fremont/31151-Glankler-Early-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "0%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 15,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 100
            }
        ],
        "remediationData": []
    },
    {
        "id": 31227,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.556389,
        "lon": -122.010155,
        "name": "Montessori School Of Centerville",
        "gradeLevels": "K-3",
        "assigned": null,
        "address": {
            "street1": "4209 Baine Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/31227-Montessori-School-Of-Centerville/",
            "reviews": "/california/fremont/31227-Montessori-School-Of-Centerville/#Reviews",
            "collegeSuccess": "/california/fremont/31227-Montessori-School-Of-Centerville/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 10,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 17423,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.552475,
        "lon": -122.055267,
        "name": "Little Flowers Montessori",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "34735 Ardenwood Blvd",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/17423-Little-Flowers-Montessori/",
            "reviews": "/california/fremont/17423-Little-Flowers-Montessori/#Reviews",
            "collegeSuccess": "/california/fremont/17423-Little-Flowers-Montessori/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 217,
        "parentRating": 3,
        "numReviews": 13,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 75
            },
            {
                "label": "Pacific Islander",
                "percentage": 17
            },
            {
                "label": "Hispanic",
                "percentage": 8
            }
        ],
        "remediationData": []
    },
    {
        "id": 15838,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.521786,
        "lon": -121.980537,
        "name": "Stratford School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "5301 Curtis Street",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/15838-Stratford-School/",
            "reviews": "/california/fremont/15838-Stratford-School/#Reviews",
            "collegeSuccess": "/california/fremont/15838-Stratford-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 411,
        "parentRating": 3,
        "numReviews": 27,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 8229,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.53825,
        "lon": -121.968422,
        "name": "Our Lady of Guadalupe School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "40374 Fremont Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/8229-Our-Lady-Of-Guadalupe-School/",
            "reviews": "/california/fremont/8229-Our-Lady-Of-Guadalupe-School/#Reviews",
            "collegeSuccess": "/california/fremont/8229-Our-Lady-Of-Guadalupe-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 239,
        "parentRating": 5,
        "numReviews": 51,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 37
            },
            {
                "label": "Asian",
                "percentage": 35
            },
            {
                "label": "White",
                "percentage": 13
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 8230,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.557701,
        "lon": -122.003769,
        "name": "Holy Spirit Elementary School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "3930 Parish Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/8230-Holy-Spirit-Elementary-School/",
            "reviews": "/california/fremont/8230-Holy-Spirit-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/8230-Holy-Spirit-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 272,
        "parentRating": 4,
        "numReviews": 22,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 55
            },
            {
                "label": "Hispanic",
                "percentage": 22
            },
            {
                "label": "Two or more races",
                "percentage": 15
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 8254,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.534969,
        "lon": -121.918983,
        "name": "St. Joseph Elementary School",
        "gradeLevels": "1-8",
        "assigned": null,
        "address": {
            "street1": "43222 Mission Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/8254-St.-Joseph-Elementary-School/",
            "reviews": "/california/fremont/8254-St.-Joseph-Elementary-School/#Reviews",
            "collegeSuccess": "/california/fremont/8254-St.-Joseph-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 259,
        "parentRating": 4,
        "numReviews": 96,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 47
            },
            {
                "label": "Hispanic",
                "percentage": 22
            },
            {
                "label": "Two or more races",
                "percentage": 17
            },
            {
                "label": "White",
                "percentage": 13
            }
        ],
        "remediationData": []
    },
    {
        "id": 15818,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.548599,
        "lon": -122.019096,
        "name": "Family Learning Center",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "37109 Dondero Way",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/15818-Family-Learning-Center/",
            "reviews": "/california/fremont/15818-Family-Learning-Center/#Reviews",
            "collegeSuccess": "/california/fremont/15818-Family-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 1,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 8603,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m,h",
        "lat": 37.550518,
        "lon": -122.019249,
        "name": "Fremont Christian School",
        "gradeLevels": "PK-12",
        "assigned": null,
        "address": {
            "street1": "4760 Thornton Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/8603-Fremont-Christian-School/",
            "reviews": "/california/fremont/8603-Fremont-Christian-School/#Reviews",
            "collegeSuccess": "/california/fremont/8603-Fremont-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 728,
        "parentRating": 3,
        "numReviews": 83,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 66
            },
            {
                "label": "Two or more races",
                "percentage": 12
            },
            {
                "label": "White",
                "percentage": 11
            },
            {
                "label": "Hispanic",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 8618,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.551891,
        "lon": -121.995613,
        "name": "Prince Of Peace Lutheran School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "38451 Fremont Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/8618-Prince-Of-Peace-Lutheran-School/",
            "reviews": "/california/fremont/8618-Prince-Of-Peace-Lutheran-School/#Reviews",
            "collegeSuccess": "/california/fremont/8618-Prince-Of-Peace-Lutheran-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 169,
        "parentRating": 3,
        "numReviews": 46,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 39
            },
            {
                "label": "Two or more races",
                "percentage": 27
            },
            {
                "label": "Asian",
                "percentage": 27
            },
            {
                "label": "African American",
                "percentage": 7
            }
        ],
        "remediationData": []
    },
    {
        "id": 17738,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.551678,
        "lon": -122.011551,
        "name": "Learn and Play Montessori School",
        "gradeLevels": "PK-2",
        "assigned": null,
        "address": {
            "street1": "4511 Peralta Blvd",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/17738-Learn-And-Play-Montessori-School/",
            "reviews": "/california/fremont/17738-Learn-And-Play-Montessori-School/#Reviews",
            "collegeSuccess": "/california/fremont/17738-Learn-And-Play-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 20,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 8716,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.529301,
        "lon": -121.955803,
        "name": "Mission Peak Christian",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "41354 Roberts Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/8716-Mission-Peak-Christian/",
            "reviews": "/california/fremont/8716-Mission-Peak-Christian/#Reviews",
            "collegeSuccess": "/california/fremont/8716-Mission-Peak-Christian/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 12844,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.580685,
        "lon": -122.054901,
        "name": "Peace Terrace Academy",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "33330 Peace Terrace",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/12844-Peace-Terrace-Academy/",
            "reviews": "/california/fremont/12844-Peace-Terrace-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/12844-Peace-Terrace-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 92,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 80
            },
            {
                "label": "White",
                "percentage": 10
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": []
    },
    {
        "id": 13179,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.533779,
        "lon": -121.917046,
        "name": "Dominican Kindergarten",
        "gradeLevels": "K",
        "assigned": null,
        "address": {
            "street1": "43326 Mission Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/13179-Dominican-Kindergarten/",
            "reviews": "/california/fremont/13179-Dominican-Kindergarten/#Reviews",
            "collegeSuccess": "/california/fremont/13179-Dominican-Kindergarten/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 30,
        "parentRating": 5,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 50
            },
            {
                "label": "Two or more races",
                "percentage": 30
            },
            {
                "label": "Hispanic",
                "percentage": 13
            },
            {
                "label": "White",
                "percentage": 7
            }
        ],
        "remediationData": []
    },
    {
        "id": 9312,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.559235,
        "lon": -121.953476,
        "name": "Kimber Hills Academy",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "39700 Mission Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/9312-Kimber-Hills-Academy/",
            "reviews": "/california/fremont/9312-Kimber-Hills-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/9312-Kimber-Hills-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 261,
        "parentRating": 5,
        "numReviews": 16,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 57
            },
            {
                "label": "White",
                "percentage": 31
            },
            {
                "label": "Hispanic",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 5
            }
        ],
        "remediationData": []
    },
    {
        "id": 12748,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.555904,
        "lon": -121.984612,
        "name": "Fremont Kindercare",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "38700 Paseo Padre Parkway",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/12748-Fremont-Kindercare/",
            "reviews": "/california/fremont/12748-Fremont-Kindercare/#Reviews",
            "collegeSuccess": "/california/fremont/12748-Fremont-Kindercare/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 12910,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.556389,
        "lon": -122.010155,
        "name": "Montessori School-Centerville",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4209 Baine Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/12910-Montessori-School-Centerville/",
            "reviews": "/california/fremont/12910-Montessori-School-Centerville/#Reviews",
            "collegeSuccess": "/california/fremont/12910-Montessori-School-Centerville/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 10120,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.53233,
        "lon": -121.962685,
        "name": "Seneca Family Of Agencies - Pathfinder Academy",
        "gradeLevels": "5-12",
        "assigned": null,
        "address": {
            "street1": "40950 Chapel Way",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/10120-Seneca-Family-Of-Agencies---Pathfinder-Academy/",
            "reviews": "/california/fremont/10120-Seneca-Family-Of-Agencies---Pathfinder-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/10120-Seneca-Family-Of-Agencies---Pathfinder-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 45,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 10293,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.533962,
        "lon": -121.921539,
        "name": "Montessori School Of Fremont",
        "gradeLevels": "PK-6",
        "assigned": null,
        "address": {
            "street1": "155 Washington Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/10293-Montessori-School-Of-Fremont/",
            "reviews": "/california/fremont/10293-Montessori-School-Of-Fremont/#Reviews",
            "collegeSuccess": "/california/fremont/10293-Montessori-School-Of-Fremont/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 233,
        "parentRating": 3,
        "numReviews": 22,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 86
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "Asian",
                "percentage": 3
            }
        ],
        "remediationData": []
    },
    {
        "id": 11310,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.548752,
        "lon": -121.941124,
        "name": "Mission Hills Christian School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "225 Driscoll Road",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/11310-Mission-Hills-Christian-School/",
            "reviews": "/california/fremont/11310-Mission-Hills-Christian-School/#Reviews",
            "collegeSuccess": "/california/fremont/11310-Mission-Hills-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 11359,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.565399,
        "lon": -122.020081,
        "name": "Bethel Christian Academy",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "36060 Fremont Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/11359-Bethel-Christian-Academy/",
            "reviews": "/california/fremont/11359-Bethel-Christian-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/11359-Bethel-Christian-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 45,
        "parentRating": 5,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 58
            },
            {
                "label": "Hispanic",
                "percentage": 17
            },
            {
                "label": "African American",
                "percentage": 15
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 4
            }
        ],
        "remediationData": []
    },
    {
        "id": 12629,
        "districtId": 1064,
        "districtName": "California School For The Blind (State Special Schl) School District",
        "districtCity": "Fremont",
        "levelCode": "e,m,h",
        "lat": 37.562576,
        "lon": -121.96563,
        "name": "California School For The Blind",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "500 Walnut Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/12629-California-School-For-The-Blind/",
            "reviews": "/california/fremont/12629-California-School-For-The-Blind/#Reviews",
            "collegeSuccess": "/california/fremont/12629-California-School-For-The-Blind/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "100%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 66,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 100
            },
            {
                "label": "Hispanic",
                "percentage": 44
            },
            {
                "label": "White",
                "percentage": 21
            },
            {
                "label": "Asian",
                "percentage": 15
            },
            {
                "label": "African American",
                "percentage": 12
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 13580,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.528767,
        "lon": -121.919357,
        "name": "Montessori Children's Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "43551 Mission Boulevard #109",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/13580-Montessori-Childrens-Center/",
            "reviews": "/california/fremont/13580-Montessori-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/fremont/13580-Montessori-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 2,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 14027,
        "districtId": 8,
        "districtName": "Fremont Unified School District",
        "districtCity": "Fremont",
        "levelCode": "e,m,h",
        "lat": 37.538033,
        "lon": -121.990837,
        "name": "Fremont Adult",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "4700 Calaveras Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/14027-Fremont-Adult/",
            "reviews": "/california/fremont/14027-Fremont-Adult/#Reviews",
            "collegeSuccess": "/california/fremont/14027-Fremont-Adult/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 15031,
        "districtId": 1229,
        "districtName": "Mission Valley Roc P School District",
        "districtCity": "Fremont",
        "levelCode": "h",
        "lat": 37.527996,
        "lon": -121.983467,
        "name": "Mission Valley Rocp School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "5019 Stevenson Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/15031-Mission-Valley-Rocp-School/",
            "reviews": "/california/fremont/15031-Mission-Valley-Rocp-School/#Reviews",
            "collegeSuccess": "/california/fremont/15031-Mission-Valley-Rocp-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 15460,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.514843,
        "lon": -121.913177,
        "name": "Montessori School Of Fremont Childrens House",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "Po Box 3296",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/15460-Montessori-School-Of-Fremont-Childrens-House/",
            "reviews": "/california/fremont/15460-Montessori-School-Of-Fremont-Childrens-House/#Reviews",
            "collegeSuccess": "/california/fremont/15460-Montessori-School-Of-Fremont-Childrens-House/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 29,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 60
            },
            {
                "label": "Asian",
                "percentage": 20
            },
            {
                "label": "Two or more races",
                "percentage": 20
            }
        ],
        "remediationData": []
    },
    {
        "id": 15519,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.561169,
        "lon": -121.992584,
        "name": "New Horizons School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "2550 Peralta Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/15519-New-Horizons-School/",
            "reviews": "/california/fremont/15519-New-Horizons-School/#Reviews",
            "collegeSuccess": "/california/fremont/15519-New-Horizons-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 223,
        "parentRating": 5,
        "numReviews": 24,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 84
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Asian",
                "percentage": 7
            },
            {
                "label": "Hispanic",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 15561,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.566601,
        "lon": -121.962898,
        "name": "Monarch Christian Preschool",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "38895 Mission Blvd",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/15561-Monarch-Christian-Preschool/",
            "reviews": "/california/fremont/15561-Monarch-Christian-Preschool/#Reviews",
            "collegeSuccess": "/california/fremont/15561-Monarch-Christian-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 32275,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.530621,
        "lon": -121.920723,
        "name": "Safari Kid Mission",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "43468 Ellsworth St.",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/32275-Safari-Kid-Mission/",
            "reviews": "/california/fremont/32275-Safari-Kid-Mission/#Reviews",
            "collegeSuccess": "/california/fremont/32275-Safari-Kid-Mission/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 17745,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.531277,
        "lon": -121.920532,
        "name": "Mission San Jose Children's House",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "43432 Ellsworth Street",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/17745-Mission-San-Jose-Childrens-House/",
            "reviews": "/california/fremont/17745-Mission-San-Jose-Childrens-House/#Reviews",
            "collegeSuccess": "/california/fremont/17745-Mission-San-Jose-Childrens-House/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19101,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.536346,
        "lon": -121.977028,
        "name": "Kid's Wonderland-Fremont",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4343 Stevenson Blvd",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19101-Kids-Wonderland-Fremont/",
            "reviews": "/california/fremont/19101-Kids-Wonderland-Fremont/#Reviews",
            "collegeSuccess": "/california/fremont/19101-Kids-Wonderland-Fremont/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19085,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.510567,
        "lon": -121.956497,
        "name": "Kiddie Kare Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2450 Durham Rd",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19085-Kiddie-Kare-Day-Care/",
            "reviews": "/california/fremont/19085-Kiddie-Kare-Day-Care/#Reviews",
            "collegeSuccess": "/california/fremont/19085-Kiddie-Kare-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19087,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.580399,
        "lon": -122.054306,
        "name": "Precious Time Christian Ps",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "33350 Peace Terrace",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19087-Precious-Time-Christian-Ps/",
            "reviews": "/california/fremont/19087-Precious-Time-Christian-Ps/#Reviews",
            "collegeSuccess": "/california/fremont/19087-Precious-Time-Christian-Ps/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 7,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19088,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.576283,
        "lon": -122.040695,
        "name": "Learning Tree",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "34050 Paseo Padre Parkway",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19088-Learning-Tree/",
            "reviews": "/california/fremont/19088-Learning-Tree/#Reviews",
            "collegeSuccess": "/california/fremont/19088-Learning-Tree/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 9,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19089,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.57716,
        "lon": -122.044205,
        "name": "Little Mud Puddles Lc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "34072 Fremont Boulevard",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19089-Little-Mud-Puddles-Lc/",
            "reviews": "/california/fremont/19089-Little-Mud-Puddles-Lc/#Reviews",
            "collegeSuccess": "/california/fremont/19089-Little-Mud-Puddles-Lc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 14,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19090,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.581398,
        "lon": -122.001434,
        "name": "Hacienda Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "35699 Niles Boulevard",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19090-Hacienda-Head-Start/",
            "reviews": "/california/fremont/19090-Hacienda-Head-Start/#Reviews",
            "collegeSuccess": "/california/fremont/19090-Hacienda-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19091,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.556625,
        "lon": -122.004578,
        "name": "Child's Hideaway Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "37531 Fremont Blvd",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19091-Childs-Hideaway-Day-Care/",
            "reviews": "/california/fremont/19091-Childs-Hideaway-Day-Care/#Reviews",
            "collegeSuccess": "/california/fremont/19091-Childs-Hideaway-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19092,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.558868,
        "lon": -121.994957,
        "name": "Rainbow Kids Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "38000 Camden Street",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19092-Rainbow-Kids-Child-Care-Center/",
            "reviews": "/california/fremont/19092-Rainbow-Kids-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/fremont/19092-Rainbow-Kids-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19093,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.540306,
        "lon": -122.007759,
        "name": "Fremont Cong Nursery School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "38255 Blacow Road",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19093-Fremont-Cong-Nursery-School/",
            "reviews": "/california/fremont/19093-Fremont-Cong-Nursery-School/#Reviews",
            "collegeSuccess": "/california/fremont/19093-Fremont-Cong-Nursery-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19094,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.55172,
        "lon": -121.989807,
        "name": "Scribbles Montessori School",
        "gradeLevels": "K-3",
        "assigned": null,
        "address": {
            "street1": "38660 Lexington Street",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19094-Scribbles-Montessori-School/",
            "reviews": "/california/fremont/19094-Scribbles-Montessori-School/#Reviews",
            "collegeSuccess": "/california/fremont/19094-Scribbles-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 39,
        "parentRating": 4,
        "numReviews": 15,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 65
            },
            {
                "label": "White",
                "percentage": 20
            },
            {
                "label": "Hispanic",
                "percentage": 8
            },
            {
                "label": "African American",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            }
        ],
        "remediationData": []
    },
    {
        "id": 19095,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.557259,
        "lon": -122.004333,
        "name": "Holy Spirit Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3930 Parish Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19095-Holy-Spirit-Pre-School/",
            "reviews": "/california/fremont/19095-Holy-Spirit-Pre-School/#Reviews",
            "collegeSuccess": "/california/fremont/19095-Holy-Spirit-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19096,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.533585,
        "lon": -121.987221,
        "name": "South Alameda County Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "39600 Sundale Dr",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19096-South-Alameda-County-Head-Start/",
            "reviews": "/california/fremont/19096-South-Alameda-County-Head-Start/#Reviews",
            "collegeSuccess": "/california/fremont/19096-South-Alameda-County-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19097,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.533329,
        "lon": -121.987114,
        "name": "Simon's Pre-School & Day Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "39614 Sundale Dr",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19097-Simons-Pre-School--Day-Care-Center/",
            "reviews": "/california/fremont/19097-Simons-Pre-School--Day-Care-Center/#Reviews",
            "collegeSuccess": "/california/fremont/19097-Simons-Pre-School--Day-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19098,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.527439,
        "lon": -121.98288,
        "name": "Creative Life School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "40155 Blacow Rd",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19098-Creative-Life-School/",
            "reviews": "/california/fremont/19098-Creative-Life-School/#Reviews",
            "collegeSuccess": "/california/fremont/19098-Creative-Life-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19099,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.549999,
        "lon": -121.970001,
        "name": "Fremont Parents Nursery School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "Po Box 7171",
            "street2": "",
            "zip": "94537",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94537",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19099-Fremont-Parents-Nursery-School/",
            "reviews": "/california/fremont/19099-Fremont-Parents-Nursery-School/#Reviews",
            "collegeSuccess": "/california/fremont/19099-Fremont-Parents-Nursery-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19100,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.504227,
        "lon": -121.964378,
        "name": "Gan Sameach Dc/ Nursery School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "Po Box 6017",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19100-Gan-Sameach-Dc--Nursery-School/",
            "reviews": "/california/fremont/19100-Gan-Sameach-Dc--Nursery-School/#Reviews",
            "collegeSuccess": "/california/fremont/19100-Gan-Sameach-Dc--Nursery-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 7,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19102,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.552254,
        "lon": -122.006744,
        "name": "Centerville Church Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4360 Central Avenue",
            "street2": "",
            "zip": "94536",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94536",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19102-Centerville-Church-Pre-School/",
            "reviews": "/california/fremont/19102-Centerville-Church-Pre-School/#Reviews",
            "collegeSuccess": "/california/fremont/19102-Centerville-Church-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19104,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.496704,
        "lon": -121.919266,
        "name": "123 Learning Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "46280 Briar Place",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19104-123-Learning-Center/",
            "reviews": "/california/fremont/19104-123-Learning-Center/#Reviews",
            "collegeSuccess": "/california/fremont/19104-123-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19105,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.480465,
        "lon": -121.940727,
        "name": "Planet Avant Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "46885 Bayside Pkwy",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19105-Planet-Avant-Day-Care/",
            "reviews": "/california/fremont/19105-Planet-Avant-Day-Care/#Reviews",
            "collegeSuccess": "/california/fremont/19105-Planet-Avant-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19106,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.538025,
        "lon": -121.992477,
        "name": "Kindercare Learning Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4727 Calaveras Avenue",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19106-Kindercare-Learning-Center/",
            "reviews": "/california/fremont/19106-Kindercare-Learning-Center/#Reviews",
            "collegeSuccess": "/california/fremont/19106-Kindercare-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 24,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 50
            },
            {
                "label": "Asian",
                "percentage": 25
            },
            {
                "label": "African American",
                "percentage": 25
            }
        ],
        "remediationData": []
    },
    {
        "id": 19107,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.479607,
        "lon": -121.920013,
        "name": "Sunshine Kids Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "239 Gable Drive",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19107-Sunshine-Kids-Pre-School/",
            "reviews": "/california/fremont/19107-Sunshine-Kids-Pre-School/#Reviews",
            "collegeSuccess": "/california/fremont/19107-Sunshine-Kids-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19108,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.548222,
        "lon": -121.942505,
        "name": "Little Lamb Pre-School & Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "505 Driscoll Road",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19108-Little-Lamb-Pre-School--Day-Care/",
            "reviews": "/california/fremont/19108-Little-Lamb-Pre-School--Day-Care/#Reviews",
            "collegeSuccess": "/california/fremont/19108-Little-Lamb-Pre-School--Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19109,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango-Delane Eastin Cdc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19109-Kidango-Delane-Eastin-Cdc/",
            "reviews": "/california/fremont/19109-Kidango-Delane-Eastin-Cdc/#Reviews",
            "collegeSuccess": "/california/fremont/19109-Kidango-Delane-Eastin-Cdc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19110,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.530991,
        "lon": -121.930939,
        "name": "Our Savior Lutheran Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "858 Washington Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19110-Our-Savior-Lutheran-Pre-School/",
            "reviews": "/california/fremont/19110-Our-Savior-Lutheran-Pre-School/#Reviews",
            "collegeSuccess": "/california/fremont/19110-Our-Savior-Lutheran-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 19855,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.509449,
        "lon": -121.948944,
        "name": "Kidango-Pepper Tree School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "44000 Old Warm Springs Boulevard",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/19855-Kidango-Pepper-Tree-School/",
            "reviews": "/california/fremont/19855-Kidango-Pepper-Tree-School/#Reviews",
            "collegeSuccess": "/california/fremont/19855-Kidango-Pepper-Tree-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 20409,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.529694,
        "lon": -121.919479,
        "name": "Bayside Montessori Child Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "43521 Mission Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/20409-Bayside-Montessori-Child-Center/",
            "reviews": "/california/fremont/20409-Bayside-Montessori-Child-Center/#Reviews",
            "collegeSuccess": "/california/fremont/20409-Bayside-Montessori-Child-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 20746,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.528767,
        "lon": -121.919357,
        "name": "Montessori School-Newark",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "43551 Mission Boulevard #109",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/20746-Montessori-School-Newark/",
            "reviews": "/california/fremont/20746-Montessori-School-Newark/#Reviews",
            "collegeSuccess": "/california/fremont/20746-Montessori-School-Newark/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 22513,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.586361,
        "lon": -122.043556,
        "name": "Our Future Tots",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3096 Turnstone Lane",
            "street2": "",
            "zip": "94555",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94555",
        "type": "school",
        "links": {
            "profile": "/california/fremont/22513-Our-Future-Tots/",
            "reviews": "/california/fremont/22513-Our-Future-Tots/#Reviews",
            "collegeSuccess": "/california/fremont/22513-Our-Future-Tots/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 23783,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.482101,
        "lon": -121.920334,
        "name": "Mills Academy",
        "gradeLevels": "2-12",
        "assigned": null,
        "address": {
            "street1": "323 Lippert Avenue",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/23783-Mills-Academy/",
            "reviews": "/california/fremont/23783-Mills-Academy/#Reviews",
            "collegeSuccess": "/california/fremont/23783-Mills-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 24142,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.532295,
        "lon": -121.913795,
        "name": "Alsion Montessori Middle / High School",
        "gradeLevels": "7-12",
        "assigned": null,
        "address": {
            "street1": "750 Witherly Lane",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/24142-Alsion-Montessori-Middle---High-School/",
            "reviews": "/california/fremont/24142-Alsion-Montessori-Middle---High-School/#Reviews",
            "collegeSuccess": "/california/fremont/24142-Alsion-Montessori-Middle---High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 59,
        "parentRating": 4,
        "numReviews": 53,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 49
            },
            {
                "label": "White",
                "percentage": 23
            },
            {
                "label": "Two or more races",
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "percentage": 7
            }
        ],
        "remediationData": []
    },
    {
        "id": 24384,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.560783,
        "lon": -121.957016,
        "name": "Mission Valley Montessori Children's Learning Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "39600 Mission Boulevard",
            "street2": "",
            "zip": "94539",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94539",
        "type": "school",
        "links": {
            "profile": "/california/fremont/24384-Mission-Valley-Montessori-Childrens-Learning-Center/",
            "reviews": "/california/fremont/24384-Mission-Valley-Montessori-Childrens-Learning-Center/#Reviews",
            "collegeSuccess": "/california/fremont/24384-Mission-Valley-Montessori-Childrens-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 190,
        "parentRating": 3,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 92
            },
            {
                "label": "White",
                "percentage": 8
            }
        ],
        "remediationData": []
    },
    {
        "id": 24476,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.53233,
        "lon": -121.962685,
        "name": "Seneca Center",
        "gradeLevels": "6-12",
        "assigned": null,
        "address": {
            "street1": "40950 Chapel Way",
            "street2": "",
            "zip": "94538",
            "city": "Fremont"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94538",
        "type": "school",
        "links": {
            "profile": "/california/fremont/24476-Seneca-Center/",
            "reviews": "/california/fremont/24476-Seneca-Center/#Reviews",
            "collegeSuccess": "/california/fremont/24476-Seneca-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 21,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "African American",
                "percentage": 38
            },
            {
                "label": "White",
                "percentage": 33
            },
            {
                "label": "Asian",
                "percentage": 10
            },
            {
                "label": "Hispanic",
                "percentage": 10
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 5
            }
        ],
        "remediationData": []
    }
]