const saratoga = {
    "geography": {
       "census_release": "ACS 2019 5-year",
       "parents": {
          "county": {
             "full_name": "Santa Clara County, CA",
             "short_name": "Santa Clara County",
             "sumlevel": "050",
             "land_area": 3343890934,
             "full_geoid": "05000US06085",
             "total_population": 1927470
          },
          "CBSA": {
             "full_name": "San Jose-Sunnyvale-Santa Clara, CA Metro Area",
             "short_name": "San Jose-Sunnyvale-Santa Clara, CA",
             "sumlevel": "310",
             "land_area": 6940482016,
             "full_geoid": "31000US41940",
             "total_population": 1987846
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39283497
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 324697795
          }
       },
       "this": {
          "full_name": "Saratoga, CA",
          "short_name": "Saratoga",
          "sumlevel": "160",
          "land_area": 33067011,
          "full_geoid": "16000US0670280",
          "total_population": 30697,
          "sumlevel_name": "Place",
          "short_geoid": "0670280"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "5"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 20.59,
                   "CBSA": 22.34,
                   "state": 22.97
                },
                "error": {
                   "this": 1.62,
                   "CBSA": 0.12,
                   "state": 0.03
                },
                "numerators": {
                   "this": 6319,
                   "CBSA": 444060,
                   "state": 9022146
                },
                "numerator_errors": {
                   "this": 498.7,
                   "CBSA": 2398.4,
                   "state": 12886.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 90
                },
                "error_ratio": {
                   "this": 7.9,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 56.3,
                   "CBSA": 64.5,
                   "state": 63.07
                },
                "error": {
                   "this": 2.68,
                   "CBSA": 0.22,
                   "state": 0.05
                },
                "numerators": {
                   "this": 17283,
                   "CBSA": 1282083,
                   "state": 2.477531E7
                },
                "numerator_errors": {
                   "this": 824.6,
                   "CBSA": 4353.9,
                   "state": 20108.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 89
                },
                "error_ratio": {
                   "this": 4.8,
                   "CBSA": 0.3,
                   "state": 0.1
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 23.11,
                   "CBSA": 13.17,
                   "state": 13.97
                },
                "error": {
                   "this": 1.68,
                   "CBSA": 0.12,
                   "state": 0.03
                },
                "numerators": {
                   "this": 7095,
                   "CBSA": 261703,
                   "state": 5486041
                },
                "numerator_errors": {
                   "this": 515,
                   "CBSA": 2466.5,
                   "state": 12678.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 175,
                   "state": 165
                },
                "error_ratio": {
                   "this": 7.3,
                   "CBSA": 0.9,
                   "state": 0.2
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 7.66,
                      "CBSA": 12.17,
                      "state": 12.52
                   },
                   "error": {
                      "this": 1.01,
                      "CBSA": 0.09,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 2350,
                      "CBSA": 241951,
                      "state": 4919754
                   },
                   "numerator_errors": {
                      "this": 311.5,
                      "CBSA": 1695.1,
                      "state": 9132.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 63,
                      "state": 61
                   },
                   "error_ratio": {
                      "this": 13.2,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 14.22,
                      "CBSA": 12.46,
                      "state": 13.09
                   },
                   "error": {
                      "this": 1.35,
                      "CBSA": 0.09,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 4364,
                      "CBSA": 247684,
                      "state": 5140633
                   },
                   "numerator_errors": {
                      "this": 413.8,
                      "CBSA": 1701.3,
                      "state": 9167.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 114,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 9.5,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.56,
                      "CBSA": 14.22,
                      "state": 14.84
                   },
                   "error": {
                      "this": 1.05,
                      "CBSA": 0.11,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 2015,
                      "CBSA": 282618,
                      "state": 5830059
                   },
                   "numerator_errors": {
                      "this": 321.4,
                      "CBSA": 2161.1,
                      "state": 10871.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 46,
                      "state": 44
                   },
                   "error_ratio": {
                      "this": 16,
                      "CBSA": 0.8,
                      "state": 0.2
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 6.52,
                      "CBSA": 15.4,
                      "state": 14.18
                   },
                   "error": {
                      "this": 0.98,
                      "CBSA": 0.1,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 2001,
                      "CBSA": 306040,
                      "state": 5570748
                   },
                   "numerator_errors": {
                      "this": 299.5,
                      "CBSA": 2049.5,
                      "state": 8798.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 42,
                      "state": 46
                   },
                   "error_ratio": {
                      "this": 15,
                      "CBSA": 0.6,
                      "state": 0.1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.51,
                      "CBSA": 14.14,
                      "state": 12.95
                   },
                   "error": {
                      "this": 1.21,
                      "CBSA": 0.1,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 4453,
                      "CBSA": 281103,
                      "state": 5088145
                   },
                   "numerator_errors": {
                      "this": 371.9,
                      "CBSA": 2043.9,
                      "state": 8746.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 112
                   },
                   "error_ratio": {
                      "this": 8.3,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 19.68,
                      "CBSA": 13.05,
                      "state": 12.76
                   },
                   "error": {
                      "this": 1.56,
                      "CBSA": 0.08,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 6042,
                      "CBSA": 259378,
                      "state": 5011016
                   },
                   "numerator_errors": {
                      "this": 478.5,
                      "CBSA": 1613,
                      "state": 6867
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 151,
                      "state": 154
                   },
                   "error_ratio": {
                      "this": 7.9,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 14.47,
                      "CBSA": 9.62,
                      "state": 10.34
                   },
                   "error": {
                      "this": 1.38,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 4443,
                      "CBSA": 191214,
                      "state": 4062419
                   },
                   "numerator_errors": {
                      "this": 424.7,
                      "CBSA": 2352.5,
                      "state": 11748.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 150,
                      "state": 140
                   },
                   "error_ratio": {
                      "this": 9.5,
                      "CBSA": 1.2,
                      "state": 0.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 8.77,
                      "CBSA": 5.51,
                      "state": 5.82
                   },
                   "error": {
                      "this": 1.01,
                      "CBSA": 0.08,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 2691,
                      "CBSA": 109560,
                      "state": 2285972
                   },
                   "numerator_errors": {
                      "this": 308.8,
                      "CBSA": 1543.2,
                      "state": 7838.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 159,
                      "state": 151
                   },
                   "error_ratio": {
                      "this": 11.5,
                      "CBSA": 1.5,
                      "state": 0.3
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 7.62,
                      "CBSA": 3.44,
                      "state": 3.5
                   },
                   "error": {
                      "this": 0.98,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 2338,
                      "CBSA": 68298,
                      "state": 1374751
                   },
                   "numerator_errors": {
                      "this": 299.4,
                      "CBSA": 1207.9,
                      "state": 6707.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 222,
                      "state": 218
                   },
                   "error_ratio": {
                      "this": 12.9,
                      "CBSA": 1.7,
                      "state": 0.6
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 10.01,
                      "CBSA": 12.39,
                      "state": 12.87
                   },
                   "error": {
                      "this": 1.74,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1524,
                      "CBSA": 124430,
                      "state": 2512581
                   },
                   "numerator_errors": {
                      "this": 267.8,
                      "CBSA": 1182.8,
                      "state": 6146.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 81,
                      "state": 78
                   },
                   "error_ratio": {
                      "this": 17.4,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 15.02,
                      "CBSA": 12.58,
                      "state": 13.46
                   },
                   "error": {
                      "this": 2.02,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 2285,
                      "CBSA": 126419,
                      "state": 2628152
                   },
                   "numerator_errors": {
                      "this": 314.3,
                      "CBSA": 1188.6,
                      "state": 6239.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 119,
                      "state": 112
                   },
                   "error_ratio": {
                      "this": 13.4,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.1,
                      "CBSA": 15.01,
                      "state": 15.42
                   },
                   "error": {
                      "this": 1.29,
                      "CBSA": 0.16,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 928,
                      "CBSA": 150743,
                      "state": 3011788
                   },
                   "numerator_errors": {
                      "this": 197.6,
                      "CBSA": 1615.1,
                      "state": 8042.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 41,
                      "state": 40
                   },
                   "error_ratio": {
                      "this": 21.1,
                      "CBSA": 1.1,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 6.31,
                      "CBSA": 15.96,
                      "state": 14.57
                   },
                   "error": {
                      "this": 1.32,
                      "CBSA": 0.16,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 960,
                      "CBSA": 160356,
                      "state": 2844329
                   },
                   "numerator_errors": {
                      "this": 202.6,
                      "CBSA": 1611.2,
                      "state": 6637.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 40,
                      "state": 43
                   },
                   "error_ratio": {
                      "this": 20.9,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.42,
                      "CBSA": 14.13,
                      "state": 13.02
                   },
                   "error": {
                      "this": 1.73,
                      "CBSA": 0.16,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 2042,
                      "CBSA": 141926,
                      "state": 2541977
                   },
                   "numerator_errors": {
                      "this": 269.4,
                      "CBSA": 1605.7,
                      "state": 6536.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 95,
                      "state": 103
                   },
                   "error_ratio": {
                      "this": 12.9,
                      "CBSA": 1.1,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 19.77,
                      "CBSA": 13.01,
                      "state": 12.65
                   },
                   "error": {
                      "this": 2.08,
                      "CBSA": 0.1,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3008,
                      "CBSA": 130685,
                      "state": 2470900
                   },
                   "numerator_errors": {
                      "this": 328.3,
                      "CBSA": 1033,
                      "state": 4877.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 152,
                      "state": 156
                   },
                   "error_ratio": {
                      "this": 10.5,
                      "CBSA": 0.8,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 13.58,
                      "CBSA": 9.28,
                      "state": 9.92
                   },
                   "error": {
                      "this": 1.89,
                      "CBSA": 0.17,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 2067,
                      "CBSA": 93182,
                      "state": 1937575
                   },
                   "numerator_errors": {
                      "this": 293.6,
                      "CBSA": 1660,
                      "state": 8018.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 146,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 13.9,
                      "CBSA": 1.8,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 9.05,
                      "CBSA": 4.96,
                      "state": 5.32
                   },
                   "error": {
                      "this": 1.41,
                      "CBSA": 0.1,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1377,
                      "CBSA": 49877,
                      "state": 1038517
                   },
                   "numerator_errors": {
                      "this": 217.6,
                      "CBSA": 993.8,
                      "state": 5256.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 182,
                      "state": 170
                   },
                   "error_ratio": {
                      "this": 15.6,
                      "CBSA": 2,
                      "state": 0.6
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 6.75,
                      "CBSA": 2.68,
                      "state": 2.77
                   },
                   "error": {
                      "this": 1.26,
                      "CBSA": 0.07,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1027,
                      "CBSA": 26955,
                      "state": 540479
                   },
                   "numerator_errors": {
                      "this": 194.6,
                      "CBSA": 748.1,
                      "state": 4503.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 252,
                      "state": 244
                   },
                   "error_ratio": {
                      "this": 18.7,
                      "CBSA": 2.6,
                      "state": 0.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 5.34,
                      "CBSA": 11.95,
                      "state": 12.18
                   },
                   "error": {
                      "this": 1.02,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 826,
                      "CBSA": 117521,
                      "state": 2407173
                   },
                   "numerator_errors": {
                      "this": 159,
                      "CBSA": 1214.2,
                      "state": 6754.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 45,
                      "state": 44
                   },
                   "error_ratio": {
                      "this": 19.1,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 13.43,
                      "CBSA": 12.33,
                      "state": 12.72
                   },
                   "error": {
                      "this": 1.7,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 2079,
                      "CBSA": 121265,
                      "state": 2512481
                   },
                   "numerator_errors": {
                      "this": 269.2,
                      "CBSA": 1217.2,
                      "state": 6716.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 109,
                      "state": 106
                   },
                   "error_ratio": {
                      "this": 12.7,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 7.02,
                      "CBSA": 13.41,
                      "state": 14.26
                   },
                   "error": {
                      "this": 1.63,
                      "CBSA": 0.15,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 1087,
                      "CBSA": 131875,
                      "state": 2818271
                   },
                   "numerator_errors": {
                      "this": 253.4,
                      "CBSA": 1436,
                      "state": 7314.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 52,
                      "state": 49
                   },
                   "error_ratio": {
                      "this": 23.2,
                      "CBSA": 1.1,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 6.73,
                      "CBSA": 14.82,
                      "state": 13.8
                   },
                   "error": {
                      "this": 1.41,
                      "CBSA": 0.13,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1041,
                      "CBSA": 145684,
                      "state": 2726419
                   },
                   "numerator_errors": {
                      "this": 220.6,
                      "CBSA": 1266.6,
                      "state": 5775.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 45,
                      "state": 49
                   },
                   "error_ratio": {
                      "this": 21,
                      "CBSA": 0.9,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 15.58,
                      "CBSA": 14.15,
                      "state": 12.89
                   },
                   "error": {
                      "this": 1.6,
                      "CBSA": 0.13,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 2411,
                      "CBSA": 139177,
                      "state": 2546168
                   },
                   "numerator_errors": {
                      "this": 256.4,
                      "CBSA": 1264.5,
                      "state": 5811.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 110,
                      "state": 121
                   },
                   "error_ratio": {
                      "this": 10.3,
                      "CBSA": 0.9,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 19.6,
                      "CBSA": 13.09,
                      "state": 12.86
                   },
                   "error": {
                      "this": 2.18,
                      "CBSA": 0.13,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3034,
                      "CBSA": 128693,
                      "state": 2540116
                   },
                   "numerator_errors": {
                      "this": 348.1,
                      "CBSA": 1238.8,
                      "state": 4833.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 150,
                      "state": 152
                   },
                   "error_ratio": {
                      "this": 11.1,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 15.35,
                      "CBSA": 9.97,
                      "state": 10.75
                   },
                   "error": {
                      "this": 1.94,
                      "CBSA": 0.17,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 2376,
                      "CBSA": 98032,
                      "state": 2124844
                   },
                   "numerator_errors": {
                      "this": 306.8,
                      "CBSA": 1666.9,
                      "state": 8586.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 154,
                      "state": 143
                   },
                   "error_ratio": {
                      "this": 12.6,
                      "CBSA": 1.7,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 8.49,
                      "CBSA": 6.07,
                      "state": 6.31
                   },
                   "error": {
                      "this": 1.4,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1314,
                      "CBSA": 59683,
                      "state": 1247455
                   },
                   "numerator_errors": {
                      "this": 219.1,
                      "CBSA": 1180.6,
                      "state": 5815.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 140,
                      "state": 135
                   },
                   "error_ratio": {
                      "this": 16.5,
                      "CBSA": 2,
                      "state": 0.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 8.47,
                      "CBSA": 4.2,
                      "state": 4.22
                   },
                   "error": {
                      "this": 1.45,
                      "CBSA": 0.1,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1311,
                      "CBSA": 41343,
                      "state": 834272
                   },
                   "numerator_errors": {
                      "this": 227.6,
                      "CBSA": 948.3,
                      "state": 4970.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 202,
                      "state": 201
                   },
                   "error_ratio": {
                      "this": 17.1,
                      "CBSA": 2.4,
                      "state": 0.7
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 50.2,
                   "CBSA": 37.1,
                   "state": 36.5
                },
                "error": {
                   "this": 0.8,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 135,
                   "state": 138
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 49.5,
                   "CBSA": 36,
                   "state": 35.4
                },
                "error": {
                   "this": 1.2,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 138,
                   "state": 140
                },
                "error_ratio": {
                   "this": 2.4,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 50.6,
                   "CBSA": 38.2,
                   "state": 37.7
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 132,
                   "state": 134
                },
                "error_ratio": {
                   "this": 1.4,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 49.57,
                "CBSA": 50.54,
                "state": 49.71
             },
             "error": {
                "this": 1.4,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 15218,
                "CBSA": 1004573,
                "state": 1.9526298E7
             },
             "numerator_errors": {
                "this": 429,
                "CBSA": 251,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 100
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": null,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 50.43,
                "CBSA": 49.46,
                "state": 50.29
             },
             "error": {
                "this": 1.39,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 15479,
                "CBSA": 983273,
                "state": 1.97572E7
             },
             "numerator_errors": {
                "this": 427,
                "CBSA": 251,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 100
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": null,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 44.84,
                "CBSA": 31.62,
                "state": 37.18
             },
             "error": {
                "this": 2.03,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 13765,
                "CBSA": 628606,
                "state": 1.4605312E7
             },
             "numerator_errors": {
                "this": 624,
                "CBSA": 844,
                "state": 3168
             },
             "index": {
                "this": 100,
                "CBSA": 142,
                "state": 121
             },
             "error_ratio": {
                "this": 4.5,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 0.46,
                "CBSA": 2.3,
                "state": 5.52
             },
             "error": {
                "this": 0.32,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 140,
                "CBSA": 45713,
                "state": 2169155
             },
             "numerator_errors": {
                "this": 97,
                "CBSA": 926,
                "state": 6936
             },
             "index": {
                "this": 100,
                "CBSA": 20,
                "state": 8
             },
             "error_ratio": {
                "this": 69.6,
                "CBSA": 2.2,
                "state": 0.4
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.4,
                "CBSA": 0.18,
                "state": 0.36
             },
             "error": {
                "this": 0.38,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 123,
                "CBSA": 3552,
                "state": 140831
             },
             "numerator_errors": {
                "this": 117,
                "CBSA": 409,
                "state": 2516
             },
             "index": {
                "this": 100,
                "CBSA": 222,
                "state": 111
             },
             "error_ratio": {
                "this": 95,
                "CBSA": 11.1,
                "state": 2.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 47.66,
                "CBSA": 35.27,
                "state": 14.28
             },
             "error": {
                "this": 2.11,
                "CBSA": 0.1,
                "state": 0.02
             },
             "numerators": {
                "this": 14629,
                "CBSA": 701044,
                "state": 5610931
             },
             "numerator_errors": {
                "this": 647,
                "CBSA": 2065,
                "state": 9199
             },
             "index": {
                "this": 100,
                "CBSA": 135,
                "state": 334
             },
             "error_ratio": {
                "this": 4.4,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0,
                "CBSA": 0.32,
                "state": 0.36
             },
             "error": {
                "this": 0,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 0,
                "CBSA": 6265,
                "state": 140788
             },
             "numerator_errors": {
                "this": 26,
                "CBSA": 499,
                "state": 2224
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 9.4,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.2,
                "CBSA": 0.28,
                "state": 0.25
             },
             "error": {
                "this": 0.19,
                "CBSA": 0.05,
                "state": 0.01
             },
             "numerators": {
                "this": 60,
                "CBSA": 5606,
                "state": 100119
             },
             "numerator_errors": {
                "this": 57,
                "CBSA": 920,
                "state": 3715
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 80
             },
             "error_ratio": {
                "this": 95,
                "CBSA": 17.9,
                "state": 4
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 3.57,
                "CBSA": 3.52,
                "state": 3.03
             },
             "error": {
                "this": 0.95,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 1095,
                "CBSA": 70001,
                "state": 1188673
             },
             "numerator_errors": {
                "this": 291,
                "CBSA": 2135,
                "state": 14463
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 118
             },
             "error_ratio": {
                "this": 26.6,
                "CBSA": 3.1,
                "state": 1.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 2.88,
                "CBSA": 26.51,
                "state": 39.02
             },
             "error": {
                "this": 0.81,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 885,
                "CBSA": 527059,
                "state": 1.5327688E7
             },
             "numerator_errors": {
                "this": 250,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 11,
                "state": 7
             },
             "error_ratio": {
                "this": 28.1,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 93627,
                "CBSA": 55547,
                "state": 36955
             },
             "error": {
                "this": 4754,
                "CBSA": 380,
                "state": 129
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 169,
                "state": 253
             },
             "error_ratio": {
                "this": 5.1,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 191677,
                "CBSA": 122478,
                "state": 75235
             },
             "error": {
                "this": 14173,
                "CBSA": 1085,
                "state": 232
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 156,
                "state": 255
             },
             "error_ratio": {
                "this": 7.4,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 13.86,
                   "CBSA": 20.8,
                   "state": 34.35
                },
                "error": {
                   "this": 2.33,
                   "CBSA": 0.38,
                   "state": 0.1
                },
                "numerators": {
                   "this": 1526,
                   "CBSA": 136936,
                   "state": 4481083
                },
                "numerator_errors": {
                   "this": 259.1,
                   "CBSA": 2507.3,
                   "state": 15330.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 67,
                   "state": 40
                },
                "error_ratio": {
                   "this": 16.8,
                   "CBSA": 1.8,
                   "state": 0.3
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 13.66,
                   "CBSA": 20.81,
                   "state": 27.93
                },
                "error": {
                   "this": 2.26,
                   "CBSA": 0.34,
                   "state": 0.09
                },
                "numerators": {
                   "this": 1504,
                   "CBSA": 136978,
                   "state": 3643284
                },
                "numerator_errors": {
                   "this": 251.1,
                   "CBSA": 2294.6,
                   "state": 12935.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 49
                },
                "error_ratio": {
                   "this": 16.5,
                   "CBSA": 1.6,
                   "state": 0.3
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 24.71,
                   "CBSA": 30.92,
                   "state": 25.56
                },
                "error": {
                   "this": 2.8,
                   "CBSA": 0.4,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2721,
                   "CBSA": 203545,
                   "state": 3333848
                },
                "numerator_errors": {
                   "this": 315.1,
                   "CBSA": 2677.2,
                   "state": 14018.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 97
                },
                "error_ratio": {
                   "this": 11.3,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 47.78,
                   "CBSA": 27.48,
                   "state": 12.16
                },
                "error": {
                   "this": 3.02,
                   "CBSA": 0.35,
                   "state": 0.08
                },
                "numerators": {
                   "this": 5262,
                   "CBSA": 180891,
                   "state": 1586051
                },
                "numerator_errors": {
                   "this": 354,
                   "CBSA": 2316,
                   "state": 10523
                },
                "index": {
                   "this": 100,
                   "CBSA": 174,
                   "state": 393
                },
                "error_ratio": {
                   "this": 6.3,
                   "CBSA": 1.3,
                   "state": 0.7
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 4.67,
                "CBSA": 7.54,
                "state": 13.36
             },
             "error": {
                "this": 1.43,
                "CBSA": 0.23,
                "state": 0.1
             },
             "numerators": {
                "this": 1426,
                "CBSA": 147595,
                "state": 5149742
             },
             "numerator_errors": {
                "this": 438,
                "CBSA": 4438,
                "state": 38666
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 62,
                "state": 35
             },
             "error_ratio": {
                "this": 30.6,
                "CBSA": 3.1,
                "state": 0.7
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 4.27,
                   "CBSA": 7.92,
                   "state": 18.13
                },
                "error": {
                   "this": 1.96,
                   "CBSA": 0.35,
                   "state": 0.12
                },
                "numerators": {
                   "this": 270,
                   "CBSA": 34773,
                   "state": 1610923
                },
                "numerator_errors": {
                   "this": 125.9,
                   "CBSA": 1570.3,
                   "state": 10973.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 24
                },
                "error_ratio": {
                   "this": 45.9,
                   "CBSA": 4.4,
                   "state": 0.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 95.73,
                   "CBSA": 92.08,
                   "state": 81.87
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 0.96,
                   "state": 0
                },
                "numerators": {
                   "this": 6049,
                   "CBSA": 404295,
                   "state": 7275254
                },
                "numerator_errors": {
                   "this": 511.4,
                   "CBSA": 2898.9,
                   "state": 15647.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 117
                },
                "error_ratio": {
                   "this": 1.4,
                   "CBSA": 1,
                   "state": null
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 5.85,
                   "CBSA": 8.01,
                   "state": 10.23
                },
                "error": {
                   "this": 1.67,
                   "CBSA": 0.37,
                   "state": 0.1
                },
                "numerators": {
                   "this": 406,
                   "CBSA": 20603,
                   "state": 551219
                },
                "numerator_errors": {
                   "this": 119.1,
                   "CBSA": 953.5,
                   "state": 5245
                },
                "index": {
                   "this": 100,
                   "CBSA": 73,
                   "state": 57
                },
                "error_ratio": {
                   "this": 28.5,
                   "CBSA": 4.6,
                   "state": 1
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 94.15,
                   "CBSA": 91.99,
                   "state": 89.77
                },
                "error": {
                   "this": 1.51,
                   "CBSA": 0.63,
                   "state": 0.16
                },
                "numerators": {
                   "this": 6539,
                   "CBSA": 236657,
                   "state": 4839112
                },
                "numerator_errors": {
                   "this": 456.2,
                   "CBSA": 998.3,
                   "state": 5421.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 105
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 0.7,
                   "state": 0.2
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 30.52,
                "CBSA": 29.51,
                "state": 29.79
             },
             "error": {
                "this": 1.14,
                "CBSA": 0.11,
                "state": 0.07
             },
             "numerators": {
                "this": 365765,
                "CBSA": 2.7906096E7,
                "state": 5.099704E8
             },
             "numerator_errors": {
                "this": 22233,
                "CBSA": 162628,
                "state": 1354876
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 102
             },
             "error_ratio": {
                "this": 3.7,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 80.04,
                   "CBSA": 74.88,
                   "state": 73.72
                },
                "error": {
                   "this": 2.08,
                   "CBSA": 0.37,
                   "state": 0.04
                },
                "numerators": {
                   "this": 10680,
                   "CBSA": 745233,
                   "state": 1.3411041E7
                },
                "numerator_errors": {
                   "this": 488,
                   "CBSA": 4630,
                   "state": 17348
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 109
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 6.34,
                   "CBSA": 10.63,
                   "state": 10.12
                },
                "error": {
                   "this": 1.58,
                   "CBSA": 0.27,
                   "state": 0.09
                },
                "numerators": {
                   "this": 846,
                   "CBSA": 105809,
                   "state": 1841632
                },
                "numerator_errors": {
                   "this": 213,
                   "CBSA": 2737,
                   "state": 16674
                },
                "index": {
                   "this": 100,
                   "CBSA": 60,
                   "state": 63
                },
                "error_ratio": {
                   "this": 24.9,
                   "CBSA": 2.5,
                   "state": 0.9
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 1.18,
                   "CBSA": 4.33,
                   "state": 5.08
                },
                "error": {
                   "this": 0.52,
                   "CBSA": 0.15,
                   "state": 0.04
                },
                "numerators": {
                   "this": 157,
                   "CBSA": 43142,
                   "state": 923834
                },
                "numerator_errors": {
                   "this": 70,
                   "CBSA": 1491,
                   "state": 7563
                },
                "index": {
                   "this": 100,
                   "CBSA": 27,
                   "state": 23
                },
                "error_ratio": {
                   "this": 44.1,
                   "CBSA": 3.5,
                   "state": 0.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.45,
                   "CBSA": 1.73,
                   "state": 0.95
                },
                "error": {
                   "this": 0.35,
                   "CBSA": 0.09,
                   "state": 0.02
                },
                "numerators": {
                   "this": 60,
                   "CBSA": 17241,
                   "state": 173081
                },
                "numerator_errors": {
                   "this": 47,
                   "CBSA": 877,
                   "state": 3177
                },
                "index": {
                   "this": 100,
                   "CBSA": 26,
                   "state": 47
                },
                "error_ratio": {
                   "this": 77.8,
                   "CBSA": 5.2,
                   "state": 2.1
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 1.22,
                   "CBSA": 2.12,
                   "state": 2.62
                },
                "error": {
                   "this": 0.57,
                   "CBSA": 0.13,
                   "state": 0.03
                },
                "numerators": {
                   "this": 163,
                   "CBSA": 21077,
                   "state": 476291
                },
                "numerator_errors": {
                   "this": 76,
                   "CBSA": 1264,
                   "state": 5234
                },
                "index": {
                   "this": 100,
                   "CBSA": 58,
                   "state": 47
                },
                "error_ratio": {
                   "this": 46.7,
                   "CBSA": 6.1,
                   "state": 1.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.59,
                   "CBSA": 1.32,
                   "state": 1.6
                },
                "error": {
                   "this": 0.37,
                   "CBSA": 0.08,
                   "state": 0.02
                },
                "numerators": {
                   "this": 79,
                   "CBSA": 13160,
                   "state": 291710
                },
                "numerator_errors": {
                   "this": 49,
                   "CBSA": 846,
                   "state": 4364
                },
                "index": {
                   "this": 100,
                   "CBSA": 45,
                   "state": 37
                },
                "error_ratio": {
                   "this": 62.7,
                   "CBSA": 6.1,
                   "state": 1.2
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 10.18,
                   "CBSA": 4.98,
                   "state": 5.9
                },
                "error": {
                   "this": 2.08,
                   "CBSA": 0.15,
                   "state": 0.05
                },
                "numerators": {
                   "this": 1359,
                   "CBSA": 49567,
                   "state": 1073966
                },
                "numerator_errors": {
                   "this": 282,
                   "CBSA": 1546,
                   "state": 8330
                },
                "index": {
                   "this": 100,
                   "CBSA": 204,
                   "state": 173
                },
                "error_ratio": {
                   "this": 20.4,
                   "CBSA": 3,
                   "state": 0.8
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 67.73,
                "CBSA": 54.67,
                "state": 48.86
             },
             "error": {
                "this": 0.95,
                "CBSA": 0.28,
                "state": 0.11
             },
             "numerators": {
                "this": 17643,
                "CBSA": 884034,
                "state": 1.5532639E7
             },
             "numerator_errors": {
                "this": 380.4,
                "CBSA": 4460.5,
                "state": 35143.9
             },
             "index": {
                "this": 100,
                "CBSA": 124,
                "state": 139
             },
             "error_ratio": {
                "this": 1.4,
                "CBSA": 0.5,
                "state": 0.2
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 32.27,
                "CBSA": 45.33,
                "state": 51.14
             },
             "error": {
                "this": 2.34,
                "CBSA": 0.29,
                "state": 0.1
             },
             "numerators": {
                "this": 8405,
                "CBSA": 732911,
                "state": 1.6255641E7
             },
             "numerator_errors": {
                "this": 624.2,
                "CBSA": 4762,
                "state": 32012.1
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 63
             },
             "error_ratio": {
                "this": 7.3,
                "CBSA": 0.6,
                "state": 0.2
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 22.89,
                   "CBSA": 37.44,
                   "state": 40.42
                },
                "error": {
                   "this": 2.79,
                   "CBSA": 0.35,
                   "state": 0.14
                },
                "numerators": {
                   "this": 2849,
                   "CBSA": 305004,
                   "state": 6343459
                },
                "numerator_errors": {
                   "this": 359,
                   "CBSA": 2839,
                   "state": 22441
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 57
                },
                "error_ratio": {
                   "this": 12.2,
                   "CBSA": 0.9,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 19.35,
                   "CBSA": 29.76,
                   "state": 33.49
                },
                "error": {
                   "this": 2.55,
                   "CBSA": 0.32,
                   "state": 0.11
                },
                "numerators": {
                   "this": 2632,
                   "CBSA": 238780,
                   "state": 5389443
                },
                "numerator_errors": {
                   "this": 357,
                   "CBSA": 2599,
                   "state": 17597
                },
                "index": {
                   "this": 100,
                   "CBSA": 65,
                   "state": 58
                },
                "error_ratio": {
                   "this": 13.2,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 70.45,
                   "CBSA": 54.94,
                   "state": 49.8
                },
                "error": {
                   "this": 2.95,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 8768,
                   "CBSA": 447525,
                   "state": 7816684
                },
                "numerator_errors": {
                   "this": 245,
                   "CBSA": 3214,
                   "state": 27451
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 141
                },
                "error_ratio": {
                   "this": 4.2,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 65.24,
                   "CBSA": 54.4,
                   "state": 47.95
                },
                "error": {
                   "this": 0.57,
                   "CBSA": 0.39,
                   "state": 0.14
                },
                "numerators": {
                   "this": 8875,
                   "CBSA": 436509,
                   "state": 7715955
                },
                "numerator_errors": {
                   "this": 291,
                   "CBSA": 3093,
                   "state": 21944
                },
                "index": {
                   "this": 100,
                   "CBSA": 120,
                   "state": 136
                },
                "error_ratio": {
                   "this": 0.9,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 4.18,
                   "CBSA": 5.95,
                   "state": 7.65
                },
                "error": {
                   "this": 1.27,
                   "CBSA": 0.19,
                   "state": 0.06
                },
                "numerators": {
                   "this": 520,
                   "CBSA": 48487,
                   "state": 1200982
                },
                "numerator_errors": {
                   "this": 159,
                   "CBSA": 1518,
                   "state": 9397
                },
                "index": {
                   "this": 100,
                   "CBSA": 70,
                   "state": 55
                },
                "error_ratio": {
                   "this": 30.4,
                   "CBSA": 3.2,
                   "state": 0.8
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 6.52,
                   "CBSA": 9.05,
                   "state": 10.85
                },
                "error": {
                   "this": 1.45,
                   "CBSA": 0.23,
                   "state": 0.05
                },
                "numerators": {
                   "this": 887,
                   "CBSA": 72589,
                   "state": 1746282
                },
                "numerator_errors": {
                   "this": 199,
                   "CBSA": 1835,
                   "state": 8613
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 60
                },
                "error_ratio": {
                   "this": 22.2,
                   "CBSA": 2.5,
                   "state": 0.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 2.47,
                   "CBSA": 1.67,
                   "state": 2.13
                },
                "error": {
                   "this": 0.94,
                   "CBSA": 0.1,
                   "state": 0.02
                },
                "numerators": {
                   "this": 308,
                   "CBSA": 13581,
                   "state": 334237
                },
                "numerator_errors": {
                   "this": 117,
                   "CBSA": 820,
                   "state": 3638
                },
                "index": {
                   "this": 100,
                   "CBSA": 148,
                   "state": 116
                },
                "error_ratio": {
                   "this": 38.1,
                   "CBSA": 6,
                   "state": 0.9
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 8.89,
                   "CBSA": 6.79,
                   "state": 7.71
                },
                "error": {
                   "this": 1.7,
                   "CBSA": 0.15,
                   "state": 0.04
                },
                "numerators": {
                   "this": 1209,
                   "CBSA": 54470,
                   "state": 1241238
                },
                "numerator_errors": {
                   "this": 234,
                   "CBSA": 1232,
                   "state": 5983
                },
                "index": {
                   "this": 100,
                   "CBSA": 131,
                   "state": 115
                },
                "error_ratio": {
                   "this": 19.1,
                   "CBSA": 2.2,
                   "state": 0.5
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 93.29,
                   "CBSA": 81.4,
                   "state": 72.23
                },
                "error": {
                   "this": 3.7,
                   "CBSA": 0.63,
                   "state": 0.27
                },
                "numerators": {
                   "this": 5753,
                   "CBSA": 319520,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 435,
                   "CBSA": 2844,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 129
                },
                "error_ratio": {
                   "this": 4,
                   "CBSA": 0.8,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 2.21,
                   "CBSA": 5.63,
                   "state": 7.67
                },
                "error": {
                   "this": 1.22,
                   "CBSA": 0.34,
                   "state": 0.11
                },
                "numerators": {
                   "this": 136,
                   "CBSA": 22081,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 76,
                   "CBSA": 1348,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 39,
                   "state": 29
                },
                "error_ratio": {
                   "this": 55.2,
                   "CBSA": 6,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 4.51,
                   "CBSA": 12.98,
                   "state": 20.1
                },
                "error": {
                   "this": 2.45,
                   "CBSA": 0.54,
                   "state": 0.21
                },
                "numerators": {
                   "this": 278,
                   "CBSA": 50947,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 152,
                   "CBSA": 2129,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 35,
                   "state": 22
                },
                "error_ratio": {
                   "this": 54.3,
                   "CBSA": 4.2,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 2.45,
                "CBSA": 4.91,
                "state": 4.88
             },
             "error": {
                "this": 1.15,
                "CBSA": 0.2,
                "state": 0.06
             },
             "numerators": {
                "this": 148,
                "CBSA": 23987,
                "state": 469884
             },
             "numerator_errors": {
                "this": 70,
                "CBSA": 958,
                "state": 5597
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 50,
                "state": 50
             },
             "error_ratio": {
                "this": 46.9,
                "CBSA": 4.1,
                "state": 1.2
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.44,
                   "state": 1.01
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.2,
                   "state": 0.07
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 252,
                   "state": 12727
                },
                "numerator_errors": {
                   "this": 26,
                   "CBSA": 117,
                   "state": 844
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 45.5,
                   "state": 6.9
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 2.68,
                   "state": 4.98
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.5,
                   "state": 0.17
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1581,
                   "state": 66276
                },
                "numerator_errors": {
                   "this": 26,
                   "CBSA": 295,
                   "state": 2263
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 18.7,
                   "state": 3.4
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 0,
                   "CBSA": 6.56,
                   "state": 7.77
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.65,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4781,
                   "state": 115600
                },
                "numerator_errors": {
                   "this": 26,
                   "CBSA": 476,
                   "state": 2442
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 9.9,
                   "state": 2.1
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 20.6,
                   "CBSA": 11.86,
                   "state": 9.88
                },
                "error": {
                   "this": 10.67,
                   "CBSA": 0.81,
                   "state": 0.2
                },
                "numerators": {
                   "this": 83,
                   "CBSA": 8909,
                   "state": 138843
                },
                "numerator_errors": {
                   "this": 54,
                   "CBSA": 617,
                   "state": 2879
                },
                "index": {
                   "this": 100,
                   "CBSA": 174,
                   "state": 209
                },
                "error_ratio": {
                   "this": 51.8,
                   "CBSA": 6.8,
                   "state": 2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 7.84,
                   "CBSA": 8.52,
                   "state": 7.09
                },
                "error": {
                   "this": 5.17,
                   "CBSA": 0.66,
                   "state": 0.16
                },
                "numerators": {
                   "this": 50,
                   "CBSA": 6011,
                   "state": 93691
                },
                "numerator_errors": {
                   "this": 35,
                   "CBSA": 478,
                   "state": 2137
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 111
                },
                "error_ratio": {
                   "this": 65.9,
                   "CBSA": 7.7,
                   "state": 2.3
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 0,
                   "CBSA": 2.67,
                   "state": 2.43
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.37,
                   "state": 0.1
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1855,
                   "state": 30695
                },
                "numerator_errors": {
                   "this": 26,
                   "CBSA": 258,
                   "state": 1315
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 13.9,
                   "state": 4.1
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0.84,
                   "CBSA": 0.71,
                   "state": 0.77
                },
                "error": {
                   "this": 1.11,
                   "CBSA": 0.21,
                   "state": 0.06
                },
                "numerators": {
                   "this": 15,
                   "CBSA": 598,
                   "state": 12052
                },
                "numerator_errors": {
                   "this": 20,
                   "CBSA": 174,
                   "state": 861
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 109
                },
                "error_ratio": {
                   "this": 132.1,
                   "CBSA": 29.6,
                   "state": 7.8
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 11013,
                "CBSA": 658350,
                "state": 1.3044266E7
             },
             "error": {
                "this": 255,
                "CBSA": 1597,
                "state": 20333
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 0.2,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.77,
                "CBSA": 2.96,
                "state": 2.95
             },
             "error": {
                "this": 0.07,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 30484,
                "CBSA": 1951101,
                "state": 3.8462236E7
             },
             "numerator_errors": {
                "this": 130,
                "CBSA": 1336,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 94,
                "state": 94
             },
             "error_ratio": {
                "this": 2.5,
                "CBSA": 0.3,
                "state": null
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 83.39,
                   "CBSA": 67.5,
                   "state": 61.04
                },
                "error": {
                   "this": 2.17,
                   "CBSA": 0.43,
                   "state": 0.21
                },
                "numerators": {
                   "this": 25422,
                   "CBSA": 1316990,
                   "state": 2.3478016E7
                },
                "numerator_errors": {
                   "this": 669,
                   "CBSA": 8371,
                   "state": 81110
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 137
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 2.47,
                   "CBSA": 6.18,
                   "state": 7.59
                },
                "error": {
                   "this": 0.97,
                   "CBSA": 0.23,
                   "state": 0.09
                },
                "numerators": {
                   "this": 752,
                   "CBSA": 120578,
                   "state": 2919408
                },
                "numerator_errors": {
                   "this": 296,
                   "CBSA": 4560,
                   "state": 33493
                },
                "index": {
                   "this": 100,
                   "CBSA": 40,
                   "state": 33
                },
                "error_ratio": {
                   "this": 39.3,
                   "CBSA": 3.7,
                   "state": 1.2
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 6.32,
                   "CBSA": 12.3,
                   "state": 16.57
                },
                "error": {
                   "this": 1.66,
                   "CBSA": 0.34,
                   "state": 0.12
                },
                "numerators": {
                   "this": 1926,
                   "CBSA": 239896,
                   "state": 6373007
                },
                "numerator_errors": {
                   "this": 505,
                   "CBSA": 6556,
                   "state": 46993
                },
                "index": {
                   "this": 100,
                   "CBSA": 51,
                   "state": 38
                },
                "error_ratio": {
                   "this": 26.3,
                   "CBSA": 2.8,
                   "state": 0.7
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 7.82,
                   "CBSA": 14.02,
                   "state": 14.8
                },
                "error": {
                   "this": 1.24,
                   "CBSA": 0.23,
                   "state": 0.07
                },
                "numerators": {
                   "this": 2384,
                   "CBSA": 273637,
                   "state": 5691804
                },
                "numerator_errors": {
                   "this": 378,
                   "CBSA": 4552,
                   "state": 27482
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 53
                },
                "error_ratio": {
                   "this": 15.9,
                   "CBSA": 1.6,
                   "state": 0.5
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 11451,
                "CBSA": 691465,
                "state": 1.4175976E7
             },
             "error": {
                "this": 314,
                "CBSA": 562,
                "state": 1184
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 2.7,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 96.18,
                   "CBSA": 95.21,
                   "state": 92.02
                },
                "error": {
                   "this": 3.45,
                   "CBSA": 0.22,
                   "state": 0.14
                },
                "numerators": {
                   "this": 11013,
                   "CBSA": 658350,
                   "state": 1.3044266E7
                },
                "numerator_errors": {
                   "this": 255,
                   "CBSA": 1597,
                   "state": 20333
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 105
                },
                "error_ratio": {
                   "this": 3.6,
                   "CBSA": 0.2,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 3.82,
                   "CBSA": 4.79,
                   "state": 7.98
                },
                "error": {
                   "this": 1.63,
                   "CBSA": 0.26,
                   "state": 0.15
                },
                "numerators": {
                   "this": 438,
                   "CBSA": 33115,
                   "state": 1131710
                },
                "numerator_errors": {
                   "this": 187,
                   "CBSA": 1770,
                   "state": 20951
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 48
                },
                "error_ratio": {
                   "this": 42.7,
                   "CBSA": 5.4,
                   "state": 1.9
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 92.96,
                   "CBSA": 63.19,
                   "state": 64.78
                },
                "error": {
                   "this": 1.57,
                   "CBSA": 0.33,
                   "state": 0.09
                },
                "numerators": {
                   "this": 10645,
                   "CBSA": 436950,
                   "state": 9183849
                },
                "numerator_errors": {
                   "this": 342.5,
                   "CBSA": 2296.3,
                   "state": 13345.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 147,
                   "state": 144
                },
                "error_ratio": {
                   "this": 1.7,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 6.58,
                   "CBSA": 33.87,
                   "state": 31.43
                },
                "error": {
                   "this": 1.48,
                   "CBSA": 0.44,
                   "state": 0.11
                },
                "numerators": {
                   "this": 754,
                   "CBSA": 234174,
                   "state": 4455831
                },
                "numerator_errors": {
                   "this": 171.1,
                   "CBSA": 3074,
                   "state": 15037
                },
                "index": {
                   "this": 100,
                   "CBSA": 19,
                   "state": 21
                },
                "error_ratio": {
                   "this": 22.5,
                   "CBSA": 1.3,
                   "state": 0.3
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.45,
                   "CBSA": 2.86,
                   "state": 3.68
                },
                "error": {
                   "this": 0.53,
                   "CBSA": 0.1,
                   "state": 0.03
                },
                "numerators": {
                   "this": 52,
                   "CBSA": 19795,
                   "state": 521135
                },
                "numerator_errors": {
                   "this": 61,
                   "CBSA": 718,
                   "state": 4740
                },
                "index": {
                   "this": 100,
                   "CBSA": 16,
                   "state": 12
                },
                "error_ratio": {
                   "this": 117.8,
                   "CBSA": 3.5,
                   "state": 0.8
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.08,
                   "state": 0.11
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.03,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 546,
                   "state": 15161
                },
                "numerator_errors": {
                   "this": 26,
                   "CBSA": 173,
                   "state": 862
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 37.5,
                   "state": 9.1
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 84.62,
                   "CBSA": 56.6,
                   "state": 54.85
                },
                "error": {
                   "this": 2.56,
                   "CBSA": 0.4,
                   "state": 0.28
                },
                "numerators": {
                   "this": 9319,
                   "CBSA": 372659,
                   "state": 7154580
                },
                "numerator_errors": {
                   "this": 355,
                   "CBSA": 2785,
                   "state": 37872
                },
                "index": {
                   "this": 100,
                   "CBSA": 150,
                   "state": 154
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 0.7,
                   "state": 0.5
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 15.38,
                   "CBSA": 43.4,
                   "state": 45.15
                },
                "error": {
                   "this": 2.12,
                   "CBSA": 0.36,
                   "state": 0.15
                },
                "numerators": {
                   "this": 1694,
                   "CBSA": 285691,
                   "state": 5889686
                },
                "numerator_errors": {
                   "this": 237,
                   "CBSA": 2449,
                   "state": 21311
                },
                "index": {
                   "this": 100,
                   "CBSA": 35,
                   "state": 34
                },
                "error_ratio": {
                   "this": 13.8,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 2000001,
                "CBSA": 968800,
                "state": 505000
             },
             "error": {
                "this": 0,
                "CBSA": 5412,
                "state": 1147
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 206,
                "state": 396
             },
             "error_ratio": {
                "this": null,
                "CBSA": 0.6,
                "state": 0.2
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 1.22,
                   "CBSA": 2.37,
                   "state": 5.03
                },
                "error": {
                   "this": 1.12,
                   "CBSA": 0.15,
                   "state": 0.05
                },
                "numerators": {
                   "this": 114,
                   "CBSA": 8828,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 104.4,
                   "CBSA": 548.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 51,
                   "state": 24
                },
                "error_ratio": {
                   "this": 91.8,
                   "CBSA": 6.3,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 0.34,
                   "CBSA": 2.07,
                   "state": 6.63
                },
                "error": {
                   "this": 0.59,
                   "CBSA": 0.15,
                   "state": 0.06
                },
                "numerators": {
                   "this": 32,
                   "CBSA": 7732,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 55.2,
                   "CBSA": 568.6,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 16,
                   "state": 5
                },
                "error_ratio": {
                   "this": 173.5,
                   "CBSA": 7.2,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 1.29,
                   "CBSA": 1.76,
                   "state": 11.06
                },
                "error": {
                   "this": 1.02,
                   "CBSA": 0.13,
                   "state": 0.06
                },
                "numerators": {
                   "this": 120,
                   "CBSA": 6570,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 95.3,
                   "CBSA": 504.3,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 73,
                   "state": 12
                },
                "error_ratio": {
                   "this": 79.1,
                   "CBSA": 7.4,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 0.78,
                   "CBSA": 2.37,
                   "state": 13.6
                },
                "error": {
                   "this": 0.58,
                   "CBSA": 0.16,
                   "state": 0.09
                },
                "numerators": {
                   "this": 73,
                   "CBSA": 8838,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 54,
                   "CBSA": 599,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 33,
                   "state": 6
                },
                "error_ratio": {
                   "this": 74.4,
                   "CBSA": 6.8,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 0.89,
                   "CBSA": 3.99,
                   "state": 13.21
                },
                "error": {
                   "this": 0.91,
                   "CBSA": 0.22,
                   "state": 0.08
                },
                "numerators": {
                   "this": 83,
                   "CBSA": 14882,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 85,
                   "CBSA": 831,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 22,
                   "state": 7
                },
                "error_ratio": {
                   "this": 102.2,
                   "CBSA": 5.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 5.09,
                   "CBSA": 40.13,
                   "state": 35.28
                },
                "error": {
                   "this": 1.49,
                   "CBSA": 0.54,
                   "state": 0.26
                },
                "numerators": {
                   "this": 474,
                   "CBSA": 149566,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 140.3,
                   "CBSA": 2309.5,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 13,
                   "state": 14
                },
                "error_ratio": {
                   "this": 29.3,
                   "CBSA": 1.3,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 8.69,
                   "CBSA": 22.29,
                   "state": 7.93
                },
                "error": {
                   "this": 1.61,
                   "CBSA": 0.41,
                   "state": 0.04
                },
                "numerators": {
                   "this": 810,
                   "CBSA": 83053,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 153,
                   "CBSA": 1652,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 39,
                   "state": 110
                },
                "error_ratio": {
                   "this": 18.5,
                   "CBSA": 1.8,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 9319,
                "CBSA": 372659,
                "state": 7154580
             },
             "error": {
                "this": 355,
                "CBSA": 2785,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 3.8,
                "CBSA": 0.7,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 5-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 19.91,
                "CBSA": 10.69,
                "state": 9.72
             },
             "error": {
                "this": 2.09,
                "CBSA": 0.25,
                "state": 0.06
             },
             "numerators": {
                "this": 6070,
                "CBSA": 208556,
                "state": 3739275
             },
             "numerator_errors": {
                "this": 637.1,
                "CBSA": 4934.2,
                "state": 21932.9
             },
             "index": {
                "this": 100,
                "CBSA": 186,
                "state": 205
             },
             "error_ratio": {
                "this": 10.5,
                "CBSA": 2.3,
                "state": 0.6
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 16.96,
                "CBSA": 11.88,
                "state": 12.25
             },
             "error": {
                "this": 2.1,
                "CBSA": 0.24,
                "state": 0.08
             },
             "numerators": {
                "this": 5170,
                "CBSA": 231731,
                "state": 4711644
             },
             "numerator_errors": {
                "this": 641.5,
                "CBSA": 4705.1,
                "state": 30793.4
             },
             "index": {
                "this": 100,
                "CBSA": 143,
                "state": 138
             },
             "error_ratio": {
                "this": 12.4,
                "CBSA": 2,
                "state": 0.7
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 25.93,
                "CBSA": 26.42,
                "state": 25.7
             },
             "error": {
                "this": 2.77,
                "CBSA": 0.43,
                "state": 0.13
             },
             "numerators": {
                "this": 7905,
                "CBSA": 515473,
                "state": 9884339
             },
             "numerator_errors": {
                "this": 844.4,
                "CBSA": 8494.2,
                "state": 50780.8
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 101
             },
             "error_ratio": {
                "this": 10.7,
                "CBSA": 1.6,
                "state": 0.5
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 23.55,
                "CBSA": 27.26,
                "state": 28.78
             },
             "error": {
                "this": 3.27,
                "CBSA": 0.45,
                "state": 0.16
             },
             "numerators": {
                "this": 7179,
                "CBSA": 531851,
                "state": 1.1067761E7
             },
             "numerator_errors": {
                "this": 996.4,
                "CBSA": 8744.9,
                "state": 60057.7
             },
             "index": {
                "this": 100,
                "CBSA": 86,
                "state": 82
             },
             "error_ratio": {
                "this": 13.9,
                "CBSA": 1.7,
                "state": 0.6
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 7.34,
                "CBSA": 14,
                "state": 14.48
             },
             "error": {
                "this": 1.57,
                "CBSA": 0.36,
                "state": 0.11
             },
             "numerators": {
                "this": 2237,
                "CBSA": 273196,
                "state": 5568721
             },
             "numerator_errors": {
                "this": 477.6,
                "CBSA": 6977.5,
                "state": 43280.9
             },
             "index": {
                "this": 100,
                "CBSA": 52,
                "state": 51
             },
             "error_ratio": {
                "this": 21.4,
                "CBSA": 2.6,
                "state": 0.8
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 6.31,
                "CBSA": 9.75,
                "state": 9.08
             },
             "error": {
                "this": 1.9,
                "CBSA": 0.29,
                "state": 0.09
             },
             "numerators": {
                "this": 1923,
                "CBSA": 190294,
                "state": 3490495
             },
             "numerator_errors": {
                "this": 580.1,
                "CBSA": 5754.6,
                "state": 33565.8
             },
             "index": {
                "this": 100,
                "CBSA": 65,
                "state": 69
             },
             "error_ratio": {
                "this": 30.1,
                "CBSA": 3,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 7.48,
                "CBSA": 14.41,
                "state": 12.86
             },
             "error": {
                "this": 1.4,
                "CBSA": 0.28,
                "state": 0.08
             },
             "numerators": {
                "this": 2286,
                "CBSA": 283223,
                "state": 4993305
             },
             "numerator_errors": {
                "this": 428.7,
                "CBSA": 5465,
                "state": 29689.5
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 52,
                "state": 58
             },
             "error_ratio": {
                "this": 18.7,
                "CBSA": 1.9,
                "state": 0.6
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 5-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 92.52,
                "CBSA": 85.59,
                "state": 87.14
             },
             "error": {
                "this": 1.42,
                "CBSA": 0.27,
                "state": 0.07
             },
             "numerators": {
                "this": 28261,
                "CBSA": 1682642,
                "state": 3.3839688E7
             },
             "numerator_errors": {
                "this": 445,
                "CBSA": 5276,
                "state": 27490
             },
             "index": {
                "this": 100,
                "CBSA": 108,
                "state": 106
             },
             "error_ratio": {
                "this": 1.5,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 3.96,
                "CBSA": 8.33,
                "state": 8.01
             },
             "error": {
                "this": 1.09,
                "CBSA": 0.22,
                "state": 0.07
             },
             "numerators": {
                "this": 1209,
                "CBSA": 163762,
                "state": 3108823
             },
             "numerator_errors": {
                "this": 332,
                "CBSA": 4270,
                "state": 25604
             },
             "index": {
                "this": 100,
                "CBSA": 48,
                "state": 49
             },
             "error_ratio": {
                "this": 27.5,
                "CBSA": 2.6,
                "state": 0.9
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 1.58,
                "CBSA": 2.5,
                "state": 2.75
             },
             "error": {
                "this": 0.54,
                "CBSA": 0.11,
                "state": 0.03
             },
             "numerators": {
                "this": 483,
                "CBSA": 49162,
                "state": 1069429
             },
             "numerator_errors": {
                "this": 165,
                "CBSA": 2113,
                "state": 11456
             },
             "index": {
                "this": 100,
                "CBSA": 63,
                "state": 57
             },
             "error_ratio": {
                "this": 34.2,
                "CBSA": 4.4,
                "state": 1.1
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 0.76,
                "CBSA": 1.81,
                "state": 1.3
             },
             "error": {
                "this": 0.43,
                "CBSA": 0.1,
                "state": 0.02
             },
             "numerators": {
                "this": 232,
                "CBSA": 35635,
                "state": 503560
             },
             "numerator_errors": {
                "this": 132,
                "CBSA": 1923,
                "state": 8001
             },
             "index": {
                "this": 100,
                "CBSA": 42,
                "state": 58
             },
             "error_ratio": {
                "this": 56.6,
                "CBSA": 5.5,
                "state": 1.5
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 1.19,
                "CBSA": 1.76,
                "state": 0.8
             },
             "error": {
                "this": 0.56,
                "CBSA": 0.09,
                "state": 0.01
             },
             "numerators": {
                "this": 362,
                "CBSA": 34664,
                "state": 311493
             },
             "numerator_errors": {
                "this": 170,
                "CBSA": 1863,
                "state": 5536
             },
             "index": {
                "this": 100,
                "CBSA": 68,
                "state": 149
             },
             "error_ratio": {
                "this": 47.1,
                "CBSA": 5.1,
                "state": 1.2
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 97.58,
                "CBSA": 88.15,
                "state": 83.31
             },
             "error": {
                "this": 3.53,
                "CBSA": 0.5,
                "state": 0.15
             },
             "numerators": {
                "this": 22463,
                "CBSA": 1211097,
                "state": 2.2052868E7
             },
             "numerator_errors": {
                "this": 909.6,
                "CBSA": 6911.2,
                "state": 39734.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 111,
                "state": 117
             },
             "error_ratio": {
                "this": 3.6,
                "CBSA": 0.6,
                "state": 0.2
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 77.52,
                "CBSA": 51.47,
                "state": 33.93
             },
             "error": {
                "this": 3.12,
                "CBSA": 0.36,
                "state": 0.09
             },
             "numerators": {
                "this": 17846,
                "CBSA": 707206,
                "state": 8980726
             },
             "numerator_errors": {
                "this": 788.4,
                "CBSA": 4914.3,
                "state": 23065.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 151,
                "state": 228
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 0.7,
                "state": 0.3
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 2.42,
                "CBSA": 11.85,
                "state": 16.69
             },
             "error": {
                "this": 0.67,
                "CBSA": 0.21,
                "state": 0.07
             },
             "numerators": {
                "this": 557,
                "CBSA": 162819,
                "state": 4418675
             },
             "numerator_errors": {
                "this": 155.6,
                "CBSA": 2905.1,
                "state": 18934.1
             },
             "index": {
                "this": 100,
                "CBSA": 20,
                "state": 14
             },
             "error_ratio": {
                "this": 27.7,
                "CBSA": 1.8,
                "state": 0.4
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 6.15,
                "CBSA": 14.4,
                "state": 20.49
             },
             "error": {
                "this": 1.13,
                "CBSA": 0.24,
                "state": 0.08
             },
             "numerators": {
                "this": 1416,
                "CBSA": 197824,
                "state": 5423462
             },
             "numerator_errors": {
                "this": 261.3,
                "CBSA": 3326.3,
                "state": 22237.1
             },
             "index": {
                "this": 100,
                "CBSA": 43,
                "state": 30
             },
             "error_ratio": {
                "this": 18.4,
                "CBSA": 1.7,
                "state": 0.4
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 13.91,
                "CBSA": 22.28,
                "state": 28.89
             },
             "error": {
                "this": 1.59,
                "CBSA": 0.26,
                "state": 0.09
             },
             "numerators": {
                "this": 3201,
                "CBSA": 306067,
                "state": 7648680
             },
             "numerator_errors": {
                "this": 370.8,
                "CBSA": 3542.7,
                "state": 23501.3
             },
             "index": {
                "this": 100,
                "CBSA": 62,
                "state": 48
             },
             "error_ratio": {
                "this": 11.4,
                "CBSA": 1.2,
                "state": 0.3
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 33.74,
                "CBSA": 27.29,
                "state": 21.17
             },
             "error": {
                "this": 2.32,
                "CBSA": 0.25,
                "state": 0.07
             },
             "numerators": {
                "this": 7768,
                "CBSA": 374919,
                "state": 5603047
             },
             "numerator_errors": {
                "this": 553.1,
                "CBSA": 3457.6,
                "state": 17216.6
             },
             "index": {
                "this": 100,
                "CBSA": 124,
                "state": 159
             },
             "error_ratio": {
                "this": 6.9,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 43.78,
                "CBSA": 24.19,
                "state": 12.76
             },
             "error": {
                "this": 2.31,
                "CBSA": 0.25,
                "state": 0.06
             },
             "numerators": {
                "this": 10078,
                "CBSA": 332287,
                "state": 3377679
             },
             "numerator_errors": {
                "this": 561.8,
                "CBSA": 3492.1,
                "state": 15348.8
             },
             "index": {
                "this": 100,
                "CBSA": 181,
                "state": 343
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 1,
                "state": 0.5
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 40.07,
                "CBSA": 38.58,
                "state": 26.85
             },
             "error": {
                "this": 2.39,
                "CBSA": 0.26,
                "state": 0.09
             },
             "numerators": {
                "this": 12300,
                "CBSA": 767006,
                "state": 1.054721E7
             },
             "numerator_errors": {
                "this": 734,
                "CBSA": 5248,
                "state": 36592
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 149
             },
             "error_ratio": {
                "this": 6,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 13.08,
                   "CBSA": 7.91,
                   "state": 6.42
                },
                "error": {
                   "this": 3.56,
                   "CBSA": 0.3,
                   "state": 0.07
                },
                "numerators": {
                   "this": 1609,
                   "CBSA": 60639,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 448,
                   "CBSA": 2354,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 165,
                   "state": 204
                },
                "error_ratio": {
                   "this": 27.2,
                   "CBSA": 3.8,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 82.05,
                   "CBSA": 65.91,
                   "state": 39.42
                },
                "error": {
                   "this": 6.74,
                   "CBSA": 0.62,
                   "state": 0.09
                },
                "numerators": {
                   "this": 10092,
                   "CBSA": 505503,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 570,
                   "CBSA": 3277,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 208
                },
                "error_ratio": {
                   "this": 8.2,
                   "CBSA": 0.9,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.2,
                   "CBSA": 1.7,
                   "state": 1.84
                },
                "error": {
                   "this": 0.89,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 147,
                   "CBSA": 13034,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 110,
                   "CBSA": 1055,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 71,
                   "state": 65
                },
                "error_ratio": {
                   "this": 74.2,
                   "CBSA": 8.2,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.07,
                   "CBSA": 0.53,
                   "state": 0.82
                },
                "error": {
                   "this": 0.11,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 9,
                   "CBSA": 4060,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 13,
                   "CBSA": 662,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 13,
                   "state": 9
                },
                "error_ratio": {
                   "this": 157.1,
                   "CBSA": 17,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 2.23,
                   "CBSA": 22.67,
                   "state": 50.28
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 0.42,
                   "state": 0.15
                },
                "numerators": {
                   "this": 274,
                   "CBSA": 173901,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 168,
                   "CBSA": 3417,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 10,
                   "state": 4
                },
                "error_ratio": {
                   "this": 61,
                   "CBSA": 1.9,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 1.37,
                   "CBSA": 1.29,
                   "state": 1.23
                },
                "error": {
                   "this": 0.83,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 169,
                   "CBSA": 9858,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 102,
                   "CBSA": 722,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 111
                },
                "error_ratio": {
                   "this": 60.6,
                   "CBSA": 7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 58.97,
                   "CBSA": 52.99,
                   "state": 56.46
                },
                "error": {
                   "this": 6.39,
                   "CBSA": 0.67,
                   "state": 0.24
                },
                "numerators": {
                   "this": 3291,
                   "CBSA": 171505,
                   "state": 3709800
                },
                "numerator_errors": {
                   "this": 403,
                   "CBSA": 2186,
                   "state": 15797
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 104
                },
                "error_ratio": {
                   "this": 10.8,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 1.13,
                   "CBSA": 22.53,
                   "state": 33.98
                },
                "error": {
                   "this": 0.91,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 63,
                   "CBSA": 72935,
                   "state": 2232947
                },
                "numerator_errors": {
                   "this": 51,
                   "CBSA": 1746,
                   "state": 13243
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": 3
                },
                "error_ratio": {
                   "this": 80.5,
                   "CBSA": 2.4,
                   "state": 0.6
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 11.75,
                   "CBSA": 6.21,
                   "state": 2.92
                },
                "error": {
                   "this": 4.41,
                   "CBSA": 0.29,
                   "state": 0.07
                },
                "numerators": {
                   "this": 656,
                   "CBSA": 20097,
                   "state": 191982
                },
                "numerator_errors": {
                   "this": 249,
                   "CBSA": 935,
                   "state": 4345
                },
                "index": {
                   "this": 100,
                   "CBSA": 189,
                   "state": 402
                },
                "error_ratio": {
                   "this": 37.5,
                   "CBSA": 4.7,
                   "state": 2.4
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 26.27,
                   "CBSA": 17,
                   "state": 5.73
                },
                "error": {
                   "this": 5.16,
                   "CBSA": 0.46,
                   "state": 0.06
                },
                "numerators": {
                   "this": 1466,
                   "CBSA": 55026,
                   "state": 376241
                },
                "numerator_errors": {
                   "this": 300,
                   "CBSA": 1483,
                   "state": 4139
                },
                "index": {
                   "this": 100,
                   "CBSA": 155,
                   "state": 458
                },
                "error_ratio": {
                   "this": 19.6,
                   "CBSA": 2.7,
                   "state": 1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.88,
                   "CBSA": 1.27,
                   "state": 0.91
                },
                "error": {
                   "this": 1.23,
                   "CBSA": 0.18,
                   "state": 0.04
                },
                "numerators": {
                   "this": 105,
                   "CBSA": 4106,
                   "state": 59648
                },
                "numerator_errors": {
                   "this": 69,
                   "CBSA": 595,
                   "state": 2823
                },
                "index": {
                   "this": 100,
                   "CBSA": 148,
                   "state": 207
                },
                "error_ratio": {
                   "this": 65.4,
                   "CBSA": 14.2,
                   "state": 4.4
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 53.91,
                   "CBSA": 46.23,
                   "state": 55.62
                },
                "error": {
                   "this": 2.63,
                   "CBSA": 0.27,
                   "state": 0.11
                },
                "numerators": {
                   "this": 13141,
                   "CBSA": 713748,
                   "state": 1.6830152E7
                },
                "numerator_errors": {
                   "this": 695.6,
                   "CBSA": 4210.1,
                   "state": 34792.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 97
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 0.6,
                   "state": 0.2
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 1.4,
                   "CBSA": 17.25,
                   "state": 27.58
                },
                "error": {
                   "this": 0.53,
                   "CBSA": 0.21,
                   "state": 0.08
                },
                "numerators": {
                   "this": 341,
                   "CBSA": 266293,
                   "state": 8345569
                },
                "numerator_errors": {
                   "this": 129.7,
                   "CBSA": 3165.9,
                   "state": 23054.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 8,
                   "state": 5
                },
                "error_ratio": {
                   "this": 37.9,
                   "CBSA": 1.2,
                   "state": 0.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 11.31,
                   "CBSA": 9.53,
                   "state": 4.85
                },
                "error": {
                   "this": 2.14,
                   "CBSA": 0.24,
                   "state": 0.04
                },
                "numerators": {
                   "this": 2756,
                   "CBSA": 147182,
                   "state": 1468932
                },
                "numerator_errors": {
                   "this": 524,
                   "CBSA": 3659.2,
                   "state": 12705.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 233
                },
                "error_ratio": {
                   "this": 18.9,
                   "CBSA": 2.5,
                   "state": 0.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 31.62,
                   "CBSA": 25.62,
                   "state": 10.88
                },
                "error": {
                   "this": 2.42,
                   "CBSA": 0.23,
                   "state": 0.03
                },
                "numerators": {
                   "this": 7709,
                   "CBSA": 395544,
                   "state": 3293073
                },
                "numerator_errors": {
                   "this": 611.3,
                   "CBSA": 3565.1,
                   "state": 10307.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 291
                },
                "error_ratio": {
                   "this": 7.7,
                   "CBSA": 0.9,
                   "state": 0.3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.77,
                   "CBSA": 1.36,
                   "state": 1.07
                },
                "error": {
                   "this": 0.77,
                   "CBSA": 0.09,
                   "state": 0.02
                },
                "numerators": {
                   "this": 431,
                   "CBSA": 21019,
                   "state": 323625
                },
                "numerator_errors": {
                   "this": 187.5,
                   "CBSA": 1339.9,
                   "state": 7069.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 130,
                   "state": 165
                },
                "error_ratio": {
                   "this": 43.5,
                   "CBSA": 6.6,
                   "state": 1.9
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 120,
                   "CBSA": 2442,
                   "state": 66574
                },
                "error": {
                   "this": 69.66,
                   "CBSA": 319.93,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": null
                },
                "error_ratio": {
                   "this": 58.1,
                   "CBSA": 13.1,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 247,
                   "CBSA": 6382,
                   "state": 145536
                },
                "error": {
                   "this": 106.98,
                   "CBSA": 405.23,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 4,
                   "state": null
                },
                "error_ratio": {
                   "this": 43.3,
                   "CBSA": 6.3,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 506,
                   "CBSA": 19651,
                   "state": 561083
                },
                "error": {
                   "this": 134.09,
                   "CBSA": 863.14,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 26.5,
                   "CBSA": 4.4,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 59,
                   "CBSA": 7981,
                   "state": 284290
                },
                "error": {
                   "this": 50.21,
                   "CBSA": 586.46,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 85.1,
                   "CBSA": 7.3,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 50,
                   "CBSA": 7720,
                   "state": 309021
                },
                "error": {
                   "this": 54.08,
                   "CBSA": 544.05,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 108.2,
                   "CBSA": 7,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 1129,
                   "CBSA": 50028,
                   "state": 1446385
                },
                "error": {
                   "this": 171,
                   "CBSA": 1263,
                   "state": 8117
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 15.1,
                   "CBSA": 2.5,
                   "state": 0.6
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 42,
                   "CBSA": 4063,
                   "state": 128146
                },
                "error": {
                   "this": 40,
                   "CBSA": 388,
                   "state": 2994
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 95.2,
                   "CBSA": 9.5,
                   "state": 2.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 1171,
                "CBSA": 54091,
                "state": 1574531
             },
             "error": {
                "this": 181,
                "CBSA": 1285,
                "state": 8842
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 15.5,
                "CBSA": 2.4,
                "state": 0.6
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 4.8,
                "CBSA": 3.51,
                "state": 5.23
             },
             "error": {
                "this": 0.74,
                "CBSA": 0.08,
                "state": 0.03
             },
             "numerators": {
                "this": 1171,
                "CBSA": 54091,
                "state": 1574531
             },
             "numerator_errors": {
                "this": 181,
                "CBSA": 1285,
                "state": 8842
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 137,
                "state": 92
             },
             "error_ratio": {
                "this": 15.4,
                "CBSA": 2.3,
                "state": 0.6
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 33067011,
       "awater": 0,
       "display_name": "Saratoga, CA",
       "full_geoid": "16000US0670280",
       "population": 30886,
       "simple_name": "Saratoga",
       "sumlevel": "160",
       "square_miles": 12.8,
       "population_density": 2404.4
    }
 }

 export default saratoga