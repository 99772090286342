exports.MOUNTAIN_HOUSE_SCHOOLS = [
		{
			"id": 31663,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "e,m",
			"lat": 37.782639,
			"lon": -121.55043,
			"name": "Altamont Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "452 W. Saint Francis Ave.",
				"street2": null,
				"zip": "95391",
				"city": "Mountain House"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95391",
			"type": "school",
			"links": {
				"profile": "/california/mountain-house/31663-Altamont-Elementary-School/",
				"reviews": "/california/mountain-house/31663-Altamont-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-house/31663-Altamont-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 700,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 27,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17651,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "e,m",
			"lat": 37.773102,
			"lon": -121.549889,
			"name": "Bethany Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "570 Escuela Drive",
				"street2": null,
				"zip": "95391",
				"city": "Mountain House"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95391",
			"type": "school",
			"links": {
				"profile": "/california/mountain-house/17651-Bethany-Elementary-School/",
				"reviews": "/california/mountain-house/17651-Bethany-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-house/17651-Bethany-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "18%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 857,
			"parentRating": 4,
			"numReviews": 20,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 19
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32721,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "e,m",
			"lat": 37.760796,
			"lon": -121.553139,
			"name": "Hansen Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1400 S. Durant Terrace",
				"street2": null,
				"zip": "95391",
				"city": "Mountain House"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95391",
			"type": "school",
			"links": {
				"profile": "/california/mountain-house/32721-Hansen-Elementary/",
				"reviews": "/california/mountain-house/32721-Hansen-Elementary/#Reviews",
				"collegeSuccess": "/california/mountain-house/32721-Hansen-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 651,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {
				"Test Scores Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 70
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 31660,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "h",
			"lat": 37.764957,
			"lon": -121.546059,
			"name": "Mountain House High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1090 S. Central Pkwy.",
				"street2": null,
				"zip": "95391",
				"city": "Mountain House"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95391",
			"type": "school",
			"links": {
				"profile": "/california/mountain-house/31660-Mountain-House-High-School/",
				"reviews": "/california/mountain-house/31660-Mountain-House-High-School/#Reviews",
				"collegeSuccess": "/california/mountain-house/31660-Mountain-House-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "20%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "72%",
				"state_average": "64%"
			},
			"enrollment": 1348,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 20
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 25330,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "e,m",
			"lat": 37.788578,
			"lon": -121.551636,
			"name": "Sebastian Questa Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "543 N. Montebello St.",
				"street2": null,
				"zip": "95391",
				"city": "Mountain House"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95391",
			"type": "school",
			"links": {
				"profile": "/california/mountain-house/25330-Sebastian-Questa-Elementary-School/",
				"reviews": "/california/mountain-house/25330-Sebastian-Questa-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-house/25330-Sebastian-Questa-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 843,
			"parentRating": 4,
			"numReviews": 18,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 43
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 16
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 14397,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "e,m",
			"lat": 37.771919,
			"lon": -121.540512,
			"name": "Wicklund Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "300 E. Legacy Dr.",
				"street2": null,
				"zip": "95391",
				"city": "Mountain House"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95391",
			"type": "school",
			"links": {
				"profile": "/california/mountain-house/14397-Wicklund-Elementary-School/",
				"reviews": "/california/mountain-house/14397-Wicklund-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-house/14397-Wicklund-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 753,
			"parentRating": 4,
			"numReviews": 23,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 23
				},
				{
					"label": "African American",
					"rating": 8,
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 25
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 8
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]
