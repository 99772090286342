exports.ALAMEDA_LISTINGS = [
		{
			"closePrice": 1050000,
			"daysOnMovoto": 80,
			"id": "7bcc4e2a-cad2-430e-a0e6-7f5994645db4",
			"tnImgPath": "https://pi.movoto.com/p/12/40970366_0_UrUuRe_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Victor Jin",
			"listPrice": 1150000,
			"lotSize": 3480,
			"sqftTotal": 2196,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970366",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Property Investment Srvcs",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1890,
			"zipCode": "94501",
			"path": "alameda-ca/2311-eagle-ave-alameda-ca-94501/pid_yznavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "90e5bf31-b444-4184-9e55-ed288a7e5543",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-09 03:26:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.769762,
				"lng": -122.240152,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2311 Eagle Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 136,
			"id": "ddd86c25-3abe-42c6-a2ca-f2920380d339",
			"tnImgPath": "https://pi.movoto.com/p/101/321077377_0_umyYjb_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Adam Ghisletta",
			"listPrice": 1199000,
			"lotSize": 2966,
			"sqftTotal": 1950,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321077377",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Avenue 8 Inc.",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1900,
			"zipCode": "94501",
			"path": "alameda-ca/1520-santa-clara-ave-alameda-ca-94501-12_40173736/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -60000,
			"propertyId": "30e0f116-bfd1-4bb1-9286-4710374f240e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-08-19 19:23:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.772681,
				"lng": -122.259939,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1520 Santa Clara Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 460000,
			"daysOnMovoto": 41,
			"id": "bf0deefd-3440-4456-be53-ee2093e05e0b",
			"tnImgPath": "https://pi.movoto.com/p/12/40973986_0_ERemYA_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Quinn Stone",
			"listPrice": 449000,
			"lotSize": 36939,
			"sqftTotal": 701,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973986",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Bayside Real Estate",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "94501",
			"path": "alameda-ca/1327-webster-st-b302-alameda-ca-94501/pid_7pvavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4de95354-5eb8-475b-a6c2-c50dd15c76ba",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-11 23:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.770394,
				"lng": -122.277878,
				"zipcode": "94501",
				"subPremise": "APT B302",
				"address": "1327 Webster St #B302"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 460000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1045000,
			"daysOnMovoto": 48,
			"id": "ae7d4b4a-8e8d-4e4f-8020-6aab51f215a1",
			"tnImgPath": "https://pi.movoto.com/p/12/40973362_0_fNyI33_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Laurie Wotus",
			"listPrice": 949000,
			"lotSize": 7800,
			"sqftTotal": 1129,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973362",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1941,
			"zipCode": "94501",
			"path": "alameda-ca/2428-roosevelt-dr-alameda-ca-94501/pid_xcnavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e2da4639-d1c0-40de-9c79-042a6d9c9103",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-05 03:26:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://www.aryeo.com/v2/bf820684-f367-42e0-b861-a4cf09b23fda/videos/51260",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.755994,
				"lng": -122.247641,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2428 Roosevelt Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1045000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.aryeo.com/v2/bf820684-f367-42e0-b861-a4cf09b23fda/videos/51260"
		},
		{
			"closePrice": 1225000,
			"daysOnMovoto": 74,
			"id": "a9daf216-0818-44c7-8791-86ac01891401",
			"tnImgPath": "https://pi.movoto.com/p/12/40970371_0_33J6eU_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Jose Cerda-Zein",
			"listPrice": 1197000,
			"lotSize": 8436,
			"sqftTotal": 2303,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970371",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Cerda-Zein Real Estate",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2016,
			"zipCode": "94501",
			"path": "alameda-ca/475-mitchell-ave-alameda-ca-94501-12_40970371/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -63000,
			"propertyId": "b11e411b-5fec-46fe-8f99-fd6072219c58",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-10-09 04:41:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=E4xrW2niTs7&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.789248,
				"lng": -122.278828,
				"zipcode": "94501",
				"subPremise": "",
				"address": "475 Mitchell Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=E4xrW2niTs7&mls=1"
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 17,
			"id": "4ecf61d5-5927-48ec-abe3-ff0977df53ff",
			"tnImgPath": "https://pi.movoto.com/p/12/40975519_0_zII22m_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Rose Krinks",
			"listPrice": 1148000,
			"lotSize": 3390,
			"sqftTotal": 1716,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975519",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1964,
			"zipCode": "94501",
			"path": "alameda-ca/331-laguna-vis-alameda-ca-94501/pid_ggtavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ac372172-2a6d-493c-b31f-de1cfb7018a0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-12-03 19:56:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=F8hu23gbjTa",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.753774,
				"lng": -122.243985,
				"zipcode": "94501",
				"subPremise": "",
				"address": "331 Laguna Vis"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=F8hu23gbjTa"
		},
		{
			"closePrice": 475000,
			"daysOnMovoto": 42,
			"id": "a994457a-5058-4d61-8a2a-ba8f28120d41",
			"tnImgPath": "https://pi.movoto.com/p/12/40971663_0_MvEAeY_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Kelsey Spurr",
			"listPrice": 465000,
			"lotSize": 51065,
			"sqftTotal": 701,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971663",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Fathom Realty Group, Inc",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "94501",
			"path": "alameda-ca/1333-webster-st-a208-alameda-ca-94501/pid_d0uavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "23d617c1-1d6c-4966-8dd1-54fe7ee74b07",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-05 16:51:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.770356,
				"lng": -122.277185,
				"zipcode": "94501",
				"subPremise": "APT A208",
				"address": "1333 Webster St #A208"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 742000,
			"daysOnMovoto": 88,
			"id": "c2e26ac5-c465-41e5-8f55-74c787ff72e2",
			"tnImgPath": "https://pi.movoto.com/p/12/40967734_0_ibarQ3_p.jpeg",
			"listDate": "2021-09-18 00:00:00",
			"listingAgent": "Nancy Evans",
			"listPrice": 739000,
			"lotSize": 1500,
			"sqftTotal": 1280,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967734",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1968,
			"zipCode": "94502",
			"path": "alameda-ca/3009-flora-vis-alameda-ca-94502/pid_gdtavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "1e499430-0be7-474c-91e8-0f284c4d815c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-09-18 20:31:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.735106,
				"lng": -122.247572,
				"zipcode": "94502",
				"subPremise": "",
				"address": "3009 Flora Vis"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 742000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 179,
			"id": "42d60c01-a01c-48c1-b794-3798a7386911",
			"tnImgPath": "https://pi.movoto.com/p/12/40955064_0_FjMYRF_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Francis M. Ho",
			"listPrice": 1799000,
			"lotSize": 9558,
			"sqftTotal": 3490,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955064",
			"bath": 5,
			"bed": 7,
			"openHouses": [],
			"officeListName": "F A S REALTY INC.",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1925,
			"zipCode": "94501",
			"path": "alameda-ca/2014-santa-clara-ave-alameda-ca-94501-12_40156308/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -51000,
			"propertyId": "108677cd-28ba-417e-8450-b9364787df3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-06-18 20:11:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.769087,
				"lng": -122.251546,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2014 Santa Clara Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 49,
			"id": "9bee4c26-b022-42e6-b7f0-d2d4ba42785f",
			"tnImgPath": "https://pi.movoto.com/p/12/40972209_0_vEaEiV_p.jpeg",
			"listDate": "2021-10-25 00:00:00",
			"listingAgent": "Darlene Gardner",
			"listPrice": 1100000,
			"lotSize": 4998,
			"sqftTotal": 1716,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972209",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Windermere Bay Area Prop.",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94501",
			"path": "alameda-ca/2000-otis-dr-alameda-ca-94501/pid_l6tavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c617478d-7dac-4a22-b845-a120095559ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-25 21:06:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.76146,
				"lng": -122.256593,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2000 Otis Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 91,
			"id": "6eb0eb38-ff0e-4843-b128-d0b5b45fae7f",
			"tnImgPath": "https://pi.movoto.com/p/12/40966894_0_FbybBV_p.jpeg",
			"listDate": "2021-09-13 00:00:00",
			"listingAgent": "Steve Cressy",
			"listPrice": 2350000,
			"lotSize": 10000,
			"sqftTotal": 3739,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966894",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1938,
			"zipCode": "94501",
			"path": "alameda-ca/1636-central-ave-alameda-ca-94501/pid_rpnavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -150000,
			"propertyId": "3b2e7e56-728d-45a6-91b1-798b5fff64a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-09-13 16:31:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 00:00:00",
			"virtualTourLink": "http://1636CentralAvenue.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.770252,
				"lng": -122.257388,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1636 Central Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://1636CentralAvenue.com/mls"
		},
		{
			"closePrice": 1562000,
			"daysOnMovoto": 48,
			"id": "ecb430cf-1be8-4dc6-a05b-b0cd2aa57421",
			"tnImgPath": "https://pi.movoto.com/p/12/40972103_0_yNUmfz_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Gezhi Weng",
			"listPrice": 1399000,
			"lotSize": 2655,
			"sqftTotal": 2508,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972103",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "SUNRISE REALTY & FINANCING INC",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2016,
			"zipCode": "94501",
			"path": "alameda-ca/2759-5th-st-alameda-ca-94501-12_40810198/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4b1028d2-0e32-4e90-81c2-cad81656347c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-23 12:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.788696,
				"lng": -122.281605,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2759 5th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1562000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 70,
			"id": "343ab637-ee35-4322-82e0-6fefe669301f",
			"tnImgPath": "https://pi.movoto.com/p/12/40969335_0_7zNuFi_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Steve Cressy",
			"listPrice": 775000,
			"lotSize": 0,
			"sqftTotal": 1413,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969335",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1940,
			"zipCode": "94501",
			"path": "alameda-ca/1802-second-st-apt-c-alameda-ca-94501-12_40969335/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "034895fc-ed9d-4eac-bdb5-675159f465f4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-01 18:46:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://www.zillow.com/view-3d-home/4a39c80c-603c-4a44-94cd-3535a48cf3e3?setAttribution=mls&wl=true",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.777833,
				"lng": -122.289335,
				"zipcode": "94501",
				"subPremise": "APT C",
				"address": "1802 Second St #C"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.zillow.com/view-3d-home/4a39c80c-603c-4a44-94cd-3535a48cf3e3?setAttribution=mls&wl=true"
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 43,
			"id": "1010f8e7-360a-43dd-9148-1a9c49d242a4",
			"tnImgPath": "https://pi.movoto.com/p/12/40971930_0_mNERi6_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Tim Marr",
			"listPrice": 868000,
			"lotSize": 6900,
			"sqftTotal": 1230,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971930",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1914,
			"zipCode": "94501",
			"path": "alameda-ca/441-pacific-ave-alameda-ca-94501/pid_vcqavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0858e756-3e1b-4469-8432-4767487a26fc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-22 06:51:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.776612,
				"lng": -122.28396,
				"zipcode": "94501",
				"subPremise": "",
				"address": "441 Pacific Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 640000,
			"daysOnMovoto": 106,
			"id": "e22d583f-e2e7-4139-97e2-67dd6ac3b10b",
			"tnImgPath": "https://pi.movoto.com/p/12/40963717_0_qfQeYN_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Jonathan Kurniadi",
			"listPrice": 699000,
			"lotSize": 5100,
			"sqftTotal": 1002,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963717",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Kurniadi Realty",
			"photoCount": 11,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1945,
			"zipCode": "94501",
			"path": "alameda-ca/100-pacific-ave-alameda-ca-94501/pid_u2tavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "46d1e99c-f61f-40cb-8bfb-bc0c1e63706b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-08-19 06:16:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.776292,
				"lng": -122.290841,
				"zipcode": "94501",
				"subPremise": "",
				"address": "100 Pacific Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 640000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1295000,
			"daysOnMovoto": 0,
			"id": "dd37c056-5c1e-4274-b632-577467efec01",
			"tnImgPath": "https://pi.movoto.com/p/12/40975205_0_YM3Irf_p.jpeg",
			"listDate": "2021-12-01 00:00:00",
			"listingAgent": "Audrey DePinna",
			"listPrice": 1095000,
			"lotSize": 4480,
			"sqftTotal": 2083,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975205",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Patio Home",
			"yearBuilt": 1975,
			"zipCode": "94502",
			"path": "alameda-ca/3547-magnolia-dr-alameda-ca-94502-12_27454723/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "868b14e1-afb2-4bcd-b617-b50ed21c556a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-12-01 08:06:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.732186,
				"lng": -122.233415,
				"zipcode": "94502",
				"subPremise": "",
				"address": "3547 Magnolia Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1295000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1180000,
			"daysOnMovoto": 19,
			"id": "ed6d1cfd-a02a-4d35-b34a-7b66e425f768",
			"tnImgPath": "https://pi.movoto.com/p/12/40974131_0_fzRn2J_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Justine Francis",
			"listPrice": 998000,
			"lotSize": 5043,
			"sqftTotal": 2584,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974131",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 25,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1910,
			"zipCode": "94501",
			"path": "alameda-ca/1438-cottage-st-alameda-ca-94501/pid_ojlavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "315c822b-8361-49ec-b2e0-1382a557b1fa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-11-12 22:31:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": "https://listing.ewalk.com/ut/1438_Cottage_St.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.772015,
				"lng": -122.258764,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1438 Cottage St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listing.ewalk.com/ut/1438_Cottage_St.html"
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 42,
			"id": "32453e35-502f-4241-83b9-da45949a87ee",
			"tnImgPath": "https://pi.movoto.com/p/12/40971451_0_NqyffZ_p.jpeg",
			"listDate": "2021-10-19 00:00:00",
			"listingAgent": "Marilyn Schumacher",
			"listPrice": 725000,
			"lotSize": 3220,
			"sqftTotal": 1058,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971451",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Gold Natomas",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1915,
			"zipCode": "94501",
			"path": "alameda-ca/831-haight-ave-alameda-ca-94501-12_21014317/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -150000,
			"propertyId": "21cefeab-f2b6-4ea3-85a3-43aa5984e650",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-19 17:21:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 00:00:00",
			"virtualTourLink": "https://app.cloudpano.com/tours/oBNPVqMW9?mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.774321,
				"lng": -122.271215,
				"zipcode": "94501",
				"subPremise": "",
				"address": "831 Haight Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://app.cloudpano.com/tours/oBNPVqMW9?mls=1"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 67,
			"id": "346e5913-9209-44a3-a411-d53d1241d729",
			"tnImgPath": "https://pi.movoto.com/p/12/40968546_0_Mz7eNM_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Jeff Goodman",
			"listPrice": 1165000,
			"lotSize": 7500,
			"sqftTotal": 3101,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968546",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": null,
			"zipCode": "94501",
			"path": "alameda-ca/2057-san-jose-ave-alameda-ca-94501/pid_qpoavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -130000,
			"propertyId": "60084c3f-a073-4608-9133-f72f5a3e8be5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-09-24 20:31:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "http://cloutrealestate.gofullframe.com/ut/2057_San_Jose_Ave.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.764254,
				"lng": -122.252693,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2057 San Jose Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://cloutrealestate.gofullframe.com/ut/2057_San_Jose_Ave.html"
		},
		{
			"closePrice": 1410000,
			"daysOnMovoto": 73,
			"id": "a3cb9bc2-334f-464e-9e61-41e2b01ee1ad",
			"tnImgPath": "https://pi.movoto.com/p/12/40967702_0_e6fj3n_p.jpeg",
			"listDate": "2021-09-18 00:00:00",
			"listingAgent": "Nina Lim",
			"listPrice": 1510000,
			"lotSize": 6974,
			"sqftTotal": 3812,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967702",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1914,
			"zipCode": "94501",
			"path": "alameda-ca/2121-santa-clara-ave-alameda-ca-94501-12_40967702/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "be0fa5cf-15c9-402c-bbd0-ab3dd4dbd6a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-09-18 08:51:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.768373,
				"lng": -122.247996,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2121 Santa Clara Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1410000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 46,
			"id": "2d0dd925-849a-4acd-abbd-08f93f077cb1",
			"tnImgPath": "https://pi.movoto.com/p/12/40970866_0_yIQvqe_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Tim Marr",
			"listPrice": 898000,
			"lotSize": 0,
			"sqftTotal": 2104,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970866",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 14,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94501",
			"path": "alameda-ca/1217-buena-vista-ave-alameda-ca-94501/pid_45lavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0f970327-3349-483e-a42f-630bce44bf02",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-14 16:06:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.777094,
				"lng": -122.263727,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1217 Buena Vista Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1830000,
			"daysOnMovoto": 41,
			"id": "79d8ade6-fed2-4ffa-9ab2-5c8713da3e40",
			"tnImgPath": "https://pi.movoto.com/p/12/40970919_0_In7yvf_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Chih Wu",
			"listPrice": 1658000,
			"lotSize": 3150,
			"sqftTotal": 2476,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970919",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1990,
			"zipCode": "94502",
			"path": "alameda-ca/202-encounter-bay-alameda-ca-94502-12_23035181/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5b4aeada-5b17-469a-8089-ebd5eba70b74",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-14 19:36:53",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://www.202Encounter.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.739434,
				"lng": -122.248395,
				"zipcode": "94502",
				"subPremise": "",
				"address": "202 Encounter Bay"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.202Encounter.com/mls"
		},
		{
			"closePrice": 1765000,
			"daysOnMovoto": 25,
			"id": "0f534e60-1abd-406b-a01c-c7550690b36d",
			"tnImgPath": "https://pi.movoto.com/p/12/40972700_0_fYUNzB_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Ramesh Ramchandani",
			"listPrice": 1700000,
			"lotSize": 7500,
			"sqftTotal": 3600,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972700",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Panavista, Inc",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94501",
			"path": "alameda-ca/2233-clinton-ave-alameda-ca-94501-12_40972700/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c3a3c3e-3faa-463c-8b73-7dbe5f2141ea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-29 22:01:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.76134,
				"lng": -122.248506,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2233 Clinton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1765000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 655000,
			"daysOnMovoto": 39,
			"id": "2764c670-ddb2-4b84-997f-239462fd2a87",
			"tnImgPath": "https://pi.movoto.com/p/12/40970487_0_3ZNj3N_p.jpeg",
			"listDate": "2021-10-11 00:00:00",
			"listingAgent": "Damir Huskic",
			"listPrice": 629000,
			"lotSize": 34172,
			"sqftTotal": 1060,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970487",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1975,
			"zipCode": "94501",
			"path": "alameda-ca/2133-santa-clara-ave-apt-208-alameda-ca-94501-12_40970487/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bda1ac4b-ab3b-4ae1-8eb1-dddf548d05e3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-11 18:21:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.768202,
				"lng": -122.247406,
				"zipcode": "94501",
				"subPremise": "APT 208",
				"address": "2133 Santa Clara Ave #208"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 655000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 540000,
			"daysOnMovoto": 50,
			"id": "cd787fde-625e-4fba-aeb9-42358feb6434",
			"tnImgPath": "https://pi.movoto.com/p/12/40969225_0_bbAqIn_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Angela McIntyre",
			"listPrice": 540000,
			"lotSize": 0,
			"sqftTotal": 780,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969225",
			"bath": 1.5,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94501",
			"path": "alameda-ca/2394-mariner-square-drive-b6-alameda-ca-94501-12_40969225/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e7f14a3b-b586-418d-8000-234bdbffcf7e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-01 00:26:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2923103",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.789568,
				"lng": -122.276262,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2394 Mariner Square Drive B6"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 540000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2923103"
		},
		{
			"closePrice": 1201000,
			"daysOnMovoto": 16,
			"id": "e98ea469-2cf2-4632-bcc5-bec340508a32",
			"tnImgPath": "https://pi.movoto.com/p/110/421609142_0_zZnqqb_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Colleen McFerrin",
			"listPrice": 888000,
			"lotSize": 3550,
			"sqftTotal": 1194,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609142",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1900,
			"zipCode": "94501",
			"path": "alameda-ca/406-haight-ave-alameda-ca-94501-12_28460105/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "082bb9d3-7144-479e-bd30-f82d0d1fc4c9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-11-05 03:06:58",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": "http://www.406haight-ave-alameda.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.77428,
				"lng": -122.285481,
				"zipcode": "94501",
				"subPremise": "",
				"address": "406 Haight Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1201000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.406haight-ave-alameda.com"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 54,
			"id": "4c8a567a-33a4-4eec-a192-1c8243408f57",
			"tnImgPath": "https://pi.movoto.com/p/12/40968369_0_3zqBnb_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Denise Brady",
			"listPrice": 1495000,
			"lotSize": 6625,
			"sqftTotal": 2040,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968369",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1924,
			"zipCode": "94501",
			"path": "alameda-ca/3006-central-ave-alameda-ca-94501/pid_kljavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e6e0138c-8217-42c5-a1c5-6ef97b33b48f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-09-24 01:51:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://www.3006centralavenue.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.758884,
				"lng": -122.233415,
				"zipcode": "94501",
				"subPremise": "",
				"address": "3006 Central Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.3006centralavenue.com/mls"
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 49,
			"id": "0091ef8a-732e-45e5-9ba8-6ce9cc656b61",
			"tnImgPath": "https://pi.movoto.com/p/12/40968747_0_UJrQrE_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Carolina Tapia-Urzua",
			"listPrice": 850000,
			"lotSize": 1540,
			"sqftTotal": 1320,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968747",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1969,
			"zipCode": "94502",
			"path": "alameda-ca/3231-santa-cruz-ln-alameda-ca-94502/pid_totavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4af5d63f-fef5-4c87-94ef-e6e6a14909e7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-09-27 17:31:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.732961,
				"lng": -122.242614,
				"zipcode": "94502",
				"subPremise": "",
				"address": "3231 Santa Cruz Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1148000,
			"daysOnMovoto": 55,
			"id": "018c8388-8538-4831-a8b8-784eb814ac61",
			"tnImgPath": "https://pi.movoto.com/p/12/40968012_0_yaV6Ny_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Guy Blume",
			"listPrice": 1148000,
			"lotSize": 3596,
			"sqftTotal": 1780,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968012",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1964,
			"zipCode": "94501",
			"path": "alameda-ca/351-laguna-vis-alameda-ca-94501/pid_dltavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "54150784-3069-47f7-85c3-de208ba2ae2c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-09-22 05:01:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": "http://www.351lagunavista.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.752917,
				"lng": -122.243706,
				"zipcode": "94501",
				"subPremise": "",
				"address": "351 Laguna Vis"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1148000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.351lagunavista.com/?mls"
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 64,
			"id": "5d5d2fe1-ff74-4d90-811b-1819ea23efa0",
			"tnImgPath": "https://pi.movoto.com/p/12/40966857_0_i7IfFJ_p.jpeg",
			"listDate": "2021-09-12 00:00:00",
			"listingAgent": "Michael Curia",
			"listPrice": 2188000,
			"lotSize": 12350,
			"sqftTotal": 5943,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966857",
			"bath": 4,
			"bed": 13,
			"openHouses": [],
			"officeListName": "Community Realty & Investment",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1902,
			"zipCode": "94501",
			"path": "alameda-ca/510-central-ave-alameda-ca-94501/pid_dosavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "96c4a65b-b7e2-41fc-9b19-85958542f6f6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-09-12 17:46:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.771328,
				"lng": -122.281915,
				"zipcode": "94501",
				"subPremise": "",
				"address": "510 Central Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 87,
			"id": "546e9c29-d314-4292-92e6-bb2b004ce654",
			"tnImgPath": "https://pi.movoto.com/p/12/40962856_0_BemfYn_p.jpeg",
			"listDate": "2021-08-20 00:00:00",
			"listingAgent": "Martin Cai",
			"listPrice": 1349000,
			"lotSize": 4400,
			"sqftTotal": 1575,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962856",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94501",
			"path": "alameda-ca/3276-washington-st-alameda-ca-94501/pid_l8eavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50000,
			"propertyId": "c52ccb15-502a-42db-972d-2f70fe9e68a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-08-20 08:31:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 00:00:00",
			"virtualTourLink": "https://sites.walkintour.com/spw/tours/6003/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.752163,
				"lng": -122.234301,
				"zipcode": "94501",
				"subPremise": "",
				"address": "3276 Washington St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://sites.walkintour.com/spw/tours/6003/unbranded"
		},
		{
			"closePrice": 879123,
			"daysOnMovoto": 216,
			"id": "3b6ac77d-0d52-4529-9d22-b323ebda36eb",
			"tnImgPath": "https://pi.movoto.com/p/12/40945321_0_m7n2YE_p.jpeg",
			"listDate": "2021-04-13 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 869003,
			"lotSize": 1102,
			"sqftTotal": 1102,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40945321",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 7,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94501",
			"path": "alameda-ca/2819-tradewind-ct-alameda-ca-94501-12_40945321/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "70af7da2-94d7-41f7-8702-0c99e1c3752a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-04-14 01:36:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"zipcode": "94501",
				"subPremise": "",
				"address": "2819 Tradewind Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 879123,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 890000,
			"daysOnMovoto": 29,
			"id": "61ebb406-9e41-4779-9397-efa7b820c7d8",
			"tnImgPath": "https://pi.movoto.com/p/12/40970906_0_VbFINu_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Michael Lane",
			"listPrice": 799000,
			"lotSize": 1540,
			"sqftTotal": 1340,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970906",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "94502",
			"path": "alameda-ca/3164-fiji-ln-alameda-ca-94502/pid_8dvavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f9cbe82c-9aef-4707-b154-f2be6faeae19",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-14 18:46:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://www.3164fiji.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.73178,
				"lng": -122.244786,
				"zipcode": "94502",
				"subPremise": "",
				"address": "3164 Fiji Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 890000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.3164fiji.com/mls"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 34,
			"id": "5bf18957-a74a-4ef7-a8d7-e8ee7d25fe4c",
			"tnImgPath": "https://pi.movoto.com/p/12/40970434_0_bqI7vi_p.jpeg",
			"listDate": "2021-10-09 00:00:00",
			"listingAgent": "Ringo Liu",
			"listPrice": 999000,
			"lotSize": 4200,
			"sqftTotal": 1470,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970434",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1937,
			"zipCode": "94501",
			"path": "alameda-ca/1805-central-ave-alameda-ca-94501-12_40260787/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f53a4d1b-c182-42b7-8105-379ceed7a206",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-10 05:46:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-09 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=gg2B3pG2s9c&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.769692,
				"lng": -122.254809,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1805 Central Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=gg2B3pG2s9c&brand=0"
		},
		{
			"closePrice": 915000,
			"daysOnMovoto": 36,
			"id": "5e39fb3c-78f8-444f-bc97-a4ec677e5333",
			"tnImgPath": "https://pi.movoto.com/p/12/40970125_0_veIYfE_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Kate McCaffrey",
			"listPrice": 795000,
			"lotSize": 2450,
			"sqftTotal": 1320,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970125",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1910,
			"zipCode": "94501",
			"path": "alameda-ca/2200-san-jose-ave-alameda-ca-94501-12_21016115/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f774d322-b5b7-4b47-aa8a-a24547c34563",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-07 23:21:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.762353,
				"lng": -122.249316,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2200 San Jose Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 915000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 419000,
			"daysOnMovoto": 49,
			"id": "3b990ff1-c23d-43d4-8a65-f994f92dd915",
			"tnImgPath": "https://pi.movoto.com/p/12/40968544_0_JnFuQQ_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Rose Krinks",
			"listPrice": 419000,
			"lotSize": 101260,
			"sqftTotal": 772,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968544",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94501",
			"path": "alameda-ca/965-shorepoint-ct-204-alameda-ca-94501/pid_t2uavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6a712de4-53f4-4da0-9610-ce9530c96aa4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-09-24 23:01:53",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=pPZfKiFDQRL&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.762802,
				"lng": -122.268957,
				"zipcode": "94501",
				"subPremise": "APT 204",
				"address": "965 Shorepoint Ct #204"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 419000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=pPZfKiFDQRL&brand=0"
		},
		{
			"closePrice": 720000,
			"daysOnMovoto": 28,
			"id": "e15f625e-dbff-4ed9-beb6-697d9de7f8e5",
			"tnImgPath": "https://pi.movoto.com/p/12/40970074_0_mzar7A_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Michael Braillard",
			"listPrice": 689000,
			"lotSize": 10383,
			"sqftTotal": 1120,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970074",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Caldecott Properties",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1969,
			"zipCode": "94501",
			"path": "alameda-ca/2115-central-ave-5-alameda-ca-94501-12_40699963/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0fd2cef3-b2a3-444c-9242-bab8383cece3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-14 01:41:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "http://tours.caldecott.com/2115centralavenueunit5/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.767059,
				"lng": -122.24916,
				"zipcode": "94501",
				"subPremise": "APT 5",
				"address": "2115 Central Ave #5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://tours.caldecott.com/2115centralavenueunit5/?mls"
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 47,
			"id": "559cc34d-60b4-45af-aecb-51d184cfc6c2",
			"tnImgPath": "https://pi.movoto.com/p/12/40968611_0_UZqfeB_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Sophia Niu",
			"listPrice": 1798000,
			"lotSize": 10400,
			"sqftTotal": 3175,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968611",
			"bath": 4.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1909,
			"zipCode": "94501",
			"path": "alameda-ca/2154-santa-clara-ave-alameda-ca-94501-12_22023501/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f979f0fa-ee5c-4703-8efa-2e132d0d7905",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-25 01:21:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/613489564",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.76721,
				"lng": -122.247159,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2154 Santa Clara Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/613489564"
		},
		{
			"closePrice": 1540000,
			"daysOnMovoto": 48,
			"id": "0e527682-8936-4e9f-8687-20e0db4f77e0",
			"tnImgPath": "https://pi.movoto.com/p/12/40968396_0_r2jm7Q_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Sharon Alva",
			"listPrice": 1150000,
			"lotSize": 4004,
			"sqftTotal": 1760,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968396",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1906,
			"zipCode": "94501",
			"path": "alameda-ca/1109-mound-st-alameda-ca-94501-12_22011387/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8b921f73-5e68-4434-ab13-bad7765639ec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-24 04:11:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=UMBwmqGDJH9&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.756043,
				"lng": -122.238722,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1109 Mound St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1540000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=UMBwmqGDJH9&brand=0"
		},
		{
			"closePrice": 1052000,
			"daysOnMovoto": 25,
			"id": "cb4dce71-10f3-441b-bc8b-571e1451b99a",
			"tnImgPath": "https://pi.movoto.com/p/12/40971190_0_ryAZa3_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Mindy Hart-Shaw",
			"listPrice": 949000,
			"lotSize": 5500,
			"sqftTotal": 1271,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971190",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94501",
			"path": "alameda-ca/1272-rosewood-way-alameda-ca-94501/pid_1psavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cede1ee1-96ac-4d59-83a6-08152080f693",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-10-15 22:51:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.763058,
				"lng": -122.262708,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1272 Rosewood Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1052000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 39,
			"id": "039cbacb-fe19-4aa6-b7ab-74cd28ea6235",
			"tnImgPath": "https://pi.movoto.com/p/12/40969277_0_Rjn3Rr_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Dania R. Alvarez",
			"listPrice": 1765000,
			"lotSize": 4772,
			"sqftTotal": 2209,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969277",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1984,
			"zipCode": "94502",
			"path": "alameda-ca/375-victoria-bay-alameda-ca-94502/pid_e6vavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3b131387-31b0-488e-8b79-eeb4a5c0f8b9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-10-01 14:01:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.739438,
				"lng": -122.239221,
				"zipcode": "94502",
				"subPremise": "",
				"address": "375 Victoria Bay"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 21,
			"id": "7312995c-ac33-4b46-a9b0-2bc35420a9f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40971109_0_byQNju_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Hanna Kerns",
			"listPrice": 895000,
			"lotSize": 2100,
			"sqftTotal": 1198,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971109",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RED OAK REALTY",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "94502",
			"path": "alameda-ca/9-bordwell-ct-alameda-ca-94502-12_21000531/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1e4e044-b4a1-451b-aa78-a575505eb762",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-15 17:31:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": "https://www.9bordwell.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.736146,
				"lng": -122.249636,
				"zipcode": "94502",
				"subPremise": "",
				"address": "9 Bordwell Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.9bordwell.com/mls"
		},
		{
			"closePrice": 1175000,
			"daysOnMovoto": 25,
			"id": "87c05dcc-da3d-42c2-bb22-a767be09cc23",
			"tnImgPath": "https://pi.movoto.com/p/12/40970463_0_Vjy36A_p.jpeg",
			"listDate": "2021-10-11 00:00:00",
			"listingAgent": "Devon Sherak",
			"listPrice": 995000,
			"lotSize": 403366,
			"sqftTotal": 1964,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970463",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1980,
			"zipCode": "94501",
			"path": "alameda-ca/1316-crown-dr-alameda-ca-94501-12_28456721/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a5df8520-f140-4218-9b05-77817a9a01a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-11 14:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.769505,
				"lng": -122.280895,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1316 Crown Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 21,
			"id": "1824912b-da16-4b11-9116-619b1a3ebf78",
			"tnImgPath": "https://pi.movoto.com/p/12/40970865_0_FJF7fq_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Tim Marr",
			"listPrice": 948000,
			"lotSize": 6250,
			"sqftTotal": 2104,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970865",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 18,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94501",
			"path": "alameda-ca/1209-buena-vista-ave-alameda-ca-94501/pid_r6lavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -947052000,
			"propertyId": "787cd52f-965b-4b9d-8020-18bb8df426ea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-14 16:06:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.777154,
				"lng": -122.263838,
				"zipcode": "94501",
				"subPremise": "",
				"address": "1209 Buena Vista Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 1221790,
			"daysOnMovoto": 51,
			"id": "b96fd5fb-3a08-4fc4-9458-e1be6bbd78b7",
			"tnImgPath": "https://pi.movoto.com/p/12/40967012_0_jqB6Je_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Janis Erion",
			"listPrice": 1195000,
			"lotSize": 2765,
			"sqftTotal": 1679,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967012",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2000,
			"zipCode": "94501",
			"path": "alameda-ca/no-address-alameda-ca-94501-12_40967012/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bdcdab6b-dc88-4640-904e-b26c63be7d6d",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-14 14:41:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 0,
				"lng": 0,
				"zipcode": "94501",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "2 Sanderling Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1221790,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 41,
			"id": "d59b06df-e5c5-4621-ba03-d731489da35a",
			"tnImgPath": "https://pi.movoto.com/p/12/40968274_0_nz66YB_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "David Gunderman",
			"listPrice": 1195000,
			"lotSize": 5255,
			"sqftTotal": 2169,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968274",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1980,
			"zipCode": "94501",
			"path": "alameda-ca/2904-central-ave-alameda-ca-94501/pid_4pjavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "06e4668d-4e0b-4331-b744-c906ff562a7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-23 19:46:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=rYT61QBtzPD&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.759461,
				"lng": -122.234213,
				"zipcode": "94501",
				"subPremise": "",
				"address": "2904 Central Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=rYT61QBtzPD&mls=1"
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 40,
			"id": "74a4efb3-71a4-4b20-910a-f7227315b363",
			"tnImgPath": "https://pi.movoto.com/p/12/40968246_0_Z3yV2b_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Wendy Sanda",
			"listPrice": 1195000,
			"lotSize": 5500,
			"sqftTotal": 1758,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968246",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1929,
			"zipCode": "94501",
			"path": "alameda-ca/3265-encinal-ave-alameda-ca-94501-12_23029545/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7997f347-ef37-4333-b813-c3944b0da660",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-23 17:56:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://3265encinalavenue.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.754924,
				"lng": -122.231731,
				"zipcode": "94501",
				"subPremise": "",
				"address": "3265 Encinal Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://3265encinalavenue.com/mls"
		},
		{
			"closePrice": 1795000,
			"daysOnMovoto": 43,
			"id": "64dfc552-ae05-4e93-8c9b-8bf65e03809c",
			"tnImgPath": "https://pi.movoto.com/p/12/40967776_0_jIRNyU_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Ramesh Ramchandani",
			"listPrice": 1790000,
			"lotSize": 5000,
			"sqftTotal": 2256,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967776",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Panavista, Inc",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94502",
			"path": "alameda-ca/111-sherwood-ln-alameda-ca-94502/pid_6muavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "22d5fc65-5cd8-46f6-b3e8-7bb43513195d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-20 06:01:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 00:00:00",
			"virtualTourLink": "https://listings.nextdoorphotos.com/111sherwoodln/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.746343,
				"lng": -122.250433,
				"zipcode": "94502",
				"subPremise": "",
				"address": "111 Sherwood Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1795000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.nextdoorphotos.com/111sherwoodln/?mls"
		},
		{
			"closePrice": 628000,
			"daysOnMovoto": 53,
			"id": "4b719abe-5210-4a44-8e9f-6db82871071d",
			"tnImgPath": "https://pi.movoto.com/p/12/40966556_0_6AFaMB_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Alex Mak",
			"listPrice": 599000,
			"lotSize": 0,
			"sqftTotal": 731,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966556",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1940,
			"zipCode": "94501",
			"path": "alameda-ca/307-pacific-ave-alameda-ca-94501-12_26445947/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e1249c95-14cb-40e6-8c83-545e24901fc9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-10 07:41:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.77664,
				"lng": -122.287665,
				"zipcode": "94501",
				"subPremise": "",
				"address": "307 Pacific Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 628000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 66,
			"id": "9437e558-febd-4141-acfc-7e522e646931",
			"tnImgPath": "https://pi.movoto.com/p/12/40965123_0_eFrrJb_p.jpeg",
			"listDate": "2021-08-28 00:00:00",
			"listingAgent": "Liz Rush",
			"listPrice": 985000,
			"lotSize": 6101,
			"sqftTotal": 3944,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965123",
			"bath": 4,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94501",
			"path": "alameda-ca/762-stewart-ct-alameda-ca-94501-12_40110852/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea634dcd-4373-4805-b20b-e921cbc5184d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-08-28 23:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-28 00:00:00",
			"virtualTourLink": "http://www.762stewartcourt.com/?mls=",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Alameda",
				"lat": 37.778742,
				"lng": -122.273696,
				"zipcode": "94501",
				"subPremise": "",
				"address": "762 Stewart Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.762stewartcourt.com/?mls="
		}
	]