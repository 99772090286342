exports.HILLSBOROUGH_LISTINGS = [
		{
			"closePrice": 3800000,
			"daysOnMovoto": 23,
			"id": "ea0c998d-c8d4-45ae-9487-00d0f3d1c32b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872918_0_7FnejI_p.jpeg",
			"listDate": "2021-12-27 00:00:00",
			"listingAgent": "Sophie Tsang",
			"listPrice": 3498000,
			"lotSize": 14749,
			"sqftTotal": 3585,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872918",
			"bath": 5.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 7,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1962,
			"zipCode": "94010",
			"path": "hillsborough-ca/1420-carlton-rd-hillsborough-ca-94010-100_ml81872918/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5a04c0ed-8fd1-4d40-bbc1-7426fa6a30c4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-12-27 22:02:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.546677,
				"lng": -122.359858,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1420 Carlton Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2985000,
			"daysOnMovoto": 153,
			"id": "ae6af06f-d171-4f20-ba72-36b5efe0c98b",
			"tnImgPath": "https://pi.movoto.com/p/110/421573392_0_fIN3au_p.jpeg",
			"listDate": "2021-07-13 00:00:00",
			"listingAgent": "Veronica Tran",
			"listPrice": 3000000,
			"lotSize": 22952,
			"sqftTotal": 2100,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421573392",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Tran Real Estate",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1962,
			"zipCode": "94010",
			"path": "hillsborough-ca/316-alberta-way-hillsborough-ca-94010-100_ml80906639/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -199000,
			"propertyId": "cafaa550-d441-45a1-b9c0-b74a99adc418",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-07-13 22:56:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.548916,
				"lng": -122.364049,
				"zipcode": "94010",
				"subPremise": "",
				"address": "316 Alberta Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2985000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3500000,
			"daysOnMovoto": 13,
			"id": "d2838277-e9f8-4eaf-8e28-08ab3a30a3aa",
			"tnImgPath": "https://pi.movoto.com/p/110/421610285_0_fMQjjZ_p.jpeg",
			"listDate": "2021-11-06 00:00:00",
			"listingAgent": "Rebecca Graffigna",
			"listPrice": 3280000,
			"lotSize": 13939,
			"sqftTotal": 2685,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421610285",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International",
			"photoCount": 10,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1946,
			"zipCode": "94010",
			"path": "hillsborough-ca/1220-marlborough-rd-hillsborough-ca-94010-100_ml80350584/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ef8aac25-1647-4a71-9690-2bd8a766d57d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-11-06 15:41:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.552062,
				"lng": -122.356133,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1220 Marlborough Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4220000,
			"daysOnMovoto": 85,
			"id": "e133067b-30cc-421f-8b84-a1213a599048",
			"tnImgPath": "https://pi.movoto.com/p/110/421588432_0_22VvRr_p.jpeg",
			"listDate": "2021-08-25 00:00:00",
			"listingAgent": "Burton Louie",
			"listPrice": 4500000,
			"lotSize": 13939,
			"sqftTotal": 3620,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421588432",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 69,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1924,
			"zipCode": "94010",
			"path": "hillsborough-ca/304-brentwood-rd-hillsborough-ca-94010-100_ml81643007/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5d748aec-c5b0-48b6-b10d-f917263f71f5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-08-25 23:51:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-25 00:00:00",
			"virtualTourLink": "http://www.304brentwoodroad.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.559857,
				"lng": -122.344372,
				"zipcode": "94010",
				"subPremise": "",
				"address": "304 Brentwood Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.304brentwoodroad.com"
		},
		{
			"closePrice": 9500000,
			"daysOnMovoto": 84,
			"id": "46970302-511d-4217-adc9-feb460f89b7f",
			"tnImgPath": "https://pi.movoto.com/p/12/40955423_0_BjENfz_p.jpeg",
			"listDate": "2021-06-21 00:00:00",
			"listingAgent": "Douglas Manful",
			"listPrice": 9995000,
			"lotSize": 29703,
			"sqftTotal": 9103,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955423",
			"bath": 7,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Black Book International, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94010",
			"path": "hillsborough-ca/25-downey-way-hillsborough-ca-94010-100_ml80649520/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0a3bc7af-91aa-46c1-9c54-f659077d1774",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-06-22 03:01:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.576555,
				"lng": -122.373377,
				"zipcode": "94010",
				"subPremise": "",
				"address": "25 Downey Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 9500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 8575000,
			"daysOnMovoto": 775,
			"id": "eeeb1aeb-bf18-4bf8-8fc9-0f6dcbee651e",
			"tnImgPath": "https://pi.movoto.com/p/12/ML81745764_0_uiaeIq_p.jpeg",
			"listDate": "2019-04-05 00:00:00",
			"listingAgent": "Stanley Lo",
			"listPrice": 8990000,
			"lotSize": 33106,
			"sqftTotal": 9355,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81745764",
			"bath": 8,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1892,
			"zipCode": "94010",
			"path": "hillsborough-ca/105-baywood-ave-hillsborough-ca-94010-100_ml80949669/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31eaa320-e2b8-4f2b-9abf-d026a3a966d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-04-23 07:45:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2019-04-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.561264,
				"lng": -122.330579,
				"zipcode": "94010",
				"subPremise": "",
				"address": "105 Baywood Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 8575000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3550000,
			"daysOnMovoto": 195,
			"id": "fc030416-13c0-4087-95d8-0be17e2d7210",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81813438_0_MzUi3v_p.jpeg",
			"listDate": "2020-09-30 00:00:00",
			"listingAgent": "Stanley Lo",
			"listPrice": 3680000,
			"lotSize": 24703,
			"sqftTotal": 4157,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81813438",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1988,
			"zipCode": "94010",
			"path": "hillsborough-ca/15-buckeye-ct-hillsborough-ca-94010-100_ml80227549/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -308000,
			"propertyId": "37f76180-c8e1-4168-a8d3-412e5000d45f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2020-09-30 22:07:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.550702,
				"lng": -122.342067,
				"zipcode": "94010",
				"subPremise": "",
				"address": "15 Buckeye Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3500000,
			"daysOnMovoto": 730,
			"id": "e849e420-fb5f-4eed-90ec-5aadabd07890",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81746253_0_nzrvZz_p.jpeg",
			"listDate": "2019-04-09 11:31:31",
			"listingAgent": "Stanley Lo",
			"listPrice": 4880000,
			"lotSize": 29926,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81746253",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 13,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94010",
			"path": "hillsborough-ca/65-bridge-rd-hillsborough-ca-94010-100_ml80722016/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d8472bea-0896-45db-88ce-50c28a80f21c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2019-04-09 18:37:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2019-04-09 11:31:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.562401,
				"lng": -122.332174,
				"zipcode": "94010",
				"subPremise": "",
				"address": "65 Bridge Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4131000,
			"daysOnMovoto": 35,
			"id": "15949884-4ca5-4a4b-bb01-1e93597fedd9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832217_0_ZRFRiB_p.jpeg",
			"listDate": "2021-03-03 10:01:45",
			"listingAgent": "Cynthia Smith",
			"listPrice": 3398000,
			"lotSize": 28750,
			"sqftTotal": 2430,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832217",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1960,
			"zipCode": "94010",
			"path": "hillsborough-ca/25-creekwood-way-hillsborough-ca-94010-100_ml81832217/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dd35ba58-2fb2-48d7-bb2c-8509735b5a1e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-03 18:07:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 10:01:45",
			"virtualTourLink": "Http://25Creekwood.com/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.554689,
				"lng": -122.338075,
				"zipcode": "94010",
				"subPremise": "",
				"address": "25 Creekwood Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4131000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://25Creekwood.com/unbranded"
		},
		{
			"closePrice": 10450000,
			"daysOnMovoto": 41,
			"id": "b22d5c9d-49e4-4910-b1a2-cbc10c49ec30",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830263_0_iVYNVn_p.jpeg",
			"listDate": "2021-02-17 20:25:02",
			"listingAgent": "The Laugesen Team",
			"listPrice": 10900000,
			"lotSize": 27948,
			"sqftTotal": 7020,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830263",
			"bath": 7,
			"bed": 6,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 83,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "94010",
			"path": "hillsborough-ca/55-aster-ave-hillsborough-ca-94010-100_ml81043293/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8185a6af-86ec-4299-bcfc-b945416cf252",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2021-02-18 04:27:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 20:25:02",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.571069,
				"lng": -122.354936,
				"zipcode": "94010",
				"subPremise": "",
				"address": "55 Aster Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 10450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3430000,
			"daysOnMovoto": 35,
			"id": "b5e7e421-7808-4ffa-86f1-9c85168c9f86",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830484_0_7zu3VN_p.jpeg",
			"listDate": "2021-02-19 00:00:00",
			"listingAgent": "Carmen Miranda",
			"listPrice": 3188000,
			"lotSize": 94525,
			"sqftTotal": 3020,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830484",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1980,
			"zipCode": "94010",
			"path": "hillsborough-ca/1105-lakeview-dr-hillsborough-ca-94010-100_ml81780403/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bf862daf-79cb-4b17-b1eb-709c064d7468",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2021-02-19 15:47:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/513746821",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.534484,
				"lng": -122.351358,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1105 Lakeview Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3430000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/513746821"
		},
		{
			"closePrice": 5850000,
			"daysOnMovoto": 61,
			"id": "d15ffc95-e3e4-44b9-b9ee-d11f64a19e22",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826818_0_Ui6YNq_p.jpeg",
			"listDate": "2021-01-22 10:47:09",
			"listingAgent": "Austin Herman",
			"listPrice": 5998000,
			"lotSize": 18809,
			"sqftTotal": 3650,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826818",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1946,
			"zipCode": "94010",
			"path": "hillsborough-ca/1715-forest-view-ave-hillsborough-ca-94010-100_ml89817420/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ce4ebb6-46f1-4794-a3a9-4636b91e058e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-01-22 18:52:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-22 10:47:09",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.579592,
				"lng": -122.362763,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1715 Forest View Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 7400000,
			"daysOnMovoto": 45,
			"id": "de979308-aa42-4347-9649-2e4fa7ee4e38",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828634_0_Jeq7J2_p.jpeg",
			"listDate": "2021-02-05 11:41:30",
			"listingAgent": "Geoffrey Nelson",
			"listPrice": 7388000,
			"lotSize": 33607,
			"sqftTotal": 5280,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828634",
			"bath": 6.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1951,
			"zipCode": "94010",
			"path": "hillsborough-ca/480-pullman-rd-hillsborough-ca-94010-100_ml81425789/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c9f9d77a-321d-4882-9584-e472ab19d4e4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-23 00:00:00",
			"createdAt": "2021-02-05 19:51:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-05 11:41:30",
			"virtualTourLink": "Https://hillsboroughblog.com/480-Pullman-Hillsborough",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.55634,
				"lng": -122.367708,
				"zipcode": "94010",
				"subPremise": "",
				"address": "480 Pullman Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://hillsboroughblog.com/480-Pullman-Hillsborough"
		},
		{
			"closePrice": 6958000,
			"daysOnMovoto": 15,
			"id": "6b67623d-dad6-46d8-9f6e-041fa3d6eb17",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832218_0_Na2RE6_p.jpeg",
			"listDate": "2021-03-03 10:05:00",
			"listingAgent": "Geoffrey Nelson",
			"listPrice": 6998000,
			"lotSize": 21837,
			"sqftTotal": 4000,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832218",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94010",
			"path": "hillsborough-ca/40-genevra-rd-hillsborough-ca-94010-100_ml89923286/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f4e40d79-db3a-4fa8-b212-f03b0840e7ba",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-03-03 18:07:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 10:05:00",
			"virtualTourLink": "Http://www.40genevra.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.574099,
				"lng": -122.355762,
				"zipcode": "94010",
				"subPremise": "",
				"address": "40 Genevra Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6958000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.40genevra.com"
		},
		{
			"closePrice": 4700000,
			"daysOnMovoto": 28,
			"id": "eb67c64e-be0b-42ea-816a-13f8997b001b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830323_0_fe2BVZ_p.jpeg",
			"listDate": "2021-02-18 10:25:44",
			"listingAgent": "The Patty Dwyer Group",
			"listPrice": 4500000,
			"lotSize": 32234,
			"sqftTotal": 4445,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830323",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1933,
			"zipCode": "94010",
			"path": "hillsborough-ca/1210-kenilworth-rd-hillsborough-ca-94010-100_ml81813737/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fc08832c-29f0-4afd-babf-b75a0004718b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-18 18:32:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 10:25:44",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2840333",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.553202,
				"lng": -122.354347,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1210 Kenilworth Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2840333"
		},
		{
			"closePrice": 5650000,
			"daysOnMovoto": 31,
			"id": "d9aa25b4-c61f-43fd-830b-84f7fa84442e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829939_0_MNvruu_p.jpeg",
			"listDate": "2021-02-15 17:47:44",
			"listingAgent": "DeLeon Team",
			"listPrice": 5488000,
			"lotSize": 29534,
			"sqftTotal": 4680,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829939",
			"bath": 5.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2001,
			"zipCode": "94010",
			"path": "hillsborough-ca/16-farm-ln-hillsborough-ca-94010-202_ml81594343/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cf327940-d03b-4d6a-af2e-23a85f9cc376",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-25 19:02:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-15 17:47:44",
			"virtualTourLink": "https://player.vimeo.com/video/510394402",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.561582,
				"lng": -122.359427,
				"zipcode": "94010",
				"subPremise": "",
				"address": "16 Farm Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/510394402"
		},
		{
			"closePrice": 3405000,
			"daysOnMovoto": 162,
			"id": "b06d7faf-be6f-49e9-a0e3-36871d1c6010",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81814219_0_ZUUR6m_p.jpeg",
			"listDate": "2020-10-05 14:34:45",
			"listingAgent": "Robert Applegate",
			"listPrice": 3500000,
			"lotSize": 18696,
			"sqftTotal": 3710,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81814219",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1964,
			"zipCode": "94010",
			"path": "hillsborough-ca/1255-la-cumbre-rd-hillsborough-ca-94010-100_ml81814219/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -350000,
			"propertyId": "b9149de7-566e-4de3-87dc-91cee0f4d499",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-17 00:00:00",
			"createdAt": "2020-10-05 21:37:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-05 14:34:45",
			"virtualTourLink": "Http://www.1255lacumbre.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.55662,
				"lng": -122.356117,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1255 La Cumbre Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3405000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.1255lacumbre.com/?mls"
		},
		{
			"closePrice": 7550000,
			"daysOnMovoto": 23,
			"id": "c2a72192-3547-49b7-83e0-29e94af62fb5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830616_0_fAjmBZ_p.jpeg",
			"listDate": "2021-02-19 14:56:15",
			"listingAgent": "Jim Arbeed",
			"listPrice": 7195000,
			"lotSize": 22952,
			"sqftTotal": 4120,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830616",
			"bath": 6.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 63,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1979,
			"zipCode": "94010",
			"path": "hillsborough-ca/1460-crystal-dr-hillsborough-ca-94010-100_ml81830616/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5f2acd8b-39a3-40c0-9752-4bed2b107e7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-15 00:00:00",
			"createdAt": "2021-02-19 23:02:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 14:56:15",
			"virtualTourLink": "Https://1460crystal.com/1781794?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.536228,
				"lng": -122.358055,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1460 Crystal Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://1460crystal.com/1781794?idx=1"
		},
		{
			"closePrice": 32000,
			"daysOnMovoto": 1472,
			"id": "744b4548-976e-4215-a30a-facacd1b170d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81639628_0_EuJNJ6_p.jpeg",
			"listDate": "2017-02-21 16:03:09",
			"listingAgent": "Max Reinhardt",
			"listPrice": 99000,
			"lotSize": 2962,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81639628",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway HomeServices DrysdaleProperties",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land - Residential",
			"yearBuilt": null,
			"zipCode": "94010",
			"path": "hillsborough-ca/0-chiltern-rd-hillsborough-ca-94010-100_ml81639628/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dc28d232-5430-4eb5-871e-805a1317b932",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-05 00:00:00",
			"createdAt": "2017-02-22 00:17:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2017-02-21 16:03:09",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"zipcode": "94010",
				"subPremise": "",
				"address": "0 Chiltern Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 32000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3598888,
			"daysOnMovoto": 48,
			"id": "d7e9aaad-c040-4ebf-97ec-312e372ce528",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825378_0_JAMURY_p.jpeg",
			"listDate": "2021-01-11 17:11:09",
			"listingAgent": "Stanley Lo",
			"listPrice": 3688000,
			"lotSize": 19166,
			"sqftTotal": 3702,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825378",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1971,
			"zipCode": "94010",
			"path": "hillsborough-ca/35-knollcrest-rd-hillsborough-ca-94010-100_ml89836060/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f5401a62-f37b-47ff-ad8f-6de97b7a3a82",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-01 00:00:00",
			"createdAt": "2021-01-12 01:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-11 17:11:09",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.55015,
				"lng": -122.35874,
				"zipcode": "94010",
				"subPremise": "",
				"address": "35 Knollcrest Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3598888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3450000,
			"daysOnMovoto": 125,
			"id": "b9e78bed-a362-433f-9fc8-d1c968d90b93",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81817186_0_eZaJMQ_p.jpeg",
			"listDate": "2020-10-23 15:07:07",
			"listingAgent": "Mary Bee",
			"listPrice": 3699000,
			"lotSize": 51836,
			"sqftTotal": 4420,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81817186",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1989,
			"zipCode": "94010",
			"path": "hillsborough-ca/1020-lakeview-dr-hillsborough-ca-94010-100_ml81625993/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a2c1d38-67e0-4483-8e97-93988a5cfb18",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-26 00:00:00",
			"createdAt": "2020-10-23 22:17:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-23 15:07:07",
			"virtualTourLink": "Https://tours.robertbarbuttiphotography.com/1725015?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.536057,
				"lng": -122.351265,
				"zipcode": "94010",
				"subPremise": "",
				"address": "1020 Lakeview Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.robertbarbuttiphotography.com/1725015?idx=1"
		},
		{
			"closePrice": 5600000,
			"daysOnMovoto": 20,
			"id": "e55019ae-9718-4390-bd30-58b08400bbd6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827508_0_nrev7j_p.jpeg",
			"listDate": "2021-01-28 10:09:14",
			"listingAgent": "Lizi Tabet",
			"listPrice": 5788000,
			"lotSize": 17015,
			"sqftTotal": 3790,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827508",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 60,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1936,
			"zipCode": "94010",
			"path": "hillsborough-ca/901-baileyana-rd-hillsborough-ca-94010-100_ml81517020/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e934805d-f8a2-4589-82d0-e38f4c7eac31",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-18 00:00:00",
			"createdAt": "2021-01-28 18:16:55",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-28 10:09:14",
			"virtualTourLink": "Https://www.901baileyana.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.574388,
				"lng": -122.377066,
				"zipcode": "94010",
				"subPremise": "",
				"address": "901 Baileyana Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.901baileyana.com/mls"
		},
		{
			"closePrice": 6000000,
			"daysOnMovoto": 40,
			"id": "015afef6-fc42-4916-b8fd-795063dd7686",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824999_0_areaMa_p.jpeg",
			"listDate": "2021-01-08 06:40:23",
			"listingAgent": "Anthony Galli",
			"listPrice": 6200000,
			"lotSize": 28096,
			"sqftTotal": 6260,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824999",
			"bath": 6.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1989,
			"zipCode": "94010",
			"path": "hillsborough-ca/160-tobin-clark-dr-hillsborough-ca-94010-100_ml81824999/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7b132f88-9f23-455d-8ef4-c376154dfb85",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-18 00:00:00",
			"createdAt": "2021-01-08 14:42:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-08 06:40:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.541312,
				"lng": -122.332031,
				"zipcode": "94010",
				"subPremise": "",
				"address": "160 Tobin Clark Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 6750000,
			"daysOnMovoto": 11,
			"id": "9c67fd9b-4698-4554-b3ad-dddd44b6af39",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828566_0_a7Muru_p.jpeg",
			"listDate": "2021-02-05 00:18:54",
			"listingAgent": "Catherine Lee",
			"listPrice": 6295000,
			"lotSize": 23522,
			"sqftTotal": 4440,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828566",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Catherine H. Lee, Broker",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1938,
			"zipCode": "94010",
			"path": "hillsborough-ca/350-el-portal-ave-hillsborough-ca-94010-100_ml81828566/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "54b13280-dbcd-4c6b-9ef6-aecd3d712974",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-17 00:00:00",
			"createdAt": "2021-02-05 08:22:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-05 00:18:54",
			"virtualTourLink": "Https://350ElPortal.com/1777016?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.564177,
				"lng": -122.345093,
				"zipcode": "94010",
				"subPremise": "",
				"address": "350 El Portal Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://350ElPortal.com/1777016?idx=1"
		},
		{
			"closePrice": 5721300,
			"daysOnMovoto": 186,
			"id": "db1dfde1-5397-4cbb-8d76-012af62ddc92",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81805998_0_FuQJ6i_p.jpeg",
			"listDate": "2020-08-13 16:42:33",
			"listingAgent": "Stanley Lo",
			"listPrice": 6250000,
			"lotSize": 102366,
			"sqftTotal": 5885,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81805998",
			"bath": 7,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 83,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2011,
			"zipCode": "94010",
			"path": "hillsborough-ca/25-jewell-pl-hillsborough-ca-94010-100_ml81805998/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "db1e548a-9807-4b9d-bec8-ebfc0c9d9743",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-16 00:00:00",
			"createdAt": "2020-08-13 23:47:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-08-13 16:42:33",
			"virtualTourLink": "Https://my.matterport.com/show/?m=KGHEpQgNsiY&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.555323,
				"lng": -122.35718,
				"zipcode": "94010",
				"subPremise": "",
				"address": "25 Jewell Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5721300,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=KGHEpQgNsiY&brand=0"
		},
		{
			"closePrice": 4800000,
			"daysOnMovoto": 97,
			"id": "57e80e0e-a04b-4f4e-8069-99cd25d45e19",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81814713_0_jNi6jF_p.jpeg",
			"listDate": "2020-11-05 00:00:00",
			"listingAgent": "Geoffrey Nelson",
			"listPrice": 4998000,
			"lotSize": 43076,
			"sqftTotal": 2395,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81814713",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1895,
			"zipCode": "94010",
			"path": "hillsborough-ca/2240-redington-rd-hillsborough-ca-94010-100_ml81627041/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -202000,
			"propertyId": "975bc449-4ca3-4ca5-b899-14e3f2447c75",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-10 00:00:00",
			"createdAt": "2020-11-05 22:47:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-05 00:00:00",
			"virtualTourLink": "Http://www.2240redington.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.572069,
				"lng": -122.369751,
				"zipcode": "94010",
				"subPremise": "",
				"address": "2240 Redington Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.2240redington.com"
		},
		{
			"closePrice": 10500000,
			"daysOnMovoto": 667,
			"id": "b211a18d-5edc-4b1f-ba44-fe682c6b2dc0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81746145_0_AjJMMm_p.jpeg",
			"listDate": "2019-04-08 15:44:31",
			"listingAgent": "Stanley Lo",
			"listPrice": 13880000,
			"lotSize": 75359,
			"sqftTotal": 10510,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81746145",
			"bath": 12,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 103,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1896,
			"zipCode": "94010",
			"path": "hillsborough-ca/119-bridge-rd-hillsborough-ca-94010-100_ml81746145/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "767ab927-5654-46a5-80e6-f9a6dd1b27bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-04 00:00:00",
			"createdAt": "2019-04-08 22:52:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2019-04-08 15:44:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hillsborough",
				"lat": 37.562142,
				"lng": -122.332762,
				"zipcode": "94010",
				"subPremise": "",
				"address": "119 Bridge Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 10500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]