exports.HILLSBOROUGH_SCHOOLS = [
		{
			"id": 6859,
			"districtId": 785,
			"districtName": "Hillsborough City Elementary",
			"districtCity": "Hillsborough",
			"levelCode": "m",
			"lat": 37.566299,
			"lon": -122.363358,
			"name": "Crocker Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2600 Ralston Ave.",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/6859-Crocker-Middle-School/",
				"reviews": "/california/hillsborough/6859-Crocker-Middle-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/6859-Crocker-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "1%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 465,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 1
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 56
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 8680,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.557514,
			"lon": -122.338272,
			"name": "Crystal Springs Uplands School",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "400 Uplands Drive",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/8680-Crystal-Springs-Uplands-School/",
				"reviews": "/california/hillsborough/8680-Crystal-Springs-Uplands-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/8680-Crystal-Springs-Uplands-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 356,
			"parentRating": 4,
			"numReviews": 17,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 47
				},
				{
					"label": "Asian",
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 21
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 24339,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.542568,
			"lon": -122.359398,
			"name": "La Petite Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1450 Tartan Trail Road",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/24339-La-Petite-Montessori-School/",
				"reviews": "/california/hillsborough/24339-La-Petite-Montessori-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/24339-La-Petite-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 15,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 67
				},
				{
					"label": "Hispanic",
					"percentage": 33
				}
			],
			"remediationData": {}
		},
		{
			"id": 6860,
			"districtId": 785,
			"districtName": "Hillsborough City Elementary",
			"districtCity": "Hillsborough",
			"levelCode": "e",
			"lat": 37.56794,
			"lon": -122.36277,
			"name": "North Hillsborough",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "545 Eucalyptus Ave.",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/6860-North-Hillsborough-School/",
				"reviews": "/california/hillsborough/6860-North-Hillsborough-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/6860-North-Hillsborough-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 300,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 53
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 6861,
			"districtId": 785,
			"districtName": "Hillsborough City Elementary",
			"districtCity": "Hillsborough",
			"levelCode": "e",
			"lat": 37.561756,
			"lon": -122.337189,
			"name": "South Hillsborough",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "303 El Cerrito Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/6861-South-Hillsborough-School/",
				"reviews": "/california/hillsborough/6861-South-Hillsborough-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/6861-South-Hillsborough-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 223,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 13,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 18240,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.563412,
			"lon": -122.338287,
			"name": "South Hillsborough Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 El Cerrito Ave Rm 3&",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/18240-South-Hillsborough-Pre-School/",
				"reviews": "/california/hillsborough/18240-South-Hillsborough-Pre-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/18240-South-Hillsborough-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8839,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.560642,
			"lon": -122.382935,
			"name": "The Nueva School",
			"gradeLevels": "PK-9",
			"assigned": null,
			"address": {
				"street1": "6565 Skyline Blvd",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/8839-The-Nueva-School/",
				"reviews": "/california/hillsborough/8839-The-Nueva-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/8839-The-Nueva-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 605,
			"parentRating": 4,
			"numReviews": 33,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 52
				},
				{
					"label": "Two or more races",
					"percentage": 23
				},
				{
					"label": "Asian",
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6862,
			"districtId": 785,
			"districtName": "Hillsborough City Elementary",
			"districtCity": "Hillsborough",
			"levelCode": "e",
			"lat": 37.549976,
			"lon": -122.367386,
			"name": "West Hillsborough",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "376 Barbara Way",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/6862-West-Hillsborough-School/",
				"reviews": "/california/hillsborough/6862-West-Hillsborough-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/6862-West-Hillsborough-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 363,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 39
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 28545,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.550014,
			"lon": -122.367409,
			"name": "West Hillsborough Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "376 Barbara Way",
				"street2": null,
				"zip": "94010",
				"city": "Hillsborough"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/hillsborough/28545-West-Hillsborough-Pre-School/",
				"reviews": "/california/hillsborough/28545-West-Hillsborough-Pre-School/#Reviews",
				"collegeSuccess": "/california/hillsborough/28545-West-Hillsborough-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]