exports.REDWOOD_CITY_SCHOOLS = [
		{
			"id": 6922,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e",
			"lat": 37.454002,
			"lon": -122.243774,
			"name": "Adelante Spanish Immersion",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3150 Granger Way",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6922-Adelante-Spanish-Immersion-School/",
				"reviews": "/california/redwood-city/6922-Adelante-Spanish-Immersion-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6922-Adelante-Spanish-Immersion-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "44%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 470,
			"parentRating": 5,
			"numReviews": 26,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 44
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31756,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.518692,
			"lon": -122.249199,
			"name": "Associated Learning & Language Specialists",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1060 Twin Dolphin Drive, Suite 100",
				"street2": "Suite 100",
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31756-Associated-Learning--Language-Specialists/",
				"reviews": "/california/redwood-city/31756-Associated-Learning--Language-Specialists/#Reviews",
				"collegeSuccess": "/california/redwood-city/31756-Associated-Learning--Language-Specialists/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21502,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.485397,
			"lon": -122.238945,
			"name": "Beresford Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "178 Clinton Street",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21502-Beresford-Montessori-School/",
				"reviews": "/california/redwood-city/21502-Beresford-Montessori-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/21502-Beresford-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30050,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.451321,
			"lon": -122.225716,
			"name": "Beresford Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "323 Santa Clara Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/30050-Beresford-Montessori-School/",
				"reviews": "/california/redwood-city/30050-Beresford-Montessori-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/30050-Beresford-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15161,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.457508,
			"lon": -122.255859,
			"name": "Bright Horizon Chinese School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "902 Emerald Hill Road",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/15161-Bright-Horizon-Chinese-School/",
				"reviews": "/california/redwood-city/15161-Bright-Horizon-Chinese-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/15161-Bright-Horizon-Chinese-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 149,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 89
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "White",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 24712,
			"districtId": 1102,
			"districtName": "San Mateo County Office of Education",
			"districtCity": "Redwood City",
			"levelCode": "m,h",
			"lat": 37.472778,
			"lon": -122.286369,
			"name": "Canyon Oaks Youth Center",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "400 Edmonds Road",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/24712-Canyon-Oaks-Youth-Center/",
				"reviews": "/california/redwood-city/24712-Canyon-Oaks-Youth-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/24712-Canyon-Oaks-Youth-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 8,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 50
				},
				{
					"label": "African American",
					"percentage": 25
				},
				{
					"label": "White",
					"percentage": 25
				}
			],
			"remediationData": {}
		},
		{
			"id": 30376,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.519028,
			"lon": -122.248581,
			"name": "Childrens' Creative Lc-Shoresp",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1050 Twin Dolphin Drive",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/30376-Childrens-Creative-Lc-Shoresp/",
				"reviews": "/california/redwood-city/30376-Childrens-Creative-Lc-Shoresp/#Reviews",
				"collegeSuccess": "/california/redwood-city/30376-Childrens-Creative-Lc-Shoresp/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6923,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.482269,
			"lon": -122.263466,
			"name": "Clifford Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "225 Clifford Ave.",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6923-Clifford-Elementary-School/",
				"reviews": "/california/redwood-city/6923-Clifford-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6923-Clifford-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 554,
			"parentRating": 5,
			"numReviews": 41,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 36
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 43
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 46
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21522,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.502159,
			"lon": -122.208656,
			"name": "Community Education Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 5382",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21522-Community-Education-Center/",
				"reviews": "/california/redwood-city/21522-Community-Education-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21522-Community-Education-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31559,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.518917,
			"lon": -122.248756,
			"name": "Compass High School",
			"gradeLevels": "9-10",
			"assigned": null,
			"address": {
				"street1": "1060 Twin Dolphin Drive",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31559-Compass-High-School/",
				"reviews": "/california/redwood-city/31559-Compass-High-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/31559-Compass-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 6,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31200,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.478752,
			"lon": -122.205223,
			"name": "Connect Community Charter",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "635 Oakside Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31200-Connect-Community-Charter/",
				"reviews": "/california/redwood-city/31200-Connect-Community-Charter/#Reviews",
				"collegeSuccess": "/california/redwood-city/31200-Connect-Community-Charter/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "85%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 212,
			"parentRating": 5,
			"numReviews": 14,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 85
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 56
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 30
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 16632,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.469002,
			"lon": -122.223,
			"name": "Daytop Preparatory",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "26 Central Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/16632-Daytop-Preparatory/",
				"reviews": "/california/redwood-city/16632-Daytop-Preparatory/#Reviews",
				"collegeSuccess": "/california/redwood-city/16632-Daytop-Preparatory/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21505,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.522892,
			"lon": -122.25325,
			"name": "Electronic Arts Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "209 Redwood Shores Pkwy",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21505-Electronic-Arts-Child-Care-Center/",
				"reviews": "/california/redwood-city/21505-Electronic-Arts-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21505-Electronic-Arts-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25118,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.473774,
			"lon": -122.203857,
			"name": "Everest Public High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "455 Fifth Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/25118-Everest-Public-High/",
				"reviews": "/california/redwood-city/25118-Everest-Public-High/#Reviews",
				"collegeSuccess": "/california/redwood-city/25118-Everest-Public-High/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "68%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "76%",
				"state_average": "64%"
			},
			"enrollment": 407,
			"parentRating": 4,
			"numReviews": 25,
			"subratings": {
				"Test Scores Rating": 6,
				"College Readiness Rating": 3,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 68
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6925,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e",
			"lat": 37.478912,
			"lon": -122.206062,
			"name": "Fair Oaks Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2950 Fair Oaks Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6925-Fair-Oaks-Elementary-School/",
				"reviews": "/california/redwood-city/6925-Fair-Oaks-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6925-Fair-Oaks-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "91%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 219,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 91
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 95
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "White",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6926,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e",
			"lat": 37.456005,
			"lon": -122.233879,
			"name": "Henry Ford Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2498 Massachusetts Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6926-Henry-Ford-Elementary-School/",
				"reviews": "/california/redwood-city/6926-Henry-Ford-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6926-Henry-Ford-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "43%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 368,
			"parentRating": 5,
			"numReviews": 18,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 43
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 52
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 15225,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.473713,
			"lon": -122.214439,
			"name": "Holy Family School Of St. Francis Center",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "151 Buckingham Avenue",
				"street2": "Floor 2",
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/15225-Holy-Family-School-Of-St.-Francis-Center/",
				"reviews": "/california/redwood-city/15225-Holy-Family-School-Of-St.-Francis-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/15225-Holy-Family-School-Of-St.-Francis-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 27,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 6930,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.481846,
			"lon": -122.215393,
			"name": "Hoover Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "701 Charter Street",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6930-Hoover-Elementary-School/",
				"reviews": "/california/redwood-city/6930-Hoover-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6930-Hoover-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "92%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 652,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 92
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 94
				},
				{
					"label": "White",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21523,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.481441,
			"lon": -122.214836,
			"name": "Hoover Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "701 Charter Street",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21523-Hoover-Pre-School/",
				"reviews": "/california/redwood-city/21523-Hoover-Pre-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/21523-Hoover-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10471,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.467243,
			"lon": -122.249603,
			"name": "Jean Weingarten Peninsula Oral",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3518 Jefferson Avenue",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/10471-Jean-Weingarten-Peninsula-Oral/",
				"reviews": "/california/redwood-city/10471-Jean-Weingarten-Peninsula-Oral/#Reviews",
				"collegeSuccess": "/california/redwood-city/10471-Jean-Weingarten-Peninsula-Oral/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 16,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 6931,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.460148,
			"lon": -122.238609,
			"name": "John F. Kennedy Middle",
			"gradeLevels": "5-8",
			"assigned": null,
			"address": {
				"street1": "2521 Goodwin Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6931-John-F.-Kennedy-Middle-School/",
				"reviews": "/california/redwood-city/6931-John-F.-Kennedy-Middle-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6931-John-F.-Kennedy-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "59%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 667,
			"parentRating": 3,
			"numReviews": 17,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 59
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 76
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Asian",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21521,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.474674,
			"lon": -122.240898,
			"name": "John Gill St. Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "555 Avenue Del Ora",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21521-John-Gill-St.-Pre-School/",
				"reviews": "/california/redwood-city/21521-John-Gill-St.-Pre-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/21521-John-Gill-St.-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31561,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.483749,
			"lon": -122.224258,
			"name": "Kiddie Garden Pre School",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "1305 Middlefield Road",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31561-Kiddie-Garden-Pre-School/",
				"reviews": "/california/redwood-city/31561-Kiddie-Garden-Pre-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/31561-Kiddie-Garden-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 58,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Hispanic",
					"percentage": 36
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "Pacific Islander",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 31781,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.481857,
			"lon": -122.216827,
			"name": "KIPP Excelencia Community Preparatory",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2950 Fair Oaks Ave",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31781-KIPP-Excelencia-Community-Preparatory/",
				"reviews": "/california/redwood-city/31781-KIPP-Excelencia-Community-Preparatory/#Reviews",
				"collegeSuccess": "/california/redwood-city/31781-KIPP-Excelencia-Community-Preparatory/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "86%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 711,
			"parentRating": 5,
			"numReviews": 11,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 86
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 92
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "White",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21503,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.460667,
			"lon": -122.226761,
			"name": "Knox Playschool-Messiah",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1835 Valota Road",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21503-Knox-Playschool-Messiah/",
				"reviews": "/california/redwood-city/21503-Knox-Playschool-Messiah/#Reviews",
				"collegeSuccess": "/california/redwood-city/21503-Knox-Playschool-Messiah/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27861,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.471912,
			"lon": -122.235466,
			"name": "Lil Learners Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1120 Roosevelt Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/27861-Lil-Learners-Pre-School/",
				"reviews": "/california/redwood-city/27861-Lil-Learners-Pre-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/27861-Lil-Learners-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6932,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "m",
			"lat": 37.480801,
			"lon": -122.234947,
			"name": "McKinley Institute of Technology",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "400 Duane Street",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6932-Mckinley-Institute-Of-Technology/",
				"reviews": "/california/redwood-city/6932-Mckinley-Institute-Of-Technology/#Reviews",
				"collegeSuccess": "/california/redwood-city/6932-Mckinley-Institute-Of-Technology/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "88%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 384,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 88
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 90
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 15262,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.473495,
			"lon": -122.204224,
			"name": "Montessori Community School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "435 Fifth Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/15262-Montessori-Community-School/",
				"reviews": "/california/redwood-city/15262-Montessori-Community-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/15262-Montessori-Community-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 32320,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.484993,
			"lon": -122.199562,
			"name": "Nasali Daycare",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "166 Broadway",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/32320-Nasali-Daycare/",
				"reviews": "/california/redwood-city/32320-Nasali-Daycare/#Reviews",
				"collegeSuccess": "/california/redwood-city/32320-Nasali-Daycare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21514,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.459068,
			"lon": -122.256416,
			"name": "Neighborhood Kid's Corner Dc",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3790 Red Oak Way",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21514-Neighborhood-Kids-Corner-Dc/",
				"reviews": "/california/redwood-city/21514-Neighborhood-Kids-Corner-Dc/#Reviews",
				"collegeSuccess": "/california/redwood-city/21514-Neighborhood-Kids-Corner-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27858,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.481537,
			"lon": -122.262993,
			"name": "Neighborhood Kid's Corner Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Clifford Elementary School",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/27858-Neighborhood-Kids-Corner-Dc/",
				"reviews": "/california/redwood-city/27858-Neighborhood-Kids-Corner-Dc/#Reviews",
				"collegeSuccess": "/california/redwood-city/27858-Neighborhood-Kids-Corner-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21507,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.466911,
			"lon": -122.244743,
			"name": "Newton CCC-Roosevelt",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2223 Vera Ave",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21507-Newton-CCC-Roosevelt/",
				"reviews": "/california/redwood-city/21507-Newton-CCC-Roosevelt/#Reviews",
				"collegeSuccess": "/california/redwood-city/21507-Newton-CCC-Roosevelt/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21518,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.479649,
			"lon": -122.239113,
			"name": "Noah's Ark Pre-School Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "401 Hudson St",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21518-Noahs-Ark-Pre-School-Day-Care-Center/",
				"reviews": "/california/redwood-city/21518-Noahs-Ark-Pre-School-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21518-Noahs-Ark-Pre-School-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21517,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.463444,
			"lon": -122.225677,
			"name": "Noah's Ark Pre-School Inc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "401 Hudson James",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21517-Noahs-Ark-Pre-School-Inc/",
				"reviews": "/california/redwood-city/21517-Noahs-Ark-Pre-School-Inc/#Reviews",
				"collegeSuccess": "/california/redwood-city/21517-Noahs-Ark-Pre-School-Inc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6933,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.480801,
			"lon": -122.234947,
			"name": "North Star Academy",
			"gradeLevels": "3-8",
			"assigned": null,
			"address": {
				"street1": "400 Duane Street",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6933-North-Star-Academy/",
				"reviews": "/california/redwood-city/6933-North-Star-Academy/#Reviews",
				"collegeSuccess": "/california/redwood-city/6933-North-Star-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 533,
			"parentRating": 4,
			"numReviews": 33,
			"studentsPerTeacher": 28,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31648,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.480091,
			"lon": -122.245468,
			"name": "Open Gate Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2124 Brewster Avenue",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31648-Open-Gate-Nursery-School/",
				"reviews": "/california/redwood-city/31648-Open-Gate-Nursery-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/31648-Open-Gate-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 11145,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e",
			"lat": 37.490273,
			"lon": -122.234055,
			"name": "Orion Alternative",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "815 Allerton Street",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/11145-Orion-Alternative-School/",
				"reviews": "/california/redwood-city/11145-Orion-Alternative-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/11145-Orion-Alternative-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "24%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 229,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 24
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 48
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8093,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.4814,
			"lon": -122.239098,
			"name": "Our Lady Of Mt. Carmel",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "301 Grand Street, Redwood City, CA 94062",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/8093-Our-Lady-Of-Mt.-Carmel/",
				"reviews": "/california/redwood-city/8093-Our-Lady-Of-Mt.-Carmel/#Reviews",
				"collegeSuccess": "/california/redwood-city/8093-Our-Lady-Of-Mt.-Carmel/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 338,
			"parentRating": 3,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 58
				},
				{
					"label": "Hispanic",
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21519,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.490295,
			"lon": -122.23172,
			"name": "Our Place Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "403 Winslow Street",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21519-Our-Place-Child-Care-Center/",
				"reviews": "/california/redwood-city/21519-Our-Place-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21519-Our-Place-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15091,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.483826,
			"lon": -122.224106,
			"name": "Peninsula Christian Schools",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1305 Middlefield Rd",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/15091-Peninsula-Christian-Schools/",
				"reviews": "/california/redwood-city/15091-Peninsula-Christian-Schools/#Reviews",
				"collegeSuccess": "/california/redwood-city/15091-Peninsula-Christian-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 2,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21513,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.462826,
			"lon": -122.248604,
			"name": "Peninsula Covenant Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3560 Farm Hill Boulevard",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21513-Peninsula-Covenant-Pre-School/",
				"reviews": "/california/redwood-city/21513-Peninsula-Covenant-Pre-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/21513-Peninsula-Covenant-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21524,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.467648,
			"lon": -122.24308,
			"name": "Rainbow Pre-School Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "900 Upton Street",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21524-Rainbow-Pre-School-Day-Care/",
				"reviews": "/california/redwood-city/21524-Rainbow-Pre-School-Day-Care/#Reviews",
				"collegeSuccess": "/california/redwood-city/21524-Rainbow-Pre-School-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21526,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.47998,
			"lon": -122.193954,
			"name": "Rcsd Child Development Serv-Hawes Cdc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "903 Tenth Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21526-Rcsd-Child-Development-Serv-Hawes-Cdc/",
				"reviews": "/california/redwood-city/21526-Rcsd-Child-Development-Serv-Hawes-Cdc/#Reviews",
				"collegeSuccess": "/california/redwood-city/21526-Rcsd-Child-Development-Serv-Hawes-Cdc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8617,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.479279,
			"lon": -122.237595,
			"name": "Redeemer Lutheran School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "468 Grand Street",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/8617-Redeemer-Lutheran-School/",
				"reviews": "/california/redwood-city/8617-Redeemer-Lutheran-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/8617-Redeemer-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 208,
			"parentRating": 4,
			"numReviews": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 75
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21504,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.495255,
			"lon": -122.244133,
			"name": "Redwood Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1968 Old County Road",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21504-Redwood-Child-Development-Center/",
				"reviews": "/california/redwood-city/21504-Redwood-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21504-Redwood-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30138,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.470585,
			"lon": -122.226524,
			"name": "Redwood Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1445 Hudson Street",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/30138-Redwood-Childrens-Center/",
				"reviews": "/california/redwood-city/30138-Redwood-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/30138-Redwood-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21509,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.477226,
			"lon": -122.213219,
			"name": "Redwood City Child Development Program",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2600 Middlefield Road",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21509-Redwood-City-Child-Development-Program/",
				"reviews": "/california/redwood-city/21509-Redwood-City-Child-Development-Program/#Reviews",
				"collegeSuccess": "/california/redwood-city/21509-Redwood-City-Child-Development-Program/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6952,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.495457,
			"lon": -122.244072,
			"name": "Redwood High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1968 Old County Road",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6952-Redwood-High-School/",
				"reviews": "/california/redwood-city/6952-Redwood-High-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6952-Redwood-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "87%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "26%",
				"state_average": "64%"
			},
			"enrollment": 227,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 87
				},
				{
					"label": "Hispanic",
					"percentage": 86
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21515,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.457928,
			"lon": -122.263458,
			"name": "Redwood Parent Nursery",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3997 Jefferson Avenue",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21515-Redwood-Parent-Nursery/",
				"reviews": "/california/redwood-city/21515-Redwood-Parent-Nursery/#Reviews",
				"collegeSuccess": "/california/redwood-city/21515-Redwood-Parent-Nursery/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25407,
			"districtId": 780,
			"districtName": "Belmont-Redwood Shores Elementary",
			"districtCity": "Belmont",
			"levelCode": "e",
			"lat": 37.543545,
			"lon": -122.245819,
			"name": "Redwood Shores Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "225 Shearwater Parkway",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/25407-Redwood-Shores-Elementary-School/",
				"reviews": "/california/redwood-city/25407-Redwood-Shores-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/25407-Redwood-Shores-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 486,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13142,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.452869,
			"lon": -122.235672,
			"name": "Ridley Montessori Dcc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1803 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/13142-Ridley-Montessori-Dcc/",
				"reviews": "/california/redwood-city/13142-Ridley-Montessori-Dcc/#Reviews",
				"collegeSuccess": "/california/redwood-city/13142-Ridley-Montessori-Dcc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31779,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e",
			"lat": 37.481613,
			"lon": -122.216217,
			"name": "Rocketship Redwood City",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "701 Charter Street",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/31779-Redwood-City-Prep/",
				"reviews": "/california/redwood-city/31779-Redwood-City-Prep/#Reviews",
				"collegeSuccess": "/california/redwood-city/31779-Redwood-City-Prep/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "76%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 278,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 76
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 94
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "White",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6934,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.466682,
			"lon": -122.243874,
			"name": "Roosevelt Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2223 Vera Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6934-Roosevelt-Elementary-School/",
				"reviews": "/california/redwood-city/6934-Roosevelt-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6934-Roosevelt-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 555,
			"parentRating": 5,
			"numReviews": 39,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 58
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 68
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6924,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.459751,
			"lon": -122.256126,
			"name": "Roy Cloud Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3790 Red Oak Way",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6924-Roy-Cloud-Elementary-School/",
				"reviews": "/california/redwood-city/6924-Roy-Cloud-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6924-Roy-Cloud-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 751,
			"parentRating": 4,
			"numReviews": 29,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 61
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21525,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.47998,
			"lon": -122.193954,
			"name": "Rscd Child Development Svc-Taft Cdc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "903 Tenth Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21525-Rscd-Child-Development-Svc-Taft-Cdc/",
				"reviews": "/california/redwood-city/21525-Rscd-Child-Development-Svc-Taft-Cdc/#Reviews",
				"collegeSuccess": "/california/redwood-city/21525-Rscd-Child-Development-Svc-Taft-Cdc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15005,
			"districtId": 1290,
			"districtName": "San Mateo County ROP",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.525928,
			"lon": -122.262993,
			"name": "San Mateo County ROP",
			"gradeLevels": "11-12",
			"assigned": null,
			"address": {
				"street1": "101 Twin Dolphin Drive",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/15005-San-Mateo-County-Rop-School/",
				"reviews": "/california/redwood-city/15005-San-Mateo-County-Rop-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/15005-San-Mateo-County-Rop-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12495,
			"districtId": 1102,
			"districtName": "San Mateo County Office of Education",
			"districtCity": "Redwood City",
			"levelCode": "e,m,h",
			"lat": 37.525928,
			"lon": -122.262993,
			"name": "San Mateo County Special Education",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "101 Twin Dolphin Drive",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 1,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/12495-San-Mateo-County-Special-Education-School/",
				"reviews": "/california/redwood-city/12495-San-Mateo-County-Special-Education-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/12495-San-Mateo-County-Special-Education-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 136,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 9,
			"subratings": {
				"College Readiness Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"percentage": 42
				},
				{
					"label": "Asian",
					"percentage": 18
				},
				{
					"label": "Filipino",
					"percentage": 15
				},
				{
					"label": "White",
					"percentage": 14
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6842,
			"districtId": 780,
			"districtName": "Belmont-Redwood Shores Elementary",
			"districtCity": "Belmont",
			"levelCode": "e,m",
			"lat": 37.53783,
			"lon": -122.235542,
			"name": "Sandpiper Elementary",
			"gradeLevels": "K-7",
			"assigned": null,
			"address": {
				"street1": "801 Redwood Shores Parkway",
				"street2": null,
				"zip": "94065",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6842-Sandpiper-Elementary-School/",
				"reviews": "/california/redwood-city/6842-Sandpiper-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6842-Sandpiper-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 617,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 39
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 26313,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.47369,
			"lon": -122.203545,
			"name": "Seaport Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "452 Fifth Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/26313-Seaport-Academy/",
				"reviews": "/california/redwood-city/26313-Seaport-Academy/#Reviews",
				"collegeSuccess": "/california/redwood-city/26313-Seaport-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 71
				},
				{
					"label": "White",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 21500,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.485615,
			"lon": -122.238449,
			"name": "Sequoia Child Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1234 Brewster Avenue",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21500-Sequoia-Child-Center/",
				"reviews": "/california/redwood-city/21500-Sequoia-Child-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21500-Sequoia-Child-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6953,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.484756,
			"lon": -122.23632,
			"name": "Sequoia High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1201 Brewster Avenue",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6953-Sequoia-High-School/",
				"reviews": "/california/redwood-city/6953-Sequoia-High-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6953-Sequoia-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "49%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "73%",
				"state_average": "64%"
			},
			"enrollment": 2067,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 7,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 49
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 59
				},
				{
					"label": "Pacific Islander",
					"rating": 3,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17367,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.473988,
			"lon": -122.249023,
			"name": "Sequoia Preschool & Kindergarten",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "277 Topaz Street",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/17367-Sequoia-Preschool--Kindergarten/",
				"reviews": "/california/redwood-city/17367-Sequoia-Preschool--Kindergarten/#Reviews",
				"collegeSuccess": "/california/redwood-city/17367-Sequoia-Preschool--Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21508,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.474682,
			"lon": -122.248802,
			"name": "Sequoia Preschool Kindergarten",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "233 Topaz Street",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21508-Sequoia-Preschool-Kindergarten/",
				"reviews": "/california/redwood-city/21508-Sequoia-Preschool-Kindergarten/#Reviews",
				"collegeSuccess": "/california/redwood-city/21508-Sequoia-Preschool-Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 74,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 67
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 17
				}
			],
			"remediationData": {}
		},
		{
			"id": 21520,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.473701,
			"lon": -122.260956,
			"name": "St. Matthias Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "533 Canyon Road",
				"street2": null,
				"zip": "94062",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21520-St.-Matthias-Day-Care-Center/",
				"reviews": "/california/redwood-city/21520-St.-Matthias-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/21520-St.-Matthias-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8159,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.46172,
			"lon": -122.226814,
			"name": "St. Pius Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1100 Woodside Road",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/8159-St.-Pius-Elementary-School/",
				"reviews": "/california/redwood-city/8159-St.-Pius-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/8159-St.-Pius-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 335,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 72
				},
				{
					"label": "Hispanic",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17017,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.486454,
			"lon": -122.210442,
			"name": "Summit Preparatory Charter High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "890 Broadway",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/17017-Summit-Preparatory-Charter-High-School/",
				"reviews": "/california/redwood-city/17017-Summit-Preparatory-Charter-High-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/17017-Summit-Preparatory-Charter-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "80%",
				"state_average": "64%"
			},
			"enrollment": 454,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 7,
				"College Readiness Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 69
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6936,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e",
			"lat": 37.480965,
			"lon": -122.194321,
			"name": "Taft Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "Tenth Ave. and Bay Rd.",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/6936-Taft-Elementary-School/",
				"reviews": "/california/redwood-city/6936-Taft-Elementary-School/#Reviews",
				"collegeSuccess": "/california/redwood-city/6936-Taft-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "90%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 279,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 90
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 97
				},
				{
					"label": "White",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21501,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.457169,
			"lon": -122.241219,
			"name": "Temple Beth Jacob-Keshet Pgrm",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1550 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/21501-Temple-Beth-Jacob-Keshet-Pgrm/",
				"reviews": "/california/redwood-city/21501-Temple-Beth-Jacob-Keshet-Pgrm/#Reviews",
				"collegeSuccess": "/california/redwood-city/21501-Temple-Beth-Jacob-Keshet-Pgrm/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15787,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.449654,
			"lon": -122.23188,
			"name": "The Redwoods International Montessori Pre-school & Kindergarten",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2000 Woodside Road",
				"street2": "Rm #3",
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/15787-The-Redwoods-International-Montessori-Pre-School--Kindergarten/",
				"reviews": "/california/redwood-city/15787-The-Redwoods-International-Montessori-Pre-School--Kindergarten/#Reviews",
				"collegeSuccess": "/california/redwood-city/15787-The-Redwoods-International-Montessori-Pre-School--Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 36,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 33
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				}
			],
			"remediationData": {}
		},
		{
			"id": 26316,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.475536,
			"lon": -122.235519,
			"name": "West Bay Christian Academy",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "901 Madison Avenue",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/26316-West-Bay-Christian-Academy/",
				"reviews": "/california/redwood-city/26316-West-Bay-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/redwood-city/26316-West-Bay-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 102,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Hispanic",
					"percentage": 33
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				},
				{
					"label": "Pacific Islander",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 9942,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.473576,
			"lon": -122.203255,
			"name": "Wherry Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "452 Fifth Avenue",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/9942-Wherry-Academy/",
				"reviews": "/california/redwood-city/9942-Wherry-Academy/#Reviews",
				"collegeSuccess": "/california/redwood-city/9942-Wherry-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 9,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 56
				},
				{
					"label": "White",
					"percentage": 44
				}
			],
			"remediationData": {}
		},
		{
			"id": 17368,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.489887,
			"lon": -122.228943,
			"name": "Wings Learning Center",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "411 Middlefield Road",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/17368-Wings-Learning-Center/",
				"reviews": "/california/redwood-city/17368-Wings-Learning-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/17368-Wings-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 39,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 77
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 26318,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.482445,
			"lon": -122.225288,
			"name": "Wings Learning Center",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1201 Main Street",
				"street2": null,
				"zip": "94063",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94063",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/26318-Wings-Learning-Center/",
				"reviews": "/california/redwood-city/26318-Wings-Learning-Center/#Reviews",
				"collegeSuccess": "/california/redwood-city/26318-Wings-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 33,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16639,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.464058,
			"lon": -122.242683,
			"name": "Woodside Hills Christian Academy",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "2323 Euclid Ave",
				"street2": null,
				"zip": "94061",
				"city": "Redwood City"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/redwood-city/16639-Woodside-Hills-Christian-Academy/",
				"reviews": "/california/redwood-city/16639-Woodside-Hills-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/redwood-city/16639-Woodside-Hills-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]