exports.LIVERMORE_SCHOOLS = [
    {
        "id": 11682,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.722019,
        "lon": -121.717049,
        "name": "Altamont Creek Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "6500 Garaventa Ranch Road",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/11682-Altamont-Creek-Elementary-School/",
            "reviews": "/california/livermore/11682-Altamont-Creek-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/11682-Altamont-Creek-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "17%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 585,
        "parentRating": 4,
        "numReviews": 20,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 17
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 21
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 45
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 144,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "m",
        "lat": 37.725475,
        "lon": -121.729652,
        "name": "Andrew N. Christensen Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "5757 Haggin Oaks Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/144-Andrew-N.-Christensen-Middle-School/",
            "reviews": "/california/livermore/144-Andrew-N.-Christensen-Middle-School/#Reviews",
            "collegeSuccess": "/california/livermore/144-Andrew-N.-Christensen-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "19%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 715,
        "parentRating": 4,
        "numReviews": 8,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 19
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 12
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 24
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 49
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 143,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.684227,
        "lon": -121.729118,
        "name": "Arroyo Seco Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "5280 Irene Way",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/143-Arroyo-Seco-Elementary-School/",
            "reviews": "/california/livermore/143-Arroyo-Seco-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/143-Arroyo-Seco-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "23%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 678,
        "parentRating": 4,
        "numReviews": 11,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 23
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 32
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 46
            },
            {
                "label": "Two or more races",
                "rating": 7,
                "percentage": 10
            },
            {
                "label": "Asian",
                "percentage": 7
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27514,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.67688,
        "lon": -121.740364,
        "name": "Bess Platt Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1401 Almond Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27514-Bess-Platt-Head-Start/",
            "reviews": "/california/livermore/27514-Bess-Platt-Head-Start/#Reviews",
            "collegeSuccess": "/california/livermore/27514-Bess-Platt-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30103,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.70665,
        "lon": -121.813812,
        "name": "Cape Day Care-Bess Platt",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3095 Independence Drive",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/30103-Cape-Day-Care-Bess-Platt/",
            "reviews": "/california/livermore/30103-Cape-Day-Care-Bess-Platt/#Reviews",
            "collegeSuccess": "/california/livermore/30103-Cape-Day-Care-Bess-Platt/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19841,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.70665,
        "lon": -121.813812,
        "name": "Cape Inc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3095 Independence Drive",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19841-Cape-Inc/",
            "reviews": "/california/livermore/19841-Cape-Inc/#Reviews",
            "collegeSuccess": "/california/livermore/19841-Cape-Inc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19850,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.678535,
        "lon": -121.744835,
        "name": "Carries Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4256 Baylor Way",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19850-Carries-Child-Care-Center/",
            "reviews": "/california/livermore/19850-Carries-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/livermore/19850-Carries-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 12909,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.709702,
        "lon": -121.734489,
        "name": "Celebration Academy",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "1135 Bluebell Drive",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/12909-Celebration-Academy/",
            "reviews": "/california/livermore/12909-Celebration-Academy/#Reviews",
            "collegeSuccess": "/california/livermore/12909-Celebration-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 58,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19838,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.709702,
        "lon": -121.734489,
        "name": "Celebration Learning Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "1135 Bluebell Drive",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19838-Celebration-Learning-Center/",
            "reviews": "/california/livermore/19838-Celebration-Learning-Center/#Reviews",
            "collegeSuccess": "/california/livermore/19838-Celebration-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27764,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.680332,
        "lon": -121.754692,
        "name": "Christian World Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3820 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27764-Christian-World-Child-Care-Center/",
            "reviews": "/california/livermore/27764-Christian-World-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/livermore/27764-Christian-World-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19845,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.659088,
        "lon": -121.762779,
        "name": "Cottage Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2567 Tapestry Drive",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19845-Cottage-Pre-School/",
            "reviews": "/california/livermore/19845-Cottage-Pre-School/#Reviews",
            "collegeSuccess": "/california/livermore/19845-Cottage-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19852,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.688267,
        "lon": -121.76976,
        "name": "Crestmont Children's Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "542 Junction Ave",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19852-Crestmont-Childrens-Center/",
            "reviews": "/california/livermore/19852-Crestmont-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/livermore/19852-Crestmont-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 146,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "m,h",
        "lat": 37.678669,
        "lon": -121.766693,
        "name": "Del Valle Continuation High",
        "gradeLevels": "7-12",
        "assigned": null,
        "address": {
            "street1": "2253 5th Street",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/146-Del-Valle-Continuation-High-School/",
            "reviews": "/california/livermore/146-Del-Valle-Continuation-High-School/#Reviews",
            "collegeSuccess": "/california/livermore/146-Del-Valle-Continuation-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "68%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "26%",
            "state_average": "64%"
        },
        "enrollment": 111,
        "parentRating": 5,
        "numReviews": 1,
        "studentsPerTeacher": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 68
            },
            {
                "label": "Hispanic",
                "percentage": 54
            },
            {
                "label": "White",
                "percentage": 39
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28592,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.672638,
        "lon": -121.793579,
        "name": "Dragon Zone Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "543 Sonoma Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/28592-Dragon-Zone-Child-Care-Center/",
            "reviews": "/california/livermore/28592-Dragon-Zone-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/livermore/28592-Dragon-Zone-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 147,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "m",
        "lat": 37.678455,
        "lon": -121.750534,
        "name": "East Avenue Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "3951 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/147-East-Avenue-Middle-School/",
            "reviews": "/california/livermore/147-East-Avenue-Middle-School/#Reviews",
            "collegeSuccess": "/california/livermore/147-East-Avenue-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "30%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 649,
        "parentRating": 4,
        "numReviews": 9,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 30
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 7
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 31
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 47
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 158,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.665173,
        "lon": -121.797012,
        "name": "Emma C. Smith Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "391 Ontario Drive",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/158-Emma-C.-Smith-Elementary-School/",
            "reviews": "/california/livermore/158-Emma-C.-Smith-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/158-Emma-C.-Smith-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "8%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 719,
        "parentRating": 4,
        "numReviews": 16,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 8
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 17
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 20
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 50
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8616,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.67083,
        "lon": -121.752678,
        "name": "Evan Anwyl",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1385 S. Livermore Ave.",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/8616-Evan-Anwyl/",
            "reviews": "/california/livermore/8616-Evan-Anwyl/#Reviews",
            "collegeSuccess": "/california/livermore/8616-Evan-Anwyl/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 271,
        "parentRating": 4,
        "numReviews": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 55
            },
            {
                "label": "Pacific Islander",
                "percentage": 29
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "Asian",
                "percentage": 2
            },
            {
                "label": "Hispanic",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27378,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.682022,
        "lon": -121.740952,
        "name": "Extended Student Child Care Center-Jackson",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4444 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27378-Extended-Student-Child-Care-Center-Jackson/",
            "reviews": "/california/livermore/27378-Extended-Student-Child-Care-Center-Jackson/#Reviews",
            "collegeSuccess": "/california/livermore/27378-Extended-Student-Child-Care-Center-Jackson/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27377,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.691254,
        "lon": -121.747505,
        "name": "Extended Student Serv Arroyo S",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "71 Trevarno Road",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27377-Extended-Student-Serv-Arroyo-S/",
            "reviews": "/california/livermore/27377-Extended-Student-Serv-Arroyo-S/#Reviews",
            "collegeSuccess": "/california/livermore/27377-Extended-Student-Serv-Arroyo-S/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27647,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.66893,
        "lon": -121.774109,
        "name": "Free To Be Me Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1040 Florence Road",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27647-Free-To-Be-Me-Child-Care-Center/",
            "reviews": "/california/livermore/27647-Free-To-Be-Me-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/livermore/27647-Free-To-Be-Me-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 148,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "h",
        "lat": 37.674992,
        "lon": -121.787834,
        "name": "Granada High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "400 Wall Street",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/148-Granada-High-School/",
            "reviews": "/california/livermore/148-Granada-High-School/#Reviews",
            "collegeSuccess": "/california/livermore/148-Granada-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "20%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "78%",
            "state_average": "64%"
        },
        "enrollment": 2282,
        "parentRating": 4,
        "numReviews": 13,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 8,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 20
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 27
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 52
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "rating": 10,
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13375,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.67371,
        "lon": -121.783073,
        "name": "Holy Cross Luthern School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1020 Mocho Street",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/13375-Holy-Cross-Luthern-School/",
            "reviews": "/california/livermore/13375-Holy-Cross-Luthern-School/#Reviews",
            "collegeSuccess": "/california/livermore/13375-Holy-Cross-Luthern-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27215,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.718849,
        "lon": -121.7211,
        "name": "Hoot Hoot Preschool",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "6282 Carson Pass Way",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27215-Hoot-Hoot-Preschool/",
            "reviews": "/california/livermore/27215-Hoot-Hoot-Preschool/#Reviews",
            "collegeSuccess": "/california/livermore/27215-Hoot-Hoot-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 149,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.684822,
        "lon": -121.741272,
        "name": "Jackson Avenue Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "554 Jackson Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/149-Jackson-Avenue-Elementary-School/",
            "reviews": "/california/livermore/149-Jackson-Avenue-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/149-Jackson-Avenue-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "39%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 526,
        "parentRating": 5,
        "numReviews": 9,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 39
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 41
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 39
            },
            {
                "label": "Asian",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 154,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e,m",
        "lat": 37.668854,
        "lon": -121.782654,
        "name": "Joe Michell",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1001 Elaine Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/154-Joe-Michell-K-8-School/",
            "reviews": "/california/livermore/154-Joe-Michell-K-8-School/#Reviews",
            "collegeSuccess": "/california/livermore/154-Joe-Michell-K-8-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "20%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 819,
        "parentRating": 4,
        "numReviews": 38,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 20
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 29
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 49
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 11
            },
            {
                "label": "Asian",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 156,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e,m",
        "lat": 37.687946,
        "lon": -121.765846,
        "name": "Junction K-8",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "298 Junction Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/156-Junction-Avenue-K-8-School/",
            "reviews": "/california/livermore/156-Junction-Avenue-K-8-School/#Reviews",
            "collegeSuccess": "/california/livermore/156-Junction-Avenue-K-8-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "62%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 934,
        "parentRating": 5,
        "numReviews": 13,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 62
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 68
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 20
            },
            {
                "label": "Two or more races",
                "rating": 5,
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Asian",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28501,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.690311,
        "lon": -121.773865,
        "name": "Kidango Cdc-Pepper Tree",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "714 Junction Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/28501-Kidango-Cdc-Pepper-Tree/",
            "reviews": "/california/livermore/28501-Kidango-Cdc-Pepper-Tree/#Reviews",
            "collegeSuccess": "/california/livermore/28501-Kidango-Cdc-Pepper-Tree/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13601,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.70425,
        "lon": -121.741402,
        "name": "Kindercare Learning Center #838",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4655 Lassen Road",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/13601-Kindercare-Learning-Center-838/",
            "reviews": "/california/livermore/13601-Kindercare-Learning-Center-838/#Reviews",
            "collegeSuccess": "/california/livermore/13601-Kindercare-Learning-Center-838/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 20,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 33
            },
            {
                "label": "Two or more races",
                "percentage": 33
            },
            {
                "label": "White",
                "percentage": 17
            },
            {
                "label": "African American",
                "percentage": 17
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19843,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.678562,
        "lon": -121.76947,
        "name": "Kinderkirk Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2020 5th Street",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19843-Kinderkirk-Head-Start/",
            "reviews": "/california/livermore/19843-Kinderkirk-Head-Start/#Reviews",
            "collegeSuccess": "/california/livermore/19843-Kinderkirk-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27961,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.691254,
        "lon": -121.747505,
        "name": "Larpd Child Care Service",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "71 Trevarno Road",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27961-Larpd-Child-Care-Service/",
            "reviews": "/california/livermore/27961-Larpd-Child-Care-Service/#Reviews",
            "collegeSuccess": "/california/livermore/27961-Larpd-Child-Care-Service/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 32567,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.690075,
        "lon": -121.770615,
        "name": "Lawrence Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "2451 Portola Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/32567-Lawrence-Elementary/",
            "reviews": "/california/livermore/32567-Lawrence-Elementary/#Reviews",
            "collegeSuccess": "/california/livermore/32567-Lawrence-Elementary/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "13%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 357,
        "parentRating": 5,
        "numReviews": 1,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 13
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 21
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 50
            },
            {
                "label": "Asian",
                "percentage": 15
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29552,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.681786,
        "lon": -121.755692,
        "name": "Leahy Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3203 Leahy Way",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/29552-Leahy-Head-Start/",
            "reviews": "/california/livermore/29552-Leahy-Head-Start/#Reviews",
            "collegeSuccess": "/california/livermore/29552-Leahy-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 145,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.717678,
        "lon": -121.728943,
        "name": "Leo R. Croce Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "5650 Scenic Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/145-Leo-R.-Croce-Elementary-School/",
            "reviews": "/california/livermore/145-Leo-R.-Croce-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/145-Leo-R.-Croce-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "25%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 601,
        "parentRating": 4,
        "numReviews": 3,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 25
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 28
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 49
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19853,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.711891,
        "lon": -121.730652,
        "name": "Little Hands Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "5491 Treeflower Dr",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19853-Little-Hands-Day-Care/",
            "reviews": "/california/livermore/19853-Little-Hands-Day-Care/#Reviews",
            "collegeSuccess": "/california/livermore/19853-Little-Hands-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19842,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.720085,
        "lon": -121.723175,
        "name": "Little Rascals Learning Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1893 North Vasco Road",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19842-Little-Rascals-Learning-Center/",
            "reviews": "/california/livermore/19842-Little-Rascals-Learning-Center/#Reviews",
            "collegeSuccess": "/california/livermore/19842-Little-Rascals-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 14551,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e,m,h",
        "lat": 37.67688,
        "lon": -121.740356,
        "name": "Livermore Adult",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "1401 Almond Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/14551-Livermore-Adult/",
            "reviews": "/california/livermore/14551-Livermore-Adult/#Reviews",
            "collegeSuccess": "/california/livermore/14551-Livermore-Adult/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 151,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "h",
        "lat": 37.681267,
        "lon": -121.760155,
        "name": "Livermore High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "600 Maple Street",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/151-Livermore-High-School/",
            "reviews": "/california/livermore/151-Livermore-High-School/#Reviews",
            "collegeSuccess": "/california/livermore/151-Livermore-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "24%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "76%",
            "state_average": "64%"
        },
        "enrollment": 1878,
        "parentRating": 4,
        "numReviews": 6,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 7,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 24
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 30
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 49
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 6
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19851,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.679241,
        "lon": -121.733841,
        "name": "Livermore Playschool",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "5261 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19851-Livermore-Playschool/",
            "reviews": "/california/livermore/19851-Livermore-Playschool/#Reviews",
            "collegeSuccess": "/california/livermore/19851-Livermore-Playschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27620,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.681873,
        "lon": -121.768005,
        "name": "Llesa Children's Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "Po Box 808",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/27620-Llesa-Childrens-Center/",
            "reviews": "/california/livermore/27620-Llesa-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/livermore/27620-Llesa-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 152,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.684559,
        "lon": -121.78614,
        "name": "Marylin Avenue Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "800 Marylin Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/152-Marylin-Avenue-Elementary-School/",
            "reviews": "/california/livermore/152-Marylin-Avenue-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/152-Marylin-Avenue-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "78%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 392,
        "parentRating": 5,
        "numReviews": 6,
        "studentsPerTeacher": 17,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 78
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 75
            },
            {
                "label": "White",
                "percentage": 12
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Asian",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19847,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.689034,
        "lon": -121.79673,
        "name": "New Horizons Nursery School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "405 East Jack London Boulevard",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19847-New-Horizons-Nursery-School/",
            "reviews": "/california/livermore/19847-New-Horizons-Nursery-School/#Reviews",
            "collegeSuccess": "/california/livermore/19847-New-Horizons-Nursery-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19839,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.671448,
        "lon": -121.752609,
        "name": "Our Savior Luth Ministries",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1385 South Livermore Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19839-Our-Savior-Luth-Ministries/",
            "reviews": "/california/livermore/19839-Our-Savior-Luth-Ministries/#Reviews",
            "collegeSuccess": "/california/livermore/19839-Our-Savior-Luth-Ministries/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 157,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.687347,
        "lon": -121.796822,
        "name": "Rancho Las Positas Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "401 East Jack London Boulevard",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/157-Rancho-Las-Positas-Elementary-School/",
            "reviews": "/california/livermore/157-Rancho-Las-Positas-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/157-Rancho-Las-Positas-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "24%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 599,
        "parentRating": 4,
        "numReviews": 18,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 24
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 32
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 45
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "Asian",
                "percentage": 8
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 23784,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.685516,
        "lon": -121.734467,
        "name": "Selah Christian School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "4955 Gladys Court",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/23784-Selah-Christian-School/",
            "reviews": "/california/livermore/23784-Selah-Christian-School/#Reviews",
            "collegeSuccess": "/california/livermore/23784-Selah-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15834,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.70499,
        "lon": -121.700607,
        "name": "Sonrise Christian Academy",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "7640 National Drive",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/15834-Sonrise-Christian-Academy/",
            "reviews": "/california/livermore/15834-Sonrise-Christian-Academy/#Reviews",
            "collegeSuccess": "/california/livermore/15834-Sonrise-Christian-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 11,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 32370,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.706795,
        "lon": -121.815369,
        "name": "Squaw Valley Academy Bay Area",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "3090 Independence Drive",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/32370-Squaw-Valley-Academy-Bay-Area/",
            "reviews": "/california/livermore/32370-Squaw-Valley-Academy-Bay-Area/#Reviews",
            "collegeSuccess": "/california/livermore/32370-Squaw-Valley-Academy-Bay-Area/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 2,
        "numReviews": 6,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8227,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.682793,
        "lon": -121.763733,
        "name": "St. Michael Elementary School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "345 Church Street",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/8227-St.-Michael-Elementary-School/",
            "reviews": "/california/livermore/8227-St.-Michael-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/8227-St.-Michael-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 233,
        "parentRating": 4,
        "numReviews": 27,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 74
            },
            {
                "label": "Hispanic",
                "percentage": 20
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19844,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.680222,
        "lon": -121.764374,
        "name": "Story Land Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2486 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19844-Story-Land-Pre-School/",
            "reviews": "/california/livermore/19844-Story-Land-Pre-School/#Reviews",
            "collegeSuccess": "/california/livermore/19844-Story-Land-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 1,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15320,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.659771,
        "lon": -121.766968,
        "name": "Sunset Christian School",
        "gradeLevels": "PK-3",
        "assigned": null,
        "address": {
            "street1": "2200 Arroyo Road",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/15320-Sunset-Christian-School/",
            "reviews": "/california/livermore/15320-Sunset-Christian-School/#Reviews",
            "collegeSuccess": "/california/livermore/15320-Sunset-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 64,
        "parentRating": 4,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 52
            },
            {
                "label": "Two or more races",
                "percentage": 35
            },
            {
                "label": "African American",
                "percentage": 9
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 4
            }
        ],
        "remediationData": {}
    },
    {
        "id": 159,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e",
        "lat": 37.662647,
        "lon": -121.774498,
        "name": "Sunset Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "1671 Frankfurt Way",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/159-Sunset-Elementary-School/",
            "reviews": "/california/livermore/159-Sunset-Elementary-School/#Reviews",
            "collegeSuccess": "/california/livermore/159-Sunset-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "7%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 771,
        "parentRating": 3,
        "numReviews": 9,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 9
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 7
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 10
            },
            {
                "label": "Hispanic",
                "rating": 9,
                "percentage": 18
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 59
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 11
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19846,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.684555,
        "lon": -121.746475,
        "name": "Teddy Bear Child Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "397 Lincoln Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19846-Teddy-Bear-Child-Care/",
            "reviews": "/california/livermore/19846-Teddy-Bear-Child-Care/#Reviews",
            "collegeSuccess": "/california/livermore/19846-Teddy-Bear-Child-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19848,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.679623,
        "lon": -121.745987,
        "name": "The Learning Tree Preschool",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4161 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19848-The-Learning-Tree-Preschool/",
            "reviews": "/california/livermore/19848-The-Learning-Tree-Preschool/#Reviews",
            "collegeSuccess": "/california/livermore/19848-The-Learning-Tree-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15029,
        "districtId": 1257,
        "districtName": "Tri-Valley ROP",
        "districtCity": "Livermore",
        "levelCode": "h",
        "lat": 37.669163,
        "lon": -121.774445,
        "name": "Tri-Valley ROP",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "1040 Florence Road",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/15029-Tri-Valley-Rop-School/",
            "reviews": "/california/livermore/15029-Tri-Valley-Rop-School/#Reviews",
            "collegeSuccess": "/california/livermore/15029-Tri-Valley-Rop-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19854,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.681873,
        "lon": -121.768005,
        "name": "Trinity Day Care",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "Po Box 2168",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/19854-Trinity-Day-Care/",
            "reviews": "/california/livermore/19854-Trinity-Day-Care/#Reviews",
            "collegeSuccess": "/california/livermore/19854-Trinity-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15112,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.695576,
        "lon": -121.772003,
        "name": "Valley Montessori School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1273 North Livermore Avenue",
            "street2": null,
            "zip": "94551",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94551",
        "type": "school",
        "links": {
            "profile": "/california/livermore/15112-Valley-Montessori-School/",
            "reviews": "/california/livermore/15112-Valley-Montessori-School/#Reviews",
            "collegeSuccess": "/california/livermore/15112-Valley-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 400,
        "parentRating": 4,
        "numReviews": 28,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 80
            },
            {
                "label": "Asian",
                "percentage": 15
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15839,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.679607,
        "lon": -121.74353,
        "name": "Vine And Branches Christian Schools",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "4303 East Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/15839-Vine-And-Branches-Christian-Schools/",
            "reviews": "/california/livermore/15839-Vine-And-Branches-Christian-Schools/#Reviews",
            "collegeSuccess": "/california/livermore/15839-Vine-And-Branches-Christian-Schools/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 6,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10866,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "e,m,h",
        "lat": 37.677246,
        "lon": -121.740562,
        "name": "Vineyard Alternative",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "1401 Almond Avenue",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/10866-Vineyard-Alternative-School/",
            "reviews": "/california/livermore/10866-Vineyard-Alternative-School/#Reviews",
            "collegeSuccess": "/california/livermore/10866-Vineyard-Alternative-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "30%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "51%",
            "state_average": "64%"
        },
        "enrollment": 136,
        "parentRating": 5,
        "numReviews": 2,
        "studentsPerTeacher": 20,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 30
            },
            {
                "label": "White",
                "percentage": 51
            },
            {
                "label": "Hispanic",
                "percentage": 32
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Asian",
                "percentage": 1
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 153,
        "districtId": 10,
        "districtName": "Livermore Valley Joint Unified",
        "districtCity": "Livermore",
        "levelCode": "m",
        "lat": 37.663258,
        "lon": -121.793266,
        "name": "William Mendenhall Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "1701 El Padro Drive",
            "street2": null,
            "zip": "94550",
            "city": "Livermore"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94550",
        "type": "school",
        "links": {
            "profile": "/california/livermore/153-William-Mendenhall-Middle-School/",
            "reviews": "/california/livermore/153-William-Mendenhall-Middle-School/#Reviews",
            "collegeSuccess": "/california/livermore/153-William-Mendenhall-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "8%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 965,
        "parentRating": 3,
        "numReviews": 19,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 8
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 9
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 18
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 59
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "rating": 10,
                "percentage": 3
            }
        ],
        "remediationData": {}
    }
]