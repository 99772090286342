exports.MILLBRAE_SCHOOLS = [
		{
			"id": 20499,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.61359,
			"lon": -122.410179,
			"name": "Calvary Lutheran Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Santa Lucia & Cypress Aves",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/20499-Calvary-Lutheran-Pre-School/",
				"reviews": "/california/millbrae/20499-Calvary-Lutheran-Pre-School/#Reviews",
				"collegeSuccess": "/california/millbrae/20499-Calvary-Lutheran-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30628,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.60709,
			"lon": -122.414474,
			"name": "Glen Oaks Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "797 Santa Margarita Avenue",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/30628-Glen-Oaks-Montessori/",
				"reviews": "/california/millbrae/30628-Glen-Oaks-Montessori/#Reviews",
				"collegeSuccess": "/california/millbrae/30628-Glen-Oaks-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 33,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Two or more races",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 13404,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.60709,
			"lon": -122.414474,
			"name": "Glen Oaks Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "797 Santa Margarita Avenue",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/13404-Glen-Oaks-Montessori-School/",
				"reviews": "/california/millbrae/13404-Glen-Oaks-Montessori-School/#Reviews",
				"collegeSuccess": "/california/millbrae/13404-Glen-Oaks-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6904,
			"districtId": 792,
			"districtName": "Millbrae Elementary",
			"districtCity": "Millbrae",
			"levelCode": "e",
			"lat": 37.605091,
			"lon": -122.404816,
			"name": "Green Hills Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "401 Ludeman Lane",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/6904-Green-Hills-Elementary-School/",
				"reviews": "/california/millbrae/6904-Green-Hills-Elementary-School/#Reviews",
				"collegeSuccess": "/california/millbrae/6904-Green-Hills-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "17%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 385,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 17
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 51
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6906,
			"districtId": 792,
			"districtName": "Millbrae Elementary",
			"districtCity": "Millbrae",
			"levelCode": "e",
			"lat": 37.601994,
			"lon": -122.419205,
			"name": "Meadows Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1101 Helen Drive",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/6906-Meadows-Elementary-School/",
				"reviews": "/california/millbrae/6906-Meadows-Elementary-School/#Reviews",
				"collegeSuccess": "/california/millbrae/6906-Meadows-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 438,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 21
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 50
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "Filipino",
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 12895,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.60709,
			"lon": -122.414474,
			"name": "Millbrae Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "797 Santa Margarita Avenue",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/12895-Millbrae-Montessori/",
				"reviews": "/california/millbrae/12895-Millbrae-Montessori/#Reviews",
				"collegeSuccess": "/california/millbrae/12895-Millbrae-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20498,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.6091,
			"lon": -122.397484,
			"name": "Millbrae Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "86 Center Street",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/20498-Millbrae-Nursery-School/",
				"reviews": "/california/millbrae/20498-Millbrae-Nursery-School/#Reviews",
				"collegeSuccess": "/california/millbrae/20498-Millbrae-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6979,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.593525,
			"lon": -122.390617,
			"name": "Mills High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "400 Murchison Drive",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/6979-Mills-High-School/",
				"reviews": "/california/millbrae/6979-Mills-High-School/#Reviews",
				"collegeSuccess": "/california/millbrae/6979-Mills-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "86%",
				"state_average": "64%"
			},
			"enrollment": 1182,
			"parentRating": 4,
			"numReviews": 17,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 21
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 46
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 19
				},
				{
					"label": "Pacific Islander",
					"rating": 4,
					"percentage": 3
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 6
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29228,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.60709,
			"lon": -122.414474,
			"name": "Nurture & Nature",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "797 Santa Margarita Avenue",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/29228-Nurture--Nature/",
				"reviews": "/california/millbrae/29228-Nurture--Nature/#Reviews",
				"collegeSuccess": "/california/millbrae/29228-Nurture--Nature/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20496,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.595005,
			"lon": -122.414146,
			"name": "Peace of Mind Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1 Alp Way",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/20496-Peace-Of-Mind-Pre-School/",
				"reviews": "/california/millbrae/20496-Peace-Of-Mind-Pre-School/#Reviews",
				"collegeSuccess": "/california/millbrae/20496-Peace-Of-Mind-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6907,
			"districtId": 792,
			"districtName": "Millbrae Elementary",
			"districtCity": "Millbrae",
			"levelCode": "e",
			"lat": 37.591316,
			"lon": -122.391373,
			"name": "Spring Valley Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "817 Murchison Drive",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/6907-Spring-Valley-Elementary-School/",
				"reviews": "/california/millbrae/6907-Spring-Valley-Elementary-School/#Reviews",
				"collegeSuccess": "/california/millbrae/6907-Spring-Valley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 425,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 52
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 14
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8142,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.606674,
			"lon": -122.399918,
			"name": "St. Dunstan's Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1150 Magnolia Avenue",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/8142-St.-Dunstans-Elementary-School/",
				"reviews": "/california/millbrae/8142-St.-Dunstans-Elementary-School/#Reviews",
				"collegeSuccess": "/california/millbrae/8142-St.-Dunstans-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 254,
			"parentRating": 4,
			"numReviews": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6908,
			"districtId": 792,
			"districtName": "Millbrae Elementary",
			"districtCity": "Millbrae",
			"levelCode": "m",
			"lat": 37.598694,
			"lon": -122.402481,
			"name": "Taylor Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "850 Taylor Boulevard",
				"street2": null,
				"zip": "94030",
				"city": "Millbrae"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94030",
			"type": "school",
			"links": {
				"profile": "/california/millbrae/6908-Taylor-Middle-School/",
				"reviews": "/california/millbrae/6908-Taylor-Middle-School/#Reviews",
				"collegeSuccess": "/california/millbrae/6908-Taylor-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "28%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 825,
			"parentRating": 4,
			"numReviews": 4,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 28
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 6
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]