exports.PLEASANTON_LISTINGS = [
		{
			"closePrice": 1425000,
			"daysOnMovoto": 0,
			"id": "88dc9a8b-ccc2-43c9-ab7d-49cdc3fc9182",
			"tnImgPath": "https://pi.movoto.com/p/102/221150429_0_qNezr7_p.jpeg",
			"listDate": null,
			"listingAgent": "Aaron Turnbull",
			"listPrice": 1199000,
			"lotSize": 6098,
			"sqftTotal": 1489,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221150429",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Turnbull Real Estate Services, Inc.",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1975,
			"zipCode": "94588",
			"path": "pleasanton-ca/4677-jefferson-pleasanton-ca-94588-102_221150429/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9300d593-a6f6-4fa7-986f-6db42228adaa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-15 14:24:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-15 14:24:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.686321,
				"lng": -121.911887,
				"zipcode": "94588",
				"subPremise": "",
				"address": "4677 Jefferson"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 51,
			"id": "1fdfbb5a-c9c7-4a69-893d-190dfd222ad2",
			"tnImgPath": "https://pi.movoto.com/p/12/40973598_0_rBjvvm_p.jpeg",
			"listDate": "2021-11-07 00:00:00",
			"listingAgent": "Jim Lavey",
			"listPrice": 1850000,
			"lotSize": 17175,
			"sqftTotal": 2696,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973598",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "J R Lavey, REALTORS®",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94566",
			"path": "pleasanton-ca/80-castlewood-dr-pleasanton-ca-94566/pid_nfegvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3e1ba8ec-82f8-484e-b2c3-29dd99c57c23",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-07 17:26:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.632778,
				"lng": -121.899084,
				"zipcode": "94566",
				"subPremise": "",
				"address": "80 Castlewood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1445000,
			"daysOnMovoto": 35,
			"id": "daa9a31c-8d5e-4548-9fbc-c1d2c42d0105",
			"tnImgPath": "https://pi.movoto.com/p/12/40974096_0_BuvZjr_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Jeff Carter",
			"listPrice": 1399000,
			"lotSize": 7000,
			"sqftTotal": 1400,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974096",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "WR Properties",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1970,
			"zipCode": "94566",
			"path": "pleasanton-ca/3544-touriga-dr-pleasanton-ca-94566/pid_q1bgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "953550d6-ef56-4467-8d30-317c3137e3fa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-12 19:31:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.658666,
				"lng": -121.85277,
				"zipcode": "94566",
				"subPremise": "",
				"address": "3544 Touriga Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1445000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 820000,
			"daysOnMovoto": 42,
			"id": "e4b68552-92a9-47af-bf27-632bfc409be4",
			"tnImgPath": "https://pi.movoto.com/p/12/40973356_0_7reBJf_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Karyn Henson",
			"listPrice": 790000,
			"lotSize": 1027,
			"sqftTotal": 1474,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973356",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "ROCK & ASSOCIATES INC",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1976,
			"zipCode": "94588",
			"path": "pleasanton-ca/3143-saginaw-ct-pleasanton-ca-94588/pid_voegvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "701ac1db-ba50-4dd2-add1-bf5ae101e0a5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-05 02:36:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.689839,
				"lng": -121.881107,
				"zipcode": "94588",
				"subPremise": "",
				"address": "3143 Saginaw Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 35,
			"id": "a1fe290b-a383-4657-ace5-d8e978fbcf7a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973279_0_3QANRy_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Tiffany Stock",
			"listPrice": 1298000,
			"lotSize": 2850,
			"sqftTotal": 1900,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973279",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1989,
			"zipCode": "94566",
			"path": "pleasanton-ca/1633-holly-cir-pleasanton-ca-94566/pid_t9hgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a834ac84-9b6b-4c25-8c62-91a699419b2e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-10 16:41:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.680167,
				"lng": -121.867924,
				"zipcode": "94566",
				"subPremise": "",
				"address": "1633 Holly Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 675000,
			"daysOnMovoto": 83,
			"id": "2937e8d5-f1ad-4360-9bfb-9f86e22e026c",
			"tnImgPath": "https://pi.movoto.com/p/12/40967834_0_JyqyvZ_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Cindy Gee",
			"listPrice": 698000,
			"lotSize": 0,
			"sqftTotal": 937,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967834",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHG Reliance Partners",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1989,
			"zipCode": "94566",
			"path": "pleasanton-ca/243-rachael-pl-pleasanton-ca-94566/pid_7u7fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d473fb9f-0d2c-4d0b-960d-3dbbeaccf211",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-09-23 18:11:56",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2920482",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.667068,
				"lng": -121.868472,
				"zipcode": "94566",
				"subPremise": "",
				"address": "243 Rachael Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2920482"
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 39,
			"id": "a301c13f-0728-4c2c-87ea-b07c6da28865",
			"tnImgPath": "https://pi.movoto.com/p/12/40972888_0_fvV6iE_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Roger Brown",
			"listPrice": 1100000,
			"lotSize": 2850,
			"sqftTotal": 1609,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972888",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1986,
			"zipCode": "94566",
			"path": "pleasanton-ca/1426-groth-cir-pleasanton-ca-94566/pid_24dgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "78b31ea5-fb3f-4d7f-8d64-03038310e9bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-04 21:51:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Gv6rVP4XKem&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.677287,
				"lng": -121.87099,
				"zipcode": "94566",
				"subPremise": "",
				"address": "1426 Groth Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Gv6rVP4XKem&brand=0"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 14,
			"id": "34799934-4bc9-47a1-8e50-ea247d3070e4",
			"tnImgPath": "https://pi.movoto.com/p/110/421613872_0_rAa6AQ_p.jpeg",
			"listDate": "2021-11-26 00:00:00",
			"listingAgent": "Anna Gandamana",
			"listPrice": 999000,
			"lotSize": 1657,
			"sqftTotal": 1614,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421613872",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty One Group Infinity",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1991,
			"zipCode": "94588",
			"path": "pleasanton-ca/7724-creekside-dr-pleasanton-ca-94588-12_40164704/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f565afd0-c951-47f1-b760-8ec367fc1319",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-26 19:51:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.689218,
				"lng": -121.925353,
				"zipcode": "94588",
				"subPremise": "",
				"address": "7724 Creekside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2510000,
			"daysOnMovoto": 35,
			"id": "59b7a9cc-d68f-4ae0-bd97-855a12bd2835",
			"tnImgPath": "https://pi.movoto.com/p/12/40973183_0_EjVIF7_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Bob Cilk",
			"listPrice": 2000000,
			"lotSize": 22600,
			"sqftTotal": 3000,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973183",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "94566",
			"path": "pleasanton-ca/6-bunker-ln-pleasanton-ca-94566-12_11161625/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "17beb2d3-4785-4f61-a9c7-8d6f87ca7ecf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-05 23:01:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.634561,
				"lng": -121.89117,
				"zipcode": "94566",
				"subPremise": "",
				"address": "6 Bunker Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 35,
			"id": "e7464758-dfd7-458d-aa73-1236fa3c11d5",
			"tnImgPath": "https://pi.movoto.com/p/12/40972772_0_2FUUfN_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Mariah Bradford",
			"listPrice": 2599000,
			"lotSize": 219978,
			"sqftTotal": 2828,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972772",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Abio Properties",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1983,
			"zipCode": "94588",
			"path": "pleasanton-ca/7890-camino-tassajara-pleasanton-ca-94588-12_40972772/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "30a32cf8-089c-45cb-9eae-2d7e6d264737",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-02 13:11:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.753465,
				"lng": -121.872116,
				"zipcode": "94588",
				"subPremise": "",
				"address": "7890 Camino Tassajara"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1355000,
			"daysOnMovoto": 0,
			"id": "1cc3db56-a636-4f90-847a-a7bc3701763f",
			"tnImgPath": "https://pi.movoto.com/p/102/221139848_0_YveB3F_p.jpeg",
			"listDate": null,
			"listingAgent": "Jeff Purcell",
			"listPrice": 1200000,
			"lotSize": 6500,
			"sqftTotal": 1368,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221139848",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Home Buyers Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1965,
			"zipCode": "94566",
			"path": "pleasanton-ca/4134-francisco-st-pleasanton-ca-94566/pid_lhicvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b7e951f6-9b7d-4598-9c82-f07c65e8f010",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-06 05:54:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-06 05:54:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.674968,
				"lng": -121.871493,
				"zipcode": "94566",
				"subPremise": "",
				"address": "4134 Francisco St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1355000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 390000,
			"daysOnMovoto": 0,
			"id": "5b5cafc6-77c9-42ee-bbd3-2dfe2b3d001e",
			"tnImgPath": "https://pi.movoto.com/p/102/221065482_0_2MAyBn_p.jpeg",
			"listDate": null,
			"listingAgent": "Rene Stepps",
			"listPrice": 395000,
			"lotSize": 0,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221065482",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty ONE Group Complete",
			"photoCount": 33,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2020,
			"zipCode": "94566",
			"path": "pleasanton-ca/3231-vineyard-ave-apt-130-pleasanton-ca-94566-102_20058158/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "93bc098e-93a3-410d-88fd-231eb8f91c2e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-06-10 00:44:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 00:44:21",
			"virtualTourLink": "http://www.vrguild.net/idx/W54885",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.666731,
				"lng": -121.851455,
				"zipcode": "94566",
				"subPremise": "APT 130",
				"address": "3231 Vineyard Ave #130"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.vrguild.net/idx/W54885"
		},
		{
			"closePrice": 1410000,
			"daysOnMovoto": 35,
			"id": "80f03184-a3d1-4ed7-a36d-f0f83476cc49",
			"tnImgPath": "https://pi.movoto.com/p/12/40972701_0_feRrEb_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Aileen Matteson",
			"listPrice": 1150000,
			"lotSize": 6103,
			"sqftTotal": 1834,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972701",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94588",
			"path": "pleasanton-ca/3616-s-hawaii-ct-pleasanton-ca-94588-12_40972701/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bd9a9663-5c8a-414d-b42a-a8f339ada030",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-29 22:21:59",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/640170338",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.6766,
				"lng": -121.906841,
				"zipcode": "94588",
				"subPremise": "",
				"address": "3616 S Hawaii Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1410000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/640170338"
		},
		{
			"closePrice": 490000,
			"daysOnMovoto": 37,
			"id": "28686171-67e2-4888-b3d0-0d9a4e46ba55",
			"tnImgPath": "https://pi.movoto.com/p/12/40972337_0_nVqm3u_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Dennis Serrao",
			"listPrice": 480000,
			"lotSize": 0,
			"sqftTotal": 798,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972337",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Elation Real Estate",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94588",
			"path": "pleasanton-ca/8132-ensenada-dr-apt-1-pleasanton-ca-94588-12_40972337/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d72ec458-6596-425d-901c-703af167472f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-27 16:26:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://8132EnsenadaDr346105mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.650798,
				"lng": -121.904216,
				"zipcode": "94588",
				"subPremise": "APT 1",
				"address": "8132 Ensenada Dr #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 490000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://8132EnsenadaDr346105mls.f8re.com/"
		},
		{
			"closePrice": 780000,
			"daysOnMovoto": 50,
			"id": "2724a2b8-6b58-4f7b-896c-a5768039b0c5",
			"tnImgPath": "https://pi.movoto.com/p/12/40970595_0_beQrM2_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Julie Lauer",
			"listPrice": 779000,
			"lotSize": 2008,
			"sqftTotal": 941,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970595",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Patio Home",
			"yearBuilt": 1971,
			"zipCode": "94566",
			"path": "pleasanton-ca/5232-golden-rd-pleasanton-ca-94566/pid_96dgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5d1d8146-ba40-427d-b1ae-33199aa99a29",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-12 18:36:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/3973843",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.66758,
				"lng": -121.886211,
				"zipcode": "94566",
				"subPremise": "",
				"address": "5232 Golden Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/3973843"
		},
		{
			"closePrice": 340000,
			"daysOnMovoto": 54,
			"id": "3fed424d-6f1c-4af5-a3be-02156b525c0c",
			"tnImgPath": "https://pi.movoto.com/p/12/40970011_0_7ZfN73_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Eva Deagen",
			"listPrice": 345000,
			"lotSize": 1539,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970011",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 31,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94566",
			"path": "pleasanton-ca/3263-vineyard-avenue-apt-107-pleasanton-ca-94566-12_40847350/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d68ca86-849e-40a5-8d80-97143b7c9d06",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-07 16:51:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "http://www.vrguild.net/idx/W55303",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.666677,
				"lng": -121.853367,
				"zipcode": "94566",
				"subPremise": "APT 107",
				"address": "3263 Vineyard Avenue #107"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 340000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.vrguild.net/idx/W55303"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 38,
			"id": "b9ef0eed-1646-4cb0-b0c4-e1bce9bc86ee",
			"tnImgPath": "https://pi.movoto.com/p/12/40971588_0_7eviqM_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "DeAnna Armario",
			"listPrice": 1499000,
			"lotSize": 7003,
			"sqftTotal": 1783,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971588",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1937,
			"zipCode": "94566",
			"path": "pleasanton-ca/4408-1st-st-pleasanton-ca-94566/pid_nsccvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a920f6ad-a865-4167-b3e1-e1c217dca7ed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-22 20:16:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.660274,
				"lng": -121.872694,
				"zipcode": "94566",
				"subPremise": "",
				"address": "4408 1st St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1920000,
			"daysOnMovoto": 34,
			"id": "d70ebef0-8f3c-4f65-9c4e-8383b6aad98a",
			"tnImgPath": "https://pi.movoto.com/p/12/40971830_0_3RqVbe_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Liz Venema",
			"listPrice": 1599000,
			"lotSize": 12130,
			"sqftTotal": 2167,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971830",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1965,
			"zipCode": "94566",
			"path": "pleasanton-ca/5253-meadowwood-ct-pleasanton-ca-94566/pid_kkagvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5c67ae02-cdf5-4e30-8e61-9e059b9a8cd9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-21 22:17:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2928659",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.670656,
				"lng": -121.886634,
				"zipcode": "94566",
				"subPremise": "",
				"address": "5253 Meadowwood Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1920000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2928659"
		},
		{
			"closePrice": 1060000,
			"daysOnMovoto": 34,
			"id": "7a268c17-97e7-4836-8919-512d77a2d9ef",
			"tnImgPath": "https://pi.movoto.com/p/12/40971765_0_6mAmjq_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Steve Fast",
			"listPrice": 950000,
			"lotSize": 2850,
			"sqftTotal": 1609,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971765",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1985,
			"zipCode": "94566",
			"path": "pleasanton-ca/1426-elliott-cir-pleasanton-ca-94566-12_40971765/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fc87ced8-4617-48bd-81ec-e4d1f65aa9f1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-21 18:36:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/DYhpd0pKfPU",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.677373,
				"lng": -121.872827,
				"zipcode": "94566",
				"subPremise": "",
				"address": "1426 Elliott Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1060000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/DYhpd0pKfPU"
		},
		{
			"closePrice": 160000,
			"daysOnMovoto": 42,
			"id": "11e57ead-c76f-4fc8-b3df-1caf438d27cd",
			"tnImgPath": "https://pi.movoto.com/p/12/40970706_0_yNbAE2_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Rick Sira",
			"listPrice": 160000,
			"lotSize": 1440,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970706",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 18,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94566",
			"path": "pleasanton-ca/3231-vineyard-apt-108-pleasanton-ca-94566-12_40938784/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee065278-3fd2-415c-82e6-e05065fdc9cc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-13 16:51:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.666731,
				"lng": -121.851455,
				"zipcode": "94566",
				"subPremise": "APT 108",
				"address": "3231 Vineyard #108"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 160000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 5100000,
			"daysOnMovoto": 104,
			"id": "6c5973e8-d94b-4e8f-83bd-11f7a0fa5343",
			"tnImgPath": "https://pi.movoto.com/p/12/40962707_0_JEQ7ye_p.jpeg",
			"listDate": "2021-08-11 00:00:00",
			"listingAgent": "Daniel Gamache",
			"listPrice": 5250000,
			"lotSize": 35899,
			"sqftTotal": 8075,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962707",
			"bath": 8.5,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2002,
			"zipCode": "94588",
			"path": "pleasanton-ca/8096-horizons-ct-pleasanton-ca-94588/pid_i41fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2963381c-69b7-41ca-8ad2-2fec718d1b50",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-08-11 22:11:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.68256,
				"lng": -121.929578,
				"zipcode": "94588",
				"subPremise": "",
				"address": "8096 Horizons Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1065000,
			"daysOnMovoto": 0,
			"id": "2d10806f-309a-4331-af38-95193ad6e912",
			"tnImgPath": "https://pi.movoto.com/p/102/221130584_0_fEU7E7_p.jpeg",
			"listDate": null,
			"listingAgent": "Cindi Cereceres-Anderson",
			"listPrice": 1075000,
			"lotSize": 1369,
			"sqftTotal": 1928,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221130584",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Dunnigan, REALTORS",
			"photoCount": 43,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1994,
			"zipCode": "94588",
			"path": "pleasanton-ca/3120-delicado-ct-pleasanton-ca-94588/pid_7i7fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "11b484fa-428a-44a9-8a23-c44766920aff",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-14 14:34:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 14:34:28",
			"virtualTourLink": "https://view.paradym.com/v/3120-Delicado-Ct-Pleasanton-CA-94588/4678933/sk/400",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.686203,
				"lng": -121.888861,
				"zipcode": "94588",
				"subPremise": "",
				"address": "3120 Delicado Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1065000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://view.paradym.com/v/3120-Delicado-Ct-Pleasanton-CA-94588/4678933/sk/400"
		},
		{
			"closePrice": 365000,
			"daysOnMovoto": 27,
			"id": "fd7bb701-1da0-40ff-9e24-fc166dc659a7",
			"tnImgPath": "https://pi.movoto.com/p/12/40971293_0_FqfYme_p.jpeg",
			"listDate": "2021-10-16 00:00:00",
			"listingAgent": "Eva Deagen",
			"listPrice": 365000,
			"lotSize": 1560,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971293",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 4,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94566",
			"path": "pleasanton-ca/3263-vineyard-avenue-apt-51-pleasanton-ca-94566-12_40971293/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f68e4d80-56d5-4258-a905-726f1ca8db97",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-17 00:00:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.666677,
				"lng": -121.853367,
				"zipcode": "94566",
				"subPremise": "APT 51",
				"address": "3263 Vineyard Avenue #51"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 365000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1460000,
			"daysOnMovoto": 37,
			"id": "17dee09a-333f-42e0-8ed9-2a62d10ef083",
			"tnImgPath": "https://pi.movoto.com/p/12/40969207_0_iN3JMJ_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Marti Gilbert",
			"listPrice": 1199000,
			"lotSize": 7385,
			"sqftTotal": 1790,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969207",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Iron Horse Real Estate",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1965,
			"zipCode": "94566",
			"path": "pleasanton-ca/1546-ridgewood-rd-pleasanton-ca-94566/pid_2kdgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "051cbe93-82eb-4b8a-b789-7982bfb8233b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-06 16:51:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2919819",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.671739,
				"lng": -121.879806,
				"zipcode": "94566",
				"subPremise": "",
				"address": "1546 Ridgewood Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1460000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2919819"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 45,
			"id": "028d49a8-c91f-434d-a130-54f589f63d5d",
			"tnImgPath": "https://pi.movoto.com/p/12/40968866_0_yJiF6R_p.jpeg",
			"listDate": "2021-09-28 00:00:00",
			"listingAgent": "Douglas Snyder",
			"listPrice": 1150000,
			"lotSize": 6178,
			"sqftTotal": 1433,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968866",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Excel Realty",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1989,
			"zipCode": "94566",
			"path": "pleasanton-ca/1602-holly-cir-pleasanton-ca-94566/pid_acigvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d3e08b9d-14f8-421e-ad34-a2538fca0980",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-09-28 20:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.680194,
				"lng": -121.867252,
				"zipcode": "94566",
				"subPremise": "",
				"address": "1602 Holly Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 56,
			"id": "bddf5503-be6f-4cc2-8ae6-ed5f9cd42173",
			"tnImgPath": "https://pi.movoto.com/p/12/40967200_0_AR3i3A_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Tom Sinclair",
			"listPrice": 849000,
			"lotSize": 4250,
			"sqftTotal": 1164,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967200",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "United Realty Partners",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1977,
			"zipCode": "94566",
			"path": "pleasanton-ca/6217-hansen-dr-pleasanton-ca-94566/pid_gicgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50000,
			"propertyId": "6e82e1ba-ae94-4d1c-88c5-ffb129a1861f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-18 03:30:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.669138,
				"lng": -121.90141,
				"zipcode": "94566",
				"subPremise": "",
				"address": "6217 Hansen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1585000,
			"daysOnMovoto": 39,
			"id": "c24dd218-f2f9-4306-afad-5146cefd6a73",
			"tnImgPath": "https://pi.movoto.com/p/12/40969214_0_ibnfVn_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Katie Moe",
			"listPrice": 1349000,
			"lotSize": 6695,
			"sqftTotal": 1948,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969214",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Arrive Real Estate",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1981,
			"zipCode": "94588",
			"path": "pleasanton-ca/3626-fairlands-pleasanton-ca-94588-12_40969214/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "255bdb67-36be-43c8-a18d-22ba0cfe4452",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-09-30 23:31:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.699035,
				"lng": -121.863622,
				"zipcode": "94588",
				"subPremise": "",
				"address": "3626 Fairlands"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1585000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 37,
			"id": "05ea4313-e4e1-4d67-adaa-3662ecbc24ae",
			"tnImgPath": "https://pi.movoto.com/p/12/40967792_0_mMij6u_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Catherine Consolino",
			"listPrice": 2395950,
			"lotSize": 10585,
			"sqftTotal": 3447,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967792",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pacific Associates",
			"photoCount": 11,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1998,
			"zipCode": "94566",
			"path": "pleasanton-ca/2445-romano-cir-pleasanton-ca-94566/pid_crjgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9259c841-ca01-4205-ad31-ed032644d896",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-29 20:26:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.637493,
				"lng": -121.816359,
				"zipcode": "94566",
				"subPremise": "",
				"address": "2445 Romano Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3225000,
			"daysOnMovoto": 44,
			"id": "f329305e-7c40-41cb-8932-209325ae5572",
			"tnImgPath": "https://pi.movoto.com/p/12/40967174_0_YIErv6_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Cindy Gee",
			"listPrice": 2688000,
			"lotSize": 16055,
			"sqftTotal": 3500,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967174",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "BHG Reliance Partners",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1995,
			"zipCode": "94588",
			"path": "pleasanton-ca/2765-lylewood-dr-pleasanton-ca-94588-12_11039129/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9df37ae7-c8f7-4520-af5e-bcfd561ccebb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-22 18:11:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2916476",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.65955,
				"lng": -121.90942,
				"zipcode": "94588",
				"subPremise": "",
				"address": "2765 Lylewood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2916476"
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 27,
			"id": "8fe62cfd-7ef6-4150-b03a-9b57e2caf750",
			"tnImgPath": "https://pi.movoto.com/p/12/40970263_0_FbNiyz_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "John Wojciechowski",
			"listPrice": 1699000,
			"lotSize": 7928,
			"sqftTotal": 2792,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970263",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94588",
			"path": "pleasanton-ca/3728-yosemite-ct-pleasanton-ca-94588-12_40952123/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "91ccbdc4-eb52-42ea-91f1-b1b5902bf796",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-08 19:01:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.676815,
				"lng": -121.909109,
				"zipcode": "94588",
				"subPremise": "",
				"address": "3728 Yosemite Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 695000,
			"daysOnMovoto": 32,
			"id": "3d19ee60-8f2d-4033-a112-8e85ed0bd41a",
			"tnImgPath": "https://pi.movoto.com/p/12/40969501_0_NYBavR_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Kelley Krock",
			"listPrice": 695000,
			"lotSize": 1548,
			"sqftTotal": 860,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969501",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1985,
			"zipCode": "94566",
			"path": "pleasanton-ca/vine-st-pleasanton-ca-94566-12_40084116/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "02b698e1-f5e5-4be5-bf32-c576acacd717",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-10-02 23:46:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=3RQQvhBhjzC&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.664347,
				"lng": -121.864054,
				"zipcode": "94566",
				"subPremise": "",
				"address": "3813 Vine St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 695000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=3RQQvhBhjzC&mls=1"
		},
		{
			"closePrice": 880000,
			"daysOnMovoto": 40,
			"id": "bb49a6e0-33e9-48ef-8f1f-7cd5774ad870",
			"tnImgPath": "https://pi.movoto.com/p/12/40968616_0_iifbVQ_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Mark Gissler",
			"listPrice": 828000,
			"lotSize": 0,
			"sqftTotal": 1690,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968616",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1982,
			"zipCode": "94566",
			"path": "pleasanton-ca/5471-black-apt-1-pleasanton-ca-94566-12_40968616/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c00c9e9b-2308-4370-b946-4cab15f9db1f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-25 02:36:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://5471Blackaveunit147272mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.672452,
				"lng": -121.890797,
				"zipcode": "94566",
				"subPremise": "APT 1",
				"address": "5471 Black #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://5471Blackaveunit147272mls.f8re.com/"
		},
		{
			"closePrice": 1498000,
			"daysOnMovoto": 36,
			"id": "027a6fde-e42f-4e9c-b257-1f1bb85717e9",
			"tnImgPath": "https://pi.movoto.com/p/12/40968812_0_M3qMIi_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Frank Qin",
			"listPrice": 1469000,
			"lotSize": 3253,
			"sqftTotal": 1987,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968812",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94566",
			"path": "pleasanton-ca/130-barias-pl-pleasanton-ca-94566-12_40824986/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "66e96161-743d-4e6c-aaf6-fb3bb045700b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-28 02:56:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.666871,
				"lng": -121.871892,
				"zipcode": "94566",
				"subPremise": "",
				"address": "130 Barias Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1498000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 41,
			"id": "0d4d342a-9725-4340-92ff-e65518ab98d2",
			"tnImgPath": "https://pi.movoto.com/p/12/40967544_0_Qfz3UJ_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Cindy Gee",
			"listPrice": 1198000,
			"lotSize": 9451,
			"sqftTotal": 1868,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967544",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHG Reliance Partners",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94588",
			"path": "pleasanton-ca/4453-clovewood-ln-pleasanton-ca-94588/pid_c4zfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3babe911-0673-4de2-9c36-f95338c2cb84",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-22 18:11:53",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2920483",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.681577,
				"lng": -121.915777,
				"zipcode": "94588",
				"subPremise": "",
				"address": "4453 Clovewood Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2920483"
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 48,
			"id": "716bee41-b150-4e2b-8472-c00851ff68d6",
			"tnImgPath": "https://pi.movoto.com/p/12/40967089_0_yjbqrA_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Cindy Gee",
			"listPrice": 2248000,
			"lotSize": 6500,
			"sqftTotal": 2759,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967089",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHG Reliance Partners",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1968,
			"zipCode": "94588",
			"path": "pleasanton-ca/4905-hillcrest-way-pleasanton-ca-94588/pid_1ayfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "954d7c26-cf9b-4e19-ab11-b1c8ef5a61c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-15 18:56:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.682952,
				"lng": -121.921577,
				"zipcode": "94588",
				"subPremise": "",
				"address": "4905 Hillcrest Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1315000,
			"daysOnMovoto": 0,
			"id": "c13b2101-2904-4e93-bbd9-a8a170862c95",
			"tnImgPath": "https://pi.movoto.com/p/102/221124825_0_JFRAfE_p.jpeg",
			"listDate": null,
			"listingAgent": "Brandice Banta",
			"listPrice": 1288000,
			"lotSize": 5929,
			"sqftTotal": 1337,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221124825",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1975,
			"zipCode": "94566",
			"path": "pleasanton-ca/373-kottinger-dr-pleasanton-ca-94566-12_10510706/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3ba3105d-d9c2-4d65-83b3-6d00ffaa715f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-30 02:59:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 02:59:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.660655,
				"lng": -121.86782,
				"zipcode": "94566",
				"subPremise": "",
				"address": "373 Kottinger Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1315000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 125,
			"id": "1710609b-c946-431d-b9a7-a3560e9121bb",
			"tnImgPath": "https://pi.movoto.com/p/12/40956370_0_RQvzEu_p.jpeg",
			"listDate": "2021-06-26 00:00:00",
			"listingAgent": "Herman Chan",
			"listPrice": 3200000,
			"lotSize": 219107,
			"sqftTotal": 4480,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956370",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's Int'l Re",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1980,
			"zipCode": "94588",
			"path": "pleasanton-ca/4100-foothill-rd-pleasanton-ca-94588-12_28518073/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -250000,
			"propertyId": "c423fcac-fc5e-470d-9f7b-c60fc7e47066",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-06-27 01:11:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-26 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=RYeEofRWGJN&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.668063,
				"lng": -121.921168,
				"zipcode": "94588",
				"subPremise": "",
				"address": "4100 Foothill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=RYeEofRWGJN&mls=1"
		},
		{
			"closePrice": 560000,
			"daysOnMovoto": 31,
			"id": "abbf123a-b854-453a-bd23-8b29e1c578bf",
			"tnImgPath": "https://pi.movoto.com/p/12/40968622_0_U7a6vq_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Liz McKinney",
			"listPrice": 550000,
			"lotSize": 0,
			"sqftTotal": 810,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968622",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94588",
			"path": "pleasanton-ca/2113-arroyo-ct-4-pleasanton-ca-94588-12_40968622/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6c4814c1-4bce-4970-ac76-5c27f79179ae",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-27 23:01:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": "https://unbranded.youriguide.com/4_2113_arroyo_ct_pleasanton_ca/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.650245,
				"lng": -121.90387,
				"zipcode": "94588",
				"subPremise": " 4",
				"address": "2113 Arroyo Ct # 4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://unbranded.youriguide.com/4_2113_arroyo_ct_pleasanton_ca/"
		},
		{
			"closePrice": 280000,
			"daysOnMovoto": 34,
			"id": "1e6e5f11-ffa5-4bb4-96ed-a1b4ac0c0896",
			"tnImgPath": "https://pi.movoto.com/p/12/40968619_0_Rj6yiJ_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Eva Deagen",
			"listPrice": 295000,
			"lotSize": 1472,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968619",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 26,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94566",
			"path": "pleasanton-ca/3263-vineyard-avenue-apt-20-pleasanton-ca-94566-12_40968619/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3aaed6b3-366f-4870-9315-b222285af5a4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-25 03:01:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "http://www.vrguild.net/idx/W55297",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.666677,
				"lng": -121.853367,
				"zipcode": "94566",
				"subPremise": "APT 20",
				"address": "3263 Vineyard Avenue #20"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.vrguild.net/idx/W55297"
		},
		{
			"closePrice": 1900888,
			"daysOnMovoto": 36,
			"id": "3d6dc9d6-15f0-4a5d-bb6e-db0280b79b58",
			"tnImgPath": "https://pi.movoto.com/p/12/40967782_0_3BurjB_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Kirt Willard",
			"listPrice": 1750000,
			"lotSize": 8186,
			"sqftTotal": 2624,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967782",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Valley Brokers on Main",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1985,
			"zipCode": "94588",
			"path": "pleasanton-ca/3155-randick-ct-pleasanton-ca-94588-12_40082600/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "60866493-e7cf-4d52-b0ca-12063dfa2d62",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-22 23:51:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/7426384",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.696173,
				"lng": -121.864556,
				"zipcode": "94588",
				"subPremise": "",
				"address": "3155 Randick Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/7426384"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 42,
			"id": "749a126e-2f17-4db6-ae87-a9ac3d406a6a",
			"tnImgPath": "https://pi.movoto.com/p/12/40967194_0_ubVN2J_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Tom Sinclair",
			"listPrice": 849000,
			"lotSize": 4250,
			"sqftTotal": 1164,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967194",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "United Realty Partners",
			"photoCount": 12,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1977,
			"zipCode": "94566",
			"path": "pleasanton-ca/6203-hansen-dr-pleasanton-ca-94566/pid_xicgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50000,
			"propertyId": "936d7722-70f2-4b15-9184-24a6c0714a1a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-09-15 20:16:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.669138,
				"lng": -121.90141,
				"zipcode": "94566",
				"subPremise": "",
				"address": "6203 Hansen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2292000,
			"daysOnMovoto": 40,
			"id": "b9072047-effa-42f8-9ce5-eef69615f7ff",
			"tnImgPath": "https://pi.movoto.com/p/12/40967157_0_iuQy6U_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Brigitte Huntemann",
			"listPrice": 2000000,
			"lotSize": 7002,
			"sqftTotal": 3038,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967157",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Venture Sotheby's Intl Rlty",
			"photoCount": 2,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "94588",
			"path": "pleasanton-ca/7659-maywood-dr-pleasanton-ca-94588/pid_ix4fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f5f7f27d-da47-49ce-92b3-a40e12327cbf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-15 17:26:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.68598,
				"lng": -121.924,
				"zipcode": "94588",
				"subPremise": "",
				"address": "7659 Maywood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2292000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 41,
			"id": "bb62f48d-550b-41df-9077-c4ec7d331579",
			"tnImgPath": "https://pi.movoto.com/p/12/40967060_0_RQnqBb_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Lloyd Felix",
			"listPrice": 1200000,
			"lotSize": 3440,
			"sqftTotal": 1792,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967060",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 2,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1991,
			"zipCode": "94588",
			"path": "pleasanton-ca/5593-baldwin-way-pleasanton-ca-94588-12_40064477/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ab324d44-36cb-4677-bf52-1e264e3a0cea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-15 19:00:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.68887,
				"lng": -121.928909,
				"zipcode": "94588",
				"subPremise": "",
				"address": "5593 Baldwin Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1190000,
			"daysOnMovoto": 30,
			"id": "4db3688a-d9c2-4789-a5ec-244d53b86e8b",
			"tnImgPath": "https://pi.movoto.com/p/12/40968309_0_zy2v3V_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Jenny Dawkins",
			"listPrice": 1145500,
			"lotSize": 6547,
			"sqftTotal": 1171,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968309",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94566",
			"path": "pleasanton-ca/320-christina-ct-pleasanton-ca-94566-12_11146489/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "76ab5ff6-9a16-479b-8eb3-48ccd2c48a2b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-23 00:00:00",
			"createdAt": "2021-09-23 21:41:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.662493,
				"lng": -121.866001,
				"zipcode": "94566",
				"subPremise": "",
				"address": "320 Christina Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1190000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1710000,
			"daysOnMovoto": 36,
			"id": "7b7140bf-0679-4c38-b2aa-d0566fa49696",
			"tnImgPath": "https://pi.movoto.com/p/12/40966152_0_zMrAfi_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Christina Rice",
			"listPrice": 1475000,
			"lotSize": 8961,
			"sqftTotal": 1979,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966152",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1981,
			"zipCode": "94566",
			"path": "pleasanton-ca/659-windmill-ln-pleasanton-ca-94566-12_26584343/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6e72c064-beee-462b-9624-a56d686ba379",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-16 15:06:59",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.653065,
				"lng": -121.870159,
				"zipcode": "94566",
				"subPremise": "",
				"address": "659 Windmill Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1140000,
			"daysOnMovoto": 51,
			"id": "85a1cd1e-a218-451e-8560-2700dd4460a0",
			"tnImgPath": "https://pi.movoto.com/p/12/40965518_0_znjfqi_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Michelle Ward",
			"listPrice": 1195000,
			"lotSize": 3400,
			"sqftTotal": 1383,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965518",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94566",
			"path": "pleasanton-ca/676-grapevine-dr-pleasanton-ca-94566-12_26584616/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ae383f55-5902-4fb7-be22-45854ca96766",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-02 01:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/tours/qha5eq?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.665922,
				"lng": -121.847432,
				"zipcode": "94566",
				"subPremise": "",
				"address": "676 Grapevine Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1140000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/tours/qha5eq?mls"
		},
		{
			"closePrice": 1950000,
			"daysOnMovoto": 27,
			"id": "47d9947a-df06-4187-86a8-f9f9dcdba0a6",
			"tnImgPath": "https://pi.movoto.com/p/12/40968292_0_6ubEnz_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Robert Song",
			"listPrice": 1699000,
			"lotSize": 6500,
			"sqftTotal": 2201,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968292",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1968,
			"zipCode": "94588",
			"path": "pleasanton-ca/4933-drywood-st-pleasanton-ca-94588/pid_91xfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "35677bbd-5447-45a1-96da-ca58de13f90c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-09-23 20:36:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "http://www.4933drywood.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.683513,
				"lng": -121.920955,
				"zipcode": "94588",
				"subPremise": "",
				"address": "4933 Drywood St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.4933drywood.com/?mls"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 33,
			"id": "ddab0800-dca7-4636-ab11-56bb9bef2c91",
			"tnImgPath": "https://pi.movoto.com/p/12/40967520_0_j337fz_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "David Duarte",
			"listPrice": 1199999,
			"lotSize": 6201,
			"sqftTotal": 1372,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967520",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1971,
			"zipCode": "94588",
			"path": "pleasanton-ca/6855-singletree-ct-pleasanton-ca-94588-12_27504894/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6455277b-e783-48c3-a129-a819ffe34488",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-09-17 15:46:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.680346,
				"lng": -121.913131,
				"zipcode": "94588",
				"subPremise": "",
				"address": "6855 Singletree Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 41,
			"id": "7fd6409c-a204-431b-811e-9cdcd3c3b144",
			"tnImgPath": "https://pi.movoto.com/p/12/40966390_0_AEUJZA_p.jpeg",
			"listDate": "2021-09-09 00:00:00",
			"listingAgent": "Liz Venema",
			"listPrice": 1249000,
			"lotSize": 6200,
			"sqftTotal": 1531,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966390",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1970,
			"zipCode": "94588",
			"path": "pleasanton-ca/4044-page-ct-pleasanton-ca-94588/pid_xh3fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4bc1c63f-40fa-4358-8c13-6bf760cd9956",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-09-09 16:11:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2905032",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.68271,
				"lng": -121.908042,
				"zipcode": "94588",
				"subPremise": "",
				"address": "4044 Page Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2905032"
		},
		{
			"closePrice": 525000,
			"daysOnMovoto": 18,
			"id": "7b7d315c-5cdc-4720-9009-2d458e92bff9",
			"tnImgPath": "https://pi.movoto.com/p/12/40969353_0_AfJyf3_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Olesya Ananyev",
			"listPrice": 495000,
			"lotSize": 0,
			"sqftTotal": 903,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969353",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94588",
			"path": "pleasanton-ca/8090-arroyo-dr-apt-2-pleasanton-ca-94588-12_40969353/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fe521fe1-8542-4906-8d40-c6038cb1ff4d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-10-01 19:46:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Pleasanton",
				"lat": 37.6514,
				"lng": -121.90498,
				"zipcode": "94588",
				"subPremise": "APT 2",
				"address": "8090 Arroyo Dr #2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]
