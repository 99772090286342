import React from 'react'

const CallToAction4 = ({bgColor, btnSTyle, paddingClass, text, city}) => {
    return (
        <section className={`${paddingClass} call-to-action-2`} style={{backgroundColor: `${bgColor}`}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="">
                            <h3 style={{color: "#fff", marginTop: "10px", fontSize: "24px"}}>{text}</h3>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <a href={"/" + city + "-real-estate-map"} className={`btn btn-primary ${btnSTyle}`}>点击查看</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction4
