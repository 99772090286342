exports.LIVERMORE_LISTINGS = [
		{
			"closePrice": 865000,
			"daysOnMovoto": 0,
			"id": "300c9ae5-e70a-4d51-a373-2f122992ea44",
			"tnImgPath": "https://pi.movoto.com/p/102/221143727_0_Z2naZa_p.jpeg",
			"listDate": null,
			"listingAgent": "Steven Patterson",
			"listPrice": 865000,
			"lotSize": 5001,
			"sqftTotal": 1112,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221143727",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Ideal California Homes",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1930,
			"zipCode": "94551",
			"path": "livermore-ca/364-n-m-st-livermore-ca-94550-12_28508286/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 15000,
			"propertyId": "96554afe-c9cf-4059-a640-188213565970",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-11-12 14:24:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 14:24:23",
			"virtualTourLink": "https://www.youtube.com/embed/SxJufnyfUic",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.684903,
				"lng": -121.774194,
				"zipcode": "94551",
				"subPremise": "",
				"address": "364 N M St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 865000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/SxJufnyfUic"
		},
		{
			"closePrice": 660000,
			"daysOnMovoto": 0,
			"id": "1b54cadd-0011-44b0-9dea-e25f8c5a3f86",
			"tnImgPath": "https://pi.movoto.com/p/102/221144493_0_2uiyJq_p.jpeg",
			"listDate": null,
			"listingAgent": "Marty Hackworth",
			"listPrice": 679000,
			"lotSize": 0,
			"sqftTotal": 1274,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221144493",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Hackworth Real Estate",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1987,
			"zipCode": "94551",
			"path": "livermore-ca/1941-monterey-dr-livermore-ca-94551-102_221144493/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9445b301-1cc8-43a0-aa70-01e2e78cd52e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-11-13 03:09:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 03:09:21",
			"virtualTourLink": "https://www.youtube.com/embed/HvHcyNJcieU",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.713609,
				"lng": -121.748591,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1941 Monterey Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 660000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/HvHcyNJcieU"
		},
		{
			"closePrice": 235000,
			"daysOnMovoto": 68,
			"id": "8c344760-445f-4b04-8ddf-a30f6fb4ed8b",
			"tnImgPath": "https://pi.movoto.com/p/12/40972558_0_AN3I3V_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Robert Costa",
			"listPrice": 235000,
			"lotSize": 1270,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972558",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 40,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94551",
			"path": "livermore-ca/728-sundial-cir-livermore-ca-94551-12_40972558/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4bada32b-b38d-47aa-9f2b-d4f3e77c2f25",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-10-28 23:16:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.705436,
				"lng": -121.736132,
				"zipcode": "94551",
				"subPremise": "",
				"address": "728 Sundial Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 235000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 0,
			"id": "ac10064b-76e7-46f7-8636-4bf5b17da8db",
			"tnImgPath": "https://pi.movoto.com/p/102/221149283_0_aJMQar_p.jpeg",
			"listDate": null,
			"listingAgent": "Simon Mills",
			"listPrice": 895000,
			"lotSize": 1581,
			"sqftTotal": 1802,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221149283",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Mills Realty",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2016,
			"zipCode": "94550",
			"path": "livermore-ca/2974-worthing-common-livermore-ca-94550-102_221149283/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5261e21-b70b-46b8-9e84-bcf4f48fb222",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-12-02 00:34:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:34:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.686164,
				"lng": -121.76099,
				"zipcode": "94550",
				"subPremise": "",
				"address": "2974 Worthing Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 651000,
			"daysOnMovoto": 0,
			"id": "1c4bc2fd-863b-460d-9256-d56b8346f264",
			"tnImgPath": "https://pi.movoto.com/p/102/221152903_0_bn6mva_p.jpeg",
			"listDate": null,
			"listingAgent": "Kelly Murdock",
			"listPrice": 650000,
			"lotSize": 4547,
			"sqftTotal": 960,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221152903",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Excel Realty",
			"photoCount": 6,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "94551",
			"path": "livermore-ca/467-adelle-st-livermore-ca-94551/pid_okjcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "175b1207-c840-4753-a89d-f98ebf25bcdf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-12-14 12:29:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-14 12:29:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.68452,
				"lng": -121.78047,
				"zipcode": "94551",
				"subPremise": "",
				"address": "467 Adelle St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 651000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 805000,
			"daysOnMovoto": 49,
			"id": "eafde964-7d01-49a6-a863-d854bd484d06",
			"tnImgPath": "https://pi.movoto.com/p/12/40973108_0_RZ3YUY_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Laura Nelson",
			"listPrice": 734000,
			"lotSize": 7929,
			"sqftTotal": 960,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973108",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94551",
			"path": "livermore-ca/644-adelle-st-livermore-ca-94551-12_40281642/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c4bed76-e66f-4bd5-b87c-0880bb7001ca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-11 19:21:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.686071,
				"lng": -121.780292,
				"zipcode": "94551",
				"subPremise": "",
				"address": "644 Adelle St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 805000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 955095,
			"daysOnMovoto": 56,
			"id": "5e4387d4-a85c-4a59-b45f-6e77fa8a831d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868953_0_6rBQVq_p.jpeg",
			"listDate": "2021-11-02 19:41:23",
			"listingAgent": "Veronica Roberson",
			"listPrice": 955095,
			"lotSize": 1063,
			"sqftTotal": 1929,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868953",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Taylor Morrison Services Inc",
			"photoCount": 8,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94551",
			"path": "livermore-ca/247-n-n-st-livermore-ca-94551-100_ml81868953/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a4a74e5d-9c74-4438-89d2-b7241bace6d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-03 02:47:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 19:41:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.683458,
				"lng": -121.775497,
				"zipcode": "94551",
				"subPremise": "",
				"address": "247 N N St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 955095,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 40,
			"id": "6ef0e3ee-0a44-4e40-b2fe-4e3a02efb49f",
			"tnImgPath": "https://pi.movoto.com/p/12/40974562_0_feIUBV_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Kelly King",
			"listPrice": 769000,
			"lotSize": 6113,
			"sqftTotal": 1036,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974562",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94551",
			"path": "livermore-ca/1182-hibiscus-way-livermore-ca-94550-12_27596862/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1804dbc0-c347-4128-8a1c-0b4bedae500e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-18 21:31:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.709618,
				"lng": -121.738047,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1182 Hibiscus Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 776000,
			"daysOnMovoto": 49,
			"id": "7fabada8-2439-44a6-91f5-68744858a75c",
			"tnImgPath": "https://pi.movoto.com/p/202/P1-7429_0_zNaayE_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "John Acevedo",
			"listPrice": 750000,
			"lotSize": 5393,
			"sqftTotal": 1605,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "P1-7429",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Dilbeck Real Estate",
			"photoCount": 11,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 1959,
			"zipCode": "94551",
			"path": "livermore-ca/358-albatross-ave-livermore-ca-94551/pid_g4icvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d2f48216-7d7f-4e78-95ca-515488065b67",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-10 18:07:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.683518,
				"lng": -121.786434,
				"zipcode": "94551",
				"subPremise": "",
				"address": "358 Albatross Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 776000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1035000,
			"daysOnMovoto": 17,
			"id": "4dda915e-1b3f-4058-a129-58a34101f197",
			"tnImgPath": "https://pi.movoto.com/p/101/321112004_0_YNaMII_p.jpeg",
			"listDate": "2021-12-10 00:00:00",
			"listingAgent": "Bob Triglia",
			"listPrice": 850000,
			"lotSize": 6199,
			"sqftTotal": 1573,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321112004",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Solano Pacific",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1968,
			"zipCode": "94551",
			"path": "livermore-ca/1472-arlington-rd-livermore-ca-94551/pid_5hlcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c102d9c-047e-4acf-92d7-45ef9925ec6d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-12-11 00:28:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.694478,
				"lng": -121.79707,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1472 Arlington Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1035000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 43,
			"id": "256aabe3-e69e-4f1b-86f5-a905fa6f0116",
			"tnImgPath": "https://pi.movoto.com/p/12/40972471_0_EnZqaN_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Jeannine Rinetti",
			"listPrice": 2188000,
			"lotSize": 14137,
			"sqftTotal": 3532,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972471",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Rinetti & Co., REALTORS®",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2001,
			"zipCode": "94550",
			"path": "livermore-ca/185-campo-ct-livermore-ca-94550/pid_txocvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "83b6d915-dfe5-4547-ad19-e4ae2041e66f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-11 01:46:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.657544,
				"lng": -121.801386,
				"zipcode": "94550",
				"subPremise": "",
				"address": "185 Campo Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 170000,
			"daysOnMovoto": 181,
			"id": "85d69924-0e2c-409a-9b40-0521a9f3e5d3",
			"tnImgPath": "https://pi.movoto.com/p/12/40954566_0_m7yuNi_p.jpeg",
			"listDate": "2021-06-23 00:00:00",
			"listingAgent": "Michael Dosanjh",
			"listPrice": 179900,
			"lotSize": 1040,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954566",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "The Hagley Group",
			"photoCount": 40,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94551",
			"path": "livermore-ca/1840-montecito-cir-livermore-ca-94551-12_40954566/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "14fe4002-6807-4bf6-bf70-3c8755ae9627",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-06-23 15:36:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.697731,
				"lng": -121.790511,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1840 Montecito Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 170000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 960000,
			"daysOnMovoto": 17,
			"id": "344b63dd-f6d7-4e88-80ee-5de9c0e33f7d",
			"tnImgPath": "https://pi.movoto.com/p/12/40975570_0_JnFyAa_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Dan Flores",
			"listPrice": 799000,
			"lotSize": 5000,
			"sqftTotal": 2143,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975570",
			"bath": 5,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 6,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1897,
			"zipCode": "94551",
			"path": "livermore-ca/492-n-livermore-ave-livermore-ca-94551/pid_98kcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "37ab26df-2ebe-41b7-86a7-85dcf506ee3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-12-04 02:06:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.687298,
				"lng": -121.770943,
				"zipcode": "94551",
				"subPremise": "",
				"address": "492 N Livermore Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 960000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1390000,
			"daysOnMovoto": 0,
			"id": "72172c7f-bd14-4f5a-bdc6-d482885f7964",
			"tnImgPath": "https://pi.movoto.com/p/102/221153840_0_BzZ3YB_p.jpeg",
			"listDate": null,
			"listingAgent": "Kevin Baxter",
			"listPrice": 1349000,
			"lotSize": 10454,
			"sqftTotal": 1674,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221153840",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty Sierra Foothills",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1975,
			"zipCode": "94550",
			"path": "livermore-ca/2438-windsor-pl-livermore-ca-94550/pid_8wucvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5190f344-76dc-4e53-8c15-30b75d874ec8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-12-18 06:04:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-18 06:04:17",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/2438-windsor-pl-livermore-ca-94550-1398026/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.651985,
				"lng": -121.77958,
				"zipcode": "94550",
				"subPremise": "",
				"address": "2438 Windsor Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/2438-windsor-pl-livermore-ca-94550-1398026/unbranded"
		},
		{
			"closePrice": 750000,
			"daysOnMovoto": 13,
			"id": "925ebcfe-cc0a-4755-b044-f2453f748295",
			"tnImgPath": "https://pi.movoto.com/p/12/40975527_0_mqZnju_p.jpeg",
			"listDate": "2021-12-04 00:00:00",
			"listingAgent": "Joel Engel",
			"listPrice": 675000,
			"lotSize": 6225,
			"sqftTotal": 1036,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975527",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94551",
			"path": "livermore-ca/1137-hibiscus-livermore-ca-94551-12_40975527/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6cbe48d2-98ab-4912-b7fc-507af8047c27",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-12-05 00:31:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-04 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/4494576",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.708664,
				"lng": -121.738292,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1137 Hibiscus"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/4494576"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 32,
			"id": "9c14f576-87d1-45fb-a31d-a02889804f45",
			"tnImgPath": "https://pi.movoto.com/p/12/40974328_0_MbEyRf_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "Cooper Eisenmann",
			"listPrice": 1249000,
			"lotSize": 7480,
			"sqftTotal": 1798,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974328",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "94550",
			"path": "livermore-ca/148-medina-st-livermore-ca-94550-12_11153176/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b615aba4-8fbe-453f-8b34-76cd8a6ee0ca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-16 03:11:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.69138,
				"lng": -121.740251,
				"zipcode": "94550",
				"subPremise": "",
				"address": "148 Medina St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 600000,
			"daysOnMovoto": 28,
			"id": "93a52146-53d9-4d70-888c-5aca487bfa75",
			"tnImgPath": "https://pi.movoto.com/p/12/40974450_0_niEIZQ_p.jpeg",
			"listDate": "2021-11-17 00:00:00",
			"listingAgent": "David Dreffs",
			"listPrice": 600000,
			"lotSize": 1140,
			"sqftTotal": 1274,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974450",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1987,
			"zipCode": "94551",
			"path": "livermore-ca/1937-monterey-dr-livermore-ca-94551/pid_8bwcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bc0904b0-0b25-4167-b2fa-3d2d1680b9dc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-17 19:21:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.713609,
				"lng": -121.748449,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1937 Monterey Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 57000,
			"daysOnMovoto": 87,
			"id": "8443a0ee-4a88-450b-a11c-e7a8ad4584d3",
			"tnImgPath": "https://pi.movoto.com/p/12/40967770_0_AQ3eVQ_p.jpeg",
			"listDate": "2021-09-19 00:00:00",
			"listingAgent": "Robert Costa",
			"listPrice": 65000,
			"lotSize": 620,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967770",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 30,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94550",
			"path": "livermore-ca/1915-montecito-cir-livermore-ca-94550-12_40967770/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3b69580d-7b37-46a9-8f45-9de0584b0932",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-09-20 00:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.699059,
				"lng": -121.790006,
				"zipcode": "94550",
				"subPremise": "",
				"address": "1915 Montecito Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 57000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 255000,
			"daysOnMovoto": 1,
			"id": "9aa28747-547f-4998-b39b-8dcc9bceab43",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-12-13 00:00:00",
			"listingAgent": "Michael Dosanjh",
			"listPrice": 255000,
			"lotSize": 1680,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40976231",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Hagley Group",
			"photoCount": 0,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94551",
			"path": "livermore-ca/1852-montecito-cir-livermore-ca-94551-12_40976231/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "68fba4b3-2018-4da8-8640-725fea9ba55b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-12-14 05:41:19",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-12-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.697762,
				"lng": -121.789814,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1852 Montecito Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 255000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 730000,
			"daysOnMovoto": 34,
			"id": "23c2da04-f421-4024-9905-8f7abbe15e9a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973852_0_nRy2Mu_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Jennifer Malakoff",
			"listPrice": 725000,
			"lotSize": 5439,
			"sqftTotal": 924,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973852",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1982,
			"zipCode": "94551",
			"path": "livermore-ca/1787-carnation-cir-livermore-ca-94551/pid_woscvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a5bee2ea-ce9f-4ea3-a01e-045d6ea9f9b4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-10 22:26:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.713339,
				"lng": -121.746996,
				"zipcode": "94551",
				"subPremise": "",
				"address": "1787 Carnation Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 730000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2178000,
			"daysOnMovoto": 37,
			"id": "18d68f7c-6a4f-4c3d-baf7-dce23e1545a2",
			"tnImgPath": "https://pi.movoto.com/p/12/40971483_0_MEAIBj_p.jpeg",
			"listDate": "2021-11-06 00:00:00",
			"listingAgent": "Katherine Couture",
			"listPrice": 2178000,
			"lotSize": 10003,
			"sqftTotal": 3532,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971483",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2001,
			"zipCode": "94550",
			"path": "livermore-ca/1655-cascina-ct-livermore-ca-94550/pid_yuocvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "db5a33ec-97b3-46a4-9e43-048195ef094d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-06 15:56:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-06 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/tours/igebvm?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.658318,
				"lng": -121.802053,
				"zipcode": "94550",
				"subPremise": "",
				"address": "1655 Cascina Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2178000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/tours/igebvm?mls"
		},
		{
			"closePrice": 951000,
			"daysOnMovoto": 40,
			"id": "c926f0cb-8203-44dd-90b5-9aafea1dbca9",
			"tnImgPath": "https://pi.movoto.com/p/12/40973051_0_ybbQQZ_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Perry Vittoria",
			"listPrice": 825000,
			"lotSize": 6308,
			"sqftTotal": 1240,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973051",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rinetti & Co., REALTORS®",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1982,
			"zipCode": "94551",
			"path": "livermore-ca/4331-cherry-blossom-way-livermore-ca-94550-12_27501339/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4915f347-f587-4c20-89f2-e38b611aec3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-03 15:21:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.713737,
				"lng": -121.746723,
				"zipcode": "94551",
				"subPremise": "",
				"address": "4331 Cherry Blossom Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 951000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 790000,
			"daysOnMovoto": 38,
			"id": "b9f221f7-3cc6-4598-bfe8-baa3b3788d9c",
			"tnImgPath": "https://pi.movoto.com/p/12/40971762_0_yAzjRj_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Andrea Rozran",
			"listPrice": 785000,
			"lotSize": 0,
			"sqftTotal": 1337,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971762",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2017,
			"zipCode": "94551",
			"path": "livermore-ca/361-basswood-cmn-apt-15-livermore-ca-94551-12_40971762/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "27bcc996-b6fc-483d-a259-bbb17e225acb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-02 18:01:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.703926,
				"lng": -121.792008,
				"zipcode": "94551",
				"subPremise": "APT 15",
				"address": "361 Basswood Cmn #15"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 790000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1280000,
			"daysOnMovoto": 65,
			"id": "b2833895-7962-45bd-b95e-bf41e0ead4ba",
			"tnImgPath": "https://pi.movoto.com/p/12/40969888_0_VfjejY_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Patti Smylie",
			"listPrice": 1195000,
			"lotSize": 14199,
			"sqftTotal": 2163,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969888",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1971,
			"zipCode": "94550",
			"path": "livermore-ca/5428-evelyn-way-livermore-ca-94550/pid_0r1cvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a67e142-b7e8-472f-9158-f8013c5d9b66",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-06 21:41:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2923529",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.689566,
				"lng": -121.72529,
				"zipcode": "94550",
				"subPremise": "",
				"address": "5428 Evelyn Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2923529"
		},
		{
			"closePrice": 1196000,
			"daysOnMovoto": 0,
			"id": "2b383f33-f504-458b-9b16-5d23731dc3c1",
			"tnImgPath": "https://pi.movoto.com/p/102/221137467_0_U2jFiB_p.jpeg",
			"listDate": null,
			"listingAgent": "Rochelle Popovits",
			"listPrice": 1150000,
			"lotSize": 4901,
			"sqftTotal": 1813,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221137467",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Grupe Gold",
			"photoCount": 45,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1998,
			"zipCode": "94551",
			"path": "livermore-ca/6139-augusta-way-livermore-ca-94551/pid_ze4cvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3301e671-3dae-4f95-badf-69bfe8d8f6f8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-27 06:54:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 06:54:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.715372,
				"lng": -121.722819,
				"zipcode": "94551",
				"subPremise": "",
				"address": "6139 Augusta Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1196000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2260000,
			"daysOnMovoto": 39,
			"id": "78e04d1a-6415-45c3-b01b-f273d89ccc61",
			"tnImgPath": "https://pi.movoto.com/p/12/40972442_0_E6jMIv_p.jpeg",
			"listDate": "2021-10-31 00:00:00",
			"listingAgent": "Michael LeBow",
			"listPrice": 1985000,
			"lotSize": 16497,
			"sqftTotal": 3571,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972442",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Black Diamond & Assoc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1999,
			"zipCode": "94550",
			"path": "livermore-ca/2122-bella-vista-pl-livermore-ca-94550-12_11207722/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "26667f95-9e3b-468e-90cd-ada6951b169b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-01 02:11:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.658426,
				"lng": -121.773189,
				"zipcode": "94550",
				"subPremise": "",
				"address": "2122 Bella Vista Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 47,
			"id": "e0a0a49a-8455-4bee-b9bb-78f1bd600ff0",
			"tnImgPath": "https://pi.movoto.com/p/12/40972100_0_Qvravr_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Karen Neuer",
			"listPrice": 1599998,
			"lotSize": 8400,
			"sqftTotal": 3270,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972100",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "The Neuer Group",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2003,
			"zipCode": "94550",
			"path": "livermore-ca/5851-elder-cir-livermore-ca-94550/pid_58ycvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8a8f065d-e32d-49bf-9a4a-5e1b8afdeb7c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-23 07:51:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/5856835",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.676333,
				"lng": -121.723954,
				"zipcode": "94550",
				"subPremise": "",
				"address": "5851 Elder Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/5856835"
		},
		{
			"closePrice": 340000,
			"daysOnMovoto": 65,
			"id": "54735ff2-c3a4-46ea-bacc-b990587b1306",
			"tnImgPath": "https://pi.movoto.com/p/12/40967769_0_rBBiff_p.jpeg",
			"listDate": "2021-10-04 00:00:00",
			"listingAgent": "Debbie Lavell",
			"listPrice": 334999,
			"lotSize": 0,
			"sqftTotal": 758,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967769",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Real Estate Depot",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94550",
			"path": "livermore-ca/1087-murrieta-blvd-341-livermore-ca-94550/pid_1ukcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "d9260e5b-57ac-4ced-8a11-e4585c74f57e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-05 00:51:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.675151,
				"lng": -121.781444,
				"zipcode": "94550",
				"subPremise": "APT 341",
				"address": "1087 Murrieta Blvd #341"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 340000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 937000,
			"daysOnMovoto": 0,
			"id": "57c75573-6fae-4901-a332-25ab3380656c",
			"tnImgPath": "https://pi.movoto.com/p/102/221115967_0_uYe2qz_p.jpeg",
			"listDate": null,
			"listingAgent": "Amandeep Dhanjal",
			"listPrice": 944000,
			"lotSize": 1641,
			"sqftTotal": 1955,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221115967",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Exit Realty Consultants",
			"photoCount": 47,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2015,
			"zipCode": "94550",
			"path": "livermore-ca/2749-patcham-common-livermore-ca-94550-12_40684776/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -5000,
			"propertyId": "bc0cabcd-0d5b-46fb-8363-dd9132e0e174",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-09-21 00:59:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:59:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.685035,
				"lng": -121.763782,
				"zipcode": "94550",
				"subPremise": "",
				"address": "2749 Patcham Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 937000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1075000,
			"daysOnMovoto": 35,
			"id": "81bffffe-ff5c-4876-9b4b-cf12f0b1b3e0",
			"tnImgPath": "https://pi.movoto.com/p/12/40973005_0_yIYbQE_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Bernadette Troncales Weir",
			"listPrice": 1125000,
			"lotSize": 0,
			"sqftTotal": 1982,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973005",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Catalina Realty Investments",
			"photoCount": 11,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2019,
			"zipCode": "94551",
			"path": "livermore-ca/202-plum-tree-st-livermore-ca-94551-12_40973005/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e287b25a-9a37-43a5-b2ca-3fbaa696d24a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-02 21:11:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.688909,
				"lng": -121.754881,
				"zipcode": "94551",
				"subPremise": "",
				"address": "202 Plum Tree St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1075000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 40,
			"id": "ec70cc79-7d68-4da9-bff0-fcf4b0347e46",
			"tnImgPath": "https://pi.movoto.com/p/12/40972321_0_izMABY_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Gordon Corsie",
			"listPrice": 1425000,
			"lotSize": 13256,
			"sqftTotal": 2433,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972321",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The Scotsman Realty Group",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "94550",
			"path": "livermore-ca/5375-bianca-way-livermore-ca-94550-12_11033631/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "053c5dd5-4963-48d7-a978-430676b80195",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-28 21:01:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.694426,
				"lng": -121.727005,
				"zipcode": "94550",
				"subPremise": "",
				"address": "5375 Bianca Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 947000,
			"daysOnMovoto": 42,
			"id": "b2d2dc78-d7a1-439e-9093-9d880f1a7eaf",
			"tnImgPath": "https://pi.movoto.com/p/12/40971088_0_FMBFiQ_p.jpeg",
			"listDate": "2021-10-26 00:00:00",
			"listingAgent": "Melissa Leiser",
			"listPrice": 915000,
			"lotSize": 8550,
			"sqftTotal": 1567,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971088",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1990,
			"zipCode": "94551",
			"path": "livermore-ca/902-bellflower-st-livermore-ca-94550-12_11045572/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5e1d5524-8821-42d6-a2ca-bf9a2eaf71c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-26 17:01:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.709179,
				"lng": -121.732882,
				"zipcode": "94551",
				"subPremise": "",
				"address": "902 Bellflower St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 947000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 330000,
			"daysOnMovoto": 102,
			"id": "1e785627-580c-4a75-b4e4-6de386b9b76a",
			"tnImgPath": "https://pi.movoto.com/p/12/40964902_0_yBYAAj_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Duane Newberry",
			"listPrice": 344900,
			"lotSize": 0,
			"sqftTotal": 758,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964902",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "EXP Realty",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94550",
			"path": "livermore-ca/1087-murrieta-blvd-136-livermore-ca-94550/pid_emjcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 25000,
			"propertyId": "5e9da67c-a8ac-4e0b-9ce6-268705314170",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-08-27 14:11:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": "https://drive.google.com/file/d/1iiNeqCiYpqphEpRqRkC9epwbjS6ggE-W/view?usp=sharing",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.675151,
				"lng": -121.781444,
				"zipcode": "94550",
				"subPremise": "APT 136",
				"address": "1087 Murrieta Blvd #136"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 330000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://drive.google.com/file/d/1iiNeqCiYpqphEpRqRkC9epwbjS6ggE-W/view?usp=sharing"
		},
		{
			"closePrice": 1885000,
			"daysOnMovoto": 46,
			"id": "83d1427e-bfaf-40ee-97bc-cbd0e10c8539",
			"tnImgPath": "https://pi.movoto.com/p/12/40971233_0_IZqVFr_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Nicole Sweeney",
			"listPrice": 1698000,
			"lotSize": 8520,
			"sqftTotal": 2912,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971233",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 4,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1997,
			"zipCode": "94550",
			"path": "livermore-ca/233-tourmaline-ave-livermore-ca-94550/pid_ytmcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b85fc663-3212-41a3-8b26-55ad13ed29eb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-22 03:11:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.661737,
				"lng": -121.80014,
				"zipcode": "94550",
				"subPremise": "",
				"address": "233 Tourmaline Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1885000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 38,
			"id": "b6e98bbc-9563-453c-ba6c-3b62e650b204",
			"tnImgPath": "https://pi.movoto.com/p/12/40971308_0_vIAIfQ_p.jpeg",
			"listDate": "2021-10-26 00:00:00",
			"listingAgent": "Jeannine Rinetti",
			"listPrice": 1249000,
			"lotSize": 8622,
			"sqftTotal": 1994,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971308",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rinetti & Co., REALTORS®",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1971,
			"zipCode": "94550",
			"path": "livermore-ca/1342-berlin-way-livermore-ca-94550-12_40044575/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "85116e0e-9408-490b-b170-c338463fa45d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-26 14:46:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.664307,
				"lng": -121.77889,
				"zipcode": "94550",
				"subPremise": "",
				"address": "1342 Berlin Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 35,
			"id": "ccd7e821-f7ac-4e8f-ad48-11a573dfdd0c",
			"tnImgPath": "https://pi.movoto.com/p/12/40971575_0_yjZAIY_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Kelly Paulsen",
			"listPrice": 1175000,
			"lotSize": 2950,
			"sqftTotal": 2021,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971575",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2013,
			"zipCode": "94551",
			"path": "livermore-ca/500-selby-ln-livermore-ca-94551/pid_fwwfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dedbed68-da5d-4e89-813d-b2568b51670d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-10-28 16:56:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": "https://www.500selbylane.com/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.709124,
				"lng": -121.798894,
				"zipcode": "94551",
				"subPremise": "",
				"address": "500 Selby Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.500selbylane.com/unbranded"
		},
		{
			"closePrice": 944000,
			"daysOnMovoto": 77,
			"id": "21b8f248-444e-4419-9ae6-5a88c07b39f7",
			"tnImgPath": "https://pi.movoto.com/p/12/40967186_0_ZvnQ7F_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Robyn Annicchero",
			"listPrice": 939950,
			"lotSize": 0,
			"sqftTotal": 2030,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967186",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2019,
			"zipCode": "94551",
			"path": "livermore-ca/105-apricot-livermore-ca-94551-12_40967186/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4826ef72-ff38-4530-a6d7-9e23bb15655f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-09-18 16:06:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.688341,
				"lng": -121.755157,
				"zipcode": "94551",
				"subPremise": "",
				"address": "105 Apricot"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 944000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1725000,
			"daysOnMovoto": 34,
			"id": "746c9ab2-c4a8-4da9-9e42-03c0b865f87c",
			"tnImgPath": "https://pi.movoto.com/p/12/40972397_0_nzAbUQ_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Lia Wendell",
			"listPrice": 1699000,
			"lotSize": 20002,
			"sqftTotal": 2582,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972397",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94550",
			"path": "livermore-ca/520-lagrange-ln-livermore-ca-94550-12_40972397/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a877513e-8253-43db-8592-e6ea50f70170",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-27 21:56:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://pinnacletour.hd.pics/520-Lagrange-Ln/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.657471,
				"lng": -121.794199,
				"zipcode": "94550",
				"subPremise": "",
				"address": "520 Lagrange Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://pinnacletour.hd.pics/520-Lagrange-Ln/idx"
		},
		{
			"closePrice": 1215000,
			"daysOnMovoto": 34,
			"id": "8b018df2-7b56-40c3-a60d-87a4a2036001",
			"tnImgPath": "https://pi.movoto.com/p/12/40971312_0_vFAAQi_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Nicole Sweeney",
			"listPrice": 998000,
			"lotSize": 5000,
			"sqftTotal": 1690,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971312",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1995,
			"zipCode": "94550",
			"path": "livermore-ca/1527-3rd-st-livermore-ca-94550-12_28514332/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fdf8ff6e-586e-42e4-8b21-5fa34e1cb746",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-27 19:06:56",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/3199349",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.677551,
				"lng": -121.776242,
				"zipcode": "94550",
				"subPremise": "",
				"address": "1527 3rd St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1215000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/3199349"
		},
		{
			"closePrice": 1010000,
			"daysOnMovoto": 48,
			"id": "932e6e35-1d3f-47fd-b3b8-506195d2612e",
			"tnImgPath": "https://pi.movoto.com/p/12/40970686_0_MQrIAB_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "John Miller",
			"listPrice": 949000,
			"lotSize": 8125,
			"sqftTotal": 1368,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970686",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Miller Service Real Estate",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1999,
			"zipCode": "94551",
			"path": "livermore-ca/2628-gelding-ln-livermore-ca-94551/pid_f52cvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "42a5331a-2b79-4807-9836-476e5e636772",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-13 07:11:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.727293,
				"lng": -121.729765,
				"zipcode": "94551",
				"subPremise": "",
				"address": "2628 Gelding Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1010000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1710000,
			"daysOnMovoto": 39,
			"id": "d6690f6d-02e2-41ab-9d8c-e561dc7702d9",
			"tnImgPath": "https://pi.movoto.com/p/12/40970485_0_NN6beq_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Romar De Claro",
			"listPrice": 1650000,
			"lotSize": 9000,
			"sqftTotal": 3541,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970485",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2002,
			"zipCode": "94550",
			"path": "livermore-ca/5849-dresslar-cir-livermore-ca-94550-12_40575453/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31086e2b-7a6a-43a1-a295-1e7aceb242c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-21 16:56:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.677906,
				"lng": -121.723954,
				"zipcode": "94550",
				"subPremise": "",
				"address": "5849 Dresslar Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1660000,
			"daysOnMovoto": 40,
			"id": "1cac84c8-a2d3-4d24-827e-853264ffe038",
			"tnImgPath": "https://pi.movoto.com/p/12/40971611_0_MEuRnR_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Christopher Tarantino",
			"listPrice": 1595000,
			"lotSize": 8700,
			"sqftTotal": 2897,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971611",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Tarantino Real Estate, Inc.",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2005,
			"zipCode": "94550",
			"path": "livermore-ca/2378-senger-st-livermore-ca-94550/pid_pv2cvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f4db8d4b-534d-4933-8b3d-fe58f95ad349",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-20 21:21:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2928745",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.671806,
				"lng": -121.722997,
				"zipcode": "94550",
				"subPremise": "",
				"address": "2378 Senger St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1660000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2928745"
		},
		{
			"closePrice": 780000,
			"daysOnMovoto": 35,
			"id": "a8e1cbb5-4cc4-45e3-8e2d-5acc684766c1",
			"tnImgPath": "https://pi.movoto.com/p/12/40971670_0_UYeYyb_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Brian Wright",
			"listPrice": 727888,
			"lotSize": 0,
			"sqftTotal": 1580,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971670",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Valley Brokers on Main",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2015,
			"zipCode": "94551",
			"path": "livermore-ca/3977-portola-cmn-apt-4-livermore-ca-94551-12_40971670/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "63317dcf-1449-49a5-a763-3ee8e85d05aa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-21 01:21:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/2728169",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.690823,
				"lng": -121.755037,
				"zipcode": "94551",
				"subPremise": "APT 4",
				"address": "3977 Portola Cmn #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/2728169"
		},
		{
			"closePrice": 687000,
			"daysOnMovoto": 22,
			"id": "ae393284-1214-4825-860a-5f3256af46b8",
			"tnImgPath": "https://pi.movoto.com/p/110/421607050_0_vmeiJA_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Betty Taisch",
			"listPrice": 649000,
			"lotSize": 7857,
			"sqftTotal": 1225,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607050",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 45,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2004,
			"zipCode": "94551",
			"path": "livermore-ca/6170-forget-me-not-common-livermore-ca-94551-100_ml81653171/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "490beb78-69ac-4458-a239-1125c890d9d1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-11-15 08:16:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.713995,
				"lng": -121.722551,
				"zipcode": "94551",
				"subPremise": "",
				"address": "6170 Forget Me Not Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 687000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 27,
			"id": "51d75c96-8139-43f7-ac29-a188e986006a",
			"tnImgPath": "https://pi.movoto.com/p/12/40972405_0_nVInj3_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Mike Carey",
			"listPrice": 825000,
			"lotSize": 910,
			"sqftTotal": 1843,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972405",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Investment Real Estate",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2016,
			"zipCode": "94551",
			"path": "livermore-ca/975-bluebell-dr-livermore-ca-94551-12_40972405/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6dcfbc87-af27-4193-ae03-906c8485d176",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-27 22:41:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.705901,
				"lng": -121.738793,
				"zipcode": "94551",
				"subPremise": "",
				"address": "975 Bluebell Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 40,
			"id": "5a17c7cd-e8fc-4307-a6b7-91d868e8a2dd",
			"tnImgPath": "https://pi.movoto.com/p/12/40970853_0_2meEAU_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Rich Hill",
			"listPrice": 975000,
			"lotSize": 0,
			"sqftTotal": 1673,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970853",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2017,
			"zipCode": "94551",
			"path": "livermore-ca/144-teasel-cmn-livermore-ca-94551-12_40970853/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d41004a2-54dc-406f-8a12-54a65d67e00b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-14 12:16:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.705264,
				"lng": -121.793919,
				"zipcode": "94551",
				"subPremise": "",
				"address": "144 Teasel Cmn"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 849950,
			"daysOnMovoto": 60,
			"id": "62f5c180-7fcd-4c9f-81a3-5188cc6a94ff",
			"tnImgPath": "https://pi.movoto.com/p/12/40968297_0_QfbyU7_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Greg Francis",
			"listPrice": 869950,
			"lotSize": 0,
			"sqftTotal": 1747,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968297",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2016,
			"zipCode": "94551",
			"path": "livermore-ca/3997-portola-cmn-apt-1-livermore-ca-94551-12_40968297/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ca095dd-d424-44e7-8829-9868204069ae",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-09-23 20:56:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.690714,
				"lng": -121.754911,
				"zipcode": "94551",
				"subPremise": "APT 1",
				"address": "3997 Portola Cmn #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 849950,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2030000,
			"daysOnMovoto": 36,
			"id": "f9a84b23-5530-490f-8223-d7e61dfbce31",
			"tnImgPath": "https://pi.movoto.com/p/12/40970965_0_b3ABRJ_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Natalie Swanson",
			"listPrice": 1899888,
			"lotSize": 18317,
			"sqftTotal": 2799,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970965",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1997,
			"zipCode": "94550",
			"path": "livermore-ca/2054-latour-ave-livermore-ca-94550-12_11020966/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d115e766-4e37-4a25-9321-8185b89d6041",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-14 21:31:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/3d-matterport/5j1fmz?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.658759,
				"lng": -121.767406,
				"zipcode": "94550",
				"subPremise": "",
				"address": "2054 Latour Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2030000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/3d-matterport/5j1fmz?mls"
		},
		{
			"closePrice": 1225000,
			"daysOnMovoto": 57,
			"id": "70877fca-5416-47a9-8670-c15e9f165801",
			"tnImgPath": "https://pi.movoto.com/p/12/40966017_0_yUfUZB_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Tracy Debus",
			"listPrice": 1225000,
			"lotSize": 11466,
			"sqftTotal": 1708,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966017",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vintage Real Estate",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94550",
			"path": "livermore-ca/392-jeannie-way-livermore-ca-94550-12_11036579/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d8333646-5ff3-408e-9607-f6182c192b84",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-09-23 21:22:05",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.688257,
				"lng": -121.740429,
				"zipcode": "94550",
				"subPremise": "",
				"address": "392 Jeannie Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 825000,
			"daysOnMovoto": 29,
			"id": "a431d25c-0826-43fc-85ff-07af8f38c2ec",
			"tnImgPath": "https://pi.movoto.com/p/12/40971581_0_Zqiyu2_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Gordon Corsie",
			"listPrice": 799950,
			"lotSize": 5414,
			"sqftTotal": 1001,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971581",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Scotsman Realty Group",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "94550",
			"path": "livermore-ca/santa-clara-way-livermore-ca-94550-12_40011036/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7dcf0609-8161-4411-9758-4c8220a800b5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-10-20 18:21:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/8326130",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Livermore",
				"lat": 37.68556,
				"lng": -121.754942,
				"zipcode": "94550",
				"subPremise": "",
				"address": "3807 Santa Clara Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/8326130"
		}
	]