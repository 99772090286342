const walnut_creek = {
    "geography":{
       "census_release":"ACS 2019 1-year",
       "parents":{
          "county":{
             "full_name":"Contra Costa County, CA",
             "short_name":"Contra Costa County",
             "sumlevel":"050",
             "land_area":1857229826,
             "full_geoid":"05000US06013",
             "total_population":1153526
          },
          "CBSA":{
             "full_name":"San Francisco-Oakland-Berkeley, CA Metro Area",
             "short_name":"San Francisco-Oakland-Berkeley, CA",
             "sumlevel":"310",
             "land_area":6398229497,
             "full_geoid":"31000US41860",
             "total_population":4731803
          },
          "state":{
             "full_name":"California",
             "short_name":"California",
             "sumlevel":"040",
             "land_area":403660088482,
             "full_geoid":"04000US06",
             "total_population":39512223
          },
          "nation":{
             "full_name":"United States",
             "short_name":"United States",
             "sumlevel":"010",
             "land_area":9160971212108,
             "full_geoid":"01000US",
             "total_population":328239523
          }
       },
       "this":{
          "full_name":"Walnut Creek, CA",
          "short_name":"Walnut Creek",
          "sumlevel":"160",
          "land_area":51169190,
          "full_geoid":"16000US0683346",
          "total_population":70161,
          "sumlevel_name":"Place",
          "short_geoid":"0683346"
       },
       "comparatives":[
          "CBSA",
          "state"
       ],
       "census_release_year":"19",
       "census_release_level":"1"
    },
    "demographics":{
       "age":{
          "distribution_by_category":{
             "metadata":{
                "table_id":"B01001",
                "universe":"Total population",
                "acs_release":"ACS 2019 1-year"
             },
             "percent_under_18":{
                "name":"Under 18",
                "values":{
                   "this":16.99,
                   "CBSA":19.53,
                   "state":22.5
                },
                "error":{
                   "this":3.34,
                   "CBSA":0.2,
                   "state":0.08
                },
                "numerators":{
                   "this":11922.0,
                   "CBSA":924120.0,
                   "state":8891064.0
                },
                "numerator_errors":{
                   "this":2340.7,
                   "CBSA":9455.0,
                   "state":32008.5
                },
                "index":{
                   "this":100.0,
                   "CBSA":87.0,
                   "state":76.0
                },
                "error_ratio":{
                   "this":19.7,
                   "CBSA":1.0,
                   "state":0.4
                }
             },
             "percent_18_to_64":{
                "name":"18 to 64",
                "values":{
                   "this":55.2,
                   "CBSA":64.56,
                   "state":62.73
                },
                "error":{
                   "this":4.94,
                   "CBSA":0.37,
                   "state":0.13
                },
                "numerators":{
                   "this":38731.0,
                   "CBSA":3054854.0,
                   "state":24786161.0
                },
                "numerator_errors":{
                   "this":3468.0,
                   "CBSA":17723.5,
                   "state":51870.1
                },
                "index":{
                   "this":100.0,
                   "CBSA":86.0,
                   "state":88.0
                },
                "error_ratio":{
                   "this":8.9,
                   "CBSA":0.6,
                   "state":0.2
                }
             },
             "percent_over_65":{
                "name":"65 and over",
                "values":{
                   "this":27.8,
                   "CBSA":15.91,
                   "state":14.77
                },
                "error":{
                   "this":2.73,
                   "CBSA":0.24,
                   "state":0.08
                },
                "numerators":{
                   "this":19508.0,
                   "CBSA":752829.0,
                   "state":5834998.0
                },
                "numerator_errors":{
                   "this":1918.6,
                   "CBSA":11568.6,
                   "state":32031.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":175.0,
                   "state":188.0
                },
                "error_ratio":{
                   "this":9.8,
                   "CBSA":1.5,
                   "state":0.5
                }
             }
          },
          "distribution_by_decade":{
             "total":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 1-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":11.61,
                      "CBSA":10.64,
                      "state":12.04
                   },
                   "error":{
                      "this":3.05,
                      "CBSA":0.15,
                      "state":0.06
                   },
                   "numerators":{
                      "this":8145.0,
                      "CBSA":503528.0,
                      "state":4758601.0
                   },
                   "numerator_errors":{
                      "this":2141.5,
                      "CBSA":6865.4,
                      "state":23052.8
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":109.0,
                      "state":96.0
                   },
                   "error_ratio":{
                      "this":26.3,
                      "CBSA":1.4,
                      "state":0.5
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":6.85,
                      "CBSA":11.03,
                      "state":13.1
                   },
                   "error":{
                      "this":1.57,
                      "CBSA":0.14,
                      "state":0.06
                   },
                   "numerators":{
                      "this":4806.0,
                      "CBSA":521896.0,
                      "state":5176959.0
                   },
                   "numerator_errors":{
                      "this":1104.0,
                      "CBSA":6799.8,
                      "state":23034.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":62.0,
                      "state":52.0
                   },
                   "error_ratio":{
                      "this":22.9,
                      "CBSA":1.3,
                      "state":0.5
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":10.74,
                      "CBSA":13.42,
                      "state":14.49
                   },
                   "error":{
                      "this":2.41,
                      "CBSA":0.17,
                      "state":0.06
                   },
                   "numerators":{
                      "this":7535.0,
                      "CBSA":634875.0,
                      "state":5723923.0
                   },
                   "numerator_errors":{
                      "this":1688.7,
                      "CBSA":7995.9,
                      "state":24219.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":80.0,
                      "state":74.0
                   },
                   "error_ratio":{
                      "this":22.4,
                      "CBSA":1.3,
                      "state":0.4
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":15.08,
                      "CBSA":16.12,
                      "state":14.47
                   },
                   "error":{
                      "this":2.68,
                      "CBSA":0.17,
                      "state":0.06
                   },
                   "numerators":{
                      "this":10578.0,
                      "CBSA":762863.0,
                      "state":5715837.0
                   },
                   "numerator_errors":{
                      "this":1876.9,
                      "CBSA":8035.9,
                      "state":22852.8
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":94.0,
                      "state":104.0
                   },
                   "error_ratio":{
                      "this":17.8,
                      "CBSA":1.1,
                      "state":0.4
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":11.34,
                      "CBSA":13.71,
                      "state":12.77
                   },
                   "error":{
                      "this":2.33,
                      "CBSA":0.17,
                      "state":0.06
                   },
                   "numerators":{
                      "this":7954.0,
                      "CBSA":648707.0,
                      "state":5046965.0
                   },
                   "numerator_errors":{
                      "this":1636.0,
                      "CBSA":8025.4,
                      "state":22530.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":83.0,
                      "state":89.0
                   },
                   "error_ratio":{
                      "this":20.5,
                      "CBSA":1.2,
                      "state":0.5
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":10.73,
                      "CBSA":13.12,
                      "state":12.46
                   },
                   "error":{
                      "this":1.86,
                      "CBSA":0.15,
                      "state":0.05
                   },
                   "numerators":{
                      "this":7525.0,
                      "CBSA":620802.0,
                      "state":4923782.0
                   },
                   "numerator_errors":{
                      "this":1306.7,
                      "CBSA":7116.2,
                      "state":20800.0
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":82.0,
                      "state":86.0
                   },
                   "error_ratio":{
                      "this":17.3,
                      "CBSA":1.1,
                      "state":0.4
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":11.51,
                      "CBSA":10.91,
                      "state":10.63
                   },
                   "error":{
                      "this":1.91,
                      "CBSA":0.23,
                      "state":0.08
                   },
                   "numerators":{
                      "this":8079.0,
                      "CBSA":516448.0,
                      "state":4200080.0
                   },
                   "numerator_errors":{
                      "this":1342.6,
                      "CBSA":10661.7,
                      "state":31191.1
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":105.0,
                      "state":108.0
                   },
                   "error_ratio":{
                      "this":16.6,
                      "CBSA":2.1,
                      "state":0.8
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":13.17,
                      "CBSA":7.02,
                      "state":6.44
                   },
                   "error":{
                      "this":1.8,
                      "CBSA":0.15,
                      "state":0.05
                   },
                   "numerators":{
                      "this":9242.0,
                      "CBSA":332170.0,
                      "state":2545061.0
                   },
                   "numerator_errors":{
                      "this":1261.3,
                      "CBSA":7111.4,
                      "state":19797.9
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":188.0,
                      "state":205.0
                   },
                   "error_ratio":{
                      "this":13.7,
                      "CBSA":2.1,
                      "state":0.8
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":8.98,
                      "CBSA":4.03,
                      "state":3.6
                   },
                   "error":{
                      "this":1.58,
                      "CBSA":0.13,
                      "state":0.04
                   },
                   "numerators":{
                      "this":6297.0,
                      "CBSA":190514.0,
                      "state":1421015.0
                   },
                   "numerator_errors":{
                      "this":1109.0,
                      "CBSA":6014.8,
                      "state":16282.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":223.0,
                      "state":249.0
                   },
                   "error_ratio":{
                      "this":17.6,
                      "CBSA":3.2,
                      "state":1.1
                   }
                }
             },
             "male":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 1-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":10.9,
                      "CBSA":11.01,
                      "state":12.35
                   },
                   "error":{
                      "this":3.25,
                      "CBSA":0.22,
                      "state":0.09
                   },
                   "numerators":{
                      "this":3462.0,
                      "CBSA":257668.0,
                      "state":2425066.0
                   },
                   "numerator_errors":{
                      "this":1049.3,
                      "CBSA":5167.5,
                      "state":17537.1
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":99.0,
                      "state":88.0
                   },
                   "error_ratio":{
                      "this":29.8,
                      "CBSA":2.0,
                      "state":0.7
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":7.04,
                      "CBSA":11.23,
                      "state":13.47
                   },
                   "error":{
                      "this":2.32,
                      "CBSA":0.22,
                      "state":0.09
                   },
                   "numerators":{
                      "this":2238.0,
                      "CBSA":262840.0,
                      "state":2646132.0
                   },
                   "numerator_errors":{
                      "this":745.9,
                      "CBSA":5150.6,
                      "state":17211.8
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":63.0,
                      "state":52.0
                   },
                   "error_ratio":{
                      "this":33.0,
                      "CBSA":2.0,
                      "state":0.7
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":12.17,
                      "CBSA":13.8,
                      "state":15.06
                   },
                   "error":{
                      "this":3.91,
                      "CBSA":0.22,
                      "state":0.08
                   },
                   "numerators":{
                      "this":3868.0,
                      "CBSA":322820.0,
                      "state":2956969.0
                   },
                   "numerator_errors":{
                      "this":1259.9,
                      "CBSA":5263.7,
                      "state":16123.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":88.0,
                      "state":81.0
                   },
                   "error_ratio":{
                      "this":32.1,
                      "CBSA":1.6,
                      "state":0.5
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":17.62,
                      "CBSA":16.65,
                      "state":14.93
                   },
                   "error":{
                      "this":3.81,
                      "CBSA":0.27,
                      "state":0.09
                   },
                   "numerators":{
                      "this":5600.0,
                      "CBSA":389458.0,
                      "state":2932306.0
                   },
                   "numerator_errors":{
                      "this":1246.4,
                      "CBSA":6428.5,
                      "state":17684.1
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":106.0,
                      "state":118.0
                   },
                   "error_ratio":{
                      "this":21.6,
                      "CBSA":1.6,
                      "state":0.6
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":10.74,
                      "CBSA":13.86,
                      "state":12.82
                   },
                   "error":{
                      "this":3.07,
                      "CBSA":0.26,
                      "state":0.09
                   },
                   "numerators":{
                      "this":3414.0,
                      "CBSA":324176.0,
                      "state":2518785.0
                   },
                   "numerator_errors":{
                      "this":994.0,
                      "CBSA":6099.0,
                      "state":17201.0
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":77.0,
                      "state":84.0
                   },
                   "error_ratio":{
                      "this":28.6,
                      "CBSA":1.9,
                      "state":0.7
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":10.97,
                      "CBSA":13.33,
                      "state":12.42
                   },
                   "error":{
                      "this":2.67,
                      "CBSA":0.24,
                      "state":0.08
                   },
                   "numerators":{
                      "this":3487.0,
                      "CBSA":311977.0,
                      "state":2439055.0
                   },
                   "numerator_errors":{
                      "this":868.9,
                      "CBSA":5512.8,
                      "state":15253.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":82.0,
                      "state":88.0
                   },
                   "error_ratio":{
                      "this":24.3,
                      "CBSA":1.8,
                      "state":0.6
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":9.49,
                      "CBSA":10.47,
                      "state":10.2
                   },
                   "error":{
                      "this":2.44,
                      "CBSA":0.33,
                      "state":0.11
                   },
                   "numerators":{
                      "this":3016.0,
                      "CBSA":244988.0,
                      "state":2004301.0
                   },
                   "numerator_errors":{
                      "this":792.3,
                      "CBSA":7814.5,
                      "state":21480.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":91.0,
                      "state":93.0
                   },
                   "error_ratio":{
                      "this":25.7,
                      "CBSA":3.2,
                      "state":1.1
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":13.56,
                      "CBSA":6.41,
                      "state":5.89
                   },
                   "error":{
                      "this":2.81,
                      "CBSA":0.21,
                      "state":0.07
                   },
                   "numerators":{
                      "this":4308.0,
                      "CBSA":150043.0,
                      "state":1156395.0
                   },
                   "numerator_errors":{
                      "this":923.1,
                      "CBSA":4926.3,
                      "state":13944.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":212.0,
                      "state":230.0
                   },
                   "error_ratio":{
                      "this":20.7,
                      "CBSA":3.3,
                      "state":1.2
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":7.49,
                      "CBSA":3.24,
                      "state":2.86
                   },
                   "error":{
                      "this":1.9,
                      "CBSA":0.16,
                      "state":0.05
                   },
                   "numerators":{
                      "this":2381.0,
                      "CBSA":75746.0,
                      "state":561785.0
                   },
                   "numerator_errors":{
                      "this":616.0,
                      "CBSA":3743.5,
                      "state":10571.6
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":231.0,
                      "state":262.0
                   },
                   "error_ratio":{
                      "this":25.4,
                      "CBSA":4.9,
                      "state":1.7
                   }
                }
             },
             "female":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 1-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":12.2,
                      "CBSA":10.28,
                      "state":11.74
                   },
                   "error":{
                      "this":4.83,
                      "CBSA":0.19,
                      "state":0.08
                   },
                   "numerators":{
                      "this":4683.0,
                      "CBSA":245860.0,
                      "state":2333535.0
                   },
                   "numerator_errors":{
                      "this":1866.8,
                      "CBSA":4520.1,
                      "state":14962.6
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":119.0,
                      "state":104.0
                   },
                   "error_ratio":{
                      "this":39.6,
                      "CBSA":1.8,
                      "state":0.7
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":6.69,
                      "CBSA":10.83,
                      "state":12.74
                   },
                   "error":{
                      "this":2.1,
                      "CBSA":0.19,
                      "state":0.08
                   },
                   "numerators":{
                      "this":2568.0,
                      "CBSA":259056.0,
                      "state":2530827.0
                   },
                   "numerator_errors":{
                      "this":814.0,
                      "CBSA":4439.4,
                      "state":15307.8
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":62.0,
                      "state":53.0
                   },
                   "error_ratio":{
                      "this":31.4,
                      "CBSA":1.8,
                      "state":0.6
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":9.55,
                      "CBSA":13.05,
                      "state":13.92
                   },
                   "error":{
                      "this":2.9,
                      "CBSA":0.25,
                      "state":0.09
                   },
                   "numerators":{
                      "this":3667.0,
                      "CBSA":312055.0,
                      "state":2766954.0
                   },
                   "numerator_errors":{
                      "this":1124.4,
                      "CBSA":6019.0,
                      "state":18072.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":73.0,
                      "state":69.0
                   },
                   "error_ratio":{
                      "this":30.4,
                      "CBSA":1.9,
                      "state":0.6
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":12.97,
                      "CBSA":15.61,
                      "state":14.01
                   },
                   "error":{
                      "this":3.61,
                      "CBSA":0.2,
                      "state":0.07
                   },
                   "numerators":{
                      "this":4978.0,
                      "CBSA":373405.0,
                      "state":2783531.0
                   },
                   "numerator_errors":{
                      "this":1403.2,
                      "CBSA":4821.8,
                      "state":14474.8
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":83.0,
                      "state":93.0
                   },
                   "error_ratio":{
                      "this":27.8,
                      "CBSA":1.3,
                      "state":0.5
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":11.83,
                      "CBSA":13.57,
                      "state":12.72
                   },
                   "error":{
                      "this":3.34,
                      "CBSA":0.22,
                      "state":0.07
                   },
                   "numerators":{
                      "this":4540.0,
                      "CBSA":324531.0,
                      "state":2528180.0
                   },
                   "numerator_errors":{
                      "this":1299.5,
                      "CBSA":5216.2,
                      "state":14551.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":87.0,
                      "state":93.0
                   },
                   "error_ratio":{
                      "this":28.2,
                      "CBSA":1.6,
                      "state":0.6
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":10.52,
                      "CBSA":12.91,
                      "state":12.5
                   },
                   "error":{
                      "this":2.5,
                      "CBSA":0.19,
                      "state":0.07
                   },
                   "numerators":{
                      "this":4038.0,
                      "CBSA":308825.0,
                      "state":2484727.0
                   },
                   "numerator_errors":{
                      "this":976.0,
                      "CBSA":4500.0,
                      "state":14140.9
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":81.0,
                      "state":84.0
                   },
                   "error_ratio":{
                      "this":23.8,
                      "CBSA":1.5,
                      "state":0.6
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":13.19,
                      "CBSA":11.35,
                      "state":11.05
                   },
                   "error":{
                      "this":2.76,
                      "CBSA":0.3,
                      "state":0.11
                   },
                   "numerators":{
                      "this":5063.0,
                      "CBSA":271460.0,
                      "state":2195779.0
                   },
                   "numerator_errors":{
                      "this":1083.9,
                      "CBSA":7253.0,
                      "state":22615.9
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":116.0,
                      "state":119.0
                   },
                   "error_ratio":{
                      "this":20.9,
                      "CBSA":2.6,
                      "state":1.0
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":12.85,
                      "CBSA":7.61,
                      "state":6.99
                   },
                   "error":{
                      "this":2.16,
                      "CBSA":0.21,
                      "state":0.07
                   },
                   "numerators":{
                      "this":4934.0,
                      "CBSA":182127.0,
                      "state":1388666.0
                   },
                   "numerator_errors":{
                      "this":859.5,
                      "CBSA":5128.7,
                      "state":14053.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":169.0,
                      "state":184.0
                   },
                   "error_ratio":{
                      "this":16.8,
                      "CBSA":2.8,
                      "state":1.0
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":10.2,
                      "CBSA":4.8,
                      "state":4.32
                   },
                   "error":{
                      "this":2.36,
                      "CBSA":0.2,
                      "state":0.06
                   },
                   "numerators":{
                      "this":3916.0,
                      "CBSA":114768.0,
                      "state":859230.0
                   },
                   "numerator_errors":{
                      "this":922.1,
                      "CBSA":4708.0,
                      "state":12383.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":212.0,
                      "state":236.0
                   },
                   "error_ratio":{
                      "this":23.1,
                      "CBSA":4.2,
                      "state":1.4
                   }
                }
             }
          },
          "median_age":{
             "total":{
                "name":"Median age",
                "values":{
                   "this":45.1,
                   "CBSA":39.2,
                   "state":37.0
                },
                "error":{
                   "this":3.8,
                   "CBSA":0.2,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 1-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":115.0,
                   "state":122.0
                },
                "error_ratio":{
                   "this":8.4,
                   "CBSA":0.5,
                   "state":0.3
                }
             },
             "male":{
                "name":"Median age male",
                "values":{
                   "this":41.8,
                   "CBSA":38.3,
                   "state":35.9
                },
                "error":{
                   "this":4.0,
                   "CBSA":0.2,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 1-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":109.0,
                   "state":116.0
                },
                "error_ratio":{
                   "this":9.6,
                   "CBSA":0.5,
                   "state":0.3
                }
             },
             "female":{
                "name":"Median age female",
                "values":{
                   "this":48.4,
                   "CBSA":40.2,
                   "state":38.2
                },
                "error":{
                   "this":4.7,
                   "CBSA":0.2,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 1-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":120.0,
                   "state":127.0
                },
                "error_ratio":{
                   "this":9.7,
                   "CBSA":0.5,
                   "state":0.3
                }
             }
          }
       },
       "sex":{
          "metadata":{
             "table_id":"B01001",
             "universe":"Total population",
             "acs_release":"ACS 2019 1-year"
          },
          "percent_male":{
             "name":"Male",
             "values":{
                "this":45.29,
                "CBSA":49.45,
                "state":49.71
             },
             "error":{
                "this":2.44,
                "CBSA":0.06,
                "state":0.02
             },
             "numerators":{
                "this":31774.0,
                "CBSA":2339716.0,
                "state":19640794.0
             },
             "numerator_errors":{
                "this":1712.0,
                "CBSA":2867.0,
                "state":6786.0
             },
             "index":{
                "this":100.0,
                "CBSA":92.0,
                "state":91.0
             },
             "error_ratio":{
                "this":5.4,
                "CBSA":0.1,
                "state":null
             }
          },
          "percent_female":{
             "name":"Female",
             "values":{
                "this":54.71,
                "CBSA":50.55,
                "state":50.29
             },
             "error":{
                "this":2.44,
                "CBSA":0.06,
                "state":0.02
             },
             "numerators":{
                "this":38387.0,
                "CBSA":2392087.0,
                "state":19871429.0
             },
             "numerator_errors":{
                "this":1709.0,
                "CBSA":2867.0,
                "state":6786.0
             },
             "index":{
                "this":100.0,
                "CBSA":108.0,
                "state":109.0
             },
             "error_ratio":{
                "this":4.5,
                "CBSA":0.1,
                "state":null
             }
          }
       },
       "race":{
          "metadata":{
             "table_id":"B03002",
             "universe":"Total population",
             "acs_release":"ACS 2019 5-year"
          },
          "percent_white":{
             "name":"White",
             "values":{
                "this":68.33,
                "CBSA":39.6,
                "state":37.18
             },
             "error":{
                "this":1.67,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":47532.0,
                "CBSA":1861545.0,
                "state":14605312.0
             },
             "numerator_errors":{
                "this":1165.0,
                "CBSA":971.0,
                "state":3168.0
             },
             "index":{
                "this":100.0,
                "CBSA":173.0,
                "state":184.0
             },
             "error_ratio":{
                "this":2.4,
                "CBSA":0.1,
                "state":null
             }
          },
          "percent_black":{
             "name":"Black",
             "values":{
                "this":1.84,
                "CBSA":7.08,
                "state":5.52
             },
             "error":{
                "this":0.55,
                "CBSA":0.05,
                "state":0.02
             },
             "numerators":{
                "this":1280.0,
                "CBSA":333087.0,
                "state":2169155.0
             },
             "numerator_errors":{
                "this":382.0,
                "CBSA":2460.0,
                "state":6936.0
             },
             "index":{
                "this":100.0,
                "CBSA":26.0,
                "state":33.0
             },
             "error_ratio":{
                "this":29.9,
                "CBSA":0.7,
                "state":0.4
             }
          },
          "percent_native":{
             "name":"Native",
             "values":{
                "this":0.09,
                "CBSA":0.23,
                "state":0.36
             },
             "error":{
                "this":0.07,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":61.0,
                "CBSA":10718.0,
                "state":140831.0
             },
             "numerator_errors":{
                "this":47.0,
                "CBSA":712.0,
                "state":2516.0
             },
             "index":{
                "this":100.0,
                "CBSA":39.0,
                "state":25.0
             },
             "error_ratio":{
                "this":77.8,
                "CBSA":8.7,
                "state":2.8
             }
          },
          "percent_asian":{
             "name":"Asian",
             "values":{
                "this":15.21,
                "CBSA":25.84,
                "state":14.28
             },
             "error":{
                "this":1.2,
                "CBSA":0.07,
                "state":0.02
             },
             "numerators":{
                "this":10580.0,
                "CBSA":1214748.0,
                "state":5610931.0
             },
             "numerator_errors":{
                "this":834.0,
                "CBSA":3362.0,
                "state":9199.0
             },
             "index":{
                "this":100.0,
                "CBSA":59.0,
                "state":107.0
             },
             "error_ratio":{
                "this":7.9,
                "CBSA":0.3,
                "state":0.1
             }
          },
          "percent_islander":{
             "name":"Islander",
             "values":{
                "this":0.48,
                "CBSA":0.68,
                "state":0.36
             },
             "error":{
                "this":0.26,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":333.0,
                "CBSA":31849.0,
                "state":140788.0
             },
             "numerator_errors":{
                "this":180.0,
                "CBSA":816.0,
                "state":2224.0
             },
             "index":{
                "this":100.0,
                "CBSA":71.0,
                "state":133.0
             },
             "error_ratio":{
                "this":54.2,
                "CBSA":2.9,
                "state":2.8
             }
          },
          "percent_other":{
             "name":"Other",
             "values":{
                "this":0.16,
                "CBSA":0.38,
                "state":0.25
             },
             "error":{
                "this":0.13,
                "CBSA":0.03,
                "state":0.01
             },
             "numerators":{
                "this":111.0,
                "CBSA":18047.0,
                "state":100119.0
             },
             "numerator_errors":{
                "this":93.0,
                "CBSA":1457.0,
                "state":3715.0
             },
             "index":{
                "this":100.0,
                "CBSA":42.0,
                "state":64.0
             },
             "error_ratio":{
                "this":81.2,
                "CBSA":7.9,
                "state":4.0
             }
          },
          "percent_two_or_more":{
             "name":"Two+",
             "values":{
                "this":4.78,
                "CBSA":4.38,
                "state":3.03
             },
             "error":{
                "this":0.92,
                "CBSA":0.09,
                "state":0.04
             },
             "numerators":{
                "this":3326.0,
                "CBSA":205932.0,
                "state":1188673.0
             },
             "numerator_errors":{
                "this":639.0,
                "CBSA":4007.0,
                "state":14463.0
             },
             "index":{
                "this":100.0,
                "CBSA":109.0,
                "state":158.0
             },
             "error_ratio":{
                "this":19.2,
                "CBSA":2.1,
                "state":1.3
             }
          },
          "percent_hispanic":{
             "name":"Hispanic",
             "values":{
                "this":9.12,
                "CBSA":21.81,
                "state":39.02
             },
             "error":{
                "this":1.12,
                "CBSA":0,
                "state":0
             },
             "numerators":{
                "this":6344.0,
                "CBSA":1025406.0,
                "state":15327688.0
             },
             "numerator_errors":{
                "this":777.0,
                "CBSA":0.0,
                "state":0.0
             },
             "index":{
                "this":100.0,
                "CBSA":42.0,
                "state":23.0
             },
             "error_ratio":{
                "this":12.3,
                "CBSA":null,
                "state":null
             }
          }
       }
    },
    "economics":{
       "income":{
          "per_capita_income_in_the_last_12_months":{
             "name":"Per capita income",
             "values":{
                "this":69429.0,
                "CBSA":60223.0,
                "state":39393.0
             },
             "error":{
                "this":4526.0,
                "CBSA":786.0,
                "state":177.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B19301",
                "universe":"Total population",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":115.0,
                "state":176.0
             },
             "error_ratio":{
                "this":6.5,
                "CBSA":1.3,
                "state":0.4
             }
          },
          "median_household_income":{
             "name":"Median household income",
             "values":{
                "this":120238.0,
                "CBSA":114696.0,
                "state":80440.0
             },
             "error":{
                "this":4142.0,
                "CBSA":1965.0,
                "state":313.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B19013",
                "universe":"Households",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":105.0,
                "state":149.0
             },
             "error_ratio":{
                "this":3.4,
                "CBSA":1.7,
                "state":0.4
             }
          },
          "household_distribution":{
             "metadata":{
                "table_id":"B19001",
                "universe":"Households",
                "acs_release":"ACS 2019 1-year"
             },
             "under_50":{
                "name":"Under $50K",
                "values":{
                   "this":16.96,
                   "CBSA":22.5,
                   "state":31.68
                },
                "error":{
                   "this":3.54,
                   "CBSA":0.65,
                   "state":0.26
                },
                "numerators":{
                   "this":5352.0,
                   "CBSA":387393.0,
                   "state":4167825.0
                },
                "numerator_errors":{
                   "this":1151.0,
                   "CBSA":11318.1,
                   "state":34680.6
                },
                "index":{
                   "this":100.0,
                   "CBSA":75.0,
                   "state":54.0
                },
                "error_ratio":{
                   "this":20.9,
                   "CBSA":2.9,
                   "state":0.8
                }
             },
             "50_to_100":{
                "name":"$50K - $100K",
                "values":{
                   "this":21.81,
                   "CBSA":21.44,
                   "state":27.83
                },
                "error":{
                   "this":4.46,
                   "CBSA":0.57,
                   "state":0.24
                },
                "numerators":{
                   "this":6883.0,
                   "CBSA":369108.0,
                   "state":3661397.0
                },
                "numerator_errors":{
                   "this":1453.6,
                   "CBSA":9959.7,
                   "state":32290.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":102.0,
                   "state":78.0
                },
                "error_ratio":{
                   "this":20.4,
                   "CBSA":2.7,
                   "state":0.9
                }
             },
             "100_to_200":{
                "name":"$100K - $200K",
                "values":{
                   "this":37.86,
                   "CBSA":29.91,
                   "state":26.8
                },
                "error":{
                   "this":5.82,
                   "CBSA":0.69,
                   "state":0.24
                },
                "numerators":{
                   "this":11948.0,
                   "CBSA":514913.0,
                   "state":3525910.0
                },
                "numerator_errors":{
                   "this":1938.1,
                   "CBSA":12053.4,
                   "state":31856.1
                },
                "index":{
                   "this":100.0,
                   "CBSA":127.0,
                   "state":141.0
                },
                "error_ratio":{
                   "this":15.4,
                   "CBSA":2.3,
                   "state":0.9
                }
             },
             "over_200":{
                "name":"Over $200K",
                "values":{
                   "this":23.37,
                   "CBSA":26.15,
                   "state":13.7
                },
                "error":{
                   "this":2.86,
                   "CBSA":0.54,
                   "state":0.14
                },
                "numerators":{
                   "this":7375.0,
                   "CBSA":450162.0,
                   "state":1802741.0
                },
                "numerator_errors":{
                   "this":980.0,
                   "CBSA":9432.0,
                   "state":18330.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":89.0,
                   "state":171.0
                },
                "error_ratio":{
                   "this":12.2,
                   "CBSA":2.1,
                   "state":1.0
                }
             }
          }
       },
       "poverty":{
          "percent_below_poverty_line":{
             "name":"Persons below poverty line",
             "values":{
                "this":3.58,
                "CBSA":8.16,
                "state":11.75
             },
             "error":{
                "this":1.61,
                "CBSA":0.42,
                "state":0.18
             },
             "numerators":{
                "this":2482.0,
                "CBSA":380982.0,
                "state":4552837.0
             },
             "numerator_errors":{
                "this":1117.0,
                "CBSA":19484.0,
                "state":67995.0
             },
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":44.0,
                "state":30.0
             },
             "error_ratio":{
                "this":45.0,
                "CBSA":5.1,
                "state":1.5
             }
          },
          "children":{
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 1-year"
             },
             "Below":{
                "name":"Poverty",
                "values":{
                   "this":4.17,
                   "CBSA":8.89,
                   "state":15.62
                },
                "error":{
                   "this":5.76,
                   "CBSA":0.65,
                   "state":0.27
                },
                "numerators":{
                   "this":491.0,
                   "CBSA":80671.0,
                   "state":1363574.0
                },
                "numerator_errors":{
                   "this":686.1,
                   "CBSA":5988.4,
                   "state":24624.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":47.0,
                   "state":27.0
                },
                "error_ratio":{
                   "this":138.1,
                   "CBSA":7.3,
                   "state":1.7
                }
             },
             "above":{
                "name":"Non-poverty",
                "values":{
                   "this":95.83,
                   "CBSA":91.11,
                   "state":84.38
                },
                "error":{
                   "this":0.9,
                   "CBSA":1.88,
                   "state":0.61
                },
                "numerators":{
                   "this":11286.0,
                   "CBSA":827095.0,
                   "state":7365259.0
                },
                "numerator_errors":{
                   "this":2330.7,
                   "CBSA":11932.8,
                   "state":37404.1
                },
                "index":{
                   "this":100.0,
                   "CBSA":105.0,
                   "state":114.0
                },
                "error_ratio":{
                   "this":0.9,
                   "CBSA":2.1,
                   "state":0.7
                }
             }
          },
          "seniors":{
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 1-year"
             },
             "Below":{
                "name":"Poverty",
                "values":{
                   "this":4.44,
                   "CBSA":8.95,
                   "state":10.45
                },
                "error":{
                   "this":2.39,
                   "CBSA":0.62,
                   "state":0.23
                },
                "numerators":{
                   "this":844.0,
                   "CBSA":66384.0,
                   "state":599877.0
                },
                "numerator_errors":{
                   "this":459.9,
                   "CBSA":4644.0,
                   "state":13070.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":50.0,
                   "state":42.0
                },
                "error_ratio":{
                   "this":53.8,
                   "CBSA":6.9,
                   "state":2.2
                }
             },
             "above":{
                "name":"Non-poverty",
                "values":{
                   "this":95.56,
                   "CBSA":91.05,
                   "state":89.55
                },
                "error":{
                   "this":1.26,
                   "CBSA":1.09,
                   "state":0.39
                },
                "numerators":{
                   "this":18148.0,
                   "CBSA":675078.0,
                   "state":5138754.0
                },
                "numerator_errors":{
                   "this":1696.9,
                   "CBSA":5093.8,
                   "state":14255.5
                },
                "index":{
                   "this":100.0,
                   "CBSA":105.0,
                   "state":107.0
                },
                "error_ratio":{
                   "this":1.3,
                   "CBSA":1.2,
                   "state":0.4
                }
             }
          }
       },
       "employment":{
          "mean_travel_time":{
             "name":"Mean travel time to work",
             "values":{
                "this":36.64,
                "CBSA":34.26,
                "state":29.79
             },
             "error":{
                "this":0.92,
                "CBSA":0.14,
                "state":0.07
             },
             "numerators":{
                "this":1049430.0,
                "CBSA":75895430.0,
                "state":509970415.0
             },
             "numerator_errors":{
                "this":42598.0,
                "CBSA":426975.0,
                "state":1354876.0
             },
             "metadata":{
                "table_id":"B08006, B08013",
                "universe":"Workers 16 years and over who did not work at home",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":107.0,
                "state":123.0
             },
             "error_ratio":{
                "this":2.5,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "transportation_distribution":{
             "metadata":{
                "table_id":"B08006",
                "universe":"Workers 16 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "drove_alone":{
                "name":"Drove alone",
                "values":{
                   "this":61.42,
                   "CBSA":57.56,
                   "state":73.72
                },
                "error":{
                   "this":2.46,
                   "CBSA":0.27,
                   "state":0.04
                },
                "numerators":{
                   "this":19371.0,
                   "CBSA":1365800.0,
                   "state":13411041.0
                },
                "numerator_errors":{
                   "this":936.0,
                   "CBSA":7921.0,
                   "state":17348.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":107.0,
                   "state":83.0
                },
                "error_ratio":{
                   "this":4.0,
                   "CBSA":0.5,
                   "state":0.1
                }
             },
             "carpooled":{
                "name":"Carpooled",
                "values":{
                   "this":5.49,
                   "CBSA":9.53,
                   "state":10.12
                },
                "error":{
                   "this":1.05,
                   "CBSA":0.17,
                   "state":0.09
                },
                "numerators":{
                   "this":1730.0,
                   "CBSA":226167.0,
                   "state":1841632.0
                },
                "numerator_errors":{
                   "this":334.0,
                   "CBSA":4187.0,
                   "state":16674.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":58.0,
                   "state":54.0
                },
                "error_ratio":{
                   "this":19.1,
                   "CBSA":1.8,
                   "state":0.9
                }
             },
             "public_transit":{
                "name":"Public transit",
                "values":{
                   "this":18.51,
                   "CBSA":17.61,
                   "state":5.08
                },
                "error":{
                   "this":1.61,
                   "CBSA":0.19,
                   "state":0.04
                },
                "numerators":{
                   "this":5838.0,
                   "CBSA":417810.0,
                   "state":923834.0
                },
                "numerator_errors":{
                   "this":532.0,
                   "CBSA":4652.0,
                   "state":7563.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":105.0,
                   "state":364.0
                },
                "error_ratio":{
                   "this":8.7,
                   "CBSA":1.1,
                   "state":0.8
                }
             },
             "Bicycle":{
                "name":"Bicycle",
                "values":{
                   "this":0.82,
                   "CBSA":1.91,
                   "state":0.95
                },
                "error":{
                   "this":0.29,
                   "CBSA":0.07,
                   "state":0.02
                },
                "numerators":{
                   "this":260.0,
                   "CBSA":45243.0,
                   "state":173081.0
                },
                "numerator_errors":{
                   "this":92.0,
                   "CBSA":1647.0,
                   "state":3177.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":43.0,
                   "state":86.0
                },
                "error_ratio":{
                   "this":35.4,
                   "CBSA":3.7,
                   "state":2.1
                }
             },
             "walked":{
                "name":"Walked",
                "values":{
                   "this":3.03,
                   "CBSA":4.69,
                   "state":2.62
                },
                "error":{
                   "this":0.82,
                   "CBSA":0.13,
                   "state":0.03
                },
                "numerators":{
                   "this":957.0,
                   "CBSA":111365.0,
                   "state":476291.0
                },
                "numerator_errors":{
                   "this":261.0,
                   "CBSA":3026.0,
                   "state":5234.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":65.0,
                   "state":116.0
                },
                "error_ratio":{
                   "this":27.1,
                   "CBSA":2.8,
                   "state":1.1
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":1.54,
                   "CBSA":2.06,
                   "state":1.6
                },
                "error":{
                   "this":0.57,
                   "CBSA":0.08,
                   "state":0.02
                },
                "numerators":{
                   "this":485.0,
                   "CBSA":48944.0,
                   "state":291710.0
                },
                "numerator_errors":{
                   "this":180.0,
                   "CBSA":2007.0,
                   "state":4364.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":75.0,
                   "state":96.0
                },
                "error_ratio":{
                   "this":37.0,
                   "CBSA":3.9,
                   "state":1.2
                }
             },
             "worked_at_home":{
                "name":"Worked at home",
                "values":{
                   "this":9.19,
                   "CBSA":6.65,
                   "state":5.9
                },
                "error":{
                   "this":1.02,
                   "CBSA":0.12,
                   "state":0.05
                },
                "numerators":{
                   "this":2898.0,
                   "CBSA":157692.0,
                   "state":1073966.0
                },
                "numerator_errors":{
                   "this":330.0,
                   "CBSA":2857.0,
                   "state":8330.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":138.0,
                   "state":156.0
                },
                "error_ratio":{
                   "this":11.1,
                   "CBSA":1.8,
                   "state":0.8
                }
             }
          }
       }
    },
    "families":{
       "marital_status":{
          "metadata":{
             "table_id":"B12001",
             "universe":"Population 15 years and over",
             "acs_release":"ACS 2019 1-year"
          },
          "married":{
             "name":"Married",
             "values":{
                "this":53.64,
                "CBSA":49.88,
                "state":48.44
             },
             "error":{
                "this":3.06,
                "CBSA":0.39,
                "state":0.14
             },
             "numerators":{
                "this":32102.0,
                "CBSA":1977052.0,
                "state":15561514.0
             },
             "numerator_errors":{
                "this":2147.0,
                "CBSA":15302.6,
                "state":46249.3
             },
             "index":{
                "this":100.0,
                "CBSA":108.0,
                "state":111.0
             },
             "error_ratio":{
                "this":5.7,
                "CBSA":0.8,
                "state":0.3
             }
          },
          "single":{
             "name":"Single",
             "values":{
                "this":46.36,
                "CBSA":50.12,
                "state":51.56
             },
             "error":{
                "this":4.84,
                "CBSA":0.45,
                "state":0.16
             },
             "numerators":{
                "this":27745.0,
                "CBSA":1986662.0,
                "state":16562598.0
             },
             "numerator_errors":{
                "this":3052.7,
                "CBSA":17817.2,
                "state":51752.6
             },
             "index":{
                "this":100.0,
                "CBSA":92.0,
                "state":90.0
             },
             "error_ratio":{
                "this":10.4,
                "CBSA":0.9,
                "state":0.3
             }
          }
       },
       "marital_status_grouped":{
          "metadata":{
             "table_id":"B12001",
             "universe":"Population 15 years and over",
             "acs_release":"ACS 2019 1-year"
          },
          "never_married":{
             "acs_release":"ACS 2019 1-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Never married"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":29.22,
                   "CBSA":39.77,
                   "state":40.83
                },
                "error":{
                   "this":5.9,
                   "CBSA":0.5,
                   "state":0.17
                },
                "numerators":{
                   "this":7907.0,
                   "CBSA":775103.0,
                   "state":6478460.0
                },
                "numerator_errors":{
                   "this":1661.0,
                   "CBSA":9861.0,
                   "state":27866.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":73.0,
                   "state":72.0
                },
                "error_ratio":{
                   "this":20.2,
                   "CBSA":1.3,
                   "state":0.4
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":28.27,
                   "CBSA":34.02,
                   "state":33.96
                },
                "error":{
                   "this":5.75,
                   "CBSA":0.46,
                   "state":0.15
                },
                "numerators":{
                   "this":9267.0,
                   "CBSA":685311.0,
                   "state":5521285.0
                },
                "numerator_errors":{
                   "this":1955.0,
                   "CBSA":9340.0,
                   "state":24532.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":83.0,
                   "state":83.0
                },
                "error_ratio":{
                   "this":20.3,
                   "CBSA":1.4,
                   "state":0.4
                }
             }
          },
          "married":{
             "acs_release":"ACS 2019 1-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Now married"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":61.67,
                   "CBSA":51.38,
                   "state":49.37
                },
                "error":{
                   "this":4.81,
                   "CBSA":0.54,
                   "state":0.2
                },
                "numerators":{
                   "this":16689.0,
                   "CBSA":1001374.0,
                   "state":7832740.0
                },
                "numerator_errors":{
                   "this":1626.0,
                   "CBSA":10667.0,
                   "state":32087.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":120.0,
                   "state":125.0
                },
                "error_ratio":{
                   "this":7.8,
                   "CBSA":1.1,
                   "state":0.4
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":47.01,
                   "CBSA":48.43,
                   "state":47.54
                },
                "error":{
                   "this":3.37,
                   "CBSA":0.54,
                   "state":0.2
                },
                "numerators":{
                   "this":15413.0,
                   "CBSA":975678.0,
                   "state":7728774.0
                },
                "numerator_errors":{
                   "this":1402.0,
                   "CBSA":10972.0,
                   "state":33308.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":97.0,
                   "state":99.0
                },
                "error_ratio":{
                   "this":7.2,
                   "CBSA":1.1,
                   "state":0.4
                }
             }
          },
          "divorced":{
             "acs_release":"ACS 2019 1-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Divorced"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":6.82,
                   "CBSA":6.95,
                   "state":7.69
                },
                "error":{
                   "this":2.27,
                   "CBSA":0.34,
                   "state":0.11
                },
                "numerators":{
                   "this":1847.0,
                   "CBSA":135520.0,
                   "state":1219434.0
                },
                "numerator_errors":{
                   "this":625.0,
                   "CBSA":6608.0,
                   "state":17626.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":98.0,
                   "state":89.0
                },
                "error_ratio":{
                   "this":33.3,
                   "CBSA":4.9,
                   "state":1.4
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":15.4,
                   "CBSA":10.37,
                   "state":10.83
                },
                "error":{
                   "this":3.77,
                   "CBSA":0.32,
                   "state":0.16
                },
                "numerators":{
                   "this":5049.0,
                   "CBSA":208872.0,
                   "state":1760791.0
                },
                "numerator_errors":{
                   "this":1268.0,
                   "CBSA":6454.0,
                   "state":25259.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":149.0,
                   "state":142.0
                },
                "error_ratio":{
                   "this":24.5,
                   "CBSA":3.1,
                   "state":1.5
                }
             }
          },
          "widowed":{
             "acs_release":"ACS 2019 1-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Widowed"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":2.29,
                   "CBSA":1.9,
                   "state":2.11
                },
                "error":{
                   "this":1.15,
                   "CBSA":0.16,
                   "state":0.05
                },
                "numerators":{
                   "this":620.0,
                   "CBSA":37037.0,
                   "state":334453.0
                },
                "numerator_errors":{
                   "this":313.0,
                   "CBSA":3146.0,
                   "state":8393.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":121.0,
                   "state":109.0
                },
                "error_ratio":{
                   "this":50.2,
                   "CBSA":8.4,
                   "state":2.4
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":9.32,
                   "CBSA":7.19,
                   "state":7.68
                },
                "error":{
                   "this":2.39,
                   "CBSA":0.3,
                   "state":0.1
                },
                "numerators":{
                   "this":3055.0,
                   "CBSA":144819.0,
                   "state":1248175.0
                },
                "numerator_errors":{
                   "this":801.0,
                   "CBSA":6145.0,
                   "state":16759.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":130.0,
                   "state":121.0
                },
                "error_ratio":{
                   "this":25.6,
                   "CBSA":4.2,
                   "state":1.3
                }
             }
          }
       },
       "family_types":{
          "children":{
             "metadata":{
                "table_id":"B09002",
                "universe":"Own children under 18 years",
                "acs_release":"ACS 2019 1-year"
             },
             "married_couple":{
                "name":"Married couple",
                "values":{
                   "this":69.31,
                   "CBSA":79.49,
                   "state":72.24
                },
                "error":{
                   "this":3.29,
                   "CBSA":1.18,
                   "state":0.46
                },
                "numerators":{
                   "this":8009.0,
                   "CBSA":647640.0,
                   "state":5449645.0
                },
                "numerator_errors":{
                   "this":1499.0,
                   "CBSA":11612.0,
                   "state":40502.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":87.0,
                   "state":96.0
                },
                "error_ratio":{
                   "this":4.7,
                   "CBSA":1.5,
                   "state":0.6
                }
             },
             "male_householder":{
                "name":"Male householder",
                "values":{
                   "this":6.62,
                   "CBSA":5.33,
                   "state":7.66
                },
                "error":{
                   "this":5.73,
                   "CBSA":0.73,
                   "state":0.26
                },
                "numerators":{
                   "this":765.0,
                   "CBSA":43433.0,
                   "state":578128.0
                },
                "numerator_errors":{
                   "this":677.0,
                   "CBSA":5944.0,
                   "state":19525.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":124.0,
                   "state":86.0
                },
                "error_ratio":{
                   "this":86.6,
                   "CBSA":13.7,
                   "state":3.4
                }
             },
             "female_householder":{
                "name":"Female householder",
                "values":{
                   "this":24.07,
                   "CBSA":15.18,
                   "state":20.1
                },
                "error":{
                   "this":13.05,
                   "CBSA":1.03,
                   "state":0.43
                },
                "numerators":{
                   "this":2782.0,
                   "CBSA":123655.0,
                   "state":1516238.0
                },
                "numerator_errors":{
                   "this":1590.0,
                   "CBSA":8506.0,
                   "state":32886.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":159.0,
                   "state":120.0
                },
                "error_ratio":{
                   "this":54.2,
                   "CBSA":6.8,
                   "state":2.1
                }
             }
          }
       },
       "fertility":{
          "total":{
             "name":"Women 15-50 who gave birth during past year",
             "values":{
                "this":5.72,
                "CBSA":4.22,
                "state":4.69
             },
             "error":{
                "this":3.11,
                "CBSA":0.35,
                "state":0.11
             },
             "numerators":{
                "this":862.0,
                "CBSA":49498.0,
                "state":450971.0
             },
             "numerator_errors":{
                "this":476.0,
                "CBSA":4126.0,
                "state":10955.0
             },
             "metadata":{
                "table_id":"B13016",
                "universe":"Women 15 to 50 years",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":136.0,
                "state":122.0
             },
             "error_ratio":{
                "this":54.4,
                "CBSA":8.3,
                "state":2.3
             }
          },
          "by_age":{
             "metadata":{
                "table_id":"B13016",
                "universe":"Women 15 to 50 years",
                "acs_release":"ACS 2019 1-year"
             },
             "15_to_19":{
                "name":"15-19",
                "values":{
                   "this":0,
                   "CBSA":0.14,
                   "state":0.87
                },
                "error":{
                   "this":0,
                   "CBSA":0.17,
                   "state":0.16
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":175.0,
                   "state":10882.0
                },
                "numerator_errors":{
                   "this":216.0,
                   "CBSA":215.0,
                   "state":2046.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":121.4,
                   "state":18.4
                }
             },
             "20_to_24":{
                "name":"20-24",
                "values":{
                   "this":14.32,
                   "CBSA":3.41,
                   "state":4.44
                },
                "error":{
                   "this":15.29,
                   "CBSA":0.98,
                   "state":0.41
                },
                "numerators":{
                   "this":209.0,
                   "CBSA":4502.0,
                   "state":56709.0
                },
                "numerator_errors":{
                   "this":248.0,
                   "CBSA":1289.0,
                   "state":5217.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":420.0,
                   "state":323.0
                },
                "error_ratio":{
                   "this":106.8,
                   "CBSA":28.7,
                   "state":9.2
                }
             },
             "25_to_29":{
                "name":"25-29",
                "values":{
                   "this":0,
                   "CBSA":3.96,
                   "state":6.85
                },
                "error":{
                   "this":0,
                   "CBSA":0.79,
                   "state":0.37
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":7129.0,
                   "state":101991.0
                },
                "numerator_errors":{
                   "this":216.0,
                   "CBSA":1423.0,
                   "state":5591.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":19.9,
                   "state":5.4
                }
             },
             "30_to_34":{
                "name":"30-35",
                "values":{
                   "this":4.5,
                   "CBSA":8.76,
                   "state":9.73
                },
                "error":{
                   "this":5.54,
                   "CBSA":1.12,
                   "state":0.51
                },
                "numerators":{
                   "this":112.0,
                   "CBSA":16867.0,
                   "state":139246.0
                },
                "numerator_errors":{
                   "this":141.0,
                   "CBSA":2170.0,
                   "state":7329.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":51.0,
                   "state":46.0
                },
                "error_ratio":{
                   "this":123.1,
                   "CBSA":12.8,
                   "state":5.2
                }
             },
             "35_to_39":{
                "name":"35-39",
                "values":{
                   "this":13.71,
                   "CBSA":8.24,
                   "state":6.95
                },
                "error":{
                   "this":15.15,
                   "CBSA":1.17,
                   "state":0.33
                },
                "numerators":{
                   "this":341.0,
                   "CBSA":14911.0,
                   "state":93949.0
                },
                "numerator_errors":{
                   "this":415.0,
                   "CBSA":2171.0,
                   "state":4611.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":166.0,
                   "state":197.0
                },
                "error_ratio":{
                   "this":110.5,
                   "CBSA":14.2,
                   "state":4.7
                }
             },
             "40_to_44":{
                "name":"40-44",
                "values":{
                   "this":8.55,
                   "CBSA":2.72,
                   "state":2.64
                },
                "error":{
                   "this":8.16,
                   "CBSA":0.6,
                   "state":0.21
                },
                "numerators":{
                   "this":200.0,
                   "CBSA":4418.0,
                   "state":33377.0
                },
                "numerator_errors":{
                   "this":212.0,
                   "CBSA":979.0,
                   "state":2743.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":314.0,
                   "state":324.0
                },
                "error_ratio":{
                   "this":95.4,
                   "CBSA":22.1,
                   "state":8.0
                }
             },
             "45_to_50":{
                "name":"45-50",
                "values":{
                   "this":0,
                   "CBSA":0.76,
                   "state":0.96
                },
                "error":{
                   "this":0,
                   "CBSA":0.3,
                   "state":0.14
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":1496.0,
                   "state":14817.0
                },
                "numerator_errors":{
                   "this":216.0,
                   "CBSA":597.0,
                   "state":2211.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":39.5,
                   "state":14.6
                }
             }
          }
       },
       "households":{
          "number_of_households":{
             "name":"Number of households",
             "values":{
                "this":31558.0,
                "CBSA":1721576.0,
                "state":13157873.0
             },
             "error":{
                "this":1639.0,
                "CBSA":7415.0,
                "state":23844.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B11001",
                "universe":"Households",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":5.2,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "persons_per_household":{
             "name":"Persons per household",
             "values":{
                "this":2.19,
                "CBSA":2.7,
                "state":2.94
             },
             "error":{
                "this":0.11,
                "CBSA":0.01,
                "state":0.01
             },
             "numerators":{
                "this":69200.0,
                "CBSA":4654824.0,
                "state":38685702.0
             },
             "numerator_errors":{
                "this":427.0,
                "CBSA":3894.0,
                "state":0.0
             },
             "metadata":{
                "table_id":"B11001,b11002",
                "universe":"Households",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":81.0,
                "state":74.0
             },
             "error_ratio":{
                "this":5.0,
                "CBSA":0.4,
                "state":0.3
             }
          },
          "distribution":{
             "metadata":{
                "table_id":"B11002",
                "universe":"People in Households",
                "acs_release":"ACS 2019 1-year"
             },
             "married_couples":{
                "name":"Married couples",
                "values":{
                   "this":61.72,
                   "CBSA":61.45,
                   "state":60.69
                },
                "error":{
                   "this":5.36,
                   "CBSA":0.62,
                   "state":0.22
                },
                "numerators":{
                   "this":42709.0,
                   "CBSA":2860522.0,
                   "state":23477092.0
                },
                "numerator_errors":{
                   "this":3718.0,
                   "CBSA":28845.0,
                   "state":84642.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":100.0,
                   "state":102.0
                },
                "error_ratio":{
                   "this":8.7,
                   "CBSA":1.0,
                   "state":0.4
                }
             },
             "male_householder":{
                "name":"Male householder",
                "values":{
                   "this":2.63,
                   "CBSA":5.55,
                   "state":7.74
                },
                "error":{
                   "this":2.09,
                   "CBSA":0.39,
                   "state":0.16
                },
                "numerators":{
                   "this":1822.0,
                   "CBSA":258173.0,
                   "state":2996042.0
                },
                "numerator_errors":{
                   "this":1443.0,
                   "CBSA":18051.0,
                   "state":63406.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":47.0,
                   "state":34.0
                },
                "error_ratio":{
                   "this":79.5,
                   "CBSA":7.0,
                   "state":2.1
                }
             },
             "female_householder":{
                "name":"Female householder",
                "values":{
                   "this":11.25,
                   "CBSA":12.87,
                   "state":16.47
                },
                "error":{
                   "this":3.71,
                   "CBSA":0.56,
                   "state":0.23
                },
                "numerators":{
                   "this":7787.0,
                   "CBSA":598953.0,
                   "state":6371949.0
                },
                "numerator_errors":{
                   "this":2570.0,
                   "CBSA":26284.0,
                   "state":87738.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":87.0,
                   "state":68.0
                },
                "error_ratio":{
                   "this":33.0,
                   "CBSA":4.4,
                   "state":1.4
                }
             },
             "nonfamily":{
                "name":"Non-family",
                "values":{
                   "this":24.4,
                   "CBSA":20.13,
                   "state":15.1
                },
                "error":{
                   "this":4.38,
                   "CBSA":0.41,
                   "state":0.1
                },
                "numerators":{
                   "this":16882.0,
                   "CBSA":937176.0,
                   "state":5840619.0
                },
                "numerator_errors":{
                   "this":3034.0,
                   "CBSA":18922.0,
                   "state":37146.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":121.0,
                   "state":162.0
                },
                "error_ratio":{
                   "this":18.0,
                   "CBSA":2.0,
                   "state":0.7
                }
             }
          }
       }
    },
    "housing":{
       "units":{
          "number":{
             "name":"Number of housing units",
             "values":{
                "this":33971.0,
                "CBSA":1841897.0,
                "state":14367012.0
             },
             "error":{
                "this":2268.0,
                "CBSA":1707.0,
                "state":1286.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B25002",
                "universe":"Housing units",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":6.7,
                "CBSA":0.1,
                "state":null
             }
          },
          "occupancy_distribution":{
             "metadata":{
                "table_id":"B25002",
                "universe":"Housing units",
                "acs_release":"ACS 2019 1-year"
             },
             "occupied":{
                "name":"Occupied",
                "values":{
                   "this":92.9,
                   "CBSA":93.47,
                   "state":91.58
                },
                "error":{
                   "this":7.86,
                   "CBSA":0.39,
                   "state":0.17
                },
                "numerators":{
                   "this":31558.0,
                   "CBSA":1721576.0,
                   "state":13157873.0
                },
                "numerator_errors":{
                   "this":1639.0,
                   "CBSA":7415.0,
                   "state":23844.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":99.0,
                   "state":101.0
                },
                "error_ratio":{
                   "this":8.5,
                   "CBSA":0.4,
                   "state":0.2
                }
             },
             "vacant":{
                "name":"Vacant",
                "values":{
                   "this":7.1,
                   "CBSA":6.53,
                   "state":8.42
                },
                "error":{
                   "this":3.43,
                   "CBSA":0.39,
                   "state":0.17
                },
                "numerators":{
                   "this":2413.0,
                   "CBSA":120321.0,
                   "state":1209139.0
                },
                "numerator_errors":{
                   "this":1175.0,
                   "CBSA":7194.0,
                   "state":24297.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":109.0,
                   "state":84.0
                },
                "error_ratio":{
                   "this":48.3,
                   "CBSA":6.0,
                   "state":2.0
                }
             }
          },
          "structure_distribution":{
             "metadata":{
                "table_id":"B25024",
                "universe":"Housing units",
                "acs_release":"ACS 2019 1-year"
             },
             "single_unit":{
                "name":"Single unit",
                "values":{
                   "this":51.11,
                   "CBSA":57.4,
                   "state":63.96
                },
                "error":{
                   "this":4.07,
                   "CBSA":0.62,
                   "state":0.22
                },
                "numerators":{
                   "this":17364.0,
                   "CBSA":1057192.0,
                   "state":9189272.0
                },
                "numerator_errors":{
                   "this":1804.3,
                   "CBSA":11515.2,
                   "state":31423.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":89.0,
                   "state":80.0
                },
                "error_ratio":{
                   "this":8.0,
                   "CBSA":1.1,
                   "state":0.3
                }
             },
             "multi_unit":{
                "name":"Multi-unit",
                "values":{
                   "this":48.13,
                   "CBSA":41.49,
                   "state":32.18
                },
                "error":{
                   "this":5.71,
                   "CBSA":0.81,
                   "state":0.27
                },
                "numerators":{
                   "this":16351.0,
                   "CBSA":764126.0,
                   "state":4622614.0
                },
                "numerator_errors":{
                   "this":2225.1,
                   "CBSA":14847.1,
                   "state":38673.9
                },
                "index":{
                   "this":100.0,
                   "CBSA":116.0,
                   "state":150.0
                },
                "error_ratio":{
                   "this":11.9,
                   "CBSA":2.0,
                   "state":0.8
                }
             },
             "mobile_home":{
                "name":"Mobile home",
                "values":{
                   "this":0.75,
                   "CBSA":1.0,
                   "state":3.74
                },
                "error":{
                   "this":1.23,
                   "CBSA":0.11,
                   "state":0.08
                },
                "numerators":{
                   "this":256.0,
                   "CBSA":18463.0,
                   "state":537931.0
                },
                "numerator_errors":{
                   "this":419.0,
                   "CBSA":1989.0,
                   "state":11591.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":75.0,
                   "state":20.0
                },
                "error_ratio":{
                   "this":164.0,
                   "CBSA":11.0,
                   "state":2.1
                }
             },
             "vehicle":{
                "name":"Boat, RV, van, etc.",
                "values":{
                   "this":0,
                   "CBSA":0.11,
                   "state":0.12
                },
                "error":{
                   "this":0,
                   "CBSA":0.05,
                   "state":0.01
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":2116.0,
                   "state":17195.0
                },
                "numerator_errors":{
                   "this":216.0,
                   "CBSA":951.0,
                   "state":2102.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":45.5,
                   "state":8.3
                }
             }
          }
       },
       "ownership":{
          "distribution":{
             "metadata":{
                "table_id":"B25003",
                "universe":"Occupied housing units",
                "acs_release":"ACS 2019 1-year"
             },
             "owner":{
                "name":"Owner occupied",
                "values":{
                   "this":63.56,
                   "CBSA":54.03,
                   "state":54.86
                },
                "error":{
                   "this":4.0,
                   "CBSA":0.59,
                   "state":0.22
                },
                "numerators":{
                   "this":20057.0,
                   "CBSA":930250.0,
                   "state":7218742.0
                },
                "numerator_errors":{
                   "this":1636.0,
                   "CBSA":10981.0,
                   "state":32100.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":118.0,
                   "state":116.0
                },
                "error_ratio":{
                   "this":6.3,
                   "CBSA":1.1,
                   "state":0.4
                }
             },
             "renter":{
                "name":"Renter occupied",
                "values":{
                   "this":36.44,
                   "CBSA":45.97,
                   "state":45.14
                },
                "error":{
                   "this":3.86,
                   "CBSA":0.65,
                   "state":0.24
                },
                "numerators":{
                   "this":11501.0,
                   "CBSA":791326.0,
                   "state":5939131.0
                },
                "numerator_errors":{
                   "this":1358.0,
                   "CBSA":11672.0,
                   "state":32807.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":79.0,
                   "state":81.0
                },
                "error_ratio":{
                   "this":10.6,
                   "CBSA":1.4,
                   "state":0.5
                }
             }
          },
          "median_value":{
             "name":"Median value of owner-occupied housing units",
             "values":{
                "this":847300.0,
                "CBSA":940900.0,
                "state":568500.0
             },
             "error":{
                "this":46941.0,
                "CBSA":7982.0,
                "state":2502.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B25077",
                "universe":"Owner-occupied housing units",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":90.0,
                "state":149.0
             },
             "error_ratio":{
                "this":5.5,
                "CBSA":0.8,
                "state":0.4
             }
          },
          "value_distribution":{
             "metadata":{
                "table_id":"B25075",
                "universe":"Owner-occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "under_100":{
                "name":"Under $100K",
                "values":{
                   "this":1.42,
                   "CBSA":2.16,
                   "state":5.03
                },
                "error":{
                   "this":0.6,
                   "CBSA":0.1,
                   "state":0.05
                },
                "numerators":{
                   "this":287.0,
                   "CBSA":20156.0,
                   "state":359961.0
                },
                "numerator_errors":{
                   "this":121.9,
                   "CBSA":908.4,
                   "state":3940.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":66.0,
                   "state":28.0
                },
                "error_ratio":{
                   "this":42.3,
                   "CBSA":4.6,
                   "state":1.0
                }
             },
             "100_to_200":{
                "name":"$100K - $200K",
                "values":{
                   "this":1.92,
                   "CBSA":1.61,
                   "state":6.63
                },
                "error":{
                   "this":0.67,
                   "CBSA":0.09,
                   "state":0.06
                },
                "numerators":{
                   "this":389.0,
                   "CBSA":15036.0,
                   "state":474527.0
                },
                "numerator_errors":{
                   "this":135.9,
                   "CBSA":818.1,
                   "state":4768.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":119.0,
                   "state":29.0
                },
                "error_ratio":{
                   "this":34.9,
                   "CBSA":5.6,
                   "state":0.9
                }
             },
             "200_to_300":{
                "name":"$200K - $300K",
                "values":{
                   "this":4.35,
                   "CBSA":2.81,
                   "state":11.06
                },
                "error":{
                   "this":0.86,
                   "CBSA":0.11,
                   "state":0.06
                },
                "numerators":{
                   "this":881.0,
                   "CBSA":26184.0,
                   "state":791435.0
                },
                "numerator_errors":{
                   "this":176.7,
                   "CBSA":1029.9,
                   "state":6253.7
                },
                "index":{
                   "this":100.0,
                   "CBSA":155.0,
                   "state":39.0
                },
                "error_ratio":{
                   "this":19.8,
                   "CBSA":3.9,
                   "state":0.5
                }
             },
             "300_to_400":{
                "name":"$300K - $400K",
                "values":{
                   "this":9.04,
                   "CBSA":5.66,
                   "state":13.6
                },
                "error":{
                   "this":1.1,
                   "CBSA":0.14,
                   "state":0.09
                },
                "numerators":{
                   "this":1831.0,
                   "CBSA":52681.0,
                   "state":973196.0
                },
                "numerator_errors":{
                   "this":231.0,
                   "CBSA":1347.0,
                   "state":7992.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":160.0,
                   "state":66.0
                },
                "error_ratio":{
                   "this":12.2,
                   "CBSA":2.5,
                   "state":0.7
                }
             },
             "400_to_500":{
                "name":"$400K - $500K",
                "values":{
                   "this":8.76,
                   "CBSA":7.56,
                   "state":13.21
                },
                "error":{
                   "this":1.7,
                   "CBSA":0.19,
                   "state":0.08
                },
                "numerators":{
                   "this":1775.0,
                   "CBSA":70432.0,
                   "state":945276.0
                },
                "numerator_errors":{
                   "this":349.0,
                   "CBSA":1773.0,
                   "state":7694.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":116.0,
                   "state":66.0
                },
                "error_ratio":{
                   "this":19.4,
                   "CBSA":2.5,
                   "state":0.6
                }
             },
             "500_to_1000000":{
                "name":"$500K - $1M",
                "values":{
                   "this":45.92,
                   "CBSA":43.94,
                   "state":35.28
                },
                "error":{
                   "this":2.12,
                   "CBSA":0.29,
                   "state":0.26
                },
                "numerators":{
                   "this":9302.0,
                   "CBSA":409063.0,
                   "state":2523951.0
                },
                "numerator_errors":{
                   "this":524.2,
                   "CBSA":3594.1,
                   "state":12891.8
                },
                "index":{
                   "this":100.0,
                   "CBSA":105.0,
                   "state":130.0
                },
                "error_ratio":{
                   "this":4.6,
                   "CBSA":0.7,
                   "state":0.7
                }
             },
             "over_1000000":{
                "name":"Over $1M",
                "values":{
                   "this":22.47,
                   "CBSA":19.26,
                   "state":7.93
                },
                "error":{
                   "this":1.75,
                   "CBSA":0.23,
                   "state":0.04
                },
                "numerators":{
                   "this":4552.0,
                   "CBSA":179279.0,
                   "state":567462.0
                },
                "numerator_errors":{
                   "this":384.0,
                   "CBSA":2390.0,
                   "state":4302.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":117.0,
                   "state":283.0
                },
                "error_ratio":{
                   "this":7.8,
                   "CBSA":1.2,
                   "state":0.5
                }
             }
          },
          "total_value":{
             "name":"Total value of owner-occupied housing units",
             "values":{
                "this":20255.0,
                "CBSA":931032.0,
                "state":7154580.0
             },
             "error":{
                "this":656.0,
                "CBSA":5500.0,
                "state":37872.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":3.2,
                "CBSA":0.6,
                "state":0.5
             }
          }
       },
       "length_of_tenure":{
          "metadata":{
             "table_id":"B25026",
             "universe":"Total population in occupied housing units",
             "acs_release":"ACS 2019 1-year"
          },
          "Before_1990":{
             "name":"Before 1990",
             "values":{
                "this":8.31,
                "CBSA":10.5,
                "state":8.86
             },
             "error":{
                "this":2.17,
                "CBSA":0.42,
                "state":0.13
             },
             "numerators":{
                "this":5748.0,
                "CBSA":488730.0,
                "state":3427072.0
             },
             "numerator_errors":{
                "this":1504.4,
                "CBSA":19338.7,
                "state":50836.5
             },
             "index":{
                "this":100.0,
                "CBSA":79.0,
                "state":94.0
             },
             "error_ratio":{
                "this":26.1,
                "CBSA":4.0,
                "state":1.5
             }
          },
          "1990s":{
             "name":"1990s",
             "values":{
                "this":15.92,
                "CBSA":10.75,
                "state":11.17
             },
             "error":{
                "this":3.93,
                "CBSA":0.42,
                "state":0.16
             },
             "numerators":{
                "this":11017.0,
                "CBSA":500178.0,
                "state":4320604.0
             },
             "numerator_errors":{
                "this":2718.4,
                "CBSA":19399.4,
                "state":63360.7
             },
             "index":{
                "this":100.0,
                "CBSA":148.0,
                "state":143.0
             },
             "error_ratio":{
                "this":24.7,
                "CBSA":3.9,
                "state":1.4
             }
          },
          "2000s":{
             "name":"2000s",
             "values":{
                "this":14.27,
                "CBSA":21.68,
                "state":21.9
             },
             "error":{
                "this":3.88,
                "CBSA":0.73,
                "state":0.25
             },
             "numerators":{
                "this":9874.0,
                "CBSA":1008965.0,
                "state":8473932.0
             },
             "numerator_errors":{
                "this":2683.8,
                "CBSA":33889.7,
                "state":98624.3
             },
             "index":{
                "this":100.0,
                "CBSA":66.0,
                "state":65.0
             },
             "error_ratio":{
                "this":27.2,
                "CBSA":3.4,
                "state":1.1
             }
          },
          "2010_to_2014":{
             "name":"2010-2014",
             "values":{
                "this":16.8,
                "CBSA":20.17,
                "state":20.05
             },
             "error":{
                "this":3.95,
                "CBSA":0.62,
                "state":0.24
             },
             "numerators":{
                "this":11629.0,
                "CBSA":939060.0,
                "state":7754580.0
             },
             "numerator_errors":{
                "this":2731.3,
                "CBSA":29080.9,
                "state":91602.6
             },
             "index":{
                "this":100.0,
                "CBSA":83.0,
                "state":84.0
             },
             "error_ratio":{
                "this":23.5,
                "CBSA":3.1,
                "state":1.2
             }
          },
          "2015_to_2016":{
             "name":"2015-2016",
             "values":{
                "this":11.1,
                "CBSA":12.37,
                "state":13.11
             },
             "error":{
                "this":3.61,
                "CBSA":0.54,
                "state":0.21
             },
             "numerators":{
                "this":7678.0,
                "CBSA":575817.0,
                "state":5072685.0
             },
             "numerator_errors":{
                "this":2500.8,
                "CBSA":25040.7,
                "state":79541.6
             },
             "index":{
                "this":100.0,
                "CBSA":90.0,
                "state":85.0
             },
             "error_ratio":{
                "this":32.5,
                "CBSA":4.4,
                "state":1.6
             }
          },
          "since_2017":{
             "name":"Since 2017",
             "values":{
                "this":33.6,
                "CBSA":24.54,
                "state":24.91
             },
             "error":{
                "this":5.42,
                "CBSA":0.73,
                "state":0.26
             },
             "numerators":{
                "this":23254.0,
                "CBSA":1142074.0,
                "state":9636829.0
             },
             "numerator_errors":{
                "this":3751.6,
                "CBSA":33871.4,
                "state":101788.8
             },
             "index":{
                "this":100.0,
                "CBSA":137.0,
                "state":135.0
             },
             "error_ratio":{
                "this":16.1,
                "CBSA":3.0,
                "state":1.0
             }
          }
       },
       "migration":{
          "moved_since_previous_year":{
             "name":"Moved since previous year",
             "values":{
                "this":16.51,
                "CBSA":12.66,
                "state":12.0
             },
             "error":{
                "this":3.79,
                "CBSA":0.41,
                "state":0.16
             },
             "numerators":{
                "this":11441.0,
                "CBSA":592840.0,
                "state":4689828.0
             },
             "numerator_errors":{
                "this":2624.2,
                "CBSA":19218.9,
                "state":63097.7
             },
             "metadata":{
                "table_id":"B07003",
                "universe":"Population 1 year and over in the United States",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":130.0,
                "state":138.0
             },
             "error_ratio":{
                "this":23.0,
                "CBSA":3.2,
                "state":1.3
             }
          }
       },
       "migration_distribution":{
          "metadata":{
             "table_id":"B07003",
             "universe":"Population 1 year and over in the United States",
             "acs_release":"ACS 2019 1-year"
          },
          "same_house_year_ago":{
             "name":"Same house year ago",
             "values":{
                "this":83.49,
                "CBSA":87.34,
                "state":88.0
             },
             "error":{
                "this":3.84,
                "CBSA":0.39,
                "state":0.17
             },
             "numerators":{
                "this":57846.0,
                "CBSA":4091762.0,
                "state":34394220.0
             },
             "numerator_errors":{
                "this":2697.0,
                "CBSA":18428.0,
                "state":68574.0
             },
             "index":{
                "this":100.0,
                "CBSA":96.0,
                "state":95.0
             },
             "error_ratio":{
                "this":4.6,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "moved_same_county":{
             "name":"From same county",
             "values":{
                "this":6.81,
                "CBSA":6.27,
                "state":7.37
             },
             "error":{
                "this":2.27,
                "CBSA":0.29,
                "state":0.13
             },
             "numerators":{
                "this":4715.0,
                "CBSA":293714.0,
                "state":2881883.0
             },
             "numerator_errors":{
                "this":1574.0,
                "CBSA":13357.0,
                "state":52031.0
             },
             "index":{
                "this":100.0,
                "CBSA":109.0,
                "state":92.0
             },
             "error_ratio":{
                "this":33.3,
                "CBSA":4.6,
                "state":1.8
             }
          },
          "moved_different_county":{
             "name":"From different county",
             "values":{
                "this":7.88,
                "CBSA":3.96,
                "state":2.72
             },
             "error":{
                "this":2.79,
                "CBSA":0.25,
                "state":0.07
             },
             "numerators":{
                "this":5457.0,
                "CBSA":185306.0,
                "state":1061256.0
             },
             "numerator_errors":{
                "this":1937.0,
                "CBSA":11875.0,
                "state":27761.0
             },
             "index":{
                "this":100.0,
                "CBSA":199.0,
                "state":290.0
             },
             "error_ratio":{
                "this":35.4,
                "CBSA":6.3,
                "state":2.6
             }
          },
          "moved_different_state":{
             "name":"From different state",
             "values":{
                "this":1.47,
                "CBSA":1.39,
                "state":1.23
             },
             "error":{
                "this":1.1,
                "CBSA":0.11,
                "state":0.05
             },
             "numerators":{
                "this":1016.0,
                "CBSA":65269.0,
                "state":480204.0
             },
             "numerator_errors":{
                "this":762.0,
                "CBSA":5065.0,
                "state":18764.0
             },
             "index":{
                "this":100.0,
                "CBSA":106.0,
                "state":120.0
             },
             "error_ratio":{
                "this":74.8,
                "CBSA":7.9,
                "state":4.1
             }
          },
          "moved_from_abroad":{
             "name":"From abroad",
             "values":{
                "this":0.37,
                "CBSA":1.04,
                "state":0.68
             },
             "error":{
                "this":0.4,
                "CBSA":0.11,
                "state":0.03
             },
             "numerators":{
                "this":253.0,
                "CBSA":48551.0,
                "state":266485.0
             },
             "numerator_errors":{
                "this":276.0,
                "CBSA":4928.0,
                "state":12302.0
             },
             "index":{
                "this":100.0,
                "CBSA":36.0,
                "state":54.0
             },
             "error_ratio":{
                "this":108.1,
                "CBSA":10.6,
                "state":4.4
             }
          }
       }
    },
    "social":{
       "educational_attainment":{
          "percent_high_school_grad_or_higher":{
             "name":"High school grad or higher",
             "values":{
                "this":97.63,
                "CBSA":89.55,
                "state":84.03
             },
             "error":{
                "this":5.29,
                "CBSA":0.79,
                "state":0.29
             },
             "numerators":{
                "this":53014.0,
                "CBSA":3083220.0,
                "state":22636359.0
             },
             "numerator_errors":{
                "this":3522.8,
                "CBSA":27313.4,
                "state":78234.5
             },
             "metadata":{
                "table_id":"B15002",
                "universe":"Population 25 years and over",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":109.0,
                "state":116.0
             },
             "error_ratio":{
                "this":5.4,
                "CBSA":0.9,
                "state":0.3
             }
          },
          "percent_bachelor_degree_or_higher":{
             "name":"Bachelor's degree or higher",
             "values":{
                "this":65.3,
                "CBSA":51.35,
                "state":35.0
             },
             "error":{
                "this":4.26,
                "CBSA":0.57,
                "state":0.17
             },
             "numerators":{
                "this":35461.0,
                "CBSA":1768174.0,
                "state":9428484.0
             },
             "numerator_errors":{
                "this":2687.3,
                "CBSA":19525.3,
                "state":44753.9
             },
             "metadata":{
                "table_id":"B15002",
                "universe":"Population 25 years and over",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":127.0,
                "state":187.0
             },
             "error_ratio":{
                "this":6.5,
                "CBSA":1.1,
                "state":0.5
             }
          }
       },
       "educational_attainment_distribution":{
          "metadata":{
             "table_id":"B15002",
             "universe":"Population 25 years and over",
             "acs_release":"ACS 2019 1-year"
          },
          "non_high_school_grad":{
             "name":"No degree",
             "values":{
                "this":2.37,
                "CBSA":10.45,
                "state":15.97
             },
             "error":{
                "this":1.21,
                "CBSA":0.34,
                "state":0.14
             },
             "numerators":{
                "this":1288.0,
                "CBSA":359842.0,
                "state":4301513.0
             },
             "numerator_errors":{
                "this":661.1,
                "CBSA":11853.8,
                "state":37669.1
             },
             "index":{
                "this":100.0,
                "CBSA":23.0,
                "state":15.0
             },
             "error_ratio":{
                "this":51.1,
                "CBSA":3.3,
                "state":0.9
             }
          },
          "high_school_grad":{
             "name":"High school",
             "values":{
                "this":7.73,
                "CBSA":15.67,
                "state":20.59
             },
             "error":{
                "this":1.91,
                "CBSA":0.37,
                "state":0.16
             },
             "numerators":{
                "this":4200.0,
                "CBSA":539467.0,
                "state":5546711.0
             },
             "numerator_errors":{
                "this":1050.9,
                "CBSA":12581.0,
                "state":42454.0
             },
             "index":{
                "this":100.0,
                "CBSA":49.0,
                "state":38.0
             },
             "error_ratio":{
                "this":24.7,
                "CBSA":2.4,
                "state":0.8
             }
          },
          "some_college":{
             "name":"Some college",
             "values":{
                "this":24.59,
                "CBSA":22.53,
                "state":28.44
             },
             "error":{
                "this":3.6,
                "CBSA":0.42,
                "state":0.18
             },
             "numerators":{
                "this":13353.0,
                "CBSA":775579.0,
                "state":7661164.0
             },
             "numerator_errors":{
                "this":2020.9,
                "CBSA":14370.3,
                "state":48118.5
             },
             "index":{
                "this":100.0,
                "CBSA":109.0,
                "state":86.0
             },
             "error_ratio":{
                "this":14.6,
                "CBSA":1.9,
                "state":0.6
             }
          },
          "Bachelor_degree":{
             "name":"Bachelor's",
             "values":{
                "this":35.8,
                "CBSA":29.97,
                "state":21.86
             },
             "error":{
                "this":3.01,
                "CBSA":0.4,
                "state":0.13
             },
             "numerators":{
                "this":19442.0,
                "CBSA":1032030.0,
                "state":5889724.0
             },
             "numerator_errors":{
                "this":1796.1,
                "CBSA":13696.0,
                "state":34784.9
             },
             "index":{
                "this":100.0,
                "CBSA":119.0,
                "state":164.0
             },
             "error_ratio":{
                "this":8.4,
                "CBSA":1.3,
                "state":0.6
             }
          },
          "post_grad_degree":{
             "name":"Post-grad",
             "values":{
                "this":29.5,
                "CBSA":21.38,
                "state":13.14
             },
             "error":{
                "this":3.5,
                "CBSA":0.4,
                "state":0.1
             },
             "numerators":{
                "this":16019.0,
                "CBSA":736144.0,
                "state":3538760.0
             },
             "numerator_errors":{
                "this":1998.9,
                "CBSA":13916.1,
                "state":28159.0
             },
             "index":{
                "this":100.0,
                "CBSA":138.0,
                "state":225.0
             },
             "error_ratio":{
                "this":11.9,
                "CBSA":1.9,
                "state":0.8
             }
          }
       },
       "place_of_birth":{
          "percent_foreign_born":{
             "name":"Foreign-born population",
             "values":{
                "this":21.37,
                "CBSA":30.94,
                "state":26.74
             },
             "error":{
                "this":3.26,
                "CBSA":0.47,
                "state":0.14
             },
             "numerators":{
                "this":14991.0,
                "CBSA":1464048.0,
                "state":10564220.0
             },
             "numerator_errors":{
                "this":2285.0,
                "CBSA":22110.0,
                "state":55357.0
             },
             "metadata":{
                "table_id":"B05002",
                "universe":"Total population",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":69.0,
                "state":80.0
             },
             "error_ratio":{
                "this":15.3,
                "CBSA":1.5,
                "state":0.5
             }
          },
          "distribution":{
             "metadata":{
                "table_id":"B05006",
                "universe":"Foreign-born population",
                "acs_release":"ACS 2019 5-year"
             },
             "europe":{
                "name":"Europe",
                "values":{
                   "this":24.25,
                   "CBSA":9.42,
                   "state":6.42
                },
                "error":{
                   "this":2.94,
                   "CBSA":0.19,
                   "state":0.07
                },
                "numerators":{
                   "this":3934.0,
                   "CBSA":135944.0,
                   "state":677261.0
                },
                "numerator_errors":{
                   "this":542.0,
                   "CBSA":2807.0,
                   "state":7344.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":257.0,
                   "state":378.0
                },
                "error_ratio":{
                   "this":12.1,
                   "CBSA":2.0,
                   "state":1.1
                }
             },
             "asia":{
                "name":"Asia",
                "values":{
                   "this":55.32,
                   "CBSA":57.07,
                   "state":39.42
                },
                "error":{
                   "this":3.75,
                   "CBSA":0.46,
                   "state":0.09
                },
                "numerators":{
                   "this":8974.0,
                   "CBSA":823767.0,
                   "state":4157181.0
                },
                "numerator_errors":{
                   "this":847.0,
                   "CBSA":4566.0,
                   "state":17026.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":97.0,
                   "state":140.0
                },
                "error_ratio":{
                   "this":6.8,
                   "CBSA":0.8,
                   "state":0.2
                }
             },
             "africa":{
                "name":"Africa",
                "values":{
                   "this":3.35,
                   "CBSA":2.41,
                   "state":1.84
                },
                "error":{
                   "this":1.38,
                   "CBSA":0.12,
                   "state":0.05
                },
                "numerators":{
                   "this":544.0,
                   "CBSA":34802.0,
                   "state":193567.0
                },
                "numerator_errors":{
                   "this":226.0,
                   "CBSA":1747.0,
                   "state":4869.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":139.0,
                   "state":182.0
                },
                "error_ratio":{
                   "this":41.2,
                   "CBSA":5.0,
                   "state":2.7
                }
             },
             "oceania":{
                "name":"Oceania",
                "values":{
                   "this":0.92,
                   "CBSA":1.66,
                   "state":0.82
                },
                "error":{
                   "this":0.88,
                   "CBSA":0.11,
                   "state":0.03
                },
                "numerators":{
                   "this":149.0,
                   "CBSA":23950.0,
                   "state":86276.0
                },
                "numerator_errors":{
                   "this":143.0,
                   "CBSA":1560.0,
                   "state":2767.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":55.0,
                   "state":112.0
                },
                "error_ratio":{
                   "this":95.7,
                   "CBSA":6.6,
                   "state":3.7
                }
             },
             "latin_america":{
                "name":"Latin America",
                "values":{
                   "this":12.1,
                   "CBSA":27.95,
                   "state":50.28
                },
                "error":{
                   "this":2.52,
                   "CBSA":0.31,
                   "state":0.15
                },
                "numerators":{
                   "this":1963.0,
                   "CBSA":403440.0,
                   "state":5302761.0
                },
                "numerator_errors":{
                   "this":429.0,
                   "CBSA":5038.0,
                   "state":24213.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":43.0,
                   "state":24.0
                },
                "error_ratio":{
                   "this":20.8,
                   "CBSA":1.1,
                   "state":0.3
                }
             },
             "north_america":{
                "name":"North America",
                "values":{
                   "this":4.05,
                   "CBSA":1.5,
                   "state":1.23
                },
                "error":{
                   "this":1.22,
                   "CBSA":0.07,
                   "state":0.03
                },
                "numerators":{
                   "this":657.0,
                   "CBSA":21628.0,
                   "state":130093.0
                },
                "numerator_errors":{
                   "this":203.0,
                   "CBSA":1082.0,
                   "state":3038.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":270.0,
                   "state":329.0
                },
                "error_ratio":{
                   "this":30.1,
                   "CBSA":4.7,
                   "state":2.4
                }
             }
          }
       },
       "language":{
          "percent_non_english_at_home":{
             "name":"Persons with language other than English spoken at home",
             "values":{
                "this":null,
                "CBSA":null,
                "state":44.23
             },
             "error":{
                "this":null,
                "CBSA":null,
                "state":0.14
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":16292017.0
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":52781.5
             },
             "metadata":{
                "table_id":"B16001",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":null,
                "state":0.3
             }
          },
          "children":{
             "metadata":{
                "table_id":"B16007",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 1-year"
             },
             "english":{
                "name":"English only",
                "values":{
                   "this":85.15,
                   "CBSA":61.88,
                   "state":56.97
                },
                "error":{
                   "this":13.67,
                   "CBSA":1.17,
                   "state":0.51
                },
                "numerators":{
                   "this":6979.0,
                   "CBSA":416591.0,
                   "state":3710631.0
                },
                "numerator_errors":{
                   "this":2037.0,
                   "CBSA":7911.0,
                   "state":33084.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":138.0,
                   "state":149.0
                },
                "error_ratio":{
                   "this":16.1,
                   "CBSA":1.9,
                   "state":0.9
                }
             },
             "spanish":{
                "name":"Spanish",
                "values":{
                   "this":3.65,
                   "CBSA":19.91,
                   "state":33.31
                },
                "error":{
                   "this":4.43,
                   "CBSA":0.9,
                   "state":0.42
                },
                "numerators":{
                   "this":299.0,
                   "CBSA":134015.0,
                   "state":2169717.0
                },
                "numerator_errors":{
                   "this":370.0,
                   "CBSA":6061.0,
                   "state":27349.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":18.0,
                   "state":11.0
                },
                "error_ratio":{
                   "this":121.4,
                   "CBSA":4.5,
                   "state":1.3
                }
             },
             "indoeuropean":{
                "name":"Indo-European",
                "values":{
                   "this":6.36,
                   "CBSA":5.64,
                   "state":3.07
                },
                "error":{
                   "this":4.91,
                   "CBSA":0.56,
                   "state":0.15
                },
                "numerators":{
                   "this":521.0,
                   "CBSA":37994.0,
                   "state":200078.0
                },
                "numerator_errors":{
                   "this":422.0,
                   "CBSA":3804.0,
                   "state":9887.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":113.0,
                   "state":207.0
                },
                "error_ratio":{
                   "this":77.2,
                   "CBSA":9.9,
                   "state":4.9
                }
             },
             "asian_islander":{
                "name":"Asian/Islander",
                "values":{
                   "this":3.86,
                   "CBSA":11.16,
                   "state":5.66
                },
                "error":{
                   "this":3.36,
                   "CBSA":0.61,
                   "state":0.17
                },
                "numerators":{
                   "this":316.0,
                   "CBSA":75163.0,
                   "state":368935.0
                },
                "numerator_errors":{
                   "this":286.0,
                   "CBSA":4124.0,
                   "state":11144.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":35.0,
                   "state":68.0
                },
                "error_ratio":{
                   "this":87.0,
                   "CBSA":5.5,
                   "state":3.0
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":0.99,
                   "CBSA":1.4,
                   "state":0.98
                },
                "error":{
                   "this":1.57,
                   "CBSA":0.3,
                   "state":0.11
                },
                "numerators":{
                   "this":81.0,
                   "CBSA":9442.0,
                   "state":63864.0
                },
                "numerator_errors":{
                   "this":130.0,
                   "CBSA":2040.0,
                   "state":6999.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":71.0,
                   "state":101.0
                },
                "error_ratio":{
                   "this":158.6,
                   "CBSA":21.4,
                   "state":11.2
                }
             }
          },
          "adults":{
             "metadata":{
                "table_id":"B16007",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 1-year"
             },
             "english":{
                "name":"English only",
                "values":{
                   "this":76.16,
                   "CBSA":58.1,
                   "state":55.21
                },
                "error":{
                   "this":3.43,
                   "CBSA":0.48,
                   "state":0.18
                },
                "numerators":{
                   "this":44357.0,
                   "CBSA":2212141.0,
                   "state":16905949.0
                },
                "numerator_errors":{
                   "this":2906.3,
                   "CBSA":18159.5,
                   "state":55036.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":131.0,
                   "state":138.0
                },
                "error_ratio":{
                   "this":4.5,
                   "CBSA":0.8,
                   "state":0.3
                }
             },
             "spanish":{
                "name":"Spanish",
                "values":{
                   "this":4.1,
                   "CBSA":14.83,
                   "state":27.8
                },
                "error":{
                   "this":1.94,
                   "CBSA":0.28,
                   "state":0.12
                },
                "numerators":{
                   "this":2386.0,
                   "CBSA":564814.0,
                   "state":8513944.0
                },
                "numerator_errors":{
                   "this":1134.2,
                   "CBSA":10565.9,
                   "state":37016.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":28.0,
                   "state":15.0
                },
                "error_ratio":{
                   "this":47.3,
                   "CBSA":1.9,
                   "state":0.4
                }
             },
             "indoeuropean":{
                "name":"Indo-European",
                "values":{
                   "this":9.19,
                   "CBSA":7.23,
                   "state":4.88
                },
                "error":{
                   "this":2.73,
                   "CBSA":0.33,
                   "state":0.09
                },
                "numerators":{
                   "this":5354.0,
                   "CBSA":275261.0,
                   "state":1494004.0
                },
                "numerator_errors":{
                   "this":1609.4,
                   "CBSA":12540.4,
                   "state":28577.5
                },
                "index":{
                   "this":100.0,
                   "CBSA":127.0,
                   "state":188.0
                },
                "error_ratio":{
                   "this":29.7,
                   "CBSA":4.6,
                   "state":1.8
                }
             },
             "asian_islander":{
                "name":"Asian/Islander",
                "values":{
                   "this":8.6,
                   "CBSA":18.63,
                   "state":10.99
                },
                "error":{
                   "this":2.76,
                   "CBSA":0.3,
                   "state":0.08
                },
                "numerators":{
                   "this":5009.0,
                   "CBSA":709253.0,
                   "state":3364089.0
                },
                "numerator_errors":{
                   "this":1624.6,
                   "CBSA":11259.6,
                   "state":24688.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":46.0,
                   "state":78.0
                },
                "error_ratio":{
                   "this":32.1,
                   "CBSA":1.6,
                   "state":0.7
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":1.95,
                   "CBSA":1.21,
                   "state":1.12
                },
                "error":{
                   "this":1.41,
                   "CBSA":0.14,
                   "state":0.05
                },
                "numerators":{
                   "this":1133.0,
                   "CBSA":46214.0,
                   "state":343173.0
                },
                "numerator_errors":{
                   "this":822.7,
                   "CBSA":5246.1,
                   "state":14221.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":161.0,
                   "state":174.0
                },
                "error_ratio":{
                   "this":72.3,
                   "CBSA":11.6,
                   "state":4.5
                }
             }
          }
       },
       "veterans":{
          "wartime_service":{
             "metadata":{
                "table_id":"B21002",
                "universe":"Civilian veterans 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "wwii":{
                "name":"WWII",
                "values":{
                   "this":749.0,
                   "CBSA":8551.0,
                   "state":66574.0
                },
                "error":{
                   "this":227.74,
                   "CBSA":587.6,
                   "state":1628.6
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":9.0,
                   "state":1.0
                },
                "error_ratio":{
                   "this":30.4,
                   "CBSA":6.9,
                   "state":2.4
                }
             },
             "korea":{
                "name":"Korea",
                "values":{
                   "this":780.0,
                   "CBSA":15732.0,
                   "state":145536.0
                },
                "error":{
                   "this":183.51,
                   "CBSA":775.97,
                   "state":2399.66
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":5.0,
                   "state":1.0
                },
                "error_ratio":{
                   "this":23.5,
                   "CBSA":4.9,
                   "state":1.6
                }
             },
             "vietnam":{
                "name":"Vietnam",
                "values":{
                   "this":1247.0,
                   "CBSA":55782.0,
                   "state":561083.0
                },
                "error":{
                   "this":252.17,
                   "CBSA":1426.49,
                   "state":4350.77
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":2.0,
                   "state":null
                },
                "error_ratio":{
                   "this":20.2,
                   "CBSA":2.6,
                   "state":0.8
                }
             },
             "gulf_1990s":{
                "name":"Gulf (1990s)",
                "values":{
                   "this":278.0,
                   "CBSA":22897.0,
                   "state":284290.0
                },
                "error":{
                   "this":110.58,
                   "CBSA":1005.17,
                   "state":3865.66
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":1.0,
                   "state":null
                },
                "error_ratio":{
                   "this":39.8,
                   "CBSA":4.4,
                   "state":1.4
                }
             },
             "gulf_2001":{
                "name":"Gulf (2001-)",
                "values":{
                   "this":287.0,
                   "CBSA":22022.0,
                   "state":309021.0
                },
                "error":{
                   "this":112.79,
                   "CBSA":1007.87,
                   "state":4425.46
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":1.0,
                   "state":null
                },
                "error_ratio":{
                   "this":39.3,
                   "CBSA":4.6,
                   "state":1.4
                }
             }
          },
          "sex":{
             "male":{
                "name":"Male",
                "values":{
                   "this":3210.0,
                   "CBSA":132185.0,
                   "state":1351006.0
                },
                "error":{
                   "this":871.0,
                   "CBSA":5340.0,
                   "state":19299.0
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B21001",
                   "universe":"Civilian population 18 years and over",
                   "acs_release":"ACS 2019 1-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":2.0,
                   "state":null
                },
                "error_ratio":{
                   "this":27.1,
                   "CBSA":4.0,
                   "state":1.4
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":104.0,
                   "CBSA":10262.0,
                   "state":120461.0
                },
                "error":{
                   "this":151.0,
                   "CBSA":1944.0,
                   "state":5618.0
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B21001",
                   "universe":"Civilian population 18 years and over",
                   "acs_release":"ACS 2019 1-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":1.0,
                   "state":null
                },
                "error_ratio":{
                   "this":145.2,
                   "CBSA":18.9,
                   "state":4.7
                }
             }
          },
          "number":{
             "name":"Total veterans",
             "values":{
                "this":3314.0,
                "CBSA":142447.0,
                "state":1471467.0
             },
             "error":{
                "this":892.0,
                "CBSA":6012.0,
                "state":19838.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B21001",
                "universe":"Civilian population 18 years and over",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":26.9,
                "CBSA":4.2,
                "state":1.3
             }
          },
          "percentage":{
             "name":"Population with veteran status",
             "values":{
                "this":5.69,
                "CBSA":3.74,
                "state":4.83
             },
             "error":{
                "this":1.52,
                "CBSA":0.16,
                "state":0.07
             },
             "numerators":{
                "this":3314.0,
                "CBSA":142447.0,
                "state":1471467.0
             },
             "numerator_errors":{
                "this":892.0,
                "CBSA":6012.0,
                "state":19838.0
             },
             "metadata":{
                "table_id":"B21001",
                "universe":"Civilian population 18 years and over",
                "acs_release":"ACS 2019 1-year"
             },
             "index":{
                "this":100.0,
                "CBSA":152.0,
                "state":118.0
             },
             "error_ratio":{
                "this":26.7,
                "CBSA":4.3,
                "state":1.4
             }
          }
       }
    },
    "geo_metadata":{
       "aland":51169190,
       "awater":31195,
       "display_name":"Walnut Creek, CA",
       "full_geoid":"16000US0683346",
       "population":69007,
       "simple_name":"Walnut Creek",
       "sumlevel":"160",
       "square_miles":19.8,
       "population_density":3551.3
    }
 }

 export default walnut_creek