const tracy = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "San Joaquin County, CA",
             "short_name": "San Joaquin County",
             "sumlevel": "050",
             "land_area": 3606127799,
             "full_geoid": "05000US06077",
             "total_population": 762148
          },
          "CBSA": {
             "full_name": "Stockton, CA Metro Area",
             "short_name": "Stockton, CA",
             "sumlevel": "310",
             "land_area": 3606127799,
             "full_geoid": "31000US44700",
             "total_population": 762148
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39512223
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Tracy, CA",
          "short_name": "Tracy",
          "sumlevel": "160",
          "land_area": 67058983,
          "full_geoid": "16000US0680238",
          "total_population": 94751,
          "sumlevel_name": "Place",
          "short_geoid": "0680238"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 24.58,
                   "CBSA": 26.73,
                   "state": 22.5
                },
                "error": {
                   "this": 2.78,
                   "CBSA": 0.62,
                   "state": 0.08
                },
                "numerators": {
                   "this": 23292,
                   "CBSA": 203759,
                   "state": 8891064
                },
                "numerator_errors": {
                   "this": 2638.7,
                   "CBSA": 4696.8,
                   "state": 32008.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 109
                },
                "error_ratio": {
                   "this": 11.3,
                   "CBSA": 2.3,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 66.51,
                   "CBSA": 60.22,
                   "state": 62.73
                },
                "error": {
                   "this": 4.65,
                   "CBSA": 0.93,
                   "state": 0.13
                },
                "numerators": {
                   "this": 63016,
                   "CBSA": 458979,
                   "state": 2.478616E7
                },
                "numerator_errors": {
                   "this": 4406.6,
                   "CBSA": 7070.5,
                   "state": 51870.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 106
                },
                "error_ratio": {
                   "this": 7,
                   "CBSA": 1.5,
                   "state": 0.2
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 8.91,
                   "CBSA": 13.04,
                   "state": 14.77
                },
                "error": {
                   "this": 1.55,
                   "CBSA": 0.56,
                   "state": 0.08
                },
                "numerators": {
                   "this": 8443,
                   "CBSA": 99410,
                   "state": 5834998
                },
                "numerator_errors": {
                   "this": 1466.4,
                   "CBSA": 4238,
                   "state": 32031.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 68,
                   "state": 60
                },
                "error_ratio": {
                   "this": 17.4,
                   "CBSA": 4.3,
                   "state": 0.5
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.64,
                      "CBSA": 13.75,
                      "state": 12.04
                   },
                   "error": {
                      "this": 2.04,
                      "CBSA": 0.43,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 11028,
                      "CBSA": 104789,
                      "state": 4758601
                   },
                   "numerator_errors": {
                      "this": 1930.4,
                      "CBSA": 3243.5,
                      "state": 23052.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 85,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 17.5,
                      "CBSA": 3.1,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 17.2,
                      "CBSA": 15.94,
                      "state": 13.1
                   },
                   "error": {
                      "this": 2.29,
                      "CBSA": 0.47,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 16301,
                      "CBSA": 121487,
                      "state": 5176959
                   },
                   "numerator_errors": {
                      "this": 2173,
                      "CBSA": 3597.3,
                      "state": 23034.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 108,
                      "state": 131
                   },
                   "error_ratio": {
                      "this": 13.3,
                      "CBSA": 2.9,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 16.59,
                      "CBSA": 13.96,
                      "state": 14.49
                   },
                   "error": {
                      "this": 2.66,
                      "CBSA": 0.5,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 15722,
                      "CBSA": 106405,
                      "state": 5723923
                   },
                   "numerator_errors": {
                      "this": 2518.5,
                      "CBSA": 3791.6,
                      "state": 24219.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 119,
                      "state": 114
                   },
                   "error_ratio": {
                      "this": 16,
                      "CBSA": 3.6,
                      "state": 0.4
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.28,
                      "CBSA": 13.21,
                      "state": 14.47
                   },
                   "error": {
                      "this": 2.07,
                      "CBSA": 0.44,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 13526,
                      "CBSA": 100653,
                      "state": 5715837
                   },
                   "numerator_errors": {
                      "this": 1963.2,
                      "CBSA": 3352.3,
                      "state": 22852.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 108,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 14.5,
                      "CBSA": 3.3,
                      "state": 0.4
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.1,
                      "CBSA": 12.94,
                      "state": 12.77
                   },
                   "error": {
                      "this": 1.82,
                      "CBSA": 0.42,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 13363,
                      "CBSA": 98642,
                      "state": 5046965
                   },
                   "numerator_errors": {
                      "this": 1727.2,
                      "CBSA": 3186.6,
                      "state": 22530.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 109,
                      "state": 110
                   },
                   "error_ratio": {
                      "this": 12.9,
                      "CBSA": 3.2,
                      "state": 0.5
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 11.4,
                      "CBSA": 11.78,
                      "state": 12.46
                   },
                   "error": {
                      "this": 1.89,
                      "CBSA": 0.3,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 10802,
                      "CBSA": 89816,
                      "state": 4923782
                   },
                   "numerator_errors": {
                      "this": 1790.8,
                      "CBSA": 2260.1,
                      "state": 20800
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 16.6,
                      "CBSA": 2.5,
                      "state": 0.4
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.98,
                      "CBSA": 9.47,
                      "state": 10.63
                   },
                   "error": {
                      "this": 1.62,
                      "CBSA": 0.49,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 8509,
                      "CBSA": 72180,
                      "state": 4200080
                   },
                   "numerator_errors": {
                      "this": 1530.3,
                      "CBSA": 3730.9,
                      "state": 31191.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 95,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 18,
                      "CBSA": 5.2,
                      "state": 0.8
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 3.82,
                      "CBSA": 5.89,
                      "state": 6.44
                   },
                   "error": {
                      "this": 1,
                      "CBSA": 0.35,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 3624,
                      "CBSA": 44876,
                      "state": 2545061
                   },
                   "numerator_errors": {
                      "this": 943.1,
                      "CBSA": 2680.8,
                      "state": 19797.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 65,
                      "state": 59
                   },
                   "error_ratio": {
                      "this": 26.2,
                      "CBSA": 5.9,
                      "state": 0.8
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 1.98,
                      "CBSA": 3.06,
                      "state": 3.6
                   },
                   "error": {
                      "this": 0.72,
                      "CBSA": 0.28,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 1876,
                      "CBSA": 23300,
                      "state": 1421015
                   },
                   "numerator_errors": {
                      "this": 681.8,
                      "CBSA": 2137.5,
                      "state": 16282.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 65,
                      "state": 55
                   },
                   "error_ratio": {
                      "this": 36.4,
                      "CBSA": 9.2,
                      "state": 1.1
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13.38,
                      "CBSA": 14.17,
                      "state": 12.35
                   },
                   "error": {
                      "this": 3.27,
                      "CBSA": 0.61,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6102,
                      "CBSA": 53949,
                      "state": 2425066
                   },
                   "numerator_errors": {
                      "this": 1522.3,
                      "CBSA": 2327,
                      "state": 17537.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 94,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 24.4,
                      "CBSA": 4.3,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 18.44,
                      "CBSA": 16.6,
                      "state": 13.47
                   },
                   "error": {
                      "this": 3.56,
                      "CBSA": 0.69,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 8409,
                      "CBSA": 63236,
                      "state": 2646132
                   },
                   "numerator_errors": {
                      "this": 1682.3,
                      "CBSA": 2625.6,
                      "state": 17211.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 111,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 19.3,
                      "CBSA": 4.2,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 15.44,
                      "CBSA": 14.35,
                      "state": 15.06
                   },
                   "error": {
                      "this": 3.93,
                      "CBSA": 0.67,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 7042,
                      "CBSA": 54667,
                      "state": 2956969
                   },
                   "numerator_errors": {
                      "this": 1829,
                      "CBSA": 2573,
                      "state": 16123.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 108,
                      "state": 103
                   },
                   "error_ratio": {
                      "this": 25.5,
                      "CBSA": 4.7,
                      "state": 0.5
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 15,
                      "CBSA": 13.5,
                      "state": 14.93
                   },
                   "error": {
                      "this": 3.29,
                      "CBSA": 0.68,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6841,
                      "CBSA": 51432,
                      "state": 2932306
                   },
                   "numerator_errors": {
                      "this": 1542.1,
                      "CBSA": 2578.4,
                      "state": 17684.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 111,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 21.9,
                      "CBSA": 5,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.19,
                      "CBSA": 12.82,
                      "state": 12.82
                   },
                   "error": {
                      "this": 2.53,
                      "CBSA": 0.63,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6471,
                      "CBSA": 48811,
                      "state": 2518785
                   },
                   "numerator_errors": {
                      "this": 1202.3,
                      "CBSA": 2397.4,
                      "state": 17201
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 111,
                      "state": 111
                   },
                   "error_ratio": {
                      "this": 17.8,
                      "CBSA": 4.9,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 10.53,
                      "CBSA": 11.89,
                      "state": 12.42
                   },
                   "error": {
                      "this": 2.43,
                      "CBSA": 0.4,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 4803,
                      "CBSA": 45283,
                      "state": 2439055
                   },
                   "numerator_errors": {
                      "this": 1136.2,
                      "CBSA": 1528.7,
                      "state": 15253.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 89,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 23.1,
                      "CBSA": 3.4,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.07,
                      "CBSA": 8.93,
                      "state": 10.2
                   },
                   "error": {
                      "this": 2.15,
                      "CBSA": 0.69,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 3681,
                      "CBSA": 33993,
                      "state": 2004301
                   },
                   "numerator_errors": {
                      "this": 997.6,
                      "CBSA": 2610.6,
                      "state": 21480.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 90,
                      "state": 79
                   },
                   "error_ratio": {
                      "this": 26.6,
                      "CBSA": 7.7,
                      "state": 1.1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 3.84,
                      "CBSA": 5.41,
                      "state": 5.89
                   },
                   "error": {
                      "this": 1.44,
                      "CBSA": 0.48,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 1753,
                      "CBSA": 20598,
                      "state": 1156395
                   },
                   "numerator_errors": {
                      "this": 664.8,
                      "CBSA": 1817.5,
                      "state": 13944.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 71,
                      "state": 65
                   },
                   "error_ratio": {
                      "this": 37.5,
                      "CBSA": 8.9,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 1.09,
                      "CBSA": 2.33,
                      "state": 2.86
                   },
                   "error": {
                      "this": 0.72,
                      "CBSA": 0.4,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 499,
                      "CBSA": 8876,
                      "state": 561785
                   },
                   "numerator_errors": {
                      "this": 328.5,
                      "CBSA": 1506.1,
                      "state": 10571.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 47,
                      "state": 38
                   },
                   "error_ratio": {
                      "this": 66.1,
                      "CBSA": 17.2,
                      "state": 1.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 10.02,
                      "CBSA": 13.33,
                      "state": 11.74
                   },
                   "error": {
                      "this": 2.37,
                      "CBSA": 0.59,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 4926,
                      "CBSA": 50840,
                      "state": 2333535
                   },
                   "numerator_errors": {
                      "this": 1187,
                      "CBSA": 2259.4,
                      "state": 14962.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 75,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 23.7,
                      "CBSA": 4.4,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 16.06,
                      "CBSA": 15.28,
                      "state": 12.74
                   },
                   "error": {
                      "this": 2.69,
                      "CBSA": 0.64,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 7892,
                      "CBSA": 58251,
                      "state": 2530827
                   },
                   "numerator_errors": {
                      "this": 1375.5,
                      "CBSA": 2459,
                      "state": 15307.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 126
                   },
                   "error_ratio": {
                      "this": 16.7,
                      "CBSA": 4.2,
                      "state": 0.6
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 17.66,
                      "CBSA": 13.57,
                      "state": 13.92
                   },
                   "error": {
                      "this": 3.42,
                      "CBSA": 0.73,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 8680,
                      "CBSA": 51738,
                      "state": 2766954
                   },
                   "numerator_errors": {
                      "this": 1731.3,
                      "CBSA": 2785,
                      "state": 18072.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 130,
                      "state": 127
                   },
                   "error_ratio": {
                      "this": 19.4,
                      "CBSA": 5.4,
                      "state": 0.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 13.6,
                      "CBSA": 12.91,
                      "state": 14.01
                   },
                   "error": {
                      "this": 2.38,
                      "CBSA": 0.56,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6685,
                      "CBSA": 49221,
                      "state": 2783531
                   },
                   "numerator_errors": {
                      "this": 1215,
                      "CBSA": 2142.4,
                      "state": 14474.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 17.5,
                      "CBSA": 4.3,
                      "state": 0.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.02,
                      "CBSA": 13.07,
                      "state": 12.72
                   },
                   "error": {
                      "this": 2.43,
                      "CBSA": 0.55,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6892,
                      "CBSA": 49831,
                      "state": 2528180
                   },
                   "numerator_errors": {
                      "this": 1240,
                      "CBSA": 2099.2,
                      "state": 14551.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 110
                   },
                   "error_ratio": {
                      "this": 17.3,
                      "CBSA": 4.2,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 12.21,
                      "CBSA": 11.68,
                      "state": 12.5
                   },
                   "error": {
                      "this": 2.75,
                      "CBSA": 0.44,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 5999,
                      "CBSA": 44533,
                      "state": 2484727
                   },
                   "numerator_errors": {
                      "this": 1384.1,
                      "CBSA": 1664.7,
                      "state": 14140.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 98
                   },
                   "error_ratio": {
                      "this": 22.5,
                      "CBSA": 3.8,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.82,
                      "CBSA": 10.01,
                      "state": 11.05
                   },
                   "error": {
                      "this": 2.31,
                      "CBSA": 0.7,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 4828,
                      "CBSA": 38187,
                      "state": 2195779
                   },
                   "numerator_errors": {
                      "this": 1160.4,
                      "CBSA": 2665.3,
                      "state": 22615.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 23.5,
                      "CBSA": 7,
                      "state": 1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 3.81,
                      "CBSA": 6.37,
                      "state": 6.99
                   },
                   "error": {
                      "this": 1.35,
                      "CBSA": 0.52,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 1871,
                      "CBSA": 24278,
                      "state": 1388666
                   },
                   "numerator_errors": {
                      "this": 668.9,
                      "CBSA": 1970.7,
                      "state": 14053.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 60,
                      "state": 55
                   },
                   "error_ratio": {
                      "this": 35.4,
                      "CBSA": 8.2,
                      "state": 1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.8,
                      "CBSA": 3.78,
                      "state": 4.32
                   },
                   "error": {
                      "this": 1.21,
                      "CBSA": 0.4,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 1377,
                      "CBSA": 14424,
                      "state": 859230
                   },
                   "numerator_errors": {
                      "this": 597.5,
                      "CBSA": 1516.8,
                      "state": 12383.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 74,
                      "state": 65
                   },
                   "error_ratio": {
                      "this": 43.2,
                      "CBSA": 10.6,
                      "state": 1.4
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 33.6,
                   "CBSA": 34.7,
                   "state": 37
                },
                "error": {
                   "this": 2.3,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 91
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 32.1,
                   "CBSA": 33.5,
                   "state": 35.9
                },
                "error": {
                   "this": 3.4,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 89
                },
                "error_ratio": {
                   "this": 10.6,
                   "CBSA": 0.9,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 34.9,
                   "CBSA": 36.2,
                   "state": 38.2
                },
                "error": {
                   "this": 1.9,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 91
                },
                "error_ratio": {
                   "this": 5.4,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 48.13,
                "CBSA": 49.97,
                "state": 49.71
             },
             "error": {
                "this": 2.49,
                "CBSA": 0.11,
                "state": 0.02
             },
             "numerators": {
                "this": 45601,
                "CBSA": 380845,
                "state": 1.9640794E7
             },
             "numerator_errors": {
                "this": 2356,
                "CBSA": 809,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 97
             },
             "error_ratio": {
                "this": 5.2,
                "CBSA": 0.2,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 51.87,
                "CBSA": 50.03,
                "state": 50.29
             },
             "error": {
                "this": 2.48,
                "CBSA": 0.11,
                "state": 0.02
             },
             "numerators": {
                "this": 49150,
                "CBSA": 381303,
                "state": 1.9871428E7
             },
             "numerator_errors": {
                "this": 2354,
                "CBSA": 809,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 103
             },
             "error_ratio": {
                "this": 4.8,
                "CBSA": 0.2,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 31.65,
                "CBSA": 30.35,
                "state": 36.33
             },
             "error": {
                "this": 4.61,
                "CBSA": 0.07,
                "state": 0.02
             },
             "numerators": {
                "this": 29984,
                "CBSA": 231286,
                "state": 1.4356081E7
             },
             "numerator_errors": {
                "this": 4371,
                "CBSA": 516,
                "state": 6353
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 87
             },
             "error_ratio": {
                "this": 14.6,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 4.47,
                "CBSA": 6.65,
                "state": 5.5
             },
             "error": {
                "this": 2.22,
                "CBSA": 0.39,
                "state": 0.04
             },
             "numerators": {
                "this": 4238,
                "CBSA": 50665,
                "state": 2171989
             },
             "numerator_errors": {
                "this": 2099,
                "CBSA": 2937,
                "state": 16630
             },
             "index": {
                "this": 100,
                "CBSA": 67,
                "state": 81
             },
             "error_ratio": {
                "this": 49.7,
                "CBSA": 5.9,
                "state": 0.7
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0,
                "CBSA": 0.3,
                "state": 0.38
             },
             "error": {
                "this": 0,
                "CBSA": 0.13,
                "state": 0.01
             },
             "numerators": {
                "this": 0,
                "CBSA": 2254,
                "state": 149063
             },
             "numerator_errors": {
                "this": 216,
                "CBSA": 997,
                "state": 5527
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 43.3,
                "state": 2.6
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 12.88,
                "CBSA": 15.83,
                "state": 14.65
             },
             "error": {
                "this": 3.4,
                "CBSA": 0.47,
                "state": 0.05
             },
             "numerators": {
                "this": 12202,
                "CBSA": 120669,
                "state": 5786711
             },
             "numerator_errors": {
                "this": 3222,
                "CBSA": 3585,
                "state": 19627
             },
             "index": {
                "this": 100,
                "CBSA": 81,
                "state": 88
             },
             "error_ratio": {
                "this": 26.4,
                "CBSA": 3,
                "state": 0.3
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.98,
                "CBSA": 0.65,
                "state": 0.36
             },
             "error": {
                "this": 0.94,
                "CBSA": 0.06,
                "state": 0.01
             },
             "numerators": {
                "this": 930,
                "CBSA": 4944,
                "state": 141846
             },
             "numerator_errors": {
                "this": 889,
                "CBSA": 433,
                "state": 4748
             },
             "index": {
                "this": 100,
                "CBSA": 151,
                "state": 272
             },
             "error_ratio": {
                "this": 95.9,
                "CBSA": 9.2,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0,
                "CBSA": 0.11,
                "state": 0.27
             },
             "error": {
                "this": 0,
                "CBSA": 0.08,
                "state": 0.03
             },
             "numerators": {
                "this": 0,
                "CBSA": 842,
                "state": 107538
             },
             "numerator_errors": {
                "this": 216,
                "CBSA": 611,
                "state": 10686
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 72.7,
                "state": 11.1
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 5.77,
                "CBSA": 4.09,
                "state": 3.1
             },
             "error": {
                "this": 2.72,
                "CBSA": 0.63,
                "state": 0.07
             },
             "numerators": {
                "this": 5463,
                "CBSA": 31154,
                "state": 1224113
             },
             "numerator_errors": {
                "this": 2573,
                "CBSA": 4823,
                "state": 26997
             },
             "index": {
                "this": 100,
                "CBSA": 141,
                "state": 186
             },
             "error_ratio": {
                "this": 47.1,
                "CBSA": 15.4,
                "state": 2.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 44.26,
                "CBSA": 42.03,
                "state": 39.42
             },
             "error": {
                "this": 5.6,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 41934,
                "CBSA": 320334,
                "state": 1.5574882E7
             },
             "numerator_errors": {
                "this": 5302,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 112
             },
             "error_ratio": {
                "this": 12.7,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 33227,
                "CBSA": 28919,
                "state": 39393
             },
             "error": {
                "this": 2736,
                "CBSA": 1020,
                "state": 177
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 84
             },
             "error_ratio": {
                "this": 8.2,
                "CBSA": 3.5,
                "state": 0.4
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 97292,
                "CBSA": 68997,
                "state": 80440
             },
             "error": {
                "this": 7139,
                "CBSA": 2715,
                "state": 313
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 141,
                "state": 121
             },
             "error_ratio": {
                "this": 7.3,
                "CBSA": 3.9,
                "state": 0.4
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 20.01,
                   "CBSA": 35.22,
                   "state": 31.68
                },
                "error": {
                   "this": 4.68,
                   "CBSA": 2.15,
                   "state": 0.26
                },
                "numerators": {
                   "this": 5356,
                   "CBSA": 81080,
                   "state": 4167825
                },
                "numerator_errors": {
                   "this": 1285.2,
                   "CBSA": 5057.2,
                   "state": 34680.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 63
                },
                "error_ratio": {
                   "this": 23.4,
                   "CBSA": 6.1,
                   "state": 0.8
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 31.2,
                   "CBSA": 31.14,
                   "state": 27.83
                },
                "error": {
                   "this": 5.32,
                   "CBSA": 1.92,
                   "state": 0.24
                },
                "numerators": {
                   "this": 8352,
                   "CBSA": 71694,
                   "state": 3661397
                },
                "numerator_errors": {
                   "this": 1495.1,
                   "CBSA": 4495.8,
                   "state": 32290.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 112
                },
                "error_ratio": {
                   "this": 17.1,
                   "CBSA": 6.2,
                   "state": 0.9
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 38.34,
                   "CBSA": 25.88,
                   "state": 26.8
                },
                "error": {
                   "this": 5.87,
                   "CBSA": 1.51,
                   "state": 0.24
                },
                "numerators": {
                   "this": 10263,
                   "CBSA": 59579,
                   "state": 3525910
                },
                "numerator_errors": {
                   "this": 1667.1,
                   "CBSA": 3551.1,
                   "state": 31856.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 148,
                   "state": 143
                },
                "error_ratio": {
                   "this": 15.3,
                   "CBSA": 5.8,
                   "state": 0.9
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 10.46,
                   "CBSA": 7.77,
                   "state": 13.7
                },
                "error": {
                   "this": 3.27,
                   "CBSA": 0.99,
                   "state": 0.14
                },
                "numerators": {
                   "this": 2800,
                   "CBSA": 17888,
                   "state": 1802741
                },
                "numerator_errors": {
                   "this": 889,
                   "CBSA": 2292,
                   "state": 18330
                },
                "index": {
                   "this": 100,
                   "CBSA": 135,
                   "state": 76
                },
                "error_ratio": {
                   "this": 31.3,
                   "CBSA": 12.7,
                   "state": 1
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 9.16,
                "CBSA": 13.69,
                "state": 11.75
             },
             "error": {
                "this": 3.65,
                "CBSA": 1.4,
                "state": 0.18
             },
             "numerators": {
                "this": 8539,
                "CBSA": 101591,
                "state": 4552837
             },
             "numerator_errors": {
                "this": 3406,
                "CBSA": 10364,
                "state": 67995
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 67,
                "state": 78
             },
             "error_ratio": {
                "this": 39.8,
                "CBSA": 10.2,
                "state": 1.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 9.66,
                   "CBSA": 18.66,
                   "state": 15.62
                },
                "error": {
                   "this": 5.14,
                   "CBSA": 1.81,
                   "state": 0.27
                },
                "numerators": {
                   "this": 2117,
                   "CBSA": 37278,
                   "state": 1363574
                },
                "numerator_errors": {
                   "this": 1157,
                   "CBSA": 3838.2,
                   "state": 24624.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 52,
                   "state": 62
                },
                "error_ratio": {
                   "this": 53.2,
                   "CBSA": 9.7,
                   "state": 1.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 90.34,
                   "CBSA": 81.34,
                   "state": 84.38
                },
                "error": {
                   "this": 0.67,
                   "CBSA": 0.49,
                   "state": 0.61
                },
                "numerators": {
                   "this": 19805,
                   "CBSA": 162484,
                   "state": 7365259
                },
                "numerator_errors": {
                   "this": 2461.9,
                   "CBSA": 5624.2,
                   "state": 37404.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 107
                },
                "error_ratio": {
                   "this": 0.7,
                   "CBSA": 0.6,
                   "state": 0.7
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 2.41,
                   "CBSA": 11.03,
                   "state": 10.45
                },
                "error": {
                   "this": 3.41,
                   "CBSA": 1.74,
                   "state": 0.23
                },
                "numerators": {
                   "this": 199,
                   "CBSA": 10536,
                   "state": 599877
                },
                "numerator_errors": {
                   "this": 283.8,
                   "CBSA": 1680.9,
                   "state": 13070.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 22,
                   "state": 23
                },
                "error_ratio": {
                   "this": 141.5,
                   "CBSA": 15.8,
                   "state": 2.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 97.59,
                   "CBSA": 88.97,
                   "state": 89.55
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 3.02,
                   "state": 0.39
                },
                "numerators": {
                   "this": 8057,
                   "CBSA": 84973,
                   "state": 5138754
                },
                "numerator_errors": {
                   "this": 1366.3,
                   "CBSA": 1846.3,
                   "state": 14255.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 109
                },
                "error_ratio": {
                   "this": 1.4,
                   "CBSA": 3.4,
                   "state": 0.4
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 44.92,
                "CBSA": 34.23,
                "state": 29.79
             },
             "error": {
                "this": 1.24,
                "CBSA": 0.51,
                "state": 0.07
             },
             "numerators": {
                "this": 1839420,
                "CBSA": 9950605,
                "state": 5.099704E8
             },
             "numerator_errors": {
                "this": 72550,
                "CBSA": 175174,
                "state": 1354876
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 131,
                "state": 151
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 1.5,
                "state": 0.2
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 76.82,
                   "CBSA": 78.75,
                   "state": 73.72
                },
                "error": {
                   "this": 1.85,
                   "CBSA": 0.65,
                   "state": 0.04
                },
                "numerators": {
                   "this": 32647,
                   "CBSA": 238729,
                   "state": 1.3411041E7
                },
                "numerator_errors": {
                   "this": 1167,
                   "CBSA": 2835,
                   "state": 17348
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 104
                },
                "error_ratio": {
                   "this": 2.4,
                   "CBSA": 0.8,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 15.17,
                   "CBSA": 12.89,
                   "state": 10.12
                },
                "error": {
                   "this": 1.77,
                   "CBSA": 0.62,
                   "state": 0.09
                },
                "numerators": {
                   "this": 6445,
                   "CBSA": 39088,
                   "state": 1841632
                },
                "numerator_errors": {
                   "this": 772,
                   "CBSA": 1917,
                   "state": 16674
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 150
                },
                "error_ratio": {
                   "this": 11.7,
                   "CBSA": 4.8,
                   "state": 0.9
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 2.37,
                   "CBSA": 1.67,
                   "state": 5.08
                },
                "error": {
                   "this": 0.54,
                   "CBSA": 0.19,
                   "state": 0.04
                },
                "numerators": {
                   "this": 1006,
                   "CBSA": 5055,
                   "state": 923834
                },
                "numerator_errors": {
                   "this": 233,
                   "CBSA": 567,
                   "state": 7563
                },
                "index": {
                   "this": 100,
                   "CBSA": 142,
                   "state": 47
                },
                "error_ratio": {
                   "this": 22.8,
                   "CBSA": 11.4,
                   "state": 0.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.47,
                   "CBSA": 0.41,
                   "state": 0.95
                },
                "error": {
                   "this": 0.38,
                   "CBSA": 0.1,
                   "state": 0.02
                },
                "numerators": {
                   "this": 199,
                   "CBSA": 1228,
                   "state": 173081
                },
                "numerator_errors": {
                   "this": 162,
                   "CBSA": 290,
                   "state": 3177
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 49
                },
                "error_ratio": {
                   "this": 80.9,
                   "CBSA": 24.4,
                   "state": 2.1
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 0.88,
                   "CBSA": 1.37,
                   "state": 2.62
                },
                "error": {
                   "this": 0.36,
                   "CBSA": 0.17,
                   "state": 0.03
                },
                "numerators": {
                   "this": 372,
                   "CBSA": 4164,
                   "state": 476291
                },
                "numerator_errors": {
                   "this": 154,
                   "CBSA": 516,
                   "state": 5234
                },
                "index": {
                   "this": 100,
                   "CBSA": 64,
                   "state": 34
                },
                "error_ratio": {
                   "this": 40.9,
                   "CBSA": 12.4,
                   "state": 1.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.66,
                   "CBSA": 0.81,
                   "state": 1.6
                },
                "error": {
                   "this": 0.26,
                   "CBSA": 0.13,
                   "state": 0.02
                },
                "numerators": {
                   "this": 280,
                   "CBSA": 2455,
                   "state": 291710
                },
                "numerator_errors": {
                   "this": 109,
                   "CBSA": 381,
                   "state": 4364
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 41
                },
                "error_ratio": {
                   "this": 39.4,
                   "CBSA": 16,
                   "state": 1.2
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 3.65,
                   "CBSA": 4.1,
                   "state": 5.9
                },
                "error": {
                   "this": 0.65,
                   "CBSA": 0.3,
                   "state": 0.05
                },
                "numerators": {
                   "this": 1550,
                   "CBSA": 12428,
                   "state": 1073966
                },
                "numerator_errors": {
                   "this": 278,
                   "CBSA": 904,
                   "state": 8330
                },
                "index": {
                   "this": 100,
                   "CBSA": 89,
                   "state": 62
                },
                "error_ratio": {
                   "this": 17.8,
                   "CBSA": 7.3,
                   "state": 0.8
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 50.38,
                "CBSA": 49.36,
                "state": 48.44
             },
             "error": {
                "this": 2.29,
                "CBSA": 1.1,
                "state": 0.14
             },
             "numerators": {
                "this": 38187,
                "CBSA": 292907,
                "state": 1.5561514E7
             },
             "numerator_errors": {
                "this": 2198.5,
                "CBSA": 6520.1,
                "state": 46249.3
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 104
             },
             "error_ratio": {
                "this": 4.5,
                "CBSA": 2.2,
                "state": 0.3
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 49.62,
                "CBSA": 50.64,
                "state": 51.56
             },
             "error": {
                "this": 4.49,
                "CBSA": 1.2,
                "state": 0.16
             },
             "numerators": {
                "this": 37612,
                "CBSA": 300555,
                "state": 1.6562598E7
             },
             "numerator_errors": {
                "this": 3651.2,
                "CBSA": 7141.7,
                "state": 51752.6
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 96
             },
             "error_ratio": {
                "this": 9,
                "CBSA": 2.4,
                "state": 0.3
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 39.2,
                   "CBSA": 40.37,
                   "state": 40.83
                },
                "error": {
                   "this": 5.54,
                   "CBSA": 1.47,
                   "state": 0.17
                },
                "numerators": {
                   "this": 13764,
                   "CBSA": 118762,
                   "state": 6478460
                },
                "numerator_errors": {
                   "this": 2147,
                   "CBSA": 4330,
                   "state": 27866
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 96
                },
                "error_ratio": {
                   "this": 14.1,
                   "CBSA": 3.6,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 36.57,
                   "CBSA": 33.7,
                   "state": 33.96
                },
                "error": {
                   "this": 5.72,
                   "CBSA": 1.26,
                   "state": 0.15
                },
                "numerators": {
                   "this": 14880,
                   "CBSA": 100860,
                   "state": 5521285
                },
                "numerator_errors": {
                   "this": 2471,
                   "CBSA": 3779,
                   "state": 24532
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 108
                },
                "error_ratio": {
                   "this": 15.6,
                   "CBSA": 3.7,
                   "state": 0.4
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 53.64,
                   "CBSA": 49.81,
                   "state": 49.37
                },
                "error": {
                   "this": 2.45,
                   "CBSA": 1.61,
                   "state": 0.2
                },
                "numerators": {
                   "this": 18835,
                   "CBSA": 146540,
                   "state": 7832740
                },
                "numerator_errors": {
                   "this": 1513,
                   "CBSA": 4758,
                   "state": 32087
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 109
                },
                "error_ratio": {
                   "this": 4.6,
                   "CBSA": 3.2,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 47.56,
                   "CBSA": 48.91,
                   "state": 47.54
                },
                "error": {
                   "this": 2.88,
                   "CBSA": 1.49,
                   "state": 0.2
                },
                "numerators": {
                   "this": 19352,
                   "CBSA": 146367,
                   "state": 7728774
                },
                "numerator_errors": {
                   "this": 1595,
                   "CBSA": 4458,
                   "state": 33308
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 100
                },
                "error_ratio": {
                   "this": 6.1,
                   "CBSA": 3,
                   "state": 0.4
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 5.39,
                   "CBSA": 7.54,
                   "state": 7.69
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.79,
                   "state": 0.11
                },
                "numerators": {
                   "this": 1891,
                   "CBSA": 22174,
                   "state": 1219434
                },
                "numerator_errors": {
                   "this": 784,
                   "CBSA": 2312,
                   "state": 17626
                },
                "index": {
                   "this": 100,
                   "CBSA": 71,
                   "state": 70
                },
                "error_ratio": {
                   "this": 40.8,
                   "CBSA": 10.5,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 9.51,
                   "CBSA": 8.97,
                   "state": 10.83
                },
                "error": {
                   "this": 2.79,
                   "CBSA": 0.82,
                   "state": 0.16
                },
                "numerators": {
                   "this": 3869,
                   "CBSA": 26845,
                   "state": 1760791
                },
                "numerator_errors": {
                   "this": 1155,
                   "CBSA": 2451,
                   "state": 25259
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 88
                },
                "error_ratio": {
                   "this": 29.3,
                   "CBSA": 9.1,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.77,
                   "CBSA": 2.29,
                   "state": 2.11
                },
                "error": {
                   "this": 1.16,
                   "CBSA": 0.36,
                   "state": 0.05
                },
                "numerators": {
                   "this": 621,
                   "CBSA": 6739,
                   "state": 334453
                },
                "numerator_errors": {
                   "this": 409,
                   "CBSA": 1064,
                   "state": 8393
                },
                "index": {
                   "this": 100,
                   "CBSA": 77,
                   "state": 84
                },
                "error_ratio": {
                   "this": 65.5,
                   "CBSA": 15.7,
                   "state": 2.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 6.36,
                   "CBSA": 8.41,
                   "state": 7.68
                },
                "error": {
                   "this": 1.7,
                   "CBSA": 0.78,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2587,
                   "CBSA": 25175,
                   "state": 1248175
                },
                "numerator_errors": {
                   "this": 707,
                   "CBSA": 2343,
                   "state": 16759
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 83
                },
                "error_ratio": {
                   "this": 26.7,
                   "CBSA": 9.3,
                   "state": 1.3
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 84.31,
                   "CBSA": 71.23,
                   "state": 72.24
                },
                "error": {
                   "this": 9.5,
                   "CBSA": 3.21,
                   "state": 0.46
                },
                "numerators": {
                   "this": 16770,
                   "CBSA": 124424,
                   "state": 5449645
                },
                "numerator_errors": {
                   "this": 3142,
                   "CBSA": 6236,
                   "state": 40502
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 117
                },
                "error_ratio": {
                   "this": 11.3,
                   "CBSA": 4.5,
                   "state": 0.6
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 4.54,
                   "CBSA": 7.97,
                   "state": 7.66
                },
                "error": {
                   "this": 2.93,
                   "CBSA": 1.97,
                   "state": 0.26
                },
                "numerators": {
                   "this": 903,
                   "CBSA": 13929,
                   "state": 578128
                },
                "numerator_errors": {
                   "this": 598,
                   "CBSA": 3460,
                   "state": 19525
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 59
                },
                "error_ratio": {
                   "this": 64.5,
                   "CBSA": 24.7,
                   "state": 3.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 11.16,
                   "CBSA": 20.79,
                   "state": 20.1
                },
                "error": {
                   "this": 4.92,
                   "CBSA": 2.52,
                   "state": 0.43
                },
                "numerators": {
                   "this": 2219,
                   "CBSA": 36317,
                   "state": 1516238
                },
                "numerator_errors": {
                   "this": 1034,
                   "CBSA": 4467,
                   "state": 32886
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 56
                },
                "error_ratio": {
                   "this": 44.1,
                   "CBSA": 12.1,
                   "state": 2.1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 6.14,
                "CBSA": 6.21,
                "state": 4.69
             },
             "error": {
                "this": 2.79,
                "CBSA": 1.06,
                "state": 0.11
             },
             "numerators": {
                "this": 1681,
                "CBSA": 11345,
                "state": 450971
             },
             "numerator_errors": {
                "this": 778,
                "CBSA": 1944,
                "state": 10955
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 131
             },
             "error_ratio": {
                "this": 45.4,
                "CBSA": 17.1,
                "state": 2.3
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 1.96,
                   "state": 0.87
                },
                "error": {
                   "this": 0,
                   "CBSA": 1.91,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 529,
                   "state": 10882
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 518,
                   "state": 2046
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 97.4,
                   "state": 18.4
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 11.62,
                   "CBSA": 5.75,
                   "state": 4.44
                },
                "error": {
                   "this": 18.43,
                   "CBSA": 3.46,
                   "state": 0.41
                },
                "numerators": {
                   "this": 368,
                   "CBSA": 1433,
                   "state": 56709
                },
                "numerator_errors": {
                   "this": 596,
                   "CBSA": 866,
                   "state": 5217
                },
                "index": {
                   "this": 100,
                   "CBSA": 202,
                   "state": 262
                },
                "error_ratio": {
                   "this": 158.6,
                   "CBSA": 60.2,
                   "state": 9.2
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 3.88,
                   "CBSA": 12.43,
                   "state": 6.85
                },
                "error": {
                   "this": 4.47,
                   "CBSA": 3.37,
                   "state": 0.37
                },
                "numerators": {
                   "this": 214,
                   "CBSA": 3332,
                   "state": 101991
                },
                "numerator_errors": {
                   "this": 253,
                   "CBSA": 919,
                   "state": 5591
                },
                "index": {
                   "this": 100,
                   "CBSA": 31,
                   "state": 57
                },
                "error_ratio": {
                   "this": 115.2,
                   "CBSA": 27.1,
                   "state": 5.4
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 10.89,
                   "CBSA": 12.42,
                   "state": 9.73
                },
                "error": {
                   "this": 8.07,
                   "CBSA": 3.28,
                   "state": 0.51
                },
                "numerators": {
                   "this": 349,
                   "CBSA": 3184,
                   "state": 139246
                },
                "numerator_errors": {
                   "this": 276,
                   "CBSA": 855,
                   "state": 7329
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 112
                },
                "error_ratio": {
                   "this": 74.1,
                   "CBSA": 26.4,
                   "state": 5.2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 15.63,
                   "CBSA": 6.07,
                   "state": 6.95
                },
                "error": {
                   "this": 11.86,
                   "CBSA": 2.6,
                   "state": 0.33
                },
                "numerators": {
                   "this": 544,
                   "CBSA": 1432,
                   "state": 93949
                },
                "numerator_errors": {
                   "this": 438,
                   "CBSA": 628,
                   "state": 4611
                },
                "index": {
                   "this": 100,
                   "CBSA": 257,
                   "state": 225
                },
                "error_ratio": {
                   "this": 75.9,
                   "CBSA": 42.8,
                   "state": 4.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 3.99,
                   "CBSA": 2.34,
                   "state": 2.64
                },
                "error": {
                   "this": 5.42,
                   "CBSA": 1.73,
                   "state": 0.21
                },
                "numerators": {
                   "this": 130,
                   "CBSA": 617,
                   "state": 33377
                },
                "numerator_errors": {
                   "this": 180,
                   "CBSA": 458,
                   "state": 2743
                },
                "index": {
                   "this": 100,
                   "CBSA": 171,
                   "state": 151
                },
                "error_ratio": {
                   "this": 135.8,
                   "CBSA": 73.9,
                   "state": 8
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 1.72,
                   "CBSA": 2.9,
                   "state": 0.96
                },
                "error": {
                   "this": 2.88,
                   "CBSA": 1.69,
                   "state": 0.14
                },
                "numerators": {
                   "this": 76,
                   "CBSA": 818,
                   "state": 14817
                },
                "numerator_errors": {
                   "this": 128,
                   "CBSA": 478,
                   "state": 2211
                },
                "index": {
                   "this": 100,
                   "CBSA": 59,
                   "state": 179
                },
                "error_ratio": {
                   "this": 167.4,
                   "CBSA": 58.3,
                   "state": 14.6
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 26771,
                "CBSA": 230241,
                "state": 1.3157873E7
             },
             "error": {
                "this": 1446,
                "CBSA": 2816,
                "state": 23844
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 12,
                "state": null
             },
             "error_ratio": {
                "this": 5.4,
                "CBSA": 1.2,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 3.53,
                "CBSA": 3.22,
                "state": 2.94
             },
             "error": {
                "this": 0.19,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 94488,
                "CBSA": 741237,
                "state": 3.8685704E7
             },
             "numerator_errors": {
                "this": 131,
                "CBSA": 2835,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 110,
                "state": 120
             },
             "error_ratio": {
                "this": 5.4,
                "CBSA": 1.2,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 66.87,
                   "CBSA": 63.99,
                   "state": 60.69
                },
                "error": {
                   "this": 5.31,
                   "CBSA": 1.99,
                   "state": 0.22
                },
                "numerators": {
                   "this": 63188,
                   "CBSA": 474333,
                   "state": 2.3477092E7
                },
                "numerator_errors": {
                   "this": 5020,
                   "CBSA": 14829,
                   "state": 84642
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 110
                },
                "error_ratio": {
                   "this": 7.9,
                   "CBSA": 3.1,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 6.55,
                   "CBSA": 7.73,
                   "state": 7.74
                },
                "error": {
                   "this": 2.81,
                   "CBSA": 1.15,
                   "state": 0.16
                },
                "numerators": {
                   "this": 6191,
                   "CBSA": 57300,
                   "state": 2996042
                },
                "numerator_errors": {
                   "this": 2651,
                   "CBSA": 8541,
                   "state": 63406
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 85
                },
                "error_ratio": {
                   "this": 42.9,
                   "CBSA": 14.9,
                   "state": 2.1
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 16.95,
                   "CBSA": 17.9,
                   "state": 16.47
                },
                "error": {
                   "this": 5.1,
                   "CBSA": 1.73,
                   "state": 0.23
                },
                "numerators": {
                   "this": 16014,
                   "CBSA": 132674,
                   "state": 6371949
                },
                "numerator_errors": {
                   "this": 4818,
                   "CBSA": 12844,
                   "state": 87738
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 103
                },
                "error_ratio": {
                   "this": 30.1,
                   "CBSA": 9.7,
                   "state": 1.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 9.63,
                   "CBSA": 10.38,
                   "state": 15.1
                },
                "error": {
                   "this": 3.02,
                   "CBSA": 0.86,
                   "state": 0.1
                },
                "numerators": {
                   "this": 9095,
                   "CBSA": 76930,
                   "state": 5840619
                },
                "numerator_errors": {
                   "this": 2858,
                   "CBSA": 6356,
                   "state": 37146
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 64
                },
                "error_ratio": {
                   "this": 31.4,
                   "CBSA": 8.3,
                   "state": 0.7
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 28246,
                "CBSA": 248673,
                "state": 1.4367012E7
             },
             "error": {
                "this": 1644,
                "CBSA": 895,
                "state": 1286
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 11,
                "state": null
             },
             "error_ratio": {
                "this": 5.8,
                "CBSA": 0.4,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 94.78,
                   "CBSA": 92.59,
                   "state": 91.58
                },
                "error": {
                   "this": 7.53,
                   "CBSA": 1.08,
                   "state": 0.17
                },
                "numerators": {
                   "this": 26771,
                   "CBSA": 230241,
                   "state": 1.3157873E7
                },
                "numerator_errors": {
                   "this": 1446,
                   "CBSA": 2816,
                   "state": 23844
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 103
                },
                "error_ratio": {
                   "this": 7.9,
                   "CBSA": 1.2,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 5.22,
                   "CBSA": 7.41,
                   "state": 8.42
                },
                "error": {
                   "this": 2.99,
                   "CBSA": 1.05,
                   "state": 0.17
                },
                "numerators": {
                   "this": 1475,
                   "CBSA": 18432,
                   "state": 1209139
                },
                "numerator_errors": {
                   "this": 849,
                   "CBSA": 2610,
                   "state": 24297
                },
                "index": {
                   "this": 100,
                   "CBSA": 70,
                   "state": 62
                },
                "error_ratio": {
                   "this": 57.3,
                   "CBSA": 14.2,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 86.41,
                   "CBSA": 77.24,
                   "state": 63.96
                },
                "error": {
                   "this": 4.5,
                   "CBSA": 1.56,
                   "state": 0.22
                },
                "numerators": {
                   "this": 24407,
                   "CBSA": 192067,
                   "state": 9189272
                },
                "numerator_errors": {
                   "this": 1906.6,
                   "CBSA": 3948.4,
                   "state": 31423.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 135
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 2,
                   "state": 0.3
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 13.05,
                   "CBSA": 19.01,
                   "state": 32.18
                },
                "error": {
                   "this": 3.99,
                   "CBSA": 1.54,
                   "state": 0.27
                },
                "numerators": {
                   "this": 3685,
                   "CBSA": 47279,
                   "state": 4622614
                },
                "numerator_errors": {
                   "this": 1146.1,
                   "CBSA": 3827.5,
                   "state": 38673.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 69,
                   "state": 41
                },
                "error_ratio": {
                   "this": 30.6,
                   "CBSA": 8.1,
                   "state": 0.8
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.55,
                   "CBSA": 3.64,
                   "state": 3.74
                },
                "error": {
                   "this": 0.69,
                   "CBSA": 0.61,
                   "state": 0.08
                },
                "numerators": {
                   "this": 154,
                   "CBSA": 9046,
                   "state": 537931
                },
                "numerator_errors": {
                   "this": 195,
                   "CBSA": 1529,
                   "state": 11591
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 15
                },
                "error_ratio": {
                   "this": 125.5,
                   "CBSA": 16.8,
                   "state": 2.1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.12
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.09,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 281,
                   "state": 17195
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 232,
                   "state": 2102
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 81.8,
                   "state": 8.3
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 61.68,
                   "CBSA": 58.39,
                   "state": 54.86
                },
                "error": {
                   "this": 5.3,
                   "CBSA": 1.72,
                   "state": 0.22
                },
                "numerators": {
                   "this": 16512,
                   "CBSA": 134428,
                   "state": 7218742
                },
                "numerator_errors": {
                   "this": 1675,
                   "CBSA": 4292,
                   "state": 32100
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 112
                },
                "error_ratio": {
                   "this": 8.6,
                   "CBSA": 2.9,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 38.32,
                   "CBSA": 41.61,
                   "state": 45.14
                },
                "error": {
                   "this": 5.94,
                   "CBSA": 1.9,
                   "state": 0.24
                },
                "numerators": {
                   "this": 10259,
                   "CBSA": 95813,
                   "state": 5939131
                },
                "numerator_errors": {
                   "this": 1685,
                   "CBSA": 4529,
                   "state": 32807
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 85
                },
                "error_ratio": {
                   "this": 15.5,
                   "CBSA": 4.6,
                   "state": 0.5
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 487200,
                "CBSA": 385600,
                "state": 568500
             },
             "error": {
                "this": 20451,
                "CBSA": 7040,
                "state": 2502
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 126,
                "state": 86
             },
             "error_ratio": {
                "this": 4.2,
                "CBSA": 1.8,
                "state": 0.4
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 2.85,
                   "CBSA": 6.5,
                   "state": 5.03
                },
                "error": {
                   "this": 0.97,
                   "CBSA": 0.45,
                   "state": 0.05
                },
                "numerators": {
                   "this": 462,
                   "CBSA": 8411,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 158.3,
                   "CBSA": 589.7,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 44,
                   "state": 57
                },
                "error_ratio": {
                   "this": 34,
                   "CBSA": 6.9,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 1.86,
                   "CBSA": 11.62,
                   "state": 6.63
                },
                "error": {
                   "this": 0.68,
                   "CBSA": 0.52,
                   "state": 0.06
                },
                "numerators": {
                   "this": 302,
                   "CBSA": 15039,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 110.9,
                   "CBSA": 706.1,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 16,
                   "state": 28
                },
                "error_ratio": {
                   "this": 36.6,
                   "CBSA": 4.5,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 6.35,
                   "CBSA": 21.64,
                   "state": 11.06
                },
                "error": {
                   "this": 1.28,
                   "CBSA": 0.74,
                   "state": 0.06
                },
                "numerators": {
                   "this": 1030,
                   "CBSA": 28001,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 211,
                   "CBSA": 1034.6,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 29,
                   "state": 57
                },
                "error_ratio": {
                   "this": 20.2,
                   "CBSA": 3.4,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 22.49,
                   "CBSA": 24.33,
                   "state": 13.6
                },
                "error": {
                   "this": 2.47,
                   "CBSA": 0.7,
                   "state": 0.09
                },
                "numerators": {
                   "this": 3649,
                   "CBSA": 31487,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 421,
                   "CBSA": 1012,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 165
                },
                "error_ratio": {
                   "this": 11,
                   "CBSA": 2.9,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 35.63,
                   "CBSA": 16.98,
                   "state": 13.21
                },
                "error": {
                   "this": 2.73,
                   "CBSA": 0.76,
                   "state": 0.08
                },
                "numerators": {
                   "this": 5781,
                   "CBSA": 21981,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 489,
                   "CBSA": 1028,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 210,
                   "state": 270
                },
                "error_ratio": {
                   "this": 7.7,
                   "CBSA": 4.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 30.23,
                   "CBSA": 17.18,
                   "state": 35.28
                },
                "error": {
                   "this": 2.49,
                   "CBSA": 0.69,
                   "state": 0.26
                },
                "numerators": {
                   "this": 4905,
                   "CBSA": 22232,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 440.6,
                   "CBSA": 945.3,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 176,
                   "state": 86
                },
                "error_ratio": {
                   "this": 8.2,
                   "CBSA": 4,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 0.22,
                   "CBSA": 0.96,
                   "state": 7.93
                },
                "error": {
                   "this": 0.32,
                   "CBSA": 0.16,
                   "state": 0.04
                },
                "numerators": {
                   "this": 35,
                   "CBSA": 1245,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 52,
                   "CBSA": 213,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 23,
                   "state": 3
                },
                "error_ratio": {
                   "this": 145.5,
                   "CBSA": 16.7,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 16226,
                "CBSA": 129419,
                "state": 7154580
             },
             "error": {
                "this": 584,
                "CBSA": 1813,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 13,
                "state": null
             },
             "error_ratio": {
                "this": 3.6,
                "CBSA": 1.4,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 4.09,
                "CBSA": 6.62,
                "state": 8.86
             },
             "error": {
                "this": 1.87,
                "CBSA": 0.79,
                "state": 0.13
             },
             "numerators": {
                "this": 3862,
                "CBSA": 49048,
                "state": 3427072
             },
             "numerator_errors": {
                "this": 1765,
                "CBSA": 5824.5,
                "state": 50836.5
             },
             "index": {
                "this": 100,
                "CBSA": 62,
                "state": 46
             },
             "error_ratio": {
                "this": 45.7,
                "CBSA": 11.9,
                "state": 1.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 8.05,
                "CBSA": 8.1,
                "state": 11.17
             },
             "error": {
                "this": 3.94,
                "CBSA": 1.13,
                "state": 0.16
             },
             "numerators": {
                "this": 7609,
                "CBSA": 60032,
                "state": 4320604
             },
             "numerator_errors": {
                "this": 3719.6,
                "CBSA": 8343.5,
                "state": 63360.7
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 72
             },
             "error_ratio": {
                "this": 48.9,
                "CBSA": 14,
                "state": 1.4
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 22.79,
                "CBSA": 21.21,
                "state": 21.9
             },
             "error": {
                "this": 4.79,
                "CBSA": 1.77,
                "state": 0.25
             },
             "numerators": {
                "this": 21533,
                "CBSA": 157209,
                "state": 8473932
             },
             "numerator_errors": {
                "this": 4530.5,
                "CBSA": 13156.7,
                "state": 98624.3
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 104
             },
             "error_ratio": {
                "this": 21,
                "CBSA": 8.3,
                "state": 1.1
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 24.48,
                "CBSA": 24.17,
                "state": 20.05
             },
             "error": {
                "this": 6.06,
                "CBSA": 2.12,
                "state": 0.24
             },
             "numerators": {
                "this": 23135,
                "CBSA": 179192,
                "state": 7754580
             },
             "numerator_errors": {
                "this": 5728.5,
                "CBSA": 15718,
                "state": 91602.6
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 122
             },
             "error_ratio": {
                "this": 24.8,
                "CBSA": 8.8,
                "state": 1.2
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 14.11,
                "CBSA": 16.09,
                "state": 13.11
             },
             "error": {
                "this": 4.23,
                "CBSA": 2.07,
                "state": 0.21
             },
             "numerators": {
                "this": 13335,
                "CBSA": 119285,
                "state": 5072685
             },
             "numerator_errors": {
                "this": 3994.8,
                "CBSA": 15372.5,
                "state": 79541.6
             },
             "index": {
                "this": 100,
                "CBSA": 88,
                "state": 108
             },
             "error_ratio": {
                "this": 30,
                "CBSA": 12.9,
                "state": 1.6
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 26.47,
                "CBSA": 23.81,
                "state": 24.91
             },
             "error": {
                "this": 7.08,
                "CBSA": 2.04,
                "state": 0.26
             },
             "numerators": {
                "this": 25014,
                "CBSA": 176471,
                "state": 9636829
             },
             "numerator_errors": {
                "this": 6692.6,
                "CBSA": 15149.3,
                "state": 101788.8
             },
             "index": {
                "this": 100,
                "CBSA": 111,
                "state": 106
             },
             "error_ratio": {
                "this": 26.7,
                "CBSA": 8.6,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 11.78,
                "CBSA": 9.39,
                "state": 12
             },
             "error": {
                "this": 3.83,
                "CBSA": 1.12,
                "state": 0.16
             },
             "numerators": {
                "this": 11012,
                "CBSA": 70752,
                "state": 4689828
             },
             "numerator_errors": {
                "this": 3579,
                "CBSA": 8431.8,
                "state": 63097.7
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 125,
                "state": 98
             },
             "error_ratio": {
                "this": 32.5,
                "CBSA": 11.9,
                "state": 1.3
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 88.22,
                "CBSA": 90.61,
                "state": 88
             },
             "error": {
                "this": 4.1,
                "CBSA": 1.07,
                "state": 0.17
             },
             "numerators": {
                "this": 82486,
                "CBSA": 682718,
                "state": 3.439422E7
             },
             "numerator_errors": {
                "this": 3880,
                "CBSA": 8211,
                "state": 68574
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 100
             },
             "error_ratio": {
                "this": 4.6,
                "CBSA": 1.2,
                "state": 0.2
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 8.69,
                "CBSA": 5.89,
                "state": 7.37
             },
             "error": {
                "this": 3.59,
                "CBSA": 1,
                "state": 0.13
             },
             "numerators": {
                "this": 8126,
                "CBSA": 44353,
                "state": 2881883
             },
             "numerator_errors": {
                "this": 3353,
                "CBSA": 7541,
                "state": 52031
             },
             "index": {
                "this": 100,
                "CBSA": 148,
                "state": 118
             },
             "error_ratio": {
                "this": 41.3,
                "CBSA": 17,
                "state": 1.8
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 2.28,
                "CBSA": 2.58,
                "state": 2.72
             },
             "error": {
                "this": 1.18,
                "CBSA": 0.42,
                "state": 0.07
             },
             "numerators": {
                "this": 2135,
                "CBSA": 19415,
                "state": 1061256
             },
             "numerator_errors": {
                "this": 1106,
                "CBSA": 3150,
                "state": 27761
             },
             "index": {
                "this": 100,
                "CBSA": 88,
                "state": 84
             },
             "error_ratio": {
                "this": 51.8,
                "CBSA": 16.3,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 0,
                "CBSA": 0.69,
                "state": 1.23
             },
             "error": {
                "this": 0,
                "CBSA": 0.25,
                "state": 0.05
             },
             "numerators": {
                "this": 0,
                "CBSA": 5173,
                "state": 480204
             },
             "numerator_errors": {
                "this": 216,
                "CBSA": 1919,
                "state": 18764
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 36.2,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 0.8,
                "CBSA": 0.24,
                "state": 0.68
             },
             "error": {
                "this": 0.58,
                "CBSA": 0.1,
                "state": 0.03
             },
             "numerators": {
                "this": 751,
                "CBSA": 1811,
                "state": 266485
             },
             "numerator_errors": {
                "this": 545,
                "CBSA": 790,
                "state": 12302
             },
             "index": {
                "this": 100,
                "CBSA": 333,
                "state": 118
             },
             "error_ratio": {
                "this": 72.5,
                "CBSA": 41.7,
                "state": 4.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 82.91,
                "CBSA": 79.59,
                "state": 84.03
             },
             "error": {
                "this": 6.09,
                "CBSA": 2.09,
                "state": 0.29
             },
             "numerators": {
                "this": 49990,
                "CBSA": 385786,
                "state": 2.263636E7
             },
             "numerator_errors": {
                "this": 4329.8,
                "CBSA": 10156.6,
                "state": 78234.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 99
             },
             "error_ratio": {
                "this": 7.3,
                "CBSA": 2.6,
                "state": 0.3
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 20.28,
                "CBSA": 19.97,
                "state": 35
             },
             "error": {
                "this": 3.31,
                "CBSA": 1.12,
                "state": 0.17
             },
             "numerators": {
                "this": 12225,
                "CBSA": 96795,
                "state": 9428484
             },
             "numerator_errors": {
                "this": 2071.2,
                "CBSA": 5415.5,
                "state": 44753.9
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 58
             },
             "error_ratio": {
                "this": 16.3,
                "CBSA": 5.6,
                "state": 0.5
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 17.09,
                "CBSA": 20.41,
                "state": 15.97
             },
             "error": {
                "this": 3.01,
                "CBSA": 1.22,
                "state": 0.14
             },
             "numerators": {
                "this": 10305,
                "CBSA": 98926,
                "state": 4301513
             },
             "numerator_errors": {
                "this": 1874.6,
                "CBSA": 5938.2,
                "state": 37669.1
             },
             "index": {
                "this": 100,
                "CBSA": 84,
                "state": 107
             },
             "error_ratio": {
                "this": 17.6,
                "CBSA": 6,
                "state": 0.9
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 26.35,
                "CBSA": 29.12,
                "state": 20.59
             },
             "error": {
                "this": 4.16,
                "CBSA": 1.16,
                "state": 0.16
             },
             "numerators": {
                "this": 15888,
                "CBSA": 141126,
                "state": 5546711
             },
             "numerator_errors": {
                "this": 2613,
                "CBSA": 5648.1,
                "state": 42454
             },
             "index": {
                "this": 100,
                "CBSA": 90,
                "state": 128
             },
             "error_ratio": {
                "this": 15.8,
                "CBSA": 4,
                "state": 0.8
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 36.28,
                "CBSA": 30.51,
                "state": 28.44
             },
             "error": {
                "this": 4.27,
                "CBSA": 1.34,
                "state": 0.18
             },
             "numerators": {
                "this": 21877,
                "CBSA": 147865,
                "state": 7661164
             },
             "numerator_errors": {
                "this": 2762.1,
                "CBSA": 6475.1,
                "state": 48118.5
             },
             "index": {
                "this": 100,
                "CBSA": 119,
                "state": 128
             },
             "error_ratio": {
                "this": 11.8,
                "CBSA": 4.4,
                "state": 0.6
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 16.46,
                "CBSA": 14.08,
                "state": 21.86
             },
             "error": {
                "this": 3.1,
                "CBSA": 0.97,
                "state": 0.13
             },
             "numerators": {
                "this": 9925,
                "CBSA": 68256,
                "state": 5889724
             },
             "numerator_errors": {
                "this": 1923.7,
                "CBSA": 4696.4,
                "state": 34784.9
             },
             "index": {
                "this": 100,
                "CBSA": 117,
                "state": 75
             },
             "error_ratio": {
                "this": 18.8,
                "CBSA": 6.9,
                "state": 0.6
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 3.81,
                "CBSA": 5.89,
                "state": 13.14
             },
             "error": {
                "this": 1.26,
                "CBSA": 0.56,
                "state": 0.1
             },
             "numerators": {
                "this": 2300,
                "CBSA": 28539,
                "state": 3538760
             },
             "numerator_errors": {
                "this": 767.5,
                "CBSA": 2696.6,
                "state": 28159
             },
             "index": {
                "this": 100,
                "CBSA": 65,
                "state": 29
             },
             "error_ratio": {
                "this": 33.1,
                "CBSA": 9.5,
                "state": 0.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 24.75,
                "CBSA": 22.98,
                "state": 26.74
             },
             "error": {
                "this": 3.23,
                "CBSA": 0.94,
                "state": 0.14
             },
             "numerators": {
                "this": 23447,
                "CBSA": 175163,
                "state": 1.056422E7
             },
             "numerator_errors": {
                "this": 3062,
                "CBSA": 7182,
                "state": 55357
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 108,
                "state": 93
             },
             "error_ratio": {
                "this": 13.1,
                "CBSA": 4.1,
                "state": 0.5
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 4.62,
                   "CBSA": 3.29,
                   "state": 6.42
                },
                "error": {
                   "this": 1.61,
                   "CBSA": 0.4,
                   "state": 0.07
                },
                "numerators": {
                   "this": 1126,
                   "CBSA": 5693,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 400,
                   "CBSA": 700,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 140,
                   "state": 72
                },
                "error_ratio": {
                   "this": 34.8,
                   "CBSA": 12.2,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 48.44,
                   "CBSA": 41.42,
                   "state": 39.42
                },
                "error": {
                   "this": 3.12,
                   "CBSA": 0.56,
                   "state": 0.09
                },
                "numerators": {
                   "this": 11811,
                   "CBSA": 71699,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 1078,
                   "CBSA": 1715,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 123
                },
                "error_ratio": {
                   "this": 6.4,
                   "CBSA": 1.4,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 2.38,
                   "CBSA": 1.41,
                   "state": 1.84
                },
                "error": {
                   "this": 1.11,
                   "CBSA": 0.42,
                   "state": 0.05
                },
                "numerators": {
                   "this": 581,
                   "CBSA": 2432,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 274,
                   "CBSA": 737,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 169,
                   "state": 129
                },
                "error_ratio": {
                   "this": 46.6,
                   "CBSA": 29.8,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 1.84,
                   "CBSA": 1.47,
                   "state": 0.82
                },
                "error": {
                   "this": 1.07,
                   "CBSA": 0.29,
                   "state": 0.03
                },
                "numerators": {
                   "this": 449,
                   "CBSA": 2550,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 262,
                   "CBSA": 513,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 125,
                   "state": 224
                },
                "error_ratio": {
                   "this": 58.2,
                   "CBSA": 19.7,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 41.76,
                   "CBSA": 51.81,
                   "state": 50.28
                },
                "error": {
                   "this": 2.23,
                   "CBSA": 0.91,
                   "state": 0.15
                },
                "numerators": {
                   "this": 10183,
                   "CBSA": 89670,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 855,
                   "CBSA": 2364,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 83
                },
                "error_ratio": {
                   "this": 5.3,
                   "CBSA": 1.8,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 0.96,
                   "CBSA": 0.6,
                   "state": 1.23
                },
                "error": {
                   "this": 0.47,
                   "CBSA": 0.27,
                   "state": 0.03
                },
                "numerators": {
                   "this": 235,
                   "CBSA": 1047,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 115,
                   "CBSA": 473,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 160,
                   "state": 78
                },
                "error_ratio": {
                   "this": 49,
                   "CBSA": 45,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 57.69,
                   "CBSA": 60.75,
                   "state": 56.97
                },
                "error": {
                   "this": 9.39,
                   "CBSA": 2.77,
                   "state": 0.51
                },
                "numerators": {
                   "this": 10231,
                   "CBSA": 92236,
                   "state": 3710631
                },
                "numerator_errors": {
                   "this": 2029,
                   "CBSA": 4223,
                   "state": 33084
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 101
                },
                "error_ratio": {
                   "this": 16.3,
                   "CBSA": 4.6,
                   "state": 0.9
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 34.18,
                   "CBSA": 31.18,
                   "state": 33.31
                },
                "error": {
                   "this": 8.9,
                   "CBSA": 2.39,
                   "state": 0.42
                },
                "numerators": {
                   "this": 6062,
                   "CBSA": 47334,
                   "state": 2169717
                },
                "numerator_errors": {
                   "this": 1721,
                   "CBSA": 3630,
                   "state": 27349
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 103
                },
                "error_ratio": {
                   "this": 26,
                   "CBSA": 7.7,
                   "state": 1.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 7.88,
                   "CBSA": 3.68,
                   "state": 3.07
                },
                "error": {
                   "this": 5.32,
                   "CBSA": 1.33,
                   "state": 0.15
                },
                "numerators": {
                   "this": 1398,
                   "CBSA": 5581,
                   "state": 200078
                },
                "numerator_errors": {
                   "this": 957,
                   "CBSA": 2021,
                   "state": 9887
                },
                "index": {
                   "this": 100,
                   "CBSA": 214,
                   "state": 257
                },
                "error_ratio": {
                   "this": 67.5,
                   "CBSA": 36.1,
                   "state": 4.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 0.24,
                   "CBSA": 4.19,
                   "state": 5.66
                },
                "error": {
                   "this": 0.4,
                   "CBSA": 1.31,
                   "state": 0.17
                },
                "numerators": {
                   "this": 43,
                   "CBSA": 6367,
                   "state": 368935
                },
                "numerator_errors": {
                   "this": 71,
                   "CBSA": 1993,
                   "state": 11144
                },
                "index": {
                   "this": 100,
                   "CBSA": 6,
                   "state": 4
                },
                "error_ratio": {
                   "this": 166.7,
                   "CBSA": 31.3,
                   "state": 3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0,
                   "CBSA": 0.21,
                   "state": 0.98
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.2,
                   "state": 0.11
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 313,
                   "state": 63864
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 302,
                   "state": 6999
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 95.2,
                   "state": 11.2
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 54.4,
                   "CBSA": 58.91,
                   "state": 55.21
                },
                "error": {
                   "this": 4.52,
                   "CBSA": 1.41,
                   "state": 0.18
                },
                "numerators": {
                   "this": 38876,
                   "CBSA": 328923,
                   "state": 1.6905948E7
                },
                "numerator_errors": {
                   "this": 3693.2,
                   "CBSA": 7902.1,
                   "state": 55036
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 99
                },
                "error_ratio": {
                   "this": 8.3,
                   "CBSA": 2.4,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 27.63,
                   "CBSA": 25.5,
                   "state": 27.8
                },
                "error": {
                   "this": 4.81,
                   "CBSA": 1.08,
                   "state": 0.12
                },
                "numerators": {
                   "this": 19747,
                   "CBSA": 142397,
                   "state": 8513944
                },
                "numerator_errors": {
                   "this": 3554.3,
                   "CBSA": 6035.2,
                   "state": 37016.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 99
                },
                "error_ratio": {
                   "this": 17.4,
                   "CBSA": 4.2,
                   "state": 0.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 9.21,
                   "CBSA": 4.67,
                   "state": 4.88
                },
                "error": {
                   "this": 2.96,
                   "CBSA": 0.85,
                   "state": 0.09
                },
                "numerators": {
                   "this": 6584,
                   "CBSA": 26081,
                   "state": 1494004
                },
                "numerator_errors": {
                   "this": 2136.9,
                   "CBSA": 4724.5,
                   "state": 28577.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 197,
                   "state": 189
                },
                "error_ratio": {
                   "this": 32.1,
                   "CBSA": 18.2,
                   "state": 1.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 8.36,
                   "CBSA": 10.5,
                   "state": 10.99
                },
                "error": {
                   "this": 2.66,
                   "CBSA": 0.83,
                   "state": 0.08
                },
                "numerators": {
                   "this": 5975,
                   "CBSA": 58610,
                   "state": 3364089
                },
                "numerator_errors": {
                   "this": 1923.8,
                   "CBSA": 4655.8,
                   "state": 24688.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 76
                },
                "error_ratio": {
                   "this": 31.8,
                   "CBSA": 7.9,
                   "state": 0.7
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.39,
                   "CBSA": 0.43,
                   "state": 1.12
                },
                "error": {
                   "this": 0.51,
                   "CBSA": 0.16,
                   "state": 0.05
                },
                "numerators": {
                   "this": 277,
                   "CBSA": 2378,
                   "state": 343173
                },
                "numerator_errors": {
                   "this": 361.6,
                   "CBSA": 904.7,
                   "state": 14221.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 91,
                   "state": 35
                },
                "error_ratio": {
                   "this": 130.8,
                   "CBSA": 37.2,
                   "state": 4.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 62,
                   "CBSA": 926,
                   "state": 66574
                },
                "error": {
                   "this": 77.7,
                   "CBSA": 192.08,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 7,
                   "state": null
                },
                "error_ratio": {
                   "this": 125.3,
                   "CBSA": 20.7,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 119,
                   "CBSA": 2137,
                   "state": 145536
                },
                "error": {
                   "this": 66.48,
                   "CBSA": 248.99,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 6,
                   "state": null
                },
                "error_ratio": {
                   "this": 55.9,
                   "CBSA": 11.7,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 982,
                   "CBSA": 11136,
                   "state": 561083
                },
                "error": {
                   "this": 195.54,
                   "CBSA": 592.84,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 9,
                   "state": null
                },
                "error_ratio": {
                   "this": 19.9,
                   "CBSA": 5.3,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 800,
                   "CBSA": 5323,
                   "state": 284290
                },
                "error": {
                   "this": 209.59,
                   "CBSA": 464.29,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": null
                },
                "error_ratio": {
                   "this": 26.2,
                   "CBSA": 8.7,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 667,
                   "CBSA": 4711,
                   "state": 309021
                },
                "error": {
                   "this": 213.13,
                   "CBSA": 518.91,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 14,
                   "state": null
                },
                "error_ratio": {
                   "this": 32,
                   "CBSA": 11,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 1841,
                   "CBSA": 23512,
                   "state": 1351006
                },
                "error": {
                   "this": 816,
                   "CBSA": 1865,
                   "state": 19299
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 8,
                   "state": null
                },
                "error_ratio": {
                   "this": 44.3,
                   "CBSA": 7.9,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 477,
                   "CBSA": 2138,
                   "state": 120461
                },
                "error": {
                   "this": 505,
                   "CBSA": 875,
                   "state": 5618
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 22,
                   "state": null
                },
                "error_ratio": {
                   "this": 105.9,
                   "CBSA": 40.9,
                   "state": 4.7
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 2318,
                "CBSA": 25650,
                "state": 1471467
             },
             "error": {
                "this": 1133,
                "CBSA": 2103,
                "state": 19838
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 9,
                "state": null
             },
             "error_ratio": {
                "this": 48.9,
                "CBSA": 8.2,
                "state": 1.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 3.24,
                "CBSA": 4.59,
                "state": 4.83
             },
             "error": {
                "this": 1.58,
                "CBSA": 0.38,
                "state": 0.07
             },
             "numerators": {
                "this": 2318,
                "CBSA": 25650,
                "state": 1471467
             },
             "numerator_errors": {
                "this": 1133,
                "CBSA": 2103,
                "state": 19838
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 67
             },
             "error_ratio": {
                "this": 48.8,
                "CBSA": 8.3,
                "state": 1.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 67058983,
       "awater": 351998,
       "display_name": "Tracy, CA",
       "full_geoid": "16000US0680238",
       "population": 88806,
       "simple_name": "Tracy",
       "sumlevel": "160",
       "square_miles": 25.9,
       "population_density": 3659.5
    }
 }

 export default tracy