exports.HAYWARD_SCHOOLS = [
    {
        "id": 30548,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.641071,
        "lon": -122.081871,
        "name": "A Joyful Noise Learning Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "26555 Gading Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/30548-A-Joyful-Noise-Learning-Center/",
            "reviews": "/california/hayward/30548-A-Joyful-Noise-Learning-Center/#Reviews",
            "collegeSuccess": "/california/hayward/30548-A-Joyful-Noise-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 22,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 40
            },
            {
                "label": "African American",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 20
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25833,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.658833,
        "lon": -122.096313,
        "name": "A Shepherd's Heart Christian School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "225 West Winton Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/25833-A-Shepherds-Heart-Christian-School/",
            "reviews": "/california/hayward/25833-A-Shepherds-Heart-Christian-School/#Reviews",
            "collegeSuccess": "/california/hayward/25833-A-Shepherds-Heart-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 27,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19428,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.638695,
        "lon": -122.069221,
        "name": "A Special Place Child Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "27305 Huntwood Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19428-A-Special-Place-Child-Care/",
            "reviews": "/california/hayward/19428-A-Special-Place-Child-Care/#Reviews",
            "collegeSuccess": "/california/hayward/19428-A-Special-Place-Child-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13988,
        "districtId": 1069,
        "districtName": "Alameda County Office of Education",
        "districtCity": "Hayward",
        "levelCode": "e,m,h",
        "lat": 37.658508,
        "lon": -122.098343,
        "name": "Alameda County Community",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "313 West Winton Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/13988-Alameda-County-Community/",
            "reviews": "/california/hayward/13988-Alameda-County-Community/#Reviews",
            "collegeSuccess": "/california/hayward/13988-Alameda-County-Community/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "86%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "20%",
            "state_average": "64%"
        },
        "enrollment": 133,
        "parentRating": 5,
        "numReviews": 1,
        "studentsPerTeacher": 14,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 86
            },
            {
                "label": "Hispanic",
                "percentage": 56
            },
            {
                "label": "African American",
                "percentage": 32
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            },
            {
                "label": "Asian",
                "percentage": 1
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 12353,
        "districtId": 1069,
        "districtName": "Alameda County Office of Education",
        "districtCity": "Hayward",
        "levelCode": "e,m,h",
        "lat": 37.658508,
        "lon": -122.098343,
        "name": "Alameda County Special Education",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "313 West Winton Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/12353-Alameda-County-Special-Education-School/",
            "reviews": "/california/hayward/12353-Alameda-County-Special-Education-School/#Reviews",
            "collegeSuccess": "/california/hayward/12353-Alameda-County-Special-Education-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 67,
        "parentRating": 1,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10651,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.672516,
        "lon": -122.075981,
        "name": "All Saints Catholic School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "22870 2nd Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/10651-All-Saints-Catholic-School/",
            "reviews": "/california/hayward/10651-All-Saints-Catholic-School/#Reviews",
            "collegeSuccess": "/california/hayward/10651-All-Saints-Catholic-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 221,
        "parentRating": 3,
        "numReviews": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 30
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 26
            },
            {
                "label": "White",
                "percentage": 19
            },
            {
                "label": "African American",
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 16834,
        "districtId": 12,
        "districtName": "New Haven Unified",
        "districtCity": "Union City",
        "levelCode": "h",
        "lat": 37.615093,
        "lon": -122.028481,
        "name": "Alternative Learning Academy at Conley-Caraballo High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "541 Blanche Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/16834-Alternative-Learning-Academy-At-Conley-Caraballo-High/",
            "reviews": "/california/hayward/16834-Alternative-Learning-Academy-At-Conley-Caraballo-High/#Reviews",
            "collegeSuccess": "/california/hayward/16834-Alternative-Learning-Academy-At-Conley-Caraballo-High/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "80%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 10,
        "parentRating": 5,
        "numReviews": 2,
        "studentsPerTeacher": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 80
            },
            {
                "label": "African American",
                "percentage": 50
            },
            {
                "label": "Hispanic",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 10
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19435,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.614246,
        "lon": -122.029602,
        "name": "Ambrose Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "493 Blanche St",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19435-Ambrose-Pre-School/",
            "reviews": "/california/hayward/19435-Ambrose-Pre-School/#Reviews",
            "collegeSuccess": "/california/hayward/19435-Ambrose-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 129,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "m",
        "lat": 37.640438,
        "lon": -122.109749,
        "name": "Anthony W. Ochoa Middle",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "2121 Depot Road",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/129-Anthony-W.-Ochoa-Middle-School/",
            "reviews": "/california/hayward/129-Anthony-W.-Ochoa-Middle-School/#Reviews",
            "collegeSuccess": "/california/hayward/129-Anthony-W.-Ochoa-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "67%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 588,
        "parentRating": 4,
        "numReviews": 17,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 5,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 67
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 8
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 9
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 58
            },
            {
                "label": "Pacific Islander",
                "rating": 3,
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 13
            },
            {
                "label": "White",
                "percentage": 4
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19425,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.658749,
        "lon": -122.061867,
        "name": "Associated Students Child",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "25800 Carlos Bee Blvd",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19425-Associated-Students-Child/",
            "reviews": "/california/hayward/19425-Associated-Students-Child/#Reviews",
            "collegeSuccess": "/california/hayward/19425-Associated-Students-Child/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 9021,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.643314,
        "lon": -122.080376,
        "name": "Bayside Seventh-Day Adventist Christian",
        "gradeLevels": "1-8",
        "assigned": null,
        "address": {
            "street1": "26400 Gading Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/9021-Bayside-Seventh-Day-Adventist-Christian/",
            "reviews": "/california/hayward/9021-Bayside-Seventh-Day-Adventist-Christian/#Reviews",
            "collegeSuccess": "/california/hayward/9021-Bayside-Seventh-Day-Adventist-Christian/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 22,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 64
            },
            {
                "label": "African American",
                "percentage": 14
            },
            {
                "label": "Asian",
                "percentage": 14
            },
            {
                "label": "White",
                "percentage": 9
            }
        ],
        "remediationData": {}
    },
    {
        "id": 108,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.641739,
        "lon": -122.062721,
        "name": "Bowman Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "520 Jefferson Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/108-Bowman-Elementary-School/",
            "reviews": "/california/hayward/108-Bowman-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/108-Bowman-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "76%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 301,
        "parentRating": 4,
        "numReviews": 5,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 2,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 1
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 76
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 66
            },
            {
                "label": "Asian",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 109,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.672092,
        "lon": -122.098038,
        "name": "Brenkwitz High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "22100 Princeton Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/109-Brenkwitz-High-School/",
            "reviews": "/california/hayward/109-Brenkwitz-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/109-Brenkwitz-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "71%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "27%",
            "state_average": "64%"
        },
        "enrollment": 161,
        "parentRating": 4,
        "numReviews": 3,
        "studentsPerTeacher": 8,
        "subratings": {
            "Test Scores Rating": 1,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 1,
                "percentage": 71
            },
            {
                "label": "All students",
                "rating": 1
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 12
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 70
            },
            {
                "label": "White",
                "rating": 2,
                "percentage": 8
            },
            {
                "label": "Pacific Islander",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Asian",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 110,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "m",
        "lat": 37.669472,
        "lon": -122.077354,
        "name": "Bret Harte Middle",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "1047 E Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/110-Bret-Harte-Middle-School/",
            "reviews": "/california/hayward/110-Bret-Harte-Middle-School/#Reviews",
            "collegeSuccess": "/california/hayward/110-Bret-Harte-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "68%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 605,
        "parentRating": 4,
        "numReviews": 6,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 4,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 68
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 4
            },
            {
                "label": "African American",
                "rating": 2,
                "percentage": 18
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 59
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "rating": 6,
                "percentage": 5
            },
            {
                "label": "Filipino",
                "rating": 5,
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 20855,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.677979,
        "lon": -122.08252,
        "name": "Bright Future Early Lc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "22351 City Center Drive #150",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/20855-Bright-Future-Early-Lc/",
            "reviews": "/california/hayward/20855-Bright-Future-Early-Lc/#Reviews",
            "collegeSuccess": "/california/hayward/20855-Bright-Future-Early-Lc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 111,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.667488,
        "lon": -122.094933,
        "name": "Burbank Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "353 B Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/111-Burbank-Elementary-School/",
            "reviews": "/california/hayward/111-Burbank-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/111-Burbank-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "73%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 867,
        "parentRating": 5,
        "numReviews": 4,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 73
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "African American",
                "rating": 6,
                "percentage": 7
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 77
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30599,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.636795,
        "lon": -122.114365,
        "name": "California Crosspoint Academy",
        "gradeLevels": "6-12",
        "assigned": null,
        "address": {
            "street1": "25500 Industrial Boulevard",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/30599-California-Crosspoint-Academy/",
            "reviews": "/california/hayward/30599-California-Crosspoint-Academy/#Reviews",
            "collegeSuccess": "/california/hayward/30599-California-Crosspoint-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 214,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 100
            }
        ],
        "remediationData": {}
    },
    {
        "id": 112,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "m",
        "lat": 37.635693,
        "lon": -122.063499,
        "name": "Cesar Chavez Middle",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "27845 Whitman Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 1,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/112-Cesar-Chavez-Middle-School/",
            "reviews": "/california/hayward/112-Cesar-Chavez-Middle-School/#Reviews",
            "collegeSuccess": "/california/hayward/112-Cesar-Chavez-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "70%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 554,
        "parentRating": 3,
        "numReviews": 5,
        "studentsPerTeacher": 40,
        "subratings": {
            "Test Scores Rating": 2,
            "Equity Overview Rating": 1
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 1,
                "percentage": 70
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Asian",
                "rating": 4,
                "percentage": 8
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 73
            },
            {
                "label": "Pacific Islander",
                "rating": 3,
                "percentage": 3
            },
            {
                "label": "Filipino",
                "rating": 3,
                "percentage": 7
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19423,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.643623,
        "lon": -122.104691,
        "name": "Chabot College Children's Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "25555 Hesperian Boulevard",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19423-Chabot-College-Childrens-Center/",
            "reviews": "/california/hayward/19423-Chabot-College-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19423-Chabot-College-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 113,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.675671,
        "lon": -122.098457,
        "name": "Cherryland Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "585 Willow Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/113-Cherryland-Elementary-School/",
            "reviews": "/california/hayward/113-Cherryland-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/113-Cherryland-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "76%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 746,
        "parentRating": 3,
        "numReviews": 7,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 2,
            "Academic Progress Rating": 3,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 76
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 85
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Asian",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26452,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.623959,
        "lon": -122.040077,
        "name": "Cherubims Children's Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "30540 Mission Blvd",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/26452-Cherubims-Childrens-Center/",
            "reviews": "/california/hayward/26452-Cherubims-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/hayward/26452-Cherubims-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29324,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.624008,
        "lon": -122.040024,
        "name": "Cherubims Childrens Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "30540 Mission Boulevard",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/29324-Cherubims-Childrens-Center/",
            "reviews": "/california/hayward/29324-Cherubims-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/hayward/29324-Cherubims-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13366,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.648827,
        "lon": -122.083733,
        "name": "Children's Choice Educare",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "185 West Harder Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/13366-Childrens-Choice-Educare/",
            "reviews": "/california/hayward/13366-Childrens-Choice-Educare/#Reviews",
            "collegeSuccess": "/california/hayward/13366-Childrens-Choice-Educare/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 47,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "African American",
                "percentage": 25
            },
            {
                "label": "Hispanic",
                "percentage": 25
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 17
            },
            {
                "label": "Asian",
                "percentage": 17
            },
            {
                "label": "White",
                "percentage": 17
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19427,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.641052,
        "lon": -122.081833,
        "name": "Circle Time Nursery School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "26555 Gading Rd",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19427-Circle-Time-Nursery-School/",
            "reviews": "/california/hayward/19427-Circle-Time-Nursery-School/#Reviews",
            "collegeSuccess": "/california/hayward/19427-Circle-Time-Nursery-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 307,
        "districtId": 18,
        "districtName": "San Lorenzo Unified",
        "districtCity": "San Lorenzo",
        "levelCode": "e",
        "lat": 37.683712,
        "lon": -122.116982,
        "name": "Colonial Acres Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "17115 Meekland Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/307-Colonial-Acres-Elementary-School/",
            "reviews": "/california/hayward/307-Colonial-Acres-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/307-Colonial-Acres-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "79%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 564,
        "parentRating": 4,
        "numReviews": 6,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 2,
            "Academic Progress Rating": 3,
            "Equity Overview Rating": 1
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 1,
                "percentage": 79
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 77
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 16833,
        "districtId": 12,
        "districtName": "New Haven Unified",
        "districtCity": "Union City",
        "levelCode": "h",
        "lat": 37.615093,
        "lon": -122.028481,
        "name": "Core Learning Academy at Conley-Caraballo High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "541 Blanche Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/16833-Core-Learning-Academy-At-Conley-Caraballo-High/",
            "reviews": "/california/hayward/16833-Core-Learning-Academy-At-Conley-Caraballo-High/#Reviews",
            "collegeSuccess": "/california/hayward/16833-Core-Learning-Academy-At-Conley-Caraballo-High/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "42%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "31%",
            "state_average": "64%"
        },
        "enrollment": 127,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 12,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 42
            },
            {
                "label": "Hispanic",
                "percentage": 69
            },
            {
                "label": "Filipino",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 114,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.671249,
        "lon": -122.055084,
        "name": "East Avenue Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "2424 East Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/114-East-Avenue-Elementary-School/",
            "reviews": "/california/hayward/114-East-Avenue-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/114-East-Avenue-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "68%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 568,
        "parentRating": 4,
        "numReviews": 11,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 68
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 16
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 53
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 10
            },
            {
                "label": "Asian",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 17040,
        "districtId": 18,
        "districtName": "San Lorenzo Unified",
        "districtCity": "San Lorenzo",
        "levelCode": "h",
        "lat": 37.669819,
        "lon": -122.114632,
        "name": "East Bay Arts High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "20450 Royal Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/17040-East-Bay-Arts-High-School/",
            "reviews": "/california/hayward/17040-East-Bay-Arts-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/17040-East-Bay-Arts-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "64%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "55%",
            "state_average": "64%"
        },
        "enrollment": 214,
        "parentRating": 4,
        "numReviews": 1,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 7,
            "College Readiness Rating": 3,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 64
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 63
            },
            {
                "label": "African American",
                "percentage": 13
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15030,
        "districtId": 1296,
        "districtName": "Eden Area ROP",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.637859,
        "lon": -122.100388,
        "name": "Eden Area ROP",
        "gradeLevels": "10-12",
        "assigned": null,
        "address": {
            "street1": "26316 Hesperian Boulevard",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/15030-Eden-Area-Rop-School/",
            "reviews": "/california/hayward/15030-Eden-Area-Rop-School/#Reviews",
            "collegeSuccess": "/california/hayward/15030-Eden-Area-Rop-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 116,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.63755,
        "lon": -122.107445,
        "name": "Eden Gardens Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "2184 Thayer Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/116-Eden-Gardens-Elementary-School/",
            "reviews": "/california/hayward/116-Eden-Gardens-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/116-Eden-Gardens-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "64%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 552,
        "parentRating": 4,
        "numReviews": 7,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 64
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Asian",
                "rating": 7,
                "percentage": 9
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 48
            },
            {
                "label": "Filipino",
                "rating": 4,
                "percentage": 15
            },
            {
                "label": "Two or more races",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19438,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.63147,
        "lon": -122.068581,
        "name": "Eden Youth Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "680 West Tennyson Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19438-Eden-Youth-Center/",
            "reviews": "/california/hayward/19438-Eden-Youth-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19438-Eden-Youth-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28281,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.646263,
        "lon": -122.10791,
        "name": "Eiffel Tower Mont Ps",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "24761 Verlor Court",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/28281-Eiffel-Tower-Mont-Ps/",
            "reviews": "/california/hayward/28281-Eiffel-Tower-Mont-Ps/#Reviews",
            "collegeSuccess": "/california/hayward/28281-Eiffel-Tower-Mont-Ps/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 117,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.635612,
        "lon": -122.086098,
        "name": "Eldridge Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "26825 Eldridge Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/117-Eldridge-Elementary-School/",
            "reviews": "/california/hayward/117-Eldridge-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/117-Eldridge-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "78%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 378,
        "parentRating": 4,
        "numReviews": 7,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 78
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 64
            },
            {
                "label": "Asian",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Pacific Islander",
                "percentage": 7
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19433,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.65543,
        "lon": -122.094185,
        "name": "Elmhurst Learning Center",
        "gradeLevels": "PK-3",
        "assigned": null,
        "address": {
            "street1": "380 Elmhurst Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19433-Elmhurst-Learning-Center/",
            "reviews": "/california/hayward/19433-Elmhurst-Learning-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19433-Elmhurst-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 18,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Two or more races",
                "percentage": 38
            },
            {
                "label": "Hispanic",
                "percentage": 38
            },
            {
                "label": "African American",
                "percentage": 13
            },
            {
                "label": "Pacific Islander",
                "percentage": 13
            }
        ],
        "remediationData": {}
    },
    {
        "id": 118,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.680187,
        "lon": -122.052849,
        "name": "Fairview Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "23515 Maud Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/118-Fairview-Elementary-School/",
            "reviews": "/california/hayward/118-Fairview-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/118-Fairview-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "74%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 549,
        "parentRating": 4,
        "numReviews": 11,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 74
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 20
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 53
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Asian",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19430,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.68074,
        "lon": -122.054092,
        "name": "Fairview Hills Pre-School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "2841 Romagnolo Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19430-Fairview-Hills-Pre-School/",
            "reviews": "/california/hayward/19430-Fairview-Hills-Pre-School/#Reviews",
            "collegeSuccess": "/california/hayward/19430-Fairview-Hills-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 122,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e,m",
        "lat": 37.677467,
        "lon": -122.072983,
        "name": "Faith Ringgold School of Arts and Science",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1570 Ward Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/122-Faith-Ringgold-School-Of-Arts-And-Science/",
            "reviews": "/california/hayward/122-Faith-Ringgold-School-Of-Arts-And-Science/#Reviews",
            "collegeSuccess": "/california/hayward/122-Faith-Ringgold-School-Of-Arts-And-Science/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "77%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 132,
        "parentRating": 4,
        "numReviews": 14,
        "studentsPerTeacher": 28,
        "subratings": {
            "Test Scores Rating": 2,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 77
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 67
            },
            {
                "label": "African American",
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "Asian",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 119,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.642109,
        "lon": -122.079842,
        "name": "Glassbrook Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "975 Schafer Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/119-Glassbrook-Elementary-School/",
            "reviews": "/california/hayward/119-Glassbrook-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/119-Glassbrook-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "80%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 515,
        "parentRating": 3,
        "numReviews": 4,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 1,
            "Academic Progress Rating": 3,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 80
            },
            {
                "label": "All students",
                "rating": 1
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 85
            },
            {
                "label": "Asian",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 120,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.651054,
        "lon": -122.072029,
        "name": "Harder Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "495 Wyeth Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/120-Harder-Elementary-School/",
            "reviews": "/california/hayward/120-Harder-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/120-Harder-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "80%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 569,
        "parentRating": 3,
        "numReviews": 14,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 1,
            "Academic Progress Rating": 3,
            "Equity Overview Rating": 1
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 1,
                "percentage": 80
            },
            {
                "label": "All students",
                "rating": 1
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 79
            },
            {
                "label": "Asian",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 14702,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e,m,h",
        "lat": 37.672623,
        "lon": -122.098137,
        "name": "Hayward Adult",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "22100 Princeton Street",
            "street2": null,
            "zip": "94540",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94540",
        "type": "school",
        "links": {
            "profile": "/california/hayward/14702-Hayward-Adult/",
            "reviews": "/california/hayward/14702-Hayward-Adult/#Reviews",
            "collegeSuccess": "/california/hayward/14702-Hayward-Adult/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 121,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.671173,
        "lon": -122.066536,
        "name": "Hayward High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "1633 East Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/121-Hayward-High-School/",
            "reviews": "/california/hayward/121-Hayward-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/121-Hayward-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "69%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "62%",
            "state_average": "64%"
        },
        "enrollment": 1637,
        "parentRating": 3,
        "numReviews": 11,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 5,
            "College Readiness Rating": 5,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 69
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 6
            },
            {
                "label": "African American",
                "rating": 2,
                "percentage": 15
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 62
            },
            {
                "label": "Pacific Islander",
                "rating": 4,
                "percentage": 2
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 7
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 32004,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e,m,h",
        "lat": 37.661999,
        "lon": -122.05761,
        "name": "Highland",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "2021 Highland Boulevard",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/32004-Highland/",
            "reviews": "/california/hayward/32004-Highland/#Reviews",
            "collegeSuccess": "/california/hayward/32004-Highland/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "87%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 23,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 8,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 87
            },
            {
                "label": "Hispanic",
                "percentage": 43
            },
            {
                "label": "African American",
                "percentage": 26
            },
            {
                "label": "White",
                "percentage": 17
            },
            {
                "label": "Two or more races",
                "percentage": 13
            }
        ],
        "remediationData": {}
    },
    {
        "id": 168,
        "districtId": 12,
        "districtName": "New Haven Unified",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.614185,
        "lon": -122.034737,
        "name": "Hillview Crest Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "31410 Wheelon Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/168-Hillview-Crest-Elementary-School/",
            "reviews": "/california/hayward/168-Hillview-Crest-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/168-Hillview-Crest-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "69%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 513,
        "parentRating": 4,
        "numReviews": 7,
        "studentsPerTeacher": 16,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 69
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 68
            },
            {
                "label": "Filipino",
                "percentage": 9
            },
            {
                "label": "Asian",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19429,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.627392,
        "lon": -122.086594,
        "name": "Husd Child Development Center-Palma Ceia",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "27679 Melbourne Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19429-Husd-Child-Development-Center-Palma-Ceia/",
            "reviews": "/california/hayward/19429-Husd-Child-Development-Center-Palma-Ceia/#Reviews",
            "collegeSuccess": "/california/hayward/19429-Husd-Child-Development-Center-Palma-Ceia/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19436,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.641621,
        "lon": -122.062637,
        "name": "Husd Child Development-Bowman",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "520 Jefferson Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19436-Husd-Child-Development-Bowman/",
            "reviews": "/california/hayward/19436-Husd-Child-Development-Bowman/#Reviews",
            "collegeSuccess": "/california/hayward/19436-Husd-Child-Development-Bowman/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19437,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.675957,
        "lon": -122.098206,
        "name": "Husd Child Development-Cherryland",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "585 Willow Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19437-Husd-Child-Development-Cherryland/",
            "reviews": "/california/hayward/19437-Husd-Child-Development-Cherryland/#Reviews",
            "collegeSuccess": "/california/hayward/19437-Husd-Child-Development-Cherryland/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19421,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.656624,
        "lon": -122.082932,
        "name": "Husd Child Development-Muir",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "24823 Soto Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19421-Husd-Child-Development-Muir/",
            "reviews": "/california/hayward/19421-Husd-Child-Development-Muir/#Reviews",
            "collegeSuccess": "/california/hayward/19421-Husd-Child-Development-Muir/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 17502,
        "districtId": 2274,
        "districtName": "Impact Academy of Arts & Technology District",
        "districtCity": "Hayward",
        "levelCode": "m,h",
        "lat": 37.630341,
        "lon": -122.102318,
        "name": "Impact Academy of Arts & Technology",
        "gradeLevels": "6-12",
        "assigned": null,
        "address": {
            "street1": "2560 Darwin Street",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/17502-Impact-Academy-Of-Arts-And-Technology/",
            "reviews": "/california/hayward/17502-Impact-Academy-Of-Arts-And-Technology/#Reviews",
            "collegeSuccess": "/california/hayward/17502-Impact-Academy-Of-Arts-And-Technology/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "64%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "82%",
            "state_average": "64%"
        },
        "enrollment": 842,
        "parentRating": 4,
        "numReviews": 8,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 6,
            "College Readiness Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 64
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 7
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 70
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 10,
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19422,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.644669,
        "lon": -122.082214,
        "name": "Kids Childrens Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "26221 Gading Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19422-Kids-Childrens-Center/",
            "reviews": "/california/hayward/19422-Kids-Childrens-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19422-Kids-Childrens-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25847,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.633389,
        "lon": -122.078888,
        "name": "Kingdom Builder Christian Private School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "27300 Patrick Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/25847-Kingdom-Builder-Christian-Private-School/",
            "reviews": "/california/hayward/25847-Kingdom-Builder-Christian-Private-School/#Reviews",
            "collegeSuccess": "/california/hayward/25847-Kingdom-Builder-Christian-Private-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 7,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31121,
        "districtId": 2370,
        "districtName": "Knowledge Enlightens You (KEY) Academy District",
        "districtCity": "Hayward",
        "levelCode": "e,m",
        "lat": 37.677467,
        "lon": -122.072983,
        "name": "Knowledge Enlightens You (KEY) Academy",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1570 Ward Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/31121-Knowledge-Enlightens-You-Key-Academy/",
            "reviews": "/california/hayward/31121-Knowledge-Enlightens-You-Key-Academy/#Reviews",
            "collegeSuccess": "/california/hayward/31121-Knowledge-Enlightens-You-Key-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "57%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 563,
        "parentRating": 4,
        "numReviews": 9,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 57
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 58
            },
            {
                "label": "White",
                "rating": 2,
                "percentage": 21
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 9742,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.63768,
        "lon": -122.102936,
        "name": "Lea's Christian",
        "gradeLevels": "K-4",
        "assigned": null,
        "address": {
            "street1": "26236 Adrian Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/9742-Leas-Christian/",
            "reviews": "/california/hayward/9742-Leas-Christian/#Reviews",
            "collegeSuccess": "/california/hayward/9742-Leas-Christian/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 99,
        "parentRating": 4,
        "numReviews": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 53
            },
            {
                "label": "White",
                "percentage": 21
            },
            {
                "label": "African American",
                "percentage": 16
            },
            {
                "label": "Hispanic",
                "percentage": 10
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24625,
        "districtId": 2385,
        "districtName": "Leadership Public Schools - Hayward District",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.623425,
        "lon": -122.086227,
        "name": "Leadership Public Schools - Hayward",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "28000 Calaroga Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/24625-Leadership-Public-Schools---Hayward/",
            "reviews": "/california/hayward/24625-Leadership-Public-Schools---Hayward/#Reviews",
            "collegeSuccess": "/california/hayward/24625-Leadership-Public-Schools---Hayward/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "62%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "88%",
            "state_average": "64%"
        },
        "enrollment": 595,
        "parentRating": 5,
        "numReviews": 7,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 7,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 62
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 10
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 67
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 12
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30016,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.677158,
        "lon": -122.081703,
        "name": "Li'l Angels Child Care Center-Russell",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1190 Russell Way",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/30016-Lil-Angels-Child-Care-Center-Russell/",
            "reviews": "/california/hayward/30016-Lil-Angels-Child-Care-Center-Russell/#Reviews",
            "collegeSuccess": "/california/hayward/30016-Lil-Angels-Child-Care-Center-Russell/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19431,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.626289,
        "lon": -122.065567,
        "name": "Li'l Angels- Ruus",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "28924 Ruus Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19431-Lil-Angels--Ruus/",
            "reviews": "/california/hayward/19431-Lil-Angels--Ruus/#Reviews",
            "collegeSuccess": "/california/hayward/19431-Lil-Angels--Ruus/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15824,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.6623,
        "lon": -122.055901,
        "name": "Liber Academy of Hayward",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "25584 University Court",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/15824-Liber-Academy-Of-Hayward/",
            "reviews": "/california/hayward/15824-Liber-Academy-Of-Hayward/#Reviews",
            "collegeSuccess": "/california/hayward/15824-Liber-Academy-Of-Hayward/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25848,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.678619,
        "lon": -122.088181,
        "name": "Liber Community School",
        "gradeLevels": "4-12",
        "assigned": null,
        "address": {
            "street1": "22138 Main Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/25848-Liber-Community-School/",
            "reviews": "/california/hayward/25848-Liber-Community-School/#Reviews",
            "collegeSuccess": "/california/hayward/25848-Liber-Community-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 125,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.658073,
        "lon": -122.109818,
        "name": "Longwood Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "850 Longwood Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/125-Longwood-Elementary-School/",
            "reviews": "/california/hayward/125-Longwood-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/125-Longwood-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "79%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 651,
        "parentRating": 4,
        "numReviews": 4,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 2,
            "Academic Progress Rating": 3,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 79
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 81
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 315,
        "districtId": 18,
        "districtName": "San Lorenzo Unified",
        "districtCity": "San Lorenzo",
        "levelCode": "e",
        "lat": 37.674732,
        "lon": -122.11869,
        "name": "Lorenzo Manor Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "18250 Bengal Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/315-Lorenzo-Manor-Elementary-School/",
            "reviews": "/california/hayward/315-Lorenzo-Manor-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/315-Lorenzo-Manor-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "70%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 569,
        "parentRating": 4,
        "numReviews": 6,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 2,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 1,
                "percentage": 70
            },
            {
                "label": "All students",
                "rating": 2
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 70
            },
            {
                "label": "African American",
                "percentage": 8
            },
            {
                "label": "Asian",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 115,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.624805,
        "lon": -122.100166,
        "name": "Lorin A. Eden Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "27790 Portsmouth Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/115-Lorin-A.-Eden-Elementary-School/",
            "reviews": "/california/hayward/115-Lorin-A.-Eden-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/115-Lorin-A.-Eden-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "62%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 389,
        "parentRating": 4,
        "numReviews": 18,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 62
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 19
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 10
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 40
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 19
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 124,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "m",
        "lat": 37.635265,
        "lon": -122.091553,
        "name": "Martin Luther King Jr. Middle",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "26890 Holly Hill Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/124-Martin-Luther-King-Jr.-Middle-School/",
            "reviews": "/california/hayward/124-Martin-Luther-King-Jr.-Middle-School/#Reviews",
            "collegeSuccess": "/california/hayward/124-Martin-Luther-King-Jr.-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "66%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 757,
        "parentRating": 4,
        "numReviews": 11,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 6,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 66
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "African American",
                "rating": 6,
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 69
            },
            {
                "label": "Pacific Islander",
                "rating": 3,
                "percentage": 3
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 3
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10803,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.648163,
        "lon": -122.082466,
        "name": "Montessori Children's House Of Hayward",
        "gradeLevels": "K-3",
        "assigned": null,
        "address": {
            "street1": "166 West Harder Road",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/10803-Montessori-Childrens-House-Of-Hayward/",
            "reviews": "/california/hayward/10803-Montessori-Childrens-House-Of-Hayward/#Reviews",
            "collegeSuccess": "/california/hayward/10803-Montessori-Childrens-House-Of-Hayward/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 65,
        "parentRating": 5,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 54
            },
            {
                "label": "White",
                "percentage": 20
            },
            {
                "label": "African American",
                "percentage": 11
            },
            {
                "label": "Hispanic",
                "percentage": 11
            },
            {
                "label": "Two or more races",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19426,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.63768,
        "lon": -122.102936,
        "name": "Montessori Childrens House",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "26236 Adrian Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19426-Montessori-Childrens-House/",
            "reviews": "/california/hayward/19426-Montessori-Childrens-House/#Reviews",
            "collegeSuccess": "/california/hayward/19426-Montessori-Childrens-House/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8228,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.644421,
        "lon": -122.060089,
        "name": "Moreau Catholic High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "27170 Mission Boulevard",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/8228-Moreau-Catholic-High-School/",
            "reviews": "/california/hayward/8228-Moreau-Catholic-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/8228-Moreau-Catholic-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 950,
        "parentRating": 4,
        "numReviews": 9,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 43
            },
            {
                "label": "Two or more races",
                "percentage": 21
            },
            {
                "label": "White",
                "percentage": 17
            },
            {
                "label": "Hispanic",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 8
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 127,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.624977,
        "lon": -122.090263,
        "name": "Mt. Eden High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "2300 Panama Street",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/127-Mount-Eden-High-School/",
            "reviews": "/california/hayward/127-Mount-Eden-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/127-Mount-Eden-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "65%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "64%",
            "state_average": "64%"
        },
        "enrollment": 1979,
        "parentRating": 3,
        "numReviews": 20,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 6,
            "College Readiness Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 65
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 12
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 55
            },
            {
                "label": "Pacific Islander",
                "rating": 3,
                "percentage": 4
            },
            {
                "label": "White",
                "rating": 4,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 15
            },
            {
                "label": "Two or more races",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15827,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.661701,
        "lon": -122.057198,
        "name": "Northstar School",
        "gradeLevels": "K-7",
        "assigned": null,
        "address": {
            "street1": "2021 Highland Boulevard",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/15827-Northstar-School/",
            "reviews": "/california/hayward/15827-Northstar-School/#Reviews",
            "collegeSuccess": "/california/hayward/15827-Northstar-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24421,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.652214,
        "lon": -122.090034,
        "name": "Northstar School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "249 West Jackson Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/24421-Northstar-School/",
            "reviews": "/california/hayward/24421-Northstar-School/#Reviews",
            "collegeSuccess": "/california/hayward/24421-Northstar-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 142,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 94
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "White",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Hispanic",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 130,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.627415,
        "lon": -122.086143,
        "name": "Palma Ceia Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "27679 Melbourne Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/130-Palma-Ceia-Elementary-School/",
            "reviews": "/california/hayward/130-Palma-Ceia-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/130-Palma-Ceia-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "76%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 551,
        "parentRating": 2,
        "numReviews": 1,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 76
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 59
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 13
            },
            {
                "label": "Asian",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 5
            },
            {
                "label": "White",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 131,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.653816,
        "lon": -122.093239,
        "name": "Park Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "411 Larchmont Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/131-Park-Elementary-School/",
            "reviews": "/california/hayward/131-Park-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/131-Park-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "84%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 532,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 84
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 71
            },
            {
                "label": "Asian",
                "percentage": 8
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "White",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25852,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.630264,
        "lon": -122.104088,
        "name": "Peaceful Learning School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "2633 Bal Harbor Lane",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/25852-Peaceful-Learning-School/",
            "reviews": "/california/hayward/25852-Peaceful-Learning-School/#Reviews",
            "collegeSuccess": "/california/hayward/25852-Peaceful-Learning-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19434,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.67466,
        "lon": -122.086739,
        "name": "R-Kids Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4779 Higher Ave",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19434-R-Kids-Child-Care-Center/",
            "reviews": "/california/hayward/19434-R-Kids-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19434-R-Kids-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19419,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.682816,
        "lon": -122.067245,
        "name": "R-Skool Pre-School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "1836 B Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19419-R-Skool-Pre-School/",
            "reviews": "/california/hayward/19419-R-Skool-Pre-School/#Reviews",
            "collegeSuccess": "/california/hayward/19419-R-Skool-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 305,
        "districtId": 18,
        "districtName": "San Lorenzo Unified",
        "districtCity": "San Lorenzo",
        "levelCode": "m,h",
        "lat": 37.669819,
        "lon": -122.114632,
        "name": "Royal Sunset (Continuation)",
        "gradeLevels": "7-12",
        "assigned": null,
        "address": {
            "street1": "20450 Royal Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/305-Royal-Sunset-Continuation-School/",
            "reviews": "/california/hayward/305-Royal-Sunset-Continuation-School/#Reviews",
            "collegeSuccess": "/california/hayward/305-Royal-Sunset-Continuation-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "59%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "15%",
            "state_average": "64%"
        },
        "enrollment": 105,
        "parentRating": 5,
        "numReviews": 1,
        "studentsPerTeacher": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 59
            },
            {
                "label": "Hispanic",
                "percentage": 63
            },
            {
                "label": "African American",
                "percentage": 25
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "Asian",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 132,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.62701,
        "lon": -122.075203,
        "name": "Ruus Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "28027 Dickens Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/132-Ruus-Elementary-School/",
            "reviews": "/california/hayward/132-Ruus-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/132-Ruus-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "72%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 486,
        "parentRating": 5,
        "numReviews": 3,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 72
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 13
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 60
            },
            {
                "label": "Filipino",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 5
            },
            {
                "label": "White",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8239,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.642601,
        "lon": -122.058029,
        "name": "Saint Clement Catholic School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "790 Calhoun Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/8239-Saint-Clement-Catholic-School/",
            "reviews": "/california/hayward/8239-Saint-Clement-Catholic-School/#Reviews",
            "collegeSuccess": "/california/hayward/8239-Saint-Clement-Catholic-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 265,
        "parentRating": 4,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 48
            },
            {
                "label": "Hispanic",
                "percentage": 24
            },
            {
                "label": "Two or more races",
                "percentage": 17
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 32015,
        "districtId": 18,
        "districtName": "San Lorenzo Unified",
        "districtCity": "San Lorenzo",
        "levelCode": "e,m,h",
        "lat": 37.669819,
        "lon": -122.114632,
        "name": "San Lorenzo Unified Preschool",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "20450 Royal Avenue",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/32015-San-Lorenzo-Unified-Preschool/",
            "reviews": "/california/hayward/32015-San-Lorenzo-Unified-Preschool/#Reviews",
            "collegeSuccess": "/california/hayward/32015-San-Lorenzo-Unified-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 13,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 133,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.642563,
        "lon": -122.086227,
        "name": "Schafer Park Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "26268 Flamingo Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/133-Schafer-Park-Elementary-School/",
            "reviews": "/california/hayward/133-Schafer-Park-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/133-Schafer-Park-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "72%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 778,
        "parentRating": 3,
        "numReviews": 7,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 72
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 81
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31933,
        "districtId": 1558,
        "districtName": "Silver Oak High Public Montessori Charter District",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.662132,
        "lon": -122.072601,
        "name": "Silver Oak High Public Montessori Charter",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "951 Palisade Street",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/31933-Silver-Oak-High-Public-Montessori-Charter/",
            "reviews": "/california/hayward/31933-Silver-Oak-High-Public-Montessori-Charter/#Reviews",
            "collegeSuccess": "/california/hayward/31933-Silver-Oak-High-Public-Montessori-Charter/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27149,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.662285,
        "lon": -122.074654,
        "name": "Silver Oak High School - Public Montessori Charter",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "951 Palisade Street",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/27149-Silver-Oak-High-School---Public-Montessori-Charter/",
            "reviews": "/california/hayward/27149-Silver-Oak-High-School---Public-Montessori-Charter/#Reviews",
            "collegeSuccess": "/california/hayward/27149-Silver-Oak-High-School---Public-Montessori-Charter/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "60%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "69%",
            "state_average": "64%"
        },
        "enrollment": 218,
        "parentRating": 4,
        "numReviews": 9,
        "studentsPerTeacher": 16,
        "subratings": {
            "Test Scores Rating": 6,
            "College Readiness Rating": 8,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 60
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 62
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 17
            },
            {
                "label": "Asian",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19424,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.631359,
        "lon": -122.102585,
        "name": "So Alameda County HD St.-Darwin Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2560 Darwin St",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19424-So-Alameda-County-HD-St.-Darwin-Center/",
            "reviews": "/california/hayward/19424-So-Alameda-County-HD-St.-Darwin-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19424-So-Alameda-County-HD-St.-Darwin-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 135,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.637589,
        "lon": -122.094093,
        "name": "Southgate Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "26601 Calaroga Avenue",
            "street2": null,
            "zip": "94545",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94545",
        "type": "school",
        "links": {
            "profile": "/california/hayward/135-Southgate-Elementary-School/",
            "reviews": "/california/hayward/135-Southgate-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/135-Southgate-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "65%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 677,
        "parentRating": 4,
        "numReviews": 12,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 65
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 17
            },
            {
                "label": "African American",
                "rating": 6,
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 45
            },
            {
                "label": "Filipino",
                "rating": 10,
                "percentage": 12
            },
            {
                "label": "Pacific Islander",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24503,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.672577,
        "lon": -122.098022,
        "name": "Spectrum Center - Mission",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "22100 Princeton Street",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/24503-Spectrum-Center---Mission/",
            "reviews": "/california/hayward/24503-Spectrum-Center---Mission/#Reviews",
            "collegeSuccess": "/california/hayward/24503-Spectrum-Center---Mission/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 84,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "African American",
                "percentage": 33
            },
            {
                "label": "White",
                "percentage": 23
            },
            {
                "label": "Hispanic",
                "percentage": 17
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 17
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28432,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.663055,
        "lon": -122.115532,
        "name": "St Joachim Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "21250 Hesperian Boulevard",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/28432-St-Joachim-Pre-School/",
            "reviews": "/california/hayward/28432-St-Joachim-Pre-School/#Reviews",
            "collegeSuccess": "/california/hayward/28432-St-Joachim-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8246,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.637863,
        "lon": -122.079597,
        "name": "St. Bede Catholic School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "26910 Patrick Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/8246-St.-Bede-Catholic-School/",
            "reviews": "/california/hayward/8246-St.-Bede-Catholic-School/#Reviews",
            "collegeSuccess": "/california/hayward/8246-St.-Bede-Catholic-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 238,
        "parentRating": 5,
        "numReviews": 8,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 54
            },
            {
                "label": "Hispanic",
                "percentage": 16
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "Pacific Islander",
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8237,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.663055,
        "lon": -122.115532,
        "name": "St. Joachim's Elementary School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "21250 Hesperian Boulevard",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/8237-St.-Joachims-Elementary-School/",
            "reviews": "/california/hayward/8237-St.-Joachims-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/8237-St.-Joachims-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 367,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 42
            },
            {
                "label": "Asian",
                "percentage": 33
            },
            {
                "label": "Two or more races",
                "percentage": 11
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25855,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.677094,
        "lon": -122.071899,
        "name": "Stellar Preparatory High School",
        "gradeLevels": "9",
        "assigned": null,
        "address": {
            "street1": "1570 Ward Street",
            "street2": "Unit 23",
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/25855-Stellar-Preparatory-High-School/",
            "reviews": "/california/hayward/25855-Stellar-Preparatory-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/25855-Stellar-Preparatory-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 17004,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.653702,
        "lon": -122.017593,
        "name": "Stonebrae Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "28761 Hayward Boulevard",
            "street2": null,
            "zip": "94542",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94542",
        "type": "school",
        "links": {
            "profile": "/california/hayward/17004-Stonebrae-Elementary-School/",
            "reviews": "/california/hayward/17004-Stonebrae-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/17004-Stonebrae-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "55%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 745,
        "parentRating": 4,
        "numReviews": 36,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 55
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 18
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 13
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 41
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 11
            },
            {
                "label": "Two or more races",
                "rating": 6,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 137,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "h",
        "lat": 37.642483,
        "lon": -122.067497,
        "name": "Tennyson High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "27035 Whitman Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/137-Tennyson-High-School/",
            "reviews": "/california/hayward/137-Tennyson-High-School/#Reviews",
            "collegeSuccess": "/california/hayward/137-Tennyson-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "76%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "50%",
            "state_average": "64%"
        },
        "enrollment": 1423,
        "parentRating": 3,
        "numReviews": 8,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 3,
            "College Readiness Rating": 2,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 76
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Asian",
                "rating": 4,
                "percentage": 7
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 72
            },
            {
                "label": "Pacific Islander",
                "rating": 2,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 5,
                "percentage": 7
            },
            {
                "label": "White",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 138,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.624962,
        "lon": -122.03862,
        "name": "Treeview Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "30565 Treeview Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/138-Treeview-Elementary/",
            "reviews": "/california/hayward/138-Treeview-Elementary/#Reviews",
            "collegeSuccess": "/california/hayward/138-Treeview-Elementary/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "75%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 461,
        "parentRating": 2,
        "numReviews": 4,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 75
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Asian",
                "rating": 7,
                "percentage": 12
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 10
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 54
            },
            {
                "label": "Filipino",
                "percentage": 10
            },
            {
                "label": "White",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 139,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.638351,
        "lon": -122.074532,
        "name": "Tyrrell Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "27000 Tyrrell Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/139-Tyrrell-Elementary-School/",
            "reviews": "/california/hayward/139-Tyrrell-Elementary-School/#Reviews",
            "collegeSuccess": "/california/hayward/139-Tyrrell-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "85%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 675,
        "parentRating": 2,
        "numReviews": 4,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 85
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 82
            },
            {
                "label": "Asian",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 1
            },
            {
                "label": "White",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15344,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.668167,
        "lon": -122.068077,
        "name": "Victory Academy",
        "gradeLevels": "7, 9-11",
        "assigned": null,
        "address": {
            "street1": "24651 Fawn Meadow Ln",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/15344-Victory-Academy/",
            "reviews": "/california/hayward/15344-Victory-Academy/#Reviews",
            "collegeSuccess": "/california/hayward/15344-Victory-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 140,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "m",
        "lat": 37.659428,
        "lon": -122.089783,
        "name": "Winton Middle",
        "gradeLevels": "7-8",
        "assigned": null,
        "address": {
            "street1": "119 West Winton Avenue",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/140-Winton-Middle-School/",
            "reviews": "/california/hayward/140-Winton-Middle-School/#Reviews",
            "collegeSuccess": "/california/hayward/140-Winton-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "74%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 505,
        "parentRating": 4,
        "numReviews": 21,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 3,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 74
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 78
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 4
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29163,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.662647,
        "lon": -122.073105,
        "name": "Ymca Of East Bay Y Kids",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "951 Palisade Street",
            "street2": null,
            "zip": "94544",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94544",
        "type": "school",
        "links": {
            "profile": "/california/hayward/29163-Ymca-Of-East-Bay-Y-Kids/",
            "reviews": "/california/hayward/29163-Ymca-Of-East-Bay-Y-Kids/#Reviews",
            "collegeSuccess": "/california/hayward/29163-Ymca-Of-East-Bay-Y-Kids/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19432,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.679401,
        "lon": -122.052254,
        "name": "Yours & Mine Learning Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2894 D St",
            "street2": null,
            "zip": "94541",
            "city": "Hayward"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94541",
        "type": "school",
        "links": {
            "profile": "/california/hayward/19432-Yours--Mine-Learning-Center/",
            "reviews": "/california/hayward/19432-Yours--Mine-Learning-Center/#Reviews",
            "collegeSuccess": "/california/hayward/19432-Yours--Mine-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    }
]