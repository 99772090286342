import alamo from "./alamo"
import blackhawk from "./blackhawk"
import campbell from "./campbell"
import castro_valley from "./castro_valley"
import cupertino from "./cupertino"
import daly_city from "./daly_city"
import danville from "./danville"
import dublin from "./dublin"
import east_palo_alto from "./east_palo_alto"
import foster_city from "./foster_city"
import fremont from "./fremont"
import hayward from "./hayward"
import livermore from "./livermore"
import millbrae from "./millbrae"
import milpitas from "./milpitas"
import mountain_view from "./mountain_view"
import newark from "./newark"
import oakland from "./oakland"
import palo_alto from "./palo_alto"
import pleasanton from "./pleasanton"
import redwood_city from "./redwood_city"
import san_jose from "./san_jose"
import san_ramon from "./san_ramon"
import san_bruno from "./san_bruno"
import san_mateo from "./san_mateo"
import san_leandro from "./san_leandro"
import santa_clara from "./santa_clara"
import saratoga from "./saratoga"
import south_san_francisco from "./south_san_francisco"
import sunnyvale from "./sunnyvale"
import union_city from "./union_city"
import las_vegas from "./las_vegas"
import orlando from "./orlando"
import austin from "./austin"
import mountain_house from "./mountain_house"
import tracy from "./tracy"
import alameda from "./alameda"
import menlo_park from "./menlo_park"
import los_gatos from "./los_gatos"
import los_altos from "./los_altos"
import atherton from "./atherton"
import walnut_creek from "./walnut_creek"
import burlingame from "./burlingame"
import hillsborough from "./hillsborough"
import woodside from "./woodside"
import portola_valley from "./portola_valley"

const cities_data = {
    "Alamo": alamo,
    "Blackhawk": blackhawk,
    "Campbell": campbell,
    "Castro Valley": castro_valley,
    "Cupertino": cupertino,
    "Daly City": daly_city,
    "Danville": danville,
    "Dublin": dublin,
    "East Palo Alto": east_palo_alto,
    "Foster City": foster_city,
    "Fremont": fremont,
    "Hayward": hayward,
    "Livermore": livermore,
    "Milpitas": milpitas,
    "Millbrae": millbrae,
    "Mountain View": mountain_view,
    "Newark": newark,
    "Oakland": oakland,
    "Palo Alto": palo_alto,
    "Pleasanton": pleasanton,
    "Redwood City": redwood_city,
    "San Jose": san_jose,
    "San Ramon": san_ramon,
    "San Bruno": san_bruno,
    "San Leandro": san_leandro,
    "San Mateo": san_mateo,
    "Santa Clara": santa_clara,
    "Saratoga": saratoga,
    "Sunnyvale": sunnyvale,
    "South San Francisco": south_san_francisco,
    "Union City": union_city,
    "Las Vegas": las_vegas,
    "Orlando": orlando,
    "Austin": austin,
    "Mountain House": mountain_house,
    "Tracy": tracy,
    "Alameda": alameda,
    "Menlo Park": menlo_park,
    "Los Gatos": los_gatos,
    "Los Altos": los_altos,
    "Atherton": atherton,
    "Walnut Creek": walnut_creek,
    "Burlingame": burlingame,
    "Hillsborough": hillsborough,
    "Portola Valley": portola_valley,
    "Woodside": woodside,
}

export default cities_data