exports.WALNUT_CREEK_LISTINGS = [
		{
			"closePrice": 1135000,
			"daysOnMovoto": 28,
			"id": "2c2bb32b-e1c6-4035-a203-f2f0863a18ed",
			"tnImgPath": "https://pi.movoto.com/p/12/40973098_0_FJAvym_p.jpeg",
			"listDate": "2021-12-02 00:00:00",
			"listingAgent": "Antonia Quanstrom",
			"listPrice": 945000,
			"lotSize": 6175,
			"sqftTotal": 1219,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973098",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1576-brentwood-ct-walnut-creek-ca-94595/pid_ss6sbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ad950d52-ff96-4127-b498-8aba054aba99",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-12-03 03:26:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/1576-brentwood-ct-walnut-creek-ca-94595-1312836/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.884017,
				"lng": -122.056067,
				"zipcode": "94595",
				"subPremise": "",
				"address": "1576 Brentwood Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1135000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/1576-brentwood-ct-walnut-creek-ca-94595-1312836/unbranded"
		},
		{
			"closePrice": 295000,
			"daysOnMovoto": 69,
			"id": "ae1e50c8-be7b-47a7-9b32-32684cb11684",
			"tnImgPath": "https://pi.movoto.com/p/12/40972080_0_nmbnA3_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Denisen Hartlove",
			"listPrice": 298000,
			"lotSize": 0,
			"sqftTotal": 528,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972080",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94596",
			"path": "walnut-creek-ca/300-n-civic-dr-307-walnut-creek-ca-94596/pid_m5zsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -7000,
			"propertyId": "fabd8c1e-e58c-407e-9c4b-5a07cf378226",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-10-23 02:56:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.910808,
				"lng": -122.059347,
				"zipcode": "94596",
				"subPremise": "APT 307",
				"address": "300 N Civic Dr #307"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 295000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 390000,
			"daysOnMovoto": 27,
			"id": "1a555d7e-4916-4351-9c1f-94798aa26ace",
			"tnImgPath": "https://pi.movoto.com/p/12/40975454_0_NjRJbm_p.jpeg",
			"listDate": "2021-12-02 00:00:00",
			"listingAgent": "Steven Monasch",
			"listPrice": 383000,
			"lotSize": 0,
			"sqftTotal": 708,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975454",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94597",
			"path": "walnut-creek-ca/1175-honey-trl-walnut-creek-ca-94597/pid_s6gsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0e14f5db-1288-4cbe-9cdc-fbbea641cb5f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-12-03 04:01:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.928531,
				"lng": -122.05359,
				"zipcode": "94597",
				"subPremise": "",
				"address": "1175 Honey Trl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1049000,
			"daysOnMovoto": 40,
			"id": "2d4a8736-c988-4387-b2a2-72c2e1ad4636",
			"tnImgPath": "https://pi.movoto.com/p/12/40974655_0_BB6VUe_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Mary Anne LaHaye",
			"listPrice": 1049000,
			"lotSize": 9750,
			"sqftTotal": 1150,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974655",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Abio Properties",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "94597",
			"path": "walnut-creek-ca/1564-1st-ave-walnut-creek-ca-94597/pid_2rvsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c4daaf5-ac7d-4882-b08a-a8d3eb3ca61b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-19 19:16:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 00:00:00",
			"virtualTourLink": "http://1564FirstAve.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.9244,
				"lng": -122.065745,
				"zipcode": "94597",
				"subPremise": "",
				"address": "1564 1st Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1049000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://1564FirstAve.com"
		},
		{
			"closePrice": 970000,
			"daysOnMovoto": 45,
			"id": "f183cfe0-a49b-428f-9571-4dbf7a6f18a7",
			"tnImgPath": "https://pi.movoto.com/p/12/40974262_0_qRIUuJ_p.jpeg",
			"listDate": "2021-11-14 00:00:00",
			"listingAgent": "Lisa Lombardi",
			"listPrice": 949000,
			"lotSize": 1500,
			"sqftTotal": 1893,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974262",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1978,
			"zipCode": "94597",
			"path": "walnut-creek-ca/519-monarch-ridge-dr-walnut-creek-ca-94597/pid_03ysbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee019760-4e48-4478-b672-b64278b08bbd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-14 22:06:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.915553,
				"lng": -122.098609,
				"zipcode": "94597",
				"subPremise": "",
				"address": "519 Monarch Ridge Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 185000,
			"daysOnMovoto": 309,
			"id": "a90389bb-4d12-41a1-8d24-f4d7701f5bd1",
			"tnImgPath": "https://pi.movoto.com/p/12/40938482_0_ZqA6QA_p.jpeg",
			"listDate": "2021-02-23 00:00:00",
			"listingAgent": "Christina Beil",
			"listPrice": 202888,
			"lotSize": 0,
			"sqftTotal": 698,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938482",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1991,
			"zipCode": "94595",
			"path": "walnut-creek-ca/no-address-walnut-creek-ca-94595-12_40938482/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "63212d3d-1c33-4f90-b9de-da5d7282a1df",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-02-23 20:41:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 0,
				"lng": 0,
				"zipcode": "94595",
				"subPremise": "APT 2337",
				"address": "Undisclosed",
				"address2": "1840 Tice Creek Dr #2337"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 185000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 680000,
			"daysOnMovoto": 54,
			"id": "50c45cb9-52ba-426c-97e6-8a6612832fd8",
			"tnImgPath": "https://pi.movoto.com/p/12/40973342_0_fv2inr_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Renee White",
			"listPrice": 650000,
			"lotSize": 19166,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973342",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 11,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "94597",
			"path": "walnut-creek-ca/1860-sunnyvale-ave-walnut-creek-ca-94597/pid_rivsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "129c4bee-3bc8-409c-94ac-460a2a50234c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-05 01:26:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.929521,
				"lng": -122.072243,
				"zipcode": "94597",
				"subPremise": "",
				"address": "1860 Sunnyvale Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 680000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 38,
			"id": "e3f77153-e6b2-47c8-b5dc-6ca2af9126de",
			"tnImgPath": "https://pi.movoto.com/p/12/40974637_0_iZMInA_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Valerie Crowell",
			"listPrice": 1475000,
			"lotSize": 27181,
			"sqftTotal": 2885,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974637",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1994,
			"zipCode": "94595",
			"path": "walnut-creek-ca/210-alamo-view-pl-walnut-creek-ca-94595/pid_839sbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "69743174-bea8-43c8-b4e5-9104957ed073",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-19 17:31:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.879342,
				"lng": -122.060973,
				"zipcode": "94595",
				"subPremise": "",
				"address": "210 Alamo View Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1245000,
			"daysOnMovoto": 70,
			"id": "f65323a1-6151-4815-a0b4-6f716d1b8e94",
			"tnImgPath": "https://pi.movoto.com/p/12/40971395_0_vb6QFz_p.jpeg",
			"listDate": "2021-10-18 00:00:00",
			"listingAgent": "Rebecca Clausnitzer",
			"listPrice": 1215000,
			"lotSize": 1843,
			"sqftTotal": 1843,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971395",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Warmington Residential",
			"photoCount": 4,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94597",
			"path": "walnut-creek-ca/132-pioneer-ave-walnut-creek-ca-94597-12_40971395/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -225000,
			"propertyId": "f6d3bb17-6ef0-47fd-bb28-f316dfd8b81d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-18 21:06:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.925453,
				"lng": -122.064982,
				"zipcode": "94597",
				"subPremise": "",
				"address": "132 Pioneer Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1245000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1560000,
			"daysOnMovoto": 97,
			"id": "f2119d34-c9fd-4ecf-8899-d01d6bf37d48",
			"tnImgPath": "https://pi.movoto.com/p/12/40967631_0_EnVqYu_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Karen Lin",
			"listPrice": 1590000,
			"lotSize": 4445,
			"sqftTotal": 2026,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967631",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94596",
			"path": "walnut-creek-ca/1794-san-miguel-dr-walnut-creek-ca-94596-2001_d8t23nkbmt/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0008e954-5533-4422-93aa-afc46213fa24",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-09-17 22:11:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=hd4uHMmB2n9&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.896954,
				"lng": -122.053935,
				"zipcode": "94596",
				"subPremise": "",
				"address": "1794 San Miguel Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=hd4uHMmB2n9&brand=0"
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 4,
			"id": "52f1f3b0-91ad-4763-a174-1b41ca635317",
			"tnImgPath": "https://pi.movoto.com/p/101/321113645_0_6Y6QVI_p.jpeg",
			"listDate": "2021-12-17 00:00:00",
			"listingAgent": "Sarah Padilla",
			"listPrice": 799000,
			"lotSize": 10350,
			"sqftTotal": 1319,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321113645",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Gold",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1961,
			"zipCode": "94597",
			"path": "walnut-creek-ca/2478-sky-rd-walnut-creek-ca-94597/pid_nmxsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8a2d816f-3872-4b20-97ac-a328a2ee64c0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-12-17 20:03:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.916518,
				"lng": -122.075511,
				"zipcode": "94597",
				"subPremise": "",
				"address": "2478 Sky Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 385000,
			"daysOnMovoto": 38,
			"id": "b33baad6-7768-4750-b041-f64995deb842",
			"tnImgPath": "https://pi.movoto.com/p/12/40974133_0_MFM7eA_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Donald Chase",
			"listPrice": 399000,
			"lotSize": 0,
			"sqftTotal": 790,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974133",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "SysVest Financial, Inc.",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94596",
			"path": "walnut-creek-ca/155-sharene-ln-102-walnut-creek-ca-94596/pid_2o3sbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "919180c8-624e-45a4-a660-34f2bd23da56",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-12 22:41:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.901288,
				"lng": -122.05429,
				"zipcode": "94596",
				"subPremise": "APT 102",
				"address": "155 Sharene Ln #102"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 385000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 480000,
			"daysOnMovoto": 60,
			"id": "30ee307d-fa4a-4f88-aabd-0d6d2859212e",
			"tnImgPath": "https://pi.movoto.com/p/12/40971847_0_UrJY3B_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "James Dye",
			"listPrice": 499000,
			"lotSize": 0,
			"sqftTotal": 969,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971847",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Heritage Properties",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94598",
			"path": "walnut-creek-ca/101-kinross-dr-apt-28-walnut-creek-ca-94598-12_40971847/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d719e070-94ef-4391-bed8-2d5a4e02ed46",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-10-21 23:01:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2927876",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.91323,
				"lng": -122.044118,
				"zipcode": "94598",
				"subPremise": "APT 28",
				"address": "101 Kinross Dr #28"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2927876"
		},
		{
			"closePrice": 325000,
			"daysOnMovoto": 46,
			"id": "d9559806-9865-4df6-a3a4-3beca7e3a358",
			"tnImgPath": "https://pi.movoto.com/p/12/40972846_0_mym3Bv_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Holly Fitzsimmons",
			"listPrice": 329000,
			"lotSize": 0,
			"sqftTotal": 701,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972846",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Holly Fitzsimmons; Broker",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94596",
			"path": "walnut-creek-ca/340-n-civic-apt-305-walnut-creek-ca-94596-12_40972846/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cfba4420-7e4d-481d-b94b-575e8eb5ff21",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-01 07:36:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.912002,
				"lng": -122.059698,
				"zipcode": "94596",
				"subPremise": "APT 305",
				"address": "340 N Civic #305"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 498000,
			"daysOnMovoto": 96,
			"id": "c50be565-7f76-4258-94e2-4c9e49c36c6b",
			"tnImgPath": "https://pi.movoto.com/p/12/40966859_0_iAnBIB_p.jpeg",
			"listDate": "2021-09-12 00:00:00",
			"listingAgent": "Lori Young",
			"listPrice": 498000,
			"lotSize": 0,
			"sqftTotal": 1605,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966859",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1991,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1840-tice-creek-drive-apt-2106-2104-walnut-creek-ca-94595-12_40966859/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -70000,
			"propertyId": "c1b2b459-db46-4c36-ae84-7489094aa8ec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-09-12 18:46:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-12 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2914884",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.874609,
				"lng": -122.076327,
				"zipcode": "94595",
				"subPremise": "APT 2106 & 2104",
				"address": "1840 Tice Creek Drive #2106 & 2104"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 498000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2914884"
		},
		{
			"closePrice": 335000,
			"daysOnMovoto": 109,
			"id": "a6c064fd-a70f-4e44-a3a9-3e43cfb114da",
			"tnImgPath": "https://pi.movoto.com/p/12/40965233_0_Nuuyye_p.jpeg",
			"listDate": "2021-08-30 00:00:00",
			"listingAgent": "Drew Plaisted",
			"listPrice": 338000,
			"lotSize": 0,
			"sqftTotal": 907,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965233",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1993,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1840-tice-creek-dr-apt-2105-walnut-creek-ca-94595-12_40965233/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -7000,
			"propertyId": "87f42ab7-3026-4c7c-a90b-edda69f39f59",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-08-30 22:56:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-30 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2912506",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.874609,
				"lng": -122.076327,
				"zipcode": "94595",
				"subPremise": "APT 2105",
				"address": "1840 Tice Creek Dr #2105"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 335000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2912506"
		},
		{
			"closePrice": 495000,
			"daysOnMovoto": 139,
			"id": "a6d475d1-4572-4991-b83f-46218984324c",
			"tnImgPath": "https://pi.movoto.com/p/12/40961093_0_E6VRqU_p.jpeg",
			"listDate": "2021-07-30 00:00:00",
			"listingAgent": "Bradford Turnbloom",
			"listPrice": 499000,
			"lotSize": 0,
			"sqftTotal": 950,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961093",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Turnbloom & Associates",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94598",
			"path": "walnut-creek-ca/1411-marchbanks-apt-3-walnut-creek-ca-94598-12_40961093/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "c26a4029-cb5f-4a1f-ae61-f52fcea79a3e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-07-30 14:36:52",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.913264,
				"lng": -122.046983,
				"zipcode": "94598",
				"subPremise": "APT 3",
				"address": "1411 Marchbanks #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 495000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 425000,
			"daysOnMovoto": 15,
			"id": "ed6e7096-8834-4ebd-8c37-bce863b67b93",
			"tnImgPath": "https://pi.movoto.com/p/12/40975145_0_aUnjAj_p.jpeg",
			"listDate": "2021-11-30 00:00:00",
			"listingAgent": "Ariana Fort",
			"listPrice": 399000,
			"lotSize": 0,
			"sqftTotal": 1058,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975145",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Woehrle Real Estate",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94595",
			"path": "walnut-creek-ca/2601-golden-rain-rd-2-walnut-creek-ca-94595/pid_ca2wbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af8813d5-2ae1-4860-8f5b-bd4116fb8079",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-30 19:01:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.880139,
				"lng": -122.081394,
				"zipcode": "94595",
				"subPremise": "APT 2",
				"address": "2601 Golden Rain Rd #2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3050000,
			"daysOnMovoto": 32,
			"id": "52d93b40-8f7e-4f84-bb56-241477e95489",
			"tnImgPath": "https://pi.movoto.com/p/12/40973915_0_E7MvRI_p.jpeg",
			"listDate": "2021-11-13 00:00:00",
			"listingAgent": "Brandon Kersis",
			"listPrice": 2795000,
			"lotSize": 18050,
			"sqftTotal": 3122,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973915",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1949,
			"zipCode": "94596",
			"path": "walnut-creek-ca/2013-hermine-ave-walnut-creek-ca-94596-12_40511089/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "88ee822a-1d76-4f4b-9c38-d12cae14d20c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-14 00:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/b9XID0Vfxgs",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.882222,
				"lng": -122.038952,
				"zipcode": "94596",
				"subPremise": "",
				"address": "2013 Hermine Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/b9XID0Vfxgs"
		},
		{
			"closePrice": 468000,
			"daysOnMovoto": 37,
			"id": "34ae1c49-6dd8-478b-b128-e8725a79fa6e",
			"tnImgPath": "https://pi.movoto.com/p/12/40973681_0_23rvJi_p.jpeg",
			"listDate": "2021-11-08 00:00:00",
			"listingAgent": "P. Scott Rogers",
			"listPrice": 459000,
			"lotSize": 0,
			"sqftTotal": 1157,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973681",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1966,
			"zipCode": "94595",
			"path": "walnut-creek-ca/3441-golden-rain-rd-3-walnut-creek-ca-94595/pid_tn1wbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "559242b4-dbe0-4eef-a332-fa1a8cb1bdbd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-09 00:56:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.878171,
				"lng": -122.082435,
				"zipcode": "94595",
				"subPremise": "APT 3",
				"address": "3441 Golden Rain Rd #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 468000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 529000,
			"daysOnMovoto": 147,
			"id": "178d9c9c-e9e6-4b18-92de-13ae125a76ff",
			"tnImgPath": "https://pi.movoto.com/p/12/40959744_0_eENI3E_p.jpeg",
			"listDate": "2021-07-21 00:00:00",
			"listingAgent": "Tim Steffen",
			"listPrice": 519900,
			"lotSize": 0,
			"sqftTotal": 968,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959744",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Better Homes Realty",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94597",
			"path": "walnut-creek-ca/3183-wayside-plz-319-walnut-creek-ca-94597/pid_pwesbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "6dca611b-800c-4d55-b33d-d56c04df2fe6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-07-21 20:01:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-21 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.931594,
				"lng": -122.055627,
				"zipcode": "94597",
				"subPremise": "APT 319",
				"address": "3183 Wayside Plz #319"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 529000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 43,
			"id": "bd377abb-bf2e-477b-a14b-900d3d696f17",
			"tnImgPath": "https://pi.movoto.com/p/12/40972863_0_6rIRNQ_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Brady Hoover",
			"listPrice": 849999,
			"lotSize": 7665,
			"sqftTotal": 1336,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972863",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1975,
			"zipCode": "94597",
			"path": "walnut-creek-ca/1622-montin-ct-walnut-creek-ca-94597/pid_u1wsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c77e0996-1fa1-498f-b83c-f2e46b422c07",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-01 18:26:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.920565,
				"lng": -122.06832,
				"zipcode": "94597",
				"subPremise": "",
				"address": "1622 Montin Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 31,
			"id": "b78c754b-c517-4fbc-b126-d7c69cd72d4e",
			"tnImgPath": "https://pi.movoto.com/p/12/40973748_0_y7Zfjj_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Frank Nelson",
			"listPrice": 840000,
			"lotSize": 0,
			"sqftTotal": 1808,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973748",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1983,
			"zipCode": "94598",
			"path": "walnut-creek-ca/1854-stratton-cir-walnut-creek-ca-94598/pid_41csbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "546a7658-f242-4546-9848-bf7fba5cc685",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-21 20:00:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.920837,
				"lng": -122.039271,
				"zipcode": "94598",
				"subPremise": "",
				"address": "1854 Stratton Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 362000,
			"daysOnMovoto": 37,
			"id": "fb5274c9-447a-4b4c-a1cb-6898a56e79ec",
			"tnImgPath": "https://pi.movoto.com/p/12/40973070_0_FyF67Q_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Justin Paek",
			"listPrice": 349000,
			"lotSize": 0,
			"sqftTotal": 695,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973070",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94596",
			"path": "walnut-creek-ca/155-sharene-lane-113-walnut-creek-ca-94596-110_436405/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eccc482c-9b8a-4bb3-a97a-bb1c79ab9f76",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-03 16:46:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.901288,
				"lng": -122.05429,
				"zipcode": "94596",
				"subPremise": "APT 113",
				"address": "155 Sharene Ln #113"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 362000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 860000,
			"daysOnMovoto": 39,
			"id": "03df1256-e013-4263-8fec-46e42cf8db68",
			"tnImgPath": "https://pi.movoto.com/p/12/40970272_0_vyiBaF_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Peter Andersen",
			"listPrice": 849000,
			"lotSize": 13000,
			"sqftTotal": 1650,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970272",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1940,
			"zipCode": "94597",
			"path": "walnut-creek-ca/65-pleasant-valley-dr-walnut-creek-ca-94597/pid_ywvsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ad22d66c-9e8b-4303-94c2-35b685dadfef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-01 23:11:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.932393,
				"lng": -122.063215,
				"zipcode": "94597",
				"subPremise": "",
				"address": "65 Pleasant Valley Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 399000,
			"daysOnMovoto": 86,
			"id": "1a5f8f69-6ceb-47bc-b91b-ed46975cc3aa",
			"tnImgPath": "https://pi.movoto.com/p/12/40966884_0_Zmr7yV_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Jamie Lee",
			"listPrice": 399000,
			"lotSize": 0,
			"sqftTotal": 740,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966884",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Pacific Venture Properties",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1992,
			"zipCode": "94597",
			"path": "walnut-creek-ca/3173-wayside-plz-203-walnut-creek-ca-94597/pid_64esbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2170d365-52e0-46da-a3ce-18d8920ee11d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-09-16 04:41:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.931367,
				"lng": -122.05467,
				"zipcode": "94597",
				"subPremise": "APT 203",
				"address": "3173 Wayside Plz #203"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 399000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 710000,
			"daysOnMovoto": 28,
			"id": "bcb9f54a-fb33-43b4-859b-f568b5f77cce",
			"tnImgPath": "https://pi.movoto.com/p/12/40973596_0_JJNuyi_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Darrell Hoh",
			"listPrice": 698000,
			"lotSize": 1120,
			"sqftTotal": 1113,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973596",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1984,
			"zipCode": "94597",
			"path": "walnut-creek-ca/233-kingston-way-walnut-creek-ca-94596-12_28253001/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1524b8a7-2c13-4955-ae4d-17ea49b10ccd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-12 03:56:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.918947,
				"lng": -122.059693,
				"zipcode": "94597",
				"subPremise": "",
				"address": "233 Kingston Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 488000,
			"daysOnMovoto": 50,
			"id": "fd44acd2-0bf3-4d52-be21-f67ad1776666",
			"tnImgPath": "https://pi.movoto.com/p/12/40971682_0_A6By3m_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Yulanda Ong",
			"listPrice": 488000,
			"lotSize": 0,
			"sqftTotal": 1157,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971682",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Yulanda Ong Broker",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94595",
			"path": "walnut-creek-ca/2549-golden-rain-rd-1-walnut-creek-ca-94595/pid_yz1wbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4a9c0953-ba8c-46d3-adef-31b9f8888a4a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-21 02:31:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.879709,
				"lng": -122.081524,
				"zipcode": "94595",
				"subPremise": "APT 1",
				"address": "2549 Golden Rain Rd #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 488000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1220000,
			"daysOnMovoto": 65,
			"id": "4142dd80-f550-4e13-881a-96737676fa78",
			"tnImgPath": "https://pi.movoto.com/p/12/40969685_0_UZVRVJ_p.jpeg",
			"listDate": "2021-10-05 00:00:00",
			"listingAgent": "Kelly McCormick",
			"listPrice": 1195000,
			"lotSize": 7200,
			"sqftTotal": 1718,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969685",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94595",
			"path": "walnut-creek-ca/884-juanita-dr-walnut-creek-ca-94595/pid_967sbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2960d12c-1b28-4919-843a-3fd677e0e09e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-05 16:31:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-05 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2923357",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.88747,
				"lng": -122.084077,
				"zipcode": "94595",
				"subPremise": "",
				"address": "884 Juanita Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2923357"
		},
		{
			"closePrice": 395000,
			"daysOnMovoto": 70,
			"id": "32f0600e-fe6b-4f95-ae14-1cea5e79021c",
			"tnImgPath": "https://pi.movoto.com/p/12/40969083_0_bqAzFu_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Anita Cox",
			"listPrice": 398000,
			"lotSize": 0,
			"sqftTotal": 1162,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969083",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1969,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1208-skycrest-apt-8-walnut-creek-ca-94595-12_40969083/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -31000,
			"propertyId": "91d55b3d-f639-4e53-9df2-828514b3a339",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-09-30 14:46:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://www.tourbuzz.net/1910510?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.880914,
				"lng": -122.087013,
				"zipcode": "94595",
				"subPremise": "APT 8",
				"address": "1208 Skycrest #8"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 395000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourbuzz.net/1910510?idx=1"
		},
		{
			"closePrice": 348000,
			"daysOnMovoto": 70,
			"id": "ce869424-a4d7-46eb-b113-0c75a5fd58c2",
			"tnImgPath": "https://pi.movoto.com/p/12/40968870_0_nyjney_p.jpeg",
			"listDate": "2021-09-28 00:00:00",
			"listingAgent": "Maria Eberle",
			"listPrice": 348000,
			"lotSize": 0,
			"sqftTotal": 907,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968870",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1991,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1840-tice-creek-dr-apt-2312-walnut-creek-ca-94595-12_40968870/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c2c11d38-04eb-46d3-adcb-ddadc196a5a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-09-28 20:46:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 00:00:00",
			"virtualTourLink": "https://www.tourbuzz.net/public/vtour/display/1911094?idx=1#!/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.874609,
				"lng": -122.076327,
				"zipcode": "94595",
				"subPremise": "APT 2312",
				"address": "1840 Tice Creek Dr #2312"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 348000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourbuzz.net/public/vtour/display/1911094?idx=1#!/"
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 28,
			"id": "7c64077d-b133-4e1c-8ab7-efd44b1df24c",
			"tnImgPath": "https://pi.movoto.com/p/12/40973653_0_FnQeia_p.jpeg",
			"listDate": "2021-11-08 00:00:00",
			"listingAgent": "Andrea Scott",
			"listPrice": 569000,
			"lotSize": 1044,
			"sqftTotal": 1308,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973653",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1973,
			"zipCode": "94598",
			"path": "walnut-creek-ca/1923-olmo-way-walnut-creek-ca-94598/pid_z57rbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e070bb94-8147-4a9c-a8bb-a13d157956c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-11-08 20:36:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2922060",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.92818,
				"lng": -122.043057,
				"zipcode": "94598",
				"subPremise": "",
				"address": "1923 Olmo Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2922060"
		},
		{
			"closePrice": 740000,
			"daysOnMovoto": 25,
			"id": "9f95b836-bccd-4d3e-9239-e17fcd5cfce5",
			"tnImgPath": "https://pi.movoto.com/p/12/40973695_0_NIeYf7_p.jpeg",
			"listDate": "2021-11-08 00:00:00",
			"listingAgent": "Melanie Peterson-Katz",
			"listPrice": 649000,
			"lotSize": 0,
			"sqftTotal": 1178,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973695",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1985,
			"zipCode": "94597",
			"path": "walnut-creek-ca/578-pimlico-ct-walnut-creek-ca-94597/pid_dhysbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "afb04b2b-addb-4e47-a622-97dadc321b61",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-11-09 02:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.912977,
				"lng": -122.062048,
				"zipcode": "94597",
				"subPremise": "",
				"address": "578 Pimlico Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 740000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 410000,
			"daysOnMovoto": 29,
			"id": "2ea98242-675f-411d-96f8-9a790d51048b",
			"tnImgPath": "https://pi.movoto.com/p/12/40973313_0_AnzjUf_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Julie Germain",
			"listPrice": 405000,
			"lotSize": 0,
			"sqftTotal": 1301,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973313",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1865-golden-rain-rd-3-walnut-creek-ca-94595/pid_53ywbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "837d7976-ee87-4556-b595-d23786bea647",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-11-04 23:26:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2933052",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.877178,
				"lng": -122.080042,
				"zipcode": "94595",
				"subPremise": "APT 3",
				"address": "1865 Golden Rain Rd #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 410000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2933052"
		},
		{
			"closePrice": 600000,
			"daysOnMovoto": 58,
			"id": "957809b6-6940-4b61-b0f7-45a90d513099",
			"tnImgPath": "https://pi.movoto.com/p/12/40969902_0_I3YI7n_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Sally Roberts",
			"listPrice": 600000,
			"lotSize": 0,
			"sqftTotal": 1346,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969902",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1977,
			"zipCode": "94595",
			"path": "walnut-creek-ca/3520-rossmoor-pkwy-6-walnut-creek-ca-94595/pid_81btbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "28a2bdf9-d685-44c8-8364-78743868670f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-06 22:31:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.856054,
				"lng": -122.059711,
				"zipcode": "94595",
				"subPremise": "APT 6",
				"address": "3520 Rossmoor Pkwy #6"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 68,
			"id": "e36a1092-50a9-477f-b72e-bdd2608b2ebe",
			"tnImgPath": "https://pi.movoto.com/p/12/40968559_0_BbfFy2_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Allen Hibbard",
			"listPrice": 1545000,
			"lotSize": 11375,
			"sqftTotal": 2550,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968559",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHG RE Reliance Partners",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94598",
			"path": "walnut-creek-ca/2095-stratton-walnut-creek-ca-94598-12_40968559/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a5990b14-7b52-46f5-9852-ad762611eab5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-09-24 21:46:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.924419,
				"lng": -122.031758,
				"zipcode": "94598",
				"subPremise": "",
				"address": "2095 Stratton"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2310000,
			"daysOnMovoto": 25,
			"id": "962b8094-3360-42d7-9d4d-0909dcbadd15",
			"tnImgPath": "https://pi.movoto.com/p/12/40972517_0_yMfYii_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Jennifer DeVinney",
			"listPrice": 1898000,
			"lotSize": 44000,
			"sqftTotal": 2672,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972517",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1978,
			"zipCode": "94595",
			"path": "walnut-creek-ca/455-montecillo-dr-walnut-creek-ca-94595-12_28252689/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ae0d92c6-ee3e-471d-883c-2a4c9ec8cf26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-11-05 19:51:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.881432,
				"lng": -122.066544,
				"zipcode": "94595",
				"subPremise": "",
				"address": "455 Montecillo Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2310000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 26,
			"id": "6641ce74-4f63-4b26-97f2-0366157b70f9",
			"tnImgPath": "https://pi.movoto.com/p/12/40973202_0_zrrqiU_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Kathy Vendel",
			"listPrice": 1550000,
			"lotSize": 10528,
			"sqftTotal": 2159,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973202",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens RP",
			"photoCount": 5,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1965,
			"zipCode": "94598",
			"path": "walnut-creek-ca/2308-lomond-ln-walnut-creek-ca-94598/pid_psasbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "112a75e0-1ec9-4807-92d9-2a029994fedf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-11-04 07:26:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.91728,
				"lng": -122.02281,
				"zipcode": "94598",
				"subPremise": "",
				"address": "2308 Lomond Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 556000,
			"daysOnMovoto": 89,
			"id": "634b6279-36db-4acc-ae9d-6b65e55a8395",
			"tnImgPath": "https://pi.movoto.com/p/12/40965128_0_jiyjIU_p.jpeg",
			"listDate": "2021-09-02 00:00:00",
			"listingAgent": "Philip Hsieh",
			"listPrice": 559000,
			"lotSize": 1019,
			"sqftTotal": 927,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965128",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty One Group AMR",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94597",
			"path": "walnut-creek-ca/3183-wayside-plz-214-walnut-creek-ca-94597/pid_bnesbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "69e001d9-d813-4f7d-820e-56eae7e75461",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-09-02 20:51:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.931594,
				"lng": -122.055627,
				"zipcode": "94597",
				"subPremise": "APT 214",
				"address": "3183 Wayside Plz #214"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 556000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 660000,
			"daysOnMovoto": 23,
			"id": "01517e8e-4b6f-4ce9-a38a-dc197b058045",
			"tnImgPath": "https://pi.movoto.com/p/12/40973558_0_A37v7e_p.jpeg",
			"listDate": "2021-11-06 00:00:00",
			"listingAgent": "Nancy Reilly",
			"listPrice": 649000,
			"lotSize": 0,
			"sqftTotal": 1081,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973558",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94595",
			"path": "walnut-creek-ca/2033-pine-knoll-dr-6-walnut-creek-ca-94595/pid_eb0wbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2f55cc35-f38f-4dce-a841-2eb6b4d2f4ba",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-11-06 16:06:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-06 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2934058",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.874856,
				"lng": -122.079885,
				"zipcode": "94595",
				"subPremise": "APT 6",
				"address": "2033 Pine Knoll Dr #6"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 660000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2934058"
		},
		{
			"closePrice": 793000,
			"daysOnMovoto": 31,
			"id": "4b21eb14-320d-455c-a5cc-9307bebc6e44",
			"tnImgPath": "https://pi.movoto.com/p/12/40972680_0_any2BF_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Jim Olson",
			"listPrice": 788000,
			"lotSize": 0,
			"sqftTotal": 1406,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972680",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1940-skycrest-dr-apt-1-walnut-creek-ca-94595-12_40972680/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b07c223f-3f57-44fd-9269-212e64b953c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-29 20:56:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://click.pstmrk.it/2sm/listings.allaccessphoto.com%2Fv2%2F1940-skycrest-dr-1-walnut-creek-ca-94595-1287181%2Funbranded/6LzhQSYN/cUpU/RN3Quku_ok/djJfZGVsaXZlcnk",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.875076,
				"lng": -122.087737,
				"zipcode": "94595",
				"subPremise": "APT 1",
				"address": "1940 Skycrest Dr #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 793000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://click.pstmrk.it/2sm/listings.allaccessphoto.com%2Fv2%2F1940-skycrest-dr-1-walnut-creek-ca-94595-1287181%2Funbranded/6LzhQSYN/cUpU/RN3Quku_ok/djJfZGVsaXZlcnk"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 31,
			"id": "1c93fc28-ace7-43d1-b8fc-66154f704356",
			"tnImgPath": "https://pi.movoto.com/p/12/40972641_0_umZ2If_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Kelly McCormick",
			"listPrice": 1195000,
			"lotSize": 11500,
			"sqftTotal": 1104,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972641",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "94595",
			"path": "walnut-creek-ca/456-bridge-rd-walnut-creek-ca-94595/pid_016sbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "494cc39e-b01b-451c-b764-8ac2b6d4f12e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-29 17:21:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2931536",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.888979,
				"lng": -122.072426,
				"zipcode": "94595",
				"subPremise": "",
				"address": "456 Bridge Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2931536"
		},
		{
			"closePrice": 1225000,
			"daysOnMovoto": 32,
			"id": "3bf90e04-7ed8-4c2c-9548-0812615bcc8a",
			"tnImgPath": "https://pi.movoto.com/p/12/40972461_0_2Zy2az_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Kathy Vendel",
			"listPrice": 1050000,
			"lotSize": 7700,
			"sqftTotal": 1404,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972461",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens RP",
			"photoCount": 6,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94598",
			"path": "walnut-creek-ca/2842-san-carlos-dr-walnut-creek-ca-94598/pid_1r8rbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "127a291e-652a-4cb1-ba94-b2309e95172d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-28 06:31:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2923858",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.911775,
				"lng": -122.029782,
				"zipcode": "94598",
				"subPremise": "",
				"address": "2842 San Carlos Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2923858"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 53,
			"id": "36b9b137-a765-4594-8fb7-d813d09f7e0f",
			"tnImgPath": "https://pi.movoto.com/p/12/40970115_0_rZ2b37_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Suzanne Masella",
			"listPrice": 1299000,
			"lotSize": 7500,
			"sqftTotal": 1716,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970115",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94598",
			"path": "walnut-creek-ca/248-santa-fe-dr-walnut-creek-ca-94598/pid_5u6rbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d8abdde-3b9e-457d-9aab-6525819f3f3e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-07 22:51:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=K6NcCcqNH8k&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.914524,
				"lng": -122.034844,
				"zipcode": "94598",
				"subPremise": "",
				"address": "248 Santa Fe Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=K6NcCcqNH8k&mls=1"
		},
		{
			"closePrice": 560000,
			"daysOnMovoto": 32,
			"id": "617c4fad-ac89-4f5e-b0c7-f65109334316",
			"tnImgPath": "https://pi.movoto.com/p/12/40972123_0_6Vi7v7_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Brandon Kersis",
			"listPrice": 535000,
			"lotSize": 0,
			"sqftTotal": 958,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972123",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1966,
			"zipCode": "94596",
			"path": "walnut-creek-ca/1743-carmel-dr-3-walnut-creek-ca-94596/pid_wj2sbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "acf370d2-e129-40b8-8b9f-590b8e5e0488",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-23 18:16:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": "http://www.1743carmeldrive.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.902574,
				"lng": -122.058292,
				"zipcode": "94596",
				"subPremise": "APT 3",
				"address": "1743 Carmel Dr #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.1743carmeldrive.com/"
		},
		{
			"closePrice": 648000,
			"daysOnMovoto": 41,
			"id": "a031f2bc-88a4-4cfb-8c0d-c723b3b3d053",
			"tnImgPath": "https://pi.movoto.com/p/12/40969964_0_YQvmAa_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Lori Young",
			"listPrice": 648000,
			"lotSize": 0,
			"sqftTotal": 1138,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969964",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rossmoor Realty / J.H. Russell",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1990,
			"zipCode": "94595",
			"path": "walnut-creek-ca/1860-tice-creek-dr-apt-1433-walnut-creek-ca-94595-12_40969964/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ff095db7-c6cb-48fd-a460-6648df4ceede",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-14 19:11:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2927174",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.872191,
				"lng": -122.075037,
				"zipcode": "94595",
				"subPremise": "APT 1433",
				"address": "1860 Tice Creek Dr #1433"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 648000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2927174"
		},
		{
			"closePrice": 349000,
			"daysOnMovoto": 62,
			"id": "ef4ca13a-902e-48c8-bd53-ab416612f717",
			"tnImgPath": "https://pi.movoto.com/p/12/40968304_0_qfN32v_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Doyle Keeton",
			"listPrice": 349000,
			"lotSize": 0,
			"sqftTotal": 746,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968304",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Golden Gate Sothebys International Realty",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94596",
			"path": "walnut-creek-ca/470-n-civic-apt-312-walnut-creek-ca-94596-12_40968304/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -8900,
			"propertyId": "18fcac5d-f0d8-4ae3-8345-1eb75edf519c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-23 21:22:01",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "http://www.470ncivicdr312.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.914494,
				"lng": -122.059201,
				"zipcode": "94596",
				"subPremise": "APT 312",
				"address": "470 N Civic #312"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 349000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.470ncivicdr312.com/?mls"
		},
		{
			"closePrice": 1298000,
			"daysOnMovoto": 19,
			"id": "5a7bc197-35a7-4fb1-a47a-f7435805a3e4",
			"tnImgPath": "https://pi.movoto.com/p/12/40973209_0_qNyviV_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "John Bennison",
			"listPrice": 1285000,
			"lotSize": 8132,
			"sqftTotal": 1849,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973209",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94596",
			"path": "walnut-creek-ca/1401-whitecliff-way-walnut-creek-ca-94596-12_40519377/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "efb3a8c6-8268-40b3-beea-cd0fb3396720",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-11-04 15:16:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.881528,
				"lng": -122.024923,
				"zipcode": "94596",
				"subPremise": "",
				"address": "1401 Whitecliff Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1298000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 417000,
			"daysOnMovoto": 31,
			"id": "3b61da82-2205-4fa1-b967-75eaedc35012",
			"tnImgPath": "https://pi.movoto.com/p/12/40972118_0_YjNmvy_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Anastasia Stephanopoulos",
			"listPrice": 412000,
			"lotSize": 0,
			"sqftTotal": 760,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972118",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "94598",
			"path": "walnut-creek-ca/341-masters-ct-4-walnut-creek-ca-94598/pid_2pbsbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d297ebe0-c095-4c30-92ea-7ff7d65d338e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-23 17:36:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/341-masters-ct-4-walnut-creek-ca-94598-1275757/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.911502,
				"lng": -122.049123,
				"zipcode": "94598",
				"subPremise": "APT 4",
				"address": "341 Masters Ct #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 417000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/341-masters-ct-4-walnut-creek-ca-94598-1275757/unbranded"
		},
		{
			"closePrice": 1135000,
			"daysOnMovoto": 1,
			"id": "401c26c8-d9df-4822-a427-267ba6a3f054",
			"tnImgPath": "https://pi.movoto.com/p/101/321109145_0_ajjvBZ_p.jpeg",
			"listDate": "2021-11-21 00:00:00",
			"listingAgent": "Michael A Nelson",
			"listPrice": 1098000,
			"lotSize": 1921,
			"sqftTotal": 2457,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321109145",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway",
			"photoCount": 64,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1979,
			"zipCode": "94597",
			"path": "walnut-creek-ca/1967-owl-ridge-ct-walnut-creek-ca-94597/pid_crysbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "faabed3a-7cde-4ab5-8310-be538864a747",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-11-21 08:13:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-21 00:00:00",
			"virtualTourLink": "https://www.zillow.com/view-3d-home/262e5e7e-174a-4493-a733-0f7c9e29abe3?setAttribution=mls&wl=true",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Walnut Creek",
				"lat": 37.916973,
				"lng": -122.098851,
				"zipcode": "94597",
				"subPremise": "",
				"address": "1967 Owl Ridge Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1135000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.zillow.com/view-3d-home/262e5e7e-174a-4493-a733-0f7c9e29abe3?setAttribution=mls&wl=true"
		}
	]