exports.FOSTER_CITY_LISTINGS = [
		{
			"closePrice": 757000,
			"daysOnMovoto": 0,
			"id": "cb9e0c8f-eeb4-4857-93e6-a54fdd1587aa",
			"tnImgPath": "https://pi.movoto.com/p/102/421609163_0_maavY6_p.jpeg",
			"listDate": null,
			"listingAgent": "Silvia Gutierrez Monney",
			"listPrice": 738000,
			"lotSize": 432988,
			"sqftTotal": 998,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421609163",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/5201-admiralty-ln-foster-city-ca-94404-100_ml81431457/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1354897f-25b9-46c2-8ef9-5c7c696eb27e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-03 05:39:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 05:39:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.561437,
				"lng": -122.267213,
				"zipcode": "94404",
				"subPremise": "",
				"address": "5201 Admiralty Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 757000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 595000,
			"daysOnMovoto": 40,
			"id": "1b1118b6-6269-4e38-b880-afe466af5087",
			"tnImgPath": "https://pi.movoto.com/p/110/421607662_0_2R3UnM_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Anthony Christen",
			"listPrice": 595000,
			"lotSize": 701665,
			"sqftTotal": 800,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607662",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/1170-foster-city-blvd-apt-217-foster-city-ca-94404-110_421607662/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "48e52f41-946e-46c8-8c79-7851db3adf63",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-28 00:01:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.551279,
				"lng": -122.253332,
				"zipcode": "94404",
				"subPremise": "APT 217",
				"address": "1170 Foster City Blvd #217"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 595000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 41,
			"id": "02d53cef-438f-4a77-88b7-6de3c8e0897c",
			"tnImgPath": "https://pi.movoto.com/p/12/40970024_0_vN6Zbu_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Herman Chan",
			"listPrice": 2495000,
			"lotSize": 5858,
			"sqftTotal": 2300,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970024",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's Int'l Re",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94404",
			"path": "foster-city-ca/602-bahama-ln-foster-city-ca-94404-100_ml80617478/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f9b420a5-62dd-406d-9147-b3d2419f5bc3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-16 04:00:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.54152,
				"lng": -122.259379,
				"zipcode": "94404",
				"subPremise": "",
				"address": "602 Bahama Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1430000,
			"daysOnMovoto": 10,
			"id": "e3d91113-eaf7-4c90-96dd-a394de6bc7bb",
			"tnImgPath": "https://pi.movoto.com/p/110/421607569_0_fjJfjN_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Alexis Cahalin",
			"listPrice": 1350000,
			"lotSize": 1440,
			"sqftTotal": 1380,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607569",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 55,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1974,
			"zipCode": "94404",
			"path": "foster-city-ca/802-magellan-ln-foster-city-ca-94404-110_421607569/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "642cd043-f85e-46c3-badf-02cb62b519e6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-06 00:00:00",
			"createdAt": "2021-10-28 07:16:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=ryzqcLbiLxy&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.552348,
				"lng": -122.265677,
				"zipcode": "94404",
				"subPremise": "",
				"address": "802 Magellan Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1430000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=ryzqcLbiLxy&brand=0"
		},
		{
			"closePrice": 610000,
			"daysOnMovoto": 69,
			"id": "8b302c93-cf78-4c1f-8619-a209ca6a3d5c",
			"tnImgPath": "https://pi.movoto.com/p/110/421595033_0_2yFBrm_p.jpeg",
			"listDate": "2021-08-17 00:00:00",
			"listingAgent": "Ellie Kravets",
			"listPrice": 599000,
			"lotSize": 432988,
			"sqftTotal": 748,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595033",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 53,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/4107-admiralty-ln-foster-city-ca-94404-100_ml81765294/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "daf7d730-f087-4d61-88ea-62be139dbb9e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-16 17:26:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Vkx6i3am4ZF",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.561437,
				"lng": -122.267213,
				"zipcode": "94404",
				"subPremise": "",
				"address": "4107 Admiralty Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Vkx6i3am4ZF"
		},
		{
			"closePrice": 1710000,
			"daysOnMovoto": 16,
			"id": "6bf66012-bf9c-4ad5-9554-ff60b42a2007",
			"tnImgPath": "https://pi.movoto.com/p/101/421594090_0_mAvI6F_p.jpeg",
			"listDate": "2021-10-03 00:00:00",
			"listingAgent": "Bob A Friestad",
			"listPrice": 1499000,
			"lotSize": 169803,
			"sqftTotal": 1618,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421594090",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Madison Company",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1977,
			"zipCode": "94404",
			"path": "foster-city-ca/890-andromeda-ln-foster-city-ca-94404-101_421594090/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3fdc4f7f-6edb-4680-941a-c7d4301c581c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-10-03 07:13:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.548061,
				"lng": -122.270895,
				"zipcode": "94404",
				"subPremise": "",
				"address": "890 Andromeda Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 38,
			"id": "5b439c73-7bc7-47d4-b679-95abf1a598e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40966647_0_2bMaby_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Bruce Wagg",
			"listPrice": 1495000,
			"lotSize": 6175,
			"sqftTotal": 1840,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966647",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "KW Santa Clara Valley, Inc.",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94404",
			"path": "foster-city-ca/991-swan-st-foster-city-ca-94404-12_40966647/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7cfb1120-3c83-42c4-88e2-6bb9521ff7c3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-18 00:00:00",
			"createdAt": "2021-09-10 19:06:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": "https://991SwanSt46798mls.f8re.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.567878,
				"lng": -122.256549,
				"zipcode": "94404",
				"subPremise": "",
				"address": "991 Swan St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://991SwanSt46798mls.f8re.com"
		},
		{
			"closePrice": 698000,
			"daysOnMovoto": 42,
			"id": "3f6a5710-e05e-4780-90fc-7b1ef5d22718",
			"tnImgPath": "https://pi.movoto.com/p/12/40963107_0_jRAuZ6_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Al Melgoza",
			"listPrice": 658000,
			"lotSize": 272190,
			"sqftTotal": 748,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963107",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Realty ONE Group Future",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/1067-shell-blvd-apt-3-foster-city-ca-94404-100_ml80377092/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dcf838e5-2f81-49c6-bfb3-6c5a1dd4680d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-08-13 20:11:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2905676",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.549505,
				"lng": -122.263246,
				"zipcode": "94404",
				"subPremise": "APT 3",
				"address": "1067 Shell Blvd #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 698000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2905676"
		},
		{
			"closePrice": 2358000,
			"daysOnMovoto": 13,
			"id": "33910185-9f4b-46d2-bbfc-4e84d3e77f50",
			"tnImgPath": "https://pi.movoto.com/p/110/421592229_0_RRfEqB_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Lanting Chen",
			"listPrice": 1990000,
			"lotSize": 7220,
			"sqftTotal": 2450,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421592229",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/265-curlew-ct-foster-city-ca-94404-100_ml80647045/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3223b7e6-392e-4517-8f6f-ce19d227e033",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-21 00:00:00",
			"createdAt": "2021-09-09 19:31:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/601235607",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.57065,
				"lng": -122.262838,
				"zipcode": "94404",
				"subPremise": "",
				"address": "265 Curlew Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2358000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/601235607"
		},
		{
			"closePrice": 1680000,
			"daysOnMovoto": 32,
			"id": "095c83b3-8a14-40b2-8e76-70c12c908856",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-08-09 00:00:00",
			"listingAgent": "Sarah Arredondo",
			"listPrice": 1680000,
			"lotSize": null,
			"sqftTotal": 2300,
			"mlsDbNumber": 210,
			"mls": {
				"dateHidden": null,
				"id": 210
			},
			"mlsNumber": "210025615",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1968,
			"zipCode": "94404",
			"path": "foster-city-ca/370-grunion-ct-foster-city-ca-94404-202_210025615/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "30c099b3-96f6-4388-8698-28aeca47cea7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-09-09 03:12:20",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-08-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.554286,
				"lng": -122.252826,
				"zipcode": "94404",
				"subPremise": "",
				"address": "370 Grunion Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1680000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 52,
			"id": "f0f18542-78c5-41e1-ad87-833119b9e979",
			"tnImgPath": "https://pi.movoto.com/p/210/210020354_0_7YQrI3_p.jpeg",
			"listDate": "2021-07-19 00:00:00",
			"listingAgent": "Sarah Arredondo",
			"listPrice": 1994888,
			"lotSize": null,
			"sqftTotal": 1820,
			"mlsDbNumber": 210,
			"mls": {
				"dateHidden": null,
				"id": 210
			},
			"mlsNumber": "210020354",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 11,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/394-port-royal-foster-city-ca-94404-210_210020354/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bcb1c5c1-436d-4dda-a983-1fafe7400e68",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-07-20 01:54:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-19 00:00:00",
			"virtualTourLink": "https://www.propertypanorama.com/instaview/snd/210020354",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.543087,
				"lng": -122.275203,
				"zipcode": "94404",
				"subPremise": "",
				"address": "394 Port Royal"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.propertypanorama.com/instaview/snd/210020354"
		},
		{
			"closePrice": 1860000,
			"daysOnMovoto": 42,
			"id": "33548e0a-3794-436a-8602-e94c6b6b0029",
			"tnImgPath": "https://pi.movoto.com/p/12/40957047_0_R6NbmY_p.jpeg",
			"listDate": "2021-07-02 00:00:00",
			"listingAgent": "Jason Kerner",
			"listPrice": 1595000,
			"lotSize": 6650,
			"sqftTotal": 1630,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957047",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Town Real Estate",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94404",
			"path": "foster-city-ca/800-arcturus-cir-foster-city-ca-94404-12_40957047/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "595c8698-8653-4be9-b044-ca07819c556e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-13 00:00:00",
			"createdAt": "2021-07-02 18:26:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.54896,
				"lng": -122.276598,
				"zipcode": "94404",
				"subPremise": "",
				"address": "800 Arcturus Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 20,
			"id": "519a37b6-1d81-4a1b-be50-ac196c1919e2",
			"tnImgPath": "https://pi.movoto.com/p/110/421575557_0_UB2Qa6_p.jpeg",
			"listDate": "2021-07-21 00:00:00",
			"listingAgent": "Margaret Chang",
			"listPrice": 1880000,
			"lotSize": 5460,
			"sqftTotal": 2160,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421575557",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Comax Realty, Inc.",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/282-killdeer-ct-foster-city-ca-94404-110_421575557/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "48c4dec5-d406-489f-ab23-db74621429f2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-10 00:00:00",
			"createdAt": "2021-07-21 22:11:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.567682,
				"lng": -122.259518,
				"zipcode": "94404",
				"subPremise": "",
				"address": "282 Killdeer Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1331000,
			"daysOnMovoto": 63,
			"id": "92647568-411a-4ad6-9ddf-dbb88e823982",
			"tnImgPath": "https://pi.movoto.com/p/12/40951617_0_NjUnBn_p.jpeg",
			"listDate": "2021-05-26 00:00:00",
			"listingAgent": "Doris Dy-Roberts",
			"listPrice": 1249950,
			"lotSize": 960,
			"sqftTotal": 1410,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951617",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Top Mission Realty & Invest",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1987,
			"zipCode": "94404",
			"path": "foster-city-ca/116-cityhomes-foster-city-ca-94404-12_40951617/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1a5e0408-2830-4b4a-8406-f27bb1bf42b7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-28 00:00:00",
			"createdAt": "2021-05-26 23:36:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.555755,
				"lng": -122.2786,
				"zipcode": "94404",
				"subPremise": "",
				"address": "116 Cityhomes"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1331000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2460000,
			"daysOnMovoto": 39,
			"id": "8e1a9e8d-1cb8-4c0b-9b12-495c26888c0a",
			"tnImgPath": "https://pi.movoto.com/p/12/40953885_0_F6bIR7_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Rica Ruiz",
			"listPrice": 1998000,
			"lotSize": 4600,
			"sqftTotal": 2180,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953885",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Meraki Real Estate",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94404",
			"path": "foster-city-ca/515-pitcairn-dr-foster-city-ca-94404-12_40953885/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bdbd903c-6ed3-44a0-bfc9-3f7b5d8a071f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-20 00:00:00",
			"createdAt": "2021-06-11 17:51:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": "Https://player.vimeo.com/video/562036775?title=0&byline=0&portrait=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.539263,
				"lng": -122.266784,
				"zipcode": "94404",
				"subPremise": "",
				"address": "515 Pitcairn Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2460000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://player.vimeo.com/video/562036775?title=0&byline=0&portrait=0"
		},
		{
			"closePrice": 1850888,
			"daysOnMovoto": 39,
			"id": "21d06cda-7cf1-4e6b-ab78-dbc8d288fbf4",
			"tnImgPath": "https://pi.movoto.com/p/12/40950494_0_InJi3f_p.jpeg",
			"listDate": "2021-05-31 00:00:00",
			"listingAgent": "Cindy Han",
			"listPrice": 1499000,
			"lotSize": 95924,
			"sqftTotal": 1618,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40950494",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1977,
			"zipCode": "94404",
			"path": "foster-city-ca/838-andromeda-ln-foster-city-ca-94404-100_ml80781740/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "32b99d8e-7e8f-42af-a004-93303b7032bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-09 00:00:00",
			"createdAt": "2021-05-31 16:51:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-31 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=dRDSTuBnpWR&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.548979,
				"lng": -122.272515,
				"zipcode": "94404",
				"subPremise": "",
				"address": "838 Andromeda Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=dRDSTuBnpWR&brand=0"
		},
		{
			"closePrice": 1105000,
			"daysOnMovoto": 14,
			"id": "da26f6e1-6233-45ab-b4b7-d4b32bd8fe42",
			"tnImgPath": "https://pi.movoto.com/p/12/40954666_0_r2z6zm_p.jpeg",
			"listDate": "2021-06-16 00:00:00",
			"listingAgent": "Sharmaine Capelli",
			"listPrice": 1100000,
			"lotSize": 701665,
			"sqftTotal": 1593,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954666",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/1170-foster-city-blvd-apt-112-foster-city-ca-94404-100_ml80223382/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "045e7e06-2c37-4951-9527-5723a30e4e45",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-30 00:00:00",
			"createdAt": "2021-06-17 04:26:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-16 00:00:00",
			"virtualTourLink": "Https://www.aryeo.com/v2/adeb839c-1cb5-455f-afee-ba48a544decc/videos/34700",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.551279,
				"lng": -122.253332,
				"zipcode": "94404",
				"subPremise": "APT 112",
				"address": "1170 Foster City Blvd #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1105000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.aryeo.com/v2/adeb839c-1cb5-455f-afee-ba48a544decc/videos/34700"
		},
		{
			"closePrice": 2701000,
			"daysOnMovoto": 33,
			"id": "9b128d23-3535-4f54-9efb-9fcd5d2c5bd7",
			"tnImgPath": "https://pi.movoto.com/p/12/40951056_0_uNnaa6_p.jpeg",
			"listDate": "2021-05-21 00:00:00",
			"listingAgent": "Elizabeth Mooring",
			"listPrice": 2298000,
			"lotSize": 9120,
			"sqftTotal": 2380,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951056",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/100-goldhunter-ct-foster-city-ca-94404-100_ml89912606/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a878ea3e-3bcf-44c6-b263-537c64f42f0e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-23 00:00:00",
			"createdAt": "2021-05-21 20:06:52",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.564399,
				"lng": -122.267095,
				"zipcode": "94404",
				"subPremise": "",
				"address": "100 Goldhunter Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2701000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 61,
			"id": "3afcfc06-5431-49f7-9977-738ac8fcdfb0",
			"tnImgPath": "https://pi.movoto.com/p/101/421528076_0_YY7aRr_p.jpeg",
			"listDate": "2021-04-16 00:00:00",
			"listingAgent": "Romeo Aurelio",
			"listPrice": 929950,
			"lotSize": 1163,
			"sqftTotal": 1213,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421528076",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Century 21 Real Estate Alliance, Inc.",
			"photoCount": 49,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "94404",
			"path": "foster-city-ca/800-sea-spray-ln-apt-212-foster-city-ca-94404-100_ml81818228/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "fc435744-e15c-4e6e-8496-e6252510c6c8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-16 00:00:00",
			"createdAt": "2021-04-16 07:18:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-16 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/523383028",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.552802,
				"lng": -122.280663,
				"zipcode": "94404",
				"subPremise": "APT 212",
				"address": "800 Sea Spray Ln #212"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/523383028"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 99,
			"id": "90e549a0-7d96-414c-8041-8548fecd55bb",
			"tnImgPath": "https://pi.movoto.com/p/12/40938839_0_ma6v6m_p.jpeg",
			"listDate": "2021-02-23 00:00:00",
			"listingAgent": "Ernette Baker",
			"listPrice": 1743000,
			"lotSize": 0,
			"sqftTotal": 2249,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938839",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "SummerHill Brokerage, Inc.",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "94404",
			"path": "foster-city-ca/348-calypso-ln-apt-18-foster-city-ca-94404-12_40938839/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5fafdef8-6fca-426f-a94a-980b036ae3bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-02 00:00:00",
			"createdAt": "2021-02-23 23:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"zipcode": "94404",
				"subPremise": "APT 18",
				"address": "348 Calypso Ln #18"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1875000,
			"daysOnMovoto": 7,
			"id": "d5d9d8bd-7407-41b0-b594-c34276b90a97",
			"tnImgPath": "https://pi.movoto.com/p/110/421548835_0_7F2Jyq_p.jpeg",
			"listDate": "2021-05-14 00:00:00",
			"listingAgent": "Emily Tam",
			"listPrice": 1595000,
			"lotSize": 5225,
			"sqftTotal": 1630,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421548835",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway-Franciscan",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1972,
			"zipCode": "94404",
			"path": "foster-city-ca/203-pelican-ct-foster-city-ca-94404-100_ml81728860/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f1ed752-3041-4fbb-ab15-e7278faa55c3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-21 00:00:00",
			"createdAt": "2021-05-14 08:22:01",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-14 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=QidWcNMVMeB",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.569343,
				"lng": -122.257258,
				"zipcode": "94404",
				"subPremise": "",
				"address": "203 Pelican Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=QidWcNMVMeB"
		},
		{
			"closePrice": 1375000,
			"daysOnMovoto": 42,
			"id": "8b905193-faf4-49b9-bc24-81aa532c8503",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-05 00:00:00",
			"listingAgent": "Alex Feldman",
			"listPrice": 1275000,
			"lotSize": 1777,
			"sqftTotal": 1290,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837411",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "94404",
			"path": "foster-city-ca/1064-eagle-ln-foster-city-ca-94404-100_ml81837411/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5c6a97d7-75d6-46f2-bbc6-c6d8b32068eb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-11 10:30:15",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.563041,
				"lng": -122.254219,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1064 Eagle Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1375000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2380000,
			"daysOnMovoto": 46,
			"id": "3c51c185-d650-4c84-8f01-1bfe4794b9ce",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-01 00:00:00",
			"listingAgent": "Mary Bee",
			"listPrice": 2498888,
			"lotSize": 6491,
			"sqftTotal": 2420,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836971",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "94404",
			"path": "foster-city-ca/106-goldhunter-ct-foster-city-ca-94404-100_ml80111782/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "00daef2d-38e6-4dfd-8382-0fec46aeb26f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-16 17:00:36",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-01 00:00:00",
			"virtualTourLink": "Https://tours.robertbarbuttiphotography.com/1805495?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.564721,
				"lng": -122.267075,
				"zipcode": "94404",
				"subPremise": "",
				"address": "106 Goldhunter Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2380000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.robertbarbuttiphotography.com/1805495?idx=1"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 35,
			"id": "e84c0329-78f6-4e2d-bbc3-e242b074b213",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-09 00:00:00",
			"listingAgent": "Caroline Huo",
			"listPrice": 1998000,
			"lotSize": 8450,
			"sqftTotal": 2130,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81838333",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1967,
			"zipCode": "94404",
			"path": "foster-city-ca/131-barkentine-st-foster-city-ca-94404-100_ml81838333/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "71f2b0e5-5800-4567-b10c-9aead25181ea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-14 00:00:00",
			"createdAt": "2021-05-11 10:30:16",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.551283,
				"lng": -122.259673,
				"zipcode": "94404",
				"subPremise": "",
				"address": "131 Barkentine St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1840000,
			"daysOnMovoto": 71,
			"id": "ed20eb57-7c5b-4347-9bb5-1cde1b26ef67",
			"tnImgPath": "https://pi.movoto.com/p/12/40938816_0_UIqBA7_p.jpeg",
			"listDate": "2021-02-23 00:00:00",
			"listingAgent": "Ernette Baker",
			"listPrice": 1857000,
			"lotSize": 0,
			"sqftTotal": 2475,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938816",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "SummerHill Brokerage, Inc.",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "94404",
			"path": "foster-city-ca/162-atlantis-ln-apt-4-foster-city-ca-94404-12_40938816/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ded18f8e-0493-43fb-80c4-fb67c53064ad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-05 00:00:00",
			"createdAt": "2021-02-23 20:36:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.565386,
				"lng": -122.268776,
				"zipcode": "94404",
				"subPremise": "APT 4",
				"address": "162 Atlantis Ln #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1840000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1675000,
			"daysOnMovoto": 58,
			"id": "b170c9c6-9cd7-47e7-98b0-7a936564202b",
			"tnImgPath": "https://pi.movoto.com/p/12/40938854_0_rQEur7_p.jpeg",
			"listDate": "2021-02-23 00:00:00",
			"listingAgent": "Ernette Baker",
			"listPrice": 1698000,
			"lotSize": 0,
			"sqftTotal": 2249,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938854",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "SummerHill Brokerage, Inc.",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "94404",
			"path": "foster-city-ca/350-calypso-ln-apt-19-foster-city-ca-94404-12_40938854/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dcdd2c5f-3ba3-4822-afb4-2f6817efd15f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-22 00:00:00",
			"createdAt": "2021-02-23 23:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"zipcode": "94404",
				"subPremise": "APT 19",
				"address": "350 Calypso Ln #19"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 23,
			"id": "b9ded405-6a21-49ba-99f7-be830705f4b0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835271_0_Q6Vn2I_p.jpeg",
			"listDate": "2021-03-22 11:39:28",
			"listingAgent": "Mary Bee",
			"listPrice": 2399000,
			"lotSize": 5776,
			"sqftTotal": 2250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835271",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1981,
			"zipCode": "94404",
			"path": "foster-city-ca/1044-windjammer-cir-foster-city-ca-94404-100_ml80561350/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7f8c947b-c897-4809-9d00-0d98d0b16379",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-22 18:42:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-22 11:39:28",
			"virtualTourLink": "Https://tours.robertbarbuttiphotography.com/1801123?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.551104,
				"lng": -122.261573,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1044 Windjammer Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.robertbarbuttiphotography.com/1801123?idx=1"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 42,
			"id": "a3a58512-8052-49e4-a7c7-277b6096cf0d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831925_0_3VRQmN_p.jpeg",
			"listDate": "2021-03-01 13:56:56",
			"listingAgent": "David Murthy",
			"listPrice": 849000,
			"lotSize": null,
			"sqftTotal": 1349,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831925",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Real Estate EBroker",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/1111-compass-ln-apt-106-foster-city-ca-94404-100_ml81202360/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "174d74e3-5625-4d51-ac63-483590e92742",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-03-01 22:07:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-01 13:56:56",
			"virtualTourLink": "Https://player.vimeo.com/video/518088011",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.551566,
				"lng": -122.253708,
				"zipcode": "94404",
				"subPremise": "APT 106",
				"address": "1111 Compass Ln #106"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://player.vimeo.com/video/518088011"
		},
		{
			"closePrice": 939000,
			"daysOnMovoto": 63,
			"id": "88a38a67-f9e6-46d2-8da2-6cd2f72d9c6b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828921_0_7ZinEe_p.jpeg",
			"listDate": "2021-02-08 11:13:46",
			"listingAgent": "Vera Hatem",
			"listPrice": 938888,
			"lotSize": null,
			"sqftTotal": 1496,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828921",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 46,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/5108-admiralty-ln-foster-city-ca-94404-100_ml81440461/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1140beb8-f859-4ea0-991d-e838eef40d56",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-02-19 11:02:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-08 11:13:46",
			"virtualTourLink": "Https://5108AdmiraltyLn36318mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.561437,
				"lng": -122.267213,
				"zipcode": "94404",
				"subPremise": "",
				"address": "5108 Admiralty Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 939000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://5108AdmiraltyLn36318mls.f8re.com/"
		},
		{
			"closePrice": 1512888,
			"daysOnMovoto": 36,
			"id": "744c6766-9ec4-4913-97d0-ece1d609c220",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832208_0_7qyYRn_p.jpeg",
			"listDate": "2021-03-03 09:02:13",
			"listingAgent": "Yu-en Tsai",
			"listPrice": 1388000,
			"lotSize": 1777,
			"sqftTotal": 1670,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832208",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Valley Vine Realty",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "94404",
			"path": "foster-city-ca/778-santa-maria-ln-foster-city-ca-94404-100_ml81235005/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a04ff877-dcd8-4810-9349-fdcf3f5d6885",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-03 17:07:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 09:02:13",
			"virtualTourLink": "Https://my.matterport.com/show/?m=xASekeJtjyn",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.560743,
				"lng": -122.262289,
				"zipcode": "94404",
				"subPremise": "",
				"address": "778 Santa Maria Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1512888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=xASekeJtjyn"
		},
		{
			"closePrice": 1180888,
			"daysOnMovoto": 35,
			"id": "c809a0f3-f152-41c8-b292-cefe3c4103de",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832236_0_NYMYBZ_p.jpeg",
			"listDate": "2021-03-03 10:43:36",
			"listingAgent": "Lucy Goldenshteyn",
			"listPrice": 1098000,
			"lotSize": null,
			"sqftTotal": 1606,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832236",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 44,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1999,
			"zipCode": "94404",
			"path": "foster-city-ca/860-meridian-bay-ln-apt-127-foster-city-ca-94404-100_ml81672113/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "86ef00bc-d9a4-4081-b9e8-d3510fbcc830",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-03 18:47:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 10:43:36",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.553985,
				"lng": -122.278843,
				"zipcode": "94404",
				"subPremise": "APT 127",
				"address": "860 Meridian Bay Ln #127"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 45,
			"id": "4263d89e-04d8-4043-9e69-f931d40530f3",
			"tnImgPath": "https://pi.movoto.com/p/12/40938399_0_y3ZAIJ_p.jpeg",
			"listDate": "2021-02-20 00:00:00",
			"listingAgent": "Donna Sapienza-Gutridge",
			"listPrice": 849500,
			"lotSize": 272190,
			"sqftTotal": 1042,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938399",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "MERRILL SIGNATURE PROPERTIES",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94404",
			"path": "foster-city-ca/1025-shell-blvd-apt-11-foster-city-ca-94404-100_ml80153609/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "24152fce-5af2-4c63-a3cc-13a820169b41",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-20 16:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-20 00:00:00",
			"virtualTourLink": "Http://listings.nextdoorphotos.com/1025shellblvdapt11/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.549748,
				"lng": -122.263253,
				"zipcode": "94404",
				"subPremise": "APT 11",
				"address": "1025 Shell Blvd #11"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://listings.nextdoorphotos.com/1025shellblvdapt11/?mls"
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 39,
			"id": "e2874a89-29bf-4970-baef-f7eea87621fd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831170_0_MenauM_p.jpeg",
			"listDate": "2021-02-24 12:44:21",
			"listingAgent": "Isabella Sun",
			"listPrice": 1650000,
			"lotSize": 6673,
			"sqftTotal": 1570,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831170",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 57,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/750-gull-ave-foster-city-ca-94404-202_ml81512821/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6e2a8a73-2ffd-4657-ae7e-dee93bfb5296",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-02-24 20:47:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-24 12:44:21",
			"virtualTourLink": "http://www.750gullave.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.567702,
				"lng": -122.262423,
				"zipcode": "94404",
				"subPremise": "",
				"address": "750 Gull Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.750gullave.com/"
		},
		{
			"closePrice": 1435000,
			"daysOnMovoto": 51,
			"id": "16c9a12d-b856-4cc5-8d94-264617a8005a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829135_0_UBZZuf_p.jpeg",
			"listDate": "2021-02-09 17:50:58",
			"listingAgent": "Rumana Jabeen",
			"listPrice": 1325000,
			"lotSize": null,
			"sqftTotal": 1490,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829135",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 75,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1975,
			"zipCode": "94404",
			"path": "foster-city-ca/1003-lido-ln-foster-city-ca-94404-100_ml80563423/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cf5a45c9-8558-465b-b98e-c0ada616c70d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-16 16:22:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-09 17:50:58",
			"virtualTourLink": "Http://www.1003lido.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.554044,
				"lng": -122.268222,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1003 Lido Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1435000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.1003lido.com/?mls"
		},
		{
			"closePrice": 980000,
			"daysOnMovoto": 39,
			"id": "a379ebd5-b951-48f8-8aa5-d93cf518f994",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830691_0_rERQMI_p.jpeg",
			"listDate": "2021-02-20 00:38:03",
			"listingAgent": "Emily Lim",
			"listPrice": 988000,
			"lotSize": null,
			"sqftTotal": 1500,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830691",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Star Properties",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1981,
			"zipCode": "94404",
			"path": "foster-city-ca/815-sea-spray-ln-apt-204-foster-city-ca-94404-100_ml81830691/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "a2fba827-6d71-4596-bda8-a7a2103079c8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-20 08:42:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-20 00:38:03",
			"virtualTourLink": "Https://815seaspray204.auto.remax.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.553696,
				"lng": -122.281415,
				"zipcode": "94404",
				"subPremise": "APT 204",
				"address": "815 Sea Spray Ln #204"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://815seaspray204.auto.remax.com/"
		},
		{
			"closePrice": 920000,
			"daysOnMovoto": 33,
			"id": "a092c44c-4bc9-4503-a9c8-c031405a9e64",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831371_0_rUrQVV_p.jpeg",
			"listDate": "2021-02-25 10:55:31",
			"listingAgent": "Bali Liu",
			"listPrice": 918000,
			"lotSize": null,
			"sqftTotal": 1505,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831371",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legend Realty & Finance Group",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/2206-admiralty-ln-foster-city-ca-94404-100_ml81600870/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9d9c3b57-a275-4ab8-b137-1ea504ff37d1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2021-02-25 18:57:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 10:55:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.561437,
				"lng": -122.267213,
				"zipcode": "94404",
				"subPremise": "",
				"address": "2206 Admiralty Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 920000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 899000,
			"daysOnMovoto": 39,
			"id": "2fc12cf7-5091-430c-906f-01dbc06a4719",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830573_0_aaeaZn_p.jpeg",
			"listDate": "2021-02-19 12:40:19",
			"listingAgent": "Max Lo",
			"listPrice": 899000,
			"lotSize": null,
			"sqftTotal": 1245,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830573",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1998,
			"zipCode": "94404",
			"path": "foster-city-ca/880-meridian-bay-ln-apt-315-foster-city-ca-94404-100_ml81830573/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "25dcf095-84a0-4db1-b521-440d3d7ffbdf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2021-02-19 20:42:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 12:40:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.554083,
				"lng": -122.27858,
				"zipcode": "94404",
				"subPremise": "APT 315",
				"address": "880 Meridian Bay Ln #315"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 899000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2425000,
			"daysOnMovoto": 39,
			"id": "e679ef8c-07e0-4d77-a1a8-9f4173e04dc3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830551_0_aBvVeR_p.jpeg",
			"listDate": "2021-02-19 11:18:46",
			"listingAgent": "James S. Shin",
			"listPrice": 1950000,
			"lotSize": 5998,
			"sqftTotal": 1810,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830551",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 69,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1978,
			"zipCode": "94404",
			"path": "foster-city-ca/950-aruba-ln-foster-city-ca-94404-100_ml81830551/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "97199e0a-e151-473b-855e-52f2c68c81ab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2021-02-19 19:22:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 11:18:46",
			"virtualTourLink": "Http://www.950arubalane.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.546407,
				"lng": -122.259296,
				"zipcode": "94404",
				"subPremise": "",
				"address": "950 Aruba Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.950arubalane.com/?mls"
		},
		{
			"closePrice": 1103000,
			"daysOnMovoto": 33,
			"id": "bddbe531-4b36-48c0-96c3-03ee0e6e4221",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830712_0_UMM2B2_p.jpeg",
			"listDate": "2021-02-20 13:01:40",
			"listingAgent": "Anna DuPont",
			"listPrice": 1059000,
			"lotSize": 1751,
			"sqftTotal": 1320,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830712",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1972,
			"zipCode": "94404",
			"path": "foster-city-ca/759-orion-ln-foster-city-ca-94404-100_ml80769838/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7359b005-b2ce-40ef-aafc-223453bb6273",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2021-02-20 21:07:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-20 13:01:40",
			"virtualTourLink": "https://player.vimeo.com/video/514523342",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.551151,
				"lng": -122.275113,
				"zipcode": "94404",
				"subPremise": "",
				"address": "759 Orion Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1103000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/514523342"
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 129,
			"id": "2d40bfa5-3ee3-4d7a-82e7-fc88b691bad9",
			"tnImgPath": "https://pi.movoto.com/p/12/40929591_0_ZQbmQn_p.jpeg",
			"listDate": "2020-11-17 00:00:00",
			"listingAgent": "Ludmila Duwe",
			"listPrice": 999800,
			"lotSize": 701665,
			"sqftTotal": 1349,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40929591",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Nova Realty",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/1061-beach-park-blvd-apt-305-foster-city-ca-94404-100_ml80311898/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7afff9f3-6b67-451c-aaa8-f12043cdde49",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2020-11-17 23:46:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.550511,
				"lng": -122.253055,
				"zipcode": "94404",
				"subPremise": "APT 305",
				"address": "1061 Beach Park Blvd #305"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 989000,
			"daysOnMovoto": 41,
			"id": "e8a49d86-088f-4ebb-b0df-d0261724eba3",
			"tnImgPath": "https://pi.movoto.com/p/12/40937649_0_qQ6U27_p.jpeg",
			"listDate": "2021-02-12 00:00:00",
			"listingAgent": "Andrea Szekrenyi",
			"listPrice": 975000,
			"lotSize": 39200,
			"sqftTotal": 1235,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937649",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Panorama Properties SFBA",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1983,
			"zipCode": "94404",
			"path": "foster-city-ca/780-sea-spray-ln-apt-114-foster-city-ca-94404-100_ml80156501/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c25aa433-bcb0-4d82-a86b-aea7b4564e03",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-02-12 23:31:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-12 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.552101,
				"lng": -122.280964,
				"zipcode": "94404",
				"subPremise": "APT 114",
				"address": "780 Sea Spray Ln #114"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 989000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 54,
			"id": "a56ec6ca-edf6-4784-a9c1-9c2250377caa",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827759_0_Uvj3eN_p.jpeg",
			"listDate": "2021-01-29 16:44:41",
			"listingAgent": "Mary Bee",
			"listPrice": 1069000,
			"lotSize": 1956,
			"sqftTotal": 980,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827759",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1972,
			"zipCode": "94404",
			"path": "foster-city-ca/759-foster-city-blvd-foster-city-ca-94404-202_ml81486330/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9e6d41fd-9f49-44bb-acae-2a54ec9d7245",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-01-30 00:47:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-29 16:44:41",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.559064,
				"lng": -122.264228,
				"zipcode": "94404",
				"subPremise": "",
				"address": "759 Foster City Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 18,
			"id": "7c343604-a310-4e73-83ca-80bfabbaa488",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832235_0_u7JRIR_p.jpeg",
			"listDate": "2021-03-03 10:43:16",
			"listingAgent": "Jennifer Conley",
			"listPrice": 1998000,
			"lotSize": 6425,
			"sqftTotal": 2250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832235",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1966,
			"zipCode": "94404",
			"path": "foster-city-ca/348-pompano-cir-foster-city-ca-94404-100_ml81694542/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cb07f09a-772c-4502-99d7-b5f113fbd977",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-03-03 18:47:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 10:43:16",
			"virtualTourLink": "Https://www.cbhometour.com/348-Pompano-Circle-Foster-City-CA-94404/mlsindex.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.558837,
				"lng": -122.251142,
				"zipcode": "94404",
				"subPremise": "",
				"address": "348 Pompano Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.cbhometour.com/348-Pompano-Circle-Foster-City-CA-94404/mlsindex.html"
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 44,
			"id": "6a180264-1761-4e88-b375-0c587e4af412",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828635_0_e2ijry_p.jpeg",
			"listDate": "2021-02-05 11:45:41",
			"listingAgent": "James Yang",
			"listPrice": 1129000,
			"lotSize": 998,
			"sqftTotal": 1340,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828635",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1987,
			"zipCode": "94404",
			"path": "foster-city-ca/1102-balclutha-dr-foster-city-ca-94404-202_ml81598521/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f83cde7c-2a7c-4d5e-9bd3-35e9d6c85eb7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-02-05 19:58:08",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-05 11:45:41",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2805672",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.559774,
				"lng": -122.26527,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1102 Balclutha Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2805672"
		},
		{
			"closePrice": 708000,
			"daysOnMovoto": 61,
			"id": "5b395e1f-638e-4f12-a957-210645a3ae7c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826303_0_NMbIbY_p.jpeg",
			"listDate": "2021-01-19 11:17:02",
			"listingAgent": "Margie Winter Swain",
			"listPrice": 718000,
			"lotSize": null,
			"sqftTotal": 990,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826303",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1976,
			"zipCode": "94404",
			"path": "foster-city-ca/1151-compass-ln-apt-114-foster-city-ca-94404-100_ml81314924/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ca5c5342-0179-4640-a6b0-74c21e85a606",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-01-19 19:22:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-19 11:17:02",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.550238,
				"lng": -122.254422,
				"zipcode": "94404",
				"subPremise": "APT 114",
				"address": "1151 Compass Ln #114"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 708000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 26,
			"id": "8b470015-d44f-4740-b683-5e3a3e236ba1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829678_0_EqUjav_p.jpeg",
			"listDate": "2021-02-12 15:53:30",
			"listingAgent": "Shirley Kraus",
			"listPrice": 1718000,
			"lotSize": 5223,
			"sqftTotal": 1930,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829678",
			"bath": 2,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1965,
			"zipCode": "94404",
			"path": "foster-city-ca/1009-gull-ave-foster-city-ca-94404-100_ml89937434/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c3d8f8a9-6f7b-458d-a8fe-28db06885325",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-11 00:00:00",
			"createdAt": "2021-02-12 23:57:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-12 15:53:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.565114,
				"lng": -122.253988,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1009 Gull Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200999,
			"daysOnMovoto": 30,
			"id": "1e6b6c72-cf6c-40ab-b92b-ab6efcc2ab7a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828978_0_mZzYuq_p.jpeg",
			"listDate": "2021-02-08 16:36:09",
			"listingAgent": "Rumana Jabeen",
			"listPrice": 1748000,
			"lotSize": 6299,
			"sqftTotal": 2250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828978",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 53,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1966,
			"zipCode": "94404",
			"path": "foster-city-ca/1272-marlin-ave-foster-city-ca-94404-100_ml81828978/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a4937266-2269-415c-bc8a-d770eae34dca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-11 00:00:00",
			"createdAt": "2021-02-10 16:07:52",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-08 16:36:09",
			"virtualTourLink": "Http://www.1272marlin.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.560906,
				"lng": -122.253181,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1272 Marlin Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200999,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.1272marlin.com/?mls"
		},
		{
			"closePrice": 695000,
			"daysOnMovoto": 49,
			"id": "44089fcd-ce8b-441e-8004-5169c1fe044e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826271_0_fzY2n3_p.jpeg",
			"listDate": "2021-01-19 08:08:39",
			"listingAgent": "Emma Elfving",
			"listPrice": 695000,
			"lotSize": null,
			"sqftTotal": 990,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826271",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94404",
			"path": "foster-city-ca/1081-beach-park-blvd-apt-303-foster-city-ca-94404-100_ml81521608/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b015a6e3-b7a6-4162-8d96-f528bdc173d2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-10 00:00:00",
			"createdAt": "2021-01-19 16:12:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-19 08:08:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.550875,
				"lng": -122.252344,
				"zipcode": "94404",
				"subPremise": "APT 303",
				"address": "1081 Beach Park Blvd #303"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 695000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1269000,
			"daysOnMovoto": 35,
			"id": "a3fcef69-7e3a-4fad-b9a7-70bd4f7a7776",
			"tnImgPath": "https://pi.movoto.com/p/110/421516343_0_avjqMa_p.jpeg",
			"listDate": "2021-02-02 00:00:00",
			"listingAgent": "Tyson Li",
			"listPrice": 1269000,
			"lotSize": 12155,
			"sqftTotal": 1653,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421516343",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2017,
			"zipCode": "94404",
			"path": "foster-city-ca/1019-foster-square-ln-apt-304-foster-city-ca-94404-100_ml81743827/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "23b5a75e-533e-4bb9-a12d-1a883de06391",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-09 00:00:00",
			"createdAt": "2021-02-03 02:26:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-02 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=oDXG8FiVhmP",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.557975,
				"lng": -122.269006,
				"zipcode": "94404",
				"subPremise": "APT 304",
				"address": "1019 Foster Square Ln #304"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1269000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=oDXG8FiVhmP"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 49,
			"id": "afcde7ed-e1a3-4fd9-948d-2262fc31f225",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826197_0_b3qjYA_p.jpeg",
			"listDate": "2021-01-18 09:03:02",
			"listingAgent": "DeLeon Team",
			"listPrice": 1998000,
			"lotSize": 5101,
			"sqftTotal": 2700,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826197",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1979,
			"zipCode": "94404",
			"path": "foster-city-ca/1301-melbourne-st-foster-city-ca-94404-100_ml81826197/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "86ac728e-b17f-4ed5-9983-8229fb3ef1b1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-09 00:00:00",
			"createdAt": "2021-01-18 17:07:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-18 09:03:02",
			"virtualTourLink": "https://player.vimeo.com/video/500676554",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Foster City",
				"lat": 37.540709,
				"lng": -122.263638,
				"zipcode": "94404",
				"subPremise": "",
				"address": "1301 Melbourne St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/500676554"
		}
	]