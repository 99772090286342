const mountain_house = {
    "geography": {
       "census_release": "ACS 2019 5-year",
       "parents": {
          "county": {
             "full_name": "San Joaquin County, CA",
             "short_name": "San Joaquin County",
             "sumlevel": "050",
             "land_area": 3606127799,
             "full_geoid": "05000US06077",
             "total_population": 742603
          },
          "CBSA": {
             "full_name": "Stockton, CA Metro Area",
             "short_name": "Stockton, CA",
             "sumlevel": "310",
             "land_area": 3606127799,
             "full_geoid": "31000US44700",
             "total_population": 742603
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39283497
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 324697795
          }
       },
       "this": {
          "full_name": "Mountain House, CA",
          "short_name": "Mountain House",
          "sumlevel": "160",
          "land_area": 8269148,
          "full_geoid": "16000US0649582",
          "total_population": 17966,
          "sumlevel_name": "Place",
          "short_geoid": "0649582"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "5"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 37.94,
                   "CBSA": 27.3,
                   "state": 22.97
                },
                "error": {
                   "this": 2.96,
                   "CBSA": 0.27,
                   "state": 0.03
                },
                "numerators": {
                   "this": 6817,
                   "CBSA": 202701,
                   "state": 9022146
                },
                "numerator_errors": {
                   "this": 779.4,
                   "CBSA": 1986.3,
                   "state": 12886.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 139,
                   "state": 165
                },
                "error_ratio": {
                   "this": 7.8,
                   "CBSA": 1,
                   "state": 0.1
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 57,
                   "CBSA": 60.17,
                   "state": 63.07
                },
                "error": {
                   "this": 1.21,
                   "CBSA": 0.36,
                   "state": 0.05
                },
                "numerators": {
                   "this": 10240,
                   "CBSA": 446821,
                   "state": 2.477531E7
                },
                "numerator_errors": {
                   "this": 883.2,
                   "CBSA": 2677.5,
                   "state": 20108.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 90
                },
                "error_ratio": {
                   "this": 2.1,
                   "CBSA": 0.6,
                   "state": 0.1
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 5.06,
                   "CBSA": 12.53,
                   "state": 13.97
                },
                "error": {
                   "this": 1.71,
                   "CBSA": 0.22,
                   "state": 0.03
                },
                "numerators": {
                   "this": 909,
                   "CBSA": 93081,
                   "state": 5486041
                },
                "numerator_errors": {
                   "this": 316.7,
                   "CBSA": 1653.5,
                   "state": 12678.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 40,
                   "state": 36
                },
                "error_ratio": {
                   "this": 33.8,
                   "CBSA": 1.8,
                   "state": 0.2
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 21.7,
                      "CBSA": 14.8,
                      "state": 12.52
                   },
                   "error": {
                      "this": 2.86,
                      "CBSA": 0.19,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3898,
                      "CBSA": 109924,
                      "state": 4919754
                   },
                   "numerator_errors": {
                      "this": 607.8,
                      "CBSA": 1406.8,
                      "state": 9132.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 147,
                      "state": 173
                   },
                   "error_ratio": {
                      "this": 13.2,
                      "CBSA": 1.3,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 18.37,
                      "CBSA": 15.32,
                      "state": 13.09
                   },
                   "error": {
                      "this": 2.47,
                      "CBSA": 0.19,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3300,
                      "CBSA": 113798,
                      "state": 5140633
                   },
                   "numerator_errors": {
                      "this": 523.1,
                      "CBSA": 1410.7,
                      "state": 9167.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 120,
                      "state": 140
                   },
                   "error_ratio": {
                      "this": 13.4,
                      "CBSA": 1.2,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 7.36,
                      "CBSA": 14.1,
                      "state": 14.84
                   },
                   "error": {
                      "this": 2.15,
                      "CBSA": 0.18,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1322,
                      "CBSA": 104723,
                      "state": 5830059
                   },
                   "numerator_errors": {
                      "this": 402.2,
                      "CBSA": 1322.9,
                      "state": 10871.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 52,
                      "state": 50
                   },
                   "error_ratio": {
                      "this": 29.2,
                      "CBSA": 1.3,
                      "state": 0.2
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 16.27,
                      "CBSA": 13.3,
                      "state": 14.18
                   },
                   "error": {
                      "this": 2.12,
                      "CBSA": 0.16,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 2923,
                      "CBSA": 98768,
                      "state": 5570748
                   },
                   "numerator_errors": {
                      "this": 452.9,
                      "CBSA": 1202.1,
                      "state": 8798.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 122,
                      "state": 115
                   },
                   "error_ratio": {
                      "this": 13,
                      "CBSA": 1.2,
                      "state": 0.1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 18.16,
                      "CBSA": 12.66,
                      "state": 12.95
                   },
                   "error": {
                      "this": 1.85,
                      "CBSA": 0.16,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3263,
                      "CBSA": 93996,
                      "state": 5088145
                   },
                   "numerator_errors": {
                      "this": 429.3,
                      "CBSA": 1193.6,
                      "state": 8746.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 143,
                      "state": 140
                   },
                   "error_ratio": {
                      "this": 10.2,
                      "CBSA": 1.3,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 7.95,
                      "CBSA": 11.87,
                      "state": 12.76
                   },
                   "error": {
                      "this": 1.54,
                      "CBSA": 0.14,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1429,
                      "CBSA": 88118,
                      "state": 5011016
                   },
                   "numerator_errors": {
                      "this": 302.1,
                      "CBSA": 1043.8,
                      "state": 6867
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 67,
                      "state": 62
                   },
                   "error_ratio": {
                      "this": 19.4,
                      "CBSA": 1.2,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 7.36,
                      "CBSA": 9.63,
                      "state": 10.34
                   },
                   "error": {
                      "this": 1.98,
                      "CBSA": 0.21,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1323,
                      "CBSA": 71532,
                      "state": 4062419
                   },
                   "numerator_errors": {
                      "this": 372.4,
                      "CBSA": 1558.5,
                      "state": 11748.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 76,
                      "state": 71
                   },
                   "error_ratio": {
                      "this": 26.9,
                      "CBSA": 2.2,
                      "state": 0.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 1.95,
                      "CBSA": 5.35,
                      "state": 5.82
                   },
                   "error": {
                      "this": 1.16,
                      "CBSA": 0.14,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 350,
                      "CBSA": 39696,
                      "state": 2285972
                   },
                   "numerator_errors": {
                      "this": 211.2,
                      "CBSA": 1018.2,
                      "state": 7838.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 36,
                      "state": 34
                   },
                   "error_ratio": {
                      "this": 59.5,
                      "CBSA": 2.6,
                      "state": 0.3
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 0.88,
                      "CBSA": 2.97,
                      "state": 3.5
                   },
                   "error": {
                      "this": 0.76,
                      "CBSA": 0.11,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 158,
                      "CBSA": 22048,
                      "state": 1374751
                   },
                   "numerator_errors": {
                      "this": 137.5,
                      "CBSA": 839.4,
                      "state": 6707.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 30,
                      "state": 25
                   },
                   "error_ratio": {
                      "this": 86.4,
                      "CBSA": 3.7,
                      "state": 0.6
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 22.3,
                      "CBSA": 15.16,
                      "state": 12.87
                   },
                   "error": {
                      "this": 4.2,
                      "CBSA": 0.27,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1909,
                      "CBSA": 56102,
                      "state": 2512581
                   },
                   "numerator_errors": {
                      "this": 402.4,
                      "CBSA": 1011.8,
                      "state": 6146.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 147,
                      "state": 173
                   },
                   "error_ratio": {
                      "this": 18.8,
                      "CBSA": 1.8,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 18.33,
                      "CBSA": 15.91,
                      "state": 13.46
                   },
                   "error": {
                      "this": 3.62,
                      "CBSA": 0.27,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1569,
                      "CBSA": 58878,
                      "state": 2628152
                   },
                   "numerator_errors": {
                      "this": 343.5,
                      "CBSA": 1012.2,
                      "state": 6239.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 115,
                      "state": 136
                   },
                   "error_ratio": {
                      "this": 19.7,
                      "CBSA": 1.7,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.18,
                      "CBSA": 14.58,
                      "state": 15.42
                   },
                   "error": {
                      "this": 2.68,
                      "CBSA": 0.24,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 529,
                      "CBSA": 53980,
                      "state": 3011788
                   },
                   "numerator_errors": {
                      "this": 235,
                      "CBSA": 873.9,
                      "state": 8042.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 42,
                      "state": 40
                   },
                   "error_ratio": {
                      "this": 43.4,
                      "CBSA": 1.6,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 15.1,
                      "CBSA": 13.56,
                      "state": 14.57
                   },
                   "error": {
                      "this": 3.39,
                      "CBSA": 0.21,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1293,
                      "CBSA": 50200,
                      "state": 2844329
                   },
                   "numerator_errors": {
                      "this": 314.7,
                      "CBSA": 786,
                      "state": 6637.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 111,
                      "state": 104
                   },
                   "error_ratio": {
                      "this": 22.5,
                      "CBSA": 1.5,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 18.54,
                      "CBSA": 12.57,
                      "state": 13.02
                   },
                   "error": {
                      "this": 2.85,
                      "CBSA": 0.21,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1587,
                      "CBSA": 46519,
                      "state": 2541977
                   },
                   "numerator_errors": {
                      "this": 286.4,
                      "CBSA": 785,
                      "state": 6536.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 147,
                      "state": 142
                   },
                   "error_ratio": {
                      "this": 15.4,
                      "CBSA": 1.7,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 9.89,
                      "CBSA": 11.82,
                      "state": 12.65
                   },
                   "error": {
                      "this": 2.61,
                      "CBSA": 0.2,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 847,
                      "CBSA": 43768,
                      "state": 2470900
                   },
                   "numerator_errors": {
                      "this": 237.1,
                      "CBSA": 755,
                      "state": 4877.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 84,
                      "state": 78
                   },
                   "error_ratio": {
                      "this": 26.4,
                      "CBSA": 1.7,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 7.43,
                      "CBSA": 9.3,
                      "state": 9.92
                   },
                   "error": {
                      "this": 2.82,
                      "CBSA": 0.29,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 636,
                      "CBSA": 34407,
                      "state": 1937575
                   },
                   "numerator_errors": {
                      "this": 248.6,
                      "CBSA": 1071.9,
                      "state": 8018.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 80,
                      "state": 75
                   },
                   "error_ratio": {
                      "this": 38,
                      "CBSA": 3.1,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 1.81,
                      "CBSA": 4.83,
                      "state": 5.32
                   },
                   "error": {
                      "this": 1.4,
                      "CBSA": 0.19,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 155,
                      "CBSA": 17862,
                      "state": 1038517
                   },
                   "numerator_errors": {
                      "this": 121.1,
                      "CBSA": 699.5,
                      "state": 5256.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 37,
                      "state": 34
                   },
                   "error_ratio": {
                      "this": 77.3,
                      "CBSA": 3.9,
                      "state": 0.6
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 0.42,
                      "CBSA": 2.28,
                      "state": 2.77
                   },
                   "error": {
                      "this": 0.55,
                      "CBSA": 0.14,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 36,
                      "CBSA": 8436,
                      "state": 540479
                   },
                   "numerator_errors": {
                      "this": 47,
                      "CBSA": 530.8,
                      "state": 4503.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 18,
                      "state": 15
                   },
                   "error_ratio": {
                      "this": 131,
                      "CBSA": 6.1,
                      "state": 0.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 21.15,
                      "CBSA": 14.45,
                      "state": 12.18
                   },
                   "error": {
                      "this": 4.36,
                      "CBSA": 0.26,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1989,
                      "CBSA": 53822,
                      "state": 2407173
                   },
                   "numerator_errors": {
                      "this": 455.6,
                      "CBSA": 977.4,
                      "state": 6754.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 146,
                      "state": 174
                   },
                   "error_ratio": {
                      "this": 20.6,
                      "CBSA": 1.8,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 18.41,
                      "CBSA": 14.75,
                      "state": 12.72
                   },
                   "error": {
                      "this": 3.77,
                      "CBSA": 0.26,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1731,
                      "CBSA": 54920,
                      "state": 2512481
                   },
                   "numerator_errors": {
                      "this": 394.5,
                      "CBSA": 982.6,
                      "state": 6716.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 145
                   },
                   "error_ratio": {
                      "this": 20.5,
                      "CBSA": 1.8,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 8.43,
                      "CBSA": 13.62,
                      "state": 14.26
                   },
                   "error": {
                      "this": 3.37,
                      "CBSA": 0.27,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 793,
                      "CBSA": 50743,
                      "state": 2818271
                   },
                   "numerator_errors": {
                      "this": 326.4,
                      "CBSA": 993.2,
                      "state": 7314.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 62,
                      "state": 59
                   },
                   "error_ratio": {
                      "this": 40,
                      "CBSA": 2,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 17.33,
                      "CBSA": 13.04,
                      "state": 13.8
                   },
                   "error": {
                      "this": 3,
                      "CBSA": 0.24,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1630,
                      "CBSA": 48568,
                      "state": 2726419
                   },
                   "numerator_errors": {
                      "this": 325.7,
                      "CBSA": 909.5,
                      "state": 5775.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 133,
                      "state": 126
                   },
                   "error_ratio": {
                      "this": 17.3,
                      "CBSA": 1.8,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 17.82,
                      "CBSA": 12.75,
                      "state": 12.89
                   },
                   "error": {
                      "this": 2.89,
                      "CBSA": 0.24,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1676,
                      "CBSA": 47477,
                      "state": 2546168
                   },
                   "numerator_errors": {
                      "this": 319.8,
                      "CBSA": 899.1,
                      "state": 5811.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 140,
                      "state": 138
                   },
                   "error_ratio": {
                      "this": 16.2,
                      "CBSA": 1.9,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 6.19,
                      "CBSA": 11.91,
                      "state": 12.86
                   },
                   "error": {
                      "this": 1.89,
                      "CBSA": 0.19,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 582,
                      "CBSA": 44350,
                      "state": 2540116
                   },
                   "numerator_errors": {
                      "this": 187.1,
                      "CBSA": 720.8,
                      "state": 4833.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 52,
                      "state": 48
                   },
                   "error_ratio": {
                      "this": 30.5,
                      "CBSA": 1.6,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 7.3,
                      "CBSA": 9.97,
                      "state": 10.75
                   },
                   "error": {
                      "this": 2.86,
                      "CBSA": 0.3,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 687,
                      "CBSA": 37125,
                      "state": 2124844
                   },
                   "numerator_errors": {
                      "this": 277.2,
                      "CBSA": 1131.3,
                      "state": 8586.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 73,
                      "state": 68
                   },
                   "error_ratio": {
                      "this": 39.2,
                      "CBSA": 3,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 2.07,
                      "CBSA": 5.86,
                      "state": 6.31
                   },
                   "error": {
                      "this": 1.83,
                      "CBSA": 0.2,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 195,
                      "CBSA": 21834,
                      "state": 1247455
                   },
                   "numerator_errors": {
                      "this": 173,
                      "CBSA": 739.9,
                      "state": 5815.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 35,
                      "state": 33
                   },
                   "error_ratio": {
                      "this": 88.4,
                      "CBSA": 3.4,
                      "state": 0.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 1.3,
                      "CBSA": 3.65,
                      "state": 4.22
                   },
                   "error": {
                      "this": 1.37,
                      "CBSA": 0.17,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 122,
                      "CBSA": 13612,
                      "state": 834272
                   },
                   "numerator_errors": {
                      "this": 129.3,
                      "CBSA": 650.2,
                      "state": 4970.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 36,
                      "state": 31
                   },
                   "error_ratio": {
                      "this": 105.4,
                      "CBSA": 4.7,
                      "state": 0.7
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 33.1,
                   "CBSA": 34.3,
                   "state": 36.5
                },
                "error": {
                   "this": 2.5,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 91
                },
                "error_ratio": {
                   "this": 7.6,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 32.9,
                   "CBSA": 33.3,
                   "state": 35.4
                },
                "error": {
                   "this": 5.3,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 93
                },
                "error_ratio": {
                   "this": 16.1,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 33.2,
                   "CBSA": 35.5,
                   "state": 37.7
                },
                "error": {
                   "this": 3.3,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 88
                },
                "error_ratio": {
                   "this": 9.9,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 47.65,
                "CBSA": 49.85,
                "state": 49.71
             },
             "error": {
                "this": 2.12,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 8561,
                "CBSA": 370152,
                "state": 1.9526298E7
             },
             "numerator_errors": {
                "this": 811,
                "CBSA": 107,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 96
             },
             "error_ratio": {
                "this": 4.4,
                "CBSA": null,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 52.35,
                "CBSA": 50.15,
                "state": 50.29
             },
             "error": {
                "this": 2.89,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 9405,
                "CBSA": 372451,
                "state": 1.97572E7
             },
             "numerator_errors": {
                "this": 942,
                "CBSA": 107,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 104
             },
             "error_ratio": {
                "this": 5.5,
                "CBSA": null,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 24.99,
                "CBSA": 31.85,
                "state": 37.18
             },
             "error": {
                "this": 5.18,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 4489,
                "CBSA": 236509,
                "state": 1.4605312E7
             },
             "numerator_errors": {
                "this": 1004,
                "CBSA": 252,
                "state": 3168
             },
             "index": {
                "this": 100,
                "CBSA": 78,
                "state": 67
             },
             "error_ratio": {
                "this": 20.7,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 9.62,
                "CBSA": 6.74,
                "state": 5.52
             },
             "error": {
                "this": 2.49,
                "CBSA": 0.14,
                "state": 0.02
             },
             "numerators": {
                "this": 1729,
                "CBSA": 50030,
                "state": 2169155
             },
             "numerator_errors": {
                "this": 470,
                "CBSA": 1071,
                "state": 6936
             },
             "index": {
                "this": 100,
                "CBSA": 143,
                "state": 174
             },
             "error_ratio": {
                "this": 25.9,
                "CBSA": 2.1,
                "state": 0.4
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0,
                "CBSA": 0.23,
                "state": 0.36
             },
             "error": {
                "this": 0,
                "CBSA": 0.05,
                "state": 0.01
             },
             "numerators": {
                "this": 0,
                "CBSA": 1697,
                "state": 140831
             },
             "numerator_errors": {
                "this": 19,
                "CBSA": 351,
                "state": 2516
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 21.7,
                "state": 2.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 42.31,
                "CBSA": 15.2,
                "state": 14.28
             },
             "error": {
                "this": 4,
                "CBSA": 0.15,
                "state": 0.02
             },
             "numerators": {
                "this": 7602,
                "CBSA": 112885,
                "state": 5610931
             },
             "numerator_errors": {
                "this": 959,
                "CBSA": 1113,
                "state": 9199
             },
             "index": {
                "this": 100,
                "CBSA": 278,
                "state": 296
             },
             "error_ratio": {
                "this": 9.5,
                "CBSA": 1,
                "state": 0.1
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.53,
                "CBSA": 0.55,
                "state": 0.36
             },
             "error": {
                "this": 0.67,
                "CBSA": 0.05,
                "state": 0.01
             },
             "numerators": {
                "this": 96,
                "CBSA": 4055,
                "state": 140788
             },
             "numerator_errors": {
                "this": 121,
                "CBSA": 357,
                "state": 2224
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 147
             },
             "error_ratio": {
                "this": 126.4,
                "CBSA": 9.1,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0,
                "CBSA": 0.13,
                "state": 0.25
             },
             "error": {
                "this": 0,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 0,
                "CBSA": 956,
                "state": 100119
             },
             "numerator_errors": {
                "this": 19,
                "CBSA": 288,
                "state": 3715
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 30.8,
                "state": 4
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 7.77,
                "CBSA": 3.91,
                "state": 3.03
             },
             "error": {
                "this": 3.83,
                "CBSA": 0.21,
                "state": 0.04
             },
             "numerators": {
                "this": 1396,
                "CBSA": 29008,
                "state": 1188673
             },
             "numerator_errors": {
                "this": 698,
                "CBSA": 1562,
                "state": 14463
             },
             "index": {
                "this": 100,
                "CBSA": 199,
                "state": 256
             },
             "error_ratio": {
                "this": 49.3,
                "CBSA": 5.4,
                "state": 1.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 14.77,
                "CBSA": 41.4,
                "state": 39.02
             },
             "error": {
                "this": 4.1,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 2654,
                "CBSA": 307463,
                "state": 1.5327688E7
             },
             "numerator_errors": {
                "this": 769,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 36,
                "state": 38
             },
             "error_ratio": {
                "this": 27.8,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 40703,
                "CBSA": 27521,
                "state": 36955
             },
             "error": {
                "this": 3122,
                "CBSA": 394,
                "state": 129
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 148,
                "state": 110
             },
             "error_ratio": {
                "this": 7.7,
                "CBSA": 1.4,
                "state": 0.3
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 140801,
                "CBSA": 64432,
                "state": 75235
             },
             "error": {
                "this": 13467,
                "CBSA": 745,
                "state": 232
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 219,
                "state": 187
             },
             "error_ratio": {
                "this": 9.6,
                "CBSA": 1.2,
                "state": 0.3
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 7.27,
                   "CBSA": 38.89,
                   "state": 34.35
                },
                "error": {
                   "this": 3.86,
                   "CBSA": 0.86,
                   "state": 0.1
                },
                "numerators": {
                   "this": 344,
                   "CBSA": 88898,
                   "state": 4481083
                },
                "numerator_errors": {
                   "this": 184.6,
                   "CBSA": 1997.4,
                   "state": 15330.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 19,
                   "state": 21
                },
                "error_ratio": {
                   "this": 53.1,
                   "CBSA": 2.2,
                   "state": 0.3
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 17.2,
                   "CBSA": 30.79,
                   "state": 27.93
                },
                "error": {
                   "this": 5.33,
                   "CBSA": 0.75,
                   "state": 0.09
                },
                "numerators": {
                   "this": 814,
                   "CBSA": 70366,
                   "state": 3643284
                },
                "numerator_errors": {
                   "this": 260.3,
                   "CBSA": 1747,
                   "state": 12935.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 62
                },
                "error_ratio": {
                   "this": 31,
                   "CBSA": 2.4,
                   "state": 0.3
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 52.49,
                   "CBSA": 23.55,
                   "state": 25.56
                },
                "error": {
                   "this": 8.3,
                   "CBSA": 0.7,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2484,
                   "CBSA": 53831,
                   "state": 3333848
                },
                "numerator_errors": {
                   "this": 439.9,
                   "CBSA": 1610.2,
                   "state": 14018.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 223,
                   "state": 205
                },
                "error_ratio": {
                   "this": 15.8,
                   "CBSA": 3,
                   "state": 0.4
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 23.03,
                   "CBSA": 6.77,
                   "state": 12.16
                },
                "error": {
                   "this": 5.6,
                   "CBSA": 0.36,
                   "state": 0.08
                },
                "numerators": {
                   "this": 1090,
                   "CBSA": 15472,
                   "state": 1586051
                },
                "numerator_errors": {
                   "this": 279,
                   "CBSA": 827,
                   "state": 10523
                },
                "index": {
                   "this": 100,
                   "CBSA": 340,
                   "state": 189
                },
                "error_ratio": {
                   "this": 24.3,
                   "CBSA": 5.3,
                   "state": 0.7
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 4.31,
                "CBSA": 14.51,
                "state": 13.36
             },
             "error": {
                "this": 3.45,
                "CBSA": 0.58,
                "state": 0.1
             },
             "numerators": {
                "this": 771,
                "CBSA": 105461,
                "state": 5149742
             },
             "numerator_errors": {
                "this": 620,
                "CBSA": 4234,
                "state": 38666
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 30,
                "state": 32
             },
             "error_ratio": {
                "this": 80,
                "CBSA": 4,
                "state": 0.7
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 6.45,
                   "CBSA": 19.86,
                   "state": 18.13
                },
                "error": {
                   "this": 3.37,
                   "CBSA": 0.77,
                   "state": 0.12
                },
                "numerators": {
                   "this": 434,
                   "CBSA": 39563,
                   "state": 1610923
                },
                "numerator_errors": {
                   "this": 232.4,
                   "CBSA": 1623,
                   "state": 10973.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 32,
                   "state": 36
                },
                "error_ratio": {
                   "this": 52.2,
                   "CBSA": 3.9,
                   "state": 0.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 93.55,
                   "CBSA": 80.14,
                   "state": 81.87
                },
                "error": {
                   "this": 2.08,
                   "CBSA": 0.22,
                   "state": 0
                },
                "numerators": {
                   "this": 6294,
                   "CBSA": 159604,
                   "state": 7275254
                },
                "numerator_errors": {
                   "this": 731.9,
                   "CBSA": 2298.8,
                   "state": 15647.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 114
                },
                "error_ratio": {
                   "this": 2.2,
                   "CBSA": 0.3,
                   "state": null
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 0,
                   "CBSA": 9.89,
                   "state": 10.23
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.74,
                   "state": 0.1
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 8902,
                   "state": 551219
                },
                "numerator_errors": {
                   "this": 38,
                   "CBSA": 670.5,
                   "state": 5245
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 7.5,
                   "state": 1
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 100,
                   "CBSA": 90.11,
                   "state": 89.77
                },
                "error": {
                   "this": 45.41,
                   "CBSA": 1.25,
                   "state": 0.16
                },
                "numerators": {
                   "this": 909,
                   "CBSA": 81104,
                   "state": 4839112
                },
                "numerator_errors": {
                   "this": 290.6,
                   "CBSA": 705.1,
                   "state": 5421.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 111
                },
                "error_ratio": {
                   "this": 45.4,
                   "CBSA": 1.4,
                   "state": 0.2
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 63.25,
                "CBSA": 34.23,
                "state": 29.79
             },
             "error": {
                "this": 10.56,
                "CBSA": 0.51,
                "state": 0.07
             },
             "numerators": {
                "this": 423970,
                "CBSA": 9950605,
                "state": 5.099704E8
             },
             "numerator_errors": {
                "this": 47180,
                "CBSA": 175174,
                "state": 1354876
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 185,
                "state": 212
             },
             "error_ratio": {
                "this": 16.7,
                "CBSA": 1.5,
                "state": 0.2
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 69.04,
                   "CBSA": 78.75,
                   "state": 73.72
                },
                "error": {
                   "this": 4.78,
                   "CBSA": 0.65,
                   "state": 0.04
                },
                "numerators": {
                   "this": 5081,
                   "CBSA": 238729,
                   "state": 1.3411041E7
                },
                "numerator_errors": {
                   "this": 649,
                   "CBSA": 2835,
                   "state": 17348
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 94
                },
                "error_ratio": {
                   "this": 6.9,
                   "CBSA": 0.8,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 13.81,
                   "CBSA": 12.89,
                   "state": 10.12
                },
                "error": {
                   "this": 4.42,
                   "CBSA": 0.62,
                   "state": 0.09
                },
                "numerators": {
                   "this": 1016,
                   "CBSA": 39088,
                   "state": 1841632
                },
                "numerator_errors": {
                   "this": 343,
                   "CBSA": 1917,
                   "state": 16674
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 136
                },
                "error_ratio": {
                   "this": 32,
                   "CBSA": 4.8,
                   "state": 0.9
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 5.65,
                   "CBSA": 1.67,
                   "state": 5.08
                },
                "error": {
                   "this": 3.09,
                   "CBSA": 0.19,
                   "state": 0.04
                },
                "numerators": {
                   "this": 416,
                   "CBSA": 5055,
                   "state": 923834
                },
                "numerator_errors": {
                   "this": 232,
                   "CBSA": 567,
                   "state": 7563
                },
                "index": {
                   "this": 100,
                   "CBSA": 338,
                   "state": 111
                },
                "error_ratio": {
                   "this": 54.7,
                   "CBSA": 11.4,
                   "state": 0.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0,
                   "CBSA": 0.41,
                   "state": 0.95
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.1,
                   "state": 0.02
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1228,
                   "state": 173081
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 290,
                   "state": 3177
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 24.4,
                   "state": 2.1
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 0,
                   "CBSA": 1.37,
                   "state": 2.62
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.17,
                   "state": 0.03
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4164,
                   "state": 476291
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 516,
                   "state": 5234
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 12.4,
                   "state": 1.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.58,
                   "CBSA": 0.81,
                   "state": 1.6
                },
                "error": {
                   "this": 1.88,
                   "CBSA": 0.13,
                   "state": 0.02
                },
                "numerators": {
                   "this": 190,
                   "CBSA": 2455,
                   "state": 291710
                },
                "numerator_errors": {
                   "this": 140,
                   "CBSA": 381,
                   "state": 4364
                },
                "index": {
                   "this": 100,
                   "CBSA": 319,
                   "state": 161
                },
                "error_ratio": {
                   "this": 72.9,
                   "CBSA": 16,
                   "state": 1.2
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 8.91,
                   "CBSA": 4.1,
                   "state": 5.9
                },
                "error": {
                   "this": 3.51,
                   "CBSA": 0.3,
                   "state": 0.05
                },
                "numerators": {
                   "this": 656,
                   "CBSA": 12428,
                   "state": 1073966
                },
                "numerator_errors": {
                   "this": 268,
                   "CBSA": 904,
                   "state": 8330
                },
                "index": {
                   "this": 100,
                   "CBSA": 217,
                   "state": 151
                },
                "error_ratio": {
                   "this": 39.4,
                   "CBSA": 7.3,
                   "state": 0.8
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 67.68,
                "CBSA": 50.09,
                "state": 48.86
             },
             "error": {
                "this": 7.51,
                "CBSA": 0.46,
                "state": 0.11
             },
             "numerators": {
                "this": 8155,
                "CBSA": 287766,
                "state": 1.5532639E7
             },
             "numerator_errors": {
                "this": 591.3,
                "CBSA": 2634.1,
                "state": 35143.9
             },
             "index": {
                "this": 100,
                "CBSA": 135,
                "state": 139
             },
             "error_ratio": {
                "this": 11.1,
                "CBSA": 0.9,
                "state": 0.2
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 32.32,
                "CBSA": 49.91,
                "state": 51.14
             },
             "error": {
                "this": 4.73,
                "CBSA": 0.48,
                "state": 0.1
             },
             "numerators": {
                "this": 3895,
                "CBSA": 286718,
                "state": 1.6255641E7
             },
             "numerator_errors": {
                "this": 657.4,
                "CBSA": 2729,
                "state": 32012.1
             },
             "index": {
                "this": 100,
                "CBSA": 65,
                "state": 63
             },
             "error_ratio": {
                "this": 14.6,
                "CBSA": 1,
                "state": 0.2
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 23.25,
                   "CBSA": 38.69,
                   "state": 40.42
                },
                "error": {
                   "this": 6.41,
                   "CBSA": 0.53,
                   "state": 0.14
                },
                "numerators": {
                   "this": 1340,
                   "CBSA": 109880,
                   "state": 6343459
                },
                "numerator_errors": {
                   "this": 392,
                   "CBSA": 1517,
                   "state": 22441
                },
                "index": {
                   "this": 100,
                   "CBSA": 60,
                   "state": 58
                },
                "error_ratio": {
                   "this": 27.6,
                   "CBSA": 1.4,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 23.3,
                   "CBSA": 31.78,
                   "state": 33.49
                },
                "error": {
                   "this": 5.95,
                   "CBSA": 0.45,
                   "state": 0.11
                },
                "numerators": {
                   "this": 1465,
                   "CBSA": 92303,
                   "state": 5389443
                },
                "numerator_errors": {
                   "this": 404,
                   "CBSA": 1310,
                   "state": 17597
                },
                "index": {
                   "this": 100,
                   "CBSA": 73,
                   "state": 70
                },
                "error_ratio": {
                   "this": 25.5,
                   "CBSA": 1.4,
                   "state": 0.3
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 71.49,
                   "CBSA": 50.76,
                   "state": 49.8
                },
                "error": {
                   "this": 1.33,
                   "CBSA": 0.66,
                   "state": 0.17
                },
                "numerators": {
                   "this": 4120,
                   "CBSA": 144170,
                   "state": 7816684
                },
                "numerator_errors": {
                   "this": 409,
                   "CBSA": 1882,
                   "state": 27451
                },
                "index": {
                   "this": 100,
                   "CBSA": 141,
                   "state": 144
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 1.3,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 64.18,
                   "CBSA": 49.44,
                   "state": 47.95
                },
                "error": {
                   "this": 1.35,
                   "CBSA": 0.63,
                   "state": 0.14
                },
                "numerators": {
                   "this": 4035,
                   "CBSA": 143596,
                   "state": 7715955
                },
                "numerator_errors": {
                   "this": 427,
                   "CBSA": 1843,
                   "state": 21944
                },
                "index": {
                   "this": 100,
                   "CBSA": 130,
                   "state": 134
                },
                "error_ratio": {
                   "this": 2.1,
                   "CBSA": 1.3,
                   "state": 0.3
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 4.88,
                   "CBSA": 8.06,
                   "state": 7.65
                },
                "error": {
                   "this": 2.72,
                   "CBSA": 0.37,
                   "state": 0.06
                },
                "numerators": {
                   "this": 281,
                   "CBSA": 22900,
                   "state": 1200982
                },
                "numerator_errors": {
                   "this": 159,
                   "CBSA": 1055,
                   "state": 9397
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 64
                },
                "error_ratio": {
                   "this": 55.7,
                   "CBSA": 4.6,
                   "state": 0.8
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 8.05,
                   "CBSA": 10.15,
                   "state": 10.85
                },
                "error": {
                   "this": 3.58,
                   "CBSA": 0.39,
                   "state": 0.05
                },
                "numerators": {
                   "this": 506,
                   "CBSA": 29470,
                   "state": 1746282
                },
                "numerator_errors": {
                   "this": 231,
                   "CBSA": 1124,
                   "state": 8613
                },
                "index": {
                   "this": 100,
                   "CBSA": 79,
                   "state": 74
                },
                "error_ratio": {
                   "this": 44.5,
                   "CBSA": 3.8,
                   "state": 0.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 0.38,
                   "CBSA": 2.49,
                   "state": 2.13
                },
                "error": {
                   "this": 0.62,
                   "CBSA": 0.18,
                   "state": 0.02
                },
                "numerators": {
                   "this": 22,
                   "CBSA": 7060,
                   "state": 334237
                },
                "numerator_errors": {
                   "this": 36,
                   "CBSA": 515,
                   "state": 3638
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 18
                },
                "error_ratio": {
                   "this": 163.2,
                   "CBSA": 7.2,
                   "state": 0.9
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 4.47,
                   "CBSA": 8.64,
                   "state": 7.71
                },
                "error": {
                   "this": 2.95,
                   "CBSA": 0.31,
                   "state": 0.04
                },
                "numerators": {
                   "this": 281,
                   "CBSA": 25105,
                   "state": 1241238
                },
                "numerator_errors": {
                   "this": 188,
                   "CBSA": 888,
                   "state": 5983
                },
                "index": {
                   "this": 100,
                   "CBSA": 52,
                   "state": 58
                },
                "error_ratio": {
                   "this": 66,
                   "CBSA": 3.6,
                   "state": 0.5
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 83.57,
                   "CBSA": 71.06,
                   "state": 72.23
                },
                "error": {
                   "this": 5.86,
                   "CBSA": 1.18,
                   "state": 0.27
                },
                "numerators": {
                   "this": 5509,
                   "CBSA": 122361,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 813,
                   "CBSA": 2463,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 116
                },
                "error_ratio": {
                   "this": 7,
                   "CBSA": 1.7,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 4.57,
                   "CBSA": 8.24,
                   "state": 7.67
                },
                "error": {
                   "this": 5.14,
                   "CBSA": 0.73,
                   "state": 0.11
                },
                "numerators": {
                   "this": 301,
                   "CBSA": 14184,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 341,
                   "CBSA": 1264,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 60
                },
                "error_ratio": {
                   "this": 112.5,
                   "CBSA": 8.9,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 11.86,
                   "CBSA": 20.7,
                   "state": 20.1
                },
                "error": {
                   "this": 6.59,
                   "CBSA": 1.08,
                   "state": 0.21
                },
                "numerators": {
                   "this": 782,
                   "CBSA": 35646,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 446,
                   "CBSA": 1902,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 59
                },
                "error_ratio": {
                   "this": 55.6,
                   "CBSA": 5.2,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 6.45,
                "CBSA": 5.78,
                "state": 4.88
             },
             "error": {
                "this": 3,
                "CBSA": 0.42,
                "state": 0.06
             },
             "numerators": {
                "this": 306,
                "CBSA": 10316,
                "state": 469884
             },
             "numerator_errors": {
                "this": 146,
                "CBSA": 748,
                "state": 5597
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 112,
                "state": 132
             },
             "error_ratio": {
                "this": 46.5,
                "CBSA": 7.3,
                "state": 1.2
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 1.18,
                   "state": 1.01
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.51,
                   "state": 0.07
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 317,
                   "state": 12727
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 136,
                   "state": 844
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 43.2,
                   "state": 6.9
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 6.5,
                   "state": 4.98
                },
                "error": {
                   "this": 0,
                   "CBSA": 1.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1621,
                   "state": 66276
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 348,
                   "state": 2263
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 21.4,
                   "state": 3.4
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 7.21,
                   "CBSA": 12.81,
                   "state": 7.77
                },
                "error": {
                   "this": 10.86,
                   "CBSA": 1.76,
                   "state": 0.16
                },
                "numerators": {
                   "this": 24,
                   "CBSA": 3304,
                   "state": 115600
                },
                "numerator_errors": {
                   "this": 39,
                   "CBSA": 462,
                   "state": 2442
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 93
                },
                "error_ratio": {
                   "this": 150.6,
                   "CBSA": 13.7,
                   "state": 2.1
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 21.26,
                   "CBSA": 10.15,
                   "state": 9.88
                },
                "error": {
                   "this": 14.2,
                   "CBSA": 1.12,
                   "state": 0.2
                },
                "numerators": {
                   "this": 101,
                   "CBSA": 2506,
                   "state": 138843
                },
                "numerator_errors": {
                   "this": 77,
                   "CBSA": 280,
                   "state": 2879
                },
                "index": {
                   "this": 100,
                   "CBSA": 209,
                   "state": 215
                },
                "error_ratio": {
                   "this": 66.8,
                   "CBSA": 11,
                   "state": 2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 13.85,
                   "CBSA": 7.81,
                   "state": 7.09
                },
                "error": {
                   "this": 8.01,
                   "CBSA": 1.16,
                   "state": 0.16
                },
                "numerators": {
                   "this": 160,
                   "CBSA": 1866,
                   "state": 93691
                },
                "numerator_errors": {
                   "this": 101,
                   "CBSA": 289,
                   "state": 2137
                },
                "index": {
                   "this": 100,
                   "CBSA": 177,
                   "state": 195
                },
                "error_ratio": {
                   "this": 57.8,
                   "CBSA": 14.9,
                   "state": 2.3
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 0,
                   "CBSA": 1.78,
                   "state": 2.43
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.59,
                   "state": 0.1
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 431,
                   "state": 30695
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 144,
                   "state": 1315
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 33.1,
                   "state": 4.1
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 3.25,
                   "CBSA": 0.96,
                   "state": 0.77
                },
                "error": {
                   "this": 5.03,
                   "CBSA": 0.34,
                   "state": 0.06
                },
                "numerators": {
                   "this": 21,
                   "CBSA": 271,
                   "state": 12052
                },
                "numerator_errors": {
                   "this": 33,
                   "CBSA": 95,
                   "state": 861
                },
                "index": {
                   "this": 100,
                   "CBSA": 339,
                   "state": 422
                },
                "error_ratio": {
                   "this": 154.8,
                   "CBSA": 35.4,
                   "state": 7.8
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 4732,
                "CBSA": 228567,
                "state": 1.3044266E7
             },
             "error": {
                "this": 377,
                "CBSA": 1049,
                "state": 20333
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 8,
                "CBSA": 0.5,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 3.8,
                "CBSA": 3.17,
                "state": 2.95
             },
             "error": {
                "this": 0.1,
                "CBSA": 0.02,
                "state": 0
             },
             "numerators": {
                "this": 17966,
                "CBSA": 725395,
                "state": 3.8462236E7
             },
             "numerator_errors": {
                "this": 1502,
                "CBSA": 1064,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 129
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 0.6,
                "state": null
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 81.08,
                   "CBSA": 63.27,
                   "state": 61.04
                },
                "error": {
                   "this": 6.2,
                   "CBSA": 0.7,
                   "state": 0.21
                },
                "numerators": {
                   "this": 14567,
                   "CBSA": 458931,
                   "state": 2.3478016E7
                },
                "numerator_errors": {
                   "this": 1650,
                   "CBSA": 5150,
                   "state": 81110
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 133
                },
                "error_ratio": {
                   "this": 7.6,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 5.69,
                   "CBSA": 8.57,
                   "state": 7.59
                },
                "error": {
                   "this": 4.19,
                   "CBSA": 0.48,
                   "state": 0.09
                },
                "numerators": {
                   "this": 1022,
                   "CBSA": 62138,
                   "state": 2919408
                },
                "numerator_errors": {
                   "this": 758,
                   "CBSA": 3505,
                   "state": 33493
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 75
                },
                "error_ratio": {
                   "this": 73.6,
                   "CBSA": 5.6,
                   "state": 1.2
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 9.08,
                   "CBSA": 17.83,
                   "state": 16.57
                },
                "error": {
                   "this": 3.94,
                   "CBSA": 0.57,
                   "state": 0.12
                },
                "numerators": {
                   "this": 1631,
                   "CBSA": 129366,
                   "state": 6373007
                },
                "numerator_errors": {
                   "this": 720,
                   "CBSA": 4113,
                   "state": 46993
                },
                "index": {
                   "this": 100,
                   "CBSA": 51,
                   "state": 55
                },
                "error_ratio": {
                   "this": 43.4,
                   "CBSA": 3.2,
                   "state": 0.7
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 4.15,
                   "CBSA": 10.33,
                   "state": 14.8
                },
                "error": {
                   "this": 1.88,
                   "CBSA": 0.34,
                   "state": 0.07
                },
                "numerators": {
                   "this": 746,
                   "CBSA": 74960,
                   "state": 5691804
                },
                "numerator_errors": {
                   "this": 344,
                   "CBSA": 2447,
                   "state": 27482
                },
                "index": {
                   "this": 100,
                   "CBSA": 40,
                   "state": 28
                },
                "error_ratio": {
                   "this": 45.3,
                   "CBSA": 3.3,
                   "state": 0.5
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 4846,
                "CBSA": 243260,
                "state": 1.4175976E7
             },
             "error": {
                "this": 359,
                "CBSA": 356,
                "state": 1184
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 7.4,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 97.65,
                   "CBSA": 93.96,
                   "state": 92.02
                },
                "error": {
                   "this": 2.86,
                   "CBSA": 0.41,
                   "state": 0.14
                },
                "numerators": {
                   "this": 4732,
                   "CBSA": 228567,
                   "state": 1.3044266E7
                },
                "numerator_errors": {
                   "this": 377,
                   "CBSA": 1049,
                   "state": 20333
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 106
                },
                "error_ratio": {
                   "this": 2.9,
                   "CBSA": 0.4,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 2.35,
                   "CBSA": 6.04,
                   "state": 7.98
                },
                "error": {
                   "this": 3.67,
                   "CBSA": 0.4,
                   "state": 0.15
                },
                "numerators": {
                   "this": 114,
                   "CBSA": 14693,
                   "state": 1131710
                },
                "numerator_errors": {
                   "this": 178,
                   "CBSA": 984,
                   "state": 20951
                },
                "index": {
                   "this": 100,
                   "CBSA": 39,
                   "state": 29
                },
                "error_ratio": {
                   "this": 156.2,
                   "CBSA": 6.6,
                   "state": 1.9
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 98.45,
                   "CBSA": 78.31,
                   "state": 64.78
                },
                "error": {
                   "this": 3.86,
                   "CBSA": 0.64,
                   "state": 0.09
                },
                "numerators": {
                   "this": 4771,
                   "CBSA": 190495,
                   "state": 9183849
                },
                "numerator_errors": {
                   "this": 399.8,
                   "CBSA": 1574.7,
                   "state": 13345.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 126,
                   "state": 152
                },
                "error_ratio": {
                   "this": 3.9,
                   "CBSA": 0.8,
                   "state": 0.1
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 1.55,
                   "CBSA": 18.19,
                   "state": 31.43
                },
                "error": {
                   "this": 1.69,
                   "CBSA": 0.58,
                   "state": 0.11
                },
                "numerators": {
                   "this": 75,
                   "CBSA": 44240,
                   "state": 4455831
                },
                "numerator_errors": {
                   "this": 82.2,
                   "CBSA": 1418.1,
                   "state": 15037
                },
                "index": {
                   "this": 100,
                   "CBSA": 9,
                   "state": 5
                },
                "error_ratio": {
                   "this": 109,
                   "CBSA": 3.2,
                   "state": 0.3
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0,
                   "CBSA": 3.39,
                   "state": 3.68
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.23,
                   "state": 0.03
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 8253,
                   "state": 521135
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 551,
                   "state": 4740
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 6.8,
                   "state": 0.8
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.11
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.04,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 272,
                   "state": 15161
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 94,
                   "state": 862
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 36.4,
                   "state": 9.1
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 75.66,
                   "CBSA": 56.62,
                   "state": 54.85
                },
                "error": {
                   "this": 5.9,
                   "CBSA": 0.75,
                   "state": 0.28
                },
                "numerators": {
                   "this": 3580,
                   "CBSA": 129419,
                   "state": 7154580
                },
                "numerator_errors": {
                   "this": 399,
                   "CBSA": 1813,
                   "state": 37872
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 138
                },
                "error_ratio": {
                   "this": 7.8,
                   "CBSA": 1.3,
                   "state": 0.5
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 24.34,
                   "CBSA": 43.38,
                   "state": 45.15
                },
                "error": {
                   "this": 6.26,
                   "CBSA": 0.68,
                   "state": 0.15
                },
                "numerators": {
                   "this": 1152,
                   "CBSA": 99148,
                   "state": 5889686
                },
                "numerator_errors": {
                   "this": 310,
                   "CBSA": 1627,
                   "state": 21311
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 54
                },
                "error_ratio": {
                   "this": 25.7,
                   "CBSA": 1.6,
                   "state": 0.3
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 595700,
                "CBSA": 342100,
                "state": 505000
             },
             "error": {
                "this": 16619,
                "CBSA": 3031,
                "state": 1147
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 174,
                "state": 118
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 0.9,
                "state": 0.2
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 0.36,
                   "CBSA": 6.5,
                   "state": 5.03
                },
                "error": {
                   "this": 1.92,
                   "CBSA": 0.45,
                   "state": 0.05
                },
                "numerators": {
                   "this": 13,
                   "CBSA": 8411,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 68.8,
                   "CBSA": 589.7,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 6,
                   "state": 7
                },
                "error_ratio": {
                   "this": 533.3,
                   "CBSA": 6.9,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 0,
                   "CBSA": 11.62,
                   "state": 6.63
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.52,
                   "state": 0.06
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 15039,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 38,
                   "CBSA": 706.1,
                   "state": 4768.2
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 4.5,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 1.23,
                   "CBSA": 21.64,
                   "state": 11.06
                },
                "error": {
                   "this": 2.1,
                   "CBSA": 0.74,
                   "state": 0.06
                },
                "numerators": {
                   "this": 44,
                   "CBSA": 28001,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 75.4,
                   "CBSA": 1034.6,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 6,
                   "state": 11
                },
                "error_ratio": {
                   "this": 170.7,
                   "CBSA": 3.4,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 7.71,
                   "CBSA": 24.33,
                   "state": 13.6
                },
                "error": {
                   "this": 5.92,
                   "CBSA": 0.7,
                   "state": 0.09
                },
                "numerators": {
                   "this": 276,
                   "CBSA": 31487,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 214,
                   "CBSA": 1012,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 32,
                   "state": 57
                },
                "error_ratio": {
                   "this": 76.8,
                   "CBSA": 2.9,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 15.78,
                   "CBSA": 16.98,
                   "state": 13.21
                },
                "error": {
                   "this": 5.04,
                   "CBSA": 0.76,
                   "state": 0.08
                },
                "numerators": {
                   "this": 565,
                   "CBSA": 21981,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 191,
                   "CBSA": 1028,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 119
                },
                "error_ratio": {
                   "this": 31.9,
                   "CBSA": 4.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 73.94,
                   "CBSA": 17.18,
                   "state": 35.28
                },
                "error": {
                   "this": 6.67,
                   "CBSA": 0.69,
                   "state": 0.26
                },
                "numerators": {
                   "this": 2647,
                   "CBSA": 22232,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 379.6,
                   "CBSA": 945.3,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 430,
                   "state": 210
                },
                "error_ratio": {
                   "this": 9,
                   "CBSA": 4,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 0.98,
                   "CBSA": 0.96,
                   "state": 7.93
                },
                "error": {
                   "this": 1.56,
                   "CBSA": 0.16,
                   "state": 0.04
                },
                "numerators": {
                   "this": 35,
                   "CBSA": 1245,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 56,
                   "CBSA": 213,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 12
                },
                "error_ratio": {
                   "this": 159.2,
                   "CBSA": 16.7,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 3580,
                "CBSA": 129419,
                "state": 7154580
             },
             "error": {
                "this": 399,
                "CBSA": 1813,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 11.1,
                "CBSA": 1.4,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 5-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 0,
                "CBSA": 6.99,
                "state": 9.72
             },
             "error": {
                "this": 0,
                "CBSA": 0.28,
                "state": 0.06
             },
             "numerators": {
                "this": 0,
                "CBSA": 50687,
                "state": 3739275
             },
             "numerator_errors": {
                "this": 26.9,
                "CBSA": 2062.1,
                "state": 21932.9
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 4,
                "state": 0.6
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 0.38,
                "CBSA": 8.9,
                "state": 12.25
             },
             "error": {
                "this": 0.62,
                "CBSA": 0.46,
                "state": 0.08
             },
             "numerators": {
                "this": 69,
                "CBSA": 64529,
                "state": 4711644
             },
             "numerator_errors": {
                "this": 110.6,
                "CBSA": 3343.3,
                "state": 30793.4
             },
             "index": {
                "this": 100,
                "CBSA": 4,
                "state": 3
             },
             "error_ratio": {
                "this": 163.2,
                "CBSA": 5.2,
                "state": 0.7
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 21.6,
                "CBSA": 25.8,
                "state": 25.7
             },
             "error": {
                "this": 6.39,
                "CBSA": 0.83,
                "state": 0.13
             },
             "numerators": {
                "this": 3881,
                "CBSA": 187118,
                "state": 9884339
             },
             "numerator_errors": {
                "this": 1192.2,
                "CBSA": 6035.6,
                "state": 50780.8
             },
             "index": {
                "this": 100,
                "CBSA": 84,
                "state": 84
             },
             "error_ratio": {
                "this": 29.6,
                "CBSA": 3.2,
                "state": 0.5
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 38.95,
                "CBSA": 33.06,
                "state": 28.78
             },
             "error": {
                "this": 6.78,
                "CBSA": 0.93,
                "state": 0.16
             },
             "numerators": {
                "this": 6998,
                "CBSA": 239847,
                "state": 1.1067761E7
             },
             "numerator_errors": {
                "this": 1352,
                "CBSA": 6777.6,
                "state": 60057.7
             },
             "index": {
                "this": 100,
                "CBSA": 118,
                "state": 135
             },
             "error_ratio": {
                "this": 17.4,
                "CBSA": 2.8,
                "state": 0.6
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 28.23,
                "CBSA": 16.48,
                "state": 14.48
             },
             "error": {
                "this": 7.95,
                "CBSA": 0.76,
                "state": 0.11
             },
             "numerators": {
                "this": 5071,
                "CBSA": 119534,
                "state": 5568721
             },
             "numerator_errors": {
                "this": 1489.2,
                "CBSA": 5527.2,
                "state": 43280.9
             },
             "index": {
                "this": 100,
                "CBSA": 171,
                "state": 195
             },
             "error_ratio": {
                "this": 28.2,
                "CBSA": 4.6,
                "state": 0.8
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 10.84,
                "CBSA": 8.78,
                "state": 9.08
             },
             "error": {
                "this": 4.28,
                "CBSA": 0.54,
                "state": 0.09
             },
             "numerators": {
                "this": 1947,
                "CBSA": 63680,
                "state": 3490495
             },
             "numerator_errors": {
                "this": 786.1,
                "CBSA": 3913.2,
                "state": 33565.8
             },
             "index": {
                "this": 100,
                "CBSA": 123,
                "state": 119
             },
             "error_ratio": {
                "this": 39.5,
                "CBSA": 6.2,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 16.45,
                "CBSA": 13.2,
                "state": 12.86
             },
             "error": {
                "this": 6.02,
                "CBSA": 0.62,
                "state": 0.08
             },
             "numerators": {
                "this": 2924,
                "CBSA": 96803,
                "state": 4993305
             },
             "numerator_errors": {
                "this": 1098.1,
                "CBSA": 4550.2,
                "state": 29689.5
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 125,
                "state": 128
             },
             "error_ratio": {
                "this": 36.6,
                "CBSA": 4.7,
                "state": 0.6
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 5-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 83.55,
                "CBSA": 86.8,
                "state": 87.14
             },
             "error": {
                "this": 4.3,
                "CBSA": 0.58,
                "state": 0.07
             },
             "numerators": {
                "this": 14855,
                "CBSA": 636277,
                "state": 3.3839688E7
             },
             "numerator_errors": {
                "this": 1455,
                "CBSA": 4292,
                "state": 27490
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 96
             },
             "error_ratio": {
                "this": 5.1,
                "CBSA": 0.7,
                "state": 0.1
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 1.21,
                "CBSA": 8.09,
                "state": 8.01
             },
             "error": {
                "this": 0.87,
                "CBSA": 0.51,
                "state": 0.07
             },
             "numerators": {
                "this": 215,
                "CBSA": 59273,
                "state": 3108823
             },
             "numerator_errors": {
                "this": 155,
                "CBSA": 3739,
                "state": 25604
             },
             "index": {
                "this": 100,
                "CBSA": 15,
                "state": 15
             },
             "error_ratio": {
                "this": 71.9,
                "CBSA": 6.3,
                "state": 0.9
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 12.79,
                "CBSA": 3.74,
                "state": 2.75
             },
             "error": {
                "this": 5.72,
                "CBSA": 0.31,
                "state": 0.03
             },
             "numerators": {
                "this": 2274,
                "CBSA": 27399,
                "state": 1069429
             },
             "numerator_errors": {
                "this": 1034,
                "CBSA": 2309,
                "state": 11456
             },
             "index": {
                "this": 100,
                "CBSA": 342,
                "state": 465
             },
             "error_ratio": {
                "this": 44.7,
                "CBSA": 8.3,
                "state": 1.1
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 1.53,
                "CBSA": 0.86,
                "state": 1.3
             },
             "error": {
                "this": 1.7,
                "CBSA": 0.13,
                "state": 0.02
             },
             "numerators": {
                "this": 272,
                "CBSA": 6299,
                "state": 503560
             },
             "numerator_errors": {
                "this": 303,
                "CBSA": 932,
                "state": 8001
             },
             "index": {
                "this": 100,
                "CBSA": 178,
                "state": 118
             },
             "error_ratio": {
                "this": 111.1,
                "CBSA": 15.1,
                "state": 1.5
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 0.92,
                "CBSA": 0.52,
                "state": 0.8
             },
             "error": {
                "this": 0.81,
                "CBSA": 0.1,
                "state": 0.01
             },
             "numerators": {
                "this": 163,
                "CBSA": 3832,
                "state": 311493
             },
             "numerator_errors": {
                "this": 144,
                "CBSA": 724,
                "state": 5536
             },
             "index": {
                "this": 100,
                "CBSA": 177,
                "state": 115
             },
             "error_ratio": {
                "this": 88,
                "CBSA": 19.2,
                "state": 1.2
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 94.06,
                "CBSA": 79.28,
                "state": 83.31
             },
             "error": {
                "this": 3.45,
                "CBSA": 0.86,
                "state": 0.15
             },
             "numerators": {
                "this": 9288,
                "CBSA": 370612,
                "state": 2.2052868E7
             },
             "numerator_errors": {
                "this": 879.4,
                "CBSA": 4010.1,
                "state": 39734.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 119,
                "state": 113
             },
             "error_ratio": {
                "this": 3.7,
                "CBSA": 1.1,
                "state": 0.2
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 47.27,
                "CBSA": 18.76,
                "state": 33.93
             },
             "error": {
                "this": 3.78,
                "CBSA": 0.45,
                "state": 0.09
             },
             "numerators": {
                "this": 4668,
                "CBSA": 87705,
                "state": 8980726
             },
             "numerator_errors": {
                "this": 552.4,
                "CBSA": 2100.6,
                "state": 23065.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 252,
                "state": 139
             },
             "error_ratio": {
                "this": 8,
                "CBSA": 2.4,
                "state": 0.3
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 5.94,
                "CBSA": 20.72,
                "state": 16.69
             },
             "error": {
                "this": 2.35,
                "CBSA": 0.5,
                "state": 0.07
             },
             "numerators": {
                "this": 587,
                "CBSA": 96843,
                "state": 4418675
             },
             "numerator_errors": {
                "this": 237.5,
                "CBSA": 2355.6,
                "state": 18934.1
             },
             "index": {
                "this": 100,
                "CBSA": 29,
                "state": 36
             },
             "error_ratio": {
                "this": 39.6,
                "CBSA": 2.4,
                "state": 0.4
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 12.39,
                "CBSA": 28.34,
                "state": 20.49
             },
             "error": {
                "this": 3.28,
                "CBSA": 0.46,
                "state": 0.08
             },
             "numerators": {
                "this": 1224,
                "CBSA": 132461,
                "state": 5423462
             },
             "numerator_errors": {
                "this": 340.8,
                "CBSA": 2128.5,
                "state": 22237.1
             },
             "index": {
                "this": 100,
                "CBSA": 44,
                "state": 60
             },
             "error_ratio": {
                "this": 26.5,
                "CBSA": 1.6,
                "state": 0.4
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 34.39,
                "CBSA": 32.18,
                "state": 28.89
             },
             "error": {
                "this": 5.2,
                "CBSA": 0.57,
                "state": 0.09
             },
             "numerators": {
                "this": 3396,
                "CBSA": 150446,
                "state": 7648680
             },
             "numerator_errors": {
                "this": 593.3,
                "CBSA": 2671.8,
                "state": 23501.3
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 119
             },
             "error_ratio": {
                "this": 15.1,
                "CBSA": 1.8,
                "state": 0.3
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 27.4,
                "CBSA": 12.89,
                "state": 21.17
             },
             "error": {
                "this": 3.5,
                "CBSA": 0.38,
                "state": 0.07
             },
             "numerators": {
                "this": 2706,
                "CBSA": 60276,
                "state": 5603047
             },
             "numerator_errors": {
                "this": 418.7,
                "CBSA": 1767,
                "state": 17216.6
             },
             "index": {
                "this": 100,
                "CBSA": 213,
                "state": 129
             },
             "error_ratio": {
                "this": 12.8,
                "CBSA": 2.9,
                "state": 0.3
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 19.87,
                "CBSA": 5.87,
                "state": 12.76
             },
             "error": {
                "this": 3.21,
                "CBSA": 0.24,
                "state": 0.06
             },
             "numerators": {
                "this": 1962,
                "CBSA": 27429,
                "state": 3377679
             },
             "numerator_errors": {
                "this": 360.4,
                "CBSA": 1135.8,
                "state": 15348.8
             },
             "index": {
                "this": 100,
                "CBSA": 339,
                "state": 156
             },
             "error_ratio": {
                "this": 16.2,
                "CBSA": 4.1,
                "state": 0.5
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 35.01,
                "CBSA": 23.31,
                "state": 26.85
             },
             "error": {
                "this": 5.21,
                "CBSA": 0.46,
                "state": 0.09
             },
             "numerators": {
                "this": 6290,
                "CBSA": 173091,
                "state": 1.054721E7
             },
             "numerator_errors": {
                "this": 1074,
                "CBSA": 3411,
                "state": 36592
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 150,
                "state": 130
             },
             "error_ratio": {
                "this": 14.9,
                "CBSA": 2,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 3.51,
                   "CBSA": 3.29,
                   "state": 6.42
                },
                "error": {
                   "this": 2.77,
                   "CBSA": 0.4,
                   "state": 0.07
                },
                "numerators": {
                   "this": 221,
                   "CBSA": 5693,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 178,
                   "CBSA": 700,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 55
                },
                "error_ratio": {
                   "this": 78.9,
                   "CBSA": 12.2,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 72.21,
                   "CBSA": 41.42,
                   "state": 39.42
                },
                "error": {
                   "this": 2.39,
                   "CBSA": 0.56,
                   "state": 0.09
                },
                "numerators": {
                   "this": 4542,
                   "CBSA": 71699,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 790,
                   "CBSA": 1715,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 174,
                   "state": 183
                },
                "error_ratio": {
                   "this": 3.3,
                   "CBSA": 1.4,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 10.65,
                   "CBSA": 1.41,
                   "state": 1.84
                },
                "error": {
                   "this": 8.34,
                   "CBSA": 0.42,
                   "state": 0.05
                },
                "numerators": {
                   "this": 670,
                   "CBSA": 2432,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 537,
                   "CBSA": 737,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 755,
                   "state": 579
                },
                "error_ratio": {
                   "this": 78.3,
                   "CBSA": 29.8,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 1.7,
                   "CBSA": 1.47,
                   "state": 0.82
                },
                "error": {
                   "this": 1.4,
                   "CBSA": 0.29,
                   "state": 0.03
                },
                "numerators": {
                   "this": 107,
                   "CBSA": 2550,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 90,
                   "CBSA": 513,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 116,
                   "state": 207
                },
                "error_ratio": {
                   "this": 82.4,
                   "CBSA": 19.7,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 7.52,
                   "CBSA": 51.81,
                   "state": 50.28
                },
                "error": {
                   "this": 3.49,
                   "CBSA": 0.91,
                   "state": 0.15
                },
                "numerators": {
                   "this": 473,
                   "CBSA": 89670,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 234,
                   "CBSA": 2364,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 15
                },
                "error_ratio": {
                   "this": 46.4,
                   "CBSA": 1.8,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 4.4,
                   "CBSA": 0.6,
                   "state": 1.23
                },
                "error": {
                   "this": 6.14,
                   "CBSA": 0.27,
                   "state": 0.03
                },
                "numerators": {
                   "this": 277,
                   "CBSA": 1047,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 389,
                   "CBSA": 473,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 733,
                   "state": 358
                },
                "error_ratio": {
                   "this": 139.5,
                   "CBSA": 45,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 74.36,
                   "CBSA": 61.32,
                   "state": 56.46
                },
                "error": {
                   "this": 7,
                   "CBSA": 1.26,
                   "state": 0.24
                },
                "numerators": {
                   "this": 3977,
                   "CBSA": 92168,
                   "state": 3709800
                },
                "numerator_errors": {
                   "this": 582,
                   "CBSA": 1896,
                   "state": 15797
                },
                "index": {
                   "this": 100,
                   "CBSA": 121,
                   "state": 132
                },
                "error_ratio": {
                   "this": 9.4,
                   "CBSA": 2.1,
                   "state": 0.4
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 3.37,
                   "CBSA": 28.88,
                   "state": 33.98
                },
                "error": {
                   "this": 2.29,
                   "CBSA": 0.85,
                   "state": 0.2
                },
                "numerators": {
                   "this": 180,
                   "CBSA": 43408,
                   "state": 2232947
                },
                "numerator_errors": {
                   "this": 124,
                   "CBSA": 1277,
                   "state": 13243
                },
                "index": {
                   "this": 100,
                   "CBSA": 12,
                   "state": 10
                },
                "error_ratio": {
                   "this": 68,
                   "CBSA": 2.9,
                   "state": 0.6
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 8.34,
                   "CBSA": 3.83,
                   "state": 2.92
                },
                "error": {
                   "this": 3.72,
                   "CBSA": 0.52,
                   "state": 0.07
                },
                "numerators": {
                   "this": 446,
                   "CBSA": 5757,
                   "state": 191982
                },
                "numerator_errors": {
                   "this": 205,
                   "CBSA": 785,
                   "state": 4345
                },
                "index": {
                   "this": 100,
                   "CBSA": 218,
                   "state": 286
                },
                "error_ratio": {
                   "this": 44.6,
                   "CBSA": 13.6,
                   "state": 2.4
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 13.26,
                   "CBSA": 5.27,
                   "state": 5.73
                },
                "error": {
                   "this": 4.33,
                   "CBSA": 0.61,
                   "state": 0.06
                },
                "numerators": {
                   "this": 709,
                   "CBSA": 7918,
                   "state": 376241
                },
                "numerator_errors": {
                   "this": 245,
                   "CBSA": 913,
                   "state": 4139
                },
                "index": {
                   "this": 100,
                   "CBSA": 252,
                   "state": 231
                },
                "error_ratio": {
                   "this": 32.7,
                   "CBSA": 11.6,
                   "state": 1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.67,
                   "CBSA": 0.71,
                   "state": 0.91
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.27,
                   "state": 0.04
                },
                "numerators": {
                   "this": 36,
                   "CBSA": 1062,
                   "state": 59648
                },
                "numerator_errors": {
                   "this": 59,
                   "CBSA": 403,
                   "state": 2823
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 74
                },
                "error_ratio": {
                   "this": 164.2,
                   "CBSA": 38,
                   "state": 4.4
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 51.22,
                   "CBSA": 58.53,
                   "state": 55.62
                },
                "error": {
                   "this": 6.76,
                   "CBSA": 0.48,
                   "state": 0.11
                },
                "numerators": {
                   "this": 5711,
                   "CBSA": 315996,
                   "state": 1.6830152E7
                },
                "numerator_errors": {
                   "this": 886.5,
                   "CBSA": 2597.6,
                   "state": 34792.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 92
                },
                "error_ratio": {
                   "this": 13.2,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 7.02,
                   "CBSA": 25.36,
                   "state": 27.58
                },
                "error": {
                   "this": 4.02,
                   "CBSA": 0.39,
                   "state": 0.08
                },
                "numerators": {
                   "this": 783,
                   "CBSA": 136904,
                   "state": 8345569
                },
                "numerator_errors": {
                   "this": 452.5,
                   "CBSA": 2091.3,
                   "state": 23054.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 28,
                   "state": 25
                },
                "error_ratio": {
                   "this": 57.3,
                   "CBSA": 1.5,
                   "state": 0.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 14.07,
                   "CBSA": 5.42,
                   "state": 4.85
                },
                "error": {
                   "this": 3.68,
                   "CBSA": 0.33,
                   "state": 0.04
                },
                "numerators": {
                   "this": 1569,
                   "CBSA": 29289,
                   "state": 1468932
                },
                "numerator_errors": {
                   "this": 430.1,
                   "CBSA": 1773.6,
                   "state": 12705.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 260,
                   "state": 290
                },
                "error_ratio": {
                   "this": 26.2,
                   "CBSA": 6.1,
                   "state": 0.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 25.34,
                   "CBSA": 10.03,
                   "state": 10.88
                },
                "error": {
                   "this": 4.23,
                   "CBSA": 0.3,
                   "state": 0.03
                },
                "numerators": {
                   "this": 2825,
                   "CBSA": 54151,
                   "state": 3293073
                },
                "numerator_errors": {
                   "this": 525.1,
                   "CBSA": 1618.5,
                   "state": 10307.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 253,
                   "state": 233
                },
                "error_ratio": {
                   "this": 16.7,
                   "CBSA": 3,
                   "state": 0.3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.34,
                   "CBSA": 0.66,
                   "state": 1.07
                },
                "error": {
                   "this": 2.29,
                   "CBSA": 0.13,
                   "state": 0.02
                },
                "numerators": {
                   "this": 261,
                   "CBSA": 3562,
                   "state": 323625
                },
                "numerator_errors": {
                   "this": 255.7,
                   "CBSA": 709,
                   "state": 7069.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 355,
                   "state": 219
                },
                "error_ratio": {
                   "this": 97.9,
                   "CBSA": 19.7,
                   "state": 1.9
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 0,
                   "CBSA": 926,
                   "state": 66574
                },
                "error": {
                   "this": 32.91,
                   "CBSA": 192.08,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 20.7,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 0,
                   "CBSA": 2137,
                   "state": 145536
                },
                "error": {
                   "this": 38,
                   "CBSA": 248.99,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 11.7,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 62,
                   "CBSA": 11136,
                   "state": 561083
                },
                "error": {
                   "this": 73.57,
                   "CBSA": 592.84,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 118.7,
                   "CBSA": 5.3,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 244,
                   "CBSA": 5323,
                   "state": 284290
                },
                "error": {
                   "this": 124.99,
                   "CBSA": 464.29,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": null
                },
                "error_ratio": {
                   "this": 51.2,
                   "CBSA": 8.7,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 149,
                   "CBSA": 4711,
                   "state": 309021
                },
                "error": {
                   "this": 80.11,
                   "CBSA": 518.91,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 53.8,
                   "CBSA": 11,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 326,
                   "CBSA": 26579,
                   "state": 1446385
                },
                "error": {
                   "this": 144,
                   "CBSA": 968,
                   "state": 8117
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 44.2,
                   "CBSA": 3.6,
                   "state": 0.6
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 21,
                   "CBSA": 2434,
                   "state": 128146
                },
                "error": {
                   "this": 33,
                   "CBSA": 376,
                   "state": 2994
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 157.1,
                   "CBSA": 15.4,
                   "state": 2.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 347,
                "CBSA": 29013,
                "state": 1574531
             },
             "error": {
                "this": 146,
                "CBSA": 1041,
                "state": 8842
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 1,
                "state": null
             },
             "error_ratio": {
                "this": 42.1,
                "CBSA": 3.6,
                "state": 0.6
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 3.11,
                "CBSA": 5.38,
                "state": 5.23
             },
             "error": {
                "this": 1.28,
                "CBSA": 0.19,
                "state": 0.03
             },
             "numerators": {
                "this": 347,
                "CBSA": 29013,
                "state": 1574531
             },
             "numerator_errors": {
                "this": 146,
                "CBSA": 1041,
                "state": 8842
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 58,
                "state": 59
             },
             "error_ratio": {
                "this": 41.2,
                "CBSA": 3.5,
                "state": 0.6
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 8269148,
       "awater": 0,
       "display_name": "Mountain House, CA",
       "full_geoid": "16000US0649582",
       "population": 15645,
       "simple_name": "Mountain House",
       "sumlevel": "160",
       "square_miles": 3.2,
       "population_density": 5627.1
    }
 }
 export default mountain_house