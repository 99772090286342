exports.MOUNTAIN_VIEW_LISTINGS = [
		{
			"closePrice": 1973000,
			"daysOnMovoto": 10,
			"id": "ff5b53c2-226c-4058-854e-03f2cc31734d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81873360_0_NFbzRv_p.jpeg",
			"listDate": "2022-01-03 14:16:53",
			"listingAgent": "Frances Ibay",
			"listPrice": 1973000,
			"lotSize": 1433,
			"sqftTotal": 1994,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81873360",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Dividend Homes, Inc.",
			"photoCount": 16,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94043",
			"path": "mountain-view-ca/2018-w-middlefield-rd-mountain-view-ca-94043-100_ml81873360/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2b9ca399-ab78-426e-b175-f88e038445aa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2022-01-03 22:22:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2022-01-03 14:16:53",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.411312,
				"lng": -122.090902,
				"zipcode": "94043",
				"subPremise": "",
				"address": "2018 W Middlefield Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1973000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 688000,
			"daysOnMovoto": 58,
			"id": "db4571cf-acda-4c54-af34-210cdc430740",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870260_0_uyBJr7_p.jpeg",
			"listDate": "2021-11-15 11:19:46",
			"listingAgent": "Vickie Burgess",
			"listPrice": 728000,
			"lotSize": null,
			"sqftTotal": 935,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870260",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realsmart Properties",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94040",
			"path": "mountain-view-ca/255-s-rengstorff-ave-40-mountain-view-ca-94040/pid_zdho2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "6ba30c4b-042b-48f5-a0eb-ad68006c613e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-11-15 19:22:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 11:19:46",
			"virtualTourLink": "http://www.255srengstorff.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.399553,
				"lng": -122.098889,
				"zipcode": "94040",
				"subPremise": "APT 40",
				"address": "255 S Rengstorff Ave #40"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 688000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.255srengstorff.com/"
		},
		{
			"closePrice": 189999,
			"daysOnMovoto": 224,
			"id": "f410b013-79bf-4927-b95a-dc8c6486353c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81846086_0_JiRvMf_p.jpeg",
			"listDate": "2021-05-27 11:46:41",
			"listingAgent": "Eric Sjoberg",
			"listPrice": 189999,
			"lotSize": null,
			"sqftTotal": 960,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81846086",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Century 21 Real Estate Alliance Inc",
			"photoCount": 17,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2015,
			"zipCode": "94041",
			"path": "mountain-view-ca/325-sylvan-ave-mountain-view-ca-94041-202_ml81452731/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -24901,
			"propertyId": "0ae80920-4047-4a29-936c-de95c41716cc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-05-27 18:57:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-27 11:46:41",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.384939,
				"lng": -122.058688,
				"zipcode": "94041",
				"subPremise": "",
				"address": "325 Sylvan Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 189999,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 795000,
			"daysOnMovoto": 41,
			"id": "f9189d47-5d43-450a-83b4-e6815f562870",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870592_0_ivFuYV_p.jpeg",
			"listDate": "2021-11-18 14:42:31",
			"listingAgent": "Royce H. Cablayan",
			"listPrice": 795000,
			"lotSize": null,
			"sqftTotal": 978,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870592",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94043",
			"path": "mountain-view-ca/500-w-middlefield-rd-49-mountain-view-ca-94043/pid_987n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "38628747-d731-43d2-a2bb-ad293236b7a0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-18 22:47:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 14:42:31",
			"virtualTourLink": "https://www.tourfactory.com/2937720",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.400379,
				"lng": -122.071166,
				"zipcode": "94043",
				"subPremise": "APT 49",
				"address": "500 W Middlefield Rd #49"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 795000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2937720"
		},
		{
			"closePrice": 1026000,
			"daysOnMovoto": 119,
			"id": "7a8da87e-77d1-46f9-8d2e-738a8b963840",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860868_0_zj7I36_p.jpeg",
			"listDate": "2021-09-01 14:30:34",
			"listingAgent": "Nena Price",
			"listPrice": 1050000,
			"lotSize": null,
			"sqftTotal": 1215,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860868",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1978,
			"zipCode": "94043",
			"path": "mountain-view-ca/905-w-middlefield-rd-966-mountain-view-ca-94043/pid_pe5n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5fdba35b-d7ab-4121-9eff-37a3c9a09372",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-09-01 21:37:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 14:30:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.405361,
				"lng": -122.077699,
				"zipcode": "94043",
				"subPremise": "APT 966",
				"address": "905 W Middlefield Rd #966"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1026000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 849000,
			"daysOnMovoto": 0,
			"id": "10801180-2641-469e-b7b2-9fcaf511eb16",
			"tnImgPath": "https://pi.movoto.com/p/102/221132269_0_jqEUrR_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Aguilar-Castellanos",
			"listPrice": 849000,
			"lotSize": 0,
			"sqftTotal": 739,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221132269",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "D R Horton America's Builder",
			"photoCount": 16,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94043",
			"path": "mountain-view-ca/654-taylor-ct-mountain-view-ca-94043-2001_dznvlhtibec/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8a39b0c3-89b4-4128-9b6f-5be8f22ac99f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-14 21:19:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 21:19:33",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.402378,
				"lng": -122.066898,
				"zipcode": "94043",
				"subPremise": "",
				"address": "654 Taylor Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 849000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2273800,
			"daysOnMovoto": 55,
			"id": "55bfdf52-8d2b-4f56-a92d-c842ba5b2699",
			"tnImgPath": "https://pi.movoto.com/p/12/40971823_0_rYIVji_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Catherine Consolino",
			"listPrice": 2550000,
			"lotSize": 5900,
			"sqftTotal": 1562,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971823",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pacific Associates",
			"photoCount": 15,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94040",
			"path": "mountain-view-ca/1539-miramonte-mountain-view-ca-94040-12_40971823/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1feb4c14-cfe1-4f6c-b2d0-dbb903dd939c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-10-30 01:46:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.377412,
				"lng": -122.08666,
				"zipcode": "94040",
				"subPremise": "",
				"address": "1539 Miramonte"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2273800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 36,
			"id": "48b8bb29-0999-4229-bc92-d8ef168f2361",
			"tnImgPath": "https://pi.movoto.com/p/12/40973913_0_FyNUq7_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Liping Zhang",
			"listPrice": 1900000,
			"lotSize": 3032,
			"sqftTotal": 1049,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973913",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2001,
			"zipCode": "94040",
			"path": "mountain-view-ca/282-college-ave-mountain-view-ca-94040-12_40973913/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 302000,
			"propertyId": "14143ea3-9ae5-4cf9-86b5-61f5e0c7a8ac",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-11 08:41:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.401196,
				"lng": -122.101219,
				"zipcode": "94040",
				"subPremise": "",
				"address": "282 College Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2400888,
			"daysOnMovoto": 35,
			"id": "bb600894-9546-45f9-b194-25fee8a2e551",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869724_0_BM3qq6_p.jpeg",
			"listDate": "2021-11-10 05:16:12",
			"listingAgent": "Josh Felder",
			"listPrice": 1998000,
			"lotSize": 7131,
			"sqftTotal": 2379,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869724",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 58,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94040",
			"path": "mountain-view-ca/1023-dale-ave-mountain-view-ca-94040/pid_gmro2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "738a5598-e1e0-4b69-933a-67d0c0f0e605",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-10 13:22:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 05:16:12",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.374451,
				"lng": -122.062819,
				"zipcode": "94040",
				"subPremise": "",
				"address": "1023 Dale Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2390000,
			"daysOnMovoto": 288,
			"id": "5e8cf20f-9941-466e-ba7c-08372637207b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832094_0_fvfMEU_p.jpeg",
			"listDate": "2021-03-02 13:17:04",
			"listingAgent": "Tom Shafer jr",
			"listPrice": 2400000,
			"lotSize": 8002,
			"sqftTotal": 3759,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832094",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Shafer's Real Estate",
			"photoCount": 32,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "94040",
			"path": "mountain-view-ca/830-williams-way-mountain-view-ca-94040/pid_rdro2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -24950,
			"propertyId": "115051e3-a91a-47b0-8241-a5a637347e17",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-03-02 21:22:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-02 13:17:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.374884,
				"lng": -122.061891,
				"zipcode": "94040",
				"subPremise": "",
				"address": "830 Williams Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 154,
			"id": "210b1195-cf51-4955-bca0-de0b2adba135",
			"tnImgPath": "https://pi.movoto.com/p/110/421594478_0_7a6ufn_p.jpeg",
			"listDate": "2021-07-14 00:00:00",
			"listingAgent": "Gregory Onzo-Tasher",
			"listPrice": 695000,
			"lotSize": 963,
			"sqftTotal": 840,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421594478",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94043",
			"path": "mountain-view-ca/123-flynn-ave-d-mountain-view-ca-94043-100_81314545/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2053dcd8-cbf3-4337-aaaa-e6fc4f737e75",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-09-14 01:21:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.398956,
				"lng": -122.06225,
				"zipcode": "94043",
				"subPremise": "APT D",
				"address": "123 Flynn Ave #D"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1790000,
			"daysOnMovoto": 8,
			"id": "2d24e795-3205-4c6a-8c48-e03a53e24807",
			"tnImgPath": "https://pi.movoto.com/p/110/421610091_0_bmMeze_p.jpeg",
			"listDate": "2021-11-30 00:00:00",
			"listingAgent": "Mary Lee",
			"listPrice": 1799000,
			"lotSize": 1016,
			"sqftTotal": 1704,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421610091",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Mary Lee, Broker",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2012,
			"zipCode": "94041",
			"path": "mountain-view-ca/231-w-evelyn-ave-mountain-view-ca-94041/pid_8qax2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -1000,
			"propertyId": "a1c56056-b008-4ad6-a6ec-b12846089438",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-12-03 08:16:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.39201,
				"lng": -122.071972,
				"zipcode": "94041",
				"subPremise": "",
				"address": "231 W Evelyn Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1790000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1895000,
			"daysOnMovoto": 38,
			"id": "b884a89c-0f40-438d-8e03-618f1e79f3eb",
			"tnImgPath": "https://pi.movoto.com/p/12/40972283_0_fuVEfm_p.jpeg",
			"listDate": "2021-10-26 00:00:00",
			"listingAgent": "Paul Desmet",
			"listPrice": 1895000,
			"lotSize": 1025,
			"sqftTotal": 1779,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972283",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Courtesy Brokerage",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94043",
			"path": "mountain-view-ca/314-xavier-way-mountain-view-ca-94043-12_40972283/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "54c34e52-015e-4587-817c-c9446b8ee17a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-26 21:16:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.405591,
				"lng": -122.090386,
				"zipcode": "94043",
				"subPremise": "",
				"address": "314 Xavier Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1895000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1825000,
			"daysOnMovoto": 62,
			"id": "7595002b-e68c-4302-b3d8-b4bdb6dc7106",
			"tnImgPath": "https://pi.movoto.com/p/12/40969466_0_IVeuq2_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Brandon Payton",
			"listPrice": 1895000,
			"lotSize": 7500,
			"sqftTotal": 1207,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969466",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 29,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1919,
			"zipCode": "94041",
			"path": "mountain-view-ca/484-s-shoreline-blvd-mountain-view-ca-94041/pid_rk7n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -104999,
			"propertyId": "7c03f305-e882-4a86-bf1c-9696136abf3b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-02 09:26:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2921479",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.392142,
				"lng": -122.085842,
				"zipcode": "94041",
				"subPremise": "",
				"address": "484 S Shoreline Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2921479"
		},
		{
			"closePrice": 1890000,
			"daysOnMovoto": 292,
			"id": "772e21b3-3978-459c-8fc7-e29d4ab8eb5c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828240_0_J6nj2e_p.jpeg",
			"listDate": "2021-02-02 23:52:11",
			"listingAgent": "Maggie Xu",
			"listPrice": 1999000,
			"lotSize": 14362,
			"sqftTotal": 2880,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828240",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Maxreal",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94041",
			"path": "mountain-view-ca/1802-higdon-ave-mountain-view-ca-94041/pid_9m5n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -39888,
			"propertyId": "a3175fd7-285b-4993-8df0-7203ba9935b6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-02-03 07:57:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-02 23:52:11",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.39954,
				"lng": -122.08952,
				"zipcode": "94041",
				"subPremise": "",
				"address": "1802 Higdon Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1890000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4250000,
			"daysOnMovoto": 35,
			"id": "3691adcf-b978-4ee8-b950-287cd2655f62",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866772_0_ivJFVB_p.jpeg",
			"listDate": "2021-10-14 11:50:26",
			"listingAgent": "Diyar Essaid",
			"listPrice": 3500000,
			"lotSize": 8015,
			"sqftTotal": 2226,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866772",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1954,
			"zipCode": "94040",
			"path": "mountain-view-ca/1445-meadow-ln-mountain-view-ca-94040-100_80121366/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a228d07d-ea2c-4357-beb2-e0f7c41b77ad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-14 18:52:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 11:50:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.379896,
				"lng": -122.092486,
				"zipcode": "94040",
				"subPremise": "",
				"address": "1445 Meadow Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 339000,
			"daysOnMovoto": 81,
			"id": "5f8cda19-d578-4666-92e3-055a7258f98c",
			"tnImgPath": "https://pi.movoto.com/p/12/40965178_0_vYznzz_p.jpeg",
			"listDate": "2021-08-30 00:00:00",
			"listingAgent": "Dessislava Gueorguieva",
			"listPrice": 349000,
			"lotSize": 1096,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965178",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 40,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94041",
			"path": "mountain-view-ca/501-moorpark-way-apt-98-mountain-view-ca-94041-12_40965178/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "44cddecb-40c5-450b-8f64-9b3c6c6ebb3a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-08-30 16:46:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-30 00:00:00",
			"virtualTourLink": "https://501MooreparkWay9846094mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.381578,
				"lng": -122.065766,
				"zipcode": "94041",
				"subPremise": "APT 98",
				"address": "501 Moorpark Way #98"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 339000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://501MooreparkWay9846094mls.f8re.com/"
		},
		{
			"closePrice": 142000,
			"daysOnMovoto": 326,
			"id": "2ee1e675-21b7-4e8f-bc3e-9fc052d938dd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824094_0_Ay7IjN_p.jpeg",
			"listDate": "2020-12-27 16:26:57",
			"listingAgent": "Derek Cappiello",
			"listPrice": 149000,
			"lotSize": null,
			"sqftTotal": 1100,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824094",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sothebys Intl Rlty",
			"photoCount": 22,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1967,
			"zipCode": "94040",
			"path": "mountain-view-ca/191-e-el-camino-mountain-view-ca-94040-100_ml81737797/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "4340592e-73fd-44d4-9ecb-f28db47c4f93",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2020-12-28 00:32:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-27 16:26:57",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.378565,
				"lng": -122.069854,
				"zipcode": "94040",
				"subPremise": "",
				"address": "191 El Camino"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 142000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2121000,
			"daysOnMovoto": 72,
			"id": "3024cc43-7ddc-4680-8c81-d7bb110c5a00",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860673_0_m262IE_p.jpeg",
			"listDate": "2021-08-31 11:07:10",
			"listingAgent": "Dominic Nicoli",
			"listPrice": 2099000,
			"lotSize": 6133,
			"sqftTotal": 2022,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860673",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 27,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1969,
			"zipCode": "94040",
			"path": "mountain-view-ca/1738-plaza-ct-mountain-view-ca-94040/pid_qtey2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "19373c67-2213-44e5-af89-ba80041c802f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-08-31 18:12:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 11:07:10",
			"virtualTourLink": "Https://www.1740Plaza.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.372228,
				"lng": -122.089204,
				"zipcode": "94040",
				"subPremise": "",
				"address": "1738 Plaza Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2121000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.1740Plaza.com"
		},
		{
			"closePrice": 1170000,
			"daysOnMovoto": 13,
			"id": "2b174c2e-005d-49d7-a4b4-937706a673ed",
			"tnImgPath": "https://pi.movoto.com/p/101/321102420_0_BBBJfa_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Michael C Williams",
			"listPrice": 1095000,
			"lotSize": 732,
			"sqftTotal": 1150,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321102420",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Aalto, Inc",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2003,
			"zipCode": "94043",
			"path": "mountain-view-ca/701-astor-ct-mountain-view-ca-94043-100_80563282/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6da26a8d-8f7c-4220-bb09-c340b5ed7295",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-29 02:13:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.412735,
				"lng": -122.092839,
				"zipcode": "94043",
				"subPremise": "",
				"address": "701 Astor Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1170000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 42,
			"id": "c5976c70-32af-4dbf-b270-d10b9d33f3fe",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863352_0_YzZIqA_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Peter Vece",
			"listPrice": 2250000,
			"lotSize": 9540,
			"sqftTotal": 2810,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863352",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 39,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1967,
			"zipCode": "94041",
			"path": "mountain-view-ca/775-777-luce-ct-mountain-view-ca-94041-100_ml81863352/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6e28047a-a36f-4cea-ae51-1681ad8e33b3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-09-21 00:17:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 00:00:00",
			"virtualTourLink": "http://www.775-777lucect.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.378011,
				"lng": -122.063644,
				"zipcode": "94041",
				"subPremise": "",
				"address": "775-777 Luce Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.775-777lucect.com"
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 63,
			"id": "d562ae07-5a1a-438c-a65f-cc7ecda16b85",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860166_0_BUebE2_p.jpeg",
			"listDate": "2021-08-26 17:14:32",
			"listingAgent": "Denise Carr",
			"listPrice": 2248000,
			"lotSize": 9448,
			"sqftTotal": 3071,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860166",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 24,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94041",
			"path": "mountain-view-ca/707-709-sylvan-ave-mountain-view-ca-94041-100_ml81860166/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e3c35cd7-5152-4f50-82c7-c6ea1575e1c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-08-27 00:17:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 17:14:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.379074,
				"lng": -122.062277,
				"zipcode": "94041",
				"subPremise": "",
				"address": "707 709 Sylvan Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1635000,
			"daysOnMovoto": 29,
			"id": "021c92b7-2819-4ce0-a605-f49ddcf68ff9",
			"tnImgPath": "https://pi.movoto.com/p/101/321090098_0_yjUfYi_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Dan Shah",
			"listPrice": 1679000,
			"lotSize": 928,
			"sqftTotal": 1885,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321090098",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "ACRE-Alliance Capital Real Est",
			"photoCount": 46,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2010,
			"zipCode": "94043",
			"path": "mountain-view-ca/1922-newbury-dr-mountain-view-ca-94043/pid_r64n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "356451d5-df35-4286-aa72-c3c008ef3384",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-29 15:03:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.416104,
				"lng": -122.088326,
				"zipcode": "94043",
				"subPremise": "",
				"address": "1922 Newbury Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1635000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 978000,
			"daysOnMovoto": 0,
			"id": "368bce7f-430b-4f51-b275-9f977077dfc3",
			"tnImgPath": "https://pi.movoto.com/p/102/221083370_0_nQqEfR_p.jpeg",
			"listDate": null,
			"listingAgent": "Matteen Helmand",
			"listPrice": 981000,
			"lotSize": 584,
			"sqftTotal": 1199,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221083370",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "SkyWest Real Estate",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1981,
			"zipCode": "94040",
			"path": "mountain-view-ca/100-w-el-camino-real-62-mountain-view-ca-94040/pid_4g8n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -1000,
			"propertyId": "8fe5637d-bad5-4b3a-9648-032b49615860",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-18 00:00:00",
			"createdAt": "2021-07-15 22:14:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 22:14:28",
			"virtualTourLink": "https://player.vimeo.com/video/575504873",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.382369,
				"lng": -122.076157,
				"zipcode": "94040",
				"subPremise": "APT 62",
				"address": "100 W El Camino Real #62"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 978000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/575504873"
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 50,
			"id": "b14012d0-96aa-47b7-80b6-0a984d7d7a36",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859932_0_7mZBVB_p.jpeg",
			"listDate": "2021-08-25 13:51:51",
			"listingAgent": "Raj Abhyanker",
			"listPrice": 1995000,
			"lotSize": 7510,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859932",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Raj Abhyanker, Broker",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94040",
			"path": "mountain-view-ca/1327-villa-st-mountain-view-ca-94041-12_40868567/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5bc8c648-0988-40ba-a2f0-57148bf907bc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-08-25 20:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-25 13:51:51",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.395987,
				"lng": -122.084876,
				"zipcode": "94040",
				"subPremise": "",
				"address": "1327 Villa St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1810000,
			"daysOnMovoto": 56,
			"id": "a3ab80d4-e5f0-4aa5-b98b-90f1fb585064",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859198_0_ieenqz_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Alex H. Wang",
			"listPrice": 1598000,
			"lotSize": 5737,
			"sqftTotal": 1600,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859198",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1953,
			"zipCode": "94040",
			"path": "mountain-view-ca/198-escuela-ave-mountain-view-ca-94040/pid_db5n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a86b921b-6d04-482e-922f-770ccdc716d1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-08-20 00:22:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": "http://www.198escuela.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.399475,
				"lng": -122.092771,
				"zipcode": "94040",
				"subPremise": "",
				"address": "198 Escuela Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.198escuela.com"
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 33,
			"id": "cf917e53-daaa-42e2-88cc-c21443583018",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861805_0_AFq7YU_p.jpeg",
			"listDate": "2021-09-08 20:59:00",
			"listingAgent": "Karen Kovacs",
			"listPrice": 1980000,
			"lotSize": 7466,
			"sqftTotal": 1604,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861805",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 22,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1951,
			"zipCode": "94041",
			"path": "mountain-view-ca/140-paul-ave-mountain-view-ca-94041/pid_nbcy2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "96d3f762-198d-4627-ab70-21e54de10bdc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-09 04:02:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 20:59:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.386144,
				"lng": -122.073674,
				"zipcode": "94041",
				"subPremise": "",
				"address": "140 Paul Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2270000,
			"daysOnMovoto": 11,
			"id": "fca1063c-e361-4b96-b6e8-769f02cd3a6d",
			"tnImgPath": "https://pi.movoto.com/p/110/421596333_0_YViQFJ_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Jeannie Gant",
			"listPrice": 2199000,
			"lotSize": 4310,
			"sqftTotal": 1723,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421596333",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Gant Properties",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2012,
			"zipCode": "94041",
			"path": "mountain-view-ca/720-pettis-ave-mountain-view-ca-94041-100_81204022/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4166c03-346a-4c40-9eeb-cbc6482edd93",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-09-21 16:41:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=rjv4d2CDHnK&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.390422,
				"lng": -122.090871,
				"zipcode": "94041",
				"subPremise": "",
				"address": "720 Pettis Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2270000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=rjv4d2CDHnK&brand=0"
		},
		{
			"closePrice": 350000,
			"daysOnMovoto": 51,
			"id": "86fbf06a-0ad1-41d0-a358-e57a5de56de9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857491_0_uqqmEe_p.jpeg",
			"listDate": "2021-08-09 10:26:41",
			"listingAgent": "Pam Sauceda",
			"listPrice": 350000,
			"lotSize": null,
			"sqftTotal": 1087,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857491",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pams Homes",
			"photoCount": 25,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2018,
			"zipCode": "94041",
			"path": "mountain-view-ca/501-moorpark-dr-mountain-view-ca-94041-100_ml81857491/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "686fb1d3-be12-4de4-979d-c560e5716dab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-08-09 17:32:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-09 10:26:41",
			"virtualTourLink": "http://www.edigitaltours.com/21/08/27606/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.382168,
				"lng": -122.065634,
				"zipcode": "94041",
				"subPremise": "",
				"address": "501 Moorpark Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.edigitaltours.com/21/08/27606/"
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 204,
			"id": "79a83c9b-ac05-439f-bcae-78da2c409738",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833033_0_faY6I3_p.jpeg",
			"listDate": "2021-03-08 00:00:00",
			"listingAgent": "Phil Billiet",
			"listPrice": 1600000,
			"lotSize": 12602,
			"sqftTotal": 1276,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833033",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes Realty",
			"photoCount": 11,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1946,
			"zipCode": "94043",
			"path": "mountain-view-ca/1925-colony-st-mountain-view-ca-94043/pid_w44n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -398000,
			"propertyId": "e963499f-96b0-492f-9f7c-4f0884a3b584",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-28 00:00:00",
			"createdAt": "2021-03-08 18:32:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.415514,
				"lng": -122.087761,
				"zipcode": "94043",
				"subPremise": "",
				"address": "1925 Colony St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1660000,
			"daysOnMovoto": 88,
			"id": "d2eeba95-98f9-4445-b37f-f1ace919c10d",
			"tnImgPath": "https://pi.movoto.com/p/12/40956476_0_7nJ7I2_p.jpeg",
			"listDate": "2021-06-28 00:00:00",
			"listingAgent": "Danny Chen",
			"listPrice": 1700000,
			"lotSize": 2399,
			"sqftTotal": 1748,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956476",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "HYC Properties",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2016,
			"zipCode": "94043",
			"path": "mountain-view-ca/1967-colony-st-mountain-view-ca-94043-12_40956476/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -60000,
			"propertyId": "dc66b084-797d-44c3-90d2-7fa6be7ffe50",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-06-28 22:16:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.415568,
				"lng": -122.088968,
				"zipcode": "94043",
				"subPremise": "",
				"address": "1967 Colony St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1660000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 47,
			"id": "69716d02-9a39-4cbc-a4e2-d166edc5e0a0",
			"tnImgPath": "https://pi.movoto.com/p/12/40958684_0_Y6IirM_p.jpeg",
			"listDate": "2021-07-29 00:00:00",
			"listingAgent": "Manel Sousou",
			"listPrice": 999900,
			"lotSize": 1880,
			"sqftTotal": 1248,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958684",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94040",
			"path": "mountain-view-ca/49-showers-dr-x256-mountain-view-ca-94040-100_80701443/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "724dc8d5-c36b-44c5-93de-411bcd2f7d98",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-14 00:00:00",
			"createdAt": "2021-07-29 17:46:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-29 00:00:00",
			"virtualTourLink": "http://listing.mercadovisualz.com/ut/49_Showers_Dr_Unit_X256.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.404465,
				"lng": -122.10374,
				"zipcode": "94040",
				"subPremise": "APT X256",
				"address": "49 Showers Dr #X256"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://listing.mercadovisualz.com/ut/49_Showers_Dr_Unit_X256.html"
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 81,
			"id": "74038bc5-f0fa-491b-8dcf-6dfde7f622d6",
			"tnImgPath": "https://pi.movoto.com/p/101/321056531_0_n6NQE7_p.jpeg",
			"listDate": "2021-06-24 00:00:00",
			"listingAgent": "Dan Shah",
			"listPrice": 1650000,
			"lotSize": 923,
			"sqftTotal": 1885,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321056531",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "ACRE-Alliance Capital Real Est",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2010,
			"zipCode": "94043",
			"path": "mountain-view-ca/1920-newbury-dr-mountain-view-ca-94043/pid_h64n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -45000,
			"propertyId": "fab4267e-17d6-4924-b0e1-411f66db360b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-07-06 02:18:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.416099,
				"lng": -122.088251,
				"zipcode": "94043",
				"subPremise": "",
				"address": "1920 Newbury Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1305000,
			"daysOnMovoto": 34,
			"id": "0d19c6b5-bb85-466a-aac8-d79bb0626cf0",
			"tnImgPath": "https://pi.movoto.com/p/110/421582996_0_f6mzna_p.jpeg",
			"listDate": "2021-08-07 00:00:00",
			"listingAgent": "Chani Blakely",
			"listPrice": 1195000,
			"lotSize": 4947,
			"sqftTotal": 1738,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421582996",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1980,
			"zipCode": "94043",
			"path": "mountain-view-ca/292-leslie-ct-a-mountain-view-ca-94043/pid_js8n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 1075500,
			"propertyId": "638677fa-b35f-467b-a718-02b8adc50b5e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-08-07 22:41:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.397761,
				"lng": -122.062915,
				"zipcode": "94043",
				"subPremise": "APT A",
				"address": "292 Leslie Ct #A"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1305000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 880000,
			"daysOnMovoto": 55,
			"id": "52ffb469-c097-41ce-8eb4-740232facd85",
			"tnImgPath": "https://pi.movoto.com/p/12/40959203_0_QBRJjm_p.jpeg",
			"listDate": "2021-07-16 00:00:00",
			"listingAgent": "Yuri Kitaigorodsky",
			"listPrice": 889000,
			"lotSize": 1332,
			"sqftTotal": 1140,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959203",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1969,
			"zipCode": "94040",
			"path": "mountain-view-ca/1033-crestview-dr-107-mountain-view-ca-94040-100_81303724/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "55cc59c5-c333-429e-8dfa-a392d52549df",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-07-16 22:51:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.372829,
				"lng": -122.059861,
				"zipcode": "94040",
				"subPremise": "APT 107",
				"address": "1033 Crestview Dr #107"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2195000,
			"daysOnMovoto": 48,
			"id": "075e6ae4-0329-49be-81f8-2da6def10e65",
			"tnImgPath": "https://pi.movoto.com/p/12/40959013_0_ZNmbyY_p.jpeg",
			"listDate": "2021-07-22 00:00:00",
			"listingAgent": "Nichole Clarkson",
			"listPrice": 2190000,
			"lotSize": 4876,
			"sqftTotal": 1004,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959013",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94040",
			"path": "mountain-view-ca/769-gantry-way-mountain-view-ca-94040/pid_o3oo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5cb6980e-5be5-4e5f-b2f1-34ee3f817a82",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-08 00:00:00",
			"createdAt": "2021-07-22 20:01:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-22 00:00:00",
			"virtualTourLink": "https://769GantryWay43908mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.371227,
				"lng": -122.085766,
				"zipcode": "94040",
				"subPremise": "",
				"address": "769 Gantry Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2195000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://769GantryWay43908mls.f8re.com/"
		},
		{
			"closePrice": 1180000,
			"daysOnMovoto": 77,
			"id": "05347ad8-a2be-4b64-a3ce-92e4059d2e32",
			"tnImgPath": "https://pi.movoto.com/p/12/40954895_0_VFm7EY_p.jpeg",
			"listDate": "2021-06-17 00:00:00",
			"listingAgent": "James Chen",
			"listPrice": 1180000,
			"lotSize": 0,
			"sqftTotal": 1232,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954895",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "JL Realty, Inc.",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94041",
			"path": "mountain-view-ca/159-centre-st-mountain-view-ca-94041-100_81465024/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4595cfc9-088c-44e1-b76d-9ed03a141533",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-02 00:00:00",
			"createdAt": "2021-06-18 02:41:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.382869,
				"lng": -122.075097,
				"zipcode": "94041",
				"subPremise": "",
				"address": "159 Centre St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 17,
			"id": "2e96e18c-041a-45f5-a4a8-e0769a26818f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858408_0_EE73e2_p.jpeg",
			"listDate": "2021-08-14 00:00:00",
			"listingAgent": "Anita Gat",
			"listPrice": 1698000,
			"lotSize": 7653,
			"sqftTotal": 1436,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858408",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Palo Alto",
			"photoCount": 19,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1952,
			"zipCode": "94041",
			"path": "mountain-view-ca/111-nancy-ct-mountain-view-ca-94041/pid_w0dy2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "01664da1-0772-4894-957e-d05aee5f1d45",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-31 00:00:00",
			"createdAt": "2021-08-14 16:12:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 00:00:00",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2904312",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.385122,
				"lng": -122.073057,
				"zipcode": "94041",
				"subPremise": "",
				"address": "111 Nancy Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2904312"
		},
		{
			"closePrice": 970000,
			"daysOnMovoto": 0,
			"id": "d67a809b-e438-4e1c-950e-50dcde3dcb4b",
			"tnImgPath": "https://pi.movoto.com/p/102/221057402_0_YfFBiv_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Aguilar-Castellanos",
			"listPrice": 998000,
			"lotSize": 0,
			"sqftTotal": 920,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221057402",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "D R Horton America's Builder",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94043",
			"path": "mountain-view-ca/533-walker-dr-mountain-view-ca-94043-102_221057402/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f2668273-35dc-43a4-a2d5-0ccc72f9b8f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-30 00:00:00",
			"createdAt": "2021-05-26 07:14:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-26 07:14:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.402472,
				"lng": -122.066874,
				"zipcode": "94043",
				"subPremise": "",
				"address": "533 Walker Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1749880,
			"daysOnMovoto": 72,
			"id": "16ebab53-5d3d-4ad5-b3b1-aeffb3c88c20",
			"tnImgPath": "https://pi.movoto.com/p/12/40954660_0_FzJ6Me_p.jpeg",
			"listDate": "2021-06-16 00:00:00",
			"listingAgent": "Bernadette Troncales Weir",
			"listPrice": 1799880,
			"lotSize": 1716,
			"sqftTotal": 1833,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954660",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Catalina Realty Investments",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94040",
			"path": "mountain-view-ca/2280-mora-pl-mountain-view-ca-94040-100_ml81784592/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "434a981d-9f31-47f1-b552-48f1c4879bc7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-27 00:00:00",
			"createdAt": "2021-06-17 03:56:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.403414,
				"lng": -122.101902,
				"zipcode": "94040",
				"subPremise": "",
				"address": "2280 Mora Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1749880,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 112,
			"id": "2dea4273-089c-4aaf-a81e-011164c776bd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81842796_0_ErFMAZ_p.jpeg",
			"listDate": "2021-05-07 00:00:00",
			"listingAgent": "Renee Ross",
			"listPrice": 1175000,
			"lotSize": 7462,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81842796",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 3,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94041",
			"path": "mountain-view-ca/527-alice-ave-mountain-view-ca-94041/pid_csao2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bb145ba3-bedb-489b-ac38-6eb848d66ac8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-27 00:00:00",
			"createdAt": "2021-05-07 20:57:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.384223,
				"lng": -122.066405,
				"zipcode": "94041",
				"subPremise": "",
				"address": "527 Alice Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1678880,
			"daysOnMovoto": 103,
			"id": "77127df1-3bc8-42e4-b52e-8125a5b0c23c",
			"tnImgPath": "https://pi.movoto.com/p/12/40949628_0_FAe3uY_p.jpeg",
			"listDate": "2021-05-12 00:00:00",
			"listingAgent": "Bernadette Troncales Weir",
			"listPrice": 1793880,
			"lotSize": 0,
			"sqftTotal": 1705,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40949628",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Catalina Realty Investments",
			"photoCount": 11,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "94040",
			"path": "mountain-view-ca/106-hacienda-ln-mountain-view-ca-94040-100_ml81760999/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 10000,
			"propertyId": "3785b735-5109-4d24-b653-d87c29c98efc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-23 00:00:00",
			"createdAt": "2021-05-13 03:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.402951,
				"lng": -122.101225,
				"zipcode": "94040",
				"subPremise": "",
				"address": "106 Hacienda Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1678880,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1530000,
			"daysOnMovoto": 35,
			"id": "917d1339-1b7a-4cdf-8b71-279f8fa8eb4d",
			"tnImgPath": "https://pi.movoto.com/p/12/40957609_0_VjeNun_p.jpeg",
			"listDate": "2021-07-07 00:00:00",
			"listingAgent": "Yvette Y.L. Teng",
			"listPrice": 1499000,
			"lotSize": 1573,
			"sqftTotal": 1674,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957609",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2019,
			"zipCode": "94043",
			"path": "mountain-view-ca/97-fairchild-dr-mountain-view-ca-94043-100_ml81708765/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c2ff4f2c-4dfc-492b-aa8e-3d9f755ee7e0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-11 00:00:00",
			"createdAt": "2021-07-07 22:11:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-07 00:00:00",
			"virtualTourLink": "https://97FairchildDrive.com/1864367?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.406266,
				"lng": -122.062288,
				"zipcode": "94043",
				"subPremise": "",
				"address": "97 Fairchild Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1530000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://97FairchildDrive.com/1864367?idx=1"
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 147,
			"id": "4575eb29-a651-40a5-9781-ddca3af51242",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832623_0_7nYiNu_p.jpeg",
			"listDate": "2021-03-04 22:56:30",
			"listingAgent": "Douglas Hong",
			"listPrice": 1925000,
			"lotSize": 5558,
			"sqftTotal": 1796,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832623",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1961,
			"zipCode": "94041",
			"path": "mountain-view-ca/74-76-centre-st-mountain-view-ca-94041-100_ml81832623/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -60000,
			"propertyId": "a4ab66dc-e268-4aec-9985-0150c985bdef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-30 00:00:00",
			"createdAt": "2021-03-05 07:02:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-04 22:56:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.382156,
				"lng": -122.072724,
				"zipcode": "94041",
				"subPremise": "",
				"address": "74-76 Centre St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2175000,
			"daysOnMovoto": 0,
			"id": "b6903698-8892-4528-9479-5acaefdd817c",
			"tnImgPath": "https://pi.movoto.com/p/102/221069186_0_q2u6R2_p.jpeg",
			"listDate": null,
			"listingAgent": "Emad Basma",
			"listPrice": 2100000,
			"lotSize": 3176,
			"sqftTotal": 1679,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221069186",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway HomeServices-Drysdale Properties",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1997,
			"zipCode": "94043",
			"path": "mountain-view-ca/105-beverly-st-mountain-view-ca-94043/pid_7i8n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e6f81b58-6d3d-41e0-b4ef-cbf5c57288bb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-21 00:00:00",
			"createdAt": "2021-06-17 22:04:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 22:04:28",
			"virtualTourLink": "Https://39pixelsphoto.com/3d-virtual-tour/105-beverly-street-mountain-view-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.389881,
				"lng": -122.061528,
				"zipcode": "94043",
				"subPremise": "",
				"address": "105 Beverly St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://39pixelsphoto.com/3d-virtual-tour/105-beverly-street-mountain-view-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 1702000,
			"daysOnMovoto": 0,
			"id": "99a10b5c-acfa-46ab-8aa9-7ebc7de525f9",
			"tnImgPath": "https://pi.movoto.com/p/102/221052939_0_eeJBVZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Jasmine Prasad Rahul",
			"listPrice": 1595000,
			"lotSize": 5952,
			"sqftTotal": 1692,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221052939",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pacific Star Real Estate & Loans",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1962,
			"zipCode": "94043",
			"path": "mountain-view-ca/882-san-ardo-way-mountain-view-ca-94043/pid_9h6n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "20918c97-d0ce-4d02-92fa-e22887b208c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-12 00:00:00",
			"createdAt": "2021-05-20 20:14:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-20 20:14:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.405916,
				"lng": -122.075111,
				"zipcode": "94043",
				"subPremise": "",
				"address": "882 San Ardo Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1702000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1681787,
			"daysOnMovoto": 49,
			"id": "b6fe262e-0145-4c29-a095-069b615c8b21",
			"tnImgPath": "https://pi.movoto.com/p/12/40949059_0_FzJ6Me_p.jpeg",
			"listDate": "2021-05-07 00:00:00",
			"listingAgent": "Bernadette Troncales Weir",
			"listPrice": 1781787,
			"lotSize": 1506,
			"sqftTotal": 1742,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40949059",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Catalina Realty Investments",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2019,
			"zipCode": "94040",
			"path": "mountain-view-ca/108-hacienda-ln-mountain-view-ca-94040-100_ml81764892/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4a316cd0-1a90-4ec7-ab82-3b7941893078",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-25 00:00:00",
			"createdAt": "2021-05-08 04:16:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.402951,
				"lng": -122.101225,
				"zipcode": "94040",
				"subPremise": "",
				"address": "108 Hacienda Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1681787,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 968000,
			"daysOnMovoto": 0,
			"id": "705538f1-31da-428f-9d29-db7516cae721",
			"tnImgPath": "https://pi.movoto.com/p/102/221046400_0_NzuUMN_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Aguilar-Castellanos",
			"listPrice": 975000,
			"lotSize": 0,
			"sqftTotal": 920,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221046400",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "D R Horton America's Builder",
			"photoCount": 12,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94043",
			"path": "mountain-view-ca/555-walker-dr-mountain-view-ca-94043-102_221046400/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "97392614-0862-4e31-a3cd-4abcc6182cd4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-24 00:00:00",
			"createdAt": "2021-05-05 20:34:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-05 20:34:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.402426,
				"lng": -122.067987,
				"zipcode": "94043",
				"subPremise": "",
				"address": "555 Walker Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 968000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1452000,
			"daysOnMovoto": 38,
			"id": "3bb17a0e-5bb8-4217-9397-32eb59600ccf",
			"tnImgPath": "https://pi.movoto.com/p/110/421557782_0_fMuayU_p.jpeg",
			"listDate": "2021-05-08 00:00:00",
			"listingAgent": "Steven Truong",
			"listPrice": 1429000,
			"lotSize": 1569,
			"sqftTotal": 1355,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421557782",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sequoia Real Estate",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94043",
			"path": "mountain-view-ca/3902-degree-ln-mountain-view-ca-94043-110_421557782/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a159bef9-33aa-47c5-b3fb-6f7f438242d2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2021-06-03 07:41:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.391523,
				"lng": -122.054385,
				"zipcode": "94043",
				"subPremise": "",
				"address": "3902 Degree Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1452000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2650000,
			"daysOnMovoto": 160,
			"id": "d26074b3-ebf7-47e1-b13f-f73617bbb1d0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824708_0_zFqRFu_p.jpeg",
			"listDate": "2021-01-05 21:16:47",
			"listingAgent": "Nicholas French",
			"listPrice": 2780000,
			"lotSize": 8307,
			"sqftTotal": 3554,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824708",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 44,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1939,
			"zipCode": "94041",
			"path": "mountain-view-ca/1555-mercy-st-mountain-view-ca-94041/pid_2w6n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -70000,
			"propertyId": "2bc4651a-5da7-47ae-a50f-bbfc3435e457",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2021-01-06 05:22:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-05 21:16:47",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2827151",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain View",
				"lat": 37.393078,
				"lng": -122.08983,
				"zipcode": "94041",
				"subPremise": "",
				"address": "1555 Mercy St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2827151"
		}
	]