exports.HAYWARD_LISTINGS = [
		{
			"closePrice": 260000,
			"daysOnMovoto": 77,
			"id": "5e664451-0719-45aa-85ff-1ae46644c690",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868229_0_7inevR_p.jpeg",
			"listDate": "2021-10-27 10:25:39",
			"listingAgent": "Alex Chow",
			"listPrice": 259000,
			"lotSize": null,
			"sqftTotal": 1254,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868229",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Manufactured Homes Inc",
			"photoCount": 3,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1998,
			"zipCode": "94544",
			"path": "hayward-ca/1527-macatera-st-hayward-ca-94544-100_ml81868229/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f4d31c96-3cfe-4596-ac40-7f2195cecce8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-10-27 17:32:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 10:25:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.622045,
				"lng": -122.07591,
				"zipcode": "94544",
				"subPremise": "",
				"address": "1527 Macatera St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 0,
			"id": "f5cc3fa8-de5d-4539-863f-6ff918c74719",
			"tnImgPath": "https://pi.movoto.com/p/102/221152503_0_qYzYaq_p.jpeg",
			"listDate": null,
			"listingAgent": "Brian Botiller",
			"listPrice": 949999,
			"lotSize": 5502,
			"sqftTotal": 1463,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221152503",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "HomeSmart PV & Associates",
			"photoCount": 83,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1950,
			"zipCode": "94544",
			"path": "hayward-ca/24482-groom-st-hayward-ca-94544-12_11138115/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a9f277ac-01ba-4c2c-8bef-1550160f8497",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-12 03:14:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-12 03:14:14",
			"virtualTourLink": "http://vtours.toddsfotos.com/24482groomstreet",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.662851,
				"lng": -122.078796,
				"zipcode": "94544",
				"subPremise": "",
				"address": "24482 Groom St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.toddsfotos.com/24482groomstreet"
		},
		{
			"closePrice": 72500,
			"daysOnMovoto": 812,
			"id": "bd30476b-3a9f-405d-ace7-d6b15e00e812",
			"tnImgPath": "https://pi.movoto.com/p/12/40886112_0_fU6bIa_p.jpeg",
			"listDate": "2019-10-16 00:00:00",
			"listingAgent": "Christopher Jacquez",
			"listPrice": 125000,
			"lotSize": 8327,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40886112",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 9,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "94544",
			"path": "hayward-ca/32153-bernice-way-hayward-ca-94544-12_40807121/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "201b4ee7-523d-47a1-9776-f512f5a24cbe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2019-10-17 00:06:58",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2019-10-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.615699,
				"lng": -122.026696,
				"zipcode": "94544",
				"subPremise": "",
				"address": "32153 Bernice Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 72500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 860000,
			"daysOnMovoto": 2,
			"id": "9fc6de75-cec1-4d68-b84a-1854e720903e",
			"tnImgPath": "https://pi.movoto.com/p/101/321114957_0_ABERIj_p.jpeg",
			"listDate": "2021-12-29 00:00:00",
			"listingAgent": "Summer Stubblefield",
			"listPrice": 800000,
			"lotSize": 5101,
			"sqftTotal": 1249,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321114957",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1939,
			"zipCode": "94541",
			"path": "hayward-ca/22585-5th-st-hayward-ca-94541-12_11068172/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7befcabd-92c6-4a4a-804f-67c2764a13d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-12-29 22:23:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.679184,
				"lng": -122.074224,
				"zipcode": "94541",
				"subPremise": "",
				"address": "22585 5th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 855000,
			"daysOnMovoto": 24,
			"id": "ccf4a4ca-46c6-445f-a66b-3f8fd81d9323",
			"tnImgPath": "https://pi.movoto.com/p/12/40975743_0_zjyaBv_p.jpeg",
			"listDate": "2021-12-06 00:00:00",
			"listingAgent": "Levy Arciaga",
			"listPrice": 768000,
			"lotSize": 4310,
			"sqftTotal": 1604,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975743",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Svcs",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1979,
			"zipCode": "94544",
			"path": "hayward-ca/270-stanislaus-way-hayward-ca-94544/pid_1lwdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "92073360-a9da-452a-b505-ea9d41ed8312",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-12-07 01:46:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.64414,
				"lng": -122.068142,
				"zipcode": "94544",
				"subPremise": "",
				"address": "270 Stanislaus Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 855000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 910000,
			"daysOnMovoto": 49,
			"id": "a9bb7141-098d-43f6-ab2c-d2e1ddd7b50a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973992_0_rufYAm_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Jessica Medina",
			"listPrice": 898000,
			"lotSize": 5445,
			"sqftTotal": 1310,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973992",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Village Properties & Assoc.",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94541",
			"path": "hayward-ca/23718-clayton-st-hayward-ca-94541-12_40072702/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f46ddcef-c055-46b4-ba74-e96be4a51c13",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-11 23:36:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.679514,
				"lng": -122.057355,
				"zipcode": "94541",
				"subPremise": "",
				"address": "23718 Clayton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 910000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 311,
			"id": "a2e2d3ae-09de-4fe5-91f6-eebea4bcbe4a",
			"tnImgPath": "https://pi.movoto.com/p/12/40938679_0_ARIZ7q_p.jpeg",
			"listDate": "2021-02-22 00:00:00",
			"listingAgent": "Farooq Khan",
			"listPrice": 650000,
			"lotSize": 7074,
			"sqftTotal": 576,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938679",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "5 Star Realty Partners",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1922,
			"zipCode": "94541",
			"path": "hayward-ca/539-meek-ave-hayward-ca-94541/pid_cfldvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 100100,
			"propertyId": "f230635e-c631-4953-bd78-797ce8d6d1e0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-02-22 20:36:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.664018,
				"lng": -122.086996,
				"zipcode": "94541",
				"subPremise": "",
				"address": "539 Meek Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 610000,
			"daysOnMovoto": 49,
			"id": "ed5764b2-5842-445c-b410-2807cf309b8f",
			"tnImgPath": "https://pi.movoto.com/p/12/40972878_0_vzjNbF_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Elisa Uribe",
			"listPrice": 577000,
			"lotSize": 0,
			"sqftTotal": 1050,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972878",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Golden Gate Sothebys International Realty",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1980,
			"zipCode": "94546",
			"path": "hayward-ca/21062-gary-dr-apt-118-hayward-ca-94546-12_40972878/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "85edb0e5-ca13-456d-81b9-c19883e3c074",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-15 19:16:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.689725,
				"lng": -122.089898,
				"zipcode": "94546",
				"subPremise": "APT 118",
				"address": "21062 Gary Dr #118"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 230000,
			"daysOnMovoto": 75,
			"id": "0fc7c9d9-def5-41e7-b7d3-0ba0d573df6b",
			"tnImgPath": "https://pi.movoto.com/p/12/40971230_0_NfIu23_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Lisa Benavides",
			"listPrice": 208800,
			"lotSize": 1780,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971230",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 28,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94544",
			"path": "hayward-ca/29412-middleborough-way-hayward-ca-94544-12_40971230/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "47671c00-0a93-4b28-ad33-d5e65221842b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-10-16 01:21:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.620466,
				"lng": -122.062238,
				"zipcode": "94544",
				"subPremise": "",
				"address": "29412 Middleborough Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 230000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1040000,
			"daysOnMovoto": 0,
			"id": "195696b6-82fa-406c-b57c-163d640911bc",
			"tnImgPath": "https://pi.movoto.com/p/102/221133213_0_zUrIfR_p.jpeg",
			"listDate": null,
			"listingAgent": "Kristopher Hansen",
			"listPrice": 1094990,
			"lotSize": 1464,
			"sqftTotal": 1885,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221133213",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "D R Horton America's Builder",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94544",
			"path": "hayward-ca/28903-hideaway-st-hayward-ca-94544-102_221133213/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 26990,
			"propertyId": "fe3c63fd-ac10-4fc8-b892-6d2a00e1554b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-10-15 23:29:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 23:29:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.635795,
				"lng": -122.050248,
				"zipcode": "94544",
				"subPremise": "",
				"address": "28903 Hideaway St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1040000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 53,
			"id": "33da70ab-0f46-4084-8400-dcad8f0c55d8",
			"tnImgPath": "https://pi.movoto.com/p/12/40973400_0_6QuJyB_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Lily Do",
			"listPrice": 1495000,
			"lotSize": 9068,
			"sqftTotal": 3151,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973400",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1997,
			"zipCode": "94542",
			"path": "hayward-ca/4025-woodstock-rd-hayward-ca-94542-12_40571053/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3fc3c31f-cf09-499a-9cc4-65ce559a063a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-05 16:06:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/642661243",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.660687,
				"lng": -122.024231,
				"zipcode": "94542",
				"subPremise": "",
				"address": "4025 Woodstock Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/642661243"
		},
		{
			"closePrice": 550000,
			"daysOnMovoto": 74,
			"id": "0ccdc638-4b04-4f0d-bce1-a804f02b8e45",
			"tnImgPath": "https://pi.movoto.com/p/12/40971026_0_nNemVV_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Ines Panic",
			"listPrice": 499999,
			"lotSize": 124670,
			"sqftTotal": 1025,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971026",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Luxe Realty Group",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1992,
			"zipCode": "94546",
			"path": "hayward-ca/21239-gary-dr-apt-112d-hayward-ca-94546-12_40761812/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1637639-0fd4-499f-b2e8-e8a28a78da39",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-15 16:11:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.686638,
				"lng": -122.09093,
				"zipcode": "94546",
				"subPremise": "APT 112D",
				"address": "21239 Gary Dr #112D"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1092000,
			"daysOnMovoto": 55,
			"id": "5ea32087-376b-4c8f-b139-e09ef4525f9b",
			"tnImgPath": "https://pi.movoto.com/p/12/40972374_0_F6n3Fe_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Michael Swift",
			"listPrice": 899888,
			"lotSize": 5460,
			"sqftTotal": 1599,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972374",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94544",
			"path": "hayward-ca/30467-prestwick-ave-hayward-ca-94544-12_27598682/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e8fc7a34-f833-4358-8224-4e05fec59d2b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-02 17:46:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=R87KJ6n42Dv",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.623239,
				"lng": -122.041527,
				"zipcode": "94544",
				"subPremise": "",
				"address": "30467 Prestwick Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1092000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=R87KJ6n42Dv"
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 66,
			"id": "c40b3fea-314f-49da-8b25-be5627569e3b",
			"tnImgPath": "https://pi.movoto.com/p/12/40971702_0_fryIQ7_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Ana Vasquez",
			"listPrice": 950000,
			"lotSize": 5310,
			"sqftTotal": 1941,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971702",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94544",
			"path": "hayward-ca/26375-eldridge-ave-hayward-ca-94544-12_40691986/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 51112,
			"propertyId": "45e99aa3-0208-4c83-8ec3-b0f0f0a5f220",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-22 21:41:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/637759637",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.640744,
				"lng": -122.09034,
				"zipcode": "94544",
				"subPremise": "",
				"address": "26375 Eldridge Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/637759637"
		},
		{
			"closePrice": 299000,
			"daysOnMovoto": 35,
			"id": "b2a70fda-947b-4745-832a-dc6d8c660895",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870505_0_627MQU_p.jpeg",
			"listDate": "2021-11-17 18:52:06",
			"listingAgent": "Ben Beveridge",
			"listPrice": 299000,
			"lotSize": null,
			"sqftTotal": 1190,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870505",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Benjamin Alan Properties",
			"photoCount": 27,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2005,
			"zipCode": "94544",
			"path": "hayward-ca/140-saturn-ave-hayward-ca-94544-100_ml81870505/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bed35221-84d4-41ad-bd4b-e6857d1a5603",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-18 02:57:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 18:52:06",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.630901,
				"lng": -122.061029,
				"zipcode": "94544",
				"subPremise": "",
				"address": "140 Saturn Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 299000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 41,
			"id": "44dc19bd-177b-42a9-b078-21580ebc3c8a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973770_0_fQYIzu_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Andrew Elkin",
			"listPrice": 779888,
			"lotSize": 5400,
			"sqftTotal": 1479,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973770",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Oak Hill Properties",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94545",
			"path": "hayward-ca/27702-orlando-ave-hayward-ca-94545/pid_7x0dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f5feb2a1-a08d-4674-af50-dd9963de2d55",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-12 20:01:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.628315,
				"lng": -122.082613,
				"zipcode": "94545",
				"subPremise": "",
				"address": "27702 Orlando Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 710000,
			"daysOnMovoto": 44,
			"id": "4bc86bce-bf12-475b-bfdf-4c7513d6bf17",
			"tnImgPath": "https://pi.movoto.com/p/12/40973786_0_6NqQzf_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Cristina Bales",
			"listPrice": 649950,
			"lotSize": 5000,
			"sqftTotal": 1040,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973786",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Fohl Realty & Investments",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94541",
			"path": "hayward-ca/920-lester-ave-hayward-ca-94541/pid_l5pdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f149df92-cde7-41ef-aadb-3bb98128ddca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-10 04:21:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/643704214",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.660576,
				"lng": -122.1137,
				"zipcode": "94541",
				"subPremise": "",
				"address": "920 Lester Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/643704214"
		},
		{
			"closePrice": 970000,
			"daysOnMovoto": 48,
			"id": "a80d808b-c200-4497-93d3-c2c6aaba302a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973441_0_e7UvQf_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Alicia DelRio-Mendez",
			"listPrice": 950000,
			"lotSize": 5008,
			"sqftTotal": 1440,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973441",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1995,
			"zipCode": "94544",
			"path": "hayward-ca/25042-plum-tree-st-hayward-ca-94544/pid_lzsdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "db90ba95-e5d0-4bd5-83a3-1e90b622f8ba",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-05 18:53:04",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.654308,
				"lng": -122.081459,
				"zipcode": "94544",
				"subPremise": "",
				"address": "25042 Plum Tree St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 949000,
			"daysOnMovoto": 0,
			"id": "bdb4b4a0-3a12-4f05-9afc-f841bb28cf53",
			"tnImgPath": "https://pi.movoto.com/p/102/221133185_0_zUrIfR_p.jpeg",
			"listDate": null,
			"listingAgent": "Kristopher Hansen",
			"listPrice": 949000,
			"lotSize": 1490,
			"sqftTotal": 1969,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221133185",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "D R Horton America's Builder",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94544",
			"path": "hayward-ca/831-oasis-ct-hayward-ca-94544-102_221133185/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -35990,
			"propertyId": "939ba4f1-ad08-44ee-acea-e78754f41806",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-10-15 23:04:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 23:04:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"zipcode": "94544",
				"subPremise": "",
				"address": "831 Oasis Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 949000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 21,
			"id": "02ee9e5d-6df6-4642-9adf-8dbe8f55ea54",
			"tnImgPath": "https://pi.movoto.com/p/12/40975331_0_zYBFbr_p.jpeg",
			"listDate": "2021-12-01 00:00:00",
			"listingAgent": "Michelle Junsay",
			"listPrice": 850000,
			"lotSize": 8008,
			"sqftTotal": 1238,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975331",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty ONE Group Future",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "94541",
			"path": "hayward-ca/3334-costa-dr-hayward-ca-94541/pid_c4edvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2be36a3d-c012-46d4-adc3-01bce95f8e7c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-12-02 07:21:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 00:00:00",
			"virtualTourLink": "https://3334CostaDr639080mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.685369,
				"lng": -122.046167,
				"zipcode": "94541",
				"subPremise": "",
				"address": "3334 Costa Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://3334CostaDr639080mls.f8re.com/"
		},
		{
			"closePrice": 720000,
			"daysOnMovoto": 36,
			"id": "bf63cc35-dbb1-4491-b4b3-76e6a5aba608",
			"tnImgPath": "https://pi.movoto.com/p/12/40974428_0_fmEaq3_p.jpeg",
			"listDate": "2021-11-16 00:00:00",
			"listingAgent": "Sam Chiu",
			"listPrice": 680000,
			"lotSize": 0,
			"sqftTotal": 1274,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974428",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Home Services",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2002,
			"zipCode": "94544",
			"path": "hayward-ca/25115-angelina-ln-hayward-ca-94542-12_40974428/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81b20142-9ab4-4567-b3c0-83a3a00890b5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-17 05:31:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.656659,
				"lng": -122.074323,
				"zipcode": "94544",
				"subPremise": "",
				"address": "25115 Angelina Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 140000,
			"daysOnMovoto": 83,
			"id": "41676fd3-864d-4be4-bb2c-f3c49dd8a9b5",
			"tnImgPath": "https://pi.movoto.com/p/12/40969208_0_eeQBIR_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Adrian Yip",
			"listPrice": 260000,
			"lotSize": 1536,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969208",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 15,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94545",
			"path": "hayward-ca/1150-w-winton-ave-apt-114-hayward-ca-94545-12_40969208/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -19950,
			"propertyId": "dca869c2-d673-4f86-80ce-150fa7629fea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-09-30 23:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.650127,
				"lng": -122.112295,
				"zipcode": "94545",
				"subPremise": "APT 114",
				"address": "1150 W Winton Ave #114"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 140000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 740000,
			"daysOnMovoto": 40,
			"id": "dedd2c5e-5b80-4681-b772-d9948dc6007b",
			"tnImgPath": "https://pi.movoto.com/p/12/40973917_0_YQRQi6_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Jeff Fajardo",
			"listPrice": 624950,
			"lotSize": 110184,
			"sqftTotal": 1488,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973917",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Fohl Realty & Investments",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1983,
			"zipCode": "94542",
			"path": "hayward-ca/3247-guillermo-pl-hayward-ca-94542-12_10594938/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0fcab6f6-5a54-4592-b832-75b7dd5196c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-11 10:26:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/644911150",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.655438,
				"lng": -122.038891,
				"zipcode": "94542",
				"subPremise": "",
				"address": "3247 Guillermo Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 740000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/644911150"
		},
		{
			"closePrice": 880000,
			"daysOnMovoto": 41,
			"id": "c8885c88-e159-43e1-8454-08d8fbac246d",
			"tnImgPath": "https://pi.movoto.com/p/12/40973893_0_QmVeYQ_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Wenhua Chen",
			"listPrice": 899000,
			"lotSize": 3890,
			"sqftTotal": 1386,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973893",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "MAXREAL",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "94544",
			"path": "hayward-ca/29148-lone-tree-pl-hayward-ca-94544/pid_qn4dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e76d3262-c416-4996-832f-3e02c3c01b1f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-11 02:21:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.625915,
				"lng": -122.062137,
				"zipcode": "94544",
				"subPremise": "",
				"address": "29148 Lone Tree Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 780000,
			"daysOnMovoto": 46,
			"id": "9715879c-e1e5-47cc-bd83-3e6f33f378b2",
			"tnImgPath": "https://pi.movoto.com/p/12/40973464_0_JNQQnR_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Cristina Bales",
			"listPrice": 699999,
			"lotSize": 6550,
			"sqftTotal": 1744,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973464",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Fohl Realty & Investments",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "94541",
			"path": "hayward-ca/23055-eddy-st-hayward-ca-94541/pid_rsedvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c7272728-ce27-4e76-aef2-8c6a32956f23",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-05 20:31:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/642571424",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.685323,
				"lng": -122.047676,
				"zipcode": "94541",
				"subPremise": "",
				"address": "23055 Eddy St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/642571424"
		},
		{
			"closePrice": 1925000,
			"daysOnMovoto": 35,
			"id": "bd1a4a4c-6177-4e1b-8b8b-10f70edd7341",
			"tnImgPath": "https://pi.movoto.com/p/12/40974053_0_ieNiim_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Lily Do",
			"listPrice": 1795000,
			"lotSize": 9516,
			"sqftTotal": 3705,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974053",
			"bath": 4,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2001,
			"zipCode": "94542",
			"path": "hayward-ca/28870-bailey-ranch-rd-hayward-ca-94542/pid_px4bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "696cb8f6-372e-4734-88fe-5585558d563f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-12 17:21:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/645141922",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.648159,
				"lng": -122.018058,
				"zipcode": "94542",
				"subPremise": "",
				"address": "28870 Bailey Ranch Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/645141922"
		},
		{
			"closePrice": 890000,
			"daysOnMovoto": 43,
			"id": "065f2c68-48c3-4836-bd24-7114b1e2d587",
			"tnImgPath": "https://pi.movoto.com/p/12/40973275_0_6BIbEm_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Cristina Bales",
			"listPrice": 699950,
			"lotSize": 5300,
			"sqftTotal": 1119,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973275",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Fohl Realty & Investments",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94545",
			"path": "hayward-ca/27672-melbourne-ave-hayward-ca-94545/pid_r5zdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1ee22cf2-4497-49ce-b34a-0ef6f10b7778",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-04 20:21:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/642358502",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.627388,
				"lng": -122.085054,
				"zipcode": "94545",
				"subPremise": "",
				"address": "27672 Melbourne Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 890000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/642358502"
		},
		{
			"closePrice": 890000,
			"daysOnMovoto": 63,
			"id": "837bd9c1-6c69-4a2b-8e55-2e61ea5fb102",
			"tnImgPath": "https://pi.movoto.com/p/12/40971087_0_ayEBEJ_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Yen Lu",
			"listPrice": 739000,
			"lotSize": 5400,
			"sqftTotal": 1308,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971087",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Twin Oaks Real Estate Inc.",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94541",
			"path": "hayward-ca/22620-zaballos-ct-hayward-ca-94541-12_40032514/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "183495bc-e329-4eca-8b3d-75465ec9dbca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-10-15 16:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.680612,
				"lng": -122.067985,
				"zipcode": "94541",
				"subPremise": "",
				"address": "22620 Zaballos Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 890000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 760000,
			"daysOnMovoto": 0,
			"id": "517ae998-4056-494e-8c28-3cb39f4d2518",
			"tnImgPath": "https://pi.movoto.com/p/102/221129576_0_UYFbVE_p.jpeg",
			"listDate": null,
			"listingAgent": "Oscar Uribe",
			"listPrice": 758888,
			"lotSize": 5460,
			"sqftTotal": 1164,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221129576",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94541",
			"path": "hayward-ca/20634-garden-ave-hayward-ca-94541-100_81017102/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8c7d46e2-f53e-4ffb-888f-ab7f364d31eb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-10-10 21:09:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-10 21:09:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.670724,
				"lng": -122.112534,
				"zipcode": "94541",
				"subPremise": "",
				"address": "20634 Garden Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 760000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 970000,
			"daysOnMovoto": 13,
			"id": "a52ba8ae-23d4-4e29-8cfc-42561d0a9928",
			"tnImgPath": "https://pi.movoto.com/p/110/421615174_0_R277ie_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Andrew de Vries",
			"listPrice": 899000,
			"lotSize": 6500,
			"sqftTotal": 1687,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615174",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway-Franciscan",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "94545",
			"path": "hayward-ca/28174-calaroga-ave-hayward-ca-94545-12_27504049/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3d996713-d41c-48cb-9467-9a760526dddf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-12-03 23:26:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": "https://www.28174calaroga.com/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.622272,
				"lng": -122.086452,
				"zipcode": "94545",
				"subPremise": "",
				"address": "28174 Calaroga Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.28174calaroga.com/unbranded"
		},
		{
			"closePrice": 945000,
			"daysOnMovoto": 35,
			"id": "73fa9dd2-700a-47d9-9664-09cab35cac25",
			"tnImgPath": "https://pi.movoto.com/p/12/40973963_0_YeJ6QQ_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Gaylen Roberts",
			"listPrice": 199000,
			"lotSize": 11895,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973963",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 10,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "94541",
			"path": "hayward-ca/3370-saddle-dr-hayward-ca-94541-12_40973963/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "07fde0d0-692d-4e9b-b562-37c0a0e2ea97",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-11 20:36:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.671361,
				"lng": -122.044063,
				"zipcode": "94541",
				"subPremise": "",
				"address": "3370 Saddle Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 945000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 945000,
			"daysOnMovoto": 35,
			"id": "4add48c6-260d-41dc-b1f5-902884585b8e",
			"tnImgPath": "https://pi.movoto.com/p/12/40973959_0_yBVjrq_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Gaylen Roberts",
			"listPrice": 899000,
			"lotSize": 10324,
			"sqftTotal": 1657,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973959",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94541",
			"path": "hayward-ca/3382-saddle-dr-hayward-ca-94541/pid_apfdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0aa2f68e-2a17-494b-a90c-19d083803c76",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-11 20:21:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.671258,
				"lng": -122.043769,
				"zipcode": "94541",
				"subPremise": "",
				"address": "3382 Saddle Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 945000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 960000,
			"daysOnMovoto": 42,
			"id": "4bffee09-b4ae-4934-9251-d160f2b8b1f8",
			"tnImgPath": "https://pi.movoto.com/p/12/40972099_0_7fuU2q_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Patricia De Castro",
			"listPrice": 899000,
			"lotSize": 5100,
			"sqftTotal": 1404,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972099",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1978,
			"zipCode": "94545",
			"path": "hayward-ca/2499-constellation-dr-hayward-ca-94545-12_40150734/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f3232226-824e-4d24-859f-5b17edd78b7b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-04 23:56:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.621806,
				"lng": -122.095572,
				"zipcode": "94545",
				"subPremise": "",
				"address": "2499 Constellation Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 960000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 81,
			"id": "fa18916b-3b86-4a87-8f83-da68f02638d3",
			"tnImgPath": "https://pi.movoto.com/p/12/40968657_0_yZMNiI_p.jpeg",
			"listDate": "2021-09-25 00:00:00",
			"listingAgent": "Lucia Blanco",
			"listPrice": 679000,
			"lotSize": 9000,
			"sqftTotal": 1258,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968657",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1920,
			"zipCode": "94541",
			"path": "hayward-ca/22851-2nd-st-hayward-ca-94541/pid_l9gdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9e79eea8-d75e-45cd-afa2-01df09fafa8a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-09-25 18:16:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.672212,
				"lng": -122.076887,
				"zipcode": "94541",
				"subPremise": "",
				"address": "22851 2nd St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 155,
			"id": "0600e927-32da-478f-80c5-b6ec521f0e4a",
			"tnImgPath": "https://pi.movoto.com/p/12/40958496_0_6QbemN_p.jpeg",
			"listDate": "2021-07-13 00:00:00",
			"listingAgent": "Dustin Roberts",
			"listPrice": 1095000,
			"lotSize": 20218,
			"sqftTotal": 2350,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958496",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "BSR Realty Inc",
			"photoCount": 5,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1937,
			"zipCode": "94541",
			"path": "hayward-ca/542-smalley-ave-hayward-ca-94541/pid_q7mdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "11476c56-9089-4e83-89d0-aaffca1fee6c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-07-13 19:56:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.671475,
				"lng": -122.092377,
				"zipcode": "94541",
				"subPremise": "",
				"address": "542 Smalley Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 781305,
			"daysOnMovoto": 41,
			"id": "a0aa7a0f-8559-49a0-bcb1-7cc9c1ddee78",
			"tnImgPath": "https://pi.movoto.com/p/12/40973152_0_6uzJQM_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Donald Huey",
			"listPrice": 699000,
			"lotSize": 5300,
			"sqftTotal": 1119,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973152",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94545",
			"path": "hayward-ca/27501-la-porte-ave-hayward-ca-94545/pid_psydvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f94e642b-423f-4096-ad1d-740c5c69d957",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-03 23:06:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.630132,
				"lng": -122.087717,
				"zipcode": "94545",
				"subPremise": "",
				"address": "27501 La Porte Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 781305,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 58,
			"id": "2eac095c-6993-48de-87f0-6467fcf53c0b",
			"tnImgPath": "https://pi.movoto.com/p/12/40971335_0_nmJeRQ_p.jpeg",
			"listDate": "2021-10-17 00:00:00",
			"listingAgent": "Carlos Montano",
			"listPrice": 850000,
			"lotSize": 5500,
			"sqftTotal": 1238,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971335",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pacific East Bay Real Estate",
			"photoCount": 10,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "94545",
			"path": "hayward-ca/26790-peterman-ave-hayward-ca-94545-12_40227866/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "36679ac0-38fe-4c4f-83a2-26cc669c5da3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-10-18 01:01:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.636715,
				"lng": -122.088693,
				"zipcode": "94545",
				"subPremise": "",
				"address": "26790 Peterman Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 0,
			"id": "739d7613-03de-4667-ae37-8ad008711be7",
			"tnImgPath": "https://pi.movoto.com/p/102/221144383_0_BUMyFm_p.jpeg",
			"listDate": null,
			"listingAgent": "Ruth Carter",
			"listPrice": 899888,
			"lotSize": 5005,
			"sqftTotal": 1407,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221144383",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "1st Choice Realty & Associates",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "94544",
			"path": "hayward-ca/31457-medinah-st-hayward-ca-94544-12_40713507/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6632a8c-ee10-4558-905b-485885962af8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-12 23:39:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 23:39:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.617392,
				"lng": -122.034089,
				"zipcode": "94544",
				"subPremise": "",
				"address": "31457 Medinah St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 970000,
			"daysOnMovoto": 33,
			"id": "dfba8200-b3a9-4daf-9bed-5de0cd8953eb",
			"tnImgPath": "https://pi.movoto.com/p/12/40973270_0_m3jMj6_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "David Wilhite",
			"listPrice": 849800,
			"lotSize": 6552,
			"sqftTotal": 1420,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973270",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "94541",
			"path": "hayward-ca/3246-hackamore-dr-hayward-ca-94541/pid_c8fdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b0b7f90e-566e-4df1-ba9d-781bcb2d29ee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-10 19:46:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": "https://3246HackamoreDrive456439mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.669722,
				"lng": -122.045634,
				"zipcode": "94541",
				"subPremise": "",
				"address": "3246 Hackamore Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://3246HackamoreDrive456439mls.f8re.com/"
		},
		{
			"closePrice": 250000,
			"daysOnMovoto": 474,
			"id": "592dc059-b171-44e4-9d45-122326eb836f",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2020-08-26 00:00:00",
			"listingAgent": "Ruben Martinez",
			"listPrice": 250000,
			"lotSize": 50530,
			"sqftTotal": null,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "OC20178235",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Fiduciary Real Estate Services",
			"photoCount": 0,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94542",
			"path": "hayward-ca/0-clover-rd-hayward-ca-94542-100_ml81639644/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -335000,
			"propertyId": "1f0f4cb1-6dbc-43dd-be3a-d0fa7fe86bcd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2020-08-29 04:47:40",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2020-08-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.671433,
				"lng": -122.040194,
				"zipcode": "94542",
				"subPremise": "",
				"address": "0 Clover Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1108888,
			"daysOnMovoto": 0,
			"id": "262d96cf-2d8a-4e91-a927-d49fc15b4d46",
			"tnImgPath": "https://pi.movoto.com/p/102/221136939_0_U3amb3_p.jpeg",
			"listDate": null,
			"listingAgent": "Rosalee Aguilar",
			"listPrice": 999888,
			"lotSize": 8220,
			"sqftTotal": 1742,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136939",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "HomeSmart PV and Associates",
			"photoCount": 65,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1959,
			"zipCode": "94545",
			"path": "hayward-ca/24830-calaroga-ave-hayward-ca-94545/pid_o4udvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "98fcde7b-7b03-48f3-97db-9914aede5d56",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-29 17:49:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 17:49:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.647353,
				"lng": -122.099833,
				"zipcode": "94545",
				"subPremise": "",
				"address": "24830 Calaroga Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1108888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1099000,
			"daysOnMovoto": 0,
			"id": "4e560169-5697-4d26-b763-7e148cc4300a",
			"tnImgPath": "https://pi.movoto.com/p/102/221133150_0_zUrIfR_p.jpeg",
			"listDate": null,
			"listingAgent": "Kristopher Hansen",
			"listPrice": 1099000,
			"lotSize": 1619,
			"sqftTotal": 2022,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221133150",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "D R Horton America's Builder",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94544",
			"path": "hayward-ca/28897-hideaway-st-hayward-ca-94544-102_221133150/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -28990,
			"propertyId": "931706cd-1dc2-4cb9-ba92-032410338781",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-15 22:19:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 22:19:28",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.635795,
				"lng": -122.050248,
				"zipcode": "94544",
				"subPremise": "",
				"address": "28897 Hideaway St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1099000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 486000,
			"daysOnMovoto": 21,
			"id": "5a64a6e0-9f64-4576-9e4e-2a179809d893",
			"tnImgPath": "https://pi.movoto.com/p/12/40974669_0_QzIqrj_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Sylvia So-Yi Wong",
			"listPrice": 486000,
			"lotSize": 256074,
			"sqftTotal": 865,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974669",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1991,
			"zipCode": "94545",
			"path": "hayward-ca/25210-copa-del-oro-dr-102-hayward-ca-94545-12_40528406/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -13500,
			"propertyId": "e74a834e-95e9-46b8-b906-35e30e2af10d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-19 21:01:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/7811396",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.645181,
				"lng": -122.101845,
				"zipcode": "94545",
				"subPremise": "APT 102",
				"address": "25210 Copa Del Oro Dr #102"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 486000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/7811396"
		},
		{
			"closePrice": 739000,
			"daysOnMovoto": 35,
			"id": "44c6e7e0-7d18-4617-bdb2-cddaa74b75c5",
			"tnImgPath": "https://pi.movoto.com/p/12/40973534_0_mqaURb_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Heidi Belle",
			"listPrice": 749000,
			"lotSize": 5656,
			"sqftTotal": 820,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973534",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1951,
			"zipCode": "94544",
			"path": "hayward-ca/32008-carroll-ave-hayward-ca-94544/pid_13fbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6eb6ec0d-0e7f-4f4a-9155-c03f1f1ac973",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-06 01:56:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": "https://32008CarrollAvenue466276mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.610734,
				"lng": -122.0352,
				"zipcode": "94544",
				"subPremise": "",
				"address": "32008 Carroll Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 739000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://32008CarrollAvenue466276mls.f8re.com/"
		},
		{
			"closePrice": 725000,
			"daysOnMovoto": 39,
			"id": "eb61a6e4-4299-44aa-840d-c746a1b20f9b",
			"tnImgPath": "https://pi.movoto.com/p/12/40972945_0_bA2nF6_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Gagan Singh",
			"listPrice": 699000,
			"lotSize": 4040,
			"sqftTotal": 1063,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972945",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94541",
			"path": "hayward-ca/653-morva-ct-hayward-ca-94541-12_40206395/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b6669b4c-83d1-49f1-bb80-a3cf88dd9604",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-02 05:56:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/BDnht9Lwzzg",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.68199,
				"lng": -122.10254,
				"zipcode": "94541",
				"subPremise": "",
				"address": "653 Morva Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/BDnht9Lwzzg"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 42,
			"id": "4ee9666a-10ca-4831-9823-d406c3fed856",
			"tnImgPath": "https://pi.movoto.com/p/12/40972734_0_fbFaQY_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Gurveer Bains",
			"listPrice": 1548777,
			"lotSize": 6300,
			"sqftTotal": 2402,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972734",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Prime Properties of Calif",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2004,
			"zipCode": "94545",
			"path": "hayward-ca/2623-spindrift-cir-hayward-ca-94545-100_81327331/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f8d240f-ba83-4e2e-b70f-19a3b6687f06",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-30 00:16:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.609496,
				"lng": -122.089803,
				"zipcode": "94545",
				"subPremise": "",
				"address": "2623 Spindrift Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1950000,
			"daysOnMovoto": 42,
			"id": "42f00b9b-cb50-4878-8317-2a994dfe5385",
			"tnImgPath": "https://pi.movoto.com/p/12/40972626_0_2mAQ6V_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Lily Do",
			"listPrice": 1699000,
			"lotSize": 9029,
			"sqftTotal": 2670,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972626",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2003,
			"zipCode": "94542",
			"path": "hayward-ca/28875-bailey-ranch-rd-hayward-ca-94542-12_40081403/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6cd361cd-9052-4f15-9ba9-26cb22d78aad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-29 16:17:07",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Z9jE1EQXzsq&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.64761,
				"lng": -122.01808,
				"zipcode": "94542",
				"subPremise": "",
				"address": "28875 Bailey Ranch Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Z9jE1EQXzsq&mls=1"
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 43,
			"id": "2142dfeb-7cf5-457c-89fb-af05ad52ed30",
			"tnImgPath": "https://pi.movoto.com/p/12/40972554_0_r6fbVV_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Cristina Bales",
			"listPrice": 899950,
			"lotSize": 3745,
			"sqftTotal": 1551,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972554",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Fohl Realty & Investments",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1991,
			"zipCode": "94545",
			"path": "hayward-ca/24842-sinclair-st-hayward-ca-94545/pid_1eodvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c64b2173-5818-4a5a-8813-3002d3e26f2d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-28 22:56:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/639833392",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.642576,
				"lng": -122.114343,
				"zipcode": "94545",
				"subPremise": "",
				"address": "24842 Sinclair St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/639833392"
		},
		{
			"closePrice": 517000,
			"daysOnMovoto": 88,
			"id": "220eec66-dee8-4fe0-8646-64242f28e3be",
			"tnImgPath": "https://pi.movoto.com/p/202/SW21203255_0_2R3YFU_p.jpeg",
			"listDate": "2021-09-13 00:00:00",
			"listingAgent": "Sinan Zakaria",
			"listPrice": 520000,
			"lotSize": 143139,
			"sqftTotal": 1007,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "SW21203255",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Listed Simply",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1989,
			"zipCode": "94545",
			"path": "hayward-ca/25850-kay-ave-327-hayward-ca-94545-12_40207579/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0fd95140-4d01-448e-aca6-a730148bd0a6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-09-21 22:47:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.641148,
				"lng": -122.097397,
				"zipcode": "94545",
				"subPremise": "APT 327",
				"address": "25850 Kay Ave #327"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 517000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 22,
			"id": "b3131b12-cfcf-44cb-8045-11b0b88229f7",
			"tnImgPath": "https://pi.movoto.com/p/12/40974469_0_7YrEvN_p.jpeg",
			"listDate": "2021-11-17 00:00:00",
			"listingAgent": "Jack Jweinat",
			"listPrice": 888888,
			"lotSize": 5000,
			"sqftTotal": 1938,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974469",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94541",
			"path": "hayward-ca/856-lester-ave-hayward-ca-94541/pid_77pdvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b506d394-2796-4b92-bb50-4618aeebf3b5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-17 21:11:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/646943609",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Hayward",
				"lat": 37.660824,
				"lng": -122.112495,
				"zipcode": "94541",
				"subPremise": "",
				"address": "856 Lester Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/646943609"
		}
	]