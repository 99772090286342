exports.SAN_JOSE_LISTINGS = [
		{
			"closePrice": 1681888,
			"daysOnMovoto": 31,
			"id": "283e4ce8-e266-447c-9134-4b9d8db9c778",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872864_0_Bm7BIf_p.jpeg",
			"listDate": "2021-12-20 13:45:40",
			"listingAgent": "Michael Than",
			"listPrice": 1499000,
			"lotSize": 6525,
			"sqftTotal": 1867,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872864",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Alpha Lux, Inc.",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1971,
			"zipCode": "95123",
			"path": "san-jose-ca/5878-treetop-ct-san-jose-ca-95123-100_81050346/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "53207d4a-9870-48d4-b475-40fa8c9db105",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-21 00:00:00",
			"createdAt": "2021-12-20 21:52:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-20 13:45:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.242021,
				"lng": -121.81558,
				"zipcode": "95123",
				"subPremise": "",
				"address": "5878 Tree Top Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1681888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 236000,
			"daysOnMovoto": 0,
			"id": "7316f749-bbc7-4d25-abd6-1768c675864f",
			"tnImgPath": "https://pi.movoto.com/p/102/221139891_0_RqynEI_p.jpeg",
			"listDate": null,
			"listingAgent": "Rigoberto Lara Lopez",
			"listPrice": 249000,
			"lotSize": 0,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221139891",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 27,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1974,
			"zipCode": "95134",
			"path": "san-jose-ca/247-el-bosque-dr-san-jose-ca-95134-102_221139891/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ac5bce14-1569-4b80-a90d-655e9bdd25cb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-21 00:00:00",
			"createdAt": "2021-11-02 21:44:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 21:44:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.411956,
				"lng": -121.944326,
				"zipcode": "95134",
				"subPremise": "",
				"address": "247 El Bosque Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 236000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 127,
			"id": "c21f073e-9dc5-4fb2-bab4-e82769206e2e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862548_0_NnfUN7_p.jpeg",
			"listDate": "2021-09-14 06:37:38",
			"listingAgent": "Joshua Michaelian",
			"listPrice": 1050000,
			"lotSize": 5593,
			"sqftTotal": 1677,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862548",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Joshua Michaelian, Broker",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1912,
			"zipCode": "95128",
			"path": "san-jose-ca/10-topeka-ave-san-jose-ca-95128/pid_vx4p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1f4205d5-eb69-42dd-b41c-d30aef131a0c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-09-14 13:42:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 06:37:38",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.323952,
				"lng": -121.930681,
				"zipcode": "95128",
				"subPremise": "",
				"address": "10 Topeka Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1717000,
			"daysOnMovoto": 34,
			"id": "9cb7f730-6b2d-41fa-a5fe-2a1635a91cb2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872546_0_QYBaav_p.jpeg",
			"listDate": "2021-12-15 09:51:13",
			"listingAgent": "Paulo Oliveira",
			"listPrice": 1750000,
			"lotSize": 7340,
			"sqftTotal": 2880,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872546",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Statewide Real Estate Services",
			"photoCount": 14,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "95117",
			"path": "san-jose-ca/3236-williamsburg-dr-san-jose-ca-95117/pid_b6sq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "235f0c6f-4316-4f03-9e87-5421c9910f18",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-12-15 17:57:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-15 09:51:13",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.297537,
				"lng": -121.95391,
				"zipcode": "95117",
				"subPremise": "",
				"address": "3236 Williamsburg Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1717000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1645000,
			"daysOnMovoto": 46,
			"id": "39af5fb7-a9bf-4087-90a2-b5c638c60f6b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871682_0_fRNeE6_p.jpeg",
			"listDate": "2021-12-03 10:36:56",
			"listingAgent": "Benjamin Frieden",
			"listPrice": 1449000,
			"lotSize": 7815,
			"sqftTotal": 2055,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871682",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "95128",
			"path": "san-jose-ca/903-s-daniel-way-san-jose-ca-95128/pid_od8p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 200000,
			"propertyId": "2b692f5e-2844-48af-980f-e31433ff4c00",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-12-03 18:42:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 10:36:56",
			"virtualTourLink": "http://www.903sdaniel.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.311306,
				"lng": -121.941867,
				"zipcode": "95128",
				"subPremise": "",
				"address": "903 S Daniel Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1645000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.903sdaniel.com"
		},
		{
			"closePrice": 910000,
			"daysOnMovoto": 3,
			"id": "2735660f-72d4-478b-8fbf-4cabe5a6034e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81874569_0_bfjAzi_p.jpeg",
			"listDate": "2022-01-14 15:55:16",
			"listingAgent": "Sophie Zeng",
			"listPrice": 910000,
			"lotSize": null,
			"sqftTotal": 1212,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81874569",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Goodview Financial & Real Estate",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "95124",
			"path": "san-jose-ca/4836-lakebird-pl-san-jose-ca-95124-100_80227861/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f236de01-bb12-4043-a00b-2a89965bc32b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2022-01-15 00:07:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2022-01-14 15:55:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.248817,
				"lng": -121.930142,
				"zipcode": "95124",
				"subPremise": "",
				"address": "4836 Lakebird Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 910000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 19,
			"id": "650f3023-3a80-4b4e-b710-15bd32e9bede",
			"tnImgPath": "https://pi.movoto.com/p/202/RS21270420_0_uNvRbv_p.jpeg",
			"listDate": "2021-12-30 00:00:00",
			"listingAgent": "Brian Brown",
			"listPrice": 989999,
			"lotSize": 6100,
			"sqftTotal": 1451,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "RS21270420",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Brown House Realty",
			"photoCount": 13,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 1970,
			"zipCode": "95148",
			"path": "san-jose-ca/2496-sturla-dr-san-jose-ca-95148/pid_n4kv2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4d67da7f-e55b-4420-ab9e-ae10bf00271e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-31 07:07:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.321687,
				"lng": -121.802565,
				"zipcode": "95148",
				"subPremise": "",
				"address": "2496 Sturla Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 905252,
			"daysOnMovoto": 42,
			"id": "2c7af654-fc03-433b-ba56-f2ff50273a64",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871902_0_67ujfI_p.jpeg",
			"listDate": "2021-12-06 14:04:39",
			"listingAgent": "Anne Hansen",
			"listPrice": 850000,
			"lotSize": 1642,
			"sqftTotal": 1643,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871902",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty One Group Infinity",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2004,
			"zipCode": "95110",
			"path": "san-jose-ca/46-w-julian-st-401-san-jose-ca-95110-100_80613691/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "34848cf0-af23-4cda-a396-33de738f1481",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-06 22:07:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 14:04:39",
			"virtualTourLink": "https://www.aryeo.com/v2/46-w-julian-st-san-jose-ca-95110-1373736/branded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.340051,
				"lng": -121.894426,
				"zipcode": "95110",
				"subPremise": "APT 401",
				"address": "46 W Julian St #401"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 905252,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.aryeo.com/v2/46-w-julian-st-san-jose-ca-95110-1373736/branded"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 60,
			"id": "bed2c60e-3552-465c-93a5-4a9023f2669d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870557_0_uA3aFI_p.jpeg",
			"listDate": "2021-11-18 11:46:30",
			"listingAgent": "Neil Kimmel",
			"listPrice": 1597000,
			"lotSize": 10258,
			"sqftTotal": 1924,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870557",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Kimmel Real Estate",
			"photoCount": 33,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1956,
			"zipCode": "95125",
			"path": "san-jose-ca/2741-almaden-rd-san-jose-ca-95125/pid_ub302vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81651224-af4d-4c26-a3cc-778b6190719b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-11-18 19:52:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 11:46:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.281127,
				"lng": -121.88201,
				"zipcode": "95125",
				"subPremise": "",
				"address": "2741 Almaden Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1930000,
			"daysOnMovoto": 63,
			"id": "a7a1b06d-db8e-4cf2-8220-feab1ea91971",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867214_0_qja3QQ_p.jpeg",
			"listDate": "2021-11-16 00:00:00",
			"listingAgent": "Brett Jennings Group",
			"listPrice": 1738888,
			"lotSize": 12145,
			"sqftTotal": 2417,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867214",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Real Estate Experts",
			"photoCount": 34,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1957,
			"zipCode": "95125",
			"path": "san-jose-ca/2785-2787-almaden-rd-san-jose-ca-95125-100_ml81867214/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5822fa75-fe4c-48da-9db0-d63216d23447",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-11-24 19:31:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.280748,
				"lng": -121.881774,
				"zipcode": "95125",
				"subPremise": "",
				"address": "2785-2787 Almaden Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1475000,
			"daysOnMovoto": 119,
			"id": "5489a10d-d562-41d4-8b56-6d12986c45f3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863523_0_VZVjfZ_p.jpeg",
			"listDate": "2021-09-20 20:22:17",
			"listingAgent": "Greg Pedone",
			"listPrice": 1699000,
			"lotSize": 10210,
			"sqftTotal": 3028,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863523",
			"bath": null,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 10,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95110",
			"path": "san-jose-ca/1182-plum-st-san-jose-ca-95110/pid_5ey02vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "53fdfb9c-a1be-41ef-b042-f64f4c6b9b2e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-09-21 03:27:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 20:22:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.317255,
				"lng": -121.879595,
				"zipcode": "95110",
				"subPremise": "",
				"address": "1182 Plum St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1390000,
			"daysOnMovoto": 0,
			"id": "00e93a4b-891b-435a-bb2f-6cfddf6b30ce",
			"tnImgPath": "https://pi.movoto.com/p/102/221151573_0_m6rmfA_p.jpeg",
			"listDate": null,
			"listingAgent": "Jacob Duncan",
			"listPrice": 1199000,
			"lotSize": 6229,
			"sqftTotal": 1067,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151573",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Olani Properties",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1971,
			"zipCode": "95131",
			"path": "san-jose-ca/1281-tourney-dr-san-jose-ca-95131/pid_9omp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "665fab48-225d-46e9-87f8-4424831901db",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-09 08:14:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 08:14:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.384687,
				"lng": -121.869047,
				"zipcode": "95131",
				"subPremise": "",
				"address": "1281 Tourney Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 285000,
			"daysOnMovoto": 21,
			"id": "b1497648-5c54-425f-9c40-3f7c21a28f6e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81873016_0_u3yeYV_p.jpeg",
			"listDate": "2021-12-23 10:39:52",
			"listingAgent": "Todd Su",
			"listPrice": 284900,
			"lotSize": null,
			"sqftTotal": 1344,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81873016",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty World-Todd Su & Company",
			"photoCount": 1,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1980,
			"zipCode": "95111",
			"path": "san-jose-ca/298-chateau-la-salle-san-jose-ca-95111-100_ml81873016/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b84ffea7-33e2-464e-9f14-3b9ed1ae1260",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-23 19:01:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-23 10:39:52",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.292887,
				"lng": -121.859397,
				"zipcode": "95111",
				"subPremise": "",
				"address": "298 Chateau La Salle"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 285000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1992000,
			"daysOnMovoto": 35,
			"id": "75f2b916-7d0f-4e89-a272-0477a839d173",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872176_0_Jfnq7a_p.jpeg",
			"listDate": "2021-12-09 12:01:28",
			"listingAgent": "Thao Dang & Brian Ng",
			"listPrice": 1658000,
			"lotSize": 6721,
			"sqftTotal": 1729,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872176",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Block Change Real Estate",
			"photoCount": 57,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1973,
			"zipCode": "95135",
			"path": "san-jose-ca/5151-harvest-est-san-jose-ca-95135-100_81101004/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "29b4d5d3-cd98-401f-9034-1c579d6e4e71",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-09 20:07:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 12:01:28",
			"virtualTourLink": "http://tours.vrsquadinc.com/5151harvestestates95135",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.295472,
				"lng": -121.765267,
				"zipcode": "95135",
				"subPremise": "",
				"address": "5151 Harvest Est"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1992000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://tours.vrsquadinc.com/5151harvestestates95135"
		},
		{
			"closePrice": 602000,
			"daysOnMovoto": 38,
			"id": "47cd973e-338c-4114-897f-b7067487c3c4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871914_0_zqeeNI_p.jpeg",
			"listDate": "2021-12-06 15:34:08",
			"listingAgent": "Kei Group",
			"listPrice": 568000,
			"lotSize": null,
			"sqftTotal": 692,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871914",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2005,
			"zipCode": "95128",
			"path": "san-jose-ca/801-s-winchester-blvd-3305-san-jose-ca-95128/pid_6yrq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3997f2ca-ff9b-468e-b41a-e623d602af31",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-06 23:37:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 15:34:08",
			"virtualTourLink": "https://my.matterport.com/show/?m=6Sh7gAhtcWy",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.31274,
				"lng": -121.950204,
				"zipcode": "95128",
				"subPremise": "APT 3305",
				"address": "801 S Winchester Blvd #3305"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 602000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=6Sh7gAhtcWy"
		},
		{
			"closePrice": 626000,
			"daysOnMovoto": 62,
			"id": "8a854e45-bea9-4f10-84bc-3d022b4191c4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870136_0_bnIQBm_p.jpeg",
			"listDate": "2021-11-12 18:08:25",
			"listingAgent": "Carol Carlson",
			"listPrice": 628000,
			"lotSize": null,
			"sqftTotal": 1015,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870136",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2005,
			"zipCode": "95112",
			"path": "san-jose-ca/1060-s-3rd-st-140-san-jose-ca-95112/pid_sfet2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a725a6a-6d99-49a0-9a4e-96500ecc80ed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-11-13 02:12:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 18:08:25",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2934447",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.321641,
				"lng": -121.876555,
				"zipcode": "95112",
				"subPremise": "APT 140",
				"address": "1060 S 3rd St #140"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 626000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2934447"
		},
		{
			"closePrice": 970000,
			"daysOnMovoto": 76,
			"id": "26e88785-f240-4025-820a-21455978383b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868550_0_QzFqB6_p.jpeg",
			"listDate": "2021-10-29 12:46:03",
			"listingAgent": "Joi Walker",
			"listPrice": 999999,
			"lotSize": 7784,
			"sqftTotal": 1607,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868550",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Aborn Properties",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1959,
			"zipCode": "95127",
			"path": "san-jose-ca/10541-observatory-dr-san-jose-ca-95127/pid_gw2u2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50001,
			"propertyId": "d333a800-7504-4dcb-8153-f7c8e4b55a38",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-10-29 19:52:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 12:46:03",
			"virtualTourLink": "https://focused.hd.pics/10541-Observatory-Dr",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.37825,
				"lng": -121.809128,
				"zipcode": "95127",
				"subPremise": "",
				"address": "10541 Observatory Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://focused.hd.pics/10541-Observatory-Dr"
		},
		{
			"closePrice": 278888,
			"daysOnMovoto": 90,
			"id": "7266b7cc-dad8-423b-a3a0-74606be01a2e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863976_0_irUMJu_p.jpeg",
			"listDate": "2021-10-16 00:00:00",
			"listingAgent": "Christy Niemeyer",
			"listPrice": 278888,
			"lotSize": null,
			"sqftTotal": 1376,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863976",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "David Lyng Real Estate",
			"photoCount": 17,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1998,
			"zipCode": "95123",
			"path": "san-jose-ca/195-blossom-hill-rd-san-jose-ca-95123-202_ml81519377/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a89fc9df-cd69-4bfb-8293-4303b097a90c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-10-16 21:07:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-16 00:00:00",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2925629",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.25401,
				"lng": -121.811881,
				"zipcode": "95123",
				"subPremise": "",
				"address": "195 Blossom Hill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 278888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2925629"
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 98,
			"id": "808bbf66-49b8-43d9-93d1-143fbd06e719",
			"tnImgPath": "https://pi.movoto.com/p/202/ND21224273_0_emrUVM_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Deepee Chattha",
			"listPrice": 875000,
			"lotSize": 5026,
			"sqftTotal": 1210,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "ND21224273",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "REX-Real Estate Exchange, Inc",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 1947,
			"zipCode": "95110",
			"path": "san-jose-ca/1323-forrestal-ave-san-jose-ca-95110/pid_2ndp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2356e495-4520-4bf9-9d50-ebbf5155e936",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-10-10 16:22:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.358985,
				"lng": -121.911998,
				"zipcode": "95110",
				"subPremise": "",
				"address": "1323 Forrestal Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 988000,
			"daysOnMovoto": 109,
			"id": "aa1f47a3-b675-4724-8980-50fc89b787cb",
			"tnImgPath": "https://pi.movoto.com/p/202/TR21209152_0_26ZfYe_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Poon Choi Chao",
			"listPrice": 988000,
			"lotSize": 595,
			"sqftTotal": 1213,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "TR21209152",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Re/Max Champions",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95131",
			"path": "san-jose-ca/2084-sheffield-dr-san-jose-ca-95131/pid_ytcp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8fe80b62-1f4c-4687-b34b-a8c7ef6e7b92",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-09-27 22:37:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/626735338",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.39393,
				"lng": -121.903134,
				"zipcode": "95131",
				"subPremise": "",
				"address": "2084 Sheffield Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 988000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/626735338"
		},
		{
			"closePrice": 858000,
			"daysOnMovoto": 186,
			"id": "603b3e6e-36b7-4b96-a67f-3bfb97422bdf",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853020_0_zVAMBF_p.jpeg",
			"listDate": "2021-07-11 16:14:19",
			"listingAgent": "Philip Ma",
			"listPrice": 839000,
			"lotSize": null,
			"sqftTotal": 1662,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853020",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Santa Clara Valley",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1998,
			"zipCode": "95112",
			"path": "san-jose-ca/130-e-san-fernando-st-325-san-jose-ca-95112/pid_ejbt2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -29000,
			"propertyId": "77c3e840-6d75-4276-bd83-4cc777c59c2a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-07-11 23:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-11 16:14:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.335493,
				"lng": -121.886295,
				"zipcode": "95112",
				"subPremise": "APT 325",
				"address": "130 E San Fernando St #325"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 858000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1265000,
			"daysOnMovoto": 0,
			"id": "b40e7b29-033d-4819-b865-b0d6bb807b1b",
			"tnImgPath": "https://pi.movoto.com/p/102/221153415_0_aQNMRi_p.jpeg",
			"listDate": null,
			"listingAgent": "Devin Spurling",
			"listPrice": 1100000,
			"lotSize": 6713,
			"sqftTotal": 1037,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221153415",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty One Group Complete",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1962,
			"zipCode": "95123",
			"path": "san-jose-ca/723-calero-avenue-san-jose-ca-95123-100_ml81517149/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "30f7b21a-ae66-41a1-9afa-18743ced7847",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-16 08:14:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-16 08:14:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.242299,
				"lng": -121.840873,
				"zipcode": "95123",
				"subPremise": "",
				"address": "723 Calero Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1265000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2201021,
			"daysOnMovoto": 0,
			"id": "70809860-f226-44bf-ac47-ae191cc80eea",
			"tnImgPath": "https://pi.movoto.com/p/102/221151653_0_uyyVj6_p.jpeg",
			"listDate": null,
			"listingAgent": "Steven Trotter",
			"listPrice": 1500000,
			"lotSize": 8063,
			"sqftTotal": 2044,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151653",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Trotter Properties",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1979,
			"zipCode": "95120",
			"path": "san-jose-ca/1021-mazzone-dr-san-jose-ca-95120-100_80933327/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2fd722a2-4d06-474e-867b-2271abd2e24a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-09 14:59:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 14:59:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.233808,
				"lng": -121.865394,
				"zipcode": "95120",
				"subPremise": "",
				"address": "1021 Mazzone Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2201021,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1120000,
			"daysOnMovoto": 27,
			"id": "6552e4c5-6726-461c-b399-59c03c83ba0a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872673_0_Muyj3e_p.jpeg",
			"listDate": "2021-12-16 13:33:40",
			"listingAgent": "Marian Campoy",
			"listPrice": 1100000,
			"lotSize": 8172,
			"sqftTotal": 1629,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872673",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rosa Realty Inc.",
			"photoCount": 5,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1956,
			"zipCode": "95116",
			"path": "san-jose-ca/652-lotus-st-san-jose-ca-95116/pid_d2i12vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e2b9ed1f-9763-46e8-bd1d-19dd5325d1bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-12-16 21:37:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-16 13:33:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.339892,
				"lng": -121.858865,
				"zipcode": "95116",
				"subPremise": "",
				"address": "652 Lotus St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1120000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 595000,
			"daysOnMovoto": 45,
			"id": "19d96c03-34b6-47c7-b125-a5ea68d7003e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870736_0_UJrBe6_p.jpeg",
			"listDate": "2021-11-29 00:00:00",
			"listingAgent": "Lisa Lyons",
			"listPrice": 599000,
			"lotSize": null,
			"sqftTotal": 663,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870736",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2006,
			"zipCode": "95110",
			"path": "san-jose-ca/175-w-saint-james-st-607-san-jose-ca-95110-100_81431077/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b5b762f9-6e94-4f76-a56a-7ae24b3feb65",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-11-29 22:37:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 00:00:00",
			"virtualTourLink": "http://www.cityheightssj607.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.337938,
				"lng": -121.89553,
				"zipcode": "95110",
				"subPremise": "APT 607",
				"address": "175 W Saint James St #607"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 595000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.cityheightssj607.com/"
		},
		{
			"closePrice": 827500,
			"daysOnMovoto": 97,
			"id": "dea955d7-7666-436f-8509-46a480afedd6",
			"tnImgPath": "https://pi.movoto.com/p/202/SB21223212_0_mIJ6VN_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Susan Earnest",
			"listPrice": 849000,
			"lotSize": null,
			"sqftTotal": 1258,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "SB21223212",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "E Real Estate Corp.",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2004,
			"zipCode": "95126",
			"path": "san-jose-ca/1310-saddle-rack-st-414-san-jose-ca-95126-100_80769673/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "58dab644-5d0a-46ec-be7a-28de39539ebe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-10-13 18:43:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.320257,
				"lng": -121.911861,
				"zipcode": "95126",
				"subPremise": "APT 414",
				"address": "1310 Saddle Rack St #414"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 827500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 115,
			"id": "e067cb3b-229b-4c40-a03b-58bb0ecc2326",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863358_0_iB3MuB_p.jpeg",
			"listDate": "2021-09-18 22:29:39",
			"listingAgent": "Jennifer Cheng",
			"listPrice": 2238800,
			"lotSize": 7998,
			"sqftTotal": 4316,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863358",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1961,
			"zipCode": "95129",
			"path": "san-jose-ca/1599-maurice-ln-san-jose-ca-95129/pid_y0qr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 2015000,
			"propertyId": "a33ee008-136d-4f50-b31c-2deb48659d78",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-09-19 05:32:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-18 22:29:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.294246,
				"lng": -121.998005,
				"zipcode": "95129",
				"subPremise": "",
				"address": "1599 Maurice Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 113,
			"id": "b1a25cfb-887b-4a99-b315-6ff84cc8895f",
			"tnImgPath": "https://pi.movoto.com/p/202/ND21208094_0_NFVrji_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Anthony Boudreault",
			"listPrice": 1275000,
			"lotSize": 6173,
			"sqftTotal": null,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "ND21208094",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1900,
			"zipCode": "95110",
			"path": "san-jose-ca/638-n-san-pedro-st-san-jose-ca-95110/pid_qatp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "c8b585b8-2c23-4df6-9fce-c79ff888b0c8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-10-05 05:07:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.346525,
				"lng": -121.901062,
				"zipcode": "95110",
				"subPremise": "",
				"address": "638 N San Pedro St N"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 260000,
			"daysOnMovoto": 119,
			"id": "785c64b1-0700-44df-a1bb-fe6d24f59f23",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862487_0_fRaQrr_p.jpeg",
			"listDate": "2021-09-13 14:56:59",
			"listingAgent": "Tran Vinh Tran",
			"listPrice": 275000,
			"lotSize": null,
			"sqftTotal": 1473,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862487",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Manufactured Homes Inc",
			"photoCount": 52,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1995,
			"zipCode": "95134",
			"path": "san-jose-ca/735-sprindrift-dr-san-jose-ca-95134-100_ml81862487/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "7b8a2b48-0bb6-4e7f-ac06-f57d50a3332a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-09-13 22:02:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 14:56:59",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.414952,
				"lng": -121.941618,
				"zipcode": "95134",
				"subPremise": "",
				"address": "735 Sprindrift Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2480000,
			"daysOnMovoto": 0,
			"id": "69c4f6bd-b627-41ec-a686-8712565e6a66",
			"tnImgPath": "https://pi.movoto.com/p/102/221124193_0_JyqvAv_p.jpeg",
			"listDate": null,
			"listingAgent": "Eloy Magdaleno",
			"listPrice": 2098800,
			"lotSize": 11760,
			"sqftTotal": 2236,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221124193",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Foothill View Real Estate Inc",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "95117",
			"path": "san-jose-ca/921-del-monte-pl-san-jose-ca-95117/pid_7zoq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2caa6761-d697-4c48-8d0f-2976db23b720",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-11-27 08:14:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-27 08:14:15",
			"virtualTourLink": "https://player.vimeo.com/video/647828393",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.310143,
				"lng": -121.95711,
				"zipcode": "95117",
				"subPremise": "",
				"address": "921 Del Monte Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/647828393"
		},
		{
			"closePrice": 1015000,
			"daysOnMovoto": 0,
			"id": "f3ea72b3-25bd-417b-97b9-c17aa5284a9f",
			"tnImgPath": "https://pi.movoto.com/p/102/221134395_0_2aEAa6_p.jpeg",
			"listDate": null,
			"listingAgent": "Demetrio Robles",
			"listPrice": 950000,
			"lotSize": 5001,
			"sqftTotal": 1683,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221134395",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "JRP Realty Group",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1959,
			"zipCode": "95116",
			"path": "san-jose-ca/2376-sleepy-hollow-ln-san-jose-ca-95116/pid_bjnt2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8555c10e-6ab6-49ad-aa98-7ce7353f49df",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-10-21 21:49:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 21:49:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.348148,
				"lng": -121.832086,
				"zipcode": "95116",
				"subPremise": "",
				"address": "2376 Sleepy Hollow Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1015000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 370000,
			"daysOnMovoto": 105,
			"id": "9fdd5572-c156-4c66-a974-234f394429c4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81864346_0_6EB7vu_p.jpeg",
			"listDate": "2021-09-26 21:33:36",
			"listingAgent": "Franco Perez",
			"listPrice": 375000,
			"lotSize": null,
			"sqftTotal": 1403,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81864346",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "KW Silicon City",
			"photoCount": 30,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2018,
			"zipCode": "95134",
			"path": "san-jose-ca/371-los-encinos-st-san-jose-ca-95134-100_ml81864346/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "10e57103-121e-48e7-b1ee-a87e79d97f1a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-09-27 04:37:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-26 21:33:36",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.415183,
				"lng": -121.945032,
				"zipcode": "95134",
				"subPremise": "",
				"address": "371 Los Encinos St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 370000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 564000,
			"daysOnMovoto": 227,
			"id": "4cce754a-d148-4e5b-9222-b9d240c8e057",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81846384_0_3nZ7zY_p.jpeg",
			"listDate": "2021-05-28 00:00:00",
			"listingAgent": "Mary Faria",
			"listPrice": 588000,
			"lotSize": 1054,
			"sqftTotal": 832,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81846384",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker-The Real Estate People",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1981,
			"zipCode": "95133",
			"path": "san-jose-ca/1055-n-capitol-ave-151-san-jose-ca-95133/pid_vzrp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d5e74817-1ac7-41df-bd08-26f6371a4943",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-05-29 00:13:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.383581,
				"lng": -121.859219,
				"zipcode": "95133",
				"subPremise": "APT 151",
				"address": "1055 N Capitol Ave #151"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 564000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 10,
			"id": "991b013f-61f9-4a3a-bf01-508f0edb3a3d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81873174_0_ZbeJRq_p.jpeg",
			"listDate": "2021-12-29 12:46:21",
			"listingAgent": "Cristina Martinez",
			"listPrice": 1050000,
			"lotSize": 5580,
			"sqftTotal": 1400,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81873174",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Cristina Martinez Company",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1961,
			"zipCode": "95116",
			"path": "san-jose-ca/2410-2412-dobern-ave-san-jose-ca-95116-100_ml81700093/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d9068e6c-e169-4435-83b9-19304948da0a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-09 00:00:00",
			"createdAt": "2021-12-29 21:01:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-29 12:46:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.353251,
				"lng": -121.835481,
				"zipcode": "95116",
				"subPremise": "",
				"address": "2410-2412 Dobern Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1180000,
			"daysOnMovoto": 57,
			"id": "8ebdb606-a40e-4e3d-a5be-9e90f9b23038",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868089_0_JbNeum_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Barbara Sunseri",
			"listPrice": 1199000,
			"lotSize": 6107,
			"sqftTotal": 1027,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868089",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "95127",
			"path": "san-jose-ca/3488-golf-dr-san-jose-ca-95127/pid_1y0u2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -26000,
			"propertyId": "dd81ed69-49d2-4e80-b150-8fd20be10d20",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-08 00:00:00",
			"createdAt": "2021-11-12 22:42:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.386854,
				"lng": -121.83104,
				"zipcode": "95127",
				"subPremise": "",
				"address": "3488 Golf Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1165000,
			"daysOnMovoto": 44,
			"id": "acdd000e-3fba-4616-9fe1-8f47f142c8fb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870098_0_JnVBaq_p.jpeg",
			"listDate": "2021-11-24 00:00:00",
			"listingAgent": "Steve Rumberg",
			"listPrice": 1100000,
			"lotSize": 1394,
			"sqftTotal": 1690,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870098",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty-Silicon Valley",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2004,
			"zipCode": "95125",
			"path": "san-jose-ca/2256-golden-crest-common-san-jose-ca-95125-100_ml81870098/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "22d9f297-e02e-4001-93aa-106408b3879b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-11-24 20:22:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.297721,
				"lng": -121.867533,
				"zipcode": "95125",
				"subPremise": "",
				"address": "2256 Golden Crest Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1165000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1680000,
			"daysOnMovoto": 0,
			"id": "9143ca39-5e3d-470b-8e51-e99ad3a18323",
			"tnImgPath": "https://pi.movoto.com/p/102/221146485_0_AMZQBm_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Minerick",
			"listPrice": 1495000,
			"lotSize": 3780,
			"sqftTotal": 1972,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221146485",
			"bath": 5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "HomeCoin.com",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1910,
			"zipCode": "95112",
			"path": "san-jose-ca/498-s-5th-st-san-jose-ca-95112/pid_91bt2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fa135643-2910-4e99-99e4-d195eec17637",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-11-20 00:54:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-20 00:54:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.330846,
				"lng": -121.880481,
				"zipcode": "95112",
				"subPremise": "",
				"address": "498 S 5th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1680000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1920000,
			"daysOnMovoto": 51,
			"id": "c3f8a4b5-b07c-4ce2-8694-940a383847ba",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870289_0_q3Vn23_p.jpeg",
			"listDate": "2021-11-15 14:54:21",
			"listingAgent": "Carlos Azucena",
			"listPrice": 1980000,
			"lotSize": 6564,
			"sqftTotal": 3136,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870289",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 13,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1958,
			"zipCode": "95117",
			"path": "san-jose-ca/3194-moorpark-ave-san-jose-ca-95117/pid_rlqq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d73df5e4-d246-49f8-a7a0-1d5a16788f9a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-11-15 22:57:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 14:54:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.315873,
				"lng": -121.952866,
				"zipcode": "95117",
				"subPremise": "",
				"address": "3194 Moorpark Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1920000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1005555,
			"daysOnMovoto": 62,
			"id": "fa720866-4a2f-48f9-a7d9-dfde1d9767fd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869135_0_MIBA6J_p.jpeg",
			"listDate": "2021-11-04 10:41:23",
			"listingAgent": "Anton Bion",
			"listPrice": 799000,
			"lotSize": 5306,
			"sqftTotal": 1505,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869135",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1937,
			"zipCode": "95116",
			"path": "san-jose-ca/195-n-24th-st-san-jose-ca-95116-100_81447380/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba55390d-9b3c-44ff-bb29-ba04f7e549c4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-11-04 17:47:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 10:41:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.34925,
				"lng": -121.870292,
				"zipcode": "95116",
				"subPremise": "",
				"address": "195 N 24th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1005555,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 459000,
			"daysOnMovoto": 42,
			"id": "892ed63b-181a-4995-8076-c87527a58573",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862767_0_eRinqu_p.jpeg",
			"listDate": "2021-11-24 00:00:00",
			"listingAgent": "Mark Stevensen",
			"listPrice": 459000,
			"lotSize": null,
			"sqftTotal": 947,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862767",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Stevensen & Neal Realtors",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "95135",
			"path": "san-jose-ca/5338-cribari-gln-san-jose-ca-95135-100_81056935/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "39308d38-bfd6-4943-a2b6-045ae0421695",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-11-24 18:32:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.297503,
				"lng": -121.756898,
				"zipcode": "95135",
				"subPremise": "",
				"address": "5338 Cribari Gln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 459000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 96,
			"id": "b2b0b271-e156-4c85-b47e-574da28cc4e7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81864975_0_uzAfIa_p.jpeg",
			"listDate": "2021-09-30 19:48:30",
			"listingAgent": "Sherdin Betbabasi",
			"listPrice": 799999,
			"lotSize": null,
			"sqftTotal": 1622,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81864975",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1998,
			"zipCode": "95112",
			"path": "san-jose-ca/130-e-san-fernando-st-314-san-jose-ca-95112-100_80251815/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -48889,
			"propertyId": "3e135cb0-b835-434c-96d8-c8b4c45837a8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-10-01 02:52:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 19:48:30",
			"virtualTourLink": "https://130eastsanfernandostreetunit31447632.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.335493,
				"lng": -121.886295,
				"zipcode": "95112",
				"subPremise": "APT 314",
				"address": "130 E San Fernando St #314"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://130eastsanfernandostreetunit31447632.f8re.com/"
		},
		{
			"closePrice": 275000,
			"daysOnMovoto": 364,
			"id": "2c950197-b545-4783-b008-1de6235d806d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824821_0_QFNMy3_p.jpeg",
			"listDate": "2021-01-06 00:00:00",
			"listingAgent": "Dinorah Gousheh",
			"listPrice": 299000,
			"lotSize": null,
			"sqftTotal": 1512,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824821",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Premier Realty Associates",
			"photoCount": 10,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Single Wide",
			"yearBuilt": 1978,
			"zipCode": "95125",
			"path": "san-jose-ca/375-s-mill-pond-dr-san-jose-ca-95125-100_ml81824821/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 34000,
			"propertyId": "86565178-cedb-4a40-b673-3da211e45ef4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-01-07 00:57:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.292326,
				"lng": -121.869181,
				"zipcode": "95125",
				"subPremise": "",
				"address": "375 S Mill Pond Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1388000,
			"daysOnMovoto": 34,
			"id": "2d188faf-3385-42f3-95f2-4ba578ac6b9e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871257_0_VFAnBF_p.jpeg",
			"listDate": "2021-11-30 07:28:36",
			"listingAgent": "Sonny Pham",
			"listPrice": 1100000,
			"lotSize": 6451,
			"sqftTotal": 1586,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871257",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "STP Real Estate Services",
			"photoCount": 15,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1978,
			"zipCode": "95121",
			"path": "san-jose-ca/3982-tuers-rd-san-jose-ca-95121/pid_t34t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba9da279-c334-4bc2-ad0f-1a52ac989389",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-11-30 15:32:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 07:28:36",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.290003,
				"lng": -121.814668,
				"zipcode": "95121",
				"subPremise": "",
				"address": "3982 Tuers Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1388000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 41,
			"id": "54cfd476-b4ea-4dae-a45b-c4f57d9a6b71",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870974_0_UZRajM_p.jpeg",
			"listDate": "2021-11-23 15:43:54",
			"listingAgent": "Gary Yip",
			"listPrice": 999950,
			"lotSize": 4783,
			"sqftTotal": 1482,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870974",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Bay One Realty & Financial Service Inc",
			"photoCount": 48,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1957,
			"zipCode": "95112",
			"path": "san-jose-ca/485-487-n-6th-st-san-jose-ca-95112-100_ml81870974/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b8fc2d4d-0cef-437b-9394-a0f1c2a99735",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-11-23 23:47:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-23 15:43:54",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.34663,
				"lng": -121.891537,
				"zipcode": "95112",
				"subPremise": "",
				"address": "485-487 N 6th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 533000,
			"daysOnMovoto": 46,
			"id": "75cbe3f9-1981-4899-87e7-70872c344ae5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870523_0_iuEnQF_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Dorothy Liu",
			"listPrice": 500000,
			"lotSize": null,
			"sqftTotal": 903,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870523",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "95123",
			"path": "san-jose-ca/5473-tyhurst-walkway-3-san-jose-ca-95123-100_80839312/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "acb7ac58-b42b-4f4c-bee6-9487eba8344b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-11-19 08:32:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 00:00:00",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2930126",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.254555,
				"lng": -121.82417,
				"zipcode": "95123",
				"subPremise": "APT 3",
				"address": "5473 Tyhurst Walkway #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 533000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2930126"
		},
		{
			"closePrice": 246000,
			"daysOnMovoto": 76,
			"id": "01852be0-19a5-40ca-bc28-ad62f8be15fc",
			"tnImgPath": "https://pi.movoto.com/p/12/40971549_0_6rJbFJ_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Becky Cheng",
			"listPrice": 249000,
			"lotSize": 1512,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971549",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Plus Realty",
			"photoCount": 37,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "95134",
			"path": "san-jose-ca/575-hermitage-dr-san-jose-ca-95134-12_40971549/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c98ffd11-abe9-4a4d-aaf4-7aaf0fc07661",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-10-20 12:31:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.417183,
				"lng": -121.943654,
				"zipcode": "95134",
				"subPremise": "",
				"address": "575 Hermitage Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 246000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1325000,
			"daysOnMovoto": 125,
			"id": "7ad813c3-740e-4e8c-a76a-4dbb61eec8a9",
			"tnImgPath": "https://pi.movoto.com/p/110/421597646_0_juMMzI_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Jimmy Lam",
			"listPrice": 1380000,
			"lotSize": 4923,
			"sqftTotal": 3488,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421597646",
			"bath": 5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Coldwell Banker Infiniti Group",
			"photoCount": 2,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "95111",
			"path": "san-jose-ca/110-roundtable-dr-4-san-jose-ca-95111/pid_y8tv2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "973bfac4-16a5-4d82-8d62-b88df0a7c07d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-09-24 01:26:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.260643,
				"lng": -121.807228,
				"zipcode": "95111",
				"subPremise": "BLDG 4",
				"address": "110 Roundtable Dr #BLDG 4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 221,
			"id": "6ec42c33-58cb-4178-8d02-0378f58dd349",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81846104_0_MjRJJU_p.jpeg",
			"listDate": "2021-05-27 12:48:21",
			"listingAgent": "Robert Anderson",
			"listPrice": 1650000,
			"lotSize": 7331,
			"sqftTotal": 2317,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81846104",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Robert Anderson, Broker",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "95128",
			"path": "san-jose-ca/2073-w-hedding-st-san-jose-ca-95128/pid_g54p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "ec68b85d-8e64-4bd2-990b-2da9e70e15c3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-05-27 19:52:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-27 12:48:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.330886,
				"lng": -121.936489,
				"zipcode": "95128",
				"subPremise": "",
				"address": "2073 W Hedding St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 5,
			"id": "c8060b7b-2b83-4fa9-9a26-7d67111a126c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872703_0_3Yvrq2_p.jpeg",
			"listDate": "2021-12-29 00:00:00",
			"listingAgent": "Susie Mize",
			"listPrice": 1250000,
			"lotSize": null,
			"sqftTotal": 1250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872703",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2002,
			"zipCode": "95128",
			"path": "san-jose-ca/333-santana-row-227-san-jose-ca-95128-100_ml81459004/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e466d35d-109e-4a48-ba2b-a898b3e821fa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-12-29 22:31:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-29 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/1636140",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.321934,
				"lng": -121.948204,
				"zipcode": "95128",
				"subPremise": "APT 227",
				"address": "333 Santana Row #227"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/1636140"
		},
		{
			"closePrice": 1430000,
			"daysOnMovoto": 34,
			"id": "4dc464cf-5cdb-4b67-af5d-961cb1ad5b23",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871172_0_zM7jMf_p.jpeg",
			"listDate": "2021-11-29 09:36:56",
			"listingAgent": "Kendra Gaeta",
			"listPrice": 1385000,
			"lotSize": 6665,
			"sqftTotal": 1970,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871172",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "95118",
			"path": "san-jose-ca/5402-russo-dr-san-jose-ca-95118/pid_p4fu2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6bd2437a-67e5-4c30-ba51-914918d63892",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-11-29 17:42:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 09:36:56",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Jose",
				"lat": 37.252177,
				"lng": -121.880213,
				"zipcode": "95118",
				"subPremise": "",
				"address": "5402 Russo Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1430000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]