const hillsborough = {
    "geography": {
       "census_release": "ACS 2019 5-year",
       "parents": {
          "county": {
             "full_name": "San Mateo County, CA",
             "short_name": "San Mateo County",
             "sumlevel": "050",
             "land_area": 1161957020,
             "full_geoid": "05000US06081",
             "total_population": 767423
          },
          "CBSA": {
             "full_name": "San Francisco-Oakland-Berkeley, CA Metro Area",
             "short_name": "San Francisco-Oakland-Berkeley, CA",
             "sumlevel": "310",
             "land_area": 6398229497,
             "full_geoid": "31000US41860",
             "total_population": 4701332
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39283497
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 324697795
          }
       },
       "this": {
          "full_name": "Hillsborough, CA",
          "short_name": "Hillsborough",
          "sumlevel": "160",
          "land_area": 16032293,
          "full_geoid": "16000US0633798",
          "total_population": 11447,
          "sumlevel_name": "Place",
          "short_geoid": "0633798"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "5"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 25.38,
                   "CBSA": 19.89,
                   "state": 22.97
                },
                "error": {
                   "this": 2.63,
                   "CBSA": 0.08,
                   "state": 0.03
                },
                "numerators": {
                   "this": 2905,
                   "CBSA": 934913,
                   "state": 9022146
                },
                "numerator_errors": {
                   "this": 301.4,
                   "CBSA": 3660.2,
                   "state": 12886.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 110
                },
                "error_ratio": {
                   "this": 10.4,
                   "CBSA": 0.4,
                   "state": 0.1
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 52.74,
                   "CBSA": 64.97,
                   "state": 63.07
                },
                "error": {
                   "this": 3.49,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 6037,
                   "CBSA": 3054483,
                   "state": 2.477531E7
                },
                "numerator_errors": {
                   "this": 399.4,
                   "CBSA": 6616.6,
                   "state": 20108.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 84
                },
                "error_ratio": {
                   "this": 6.6,
                   "CBSA": 0.2,
                   "state": 0.1
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 21.88,
                   "CBSA": 15.14,
                   "state": 13.97
                },
                "error": {
                   "this": 2.26,
                   "CBSA": 0.1,
                   "state": 0.03
                },
                "numerators": {
                   "this": 2505,
                   "CBSA": 711936,
                   "state": 5486041
                },
                "numerator_errors": {
                   "this": 258.4,
                   "CBSA": 4598.4,
                   "state": 12678.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 145,
                   "state": 157
                },
                "error_ratio": {
                   "this": 10.3,
                   "CBSA": 0.7,
                   "state": 0.2
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.35,
                      "CBSA": 10.98,
                      "state": 12.52
                   },
                   "error": {
                      "this": 1.87,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1299,
                      "CBSA": 516235,
                      "state": 4919754
                   },
                   "numerator_errors": {
                      "this": 213.8,
                      "CBSA": 2590.6,
                      "state": 9132.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 16.5,
                      "CBSA": 0.5,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 16.52,
                      "CBSA": 11.01,
                      "state": 13.09
                   },
                   "error": {
                      "this": 2.09,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1891,
                      "CBSA": 517483,
                      "state": 5140633
                   },
                   "numerator_errors": {
                      "this": 239.1,
                      "CBSA": 2602.5,
                      "state": 9167.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 150,
                      "state": 126
                   },
                   "error_ratio": {
                      "this": 12.7,
                      "CBSA": 0.5,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.21,
                      "CBSA": 13.76,
                      "state": 14.84
                   },
                   "error": {
                      "this": 1.46,
                      "CBSA": 0.06,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 711,
                      "CBSA": 647077,
                      "state": 5830059
                   },
                   "numerator_errors": {
                      "this": 167.1,
                      "CBSA": 3018.4,
                      "state": 10871.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 45,
                      "state": 42
                   },
                   "error_ratio": {
                      "this": 23.5,
                      "CBSA": 0.4,
                      "state": 0.2
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 5.55,
                      "CBSA": 15.72,
                      "state": 14.18
                   },
                   "error": {
                      "this": 1.32,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 635,
                      "CBSA": 738889,
                      "state": 5570748
                   },
                   "numerator_errors": {
                      "this": 150.8,
                      "CBSA": 2958.8,
                      "state": 8798.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 35,
                      "state": 39
                   },
                   "error_ratio": {
                      "this": 23.8,
                      "CBSA": 0.4,
                      "state": 0.1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.21,
                      "CBSA": 13.98,
                      "state": 12.95
                   },
                   "error": {
                      "this": 1.49,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1627,
                      "CBSA": 657446,
                      "state": 5088145
                   },
                   "numerator_errors": {
                      "this": 170.8,
                      "CBSA": 2972.2,
                      "state": 8746.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 110
                   },
                   "error_ratio": {
                      "this": 10.5,
                      "CBSA": 0.4,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 16.76,
                      "CBSA": 13.41,
                      "state": 12.76
                   },
                   "error": {
                      "this": 1.78,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1918,
                      "CBSA": 630235,
                      "state": 5011016
                   },
                   "numerator_errors": {
                      "this": 204.1,
                      "CBSA": 2628.2,
                      "state": 6867
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 131
                   },
                   "error_ratio": {
                      "this": 10.6,
                      "CBSA": 0.4,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 12.95,
                      "CBSA": 11.01,
                      "state": 10.34
                   },
                   "error": {
                      "this": 1.77,
                      "CBSA": 0.09,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1482,
                      "CBSA": 517687,
                      "state": 4062419
                   },
                   "numerator_errors": {
                      "this": 202.9,
                      "CBSA": 4180.8,
                      "state": 11748.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 118,
                      "state": 125
                   },
                   "error_ratio": {
                      "this": 13.7,
                      "CBSA": 0.8,
                      "state": 0.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 10.76,
                      "CBSA": 6.25,
                      "state": 5.82
                   },
                   "error": {
                      "this": 1.65,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1232,
                      "CBSA": 293719,
                      "state": 2285972
                   },
                   "numerator_errors": {
                      "this": 189.2,
                      "CBSA": 2768.5,
                      "state": 7838.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 172,
                      "state": 185
                   },
                   "error_ratio": {
                      "this": 15.3,
                      "CBSA": 1,
                      "state": 0.3
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 5.7,
                      "CBSA": 3.88,
                      "state": 3.5
                   },
                   "error": {
                      "this": 1.1,
                      "CBSA": 0.05,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 652,
                      "CBSA": 182561,
                      "state": 1374751
                   },
                   "numerator_errors": {
                      "this": 125.6,
                      "CBSA": 2467.2,
                      "state": 6707.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 147,
                      "state": 163
                   },
                   "error_ratio": {
                      "this": 19.3,
                      "CBSA": 1.3,
                      "state": 0.6
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13.47,
                      "CBSA": 11.35,
                      "state": 12.87
                   },
                   "error": {
                      "this": 3.09,
                      "CBSA": 0.08,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 764,
                      "CBSA": 264024,
                      "state": 2512581
                   },
                   "numerator_errors": {
                      "this": 177.5,
                      "CBSA": 1745.7,
                      "state": 6146.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 119,
                      "state": 105
                   },
                   "error_ratio": {
                      "this": 22.9,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 15.6,
                      "CBSA": 11.33,
                      "state": 13.46
                   },
                   "error": {
                      "this": 2.8,
                      "CBSA": 0.08,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 885,
                      "CBSA": 263377,
                      "state": 2628152
                   },
                   "numerator_errors": {
                      "this": 162.2,
                      "CBSA": 1746.6,
                      "state": 6239.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 138,
                      "state": 116
                   },
                   "error_ratio": {
                      "this": 17.9,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 5.75,
                      "CBSA": 14.15,
                      "state": 15.42
                   },
                   "error": {
                      "this": 1.91,
                      "CBSA": 0.09,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 326,
                      "CBSA": 328994,
                      "state": 3011788
                   },
                   "numerator_errors": {
                      "this": 108.7,
                      "CBSA": 2157.1,
                      "state": 8042.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 41,
                      "state": 37
                   },
                   "error_ratio": {
                      "this": 33.2,
                      "CBSA": 0.6,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 5.22,
                      "CBSA": 16.15,
                      "state": 14.57
                   },
                   "error": {
                      "this": 1.7,
                      "CBSA": 0.09,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 296,
                      "CBSA": 375656,
                      "state": 2844329
                   },
                   "numerator_errors": {
                      "this": 97,
                      "CBSA": 2007.2,
                      "state": 6637.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 32,
                      "state": 36
                   },
                   "error_ratio": {
                      "this": 32.6,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 12.85,
                      "CBSA": 14.18,
                      "state": 13.02
                   },
                   "error": {
                      "this": 1.98,
                      "CBSA": 0.09,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 729,
                      "CBSA": 329655,
                      "state": 2541977
                   },
                   "numerator_errors": {
                      "this": 115.3,
                      "CBSA": 2029.9,
                      "state": 6536.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 91,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 15.4,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 16.26,
                      "CBSA": 13.45,
                      "state": 12.65
                   },
                   "error": {
                      "this": 2.42,
                      "CBSA": 0.08,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 922,
                      "CBSA": 312888,
                      "state": 2470900
                   },
                   "numerator_errors": {
                      "this": 141.4,
                      "CBSA": 1902.7,
                      "state": 4877.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 121,
                      "state": 129
                   },
                   "error_ratio": {
                      "this": 14.9,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 13.59,
                      "CBSA": 10.64,
                      "state": 9.92
                   },
                   "error": {
                      "this": 2.45,
                      "CBSA": 0.13,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 771,
                      "CBSA": 247481,
                      "state": 1937575
                   },
                   "numerator_errors": {
                      "this": 141.8,
                      "CBSA": 2962.3,
                      "state": 8018.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 128,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 18,
                      "CBSA": 1.2,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 10.93,
                      "CBSA": 5.76,
                      "state": 5.32
                   },
                   "error": {
                      "this": 2.14,
                      "CBSA": 0.08,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 620,
                      "CBSA": 133857,
                      "state": 1038517
                   },
                   "numerator_errors": {
                      "this": 123.5,
                      "CBSA": 1835.1,
                      "state": 5256.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 190,
                      "state": 205
                   },
                   "error_ratio": {
                      "this": 19.6,
                      "CBSA": 1.4,
                      "state": 0.6
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 6.33,
                      "CBSA": 3,
                      "state": 2.77
                   },
                   "error": {
                      "this": 1.59,
                      "CBSA": 0.07,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 359,
                      "CBSA": 69655,
                      "state": 540479
                   },
                   "numerator_errors": {
                      "this": 91.2,
                      "CBSA": 1594.6,
                      "state": 4503.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 211,
                      "state": 229
                   },
                   "error_ratio": {
                      "this": 25.1,
                      "CBSA": 2.3,
                      "state": 0.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 9.26,
                      "CBSA": 10.62,
                      "state": 12.18
                   },
                   "error": {
                      "this": 2.04,
                      "CBSA": 0.08,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 535,
                      "CBSA": 252211,
                      "state": 2407173
                   },
                   "numerator_errors": {
                      "this": 119.2,
                      "CBSA": 1914.1,
                      "state": 6754.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 87,
                      "state": 76
                   },
                   "error_ratio": {
                      "this": 22,
                      "CBSA": 0.8,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 17.42,
                      "CBSA": 10.7,
                      "state": 12.72
                   },
                   "error": {
                      "this": 2.98,
                      "CBSA": 0.08,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1006,
                      "CBSA": 254106,
                      "state": 2512481
                   },
                   "numerator_errors": {
                      "this": 175.8,
                      "CBSA": 1929.4,
                      "state": 6716.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 163,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 17.1,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.67,
                      "CBSA": 13.39,
                      "state": 14.26
                   },
                   "error": {
                      "this": 2.18,
                      "CBSA": 0.09,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 385,
                      "CBSA": 318083,
                      "state": 2818271
                   },
                   "numerator_errors": {
                      "this": 126.9,
                      "CBSA": 2111.3,
                      "state": 7314.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 50,
                      "state": 47
                   },
                   "error_ratio": {
                      "this": 32.7,
                      "CBSA": 0.7,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 5.87,
                      "CBSA": 15.29,
                      "state": 13.8
                   },
                   "error": {
                      "this": 1.99,
                      "CBSA": 0.09,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 339,
                      "CBSA": 363233,
                      "state": 2726419
                   },
                   "numerator_errors": {
                      "this": 115.4,
                      "CBSA": 2173.9,
                      "state": 5775.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 38,
                      "state": 43
                   },
                   "error_ratio": {
                      "this": 33.9,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 15.55,
                      "CBSA": 13.8,
                      "state": 12.89
                   },
                   "error": {
                      "this": 2.11,
                      "CBSA": 0.09,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 898,
                      "CBSA": 327791,
                      "state": 2546168
                   },
                   "numerator_errors": {
                      "this": 126,
                      "CBSA": 2171.1,
                      "state": 5811.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 113,
                      "state": 121
                   },
                   "error_ratio": {
                      "this": 13.6,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 17.25,
                      "CBSA": 13.36,
                      "state": 12.86
                   },
                   "error": {
                      "this": 2.47,
                      "CBSA": 0.08,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 996,
                      "CBSA": 317347,
                      "state": 2540116
                   },
                   "numerator_errors": {
                      "this": 147.1,
                      "CBSA": 1813,
                      "state": 4833.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 129,
                      "state": 134
                   },
                   "error_ratio": {
                      "this": 14.3,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 12.31,
                      "CBSA": 11.37,
                      "state": 10.75
                   },
                   "error": {
                      "this": 2.47,
                      "CBSA": 0.12,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 711,
                      "CBSA": 270206,
                      "state": 2124844
                   },
                   "numerator_errors": {
                      "this": 145.1,
                      "CBSA": 2950.1,
                      "state": 8586.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 108,
                      "state": 115
                   },
                   "error_ratio": {
                      "this": 20.1,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 10.6,
                      "CBSA": 6.73,
                      "state": 6.31
                   },
                   "error": {
                      "this": 2.45,
                      "CBSA": 0.09,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 612,
                      "CBSA": 159862,
                      "state": 1247455
                   },
                   "numerator_errors": {
                      "this": 143.4,
                      "CBSA": 2073,
                      "state": 5815.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 158,
                      "state": 168
                   },
                   "error_ratio": {
                      "this": 23.1,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 5.07,
                      "CBSA": 4.75,
                      "state": 4.22
                   },
                   "error": {
                      "this": 1.48,
                      "CBSA": 0.08,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 293,
                      "CBSA": 112906,
                      "state": 834272
                   },
                   "numerator_errors": {
                      "this": 86.3,
                      "CBSA": 1882.7,
                      "state": 4970.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 120
                   },
                   "error_ratio": {
                      "this": 29.2,
                      "CBSA": 1.7,
                      "state": 0.7
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 47.9,
                   "CBSA": 39,
                   "state": 36.5
                },
                "error": {
                   "this": 1.4,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 131
                },
                "error_ratio": {
                   "this": 2.9,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 47.9,
                   "CBSA": 38,
                   "state": 35.4
                },
                "error": {
                   "this": 2.6,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 126,
                   "state": 135
                },
                "error_ratio": {
                   "this": 5.4,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 47.8,
                   "CBSA": 40,
                   "state": 37.7
                },
                "error": {
                   "this": 1.7,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 127
                },
                "error_ratio": {
                   "this": 3.6,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 49.55,
                "CBSA": 49.47,
                "state": 49.71
             },
             "error": {
                "this": 1.83,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 5672,
                "CBSA": 2325587,
                "state": 1.9526298E7
             },
             "numerator_errors": {
                "this": 210,
                "CBSA": 307,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 100
             },
             "error_ratio": {
                "this": 3.7,
                "CBSA": null,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 50.45,
                "CBSA": 50.53,
                "state": 50.29
             },
             "error": {
                "this": 1.84,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 5775,
                "CBSA": 2375745,
                "state": 1.97572E7
             },
             "numerator_errors": {
                "this": 211,
                "CBSA": 307,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 100
             },
             "error_ratio": {
                "this": 3.6,
                "CBSA": null,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 56.8,
                "CBSA": 39.6,
                "state": 37.18
             },
             "error": {
                "this": 3.05,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 6502,
                "CBSA": 1861545,
                "state": 1.4605312E7
             },
             "numerator_errors": {
                "this": 349,
                "CBSA": 971,
                "state": 3168
             },
             "index": {
                "this": 100,
                "CBSA": 143,
                "state": 153
             },
             "error_ratio": {
                "this": 5.4,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 1.08,
                "CBSA": 7.08,
                "state": 5.52
             },
             "error": {
                "this": 1.03,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 124,
                "CBSA": 333087,
                "state": 2169155
             },
             "numerator_errors": {
                "this": 118,
                "CBSA": 2460,
                "state": 6936
             },
             "index": {
                "this": 100,
                "CBSA": 15,
                "state": 20
             },
             "error_ratio": {
                "this": 95.4,
                "CBSA": 0.7,
                "state": 0.4
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0,
                "CBSA": 0.23,
                "state": 0.36
             },
             "error": {
                "this": 0,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 0,
                "CBSA": 10718,
                "state": 140831
             },
             "numerator_errors": {
                "this": 19,
                "CBSA": 712,
                "state": 2516
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 8.7,
                "state": 2.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 31.49,
                "CBSA": 25.84,
                "state": 14.28
             },
             "error": {
                "this": 3.24,
                "CBSA": 0.07,
                "state": 0.02
             },
             "numerators": {
                "this": 3605,
                "CBSA": 1214748,
                "state": 5610931
             },
             "numerator_errors": {
                "this": 371,
                "CBSA": 3362,
                "state": 9199
             },
             "index": {
                "this": 100,
                "CBSA": 122,
                "state": 221
             },
             "error_ratio": {
                "this": 10.3,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.19,
                "CBSA": 0.68,
                "state": 0.36
             },
             "error": {
                "this": 0.29,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 22,
                "CBSA": 31849,
                "state": 140788
             },
             "numerator_errors": {
                "this": 33,
                "CBSA": 816,
                "state": 2224
             },
             "index": {
                "this": 100,
                "CBSA": 28,
                "state": 53
             },
             "error_ratio": {
                "this": 152.6,
                "CBSA": 2.9,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.4,
                "CBSA": 0.38,
                "state": 0.25
             },
             "error": {
                "this": 0.38,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 46,
                "CBSA": 18047,
                "state": 100119
             },
             "numerator_errors": {
                "this": 44,
                "CBSA": 1457,
                "state": 3715
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 160
             },
             "error_ratio": {
                "this": 95,
                "CBSA": 7.9,
                "state": 4
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 5.96,
                "CBSA": 4.38,
                "state": 3.03
             },
             "error": {
                "this": 1.69,
                "CBSA": 0.09,
                "state": 0.04
             },
             "numerators": {
                "this": 682,
                "CBSA": 205932,
                "state": 1188673
             },
             "numerator_errors": {
                "this": 194,
                "CBSA": 4007,
                "state": 14463
             },
             "index": {
                "this": 100,
                "CBSA": 136,
                "state": 197
             },
             "error_ratio": {
                "this": 28.4,
                "CBSA": 2.1,
                "state": 1.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 4.07,
                "CBSA": 21.81,
                "state": 39.02
             },
             "error": {
                "this": 1.52,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 466,
                "CBSA": 1025406,
                "state": 1.5327688E7
             },
             "numerator_errors": {
                "this": 174,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 19,
                "state": 10
             },
             "error_ratio": {
                "this": 37.3,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 140843,
                "CBSA": 55252,
                "state": 36955
             },
             "error": {
                "this": 13398,
                "CBSA": 296,
                "state": 129
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 255,
                "state": 381
             },
             "error_ratio": {
                "this": 9.5,
                "CBSA": 0.5,
                "state": 0.3
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 250001,
                "CBSA": 106025,
                "state": 75235
             },
             "error": {
                "this": 0,
                "CBSA": 531,
                "state": 232
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 236,
                "state": 332
             },
             "error_ratio": {
                "this": null,
                "CBSA": 0.5,
                "state": 0.3
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 9.61,
                   "CBSA": 24.78,
                   "state": 34.35
                },
                "error": {
                   "this": 2.64,
                   "CBSA": 0.27,
                   "state": 0.1
                },
                "numerators": {
                   "this": 349,
                   "CBSA": 422081,
                   "state": 4481083
                },
                "numerator_errors": {
                   "this": 96.8,
                   "CBSA": 4784.6,
                   "state": 15330.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 39,
                   "state": 28
                },
                "error_ratio": {
                   "this": 27.5,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 5.62,
                   "CBSA": 22.69,
                   "state": 27.93
                },
                "error": {
                   "this": 1.84,
                   "CBSA": 0.23,
                   "state": 0.09
                },
                "numerators": {
                   "this": 204,
                   "CBSA": 386399,
                   "state": 3643284
                },
                "numerator_errors": {
                   "this": 67.3,
                   "CBSA": 3975.4,
                   "state": 12935.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 25,
                   "state": 20
                },
                "error_ratio": {
                   "this": 32.7,
                   "CBSA": 1,
                   "state": 0.3
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 22.63,
                   "CBSA": 29.57,
                   "state": 25.56
                },
                "error": {
                   "this": 4.14,
                   "CBSA": 0.26,
                   "state": 0.1
                },
                "numerators": {
                   "this": 822,
                   "CBSA": 503605,
                   "state": 3333848
                },
                "numerator_errors": {
                   "this": 153.9,
                   "CBSA": 4649.1,
                   "state": 14018.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 77,
                   "state": 89
                },
                "error_ratio": {
                   "this": 18.3,
                   "CBSA": 0.9,
                   "state": 0.4
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 62.15,
                   "CBSA": 22.97,
                   "state": 12.16
                },
                "error": {
                   "this": 3.56,
                   "CBSA": 0.22,
                   "state": 0.08
                },
                "numerators": {
                   "this": 2258,
                   "CBSA": 391190,
                   "state": 1586051
                },
                "numerator_errors": {
                   "this": 157,
                   "CBSA": 3780,
                   "state": 10523
                },
                "index": {
                   "this": 100,
                   "CBSA": 271,
                   "state": 511
                },
                "error_ratio": {
                   "this": 5.7,
                   "CBSA": 1,
                   "state": 0.7
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 2.42,
                "CBSA": 9.02,
                "state": 13.36
             },
             "error": {
                "this": 1.27,
                "CBSA": 0.16,
                "state": 0.1
             },
             "numerators": {
                "this": 276,
                "CBSA": 418640,
                "state": 5149742
             },
             "numerator_errors": {
                "this": 145,
                "CBSA": 7321,
                "state": 38666
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 27,
                "state": 18
             },
             "error_ratio": {
                "this": 52.5,
                "CBSA": 1.8,
                "state": 0.7
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 1.48,
                   "CBSA": 10.18,
                   "state": 18.13
                },
                "error": {
                   "this": 2.21,
                   "CBSA": 0.27,
                   "state": 0.12
                },
                "numerators": {
                   "this": 42,
                   "CBSA": 93859,
                   "state": 1610923
                },
                "numerator_errors": {
                   "this": 62.9,
                   "CBSA": 2557.7,
                   "state": 10973.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 8
                },
                "error_ratio": {
                   "this": 149.3,
                   "CBSA": 2.7,
                   "state": 0.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 98.52,
                   "CBSA": 89.82,
                   "state": 81.87
                },
                "error": {
                   "this": 13.21,
                   "CBSA": 0.7,
                   "state": 0
                },
                "numerators": {
                   "this": 2798,
                   "CBSA": 828299,
                   "state": 7275254
                },
                "numerator_errors": {
                   "this": 263.6,
                   "CBSA": 4500.8,
                   "state": 15647.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 120
                },
                "error_ratio": {
                   "this": 13.4,
                   "CBSA": 0.8,
                   "state": null
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 0.84,
                   "CBSA": 8.7,
                   "state": 10.23
                },
                "error": {
                   "this": 1.38,
                   "CBSA": 0.24,
                   "state": 0.1
                },
                "numerators": {
                   "this": 21,
                   "CBSA": 60893,
                   "state": 551219
                },
                "numerator_errors": {
                   "this": 34.7,
                   "CBSA": 1659.5,
                   "state": 5245
                },
                "index": {
                   "this": 100,
                   "CBSA": 10,
                   "state": 8
                },
                "error_ratio": {
                   "this": 164.3,
                   "CBSA": 2.8,
                   "state": 1
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 99.16,
                   "CBSA": 91.3,
                   "state": 89.77
                },
                "error": {
                   "this": 13.42,
                   "CBSA": 0.42,
                   "state": 0.16
                },
                "numerators": {
                   "this": 2484,
                   "CBSA": 639117,
                   "state": 4839112
                },
                "numerator_errors": {
                   "this": 237.4,
                   "CBSA": 1861.2,
                   "state": 5421.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 110
                },
                "error_ratio": {
                   "this": 13.5,
                   "CBSA": 0.5,
                   "state": 0.2
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 31.84,
                "CBSA": 34.26,
                "state": 29.79
             },
             "error": {
                "this": 2.7,
                "CBSA": 0.14,
                "state": 0.07
             },
             "numerators": {
                "this": 131155,
                "CBSA": 7.5895432E7,
                "state": 5.099704E8
             },
             "numerator_errors": {
                "this": 15590,
                "CBSA": 426975,
                "state": 1354876
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 107
             },
             "error_ratio": {
                "this": 8.5,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 71.62,
                   "CBSA": 57.56,
                   "state": 73.72
                },
                "error": {
                   "this": 3.22,
                   "CBSA": 0.27,
                   "state": 0.04
                },
                "numerators": {
                   "this": 3392,
                   "CBSA": 1365800,
                   "state": 1.3411041E7
                },
                "numerator_errors": {
                   "this": 274,
                   "CBSA": 7921,
                   "state": 17348
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 97
                },
                "error_ratio": {
                   "this": 4.5,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 7.47,
                   "CBSA": 9.53,
                   "state": 10.12
                },
                "error": {
                   "this": 2.63,
                   "CBSA": 0.17,
                   "state": 0.09
                },
                "numerators": {
                   "this": 354,
                   "CBSA": 226167,
                   "state": 1841632
                },
                "numerator_errors": {
                   "this": 127,
                   "CBSA": 4187,
                   "state": 16674
                },
                "index": {
                   "this": 100,
                   "CBSA": 78,
                   "state": 74
                },
                "error_ratio": {
                   "this": 35.2,
                   "CBSA": 1.8,
                   "state": 0.9
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 5.09,
                   "CBSA": 17.61,
                   "state": 5.08
                },
                "error": {
                   "this": 1.57,
                   "CBSA": 0.19,
                   "state": 0.04
                },
                "numerators": {
                   "this": 241,
                   "CBSA": 417810,
                   "state": 923834
                },
                "numerator_errors": {
                   "this": 76,
                   "CBSA": 4652,
                   "state": 7563
                },
                "index": {
                   "this": 100,
                   "CBSA": 29,
                   "state": 100
                },
                "error_ratio": {
                   "this": 30.8,
                   "CBSA": 1.1,
                   "state": 0.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.25,
                   "CBSA": 1.91,
                   "state": 0.95
                },
                "error": {
                   "this": 0.42,
                   "CBSA": 0.07,
                   "state": 0.02
                },
                "numerators": {
                   "this": 12,
                   "CBSA": 45243,
                   "state": 173081
                },
                "numerator_errors": {
                   "this": 20,
                   "CBSA": 1647,
                   "state": 3177
                },
                "index": {
                   "this": 100,
                   "CBSA": 13,
                   "state": 26
                },
                "error_ratio": {
                   "this": 168,
                   "CBSA": 3.7,
                   "state": 2.1
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 0.11,
                   "CBSA": 4.69,
                   "state": 2.62
                },
                "error": {
                   "this": 0.17,
                   "CBSA": 0.13,
                   "state": 0.03
                },
                "numerators": {
                   "this": 5,
                   "CBSA": 111365,
                   "state": 476291
                },
                "numerator_errors": {
                   "this": 8,
                   "CBSA": 3026,
                   "state": 5234
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": 4
                },
                "error_ratio": {
                   "this": 154.5,
                   "CBSA": 2.8,
                   "state": 1.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.43,
                   "CBSA": 2.06,
                   "state": 1.6
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 0.08,
                   "state": 0.02
                },
                "numerators": {
                   "this": 115,
                   "CBSA": 48944,
                   "state": 291710
                },
                "numerator_errors": {
                   "this": 65,
                   "CBSA": 2007,
                   "state": 4364
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 152
                },
                "error_ratio": {
                   "this": 56,
                   "CBSA": 3.9,
                   "state": 1.2
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 13.03,
                   "CBSA": 6.65,
                   "state": 5.9
                },
                "error": {
                   "this": 2.56,
                   "CBSA": 0.12,
                   "state": 0.05
                },
                "numerators": {
                   "this": 617,
                   "CBSA": 157692,
                   "state": 1073966
                },
                "numerator_errors": {
                   "this": 128,
                   "CBSA": 2857,
                   "state": 8330
                },
                "index": {
                   "this": 100,
                   "CBSA": 196,
                   "state": 221
                },
                "error_ratio": {
                   "this": 19.6,
                   "CBSA": 1.8,
                   "state": 0.8
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 70.52,
                "CBSA": 50.25,
                "state": 48.86
             },
             "error": {
                "this": 1.75,
                "CBSA": 0.19,
                "state": 0.11
             },
             "numerators": {
                "this": 6463,
                "CBSA": 1970200,
                "state": 1.5532639E7
             },
             "numerator_errors": {
                "this": 208.7,
                "CBSA": 7366.2,
                "state": 35143.9
             },
             "index": {
                "this": 100,
                "CBSA": 140,
                "state": 144
             },
             "error_ratio": {
                "this": 2.5,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 29.48,
                "CBSA": 49.75,
                "state": 51.14
             },
             "error": {
                "this": 3.3,
                "CBSA": 0.2,
                "state": 0.1
             },
             "numerators": {
                "this": 2702,
                "CBSA": 1950940,
                "state": 1.6255641E7
             },
             "numerator_errors": {
                "this": 307.5,
                "CBSA": 7807.4,
                "state": 32012.1
             },
             "index": {
                "this": 100,
                "CBSA": 59,
                "state": 58
             },
             "error_ratio": {
                "this": 11.2,
                "CBSA": 0.4,
                "state": 0.2
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 18.09,
                   "CBSA": 39.35,
                   "state": 40.42
                },
                "error": {
                   "this": 4,
                   "CBSA": 0.23,
                   "state": 0.14
                },
                "numerators": {
                   "this": 790,
                   "CBSA": 758354,
                   "state": 6343459
                },
                "numerator_errors": {
                   "this": 177,
                   "CBSA": 4434,
                   "state": 22441
                },
                "index": {
                   "this": 100,
                   "CBSA": 46,
                   "state": 45
                },
                "error_ratio": {
                   "this": 22.1,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 22.91,
                   "CBSA": 33.31,
                   "state": 33.49
                },
                "error": {
                   "this": 3.91,
                   "CBSA": 0.23,
                   "state": 0.11
                },
                "numerators": {
                   "this": 1099,
                   "CBSA": 664257,
                   "state": 5389443
                },
                "numerator_errors": {
                   "this": 193,
                   "CBSA": 4586,
                   "state": 17597
                },
                "index": {
                   "this": 100,
                   "CBSA": 69,
                   "state": 68
                },
                "error_ratio": {
                   "this": 17.1,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 74.95,
                   "CBSA": 51.69,
                   "state": 49.8
                },
                "error": {
                   "this": 2.28,
                   "CBSA": 0.28,
                   "state": 0.17
                },
                "numerators": {
                   "this": 3274,
                   "CBSA": 996209,
                   "state": 7816684
                },
                "numerator_errors": {
                   "this": 153,
                   "CBSA": 5387,
                   "state": 27451
                },
                "index": {
                   "this": 100,
                   "CBSA": 145,
                   "state": 151
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 66.48,
                   "CBSA": 48.85,
                   "state": 47.95
                },
                "error": {
                   "this": 1.08,
                   "CBSA": 0.25,
                   "state": 0.14
                },
                "numerators": {
                   "this": 3189,
                   "CBSA": 973991,
                   "state": 7715955
                },
                "numerator_errors": {
                   "this": 142,
                   "CBSA": 5024,
                   "state": 21944
                },
                "index": {
                   "this": 100,
                   "CBSA": 136,
                   "state": 139
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 4.03,
                   "CBSA": 7.02,
                   "state": 7.65
                },
                "error": {
                   "this": 1.96,
                   "CBSA": 0.13,
                   "state": 0.06
                },
                "numerators": {
                   "this": 176,
                   "CBSA": 135241,
                   "state": 1200982
                },
                "numerator_errors": {
                   "this": 86,
                   "CBSA": 2521,
                   "state": 9397
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 53
                },
                "error_ratio": {
                   "this": 48.6,
                   "CBSA": 1.9,
                   "state": 0.8
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 4.94,
                   "CBSA": 10.43,
                   "state": 10.85
                },
                "error": {
                   "this": 1.74,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 237,
                   "CBSA": 207906,
                   "state": 1746282
                },
                "numerator_errors": {
                   "this": 84,
                   "CBSA": 2835,
                   "state": 8613
                },
                "index": {
                   "this": 100,
                   "CBSA": 47,
                   "state": 46
                },
                "error_ratio": {
                   "this": 35.2,
                   "CBSA": 1.3,
                   "state": 0.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 2.93,
                   "CBSA": 1.94,
                   "state": 2.13
                },
                "error": {
                   "this": 1.44,
                   "CBSA": 0.06,
                   "state": 0.02
                },
                "numerators": {
                   "this": 128,
                   "CBSA": 37321,
                   "state": 334237
                },
                "numerator_errors": {
                   "this": 63,
                   "CBSA": 1155,
                   "state": 3638
                },
                "index": {
                   "this": 100,
                   "CBSA": 151,
                   "state": 138
                },
                "error_ratio": {
                   "this": 49.1,
                   "CBSA": 3.1,
                   "state": 0.9
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 5.67,
                   "CBSA": 7.42,
                   "state": 7.71
                },
                "error": {
                   "this": 1.8,
                   "CBSA": 0.11,
                   "state": 0.04
                },
                "numerators": {
                   "this": 272,
                   "CBSA": 147861,
                   "state": 1241238
                },
                "numerator_errors": {
                   "this": 87,
                   "CBSA": 2130,
                   "state": 5983
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 74
                },
                "error_ratio": {
                   "this": 31.7,
                   "CBSA": 1.5,
                   "state": 0.5
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 94.67,
                   "CBSA": 78.76,
                   "state": 72.23
                },
                "error": {
                   "this": 3.46,
                   "CBSA": 0.42,
                   "state": 0.27
                },
                "numerators": {
                   "this": 2575,
                   "CBSA": 652636,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 180,
                   "CBSA": 4069,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 120,
                   "state": 131
                },
                "error_ratio": {
                   "this": 3.7,
                   "CBSA": 0.5,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 1.65,
                   "CBSA": 5.35,
                   "state": 7.67
                },
                "error": {
                   "this": 1.58,
                   "CBSA": 0.26,
                   "state": 0.11
                },
                "numerators": {
                   "this": 45,
                   "CBSA": 44369,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 43,
                   "CBSA": 2181,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 31,
                   "state": 22
                },
                "error_ratio": {
                   "this": 95.8,
                   "CBSA": 4.9,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 3.68,
                   "CBSA": 15.88,
                   "state": 20.1
                },
                "error": {
                   "this": 2.19,
                   "CBSA": 0.36,
                   "state": 0.21
                },
                "numerators": {
                   "this": 100,
                   "CBSA": 131584,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 60,
                   "CBSA": 3049,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 23,
                   "state": 18
                },
                "error_ratio": {
                   "this": 59.5,
                   "CBSA": 2.3,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 2.35,
                "CBSA": 4.44,
                "state": 4.88
             },
             "error": {
                "this": 1.72,
                "CBSA": 0.13,
                "state": 0.06
             },
             "numerators": {
                "this": 53,
                "CBSA": 51849,
                "state": 469884
             },
             "numerator_errors": {
                "this": 39,
                "CBSA": 1524,
                "state": 5597
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 53,
                "state": 48
             },
             "error_ratio": {
                "this": 73.2,
                "CBSA": 2.9,
                "state": 1.2
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.34,
                   "state": 1.01
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.07
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 422,
                   "state": 12727
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 135,
                   "state": 844
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 32.4,
                   "state": 6.9
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 3.03,
                   "state": 4.98
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.38,
                   "state": 0.17
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4063,
                   "state": 66276
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 516,
                   "state": 2263
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 12.5,
                   "state": 3.4
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 0,
                   "CBSA": 5.17,
                   "state": 7.77
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.37,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 9502,
                   "state": 115600
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 690,
                   "state": 2442
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 7.2,
                   "state": 2.1
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 19.67,
                   "CBSA": 9.21,
                   "state": 9.88
                },
                "error": {
                   "this": 20.24,
                   "CBSA": 0.44,
                   "state": 0.2
                },
                "numerators": {
                   "this": 24,
                   "CBSA": 17478,
                   "state": 138843
                },
                "numerator_errors": {
                   "this": 28,
                   "CBSA": 847,
                   "state": 2879
                },
                "index": {
                   "this": 100,
                   "CBSA": 214,
                   "state": 199
                },
                "error_ratio": {
                   "this": 102.9,
                   "CBSA": 4.8,
                   "state": 2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 8.29,
                   "CBSA": 7.97,
                   "state": 7.09
                },
                "error": {
                   "this": 8.98,
                   "CBSA": 0.47,
                   "state": 0.16
                },
                "numerators": {
                   "this": 18,
                   "CBSA": 13817,
                   "state": 93691
                },
                "numerator_errors": {
                   "this": 21,
                   "CBSA": 835,
                   "state": 2137
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 117
                },
                "error_ratio": {
                   "this": 108.3,
                   "CBSA": 5.9,
                   "state": 2.3
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 3.2,
                   "CBSA": 2.95,
                   "state": 2.43
                },
                "error": {
                   "this": 4.89,
                   "CBSA": 0.28,
                   "state": 0.1
                },
                "numerators": {
                   "this": 11,
                   "CBSA": 4846,
                   "state": 30695
                },
                "numerator_errors": {
                   "this": 17,
                   "CBSA": 463,
                   "state": 1315
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 132
                },
                "error_ratio": {
                   "this": 152.8,
                   "CBSA": 9.5,
                   "state": 4.1
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0,
                   "CBSA": 0.87,
                   "state": 0.77
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.12,
                   "state": 0.06
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1721,
                   "state": 12052
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 239,
                   "state": 861
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 13.8,
                   "state": 7.8
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 3633,
                "CBSA": 1703275,
                "state": 1.3044266E7
             },
             "error": {
                "this": 143,
                "CBSA": 3959,
                "state": 20333
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": 3.9,
                "CBSA": 0.2,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 3.15,
                "CBSA": 2.71,
                "state": 2.95
             },
             "error": {
                "this": 0.12,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 11447,
                "CBSA": 4621986,
                "state": 3.8462236E7
             },
             "numerator_errors": {
                "this": 30,
                "CBSA": 2408,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 116,
                "state": 107
             },
             "error_ratio": {
                "this": 3.8,
                "CBSA": 0.4,
                "state": null
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 88.42,
                   "CBSA": 61.62,
                   "state": 61.04
                },
                "error": {
                   "this": 2.8,
                   "CBSA": 0.31,
                   "state": 0.21
                },
                "numerators": {
                   "this": 10121,
                   "CBSA": 2848170,
                   "state": 2.3478016E7
                },
                "numerator_errors": {
                   "this": 322,
                   "CBSA": 14203,
                   "state": 81110
                },
                "index": {
                   "this": 100,
                   "CBSA": 143,
                   "state": 145
                },
                "error_ratio": {
                   "this": 3.2,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 3.15,
                   "CBSA": 5.86,
                   "state": 7.59
                },
                "error": {
                   "this": 1.9,
                   "CBSA": 0.16,
                   "state": 0.09
                },
                "numerators": {
                   "this": 361,
                   "CBSA": 270774,
                   "state": 2919408
                },
                "numerator_errors": {
                   "this": 217,
                   "CBSA": 7230,
                   "state": 33493
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 42
                },
                "error_ratio": {
                   "this": 60.3,
                   "CBSA": 2.7,
                   "state": 1.2
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 3.93,
                   "CBSA": 13.44,
                   "state": 16.57
                },
                "error": {
                   "this": 1.71,
                   "CBSA": 0.23,
                   "state": 0.12
                },
                "numerators": {
                   "this": 450,
                   "CBSA": 621104,
                   "state": 6373007
                },
                "numerator_errors": {
                   "this": 196,
                   "CBSA": 10609,
                   "state": 46993
                },
                "index": {
                   "this": 100,
                   "CBSA": 29,
                   "state": 24
                },
                "error_ratio": {
                   "this": 43.5,
                   "CBSA": 1.7,
                   "state": 0.7
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 4.5,
                   "CBSA": 19.08,
                   "state": 14.8
                },
                "error": {
                   "this": 1.15,
                   "CBSA": 0.17,
                   "state": 0.07
                },
                "numerators": {
                   "this": 515,
                   "CBSA": 881938,
                   "state": 5691804
                },
                "numerator_errors": {
                   "this": 132,
                   "CBSA": 7855,
                   "state": 27482
                },
                "index": {
                   "this": 100,
                   "CBSA": 24,
                   "state": 30
                },
                "error_ratio": {
                   "this": 25.6,
                   "CBSA": 0.9,
                   "state": 0.5
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 4031,
                "CBSA": 1810484,
                "state": 1.4175976E7
             },
             "error": {
                "this": 166,
                "CBSA": 1013,
                "state": 1184
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": 4.1,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 90.13,
                   "CBSA": 94.08,
                   "state": 92.02
                },
                "error": {
                   "this": 5.13,
                   "CBSA": 0.21,
                   "state": 0.14
                },
                "numerators": {
                   "this": 3633,
                   "CBSA": 1703275,
                   "state": 1.3044266E7
                },
                "numerator_errors": {
                   "this": 143,
                   "CBSA": 3959,
                   "state": 20333
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 98
                },
                "error_ratio": {
                   "this": 5.7,
                   "CBSA": 0.2,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 9.87,
                   "CBSA": 5.92,
                   "state": 7.98
                },
                "error": {
                   "this": 3.02,
                   "CBSA": 0.22,
                   "state": 0.15
                },
                "numerators": {
                   "this": 398,
                   "CBSA": 107209,
                   "state": 1131710
                },
                "numerator_errors": {
                   "this": 123,
                   "CBSA": 3933,
                   "state": 20951
                },
                "index": {
                   "this": 100,
                   "CBSA": 167,
                   "state": 124
                },
                "error_ratio": {
                   "this": 30.6,
                   "CBSA": 3.7,
                   "state": 1.9
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 98.73,
                   "CBSA": 58.77,
                   "state": 64.78
                },
                "error": {
                   "this": 0.38,
                   "CBSA": 0.22,
                   "state": 0.09
                },
                "numerators": {
                   "this": 3980,
                   "CBSA": 1064031,
                   "state": 9183849
                },
                "numerator_errors": {
                   "this": 164.6,
                   "CBSA": 3984.4,
                   "state": 13345.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 168,
                   "state": 152
                },
                "error_ratio": {
                   "this": 0.4,
                   "CBSA": 0.4,
                   "state": 0.1
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 0.92,
                   "CBSA": 40.07,
                   "state": 31.43
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 0.3,
                   "state": 0.11
                },
                "numerators": {
                   "this": 37,
                   "CBSA": 725513,
                   "state": 4455831
                },
                "numerator_errors": {
                   "this": 53.9,
                   "CBSA": 5384.7,
                   "state": 15037
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": 3
                },
                "error_ratio": {
                   "this": 145.7,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.35,
                   "CBSA": 1.07,
                   "state": 3.68
                },
                "error": {
                   "this": 0.5,
                   "CBSA": 0.05,
                   "state": 0.03
                },
                "numerators": {
                   "this": 14,
                   "CBSA": 19396,
                   "state": 521135
                },
                "numerator_errors": {
                   "this": 20,
                   "CBSA": 908,
                   "state": 4740
                },
                "index": {
                   "this": 100,
                   "CBSA": 33,
                   "state": 10
                },
                "error_ratio": {
                   "this": 142.9,
                   "CBSA": 4.7,
                   "state": 0.8
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.09,
                   "state": 0.11
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.02,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1544,
                   "state": 15161
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 274,
                   "state": 862
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 22.2,
                   "state": 9.1
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 93.28,
                   "CBSA": 54.66,
                   "state": 54.85
                },
                "error": {
                   "this": 2.53,
                   "CBSA": 0.3,
                   "state": 0.28
                },
                "numerators": {
                   "this": 3389,
                   "CBSA": 931032,
                   "state": 7154580
                },
                "numerator_errors": {
                   "this": 162,
                   "CBSA": 5500,
                   "state": 37872
                },
                "index": {
                   "this": 100,
                   "CBSA": 171,
                   "state": 170
                },
                "error_ratio": {
                   "this": 2.7,
                   "CBSA": 0.5,
                   "state": 0.5
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 6.72,
                   "CBSA": 45.34,
                   "state": 45.15
                },
                "error": {
                   "this": 2.19,
                   "CBSA": 0.23,
                   "state": 0.15
                },
                "numerators": {
                   "this": 244,
                   "CBSA": 772243,
                   "state": 5889686
                },
                "numerator_errors": {
                   "this": 80,
                   "CBSA": 4238,
                   "state": 21311
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 15
                },
                "error_ratio": {
                   "this": 32.6,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 2000001,
                "CBSA": 840600,
                "state": 505000
             },
             "error": {
                "this": 0,
                "CBSA": 3101,
                "state": 1147
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 238,
                "state": 396
             },
             "error_ratio": {
                "this": null,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 0.53,
                   "CBSA": 2.16,
                   "state": 5.03
                },
                "error": {
                   "this": 1.96,
                   "CBSA": 0.1,
                   "state": 0.05
                },
                "numerators": {
                   "this": 18,
                   "CBSA": 20156,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 66.6,
                   "CBSA": 908.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 25,
                   "state": 11
                },
                "error_ratio": {
                   "this": 369.8,
                   "CBSA": 4.6,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 0.41,
                   "CBSA": 1.61,
                   "state": 6.63
                },
                "error": {
                   "this": 1.14,
                   "CBSA": 0.09,
                   "state": 0.06
                },
                "numerators": {
                   "this": 14,
                   "CBSA": 15036,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 38.5,
                   "CBSA": 818.1,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 25,
                   "state": 6
                },
                "error_ratio": {
                   "this": 278,
                   "CBSA": 5.6,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 0.27,
                   "CBSA": 2.81,
                   "state": 11.06
                },
                "error": {
                   "this": 0.71,
                   "CBSA": 0.11,
                   "state": 0.06
                },
                "numerators": {
                   "this": 9,
                   "CBSA": 26184,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 24.2,
                   "CBSA": 1029.9,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 10,
                   "state": 2
                },
                "error_ratio": {
                   "this": 263,
                   "CBSA": 3.9,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 0.83,
                   "CBSA": 5.66,
                   "state": 13.6
                },
                "error": {
                   "this": 0.77,
                   "CBSA": 0.14,
                   "state": 0.09
                },
                "numerators": {
                   "this": 28,
                   "CBSA": 52681,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 26,
                   "CBSA": 1347,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 6
                },
                "error_ratio": {
                   "this": 92.8,
                   "CBSA": 2.5,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 0,
                   "CBSA": 7.56,
                   "state": 13.21
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.19,
                   "state": 0.08
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 70432,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 1773,
                   "state": 7694
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 2.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 2.07,
                   "CBSA": 43.94,
                   "state": 35.28
                },
                "error": {
                   "this": 1.32,
                   "CBSA": 0.29,
                   "state": 0.26
                },
                "numerators": {
                   "this": 70,
                   "CBSA": 409063,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 44.7,
                   "CBSA": 3594.1,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": 6
                },
                "error_ratio": {
                   "this": 63.8,
                   "CBSA": 0.7,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 0,
                   "CBSA": 19.26,
                   "state": 7.93
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.23,
                   "state": 0.04
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 179279,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 19,
                   "CBSA": 2390,
                   "state": 4302
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 1.2,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 3389,
                "CBSA": 931032,
                "state": 7154580
             },
             "error": {
                "this": 162,
                "CBSA": 5500,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": 4.8,
                "CBSA": 0.6,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 5-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 18.14,
                "CBSA": 11.62,
                "state": 9.72
             },
             "error": {
                "this": 2.77,
                "CBSA": 0.17,
                "state": 0.06
             },
             "numerators": {
                "this": 2077,
                "CBSA": 536918,
                "state": 3739275
             },
             "numerator_errors": {
                "this": 317,
                "CBSA": 7928.2,
                "state": 21932.9
             },
             "index": {
                "this": 100,
                "CBSA": 156,
                "state": 187
             },
             "error_ratio": {
                "this": 15.3,
                "CBSA": 1.5,
                "state": 0.6
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 16.49,
                "CBSA": 12.28,
                "state": 12.25
             },
             "error": {
                "this": 3.22,
                "CBSA": 0.19,
                "state": 0.08
             },
             "numerators": {
                "this": 1888,
                "CBSA": 567596,
                "state": 4711644
             },
             "numerator_errors": {
                "this": 368.5,
                "CBSA": 8970,
                "state": 30793.4
             },
             "index": {
                "this": 100,
                "CBSA": 134,
                "state": 135
             },
             "error_ratio": {
                "this": 19.5,
                "CBSA": 1.5,
                "state": 0.7
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 31.2,
                "CBSA": 25.39,
                "state": 25.7
             },
             "error": {
                "this": 5.28,
                "CBSA": 0.28,
                "state": 0.13
             },
             "numerators": {
                "this": 3572,
                "CBSA": 1173309,
                "state": 9884339
             },
             "numerator_errors": {
                "this": 604.3,
                "CBSA": 12859.2,
                "state": 50780.8
             },
             "index": {
                "this": 100,
                "CBSA": 123,
                "state": 121
             },
             "error_ratio": {
                "this": 16.9,
                "CBSA": 1.1,
                "state": 0.5
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 20.33,
                "CBSA": 28.34,
                "state": 28.78
             },
             "error": {
                "this": 4.39,
                "CBSA": 0.31,
                "state": 0.16
             },
             "numerators": {
                "this": 2327,
                "CBSA": 1309646,
                "state": 1.1067761E7
             },
             "numerator_errors": {
                "this": 502.4,
                "CBSA": 14501.5,
                "state": 60057.7
             },
             "index": {
                "this": 100,
                "CBSA": 72,
                "state": 71
             },
             "error_ratio": {
                "this": 21.6,
                "CBSA": 1.1,
                "state": 0.6
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 9.36,
                "CBSA": 13.43,
                "state": 14.48
             },
             "error": {
                "this": 2.96,
                "CBSA": 0.24,
                "state": 0.11
             },
             "numerators": {
                "this": 1071,
                "CBSA": 620882,
                "state": 5568721
             },
             "numerator_errors": {
                "this": 338.6,
                "CBSA": 11050.1,
                "state": 43280.9
             },
             "index": {
                "this": 100,
                "CBSA": 70,
                "state": 65
             },
             "error_ratio": {
                "this": 31.6,
                "CBSA": 1.8,
                "state": 0.8
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 4.47,
                "CBSA": 8.95,
                "state": 9.08
             },
             "error": {
                "this": 2.13,
                "CBSA": 0.16,
                "state": 0.09
             },
             "numerators": {
                "this": 512,
                "CBSA": 413635,
                "state": 3490495
             },
             "numerator_errors": {
                "this": 244.3,
                "CBSA": 7594.6,
                "state": 33565.8
             },
             "index": {
                "this": 100,
                "CBSA": 50,
                "state": 49
             },
             "error_ratio": {
                "this": 47.7,
                "CBSA": 1.8,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 7.08,
                "CBSA": 12.92,
                "state": 12.86
             },
             "error": {
                "this": 2.42,
                "CBSA": 0.17,
                "state": 0.08
             },
             "numerators": {
                "this": 801,
                "CBSA": 601348,
                "state": 4993305
             },
             "numerator_errors": {
                "this": 273.8,
                "CBSA": 7904.4,
                "state": 29689.5
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 55,
                "state": 55
             },
             "error_ratio": {
                "this": 34.2,
                "CBSA": 1.3,
                "state": 0.6
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 5-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 92.92,
                "CBSA": 87.08,
                "state": 87.14
             },
             "error": {
                "this": 2.25,
                "CBSA": 0.18,
                "state": 0.07
             },
             "numerators": {
                "this": 10514,
                "CBSA": 4051437,
                "state": 3.3839688E7
             },
             "numerator_errors": {
                "this": 278,
                "CBSA": 8662,
                "state": 27490
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 107
             },
             "error_ratio": {
                "this": 2.4,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 3.76,
                "CBSA": 6.33,
                "state": 8.01
             },
             "error": {
                "this": 1.86,
                "CBSA": 0.12,
                "state": 0.07
             },
             "numerators": {
                "this": 425,
                "CBSA": 294732,
                "state": 3108823
             },
             "numerator_errors": {
                "this": 211,
                "CBSA": 5689,
                "state": 25604
             },
             "index": {
                "this": 100,
                "CBSA": 59,
                "state": 47
             },
             "error_ratio": {
                "this": 49.5,
                "CBSA": 1.9,
                "state": 0.9
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 1.61,
                "CBSA": 3.92,
                "state": 2.75
             },
             "error": {
                "this": 0.98,
                "CBSA": 0.09,
                "state": 0.03
             },
             "numerators": {
                "this": 182,
                "CBSA": 182221,
                "state": 1069429
             },
             "numerator_errors": {
                "this": 111,
                "CBSA": 3976,
                "state": 11456
             },
             "index": {
                "this": 100,
                "CBSA": 41,
                "state": 59
             },
             "error_ratio": {
                "this": 60.9,
                "CBSA": 2.3,
                "state": 1.1
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 0.95,
                "CBSA": 1.5,
                "state": 1.3
             },
             "error": {
                "this": 0.88,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 108,
                "CBSA": 69611,
                "state": 503560
             },
             "numerator_errors": {
                "this": 100,
                "CBSA": 2727,
                "state": 8001
             },
             "index": {
                "this": 100,
                "CBSA": 63,
                "state": 73
             },
             "error_ratio": {
                "this": 92.6,
                "CBSA": 4,
                "state": 1.5
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 0.76,
                "CBSA": 1.18,
                "state": 0.8
             },
             "error": {
                "this": 0.8,
                "CBSA": 0.06,
                "state": 0.01
             },
             "numerators": {
                "this": 86,
                "CBSA": 54784,
                "state": 311493
             },
             "numerator_errors": {
                "this": 90,
                "CBSA": 2621,
                "state": 5536
             },
             "index": {
                "this": 100,
                "CBSA": 64,
                "state": 95
             },
             "error_ratio": {
                "this": 105.3,
                "CBSA": 5.1,
                "state": 1.2
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 96.51,
                "CBSA": 89.14,
                "state": 83.31
             },
             "error": {
                "this": 5.18,
                "CBSA": 0.33,
                "state": 0.15
             },
             "numerators": {
                "this": 7467,
                "CBSA": 3028069,
                "state": 2.2052868E7
             },
             "numerator_errors": {
                "this": 440.6,
                "CBSA": 11285.7,
                "state": 39734.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 108,
                "state": 116
             },
             "error_ratio": {
                "this": 5.4,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 78.95,
                "CBSA": 49.68,
                "state": 33.93
             },
             "error": {
                "this": 4.62,
                "CBSA": 0.22,
                "state": 0.09
             },
             "numerators": {
                "this": 6108,
                "CBSA": 1687542,
                "state": 8980726
             },
             "numerator_errors": {
                "this": 387.2,
                "CBSA": 7543.2,
                "state": 23065.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 159,
                "state": 233
             },
             "error_ratio": {
                "this": 5.9,
                "CBSA": 0.4,
                "state": 0.3
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 3.49,
                "CBSA": 10.86,
                "state": 16.69
             },
             "error": {
                "this": 1.38,
                "CBSA": 0.14,
                "state": 0.07
             },
             "numerators": {
                "this": 270,
                "CBSA": 368794,
                "state": 4418675
             },
             "numerator_errors": {
                "this": 107.3,
                "CBSA": 4809.6,
                "state": 18934.1
             },
             "index": {
                "this": 100,
                "CBSA": 32,
                "state": 21
             },
             "error_ratio": {
                "this": 39.5,
                "CBSA": 1.3,
                "state": 0.4
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 6.41,
                "CBSA": 15.53,
                "state": 20.49
             },
             "error": {
                "this": 1.85,
                "CBSA": 0.16,
                "state": 0.08
             },
             "numerators": {
                "this": 496,
                "CBSA": 527527,
                "state": 5423462
             },
             "numerator_errors": {
                "this": 144,
                "CBSA": 5290.9,
                "state": 22237.1
             },
             "index": {
                "this": 100,
                "CBSA": 41,
                "state": 31
             },
             "error_ratio": {
                "this": 28.9,
                "CBSA": 1,
                "state": 0.4
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 11.15,
                "CBSA": 23.93,
                "state": 28.89
             },
             "error": {
                "this": 1.96,
                "CBSA": 0.19,
                "state": 0.09
             },
             "numerators": {
                "this": 863,
                "CBSA": 813000,
                "state": 7648680
             },
             "numerator_errors": {
                "this": 153.3,
                "CBSA": 6517.2,
                "state": 23501.3
             },
             "index": {
                "this": 100,
                "CBSA": 47,
                "state": 39
             },
             "error_ratio": {
                "this": 17.6,
                "CBSA": 0.8,
                "state": 0.3
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 34.99,
                "CBSA": 29.25,
                "state": 21.17
             },
             "error": {
                "this": 3.1,
                "CBSA": 0.17,
                "state": 0.07
             },
             "numerators": {
                "this": 2707,
                "CBSA": 993618,
                "state": 5603047
             },
             "numerator_errors": {
                "this": 248.5,
                "CBSA": 5612.3,
                "state": 17216.6
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 165
             },
             "error_ratio": {
                "this": 8.9,
                "CBSA": 0.6,
                "state": 0.3
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 43.96,
                "CBSA": 20.43,
                "state": 12.76
             },
             "error": {
                "this": 3.68,
                "CBSA": 0.15,
                "state": 0.06
             },
             "numerators": {
                "this": 3401,
                "CBSA": 693924,
                "state": 3377679
             },
             "numerator_errors": {
                "this": 296.9,
                "CBSA": 5040,
                "state": 15348.8
             },
             "index": {
                "this": 100,
                "CBSA": 215,
                "state": 345
             },
             "error_ratio": {
                "this": 8.4,
                "CBSA": 0.7,
                "state": 0.5
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 24.2,
                "CBSA": 30.7,
                "state": 26.85
             },
             "error": {
                "this": 3.26,
                "CBSA": 0.18,
                "state": 0.09
             },
             "numerators": {
                "this": 2770,
                "CBSA": 1443541,
                "state": 1.054721E7
             },
             "numerator_errors": {
                "this": 373,
                "CBSA": 8570,
                "state": 36592
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 79,
                "state": 90
             },
             "error_ratio": {
                "this": 13.5,
                "CBSA": 0.6,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 10.97,
                   "CBSA": 9.42,
                   "state": 6.42
                },
                "error": {
                   "this": 3.8,
                   "CBSA": 0.19,
                   "state": 0.07
                },
                "numerators": {
                   "this": 304,
                   "CBSA": 135944,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 113,
                   "CBSA": 2807,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 116,
                   "state": 171
                },
                "error_ratio": {
                   "this": 34.6,
                   "CBSA": 2,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 78.66,
                   "CBSA": 57.07,
                   "state": 39.42
                },
                "error": {
                   "this": 7.21,
                   "CBSA": 0.46,
                   "state": 0.09
                },
                "numerators": {
                   "this": 2179,
                   "CBSA": 823767,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 355,
                   "CBSA": 4566,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 138,
                   "state": 200
                },
                "error_ratio": {
                   "this": 9.2,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.52,
                   "CBSA": 2.41,
                   "state": 1.84
                },
                "error": {
                   "this": 1.47,
                   "CBSA": 0.12,
                   "state": 0.05
                },
                "numerators": {
                   "this": 42,
                   "CBSA": 34802,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 41,
                   "CBSA": 1747,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 63,
                   "state": 83
                },
                "error_ratio": {
                   "this": 96.7,
                   "CBSA": 5,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 1.62,
                   "CBSA": 1.66,
                   "state": 0.82
                },
                "error": {
                   "this": 1.43,
                   "CBSA": 0.11,
                   "state": 0.03
                },
                "numerators": {
                   "this": 45,
                   "CBSA": 23950,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 40,
                   "CBSA": 1560,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 198
                },
                "error_ratio": {
                   "this": 88.3,
                   "CBSA": 6.6,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 4.66,
                   "CBSA": 27.95,
                   "state": 50.28
                },
                "error": {
                   "this": 2.93,
                   "CBSA": 0.31,
                   "state": 0.15
                },
                "numerators": {
                   "this": 129,
                   "CBSA": 403440,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 83,
                   "CBSA": 5038,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 17,
                   "state": 9
                },
                "error_ratio": {
                   "this": 62.9,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 2.56,
                   "CBSA": 1.5,
                   "state": 1.23
                },
                "error": {
                   "this": 1.92,
                   "CBSA": 0.07,
                   "state": 0.03
                },
                "numerators": {
                   "this": 71,
                   "CBSA": 21628,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 54,
                   "CBSA": 1082,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 171,
                   "state": 208
                },
                "error_ratio": {
                   "this": 75,
                   "CBSA": 4.7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 76.68,
                   "CBSA": 61.01,
                   "state": 56.46
                },
                "error": {
                   "this": 6.82,
                   "CBSA": 0.59,
                   "state": 0.24
                },
                "numerators": {
                   "this": 1808,
                   "CBSA": 412994,
                   "state": 3709800
                },
                "numerator_errors": {
                   "this": 196,
                   "CBSA": 3979,
                   "state": 15797
                },
                "index": {
                   "this": 100,
                   "CBSA": 126,
                   "state": 136
                },
                "error_ratio": {
                   "this": 8.9,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 3.22,
                   "CBSA": 21.04,
                   "state": 33.98
                },
                "error": {
                   "this": 2.32,
                   "CBSA": 0.4,
                   "state": 0.2
                },
                "numerators": {
                   "this": 76,
                   "CBSA": 142403,
                   "state": 2232947
                },
                "numerator_errors": {
                   "this": 55,
                   "CBSA": 2700,
                   "state": 13243
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 9
                },
                "error_ratio": {
                   "this": 72,
                   "CBSA": 1.9,
                   "state": 0.6
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 3.01,
                   "CBSA": 5.49,
                   "state": 2.92
                },
                "error": {
                   "this": 2.37,
                   "CBSA": 0.28,
                   "state": 0.07
                },
                "numerators": {
                   "this": 71,
                   "CBSA": 37137,
                   "state": 191982
                },
                "numerator_errors": {
                   "this": 56,
                   "CBSA": 1876,
                   "state": 4345
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 103
                },
                "error_ratio": {
                   "this": 78.7,
                   "CBSA": 5.1,
                   "state": 2.4
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 14.97,
                   "CBSA": 11.16,
                   "state": 5.73
                },
                "error": {
                   "this": 5.13,
                   "CBSA": 0.29,
                   "state": 0.06
                },
                "numerators": {
                   "this": 353,
                   "CBSA": 75535,
                   "state": 376241
                },
                "numerator_errors": {
                   "this": 123,
                   "CBSA": 1954,
                   "state": 4139
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 261
                },
                "error_ratio": {
                   "this": 34.3,
                   "CBSA": 2.6,
                   "state": 1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.12,
                   "CBSA": 1.32,
                   "state": 0.91
                },
                "error": {
                   "this": 1.86,
                   "CBSA": 0.12,
                   "state": 0.04
                },
                "numerators": {
                   "this": 50,
                   "CBSA": 8906,
                   "state": 59648
                },
                "numerator_errors": {
                   "this": 44,
                   "CBSA": 809,
                   "state": 2823
                },
                "index": {
                   "this": 100,
                   "CBSA": 161,
                   "state": 233
                },
                "error_ratio": {
                   "this": 87.7,
                   "CBSA": 9.1,
                   "state": 4.4
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 66.9,
                   "CBSA": 58.17,
                   "state": 55.62
                },
                "error": {
                   "this": 3.93,
                   "CBSA": 0.19,
                   "state": 0.11
                },
                "numerators": {
                   "this": 5715,
                   "CBSA": 2191040,
                   "state": 1.6830152E7
                },
                "numerator_errors": {
                   "this": 385.1,
                   "CBSA": 7329.1,
                   "state": 34792.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 120
                },
                "error_ratio": {
                   "this": 5.9,
                   "CBSA": 0.3,
                   "state": 0.2
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 2.2,
                   "CBSA": 14.93,
                   "state": 27.58
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.12,
                   "state": 0.08
                },
                "numerators": {
                   "this": 188,
                   "CBSA": 562235,
                   "state": 8345569
                },
                "numerator_errors": {
                   "this": 94.2,
                   "CBSA": 4401.6,
                   "state": 23054.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 8
                },
                "error_ratio": {
                   "this": 50,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 6.22,
                   "CBSA": 7.18,
                   "state": 4.85
                },
                "error": {
                   "this": 1.73,
                   "CBSA": 0.13,
                   "state": 0.04
                },
                "numerators": {
                   "this": 531,
                   "CBSA": 270336,
                   "state": 1468932
                },
                "numerator_errors": {
                   "this": 148.7,
                   "CBSA": 4894,
                   "state": 12705.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 128
                },
                "error_ratio": {
                   "this": 27.8,
                   "CBSA": 1.8,
                   "state": 0.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 22.55,
                   "CBSA": 18.48,
                   "state": 10.88
                },
                "error": {
                   "this": 4.15,
                   "CBSA": 0.13,
                   "state": 0.03
                },
                "numerators": {
                   "this": 1926,
                   "CBSA": 695965,
                   "state": 3293073
                },
                "numerator_errors": {
                   "this": 359.9,
                   "CBSA": 4916.4,
                   "state": 10307.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 122,
                   "state": 207
                },
                "error_ratio": {
                   "this": 18.4,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.13,
                   "CBSA": 1.24,
                   "state": 1.07
                },
                "error": {
                   "this": 1.95,
                   "CBSA": 0.05,
                   "state": 0.02
                },
                "numerators": {
                   "this": 182,
                   "CBSA": 46843,
                   "state": 323625
                },
                "numerator_errors": {
                   "this": 167.1,
                   "CBSA": 2045.7,
                   "state": 7069.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 172,
                   "state": 199
                },
                "error_ratio": {
                   "this": 91.5,
                   "CBSA": 4,
                   "state": 1.9
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 31,
                   "CBSA": 8551,
                   "state": 66574
                },
                "error": {
                   "this": 38.09,
                   "CBSA": 587.6,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 122.9,
                   "CBSA": 6.9,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 40,
                   "CBSA": 15732,
                   "state": 145536
                },
                "error": {
                   "this": 45.9,
                   "CBSA": 775.97,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 114.7,
                   "CBSA": 4.9,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 167,
                   "CBSA": 55782,
                   "state": 561083
                },
                "error": {
                   "this": 65.22,
                   "CBSA": 1426.49,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 39.1,
                   "CBSA": 2.6,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 10,
                   "CBSA": 22897,
                   "state": 284290
                },
                "error": {
                   "this": 36.59,
                   "CBSA": 1005.17,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 365.9,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 9,
                   "CBSA": 22022,
                   "state": 309021
                },
                "error": {
                   "this": 30.3,
                   "CBSA": 1007.87,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 336.7,
                   "CBSA": 4.6,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 350,
                   "CBSA": 143204,
                   "state": 1446385
                },
                "error": {
                   "this": 83,
                   "CBSA": 2400,
                   "state": 8117
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 23.7,
                   "CBSA": 1.7,
                   "state": 0.6
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 19,
                   "CBSA": 11528,
                   "state": 128146
                },
                "error": {
                   "this": 22,
                   "CBSA": 751,
                   "state": 2994
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": 115.8,
                   "CBSA": 6.5,
                   "state": 2.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 369,
                "CBSA": 154732,
                "state": 1574531
             },
             "error": {
                "this": 83,
                "CBSA": 2583,
                "state": 8842
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": 22.5,
                "CBSA": 1.7,
                "state": 0.6
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 4.32,
                "CBSA": 4.11,
                "state": 5.23
             },
             "error": {
                "this": 0.97,
                "CBSA": 0.07,
                "state": 0.03
             },
             "numerators": {
                "this": 369,
                "CBSA": 154732,
                "state": 1574531
             },
             "numerator_errors": {
                "this": 83,
                "CBSA": 2583,
                "state": 8842
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 83
             },
             "error_ratio": {
                "this": 22.5,
                "CBSA": 1.7,
                "state": 0.6
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 16032293,
       "awater": 0,
       "display_name": "Hillsborough, CA",
       "full_geoid": "16000US0633798",
       "population": 11439,
       "simple_name": "Hillsborough",
       "sumlevel": "160",
       "square_miles": 6.2,
       "population_density": 1849.2
    }
 }

 export default hillsborough