exports.WALNUT_CREEK_SCHOOLS = [
		{
			"id": 21294,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.924976,
			"lon": -122.068367,
			"name": "A Small World Inf & Tdlr Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "933 Monet Circle",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/21294-A-Small-World-Inf--Tdlr-Center/",
				"reviews": "/california/walnut-creek/21294-A-Small-World-Inf--Tdlr-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/21294-A-Small-World-Inf--Tdlr-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14730,
			"districtId": 39,
			"districtName": "Acalanes Union High",
			"districtCity": "Lafayette",
			"levelCode": "e,m,h",
			"lat": 37.876678,
			"lon": -122.07251,
			"name": "Acalanes Adult Education Center",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "1963 Tice Valley Boulevard",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/14730-Acalanes-Adult-Education-Center/",
				"reviews": "/california/walnut-creek/14730-Acalanes-Adult-Education-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/14730-Acalanes-Adult-Education-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14144,
			"districtId": 39,
			"districtName": "Acalanes Union High",
			"districtCity": "Lafayette",
			"levelCode": "h",
			"lat": 37.87698,
			"lon": -122.07605,
			"name": "Acalanes Center for Independent Study",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1963 Tice Valley Boulevard",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/14144-Acalanes-Center-For-Independent-Study/",
				"reviews": "/california/walnut-creek/14144-Acalanes-Center-For-Independent-Study/#Reviews",
				"collegeSuccess": "/california/walnut-creek/14144-Acalanes-Center-For-Independent-Study/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "58%",
				"state_average": "64%"
			},
			"enrollment": 27,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 6,
			"subratings": {
				"College Readiness Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 7
				},
				{
					"label": "White",
					"percentage": 67
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 13242,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.932812,
			"lon": -122.073303,
			"name": "Alice's Montessori Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3158 Putnam Boulevard",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/13242-Alices-Montessori-Learning-Center/",
				"reviews": "/california/walnut-creek/13242-Alices-Montessori-Learning-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/13242-Alices-Montessori-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 50,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 19849,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.888103,
			"lon": -122.030289,
			"name": "Ark Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "706 Rosewood Drive",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/19849-Ark-Pre-School/",
				"reviews": "/california/walnut-creek/19849-Ark-Pre-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/19849-Ark-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 462,
			"districtId": 51,
			"districtName": "Mt. Diablo Unified",
			"districtCity": "Concord",
			"levelCode": "e",
			"lat": 37.929001,
			"lon": -122.037529,
			"name": "Bancroft Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2200 Parish Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/462-Bancroft-Elementary-School/",
				"reviews": "/california/walnut-creek/462-Bancroft-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/462-Bancroft-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "14%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 645,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 14
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23792,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.930717,
			"lon": -122.040428,
			"name": "Benham Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1541 Longford Court",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23792-Benham-Academy/",
				"reviews": "/california/walnut-creek/23792-Benham-Academy/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23792-Benham-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8571,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.913734,
			"lon": -122.032097,
			"name": "Berean Christian High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "245 El Divisadero Avenue",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/8571-Berean-Christian-High-School/",
				"reviews": "/california/walnut-creek/8571-Berean-Christian-High-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/8571-Berean-Christian-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 411,
			"parentRating": 4,
			"numReviews": 18,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Asian",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 28122,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.911572,
			"lon": -122.071136,
			"name": "Buena Vista Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2372 Buena Vista Avenue",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/28122-Buena-Vista-Club/",
				"reviews": "/california/walnut-creek/28122-Buena-Vista-Club/#Reviews",
				"collegeSuccess": "/california/walnut-creek/28122-Buena-Vista-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 558,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "e",
			"lat": 37.913399,
			"lon": -122.07003,
			"name": "Buena Vista Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2355 San Juan Avenue",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/558-Buena-Vista-Elementary-School/",
				"reviews": "/california/walnut-creek/558-Buena-Vista-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/558-Buena-Vista-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "15%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 462,
			"parentRating": 5,
			"numReviews": 10,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 9167,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.922283,
			"lon": -122.079193,
			"name": "Contra Costa Christian Schools",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "2721 Larkey Lane, Walnut Creek CA 94597",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/9167-Contra-Costa-Christian-Schools/",
				"reviews": "/california/walnut-creek/9167-Contra-Costa-Christian-Schools/#Reviews",
				"collegeSuccess": "/california/walnut-creek/9167-Contra-Costa-Christian-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 247,
			"parentRating": 5,
			"numReviews": 75,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 88
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13495,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.87579,
			"lon": -122.069,
			"name": "Contra Costa Jewish Community Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2071 Tice Valley Boulevard",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/13495-Contra-Costa-Jewish-Community-Center/",
				"reviews": "/california/walnut-creek/13495-Contra-Costa-Jewish-Community-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/13495-Contra-Costa-Jewish-Community-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 75,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 77
				},
				{
					"label": "Two or more races",
					"percentage": 15
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 24218,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.898457,
			"lon": -122.044594,
			"name": "Contra Costa Midrasha",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "74 Eckley Ln",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/24218-Contra-Costa-Midrasha/",
				"reviews": "/california/walnut-creek/24218-Contra-Costa-Midrasha/#Reviews",
				"collegeSuccess": "/california/walnut-creek/24218-Contra-Costa-Midrasha/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 32484,
			"districtId": 2065,
			"districtName": "Contra Costa School of Performing Arts District",
			"districtCity": "Walnut Creek",
			"levelCode": "m,h",
			"lat": 37.930347,
			"lon": -122.024612,
			"name": "Contra Costa School of Performing Arts",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "2730 Mitchell Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/32484-Contra-Costa-School-Of-Performing-Arts/",
				"reviews": "/california/walnut-creek/32484-Contra-Costa-School-Of-Performing-Arts/#Reviews",
				"collegeSuccess": "/california/walnut-creek/32484-Contra-Costa-School-Of-Performing-Arts/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "23%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 471,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 23
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 25
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 49
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 11924,
			"districtId": 51,
			"districtName": "Mt. Diablo Unified",
			"districtCity": "Concord",
			"levelCode": "e,m",
			"lat": 37.91143,
			"lon": -122.010956,
			"name": "Eagle Peak Montessori",
			"gradeLevels": "1-8",
			"assigned": null,
			"address": {
				"street1": "800 Hutchinson Road",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/11924-Eagle-Peak-Montessori-School/",
				"reviews": "/california/walnut-creek/11924-Eagle-Peak-Montessori-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/11924-Eagle-Peak-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 286,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 9
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 49
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 23567,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.911335,
			"lon": -122.070038,
			"name": "First Baptist Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2336 Buena Vista Avenue",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23567-First-Baptist-Pre-School/",
				"reviews": "/california/walnut-creek/23567-First-Baptist-Pre-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23567-First-Baptist-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 473,
			"districtId": 51,
			"districtName": "Mt. Diablo Unified",
			"districtCity": "Concord",
			"levelCode": "m",
			"lat": 37.921253,
			"lon": -122.016502,
			"name": "Foothill Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2775 Cedro Lane",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/473-Foothill-Middle-School/",
				"reviews": "/california/walnut-creek/473-Foothill-Middle-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/473-Foothill-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "13%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 974,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 25874,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.929203,
			"lon": -122.058342,
			"name": "Fusion Academy Walnut Creek",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "3003 Oak Road",
				"street2": "Suite 150",
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/25874-Fusion-Academy-Walnut-Creek/",
				"reviews": "/california/walnut-creek/25874-Fusion-Academy-Walnut-Creek/#Reviews",
				"collegeSuccess": "/california/walnut-creek/25874-Fusion-Academy-Walnut-Creek/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 57,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 96
				},
				{
					"label": "Asian",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 31749,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.904167,
			"lon": -122.068787,
			"name": "Futures Academy - Walnut Creek",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "101 Ygnacio Valley Rd., Ste. 150",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/31749-Futures-Academy---Walnut-Creek/",
				"reviews": "/california/walnut-creek/31749-Futures-Academy---Walnut-Creek/#Reviews",
				"collegeSuccess": "/california/walnut-creek/31749-Futures-Academy---Walnut-Creek/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24264,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.911556,
			"lon": -122.073494,
			"name": "Garden Gate Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "63 Sandy Lane",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/24264-Garden-Gate-Montessori-School/",
				"reviews": "/california/walnut-creek/24264-Garden-Gate-Montessori-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/24264-Garden-Gate-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 23,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"percentage": 29
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 29
				}
			],
			"remediationData": {}
		},
		{
			"id": 26935,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.876755,
			"lon": -122.069229,
			"name": "Grace Cooperative Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2100 Tice Valley Blvd.",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/26935-Grace-Cooperative-Preschool/",
				"reviews": "/california/walnut-creek/26935-Grace-Cooperative-Preschool/#Reviews",
				"collegeSuccess": "/california/walnut-creek/26935-Grace-Cooperative-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 559,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "e",
			"lat": 37.900448,
			"lon": -122.032005,
			"name": "Indian Valley Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "551 Marshall Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/559-Indian-Valley-Elementary-School/",
				"reviews": "/california/walnut-creek/559-Indian-Valley-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/559-Indian-Valley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "13%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 395,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 13
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 53
				},
				{
					"label": "Asian",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27923,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.899937,
			"lon": -122.032265,
			"name": "Indian Valley Kids Only Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "551 Marshall Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/27923-Indian-Valley-Kids-Only-Club/",
				"reviews": "/california/walnut-creek/27923-Indian-Valley-Kids-Only-Club/#Reviews",
				"collegeSuccess": "/california/walnut-creek/27923-Indian-Valley-Kids-Only-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23562,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.87579,
			"lon": -122.069,
			"name": "Jewish Early Childhood Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2071 Tice Valley Boulevard",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23562-Jewish-Early-Childhood-Center/",
				"reviews": "/california/walnut-creek/23562-Jewish-Early-Childhood-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23562-Jewish-Early-Childhood-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27924,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.887299,
			"lon": -122.068611,
			"name": "Keyspot Pack Mead",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1920 Magnolia Way",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/27924-Keyspot-Pack-Mead/",
				"reviews": "/california/walnut-creek/27924-Keyspot-Pack-Mead/#Reviews",
				"collegeSuccess": "/california/walnut-creek/27924-Keyspot-Pack-Mead/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23557,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.926037,
			"lon": -122.065125,
			"name": "Kid Time Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1547 Geary Road",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23557-Kid-Time-Day-Care-Center/",
				"reviews": "/california/walnut-creek/23557-Kid-Time-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23557-Kid-Time-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28057,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.912434,
			"lon": -122.059425,
			"name": "Kids Connection-Alamo Site",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "350 North Civic Drive",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/28057-Kids-Connection-Alamo-Site/",
				"reviews": "/california/walnut-creek/28057-Kids-Connection-Alamo-Site/#Reviews",
				"collegeSuccess": "/california/walnut-creek/28057-Kids-Connection-Alamo-Site/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19716,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.92485,
			"lon": -122.086929,
			"name": "Kids Into Speaking Spanish",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1547 Palos Verdes Mall #305",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/19716-Kids-Into-Speaking-Spanish/",
				"reviews": "/california/walnut-creek/19716-Kids-Into-Speaking-Spanish/#Reviews",
				"collegeSuccess": "/california/walnut-creek/19716-Kids-Into-Speaking-Spanish/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28948,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.92485,
			"lon": -122.086929,
			"name": "Kids Into Speaking Spanish Ps",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1547 Palos Verdes Mall #305",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/28948-Kids-Into-Speaking-Spanish-Ps/",
				"reviews": "/california/walnut-creek/28948-Kids-Into-Speaking-Spanish-Ps/#Reviews",
				"collegeSuccess": "/california/walnut-creek/28948-Kids-Into-Speaking-Spanish-Ps/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23568,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.907589,
			"lon": -122.05497,
			"name": "Kinder Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2521 Walnut Boulevard",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23568-Kinder-Care/",
				"reviews": "/california/walnut-creek/23568-Kinder-Care/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23568-Kinder-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24925,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.907516,
			"lon": -122.055008,
			"name": "Kindercare Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2521 Walnut Boulevard",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/24925-Kindercare-Learning-Center/",
				"reviews": "/california/walnut-creek/24925-Kindercare-Learning-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/24925-Kindercare-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24926,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.925892,
			"lon": -122.050758,
			"name": "Kindercare Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2850 Cherry Lane",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/24926-Kindercare-Learning-Center/",
				"reviews": "/california/walnut-creek/24926-Kindercare-Learning-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/24926-Kindercare-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31696,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.928391,
			"lon": -122.026512,
			"name": "KLASCHOOLS of Walnut Creek",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "298 N Wiget Ln",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/31696-KLASCHOOLS-Of-Walnut-Creek/",
				"reviews": "/california/walnut-creek/31696-KLASCHOOLS-Of-Walnut-Creek/#Reviews",
				"collegeSuccess": "/california/walnut-creek/31696-KLASCHOOLS-Of-Walnut-Creek/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 405,
			"districtId": 39,
			"districtName": "Acalanes Union High",
			"districtCity": "Lafayette",
			"levelCode": "h",
			"lat": 37.890476,
			"lon": -122.056595,
			"name": "Las Lomas High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1460 South Main Street",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/405-Las-Lomas-High-School/",
				"reviews": "/california/walnut-creek/405-Las-Lomas-High-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/405-Las-Lomas-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "12%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "81%",
				"state_average": "64%"
			},
			"enrollment": 1601,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 63
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 24983,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.886105,
			"lon": -122.074608,
			"name": "Little Genius Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2151 Olympic Boulevard",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/24983-Little-Genius-Academy/",
				"reviews": "/california/walnut-creek/24983-Little-Genius-Academy/#Reviews",
				"collegeSuccess": "/california/walnut-creek/24983-Little-Genius-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23561,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.926025,
			"lon": -122.077744,
			"name": "Love & Care Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1985 Geary Road",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23561-Love--Care-Learning-Center/",
				"reviews": "/california/walnut-creek/23561-Love--Care-Learning-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23561-Love--Care-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23563,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.886032,
			"lon": -122.074142,
			"name": "Morning Star Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2131 Olympic Blvd",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23563-Morning-Star-Pre-School/",
				"reviews": "/california/walnut-creek/23563-Morning-Star-Pre-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23563-Morning-Star-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 69,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 23575,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.900097,
			"lon": -122.045845,
			"name": "Mount Diablo Co-Operative Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "55 Eckley Ln",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23575-Mount-Diablo-Co-Operative-Pre-School/",
				"reviews": "/california/walnut-creek/23575-Mount-Diablo-Co-Operative-Pre-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23575-Mount-Diablo-Co-Operative-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 560,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "e",
			"lat": 37.883884,
			"lon": -122.048584,
			"name": "Murwood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2050 Vanderslice Avenue",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/560-Murwood-Elementary-School/",
				"reviews": "/california/walnut-creek/560-Murwood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/560-Murwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "20%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 366,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 20
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 58
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23560,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.926147,
			"lon": -122.073936,
			"name": "New World Cdc-Walnut Creek",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1919 Geary Road",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23560-New-World-Cdc-Walnut-Creek/",
				"reviews": "/california/walnut-creek/23560-New-World-Cdc-Walnut-Creek/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23560-New-World-Cdc-Walnut-Creek/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13037,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.922367,
			"lon": -122.028465,
			"name": "NorthCreek Academy & Preschool",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "2303 Ygnacio Valley Road",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/13037-NorthCreek-Academy--Preschool/",
				"reviews": "/california/walnut-creek/13037-NorthCreek-Academy--Preschool/#Reviews",
				"collegeSuccess": "/california/walnut-creek/13037-NorthCreek-Academy--Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 390,
			"parentRating": 5,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13409,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.92223,
			"lon": -122.028442,
			"name": "Northcreek Academy & Preschool",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "2303 Ygnacio Valley Road",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/13409-Northcreek-Academy--Preschool/",
				"reviews": "/california/walnut-creek/13409-Northcreek-Academy--Preschool/#Reviews",
				"collegeSuccess": "/california/walnut-creek/13409-Northcreek-Academy--Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 534,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 79
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 486,
			"districtId": 51,
			"districtName": "Mt. Diablo Unified",
			"districtCity": "Concord",
			"levelCode": "h",
			"lat": 37.907616,
			"lon": -122.007103,
			"name": "Northgate High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "425 Castle Rock Road",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/486-Northgate-High-School/",
				"reviews": "/california/walnut-creek/486-Northgate-High-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/486-Northgate-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "12%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "81%",
				"state_average": "64%"
			},
			"enrollment": 1490,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 55
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31244,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m",
			"lat": 37.900364,
			"lon": -122.056824,
			"name": "Oro School",
			"gradeLevels": "7",
			"assigned": null,
			"address": {
				"street1": "130 Village Court",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/31244-Oro-School/",
				"reviews": "/california/walnut-creek/31244-Oro-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/31244-Oro-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8846,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.92347,
			"lon": -122.06002,
			"name": "Palmer School For Boys And Girls",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2740 Jones Road",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/8846-Palmer-School-For-Boys-And-Girls/",
				"reviews": "/california/walnut-creek/8846-Palmer-School-For-Boys-And-Girls/#Reviews",
				"collegeSuccess": "/california/walnut-creek/8846-Palmer-School-For-Boys-And-Girls/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 386,
			"parentRating": 4,
			"numReviews": 11,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 561,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "e",
			"lat": 37.887394,
			"lon": -122.068077,
			"name": "Parkmead Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1920 Magnolia Way",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/561-Parkmead-Elementary-School/",
				"reviews": "/california/walnut-creek/561-Parkmead-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/561-Parkmead-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 450,
			"parentRating": 4,
			"numReviews": 20,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 11
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 23566,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.886295,
			"lon": -122.077515,
			"name": "Pied Piper Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2263 Whyte Park Avenue",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23566-Pied-Piper-Preschool/",
				"reviews": "/california/walnut-creek/23566-Pied-Piper-Preschool/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23566-Pied-Piper-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15106,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.909901,
			"lon": -122.070099,
			"name": "S.T.A.R.S. School",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "2317 Buena Vista Avenue",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/15106-S.T.A.R.S.-School/",
				"reviews": "/california/walnut-creek/15106-S.T.A.R.S.-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/15106-S.T.A.R.S.-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23556,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.916664,
			"lon": -122.059486,
			"name": "Saybrook Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1355 Walden Road",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23556-Saybrook-Learning-Center/",
				"reviews": "/california/walnut-creek/23556-Saybrook-Learning-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23556-Saybrook-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8570,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.922127,
			"lon": -122.048889,
			"name": "Seven Hills, The",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "975 North San Carlos Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/8570-Seven-Hills-The/",
				"reviews": "/california/walnut-creek/8570-Seven-Hills-The/#Reviews",
				"collegeSuccess": "/california/walnut-creek/8570-Seven-Hills-The/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 388,
			"parentRating": 4,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 79
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31247,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.883175,
			"lon": -122.036285,
			"name": "Singing Stones School",
			"gradeLevels": "PK-4",
			"assigned": null,
			"address": {
				"street1": "2491 San Miguel Drive",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/31247-Singing-Stones-School/",
				"reviews": "/california/walnut-creek/31247-Singing-Stones-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/31247-Singing-Stones-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 63,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 76
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 17144,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.907299,
			"lon": -122.007004,
			"name": "Spectrum Center-Northgate Campus",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "425 Castle Rock Road",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/17144-Spectrum-Center-Northgate-Campus/",
				"reviews": "/california/walnut-creek/17144-Spectrum-Center-Northgate-Campus/#Reviews",
				"collegeSuccess": "/california/walnut-creek/17144-Spectrum-Center-Northgate-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13197,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.931278,
			"lon": -122.023445,
			"name": "Springfield Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2780 Mitchell Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/13197-Springfield-Montessori-School/",
				"reviews": "/california/walnut-creek/13197-Springfield-Montessori-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/13197-Springfield-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 124,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 88
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				}
			],
			"remediationData": {}
		},
		{
			"id": 23553,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.895592,
			"lon": -122.068604,
			"name": "St Mary Pre Kindergarten",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1158 Bont Lane",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23553-St-Mary-Pre-Kindergarten/",
				"reviews": "/california/walnut-creek/23553-St-Mary-Pre-Kindergarten/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23553-St-Mary-Pre-Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8255,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.89555,
			"lon": -122.068329,
			"name": "St. Mary of the Immaculate Conception School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1158 Bont Lane",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/8255-St.-Mary-Of-The-Immaculate-Conception-School/",
				"reviews": "/california/walnut-creek/8255-St.-Mary-Of-The-Immaculate-Conception-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/8255-St.-Mary-Of-The-Immaculate-Conception-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 303,
			"parentRating": 4,
			"numReviews": 13,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 52
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 23555,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.90667,
			"lon": -122.031296,
			"name": "Step Ahead Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "15 Rani Court",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23555-Step-Ahead-Learning-Center/",
				"reviews": "/california/walnut-creek/23555-Step-Ahead-Learning-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23555-Step-Ahead-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23554,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.930389,
			"lon": -122.056183,
			"name": "Step Ahead Learning Infant Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1338 Las Juntas Way",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23554-Step-Ahead-Learning-Infant-Center/",
				"reviews": "/california/walnut-creek/23554-Step-Ahead-Learning-Infant-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23554-Step-Ahead-Learning-Infant-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 32028,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "e,m",
			"lat": 37.888855,
			"lon": -122.066803,
			"name": "Tice Creek",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1847 Newell Avenue",
				"street2": null,
				"zip": "94595",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94595",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/32028-Tice-Creek/",
				"reviews": "/california/walnut-creek/32028-Tice-Creek/#Reviews",
				"collegeSuccess": "/california/walnut-creek/32028-Tice-Creek/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 427,
			"parentRating": 5,
			"numReviews": 6,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 58
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27321,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.933159,
			"lon": -122.013817,
			"name": "Valle Verde Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3275 Peachwillow Lane",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/27321-Valle-Verde-Childrens-Center/",
				"reviews": "/california/walnut-creek/27321-Valle-Verde-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/27321-Valle-Verde-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 504,
			"districtId": 51,
			"districtName": "Mt. Diablo Unified",
			"districtCity": "Concord",
			"levelCode": "e",
			"lat": 37.933929,
			"lon": -122.013908,
			"name": "Valle Verde Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3275 Peachwillow Lane",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/504-Valle-Verde-Elementary-School/",
				"reviews": "/california/walnut-creek/504-Valle-Verde-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/504-Valle-Verde-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 472,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23574,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.918629,
			"lon": -122.020767,
			"name": "Walnut Acres Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "450 Wiget Lane",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23574-Walnut-Acres-Day-Care-Center/",
				"reviews": "/california/walnut-creek/23574-Walnut-Acres-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23574-Walnut-Acres-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 506,
			"districtId": 51,
			"districtName": "Mt. Diablo Unified",
			"districtCity": "Concord",
			"levelCode": "e",
			"lat": 37.918732,
			"lon": -122.020164,
			"name": "Walnut Acres Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "180 Cerezo Drive",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/506-Walnut-Acres-Elementary-School/",
				"reviews": "/california/walnut-creek/506-Walnut-Acres-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/506-Walnut-Acres-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 634,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 46
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 23570,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.919609,
			"lon": -122.030769,
			"name": "Walnut Ave Christian Community Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "260 Walnut Avenue",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23570-Walnut-Ave-Christian-Community-Pre-School/",
				"reviews": "/california/walnut-creek/23570-Walnut-Ave-Christian-Community-Pre-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23570-Walnut-Ave-Christian-Community-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8663,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.910786,
			"lon": -122.070435,
			"name": "Walnut Creek Christian Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "2336 Buena Vista Avenue",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/8663-Walnut-Creek-Christian-Academy/",
				"reviews": "/california/walnut-creek/8663-Walnut-Creek-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/walnut-creek/8663-Walnut-Creek-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 271,
			"parentRating": 4,
			"numReviews": 26,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Asian",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 562,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "m",
			"lat": 37.909149,
			"lon": -122.055687,
			"name": "Walnut Creek Intermediate",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2425 Walnut Boulevard",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/562-Walnut-Creek-Intermediate-School/",
				"reviews": "/california/walnut-creek/562-Walnut-Creek-Intermediate-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/562-Walnut-Creek-Intermediate-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "13%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1049,
			"parentRating": 3,
			"numReviews": 21,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 13
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "African American",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 56
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 7
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 23571,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.930557,
			"lon": -122.019913,
			"name": "Walnut Creek KinderCare",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2875 Mitchell Dr.",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23571-Walnut-Creek-KinderCare/",
				"reviews": "/california/walnut-creek/23571-Walnut-Creek-KinderCare/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23571-Walnut-Creek-KinderCare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23559,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.902355,
			"lon": -122.068588,
			"name": "Walnut Creek Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1801 Lacassie Avenue",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23559-Walnut-Creek-Pre-School/",
				"reviews": "/california/walnut-creek/23559-Walnut-Creek-Pre-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23559-Walnut-Creek-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 563,
			"districtId": 56,
			"districtName": "Walnut Creek Elementary",
			"districtCity": "Walnut Creek",
			"levelCode": "e",
			"lat": 37.894844,
			"lon": -122.037331,
			"name": "Walnut Heights Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "4064 Walnut Boulevard",
				"street2": null,
				"zip": "94596",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94596",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/563-Walnut-Heights-Elementary-School/",
				"reviews": "/california/walnut-creek/563-Walnut-Heights-Elementary-School/#Reviews",
				"collegeSuccess": "/california/walnut-creek/563-Walnut-Heights-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 387,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23564,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.929932,
			"lon": -122.016869,
			"name": "Weecare Campus Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2210 Oak Grove Road",
				"street2": null,
				"zip": "94598",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94598",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23564-Weecare-Campus-Child-Care-Center/",
				"reviews": "/california/walnut-creek/23564-Weecare-Campus-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23564-Weecare-Campus-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23558,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.921513,
			"lon": -122.072311,
			"name": "Zvenigordsky's Day Care Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1791 2nd Avenue",
				"street2": null,
				"zip": "94597",
				"city": "Walnut Creek"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94597",
			"type": "school",
			"links": {
				"profile": "/california/walnut-creek/23558-Zvenigordskys-Day-Care-Center/",
				"reviews": "/california/walnut-creek/23558-Zvenigordskys-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/walnut-creek/23558-Zvenigordskys-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]