exports.SAN_FRANCISCO_LISTINGS = [
		{
			"closePrice": 1600000,
			"daysOnMovoto": 126,
			"id": "0fc769fd-2e56-4d37-ad8f-968559e7994b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862728_0_6IYfim_p.jpeg",
			"listDate": "2021-09-15 10:19:54",
			"listingAgent": "Amanda Mccarthy",
			"listPrice": 1699000,
			"lotSize": 1999,
			"sqftTotal": 2820,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862728",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens Thrive",
			"photoCount": 27,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1907,
			"zipCode": "94118",
			"path": "san-francisco-ca/2601-mcallister-st-san-francisco-ca-94118-100_ml81862728/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "2d80aea8-d7cb-4ad5-9438-46f0e790f3c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-09-15 17:22:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 10:19:54",
			"virtualTourLink": "https://player.vimeo.com/video/605016418",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.775546,
				"lng": -122.454973,
				"zipcode": "94118",
				"subPremise": "",
				"address": "2601 Mcallister St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/605016418"
		},
		{
			"closePrice": 1720188,
			"daysOnMovoto": 145,
			"id": "e9b31d2a-22f6-4764-9b67-79b5546ba22d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860344_0_Z2v2vN_p.jpeg",
			"listDate": "2021-08-27 15:22:33",
			"listingAgent": "Elbert Mui",
			"listPrice": 1780000,
			"lotSize": 3001,
			"sqftTotal": 2750,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860344",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Nu Level Realty, Inc.",
			"photoCount": 60,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1900,
			"zipCode": "94134",
			"path": "san-francisco-ca/2930-2914-san-bruno-ave-san-francisco-ca-94134-100_ml81860344/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 600000,
			"propertyId": "1443c128-8835-4731-9c8d-3feba0d15ed1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-08-27 22:27:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 15:22:33",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"zipcode": "94134",
				"subPremise": "",
				"address": "2930 2914 San Bruno Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1720188,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 780888,
			"daysOnMovoto": 0,
			"id": "234294f2-f68d-406d-a327-e427dcdc6286",
			"tnImgPath": "https://pi.movoto.com/p/102/421537390_0_NZubvQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Mary Seo",
			"listPrice": 780000,
			"lotSize": 42325,
			"sqftTotal": 984,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421537390",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Metropolitan Real Estate & Development",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2001,
			"zipCode": "94134",
			"path": "san-francisco-ca/101-crescent-way-2310-san-francisco-ca-94134-100_80708426/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -19000,
			"propertyId": "0a889465-ca3f-4051-a3b1-e3b0827fb1f8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-04-24 15:34:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-24 15:34:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.710919,
				"lng": -122.389249,
				"zipcode": "94134",
				"subPremise": "APT 2310",
				"address": "101 Crescent Way #2310"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 780888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 78,
			"id": "b57e9fbd-0afd-4228-bc88-81f6c85a7724",
			"tnImgPath": "https://pi.movoto.com/p/110/421609137_0_V7MnBF_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Colleen McFerrin",
			"listPrice": 724888,
			"lotSize": 5000,
			"sqftTotal": 777,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609137",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2001,
			"zipCode": "94107",
			"path": "san-francisco-ca/1047-mississippi-st-5-san-francisco-ca-94107/pid_lk6yoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eb67aca1-22b7-4b02-9eaf-01e2c9131030",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-11-05 03:06:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": "http://www.1047-mississippist-unit5-sanfrancisco.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.753164,
				"lng": -122.393373,
				"zipcode": "94107",
				"subPremise": "APT 5",
				"address": "1047 Mississippi St #5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.1047-mississippist-unit5-sanfrancisco.com"
		},
		{
			"closePrice": 425659,
			"daysOnMovoto": 204,
			"id": "da57c7a8-149f-4550-b2d9-8247c896426a",
			"tnImgPath": "https://pi.movoto.com/p/110/421567571_0_ye6YNa_p.jpeg",
			"listDate": "2021-06-24 00:00:00",
			"listingAgent": "Derrick Mar",
			"listPrice": 425659,
			"lotSize": 37623,
			"sqftTotal": 736,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421567571",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2004,
			"zipCode": "94107",
			"path": "san-francisco-ca/88-townsend-st-315-san-francisco-ca-94107/pid_v10yoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 20269,
			"propertyId": "6715c8df-de25-43e0-8431-98a01f15d2ac",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-06-25 07:01:08",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.780868,
				"lng": -122.390538,
				"zipcode": "94107",
				"subPremise": "APT 315",
				"address": "88 Townsend St #315"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 425659,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1475000,
			"daysOnMovoto": 56,
			"id": "bae33916-cf87-4c17-8c40-828b5d7fc80b",
			"tnImgPath": "https://pi.movoto.com/p/110/421612762_0_ej67vn_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Garrett Frakes",
			"listPrice": 1475000,
			"lotSize": 47916,
			"sqftTotal": 1036,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421612762",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Polaris Pacific",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": null,
			"zipCode": "94105",
			"path": "san-francisco-ca/280-spear-st-apt-8c-san-francisco-ca-94105-110_421612762/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e2210e95-aa47-479a-8452-35e62c911d08",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-11-18 16:26:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": "https://steelblue.media/TishmanSpeyer/MIRA/ModelUnits/23D/index.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.790374,
				"lng": -122.391381,
				"zipcode": "94105",
				"subPremise": "APT 8C",
				"address": "280 Spear St #8C"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://steelblue.media/TishmanSpeyer/MIRA/ModelUnits/23D/index.html"
		},
		{
			"closePrice": 945000,
			"daysOnMovoto": 88,
			"id": "32f9a617-e225-4469-a905-a252b6e88d32",
			"tnImgPath": "https://pi.movoto.com/p/110/421604465_0_j7yARV_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Mark Fry",
			"listPrice": 895000,
			"lotSize": 5000,
			"sqftTotal": 1117,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604465",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Polaris Realty",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2000,
			"zipCode": "94103",
			"path": "san-francisco-ca/1150-folsom-st-3-san-francisco-ca-94103-110_429651/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -54000,
			"propertyId": "44b028a5-46c9-45b8-94d3-3250ee728779",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-10-16 00:36:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=S6X8gHHwx2M",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.77596,
				"lng": -122.409319,
				"zipcode": "94103",
				"subPremise": "APT 3",
				"address": "1150 Folsom St #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 945000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=S6X8gHHwx2M"
		},
		{
			"closePrice": 860000,
			"daysOnMovoto": 89,
			"id": "47824aa8-3cf2-4fca-9a88-74529d0b7821",
			"tnImgPath": "https://pi.movoto.com/p/110/421604167_0_UARvnJ_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Jeffrey Ancker",
			"listPrice": 888000,
			"lotSize": 27277,
			"sqftTotal": 785,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604167",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Citiland Investment Corp.",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1960,
			"zipCode": "94109",
			"path": "san-francisco-ca/1817-california-st-201-san-francisco-ca-94109/pid_gmqwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "899d5e9b-a6ff-412d-a0fb-b52a1aa6fed7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-10-14 20:16:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://www.1817california201.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.789489,
				"lng": -122.424604,
				"zipcode": "94109",
				"subPremise": "APT 201",
				"address": "1817 California St #201"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.1817california201.com/mls"
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 152,
			"id": "a0a3aba4-da86-4f14-b893-5f41e628ea61",
			"tnImgPath": "https://pi.movoto.com/p/110/421588599_0_RQQf6z_p.jpeg",
			"listDate": "2021-08-12 00:00:00",
			"listingAgent": "Paula Gold-Nocella",
			"listPrice": 1695000,
			"lotSize": 2979,
			"sqftTotal": 2615,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421588599",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "2 Houses on Lot",
			"yearBuilt": 1936,
			"zipCode": "94131",
			"path": "san-francisco-ca/220-romain-st-san-francisco-ca-94131/pid_q39xoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "368607f1-824f-400b-b114-b95c4d2be35a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-08-27 07:21:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 00:00:00",
			"virtualTourLink": "http://classicvintageduplex.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.756176,
				"lng": -122.441957,
				"zipcode": "94131",
				"subPremise": "",
				"address": "220 Romain St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://classicvintageduplex.com"
		},
		{
			"closePrice": 422133,
			"daysOnMovoto": 248,
			"id": "ebc8f26f-de58-41c0-a52c-a7ac9e4e129f",
			"tnImgPath": "https://pi.movoto.com/p/110/421572748_0_6jQBJ3_p.jpeg",
			"listDate": "2021-05-08 00:00:00",
			"listingAgent": "Robert Belli",
			"listPrice": 422133,
			"lotSize": 6813,
			"sqftTotal": 684,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421572748",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 16,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1975,
			"zipCode": "94131",
			"path": "san-francisco-ca/60-ora-way-apt-h208-san-francisco-ca-94131-110_421572748/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fd1c102d-5d14-4e7b-8935-d735dac21757",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-07-12 02:01:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-08 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=5tWeAjYVXre&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.741047,
				"lng": -122.437988,
				"zipcode": "94131",
				"subPremise": "APT H208",
				"address": "60 Ora Way #H208"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 422133,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=5tWeAjYVXre&mls=1"
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 38,
			"id": "2f0027e0-c454-4445-9fe0-50d7856609b1",
			"tnImgPath": "https://pi.movoto.com/p/110/421610813_0_3iyRnQ_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Steven Clark",
			"listPrice": 1350000,
			"lotSize": 2449,
			"sqftTotal": 1328,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421610813",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 56,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1892,
			"zipCode": "94117",
			"path": "san-francisco-ca/612-broderick-st-san-francisco-ca-94117/pid_2o4woad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0d19de40-8d4f-4657-b57e-2e8493764df0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-12-03 16:31:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": "http://www.612BroderickStreet.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.776011,
				"lng": -122.439422,
				"zipcode": "94117",
				"subPremise": "",
				"address": "612 Broderick St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.612BroderickStreet.com"
		},
		{
			"closePrice": 1635000,
			"daysOnMovoto": 44,
			"id": "179c6330-1d55-4628-83fa-4cdd3caf4a8b",
			"tnImgPath": "https://pi.movoto.com/p/110/421614002_0_3BNqq7_p.jpeg",
			"listDate": "2021-11-27 00:00:00",
			"listingAgent": "Britton Jackson",
			"listPrice": 1650000,
			"lotSize": 19971,
			"sqftTotal": 1244,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614002",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Jackson Fuller Real Estate",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94107",
			"path": "san-francisco-ca/815-tennessee-st-apt-303-san-francisco-ca-94107-110_469434/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b555a98c-65df-4da5-bde8-ae6216b05232",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-12-17 21:35:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.761196,
				"lng": -122.389334,
				"zipcode": "94107",
				"subPremise": "APT 303",
				"address": "815 Tennessee St #303"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1635000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1455000,
			"daysOnMovoto": 90,
			"id": "4ecbe4cf-20e6-4ddb-a13a-df9018675151",
			"tnImgPath": "https://pi.movoto.com/p/101/421603371_0_U2FVRa_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Marshall P Ward",
			"listPrice": 1750000,
			"lotSize": 823,
			"sqftTotal": 1920,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421603371",
			"bath": 3,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1912,
			"zipCode": "94133",
			"path": "san-francisco-ca/837-columbus-ave-san-francisco-ca-94133-101_421603371/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -350000,
			"propertyId": "61800846-1549-4d33-a647-eaf4994e4fd2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-10-12 18:33:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.802382,
				"lng": -122.413482,
				"zipcode": "94133",
				"subPremise": "",
				"address": "837 Columbus Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1455000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 206,
			"id": "6b012808-fb41-4105-a2ea-f07803dd1809",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81849017_0_mIVuM3_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Justin Murphy",
			"listPrice": 1750000,
			"lotSize": 3080,
			"sqftTotal": 2870,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81849017",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1904,
			"zipCode": "94110",
			"path": "san-francisco-ca/3484-3486-mission-st-san-francisco-ca-94110-100_ml81849017/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "19c72678-9c2f-4c0f-84f6-65aca514b220",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-06-18 17:32:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.740979,
				"lng": -122.423188,
				"zipcode": "94110",
				"subPremise": "",
				"address": "3484-3486 Mission St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 85,
			"id": "b11047b8-ee50-4d0d-b2b7-872e01672fa7",
			"tnImgPath": "https://pi.movoto.com/p/110/421604652_0_vfAeMI_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Subhi Barakat",
			"listPrice": 2200000,
			"lotSize": 5388,
			"sqftTotal": 1411,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604652",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1962,
			"zipCode": "94131",
			"path": "san-francisco-ca/48-turquoise-way-san-francisco-ca-94131/pid_eme0oad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "9776bcc7-2bf9-4111-916d-7eda3f462226",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-08 00:00:00",
			"createdAt": "2021-10-15 20:51:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.744436,
				"lng": -122.445994,
				"zipcode": "94131",
				"subPremise": "",
				"address": "48 Turquoise Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1033343,
			"daysOnMovoto": 144,
			"id": "8fde830f-1044-4ae6-98f7-01d7fc6956d8",
			"tnImgPath": "https://pi.movoto.com/p/110/421602451_0_rAFien_p.jpeg",
			"listDate": "2021-08-17 00:00:00",
			"listingAgent": "Shalini Sadda",
			"listPrice": 1099000,
			"lotSize": 22594,
			"sqftTotal": 852,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421602451",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "City Real Estate",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2006,
			"zipCode": "94105",
			"path": "san-francisco-ca/501-beale-st-15f-san-francisco-ca-94105/pid_6qzyoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cdeb2ca7-9203-4a69-8c93-6d781caf0f37",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-08 00:00:00",
			"createdAt": "2021-10-08 18:01:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 00:00:00",
			"virtualTourLink": "https://www.watermarkviews.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.786236,
				"lng": -122.389074,
				"zipcode": "94105",
				"subPremise": "APT 15F",
				"address": "501 Beale St #15F"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1033343,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.watermarkviews.com/"
		},
		{
			"closePrice": 1068000,
			"daysOnMovoto": 32,
			"id": "31a1f309-ddd3-4b45-a0c3-f972fdae2d53",
			"tnImgPath": "https://pi.movoto.com/p/110/421615700_0_MImIUe_p.jpeg",
			"listDate": "2021-12-06 00:00:00",
			"listingAgent": "Veronica Tran",
			"listPrice": 1090000,
			"lotSize": 1799,
			"sqftTotal": 0,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615700",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Tran Real Estate",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1943,
			"zipCode": "94134",
			"path": "san-francisco-ca/59-w-view-ave-san-francisco-ca-94134/pid_pxlzoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7f5efa36-6392-4799-a7c2-fa5e1222afd0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-12-07 02:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.731239,
				"lng": -122.417873,
				"zipcode": "94134",
				"subPremise": "",
				"address": "59 W View Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1068000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1345000,
			"daysOnMovoto": 38,
			"id": "a9a0eec0-59e2-4559-acf1-5a00a8086a58",
			"tnImgPath": "https://pi.movoto.com/p/110/421614387_0_BnQQyb_p.jpeg",
			"listDate": "2021-11-30 00:00:00",
			"listingAgent": "Matthew Hilzendrager",
			"listPrice": 1249000,
			"lotSize": 2175,
			"sqftTotal": 2020,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614387",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 35,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1986,
			"zipCode": "94116",
			"path": "san-francisco-ca/2488-39th-ave-san-francisco-ca-94116/pid_hz2xoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e06905b0-784d-4227-bbf3-910ffb75f405",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-12-01 00:36:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 00:00:00",
			"virtualTourLink": "https://248839thavenue686238mls.f8re.com/Website/Index",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.740455,
				"lng": -122.496837,
				"zipcode": "94116",
				"subPremise": "",
				"address": "2488 39th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1345000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://248839thavenue686238mls.f8re.com/Website/Index"
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 67,
			"id": "9f6adad3-a122-4496-9baf-0d013e889e09",
			"tnImgPath": "https://pi.movoto.com/p/110/421611015_0_7i6QIM_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Sandra Onken Fiek",
			"listPrice": 1198000,
			"lotSize": 4216,
			"sqftTotal": 0,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421611015",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1900,
			"zipCode": "94131",
			"path": "san-francisco-ca/12-flood-ave-san-francisco-ca-94131/pid_9e4zoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9741463-f4c3-4e9f-97ca-19b7ba75589f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-11-11 19:51:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.730168,
				"lng": -122.440937,
				"zipcode": "94131",
				"subPremise": "",
				"address": "12 Flood Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 84,
			"id": "9ad63334-fa8b-4fc4-9757-b0f33bcc3170",
			"tnImgPath": "https://pi.movoto.com/p/110/421604510_0_U2rzqJ_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Simon Shue",
			"listPrice": 1199000,
			"lotSize": 17557,
			"sqftTotal": 975,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604510",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "94109",
			"path": "san-francisco-ca/1945-washington-st-506-san-francisco-ca-94109/pid_vlpwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 104000,
			"propertyId": "cad18a05-5a34-40c3-b094-0be99f098191",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-10-16 03:21:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.792525,
				"lng": -122.425297,
				"zipcode": "94109",
				"subPremise": "APT 506",
				"address": "1945 Washington St #506"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 830000,
			"daysOnMovoto": 86,
			"id": "b0c28420-0882-4d36-8ecd-00ff25935fb4",
			"tnImgPath": "https://pi.movoto.com/p/110/421603786_0_jqMaMj_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Jonathan Britton",
			"listPrice": 888000,
			"lotSize": 2495,
			"sqftTotal": 2020,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421603786",
			"bath": null,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Britton & Company, W.J.",
			"photoCount": 11,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1926,
			"zipCode": "94124",
			"path": "san-francisco-ca/1642-revere-ave-san-francisco-ca-94124-110_421603786/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -311000,
			"propertyId": "995c7009-1391-4392-8531-e2803dd5fd02",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-10-13 21:16:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.732124,
				"lng": -122.390732,
				"zipcode": "94124",
				"subPremise": "",
				"address": "1642 Revere Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 995000,
			"daysOnMovoto": 127,
			"id": "ded5cc9c-697f-4ed2-9e27-9bc97d18264d",
			"tnImgPath": "https://pi.movoto.com/p/110/421593340_0_BZYqVQ_p.jpeg",
			"listDate": "2021-09-02 00:00:00",
			"listingAgent": "Heidemarie Howell",
			"listPrice": 995000,
			"lotSize": 0,
			"sqftTotal": 884,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421593340",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "North Point Real Estate",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1978,
			"zipCode": "94108",
			"path": "san-francisco-ca/1177-california-st-1201-san-francisco-ca-94108/pid_80dwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fd33f8fb-492c-467b-b4c5-0b35adb4992b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-09-17 07:26:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.790977,
				"lng": -122.413661,
				"zipcode": "94108",
				"subPremise": "APT 1201",
				"address": "1177 California St #1201"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 995000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3350000,
			"daysOnMovoto": 319,
			"id": "fad6dd4b-bec6-4dc1-a41f-70cb5816dd07",
			"tnImgPath": "https://pi.movoto.com/p/110/421529235_0_U6BvQ3_p.jpeg",
			"listDate": "2021-02-22 00:00:00",
			"listingAgent": "Bernadette Lamothe",
			"listPrice": 3995000,
			"lotSize": 16839,
			"sqftTotal": 2794,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421529235",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Stock Cooperative",
			"yearBuilt": 1925,
			"zipCode": "94109",
			"path": "san-francisco-ca/1960-broadway-5-san-francisco-ca-94109/pid_yrnwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -505000,
			"propertyId": "7e21a157-aea0-4495-a3a7-010c2854ae94",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-08-16 20:46:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.795134,
				"lng": -122.429405,
				"zipcode": "94109",
				"subPremise": "APT 5",
				"address": "1960 Broadway #5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 38,
			"id": "8e2e9443-bbd0-411f-b82b-35ab5ecc67d5",
			"tnImgPath": "https://pi.movoto.com/p/110/421615015_0_E2juUj_p.jpeg",
			"listDate": "2021-11-29 00:00:00",
			"listingAgent": "Jennifer Burden",
			"listPrice": 899000,
			"lotSize": 1746,
			"sqftTotal": 1150,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615015",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1908,
			"zipCode": "94110",
			"path": "san-francisco-ca/241-gates-st-san-francisco-ca-94110/pid_j8hzoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c87083b4-7d66-483b-8cfa-f02abf1b3972",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-12-03 19:16:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.73968,
				"lng": -122.413842,
				"zipcode": "94110",
				"subPremise": "",
				"address": "241 Gates St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1645000,
			"daysOnMovoto": 38,
			"id": "2d164084-f451-4fdb-b72e-ad6131a3a834",
			"tnImgPath": "https://pi.movoto.com/p/110/421614073_0_bJRmIi_p.jpeg",
			"listDate": "2021-11-29 00:00:00",
			"listingAgent": "Garrett Frakes",
			"listPrice": 1695000,
			"lotSize": 47916,
			"sqftTotal": 1457,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614073",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Polaris Pacific",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": null,
			"zipCode": "94105",
			"path": "san-francisco-ca/280-spear-st-apt-7h-san-francisco-ca-94105-110_482875/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ccab0e3c-d259-4da2-be54-64c7f81a6225",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-11-29 15:51:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 00:00:00",
			"virtualTourLink": "https://mirasf.com/marketing",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.790374,
				"lng": -122.391381,
				"zipcode": "94105",
				"subPremise": "APT 7H",
				"address": "280 Spear St #7H"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1645000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mirasf.com/marketing"
		},
		{
			"closePrice": 1525000,
			"daysOnMovoto": 147,
			"id": "318037fb-301c-411c-b1ba-18464cb89c06",
			"tnImgPath": "https://pi.movoto.com/p/110/421584970_0_72YAE7_p.jpeg",
			"listDate": "2021-08-12 00:00:00",
			"listingAgent": "Edie Narrido",
			"listPrice": 1495000,
			"lotSize": 15195,
			"sqftTotal": 1030,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421584970",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Polaris Pacific",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1964,
			"zipCode": "94109",
			"path": "san-francisco-ca/1998-broadway-st-apt-1407-san-francisco-ca-94109-110_421584970/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "18c7b062-b5c6-4ba8-ba9f-afc3b9ba0fbe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-10-01 16:36:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=o6Cc8D58evA",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.795127,
				"lng": -122.429699,
				"zipcode": "94109",
				"subPremise": "APT 1407",
				"address": "1998 Broadway St #1407"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=o6Cc8D58evA"
		},
		{
			"closePrice": 1735000,
			"daysOnMovoto": 68,
			"id": "5a77dd00-59dc-4237-82ea-dd3a17b866ca",
			"tnImgPath": "https://pi.movoto.com/p/110/421608420_0_A27yJY_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Dennis Sanchez",
			"listPrice": 1750000,
			"lotSize": 2996,
			"sqftTotal": 2200,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421608420",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Berri Real Estate",
			"photoCount": 72,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1912,
			"zipCode": "94122",
			"path": "san-francisco-ca/1235-45th-ave-san-francisco-ca-94122-110_421608420/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 51000,
			"propertyId": "efedac56-f964-486c-8eaf-a8c659bafd75",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-10-30 01:36:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "http://tours.vrsquadinc.com/1235123745thave/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.763498,
				"lng": -122.505535,
				"zipcode": "94122",
				"subPremise": "",
				"address": "1235 45th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1735000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://tours.vrsquadinc.com/1235123745thave/?mls"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 120,
			"id": "f71d84c6-9e67-4ad7-8e62-41b338a7bf41",
			"tnImgPath": "https://pi.movoto.com/p/110/421601465_0_ua2vau_p.jpeg",
			"listDate": "2021-09-07 00:00:00",
			"listingAgent": "Jamie Comer",
			"listPrice": 929000,
			"lotSize": 18962,
			"sqftTotal": 1188,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421601465",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Kindred SF Homes",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2002,
			"zipCode": "94107",
			"path": "san-francisco-ca/77-dow-pl-1302-san-francisco-ca-94107-110_422230/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13ec0f80-f0a5-48fd-a4b0-63e36a4bc705",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-10-07 16:41:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.78411,
				"lng": -122.39664,
				"zipcode": "94107",
				"subPremise": "APT 1302",
				"address": "77 Dow Pl #1302"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1890000,
			"daysOnMovoto": 32,
			"id": "86b4434b-0afa-4ebf-9bdc-b3677dc155b2",
			"tnImgPath": "https://pi.movoto.com/p/110/421595514_0_fRZYaU_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Tim Brown",
			"listPrice": 1825000,
			"lotSize": 4375,
			"sqftTotal": 1579,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595514",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1926,
			"zipCode": "94109",
			"path": "san-francisco-ca/2444-van-ness-ave-1-san-francisco-ca-94109/pid_ivmwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6181f83-bac3-4c6d-9a23-c853004e9924",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-12-03 19:02:02",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": "http://www.2444-van-ness.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.797987,
				"lng": -122.42364,
				"zipcode": "94109",
				"subPremise": "APT 1",
				"address": "2444 Van Ness Ave #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1890000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.2444-van-ness.com"
		},
		{
			"closePrice": 1757500,
			"daysOnMovoto": 79,
			"id": "3151f80b-a938-4dcf-a645-b182b38237e2",
			"tnImgPath": "https://pi.movoto.com/p/110/421607822_0_MnNqJR_p.jpeg",
			"listDate": "2021-10-17 00:00:00",
			"listingAgent": "Gary Saydah",
			"listPrice": 1995000,
			"lotSize": 1947,
			"sqftTotal": 3069,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607822",
			"bath": 3.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Helm Real Estate",
			"photoCount": 31,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1900,
			"zipCode": "94117",
			"path": "san-francisco-ca/51-webster-st-san-francisco-ca-94117-110_421607822/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8a3cffc8-e715-4099-a897-59138b598ae1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-10-29 07:16:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.770556,
				"lng": -122.428607,
				"zipcode": "94117",
				"subPremise": "",
				"address": "51 Webster St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1757500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 690000,
			"daysOnMovoto": 259,
			"id": "4e23fc1b-9719-48b0-85b5-99b137f0992b",
			"tnImgPath": "https://pi.movoto.com/p/110/421591078_0_7FbnBI_p.jpeg",
			"listDate": "2021-04-20 00:00:00",
			"listingAgent": "Joy Liu",
			"listPrice": 688000,
			"lotSize": 3972,
			"sqftTotal": 910,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421591078",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Helm Real Estate",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condo/Coop/TIC/Loft",
			"yearBuilt": null,
			"zipCode": "94103",
			"path": "san-francisco-ca/55-woodward-st-apt-63-san-francisco-ca-94103-110_421591078/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "24abcdf8-bd23-4f0c-96ee-4c68ae7efc71",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-09-16 20:41:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-20 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/595978103",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.769007,
				"lng": -122.420643,
				"zipcode": "94103",
				"subPremise": "APT 63",
				"address": "55 Woodward St #63"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 690000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/595978103"
		},
		{
			"closePrice": 767000,
			"daysOnMovoto": 31,
			"id": "29acb6f0-86fe-44cc-89b0-c62754cd1e37",
			"tnImgPath": "https://pi.movoto.com/p/110/421614913_0_iZnA2y_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Mary Kern",
			"listPrice": 735000,
			"lotSize": 50190,
			"sqftTotal": 633,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614913",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1993,
			"zipCode": "94111",
			"path": "san-francisco-ca/111-chestnut-st-807-san-francisco-ca-94111-110_425399/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fb21abd3-0246-4464-b2de-729b07ddb29f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-12-03 19:01:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.804325,
				"lng": -122.405773,
				"zipcode": "94111",
				"subPremise": "APT 807",
				"address": "111 Chestnut St #807"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 767000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 35,
			"id": "885386a0-0e87-4ff5-9ff6-082185e5a40a",
			"tnImgPath": "https://pi.movoto.com/p/110/421614049_0_VZMN6Q_p.jpeg",
			"listDate": "2021-11-29 00:00:00",
			"listingAgent": "Frank Nolan",
			"listPrice": 999000,
			"lotSize": 2495,
			"sqftTotal": 1230,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614049",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condo/Coop/TIC/Loft",
			"yearBuilt": 1900,
			"zipCode": "94110",
			"path": "san-francisco-ca/1659-dolores-st-san-francisco-ca-94110-110_421614049/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "28597563-c519-4a4c-adc1-5d9ba9c1f545",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-11-29 17:26:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.742808,
				"lng": -122.424052,
				"zipcode": "94110",
				"subPremise": "",
				"address": "1659 Dolores St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 920000,
			"daysOnMovoto": 46,
			"id": "e927be66-6c4e-4235-ac98-da2fb9ca2b79",
			"tnImgPath": "https://pi.movoto.com/p/101/421612175_0_Uvjzzb_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Curtis Lafferty",
			"listPrice": 1000000,
			"lotSize": 2500,
			"sqftTotal": 900,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421612175",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Century 21 Schutjer Realty, Inc",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1947,
			"zipCode": "94112",
			"path": "san-francisco-ca/18-athens-st-san-francisco-ca-94112/pid_cvmzoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5a58ce76-7134-43de-87c2-6bb9b78eb948",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-11-19 19:33:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.727913,
				"lng": -122.423952,
				"zipcode": "94112",
				"subPremise": "",
				"address": "18 Athens St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 920000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 66,
			"id": "76c7196a-bbb6-4d11-bee2-d1c193350689",
			"tnImgPath": "https://pi.movoto.com/p/110/421608059_0_ez2bAj_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Tim Brown",
			"listPrice": 1095000,
			"lotSize": 2748,
			"sqftTotal": 1090,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421608059",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condo/Coop/TIC/Loft",
			"yearBuilt": 1910,
			"zipCode": "94133",
			"path": "san-francisco-ca/456-vallejo-st-apt-b-san-francisco-ca-94133-110_421608059/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aecffd45-7f96-401c-97fe-32cef6ec7a78",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-10-29 17:56:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://www.456-vallejo.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.799337,
				"lng": -122.405003,
				"zipcode": "94133",
				"subPremise": "APT B",
				"address": "456 Vallejo St #B"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.456-vallejo.com"
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 75,
			"id": "1c47fa43-1981-4027-a846-48b8e713168a",
			"tnImgPath": "https://pi.movoto.com/p/110/421606141_0_m7fbea_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Pota Perimenis",
			"listPrice": 4100000,
			"lotSize": 2688,
			"sqftTotal": 3210,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421606141",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1900,
			"zipCode": "94115",
			"path": "san-francisco-ca/848-lyon-st-san-francisco-ca-94115-110_432426/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "da64abe3-f69c-4992-bdad-61d706b45fa0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2022-01-03 16:51:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/8s32IKUZ3sM",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.778803,
				"lng": -122.443443,
				"zipcode": "94115",
				"subPremise": "",
				"address": "848 Lyon St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/8s32IKUZ3sM"
		},
		{
			"closePrice": 630000,
			"daysOnMovoto": 298,
			"id": "53bad710-d794-4f25-b867-14d1d788e3ac",
			"tnImgPath": "https://pi.movoto.com/p/110/421527514_0_rafi22_p.jpeg",
			"listDate": "2021-03-11 00:00:00",
			"listingAgent": "Daniel Langford",
			"listPrice": 649000,
			"lotSize": 105806,
			"sqftTotal": 834,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421527514",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94102",
			"path": "san-francisco-ca/601-van-ness-ave-424-san-francisco-ca-94102/pid_6buwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1a566a8e-4bc6-4201-bfed-c0f282dc872c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-03-11 23:01:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-11 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=HvaxSwkoTph&brand=0&mls=1&",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.781117,
				"lng": -122.421331,
				"zipcode": "94102",
				"subPremise": "APT 424",
				"address": "601 Van Ness Ave #424"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=HvaxSwkoTph&brand=0&mls=1&"
		},
		{
			"closePrice": 1075000,
			"daysOnMovoto": 34,
			"id": "a09e0aeb-750b-4b5e-a899-f4682825b454",
			"tnImgPath": "https://pi.movoto.com/p/110/421614112_0_Yn7ubq_p.jpeg",
			"listDate": "2021-11-28 00:00:00",
			"listingAgent": "John Barnette",
			"listPrice": 999000,
			"lotSize": 0,
			"sqftTotal": 997,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614112",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1900,
			"zipCode": "94110",
			"path": "san-francisco-ca/2926-21st-st-san-francisco-ca-94110-110_421614112/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "d2fedd89-f5f3-4a35-8621-300299ad2b1d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-01 00:00:00",
			"createdAt": "2021-12-03 08:16:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.757614,
				"lng": -122.413031,
				"zipcode": "94110",
				"subPremise": "",
				"address": "2926 21st St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1075000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 908000,
			"daysOnMovoto": 86,
			"id": "8bcd544c-d90f-4bb2-b654-84a1306b9024",
			"tnImgPath": "https://pi.movoto.com/p/110/421602178_0_NNzfIB_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Rosemarie Hayes",
			"listPrice": 908000,
			"lotSize": 0,
			"sqftTotal": 1460,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421602178",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Mosaik Real Estate",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1913,
			"zipCode": "94112",
			"path": "san-francisco-ca/400-grafton-ave-san-francisco-ca-94112/pid_d75zoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f3f6edd1-450a-49db-91a0-2c6c3c3e296f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-01 00:00:00",
			"createdAt": "2021-10-08 19:21:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.720217,
				"lng": -122.457349,
				"zipcode": "94112",
				"subPremise": "",
				"address": "400 Grafton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 908000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1840000,
			"daysOnMovoto": 103,
			"id": "a32042f4-cc8d-494c-a90c-af3dd8e7e593",
			"tnImgPath": "https://pi.movoto.com/p/110/421596634_0_zYaam7_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Miyuki Mia Takami",
			"listPrice": 1850000,
			"lotSize": 0,
			"sqftTotal": 1653,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421596634",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 69,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2004,
			"zipCode": "94107",
			"path": "san-francisco-ca/250-king-st-752-san-francisco-ca-94107/pid_sfg0oad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6247a97f-f0dd-47ad-82e9-51f087177a82",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-01 00:00:00",
			"createdAt": "2021-10-16 03:46:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.778131,
				"lng": -122.393248,
				"zipcode": "94107",
				"subPremise": "APT 752",
				"address": "250 King St #752"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1840000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 965300,
			"daysOnMovoto": 317,
			"id": "0bdd82bc-2392-4fd4-973e-dfd8154a2bcb",
			"tnImgPath": "https://pi.movoto.com/p/110/421526367_0_nR7mU7_p.jpeg",
			"listDate": "2021-02-18 00:00:00",
			"listingAgent": "Michael Harrington",
			"listPrice": 985000,
			"lotSize": 8300,
			"sqftTotal": 1500,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421526367",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94109",
			"path": "san-francisco-ca/1835-franklin-st-702-san-francisco-ca-94109/pid_xqpwoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -65000,
			"propertyId": "baf84f5c-24b9-4b9c-af19-a5d676f124a9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-01 00:00:00",
			"createdAt": "2021-05-12 15:43:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.791596,
				"lng": -122.424547,
				"zipcode": "94109",
				"subPremise": "APT 702",
				"address": "1835 Franklin St #702"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 965300,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3401510,
			"daysOnMovoto": 41,
			"id": "f499120b-ea6f-447a-adb3-de425ce16e36",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870779_0_FQeYVi_p.jpeg",
			"listDate": "2021-11-19 17:59:55",
			"listingAgent": "Team Pete & Christine",
			"listPrice": 2799888,
			"lotSize": 2849,
			"sqftTotal": 3230,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870779",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Star Properties",
			"photoCount": 85,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1922,
			"zipCode": "94110",
			"path": "san-francisco-ca/150-liberty-st-san-francisco-ca-94110-100_ml81870779/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "748e3341-4c8d-4834-badc-0dbb352b1eef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-11-20 02:02:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 17:59:55",
			"virtualTourLink": "https://www.150-152libertystreetsf.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.757742,
				"lng": -122.424584,
				"zipcode": "94110",
				"subPremise": "",
				"address": "150 Liberty St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3401510,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.150-152libertystreetsf.com/"
		},
		{
			"closePrice": 1254500,
			"daysOnMovoto": 56,
			"id": "8459f9fc-a2f7-4c63-ac01-f8575fff78ef",
			"tnImgPath": "https://pi.movoto.com/p/110/421609703_0_EMYMje_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Ludovico Mazzola",
			"listPrice": 1195000,
			"lotSize": 2482,
			"sqftTotal": 2525,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609703",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 67,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "2 Houses on Lot",
			"yearBuilt": null,
			"zipCode": "94124",
			"path": "san-francisco-ca/1764-oakdale-ave-san-francisco-ca-94124-110_421609703/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9eb4efa3-3622-41b3-a1d3-a447378a134d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-11-05 22:06:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.73654,
				"lng": -122.393237,
				"zipcode": "94124",
				"subPremise": "",
				"address": "1764 Oakdale Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1254500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 895000,
			"daysOnMovoto": 67,
			"id": "27c0c821-1b78-46aa-9cfe-2a81274fbf01",
			"tnImgPath": "https://pi.movoto.com/p/110/421611178_0_3VEyif_p.jpeg",
			"listDate": "2021-10-25 00:00:00",
			"listingAgent": "Ludovico Mazzola",
			"listPrice": 895000,
			"lotSize": 0,
			"sqftTotal": 714,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421611178",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 46,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": null,
			"zipCode": "94102",
			"path": "san-francisco-ca/388-fulton-st-apt-311-san-francisco-ca-94102-110_448912/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c6bdbc33-adea-4050-b9d4-643d43869ce2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-11-12 19:56:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.778824,
				"lng": -122.42298,
				"zipcode": "94102",
				"subPremise": "APT 311",
				"address": "388 Fulton St #311"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 895000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 85,
			"id": "578516ae-249e-4de7-b544-5d54aaeeb82e",
			"tnImgPath": "https://pi.movoto.com/p/110/421601050_0_z7bVEm_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Laurent Martini",
			"listPrice": 1315000,
			"lotSize": 48636,
			"sqftTotal": 1440,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421601050",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pacific Realty",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1993,
			"zipCode": "94109",
			"path": "san-francisco-ca/1483-sutter-st-1005-san-francisco-ca-94109/pid_5dswoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -85000,
			"propertyId": "99622c62-2902-4f31-9d29-3326db8011a4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-10-07 17:16:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.786774,
				"lng": -122.424136,
				"zipcode": "94109",
				"subPremise": "APT 1005",
				"address": "1483 Sutter St #1005"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 825000,
			"daysOnMovoto": 91,
			"id": "72560a7f-79e8-45bb-8273-58c2904f1de1",
			"tnImgPath": "https://pi.movoto.com/p/110/421605752_0_zyie2I_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Michelle Pender",
			"listPrice": 779000,
			"lotSize": 1751,
			"sqftTotal": 0,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421605752",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Engel & Voelkers San Francisco",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condo/Coop/TIC/Loft",
			"yearBuilt": 1954,
			"zipCode": "94110",
			"path": "san-francisco-ca/67-prospect-ave-san-francisco-ca-94110-110_366316/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a882570a-c018-4e00-8fb7-042347402789",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-10-22 22:31:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.743814,
				"lng": -122.418224,
				"zipcode": "94110",
				"subPremise": "",
				"address": "67 Prospect Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1066000,
			"daysOnMovoto": 92,
			"id": "0c588f29-1d08-4e08-b953-37eb57a8a0cd",
			"tnImgPath": "https://pi.movoto.com/p/110/421599723_0_3QNYB7_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Steven Cervantes",
			"listPrice": 990000,
			"lotSize": 2247,
			"sqftTotal": 1680,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421599723",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1908,
			"zipCode": "94134",
			"path": "san-francisco-ca/251-ward-st-san-francisco-ca-94134/pid_x7qzoad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -109000,
			"propertyId": "ff06e9df-2083-43ef-89e2-e4564e9bdd25",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-09-30 17:46:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.718367,
				"lng": -122.402857,
				"zipcode": "94134",
				"subPremise": "",
				"address": "251 Ward St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1066000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 130,
			"id": "c05a36a8-23a5-4b23-b585-b436754b0349",
			"tnImgPath": "https://pi.movoto.com/p/110/421615264_0_Ye2FR2_p.jpeg",
			"listDate": "2021-08-23 00:00:00",
			"listingAgent": "Stacey Caen",
			"listPrice": 1250000,
			"lotSize": 5279,
			"sqftTotal": 918,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615264",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1928,
			"zipCode": "94109",
			"path": "san-francisco-ca/1101-green-st-603-san-francisco-ca-94109/pid_ek9voad48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9bf09aa6-d508-43a8-a548-1efaa7dfd23b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-12-04 21:31:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.798257,
				"lng": -122.417411,
				"zipcode": "94109",
				"subPremise": "APT 603",
				"address": "1101 Green St #603"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1315000,
			"daysOnMovoto": 28,
			"id": "faa1a3f5-140a-41f5-9dc5-2ee8a035b26a",
			"tnImgPath": "https://pi.movoto.com/p/110/421614584_0_imffuB_p.jpeg",
			"listDate": "2021-12-02 00:00:00",
			"listingAgent": "Tim Brown",
			"listPrice": 1279000,
			"lotSize": 5127,
			"sqftTotal": 1129,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614584",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condo/Coop/TIC/Loft",
			"yearBuilt": 1900,
			"zipCode": "94117",
			"path": "san-francisco-ca/38-steiner-st-apt-103-san-francisco-ca-94117-110_421614584/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "02b01b5c-12f8-4368-9687-f74952c76147",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-12-02 17:36:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:00:00",
			"virtualTourLink": "http://www.livedubocepark.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.770216,
				"lng": -122.431628,
				"zipcode": "94117",
				"subPremise": "APT 103",
				"address": "38 Steiner St #103"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1315000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.livedubocepark.com"
		},
		{
			"closePrice": 1225000,
			"daysOnMovoto": 48,
			"id": "d3c0050f-fe89-4d53-b242-0e691bc39942",
			"tnImgPath": "https://pi.movoto.com/p/110/421611065_0_AVbvyu_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Tim Brown",
			"listPrice": 1095000,
			"lotSize": 5127,
			"sqftTotal": 0,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421611065",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condo/Coop/TIC/Loft",
			"yearBuilt": 1900,
			"zipCode": "94117",
			"path": "san-francisco-ca/42-steiner-st-san-francisco-ca-94117-110_421611065/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1536b34d-4358-4531-9eb6-f1b9e2750423",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-12 19:36:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": "http://www.livedubocepark.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Francisco",
				"lat": 37.769922,
				"lng": -122.431503,
				"zipcode": "94117",
				"subPremise": "",
				"address": "42 Steiner St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.livedubocepark.com"
		}
	]