exports.WOODSIDE_LISTINGS = [
		{
			"closePrice": 1750000,
			"daysOnMovoto": 109,
			"id": "02352f88-528f-4bf4-870b-79857b34c979",
			"tnImgPath": "https://pi.movoto.com/p/110/421584325_0_QzBAf7_p.jpeg",
			"listDate": "2021-07-12 00:00:00",
			"listingAgent": "Fatemah Nikchehi",
			"listPrice": 1800000,
			"lotSize": 50990,
			"sqftTotal": 2480,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421584325",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Own Real Estate",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1992,
			"zipCode": "94062",
			"path": "woodside-ca/147-comstock-rd-woodside-ca-94062-110_421584325/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "ccb7ac94-51b1-4d07-bbc4-ccdbb8dc1ff4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-08-18 00:51:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/588114665",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.445163,
				"lng": -122.331168,
				"zipcode": "94062",
				"subPremise": "",
				"address": "147 Comstock Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/588114665"
		},
		{
			"closePrice": 6050000,
			"daysOnMovoto": 36,
			"id": "cc8bb2cf-3335-49ba-affa-eb34a7c6d070",
			"tnImgPath": "https://pi.movoto.com/p/110/421572837_0_UjNN3b_p.jpeg",
			"listDate": "2021-07-04 00:00:00",
			"listingAgent": "Andres Ardila",
			"listPrice": 5485000,
			"lotSize": 43996,
			"sqftTotal": 4370,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421572837",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 77,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2005,
			"zipCode": "94062",
			"path": "woodside-ca/165-brookwood-rd-woodside-ca-94062-110_421572837/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fd93e774-e082-43c3-a5a4-991ec60390c5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-09 00:00:00",
			"createdAt": "2021-08-05 19:31:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-04 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/583609586",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.445854,
				"lng": -122.246755,
				"zipcode": "94062",
				"subPremise": "",
				"address": "165 Brookwood Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/583609586"
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 19,
			"id": "5d7acc3f-7f9a-405f-a4c9-a868e373adbd",
			"tnImgPath": "https://pi.movoto.com/p/12/40955555_0_BMBzUr_p.jpeg",
			"listDate": "2021-06-24 00:00:00",
			"listingAgent": "Shani Muscat",
			"listPrice": 1795000,
			"lotSize": 12316,
			"sqftTotal": 1770,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955555",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty ONE Group Future",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94062",
			"path": "woodside-ca/8-blakewood-way-woodside-ca-94062-100_ml80533811/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "be01336e-e93d-478a-817c-93ab7442f795",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-13 00:00:00",
			"createdAt": "2021-06-24 19:11:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-24 00:00:00",
			"virtualTourLink": "Https://8Blakewood42498mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.387739,
				"lng": -122.267448,
				"zipcode": "94062",
				"subPremise": "",
				"address": "8 Blakewood Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://8Blakewood42498mls.f8re.com/"
		},
		{
			"closePrice": 5256440,
			"daysOnMovoto": 63,
			"id": "6ab7879a-86b6-4bfa-a2fa-44c2aaf9a1f0",
			"tnImgPath": "https://pi.movoto.com/p/12/40948992_0_rN6QNY_p.jpeg",
			"listDate": "2021-05-07 00:00:00",
			"listingAgent": "Mary Byrne Hernandez",
			"listPrice": 5688000,
			"lotSize": 44507,
			"sqftTotal": 3467,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40948992",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "MBH Properties",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "94062",
			"path": "woodside-ca/180-brookwood-woodside-ca-94062-12_40948992/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -300000,
			"propertyId": "f064d260-e9e9-4888-8525-1fef204df153",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-09 00:00:00",
			"createdAt": "2021-05-07 22:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-07 00:00:00",
			"virtualTourLink": "Https://180brookwoodrd.com/1829605?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.447115,
				"lng": -122.247287,
				"zipcode": "94062",
				"subPremise": "",
				"address": "180 Brookwood"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5256440,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://180brookwoodrd.com/1829605?idx=1"
		},
		{
			"closePrice": 3400000,
			"daysOnMovoto": 104,
			"id": "3cd48bcc-edd9-4400-b8b6-9af3bc5d005e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830388_0_rVMbNn_p.jpeg",
			"listDate": "2021-02-18 14:46:09",
			"listingAgent": "Scott Hayes",
			"listPrice": 3498000,
			"lotSize": 4258426,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830388",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Development Land",
			"yearBuilt": null,
			"zipCode": "94062",
			"path": "woodside-ca/0-la-honda-woodside-ca-94062-100_ml81830388/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "441624c6-6783-4e5a-8bb3-60553fbbf785",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-03 00:00:00",
			"createdAt": "2021-02-18 22:52:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 14:46:09",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"zipcode": "94062",
				"subPremise": "",
				"address": "0 La Honda"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 1135,
			"id": "7c2bbbd5-0e98-45f7-bc12-2b62eb29209a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81700401_0_YEuY3U_p.jpeg",
			"listDate": "2018-04-12 00:00:00",
			"listingAgent": "Wayne Rivas",
			"listPrice": 1450000,
			"lotSize": 159342,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81700401",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 12,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94062",
			"path": "woodside-ca/12-montecito-rd-woodside-ca-94062-100_ml81700401/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -40000,
			"propertyId": "17fb7c30-65d4-429e-8f1f-bda167e3a74b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-21 00:00:00",
			"createdAt": "2018-04-12 18:57:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2018-04-12 00:00:00",
			"virtualTourLink": "Http://www.tourfactory.com/idxr422896",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.38943,
				"lng": -122.242199,
				"zipcode": "94062",
				"subPremise": "",
				"address": "12 Montecito Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.tourfactory.com/idxr422896"
		},
		{
			"closePrice": 5500000,
			"daysOnMovoto": 158,
			"id": "77e7d0da-06d5-4a30-aec4-ecfdc090981e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81817077_0_iRRUYf_p.jpeg",
			"listDate": "2020-10-23 08:58:47",
			"listingAgent": "Monique Lombardelli",
			"listPrice": 5888000,
			"lotSize": 73921,
			"sqftTotal": 4200,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81817077",
			"bath": 5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Modern Homes Realty",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "94062",
			"path": "woodside-ca/161-eleanor-dr-woodside-ca-94062-100_ml81028091/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7fb7c5c-720c-4f99-a67a-972a3d3898bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-31 00:00:00",
			"createdAt": "2020-10-23 16:02:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-23 08:58:47",
			"virtualTourLink": "Https://my.matterport.com/show/?m=Gtsr5fog6tz&brand=0\"",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.439455,
				"lng": -122.234409,
				"zipcode": "94062",
				"subPremise": "",
				"address": "161 Eleanor Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=Gtsr5fog6tz&brand=0\""
		},
		{
			"closePrice": 3700000,
			"daysOnMovoto": 36,
			"id": "8b97619d-3496-46e8-9a61-ffdc04f48075",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830002_0_y3nVei_p.jpeg",
			"listDate": "2021-02-16 11:40:35",
			"listingAgent": "Erika Demma",
			"listPrice": 3595000,
			"lotSize": 17772,
			"sqftTotal": 2771,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830002",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2012,
			"zipCode": "94062",
			"path": "woodside-ca/750-w-california-way-woodside-ca-94062-100_ml81206643/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0fa04ae8-1a01-4819-8587-c341c09b816e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-02-16 19:42:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-16 11:40:35",
			"virtualTourLink": "Http://750westcalifornia.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.455398,
				"lng": -122.273807,
				"zipcode": "94062",
				"subPremise": "",
				"address": "750 W California Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://750westcalifornia.com/"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 21,
			"id": "57d14083-f201-42bd-b27c-a482ffb48fa0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832144_0_iFZvrU_p.jpeg",
			"listDate": "2021-03-02 16:29:17",
			"listingAgent": "Allen Nazari",
			"listPrice": 1350000,
			"lotSize": 53901,
			"sqftTotal": 1970,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832144",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Park Lane Capital Inc",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1925,
			"zipCode": "94062",
			"path": "woodside-ca/35-sequoia-way-woodside-ca-94062-100_ml80339389/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "96ec0e2b-c389-4ff8-b408-2ab69ea0c2ee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-24 00:00:00",
			"createdAt": "2021-03-03 00:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-02 16:29:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.381563,
				"lng": -122.261697,
				"zipcode": "94062",
				"subPremise": "",
				"address": "35 Sequoia Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4850000,
			"daysOnMovoto": 15,
			"id": "878673fe-345a-4c5b-8837-a21212d8214d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832853_0_26r662_p.jpeg",
			"listDate": "2021-03-06 08:17:56",
			"listingAgent": "Erika Demma",
			"listPrice": 4495000,
			"lotSize": 58370,
			"sqftTotal": 3650,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832853",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1963,
			"zipCode": "94062",
			"path": "woodside-ca/3710-tripp-rd-woodside-ca-94062-100_ml80301986/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "75753158-4500-4c86-acb1-cb40ac826516",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-03-06 16:22:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-06 08:17:56",
			"virtualTourLink": "Http://3710tripp.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.4195,
				"lng": -122.269966,
				"zipcode": "94062",
				"subPremise": "",
				"address": "3710 Tripp Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://3710tripp.com/"
		},
		{
			"closePrice": 4625000,
			"daysOnMovoto": 55,
			"id": "9ffb61ff-2226-460a-b080-9e1db96b75ba",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826798_0_RFmeam_p.jpeg",
			"listDate": "2021-01-22 09:31:32",
			"listingAgent": "Brent Gullixson",
			"listPrice": 4695000,
			"lotSize": 43573,
			"sqftTotal": 3290,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826798",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1978,
			"zipCode": "94062",
			"path": "woodside-ca/311-ridgeway-rd-woodside-ca-94062-100_ml81654548/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "ac588434-c21d-4e39-ad73-f8fe5ae586e5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-01-29 16:32:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-22 09:31:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.445419,
				"lng": -122.253784,
				"zipcode": "94062",
				"subPremise": "",
				"address": "311 Ridgeway Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4625000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 74,
			"id": "94479055-6e0b-4699-ab8c-ead3deaacd25",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824334_0_eMBjeA_p.jpeg",
			"listDate": "2020-12-31 16:30:46",
			"listingAgent": "Scott Dancer",
			"listPrice": 1149000,
			"lotSize": 17398,
			"sqftTotal": 865,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824334",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 12,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1930,
			"zipCode": "94062",
			"path": "woodside-ca/232-blakewood-way-woodside-ca-94062-100_ml81212885/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -51000,
			"propertyId": "43fa68df-6173-4ea8-bb12-1f692a954a89",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-16 00:00:00",
			"createdAt": "2021-01-01 00:37:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-31 16:30:46",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.388166,
				"lng": -122.268491,
				"zipcode": "94062",
				"subPremise": "",
				"address": "232 Blakewood Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 87,
			"id": "ba953ed7-290a-4ec4-9764-8618e07c0fec",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823194_0_Zz67um_p.jpeg",
			"listDate": "2020-12-14 11:30:59",
			"listingAgent": "Vicki Ferrando",
			"listPrice": 2198000,
			"lotSize": 161956,
			"sqftTotal": 2505,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823194",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "94062",
			"path": "woodside-ca/885-espinosa-rd-woodside-ca-94062-100_ml81654380/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1ddc98f2-b81d-47d9-8286-a33c40978b1d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-12 00:00:00",
			"createdAt": "2020-12-16 17:57:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-14 11:30:59",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2824065",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.387679,
				"lng": -122.254964,
				"zipcode": "94062",
				"subPremise": "",
				"address": "885 Espinosa Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2824065"
		},
		{
			"closePrice": 5200000,
			"daysOnMovoto": 14,
			"id": "d8433c9a-b831-4d0e-9f64-9ce2d8623af5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830120_0_N2Bbju_p.jpeg",
			"listDate": "2021-02-17 00:00:00",
			"listingAgent": "Scott Dancer",
			"listPrice": 4750000,
			"lotSize": 43647,
			"sqftTotal": 3690,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830120",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1987,
			"zipCode": "94062",
			"path": "woodside-ca/1589-our-hill-ln-woodside-ca-94062-100_ml81830120/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cf28bec0-eb56-4777-b7ba-0a8347ff5e4d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-03 00:00:00",
			"createdAt": "2021-02-17 16:47:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2835628",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.429995,
				"lng": -122.251497,
				"zipcode": "94062",
				"subPremise": "",
				"address": "1589 Our Hill Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2835628"
		},
		{
			"closePrice": 5520000,
			"daysOnMovoto": 28,
			"id": "6e0e0874-320a-4f86-b37a-1e31c83a243b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827924_0_QVIien_p.jpeg",
			"listDate": "2021-02-01 09:40:51",
			"listingAgent": "Helen Miller",
			"listPrice": 4950000,
			"lotSize": 88732,
			"sqftTotal": 4195,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827924",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1950,
			"zipCode": "94062",
			"path": "woodside-ca/91-tum-suden-way-woodside-ca-94062-100_ml80922514/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "40fdc373-31b6-4096-a45e-255c3cf48774",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-02 00:00:00",
			"createdAt": "2021-02-01 17:47:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 09:40:51",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2825283",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.453097,
				"lng": -122.268975,
				"zipcode": "94062",
				"subPremise": "",
				"address": "91 Tum Suden Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5520000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2825283"
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 26,
			"id": "09c1bca4-afc1-49b1-96b9-79555700b284",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826875_0_rI7IBQ_p.jpeg",
			"listDate": "2021-01-22 14:14:35",
			"listingAgent": "Ginny&John Kavanaugh",
			"listPrice": 3200000,
			"lotSize": 263277,
			"sqftTotal": 2930,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826875",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1977,
			"zipCode": "94062",
			"path": "woodside-ca/7-montecito-rd-woodside-ca-94062-100_ml81826875/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ddcdd852-25f1-4d73-902b-fa404226a16e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-18 00:00:00",
			"createdAt": "2021-01-22 22:17:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-22 14:14:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.390003,
				"lng": -122.242904,
				"zipcode": "94062",
				"subPremise": "",
				"address": "7 Montecito Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4500000,
			"daysOnMovoto": 86,
			"id": "075208f0-5ac2-481b-96e3-10b737f1e723",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81820946_0_EZbyRi_p.jpeg",
			"listDate": "2020-11-22 00:00:00",
			"listingAgent": "Kimberly Hansen",
			"listPrice": 4800000,
			"lotSize": 134818,
			"sqftTotal": 3440,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81820946",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1979,
			"zipCode": "94062",
			"path": "woodside-ca/6-friars-ln-woodside-ca-94062-100_ml81313135/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7db04f46-32a8-42fe-8404-ea769f834724",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-16 00:00:00",
			"createdAt": "2020-11-23 05:47:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-22 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2817579",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.393437,
				"lng": -122.25752,
				"zipcode": "94062",
				"subPremise": "",
				"address": "6 Friars Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2817579"
		},
		{
			"closePrice": 1325000,
			"daysOnMovoto": 113,
			"id": "e38e75e8-9583-4b28-a094-685afb495138",
			"tnImgPath": "https://pi.movoto.com/p/12/40926267_0_UmvjqY_p.jpeg",
			"listDate": "2020-10-21 00:00:00",
			"listingAgent": "Martin Pham",
			"listPrice": 1375000,
			"lotSize": 25286,
			"sqftTotal": 1776,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40926267",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "TN P Real Estate Investment",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1930,
			"zipCode": "94062",
			"path": "woodside-ca/112-comstock-rd-woodside-ca-94062-100_ml81668610/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "753531ab-ad89-45da-bf78-e379cc0f176c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-11 00:00:00",
			"createdAt": "2020-10-21 18:46:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-21 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=qo6FQajq5k3",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.446008,
				"lng": -122.332872,
				"zipcode": "94062",
				"subPremise": "",
				"address": "112 Comstock Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=qo6FQajq5k3"
		},
		{
			"closePrice": 3573000,
			"daysOnMovoto": 45,
			"id": "baa3537c-a863-4f03-bb9d-08c867461cea",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81800938_0_aY6YaZ_p.jpeg",
			"listDate": "2020-12-22 00:00:00",
			"listingAgent": "Alex H. Wang",
			"listPrice": 3298000,
			"lotSize": 14876,
			"sqftTotal": 2300,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81800938",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1946,
			"zipCode": "94062",
			"path": "woodside-ca/806-w-california-way-woodside-ca-94062-100_ml81106118/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fe7cd667-e550-45e2-b64d-fc6e86c7b127",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-05 00:00:00",
			"createdAt": "2021-01-20 01:27:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Woodside",
				"lat": 37.454702,
				"lng": -122.27213,
				"zipcode": "94062",
				"subPremise": "",
				"address": "806 W California Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3573000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]