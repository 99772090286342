const redwood_city = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "San Mateo County, CA",
             "short_name": "San Mateo County",
             "sumlevel": "050",
             "land_area": 1161957020,
             "full_geoid": "05000US06081",
             "total_population": 766573
          },
          "CBSA": {
             "full_name": "San Francisco-Oakland-Berkeley, CA Metro Area",
             "short_name": "San Francisco-Oakland-Berkeley, CA",
             "sumlevel": "310",
             "land_area": 6398229497,
             "full_geoid": "31000US41860",
             "total_population": 4731803
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39512223
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Redwood City, CA",
          "short_name": "Redwood City",
          "sumlevel": "160",
          "land_area": 49899123,
          "full_geoid": "16000US0660102",
          "total_population": 85926,
          "sumlevel_name": "Place",
          "short_geoid": "0660102"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 20.3,
                   "CBSA": 19.53,
                   "state": 22.5
                },
                "error": {
                   "this": 2.7,
                   "CBSA": 0.2,
                   "state": 0.08
                },
                "numerators": {
                   "this": 17445,
                   "CBSA": 924120,
                   "state": 8891064
                },
                "numerator_errors": {
                   "this": 2318.1,
                   "CBSA": 9455,
                   "state": 32008.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 90
                },
                "error_ratio": {
                   "this": 13.3,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 66.01,
                   "CBSA": 64.56,
                   "state": 62.73
                },
                "error": {
                   "this": 4.48,
                   "CBSA": 0.37,
                   "state": 0.13
                },
                "numerators": {
                   "this": 56716,
                   "CBSA": 3054854,
                   "state": 2.478616E7
                },
                "numerator_errors": {
                   "this": 3847.5,
                   "CBSA": 17723.5,
                   "state": 51870.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 105
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 0.6,
                   "state": 0.2
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 13.69,
                   "CBSA": 15.91,
                   "state": 14.77
                },
                "error": {
                   "this": 2.01,
                   "CBSA": 0.24,
                   "state": 0.08
                },
                "numerators": {
                   "this": 11765,
                   "CBSA": 752829,
                   "state": 5834998
                },
                "numerator_errors": {
                   "this": 1726.9,
                   "CBSA": 11568.6,
                   "state": 32031.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 93
                },
                "error_ratio": {
                   "this": 14.7,
                   "CBSA": 1.5,
                   "state": 0.5
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 12.26,
                      "CBSA": 10.64,
                      "state": 12.04
                   },
                   "error": {
                      "this": 1.95,
                      "CBSA": 0.15,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 10533,
                      "CBSA": 503528,
                      "state": 4758601
                   },
                   "numerator_errors": {
                      "this": 1679.1,
                      "CBSA": 6865.4,
                      "state": 23052.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 115,
                      "state": 102
                   },
                   "error_ratio": {
                      "this": 15.9,
                      "CBSA": 1.4,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 8.93,
                      "CBSA": 11.03,
                      "state": 13.1
                   },
                   "error": {
                      "this": 1.92,
                      "CBSA": 0.14,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 7674,
                      "CBSA": 521896,
                      "state": 5176959
                   },
                   "numerator_errors": {
                      "this": 1653.5,
                      "CBSA": 6799.8,
                      "state": 23034.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 81,
                      "state": 68
                   },
                   "error_ratio": {
                      "this": 21.5,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 14.33,
                      "CBSA": 13.42,
                      "state": 14.49
                   },
                   "error": {
                      "this": 2.05,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 12314,
                      "CBSA": 634875,
                      "state": 5723923
                   },
                   "numerator_errors": {
                      "this": 1765,
                      "CBSA": 7995.9,
                      "state": 24219.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 14.3,
                      "CBSA": 1.3,
                      "state": 0.4
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 17.34,
                      "CBSA": 16.12,
                      "state": 14.47
                   },
                   "error": {
                      "this": 2.37,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 14903,
                      "CBSA": 762863,
                      "state": 5715837
                   },
                   "numerator_errors": {
                      "this": 2038.1,
                      "CBSA": 8035.9,
                      "state": 22852.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 108,
                      "state": 120
                   },
                   "error_ratio": {
                      "this": 13.7,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.73,
                      "CBSA": 13.71,
                      "state": 12.77
                   },
                   "error": {
                      "this": 1.95,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 11794,
                      "CBSA": 648707,
                      "state": 5046965
                   },
                   "numerator_errors": {
                      "this": 1674.2,
                      "CBSA": 8025.4,
                      "state": 22530.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 14.2,
                      "CBSA": 1.2,
                      "state": 0.5
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.71,
                      "CBSA": 13.12,
                      "state": 12.46
                   },
                   "error": {
                      "this": 2.01,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 11780,
                      "CBSA": 620802,
                      "state": 4923782
                   },
                   "numerator_errors": {
                      "this": 1724.2,
                      "CBSA": 7116.2,
                      "state": 20800
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 110
                   },
                   "error_ratio": {
                      "this": 14.7,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.01,
                      "CBSA": 10.91,
                      "state": 10.63
                   },
                   "error": {
                      "this": 1.79,
                      "CBSA": 0.23,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 8600,
                      "CBSA": 516448,
                      "state": 4200080
                   },
                   "numerator_errors": {
                      "this": 1542.3,
                      "CBSA": 10661.7,
                      "state": 31191.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 92,
                      "state": 94
                   },
                   "error_ratio": {
                      "this": 17.9,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 5.4,
                      "CBSA": 7.02,
                      "state": 6.44
                   },
                   "error": {
                      "this": 1.09,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 4641,
                      "CBSA": 332170,
                      "state": 2545061
                   },
                   "numerator_errors": {
                      "this": 934,
                      "CBSA": 7111.4,
                      "state": 19797.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 77,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 20.2,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.29,
                      "CBSA": 4.03,
                      "state": 3.6
                   },
                   "error": {
                      "this": 1.33,
                      "CBSA": 0.13,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 3687,
                      "CBSA": 190514,
                      "state": 1421015
                   },
                   "numerator_errors": {
                      "this": 1144.5,
                      "CBSA": 6014.8,
                      "state": 16282.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 106,
                      "state": 119
                   },
                   "error_ratio": {
                      "this": 31,
                      "CBSA": 3.2,
                      "state": 1.1
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.04,
                      "CBSA": 11.01,
                      "state": 12.35
                   },
                   "error": {
                      "this": 2.9,
                      "CBSA": 0.22,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 4534,
                      "CBSA": 257668,
                      "state": 2425066
                   },
                   "numerator_errors": {
                      "this": 1212.5,
                      "CBSA": 5167.5,
                      "state": 17537.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 26.3,
                      "CBSA": 2,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 8.56,
                      "CBSA": 11.23,
                      "state": 13.47
                   },
                   "error": {
                      "this": 2.9,
                      "CBSA": 0.22,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 3517,
                      "CBSA": 262840,
                      "state": 2646132
                   },
                   "numerator_errors": {
                      "this": 1205.9,
                      "CBSA": 5150.6,
                      "state": 17211.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 76,
                      "state": 64
                   },
                   "error_ratio": {
                      "this": 33.9,
                      "CBSA": 2,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 16.19,
                      "CBSA": 13.8,
                      "state": 15.06
                   },
                   "error": {
                      "this": 2.95,
                      "CBSA": 0.22,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 6648,
                      "CBSA": 322820,
                      "state": 2956969
                   },
                   "numerator_errors": {
                      "this": 1258.7,
                      "CBSA": 5263.7,
                      "state": 16123.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 117,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 18.2,
                      "CBSA": 1.6,
                      "state": 0.5
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 17.46,
                      "CBSA": 16.65,
                      "state": 14.93
                   },
                   "error": {
                      "this": 2.87,
                      "CBSA": 0.27,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 7170,
                      "CBSA": 389458,
                      "state": 2932306
                   },
                   "numerator_errors": {
                      "this": 1232.5,
                      "CBSA": 6428.5,
                      "state": 17684.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 117
                   },
                   "error_ratio": {
                      "this": 16.4,
                      "CBSA": 1.6,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.58,
                      "CBSA": 13.86,
                      "state": 12.82
                   },
                   "error": {
                      "this": 2.75,
                      "CBSA": 0.26,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 5575,
                      "CBSA": 324176,
                      "state": 2518785
                   },
                   "numerator_errors": {
                      "this": 1166.3,
                      "CBSA": 6099,
                      "state": 17201
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 106
                   },
                   "error_ratio": {
                      "this": 20.3,
                      "CBSA": 1.9,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 14.92,
                      "CBSA": 13.33,
                      "state": 12.42
                   },
                   "error": {
                      "this": 3.06,
                      "CBSA": 0.24,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 6129,
                      "CBSA": 311977,
                      "state": 2439055
                   },
                   "numerator_errors": {
                      "this": 1293.1,
                      "CBSA": 5512.8,
                      "state": 15253.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 112,
                      "state": 120
                   },
                   "error_ratio": {
                      "this": 20.5,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.64,
                      "CBSA": 10.47,
                      "state": 10.2
                   },
                   "error": {
                      "this": 2.61,
                      "CBSA": 0.33,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 4371,
                      "CBSA": 244988,
                      "state": 2004301
                   },
                   "numerator_errors": {
                      "this": 1095.3,
                      "CBSA": 7814.5,
                      "state": 21480.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 104
                   },
                   "error_ratio": {
                      "this": 24.5,
                      "CBSA": 3.2,
                      "state": 1.1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.06,
                      "CBSA": 6.41,
                      "state": 5.89
                   },
                   "error": {
                      "this": 1.24,
                      "CBSA": 0.21,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 1667,
                      "CBSA": 150043,
                      "state": 1156395
                   },
                   "numerator_errors": {
                      "this": 515.4,
                      "CBSA": 4926.3,
                      "state": 13944.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 63,
                      "state": 69
                   },
                   "error_ratio": {
                      "this": 30.5,
                      "CBSA": 3.3,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.55,
                      "CBSA": 3.24,
                      "state": 2.86
                   },
                   "error": {
                      "this": 1.36,
                      "CBSA": 0.16,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 1456,
                      "CBSA": 75746,
                      "state": 561785
                   },
                   "numerator_errors": {
                      "this": 562.6,
                      "CBSA": 3743.5,
                      "state": 10571.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 110,
                      "state": 124
                   },
                   "error_ratio": {
                      "this": 38.3,
                      "CBSA": 4.9,
                      "state": 1.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13.37,
                      "CBSA": 10.28,
                      "state": 11.74
                   },
                   "error": {
                      "this": 2.51,
                      "CBSA": 0.19,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 5999,
                      "CBSA": 245860,
                      "state": 2333535
                   },
                   "numerator_errors": {
                      "this": 1161.6,
                      "CBSA": 4520.1,
                      "state": 14962.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 130,
                      "state": 114
                   },
                   "error_ratio": {
                      "this": 18.8,
                      "CBSA": 1.8,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 9.27,
                      "CBSA": 10.83,
                      "state": 12.74
                   },
                   "error": {
                      "this": 2.48,
                      "CBSA": 0.19,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 4157,
                      "CBSA": 259056,
                      "state": 2530827
                   },
                   "numerator_errors": {
                      "this": 1131.3,
                      "CBSA": 4439.4,
                      "state": 15307.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 86,
                      "state": 73
                   },
                   "error_ratio": {
                      "this": 26.8,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 12.63,
                      "CBSA": 13.05,
                      "state": 13.92
                   },
                   "error": {
                      "this": 2.69,
                      "CBSA": 0.25,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 5666,
                      "CBSA": 312055,
                      "state": 2766954
                   },
                   "numerator_errors": {
                      "this": 1237.3,
                      "CBSA": 6019,
                      "state": 18072.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 21.3,
                      "CBSA": 1.9,
                      "state": 0.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 17.24,
                      "CBSA": 15.61,
                      "state": 14.01
                   },
                   "error": {
                      "this": 3.53,
                      "CBSA": 0.2,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 7733,
                      "CBSA": 373405,
                      "state": 2783531
                   },
                   "numerator_errors": {
                      "this": 1623.1,
                      "CBSA": 4821.8,
                      "state": 14474.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 110,
                      "state": 123
                   },
                   "error_ratio": {
                      "this": 20.5,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.86,
                      "CBSA": 13.57,
                      "state": 12.72
                   },
                   "error": {
                      "this": 2.6,
                      "CBSA": 0.22,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6219,
                      "CBSA": 324531,
                      "state": 2528180
                   },
                   "numerator_errors": {
                      "this": 1201.1,
                      "CBSA": 5216.2,
                      "state": 14551.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 18.8,
                      "CBSA": 1.6,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 12.6,
                      "CBSA": 12.91,
                      "state": 12.5
                   },
                   "error": {
                      "this": 2.47,
                      "CBSA": 0.19,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 5651,
                      "CBSA": 308825,
                      "state": 2484727
                   },
                   "numerator_errors": {
                      "this": 1140.6,
                      "CBSA": 4500,
                      "state": 14140.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 101
                   },
                   "error_ratio": {
                      "this": 19.6,
                      "CBSA": 1.5,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.43,
                      "CBSA": 11.35,
                      "state": 11.05
                   },
                   "error": {
                      "this": 2.38,
                      "CBSA": 0.3,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 4229,
                      "CBSA": 271460,
                      "state": 2195779
                   },
                   "numerator_errors": {
                      "this": 1085.8,
                      "CBSA": 7253,
                      "state": 22615.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 83,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 25.2,
                      "CBSA": 2.6,
                      "state": 1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.63,
                      "CBSA": 7.61,
                      "state": 6.99
                   },
                   "error": {
                      "this": 1.71,
                      "CBSA": 0.21,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 2974,
                      "CBSA": 182127,
                      "state": 1388666
                   },
                   "numerator_errors": {
                      "this": 779,
                      "CBSA": 5128.7,
                      "state": 14053.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 87,
                      "state": 95
                   },
                   "error_ratio": {
                      "this": 25.8,
                      "CBSA": 2.8,
                      "state": 1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.97,
                      "CBSA": 4.8,
                      "state": 4.32
                   },
                   "error": {
                      "this": 2.21,
                      "CBSA": 0.2,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 2231,
                      "CBSA": 114768,
                      "state": 859230
                   },
                   "numerator_errors": {
                      "this": 996.7,
                      "CBSA": 4708,
                      "state": 12383.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 115
                   },
                   "error_ratio": {
                      "this": 44.5,
                      "CBSA": 4.2,
                      "state": 1.4
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 38.3,
                   "CBSA": 39.2,
                   "state": 37
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 104
                },
                "error_ratio": {
                   "this": 5.7,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 38,
                   "CBSA": 38.3,
                   "state": 35.9
                },
                "error": {
                   "this": 2.7,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 106
                },
                "error_ratio": {
                   "this": 7.1,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 38.4,
                   "CBSA": 40.2,
                   "state": 38.2
                },
                "error": {
                   "this": 2.7,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 101
                },
                "error_ratio": {
                   "this": 7,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 47.79,
                "CBSA": 49.45,
                "state": 49.71
             },
             "error": {
                "this": 2.43,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 41067,
                "CBSA": 2339716,
                "state": 1.9640794E7
             },
             "numerator_errors": {
                "this": 2090,
                "CBSA": 2867,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 96
             },
             "error_ratio": {
                "this": 5.1,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 52.21,
                "CBSA": 50.55,
                "state": 50.29
             },
             "error": {
                "this": 2.43,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 44859,
                "CBSA": 2392087,
                "state": 1.9871428E7
             },
             "numerator_errors": {
                "this": 2091,
                "CBSA": 2867,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 104
             },
             "error_ratio": {
                "this": 4.7,
                "CBSA": 0.1,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 46.92,
                "CBSA": 38.62,
                "state": 36.33
             },
             "error": {
                "this": 4.7,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 40313,
                "CBSA": 1827248,
                "state": 1.4356081E7
             },
             "numerator_errors": {
                "this": 4036,
                "CBSA": 2941,
                "state": 6353
             },
             "index": {
                "this": 100,
                "CBSA": 121,
                "state": 129
             },
             "error_ratio": {
                "this": 10,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 1.31,
                "CBSA": 7.22,
                "state": 5.5
             },
             "error": {
                "this": 0.7,
                "CBSA": 0.09,
                "state": 0.04
             },
             "numerators": {
                "this": 1126,
                "CBSA": 341500,
                "state": 2171989
             },
             "numerator_errors": {
                "this": 601,
                "CBSA": 4426,
                "state": 16630
             },
             "index": {
                "this": 100,
                "CBSA": 18,
                "state": 24
             },
             "error_ratio": {
                "this": 53.4,
                "CBSA": 1.2,
                "state": 0.7
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.83,
                "CBSA": 0.24,
                "state": 0.38
             },
             "error": {
                "this": 0.92,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 716,
                "CBSA": 11524,
                "state": 149063
             },
             "numerator_errors": {
                "this": 789,
                "CBSA": 1876,
                "state": 5527
             },
             "index": {
                "this": 100,
                "CBSA": 346,
                "state": 218
             },
             "error_ratio": {
                "this": 110.8,
                "CBSA": 16.7,
                "state": 2.6
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 15.48,
                "CBSA": 26.82,
                "state": 14.65
             },
             "error": {
                "this": 3.27,
                "CBSA": 0.18,
                "state": 0.05
             },
             "numerators": {
                "this": 13303,
                "CBSA": 1269163,
                "state": 5786711
             },
             "numerator_errors": {
                "this": 2807,
                "CBSA": 8649,
                "state": 19627
             },
             "index": {
                "this": 100,
                "CBSA": 58,
                "state": 106
             },
             "error_ratio": {
                "this": 21.1,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 1.87,
                "CBSA": 0.63,
                "state": 0.36
             },
             "error": {
                "this": 1.38,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 1610,
                "CBSA": 29935,
                "state": 141846
             },
             "numerator_errors": {
                "this": 1189,
                "CBSA": 1990,
                "state": 4748
             },
             "index": {
                "this": 100,
                "CBSA": 297,
                "state": 519
             },
             "error_ratio": {
                "this": 73.8,
                "CBSA": 6.3,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.2,
                "CBSA": 0.44,
                "state": 0.27
             },
             "error": {
                "this": 0.23,
                "CBSA": 0.12,
                "state": 0.03
             },
             "numerators": {
                "this": 176,
                "CBSA": 20869,
                "state": 107538
             },
             "numerator_errors": {
                "this": 197,
                "CBSA": 5470,
                "state": 10686
             },
             "index": {
                "this": 100,
                "CBSA": 45,
                "state": 74
             },
             "error_ratio": {
                "this": 115,
                "CBSA": 27.3,
                "state": 11.1
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 3.26,
                "CBSA": 4.18,
                "state": 3.1
             },
             "error": {
                "this": 1.33,
                "CBSA": 0.2,
                "state": 0.07
             },
             "numerators": {
                "this": 2800,
                "CBSA": 197642,
                "state": 1224113
             },
             "numerator_errors": {
                "this": 1141,
                "CBSA": 9563,
                "state": 26997
             },
             "index": {
                "this": 100,
                "CBSA": 78,
                "state": 105
             },
             "error_ratio": {
                "this": 40.8,
                "CBSA": 4.8,
                "state": 2.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 30.12,
                "CBSA": 21.85,
                "state": 39.42
             },
             "error": {
                "this": 5.64,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 25882,
                "CBSA": 1033922,
                "state": 1.5574882E7
             },
             "numerator_errors": {
                "this": 4848,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 138,
                "state": 76
             },
             "error_ratio": {
                "this": 18.7,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 74326,
                "CBSA": 60223,
                "state": 39393
             },
             "error": {
                "this": 8831,
                "CBSA": 786,
                "state": 177
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 123,
                "state": 189
             },
             "error_ratio": {
                "this": 11.9,
                "CBSA": 1.3,
                "state": 0.4
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 138913,
                "CBSA": 114696,
                "state": 80440
             },
             "error": {
                "this": 17229,
                "CBSA": 1965,
                "state": 313
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 121,
                "state": 173
             },
             "error_ratio": {
                "this": 12.4,
                "CBSA": 1.7,
                "state": 0.4
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 20.38,
                   "CBSA": 22.5,
                   "state": 31.68
                },
                "error": {
                   "this": 4.12,
                   "CBSA": 0.65,
                   "state": 0.26
                },
                "numerators": {
                   "this": 6562,
                   "CBSA": 387393,
                   "state": 4167825
                },
                "numerator_errors": {
                   "this": 1396.6,
                   "CBSA": 11318.1,
                   "state": 34680.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 91,
                   "state": 64
                },
                "error_ratio": {
                   "this": 20.2,
                   "CBSA": 2.9,
                   "state": 0.8
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 17.58,
                   "CBSA": 21.44,
                   "state": 27.83
                },
                "error": {
                   "this": 3.65,
                   "CBSA": 0.57,
                   "state": 0.24
                },
                "numerators": {
                   "this": 5661,
                   "CBSA": 369108,
                   "state": 3661397
                },
                "numerator_errors": {
                   "this": 1233.2,
                   "CBSA": 9959.7,
                   "state": 32290.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 63
                },
                "error_ratio": {
                   "this": 20.8,
                   "CBSA": 2.7,
                   "state": 0.9
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 27.62,
                   "CBSA": 29.91,
                   "state": 26.8
                },
                "error": {
                   "this": 4.11,
                   "CBSA": 0.69,
                   "state": 0.24
                },
                "numerators": {
                   "this": 8893,
                   "CBSA": 514913,
                   "state": 3525910
                },
                "numerator_errors": {
                   "this": 1445.8,
                   "CBSA": 12053.4,
                   "state": 31856.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 103
                },
                "error_ratio": {
                   "this": 14.9,
                   "CBSA": 2.3,
                   "state": 0.9
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 34.42,
                   "CBSA": 26.15,
                   "state": 13.7
                },
                "error": {
                   "this": 4.6,
                   "CBSA": 0.54,
                   "state": 0.14
                },
                "numerators": {
                   "this": 11083,
                   "CBSA": 450162,
                   "state": 1802741
                },
                "numerator_errors": {
                   "this": 1652,
                   "CBSA": 9432,
                   "state": 18330
                },
                "index": {
                   "this": 100,
                   "CBSA": 132,
                   "state": 251
                },
                "error_ratio": {
                   "this": 13.4,
                   "CBSA": 2.1,
                   "state": 1
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 7.27,
                "CBSA": 8.16,
                "state": 11.75
             },
             "error": {
                "this": 2.9,
                "CBSA": 0.42,
                "state": 0.18
             },
             "numerators": {
                "this": 6172,
                "CBSA": 380982,
                "state": 4552837
             },
             "numerator_errors": {
                "this": 2465,
                "CBSA": 19484,
                "state": 67995
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 89,
                "state": 62
             },
             "error_ratio": {
                "this": 39.9,
                "CBSA": 5.1,
                "state": 1.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 7.98,
                   "CBSA": 8.89,
                   "state": 15.62
                },
                "error": {
                   "this": 4.95,
                   "CBSA": 0.65,
                   "state": 0.27
                },
                "numerators": {
                   "this": 1379,
                   "CBSA": 80671,
                   "state": 1363574
                },
                "numerator_errors": {
                   "this": 875.3,
                   "CBSA": 5988.4,
                   "state": 24624.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 51
                },
                "error_ratio": {
                   "this": 62,
                   "CBSA": 7.3,
                   "state": 1.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 92.02,
                   "CBSA": 91.11,
                   "state": 84.38
                },
                "error": {
                   "this": 1.79,
                   "CBSA": 1.88,
                   "state": 0.61
                },
                "numerators": {
                   "this": 15901,
                   "CBSA": 827095,
                   "state": 7365259
                },
                "numerator_errors": {
                   "this": 2203.2,
                   "CBSA": 11932.8,
                   "state": 37404.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 109
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 2.1,
                   "state": 0.7
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 5.52,
                   "CBSA": 8.95,
                   "state": 10.45
                },
                "error": {
                   "this": 2.85,
                   "CBSA": 0.62,
                   "state": 0.23
                },
                "numerators": {
                   "this": 640,
                   "CBSA": 66384,
                   "state": 599877
                },
                "numerator_errors": {
                   "this": 343.3,
                   "CBSA": 4644,
                   "state": 13070.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 62,
                   "state": 53
                },
                "error_ratio": {
                   "this": 51.6,
                   "CBSA": 6.9,
                   "state": 2.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 94.48,
                   "CBSA": 91.05,
                   "state": 89.55
                },
                "error": {
                   "this": 3.76,
                   "CBSA": 1.09,
                   "state": 0.39
                },
                "numerators": {
                   "this": 10963,
                   "CBSA": 675078,
                   "state": 5138754
                },
                "numerator_errors": {
                   "this": 1660.1,
                   "CBSA": 5093.8,
                   "state": 14255.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 106
                },
                "error_ratio": {
                   "this": 4,
                   "CBSA": 1.2,
                   "state": 0.4
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 26.48,
                "CBSA": 35.17,
                "state": 30.68
             },
             "error": {
                "this": 1.79,
                "CBSA": 0.3,
                "state": 0.1
             },
             "numerators": {
                "this": 1182545,
                "CBSA": 7.9633688E7,
                "state": 5.3813811E8
             },
             "numerator_errors": {
                "this": 112680,
                "CBSA": 939421,
                "state": 2278526
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 75,
                "state": 86
             },
             "error_ratio": {
                "this": 6.8,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 72.92,
                   "CBSA": 56.49,
                   "state": 73.51
                },
                "error": {
                   "this": 2.61,
                   "CBSA": 0.56,
                   "state": 0.23
                },
                "numerators": {
                   "this": 34630,
                   "CBSA": 1377616,
                   "state": 1.3767903E7
                },
                "numerator_errors": {
                   "this": 2449,
                   "CBSA": 16525,
                   "state": 52769
                },
                "index": {
                   "this": 100,
                   "CBSA": 129,
                   "state": 99
                },
                "error_ratio": {
                   "this": 3.6,
                   "CBSA": 1,
                   "state": 0.3
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 7.6,
                   "CBSA": 8.62,
                   "state": 9.83
                },
                "error": {
                   "this": 1.82,
                   "CBSA": 0.33,
                   "state": 0.15
                },
                "numerators": {
                   "this": 3610,
                   "CBSA": 210199,
                   "state": 1841273
                },
                "numerator_errors": {
                   "this": 893,
                   "CBSA": 8119,
                   "state": 29274
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 77
                },
                "error_ratio": {
                   "this": 23.9,
                   "CBSA": 3.8,
                   "state": 1.5
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 6.52,
                   "CBSA": 18.94,
                   "state": 5.18
                },
                "error": {
                   "this": 2.02,
                   "CBSA": 0.41,
                   "state": 0.08
                },
                "numerators": {
                   "this": 3098,
                   "CBSA": 461832,
                   "state": 970901
                },
                "numerator_errors": {
                   "this": 976,
                   "CBSA": 10580,
                   "state": 15634
                },
                "index": {
                   "this": 100,
                   "CBSA": 34,
                   "state": 126
                },
                "error_ratio": {
                   "this": 31,
                   "CBSA": 2.2,
                   "state": 1.5
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 1.75,
                   "CBSA": 1.83,
                   "state": 0.86
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.14,
                   "state": 0.04
                },
                "numerators": {
                   "this": 833,
                   "CBSA": 44520,
                   "state": 160661
                },
                "numerator_errors": {
                   "this": 338,
                   "CBSA": 3413,
                   "state": 7943
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 203
                },
                "error_ratio": {
                   "this": 40,
                   "CBSA": 7.7,
                   "state": 4.7
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 3.38,
                   "CBSA": 4.69,
                   "state": 2.56
                },
                "error": {
                   "this": 1.69,
                   "CBSA": 0.36,
                   "state": 0.08
                },
                "numerators": {
                   "this": 1607,
                   "CBSA": 114287,
                   "state": 479751
                },
                "numerator_errors": {
                   "this": 810,
                   "CBSA": 8717,
                   "state": 15458
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 132
                },
                "error_ratio": {
                   "this": 50,
                   "CBSA": 7.7,
                   "state": 3.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.87,
                   "CBSA": 2.28,
                   "state": 1.72
                },
                "error": {
                   "this": 0.87,
                   "CBSA": 0.2,
                   "state": 0.06
                },
                "numerators": {
                   "this": 886,
                   "CBSA": 55564,
                   "state": 321375
                },
                "numerator_errors": {
                   "this": 417,
                   "CBSA": 4861,
                   "state": 11717
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 109
                },
                "error_ratio": {
                   "this": 46.5,
                   "CBSA": 8.8,
                   "state": 3.5
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 5.95,
                   "CBSA": 7.16,
                   "state": 6.34
                },
                "error": {
                   "this": 1.6,
                   "CBSA": 0.34,
                   "state": 0.12
                },
                "numerators": {
                   "this": 2825,
                   "CBSA": 174591,
                   "state": 1188387
                },
                "numerator_errors": {
                   "this": 778,
                   "CBSA": 8386,
                   "state": 22217
                },
                "index": {
                   "this": 100,
                   "CBSA": 83,
                   "state": 94
                },
                "error_ratio": {
                   "this": 26.9,
                   "CBSA": 4.7,
                   "state": 1.9
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 52.76,
                "CBSA": 49.88,
                "state": 48.44
             },
             "error": {
                "this": 2.79,
                "CBSA": 0.39,
                "state": 0.14
             },
             "numerators": {
                "this": 37511,
                "CBSA": 1977052,
                "state": 1.5561514E7
             },
             "numerator_errors": {
                "this": 2305.2,
                "CBSA": 15302.6,
                "state": 46249.3
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 109
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 0.8,
                "state": 0.3
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 47.24,
                "CBSA": 50.12,
                "state": 51.56
             },
             "error": {
                "this": 4.49,
                "CBSA": 0.45,
                "state": 0.16
             },
             "numerators": {
                "this": 33587,
                "CBSA": 1986662,
                "state": 1.6562598E7
             },
             "numerator_errors": {
                "this": 3361.1,
                "CBSA": 17817.2,
                "state": 51752.6
             },
             "index": {
                "this": 100,
                "CBSA": 94,
                "state": 92
             },
             "error_ratio": {
                "this": 9.5,
                "CBSA": 0.9,
                "state": 0.3
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 37.21,
                   "CBSA": 39.77,
                   "state": 40.83
                },
                "error": {
                   "this": 5.85,
                   "CBSA": 0.5,
                   "state": 0.17
                },
                "numerators": {
                   "this": 12922,
                   "CBSA": 775103,
                   "state": 6478460
                },
                "numerator_errors": {
                   "this": 2194,
                   "CBSA": 9861,
                   "state": 27866
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 91
                },
                "error_ratio": {
                   "this": 15.7,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 31.85,
                   "CBSA": 34.02,
                   "state": 33.96
                },
                "error": {
                   "this": 5.04,
                   "CBSA": 0.46,
                   "state": 0.15
                },
                "numerators": {
                   "this": 11583,
                   "CBSA": 685311,
                   "state": 5521285
                },
                "numerator_errors": {
                   "this": 1935,
                   "CBSA": 9340,
                   "state": 24532
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 94
                },
                "error_ratio": {
                   "this": 15.8,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 56.28,
                   "CBSA": 51.38,
                   "state": 49.37
                },
                "error": {
                   "this": 2.95,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 19543,
                   "CBSA": 1001374,
                   "state": 7832740
                },
                "numerator_errors": {
                   "this": 1621,
                   "CBSA": 10667,
                   "state": 32087
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 114
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 49.4,
                   "CBSA": 48.43,
                   "state": 47.54
                },
                "error": {
                   "this": 3.66,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 17968,
                   "CBSA": 975678,
                   "state": 7728774
                },
                "numerator_errors": {
                   "this": 1639,
                   "CBSA": 10972,
                   "state": 33308
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 104
                },
                "error_ratio": {
                   "this": 7.4,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 5.19,
                   "CBSA": 6.95,
                   "state": 7.69
                },
                "error": {
                   "this": 2.26,
                   "CBSA": 0.34,
                   "state": 0.11
                },
                "numerators": {
                   "this": 1803,
                   "CBSA": 135520,
                   "state": 1219434
                },
                "numerator_errors": {
                   "this": 792,
                   "CBSA": 6608,
                   "state": 17626
                },
                "index": {
                   "this": 100,
                   "CBSA": 75,
                   "state": 67
                },
                "error_ratio": {
                   "this": 43.5,
                   "CBSA": 4.9,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 12.29,
                   "CBSA": 10.37,
                   "state": 10.83
                },
                "error": {
                   "this": 2.98,
                   "CBSA": 0.32,
                   "state": 0.16
                },
                "numerators": {
                   "this": 4469,
                   "CBSA": 208872,
                   "state": 1760791
                },
                "numerator_errors": {
                   "this": 1110,
                   "CBSA": 6454,
                   "state": 25259
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 113
                },
                "error_ratio": {
                   "this": 24.2,
                   "CBSA": 3.1,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.32,
                   "CBSA": 1.9,
                   "state": 2.11
                },
                "error": {
                   "this": 0.93,
                   "CBSA": 0.16,
                   "state": 0.05
                },
                "numerators": {
                   "this": 457,
                   "CBSA": 37037,
                   "state": 334453
                },
                "numerator_errors": {
                   "this": 325,
                   "CBSA": 3146,
                   "state": 8393
                },
                "index": {
                   "this": 100,
                   "CBSA": 69,
                   "state": 63
                },
                "error_ratio": {
                   "this": 70.5,
                   "CBSA": 8.4,
                   "state": 2.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 6.47,
                   "CBSA": 7.19,
                   "state": 7.68
                },
                "error": {
                   "this": 2.39,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2353,
                   "CBSA": 144819,
                   "state": 1248175
                },
                "numerator_errors": {
                   "this": 880,
                   "CBSA": 6145,
                   "state": 16759
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 84
                },
                "error_ratio": {
                   "this": 36.9,
                   "CBSA": 4.2,
                   "state": 1.3
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 73.9,
                   "CBSA": 79.49,
                   "state": 72.24
                },
                "error": {
                   "this": 8.09,
                   "CBSA": 1.18,
                   "state": 0.46
                },
                "numerators": {
                   "this": 11770,
                   "CBSA": 647640,
                   "state": 5449645
                },
                "numerator_errors": {
                   "this": 1980,
                   "CBSA": 11612,
                   "state": 40502
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 102
                },
                "error_ratio": {
                   "this": 10.9,
                   "CBSA": 1.5,
                   "state": 0.6
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 11.6,
                   "CBSA": 5.33,
                   "state": 7.66
                },
                "error": {
                   "this": 7.11,
                   "CBSA": 0.73,
                   "state": 0.26
                },
                "numerators": {
                   "this": 1847,
                   "CBSA": 43433,
                   "state": 578128
                },
                "numerator_errors": {
                   "this": 1157,
                   "CBSA": 5944,
                   "state": 19525
                },
                "index": {
                   "this": 100,
                   "CBSA": 218,
                   "state": 151
                },
                "error_ratio": {
                   "this": 61.3,
                   "CBSA": 13.7,
                   "state": 3.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 14.5,
                   "CBSA": 15.18,
                   "state": 20.1
                },
                "error": {
                   "this": 7.16,
                   "CBSA": 1.03,
                   "state": 0.43
                },
                "numerators": {
                   "this": 2309,
                   "CBSA": 123655,
                   "state": 1516238
                },
                "numerator_errors": {
                   "this": 1178,
                   "CBSA": 8506,
                   "state": 32886
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 72
                },
                "error_ratio": {
                   "this": 49.4,
                   "CBSA": 6.8,
                   "state": 2.1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 7.39,
                "CBSA": 4.22,
                "state": 4.69
             },
             "error": {
                "this": 2.57,
                "CBSA": 0.35,
                "state": 0.11
             },
             "numerators": {
                "this": 1604,
                "CBSA": 49498,
                "state": 450971
             },
             "numerator_errors": {
                "this": 573,
                "CBSA": 4126,
                "state": 10955
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 175,
                "state": 158
             },
             "error_ratio": {
                "this": 34.8,
                "CBSA": 8.3,
                "state": 2.3
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.14,
                   "state": 0.87
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.17,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 175,
                   "state": 10882
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 215,
                   "state": 2046
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 121.4,
                   "state": 18.4
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 10.98,
                   "CBSA": 3.41,
                   "state": 4.44
                },
                "error": {
                   "this": 17.22,
                   "CBSA": 0.98,
                   "state": 0.41
                },
                "numerators": {
                   "this": 228,
                   "CBSA": 4502,
                   "state": 56709
                },
                "numerator_errors": {
                   "this": 367,
                   "CBSA": 1289,
                   "state": 5217
                },
                "index": {
                   "this": 100,
                   "CBSA": 322,
                   "state": 247
                },
                "error_ratio": {
                   "this": 156.8,
                   "CBSA": 28.7,
                   "state": 9.2
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 10.11,
                   "CBSA": 3.96,
                   "state": 6.85
                },
                "error": {
                   "this": 5.41,
                   "CBSA": 0.79,
                   "state": 0.37
                },
                "numerators": {
                   "this": 363,
                   "CBSA": 7129,
                   "state": 101991
                },
                "numerator_errors": {
                   "this": 219,
                   "CBSA": 1423,
                   "state": 5591
                },
                "index": {
                   "this": 100,
                   "CBSA": 255,
                   "state": 148
                },
                "error_ratio": {
                   "this": 53.5,
                   "CBSA": 19.9,
                   "state": 5.4
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 10.35,
                   "CBSA": 8.76,
                   "state": 9.73
                },
                "error": {
                   "this": 6.19,
                   "CBSA": 1.12,
                   "state": 0.51
                },
                "numerators": {
                   "this": 364,
                   "CBSA": 16867,
                   "state": 139246
                },
                "numerator_errors": {
                   "this": 242,
                   "CBSA": 2170,
                   "state": 7329
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 106
                },
                "error_ratio": {
                   "this": 59.8,
                   "CBSA": 12.8,
                   "state": 5.2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 13.69,
                   "CBSA": 8.24,
                   "state": 6.95
                },
                "error": {
                   "this": 8.68,
                   "CBSA": 1.17,
                   "state": 0.33
                },
                "numerators": {
                   "this": 577,
                   "CBSA": 14911,
                   "state": 93949
                },
                "numerator_errors": {
                   "this": 399,
                   "CBSA": 2171,
                   "state": 4611
                },
                "index": {
                   "this": 100,
                   "CBSA": 166,
                   "state": 197
                },
                "error_ratio": {
                   "this": 63.4,
                   "CBSA": 14.2,
                   "state": 4.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 2.37,
                   "CBSA": 2.72,
                   "state": 2.64
                },
                "error": {
                   "this": 2.68,
                   "CBSA": 0.6,
                   "state": 0.21
                },
                "numerators": {
                   "this": 72,
                   "CBSA": 4418,
                   "state": 33377
                },
                "numerator_errors": {
                   "this": 84,
                   "CBSA": 979,
                   "state": 2743
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 90
                },
                "error_ratio": {
                   "this": 113.1,
                   "CBSA": 22.1,
                   "state": 8
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0,
                   "CBSA": 0.76,
                   "state": 0.96
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.3,
                   "state": 0.14
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1496,
                   "state": 14817
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 597,
                   "state": 2211
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 39.5,
                   "state": 14.6
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 32199,
                "CBSA": 1721576,
                "state": 1.3157873E7
             },
             "error": {
                "this": 2119,
                "CBSA": 7415,
                "state": 23844
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 6.6,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.62,
                "CBSA": 2.7,
                "state": 2.94
             },
             "error": {
                "this": 0.18,
                "CBSA": 0.01,
                "state": 0.01
             },
             "numerators": {
                "this": 84442,
                "CBSA": 4654824,
                "state": 3.8685704E7
             },
             "numerator_errors": {
                "this": 1001,
                "CBSA": 3894,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 89
             },
             "error_ratio": {
                "this": 6.9,
                "CBSA": 0.4,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 60.67,
                   "CBSA": 61.45,
                   "state": 60.69
                },
                "error": {
                   "this": 5.41,
                   "CBSA": 0.62,
                   "state": 0.22
                },
                "numerators": {
                   "this": 51228,
                   "CBSA": 2860522,
                   "state": 2.3477092E7
                },
                "numerator_errors": {
                   "this": 4607,
                   "CBSA": 28845,
                   "state": 84642
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 100
                },
                "error_ratio": {
                   "this": 8.9,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 8.07,
                   "CBSA": 5.55,
                   "state": 7.74
                },
                "error": {
                   "this": 3.36,
                   "CBSA": 0.39,
                   "state": 0.16
                },
                "numerators": {
                   "this": 6814,
                   "CBSA": 258173,
                   "state": 2996042
                },
                "numerator_errors": {
                   "this": 2836,
                   "CBSA": 18051,
                   "state": 63406
                },
                "index": {
                   "this": 100,
                   "CBSA": 145,
                   "state": 104
                },
                "error_ratio": {
                   "this": 41.6,
                   "CBSA": 7,
                   "state": 2.1
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 10.46,
                   "CBSA": 12.87,
                   "state": 16.47
                },
                "error": {
                   "this": 3.61,
                   "CBSA": 0.56,
                   "state": 0.23
                },
                "numerators": {
                   "this": 8835,
                   "CBSA": 598953,
                   "state": 6371949
                },
                "numerator_errors": {
                   "this": 3048,
                   "CBSA": 26284,
                   "state": 87738
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 64
                },
                "error_ratio": {
                   "this": 34.5,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 20.8,
                   "CBSA": 20.13,
                   "state": 15.1
                },
                "error": {
                   "this": 3.68,
                   "CBSA": 0.41,
                   "state": 0.1
                },
                "numerators": {
                   "this": 17565,
                   "CBSA": 937176,
                   "state": 5840619
                },
                "numerator_errors": {
                   "this": 3111,
                   "CBSA": 18922,
                   "state": 37146
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 138
                },
                "error_ratio": {
                   "this": 17.7,
                   "CBSA": 2,
                   "state": 0.7
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 33563,
                "CBSA": 1841897,
                "state": 1.4367012E7
             },
             "error": {
                "this": 2168,
                "CBSA": 1707,
                "state": 1286
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 6.5,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 95.94,
                   "CBSA": 93.47,
                   "state": 91.58
                },
                "error": {
                   "this": 1.21,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 32199,
                   "CBSA": 1721576,
                   "state": 1.3157873E7
                },
                "numerator_errors": {
                   "this": 2119,
                   "CBSA": 7415,
                   "state": 23844
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 105
                },
                "error_ratio": {
                   "this": 1.3,
                   "CBSA": 0.4,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 4.06,
                   "CBSA": 6.53,
                   "state": 8.42
                },
                "error": {
                   "this": 2.05,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 1364,
                   "CBSA": 120321,
                   "state": 1209139
                },
                "numerator_errors": {
                   "this": 695,
                   "CBSA": 7194,
                   "state": 24297
                },
                "index": {
                   "this": 100,
                   "CBSA": 62,
                   "state": 48
                },
                "error_ratio": {
                   "this": 50.5,
                   "CBSA": 6,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 55.84,
                   "CBSA": 57.4,
                   "state": 63.96
                },
                "error": {
                   "this": 4.38,
                   "CBSA": 0.62,
                   "state": 0.22
                },
                "numerators": {
                   "this": 18741,
                   "CBSA": 1057192,
                   "state": 9189272
                },
                "numerator_errors": {
                   "this": 1905.6,
                   "CBSA": 11515.2,
                   "state": 31423.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 87
                },
                "error_ratio": {
                   "this": 7.8,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 42.71,
                   "CBSA": 41.49,
                   "state": 32.18
                },
                "error": {
                   "this": 4.63,
                   "CBSA": 0.81,
                   "state": 0.27
                },
                "numerators": {
                   "this": 14334,
                   "CBSA": 764126,
                   "state": 4622614
                },
                "numerator_errors": {
                   "this": 1809.8,
                   "CBSA": 14847.1,
                   "state": 38673.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 133
                },
                "error_ratio": {
                   "this": 10.8,
                   "CBSA": 2,
                   "state": 0.8
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 1.45,
                   "CBSA": 1,
                   "state": 3.74
                },
                "error": {
                   "this": 0.8,
                   "CBSA": 0.11,
                   "state": 0.08
                },
                "numerators": {
                   "this": 488,
                   "CBSA": 18463,
                   "state": 537931
                },
                "numerator_errors": {
                   "this": 269,
                   "CBSA": 1989,
                   "state": 11591
                },
                "index": {
                   "this": 100,
                   "CBSA": 145,
                   "state": 39
                },
                "error_ratio": {
                   "this": 55.2,
                   "CBSA": 11,
                   "state": 2.1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.12
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.05,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 2116,
                   "state": 17195
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 951,
                   "state": 2102
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 45.5,
                   "state": 8.3
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 45.46,
                   "CBSA": 54.03,
                   "state": 54.86
                },
                "error": {
                   "this": 4.36,
                   "CBSA": 0.59,
                   "state": 0.22
                },
                "numerators": {
                   "this": 14639,
                   "CBSA": 930250,
                   "state": 7218742
                },
                "numerator_errors": {
                   "this": 1703,
                   "CBSA": 10981,
                   "state": 32100
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 83
                },
                "error_ratio": {
                   "this": 9.6,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 54.54,
                   "CBSA": 45.97,
                   "state": 45.14
                },
                "error": {
                   "this": 4.04,
                   "CBSA": 0.65,
                   "state": 0.24
                },
                "numerators": {
                   "this": 17560,
                   "CBSA": 791326,
                   "state": 5939131
                },
                "numerator_errors": {
                   "this": 1739,
                   "CBSA": 11672,
                   "state": 32807
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 121
                },
                "error_ratio": {
                   "this": 7.4,
                   "CBSA": 1.4,
                   "state": 0.5
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 1570300,
                "CBSA": 940900,
                "state": 568500
             },
             "error": {
                "this": 68779,
                "CBSA": 7982,
                "state": 2502
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 167,
                "state": 276
             },
             "error_ratio": {
                "this": 4.4,
                "CBSA": 0.8,
                "state": 0.4
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 2.09,
                   "CBSA": 2.16,
                   "state": 5.03
                },
                "error": {
                   "this": 0.75,
                   "CBSA": 0.1,
                   "state": 0.05
                },
                "numerators": {
                   "this": 321,
                   "CBSA": 20156,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 115.5,
                   "CBSA": 908.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 42
                },
                "error_ratio": {
                   "this": 35.9,
                   "CBSA": 4.6,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 2.03,
                   "CBSA": 1.61,
                   "state": 6.63
                },
                "error": {
                   "this": 0.59,
                   "CBSA": 0.09,
                   "state": 0.06
                },
                "numerators": {
                   "this": 311,
                   "CBSA": 15036,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 91.1,
                   "CBSA": 818.1,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 126,
                   "state": 31
                },
                "error_ratio": {
                   "this": 29.1,
                   "CBSA": 5.6,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 1.08,
                   "CBSA": 2.81,
                   "state": 11.06
                },
                "error": {
                   "this": 0.46,
                   "CBSA": 0.11,
                   "state": 0.06
                },
                "numerators": {
                   "this": 166,
                   "CBSA": 26184,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 71.3,
                   "CBSA": 1029.9,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 38,
                   "state": 10
                },
                "error_ratio": {
                   "this": 42.6,
                   "CBSA": 3.9,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 0.79,
                   "CBSA": 5.66,
                   "state": 13.6
                },
                "error": {
                   "this": 0.45,
                   "CBSA": 0.14,
                   "state": 0.09
                },
                "numerators": {
                   "this": 121,
                   "CBSA": 52681,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 69,
                   "CBSA": 1347,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 14,
                   "state": 6
                },
                "error_ratio": {
                   "this": 57,
                   "CBSA": 2.5,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 1.12,
                   "CBSA": 7.56,
                   "state": 13.21
                },
                "error": {
                   "this": 0.52,
                   "CBSA": 0.19,
                   "state": 0.08
                },
                "numerators": {
                   "this": 172,
                   "CBSA": 70432,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 80,
                   "CBSA": 1773,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 15,
                   "state": 8
                },
                "error_ratio": {
                   "this": 46.4,
                   "CBSA": 2.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 23.54,
                   "CBSA": 43.94,
                   "state": 35.28
                },
                "error": {
                   "this": 2.09,
                   "CBSA": 0.29,
                   "state": 0.26
                },
                "numerators": {
                   "this": 3612,
                   "CBSA": 409063,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 347.6,
                   "CBSA": 3594.1,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 67
                },
                "error_ratio": {
                   "this": 8.9,
                   "CBSA": 0.7,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 30.69,
                   "CBSA": 19.26,
                   "state": 7.93
                },
                "error": {
                   "this": 2.67,
                   "CBSA": 0.23,
                   "state": 0.04
                },
                "numerators": {
                   "this": 4708,
                   "CBSA": 179279,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 446,
                   "CBSA": 2390,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 159,
                   "state": 387
                },
                "error_ratio": {
                   "this": 8.7,
                   "CBSA": 1.2,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 15342,
                "CBSA": 931032,
                "state": 7154580
             },
             "error": {
                "this": 575,
                "CBSA": 5500,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 3.7,
                "CBSA": 0.6,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 7.44,
                "CBSA": 10.5,
                "state": 8.86
             },
             "error": {
                "this": 2.02,
                "CBSA": 0.42,
                "state": 0.13
             },
             "numerators": {
                "this": 6282,
                "CBSA": 488730,
                "state": 3427072
             },
             "numerator_errors": {
                "this": 1704.4,
                "CBSA": 19338.7,
                "state": 50836.5
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 84
             },
             "error_ratio": {
                "this": 27.2,
                "CBSA": 4,
                "state": 1.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 9.35,
                "CBSA": 10.75,
                "state": 11.17
             },
             "error": {
                "this": 2.71,
                "CBSA": 0.42,
                "state": 0.16
             },
             "numerators": {
                "this": 7896,
                "CBSA": 500178,
                "state": 4320604
             },
             "numerator_errors": {
                "this": 2288,
                "CBSA": 19399.4,
                "state": 63360.7
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 84
             },
             "error_ratio": {
                "this": 29,
                "CBSA": 3.9,
                "state": 1.4
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 19.67,
                "CBSA": 21.68,
                "state": 21.9
             },
             "error": {
                "this": 4.68,
                "CBSA": 0.73,
                "state": 0.25
             },
             "numerators": {
                "this": 16611,
                "CBSA": 1008965,
                "state": 8473932
             },
             "numerator_errors": {
                "this": 3957.6,
                "CBSA": 33889.7,
                "state": 98624.3
             },
             "index": {
                "this": 100,
                "CBSA": 91,
                "state": 90
             },
             "error_ratio": {
                "this": 23.8,
                "CBSA": 3.4,
                "state": 1.1
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 17.98,
                "CBSA": 20.17,
                "state": 20.05
             },
             "error": {
                "this": 4.33,
                "CBSA": 0.62,
                "state": 0.24
             },
             "numerators": {
                "this": 15179,
                "CBSA": 939060,
                "state": 7754580
             },
             "numerator_errors": {
                "this": 3658.5,
                "CBSA": 29080.9,
                "state": 91602.6
             },
             "index": {
                "this": 100,
                "CBSA": 89,
                "state": 90
             },
             "error_ratio": {
                "this": 24.1,
                "CBSA": 3.1,
                "state": 1.2
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 14.53,
                "CBSA": 12.37,
                "state": 13.11
             },
             "error": {
                "this": 4.53,
                "CBSA": 0.54,
                "state": 0.21
             },
             "numerators": {
                "this": 12273,
                "CBSA": 575817,
                "state": 5072685
             },
             "numerator_errors": {
                "this": 3828.6,
                "CBSA": 25040.7,
                "state": 79541.6
             },
             "index": {
                "this": 100,
                "CBSA": 117,
                "state": 111
             },
             "error_ratio": {
                "this": 31.2,
                "CBSA": 4.4,
                "state": 1.6
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 31.03,
                "CBSA": 24.54,
                "state": 24.91
             },
             "error": {
                "this": 4.7,
                "CBSA": 0.73,
                "state": 0.26
             },
             "numerators": {
                "this": 26201,
                "CBSA": 1142074,
                "state": 9636829
             },
             "numerator_errors": {
                "this": 3980.1,
                "CBSA": 33871.4,
                "state": 101788.8
             },
             "index": {
                "this": 100,
                "CBSA": 126,
                "state": 125
             },
             "error_ratio": {
                "this": 15.1,
                "CBSA": 3,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 16.84,
                "CBSA": 12.66,
                "state": 12
             },
             "error": {
                "this": 3.29,
                "CBSA": 0.41,
                "state": 0.16
             },
             "numerators": {
                "this": 14193,
                "CBSA": 592840,
                "state": 4689828
             },
             "numerator_errors": {
                "this": 2775.2,
                "CBSA": 19218.9,
                "state": 63097.7
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 133,
                "state": 140
             },
             "error_ratio": {
                "this": 19.5,
                "CBSA": 3.2,
                "state": 1.3
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 83.16,
                "CBSA": 87.34,
                "state": 88
             },
             "error": {
                "this": 3.37,
                "CBSA": 0.39,
                "state": 0.17
             },
             "numerators": {
                "this": 70091,
                "CBSA": 4091762,
                "state": 3.439422E7
             },
             "numerator_errors": {
                "this": 2885,
                "CBSA": 18428,
                "state": 68574
             },
             "index": {
                "this": 100,
                "CBSA": 95,
                "state": 94
             },
             "error_ratio": {
                "this": 4.1,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 7.42,
                "CBSA": 6.27,
                "state": 7.37
             },
             "error": {
                "this": 2.57,
                "CBSA": 0.29,
                "state": 0.13
             },
             "numerators": {
                "this": 6257,
                "CBSA": 293714,
                "state": 2881883
             },
             "numerator_errors": {
                "this": 2164,
                "CBSA": 13357,
                "state": 52031
             },
             "index": {
                "this": 100,
                "CBSA": 118,
                "state": 101
             },
             "error_ratio": {
                "this": 34.6,
                "CBSA": 4.6,
                "state": 1.8
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 5.49,
                "CBSA": 3.96,
                "state": 2.72
             },
             "error": {
                "this": 1.69,
                "CBSA": 0.25,
                "state": 0.07
             },
             "numerators": {
                "this": 4623,
                "CBSA": 185306,
                "state": 1061256
             },
             "numerator_errors": {
                "this": 1428,
                "CBSA": 11875,
                "state": 27761
             },
             "index": {
                "this": 100,
                "CBSA": 139,
                "state": 202
             },
             "error_ratio": {
                "this": 30.8,
                "CBSA": 6.3,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 1.78,
                "CBSA": 1.39,
                "state": 1.23
             },
             "error": {
                "this": 0.9,
                "CBSA": 0.11,
                "state": 0.05
             },
             "numerators": {
                "this": 1497,
                "CBSA": 65269,
                "state": 480204
             },
             "numerator_errors": {
                "this": 756,
                "CBSA": 5065,
                "state": 18764
             },
             "index": {
                "this": 100,
                "CBSA": 128,
                "state": 145
             },
             "error_ratio": {
                "this": 50.6,
                "CBSA": 7.9,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 2.15,
                "CBSA": 1.04,
                "state": 0.68
             },
             "error": {
                "this": 0.76,
                "CBSA": 0.11,
                "state": 0.03
             },
             "numerators": {
                "this": 1816,
                "CBSA": 48551,
                "state": 266485
             },
             "numerator_errors": {
                "this": 639,
                "CBSA": 4928,
                "state": 12302
             },
             "index": {
                "this": 100,
                "CBSA": 207,
                "state": 316
             },
             "error_ratio": {
                "this": 35.3,
                "CBSA": 10.6,
                "state": 4.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 91.28,
                "CBSA": 89.55,
                "state": 84.03
             },
             "error": {
                "this": 5.25,
                "CBSA": 0.79,
                "state": 0.29
             },
             "numerators": {
                "this": 57581,
                "CBSA": 3083220,
                "state": 2.263636E7
             },
             "numerator_errors": {
                "this": 4119.2,
                "CBSA": 27313.4,
                "state": 78234.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 109
             },
             "error_ratio": {
                "this": 5.8,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 54.09,
                "CBSA": 51.35,
                "state": 35
             },
             "error": {
                "this": 4.34,
                "CBSA": 0.57,
                "state": 0.17
             },
             "numerators": {
                "this": 34122,
                "CBSA": 1768174,
                "state": 9428484
             },
             "numerator_errors": {
                "this": 3100.1,
                "CBSA": 19525.3,
                "state": 44753.9
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 155
             },
             "error_ratio": {
                "this": 8,
                "CBSA": 1.1,
                "state": 0.5
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 8.72,
                "CBSA": 10.45,
                "state": 15.97
             },
             "error": {
                "this": 2.21,
                "CBSA": 0.34,
                "state": 0.14
             },
             "numerators": {
                "this": 5503,
                "CBSA": 359842,
                "state": 4301513
             },
             "numerator_errors": {
                "this": 1411.2,
                "CBSA": 11853.8,
                "state": 37669.1
             },
             "index": {
                "this": 100,
                "CBSA": 83,
                "state": 55
             },
             "error_ratio": {
                "this": 25.3,
                "CBSA": 3.3,
                "state": 0.9
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 15.39,
                "CBSA": 15.67,
                "state": 20.59
             },
             "error": {
                "this": 3.04,
                "CBSA": 0.37,
                "state": 0.16
             },
             "numerators": {
                "this": 9711,
                "CBSA": 539467,
                "state": 5546711
             },
             "numerator_errors": {
                "this": 1960.2,
                "CBSA": 12581,
                "state": 42454
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 75
             },
             "error_ratio": {
                "this": 19.8,
                "CBSA": 2.4,
                "state": 0.8
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 21.79,
                "CBSA": 22.53,
                "state": 28.44
             },
             "error": {
                "this": 2.82,
                "CBSA": 0.42,
                "state": 0.18
             },
             "numerators": {
                "this": 13748,
                "CBSA": 775579,
                "state": 7661164
             },
             "numerator_errors": {
                "this": 1874.9,
                "CBSA": 14370.3,
                "state": 48118.5
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 77
             },
             "error_ratio": {
                "this": 12.9,
                "CBSA": 1.9,
                "state": 0.6
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 27.83,
                "CBSA": 29.97,
                "state": 21.86
             },
             "error": {
                "this": 3.32,
                "CBSA": 0.4,
                "state": 0.13
             },
             "numerators": {
                "this": 17558,
                "CBSA": 1032030,
                "state": 5889724
             },
             "numerator_errors": {
                "this": 2223.5,
                "CBSA": 13696,
                "state": 34784.9
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 127
             },
             "error_ratio": {
                "this": 11.9,
                "CBSA": 1.3,
                "state": 0.6
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 26.26,
                "CBSA": 21.38,
                "state": 13.14
             },
             "error": {
                "this": 3.24,
                "CBSA": 0.4,
                "state": 0.1
             },
             "numerators": {
                "this": 16564,
                "CBSA": 736144,
                "state": 3538760
             },
             "numerator_errors": {
                "this": 2160.2,
                "CBSA": 13916.1,
                "state": 28159
             },
             "index": {
                "this": 100,
                "CBSA": 123,
                "state": 200
             },
             "error_ratio": {
                "this": 12.3,
                "CBSA": 1.9,
                "state": 0.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 34.65,
                "CBSA": 30.94,
                "state": 26.74
             },
             "error": {
                "this": 3.86,
                "CBSA": 0.47,
                "state": 0.14
             },
             "numerators": {
                "this": 29776,
                "CBSA": 1464048,
                "state": 1.056422E7
             },
             "numerator_errors": {
                "this": 3314,
                "CBSA": 22110,
                "state": 55357
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 112,
                "state": 130
             },
             "error_ratio": {
                "this": 11.1,
                "CBSA": 1.5,
                "state": 0.5
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 12.38,
                   "CBSA": 9.42,
                   "state": 6.42
                },
                "error": {
                   "this": 1.28,
                   "CBSA": 0.19,
                   "state": 0.07
                },
                "numerators": {
                   "this": 3612,
                   "CBSA": 135944,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 409,
                   "CBSA": 2807,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 131,
                   "state": 193
                },
                "error_ratio": {
                   "this": 10.3,
                   "CBSA": 2,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 29.3,
                   "CBSA": 57.07,
                   "state": 39.42
                },
                "error": {
                   "this": 2,
                   "CBSA": 0.46,
                   "state": 0.09
                },
                "numerators": {
                   "this": 8552,
                   "CBSA": 823767,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 701,
                   "CBSA": 4566,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 51,
                   "state": 74
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.84,
                   "CBSA": 2.41,
                   "state": 1.84
                },
                "error": {
                   "this": 0.62,
                   "CBSA": 0.12,
                   "state": 0.05
                },
                "numerators": {
                   "this": 536,
                   "CBSA": 34802,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 184,
                   "CBSA": 1747,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 100
                },
                "error_ratio": {
                   "this": 33.7,
                   "CBSA": 5,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 1.87,
                   "CBSA": 1.66,
                   "state": 0.82
                },
                "error": {
                   "this": 0.63,
                   "CBSA": 0.11,
                   "state": 0.03
                },
                "numerators": {
                   "this": 545,
                   "CBSA": 23950,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 186,
                   "CBSA": 1560,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 113,
                   "state": 228
                },
                "error_ratio": {
                   "this": 33.7,
                   "CBSA": 6.6,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 53.08,
                   "CBSA": 27.95,
                   "state": 50.28
                },
                "error": {
                   "this": 3.31,
                   "CBSA": 0.31,
                   "state": 0.15
                },
                "numerators": {
                   "this": 15493,
                   "CBSA": 403440,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 1196,
                   "CBSA": 5038,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 190,
                   "state": 106
                },
                "error_ratio": {
                   "this": 6.2,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 1.53,
                   "CBSA": 1.5,
                   "state": 1.23
                },
                "error": {
                   "this": 0.47,
                   "CBSA": 0.07,
                   "state": 0.03
                },
                "numerators": {
                   "this": 448,
                   "CBSA": 21628,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 139,
                   "CBSA": 1082,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 124
                },
                "error_ratio": {
                   "this": 30.7,
                   "CBSA": 4.7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 58.94,
                   "CBSA": 61.88,
                   "state": 56.97
                },
                "error": {
                   "this": 10.32,
                   "CBSA": 1.17,
                   "state": 0.51
                },
                "numerators": {
                   "this": 6763,
                   "CBSA": 416591,
                   "state": 3710631
                },
                "numerator_errors": {
                   "this": 1785,
                   "CBSA": 7911,
                   "state": 33084
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 103
                },
                "error_ratio": {
                   "this": 17.5,
                   "CBSA": 1.9,
                   "state": 0.9
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 30.07,
                   "CBSA": 19.91,
                   "state": 33.31
                },
                "error": {
                   "this": 13.16,
                   "CBSA": 0.9,
                   "state": 0.42
                },
                "numerators": {
                   "this": 3450,
                   "CBSA": 134015,
                   "state": 2169717
                },
                "numerator_errors": {
                   "this": 1657,
                   "CBSA": 6061,
                   "state": 27349
                },
                "index": {
                   "this": 100,
                   "CBSA": 151,
                   "state": 90
                },
                "error_ratio": {
                   "this": 43.8,
                   "CBSA": 4.5,
                   "state": 1.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 4.96,
                   "CBSA": 5.64,
                   "state": 3.07
                },
                "error": {
                   "this": 3.18,
                   "CBSA": 0.56,
                   "state": 0.15
                },
                "numerators": {
                   "this": 569,
                   "CBSA": 37994,
                   "state": 200078
                },
                "numerator_errors": {
                   "this": 382,
                   "CBSA": 3804,
                   "state": 9887
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 162
                },
                "error_ratio": {
                   "this": 64.1,
                   "CBSA": 9.9,
                   "state": 4.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 5,
                   "CBSA": 11.16,
                   "state": 5.66
                },
                "error": {
                   "this": 3.91,
                   "CBSA": 0.61,
                   "state": 0.17
                },
                "numerators": {
                   "this": 574,
                   "CBSA": 75163,
                   "state": 368935
                },
                "numerator_errors": {
                   "this": 463,
                   "CBSA": 4124,
                   "state": 11144
                },
                "index": {
                   "this": 100,
                   "CBSA": 45,
                   "state": 88
                },
                "error_ratio": {
                   "this": 78.2,
                   "CBSA": 5.5,
                   "state": 3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.04,
                   "CBSA": 1.4,
                   "state": 0.98
                },
                "error": {
                   "this": 1.7,
                   "CBSA": 0.3,
                   "state": 0.11
                },
                "numerators": {
                   "this": 119,
                   "CBSA": 9442,
                   "state": 63864
                },
                "numerator_errors": {
                   "this": 197,
                   "CBSA": 2040,
                   "state": 6999
                },
                "index": {
                   "this": 100,
                   "CBSA": 74,
                   "state": 106
                },
                "error_ratio": {
                   "this": 163.5,
                   "CBSA": 21.4,
                   "state": 11.2
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 61.77,
                   "CBSA": 58.1,
                   "state": 55.21
                },
                "error": {
                   "this": 4.94,
                   "CBSA": 0.48,
                   "state": 0.18
                },
                "numerators": {
                   "this": 42304,
                   "CBSA": 2212141,
                   "state": 1.6905948E7
                },
                "numerator_errors": {
                   "this": 3849.5,
                   "CBSA": 18159.5,
                   "state": 55036
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 112
                },
                "error_ratio": {
                   "this": 8,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 20.18,
                   "CBSA": 14.83,
                   "state": 27.8
                },
                "error": {
                   "this": 4.15,
                   "CBSA": 0.28,
                   "state": 0.12
                },
                "numerators": {
                   "this": 13818,
                   "CBSA": 564814,
                   "state": 8513944
                },
                "numerator_errors": {
                   "this": 2902.5,
                   "CBSA": 10565.9,
                   "state": 37016.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 136,
                   "state": 73
                },
                "error_ratio": {
                   "this": 20.6,
                   "CBSA": 1.9,
                   "state": 0.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 7.13,
                   "CBSA": 7.23,
                   "state": 4.88
                },
                "error": {
                   "this": 2.09,
                   "CBSA": 0.33,
                   "state": 0.09
                },
                "numerators": {
                   "this": 4884,
                   "CBSA": 275261,
                   "state": 1494004
                },
                "numerator_errors": {
                   "this": 1449.4,
                   "CBSA": 12540.4,
                   "state": 28577.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 146
                },
                "error_ratio": {
                   "this": 29.3,
                   "CBSA": 4.6,
                   "state": 1.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 10.23,
                   "CBSA": 18.63,
                   "state": 10.99
                },
                "error": {
                   "this": 2.56,
                   "CBSA": 0.3,
                   "state": 0.08
                },
                "numerators": {
                   "this": 7004,
                   "CBSA": 709253,
                   "state": 3364089
                },
                "numerator_errors": {
                   "this": 1780.1,
                   "CBSA": 11259.6,
                   "state": 24688.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 93
                },
                "error_ratio": {
                   "this": 25,
                   "CBSA": 1.6,
                   "state": 0.7
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.69,
                   "CBSA": 1.21,
                   "state": 1.12
                },
                "error": {
                   "this": 0.49,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 471,
                   "CBSA": 46214,
                   "state": 343173
                },
                "numerator_errors": {
                   "this": 335.9,
                   "CBSA": 5246.1,
                   "state": 14221.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 62
                },
                "error_ratio": {
                   "this": 71,
                   "CBSA": 11.6,
                   "state": 4.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 128,
                   "CBSA": 8551,
                   "state": 66574
                },
                "error": {
                   "this": 75.67,
                   "CBSA": 587.6,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 59.1,
                   "CBSA": 6.9,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 324,
                   "CBSA": 15732,
                   "state": 145536
                },
                "error": {
                   "this": 105.47,
                   "CBSA": 775.97,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 32.6,
                   "CBSA": 4.9,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 849,
                   "CBSA": 55782,
                   "state": 561083
                },
                "error": {
                   "this": 166.44,
                   "CBSA": 1426.49,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 19.6,
                   "CBSA": 2.6,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 338,
                   "CBSA": 22897,
                   "state": 284290
                },
                "error": {
                   "this": 125.56,
                   "CBSA": 1005.17,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 37.1,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 500,
                   "CBSA": 22022,
                   "state": 309021
                },
                "error": {
                   "this": 178.91,
                   "CBSA": 1007.87,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 35.8,
                   "CBSA": 4.6,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 2143,
                   "CBSA": 132185,
                   "state": 1351006
                },
                "error": {
                   "this": 767,
                   "CBSA": 5340,
                   "state": 19299
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 35.8,
                   "CBSA": 4,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 52,
                   "CBSA": 10262,
                   "state": 120461
                },
                "error": {
                   "this": 89,
                   "CBSA": 1944,
                   "state": 5618
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 171.2,
                   "CBSA": 18.9,
                   "state": 4.7
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 2195,
                "CBSA": 142447,
                "state": 1471467
             },
             "error": {
                "this": 777,
                "CBSA": 6012,
                "state": 19838
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 35.4,
                "CBSA": 4.2,
                "state": 1.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 3.21,
                "CBSA": 3.74,
                "state": 4.83
             },
             "error": {
                "this": 1.13,
                "CBSA": 0.16,
                "state": 0.07
             },
             "numerators": {
                "this": 2195,
                "CBSA": 142447,
                "state": 1471467
             },
             "numerator_errors": {
                "this": 777,
                "CBSA": 6012,
                "state": 19838
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 86,
                "state": 66
             },
             "error_ratio": {
                "this": 35.2,
                "CBSA": 4.3,
                "state": 1.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 49899123,
       "awater": 39902210,
       "display_name": "Redwood City, CA",
       "full_geoid": "16000US0660102",
       "population": 85217,
       "simple_name": "Redwood City",
       "sumlevel": "160",
       "square_miles": 19.3,
       "population_density": 4459.9
    }
 }

 export default redwood_city