exports.MILPITAS_LISTINGS = [
		{
			"closePrice": 820000,
			"daysOnMovoto": 29,
			"id": "b63518a2-18a7-469e-8578-36c5b1bcb499",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872543_0_nb2Mrn_p.jpeg",
			"listDate": "2021-12-15 09:18:48",
			"listingAgent": "Darwin Greenwell",
			"listPrice": 750000,
			"lotSize": null,
			"sqftTotal": 1108,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872543",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Greenwell Realty",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95035",
			"path": "milpitas-ca/700-s-abel-st-523-milpitas-ca-95035-100_81430194/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cf89b91a-a764-4ce8-9e30-080ab8c991ed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-15 17:22:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-15 09:18:48",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.42105,
				"lng": -121.906199,
				"zipcode": "95035",
				"subPremise": "APT 523",
				"address": "700 S Abel St #523"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 0,
			"id": "42582139-c452-4d6d-9b71-976bb236a4b1",
			"tnImgPath": "https://pi.movoto.com/p/102/221153780_0_vf2UmV_p.jpeg",
			"listDate": null,
			"listingAgent": "Stacey Li",
			"listPrice": 1036000,
			"lotSize": 967,
			"sqftTotal": 1353,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221153780",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 42,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2000,
			"zipCode": "95035",
			"path": "milpitas-ca/928-rain-dance-milpitas-ca-95035-100_80714237/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cde26b06-cb63-4840-b089-7cf947b7fa42",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-17 19:29:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-17 19:29:42",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.417918,
				"lng": -121.901683,
				"zipcode": "95035",
				"subPremise": "",
				"address": "928 Rain Dance"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 28,
			"id": "8ae428f6-5e51-4342-b90d-7f6f2d5701f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40975308_0_QmMNnI_p.jpeg",
			"listDate": "2021-12-01 00:00:00",
			"listingAgent": "Ziqing Huang",
			"listPrice": 939000,
			"lotSize": 805,
			"sqftTotal": 1280,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975308",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2005,
			"zipCode": "95035",
			"path": "milpitas-ca/133-parc-place-dr-milpitas-ca-95035/pid_rj8m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "35de2bc2-f9ad-4360-8724-951379a69f0d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-12-02 02:31:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=TmMZp4XacKe&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.420509,
				"lng": -121.901431,
				"zipcode": "95035",
				"subPremise": "",
				"address": "133 Parc Place Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=TmMZp4XacKe&brand=0"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 76,
			"id": "6b8f4971-cb49-42c2-8860-9491f18ddc51",
			"tnImgPath": "https://pi.movoto.com/p/12/40970048_0_Nm6aMq_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Jennifer Petrocelli",
			"listPrice": 1189000,
			"lotSize": 7500,
			"sqftTotal": 1166,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970048",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Petrocelli Homes Realty Grp",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "95035",
			"path": "milpitas-ca/461-gross-st-milpitas-ca-95035-100_81229675/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -11000,
			"propertyId": "616a3b7c-6188-42f6-8373-3fee7f9497ad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-10-07 19:31:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "http://www.461grossst.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.463079,
				"lng": -121.904159,
				"zipcode": "95035",
				"subPremise": "",
				"address": "461 Gross St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.461grossst.com/mls"
		},
		{
			"closePrice": 1438888,
			"daysOnMovoto": 0,
			"id": "c30dbfb9-1a96-42e1-b104-af2f9415c480",
			"tnImgPath": "https://pi.movoto.com/p/102/221143712_0_iR6mNi_p.jpeg",
			"listDate": null,
			"listingAgent": "Jansen Friedman",
			"listPrice": 1300000,
			"lotSize": 2653,
			"sqftTotal": 1536,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221143712",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1997,
			"zipCode": "95035",
			"path": "milpitas-ca/418-sandhurst-dr-milpitas-ca-95035-100_80731225/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f07f4c2f-8952-4da2-be7a-6c5377735ff4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-12 19:54:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 19:54:28",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.435838,
				"lng": -121.89832,
				"zipcode": "95035",
				"subPremise": "",
				"address": "418 Sandhurst Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1438888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 0,
			"id": "d7da2bc9-7843-4c83-b852-87ef5abd54f6",
			"tnImgPath": "https://pi.movoto.com/p/102/221139520_0_nREiYR_p.jpeg",
			"listDate": null,
			"listingAgent": "Benjamin Oribello",
			"listPrice": 1325000,
			"lotSize": 8237,
			"sqftTotal": 1773,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221139520",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 8,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1978,
			"zipCode": "95035",
			"path": "milpitas-ca/309-la-honda-dr-milpitas-ca-95035/pid_uezm2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3726dafe-e720-4dd2-9b75-28dec2020d5e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-07 17:34:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-07 17:34:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.443447,
				"lng": -121.912309,
				"zipcode": "95035",
				"subPremise": "",
				"address": "309 La Honda Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2130000,
			"daysOnMovoto": 35,
			"id": "00f4dd79-0f98-4ea0-bd3c-2c9ea817dde3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869010_0_vi7fJU_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Anson Ip",
			"listPrice": 1798000,
			"lotSize": 3132,
			"sqftTotal": 2636,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869010",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2014,
			"zipCode": "95035",
			"path": "milpitas-ca/170-caladenia-ln-milpitas-ca-95035-100_ml81869010/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9d0dd323-f269-4722-91a6-e2f86b230d58",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-11-03 18:42:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.429235,
				"lng": -121.900528,
				"zipcode": "95035",
				"subPremise": "",
				"address": "170 Caladenia Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2130000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 830000,
			"daysOnMovoto": 0,
			"id": "40a1dacc-a584-420c-8d3d-488b49107395",
			"tnImgPath": "https://pi.movoto.com/p/102/221139765_0_VBN2eY_p.jpeg",
			"listDate": null,
			"listingAgent": "Christina Suriben-Tovera",
			"listPrice": 778000,
			"lotSize": 1202,
			"sqftTotal": 1259,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221139765",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Capital Financial Realty",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95035",
			"path": "milpitas-ca/800-s-abel-st-213-milpitas-ca-95035/pid_038m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "be4455b0-52da-4508-ac7c-e309e10381b8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-04 05:14:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 05:14:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.419027,
				"lng": -121.905579,
				"zipcode": "95035",
				"subPremise": "APT 213",
				"address": "800 S Abel St #213"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1950000,
			"daysOnMovoto": 0,
			"id": "c53e929e-699d-44b7-bf2f-cbb22c7a70f2",
			"tnImgPath": "https://pi.movoto.com/p/102/221124139_0_2f3QRQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Susan Wylie",
			"listPrice": 1988000,
			"lotSize": 3947,
			"sqftTotal": 2697,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221124139",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Savvy Lane, Inc.",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1993,
			"zipCode": "95035",
			"path": "milpitas-ca/122-edgewater-dr-milpitas-ca-95035-100_80250949/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31d0ebe2-e610-4541-b035-77349a9519ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-09-28 22:44:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 22:44:26",
			"virtualTourLink": "https://122edgewater.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.437765,
				"lng": -121.904564,
				"zipcode": "95035",
				"subPremise": "",
				"address": "122 Edgewater Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://122edgewater.com/"
		},
		{
			"closePrice": 1540805,
			"daysOnMovoto": 44,
			"id": "9cbcef0b-bd8c-4cda-9f38-0eeb5807f9ce",
			"tnImgPath": "https://pi.movoto.com/p/12/40971649_0_fiz3i2_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Dianna Novela Sanchez",
			"listPrice": 1561805,
			"lotSize": 0,
			"sqftTotal": 1979,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971649",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95035",
			"path": "milpitas-ca/2067-joshua-tree-cir-milpitas-ca-95035-2001_86g0d302cw/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7a00c865-b360-4e10-bdc8-cbb70b0a424c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-21 00:01:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.404983,
				"lng": -121.890448,
				"zipcode": "95035",
				"subPremise": "",
				"address": "2067 Joshua Tree Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1540805,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1660000,
			"daysOnMovoto": 0,
			"id": "54f2b99d-ca01-4ac6-ae0f-84665149a173",
			"tnImgPath": "https://pi.movoto.com/p/102/221136121_0_QENRqu_p.jpeg",
			"listDate": null,
			"listingAgent": "David Hernandez",
			"listPrice": 1599999,
			"lotSize": 5998,
			"sqftTotal": 2074,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136121",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Prestige Realty",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1971,
			"zipCode": "95035",
			"path": "milpitas-ca/2107-seacliff-dr-milpitas-ca-95035/pid_phcn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 999,
			"propertyId": "e4d9ebd0-9183-4bb3-915c-c807208af3d7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-10-22 19:29:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 19:29:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.427982,
				"lng": -121.866001,
				"zipcode": "95035",
				"subPremise": "",
				"address": "2107 Seacliff Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1660000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1508000,
			"daysOnMovoto": 53,
			"id": "50b0ae54-b5e6-4f49-ba54-e941c7f3b2e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40970139_0_yQUAeF_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Daryll Canlas",
			"listPrice": 1399000,
			"lotSize": 6300,
			"sqftTotal": 1528,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970139",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The Canlas Brothers, Inc",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "95035",
			"path": "milpitas-ca/1408-portola-dr-milpitas-ca-95035/pid_wjan2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eb5a933c-b3aa-4239-9804-3835178c5128",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-08 00:16:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.421965,
				"lng": -121.876607,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1408 Portola Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1508000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 785000,
			"daysOnMovoto": 59,
			"id": "f0912c9c-e543-4c58-9f28-658f3afb8361",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863797_0_FNajuj_p.jpeg",
			"listDate": "2021-09-22 16:09:06",
			"listingAgent": "Eddie Oberoi",
			"listPrice": 799888,
			"lotSize": null,
			"sqftTotal": 1259,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863797",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 57,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95035",
			"path": "milpitas-ca/700-s-abel-st-506-milpitas-ca-95035/pid_m99m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c3103297-8784-4466-9db7-02090650904a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-21 00:00:00",
			"createdAt": "2021-09-22 23:12:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 16:09:06",
			"virtualTourLink": "https://www.youtube.com/embed/U2kCgjd4y4A",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.42105,
				"lng": -121.906199,
				"zipcode": "95035",
				"subPremise": "APT 506",
				"address": "700 S Abel St #506"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 785000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/U2kCgjd4y4A"
		},
		{
			"closePrice": 1410000,
			"daysOnMovoto": 28,
			"id": "e782e89c-9a91-40ad-a85f-02b0852a163c",
			"tnImgPath": "https://pi.movoto.com/p/12/40971089_0_MEzqvb_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Jennifer Barron",
			"listPrice": 1099000,
			"lotSize": 3604,
			"sqftTotal": 1774,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971089",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1981,
			"zipCode": "95035",
			"path": "milpitas-ca/997-courtland-ct-milpitas-ca-95035/pid_ibbn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "62d85a3f-6a58-4fa5-be37-c4cabb40bbe7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-22 17:46:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/tours/o6anfv?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.421486,
				"lng": -121.878212,
				"zipcode": "95035",
				"subPremise": "",
				"address": "997 Courtland Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1410000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/tours/o6anfv?mls"
		},
		{
			"closePrice": 865000,
			"daysOnMovoto": 35,
			"id": "683687f6-e0e2-4b33-9cc1-bbb973e8ede9",
			"tnImgPath": "https://pi.movoto.com/p/12/40971028_0_3IEJ67_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Sharon He",
			"listPrice": 850000,
			"lotSize": 1267,
			"sqftTotal": 1038,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971028",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2005,
			"zipCode": "95035",
			"path": "milpitas-ca/125-parc-place-dr-milpitas-ca-95035/pid_qi8m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "94ca6fb5-af1d-4d18-985c-f819233b88f4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-10-15 00:56:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://125ParcPlaceDrive174094mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.420334,
				"lng": -121.901331,
				"zipcode": "95035",
				"subPremise": "",
				"address": "125 Parc Place Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 865000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://125ParcPlaceDrive174094mls.f8re.com/"
		},
		{
			"closePrice": 1012500,
			"daysOnMovoto": 0,
			"id": "925646aa-1f49-4f78-abfb-4d4a047dfc4d",
			"tnImgPath": "https://pi.movoto.com/p/102/221120502_0_rN3nFZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Joyce Fritz",
			"listPrice": 1077000,
			"lotSize": 2994,
			"sqftTotal": 1550,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221120502",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "PMZ Real Estate",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1970,
			"zipCode": "95035",
			"path": "milpitas-ca/428-galaxy-ct-milpitas-ca-95035/pid_fy7m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -22000,
			"propertyId": "dbf7fa80-2071-4266-86ba-cc2fd212277c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-09-21 17:29:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 17:29:48",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.411597,
				"lng": -121.91082,
				"zipcode": "95035",
				"subPremise": "",
				"address": "428 Galaxy Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1012500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 60,
			"id": "f88c6cc3-806e-4089-b6e3-29805c8483f6",
			"tnImgPath": "https://pi.movoto.com/p/12/40967289_0_a7VYRy_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Stephen Lin",
			"listPrice": 1499000,
			"lotSize": 1735,
			"sqftTotal": 1990,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967289",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1991,
			"zipCode": "95035",
			"path": "milpitas-ca/1212-monte-dr-milpitas-ca-95035-100_89936102/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e5b44d21-0d08-41ad-96ea-60077dbcdde7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-09-16 05:26:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=bUG2NR57qJL&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.421321,
				"lng": -121.867088,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1212 Monte Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=bUG2NR57qJL&mls=1"
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 31,
			"id": "6036c911-c931-4a4e-a1ff-45b2e106b151",
			"tnImgPath": "https://pi.movoto.com/p/12/40968810_0_YBVEua_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Aimee Ran Song",
			"listPrice": 1349800,
			"lotSize": 1892,
			"sqftTotal": 2465,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968810",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2012,
			"zipCode": "95035",
			"path": "milpitas-ca/69-henriques-ln-milpitas-ca-95035/pid_fiax2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b320c618-26b8-4d35-a59e-c56f2a98029d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-12 16:21:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=9y9qkxFhTgF&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.43337,
				"lng": -121.890451,
				"zipcode": "95035",
				"subPremise": "",
				"address": "69 Henriques Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=9y9qkxFhTgF&brand=0"
		},
		{
			"closePrice": 1054056,
			"daysOnMovoto": 330,
			"id": "32b4eb9d-3e82-4b67-9d9f-438c5aa45097",
			"tnImgPath": "https://pi.movoto.com/p/12/40932036_0_aqBErB_p.jpeg",
			"listDate": "2020-12-15 00:00:00",
			"listingAgent": "Dianna Novela Sanchez",
			"listPrice": 1055995,
			"lotSize": 0,
			"sqftTotal": 1467,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40932036",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 8,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95035",
			"path": "milpitas-ca/520-sage-ct-milpitas-ca-95035-12_40932036/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7ec147c-abf6-41ea-abde-6e6e3bee5b1f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2020-12-15 23:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.405596,
				"lng": -121.890677,
				"zipcode": "95035",
				"subPremise": "",
				"address": "520 Sage Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1054056,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1088888,
			"daysOnMovoto": 61,
			"id": "67a717bb-a4a3-421d-9939-a469bc14d10f",
			"tnImgPath": "https://pi.movoto.com/p/12/40966492_0_juIAbQ_p.jpeg",
			"listDate": "2021-09-09 00:00:00",
			"listingAgent": "Irene Xie",
			"listPrice": 1088888,
			"lotSize": 1294,
			"sqftTotal": 1997,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966492",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "MAXREAL",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "95035",
			"path": "milpitas-ca/1462-lavender-loop-milpitas-ca-95035-100_ml81742636/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7f42b562-e954-438b-a90b-b7170a551379",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-09-09 22:51:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.413215,
				"lng": -121.888885,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1462 Lavender Loop"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1088888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 42,
			"id": "d8dff29d-9bf2-4041-9082-c38590e1bd96",
			"tnImgPath": "https://pi.movoto.com/p/12/40968549_0_anF2IU_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Omar Murillo",
			"listPrice": 825000,
			"lotSize": 0,
			"sqftTotal": 1104,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968549",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 11,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2005,
			"zipCode": "95035",
			"path": "milpitas-ca/264-parc-place-dr-milpitas-ca-95035/pid_ka8m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f53c82c3-1707-4334-bb97-746916e9e7d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-24 20:41:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=jRfbY4MVZNr&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.420662,
				"lng": -121.899244,
				"zipcode": "95035",
				"subPremise": "",
				"address": "264 Parc Place Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=jRfbY4MVZNr&brand=0"
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 35,
			"id": "14f03be8-ff75-4f5a-aca1-023271b49101",
			"tnImgPath": "https://pi.movoto.com/p/12/40967973_0_nmmNyq_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Shaiju Varghese",
			"listPrice": 1590000,
			"lotSize": 4000,
			"sqftTotal": 1996,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967973",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "95035",
			"path": "milpitas-ca/1157-fox-hollow-ct-milpitas-ca-95035/pid_vl4m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "875f2ce8-1f60-49d9-86f6-a63df79fddbd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-21 21:56:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=synyZ9xBxWQ&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.448908,
				"lng": -121.889013,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1157 Fox Hollow Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=synyZ9xBxWQ&brand=0"
		},
		{
			"closePrice": 1488150,
			"daysOnMovoto": 215,
			"id": "d064701f-aaf9-4e27-8225-155529bfff38",
			"tnImgPath": "https://pi.movoto.com/p/12/40942249_0_32e6Mr_p.jpeg",
			"listDate": "2021-03-21 00:00:00",
			"listingAgent": "Dianna Novela Sanchez",
			"listPrice": 1439695,
			"lotSize": 0,
			"sqftTotal": 1979,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40942249",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95035",
			"path": "milpitas-ca/506-sage-ct-milpitas-ca-95035-12_40942249/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "2d756d74-7027-4c16-b4d9-6529adc772c5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-03-22 00:31:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.405554,
				"lng": -121.891043,
				"zipcode": "95035",
				"subPremise": "",
				"address": "506 Sage Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1488150,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 62,
			"id": "3e40c0f0-3ed3-46b5-b95e-7ca8e5a53af8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858986_0_aBi3nm_p.jpeg",
			"listDate": "2021-08-18 16:52:08",
			"listingAgent": "Lawrence Monterrosa",
			"listPrice": 1799999,
			"lotSize": 7562,
			"sqftTotal": 2992,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858986",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 28,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95035",
			"path": "milpitas-ca/1188-edsel-milpitas-ca-95035-100_ml81858986/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -75001,
			"propertyId": "206b3217-f58b-4364-a72f-d21b6522f92e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-08-18 23:57:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 16:52:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.430148,
				"lng": -121.883366,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1188 Edsel"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 980000,
			"daysOnMovoto": 78,
			"id": "5d76c2a8-65bd-436d-b764-f79563f998a4",
			"tnImgPath": "https://pi.movoto.com/p/12/40960914_0_e7urVr_p.jpeg",
			"listDate": "2021-07-29 00:00:00",
			"listingAgent": "Bernard Laffer",
			"listPrice": 948000,
			"lotSize": 0,
			"sqftTotal": 1449,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40960914",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty World Complete Srcvs",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "95035",
			"path": "milpitas-ca/215-baja-rose-st-milpitas-ca-95035-12_40960914/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a165b17-332b-48c9-af80-79b9f8695393",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-07-29 16:46:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-29 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2896801",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.409128,
				"lng": -121.898056,
				"zipcode": "95035",
				"subPremise": "",
				"address": "215 Baja Rose St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2896801"
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 81,
			"id": "b3002938-94ef-4da5-9334-ed4bae6147c2",
			"tnImgPath": "https://pi.movoto.com/p/12/40960530_0_6YEja7_p.jpeg",
			"listDate": "2021-07-26 00:00:00",
			"listingAgent": "Josh Uy",
			"listPrice": 1495000,
			"lotSize": 5775,
			"sqftTotal": 1845,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40960530",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1972,
			"zipCode": "95035",
			"path": "milpitas-ca/597-singley-dr-milpitas-ca-95035/pid_i01m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d622177b-b245-42de-a510-df9e6484b314",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-07-27 00:51:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-26 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=ZjKSnmmf5mn&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.443792,
				"lng": -121.89755,
				"zipcode": "95035",
				"subPremise": "",
				"address": "597 Singley Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=ZjKSnmmf5mn&brand=0"
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 39,
			"id": "30ae8e65-6a7b-406b-90f6-a5a80e10c4b9",
			"tnImgPath": "https://pi.movoto.com/p/12/40965820_0_VbmMNJ_p.jpeg",
			"listDate": "2021-09-03 00:00:00",
			"listingAgent": "Omar Murillo",
			"listPrice": 899950,
			"lotSize": 1200,
			"sqftTotal": 1164,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965820",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 12,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1996,
			"zipCode": "95035",
			"path": "milpitas-ca/diamond-way-milpitas-ca-95035-100_80201550/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "537c85e6-71dd-46a7-bcec-9926fb8a0392",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-03 21:41:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=ubZaGSjKutT&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.434198,
				"lng": -121.908882,
				"zipcode": "95035",
				"subPremise": "",
				"address": "283 Diamond Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=ubZaGSjKutT&brand=0"
		},
		{
			"closePrice": 1040000,
			"daysOnMovoto": 39,
			"id": "b1aae408-9ed2-4b4b-bb6f-64fb2edb1fcb",
			"tnImgPath": "https://pi.movoto.com/p/12/40965769_0_jUuBZF_p.jpeg",
			"listDate": "2021-09-03 00:00:00",
			"listingAgent": "Allison Yang",
			"listPrice": 949888,
			"lotSize": 780,
			"sqftTotal": 1375,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965769",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Primary Realty and Finance",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1992,
			"zipCode": "95035",
			"path": "milpitas-ca/430-alegra-milpitas-ca-95035-100_80399294/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d089f7e1-d190-42c8-ab4b-03686aa0182e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-03 19:01:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.451552,
				"lng": -121.917193,
				"zipcode": "95035",
				"subPremise": "",
				"address": "430 Alegra"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1040000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1068000,
			"daysOnMovoto": 52,
			"id": "7e6a81f2-e67c-47be-ba99-5c8bd891a4c1",
			"tnImgPath": "https://pi.movoto.com/p/12/40963336_0_QZFjEF_p.jpeg",
			"listDate": "2021-08-16 00:00:00",
			"listingAgent": "Skand Mittal",
			"listPrice": 1099888,
			"lotSize": 1294,
			"sqftTotal": 1997,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963336",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "95035",
			"path": "milpitas-ca/792-garden-st-milpitas-ca-95035-12_40956536/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5608a4c8-8dcf-492e-989a-31c5283844ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-08-16 18:51:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-16 00:00:00",
			"virtualTourLink": "https://792GardenSt43010mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.413858,
				"lng": -121.889053,
				"zipcode": "95035",
				"subPremise": "",
				"address": "792 Garden St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1068000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://792GardenSt43010mls.f8re.com/"
		},
		{
			"closePrice": 1630000,
			"daysOnMovoto": 0,
			"id": "111cd1ad-589b-4a49-8825-4aebfa0e742b",
			"tnImgPath": "https://pi.movoto.com/p/102/221109716_0_veIf2J_p.jpeg",
			"listDate": null,
			"listingAgent": "Frederick Fernandez",
			"listPrice": 1499000,
			"lotSize": 4138,
			"sqftTotal": 1715,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221109716",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The Fernandez Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1988,
			"zipCode": "95035",
			"path": "milpitas-ca/667-grayson-way-milpitas-ca-95035-102_221109716/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3763375d-bd99-4e2e-9aba-205a934d31f1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-08-31 14:04:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 14:04:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.44807,
				"lng": -121.897338,
				"zipcode": "95035",
				"subPremise": "",
				"address": "667 Grayson Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1790000,
			"daysOnMovoto": 61,
			"id": "38db429b-fbc7-4d1e-baef-1eed5a46da49",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81856909_0_ruIaNJ_p.jpeg",
			"listDate": "2021-08-05 08:12:47",
			"listingAgent": "Suke Zhao",
			"listPrice": 1799900,
			"lotSize": 7527,
			"sqftTotal": 2992,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81856909",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Bayview Realty & Financial",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95035",
			"path": "milpitas-ca/1119-shirley-dr-milpitas-ca-95035/pid_629m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee6ccd0a-74c8-460f-895b-031eefb0c86c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-06 00:00:00",
			"createdAt": "2021-08-05 15:17:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-05 08:12:47",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.431273,
				"lng": -121.884744,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1119 Shirley Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1790000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1080000,
			"daysOnMovoto": 110,
			"id": "da5047e1-1dbf-4e57-8df6-cd0a8a75f684",
			"tnImgPath": "https://pi.movoto.com/p/12/40955173_0_mJuJnv_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Tina Ying",
			"listPrice": 1146000,
			"lotSize": 985,
			"sqftTotal": 1504,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955173",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "New Century RE & Investment",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2009,
			"zipCode": "95035",
			"path": "milpitas-ca/307-shaughnessy-dr-milpitas-ca-95035-100_81045392/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -4000,
			"propertyId": "07775546-2342-493f-8500-f70864d73b1d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-06 00:00:00",
			"createdAt": "2021-06-19 01:56:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.435785,
				"lng": -121.897711,
				"zipcode": "95035",
				"subPremise": "",
				"address": "307 Shaughnessy Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1080000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1467720,
			"daysOnMovoto": 140,
			"id": "9baf4356-5828-4b6d-8015-b0a7946c69a4",
			"tnImgPath": "https://pi.movoto.com/p/12/40950404_0_32e6Mr_p.jpeg",
			"listDate": "2021-05-18 00:00:00",
			"listingAgent": "Dianna Novela Sanchez",
			"listPrice": 1507620,
			"lotSize": 0,
			"sqftTotal": 1979,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40950404",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95035",
			"path": "milpitas-ca/2053-joshua-tree-cir-milpitas-ca-95035-2001_sj0znz1as1/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ce859a5b-59c7-439b-b9a7-7c9ebc0cc919",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-05-18 20:01:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.405177,
				"lng": -121.890591,
				"zipcode": "95035",
				"subPremise": "",
				"address": "2053 Joshua Tree Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1467720,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3605000,
			"daysOnMovoto": 38,
			"id": "f4e13609-a60b-430d-836f-e795d15cc48a",
			"tnImgPath": "https://pi.movoto.com/p/12/40962089_0_nzrvQZ_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Michael Tessaro",
			"listPrice": 3400000,
			"lotSize": 65340,
			"sqftTotal": 5093,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962089",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "95035",
			"path": "milpitas-ca/1626-calera-creek-heights-dr-milpitas-ca-95035/pid_035m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "86049edc-5fc9-41bc-8843-09c328c9bd3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-04 00:00:00",
			"createdAt": "2021-08-27 19:01:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/7850337",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.461806,
				"lng": -121.882366,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1626 Calera Creek Heights Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3605000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/7850337"
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 38,
			"id": "adb6344f-aa0d-4176-b020-497a3e94953f",
			"tnImgPath": "https://pi.movoto.com/p/12/40964905_0_bfAubQ_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Stephen Lin",
			"listPrice": 819000,
			"lotSize": 435,
			"sqftTotal": 1386,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964905",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95035",
			"path": "milpitas-ca/1101-s-main-st-232-milpitas-ca-95035-100_81035452/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7db6812e-68e4-43ac-9e72-ae8d23679c3a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-04 00:00:00",
			"createdAt": "2021-08-27 14:46:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/2052171",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.413869,
				"lng": -121.902901,
				"zipcode": "95035",
				"subPremise": "APT 232",
				"address": "1101 S Main St #232"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/2052171"
		},
		{
			"closePrice": 2175000,
			"daysOnMovoto": 28,
			"id": "edb0e43e-601b-4744-8b37-86ce111089b5",
			"tnImgPath": "https://pi.movoto.com/p/12/40965609_0_RaeFBy_p.jpeg",
			"listDate": "2021-09-02 00:00:00",
			"listingAgent": "Liping Zhang",
			"listPrice": 1998000,
			"lotSize": 4438,
			"sqftTotal": 3106,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965609",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "95035",
			"path": "milpitas-ca/208-silverlake-dr-milpitas-ca-95035-100_81409932/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5c237732-8c83-4a59-a254-c00f87b98daf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-09-02 18:56:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.435619,
				"lng": -121.903018,
				"zipcode": "95035",
				"subPremise": "",
				"address": "208 Silverlake Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 71,
			"id": "15211124-3994-4c06-b606-7a379dab0029",
			"tnImgPath": "https://pi.movoto.com/p/12/40959751_0_63Urmf_p.jpeg",
			"listDate": "2021-07-21 00:00:00",
			"listingAgent": "James Quintero",
			"listPrice": 1415000,
			"lotSize": 6272,
			"sqftTotal": 1247,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959751",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Radius Agent Realty",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "95035",
			"path": "milpitas-ca/1108-moonlight-way-milpitas-ca-95035/pid_be5m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "64c55f53-2b7a-4ad4-be13-27fce73138b4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-07-21 20:21:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=PqXdDbF8BXN&brand=0&mls=1&",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.412897,
				"lng": -121.909553,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1108 Moonlight Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=PqXdDbF8BXN&brand=0&mls=1&"
		},
		{
			"closePrice": 245000,
			"daysOnMovoto": 63,
			"id": "a5deaa6a-0cb6-43a6-8e03-64a45d1f5217",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81855543_0_7VvfMA_p.jpeg",
			"listDate": "2021-07-27 10:47:12",
			"listingAgent": "Lan Nguyen",
			"listPrice": 245000,
			"lotSize": null,
			"sqftTotal": 968,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81855543",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Alliance Manufactured Homes, Inc.",
			"photoCount": 38,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1980,
			"zipCode": "95035",
			"path": "milpitas-ca/1515-n-milpitas-blvd-milpitas-ca-95035-100_ml81646758/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1b5c7b0a-924d-4488-aeeb-ee40f6c2c145",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-29 00:00:00",
			"createdAt": "2021-07-27 17:52:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-27 10:47:12",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.453417,
				"lng": -121.913486,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1515 N Milpitas Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 245000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1433000,
			"daysOnMovoto": 69,
			"id": "4c20abd2-f8ae-420a-a725-34d3ebc7d548",
			"tnImgPath": "https://pi.movoto.com/p/110/421594480_0_eV7IIr_p.jpeg",
			"listDate": "2021-07-20 00:00:00",
			"listingAgent": "Tina Tsao",
			"listPrice": 1200000,
			"lotSize": 6239,
			"sqftTotal": 1475,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421594480",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1977,
			"zipCode": "95035",
			"path": "milpitas-ca/256-la-honda-dr-milpitas-ca-95035-110_421594480/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "12c8855b-a404-4e8d-9ad4-0599cca78439",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-27 00:00:00",
			"createdAt": "2021-09-20 17:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.443304,
				"lng": -121.911287,
				"zipcode": "95035",
				"subPremise": "",
				"address": "256 La Honda Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1433000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1330000,
			"daysOnMovoto": 41,
			"id": "1616e5ae-01c3-41c6-9831-6faab74e9a82",
			"tnImgPath": "https://pi.movoto.com/p/12/40962450_0_y32amu_p.jpeg",
			"listDate": "2021-08-14 00:00:00",
			"listingAgent": "Dennis Lau",
			"listPrice": 1199000,
			"lotSize": 3306,
			"sqftTotal": 1559,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962450",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "CBC Realty Inc",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1985,
			"zipCode": "95035",
			"path": "milpitas-ca/37-washington-dr-milpitas-ca-95035/pid_tw0m2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1674b91-ed9b-4f73-ac33-c49774f2b94a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-08-14 07:16:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.452906,
				"lng": -121.909553,
				"zipcode": "95035",
				"subPremise": "",
				"address": "37 Washington Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1330000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 34,
			"id": "9ad4e5a3-3fcc-4205-8c89-8574f3b74d40",
			"tnImgPath": "https://pi.movoto.com/p/110/421595112_0_MmEifa_p.jpeg",
			"listDate": "2021-08-20 00:00:00",
			"listingAgent": "Danni Zhao",
			"listPrice": 899800,
			"lotSize": 1202,
			"sqftTotal": 1851,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595112",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 46,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95035",
			"path": "milpitas-ca/700-s-abel-st-413-milpitas-ca-95035-100_81435373/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7a126263-cf16-45eb-b09c-bfca7910addc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-23 00:00:00",
			"createdAt": "2021-09-15 21:26:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 00:00:00",
			"virtualTourLink": "http://www.topofluna.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.42105,
				"lng": -121.906199,
				"zipcode": "95035",
				"subPremise": "APT 413",
				"address": "700 S Abel St #413"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.topofluna.com"
		},
		{
			"closePrice": 1070000,
			"daysOnMovoto": 64,
			"id": "807558a5-98fa-491a-963f-e015fe2f4a04",
			"tnImgPath": "https://pi.movoto.com/p/12/40959369_0_6RmRmi_p.jpeg",
			"listDate": "2021-07-20 00:00:00",
			"listingAgent": "Man Kit Mark Tsoi",
			"listPrice": 1050000,
			"lotSize": 3300,
			"sqftTotal": 1350,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959369",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "MJE Real Estate, Inc",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1983,
			"zipCode": "95035",
			"path": "milpitas-ca/717-folsom-cir-milpitas-ca-95035-100_80838437/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d375eed2-2936-4b3a-ba56-86375f9f68f9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-22 00:00:00",
			"createdAt": "2021-07-21 04:26:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.439295,
				"lng": -121.905148,
				"zipcode": "95035",
				"subPremise": "",
				"address": "717 Folsom Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1070000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 969,
			"id": "72a03d7d-1e5a-460c-9ed7-29dd42677354",
			"tnImgPath": "https://pi.movoto.com/p/12/40851327_0_y3MYu3_p.jpeg",
			"listDate": "2019-01-26 00:00:00",
			"listingAgent": "May Pu",
			"listPrice": 899000,
			"lotSize": 103237,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40851327",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 16,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "95035",
			"path": "milpitas-ca/500-vista-spring-ct-milpitas-ca-95035-12_40851327/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -110900,
			"propertyId": "5c60f9eb-f5f4-4536-9629-57a937d4cb4f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-21 00:00:00",
			"createdAt": "2019-01-26 16:11:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2019-01-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.438461,
				"lng": -121.844029,
				"zipcode": "95035",
				"subPremise": "",
				"address": "500 Vista Spring Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1670000,
			"daysOnMovoto": 37,
			"id": "9334197a-7cbe-44f8-bd27-d86fb13ed5f5",
			"tnImgPath": "https://pi.movoto.com/p/12/40959917_0_7rqVi6_p.jpeg",
			"listDate": "2021-08-09 00:00:00",
			"listingAgent": "Shivani Yadav",
			"listPrice": 1450000,
			"lotSize": 5500,
			"sqftTotal": 2006,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959917",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty One Group AMR",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1965,
			"zipCode": "95035",
			"path": "milpitas-ca/1630-crater-lake-ave-milpitas-ca-95035-100_80212934/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bf9b3e48-bbcd-4725-abe1-f554c815bef8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-15 00:00:00",
			"createdAt": "2021-08-09 17:11:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-09 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/584561438",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.427025,
				"lng": -121.874079,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1630 Crater Lake Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/584561438"
		},
		{
			"closePrice": 885000,
			"daysOnMovoto": 40,
			"id": "ba0d5674-12a9-4c13-8221-afae4b40622b",
			"tnImgPath": "https://pi.movoto.com/p/12/40960963_0_26MQyj_p.jpeg",
			"listDate": "2021-08-04 00:00:00",
			"listingAgent": "Shelly Arora",
			"listPrice": 849000,
			"lotSize": 0,
			"sqftTotal": 1243,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40960963",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "95035",
			"path": "milpitas-ca/1463-nightshade-rd-apt-41-milpitas-ca-95035-100_ml81770050/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "61c1daac-a934-4f1b-bc38-2805e6633cf8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-08-04 16:41:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-04 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/4438665",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.412755,
				"lng": -121.88997,
				"zipcode": "95035",
				"subPremise": "APT 41",
				"address": "1463 Nightshade Rd #41"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 885000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/4438665"
		},
		{
			"closePrice": 1118000,
			"daysOnMovoto": 28,
			"id": "49da616b-5b5a-4722-956f-2ec2ab2a1d94",
			"tnImgPath": "https://pi.movoto.com/p/12/40963153_0_vejire_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Kenneth Young",
			"listPrice": 1060000,
			"lotSize": 1145,
			"sqftTotal": 1512,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963153",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2013,
			"zipCode": "95035",
			"path": "milpitas-ca/1669-lee-way-milpitas-ca-95035/pid_lyox2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c2acd4f-e3e2-4e83-bd82-b775bf95c8cf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-08-13 22:41:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=BjfQugZCske&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.407289,
				"lng": -121.900377,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1669 Lee Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1118000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=BjfQugZCske&brand=0"
		},
		{
			"closePrice": 1168000,
			"daysOnMovoto": 50,
			"id": "981a7f2f-92d1-4f98-871b-f2a0eeb89548",
			"tnImgPath": "https://pi.movoto.com/p/12/40959855_0_YzUr7u_p.jpeg",
			"listDate": "2021-07-22 00:00:00",
			"listingAgent": "Kaajal Shahani",
			"listPrice": 1099000,
			"lotSize": 675,
			"sqftTotal": 1522,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959855",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2014,
			"zipCode": "95035",
			"path": "milpitas-ca/1581-coyote-creek-way-milpitas-ca-95035/pid_mqox2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c715317d-6443-44b0-a9ea-32637330190c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-07-23 04:51:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-22 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=MEfkZpKyknU&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.416668,
				"lng": -121.92832,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1581 Coyote Creek Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1168000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=MEfkZpKyknU&mls=1"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 0,
			"id": "edd453bb-a489-45a5-bf40-5cbaa54c5934",
			"tnImgPath": "https://pi.movoto.com/p/102/221091326_0_7abrAu_p.jpeg",
			"listDate": null,
			"listingAgent": "Victoria Witham",
			"listPrice": 875000,
			"lotSize": 479,
			"sqftTotal": 1065,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221091326",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Witham Real Estate",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2018,
			"zipCode": "95035",
			"path": "milpitas-ca/1486-cherry-cir-milpitas-ca-95035-102_221091326/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c731970f-48b1-4d2a-99b3-2f0b47052768",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-07-29 23:09:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-29 23:09:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.413691,
				"lng": -121.887835,
				"zipcode": "95035",
				"subPremise": "",
				"address": "1486 Cherry Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 894000,
			"daysOnMovoto": 48,
			"id": "5fd19a0c-8a42-46da-a344-0eeb93fb2f80",
			"tnImgPath": "https://pi.movoto.com/p/12/40959827_0_fqvMmi_p.jpeg",
			"listDate": "2021-07-23 00:00:00",
			"listingAgent": "Jhanhara Mohammed",
			"listPrice": 899000,
			"lotSize": 435,
			"sqftTotal": 1421,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959827",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Top Mission Realty & Invest",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95035",
			"path": "milpitas-ca/600-s-abel-st-414-milpitas-ca-95035/pid_qlan2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "01476d9c-67a1-4955-ae6b-5f5a05897d62",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-07-24 04:11:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-23 00:00:00",
			"virtualTourLink": "https://600SAbelStUnit41444360mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.421672,
				"lng": -121.905937,
				"zipcode": "95035",
				"subPremise": "APT 414",
				"address": "600 S Abel St #414"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 894000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://600SAbelStUnit41444360mls.f8re.com/"
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 7,
			"id": "6645d8d2-6dd1-4647-a1d0-275481502e4b",
			"tnImgPath": "https://pi.movoto.com/p/110/421591139_0_UYBYF2_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Brandon Chen",
			"listPrice": 1495000,
			"lotSize": 3294,
			"sqftTotal": 2295,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421591139",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 58,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2013,
			"zipCode": "95035",
			"path": "milpitas-ca/946-gomes-ln-milpitas-ca-95035/pid_ullx2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d843698c-7dcf-4b32-b39f-dc667d8cfce1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-08 00:00:00",
			"createdAt": "2021-09-03 18:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=aeTWViEyVHx&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Milpitas",
				"lat": 37.430358,
				"lng": -121.887889,
				"zipcode": "95035",
				"subPremise": "",
				"address": "946 Gomes Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=aeTWViEyVHx&mls=1"
		}
	]