exports.SUNNYVALE_LISTINGS = [
		{
			"closePrice": 630000,
			"daysOnMovoto": 12,
			"id": "88442298-8813-4cb5-99cf-d47fe7e9840e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81873668_0_rbfz7r_p.jpeg",
			"listDate": "2022-01-06 15:26:23",
			"listingAgent": "Lila Kazemi",
			"listPrice": 599888,
			"lotSize": null,
			"sqftTotal": 822,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81873668",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1976,
			"zipCode": "94086",
			"path": "sunnyvale-ca/999-w-evelyn-ter-73-sunnyvale-ca-94086-100_81338729/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ff5b6e2-57db-4f53-9ae0-15426d76befc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2022-01-06 23:32:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2022-01-06 15:26:23",
			"virtualTourLink": "https://my.matterport.com/show/?m=bLKTySM6e4b",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.371657,
				"lng": -122.007005,
				"zipcode": "94086",
				"subPremise": "APT 73",
				"address": "999 W Evelyn Ter #73"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=bLKTySM6e4b"
		},
		{
			"closePrice": 2005000,
			"daysOnMovoto": 40,
			"id": "d571ba01-cc5e-4906-8605-2c7e0e637478",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871707_0_UbeQRJ_p.jpeg",
			"listDate": "2021-12-09 00:00:00",
			"listingAgent": "Team Bedbury",
			"listPrice": 1649000,
			"lotSize": 1346,
			"sqftTotal": 1700,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871707",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 65,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2020,
			"zipCode": "94086",
			"path": "sunnyvale-ca/629-e-evelyn-ave-sunnyvale-ca-94086-100_ml81871707/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7a79715d-daad-4518-96a0-312301047e27",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-09 17:12:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 00:00:00",
			"virtualTourLink": "http://629Evelyn.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.373139,
				"lng": -122.019274,
				"zipcode": "94086",
				"subPremise": "",
				"address": "629 Evelyn Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2005000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://629Evelyn.com"
		},
		{
			"closePrice": 1862000,
			"daysOnMovoto": 47,
			"id": "bb074dd7-7213-4ef0-8493-430f6ff528d8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871475_0_izFiZe_p.jpeg",
			"listDate": "2021-12-01 15:46:40",
			"listingAgent": "David Martz",
			"listPrice": 1799950,
			"lotSize": 7000,
			"sqftTotal": 2288,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871475",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 2,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1954,
			"zipCode": "94086",
			"path": "sunnyvale-ca/209-morse-ave-sunnyvale-ca-94086/pid_4dzo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e6723028-929d-477b-849f-9e4c6951d37a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-01 23:52:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 15:46:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.380882,
				"lng": -122.02361,
				"zipcode": "94086",
				"subPremise": "",
				"address": "209 Morse Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1862000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 755000,
			"daysOnMovoto": 153,
			"id": "fc965c7d-5547-4c35-b6c7-7a975bb81dad",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858674_0_R2bnMA_p.jpeg",
			"listDate": "2021-08-17 08:21:25",
			"listingAgent": "Avil Soleiman",
			"listPrice": 760000,
			"lotSize": null,
			"sqftTotal": 970,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858674",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Akkadian Group",
			"photoCount": 42,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1980,
			"zipCode": "94085",
			"path": "sunnyvale-ca/577-e-taylor-ave-apt-m-sunnyvale-ca-94085-100_ml81858674/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "a9160443-3465-4ce7-a1af-fd781d433da2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-08-17 15:27:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 08:21:25",
			"virtualTourLink": "https://577EastTaylorAvenueUnitM45336.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.383874,
				"lng": -122.018472,
				"zipcode": "94085",
				"subPremise": "APT M",
				"address": "577 E Taylor Ave #M"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 755000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://577EastTaylorAvenueUnitM45336.f8re.com/"
		},
		{
			"closePrice": 2015000,
			"daysOnMovoto": 35,
			"id": "bbeafa62-987b-4bcf-9087-6dfe8a6a523d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872065_0_jR2me6_p.jpeg",
			"listDate": "2021-12-08 11:45:21",
			"listingAgent": "Robert Gosalvez",
			"listPrice": 1550000,
			"lotSize": 6273,
			"sqftTotal": 1453,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872065",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1948,
			"zipCode": "94085",
			"path": "sunnyvale-ca/347-n-bayview-ave-sunnyvale-ca-94085/pid_nfyo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13d10b1b-a661-409b-8bfe-6b222a996764",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-12-08 19:47:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-08 11:45:21",
			"virtualTourLink": "https://www.youtube.com/embed/TrREBif5TiY",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.385128,
				"lng": -122.023343,
				"zipcode": "94085",
				"subPremise": "",
				"address": "347 N Bayview Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2015000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/TrREBif5TiY"
		},
		{
			"closePrice": 1688000,
			"daysOnMovoto": 34,
			"id": "dfab5411-fd0c-49bd-af99-7dec91a9062d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871725_0_NmJbBN_p.jpeg",
			"listDate": "2021-12-03 14:23:17",
			"listingAgent": "Rebecca Yen",
			"listPrice": 1468000,
			"lotSize": 6322,
			"sqftTotal": 1497,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871725",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1954,
			"zipCode": "94085",
			"path": "sunnyvale-ca/596-n-eden-ave-sunnyvale-ca-94085/pid_0gwo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5063dcda-86d8-490c-b8f0-3451892fe736",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-12-03 22:27:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 14:23:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.390311,
				"lng": -122.014994,
				"zipcode": "94085",
				"subPremise": "",
				"address": "596 N Eden Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1688000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1720000,
			"daysOnMovoto": 35,
			"id": "8789faf4-977a-447d-aa83-e3bcf6d6dc91",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870215_0_UYyJE6_p.jpeg",
			"listDate": "2021-12-02 00:00:00",
			"listingAgent": "L. Bobby Balagot",
			"listPrice": 1350000,
			"lotSize": 5149,
			"sqftTotal": 1008,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870215",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Genesis Homes & Associates",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1940,
			"zipCode": "94086",
			"path": "sunnyvale-ca/190-n-murphy-ave-sunnyvale-ca-94086/pid_af0o2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e50d3501-fe74-48d1-a285-5f0623438e30",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-12-02 22:42:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.380681,
				"lng": -122.028628,
				"zipcode": "94086",
				"subPremise": "",
				"address": "190 N Murphy Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 838000,
			"daysOnMovoto": 22,
			"id": "11130a2c-adee-4e71-a877-72facc181a36",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872421_0_FJqBNb_p.jpeg",
			"listDate": "2021-12-13 11:10:50",
			"listingAgent": "Brad Pickens",
			"listPrice": 799000,
			"lotSize": 1390,
			"sqftTotal": 930,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872421",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Real Estate Experts",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "94087",
			"path": "sunnyvale-ca/880-e-fremont-ave-720-sunnyvale-ca-94087/pid_0yzq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9f36b3c6-a3ff-402b-91f8-8a04058f8401",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-12-13 19:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-13 11:10:50",
			"virtualTourLink": "https://www.youtube.com/embed/r7J-uQrol9s",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.35183,
				"lng": -122.015045,
				"zipcode": "94087",
				"subPremise": "APT 720",
				"address": "880 E Fremont Ave #720"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 838000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/r7J-uQrol9s"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 0,
			"id": "7238a491-0991-47e1-aa45-ef874b830dfc",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2022-01-04 00:00:00",
			"listingAgent": "Alex H. Wang",
			"listPrice": 1398000,
			"lotSize": 3184,
			"sqftTotal": 1374,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81873486",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 0,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1973,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1193-s-bernardo-ave-sunnyvale-ca-94087/pid_9sxo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "71164d32-7599-4ff6-8d62-0d866fc18394",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2022-01-05 01:31:16",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2022-01-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.355918,
				"lng": -122.059925,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1193 S Bernardo Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 42,
			"id": "03cd3663-a386-41a1-b9ef-20e0ede715e9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870321_0_uyvq2F_p.jpeg",
			"listDate": "2021-11-16 08:04:09",
			"listingAgent": "David Lillo",
			"listPrice": 1198000,
			"lotSize": 5201,
			"sqftTotal": 1116,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870321",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "DPL Real Estate",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94085",
			"path": "sunnyvale-ca/662-e-duane-ave-sunnyvale-ca-94085-100_81319623/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dcd28cc8-e60f-47d5-8202-e74649d79067",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-16 16:07:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 08:04:09",
			"virtualTourLink": "https://www.tourfactory.com/2936421",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.388241,
				"lng": -122.013678,
				"zipcode": "94085",
				"subPremise": "",
				"address": "662 E Duane Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2936421"
		},
		{
			"closePrice": 1375000,
			"daysOnMovoto": 0,
			"id": "f2b5d4d4-400f-4020-b4aa-1055555828a1",
			"tnImgPath": "https://pi.movoto.com/p/102/221142214_0_ZfbIVI_p.jpeg",
			"listDate": null,
			"listingAgent": "Ronald Cedillo",
			"listPrice": 1190000,
			"lotSize": 5040,
			"sqftTotal": 884,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221142214",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Home Buyers Realty",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1950,
			"zipCode": "94085",
			"path": "sunnyvale-ca/593-borregas-ave-sunnyvale-ca-94085/pid_1izo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d8967d7c-67f7-4a6c-af34-70e481a8e5b3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-10 20:04:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 20:04:33",
			"virtualTourLink": "https://my.matterport.com/show/?m=j65ifunTAHv",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.390528,
				"lng": -122.025341,
				"zipcode": "94085",
				"subPremise": "",
				"address": "593 Borregas Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1375000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=j65ifunTAHv"
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 77,
			"id": "fb9383fd-1b4f-4c80-b4c6-8f9fadf0e09e",
			"tnImgPath": "https://pi.movoto.com/p/12/40970367_0_Qbn22j_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Bo Zhang",
			"listPrice": 869000,
			"lotSize": 960,
			"sqftTotal": 1134,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970367",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Show Best Realty",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94085",
			"path": "sunnyvale-ca/765-n-fair-oaks-ave-4-sunnyvale-ca-94085-100_81342201/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "27da0d93-b3f2-425f-8fec-dc5a07cd558e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-24 00:00:00",
			"createdAt": "2021-10-09 03:41:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.392952,
				"lng": -122.015349,
				"zipcode": "94085",
				"subPremise": "APT 4",
				"address": "765 N Fair Oaks Ave #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 385000,
			"daysOnMovoto": 112,
			"id": "8c8bcbf4-30b6-47da-a368-ec02d1f9c099",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860808_0_qqMAzE_p.jpeg",
			"listDate": "2021-09-01 11:02:32",
			"listingAgent": "Lan Nguyen",
			"listPrice": 389000,
			"lotSize": null,
			"sqftTotal": 1534,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860808",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Manufactured Homes, Inc.",
			"photoCount": 56,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2018,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1085-tasman-sunnyvale-ca-94089-202_ml81518423/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b894386a-b9fb-4785-af5b-71c0d9e2ef3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-09-01 18:07:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 11:02:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.403773,
				"lng": -121.999491,
				"zipcode": "94089",
				"subPremise": "",
				"address": "1085 Tasman"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 385000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2150000,
			"daysOnMovoto": 60,
			"id": "50e2ff9f-308c-4bf3-b9ba-f9f27bd24c4b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866186_0_vzeYqN_p.jpeg",
			"listDate": "2021-10-09 06:10:35",
			"listingAgent": "Rick Trevino",
			"listPrice": 2125000,
			"lotSize": 5937,
			"sqftTotal": 2832,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866186",
			"bath": null,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 66,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1966,
			"zipCode": "94086",
			"path": "sunnyvale-ca/434-s-bernardo-ave-sunnyvale-ca-94086/pid_ryao2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "ccba9681-7760-4489-bce3-a1e098429ccc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-09 13:17:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-09 06:10:35",
			"virtualTourLink": "https://my.matterport.com/show/?m=htou8rBuy2q",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.376379,
				"lng": -122.05518,
				"zipcode": "94086",
				"subPremise": "",
				"address": "434 S Bernardo Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=htou8rBuy2q"
		},
		{
			"closePrice": 2828000,
			"daysOnMovoto": 47,
			"id": "f0ad39af-4396-4de2-92c0-4fb78cf931df",
			"tnImgPath": "https://pi.movoto.com/p/12/40971446_0_uNY73U_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Conrad Bassett",
			"listPrice": 2288888,
			"lotSize": 3499,
			"sqftTotal": 2003,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971446",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Outsource Relocation Partners",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2015,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1326-panache-ter-sunnyvale-ca-94087-12_40971446/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77c82125-6764-4e88-abd3-ce93e198efec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-21 16:41:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.351441,
				"lng": -122.039329,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1326 Panache Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2828000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2280000,
			"daysOnMovoto": 75,
			"id": "7afdace0-b093-44ab-94fe-42410af699e6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863773_0_6nmzzv_p.jpeg",
			"listDate": "2021-09-22 14:39:08",
			"listingAgent": "Vicky Li",
			"listPrice": 2250000,
			"lotSize": 5942,
			"sqftTotal": 2960,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863773",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1605-ontario-dr-sunnyvale-ca-94087/pid_1w6q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -40000,
			"propertyId": "bd33b4fa-5a23-4cff-9d4c-99593715d27a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-09-22 21:42:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 14:39:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.340789,
				"lng": -122.04004,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1605 Ontario Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1980000,
			"daysOnMovoto": 60,
			"id": "ed541774-234c-4d98-b193-5d9432171797",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81865156_0_NRBmQy_p.jpeg",
			"listDate": "2021-10-01 15:26:55",
			"listingAgent": "Florence Lam",
			"listPrice": 1950000,
			"lotSize": 7501,
			"sqftTotal": 2390,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81865156",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Trans Continental Real Estate Investment",
			"photoCount": 8,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1957,
			"zipCode": "94086",
			"path": "sunnyvale-ca/499-s-pastoria-ave-sunnyvale-ca-94086-100_ml81865156/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "124c1111-e641-4cef-83c5-4a57a9c3c982",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-01 22:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 15:26:55",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.371398,
				"lng": -122.040752,
				"zipcode": "94086",
				"subPremise": "",
				"address": "499 S Pastoria Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 37,
			"id": "460a6fca-59b2-4050-8bb3-07c1ea82a856",
			"tnImgPath": "https://pi.movoto.com/p/12/40972149_0_VVUAyq_p.jpeg",
			"listDate": "2021-10-24 00:00:00",
			"listingAgent": "Gene Brown",
			"listPrice": 1049000,
			"lotSize": 2450,
			"sqftTotal": 1535,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972149",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty World Diablo Homes",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1992,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1171-la-rochelle-ter-b-sunnyvale-ca-94089-100_81457743/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6f0d734c-c71a-4859-8281-3b6f66abc72a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-24 15:06:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=FzVyvgRSHgj",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.404818,
				"lng": -122.011883,
				"zipcode": "94089",
				"subPremise": "APT B",
				"address": "1171 La Rochelle Ter #B"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=FzVyvgRSHgj"
		},
		{
			"closePrice": 2578000,
			"daysOnMovoto": 63,
			"id": "754f02d6-4e5e-415f-9205-3c0db9bb189e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863408_0_3i2UaJ_p.jpeg",
			"listDate": "2021-09-20 08:10:03",
			"listingAgent": "Mitchell Zurich",
			"listPrice": 2550000,
			"lotSize": 8133,
			"sqftTotal": 3888,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863408",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 18,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1741-noranda-dr-sunnyvale-ca-94087/pid_tv6q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8326dab5-cb50-4b1d-93cd-1158e7a6ea04",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-09-20 15:12:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 08:10:03",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.335743,
				"lng": -122.044568,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1741 Noranda Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2578000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2315000,
			"daysOnMovoto": 37,
			"id": "80752aee-40fb-4e24-a7f6-f3f39c00b273",
			"tnImgPath": "https://pi.movoto.com/p/12/40971251_0_36FbjZ_p.jpeg",
			"listDate": "2021-10-16 00:00:00",
			"listingAgent": "Kevin Lin",
			"listPrice": 2328000,
			"lotSize": 6300,
			"sqftTotal": 4029,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971251",
			"bath": 6,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Century 21 RE Alliance",
			"photoCount": 3,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1978,
			"zipCode": "94086",
			"path": "sunnyvale-ca/1043-reed-ter-sunnyvale-ca-94086/pid_ur6o2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d4a78d93-aa01-4731-aea4-08458a3222f8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-25 22:30:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.368434,
				"lng": -122.000848,
				"zipcode": "94086",
				"subPremise": "",
				"address": "1043 Reed Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2315000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 0,
			"id": "ecd5408d-11e7-434b-9786-e2a11af10b0a",
			"tnImgPath": "https://pi.movoto.com/p/102/221132605_0_rbjQni_p.jpeg",
			"listDate": null,
			"listingAgent": "Christopher Malone",
			"listPrice": 2349000,
			"lotSize": 8342,
			"sqftTotal": 1902,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221132605",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The Malone Group",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1976,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1768-laurentian-way-sunnyvale-ca-94087/pid_er8q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f85c7e47-3ea7-40b3-bcf3-a4ca0b8fb7e1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-15 03:14:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 03:14:22",
			"virtualTourLink": "http://1768Laurentian.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.3351,
				"lng": -122.051051,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1768 Laurentian Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://1768Laurentian.com"
		},
		{
			"closePrice": 1799888,
			"daysOnMovoto": 173,
			"id": "164eb650-b668-4562-8a25-e73c14afd80b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81846448_0_vJFFY6_p.jpeg",
			"listDate": "2021-05-28 22:08:47",
			"listingAgent": "Dina Dy",
			"listPrice": 1799888,
			"lotSize": 5846,
			"sqftTotal": 2756,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81846448",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Movoto Inc.",
			"photoCount": 29,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1959,
			"zipCode": "94086",
			"path": "sunnyvale-ca/611-bryan-ave-sunnyvale-ca-94086/pid_3wzo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -150000,
			"propertyId": "7da5f5ad-5613-4e48-b62d-c7cfb0f4b438",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-05-29 05:12:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-28 22:08:47",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.372521,
				"lng": -122.020873,
				"zipcode": "94086",
				"subPremise": "",
				"address": "611 Bryan Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": true,
			"priceRaw": 1799888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3460000,
			"daysOnMovoto": 77,
			"id": "765214ab-ed38-4987-93a2-06acc718cf63",
			"tnImgPath": "https://pi.movoto.com/p/110/421605038_0_vImaJI_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Jonathan Ng",
			"listPrice": 2495000,
			"lotSize": 8678,
			"sqftTotal": 2167,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421605038",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 81,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1961,
			"zipCode": "94087",
			"path": "sunnyvale-ca/847-the-dalles-ave-sunnyvale-ca-94087/pid_r83q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "35cf462f-39c7-40bd-b707-7fbb56d912c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-11 00:00:00",
			"createdAt": "2021-11-03 17:46:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=3ctq47Vg48o",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.345089,
				"lng": -122.04781,
				"zipcode": "94087",
				"subPremise": "",
				"address": "847 The Dalles Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3460000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=3ctq47Vg48o"
		},
		{
			"closePrice": 1280000,
			"daysOnMovoto": 41,
			"id": "f9bf5908-40ce-4ff7-a3d0-e122603fc00f",
			"tnImgPath": "https://pi.movoto.com/p/12/40969257_0_z2FRab_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Navi Dhillon",
			"listPrice": 1299000,
			"lotSize": 5000,
			"sqftTotal": 1073,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969257",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1944,
			"zipCode": "94086",
			"path": "sunnyvale-ca/855-cedar-ave-sunnyvale-ca-94086-100_80021544/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f9cfb9fc-120d-42e9-918e-1ed7e1d2615c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-01 04:21:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://855Cedar.com/1892248?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.377503,
				"lng": -122.018014,
				"zipcode": "94086",
				"subPremise": "",
				"address": "855 Cedar Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://855Cedar.com/1892248?idx=1"
		},
		{
			"closePrice": 2512000,
			"daysOnMovoto": 48,
			"id": "7b123f49-b20d-4549-82fc-1039c20884f1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863715_0_fJuAM7_p.jpeg",
			"listDate": "2021-09-22 10:51:07",
			"listingAgent": "Valerie Smith",
			"listPrice": 2250000,
			"lotSize": 8050,
			"sqftTotal": 2560,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863715",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1969,
			"zipCode": "94087",
			"path": "sunnyvale-ca/111-113-killean-ct-sunnyvale-ca-94087-100_ml81863715/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d1b4662-0cd5-4013-a48c-683cd8a76db0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-22 17:57:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 10:51:07",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.34091,
				"lng": -122.031705,
				"zipcode": "94087",
				"subPremise": "",
				"address": "111-113 Killean Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2512000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 785000,
			"daysOnMovoto": 82,
			"id": "82371049-b993-47f5-9bb8-4a6c4b2276da",
			"tnImgPath": "https://pi.movoto.com/p/12/40964125_0_Qi3VRj_p.jpeg",
			"listDate": "2021-08-20 00:00:00",
			"listingAgent": "Deepa Dulepet",
			"listPrice": 799000,
			"lotSize": 0,
			"sqftTotal": 1061,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964125",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1995,
			"zipCode": "94085",
			"path": "sunnyvale-ca/250-santa-fe-ter-103-sunnyvale-ca-94085-100_81018038/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "15c7b6fc-8668-42f3-8ce7-436176e3c45c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-08-21 00:16:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.381015,
				"lng": -122.016309,
				"zipcode": "94085",
				"subPremise": "APT 103",
				"address": "250 Santa Fe Ter #103"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 785000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 60,
			"id": "0399c46c-af0b-430e-bbde-6b269d7d4e4a",
			"tnImgPath": "https://pi.movoto.com/p/12/40965979_0_eiEYaY_p.jpeg",
			"listDate": "2021-09-06 00:00:00",
			"listingAgent": "Hamed Barakzoy",
			"listPrice": 1998880,
			"lotSize": 8343,
			"sqftTotal": 1584,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965979",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty World Acclaim",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94086",
			"path": "sunnyvale-ca/149-leota-ave-sunnyvale-ca-94086/pid_mdeo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "10aec2c4-a501-448c-b754-655519085463",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-06 08:26:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.382579,
				"lng": -122.049186,
				"zipcode": "94086",
				"subPremise": "",
				"address": "149 Leota Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 125000,
			"daysOnMovoto": 0,
			"id": "7d79bf83-0a30-4611-b282-601918af98f7",
			"tnImgPath": "https://pi.movoto.com/p/102/221098373_0_VQvyZU_p.jpeg",
			"listDate": null,
			"listingAgent": "Amanda Suarez",
			"listPrice": 124900,
			"lotSize": 0,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221098373",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "AGM Capital Real Estate",
			"photoCount": 35,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1971,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1225-vienna-dr-apt-217-sunnyvale-ca-94089-102_221098373/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -5000,
			"propertyId": "6d567472-e973-42aa-9c72-8a1e25d99415",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-08-09 07:14:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-09 07:14:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.402095,
				"lng": -121.999615,
				"zipcode": "94089",
				"subPremise": "APT 217",
				"address": "1225 Vienna Dr #217"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 125000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1233500,
			"daysOnMovoto": 40,
			"id": "4d48b85b-4908-40e6-993f-3c2edf48117b",
			"tnImgPath": "https://pi.movoto.com/p/12/40967643_0_UaUZZM_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Desiree Shockey",
			"listPrice": 1195000,
			"lotSize": 9000,
			"sqftTotal": 1137,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967643",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Excel Realty",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1133-stoneylake-ct-sunnyvale-ca-94089/pid_cijn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af89b170-b6f3-48f4-939f-3ae9c4e17501",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-09-17 22:56:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=AELdEpsJrAp",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.397296,
				"lng": -121.998427,
				"zipcode": "94089",
				"subPremise": "",
				"address": "1133 Stoneylake Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1233500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=AELdEpsJrAp"
		},
		{
			"closePrice": 2335000,
			"daysOnMovoto": 179,
			"id": "32b88e71-2523-42aa-b375-79ae9ac98aee",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81841693_0_QneYmq_p.jpeg",
			"listDate": "2021-04-30 15:56:25",
			"listingAgent": "Jenny Huang",
			"listPrice": 2268000,
			"lotSize": 5942,
			"sqftTotal": 3252,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81841693",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 55,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1959,
			"zipCode": "94086",
			"path": "sunnyvale-ca/670-grand-fir-ave-sunnyvale-ca-94086/pid_7yzo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -31950,
			"propertyId": "8a7792c2-bef4-48ec-818c-4ad72b1ccf43",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-04-30 23:02:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-30 15:56:25",
			"virtualTourLink": "https://670GrandFirAveUnits123439019.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.364807,
				"lng": -122.021656,
				"zipcode": "94086",
				"subPremise": "",
				"address": "670 Grand Fir Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2335000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://670GrandFirAveUnits123439019.f8re.com/"
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 201,
			"id": "1b6e11bc-7656-4ac4-849a-1069ee50cacd",
			"tnImgPath": "https://pi.movoto.com/p/12/40944849_0_6Ba3bn_p.jpeg",
			"listDate": "2021-04-09 00:00:00",
			"listingAgent": "Mary Byrne Hernandez",
			"listPrice": 650000,
			"lotSize": 960,
			"sqftTotal": 926,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40944849",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "MBH Properties",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94085",
			"path": "sunnyvale-ca/767-n-fair-oaks-ave-4-sunnyvale-ca-94085/pid_5mvo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -19000,
			"propertyId": "c2f5cf47-6e8a-45d4-b8d1-87331462ee20",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-04-09 17:11:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-09 00:00:00",
			"virtualTourLink": "https://tours.tourspace.net/1810674?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.393101,
				"lng": -122.0157,
				"zipcode": "94085",
				"subPremise": "APT 4",
				"address": "767 N Fair Oaks Ave #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourspace.net/1810674?idx=1"
		},
		{
			"closePrice": 140000,
			"daysOnMovoto": 0,
			"id": "861e862d-c387-4b14-b8f8-ed1d16c87c3d",
			"tnImgPath": "https://pi.movoto.com/p/102/221110575_0_MymUY6_p.jpeg",
			"listDate": null,
			"listingAgent": "Katrina Sousa",
			"listPrice": 125000,
			"lotSize": 0,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221110575",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "PMZ Real Estate",
			"photoCount": 41,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1998,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1225-vienna-dr-apt-409-sunnyvale-ca-94089-102_221110575/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e8a8f56d-96a1-4dd2-a35e-37719a068131",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-08-31 23:14:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 23:14:23",
			"virtualTourLink": "https://www.youtube.com/embed/iSpdkdMWRO4",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.402095,
				"lng": -121.999615,
				"zipcode": "94089",
				"subPremise": "APT 409",
				"address": "1225 Vienna Dr #409"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 140000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/iSpdkdMWRO4"
		},
		{
			"closePrice": 1880000,
			"daysOnMovoto": 40,
			"id": "7916d592-eaa7-499c-b1a0-c2bb21c1c15b",
			"tnImgPath": "https://pi.movoto.com/p/12/40967136_0_2JUVAR_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Liping Zhang",
			"listPrice": 1795000,
			"lotSize": 2989,
			"sqftTotal": 2048,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967136",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2008,
			"zipCode": "94086",
			"path": "sunnyvale-ca/469-s-murphy-ave-sunnyvale-ca-94086-100_ml81768512/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ef497af-69b4-4b34-a1ad-0f2ba7a9bb39",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-15 15:41:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.371514,
				"lng": -122.032202,
				"zipcode": "94086",
				"subPremise": "",
				"address": "469 S Murphy Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1368000,
			"daysOnMovoto": 81,
			"id": "43b2baf1-9449-4972-addb-9a679078678c",
			"tnImgPath": "https://pi.movoto.com/p/110/421581710_0_ZmEMuq_p.jpeg",
			"listDate": "2021-08-05 00:00:00",
			"listingAgent": "Athena Chan",
			"listPrice": 1349000,
			"lotSize": 1060,
			"sqftTotal": 1600,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421581710",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "National Realty",
			"photoCount": 50,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2004,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1126-le-mans-ter-sunnyvale-ca-94089-110_421581710/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 61000,
			"propertyId": "f5d48fee-901b-4cfe-a5e7-4094322fafb9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-08-05 19:31:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-05 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=f34tMoJHhZF&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.402044,
				"lng": -122.011014,
				"zipcode": "94089",
				"subPremise": "",
				"address": "1126 Le Mans Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1368000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=f34tMoJHhZF&brand=0"
		},
		{
			"closePrice": 1410000,
			"daysOnMovoto": 31,
			"id": "30a5313d-ebbe-4efe-b41d-f9afcfaf1c42",
			"tnImgPath": "https://pi.movoto.com/p/12/40968009_0_F6mQfe_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Jamie Man Chan",
			"listPrice": 1188000,
			"lotSize": 5670,
			"sqftTotal": 1108,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968009",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Ateev, Inc.",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1166-manzano-way-sunnyvale-ca-94089-100_81118057/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5aa6ebbc-09d2-490f-8581-3fb47dfea86f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-22 03:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.399073,
				"lng": -121.98724,
				"zipcode": "94089",
				"subPremise": "",
				"address": "1166 Manzano Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1410000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1855000,
			"daysOnMovoto": 35,
			"id": "f7e15407-5c71-465d-8b17-f2e640d56919",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863030_0_rE22uv_p.jpeg",
			"listDate": "2021-09-16 14:04:01",
			"listingAgent": "William Chen",
			"listPrice": 1730000,
			"lotSize": 10010,
			"sqftTotal": 2196,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863030",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Faithful",
			"photoCount": 19,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "94086",
			"path": "sunnyvale-ca/1118-ayala-dr-sunnyvale-ca-94086/pid_wceo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5003a91e-85ca-4e97-874a-9263bf25cf61",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-16 21:07:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 14:04:01",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.382933,
				"lng": -122.049008,
				"zipcode": "94086",
				"subPremise": "",
				"address": "1118 Ayala Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1855000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 40,
			"id": "23faf961-d647-4e7e-8ef8-911238f8b471",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862175_0_Vj6ze2_p.jpeg",
			"listDate": "2021-09-10 13:00:14",
			"listingAgent": "Rob Chiang",
			"listPrice": 2300000,
			"lotSize": 9662,
			"sqftTotal": 3763,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862175",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Robert Chiang Real Estate ServicesIncorporated",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94086",
			"path": "sunnyvale-ca/1245-coronado-dr-sunnyvale-ca-94086/pid_6ado2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "899f13ff-ef69-4a24-967a-661e14f9cd87",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-10 20:07:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 13:00:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.382969,
				"lng": -122.055224,
				"zipcode": "94086",
				"subPremise": "",
				"address": "1245 Coronado Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2325000,
			"daysOnMovoto": 134,
			"id": "5df906d6-8631-42ef-832e-b8bb16681eb8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81847854_0_nA3nVQ_p.jpeg",
			"listDate": "2021-06-08 12:29:00",
			"listingAgent": "Rob Chiang",
			"listPrice": 2400000,
			"lotSize": 7466,
			"sqftTotal": 3763,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81847854",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Robert Chiang Real Estate ServicesIncorporated",
			"photoCount": 35,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94086",
			"path": "sunnyvale-ca/1241-coronado-dr-sunnyvale-ca-94086/pid_vado2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "b0361d55-5336-42a2-8074-ecb4104adb60",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-06-08 19:32:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-08 12:29:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.38294,
				"lng": -122.055027,
				"zipcode": "94086",
				"subPremise": "",
				"address": "1241 Coronado Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2325000,
			"daysOnMovoto": 134,
			"id": "1897416b-a772-4c1c-a9ad-ac9b656fe23b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81847851_0_nA3nVQ_p.jpeg",
			"listDate": "2021-06-08 12:15:08",
			"listingAgent": "Rob Chiang",
			"listPrice": 2400000,
			"lotSize": 7466,
			"sqftTotal": 3763,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81847851",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Robert Chiang Real Estate ServicesIncorporated",
			"photoCount": 35,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94086",
			"path": "sunnyvale-ca/1237-coronado-dr-sunnyvale-ca-94086/pid_lado2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "6e31f46d-76b6-4cdd-bd10-ccc79d943707",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-06-08 19:17:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-08 12:15:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.382891,
				"lng": -122.054805,
				"zipcode": "94086",
				"subPremise": "",
				"address": "1237 Coronado Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 164,
			"id": "540cae0f-1844-4d39-969a-95dfb1615a00",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81842328_0_mYEIAu_p.jpeg",
			"listDate": "2021-05-10 00:00:00",
			"listingAgent": "Moti Mizrahi",
			"listPrice": 1599000,
			"lotSize": 6342,
			"sqftTotal": 1416,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81842328",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty - Cupertino",
			"photoCount": 8,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1957,
			"zipCode": "94086",
			"path": "sunnyvale-ca/824-lewis-ave-sunnyvale-ca-94086/pid_wugy2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "748ce32d-07df-467c-900b-9a80be08d746",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-05-10 18:37:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-10 00:00:00",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2919423",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.378512,
				"lng": -122.039052,
				"zipcode": "94086",
				"subPremise": "",
				"address": "824 Lewis Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2919423"
		},
		{
			"closePrice": 1950000,
			"daysOnMovoto": 59,
			"id": "9f38473c-ddb4-4328-9c9c-3ca0ef98063a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859430_0_67q3Qr_p.jpeg",
			"listDate": "2021-08-20 18:58:57",
			"listingAgent": "Julie Tsai Law",
			"listPrice": 1850000,
			"lotSize": 8891,
			"sqftTotal": 2764,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859430",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 4,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1961,
			"zipCode": "94086",
			"path": "sunnyvale-ca/993-helen-ave-sunnyvale-ca-94086/pid_8w3o2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "320e4cb7-bd90-4097-b83b-f01ce540f325",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-08-21 02:07:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 18:58:57",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.354759,
				"lng": -122.001314,
				"zipcode": "94086",
				"subPremise": "",
				"address": "993 Helen Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1780000,
			"daysOnMovoto": 40,
			"id": "21208579-7904-4640-b101-682b21c92ab8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861741_0_eReBBY_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "C.T. Harris",
			"listPrice": 1750000,
			"lotSize": 7314,
			"sqftTotal": 2245,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861741",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 27,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1970,
			"zipCode": "94086",
			"path": "sunnyvale-ca/909-911-suntree-ct-sunnyvale-ca-94086-100_ml81861741/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4a72fa05-a457-41dc-8fb8-8496312c119f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-18 00:00:00",
			"createdAt": "2021-09-08 23:37:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"zipcode": "94086",
				"subPremise": "",
				"address": "909 911 Suntree Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1330000,
			"daysOnMovoto": 56,
			"id": "a503dfef-3c3c-4350-928c-5bc53bb501a8",
			"tnImgPath": "https://pi.movoto.com/p/12/40961572_0_bIny6e_p.jpeg",
			"listDate": "2021-08-20 00:00:00",
			"listingAgent": "Amanda Lau",
			"listPrice": 1290000,
			"lotSize": 767,
			"sqftTotal": 1194,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961572",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2017,
			"zipCode": "94086",
			"path": "sunnyvale-ca/258-carroll-st-apt-112-sunnyvale-ca-94086-12_40961572/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -60000,
			"propertyId": "e4c2b5cd-16f4-4963-a91b-e75ea884ed05",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-08-20 21:36:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.374208,
				"lng": -122.028712,
				"zipcode": "94086",
				"subPremise": "APT 112",
				"address": "258 Carroll St #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1330000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2175000,
			"daysOnMovoto": 0,
			"id": "594abef1-a9d1-4987-a156-c157a39fabe4",
			"tnImgPath": "https://pi.movoto.com/p/102/221115673_0_33emVv_p.jpeg",
			"listDate": null,
			"listingAgent": "Matthew Wistrich",
			"listPrice": 2125000,
			"lotSize": 6403,
			"sqftTotal": 2346,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221115673",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1970,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1480-mallard-way-sunnyvale-ca-94087/pid_6lwq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "47c889a8-6477-4ec7-8340-067e4ab99346",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-13 00:00:00",
			"createdAt": "2021-09-11 00:49:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-11 00:49:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.345754,
				"lng": -122.015616,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1480 Mallard Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2360000,
			"daysOnMovoto": 62,
			"id": "01a6b43f-36ed-4252-b418-0b85da5552c8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857099_0_2MiRqF_p.jpeg",
			"listDate": "2021-08-06 00:09:21",
			"listingAgent": "Suke Zhao",
			"listPrice": 2399900,
			"lotSize": 6608,
			"sqftTotal": 3446,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857099",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Bayview Realty & Financial",
			"photoCount": 15,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94087",
			"path": "sunnyvale-ca/1583-quebec-ct-sunnyvale-ca-94087/pid_ap5q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "602eb7c9-6218-4d52-9a8c-0fd94292869e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-08-06 07:12:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-06 00:09:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.341878,
				"lng": -122.038796,
				"zipcode": "94087",
				"subPremise": "",
				"address": "1583 Quebec Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 219900,
			"daysOnMovoto": 75,
			"id": "2ed48d3e-5c4b-4839-a23c-86ef7168895a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81855112_0_iAQz2J_p.jpeg",
			"listDate": "2021-07-23 13:03:52",
			"listingAgent": "Joyce Lavrar",
			"listPrice": 219900,
			"lotSize": null,
			"sqftTotal": 1776,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81855112",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 27,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1977,
			"zipCode": "94089",
			"path": "sunnyvale-ca/1050-borregas-dr-sunnyvale-ca-94089-100_ml81855112/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d67c2958-5467-490e-a989-c7be307bdf36",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-07-23 20:07:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-23 13:03:52",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.400293,
				"lng": -122.020523,
				"zipcode": "94089",
				"subPremise": "",
				"address": "1050 Borregas Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 219900,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 715000,
			"daysOnMovoto": 11,
			"id": "fd69bd3f-ddf5-4345-a119-46d052820585",
			"tnImgPath": "https://pi.movoto.com/p/110/421598333_0_JIrf6V_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Darryl Honda",
			"listPrice": 698000,
			"lotSize": 465,
			"sqftTotal": 764,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421598333",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1995,
			"zipCode": "94087",
			"path": "sunnyvale-ca/979-pinto-palm-ter-19-sunnyvale-ca-94087-100_80142696/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee64af2a-beb5-4974-83b2-6bbb7cc4cef1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-09-24 23:16:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=JntZGGD3W2q&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.352549,
				"lng": -122.008916,
				"zipcode": "94087",
				"subPremise": "APT 19",
				"address": "979 Pinto Palm Ter #19"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 715000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=JntZGGD3W2q&mls=1"
		},
		{
			"closePrice": 119000,
			"daysOnMovoto": 47,
			"id": "31569f6d-a6e1-4717-bb4b-b77366b42a4a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858448_0_rzYVAA_p.jpeg",
			"listDate": "2021-08-14 14:12:33",
			"listingAgent": "Bonnie Moran",
			"listPrice": 119000,
			"lotSize": null,
			"sqftTotal": 980,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858448",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 17,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Single Wide",
			"yearBuilt": 1992,
			"zipCode": "94086",
			"path": "sunnyvale-ca/1201-sycamore-sunnyvale-ca-94086-100_ml81683966/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20999,
			"propertyId": "b8f84bb5-7fee-4518-b30d-31f9cf44cb48",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-08-14 21:17:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 14:12:33",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.354402,
				"lng": -122.002849,
				"zipcode": "94086",
				"subPremise": "",
				"address": "1201 Sycamore"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 119000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2829940,
			"daysOnMovoto": 232,
			"id": "9a404457-d9e3-47a0-b256-88f58823046d",
			"tnImgPath": "https://pi.movoto.com/p/12/40937134_0_YezBjV_p.jpeg",
			"listDate": "2021-02-09 00:00:00",
			"listingAgent": "Sandy Richert",
			"listPrice": 2655000,
			"lotSize": 3744,
			"sqftTotal": 2977,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937134",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Trumark Construction",
			"photoCount": 12,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "94086",
			"path": "sunnyvale-ca/725-vine-maple-ter-sunnyvale-ca-94086-12_40937134/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d8509827-d890-45a2-80cb-75ede00b2203",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-29 00:00:00",
			"createdAt": "2021-02-09 21:31:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.362445,
				"lng": -121.996895,
				"zipcode": "94086",
				"subPremise": "",
				"address": "725 Vinemaple Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2829940,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 39,
			"id": "9e24e1da-ea76-42ba-92e0-0c10a7711d39",
			"tnImgPath": "https://pi.movoto.com/p/12/40963510_0_IuIIyr_p.jpeg",
			"listDate": "2021-08-20 00:00:00",
			"listingAgent": "Herson Pinto",
			"listPrice": 1100000,
			"lotSize": 5000,
			"sqftTotal": 1112,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963510",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "94085",
			"path": "sunnyvale-ca/869-san-mateo-sunnyvale-ca-94085-12_40963510/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d3b8973-574e-4b5f-a0ca-46b52608cee9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-28 00:00:00",
			"createdAt": "2021-08-20 20:46:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Sunnyvale",
				"lat": 37.393786,
				"lng": -122.008199,
				"zipcode": "94085",
				"subPremise": "",
				"address": "869 San Mateo"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]