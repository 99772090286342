exports.SARATOGA_SCHOOLS = [
		{
			"id": 22892,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.271347,
			"lon": -122.031784,
			"name": "Action Day Primary Plus Inc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "13560 Saratoga Sunnyvale Road",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/22892-Action-Day-Primary-Plus-Inc/",
				"reviews": "/california/saratoga/22892-Action-Day-Primary-Plus-Inc/#Reviews",
				"collegeSuccess": "/california/saratoga/22892-Action-Day-Primary-Plus-Inc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5620,
			"districtId": 653,
			"districtName": "Saratoga Union Elementary",
			"districtCity": "Saratoga",
			"levelCode": "e",
			"lat": 37.27676,
			"lon": -122.025635,
			"name": "Argonaut Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "13200 Shadow Mountain Drive",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5620-Argonaut-Elementary-School/",
				"reviews": "/california/saratoga/5620-Argonaut-Elementary-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5620-Argonaut-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "1%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 344,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 1
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 75
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5428,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.289162,
			"lon": -122.02523,
			"name": "Blue Hills Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "12300 De Sanka Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5428-Blue-Hills-Elementary-School/",
				"reviews": "/california/saratoga/5428-Blue-Hills-Elementary-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5428-Blue-Hills-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 339,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 77
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 26343,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.280846,
			"lon": -122.000282,
			"name": "Challenger School - Saratoga",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "18811 Cox Ave",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/26343-Challenger-School---Saratoga/",
				"reviews": "/california/saratoga/26343-Challenger-School---Saratoga/#Reviews",
				"collegeSuccess": "/california/saratoga/26343-Challenger-School---Saratoga/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 13,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5440,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e,m",
			"lat": 37.291599,
			"lon": -122.010666,
			"name": "Christa McAuliffe Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "12211 Titus Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5440-Christa-McAuliffe-Elementary-School/",
				"reviews": "/california/saratoga/5440-Christa-McAuliffe-Elementary-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5440-Christa-McAuliffe-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 493,
			"parentRating": 5,
			"numReviews": 75,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "White",
					"percentage": 32
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29833,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.278435,
			"lon": -122.005142,
			"name": "Empire Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "18801 Bellgrove Cir",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/29833-Empire-Pre-School/",
				"reviews": "/california/saratoga/29833-Empire-Pre-School/#Reviews",
				"collegeSuccess": "/california/saratoga/29833-Empire-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5621,
			"districtId": 653,
			"districtName": "Saratoga Union Elementary",
			"districtCity": "Saratoga",
			"levelCode": "e",
			"lat": 37.265331,
			"lon": -122.033714,
			"name": "Foothill Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "13919 Lynde Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5621-Foothill-Elementary-School/",
				"reviews": "/california/saratoga/5621-Foothill-Elementary-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5621-Foothill-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 330,
			"parentRating": 3,
			"numReviews": 16,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 54
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 24986,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.293613,
			"lon": -122.031891,
			"name": "Growing Tree Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "12000 Saratoga Sunnyvale Road",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/24986-Growing-Tree-Learning-Center/",
				"reviews": "/california/saratoga/24986-Growing-Tree-Learning-Center/#Reviews",
				"collegeSuccess": "/california/saratoga/24986-Growing-Tree-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5417,
			"districtId": 629,
			"districtName": "Campbell Union",
			"districtCity": "Campbell",
			"levelCode": "e",
			"lat": 37.262619,
			"lon": -121.996429,
			"name": "Marshall Lane Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "14114 Marilyn Lane",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5417-Marshall-Lane-Elementary-School/",
				"reviews": "/california/saratoga/5417-Marshall-Lane-Elementary-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5417-Marshall-Lane-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 541,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22897,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.240681,
			"lon": -122.004295,
			"name": "Next Generation Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "19010 Austin Way",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/22897-Next-Generation-Day-Care/",
				"reviews": "/california/saratoga/22897-Next-Generation-Day-Care/#Reviews",
				"collegeSuccess": "/california/saratoga/22897-Next-Generation-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9853,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.283176,
			"lon": -121.99839,
			"name": "Primary Plus Elementary Quito School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "18720 Bucknall Road",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/9853-Primary-Plus-Elementary-Quito-School/",
				"reviews": "/california/saratoga/9853-Primary-Plus-Elementary-Quito-School/#Reviews",
				"collegeSuccess": "/california/saratoga/9853-Primary-Plus-Elementary-Quito-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 99,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 40
				},
				{
					"label": "White",
					"percentage": 40
				},
				{
					"label": "African American",
					"percentage": 21
				}
			],
			"remediationData": {}
		},
		{
			"id": 5426,
			"districtId": 630,
			"districtName": "Campbell Union High",
			"districtCity": "San Jose",
			"levelCode": "h",
			"lat": 37.291634,
			"lon": -122.001068,
			"name": "Prospect High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "18900 Prospect Road",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5426-Prospect-High-School/",
				"reviews": "/california/saratoga/5426-Prospect-High-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5426-Prospect-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "28%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "80%",
				"state_average": "64%"
			},
			"enrollment": 1555,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 28
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 36
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 27
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 28378,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.293777,
			"lon": -122.030968,
			"name": "Prospective Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "19888 Prospect Road",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/28378-Prospective-Learning-Center/",
				"reviews": "/california/saratoga/28378-Prospective-Learning-Center/#Reviews",
				"collegeSuccess": "/california/saratoga/28378-Prospective-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5622,
			"districtId": 653,
			"districtName": "Saratoga Union Elementary",
			"districtCity": "Saratoga",
			"levelCode": "m",
			"lat": 37.265438,
			"lon": -122.015427,
			"name": "Redwood Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "13925 Fruitvale Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5622-Redwood-Middle-School/",
				"reviews": "/california/saratoga/5622-Redwood-Middle-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5622-Redwood-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 761,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 58
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 6
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 9104,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.267971,
			"lon": -122.018036,
			"name": "Sacred Heart School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "13718 Saratoga Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/9104-Sacred-Heart-School/",
				"reviews": "/california/saratoga/9104-Sacred-Heart-School/#Reviews",
				"collegeSuccess": "/california/saratoga/9104-Sacred-Heart-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 268,
			"parentRating": 4,
			"numReviews": 16,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 58
				},
				{
					"label": "Two or more races",
					"percentage": 22
				},
				{
					"label": "Asian",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8521,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.271523,
			"lon": -122.01564,
			"name": "Saint Andrew's Episcopal School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "13601 Saratoga Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/8521-Saint-Andrews-Episcopal-School/",
				"reviews": "/california/saratoga/8521-Saint-Andrews-Episcopal-School/#Reviews",
				"collegeSuccess": "/california/saratoga/8521-Saint-Andrews-Episcopal-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 381,
			"parentRating": 4,
			"numReviews": 28,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 53
				},
				{
					"label": "Asian",
					"percentage": 43
				},
				{
					"label": "Hispanic",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5623,
			"districtId": 653,
			"districtName": "Saratoga Union Elementary",
			"districtCity": "Saratoga",
			"levelCode": "e",
			"lat": 37.255981,
			"lon": -122.033478,
			"name": "Saratoga Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "14592 Oak Street",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5623-Saratoga-Elementary-School/",
				"reviews": "/california/saratoga/5623-Saratoga-Elementary-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5623-Saratoga-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 330,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 31720,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.281979,
			"lon": -122.001289,
			"name": "saratoga french cultural preschool",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "12850 saratoga avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/31720-Saratoga-French-Cultural-Preschool/",
				"reviews": "/california/saratoga/31720-Saratoga-French-Cultural-Preschool/#Reviews",
				"collegeSuccess": "/california/saratoga/31720-Saratoga-French-Cultural-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5524,
			"districtId": 639,
			"districtName": "Los Gatos-Saratoga Joint Union High",
			"districtCity": "Los Gatos",
			"levelCode": "h",
			"lat": 37.266972,
			"lon": -122.031403,
			"name": "Saratoga High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "20300 Herriman Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/5524-Saratoga-High-School/",
				"reviews": "/california/saratoga/5524-Saratoga-High-School/#Reviews",
				"collegeSuccess": "/california/saratoga/5524-Saratoga-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "88%",
				"state_average": "64%"
			},
			"enrollment": 1371,
			"parentRating": 3,
			"numReviews": 11,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 61
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 4
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 22899,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.263847,
			"lon": -122.031586,
			"name": "Saratoga Parent Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "20490 Williams Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/22899-Saratoga-Parent-Nursery-School/",
				"reviews": "/california/saratoga/22899-Saratoga-Parent-Nursery-School/#Reviews",
				"collegeSuccess": "/california/saratoga/22899-Saratoga-Parent-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27507,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.268044,
			"lon": -122.031036,
			"name": "Saratoga Presbyterian Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "20455 Herriman Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/27507-Saratoga-Presbyterian-Pre-School/",
				"reviews": "/california/saratoga/27507-Saratoga-Presbyterian-Pre-School/#Reviews",
				"collegeSuccess": "/california/saratoga/27507-Saratoga-Presbyterian-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 32882,
			"districtId": 653,
			"districtName": "Saratoga Union Elementary",
			"districtCity": "Saratoga",
			"levelCode": "e,m,h",
			"lat": 37.255474,
			"lon": -122.031693,
			"name": "Saratoga Special Services Preschool",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "20460 Forrest Hills Drive",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/32882-Saratoga-Special-Services-Preschool/",
				"reviews": "/california/saratoga/32882-Saratoga-Special-Services-Preschool/#Reviews",
				"collegeSuccess": "/california/saratoga/32882-Saratoga-Special-Services-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30807,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.255852,
			"lon": -122.090561,
			"name": "Valley International Academy",
			"gradeLevels": "10-12",
			"assigned": null,
			"address": {
				"street1": "24500 Big Basin Way",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/30807-Valley-International-Academy/",
				"reviews": "/california/saratoga/30807-Valley-International-Academy/#Reviews",
				"collegeSuccess": "/california/saratoga/30807-Valley-International-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 24,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 96
				},
				{
					"label": "White",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 22898,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.258358,
			"lon": -122.030258,
			"name": "Village Preschool of Saratoga",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "20390 Park Pl",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/22898-Village-Preschool-Of-Saratoga/",
				"reviews": "/california/saratoga/22898-Village-Preschool-Of-Saratoga/#Reviews",
				"collegeSuccess": "/california/saratoga/22898-Village-Preschool-Of-Saratoga/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22893,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.266582,
			"lon": -122.013756,
			"name": "West Valley Community College Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "14000 Fruitvale Avenue",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/22893-West-Valley-Community-College-Dc/",
				"reviews": "/california/saratoga/22893-West-Valley-Community-College-Dc/#Reviews",
				"collegeSuccess": "/california/saratoga/22893-West-Valley-Community-College-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28160,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.27647,
			"lon": -122.02607,
			"name": "World Of Discovery Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "13200 Shadow Mountain Drive",
				"street2": null,
				"zip": "95070",
				"city": "Saratoga"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95070",
			"type": "school",
			"links": {
				"profile": "/california/saratoga/28160-World-Of-Discovery-Pre-School/",
				"reviews": "/california/saratoga/28160-World-Of-Discovery-Pre-School/#Reviews",
				"collegeSuccess": "/california/saratoga/28160-World-Of-Discovery-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]