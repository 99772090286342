exports.ALAMEDA_SCHOOLS = [
		{
			"id": 27749,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.773731,
			"lon": -122.264572,
			"name": "Abc Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1525 Bay Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/27749-Abc-Pre-School/",
				"reviews": "/california/alameda/27749-Abc-Pre-School/#Reviews",
				"collegeSuccess": "/california/alameda/27749-Abc-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8485,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.768845,
			"lon": -122.243881,
			"name": "Alameda Christian School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2226 Pacific Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/8485-Alameda-Christian-School/",
				"reviews": "/california/alameda/8485-Alameda-Christian-School/#Reviews",
				"collegeSuccess": "/california/alameda/8485-Alameda-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 48,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 33
				},
				{
					"label": "African American",
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"percentage": 21
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 11902,
			"districtId": 1823,
			"districtName": "Alameda Community Learning Center District",
			"districtCity": "Alameda",
			"levelCode": "m,h",
			"lat": 37.778751,
			"lon": -122.28688,
			"name": "Alameda Community Learning Center",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "1900 3rd Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/11902-Alameda-Community-Learning-Center/",
				"reviews": "/california/alameda/11902-Alameda-Community-Learning-Center/#Reviews",
				"collegeSuccess": "/california/alameda/11902-Alameda-Community-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "73%",
				"state_average": "64%"
			},
			"enrollment": 351,
			"parentRating": 4,
			"numReviews": 26,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"College Readiness Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 17770,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.770393,
			"lon": -122.238831,
			"name": "Alameda Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2325 Clement Ave",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17770-Alameda-Head-Start/",
				"reviews": "/california/alameda/17770-Alameda-Head-Start/#Reviews",
				"collegeSuccess": "/california/alameda/17770-Alameda-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "h",
			"lat": 37.76421,
			"lon": -122.24704,
			"name": "Alameda High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2201 Encinal Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/1-Alameda-High-School/",
				"reviews": "/california/alameda/1-Alameda-High-School/#Reviews",
				"collegeSuccess": "/california/alameda/1-Alameda-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "82%",
				"state_average": "64%"
			},
			"enrollment": 1767,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 21
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 5
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 6
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29138,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.770874,
			"lon": -122.255272,
			"name": "Alameda Island Kids",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1724 Santa Clara Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29138-Alameda-Island-Kids/",
				"reviews": "/california/alameda/29138-Alameda-Island-Kids/#Reviews",
				"collegeSuccess": "/california/alameda/29138-Alameda-Island-Kids/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27823,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.770874,
			"lon": -122.255272,
			"name": "Alameda Island Kids At Edison",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1724 Santa Clara Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/27823-Alameda-Island-Kids-At-Edison/",
				"reviews": "/california/alameda/27823-Alameda-Island-Kids-At-Edison/#Reviews",
				"collegeSuccess": "/california/alameda/27823-Alameda-Island-Kids-At-Edison/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29005,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.76638,
			"lon": -122.245293,
			"name": "Alameda Park & Rec Tiny Tots",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2226 Santa Clara Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29005-Alameda-Park--Rec-Tiny-Tots/",
				"reviews": "/california/alameda/29005-Alameda-Park--Rec-Tiny-Tots/#Reviews",
				"collegeSuccess": "/california/alameda/29005-Alameda-Park--Rec-Tiny-Tots/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14052,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "h",
			"lat": 37.780872,
			"lon": -122.279305,
			"name": "Alameda Science and Technology Institute",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "555 Ralph Appezzato Memorial Parkway",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/14052-Alameda-Science-And-Technology-Institute/",
				"reviews": "/california/alameda/14052-Alameda-Science-And-Technology-Institute/#Reviews",
				"collegeSuccess": "/california/alameda/14052-Alameda-Science-And-Technology-Institute/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "29%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "84%",
				"state_average": "64%"
			},
			"enrollment": 192,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 31,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 29
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32845,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e,m,h",
			"lat": 37.782211,
			"lon": -122.270088,
			"name": "Alameda Unified Special Education",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "2060 Challenger Drive",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/32845-Alameda-Unified-Special-Education/",
				"reviews": "/california/alameda/32845-Alameda-Unified-Special-Education/#Reviews",
				"collegeSuccess": "/california/alameda/32845-Alameda-Unified-Special-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27835,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.75452,
			"lon": -122.238617,
			"name": "Alamede Island Kids",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3010 Fillmore Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/27835-Alamede-Island-Kids/",
				"reviews": "/california/alameda/27835-Alamede-Island-Kids/#Reviews",
				"collegeSuccess": "/california/alameda/27835-Alamede-Island-Kids/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 4,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.744888,
			"lon": -122.237427,
			"name": "Amelia Earhart Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "400 Packet Landing Road",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/4-Amelia-Earhart-Elementary-School/",
				"reviews": "/california/alameda/4-Amelia-Earhart-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/4-Amelia-Earhart-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "12%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 651,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 16
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 29733,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.771767,
			"lon": -122.272491,
			"name": "American Inter Mont School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1410 8th Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29733-American-Inter-Mont-School/",
				"reviews": "/california/alameda/29733-American-Inter-Mont-School/#Reviews",
				"collegeSuccess": "/california/alameda/29733-American-Inter-Mont-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28831,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.779613,
			"lon": -122.28791,
			"name": "Angela Aguilar Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1901 3rd Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/28831-Angela-Aguilar-Head-Start/",
				"reviews": "/california/alameda/28831-Angela-Aguilar-Head-Start/#Reviews",
				"collegeSuccess": "/california/alameda/28831-Angela-Aguilar-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17140,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.76968,
			"lon": -122.249313,
			"name": "Applied Scholastics Academy, East Bay",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "2017 Central Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17140-Applied-Scholastics-Academy-East-Bay/",
				"reviews": "/california/alameda/17140-Applied-Scholastics-Academy-East-Bay/#Reviews",
				"collegeSuccess": "/california/alameda/17140-Applied-Scholastics-Academy-East-Bay/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Two or more races",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 2,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e,m",
			"lat": 37.744686,
			"lon": -122.251518,
			"name": "Bay Farm",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "200 Aughinbaugh Way",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/2-Bay-Farm/",
				"reviews": "/california/alameda/2-Bay-Farm/#Reviews",
				"collegeSuccess": "/california/alameda/2-Bay-Farm/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 610,
			"parentRating": 5,
			"numReviews": 23,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 19
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 13358,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.769276,
			"lon": -122.248848,
			"name": "Bayside Montessori Association",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1523 Willow Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/13358-Bayside-Montessori-Association/",
				"reviews": "/california/alameda/13358-Bayside-Montessori-Association/#Reviews",
				"collegeSuccess": "/california/alameda/13358-Bayside-Montessori-Association/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 24,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 43
				},
				{
					"label": "White",
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"percentage": 29
				}
			],
			"remediationData": {}
		},
		{
			"id": 17761,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.769379,
			"lon": -122.249016,
			"name": "Bayside Montessori Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1523 Willow St",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17761-Bayside-Montessori-Day-Care-Center/",
				"reviews": "/california/alameda/17761-Bayside-Montessori-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17761-Bayside-Montessori-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15792,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.731422,
			"lon": -122.245674,
			"name": "Bright Horizons At Garner Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2275 North Loop Road",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/15792-Bright-Horizons-At-Garner-Learning-Center/",
				"reviews": "/california/alameda/15792-Bright-Horizons-At-Garner-Learning-Center/#Reviews",
				"collegeSuccess": "/california/alameda/15792-Bright-Horizons-At-Garner-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 62,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 81
				},
				{
					"label": "African American",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 15815,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.767033,
			"lon": -122.24836,
			"name": "Central Christian School",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "2133 Central Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/15815-Central-Christian-School/",
				"reviews": "/california/alameda/15815-Central-Christian-School/#Reviews",
				"collegeSuccess": "/california/alameda/15815-Central-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17769,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.759537,
			"lon": -122.244461,
			"name": "Child Unique Mont School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1178 Park Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17769-Child-Unique-Mont-School/",
				"reviews": "/california/alameda/17769-Child-Unique-Mont-School/#Reviews",
				"collegeSuccess": "/california/alameda/17769-Child-Unique-Mont-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15173,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.766087,
			"lon": -122.248116,
			"name": "Children's Learning Center",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2152 Central Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/15173-Childrens-Learning-Center/",
				"reviews": "/california/alameda/15173-Childrens-Learning-Center/#Reviews",
				"collegeSuccess": "/california/alameda/15173-Childrens-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10045,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.768581,
			"lon": -122.253288,
			"name": "Children's Learning Center",
			"gradeLevels": "1-12",
			"assigned": null,
			"address": {
				"street1": "1910 Central Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/10045-Childrens-Learning-Center/",
				"reviews": "/california/alameda/10045-Childrens-Learning-Center/#Reviews",
				"collegeSuccess": "/california/alameda/10045-Childrens-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 81,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 62
				},
				{
					"label": "Two or more races",
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 25837,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.725845,
			"lon": -122.239342,
			"name": "Chinese American Schools - Alameda",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1501 Harbor Bay Parkway",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/25837-Chinese-American-Schools---Alameda/",
				"reviews": "/california/alameda/25837-Chinese-American-Schools---Alameda/#Reviews",
				"collegeSuccess": "/california/alameda/25837-Chinese-American-Schools---Alameda/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24205,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.727272,
			"lon": -122.239456,
			"name": "Chinese Christian Schools - Alameda",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1801 North Loop Road",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/24205-Chinese-Christian-Schools---Alameda/",
				"reviews": "/california/alameda/24205-Chinese-Christian-Schools---Alameda/#Reviews",
				"collegeSuccess": "/california/alameda/24205-Chinese-Christian-Schools---Alameda/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 349,
			"parentRating": 3,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 78
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29013,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.769466,
			"lon": -122.239357,
			"name": "Coast Guard Cdc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2329 Eagle Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29013-Coast-Guard-Cdc/",
				"reviews": "/california/alameda/29013-Coast-Guard-Cdc/#Reviews",
				"collegeSuccess": "/california/alameda/29013-Coast-Guard-Cdc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17774,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.780972,
			"lon": -122.279289,
			"name": "College Of Alameda Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "555 Ralph Appezzato Memorial Parkway",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17774-College-Of-Alameda-Childrens-Center/",
				"reviews": "/california/alameda/17774-College-Of-Alameda-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17774-College-Of-Alameda-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31224,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.772339,
			"lon": -122.282875,
			"name": "Da Vinci Center For Gifted Children",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "472 Taylor Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/31224-Da-Vinci-Center-For-Gifted-Children/",
				"reviews": "/california/alameda/31224-Da-Vinci-Center-For-Gifted-Children/#Reviews",
				"collegeSuccess": "/california/alameda/31224-Da-Vinci-Center-For-Gifted-Children/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 6,
			"parentRating": 2,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.760616,
			"lon": -122.26149,
			"name": "Donald D. Lum Elementary",
			"gradeLevels": "5",
			"assigned": null,
			"address": {
				"street1": "420 Grand Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/12-Donald-D.-Lum-Elementary-School/",
				"reviews": "/california/alameda/12-Donald-D.-Lum-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/12-Donald-D.-Lum-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "27%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 30,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 9,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 27
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"percentage": 17
				},
				{
					"label": "White",
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 5,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.765064,
			"lon": -122.234657,
			"name": "Edison Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2700 Buena Vista Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/5-Edison-Elementary-School/",
				"reviews": "/california/alameda/5-Edison-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/5-Edison-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "13%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 469,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 46
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "m,h",
			"lat": 37.771622,
			"lon": -122.289848,
			"name": "Encinal Junior/Senior High",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "210 Central Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/6-Encinal-Junior-Senior-High/",
				"reviews": "/california/alameda/6-Encinal-Junior-Senior-High/#Reviews",
				"collegeSuccess": "/california/alameda/6-Encinal-Junior-Senior-High/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "39%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "74%",
				"state_average": "64%"
			},
			"enrollment": 1261,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 39
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 8
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17764,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.769726,
			"lon": -122.251846,
			"name": "First Presbyterian Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2001 Santa Clara Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17764-First-Presbyterian-Child-Care-Center/",
				"reviews": "/california/alameda/17764-First-Presbyterian-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17764-First-Presbyterian-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.754227,
			"lon": -122.238541,
			"name": "Frank Otis Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3010 Fillmore Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/14-Frank-Otis-Elementary-School/",
				"reviews": "/california/alameda/14-Frank-Otis-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/14-Frank-Otis-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 640,
			"parentRating": 5,
			"numReviews": 11,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 39
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 7,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.769115,
			"lon": -122.260933,
			"name": "Franklin Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1433 San Antonio Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/7-Franklin-Elementary-School/",
				"reviews": "/california/alameda/7-Franklin-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/7-Franklin-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 335,
			"parentRating": 5,
			"numReviews": 32,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29108,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.744122,
			"lon": -122.251923,
			"name": "Girls Inc Bay Farm Activity",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "200 Aughinbaugh Way",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29108-Girls-Inc-Bay-Farm-Activity/",
				"reviews": "/california/alameda/29108-Girls-Inc-Bay-Farm-Activity/#Reviews",
				"collegeSuccess": "/california/alameda/29108-Girls-Inc-Bay-Farm-Activity/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29167,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.76886,
			"lon": -122.261124,
			"name": "Girls Inc Franklin Activity",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1433 San Antonio Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29167-Girls-Inc-Franklin-Activity/",
				"reviews": "/california/alameda/29167-Girls-Inc-Franklin-Activity/#Reviews",
				"collegeSuccess": "/california/alameda/29167-Girls-Inc-Franklin-Activity/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17762,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.774586,
			"lon": -122.262344,
			"name": "Golden House Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1545 Morton St",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17762-Golden-House-Child-Care-Center/",
				"reviews": "/california/alameda/17762-Golden-House-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17762-Golden-House-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28292,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.764668,
			"lon": -122.248215,
			"name": "Happy Little World Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1209 Walnut Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/28292-Happy-Little-World-Child-Care-Center/",
				"reviews": "/california/alameda/28292-Happy-Little-World-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/alameda/28292-Happy-Little-World-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17772,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.779419,
			"lon": -122.287651,
			"name": "Home Sweet Home Child Care Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1900 3rd Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17772-Home-Sweet-Home-Child-Care-Center/",
				"reviews": "/california/alameda/17772-Home-Sweet-Home-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17772-Home-Sweet-Home-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17771,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.768421,
			"lon": -122.237831,
			"name": "Island Head Start Parent/Child Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2437 Eagle Ave",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17771-Island-Head-Start-Parent-Child-Center/",
				"reviews": "/california/alameda/17771-Island-Head-Start-Parent-Child-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17771-Island-Head-Start-Parent-Child-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "h",
			"lat": 37.776005,
			"lon": -122.281738,
			"name": "Island High (Continuation)",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "500 Pacific Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/9-Island-High-Continuation-School/",
				"reviews": "/california/alameda/9-Island-High-Continuation-School/#Reviews",
				"collegeSuccess": "/california/alameda/9-Island-High-Continuation-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "38%",
				"state_average": "64%"
			},
			"enrollment": 96,
			"parentRating": 4,
			"numReviews": 4,
			"studentsPerTeacher": 11,
			"subratings": {
				"Test Scores Rating": 2,
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"percentage": 27
				},
				{
					"label": "Hispanic",
					"percentage": 25
				},
				{
					"label": "White",
					"percentage": 22
				},
				{
					"label": "Filipino",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27516,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.77713,
			"lon": -122.290001,
			"name": "Kiddie Kampus Nursery",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1711 2nd Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/27516-Kiddie-Kampus-Nursery/",
				"reviews": "/california/alameda/27516-Kiddie-Kampus-Nursery/#Reviews",
				"collegeSuccess": "/california/alameda/27516-Kiddie-Kampus-Nursery/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17768,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.730656,
			"lon": -122.243614,
			"name": "Kindercare Learning Center #1335",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2155 North Loop Road",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17768-Kindercare-Learning-Center-1335/",
				"reviews": "/california/alameda/17768-Kindercare-Learning-Center-1335/#Reviews",
				"collegeSuccess": "/california/alameda/17768-Kindercare-Learning-Center-1335/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 79,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Hispanic",
					"percentage": 20
				},
				{
					"label": "African American",
					"percentage": 20
				}
			],
			"remediationData": {}
		},
		{
			"id": 10,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "m",
			"lat": 37.752831,
			"lon": -122.231895,
			"name": "Lincoln Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1250 Fernside Boulevard",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/10-Lincoln-Middle-School/",
				"reviews": "/california/alameda/10-Lincoln-Middle-School/#Reviews",
				"collegeSuccess": "/california/alameda/10-Lincoln-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "15%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 872,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 15
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 28
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 36
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 17765,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.784492,
			"lon": -122.275764,
			"name": "Little Seed Childrens Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2100 Mariner Square Drive",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17765-Little-Seed-Childrens-Center/",
				"reviews": "/california/alameda/17765-Little-Seed-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/alameda/17765-Little-Seed-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.770409,
			"lon": -122.25061,
			"name": "Love Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2025 Santa Clara Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/8-Henry-Haight-Elementary-School/",
				"reviews": "/california/alameda/8-Henry-Haight-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/8-Henry-Haight-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "46%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 544,
			"parentRating": 5,
			"numReviews": 14,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 46
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17760,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.761742,
			"lon": -122.243973,
			"name": "Luna's Mont Bilingual School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1247 Park Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17760-Lunas-Mont-Bilingual-School/",
				"reviews": "/california/alameda/17760-Lunas-Mont-Bilingual-School/#Reviews",
				"collegeSuccess": "/california/alameda/17760-Lunas-Mont-Bilingual-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 32875,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e,m,h",
			"lat": 37.772663,
			"lon": -122.271461,
			"name": "Maya Lin",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "825 Taylor Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/32875-Maya-Lin/",
				"reviews": "/california/alameda/32875-Maya-Lin/#Reviews",
				"collegeSuccess": "/california/alameda/32875-Maya-Lin/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26955,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.772724,
			"lon": -122.271889,
			"name": "Maya Lin School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "825 Taylor Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/26955-Maya-Lin-School/",
				"reviews": "/california/alameda/26955-Maya-Lin-School/#Reviews",
				"collegeSuccess": "/california/alameda/26955-Maya-Lin-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "23%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 427,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 23
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 16
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 25151,
			"districtId": 2476,
			"districtName": "Nea Community Learning Center District",
			"districtCity": "Alameda",
			"levelCode": "e,m,h",
			"lat": 37.778751,
			"lon": -122.28688,
			"name": "Nea Community Learning Center",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1900 3rd Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/25151-Nea-Community-Learning-Center-School/",
				"reviews": "/california/alameda/25151-Nea-Community-Learning-Center-School/#Reviews",
				"collegeSuccess": "/california/alameda/25151-Nea-Community-Learning-Center-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "62%",
				"state_average": "64%"
			},
			"enrollment": 579,
			"parentRating": 4,
			"numReviews": 69,
			"studentsPerTeacher": 8,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"College Readiness Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 35
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 17775,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.758911,
			"lon": -122.231613,
			"name": "Our Childrens Cottage",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1421 High Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17775-Our-Childrens-Cottage/",
				"reviews": "/california/alameda/17775-Our-Childrens-Cottage/#Reviews",
				"collegeSuccess": "/california/alameda/17775-Our-Childrens-Cottage/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 2,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17773,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.784492,
			"lon": -122.275764,
			"name": "Peter Pan Academy",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "2100 Mariner Square Drive",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17773-Peter-Pan-Academy/",
				"reviews": "/california/alameda/17773-Peter-Pan-Academy/#Reviews",
				"collegeSuccess": "/california/alameda/17773-Peter-Pan-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8852,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.738087,
			"lon": -122.242371,
			"name": "Peter Pan Academy",
			"gradeLevels": "K-2",
			"assigned": null,
			"address": {
				"street1": "3171 Mecartney Road",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/8852-Peter-Pan-Academy/",
				"reviews": "/california/alameda/8852-Peter-Pan-Academy/#Reviews",
				"collegeSuccess": "/california/alameda/8852-Peter-Pan-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 17,
			"parentRating": 3,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 24
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 18
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 12
				}
			],
			"remediationData": {}
		},
		{
			"id": 17766,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.784492,
			"lon": -122.275764,
			"name": "Peter Pan Pre-Schools",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2100 Mariner Square Drive",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17766-Peter-Pan-Pre-Schools/",
				"reviews": "/california/alameda/17766-Peter-Pan-Pre-Schools/#Reviews",
				"collegeSuccess": "/california/alameda/17766-Peter-Pan-Pre-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9315,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.758911,
			"lon": -122.231613,
			"name": "Rising Star Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1421 High Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/9315-Rising-Star-Montessori-School/",
				"reviews": "/california/alameda/9315-Rising-Star-Montessori-School/#Reviews",
				"collegeSuccess": "/california/alameda/9315-Rising-Star-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 161,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Two or more races",
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 28
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 29241,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.770397,
			"lon": -122.238808,
			"name": "Rosalie Sterling Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2325 Clement Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/29241-Rosalie-Sterling-Head-Start/",
				"reviews": "/california/alameda/29241-Rosalie-Sterling-Head-Start/#Reviews",
				"collegeSuccess": "/california/alameda/29241-Rosalie-Sterling-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16978,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.782246,
			"lon": -122.286301,
			"name": "Ruby Bridges Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "351 Jack London Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/16978-Ruby-Bridges-Elementary-School/",
				"reviews": "/california/alameda/16978-Ruby-Bridges-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/16978-Ruby-Bridges-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 484,
			"parentRating": 5,
			"numReviews": 17,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 63
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 23
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8234,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.765614,
			"lon": -122.255119,
			"name": "Saint Joseph Notre Dame High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1011 Chestnut Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/8234-Saint-Joseph-Notre-Dame-High-School/",
				"reviews": "/california/alameda/8234-Saint-Joseph-Notre-Dame-High-School/#Reviews",
				"collegeSuccess": "/california/alameda/8234-Saint-Joseph-Notre-Dame-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 406,
			"parentRating": 4,
			"numReviews": 15,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 31
				},
				{
					"label": "Hispanic",
					"percentage": 22
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 13249,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.742344,
			"lon": -122.257477,
			"name": "Seedling Child Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "310 Tipperary Lane",
				"street2": null,
				"zip": "94502",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94502",
			"type": "school",
			"links": {
				"profile": "/california/alameda/13249-Seedling-Child-Montessori-School/",
				"reviews": "/california/alameda/13249-Seedling-Child-Montessori-School/#Reviews",
				"collegeSuccess": "/california/alameda/13249-Seedling-Child-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28188,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.750412,
			"lon": -122.237572,
			"name": "Small Size Big Mind Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3300 Bridgeview Isle",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/28188-Small-Size-Big-Mind-Pre-School/",
				"reviews": "/california/alameda/28188-Small-Size-Big-Mind-Pre-School/#Reviews",
				"collegeSuccess": "/california/alameda/28188-Small-Size-Big-Mind-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17763,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.769669,
			"lon": -122.252403,
			"name": "Son-Light Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1910 Santa Clara Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17763-Son-Light-Pre-School/",
				"reviews": "/california/alameda/17763-Son-Light-Pre-School/#Reviews",
				"collegeSuccess": "/california/alameda/17763-Son-Light-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8235,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.766079,
			"lon": -122.25502,
			"name": "St. Joseph Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1910 San Antonio Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/8235-St.-Joseph-Elementary-School/",
				"reviews": "/california/alameda/8235-St.-Joseph-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/8235-St.-Joseph-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 264,
			"parentRating": 3,
			"numReviews": 20,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 45
				},
				{
					"label": "Asian",
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"percentage": 23
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8253,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.757187,
			"lon": -122.233139,
			"name": "St. Philip Neri Elementary School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1335 High Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/8253-St.-Philip-Neri-Elementary-School/",
				"reviews": "/california/alameda/8253-St.-Philip-Neri-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/8253-St.-Philip-Neri-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 228,
			"parentRating": 4,
			"numReviews": 27,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 52
				},
				{
					"label": "Hispanic",
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Asian",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 28980,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.786469,
			"lon": -122.275948,
			"name": "Sugar & Spice Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2238 Mariner Square Drive",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/28980-Sugar--Spice-Child-Care-Center/",
				"reviews": "/california/alameda/28980-Sugar--Spice-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/alameda/28980-Sugar--Spice-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25256,
			"districtId": 2741,
			"districtName": "The Academy of Alameda District",
			"districtCity": "Alameda",
			"levelCode": "m",
			"lat": 37.777298,
			"lon": -122.284996,
			"name": "The Academy of Alameda",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "401 Pacific Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/25256-The-Academy-Of-Alameda/",
				"reviews": "/california/alameda/25256-The-Academy-Of-Alameda/#Reviews",
				"collegeSuccess": "/california/alameda/25256-The-Academy-Of-Alameda/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 494,
			"parentRating": 5,
			"numReviews": 21,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 17
				},
				{
					"label": "Filipino",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 32002,
			"districtId": 2742,
			"districtName": "The Academy of Alameda Elementary District",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.777298,
			"lon": -122.284996,
			"name": "The Academy of Alameda Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "401 Pacific Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/32002-The-Academy-Of-Alameda-Elementary/",
				"reviews": "/california/alameda/32002-The-Academy-Of-Alameda-Elementary/#Reviews",
				"collegeSuccess": "/california/alameda/32002-The-Academy-Of-Alameda-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "45%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 251,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {
				"Test Scores Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 45
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 4,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 25857,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.763783,
			"lon": -122.247086,
			"name": "The Child Unique Montessori School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2226 Encinal Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/25857-The-Child-Unique-Montessori-School/",
				"reviews": "/california/alameda/25857-The-Child-Unique-Montessori-School/#Reviews",
				"collegeSuccess": "/california/alameda/25857-The-Child-Unique-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 17,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9623,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.763783,
			"lon": -122.247086,
			"name": "The Child Unique Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2226 Encinal Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/9623-The-Child-Unique-Montessori-School/",
				"reviews": "/california/alameda/9623-The-Child-Unique-Montessori-School/#Reviews",
				"collegeSuccess": "/california/alameda/9623-The-Child-Unique-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 78,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Asian",
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 18
				}
			],
			"remediationData": {}
		},
		{
			"id": 17,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "m",
			"lat": 37.760616,
			"lon": -122.26149,
			"name": "Will C. Wood Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "420 Grand Street",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/17-Will-C.-Wood-Middle-School/",
				"reviews": "/california/alameda/17-Will-C.-Wood-Middle-School/#Reviews",
				"collegeSuccess": "/california/alameda/17-Will-C.-Wood-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "43%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 575,
			"parentRating": 5,
			"numReviews": 14,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 43
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 27
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 9
				},
				{
					"label": "Filipino",
					"rating": 7,
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 15,
			"districtId": 1,
			"districtName": "Alameda Unified",
			"districtCity": "Alameda",
			"levelCode": "e",
			"lat": 37.771278,
			"lon": -122.283905,
			"name": "William G. Paden Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "444 Central Avenue",
				"street2": null,
				"zip": "94501",
				"city": "Alameda"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94501",
			"type": "school",
			"links": {
				"profile": "/california/alameda/15-William-G.-Paden-Elementary-School/",
				"reviews": "/california/alameda/15-William-G.-Paden-Elementary-School/#Reviews",
				"collegeSuccess": "/california/alameda/15-William-G.-Paden-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "46%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 397,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 46
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 19
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]