var danville 	= require('./danville');
var dublin 	= require('./dublin');
var milpitas 	= require('./milpitas');
var pleasanton 	= require('./pleasanton');
var san_francisco 	= require('./san_francisco');
var san_jose 	= require('./san_jose');
var san_ramon 	= require('./san_ramon');
var fremont 	= require('./fremont');
var union_city 	= require('./union_city');
var mountain_view 	= require('./mountain_view');
var cupertino 	= require('./cupertino');
var campbell 	= require('./campbell');
var castro_valley 	= require('./castro_valley');
var daly_city 	= require('./daly_city');
var foster_city 	= require('./foster_city');
var hayward 	= require('./hayward');
var livermore 	= require('./livermore');
var millbrae 	= require('./millbrae');
var newark 	= require('./newark');
var palo_alto 	= require('./palo_alto');
var redwood_city 	= require('./redwood_city');
var san_bruno 	= require('./san_bruno');
var san_leandro 	= require('./san_leandro');
var san_mateo 	= require('./san_mateo');
var santa_clara 	= require('./santa_clara');
var saratoga 	= require('./saratoga');
var sunnyvale 	= require('./sunnyvale');
var mountain_house 	= require('./mountain_house');
var tracy 	= require('./tracy');
var las_vegas 	= require('./las_vegas');
var alameda 	= require('./alameda');
var menlo_park 	= require('./menlo_park');
var los_gatos 	= require('./los_gatos');
var los_altos 	= require('./los_altos');
var atherton 	= require('./atherton');
var walnut_creek 	= require('./walnut_creek');
var burlingame 	= require('./burlingame');
var hillsborough 	= require('./hillsborough');
var woodside 	= require('./woodside');
var portola_valley 	= require('./portola_valley');

const schoolMap = {
    "San Francisco": san_francisco.SAN_FRANCISCO_SCHOOLS,
    "San Ramon": san_ramon.SAN_RAMON_SCHOOLS,
    "Pleasanton": pleasanton.PLEASANTON_SCHOOLS,
    "Dublin": dublin.DUBLIN_SCHOOLS,
    "Danville": danville.DAN_VILLE_SCHOOLS,
    "Milpitas": milpitas.MILPITAS_SCHOOLS,
    "San Jose": san_jose.SAN_JOSE_SCHOOLS,
    "Fremont": fremont.FREMONT_SCHOOLS,
    "Union City": union_city.UNION_CITY_SCHOOLS,
    "Mountain View": mountain_view.MOUNTAIN_VIEW_SCHOOLS,
    "Cupertino": cupertino.CUPERTINO_SCHOOLS,
    "Campbell": campbell.CAMPBELL_SCHOOLS,
    "Castro Valley": castro_valley.CASTRO_VALLEY_SCHOOLS,
    "Daly City": daly_city.DALY_CITY_SCHOOLS,
    "Foster City": foster_city.FOSTER_CITY_SCHOOLS,
    "Hayward": hayward.HAYWARD_SCHOOLS,
    "Livermore": livermore.LIVERMORE_SCHOOLS,
    "Millbrae": millbrae.MILLBRAE_SCHOOLS,
    "Newark": newark.NEWARK_SCHOOLS,
    "Palo Alto": palo_alto.PALO_ALTO_SCHOOLS,
    "Redwood City": redwood_city.REDWOOD_CITY_SCHOOLS,
    "San Bruno": san_bruno.SAN_BRUNO_SCHOOLS,
    "San Leandro": san_leandro.SAN_LEANDRO_SCHOOLS,
    "San Mateo": san_mateo.SAN_MATEO_SCHOOLS,
    "Santa Clara": santa_clara.SANTA_CLARA_SCHOOLS,
    "Saratoga": saratoga.SARATOGA_SCHOOLS,
    "Sunnyvale": sunnyvale.SUNNYVALE_SCHOOLS,
    "Mountain House": mountain_house.MOUNTAIN_HOUSE_SCHOOLS,
    "Tracy": tracy.TRACY_SCHOOLS,
    "Las Vegas": las_vegas.LAS_VEGAS_SCHOOLS,
    "Alameda": alameda.ALAMEDA_SCHOOLS,
    "Menlo Park": menlo_park.MENLO_PARK_SCHOOLS,
    "Los Gatos": los_gatos.LOS_GATOS_SCHOOLS,
    "Los Altos": los_altos.LOS_ALTOS_SCHOOLS,
    "Atherton": atherton.ATHERTON_SCHOOLS,
    "Walnut Creek": walnut_creek.WALNUT_CREEK_SCHOOLS,
    "Burlingame": burlingame.BURLINGAME_SCHOOLS,
    "Hillsborough": hillsborough.HILLSBOROUGH_SCHOOLS,
    "Woodside": woodside.WOODSIDE_SCHOOLS,
    "Portola Valley": portola_valley.PORTOLA_VALLEY_SCHOOLS,
}

export default schoolMap