exports.PALO_ALTO_LISTINGS = [
		{
			"closePrice": 3150000,
			"daysOnMovoto": 42,
			"id": "0b88666e-8ee9-4534-a5a8-e42d0368abec",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871916_0_EbJIqZ_p.jpeg",
			"listDate": "2021-12-07 00:00:00",
			"listingAgent": "Juliana Lee Team",
			"listPrice": 2750000,
			"lotSize": 7331,
			"sqftTotal": 1323,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871916",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "JLEE Realty",
			"photoCount": 58,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1950,
			"zipCode": "94303",
			"path": "palo-alto-ca/642-wildwood-ln-palo-alto-ca-94303/pid_4mwm2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -250000,
			"propertyId": "05ddbe18-7548-4cd4-bd15-2f81653a75d5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-07 18:47:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:00:00",
			"virtualTourLink": "https://julianalee.com/homes/2021/wildwood_ln_642/tour.htm",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.449854,
				"lng": -122.127831,
				"zipcode": "94303",
				"subPremise": "",
				"address": "642 Wildwood Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://julianalee.com/homes/2021/wildwood_ln_642/tour.htm"
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 44,
			"id": "6e4d7ac0-a570-4a4c-a7f2-72615783cc8b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868327_0_NJIAMN_p.jpeg",
			"listDate": "2021-10-27 20:39:55",
			"listingAgent": "Steve Pierce",
			"listPrice": 2298000,
			"lotSize": 6151,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868327",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Zane MacGregor",
			"photoCount": 5,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94301",
			"path": "palo-alto-ca/886-boyce-ave-palo-alto-ca-94301/pid_nqum2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13a4b2b7-8987-47d8-b457-120da7f573f4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-11 00:00:00",
			"createdAt": "2021-10-28 03:42:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 20:39:55",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.451022,
				"lng": -122.149923,
				"zipcode": "94301",
				"subPremise": "",
				"address": "886 Boyce Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1378000,
			"daysOnMovoto": 112,
			"id": "492aa69b-6652-485d-a849-4cb1b7ade606",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858676_0_IJ66JN_p.jpeg",
			"listDate": "2021-08-17 08:52:34",
			"listingAgent": "Raymond Ni",
			"listPrice": 1398000,
			"lotSize": null,
			"sqftTotal": 1313,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858676",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 44,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1958,
			"zipCode": "94301",
			"path": "palo-alto-ca/750-university-ave-palo-alto-ca-94301-100_81240245/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e2ffb8c4-c8c3-4d3c-815e-3e7d2b20c358",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-08-17 15:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 08:52:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.451338,
				"lng": -122.155822,
				"zipcode": "94301",
				"subPremise": "",
				"address": "750 University Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1378000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 885000,
			"daysOnMovoto": 77,
			"id": "b488a4a7-05c9-40d1-90d0-d88111469c80",
			"tnImgPath": "https://pi.movoto.com/p/12/40966252_0_IfMu7y_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Jeffery Atkinson",
			"listPrice": 890000,
			"lotSize": 1260,
			"sqftTotal": 737,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966252",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Everhome Realty, Inc.",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1960,
			"zipCode": "94301",
			"path": "palo-alto-ca/315-poe-st-palo-alto-ca-94301/pid_hmsn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "79b6ab95-32e8-4a8c-bca7-a47e4dad0c33",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-08 20:31:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2913701",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.449916,
				"lng": -122.167015,
				"zipcode": "94301",
				"subPremise": "",
				"address": "315 Poe St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 885000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2913701"
		},
		{
			"closePrice": 3910000,
			"daysOnMovoto": 36,
			"id": "19156469-9bfd-40c7-820c-9401227eb30e",
			"tnImgPath": "https://pi.movoto.com/p/12/40970167_0_JQNiB2_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Ashish Patel",
			"listPrice": 3380000,
			"lotSize": 8100,
			"sqftTotal": 1771,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970167",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Panam Properties",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "94303",
			"path": "palo-alto-ca/856-thornwood-dr-palo-alto-ca-94303/pid_0ovn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "65b0af8f-00a3-4739-bad1-aa04220e01e6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-08 03:46:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.429103,
				"lng": -122.112901,
				"zipcode": "94303",
				"subPremise": "",
				"address": "856 Thornwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3910000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4290000,
			"daysOnMovoto": 83,
			"id": "84699820-b523-4400-880c-fb7020f0cb42",
			"tnImgPath": "https://pi.movoto.com/p/12/40961501_0_bE3nvZ_p.jpeg",
			"listDate": "2021-08-03 00:00:00",
			"listingAgent": "Marcin Grobelny",
			"listPrice": 4499000,
			"lotSize": 5000,
			"sqftTotal": 3635,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961501",
			"bath": 7,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Prominent Realty & Funding",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94306",
			"path": "palo-alto-ca/3751-el-centro-street-palo-alto-ca-94306-100_ml81514823/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -251000,
			"propertyId": "8e7c49c3-c9c3-4e89-b9c8-d40674d58b3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-08-03 14:26:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-03 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/Hsy_tVMuzCI",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.412808,
				"lng": -122.135196,
				"zipcode": "94306",
				"subPremise": "",
				"address": "3751 El Centro St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/Hsy_tVMuzCI"
		},
		{
			"closePrice": 3350000,
			"daysOnMovoto": 40,
			"id": "a916bc18-1019-4f58-bb6b-9f412f55fbcc",
			"tnImgPath": "https://pi.movoto.com/p/110/421595856_0_qi7rvv_p.jpeg",
			"listDate": "2021-08-11 00:00:00",
			"listingAgent": "Angelo Cosentino",
			"listPrice": 2698000,
			"lotSize": 6138,
			"sqftTotal": 1401,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595856",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass SF",
			"photoCount": 45,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": null,
			"zipCode": "94306",
			"path": "palo-alto-ca/581-colorado-ave-palo-alto-ca-94306/pid_edwn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50942a39-36c2-4a52-ab61-8866f4a55e97",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-20 00:00:00",
			"createdAt": "2021-09-17 17:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-11 00:00:00",
			"virtualTourLink": "https://www.581colorado.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.432193,
				"lng": -122.130715,
				"zipcode": "94306",
				"subPremise": "",
				"address": "581 Colorado Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.581colorado.com/mls"
		},
		{
			"closePrice": 5200000,
			"daysOnMovoto": 44,
			"id": "db70900d-ba32-41e3-a7ff-1e627190110e",
			"tnImgPath": "https://pi.movoto.com/p/12/40960877_0_rMubA6_p.jpeg",
			"listDate": "2021-08-03 00:00:00",
			"listingAgent": "Jamie Chan",
			"listPrice": 5200000,
			"lotSize": 7303,
			"sqftTotal": 2742,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40960877",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Re/Max Active Realty",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2014,
			"zipCode": "94303",
			"path": "palo-alto-ca/2620-marshall-dr-palo-alto-ca-94303-12_40960877/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4f59bfd1-8c36-4b5d-a779-de56befe64bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-16 00:00:00",
			"createdAt": "2021-08-04 04:11:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-03 00:00:00",
			"virtualTourLink": "http://www.2620marshall.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.43684,
				"lng": -122.127254,
				"zipcode": "94303",
				"subPremise": "",
				"address": "2620 Marshall Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.2620marshall.com/?mls"
		},
		{
			"closePrice": 5700000,
			"daysOnMovoto": 222,
			"id": "9de35b92-8092-4a1b-81db-323fc8070f6f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826731_0_nYqNyM_p.jpeg",
			"listDate": "2021-01-21 18:18:40",
			"listingAgent": "Joshua Johnson",
			"listPrice": 5800000,
			"lotSize": 18870,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826731",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 6,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94303",
			"path": "palo-alto-ca/800-san-antonio-rd-palo-alto-ca-94303-100_ml81826731/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b2f1c839-c3c1-4520-b122-a66b80002a60",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-01 00:00:00",
			"createdAt": "2021-01-22 02:22:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-21 18:18:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.420277,
				"lng": -122.10145,
				"zipcode": "94303",
				"subPremise": "",
				"address": "800 San Antonio Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2305000,
			"daysOnMovoto": 59,
			"id": "485ddd47-93a7-4366-90bf-2e0182dd0f10",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81851749_0_rIMFRq_p.jpeg",
			"listDate": "2021-07-02 13:48:03",
			"listingAgent": "Brett Jennings Group",
			"listPrice": 2300000,
			"lotSize": 5441,
			"sqftTotal": 1391,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81851749",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Real Estate Experts",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1949,
			"zipCode": "94306",
			"path": "palo-alto-ca/2740-2742-byron-st-palo-alto-ca-94306-100_ml81851749/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7ce4c0d4-31cc-44a9-bd5b-4eb48b274fe3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-31 00:00:00",
			"createdAt": "2021-07-02 20:52:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-02 13:48:03",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.432088,
				"lng": -122.129137,
				"zipcode": "94306",
				"subPremise": "",
				"address": "2740-2742 Byron St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2305000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3880000,
			"daysOnMovoto": 37,
			"id": "e99a15be-2886-43ec-a8ce-32d1d900091e",
			"tnImgPath": "https://pi.movoto.com/p/12/40958622_0_fZ7quB_p.jpeg",
			"listDate": "2021-07-14 00:00:00",
			"listingAgent": "Mike Carey",
			"listPrice": 3658000,
			"lotSize": 6000,
			"sqftTotal": 2626,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958622",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Investment Real Estate",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94303",
			"path": "palo-alto-ca/1539-walnut-palo-alto-ca-94303-12_40958622/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9a67c69-6b92-4d1c-b9a2-2a90def46ee6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-20 00:00:00",
			"createdAt": "2021-07-14 16:11:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.446271,
				"lng": -122.138168,
				"zipcode": "94303",
				"subPremise": "",
				"address": "1539 Walnut"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3310000,
			"daysOnMovoto": 51,
			"id": "fe7706d7-f927-4c0f-b424-d60006f6072a",
			"tnImgPath": "https://pi.movoto.com/p/12/40955278_0_7i3Ban_p.jpeg",
			"listDate": "2021-06-20 00:00:00",
			"listingAgent": "Daniel Donate",
			"listPrice": 2795000,
			"lotSize": 6400,
			"sqftTotal": 1716,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955278",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Cal Bay Realty, Inc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94303",
			"path": "palo-alto-ca/973-moreno-ave-palo-alto-ca-94303/pid_0prn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1ec50055-e921-4d6d-9ac3-080fe60a8ecf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-10 00:00:00",
			"createdAt": "2021-06-20 14:56:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-20 00:00:00",
			"virtualTourLink": "https://973morenoave42641mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.441648,
				"lng": -122.12437,
				"zipcode": "94303",
				"subPremise": "",
				"address": "973 Moreno Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3310000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://973morenoave42641mls.f8re.com/"
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 38,
			"id": "3a9d0b71-611e-4f98-984f-308426ded4a6",
			"tnImgPath": "https://pi.movoto.com/p/12/40955405_0_qj6Q2Y_p.jpeg",
			"listDate": "2021-06-22 00:00:00",
			"listingAgent": "Brett Weinstein",
			"listPrice": 2350000,
			"lotSize": 6536,
			"sqftTotal": 1341,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955405",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "REALTY ADVOCATES",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94303",
			"path": "palo-alto-ca/661-wildwood-ln-palo-alto-ca-94303/pid_joum2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f5c5fc45-5179-457c-9dac-4e94cc812ffb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-30 00:00:00",
			"createdAt": "2021-06-22 17:56:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-22 00:00:00",
			"virtualTourLink": "https://661wildwood.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.449527,
				"lng": -122.127365,
				"zipcode": "94303",
				"subPremise": "",
				"address": "661 Wildwood Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://661wildwood.com/mls"
		},
		{
			"closePrice": 2810000,
			"daysOnMovoto": 0,
			"id": "947d08d8-089d-4eb0-9141-3c6ca22cbbf4",
			"tnImgPath": "https://pi.movoto.com/p/102/221068681_0_b2ZB3Y_p.jpeg",
			"listDate": null,
			"listingAgent": "Mathew Berg",
			"listPrice": 2698000,
			"lotSize": 6473,
			"sqftTotal": 1208,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221068681",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berg Properties",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1951,
			"zipCode": "94306",
			"path": "palo-alto-ca/3641-ramona-st-palo-alto-ca-94306-102_221068681/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "da8b9325-0025-421d-a746-0de470ead293",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-28 00:00:00",
			"createdAt": "2021-06-17 17:24:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 17:24:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.417757,
				"lng": -122.12018,
				"zipcode": "94306",
				"subPremise": "",
				"address": "3641 Ramona St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 7,
			"id": "328d78a5-1638-40ae-b28b-c1ad522ea6ce",
			"tnImgPath": "https://pi.movoto.com/p/101/321067290_0_6EV7n3_p.jpeg",
			"listDate": "2021-07-19 00:00:00",
			"listingAgent": "Ceci Cook",
			"listPrice": 2300000,
			"lotSize": 6000,
			"sqftTotal": 1000,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321067290",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1942,
			"zipCode": "94306",
			"path": "palo-alto-ca/240-matadero-ave-palo-alto-ca-94306/pid_2gun2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d6059a7-03d7-4e3f-8ca0-0c22189fbff1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-26 00:00:00",
			"createdAt": "2021-07-20 01:33:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-19 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=4C56Z24k71R&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.421425,
				"lng": -122.131868,
				"zipcode": "94306",
				"subPremise": "",
				"address": "240 Matadero Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=4C56Z24k71R&mls=1"
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 84,
			"id": "90617eba-ad28-4d89-b38a-c04aa97b2ade",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81840284_0_jEbuUn_p.jpeg",
			"listDate": "2021-04-22 12:47:52",
			"listingAgent": "Timothy Foy",
			"listPrice": 2999000,
			"lotSize": 7501,
			"sqftTotal": 1578,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81840284",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Midtown Realty",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1922,
			"zipCode": "94306",
			"path": "palo-alto-ca/443-447-oxford-ave-palo-alto-ca-94306-100_ml81840284/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4470b3b6-59cb-43bd-a7e7-9bcceb5c63b8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-16 00:00:00",
			"createdAt": "2021-04-22 19:52:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-22 12:47:52",
			"virtualTourLink": "Https://www.447oxford.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.427555,
				"lng": -122.147617,
				"zipcode": "94306",
				"subPremise": "",
				"address": "443-447 Oxford Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.447oxford.com/"
		},
		{
			"closePrice": 1625700,
			"daysOnMovoto": 22,
			"id": "17808733-5675-47f8-a204-ad9a9da45eb3",
			"tnImgPath": "https://pi.movoto.com/p/12/40952673_0_erfANi_p.jpeg",
			"listDate": "2021-06-17 00:00:00",
			"listingAgent": "John Adams",
			"listPrice": 1250000,
			"lotSize": 3175,
			"sqftTotal": 746,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40952673",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Re/Max Gold",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1928,
			"zipCode": "94301",
			"path": "palo-alto-ca/555-hawthorne-ave-palo-alto-ca-94301/pid_lurn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e54f8fed-aadf-49ac-9efc-f4fa68184341",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-09 00:00:00",
			"createdAt": "2021-06-17 17:51:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2884684",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.451547,
				"lng": -122.162387,
				"zipcode": "94301",
				"subPremise": "",
				"address": "555 Hawthorne Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1625700,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2884684"
		},
		{
			"closePrice": 2475000,
			"daysOnMovoto": 116,
			"id": "67cb1b0d-5323-43cc-85cd-60ce7b7d0103",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830378_0_JyyeqQ_p.jpeg",
			"listDate": "2021-02-18 13:49:40",
			"listingAgent": "Keith Suddjian",
			"listPrice": 2538000,
			"lotSize": 4373,
			"sqftTotal": 2208,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830378",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1949,
			"zipCode": "94306",
			"path": "palo-alto-ca/2090-2096-yale-st-palo-alto-ca-94306-100_ml81830378/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -160000,
			"propertyId": "e72f022b-b071-4b0a-9968-df03ff0d35a9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2021-02-18 21:52:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 13:49:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.425591,
				"lng": -122.149714,
				"zipcode": "94306",
				"subPremise": "",
				"address": "2090-2096 Yale St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 50,
			"id": "0d420133-f1b0-433f-8091-05f2915a4450",
			"tnImgPath": "https://pi.movoto.com/p/12/40946284_0_Uq3nMz_p.jpeg",
			"listDate": "2021-04-20 00:00:00",
			"listingAgent": "Heman Galzie",
			"listPrice": 2399000,
			"lotSize": 7750,
			"sqftTotal": 1662,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40946284",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "New Cal Realty",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1940,
			"zipCode": "94306",
			"path": "palo-alto-ca/260-edlee-ave-palo-alto-ca-94306/pid_302n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "64828cb0-03ed-4194-a535-595d50f8100e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-09 00:00:00",
			"createdAt": "2021-04-20 17:01:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-20 00:00:00",
			"virtualTourLink": "Https://260edleeave.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.412374,
				"lng": -122.11807,
				"zipcode": "94306",
				"subPremise": "",
				"address": "260 Edlee Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://260edleeave.com/idx"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 0,
			"id": "b6450fe7-d4f0-45d3-8a07-54e00260f65d",
			"tnImgPath": "https://pi.movoto.com/p/102/221023224_0_NvM6MA_p.jpeg",
			"listDate": null,
			"listingAgent": "Janice Bothwell",
			"listPrice": 1395000,
			"lotSize": 1306,
			"sqftTotal": 1149,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221023224",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Lyon RE El Dorado Hills",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94301",
			"path": "palo-alto-ca/768-bryant-st-apt-3-palo-alto-ca-94301-102_221023224/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 100000,
			"propertyId": "43bf3638-5774-4222-bc5d-6bd818913249",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-09 00:00:00",
			"createdAt": "2021-03-26 16:19:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-26 16:19:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.443521,
				"lng": -122.158181,
				"zipcode": "94301",
				"subPremise": "APT 3",
				"address": "768 Bryant St #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2470000,
			"daysOnMovoto": 53,
			"id": "15ff09e1-90a6-4e93-a52d-4ccf0b9902c8",
			"tnImgPath": "https://pi.movoto.com/p/12/40944876_0_2MFZq6_p.jpeg",
			"listDate": "2021-04-09 00:00:00",
			"listingAgent": "Ming Shao",
			"listPrice": 1998000,
			"lotSize": 7403,
			"sqftTotal": 948,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40944876",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94303",
			"path": "palo-alto-ca/875-warren-way-palo-alto-ca-94303/pid_7iwn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e7ef1917-0241-4d2c-acdb-a8e8b0f91a91",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-01 00:00:00",
			"createdAt": "2021-04-09 18:56:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-09 00:00:00",
			"virtualTourLink": "Https://www.youtube.com/embed/BoLW7csekuk",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.439587,
				"lng": -122.128452,
				"zipcode": "94303",
				"subPremise": "",
				"address": "875 Warren Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2470000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.youtube.com/embed/BoLW7csekuk"
		},
		{
			"closePrice": 2750000,
			"daysOnMovoto": 105,
			"id": "86d0b4a8-cf0d-4faf-9dc6-caecc6c2dd81",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828613_0_EUzqbq_p.jpeg",
			"listDate": "2021-02-05 00:00:00",
			"listingAgent": "Alex H. Wang",
			"listPrice": 2798000,
			"lotSize": 5075,
			"sqftTotal": 2180,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828613",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 23,
			"propertyType": "OTHER",
			"propertyTypeDisplayName": "Mixed Use",
			"yearBuilt": 1908,
			"zipCode": "94301",
			"path": "palo-alto-ca/517-everett-ave-palo-alto-ca-94301/pid_tz8x2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "a6c95fa2-c802-4eee-a78f-37144dbb386a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-21 00:00:00",
			"createdAt": "2021-02-05 18:37:03",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.450275,
				"lng": -122.161672,
				"zipcode": "94301",
				"subPremise": "",
				"address": "517 Everett Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2870000,
			"daysOnMovoto": 40,
			"id": "10923d23-793b-4d23-ad86-2a470e8c7760",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-07 00:00:00",
			"listingAgent": "Juliana Lee Team",
			"listPrice": 2700000,
			"lotSize": 8659,
			"sqftTotal": 1785,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837841",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "JLEE Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "94303",
			"path": "palo-alto-ca/869-e-meadow-dr-palo-alto-ca-94303-100_80616074/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "013f06fa-5c0e-4152-b0a8-a685ca3754e2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-18 19:00:52",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-07 00:00:00",
			"virtualTourLink": "Https://palo-alto-ca-real-estate.com/869-e-meadow-dr.htm",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.427368,
				"lng": -122.109285,
				"zipcode": "94303",
				"subPremise": "",
				"address": "869 E Meadow Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2870000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://palo-alto-ca-real-estate.com/869-e-meadow-dr.htm"
		},
		{
			"closePrice": 880000,
			"daysOnMovoto": 195,
			"id": "d3c9d9eb-2f82-463b-b90b-7cbcc6fcd46a",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2020-11-03 00:00:00",
			"listingAgent": "Terri Balandra",
			"listPrice": 899000,
			"lotSize": null,
			"sqftTotal": 804,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81818641",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1977,
			"zipCode": "94306",
			"path": "palo-alto-ca/410-sheridan-ave-330-palo-alto-ca-94306/pid_wtun2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "91a63a7d-11b2-48f1-a324-f77980916b07",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-04-29 23:16:42",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2020-11-03 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2808765",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.424632,
				"lng": -122.142483,
				"zipcode": "94306",
				"subPremise": "APT 330",
				"address": "410 Sheridan Ave #330"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2808765"
		},
		{
			"closePrice": 736250,
			"daysOnMovoto": 93,
			"id": "43ed50c0-4462-4b24-b642-db8edde6b8ab",
			"tnImgPath": "https://pi.movoto.com/p/12/40937235_0_MQEzQZ_p.jpeg",
			"listDate": "2021-02-10 00:00:00",
			"listingAgent": "MASON M YANOWITZ",
			"listPrice": 775000,
			"lotSize": 1432,
			"sqftTotal": 1454,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937235",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Real Estate Source Inc.",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94301",
			"path": "palo-alto-ca/555-byron-st-202-palo-alto-ca-94301/pid_qkon2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -40000,
			"propertyId": "4035082f-5cca-42ff-a41a-9ba0c4c5bd8a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-14 00:00:00",
			"createdAt": "2021-02-10 18:06:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.449833,
				"lng": -122.156241,
				"zipcode": "94301",
				"subPremise": "APT 202",
				"address": "555 Byron St #202"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 736250,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 49,
			"id": "9842f497-8260-45f2-8fbb-98e3bbdcfa30",
			"tnImgPath": "https://pi.movoto.com/p/12/40940752_0_2MfuMz_p.jpeg",
			"listDate": "2021-03-10 00:00:00",
			"listingAgent": "Xiaomin Li",
			"listPrice": 1988000,
			"lotSize": 5407,
			"sqftTotal": 1288,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40940752",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1949,
			"zipCode": "94306",
			"path": "palo-alto-ca/3510-park-blvd-palo-alto-ca-94306/pid_kfun2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "74046b2d-4a55-4f39-ad62-87a6e20b70c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-28 00:00:00",
			"createdAt": "2021-03-10 21:01:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-10 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=FHgrebakbCT&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.422001,
				"lng": -122.131824,
				"zipcode": "94306",
				"subPremise": "",
				"address": "3510 Park Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=FHgrebakbCT&mls=1"
		},
		{
			"closePrice": 6900000,
			"daysOnMovoto": 461,
			"id": "1ac4efec-25ce-46b1-9a16-504d5eef385d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81779871_0_NQU7uY_p.jpeg",
			"listDate": "2020-01-22 14:46:34",
			"listingAgent": "Wendy Kandasamy",
			"listPrice": 7500000,
			"lotSize": 11374,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81779871",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 18,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94301",
			"path": "palo-alto-ca/1628-bryant-st-palo-alto-ca-94301-100_ml81746275/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -300000,
			"propertyId": "bdc9292a-3fce-4501-aac8-37b8b476a2cd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-28 00:00:00",
			"createdAt": "2020-01-22 23:07:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-01-22 14:46:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.436777,
				"lng": -122.147611,
				"zipcode": "94301",
				"subPremise": "",
				"address": "1628 Bryant St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 56,
			"id": "a4db7b3e-f355-421d-842c-aab4c1bd8743",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830294_0_nnYMAZ_p.jpeg",
			"listDate": "2021-02-18 07:13:16",
			"listingAgent": "John W. King",
			"listPrice": 2898000,
			"lotSize": 5942,
			"sqftTotal": 1333,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830294",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Palo Alto",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1948,
			"zipCode": "94303",
			"path": "palo-alto-ca/220-iris-way-palo-alto-ca-94303-100_80936603/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2be6d2d5-de53-46be-adbb-a8134b178b34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-02-18 15:17:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 07:13:16",
			"virtualTourLink": "Https://220IrisWay35650mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.446802,
				"lng": -122.130471,
				"zipcode": "94303",
				"subPremise": "",
				"address": "220 Iris Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://220IrisWay35650mls.f8re.com/"
		},
		{
			"closePrice": 3950000,
			"daysOnMovoto": 35,
			"id": "e0070e83-b872-4aa3-b16d-7a60fa925964",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833527_0_VRqfvI_p.jpeg",
			"listDate": "2021-03-10 21:51:07",
			"listingAgent": "Steven Peng",
			"listPrice": 3398000,
			"lotSize": 9457,
			"sqftTotal": 2186,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833527",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pegasus Real Estate Services",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94303",
			"path": "palo-alto-ca/816-sutter-ave-palo-alto-ca-94303/pid_3gun2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "369e2b1e-c741-457e-b4f1-bcf9ce91e474",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-11 05:57:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-10 21:51:07",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.434519,
				"lng": -122.123661,
				"zipcode": "94303",
				"subPremise": "",
				"address": "816 Sutter Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2525000,
			"daysOnMovoto": 39,
			"id": "db249791-192d-4a4e-a236-e1daa5e39969",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832860_0_EM2y32_p.jpeg",
			"listDate": "2021-03-06 09:29:56",
			"listingAgent": "Kathleen Pasin",
			"listPrice": 2598000,
			"lotSize": 5375,
			"sqftTotal": 1604,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832860",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1933,
			"zipCode": "94306",
			"path": "palo-alto-ca/la-selva-dr-palo-alto-ca-94306-100_80393012/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "62716b97-d675-4693-a63c-3d2a972c7bd5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-06 17:32:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-06 09:29:56",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2831224",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.417093,
				"lng": -122.132848,
				"zipcode": "94306",
				"subPremise": "",
				"address": "3748 La Selva Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2831224"
		},
		{
			"closePrice": 5795000,
			"daysOnMovoto": 42,
			"id": "c5e38fa1-2bdf-4470-8347-36f604464403",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832229_0_bvrYqQ_p.jpeg",
			"listDate": "2021-03-03 10:22:19",
			"listingAgent": "DeLeon Team",
			"listPrice": 5788000,
			"lotSize": 9888,
			"sqftTotal": 3276,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832229",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2017,
			"zipCode": "94303",
			"path": "palo-alto-ca/2797-ross-rd-palo-alto-ca-94303/pid_g2un2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aa373086-135f-480c-ade4-fa7e33c681cd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-03 18:32:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 10:22:19",
			"virtualTourLink": "https://player.vimeo.com/video/470790054",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.435363,
				"lng": -122.125631,
				"zipcode": "94303",
				"subPremise": "",
				"address": "2797 Ross Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5795000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/470790054"
		},
		{
			"closePrice": 3160000,
			"daysOnMovoto": 39,
			"id": "250312a8-e576-4df5-bdb0-34a017b25af4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832466_0_6yIVVq_p.jpeg",
			"listDate": "2021-03-04 11:35:34",
			"listingAgent": "Linda Xu",
			"listPrice": 2795000,
			"lotSize": 7366,
			"sqftTotal": 1992,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832466",
			"bath": 2,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Parc Agency Corporation",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94303",
			"path": "palo-alto-ca/2657-greer-rd-palo-alto-ca-94303-100_81463809/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "70f5e2ff-a882-447c-ab4f-ffc3ef662b98",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-03-04 19:42:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-04 11:35:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.441787,
				"lng": -122.122675,
				"zipcode": "94303",
				"subPremise": "",
				"address": "2657 Greer Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3160000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 923900,
			"daysOnMovoto": 42,
			"id": "4287ba8d-0f60-43b0-9e38-dc821649bd43",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831875_0_zNRz3r_p.jpeg",
			"listDate": "2021-03-01 10:37:56",
			"listingAgent": "Josh Felder",
			"listPrice": 899000,
			"lotSize": null,
			"sqftTotal": 913,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831875",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1960,
			"zipCode": "94301",
			"path": "palo-alto-ca/101-alma-st-1108-palo-alto-ca-94301/pid_mion2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bdc26d34-d062-4826-968c-f1c9d71cfcfd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-03-01 18:42:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-01 10:37:56",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.446985,
				"lng": -122.168236,
				"zipcode": "94301",
				"subPremise": "APT 1108",
				"address": "101 Alma St #1108"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 923900,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2710000,
			"daysOnMovoto": 45,
			"id": "e664ff3c-ce3a-45f4-aa90-d189d61219c9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831318_0_rvra7B_p.jpeg",
			"listDate": "2021-02-25 00:59:26",
			"listingAgent": "Coco Tan",
			"listPrice": 2299888,
			"lotSize": 5349,
			"sqftTotal": 1366,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831318",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty-Silicon Valley",
			"photoCount": 69,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1938,
			"zipCode": "94306",
			"path": "palo-alto-ca/567-barron-ave-palo-alto-ca-94306-100_911582/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9f6b3984-506c-4913-a90d-328a5dad907b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-12 00:00:00",
			"createdAt": "2021-02-25 09:02:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 00:59:26",
			"virtualTourLink": "https://www.youtube.com/embed/81IBjWhJeqc",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.416707,
				"lng": -122.133247,
				"zipcode": "94306",
				"subPremise": "",
				"address": "567 Barron Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/81IBjWhJeqc"
		},
		{
			"closePrice": 835000,
			"daysOnMovoto": 13,
			"id": "9987ec3b-68f9-4e22-a72e-59cc861cd3d2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836150_0_R7nqEv_p.jpeg",
			"listDate": "2021-03-26 16:04:57",
			"listingAgent": "Jordan Mott",
			"listPrice": 799999,
			"lotSize": 1951,
			"sqftTotal": 906,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836150",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94303",
			"path": "palo-alto-ca/2456-w-bayshore-rd-1-palo-alto-ca-94303/pid_wdtn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1d7f2877-5d97-47ef-89b9-59f8c677040f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-26 23:07:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-26 16:04:57",
			"virtualTourLink": "Http://www.oregongreenunit1.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.445053,
				"lng": -122.121518,
				"zipcode": "94303",
				"subPremise": "APT 1",
				"address": "2456 W Bayshore Rd #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 835000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.oregongreenunit1.com/?mls"
		},
		{
			"closePrice": 4900000,
			"daysOnMovoto": 35,
			"id": "7e75e65c-2078-487d-8f58-f41fa6cda5f2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832364_0_REu2RI_p.jpeg",
			"listDate": "2021-03-03 17:41:39",
			"listingAgent": "Weda Gray",
			"listPrice": 4999000,
			"lotSize": 7597,
			"sqftTotal": 2803,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832364",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Associates",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1925,
			"zipCode": "94301",
			"path": "palo-alto-ca/852-lincoln-ave-palo-alto-ca-94301-100_81109312/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -494901000,
			"propertyId": "09cf178c-f0ae-447a-93b0-333fd3b4d644",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-04 01:47:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 17:41:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.447861,
				"lng": -122.147174,
				"zipcode": "94301",
				"subPremise": "",
				"address": "852 Lincoln Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2350000,
			"daysOnMovoto": 40,
			"id": "f1dc1109-9e0d-4f2e-ac25-13d041b9c656",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831649_0_6iyUav_p.jpeg",
			"listDate": "2021-02-26 16:13:00",
			"listingAgent": "Hugh Cornish",
			"listPrice": 2400000,
			"lotSize": 5240,
			"sqftTotal": 2240,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831649",
			"bath": null,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 6,
			"propertyType": "OTHER",
			"propertyTypeDisplayName": "Mixed Use",
			"yearBuilt": 1952,
			"zipCode": "94306",
			"path": "palo-alto-ca/542-546-oxford-ave-palo-alto-ca-94306-100_ml81831649/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2a60ff7a-a1a9-4d4c-b33b-7c590cb5ce33",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-02-27 00:17:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 16:13:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.426617,
				"lng": -122.149169,
				"zipcode": "94306",
				"subPremise": "",
				"address": "542 546 Oxford Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3195000,
			"daysOnMovoto": 41,
			"id": "f05461d7-6247-456c-aefb-b8908c55b388",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831321_0_iBBr7F_p.jpeg",
			"listDate": "2021-02-25 07:20:55",
			"listingAgent": "Don Yarkin",
			"listPrice": 2995000,
			"lotSize": 8815,
			"sqftTotal": 2315,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831321",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Yarkin Realty",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "94303",
			"path": "palo-alto-ca/3538-evergreen-dr-palo-alto-ca-94303/pid_ipvn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77436b84-3323-4a98-aa62-072aaa3733d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-02-25 15:27:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 07:20:55",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2840749",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.429387,
				"lng": -122.112457,
				"zipcode": "94303",
				"subPremise": "",
				"address": "3538 Evergreen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3195000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2840749"
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 42,
			"id": "87ee3f17-b66c-447b-a7a5-69ad7ad36d93",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831070_0_yanJBR_p.jpeg",
			"listDate": "2021-02-23 19:43:55",
			"listingAgent": "Julie Lau",
			"listPrice": 1898000,
			"lotSize": 1176,
			"sqftTotal": 1917,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831070",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 50,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2014,
			"zipCode": "94306",
			"path": "palo-alto-ca/4062-el-camino-way-palo-alto-ca-94306-100_ml81831070/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f13799ac-3c46-42da-b5f3-b500a12d58bc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-02-24 03:47:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 19:43:55",
			"virtualTourLink": "Https://4062ElCaminoWay36349mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.414985,
				"lng": -122.126727,
				"zipcode": "94306",
				"subPremise": "",
				"address": "4062 El Camino Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://4062ElCaminoWay36349mls.f8re.com/"
		},
		{
			"closePrice": 2810000,
			"daysOnMovoto": 62,
			"id": "d6326386-2db3-44fd-b46f-5a9613e93284",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828311_0_A2i3yb_p.jpeg",
			"listDate": "2021-02-03 12:51:14",
			"listingAgent": "Sydney Ereno",
			"listPrice": 2900000,
			"lotSize": 6120,
			"sqftTotal": 2067,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828311",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1965,
			"zipCode": "94306",
			"path": "palo-alto-ca/1014-paradise-way-palo-alto-ca-94306/pid_69wn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ff813c29-8f68-4395-b8ce-b2d3affc5c91",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-02-03 20:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-03 12:51:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.408344,
				"lng": -122.137059,
				"zipcode": "94306",
				"subPremise": "",
				"address": "1014 Paradise Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2850000,
			"daysOnMovoto": 40,
			"id": "913d347a-2fe1-4842-aca0-715b07e8792e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831123_0_aaVRza_p.jpeg",
			"listDate": "2021-02-24 09:29:38",
			"listingAgent": "Bret A. Maryon",
			"listPrice": 2578000,
			"lotSize": 7606,
			"sqftTotal": 1688,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831123",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "94306",
			"path": "palo-alto-ca/4062-ben-lomond-dr-palo-alto-ca-94306/pid_7s2n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a575e10-1cb7-45f6-904f-b29487fe677c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-24 17:32:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-24 09:29:38",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2840826",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.411562,
				"lng": -122.111592,
				"zipcode": "94306",
				"subPremise": "",
				"address": "4062 Ben Lomond Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2840826"
		},
		{
			"closePrice": 2026000,
			"daysOnMovoto": 42,
			"id": "07505e87-d186-4d65-8af8-a7cdb30f8f87",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830914_0_aeqjUE_p.jpeg",
			"listDate": "2021-02-22 18:55:50",
			"listingAgent": "Josh Felder",
			"listPrice": 1799000,
			"lotSize": 3798,
			"sqftTotal": 1812,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830914",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 52,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1975,
			"zipCode": "94306",
			"path": "palo-alto-ca/4253-ponce-dr-palo-alto-ca-94306/pid_tg0n2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b7442dc0-786b-4ffa-a806-abb128078b2f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-23 03:02:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-22 18:55:50",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.409722,
				"lng": -122.108819,
				"zipcode": "94306",
				"subPremise": "",
				"address": "4253 Ponce Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2026000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 46,
			"id": "f134a7a1-0816-48ba-a387-a09ab4451175",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830377_0_fqf6uv_p.jpeg",
			"listDate": "2021-02-18 13:48:08",
			"listingAgent": "Keith Suddjian",
			"listPrice": 2850000,
			"lotSize": 6425,
			"sqftTotal": 3136,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830377",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 18,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Triplex",
			"yearBuilt": 1958,
			"zipCode": "94306",
			"path": "palo-alto-ca/2175-2179-williams-st-palo-alto-ca-94306-100_ml81830377/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c87e2864-c519-408e-a24c-dac19ca2c130",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-18 21:52:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 13:48:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.424708,
				"lng": -122.149225,
				"zipcode": "94306",
				"subPremise": "",
				"address": "2175-2179 Williams St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 7880000,
			"daysOnMovoto": 20,
			"id": "84b75cb0-56fe-4a08-8a92-64915bce8ba8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834262_0_yIYmZZ_p.jpeg",
			"listDate": "2021-03-15 23:58:31",
			"listingAgent": "John Forsyth James",
			"listPrice": 7388000,
			"lotSize": 11809,
			"sqftTotal": 4005,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834262",
			"bath": 6.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2021,
			"zipCode": "94301",
			"path": "palo-alto-ca/1052-fife-ave-palo-alto-ca-94301/pid_5qum2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3ae321d4-8fe7-4d2d-b08a-1d7acd97d1ca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-03-16 07:02:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-15 23:58:31",
			"virtualTourLink": "https://player.vimeo.com/video/523114008",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.451067,
				"lng": -122.149577,
				"zipcode": "94301",
				"subPremise": "",
				"address": "1052 Fife Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/523114008"
		},
		{
			"closePrice": 2750888,
			"daysOnMovoto": 32,
			"id": "c319b8fc-01e9-4d76-9db1-e73cefe0e1c0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832224_0_NiuZbN_p.jpeg",
			"listDate": "2021-03-03 10:13:51",
			"listingAgent": "DeLeon Team",
			"listPrice": 2488000,
			"lotSize": 6303,
			"sqftTotal": 1579,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832224",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1954,
			"zipCode": "94306",
			"path": "palo-alto-ca/3756-starr-king-cir-palo-alto-ca-94306/pid_2xxn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50a8354d-ae1b-4b45-a69b-29d73e556877",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-03-03 18:17:55",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 10:13:51",
			"virtualTourLink": "https://player.vimeo.com/video/517331045",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.41686,
				"lng": -122.118669,
				"zipcode": "94306",
				"subPremise": "",
				"address": "3756 Starr King Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2750888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/517331045"
		},
		{
			"closePrice": 3310000,
			"daysOnMovoto": 107,
			"id": "8c39fc5d-a7d3-434a-ba9e-8620543dd0d4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823640_0_UJj2yr_p.jpeg",
			"listDate": "2020-12-18 19:36:08",
			"listingAgent": "Mark DeTar",
			"listPrice": 2800000,
			"lotSize": 6299,
			"sqftTotal": 2052,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823640",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94303",
			"path": "palo-alto-ca/2972-clara-dr-palo-alto-ca-94303-100_80258616/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9f82effc-7c5c-43ad-9edb-8766e67465a9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2020-12-19 03:42:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-18 19:36:08",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2824980",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.437445,
				"lng": -122.11918,
				"zipcode": "94303",
				"subPremise": "",
				"address": "2972 Clara Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3310000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2824980"
		},
		{
			"closePrice": 3276000,
			"daysOnMovoto": 21,
			"id": "3505ad92-26fd-470b-9d1c-0fa20d061776",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833579_0_QNuyY2_p.jpeg",
			"listDate": "2021-03-11 09:53:12",
			"listingAgent": "Mar Andres Keehn",
			"listPrice": 2798000,
			"lotSize": 9936,
			"sqftTotal": 1910,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833579",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1941,
			"zipCode": "94306",
			"path": "palo-alto-ca/1575-stanford-ave-palo-alto-ca-94306/pid_bjwn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7b504e7e-b424-40b8-abb0-3e77cef78df7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-03-11 17:57:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-11 09:53:12",
			"virtualTourLink": "Https://my.matterport.com/show/?m=1Ma5Kh8YgqN&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.419129,
				"lng": -122.156,
				"zipcode": "94306",
				"subPremise": "",
				"address": "1575 Stanford Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3276000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=1Ma5Kh8YgqN&brand=0"
		},
		{
			"closePrice": 3310000,
			"daysOnMovoto": 33,
			"id": "f58fca35-81a4-4b86-ab8d-851d21dca284",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831518_0_AEYJeM_p.jpeg",
			"listDate": "2021-02-26 07:48:30",
			"listingAgent": "Alex Bouja",
			"listPrice": 2699000,
			"lotSize": 6412,
			"sqftTotal": 1682,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831518",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94303",
			"path": "palo-alto-ca/clara-dr-palo-alto-ca-94303-100_80017800/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0741e2ab-44b1-4060-a0e9-d8241b571e2c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-26 15:52:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 07:48:30",
			"virtualTourLink": "Http://sites.listvt.com/930claradrive/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.436385,
				"lng": -122.119641,
				"zipcode": "94303",
				"subPremise": "",
				"address": "930 Clara Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3310000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://sites.listvt.com/930claradrive/?mls"
		},
		{
			"closePrice": 2928000,
			"daysOnMovoto": 40,
			"id": "5fdbc495-621f-4499-94e4-8499fb8efdf2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830465_0_Q7a2Ri_p.jpeg",
			"listDate": "2021-02-19 00:05:42",
			"listingAgent": "Greg Celotti",
			"listPrice": 2750000,
			"lotSize": 5410,
			"sqftTotal": 1855,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830465",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1951,
			"zipCode": "94303",
			"path": "palo-alto-ca/2830-ross-rd-palo-alto-ca-94303/pid_20wn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b4589e3c-d55d-489b-8c99-d8f4e65eac28",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-19 08:07:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 00:05:42",
			"virtualTourLink": "Https://spark.adobe.com/page/uRHWwenqXLoGE/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.434436,
				"lng": -122.125169,
				"zipcode": "94303",
				"subPremise": "",
				"address": "2830 Ross Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2928000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://spark.adobe.com/page/uRHWwenqXLoGE/"
		},
		{
			"closePrice": 3400000,
			"daysOnMovoto": 48,
			"id": "b7093b46-d650-49d1-bdfc-d780611bc38a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829498_0_riBZYu_p.jpeg",
			"listDate": "2021-02-11 16:50:13",
			"listingAgent": "Don Yarkin",
			"listPrice": 2950000,
			"lotSize": 8246,
			"sqftTotal": 2224,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829498",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Yarkin Realty",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1950,
			"zipCode": "94306",
			"path": "palo-alto-ca/4057-amaranta-ave-palo-alto-ca-94306/pid_apyn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "12fd984c-5a5d-4fb0-9e23-fed98d62d168",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-12 00:52:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 16:50:13",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2838132",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Palo Alto",
				"lat": 37.410028,
				"lng": -122.13138,
				"zipcode": "94306",
				"subPremise": "",
				"address": "4057 Amaranta Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2838132"
		}
	]