exports.NEWARK_SCHOOLS = [
		{
			"id": 29545,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.524902,
			"lon": -122.039032,
			"name": "Ash Street Center Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "37365 Ash Street",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/29545-Ash-Street-Center-Head-Start/",
				"reviews": "/california/newark/29545-Ash-Street-Center-Head-Start/#Reviews",
				"collegeSuccess": "/california/newark/29545-Ash-Street-Center-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 182,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.527954,
			"lon": -122.047409,
			"name": "August Schilling Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "36901 Spruce Street",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/182-August-Schilling-Elementary-School/",
				"reviews": "/california/newark/182-August-Schilling-Elementary-School/#Reviews",
				"collegeSuccess": "/california/newark/182-August-Schilling-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "74%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 378,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 74
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 80
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "White",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 178,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.535004,
			"lon": -122.022301,
			"name": "Birch Grove Intermediate",
			"gradeLevels": "3-6",
			"assigned": null,
			"address": {
				"street1": "37490 Birch Street",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/178-Birch-Grove-Intermediate/",
				"reviews": "/california/newark/178-Birch-Grove-Intermediate/#Reviews",
				"collegeSuccess": "/california/newark/178-Birch-Grove-Intermediate/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "41%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 438,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 41
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 14
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 20750,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.531155,
			"lon": -122.017975,
			"name": "Birch Grove Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6020 Robertson Ave",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/20750-Birch-Grove-Pre-School/",
				"reviews": "/california/newark/20750-Birch-Grove-Pre-School/#Reviews",
				"collegeSuccess": "/california/newark/20750-Birch-Grove-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 174,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.528782,
			"lon": -122.01593,
			"name": "Birch Grove Primary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "6071 Smith Avenue",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/174-Birch-Grove-Primary/",
				"reviews": "/california/newark/174-Birch-Grove-Primary/#Reviews",
				"collegeSuccess": "/california/newark/174-Birch-Grove-Primary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "28%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 418,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 28
				},
				{
					"label": "Asian",
					"percentage": 33
				},
				{
					"label": "Hispanic",
					"percentage": 30
				},
				{
					"label": "White",
					"percentage": 14
				},
				{
					"label": "Filipino",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 173,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "h",
			"lat": 37.549778,
			"lon": -122.037331,
			"name": "Bridgepoint High (Continuation)",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "35753 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/173-Bridgepoint-High-Continuation-School/",
				"reviews": "/california/newark/173-Bridgepoint-High-Continuation-School/#Reviews",
				"collegeSuccess": "/california/newark/173-Bridgepoint-High-Continuation-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "74%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "30%",
				"state_average": "64%"
			},
			"enrollment": 66,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 74
				},
				{
					"label": "Hispanic",
					"percentage": 80
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 13387,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.52195,
			"lon": -121.998199,
			"name": "Challenger - Newark",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "39600 Cedar Blvd",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/13387-Challenger---Newark/",
				"reviews": "/california/newark/13387-Challenger---Newark/#Reviews",
				"collegeSuccess": "/california/newark/13387-Challenger---Newark/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 44,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13098,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.546909,
			"lon": -122.053215,
			"name": "Challenger School - Ardenwood",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "35487 Dumbarton Court",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/13098-Challenger-School---Ardenwood/",
				"reviews": "/california/newark/13098-Challenger-School---Ardenwood/#Reviews",
				"collegeSuccess": "/california/newark/13098-Challenger-School---Ardenwood/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 806,
			"parentRating": 2,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10867,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e,m,h",
			"lat": 37.549778,
			"lon": -122.037331,
			"name": "Crossroads High (Alternative)",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "35753 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/10867-Crossroads-High-Alternative-School/",
				"reviews": "/california/newark/10867-Crossroads-High-Alternative-School/#Reviews",
				"collegeSuccess": "/california/newark/10867-Crossroads-High-Alternative-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "60%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "28%",
				"state_average": "64%"
			},
			"enrollment": 43,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 28,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 60
				},
				{
					"label": "Hispanic",
					"percentage": 53
				},
				{
					"label": "African American",
					"percentage": 19
				},
				{
					"label": "White",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 20747,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.526089,
			"lon": -122.036896,
			"name": "Diversity Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "37371 Filbert Street",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/20747-Diversity-Childrens-Center/",
				"reviews": "/california/newark/20747-Diversity-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/newark/20747-Diversity-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 179,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.543812,
			"lon": -122.030823,
			"name": "E. L. Musick Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "5735 Musick Avenue",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/179-E.-L.-Musick-Elementary-School/",
				"reviews": "/california/newark/179-E.-L.-Musick-Elementary-School/#Reviews",
				"collegeSuccess": "/california/newark/179-E.-L.-Musick-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 283,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 62
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 62
				},
				{
					"label": "Asian",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 183,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.542419,
			"lon": -122.049294,
			"name": "H. A. Snow Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "6580 Mirabeau Drive",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/183-H.-A.-Snow-Elementary-School/",
				"reviews": "/california/newark/183-H.-A.-Snow-Elementary-School/#Reviews",
				"collegeSuccess": "/california/newark/183-H.-A.-Snow-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "51%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 343,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 51
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 57
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 175,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.539078,
			"lon": -122.042015,
			"name": "James A. Graham Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "36270 Cherry Street",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/175-James-A.-Graham-Elementary-School/",
				"reviews": "/california/newark/175-James-A.-Graham-Elementary-School/#Reviews",
				"collegeSuccess": "/california/newark/175-James-A.-Graham-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "64%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 375,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 64
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 67
				},
				{
					"label": "Asian",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 176,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.552475,
			"lon": -122.042412,
			"name": "John F. Kennedy Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "35430 Blackburn Drive",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/176-John-F.-Kennedy-Elementary-School/",
				"reviews": "/california/newark/176-John-F.-Kennedy-Elementary-School/#Reviews",
				"collegeSuccess": "/california/newark/176-John-F.-Kennedy-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "28%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 404,
			"parentRating": 4,
			"numReviews": 16,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 28
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20748,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.532261,
			"lon": -122.011452,
			"name": "King's Kids Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "38325 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/20748-Kings-Kids-Pre-School/",
				"reviews": "/california/newark/20748-Kings-Kids-Pre-School/#Reviews",
				"collegeSuccess": "/california/newark/20748-Kings-Kids-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30097,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.527779,
			"lon": -122.016899,
			"name": "Learning Springs Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "38273 Guava Drive",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/30097-Learning-Springs-Academy/",
				"reviews": "/california/newark/30097-Learning-Springs-Academy/#Reviews",
				"collegeSuccess": "/california/newark/30097-Learning-Springs-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 177,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e",
			"lat": 37.536041,
			"lon": -122.052734,
			"name": "Lincoln Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "36111 Bettencourt Street",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/177-Lincoln-Elementary-School/",
				"reviews": "/california/newark/177-Lincoln-Elementary-School/#Reviews",
				"collegeSuccess": "/california/newark/177-Lincoln-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 401,
			"parentRating": 5,
			"numReviews": 13,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 53
				},
				{
					"label": "White",
					"percentage": 17
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 31033,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.532196,
			"lon": -122.020203,
			"name": "Little Flowers Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "37815 Birch St",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/31033-Little-Flowers-Montessori/",
				"reviews": "/california/newark/31033-Little-Flowers-Montessori/#Reviews",
				"collegeSuccess": "/california/newark/31033-Little-Flowers-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13172,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.551136,
			"lon": -122.038963,
			"name": "Montessori School of Newark",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "35660 Cedar Blvd",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/13172-Montessori-School-Of-Newark/",
				"reviews": "/california/newark/13172-Montessori-School-Of-Newark/#Reviews",
				"collegeSuccess": "/california/newark/13172-Montessori-School-Of-Newark/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14217,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e,m,h",
			"lat": 37.55061,
			"lon": -122.037933,
			"name": "Newark Adult",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "35777 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/14217-Newark-Adult/",
				"reviews": "/california/newark/14217-Newark-Adult/#Reviews",
				"collegeSuccess": "/california/newark/14217-Newark-Adult/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 181,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "m",
			"lat": 37.542252,
			"lon": -122.04068,
			"name": "Newark Junior High",
			"gradeLevels": "7-8",
			"assigned": null,
			"address": {
				"street1": "6201 Lafayette Avenue",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/181-Newark-Junior-High-School/",
				"reviews": "/california/newark/181-Newark-Junior-High-School/#Reviews",
				"collegeSuccess": "/california/newark/181-Newark-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 889,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 56
				},
				{
					"label": "Pacific Islander",
					"rating": 7,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 9
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 180,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "h",
			"lat": 37.519844,
			"lon": -122.002045,
			"name": "Newark Memorial High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "39375 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/180-Newark-Memorial-High-School/",
				"reviews": "/california/newark/180-Newark-Memorial-High-School/#Reviews",
				"collegeSuccess": "/california/newark/180-Newark-Memorial-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "45%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "71%",
				"state_average": "64%"
			},
			"enrollment": 1711,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 6,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 45
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 52
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 3
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29321,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.546989,
			"lon": -122.036293,
			"name": "Ruschin Pre-School Center Head Start",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "36120 Ruschin Drive",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/29321-Ruschin-Pre-School-Center-Head-Start/",
				"reviews": "/california/newark/29321-Ruschin-Pre-School-Center-Head-Start/#Reviews",
				"collegeSuccess": "/california/newark/29321-Ruschin-Pre-School-Center-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29846,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.551277,
			"lon": -122.051201,
			"name": "Safari Kid",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "34899 Newark Blvd",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/29846-Safari-Kid/",
				"reviews": "/california/newark/29846-Safari-Kid/#Reviews",
				"collegeSuccess": "/california/newark/29846-Safari-Kid/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30178,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.531143,
			"lon": -122.018188,
			"name": "Smart Start Creative Lc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6020 Robertson Avenue",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/30178-Smart-Start-Creative-Lc/",
				"reviews": "/california/newark/30178-Smart-Start-Creative-Lc/#Reviews",
				"collegeSuccess": "/california/newark/30178-Smart-Start-Creative-Lc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8226,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.539227,
			"lon": -122.027908,
			"name": "St. Edward School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "5788 Thornton Avenue",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/8226-St.-Edward-School/",
				"reviews": "/california/newark/8226-St.-Edward-School/#Reviews",
				"collegeSuccess": "/california/newark/8226-St.-Edward-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 249,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 29
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 10144,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.532261,
			"lon": -122.011452,
			"name": "Stellar Academy For Dyslexics",
			"gradeLevels": "2-8",
			"assigned": null,
			"address": {
				"street1": "38325 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/10144-Stellar-Academy-For-Dyslexics/",
				"reviews": "/california/newark/10144-Stellar-Academy-For-Dyslexics/#Reviews",
				"collegeSuccess": "/california/newark/10144-Stellar-Academy-For-Dyslexics/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 17,
			"parentRating": 5,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 76
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Asian",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 17588,
			"districtId": 13,
			"districtName": "Newark Unified",
			"districtCity": "Newark",
			"levelCode": "e,m,h",
			"lat": 37.550171,
			"lon": -122.038155,
			"name": "Whiteford (June) Special Education Preschool",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "35725 Cedar Boulevard",
				"street2": null,
				"zip": "94560",
				"city": "Newark"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94560",
			"type": "school",
			"links": {
				"profile": "/california/newark/17588-Whiteford-June-Special-Education-Preschool/",
				"reviews": "/california/newark/17588-Whiteford-June-Special-Education-Preschool/#Reviews",
				"collegeSuccess": "/california/newark/17588-Whiteford-June-Special-Education-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 50,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]