exports.SARATOGA_LISTINGS = [
		{
			"closePrice": 5670000,
			"daysOnMovoto": 96,
			"id": "aaeb92d3-85ff-4abf-9e8b-918188dfc987",
			"tnImgPath": "https://pi.movoto.com/p/202/ND21207222_0_6vA6qN_p.jpeg",
			"listDate": "2021-09-18 00:00:00",
			"listingAgent": "Anthony Boudreault",
			"listPrice": 5050000,
			"lotSize": 71874,
			"sqftTotal": 5926,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "ND21207222",
			"bath": 8,
			"bed": 12,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 1933,
			"zipCode": "95070",
			"path": "saratoga-ca/13845-saratoga-ave-saratoga-ca-95070-100_80711900/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3e98e072-9e48-4615-9196-f10b18d5e931",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-10-12 02:57:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.267625,
				"lng": -122.021308,
				"zipcode": "95070",
				"subPremise": "",
				"address": "13845 Saratoga Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1380000,
			"daysOnMovoto": 294,
			"id": "421d8f74-c708-4708-8e0d-4c7aa5654465",
			"tnImgPath": "https://pi.movoto.com/p/12/40938809_0_BUenqJ_p.jpeg",
			"listDate": "2021-02-23 00:00:00",
			"listingAgent": "Michelle Lee",
			"listPrice": 1650000,
			"lotSize": 10009,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938809",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 3,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "95070",
			"path": "saratoga-ca/000-sun-valley-ct-saratoga-ca-95070-12_40938809/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "67fd87fd-be25-41e1-9da9-3dc2c6625352",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-02-23 20:16:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"zipcode": "95070",
				"subPremise": "",
				"address": "000 Sun Valley Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1380000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3526800,
			"daysOnMovoto": 41,
			"id": "d622c68f-9429-474a-9241-97a9bf28e6cc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868233_0_BI3ZYi_p.jpeg",
			"listDate": "2021-10-27 10:41:21",
			"listingAgent": "Robert Catalano",
			"listPrice": 2980000,
			"lotSize": 10032,
			"sqftTotal": 2303,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868233",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Real Estate Connections",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1969,
			"zipCode": "95070",
			"path": "saratoga-ca/12182-kirkbrook-dr-saratoga-ca-95070/pid_68xr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d7f5556-17a0-421f-a16a-0ca6be8a8578",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-27 17:47:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 10:41:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.291312,
				"lng": -122.026696,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12182 Kirkbrook Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3526800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 26,
			"id": "e8e39b2a-7db1-476d-8d46-c7dfd48c4874",
			"tnImgPath": "https://pi.movoto.com/p/202/PF21238297_0_VrQIZb_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Harout Khayalian",
			"listPrice": 929000,
			"lotSize": 532,
			"sqftTotal": 1088,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "PF21238297",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Lucent Properties",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "95070",
			"path": "saratoga-ca/19223-vineyard-ln-saratoga-ca-95070-100_ml81407408/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e26aab80-a6b3-48f5-baf8-21c4c8fad175",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-29 21:32:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=bRj4yENmaj4&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.278268,
				"lng": -122.007743,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19223 Vineyard Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=bRj4yENmaj4&mls=1"
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 68,
			"id": "0de3edd7-914c-440b-a1ee-2d22910a0d24",
			"tnImgPath": "https://pi.movoto.com/p/12/40967661_0_fQb7rz_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Sam Far",
			"listPrice": 1698888,
			"lotSize": 217870,
			"sqftTotal": 2850,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967661",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 2,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95070",
			"path": "saratoga-ca/16365-sanborn-rd-saratoga-ca-95070-100_ml81842472/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -300112,
			"propertyId": "513b2074-6fdf-4882-ba25-923d04dc1184",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-18 00:16:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.231955,
				"lng": -122.056039,
				"zipcode": "95070",
				"subPremise": "",
				"address": "16365 Sanborn Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3650000,
			"daysOnMovoto": 95,
			"id": "dd585cd6-da73-4712-9fea-3a35398059ef",
			"tnImgPath": "https://pi.movoto.com/p/12/40956726_0_vrJvqz_p.jpeg",
			"listDate": "2021-07-12 00:00:00",
			"listingAgent": "Jesmeet Kalra",
			"listPrice": 3499000,
			"lotSize": 12684,
			"sqftTotal": 3365,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956726",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Nest Smarter Inc.",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "95070",
			"path": "saratoga-ca/19887-merribrook-dr-saratoga-ca-95070/pid_wfzr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -286000,
			"propertyId": "d4718eb9-e14d-490d-9a28-4679921ed6a6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-07-12 20:01:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Jx285WtYUyV&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.269387,
				"lng": -122.021078,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19887 Merribrook Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Jx285WtYUyV&mls=1"
		},
		{
			"closePrice": 4068000,
			"daysOnMovoto": 35,
			"id": "e83506aa-937b-455c-84f3-00bf93e4ca93",
			"tnImgPath": "https://pi.movoto.com/p/12/40964613_0_R23zqu_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Sharon Tom",
			"listPrice": 3498000,
			"lotSize": 9525,
			"sqftTotal": 2700,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964613",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "95070",
			"path": "saratoga-ca/12455-brookglen-dr-saratoga-ca-95070-12_40964613/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c514a36-8ec5-4e6d-ad2f-50a2a09f3d55",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-08-27 18:36:58",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=x3WjqTQGtBX&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.287611,
				"lng": -122.00576,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12455 Brookglen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4068000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=x3WjqTQGtBX&brand=0"
		},
		{
			"closePrice": 3950000,
			"daysOnMovoto": 66,
			"id": "4ba03344-b9df-4c56-b9e7-4eda2cc85767",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81855260_0_eznrZv_p.jpeg",
			"listDate": "2021-07-24 10:08:19",
			"listingAgent": "Andy Tse",
			"listPrice": 3980000,
			"lotSize": 62435,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81855260",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95070",
			"path": "saratoga-ca/18935-hayfield-ct-saratoga-ca-95070-100_ml81771836/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0756520d-118b-4d59-9aed-487f89ac06ac",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-29 00:00:00",
			"createdAt": "2021-07-24 17:12:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-24 10:08:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.259351,
				"lng": -122.023271,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18935 Hayfield Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2780000,
			"daysOnMovoto": 35,
			"id": "f8715435-d123-4942-ac32-7584e7075282",
			"tnImgPath": "https://pi.movoto.com/p/12/40957919_0_Uf7eNf_p.jpeg",
			"listDate": "2021-07-14 00:00:00",
			"listingAgent": "Lily Yong",
			"listPrice": 2388000,
			"lotSize": 10165,
			"sqftTotal": 1709,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957919",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "95070",
			"path": "saratoga-ca/12451-woodside-dr-saratoga-ca-95070/pid_mxpr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c9f3d6e-cbf8-4c50-a411-a927fa736102",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-18 00:00:00",
			"createdAt": "2021-07-14 19:06:59",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/574472552",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.287909,
				"lng": -122.008376,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12451 Woodside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/574472552"
		},
		{
			"closePrice": 2510000,
			"daysOnMovoto": 36,
			"id": "8473582b-47bc-4d53-95cf-76e799afb5cd",
			"tnImgPath": "https://pi.movoto.com/p/12/40955812_0_zN72vi_p.jpeg",
			"listDate": "2021-06-24 00:00:00",
			"listingAgent": "DC Wei",
			"listPrice": 2290000,
			"lotSize": 10975,
			"sqftTotal": 1875,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40955812",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "95070",
			"path": "saratoga-ca/12320-woodside-dr-saratoga-ca-95070-100_81141710/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "40013a45-836f-4fda-938f-33d2874bb613",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-30 00:00:00",
			"createdAt": "2021-06-24 05:11:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.289368,
				"lng": -122.006777,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12320 Woodside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2675000,
			"daysOnMovoto": 0,
			"id": "2216e086-36d4-4e71-8a2b-313d904190df",
			"tnImgPath": "https://pi.movoto.com/p/102/221060573_0_RFiEfE_p.jpeg",
			"listDate": null,
			"listingAgent": "Cory Swint",
			"listPrice": 2750000,
			"lotSize": 11888,
			"sqftTotal": 2497,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221060573",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Swint Real Estate, Inc.",
			"photoCount": 9,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1974,
			"zipCode": "95070",
			"path": "saratoga-ca/18732-wood-dell-ct-saratoga-ca-95070/pid_1uqr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "9c40b3e8-ef67-413b-9651-5283d387491f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-14 00:00:00",
			"createdAt": "2021-06-01 20:04:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-01 20:04:43",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2875254",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.28482,
				"lng": -122.000293,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18732 Wood Dell Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2875254"
		},
		{
			"closePrice": 2640000,
			"daysOnMovoto": 34,
			"id": "0a51790d-8b4f-4c42-a28d-04e3fe3f5bef",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81845581_0_f6e2Ea_p.jpeg",
			"listDate": "2021-05-24 18:46:53",
			"listingAgent": "Bill Gorman",
			"listPrice": 2788000,
			"lotSize": 43307,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81845581",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 6,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95070",
			"path": "saratoga-ca/14171-chester-ave-saratoga-ca-95070-100_ml81845581/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bb55bea2-b5d7-447a-9c9f-40f93b1101c3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-28 00:00:00",
			"createdAt": "2021-05-25 02:02:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-24 18:46:53",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.263819,
				"lng": -122.0041,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14171 Chester Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2640000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 0,
			"id": "24b78cd6-7e09-4d59-964c-a76ea2cedc00",
			"tnImgPath": "https://pi.movoto.com/p/102/221050065_0_Zqvyra_p.jpeg",
			"listDate": null,
			"listingAgent": "Denise Bajtos",
			"listPrice": 2395000,
			"lotSize": 8455,
			"sqftTotal": 2021,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221050065",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Lyon RE Roseville",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1947,
			"zipCode": "95070",
			"path": "saratoga-ca/20895-4th-st-saratoga-ca-95070/pid_ag0t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0739d90b-fe46-4323-89bc-03e888227a88",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2021-05-12 23:04:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-12 23:04:28",
			"virtualTourLink": "Https://tours.pixsellmedia.com/1826917",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.259353,
				"lng": -122.039152,
				"zipcode": "95070",
				"subPremise": "",
				"address": "20895 4th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.pixsellmedia.com/1826917"
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 17,
			"id": "dcb71ca8-67e1-4cec-97f7-6a50bd8dfa2b",
			"tnImgPath": "https://pi.movoto.com/p/12/40950560_0_67Mj2I_p.jpeg",
			"listDate": "2021-05-25 00:00:00",
			"listingAgent": "COLIN ELBASANI",
			"listPrice": 2988888,
			"lotSize": 12262,
			"sqftTotal": 2986,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40950560",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "COLDWELL BANKER REALTY",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "95070",
			"path": "saratoga-ca/14130-alta-vista-ave-saratoga-ca-95070-100_81211986/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -311112,
			"propertyId": "fd6638cb-a577-4f94-99ac-dfc5905ee652",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-11 00:00:00",
			"createdAt": "2021-05-25 18:16:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.263027,
				"lng": -122.02996,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14130 Alta Vista Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 20,
			"id": "4fe674e2-ab74-459a-9890-6e1fc063cb63",
			"tnImgPath": "https://pi.movoto.com/p/110/421559437_0_IVB7F6_p.jpeg",
			"listDate": "2021-05-22 00:00:00",
			"listingAgent": "George Lim",
			"listPrice": 2188000,
			"lotSize": 9583,
			"sqftTotal": 1609,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421559437",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "95070",
			"path": "saratoga-ca/12611-paseo-cerro-saratoga-ca-95070-100_80545341/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "60abf524-9956-4e48-a0bb-827b0c231b14",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-11 00:00:00",
			"createdAt": "2021-06-03 22:56:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-22 00:00:00",
			"virtualTourLink": "Http://www.12611paseocerro.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.284537,
				"lng": -121.998505,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12611 Paseo Cerro"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.12611paseocerro.com/"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 67,
			"id": "1d5d082b-4b28-4392-ba50-d10a4399079e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834988_0_JQEQUA_p.jpeg",
			"listDate": "2021-03-19 13:47:26",
			"listingAgent": "Jim Meech",
			"listPrice": 1200000,
			"lotSize": 2003760,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834988",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "James Meech, Broker",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Development Land",
			"yearBuilt": null,
			"zipCode": "95070",
			"path": "saratoga-ca/24140-booker-crk-saratoga-ca-95070-202_ml81447366/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f7ee0efd-4d32-415e-919a-7be622650c8f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-26 00:00:00",
			"createdAt": "2021-03-19 20:52:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-19 13:47:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.252439,
				"lng": -122.09701,
				"zipcode": "95070",
				"subPremise": "",
				"address": "24140 Booker Crk"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3970000,
			"daysOnMovoto": 41,
			"id": "9e50502d-c2d2-4216-8e41-87828c2fb353",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-08 00:00:00",
			"listingAgent": "Minhua Jin",
			"listPrice": 3498000,
			"lotSize": 12084,
			"sqftTotal": 2489,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837972",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "95070",
			"path": "saratoga-ca/19665-charters-ave-saratoga-ca-95070-100_81018016/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "66380317-b4b5-4f83-b6b9-fe4d51303f87",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-19 18:04:39",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-08 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2853954",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.275789,
				"lng": -122.016845,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19665 Charters Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2853954"
		},
		{
			"closePrice": 4550000,
			"daysOnMovoto": 53,
			"id": "a95cc1d0-cf03-4b12-bbd6-45f88f38ccf9",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-26 00:00:00",
			"listingAgent": "David Guercio",
			"listPrice": 3800000,
			"lotSize": 27335,
			"sqftTotal": 3225,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836137",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "95070",
			"path": "saratoga-ca/14850-montalvo-road-saratoga-ca-95070-100_ml81499282/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5ae5d750-fe88-47fc-81b8-e87511837ee5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-04-27 16:30:50",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-26 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2851859",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.252306,
				"lng": -122.026778,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14850 Montalvo Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2851859"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 47,
			"id": "f72af5b3-480d-44f2-b075-72b7f9b0b036",
			"tnImgPath": "https://pi.movoto.com/p/12/40941139_0_MIVrqR_p.jpeg",
			"listDate": "2021-03-12 00:00:00",
			"listingAgent": "Tiffany Liu",
			"listPrice": 1188888,
			"lotSize": 968,
			"sqftTotal": 1612,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40941139",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Giant Realty",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "95070",
			"path": "saratoga-ca/19310-vineyard-ln-saratoga-ca-95070-100_80211282/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "728b7a20-4321-45ef-a604-d0bbb9cb2e73",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-28 00:00:00",
			"createdAt": "2021-03-12 21:22:06",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=oTEgyKoSPXJ&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.278508,
				"lng": -122.008395,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19310 Vineyard Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=oTEgyKoSPXJ&brand=0"
		},
		{
			"closePrice": 3418000,
			"daysOnMovoto": 29,
			"id": "0025b493-b729-4cab-a354-a3e0a7cb37e7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834098_0_JyVAbM_p.jpeg",
			"listDate": "2021-03-15 08:51:31",
			"listingAgent": "Julie Moore",
			"listPrice": 2985000,
			"lotSize": 14344,
			"sqftTotal": 2681,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834098",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1968,
			"zipCode": "95070",
			"path": "saratoga-ca/12909-cumberland-dr-saratoga-ca-95070/pid_yzur2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b6d1e61c-f98f-4648-a0de-3cd0526983da",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-15 15:57:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-15 08:51:31",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2848408",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.280964,
				"lng": -122.021633,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12909 Cumberland Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3418000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2848408"
		},
		{
			"closePrice": 3035000,
			"daysOnMovoto": 30,
			"id": "820af2fe-a579-4d20-9274-dcca13df7e63",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834047_0_Jr7ZV2_p.jpeg",
			"listDate": "2021-03-14 11:05:59",
			"listingAgent": "Kenn Callahan",
			"listPrice": 2925000,
			"lotSize": 54803,
			"sqftTotal": 2760,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834047",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "95070",
			"path": "saratoga-ca/14251-sobey-rd-saratoga-ca-95070/pid_ojzr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4d639c08-d89a-4afb-b50d-643da8149369",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-14 18:12:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-14 11:05:59",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2833549",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.260834,
				"lng": -121.999969,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14251 Sobey Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3035000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2833549"
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 36,
			"id": "9dd90907-1f2b-4dd5-8050-d50531e324d5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833044_0_IyIvUz_p.jpeg",
			"listDate": "2021-03-08 10:55:43",
			"listingAgent": "Bogard-Tanigami Team",
			"listPrice": 2598000,
			"lotSize": 12637,
			"sqftTotal": 1998,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833044",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1970,
			"zipCode": "95070",
			"path": "saratoga-ca/12653-orella-ct-saratoga-ca-95070/pid_lvvr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a46ea621-1688-446b-a052-d12187457899",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-08 19:02:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-08 10:55:43",
			"virtualTourLink": "Https://spark.adobe.com/page/JBm0UQhaHYVWy/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.284765,
				"lng": -122.021989,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12653 Orella Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://spark.adobe.com/page/JBm0UQhaHYVWy/"
		},
		{
			"closePrice": 3200000,
			"daysOnMovoto": 40,
			"id": "06d62b91-817a-4f4c-8034-3e48e9c1d99b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832816_0_uivnJ2_p.jpeg",
			"listDate": "2021-03-05 00:00:00",
			"listingAgent": "Steve McCarrick",
			"listPrice": 2998000,
			"lotSize": 10271,
			"sqftTotal": 2945,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832816",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2002,
			"zipCode": "95070",
			"path": "saratoga-ca/12469-lolly-ct-saratoga-ca-95070-100_80398037/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "600a9649-a0fe-4df3-a0b6-e8c3638543f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-06 03:02:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2842362",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.287123,
				"lng": -121.998852,
				"zipcode": "95070",
				"subPremise": "",
				"address": "12469 Lolly Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2842362"
		},
		{
			"closePrice": 3300000,
			"daysOnMovoto": 14,
			"id": "3e075444-4d1d-4baf-9b0e-8650d5331ccc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835858_0_yA2M6Y_p.jpeg",
			"listDate": "2021-03-25 12:28:01",
			"listingAgent": "Jackie Miano",
			"listPrice": 2749000,
			"lotSize": 10232,
			"sqftTotal": 2488,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835858",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1954,
			"zipCode": "95070",
			"path": "saratoga-ca/18558-ravenwood-dr-saratoga-ca-95070/pid_s6wr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4c7e483-2ceb-4d19-a907-547c6d7f28f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-25 19:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-25 12:28:01",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2851869",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.265329,
				"lng": -121.995429,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18558 Ravenwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2851869"
		},
		{
			"closePrice": 1825000,
			"daysOnMovoto": 31,
			"id": "10a82d7f-3d08-485f-9ed1-fc966fdb8464",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833034_0_IY6JVu_p.jpeg",
			"listDate": "2021-03-08 10:30:43",
			"listingAgent": "Susan Woods",
			"listPrice": 1700000,
			"lotSize": 8873,
			"sqftTotal": 1504,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833034",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "95070",
			"path": "saratoga-ca/18531-montpere-way-saratoga-ca-95070/pid_kwwr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea65d8d0-3b0c-4dfa-9f51-868b22f5ab0d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-08 18:37:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-08 10:30:43",
			"virtualTourLink": "Http://www.edigitaltours.com/21/03/27465/unbranded.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.267938,
				"lng": -121.995318,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18531 Montpere Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.edigitaltours.com/21/03/27465/unbranded.html"
		},
		{
			"closePrice": 4810000,
			"daysOnMovoto": 34,
			"id": "5ed4f12b-f3db-467f-b8a6-d7c94cd02e83",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832653_0_m6AyEJ_p.jpeg",
			"listDate": "2021-03-05 08:35:40",
			"listingAgent": "Moe Baniani",
			"listPrice": 4298000,
			"lotSize": 32670,
			"sqftTotal": 3700,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832653",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 58,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1995,
			"zipCode": "95070",
			"path": "saratoga-ca/19264-kerwin-ranch-ct-saratoga-ca-95070-100_81304207/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9796c86a-7f58-44d7-97f3-84ffd44a0b45",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-05 16:42:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 08:35:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.270069,
				"lng": -122.013487,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19264 Kerwin Ranch Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3300000,
			"daysOnMovoto": 37,
			"id": "cbc4d5b0-2303-4780-accd-1d6e45c96b3b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832018_0_BjVuef_p.jpeg",
			"listDate": "2021-03-02 00:02:13",
			"listingAgent": "David Lillo",
			"listPrice": 2798000,
			"lotSize": 11552,
			"sqftTotal": 2216,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832018",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "DPL Real Estate",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1960,
			"zipCode": "95070",
			"path": "saratoga-ca/19231-portos-dr-saratoga-ca-95070/pid_hswr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aa0b1892-880a-47e5-9fc6-99972a48c7d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-02 08:12:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-02 00:02:13",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2843679",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.273179,
				"lng": -122.008243,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19231 Portos Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2843679"
		},
		{
			"closePrice": 2285000,
			"daysOnMovoto": 43,
			"id": "df918819-ff43-4ade-887f-630d282b0903",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831476_0_nJuM3i_p.jpeg",
			"listDate": "2021-02-25 00:00:00",
			"listingAgent": "Steve McCarrick",
			"listPrice": 1898000,
			"lotSize": 10502,
			"sqftTotal": 1846,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831476",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1951,
			"zipCode": "95070",
			"path": "saratoga-ca/13238-paseo-presada-saratoga-ca-95070/pid_03xr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7bd223aa-a2fd-4e65-b654-3cd7df5004fa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-02-26 02:57:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2841588",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.275352,
				"lng": -121.99816,
				"zipcode": "95070",
				"subPremise": "",
				"address": "13238 Paseo Presada"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2285000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2841588"
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 33,
			"id": "e854f5ac-6c47-41bc-ae1b-709bf1bd538e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832713_0_fUzmjN_p.jpeg",
			"listDate": "2021-03-05 12:40:56",
			"listingAgent": "Andy Tse",
			"listPrice": 2000000,
			"lotSize": 458926,
			"sqftTotal": 1897,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832713",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 62,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1979,
			"zipCode": "95070",
			"path": "saratoga-ca/16830-bohlman-rd-saratoga-ca-95070-100_81472582/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9ec93a6-89c4-4ea1-9a15-9cb0ce6c39f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-05 20:47:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 12:40:56",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.224131,
				"lng": -122.035511,
				"zipcode": "95070",
				"subPremise": "",
				"address": "16830 Bohlman Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3750000,
			"daysOnMovoto": 63,
			"id": "5c61b83d-3558-4e9e-acc6-31c0ad31d45a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828323_0_vURQBy_p.jpeg",
			"listDate": "2021-02-03 13:40:49",
			"listingAgent": "David Welton",
			"listPrice": 3999000,
			"lotSize": 40798,
			"sqftTotal": 3750,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828323",
			"bath": 5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1963,
			"zipCode": "95070",
			"path": "saratoga-ca/15650-robles-del-oro-saratoga-ca-95070-100_81220078/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "149d28c3-c87e-4fef-b719-dfda329d7fc1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-02-25 00:52:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-03 13:40:49",
			"virtualTourLink": "Http://www.15650roblesdeloro.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.24094,
				"lng": -122.002691,
				"zipcode": "95070",
				"subPremise": "",
				"address": "15650 Robles Del Oro"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.15650roblesdeloro.com/?mls"
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 35,
			"id": "85ffdf18-679f-4a03-beaf-68c06ba4ba77",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832126_0_ze3A7B_p.jpeg",
			"listDate": "2021-03-02 15:34:54",
			"listingAgent": "Stuart Campbell",
			"listPrice": 1299000,
			"lotSize": 1050,
			"sqftTotal": 1614,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832126",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Astute Realty Inc",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1980,
			"zipCode": "95070",
			"path": "saratoga-ca/18920-sara-park-cir-saratoga-ca-95070/pid_i3ur2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c1c37372-a57f-4343-b936-8169ef77270a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-03-02 23:37:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-02 15:34:54",
			"virtualTourLink": "Https://tours.pixsellmedia.com/1789225?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.28285,
				"lng": -122.000158,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18920 Sara Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.pixsellmedia.com/1789225?idx=1"
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 73,
			"id": "390b887a-0593-4fbc-b655-3db2fb96a62b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827004_0_zRrMYb_p.jpeg",
			"listDate": "2021-01-23 16:47:12",
			"listingAgent": "Gregory Howell",
			"listPrice": 3750000,
			"lotSize": 13194,
			"sqftTotal": 3405,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827004",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 9,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2021,
			"zipCode": "95070",
			"path": "saratoga-ca/13930-loquat-ct-saratoga-ca-95070-100_ml81802559/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4b0b32cb-b651-4ea5-81b5-8dfb5713b593",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-01-24 00:52:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-23 16:47:12",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.266417,
				"lng": -121.993839,
				"zipcode": "95070",
				"subPremise": "",
				"address": "13930 Loquat Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2420000,
			"daysOnMovoto": 48,
			"id": "9ae38c56-244a-4716-9b54-83405be3a2f9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830045_0_f3JVVU_p.jpeg",
			"listDate": "2021-02-16 15:21:54",
			"listingAgent": "Sue Liu",
			"listPrice": 2420000,
			"lotSize": 8316,
			"sqftTotal": 2222,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830045",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Real Estate EBroker",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1954,
			"zipCode": "95070",
			"path": "saratoga-ca/13450-ward-way-saratoga-ca-95070/pid_klvr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cbe65d53-7f5f-4a27-846f-96f9c2e46bbf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-18 19:07:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-16 15:21:54",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.272825,
				"lng": -122.005178,
				"zipcode": "95070",
				"subPremise": "",
				"address": "13450 Ward Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2420000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3080000,
			"daysOnMovoto": 0,
			"id": "70bd36b2-9541-4568-9372-68029baa0e7a",
			"tnImgPath": "https://pi.movoto.com/p/102/20058254_0_YyYvyR_p.jpeg",
			"listDate": null,
			"listingAgent": "Linda Navarro",
			"listPrice": 3100000,
			"lotSize": 56959,
			"sqftTotal": 3891,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "20058254",
			"bath": 6,
			"bed": 5,
			"openHouses": [],
			"officeListName": "HomeSmart ICARE Realty",
			"photoCount": 56,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1995,
			"zipCode": "95070",
			"path": "saratoga-ca/21519-saratoga-heights-dr-saratoga-ca-95070/pid_6b2t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -430000,
			"propertyId": "a5bb0581-ff51-4f3d-a283-e89d19bd1710",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2020-10-17 19:39:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-17 19:39:21",
			"virtualTourLink": "https://player.vimeo.com/video/468776120",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.258125,
				"lng": -122.050762,
				"zipcode": "95070",
				"subPremise": "",
				"address": "21519 Saratoga Heights Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3080000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/468776120"
		},
		{
			"closePrice": 2205000,
			"daysOnMovoto": 10,
			"id": "c4803b74-153f-46db-bc82-8820cf23733a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835899_0_Zzmi6B_p.jpeg",
			"listDate": "2021-03-25 14:39:39",
			"listingAgent": "Alex Bouja",
			"listPrice": 1850000,
			"lotSize": 8151,
			"sqftTotal": 1467,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835899",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "95070",
			"path": "saratoga-ca/18468-purdue-dr-saratoga-ca-95070/pid_lezr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8218bc34-345d-43de-96a7-58d12599247d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-03-25 21:42:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-25 14:39:39",
			"virtualTourLink": "Http://sites.listvt.com/18468purduedr/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.279541,
				"lng": -121.993985,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18468 Purdue Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2205000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://sites.listvt.com/18468purduedr/?mls"
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 19,
			"id": "4aabfb24-3d95-4c3f-9ce5-d40bef50e8f8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834402_0_zYvjzv_p.jpeg",
			"listDate": "2021-03-16 17:13:39",
			"listingAgent": "Robert Gosalvez",
			"listPrice": 3000000,
			"lotSize": 45843,
			"sqftTotal": 2670,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834402",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1964,
			"zipCode": "95070",
			"path": "saratoga-ca/21755-arrowhead-ln-saratoga-ca-95070/pid_15gr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f236ad01-6a0a-495b-ac5a-458cc8b921e8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-03-17 00:17:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-16 17:13:39",
			"virtualTourLink": "https://www.youtube.com/embed/wPU-3oNrsUQ",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.288722,
				"lng": -122.050229,
				"zipcode": "95070",
				"subPremise": "",
				"address": "21755 Arrowhead Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/wPU-3oNrsUQ"
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 81,
			"id": "b5891a3c-d79d-4c59-972e-a5f498f2c149",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825646_0_iVYvF6_p.jpeg",
			"listDate": "2021-01-13 14:25:13",
			"listingAgent": "Amir Cyrus",
			"listPrice": 3110000,
			"lotSize": 51401,
			"sqftTotal": 2753,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825646",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "A. C. Madison RE Svcs.",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "95070",
			"path": "saratoga-ca/via-regina-saratoga-ca-95070-100_80140043/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "79b03704-0128-46a4-86a7-6d67849c7438",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-01-28 19:32:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-13 14:25:13",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.268924,
				"lng": -122.052972,
				"zipcode": "95070",
				"subPremise": "",
				"address": "21851 Via Regina"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3350000,
			"daysOnMovoto": 36,
			"id": "fe9a2dc5-5688-44a2-8d09-6776de3f2cd9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831454_0_MuynAM_p.jpeg",
			"listDate": "2021-02-25 00:00:00",
			"listingAgent": "Steve McCarrick",
			"listPrice": 2898000,
			"lotSize": 14066,
			"sqftTotal": 2216,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831454",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1962,
			"zipCode": "95070",
			"path": "saratoga-ca/19091-portos-dr-saratoga-ca-95070/pid_j2xr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81e4293f-3291-42a7-b6c2-ba654d03d340",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-26 00:37:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2841585",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.270733,
				"lng": -122.005134,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19091 Portos Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2841585"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 59,
			"id": "556ca529-e6cf-476f-9e6a-e637aed163e6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827957_0_zRrMYb_p.jpeg",
			"listDate": "2021-02-01 11:52:54",
			"listingAgent": "Gregory Howell",
			"listPrice": 3350000,
			"lotSize": 12815,
			"sqftTotal": 3230,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827957",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 9,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2021,
			"zipCode": "95070",
			"path": "saratoga-ca/13939-quito-rd-saratoga-ca-95070/pid_kvwr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0118c1ce-da0f-4b04-93c2-aad3c9fde83d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-01 19:57:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 11:52:54",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.266163,
				"lng": -121.993718,
				"zipcode": "95070",
				"subPremise": "",
				"address": "13939 Quito Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 60,
			"id": "ddfc7ff3-bee1-4c1d-aab0-7be8177e12b3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827881_0_zRrMYb_p.jpeg",
			"listDate": "2021-01-31 13:50:44",
			"listingAgent": "Gregory Howell",
			"listPrice": 1598000,
			"lotSize": 12815,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827881",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 9,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95070",
			"path": "saratoga-ca/13939-quito-saratoga-ca-95070-100_ml81827881/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "461f25e8-f89e-45ff-bb6e-13052640d883",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-01-31 21:57:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-31 13:50:44",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.266163,
				"lng": -121.993718,
				"zipcode": "95070",
				"subPremise": "",
				"address": "13939 Quito"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 812000,
			"daysOnMovoto": 41,
			"id": "2168db52-3e32-490e-b4a2-fa40550b5ae7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830327_0_yEqUR7_p.jpeg",
			"listDate": "2021-02-18 10:46:45",
			"listingAgent": "Ilan Sigura",
			"listPrice": 839000,
			"lotSize": null,
			"sqftTotal": 876,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830327",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "95070",
			"path": "saratoga-ca/20700-4th-street-9-saratoga-ca-95070-100_ml81500338/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a74186b0-3160-46a9-8d7e-5dee7db198a3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-18 18:52:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 10:46:45",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.257795,
				"lng": -122.036975,
				"zipcode": "95070",
				"subPremise": "APT 9",
				"address": "20700 4th St #9"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 812000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4150000,
			"daysOnMovoto": 46,
			"id": "a654ad8e-9c25-4423-adb8-0626cf2d59ed",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829352_0_6bJrmy_p.jpeg",
			"listDate": "2021-02-11 00:00:00",
			"listingAgent": "Steve McCarrick",
			"listPrice": 3998000,
			"lotSize": 29394,
			"sqftTotal": 3940,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829352",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1995,
			"zipCode": "95070",
			"path": "saratoga-ca/19360-kerwin-ranch-ct-saratoga-ca-95070/pid_vtxr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "86fab41f-5570-4e08-861a-e507c68f91b2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-29 00:00:00",
			"createdAt": "2021-02-11 08:27:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2835825",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.271529,
				"lng": -122.013284,
				"zipcode": "95070",
				"subPremise": "",
				"address": "19360 Kerwin Ranch Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2835825"
		},
		{
			"closePrice": 3843025,
			"daysOnMovoto": 44,
			"id": "5570e453-0864-4d4e-84f8-9eaef1b30f22",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828828_0_RriYVF_p.jpeg",
			"listDate": "2021-02-06 20:16:57",
			"listingAgent": "Jeff Eisenbaum",
			"listPrice": 3679000,
			"lotSize": 48787,
			"sqftTotal": 4802,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828828",
			"bath": 3.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 47,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1973,
			"zipCode": "95070",
			"path": "saratoga-ca/14199-short-hill-ct-saratoga-ca-95070/pid_govr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "df8bacea-8f34-4fa1-91c9-65ac70f3e663",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-23 00:00:00",
			"createdAt": "2021-02-07 04:22:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-06 20:16:57",
			"virtualTourLink": "Https://14199ShortHillCt35909mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.262214,
				"lng": -122.005711,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14199 Short Hill Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3843025,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://14199ShortHillCt35909mls.f8re.com/"
		},
		{
			"closePrice": 4885000,
			"daysOnMovoto": 20,
			"id": "4df8640d-4dae-4f26-a8f4-c016e58068a0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831874_0_eUVjaN_p.jpeg",
			"listDate": "2021-03-01 10:37:00",
			"listingAgent": "Amy A. McCafferty",
			"listPrice": 4898000,
			"lotSize": 53871,
			"sqftTotal": 5323,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831874",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1966,
			"zipCode": "95070",
			"path": "saratoga-ca/14582-horseshoe-ct-saratoga-ca-95070-100_ml81763265/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1bc465b8-c8b8-47ef-9c71-51556b2c5ae3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-03-01 19:22:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-01 10:37:00",
			"virtualTourLink": "Http://sites.listvt.com/14582horseshoecourt/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.255971,
				"lng": -122.021689,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14582 Horseshoe Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4885000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://sites.listvt.com/14582horseshoecourt/?mls"
		},
		{
			"closePrice": 1620000,
			"daysOnMovoto": 37,
			"id": "38f2c182-dd93-4adb-aaf4-29ccc01efddd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829574_0_73qfvz_p.jpeg",
			"listDate": "2021-02-12 09:09:53",
			"listingAgent": "Mary Tan",
			"listPrice": 1599000,
			"lotSize": 1563,
			"sqftTotal": 1807,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829574",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 48,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1979,
			"zipCode": "95070",
			"path": "saratoga-ca/20661-oak-creek-ln-saratoga-ca-95070-100_89949596/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1e5eaa75-c4fa-429f-bb93-20acd2528219",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-02-12 17:12:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-12 09:09:53",
			"virtualTourLink": "Http://mtrthome.com/20661oakcreek-unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.290515,
				"lng": -122.035021,
				"zipcode": "95070",
				"subPremise": "",
				"address": "20661 Oak Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1620000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://mtrthome.com/20661oakcreek-unbranded"
		},
		{
			"closePrice": 1925000,
			"daysOnMovoto": 52,
			"id": "beccc953-1627-48e1-ae17-6f9082b6493e",
			"tnImgPath": "https://pi.movoto.com/p/12/40935933_0_y267rJ_p.jpeg",
			"listDate": "2021-01-29 00:00:00",
			"listingAgent": "Vikram Sharma",
			"listPrice": 1999888,
			"lotSize": 1400,
			"sqftTotal": 2585,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40935933",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vikram Sharma, Broker",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1977,
			"zipCode": "95070",
			"path": "saratoga-ca/14634-fieldstone-dr-saratoga-ca-95070/pid_6f1t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0cb49856-8afc-495b-9dd3-d5cf92d6a26c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-01-29 16:26:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.257217,
				"lng": -122.039979,
				"zipcode": "95070",
				"subPremise": "",
				"address": "14634 Fieldstone Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 62,
			"id": "2634f171-63f8-4f26-9d8d-97138a10d3e8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826217_0_rNJZY2_p.jpeg",
			"listDate": "2021-01-18 11:14:42",
			"listingAgent": "Amy A. McCafferty",
			"listPrice": 4488000,
			"lotSize": 42815,
			"sqftTotal": 5000,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826217",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 45,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2000,
			"zipCode": "95070",
			"path": "saratoga-ca/21194-haymeadow-dr-saratoga-ca-95070/pid_z91t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -210000,
			"propertyId": "6559a43b-c3fc-43eb-9bb2-19b85c132705",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-01-18 19:17:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-18 11:14:42",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2832139",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.254127,
				"lng": -122.045079,
				"zipcode": "95070",
				"subPremise": "",
				"address": "21194 Haymeadow Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2832139"
		},
		{
			"closePrice": 2350000,
			"daysOnMovoto": 29,
			"id": "29ab53ec-b734-4fe1-823e-f03389dcb853",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830167_0_euZ6Zu_p.jpeg",
			"listDate": "2021-02-17 11:22:04",
			"listingAgent": "The Chandani Group",
			"listPrice": 2499000,
			"lotSize": 106970,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830167",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Anthem Realty",
			"photoCount": 46,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Development Land",
			"yearBuilt": null,
			"zipCode": "95070",
			"path": "saratoga-ca/22657-garrod-rd-saratoga-ca-95070-100_ml81694964/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3ecd642d-4e88-4a2a-8c5b-5590ebd7cc74",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-17 19:27:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 11:22:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.275553,
				"lng": -122.055024,
				"zipcode": "95070",
				"subPremise": "",
				"address": "22657 Garrod Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1477000,
			"daysOnMovoto": 41,
			"id": "f0c31ffe-86af-444a-93e1-ef04c1527436",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828544_0_7ZruiZ_p.jpeg",
			"listDate": "2021-02-04 20:20:49",
			"listingAgent": "Manu Changotra",
			"listPrice": 1500000,
			"lotSize": 2936,
			"sqftTotal": 1991,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828544",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1986,
			"zipCode": "95070",
			"path": "saratoga-ca/18883-biarritz-ln-saratoga-ca-95070-100_80314747/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8b2a7e3b-4bd4-42a0-a384-442aac90384f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-18 00:00:00",
			"createdAt": "2021-02-05 04:27:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-04 20:20:49",
			"virtualTourLink": "https://www.youtube.com/embed/cawgfeiU6IE",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.283906,
				"lng": -121.99963,
				"zipcode": "95070",
				"subPremise": "",
				"address": "18883 Biarritz Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1477000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/cawgfeiU6IE"
		},
		{
			"closePrice": 4050000,
			"daysOnMovoto": 31,
			"id": "8d10c253-b968-4183-adea-4d5928eac33b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829771_0_MR7BYM_p.jpeg",
			"listDate": "2021-02-13 14:08:54",
			"listingAgent": "Irina Sikela",
			"listPrice": 3888000,
			"lotSize": 22298,
			"sqftTotal": 2909,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829771",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Advanced RES",
			"photoCount": 75,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1963,
			"zipCode": "95070",
			"path": "saratoga-ca/20076-mendelsohn-ln-saratoga-ca-95070-100_81211843/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b5757658-8d64-4875-925d-2fd354181da9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-17 00:00:00",
			"createdAt": "2021-02-13 22:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-13 14:08:54",
			"virtualTourLink": "https://player.vimeo.com/video/511674049",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Saratoga",
				"lat": 37.250146,
				"lng": -122.023522,
				"zipcode": "95070",
				"subPremise": "",
				"address": "20076 Mendelsohn Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/511674049"
		}
	]