exports.CAMPBELL_SCHOOLS = [
    {
        "id": 29731,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.280853,
        "lon": -121.95388,
        "name": "Ab's Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "229 Budd Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/29731-Abs-Pre-School/",
            "reviews": "/california/campbell/29731-Abs-Pre-School/#Reviews",
            "collegeSuccess": "/california/campbell/29731-Abs-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30174,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.284573,
        "lon": -121.976234,
        "name": "Alka Montessori School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "70 South San Tomas Aquino Road",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/30174-Alka-Montessori-School/",
            "reviews": "/california/campbell/30174-Alka-Montessori-School/#Reviews",
            "collegeSuccess": "/california/campbell/30174-Alka-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13449,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.283501,
        "lon": -121.9319,
        "name": "Aurora Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "995 Apricot Ave",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/13449-Aurora-Child-Development-Center/",
            "reviews": "/california/campbell/13449-Aurora-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/campbell/13449-Aurora-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29451,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.292725,
        "lon": -121.950226,
        "name": "Bright Days Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1675 Winchester Boulevard",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/29451-Bright-Days-Pre-School/",
            "reviews": "/california/campbell/29451-Bright-Days-Pre-School/#Reviews",
            "collegeSuccess": "/california/campbell/29451-Bright-Days-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26944,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.288059,
        "lon": -121.930794,
        "name": "Campbell Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1063 Fewtrell Drive",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/26944-Campbell-Child-Development-Center/",
            "reviews": "/california/campbell/26944-Campbell-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/campbell/26944-Campbell-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 9148,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.287422,
        "lon": -121.967896,
        "name": "Campbell Christian School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "1075 West Campbell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/9148-Campbell-Christian-School/",
            "reviews": "/california/campbell/9148-Campbell-Christian-School/#Reviews",
            "collegeSuccess": "/california/campbell/9148-Campbell-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 272,
        "parentRating": 5,
        "numReviews": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Two or more races",
                "percentage": 52
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 20
            },
            {
                "label": "White",
                "percentage": 15
            },
            {
                "label": "Hispanic",
                "percentage": 11
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18285,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.294968,
        "lon": -121.942627,
        "name": "Campbell Parents Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "528 Harrison Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/18285-Campbell-Parents-Pre-School/",
            "reviews": "/california/campbell/18285-Campbell-Parents-Pre-School/#Reviews",
            "collegeSuccess": "/california/campbell/18285-Campbell-Parents-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 5411,
        "districtId": 629,
        "districtName": "Campbell Union",
        "districtCity": "Campbell",
        "levelCode": "e,m",
        "lat": 37.283321,
        "lon": -121.95562,
        "name": "Campbell School of Innovation",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "295 Cherry Lane",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/5411-Campbell-School-Of-Innovation/",
            "reviews": "/california/campbell/5411-Campbell-School-Of-Innovation/#Reviews",
            "collegeSuccess": "/california/campbell/5411-Campbell-School-Of-Innovation/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "17%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 300,
        "parentRating": 4,
        "numReviews": 8,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 8,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 17
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 25
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 34
            },
            {
                "label": "Two or more races",
                "percentage": 17
            },
            {
                "label": "Asian",
                "percentage": 16
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15165,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.277111,
        "lon": -121.961693,
        "name": "Canyon Heights Academy",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "775 Waldo Road",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/15165-Canyon-Heights-Academy/",
            "reviews": "/california/campbell/15165-Canyon-Heights-Academy/#Reviews",
            "collegeSuccess": "/california/campbell/15165-Canyon-Heights-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 232,
        "parentRating": 4,
        "numReviews": 14,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 92
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Asian",
                "percentage": 2
            },
            {
                "label": "Hispanic",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 5412,
        "districtId": 629,
        "districtName": "Campbell Union",
        "districtCity": "Campbell",
        "levelCode": "e",
        "lat": 37.265354,
        "lon": -121.964729,
        "name": "Capri Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "850 Chapman Drive",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/5412-Capri-Elementary-School/",
            "reviews": "/california/campbell/5412-Capri-Elementary-School/#Reviews",
            "collegeSuccess": "/california/campbell/5412-Capri-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "33%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 589,
        "parentRating": 4,
        "numReviews": 19,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 33
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 15
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 37
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 30
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 9955,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.290352,
        "lon": -121.948235,
        "name": "Casa Di Mir Montessori School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "90 East Latimer Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/9955-Casa-Di-Mir-Montessori-School/",
            "reviews": "/california/campbell/9955-Casa-Di-Mir-Montessori-School/#Reviews",
            "collegeSuccess": "/california/campbell/9955-Casa-Di-Mir-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 155,
        "parentRating": 4,
        "numReviews": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 39
            },
            {
                "label": "Hispanic",
                "percentage": 11
            },
            {
                "label": "Pacific Islander",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 5413,
        "districtId": 629,
        "districtName": "Campbell Union",
        "districtCity": "Campbell",
        "levelCode": "e",
        "lat": 37.300819,
        "lon": -121.94696,
        "name": "Castlemont Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "3040 E. Payne Ave.",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/5413-Castlemont-Elementary-School/",
            "reviews": "/california/campbell/5413-Castlemont-Elementary-School/#Reviews",
            "collegeSuccess": "/california/campbell/5413-Castlemont-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "56%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 626,
        "parentRating": 5,
        "numReviews": 8,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 56
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 57
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 21
            },
            {
                "label": "Asian",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18286,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.284828,
        "lon": -121.95929,
        "name": "Centro Armonia School",
        "gradeLevels": "K",
        "assigned": null,
        "address": {
            "street1": "531 West Rincon Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/18286-Centro-Armonia-School/",
            "reviews": "/california/campbell/18286-Centro-Armonia-School/#Reviews",
            "collegeSuccess": "/california/campbell/18286-Centro-Armonia-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26700,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.284828,
        "lon": -121.95929,
        "name": "Centro Armonia School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "531 West Rincon Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/26700-Centro-Armonia-School/",
            "reviews": "/california/campbell/26700-Centro-Armonia-School/#Reviews",
            "collegeSuccess": "/california/campbell/26700-Centro-Armonia-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 90,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 45
            },
            {
                "label": "White",
                "percentage": 36
            },
            {
                "label": "Asian",
                "percentage": 18
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31579,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.288136,
        "lon": -121.951401,
        "name": "Delphi Academy Of Campbell",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1 West Campbell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/31579-Delphi-Academy-Of-Campbell/",
            "reviews": "/california/campbell/31579-Delphi-Academy-Of-Campbell/#Reviews",
            "collegeSuccess": "/california/campbell/31579-Delphi-Academy-Of-Campbell/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 130,
        "parentRating": 5,
        "numReviews": 19,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30350,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.266258,
        "lon": -121.958786,
        "name": "Handprints Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3225 Winchester Boulevard",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/30350-Handprints-Pre-School/",
            "reviews": "/california/campbell/30350-Handprints-Pre-School/#Reviews",
            "collegeSuccess": "/california/campbell/30350-Handprints-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 20666,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.28418,
        "lon": -121.931114,
        "name": "Kid's Korner Christian Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2160 South Bascom Avenue #3",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/20666-Kids-Korner-Christian-Center/",
            "reviews": "/california/campbell/20666-Kids-Korner-Christian-Center/#Reviews",
            "collegeSuccess": "/california/campbell/20666-Kids-Korner-Christian-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27195,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.287746,
        "lon": -121.952065,
        "name": "Let's Play in Spanish",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1 West Campbell Ave Suite J-67",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/27195-Lets-Play-In-Spanish/",
            "reviews": "/california/campbell/27195-Lets-Play-In-Spanish/#Reviews",
            "collegeSuccess": "/california/campbell/27195-Lets-Play-In-Spanish/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30477,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.293102,
        "lon": -121.963104,
        "name": "Little Tree Montessori School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "430 Darryl Drive",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/30477-Little-Tree-Montessori-School/",
            "reviews": "/california/campbell/30477-Little-Tree-Montessori-School/#Reviews",
            "collegeSuccess": "/california/campbell/30477-Little-Tree-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24390,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.285069,
        "lon": -121.946747,
        "name": "Montessori Academy Of Campbell",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "177 East Rincon Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/24390-Montessori-Academy-Of-Campbell/",
            "reviews": "/california/campbell/24390-Montessori-Academy-Of-Campbell/#Reviews",
            "collegeSuccess": "/california/campbell/24390-Montessori-Academy-Of-Campbell/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 51,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 40
            },
            {
                "label": "Hispanic",
                "percentage": 20
            }
        ],
        "remediationData": {}
    },
    {
        "id": 22431,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.260998,
        "lon": -121.948311,
        "name": "Neighborhood Christian Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1880 White Oaks Road",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/22431-Neighborhood-Christian-Pre-School/",
            "reviews": "/california/campbell/22431-Neighborhood-Christian-Pre-School/#Reviews",
            "collegeSuccess": "/california/campbell/22431-Neighborhood-Christian-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18283,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.274303,
        "lon": -121.972427,
        "name": "New Generation Preschool & DC",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1291 Elam Ave",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/18283-New-Generation-Preschool--DC/",
            "reviews": "/california/campbell/18283-New-Generation-Preschool--DC/#Reviews",
            "collegeSuccess": "/california/campbell/18283-New-Generation-Preschool--DC/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18287,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.342388,
        "lon": -121.946098,
        "name": "Noah's Ark Infant & TLDR CTRR",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "560 N Hadison Ave",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/18287-Noahs-Ark-Infant--TLDR-CTRR/",
            "reviews": "/california/campbell/18287-Noahs-Ark-Infant--TLDR-CTRR/#Reviews",
            "collegeSuccess": "/california/campbell/18287-Noahs-Ark-Infant--TLDR-CTRR/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 1,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29158,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.295303,
        "lon": -121.942322,
        "name": "Noahs Ark Children Learning Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "560 Harrison Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/29158-Noahs-Ark-Children-Learning-Center/",
            "reviews": "/california/campbell/29158-Noahs-Ark-Children-Learning-Center/#Reviews",
            "collegeSuccess": "/california/campbell/29158-Noahs-Ark-Children-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8842,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.286999,
        "lon": -121.956703,
        "name": "Old Orchard School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "400 West Campbell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/8842-Old-Orchard-School/",
            "reviews": "/california/campbell/8842-Old-Orchard-School/#Reviews",
            "collegeSuccess": "/california/campbell/8842-Old-Orchard-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 136,
        "parentRating": 5,
        "numReviews": 15,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 72
            },
            {
                "label": "Asian",
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28205,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.291428,
        "lon": -121.942856,
        "name": "Pasitos School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "299 Harrison Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/28205-Pasitos-School/",
            "reviews": "/california/campbell/28205-Pasitos-School/#Reviews",
            "collegeSuccess": "/california/campbell/28205-Pasitos-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 16684,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.291485,
        "lon": -121.949898,
        "name": "Pioneer Family Academy",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "1799 South Winchester Boulevard",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/16684-Pioneer-Family-Academy/",
            "reviews": "/california/campbell/16684-Pioneer-Family-Academy/#Reviews",
            "collegeSuccess": "/california/campbell/16684-Pioneer-Family-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 136,
        "parentRating": 4,
        "numReviews": 8,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10326,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.287197,
        "lon": -121.969406,
        "name": "Primary Plus Coventry School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "1125 West Campbell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/10326-Primary-Plus-Coventry-School/",
            "reviews": "/california/campbell/10326-Primary-Plus-Coventry-School/#Reviews",
            "collegeSuccess": "/california/campbell/10326-Primary-Plus-Coventry-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 71,
        "parentRating": 4,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 59
            },
            {
                "label": "African American",
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "percentage": 8
            },
            {
                "label": "Asian",
                "percentage": 8
            }
        ],
        "remediationData": {}
    },
    {
        "id": 5420,
        "districtId": 629,
        "districtName": "Campbell Union",
        "districtCity": "Campbell",
        "levelCode": "e",
        "lat": 37.295132,
        "lon": -121.955353,
        "name": "Rosemary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "401 West Hamilton Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/5420-Rosemary-Elementary-School/",
            "reviews": "/california/campbell/5420-Rosemary-Elementary-School/#Reviews",
            "collegeSuccess": "/california/campbell/5420-Rosemary-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "89%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 466,
        "parentRating": 3,
        "numReviews": 1,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 89
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 87
            },
            {
                "label": "Asian",
                "percentage": 3
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "Two or more races",
                "percentage": 1
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27862,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.295086,
        "lon": -121.955841,
        "name": "Rosemary Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "401 West Hamilton Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/27862-Rosemary-Child-Development-Center/",
            "reviews": "/california/campbell/27862-Rosemary-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/campbell/27862-Rosemary-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10610,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.291931,
        "lon": -121.92588,
        "name": "San Jose Christian School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "1300 Sheffield Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/10610-San-Jose-Christian-School/",
            "reviews": "/california/campbell/10610-San-Jose-Christian-School/#Reviews",
            "collegeSuccess": "/california/campbell/10610-San-Jose-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 252,
        "parentRating": 5,
        "numReviews": 23,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 70
            },
            {
                "label": "Asian",
                "percentage": 15
            },
            {
                "label": "Hispanic",
                "percentage": 11
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28086,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.262383,
        "lon": -121.95591,
        "name": "Small World School-Noddin",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1610 Dell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/28086-Small-World-School-Noddin/",
            "reviews": "/california/campbell/28086-Small-World-School-Noddin/#Reviews",
            "collegeSuccess": "/california/campbell/28086-Small-World-School-Noddin/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28087,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.262383,
        "lon": -121.95591,
        "name": "Small World Sutter Sa",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1610 Dell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/28087-Small-World-Sutter-Sa/",
            "reviews": "/california/campbell/28087-Small-World-Sutter-Sa/#Reviews",
            "collegeSuccess": "/california/campbell/28087-Small-World-Sutter-Sa/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27278,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.276363,
        "lon": -121.96167,
        "name": "Springbridge International School",
        "gradeLevels": "PK-6",
        "assigned": null,
        "address": {
            "street1": "Preschool & Kindergarten 1625 W. Campbell Ave. Grades 1-7 1980 Hamilton Ave",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/27278-Springbridge-International-School/",
            "reviews": "/california/campbell/27278-Springbridge-International-School/#Reviews",
            "collegeSuccess": "/california/campbell/27278-Springbridge-International-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 21,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31590,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.276279,
        "lon": -121.963432,
        "name": "Springbridge International School",
        "gradeLevels": "K-1",
        "assigned": null,
        "address": {
            "street1": "771 Waldo Road",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/31590-Springbridge-International-School/",
            "reviews": "/california/campbell/31590-Springbridge-International-School/#Reviews",
            "collegeSuccess": "/california/campbell/31590-Springbridge-International-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 23,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 9090,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.281948,
        "lon": -121.948524,
        "name": "St. Lucy Parish School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "76 Kennedy Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/9090-St.-Lucy-Parish-School/",
            "reviews": "/california/campbell/9090-St.-Lucy-Parish-School/#Reviews",
            "collegeSuccess": "/california/campbell/9090-St.-Lucy-Parish-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 295,
        "parentRating": 4,
        "numReviews": 26,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 51
            },
            {
                "label": "Hispanic",
                "percentage": 27
            },
            {
                "label": "Two or more races",
                "percentage": 11
            },
            {
                "label": "Asian",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24522,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.285473,
        "lon": -121.981659,
        "name": "Starbright School - Campbell",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "1806 West Campbell Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/24522-Starbright-School---Campbell/",
            "reviews": "/california/campbell/24522-Starbright-School---Campbell/#Reviews",
            "collegeSuccess": "/california/campbell/24522-Starbright-School---Campbell/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 139,
        "parentRating": 5,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 89
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 11
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24027,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.288136,
        "lon": -121.951401,
        "name": "Stellar Learning Academy",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "2503 Easton Place #38 San Jose, CA 95133",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/24027-Stellar-Learning-Academy/",
            "reviews": "/california/campbell/24027-Stellar-Learning-Academy/#Reviews",
            "collegeSuccess": "/california/campbell/24027-Stellar-Learning-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 22,
        "parentRating": 4,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 35
            },
            {
                "label": "Hispanic",
                "percentage": 20
            },
            {
                "label": "Two or more races",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18288,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.294731,
        "lon": -121.934929,
        "name": "University Park Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "851 E Hamilton Ave",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/18288-University-Park-Child-Development-Center/",
            "reviews": "/california/campbell/18288-University-Park-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/campbell/18288-University-Park-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26369,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.288136,
        "lon": -121.951401,
        "name": "Valley International Academy",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "1 West Campbell Avenue",
            "street2": "Room E43",
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/26369-Valley-International-Academy/",
            "reviews": "/california/campbell/26369-Valley-International-Academy/#Reviews",
            "collegeSuccess": "/california/campbell/26369-Valley-International-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 10,
        "parentRating": 4,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24980,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.287746,
        "lon": -121.952065,
        "name": "Valley International Academy - Campbell Community Center",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "1 W. Campbell Ave, B20",
            "street2": null,
            "zip": "95006",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95006",
        "type": "school",
        "links": {
            "profile": "/california/campbell/24980-Valley-International-Academy---Campbell-Community-Center/",
            "reviews": "/california/campbell/24980-Valley-International-Academy---Campbell-Community-Center/#Reviews",
            "collegeSuccess": "/california/campbell/24980-Valley-International-Academy---Campbell-Community-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 16702,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.277004,
        "lon": -121.952995,
        "name": "Veritas Christian Academy",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/16702-Veritas-Christian-Academy/",
            "reviews": "/california/campbell/16702-Veritas-Christian-Academy/#Reviews",
            "collegeSuccess": "/california/campbell/16702-Veritas-Christian-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 14037,
        "districtId": 629,
        "districtName": "Campbell Union",
        "districtCity": "Campbell",
        "levelCode": "e",
        "lat": 37.264427,
        "lon": -121.964684,
        "name": "Village",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "825 West Parr Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/14037-Village/",
            "reviews": "/california/campbell/14037-Village/#Reviews",
            "collegeSuccess": "/california/campbell/14037-Village/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "7%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 263,
        "parentRating": 4,
        "numReviews": 19,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 7
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 53
            },
            {
                "label": "Two or more races",
                "percentage": 13
            },
            {
                "label": "Asian",
                "percentage": 11
            },
            {
                "label": "Hispanic",
                "percentage": 8
            },
            {
                "label": "African American",
                "percentage": 6
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8994,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.285622,
        "lon": -121.959335,
        "name": "West Valley Christian School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "95 Dot Avenue",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/8994-West-Valley-Christian-School/",
            "reviews": "/california/campbell/8994-West-Valley-Christian-School/#Reviews",
            "collegeSuccess": "/california/campbell/8994-West-Valley-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 25,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 76
            },
            {
                "label": "White",
                "percentage": 12
            },
            {
                "label": "African American",
                "percentage": 12
            }
        ],
        "remediationData": {}
    },
    {
        "id": 5427,
        "districtId": 630,
        "districtName": "Campbell Union High",
        "districtCity": "San Jose",
        "levelCode": "h",
        "lat": 37.270901,
        "lon": -121.985771,
        "name": "Westmont High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "4805 W.mont Ave.",
            "street2": null,
            "zip": "95008",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95008",
        "type": "school",
        "links": {
            "profile": "/california/campbell/5427-Westmont-High-School/",
            "reviews": "/california/campbell/5427-Westmont-High-School/#Reviews",
            "collegeSuccess": "/california/campbell/5427-Westmont-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "27%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "82%",
            "state_average": "64%"
        },
        "enrollment": 1601,
        "parentRating": 4,
        "numReviews": 12,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 9,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 27
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 18
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 29
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 39
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 2
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 22451,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.290001,
        "lon": -121.949997,
        "name": "Westside Co-Op Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "Po Box 1265",
            "street2": null,
            "zip": "95009",
            "city": "Campbell"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95009",
        "type": "school",
        "links": {
            "profile": "/california/campbell/22451-Westside-Co-Op-Pre-School/",
            "reviews": "/california/campbell/22451-Westside-Co-Op-Pre-School/#Reviews",
            "collegeSuccess": "/california/campbell/22451-Westside-Co-Op-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    }
]