exports.DALY_CITY_LISTINGS = [
		{
			"closePrice": 2400000,
			"daysOnMovoto": 72,
			"id": "86104400-17a8-4428-8c00-236f5775b877",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869592_0_bMvyAA_p.jpeg",
			"listDate": "2021-11-08 17:36:48",
			"listingAgent": "Kris Focha",
			"listPrice": 2250000,
			"lotSize": 2500,
			"sqftTotal": 5632,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869592",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94014",
			"path": "daly-city-ca/677-villa-st-daly-city-ca-94014/pid_td64w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c28218d4-1a28-4494-a1fd-dccba1f3649f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-11-09 01:42:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 17:36:48",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.686392,
				"lng": -122.458025,
				"zipcode": "94014",
				"subPremise": "",
				"address": "677 Villa St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2290000,
			"daysOnMovoto": 72,
			"id": "4e988b4c-95ed-4aeb-b3aa-cc1fb6618cdd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869588_0_vbMjIA_p.jpeg",
			"listDate": "2021-11-08 17:19:31",
			"listingAgent": "Kris Focha",
			"listPrice": 2250000,
			"lotSize": 2500,
			"sqftTotal": 5632,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869588",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94014",
			"path": "daly-city-ca/687-villa-st-daly-city-ca-94014/pid_rd64w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7382271a-905c-4416-9754-112d0484ec35",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-11-09 01:22:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 17:19:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.686335,
				"lng": -122.457848,
				"zipcode": "94014",
				"subPremise": "",
				"address": "687 Villa St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 560000,
			"daysOnMovoto": 8,
			"id": "a72668ac-27ba-4eff-bd56-231ae447b839",
			"tnImgPath": "https://pi.movoto.com/p/110/422618770_0_eVYfBj_p.jpeg",
			"listDate": "2022-01-03 00:00:00",
			"listingAgent": "Patrick Shannon",
			"listPrice": 338888,
			"lotSize": 2500,
			"sqftTotal": null,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "422618770",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Trinity Realty, Inc.",
			"photoCount": 14,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94014",
			"path": "daly-city-ca/1137-hanover-st-daly-city-ca-94014/pid_b4z4w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "49ca4ad9-0db8-4c71-85ff-1afa40e1dc6e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2022-01-03 22:46:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2022-01-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.703762,
				"lng": -122.457903,
				"zipcode": "94014",
				"subPremise": "",
				"address": "1137 Hanover St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 0,
			"id": "38ac4fdc-ea73-409f-aa19-71dcf68a76eb",
			"tnImgPath": "https://pi.movoto.com/p/12/40976957_0_AMvYUb_p.jpeg",
			"listDate": "2021-12-31 00:00:00",
			"listingAgent": "Geary Do",
			"listPrice": 850000,
			"lotSize": 972,
			"sqftTotal": 969,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40976957",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Mosaik Real Estate",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1985,
			"zipCode": "94014",
			"path": "daly-city-ca/1209-cameron-ln-apt-217-daly-city-ca-94014-12_40976957/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5efa273-40b7-441f-bff2-0fcf3dafe6df",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2022-01-01 00:21:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.703498,
				"lng": -122.444598,
				"zipcode": "94014",
				"subPremise": "APT 217",
				"address": "1209 Cameron Ln #217"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1060000,
			"daysOnMovoto": 55,
			"id": "5481bd55-e20c-4fbe-8c4e-ca999db5c8e3",
			"tnImgPath": "https://pi.movoto.com/p/12/40972049_0_rFyMjQ_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Daisy Munoz",
			"listPrice": 899888,
			"lotSize": 2500,
			"sqftTotal": 1115,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972049",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94014",
			"path": "daly-city-ca/449-santa-barbara-ave-daly-city-ca-94014/pid_tx65w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cdd0d8ca-6c47-4083-a478-b878b1c0544e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-10-22 23:16:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.702166,
				"lng": -122.463907,
				"zipcode": "94014",
				"subPremise": "",
				"address": "449 Santa Barbara Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1060000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 0,
			"id": "a9330095-eab0-4e25-b977-ad2f32871e05",
			"tnImgPath": "https://pi.movoto.com/p/102/421598372_0_nz73ay_p.jpeg",
			"listDate": null,
			"listingAgent": "Luis Sosa-Guasch",
			"listPrice": 998000,
			"lotSize": 5200,
			"sqftTotal": 1030,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421598372",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "ERA Carlile Realty Group",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1946,
			"zipCode": "94015",
			"path": "daly-city-ca/508-90th-st-daly-city-ca-94015-100_80362480/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -72000,
			"propertyId": "4bafcc1f-f7cc-468e-bed1-0561f26d61cd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-09-24 23:49:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 23:49:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.691312,
				"lng": -122.476,
				"zipcode": "94015",
				"subPremise": "",
				"address": "508 90th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1388000,
			"daysOnMovoto": 6,
			"id": "c3eadbf0-c46b-4ac4-a28d-f5a6d7d91a9b",
			"tnImgPath": "https://pi.movoto.com/p/110/421616071_0_BIi2Rn_p.jpeg",
			"listDate": "2021-12-08 00:00:00",
			"listingAgent": "Mia Baldini",
			"listPrice": 1388000,
			"lotSize": 2631,
			"sqftTotal": 2034,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421616071",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Century 21 Baldini Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2021,
			"zipCode": "94014",
			"path": "daly-city-ca/38-rio-verde-st-daly-city-ca-94014-110_421616071/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "24e6f784-ade8-49fe-88cd-655fb28e2328",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-12-09 02:16:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.708091,
				"lng": -122.41569,
				"zipcode": "94014",
				"subPremise": "",
				"address": "38 Rio Verde St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1388000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 550000,
			"daysOnMovoto": 12,
			"id": "851690db-f418-4f59-a90a-20d4eca83651",
			"tnImgPath": "https://pi.movoto.com/p/110/421615773_0_eAYAZf_p.jpeg",
			"listDate": "2021-12-02 00:00:00",
			"listingAgent": "Jeremy Rushton",
			"listPrice": 549000,
			"lotSize": 696789,
			"sqftTotal": 655,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615773",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94014",
			"path": "daly-city-ca/1028-san-luis-cir-729-daly-city-ca-94014/pid_l2f9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ec13d89d-a627-4395-af85-df080b6a6635",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-12-07 21:56:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.706743,
				"lng": -122.441594,
				"zipcode": "94014",
				"subPremise": "APT 729",
				"address": "1028 San Luis Cir #729"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1388000,
			"daysOnMovoto": 6,
			"id": "dffbd49c-b15e-4c70-ab24-c998d87ba2f9",
			"tnImgPath": "https://pi.movoto.com/p/110/421615950_0_AveBrA_p.jpeg",
			"listDate": "2021-12-07 00:00:00",
			"listingAgent": "Mia Baldini",
			"listPrice": 1388000,
			"lotSize": 2631,
			"sqftTotal": 2034,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615950",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Century 21 Baldini Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2021,
			"zipCode": "94014",
			"path": "daly-city-ca/32-rio-verde-st-daly-city-ca-94014-110_421599880/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ceb36137-5b6a-4315-bdc1-2a97989c34a6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-12-08 19:41:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.708131,
				"lng": -122.415623,
				"zipcode": "94014",
				"subPremise": "",
				"address": "32 Rio Verde St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1388000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 46,
			"id": "c10281c2-000f-4b26-94b1-210b3379f049",
			"tnImgPath": "https://pi.movoto.com/p/110/421606872_0_zRUfmy_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Sally Zhang",
			"listPrice": 1380000,
			"lotSize": 2400,
			"sqftTotal": 2710,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421606872",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sterling Real Estate",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1995,
			"zipCode": "94014",
			"path": "daly-city-ca/139-santa-cruz-ave-daly-city-ca-94014-110_425839/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e48e2493-4fcd-412a-9860-b0954b2bd018",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-29 01:36:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.707495,
				"lng": -122.465434,
				"zipcode": "94014",
				"subPremise": "",
				"address": "139 Santa Cruz Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 0,
			"id": "340d0608-afba-4501-8583-d8bb1baa6f6f",
			"tnImgPath": "https://pi.movoto.com/p/102/421601792_0_aM7N36_p.jpeg",
			"listDate": null,
			"listingAgent": "Celso Manganaan",
			"listPrice": 949000,
			"lotSize": 2525,
			"sqftTotal": 1180,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421601792",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1927,
			"zipCode": "94014",
			"path": "daly-city-ca/137-alexander-avenue-daly-city-ca-94014-100_ml89846111/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b7306345-cc53-46e5-b012-4cd303cef5ec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-07 04:59:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 04:59:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.703092,
				"lng": -122.452901,
				"zipcode": "94014",
				"subPremise": "",
				"address": "137 Alexander Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 8,
			"id": "c6e424e5-f62c-4f0d-b3f9-964de7ebdf6a",
			"tnImgPath": "https://pi.movoto.com/p/110/421613949_0_BRuEab_p.jpeg",
			"listDate": "2021-12-01 00:00:00",
			"listingAgent": "Samantha Competente",
			"listPrice": 998000,
			"lotSize": 3135,
			"sqftTotal": 1150,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421613949",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 4,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1957,
			"zipCode": "94015",
			"path": "daly-city-ca/536-skyline-dr-daly-city-ca-94015/pid_0b84w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7db6b3e-b37b-447d-b6b0-8b45ab45219a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-12-02 05:31:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.682281,
				"lng": -122.490616,
				"zipcode": "94015",
				"subPremise": "",
				"address": "536 Skyline Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1110000,
			"daysOnMovoto": 50,
			"id": "375f1371-025e-4a93-900d-0373220d701e",
			"tnImgPath": "https://pi.movoto.com/p/110/421613320_0_Ny3bBR_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Najeen Sorrentino",
			"listPrice": 979000,
			"lotSize": 2500,
			"sqftTotal": 1400,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421613320",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens Real Estate Thrive",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94014",
			"path": "daly-city-ca/251-wyandotte-ave-daly-city-ca-94014/pid_oez4w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "be8b8a02-b78e-4d8a-b4e3-9d8bbf45cdb0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-20 19:31:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://www.seetheproperty.com/u/400796",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.696756,
				"lng": -122.460059,
				"zipcode": "94014",
				"subPremise": "",
				"address": "251 Wyandotte Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1110000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.seetheproperty.com/u/400796"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 9,
			"id": "4a10e0b4-4724-4ff8-a394-60646c127114",
			"tnImgPath": "https://pi.movoto.com/p/110/421614179_0_N7yMYv_p.jpeg",
			"listDate": "2021-11-29 00:00:00",
			"listingAgent": "Michael Al-Ahwal",
			"listPrice": 1189500,
			"lotSize": 3036,
			"sqftTotal": 1240,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614179",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Comax Realty, Inc.",
			"photoCount": 10,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1979,
			"zipCode": "94015",
			"path": "daly-city-ca/69-lycett-cir-daly-city-ca-94015-110_447388/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c846a5c4-624a-4abc-9919-d87d2e119b4a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-11-29 23:26:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.654757,
				"lng": -122.465814,
				"zipcode": "94015",
				"subPremise": "",
				"address": "69 Lycett Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 980000,
			"daysOnMovoto": 35,
			"id": "f687589d-70de-4d00-9bba-7675427b7c82",
			"tnImgPath": "https://pi.movoto.com/p/12/40973043_0_rZuZuf_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Geary Do",
			"listPrice": 898000,
			"lotSize": 839,
			"sqftTotal": 1177,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973043",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Mosaik Real Estate",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1985,
			"zipCode": "94014",
			"path": "daly-city-ca/1716-hampton-ln-daly-city-ca-94014-12_40973043/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "38cea744-588c-4762-9f9c-5a53b0389a69",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-03 01:56:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?brand=0&m=WQSUrNg5Zk4",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.702502,
				"lng": -122.444028,
				"zipcode": "94014",
				"subPremise": "",
				"address": "1716 Hampton Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?brand=0&m=WQSUrNg5Zk4"
		},
		{
			"closePrice": 1277000,
			"daysOnMovoto": 57,
			"id": "ae3a6ad8-a5f9-49b5-ac94-1a83c3211fe4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866210_0_IbvAAr_p.jpeg",
			"listDate": "2021-10-09 11:41:48",
			"listingAgent": "Team Pete & Christine",
			"listPrice": 1298888,
			"lotSize": 2465,
			"sqftTotal": 2410,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866210",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Star Properties",
			"photoCount": 65,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1975,
			"zipCode": "94014",
			"path": "daly-city-ca/235-gambetta-st-daly-city-ca-94014/pid_hlc5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8831db51-a2d1-4253-8a0e-3dea68c7e36b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-09 18:47:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-09 11:41:48",
			"virtualTourLink": "https://www.235gambettastreet.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.69911,
				"lng": -122.460058,
				"zipcode": "94014",
				"subPremise": "",
				"address": "235 Gambetta St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1277000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.235gambettastreet.com"
		},
		{
			"closePrice": 500000,
			"daysOnMovoto": 11,
			"id": "75df2c89-535f-464e-8189-17283966ab45",
			"tnImgPath": "https://pi.movoto.com/p/110/421612222_0_ayz2iI_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "John Farrell",
			"listPrice": 495000,
			"lotSize": 1057906,
			"sqftTotal": 788,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421612222",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Farrell Real Estate Investments",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1974,
			"zipCode": "94015",
			"path": "daly-city-ca/389-half-moon-ln-13-daly-city-ca-94015/pid_bv48w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2f04e911-db1e-4eb4-ae29-a3243d7b07ab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-26 00:00:00",
			"createdAt": "2021-11-16 00:56:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": "http://www.389halfmoon-13.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.664329,
				"lng": -122.464882,
				"zipcode": "94015",
				"subPremise": "APT 13",
				"address": "389 Half Moon Ln #13"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.389halfmoon-13.com"
		},
		{
			"closePrice": 850888,
			"daysOnMovoto": 32,
			"id": "c6758fe1-8c5e-4488-a235-50d01046b68d",
			"tnImgPath": "https://pi.movoto.com/p/110/421606499_0_yV3n7u_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Herbert Ng",
			"listPrice": 849000,
			"lotSize": 3500,
			"sqftTotal": 980,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421606499",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 13,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1924,
			"zipCode": "94014",
			"path": "daly-city-ca/133-macdonald-avenue-daly-city-ca-94014-100_ml89902404/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -21000,
			"propertyId": "0d714d86-6c1d-4fb6-a171-c0ed51eb73be",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-22 17:26:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.706005,
				"lng": -122.410257,
				"zipcode": "94014",
				"subPremise": "",
				"address": "133 Macdonald Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 696000,
			"daysOnMovoto": 52,
			"id": "ab2b4865-0e30-4d00-95b3-0d9f5ddf246c",
			"tnImgPath": "https://pi.movoto.com/p/110/421604868_0_v6rQa7_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Vilma Palaad",
			"listPrice": 688888,
			"lotSize": 696789,
			"sqftTotal": 1004,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604868",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Daly City",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94014",
			"path": "daly-city-ca/1021-san-luis-cir-apt-612-daly-city-ca-94014-110_421604868/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dec5b468-3e7e-4bb7-b41d-116ba6538c8e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-21 01:01:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.70614,
				"lng": -122.441186,
				"zipcode": "94014",
				"subPremise": "APT 612",
				"address": "1021 San Luis Cir #612"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 696000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1730000,
			"daysOnMovoto": 177,
			"id": "a034056a-942a-4839-974f-d75b3f2bbf7c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81846503_0_riuffR_p.jpeg",
			"listDate": "2021-05-29 14:54:32",
			"listingAgent": "Giovani Franco",
			"listPrice": 1795000,
			"lotSize": 2500,
			"sqftTotal": 5682,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81846503",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Kinetic Real Estate",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1965,
			"zipCode": "94014",
			"path": "daly-city-ca/693-linden-st-daly-city-ca-94014/pid_ke64w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "cb2fef55-d765-45e8-ab13-0fd70910f57b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-05-29 21:57:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-29 14:54:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.685701,
				"lng": -122.458334,
				"zipcode": "94014",
				"subPremise": "",
				"address": "693 Linden St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1730000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1268888,
			"daysOnMovoto": 25,
			"id": "1621b764-f5b6-468c-ad43-599ea5f45afd",
			"tnImgPath": "https://pi.movoto.com/p/110/421608874_0_VAenFv_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Monica Chung",
			"listPrice": 1088000,
			"lotSize": 5044,
			"sqftTotal": 1140,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421608874",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Andersen, Jung & Co.",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1946,
			"zipCode": "94015",
			"path": "daly-city-ca/1808-sweetwood-dr-daly-city-ca-94015-100_81229664/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6b4bfd02-77bf-4a06-a96d-c3577a484b0b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-11-16 20:46:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.68979,
				"lng": -122.478757,
				"zipcode": "94015",
				"subPremise": "",
				"address": "1808 Sweetwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1268888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1325000,
			"daysOnMovoto": 8,
			"id": "afaa63ad-0263-49a8-b68e-472a18179dbf",
			"tnImgPath": "https://pi.movoto.com/p/110/421610937_0_r7uzaz_p.jpeg",
			"listDate": "2021-11-13 00:00:00",
			"listingAgent": "Michelle Kim",
			"listPrice": 1098000,
			"lotSize": 1412719,
			"sqftTotal": 1562,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421610937",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Mosaik Real Estate",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2000,
			"zipCode": "94014",
			"path": "daly-city-ca/88-cityview-dr-daly-city-ca-94014-12_ml80385353/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "06fee2c1-4f0d-4f42-9da9-67f579ba2e5a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-21 00:00:00",
			"createdAt": "2021-11-15 00:56:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=eDSwLxfdXct&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.70445,
				"lng": -122.443088,
				"zipcode": "94014",
				"subPremise": "",
				"address": "88 City View Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=eDSwLxfdXct&brand=0"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 51,
			"id": "424dae08-a44e-4d5e-818b-c756952ceb22",
			"tnImgPath": "https://pi.movoto.com/p/110/421600327_0_beIZi6_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Robert Garrison",
			"listPrice": 899000,
			"lotSize": 1139,
			"sqftTotal": 1272,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421600327",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Garrison Properties",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1974,
			"zipCode": "94015",
			"path": "daly-city-ca/272-eastmoor-ave-daly-city-ca-94015/pid_i418w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b1770995-3172-4233-8170-6905c6c4465a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-21 00:00:00",
			"createdAt": "2021-10-01 19:16:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.684441,
				"lng": -122.478265,
				"zipcode": "94015",
				"subPremise": "",
				"address": "272 Eastmoor Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 802000,
			"daysOnMovoto": 270,
			"id": "acb24f13-def9-4885-a416-7f04fef58547",
			"tnImgPath": "https://pi.movoto.com/p/110/421595985_0_Y2vI2n_p.jpeg",
			"listDate": "2021-02-22 00:00:00",
			"listingAgent": "Dexter Lim",
			"listPrice": 799000,
			"lotSize": 237822,
			"sqftTotal": 1200,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595985",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1990,
			"zipCode": "94014",
			"path": "daly-city-ca/311-oak-ct-311-daly-city-ca-94014/pid_4388w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -29000,
			"propertyId": "d3b54b2a-8d12-40ca-ad38-128e283fcfa5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-01 16:31:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.707669,
				"lng": -122.427126,
				"zipcode": "94014",
				"subPremise": "APT 311",
				"address": "311 Oak Ct #311"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 802000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 6,
			"id": "aa9361b3-71cd-445b-aa81-48d6dc75a1f8",
			"tnImgPath": "https://pi.movoto.com/p/110/421611743_0_ZzAffI_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Samantha Huang",
			"listPrice": 1298888,
			"lotSize": 3300,
			"sqftTotal": 0,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421611743",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens Real Estate Thrive",
			"photoCount": 60,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "94015",
			"path": "daly-city-ca/66-lakewood-dr-daly-city-ca-94015/pid_9tt5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "02ec1305-855a-4421-942c-563dd00704a5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-11-12 22:41:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/645132143",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.697569,
				"lng": -122.490926,
				"zipcode": "94015",
				"subPremise": "",
				"address": "66 Lakewood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/645132143"
		},
		{
			"closePrice": 1080000,
			"daysOnMovoto": 43,
			"id": "73ae38fe-d177-4738-a35e-6228019dc084",
			"tnImgPath": "https://pi.movoto.com/p/12/40969887_0_RAaJMv_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Denille Fontana",
			"listPrice": 988000,
			"lotSize": 3927,
			"sqftTotal": 1110,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969887",
			"bath": 6,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Real Estate Source, Inc.",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94015",
			"path": "daly-city-ca/47-longview-dr-daly-city-ca-94015/pid_35a5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7b6c5fd9-1465-4c0c-b26a-83df4aa95eb4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-10-06 21:36:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/?app=desktop",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.666896,
				"lng": -122.489157,
				"zipcode": "94015",
				"subPremise": "",
				"address": "47 Longview Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1080000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/?app=desktop"
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 26,
			"id": "23dc73b9-3c54-4783-8532-6161fb3ee15f",
			"tnImgPath": "https://pi.movoto.com/p/101/421605572_0_VrmVyv_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Vince Sheehan",
			"listPrice": 988000,
			"lotSize": 3960,
			"sqftTotal": 1050,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421605572",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Marin's Finest",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "94015",
			"path": "daly-city-ca/35-montebello-dr-daly-city-ca-94015/pid_f7a5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "19a5d905-7206-4c7a-8fde-0c9b41b81c60",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-22 07:43:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/638198812",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.666139,
				"lng": -122.488228,
				"zipcode": "94015",
				"subPremise": "",
				"address": "35 Montebello Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/638198812"
		},
		{
			"closePrice": 400000,
			"daysOnMovoto": 41,
			"id": "48acf9c7-e658-4f26-995c-6485b1178658",
			"tnImgPath": "https://pi.movoto.com/p/110/421602197_0_2aI3y3_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Christopher Chow",
			"listPrice": 418000,
			"lotSize": 1057906,
			"sqftTotal": 483,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421602197",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Pacific Land Company",
			"photoCount": 58,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94015",
			"path": "daly-city-ca/368-imperial-way-248-daly-city-ca-94015-100_80769238/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "64622611-0c9a-4348-8bb8-87d346c3232f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-08 05:16:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "https://368imperialwayunit24872761.f8re.com/Website/Index#VIDEOS",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.665707,
				"lng": -122.464106,
				"zipcode": "94015",
				"subPremise": "APT 248",
				"address": "368 Imperial Way #248"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://368imperialwayunit24872761.f8re.com/Website/Index#VIDEOS"
		},
		{
			"closePrice": 1380000,
			"daysOnMovoto": 13,
			"id": "d7ee6b15-7d79-4359-aba9-947663adc987",
			"tnImgPath": "https://pi.movoto.com/p/110/421590589_0_zAZyBR_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Lynn Aurelio",
			"listPrice": 1098000,
			"lotSize": 3234,
			"sqftTotal": 1790,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421590589",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Century 21 Baldini Realty",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1961,
			"zipCode": "94014",
			"path": "daly-city-ca/262-polaris-way-daly-city-ca-94014/pid_jhs8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "51fccdc7-dc75-442c-afe3-626630bb50dd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-11-04 18:56:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/642417856",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.708029,
				"lng": -122.437546,
				"zipcode": "94014",
				"subPremise": "",
				"address": "262 Polaris Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1380000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/642417856"
		},
		{
			"closePrice": 1220000,
			"daysOnMovoto": 10,
			"id": "e2900633-9c71-410d-a602-ce5125bf3c8f",
			"tnImgPath": "https://pi.movoto.com/p/110/421609754_0_7arrvj_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Tanya Dzhibrailova",
			"listPrice": 895000,
			"lotSize": 9450,
			"sqftTotal": 1030,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609754",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1947,
			"zipCode": "94015",
			"path": "daly-city-ca/1183-nimitz-dr-daly-city-ca-94015/pid_vq34w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c264cbb5-1852-4eb9-9950-fb6b1388519c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-11-05 18:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": "https://www.1183-nimitz.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.691907,
				"lng": -122.483674,
				"zipcode": "94015",
				"subPremise": "",
				"address": "1183 Nimitz Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.1183-nimitz.com/mls"
		},
		{
			"closePrice": 1295000,
			"daysOnMovoto": 16,
			"id": "f391bb84-8bfd-4f76-a720-aba3e878495b",
			"tnImgPath": "https://pi.movoto.com/p/110/421607630_0_FViinV_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Alex Dabit",
			"listPrice": 995000,
			"lotSize": 3300,
			"sqftTotal": 1630,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607630",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Park North Real Estate",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": null,
			"zipCode": "94015",
			"path": "daly-city-ca/262-shipley-ave-daly-city-ca-94015-110_421607630/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "330075a7-dea1-45b2-8dcc-4dd4b7b39046",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-29 18:41:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.653119,
				"lng": -122.467629,
				"zipcode": "94015",
				"subPremise": "",
				"address": "262 Shipley Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1295000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1370000,
			"daysOnMovoto": 20,
			"id": "73d3bc3d-fa28-4608-88c7-8a3c61a9e622",
			"tnImgPath": "https://pi.movoto.com/p/110/421606908_0_UV7A7N_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Christopher Chow",
			"listPrice": 998000,
			"lotSize": 3400,
			"sqftTotal": 1370,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421606908",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pacific Land Company",
			"photoCount": 58,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1950,
			"zipCode": "94015",
			"path": "daly-city-ca/144-n-mayfair-ave-daly-city-ca-94015-100_81122926/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c8ee8927-7988-43ae-af94-e0a58aa0012b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-30 01:21:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/640483448",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.704015,
				"lng": -122.477458,
				"zipcode": "94015",
				"subPremise": "",
				"address": "144 N Mayfair Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1370000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/640483448"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 7,
			"id": "65b9fa0e-2628-48fa-bd7e-04c1ec9a2cbf",
			"tnImgPath": "https://pi.movoto.com/p/110/421609511_0_yU62uj_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Raymond Ho",
			"listPrice": 1098000,
			"lotSize": 4653,
			"sqftTotal": 1580,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609511",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1959,
			"zipCode": "94015",
			"path": "daly-city-ca/642-higate-dr-daly-city-ca-94015-100_80810979/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6d4ab5db-1e40-4bc5-b362-cfeb8976ff9c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-11 00:00:00",
			"createdAt": "2021-11-04 18:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.675302,
				"lng": -122.488929,
				"zipcode": "94015",
				"subPremise": "",
				"address": "642 Higate Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 560000,
			"daysOnMovoto": 10,
			"id": "8d2e102a-9fc2-44d8-9a30-ad1f92e85613",
			"tnImgPath": "https://pi.movoto.com/p/110/421607246_0_eZZBZQ_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Janice Lee",
			"listPrice": 499000,
			"lotSize": 1057906,
			"sqftTotal": 717,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607246",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94015",
			"path": "daly-city-ca/377-mandarin-dr-213-daly-city-ca-94015-100_81447834/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ece415e9-ac02-48f2-bc18-731768069450",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-11 00:00:00",
			"createdAt": "2021-11-02 05:11:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": "http://www.JaniceLeeHomes.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.664999,
				"lng": -122.462358,
				"zipcode": "94015",
				"subPremise": "APT 213",
				"address": "377 Mandarin Dr #213"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.JaniceLeeHomes.com"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 10,
			"id": "2ac675bb-169e-48e4-9ee1-8060c6e0c8f8",
			"tnImgPath": "https://pi.movoto.com/p/110/421608815_0_yUvFuu_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Mei Huang",
			"listPrice": 1288000,
			"lotSize": 3600,
			"sqftTotal": 2200,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421608815",
			"bath": 5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "First National Realty",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1959,
			"zipCode": "94015",
			"path": "daly-city-ca/1385-southgate-ave-daly-city-ca-94015/pid_1aa5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b09cd14b-5c6b-45e1-9160-276e205cb474",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-11 00:00:00",
			"createdAt": "2021-11-01 22:11:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.676284,
				"lng": -122.480042,
				"zipcode": "94015",
				"subPremise": "",
				"address": "1385 Southgate Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 73,
			"id": "911cda42-241b-4705-a102-d9882425fa58",
			"tnImgPath": "https://pi.movoto.com/p/110/421588874_0_NyjQ6r_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Anna Gandamana",
			"listPrice": 1298000,
			"lotSize": 2884,
			"sqftTotal": 2464,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421588874",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty One Group Infinity",
			"photoCount": 52,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "94014",
			"path": "daly-city-ca/193-bellevue-ave-daly-city-ca-94014-100_80823050/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "52282fc4-f780-4115-bd60-d2b340c9a4e0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-08-27 21:41:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.7072,
				"lng": -122.443786,
				"zipcode": "94014",
				"subPremise": "",
				"address": "193 Bellevue Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 48,
			"id": "9753cbcd-c974-4025-bc55-020ce4c57401",
			"tnImgPath": "https://pi.movoto.com/p/12/40967672_0_MI6IrM_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "David Christie",
			"listPrice": 1050000,
			"lotSize": 3276,
			"sqftTotal": 1270,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967672",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94015",
			"path": "daly-city-ca/1395-southgate-ave-daly-city-ca-94015/pid_yaa5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "167c824d-18f1-41e0-898a-74eb1b6e0361",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-18 01:21:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://mls.ricohtours.com/e521c855-d5ba-41f4-90e8-6d287fbb3f11",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.676168,
				"lng": -122.479739,
				"zipcode": "94015",
				"subPremise": "",
				"address": "1395 Southgate Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mls.ricohtours.com/e521c855-d5ba-41f4-90e8-6d287fbb3f11"
		},
		{
			"closePrice": 830000,
			"daysOnMovoto": 62,
			"id": "b7309d47-2b30-43a9-aabf-fbfb18dd957d",
			"tnImgPath": "https://pi.movoto.com/p/110/421597306_0_evuevm_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Sherry Juneja",
			"listPrice": 830000,
			"lotSize": 126988,
			"sqftTotal": 1158,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421597306",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams San Francisco",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94014",
			"path": "daly-city-ca/631-pointe-pacific-dr-apt-9-daly-city-ca-94014-110_421597306/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -45000,
			"propertyId": "3401a1ab-9772-4b09-8437-c9258e61df99",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-22 22:11:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/612071402",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.700189,
				"lng": -122.453981,
				"zipcode": "94014",
				"subPremise": "APT 9",
				"address": "631 Pointe Pacific Dr #9"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/612071402"
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 66,
			"id": "f9d73a76-29f5-4f91-a760-1695476ffba1",
			"tnImgPath": "https://pi.movoto.com/p/12/40964920_0_rBar23_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Diana Martinez",
			"listPrice": 1299000,
			"lotSize": 2500,
			"sqftTotal": 1770,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964920",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty ONE Group Future",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1979,
			"zipCode": "94014",
			"path": "daly-city-ca/447-bonnie-st-daly-city-ca-94014/pid_5pz4w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "2a68313e-a667-4390-9da5-53257ee28bc9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-08-27 17:01:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=6FRyucQZ9KC",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.695386,
				"lng": -122.456647,
				"zipcode": "94014",
				"subPremise": "",
				"address": "447 Bonnie St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=6FRyucQZ9KC"
		},
		{
			"closePrice": 1240000,
			"daysOnMovoto": 112,
			"id": "d31091c2-8ae0-445b-8cea-656996a48c23",
			"tnImgPath": "https://pi.movoto.com/p/110/421594625_0_NIbI6Y_p.jpeg",
			"listDate": "2021-07-12 00:00:00",
			"listingAgent": "Winnie Fok",
			"listPrice": 1286000,
			"lotSize": 6327,
			"sqftTotal": 1440,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421594625",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1946,
			"zipCode": "94015",
			"path": "daly-city-ca/washington-st-daly-city-ca-94015-100_80231734/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 157000,
			"propertyId": "5ec3f56e-1087-4410-ad5b-d878bfaec7af",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-09-16 16:36:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.690549,
				"lng": -122.476519,
				"zipcode": "94015",
				"subPremise": "",
				"address": "832 Washington St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1240000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 882500,
			"daysOnMovoto": 144,
			"id": "2ea4addc-edb0-47e0-bfe1-3f733645b0de",
			"tnImgPath": "https://pi.movoto.com/p/110/421570792_0_iEFBJV_p.jpeg",
			"listDate": "2021-06-10 00:00:00",
			"listingAgent": "Tara Donohue",
			"listPrice": 882500,
			"lotSize": 126988,
			"sqftTotal": 1394,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421570792",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94014",
			"path": "daly-city-ca/691-pointe-pacific-dr-apt-4701-daly-city-ca-94014-110_421570792/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -450,
			"propertyId": "f710b4fc-9402-48a5-8ba4-f2fdbf0eab06",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-07-09 18:11:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.700189,
				"lng": -122.453981,
				"zipcode": "94014",
				"subPremise": "APT 4701",
				"address": "691 Pointe Pacific Dr #4701"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 882500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 33,
			"id": "a6f53a80-1730-4cdd-8232-1b1795e4fce4",
			"tnImgPath": "https://pi.movoto.com/p/110/421603762_0_YAZnUI_p.jpeg",
			"listDate": "2021-09-26 00:00:00",
			"listingAgent": "Marc Dickow",
			"listPrice": 769000,
			"lotSize": 189901,
			"sqftTotal": 1158,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421603762",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Core7 Real Estate",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94014",
			"path": "daly-city-ca/483-green-ridge-dr-9-daly-city-ca-94014/pid_6zb9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "723e920a-ce7a-418b-bf9e-97cca6ed06f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-10-14 21:21:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-26 00:00:00",
			"virtualTourLink": "http://www.mpowersellsgreenridge.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.69946,
				"lng": -122.456558,
				"zipcode": "94014",
				"subPremise": "APT 9",
				"address": "483 Green Ridge Dr #9"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.mpowersellsgreenridge.com"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 45,
			"id": "659e4011-0cd8-4ab0-89bf-08c3e8fb666f",
			"tnImgPath": "https://pi.movoto.com/p/12/40966916_0_RNVn76_p.jpeg",
			"listDate": "2021-09-13 00:00:00",
			"listingAgent": "Alice Chan",
			"listPrice": 988000,
			"lotSize": 2500,
			"sqftTotal": 1942,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966916",
			"bath": 3,
			"bed": 7,
			"openHouses": [],
			"officeListName": "New Horizon Real Estate",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1943,
			"zipCode": "94014",
			"path": "daly-city-ca/406-oriente-st-daly-city-ca-94014/pid_mr14w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "89a60d0f-d5e5-489f-9e28-0cb6bc633aa1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-13 19:36:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.701294,
				"lng": -122.416741,
				"zipcode": "94014",
				"subPremise": "",
				"address": "406 Oriente St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1265000,
			"daysOnMovoto": 95,
			"id": "40ca501d-6d0a-4ae0-9ad8-f6a3692c02a7",
			"tnImgPath": "https://pi.movoto.com/p/110/421605829_0_yAUjNy_p.jpeg",
			"listDate": "2021-07-24 00:00:00",
			"listingAgent": "Gary Snow",
			"listPrice": 1195000,
			"lotSize": 3570,
			"sqftTotal": 1670,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421605829",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass SF",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94015",
			"path": "daly-city-ca/52-cliffside-dr-daly-city-ca-94015/pid_1zc5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6f508971-33de-480a-bb8f-79b637ec3788",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-10-21 03:11:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=rqVEYcUHhMZ&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.705064,
				"lng": -122.482436,
				"zipcode": "94015",
				"subPremise": "",
				"address": "52 Cliffside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1265000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=rqVEYcUHhMZ&mls=1"
		},
		{
			"closePrice": 1138000,
			"daysOnMovoto": 5,
			"id": "9c32730e-380b-4ea6-b9a5-19e169237025",
			"tnImgPath": "https://pi.movoto.com/p/110/421605933_0_ffinvz_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Courtney Jung",
			"listPrice": 1095000,
			"lotSize": 851,
			"sqftTotal": 1538,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421605933",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "City Real Estate",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2017,
			"zipCode": "94014",
			"path": "daly-city-ca/21-plumeria-ct-daly-city-ca-94014-110_421605933/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "66008678-9422-498d-897d-12e70c53ade7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-10-22 04:26:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.687291,
				"lng": -122.463023,
				"zipcode": "94014",
				"subPremise": "",
				"address": "21 Plumeria Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1138000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 41,
			"id": "0dd806e2-b690-4b69-9169-e1399d2e9cf5",
			"tnImgPath": "https://pi.movoto.com/p/12/40967258_0_nBqUvY_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Doris Mitchell-Trice",
			"listPrice": 869000,
			"lotSize": 5000,
			"sqftTotal": 1350,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967258",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "DMW Realty",
			"photoCount": 8,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1917,
			"zipCode": "94014",
			"path": "daly-city-ca/626-templeton-ave-daly-city-ca-94014/pid_no04w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3feb03ed-bdfb-49b8-a415-68ac2701035a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-16 00:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.70462,
				"lng": -122.451213,
				"zipcode": "94014",
				"subPremise": "",
				"address": "626 Templeton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1725000,
			"daysOnMovoto": 0,
			"id": "a88cc00d-bbf0-43d5-a1bf-75d97c688b9d",
			"tnImgPath": "https://pi.movoto.com/p/102/421583136_0_V6QFJQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Emad Basma",
			"listPrice": 1790000,
			"lotSize": 3610,
			"sqftTotal": 2600,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421583136",
			"bath": 3,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway HomeServices-Drysdale Properties",
			"photoCount": 45,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1962,
			"zipCode": "94015",
			"path": "daly-city-ca/44-westpark-dr-daly-city-ca-94015/pid_e3z5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 611000,
			"propertyId": "ed48df0e-aa64-4d03-96a7-011b6569f834",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-08-11 21:19:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-11 21:19:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.707266,
				"lng": -122.485443,
				"zipcode": "94015",
				"subPremise": "",
				"address": "44 Westpark Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 46,
			"id": "36959cc9-ccfa-4519-ae42-028d1b05155e",
			"tnImgPath": "https://pi.movoto.com/p/12/40966009_0_7vrerM_p.jpeg",
			"listDate": "2021-09-06 00:00:00",
			"listingAgent": "Jimmy Gaw",
			"listPrice": 1299000,
			"lotSize": 3927,
			"sqftTotal": 1240,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966009",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Noble House Of California R.E.",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94015",
			"path": "daly-city-ca/545-verducci-dr-daly-city-ca-94015/pid_hm38w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c6685b14-e9cb-48c1-be32-dcd072e3bcbf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-21 23:00:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.651251,
				"lng": -122.461165,
				"zipcode": "94015",
				"subPremise": "",
				"address": "545 Verducci Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1258473,
			"daysOnMovoto": 14,
			"id": "5e68c110-c949-4936-aadb-517b44f3910d",
			"tnImgPath": "https://pi.movoto.com/p/110/421601589_0_VjrZmf_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Sunny Lau",
			"listPrice": 1188000,
			"lotSize": 2500,
			"sqftTotal": 2300,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421601589",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Century 21 Realty Alliance Fine Homes and Estates",
			"photoCount": 32,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1925,
			"zipCode": "94014",
			"path": "daly-city-ca/24-como-ave-daly-city-ca-94014/pid_cmb6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50dd715e-f83d-414d-84c1-9452e254e25b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-10-06 19:56:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.699959,
				"lng": -122.463162,
				"zipcode": "94014",
				"subPremise": "",
				"address": "24 Como Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1258473,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1180000,
			"daysOnMovoto": 13,
			"id": "55c74966-a8c1-4459-9bb2-fb08efd45315",
			"tnImgPath": "https://pi.movoto.com/p/110/421602097_0_eQn2RE_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Teresina Pak",
			"listPrice": 999000,
			"lotSize": 3400,
			"sqftTotal": 1150,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421602097",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berri Real Estate",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1958,
			"zipCode": "94015",
			"path": "daly-city-ca/59-wavecrest-dr-daly-city-ca-94015/pid_9284w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fbc99096-4c6e-4898-9274-3ee5c44c3d9a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-10-07 22:11:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Daly City",
				"lat": 37.6766,
				"lng": -122.491366,
				"zipcode": "94015",
				"subPremise": "",
				"address": "59 Wavecrest Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]