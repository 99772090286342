exports.SANTA_CLARA_LISTINGS = [
		{
			"closePrice": 2400000,
			"daysOnMovoto": 19,
			"id": "90956672-72ec-4dae-81a0-ed4d13f9a765",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872640_0_Y27MQN_p.jpeg",
			"listDate": "2021-12-16 08:25:19",
			"listingAgent": "Lauson Fargher",
			"listPrice": 1888000,
			"lotSize": 7680,
			"sqftTotal": 1947,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872640",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realcom Associates",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1947,
			"zipCode": "95051",
			"path": "santa-clara-ca/2952-fresno-st-santa-clara-ca-95051-100_ml81872640/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b8749d8e-c367-475f-b27d-04e9f5b17bda",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-12-16 16:27:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-16 08:25:19",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2940035",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.349724,
				"lng": -121.979761,
				"zipcode": "95051",
				"subPremise": "",
				"address": "2952 Fresno St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2940035"
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 24,
			"id": "f6f15693-e2cb-4323-93f7-ecf7cbf144ea",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872361_0_inq7BM_p.jpeg",
			"listDate": "2021-12-11 11:05:56",
			"listingAgent": "Steven Larson",
			"listPrice": 1395000,
			"lotSize": 6639,
			"sqftTotal": 1476,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872361",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Westside Real Estate Services",
			"photoCount": 18,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "95051",
			"path": "santa-clara-ca/1157-white-dr-santa-clara-ca-95051/pid_gicz2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e3ad36b7-9b16-46a1-8b6e-fc8506a71b70",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-12-11 19:12:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-11 11:05:56",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.347384,
				"lng": -121.972439,
				"zipcode": "95051",
				"subPremise": "",
				"address": "1157 White Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 2676800,
			"daysOnMovoto": 0,
			"id": "ce2ea8bf-6903-4ec4-98e7-7335fa12946d",
			"tnImgPath": "https://pi.movoto.com/p/102/221149709_0_Jar3Fj_p.jpeg",
			"listDate": null,
			"listingAgent": "Breanna Akers",
			"listPrice": 2450000,
			"lotSize": 7065,
			"sqftTotal": 3130,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221149709",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 78,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "95051",
			"path": "santa-clara-ca/3016-forbes-ave-santa-clara-ca-95051-100_80370844/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "990dad46-d39f-4dfe-af60-aa623d0e303b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-12-02 22:49:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 22:49:24",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.333248,
				"lng": -121.983523,
				"zipcode": "95051",
				"subPremise": "",
				"address": "3016 Forbes Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2676800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2330000,
			"daysOnMovoto": 40,
			"id": "2904f736-35c8-403b-8baf-f00a38e2da62",
			"tnImgPath": "https://pi.movoto.com/p/12/40974588_0_BQr6ye_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Flavio Amaral",
			"listPrice": 1998000,
			"lotSize": 5777,
			"sqftTotal": 2225,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974588",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "95050",
			"path": "santa-clara-ca/2344-benton-st-santa-clara-ca-95050/pid_rwfq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "463c3490-0836-4e6d-b35a-d89bff3039dd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-19 00:36:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": "https://4-phase-photographic.seehouseat.com/1933847?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.346078,
				"lng": -121.966107,
				"zipcode": "95050",
				"subPremise": "",
				"address": "2344 Benton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2330000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://4-phase-photographic.seehouseat.com/1933847?idx=1"
		},
		{
			"closePrice": 600000,
			"daysOnMovoto": 0,
			"id": "1c1438b7-5a9a-4325-9755-a71e9f26660a",
			"tnImgPath": "https://pi.movoto.com/p/102/221122788_0_a7mizI_p.jpeg",
			"listDate": null,
			"listingAgent": "Johanne Reno",
			"listPrice": 644900,
			"lotSize": 1176,
			"sqftTotal": 1028,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221122788",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 16,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1969,
			"zipCode": "95051",
			"path": "santa-clara-ca/150-saratoga-ave-328-santa-clara-ca-95051-100_80203234/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "b3516969-dab8-4148-8a43-5b00455fa7e2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-18 23:09:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 23:09:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.327074,
				"lng": -121.969579,
				"zipcode": "95051",
				"subPremise": "APT 328",
				"address": "150 Saratoga Ave #328"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 720000,
			"daysOnMovoto": 38,
			"id": "ff0611a8-c5b5-474f-a9e1-d422db2a2382",
			"tnImgPath": "https://pi.movoto.com/p/110/421612161_0_2J3aeI_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "Teresita Nubla",
			"listPrice": 698000,
			"lotSize": 970,
			"sqftTotal": 984,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421612161",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Statewide Realty",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95051",
			"path": "santa-clara-ca/2580-homestead-rd-1201-santa-clara-ca-95051/pid_dblq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e800644f-a385-4f29-9191-e6b210877a7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-16 01:11:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.339775,
				"lng": -121.971528,
				"zipcode": "95051",
				"subPremise": "APT 1201",
				"address": "2580 Homestead Rd #1201"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 445000,
			"daysOnMovoto": 100,
			"id": "b092f595-7641-45b8-894e-34bb8f81b0ba",
			"tnImgPath": "https://pi.movoto.com/p/12/40967033_0_VZ7UF2_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Hui Chen",
			"listPrice": 459000,
			"lotSize": 871,
			"sqftTotal": 653,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967033",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "TransGlobal Realty",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "95050",
			"path": "santa-clara-ca/1700-civic-center-dr-513-santa-clara-ca-95050-100_81104770/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -16000,
			"propertyId": "f9e4adc6-075d-41a6-89f6-d99127182d60",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-09-14 17:41:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.355452,
				"lng": -121.957686,
				"zipcode": "95050",
				"subPremise": "APT 513",
				"address": "1700 Civic Center Dr #513"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 445000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 52,
			"id": "d0334a54-2748-45fc-9a2b-c2fba486cba2",
			"tnImgPath": "https://pi.movoto.com/p/12/40972766_0_A36Z7E_p.jpeg",
			"listDate": "2021-10-30 00:00:00",
			"listingAgent": "Daryll Canlas",
			"listPrice": 1149000,
			"lotSize": 4320,
			"sqftTotal": 1273,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972766",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Canlas Brothers, Inc",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "95050",
			"path": "santa-clara-ca/1992-monroe-st-santa-clara-ca-95050/pid_fzcp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6a7d4f41-b755-408b-a4b4-98dc86e426dc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-10-30 08:21:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.357923,
				"lng": -121.953643,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1992 Monroe St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 57,
			"id": "38283f4a-b249-4953-8fe0-d9fb1a6d9324",
			"tnImgPath": "https://pi.movoto.com/p/202/MC21235524_0_z6AJvn_p.jpeg",
			"listDate": "2021-10-25 00:00:00",
			"listingAgent": "Candace Bartholomew",
			"listPrice": 1300000,
			"lotSize": 6000,
			"sqftTotal": 1095,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "MC21235524",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Property Team",
			"photoCount": 4,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 1960,
			"zipCode": "95054",
			"path": "santa-clara-ca/armour-dr-santa-clara-ca-95054-100_80104519/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "135a8e1f-db5c-47d8-83a8-82e551b8ee45",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-10-26 14:52:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.401848,
				"lng": -121.959732,
				"zipcode": "95054",
				"subPremise": "",
				"address": "4715 Armour Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1715000,
			"daysOnMovoto": 0,
			"id": "3c891659-4fa7-4514-800a-690cbcc41d30",
			"tnImgPath": "https://pi.movoto.com/p/102/221123245_0_6niIin_p.jpeg",
			"listDate": null,
			"listingAgent": "Clara Rugnao",
			"listPrice": 1600000,
			"lotSize": 6303,
			"sqftTotal": 2147,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221123245",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1959,
			"zipCode": "95051",
			"path": "santa-clara-ca/2673-cabrillo-ave-santa-clara-ca-95051-100_937846/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -288000,
			"propertyId": "18b1d2c1-b7b6-40e3-92f4-bcb5fce0e4f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-09-27 22:14:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 22:14:25",
			"virtualTourLink": "https://www.aryeo.com/v2/2673-cabrillo-ave-santa-clara-ca-95051-1222809/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.360565,
				"lng": -121.974821,
				"zipcode": "95051",
				"subPremise": "",
				"address": "2673 Cabrillo Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1715000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.aryeo.com/v2/2673-cabrillo-ave-santa-clara-ca-95051-1222809/unbranded"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 16,
			"id": "7a4d1249-24e5-4c5e-9dde-b66e7a886b37",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871264_0_rI7I3A_p.jpeg",
			"listDate": "2021-11-30 10:09:48",
			"listingAgent": "John Faylor",
			"listPrice": 1499000,
			"lotSize": 7858,
			"sqftTotal": 1888,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871264",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 10,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "95050",
			"path": "santa-clara-ca/1185-manchester-dr-santa-clara-ca-95050/pid_8j3p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3f5f46d3-a676-4f7c-a566-d6762680adee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-30 18:12:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 10:09:48",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.34104,
				"lng": -121.941864,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1185 Manchester Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 29,
			"id": "1aee4c61-baf1-4175-b025-2cc9c27106e8",
			"tnImgPath": "https://pi.movoto.com/p/12/40974530_0_jZqrUq_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Clay Allen",
			"listPrice": 1350000,
			"lotSize": 5700,
			"sqftTotal": 1075,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974530",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Clay Allen Real Estate",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "95050",
			"path": "santa-clara-ca/2641-newhall-st-santa-clara-ca-95050/pid_8k2p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "849ee9ba-8eab-4f44-b57e-f385ce418e4b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-18 18:26:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=k8z4yxCfrq7&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.338032,
				"lng": -121.95271,
				"zipcode": "95050",
				"subPremise": "",
				"address": "2641 Newhall St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=k8z4yxCfrq7&mls=1"
		},
		{
			"closePrice": 1520000,
			"daysOnMovoto": 47,
			"id": "c4e296ad-e535-4a71-a93f-c96130873dba",
			"tnImgPath": "https://pi.movoto.com/p/12/40972636_0_NNV33j_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Ted Chen",
			"listPrice": 1258000,
			"lotSize": 1920,
			"sqftTotal": 1602,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972636",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2000,
			"zipCode": "95054",
			"path": "santa-clara-ca/4566-cheeney-st-santa-clara-ca-95054/pid_g0jn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e5c5eeb2-2c8e-4654-8acd-3fcfe033bf3e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-29 17:06:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=W7ao8BLtjXn&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.397575,
				"lng": -121.963864,
				"zipcode": "95054",
				"subPremise": "",
				"address": "4566 Cheeney St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1520000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=W7ao8BLtjXn&mls=1"
		},
		{
			"closePrice": 440088,
			"daysOnMovoto": 0,
			"id": "7f3114da-9462-4c58-8d50-7692846ca34f",
			"tnImgPath": "https://pi.movoto.com/p/102/221136911_0_ZIjFJ3_p.jpeg",
			"listDate": null,
			"listingAgent": "Betty Morris-Laub",
			"listPrice": 435800,
			"lotSize": 706,
			"sqftTotal": 678,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136911",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Fathom Realty Group, Inc.",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1970,
			"zipCode": "95051",
			"path": "santa-clara-ca/1730-halford-ave-347-santa-clara-ca-95051-100_80930828/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7f6593c2-be2e-4469-a8b4-d1a7b72d0879",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-30 21:14:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-30 21:14:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.355101,
				"lng": -121.999987,
				"zipcode": "95051",
				"subPremise": "APT 347",
				"address": "1730 Halford Ave #347"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 440088,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1708000,
			"daysOnMovoto": 46,
			"id": "e697b3b4-3fd2-4e0d-8ddd-579f4b7d3836",
			"tnImgPath": "https://pi.movoto.com/p/12/40972135_0_Jm7vm2_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Pam Munoz",
			"listPrice": 1708000,
			"lotSize": 1400,
			"sqftTotal": 1861,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972135",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KB Home",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/2785-ball-pl-apt-5-santa-clara-ca-95051-2001_j7j1ds3i9s/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "58c973c0-e8c1-4486-abdc-49c40add4d03",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-23 21:11:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.350415,
				"lng": -121.976529,
				"zipcode": "95051",
				"subPremise": " 5",
				"address": "2785 Ball Pl # 5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1708000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 93,
			"id": "c19a9a48-4120-4861-88ef-423316a4bdf8",
			"tnImgPath": "https://pi.movoto.com/p/12/40966012_0_7IeUAA_p.jpeg",
			"listDate": "2021-09-06 00:00:00",
			"listingAgent": "Pam Munoz",
			"listPrice": 1305000,
			"lotSize": 1400,
			"sqftTotal": 1209,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966012",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "KB Home",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/1475-kiely-blvd-2-santa-clara-ca-95051-12_40966012/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "29ca4e97-eb87-49f8-9315-475d72a9d9a9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-09-06 22:16:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.350999,
				"lng": -121.977656,
				"zipcode": "95051",
				"subPremise": " 2",
				"address": "1475 Kiely Blvd # 2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 41,
			"id": "c8671fd4-ebc2-4c1a-9815-ddf33ff53c45",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868180_0_yN2EMB_p.jpeg",
			"listDate": "2021-10-26 19:57:37",
			"listingAgent": "Joseph Portale",
			"listPrice": 1350000,
			"lotSize": 7924,
			"sqftTotal": 1478,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868180",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1948,
			"zipCode": "95050",
			"path": "santa-clara-ca/679-monroe-st-santa-clara-ca-95050/pid_we2y2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f23661b3-ebac-43bb-9e85-6605215b813f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-27 03:02:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 19:57:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.345445,
				"lng": -121.945971,
				"zipcode": "95050",
				"subPremise": "",
				"address": "679 Monroe St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2839000,
			"daysOnMovoto": 36,
			"id": "e0933623-e45a-432a-a7d5-5da682126d55",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868207_0_uQy6ZN_p.jpeg",
			"listDate": "2021-10-27 06:06:05",
			"listingAgent": "Suzhi Wang",
			"listPrice": 2198800,
			"lotSize": 5741,
			"sqftTotal": 2599,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868207",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Goodview Financial & Real Estate",
			"photoCount": 68,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2019,
			"zipCode": "95051",
			"path": "santa-clara-ca/723-salberg-ave-santa-clara-ca-95051/pid_crkq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cb8a0a67-bc5e-4bac-a140-1d160a81dfae",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-27 13:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 06:06:05",
			"virtualTourLink": "https://www.youtube.com/embed/3xAg0OAfT5c",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.33738,
				"lng": -121.971795,
				"zipcode": "95051",
				"subPremise": "",
				"address": "723 Salberg Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2839000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/3xAg0OAfT5c"
		},
		{
			"closePrice": 1363000,
			"daysOnMovoto": 38,
			"id": "d12d4338-808e-4091-8f34-9f9bced66bb1",
			"tnImgPath": "https://pi.movoto.com/p/12/40971666_0_j6BVeB_p.jpeg",
			"listDate": "2021-10-26 00:00:00",
			"listingAgent": "Aimee Ran Song",
			"listPrice": 1149800,
			"lotSize": 1750,
			"sqftTotal": 1708,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971666",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1981,
			"zipCode": "95050",
			"path": "santa-clara-ca/2088-eucalyptus-ct-santa-clara-ca-95050-100_80708370/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9e665f24-1328-429b-9ca2-7acecfd35d5a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-26 16:56:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=BA1T4zfqi17&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.35983,
				"lng": -121.963325,
				"zipcode": "95050",
				"subPremise": "",
				"address": "2088 Eucalyptus Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1363000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=BA1T4zfqi17&brand=0"
		},
		{
			"closePrice": 1425000,
			"daysOnMovoto": 50,
			"id": "ef4b0d62-ef46-4eb2-955c-60f5a7913d02",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866561_0_QunN3b_p.jpeg",
			"listDate": "2021-10-13 08:36:12",
			"listingAgent": "Erin Benford",
			"listPrice": 1498000,
			"lotSize": 5550,
			"sqftTotal": 2037,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866561",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 20,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1956,
			"zipCode": "95050",
			"path": "santa-clara-ca/1473-1471-harrison-st-santa-clara-ca-95050-100_ml81866561/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bbf59cf2-1078-4e16-b916-404abc2ae0c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-13 15:42:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 08:36:12",
			"virtualTourLink": "https://www.tourfactory.com/2926234",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.350908,
				"lng": -121.951681,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1473-1471 Harrison St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2926234"
		},
		{
			"closePrice": 1152000,
			"daysOnMovoto": 0,
			"id": "bb254319-cfa7-4366-bddc-86e96747cbbe",
			"tnImgPath": "https://pi.movoto.com/p/102/221128142_0_jVjQzm_p.jpeg",
			"listDate": null,
			"listingAgent": "Yuying Holick",
			"listPrice": 999000,
			"lotSize": 880,
			"sqftTotal": 1338,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221128142",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty One Group Complete",
			"photoCount": 54,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2007,
			"zipCode": "95050",
			"path": "santa-clara-ca/1475-isabella-st-santa-clara-ca-95050/pid_ee0p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5cd6583-5002-4622-bd6c-d99bee3da663",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-14 07:14:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 07:14:13",
			"virtualTourLink": "https://my.matterport.com/show/?m=TVoz8ohVS59",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.351335,
				"lng": -121.955066,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1475 Isabella St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1152000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=TVoz8ohVS59"
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 40,
			"id": "31c77b18-90af-40d4-b9c5-aaf49aaf8043",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867427_0_7mMfEa_p.jpeg",
			"listDate": "2021-10-20 09:28:46",
			"listingAgent": "John Forsyth James",
			"listPrice": 1498000,
			"lotSize": 6991,
			"sqftTotal": 2450,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867427",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 34,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1957,
			"zipCode": "95051",
			"path": "santa-clara-ca/35243526-bennett-ave-santa-clara-ca-95051-100_ml81867427/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "37d647b1-dd91-4e91-a662-e9750ae41202",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-20 16:32:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 09:28:46",
			"virtualTourLink": "https://player.vimeo.com/video/633226009",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"zipcode": "95051",
				"subPremise": "",
				"address": "35243526 Bennett Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/633226009"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 39,
			"id": "2ff93574-6315-4c71-8f10-cef05ad12725",
			"tnImgPath": "https://pi.movoto.com/p/202/OC21227344_0_mZYvaN_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Clifford Burdett",
			"listPrice": 1250000,
			"lotSize": 6395,
			"sqftTotal": 936,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "OC21227344",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Clifford Robert Dirk Burdett",
			"photoCount": 2,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 1947,
			"zipCode": "95050",
			"path": "santa-clara-ca/656-scott-blvd-santa-clara-ca-95050/pid_w9iq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "616f7177-4de3-4f70-acf4-271ac185875a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-18 01:12:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.340528,
				"lng": -121.958398,
				"zipcode": "95050",
				"subPremise": "",
				"address": "656 Scott Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 42,
			"id": "bad6dcdc-ae4e-4537-8257-b000713f42e6",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Tony Kang",
			"listPrice": 1688000,
			"lotSize": 6900,
			"sqftTotal": 2383,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970210",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "95050",
			"path": "santa-clara-ca/1470-los-padres-blvd-santa-clara-ca-95050-100_80702631/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8818026c-902f-4f5d-a25d-a1fb99c4db57",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-08 07:16:19",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.350828,
				"lng": -121.965241,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1470 Los Padres Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 48,
			"id": "2ef358f6-5778-4790-9bc5-88d3a08bda71",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81864810_0_U23Vvy_p.jpeg",
			"listDate": "2021-09-30 07:01:53",
			"listingAgent": "Douglas Goss",
			"listPrice": 1150000,
			"lotSize": 7915,
			"sqftTotal": 1762,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81864810",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "95051",
			"path": "santa-clara-ca/908-live-oak-dr-santa-clara-ca-95051/pid_ba8y2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cc3a22c9-8090-437d-8083-bf36fdad5544",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-09-30 14:07:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 07:01:53",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.341572,
				"lng": -121.985615,
				"zipcode": "95051",
				"subPremise": "",
				"address": "908 Live Oak Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1675000,
			"daysOnMovoto": 63,
			"id": "3a51ec3a-0195-4b2b-a322-130b9eeae0cb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862516_0_3Z2Nyi_p.jpeg",
			"listDate": "2021-09-13 17:15:39",
			"listingAgent": "Dakota Dillon",
			"listPrice": 1498000,
			"lotSize": 8634,
			"sqftTotal": 2614,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862516",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Dakota Dillon Real Estate",
			"photoCount": 2,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1959,
			"zipCode": "95050",
			"path": "santa-clara-ca/2453-anna-dr-santa-clara-ca-95050/pid_msgq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dd3eba67-fbf8-4872-b5e4-ea4d39c3929e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-09-14 00:22:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 17:15:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.351421,
				"lng": -121.966263,
				"zipcode": "95050",
				"subPremise": "",
				"address": "2453 Anna Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 0,
			"id": "48fb7eaa-85e6-4f0d-ae05-5229bf4dafee",
			"tnImgPath": "https://pi.movoto.com/p/102/221123755_0_3by3uU_p.jpeg",
			"listDate": null,
			"listingAgent": "Richard Calhoun",
			"listPrice": 1500000,
			"lotSize": 5870,
			"sqftTotal": 1162,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221123755",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Creekside Realty",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1950,
			"zipCode": "95050",
			"path": "santa-clara-ca/665-armanini-ave-santa-clara-ca-95050/pid_xhkq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "38d57522-fec3-4ff5-a00d-fc5d56d254e8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-10-15 22:04:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 22:04:24",
			"virtualTourLink": "https://player.vimeo.com/video/632515473",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.339457,
				"lng": -121.961398,
				"zipcode": "95050",
				"subPremise": "",
				"address": "665 Armanini Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/632515473"
		},
		{
			"closePrice": 1655000,
			"daysOnMovoto": 36,
			"id": "a8d3db1d-c518-4d5c-ad78-d0c1f31904e9",
			"tnImgPath": "https://pi.movoto.com/p/12/40970172_0_b7b6ZV_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Sanjiv Nayyar",
			"listPrice": 1499999,
			"lotSize": 5000,
			"sqftTotal": 1336,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970172",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "95050",
			"path": "santa-clara-ca/729-robin-dr-santa-clara-ca-95050/pid_cdlq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fe66c601-9937-439c-9e3b-78a024e97be3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-08 04:26:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=SAexgbxW2Gz&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.341149,
				"lng": -121.961331,
				"zipcode": "95050",
				"subPremise": "",
				"address": "729 Robin Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1655000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=SAexgbxW2Gz&mls=1"
		},
		{
			"closePrice": 1488000,
			"daysOnMovoto": 36,
			"id": "584c34fa-d274-4d91-ad9d-b51602a2e0c7",
			"tnImgPath": "https://pi.movoto.com/p/12/40969972_0_zUFUnZ_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Liping Zhang",
			"listPrice": 1298000,
			"lotSize": 5500,
			"sqftTotal": 1095,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969972",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "95051",
			"path": "santa-clara-ca/2911-monroe-st-santa-clara-ca-95051/pid_sj6o2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "20758e51-e883-4f41-be9f-8bfffc4ad3da",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-11 00:00:00",
			"createdAt": "2021-10-07 05:41:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.363547,
				"lng": -121.979436,
				"zipcode": "95051",
				"subPremise": "",
				"address": "2911 Monroe St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1488000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 545000,
			"daysOnMovoto": 59,
			"id": "a9d3d16d-a941-46a7-b6c5-1513b42c4cbe",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861981_0_6yUzVZ_p.jpeg",
			"listDate": "2021-09-09 15:10:22",
			"listingAgent": "Jason Belcher",
			"listPrice": 554888,
			"lotSize": null,
			"sqftTotal": 716,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861981",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Golden Realty & Investments, Inc",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "95050",
			"path": "santa-clara-ca/2250-monroe-st-386-santa-clara-ca-95050/pid_7scp2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "29b56ae2-0689-4ec3-b3e5-6a7ea8d1ee3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-09-09 22:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 15:10:22",
			"virtualTourLink": "https://www.tourfactory.com/2913303",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.362009,
				"lng": -121.963789,
				"zipcode": "95050",
				"subPremise": "APT 386",
				"address": "2250 Monroe St #386"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 545000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2913303"
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 0,
			"id": "d9784631-7d24-4cce-a084-f5475c9b3d66",
			"tnImgPath": "https://pi.movoto.com/p/102/221116177_0_AryfyZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Minerick",
			"listPrice": 1600000,
			"lotSize": 9757,
			"sqftTotal": 1450,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221116177",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "HomeCoin.com",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "95050",
			"path": "santa-clara-ca/237-n-henry-ave-santa-clara-ca-95050/pid_xxrq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "80f861e0-13c2-4271-b584-59ccfd73018f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-17 22:49:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 22:49:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.324603,
				"lng": -121.95551,
				"zipcode": "95050",
				"subPremise": "",
				"address": "237 N Henry Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1558000,
			"daysOnMovoto": 0,
			"id": "19d0b130-b8c1-4d71-b2ad-446d91ddeb79",
			"tnImgPath": "https://pi.movoto.com/p/102/221124998_0_yqbazU_p.jpeg",
			"listDate": null,
			"listingAgent": "Gregory Dias",
			"listPrice": 1399000,
			"lotSize": 5711,
			"sqftTotal": 1095,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221124998",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty One Group Complete",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "95051",
			"path": "santa-clara-ca/2040-brown-ct-santa-clara-ca-95051/pid_j56o2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9433a8d9-fc27-45c5-ae0b-b9b382677bda",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-30 16:09:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 16:09:35",
			"virtualTourLink": "https://app.cloudpano.com/tours/2TxFtWQ6g",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.359834,
				"lng": -121.978548,
				"zipcode": "95051",
				"subPremise": "",
				"address": "2040 Brown Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1558000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://app.cloudpano.com/tours/2TxFtWQ6g"
		},
		{
			"closePrice": 715000,
			"daysOnMovoto": 0,
			"id": "9105a85d-1957-4071-82d5-388dd08bfa73",
			"tnImgPath": "https://pi.movoto.com/p/102/221065565_0_AZZeAQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Virginia Gaskins",
			"listPrice": 728000,
			"lotSize": 967,
			"sqftTotal": 1062,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221065565",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Mike Guttridge Realty",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "95051",
			"path": "santa-clara-ca/1400-bowe-ave-1906-santa-clara-ca-95051/pid_zsiq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -57000,
			"propertyId": "1ef4c24e-ae27-4d39-afac-32c57dc88343",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-06-10 23:34:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 23:34:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.348987,
				"lng": -121.975137,
				"zipcode": "95051",
				"subPremise": "APT 1906",
				"address": "1400 Bowe Ave #1906"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 715000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1476000,
			"daysOnMovoto": 39,
			"id": "679ca43e-25fa-47bd-8c27-eafbac4b1959",
			"tnImgPath": "https://pi.movoto.com/p/12/40967503_0_yBzV3V_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Deepa Dulepet",
			"listPrice": 1249999,
			"lotSize": 7500,
			"sqftTotal": 1568,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967503",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "95054",
			"path": "santa-clara-ca/871-clyde-ave-santa-clara-ca-95054-100_81014386/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eb797ea7-64d8-43a9-8b5f-2d12d042dcd5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-18 03:46:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.391127,
				"lng": -121.949866,
				"zipcode": "95054",
				"subPremise": "",
				"address": "871 Clyde Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1476000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1134095,
			"daysOnMovoto": 167,
			"id": "fe1f74de-974b-4dd4-998a-a21a5dfab88f",
			"tnImgPath": "https://pi.movoto.com/p/12/40949106_0_i6QNNy_p.jpeg",
			"listDate": "2021-05-08 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1134095,
			"lotSize": 0,
			"sqftTotal": 1427,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40949106",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-417-santa-clara-ca-95051-12_40944184/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "be016f16-6d1d-4d43-93af-6f45cf3e2661",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-05-08 20:21:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 417",
				"address": "3578 Rambla Pl #417"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1134095,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1299000,
			"daysOnMovoto": 0,
			"id": "6d0b0409-ebd3-4937-9d36-fe43044ddd8d",
			"tnImgPath": "https://pi.movoto.com/p/102/221119124_0_M6EUQu_p.jpeg",
			"listDate": null,
			"listingAgent": "Mario Rodriguez",
			"listPrice": 1299000,
			"lotSize": 5963,
			"sqftTotal": 1324,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221119124",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "95050",
			"path": "santa-clara-ca/1996-stanley-ave-santa-clara-ca-95050/pid_4b2p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f1927ff9-08ce-439b-abf0-13c7c3c2c128",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-17 17:24:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 17:24:33",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.339014,
				"lng": -121.955066,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1996 Stanley Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1299000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1353995,
			"daysOnMovoto": 243,
			"id": "1028b0ab-5dfd-43a4-82fd-6d78a510a737",
			"tnImgPath": "https://pi.movoto.com/p/12/40937786_0_n7QJin_p.jpeg",
			"listDate": "2021-02-14 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1349995,
			"lotSize": 0,
			"sqftTotal": 1774,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937786",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 9,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-321-santa-clara-ca-95051-12_40898582/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c2959c56-be0c-48be-92f0-a8e63e9daaaf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-02-14 20:56:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 321",
				"address": "3578 Rambla Pl #321"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1353995,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1454025,
			"daysOnMovoto": 466,
			"id": "3d96c93a-5c12-4c9d-8af3-86a813ef339f",
			"tnImgPath": "https://pi.movoto.com/p/12/40911175_0_afZABz_p.jpeg",
			"listDate": "2020-07-06 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1423795,
			"lotSize": 0,
			"sqftTotal": 1880,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40911175",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 9,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-329-santa-clara-ca-95051-12_40911175/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a24fab54-3a07-485b-a7c2-eb260a420a61",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2020-07-06 21:41:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-07-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 329",
				"address": "3578 Rambla Pl #329"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1454025,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 999995,
			"daysOnMovoto": 278,
			"id": "bb3a4b54-9409-4d30-8db0-89030b198137",
			"tnImgPath": "https://pi.movoto.com/p/12/40933745_0_ra3mej_p.jpeg",
			"listDate": "2021-01-09 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 999995,
			"lotSize": 0,
			"sqftTotal": 1432,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40933745",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-315-santa-clara-ca-95051-12_40933745/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c47cbff6-9789-4f57-b40f-f5a754b57aef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-01-09 21:16:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 315",
				"address": "3578 Rambla Pl #315"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 999995,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 56,
			"id": "ddcde742-4370-4e54-87b1-537a870edcec",
			"tnImgPath": "https://pi.movoto.com/p/12/40963629_0_ARB3Zv_p.jpeg",
			"listDate": "2021-08-18 00:00:00",
			"listingAgent": "Savneet Kaur",
			"listPrice": 1388888,
			"lotSize": 6279,
			"sqftTotal": 1707,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963629",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "95054",
			"path": "santa-clara-ca/2085-hogan-dr-santa-clara-ca-95054/pid_n3fn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -61112,
			"propertyId": "67fcd515-88b3-4bcb-9f6c-8be40fe6d118",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-13 00:00:00",
			"createdAt": "2021-08-18 21:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?brand=0&m=2wd1G2vahnr",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.402038,
				"lng": -121.961509,
				"zipcode": "95054",
				"subPremise": "",
				"address": "2085 Hogan Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?brand=0&m=2wd1G2vahnr"
		},
		{
			"closePrice": 1040000,
			"daysOnMovoto": 31,
			"id": "75535012-6a38-4e43-872a-19e2bb63f24b",
			"tnImgPath": "https://pi.movoto.com/p/12/40966065_0_yJeUaN_p.jpeg",
			"listDate": "2021-09-07 00:00:00",
			"listingAgent": "Carol Chu",
			"listPrice": 998000,
			"lotSize": 5000,
			"sqftTotal": 1060,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966065",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Hilltop Residential Rl Est.",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "95054",
			"path": "santa-clara-ca/1801-chestnut-st-santa-clara-ca-95054-100_81446431/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "43657650-e6cb-4a26-b8d7-54648dff094d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-09-07 16:41:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-07 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=aL8L9pq21Xt",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.390901,
				"lng": -121.958354,
				"zipcode": "95054",
				"subPremise": "",
				"address": "1801 Chestnut St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1040000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=aL8L9pq21Xt"
		},
		{
			"closePrice": 1460000,
			"daysOnMovoto": 43,
			"id": "2aac6621-48f3-456d-918a-dd51397fee2c",
			"tnImgPath": "https://pi.movoto.com/p/12/40964833_0_RnJNmI_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Ruth Smith",
			"listPrice": 1389900,
			"lotSize": 2372,
			"sqftTotal": 1716,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964833",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2004,
			"zipCode": "95054",
			"path": "santa-clara-ca/4089-crandall-cir-santa-clara-ca-95054/pid_4whn2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d750b4f-52b6-4e78-bd33-a33817899c21",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-08-26 23:51:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.397441,
				"lng": -121.9456,
				"zipcode": "95054",
				"subPremise": "",
				"address": "4089 Crandall Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1460000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1199995,
			"daysOnMovoto": 236,
			"id": "cde0c070-da02-41cb-a0b3-ea4bdae634c1",
			"tnImgPath": "https://pi.movoto.com/p/12/40937793_0_mrefiJ_p.jpeg",
			"listDate": "2021-02-14 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1199995,
			"lotSize": 0,
			"sqftTotal": 1395,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937793",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-305-santa-clara-ca-95051-12_40937793/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e3b335f1-0b0d-4242-8a04-8996cfacc098",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-02-14 22:56:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 305",
				"address": "3578 Rambla Pl #305"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1199995,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1354395,
			"daysOnMovoto": 335,
			"id": "b095492e-5282-4964-a074-f8553508642b",
			"tnImgPath": "https://pi.movoto.com/p/12/40928494_0_neqZyR_p.jpeg",
			"listDate": "2020-11-07 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1299795,
			"lotSize": 0,
			"sqftTotal": 1432,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40928494",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-709-santa-clara-ca-95051-12_40928494/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "75f26ee3-ac6f-4cfe-acaf-5ce036ca2962",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2020-11-07 21:21:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 709",
				"address": "3578 Rambla Pl #709"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1354395,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1578795,
			"daysOnMovoto": 285,
			"id": "649c52e8-18e9-4419-b0d5-2c04620ad557",
			"tnImgPath": "https://pi.movoto.com/p/12/40932681_0_afZABz_p.jpeg",
			"listDate": "2020-12-26 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1578795,
			"lotSize": 0,
			"sqftTotal": 1880,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40932681",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 9,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-429-santa-clara-ca-95051-12_40932681/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b689dcd4-ff4c-4b22-9f32-c2190a7550b9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2020-12-26 22:51:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 429",
				"address": "3578 Rambla Pl #429"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1578795,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 56,
			"id": "c23e0257-c998-4f85-9498-b52f5505c6a1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857720_0_rIMfNE_p.jpeg",
			"listDate": "2021-08-10 14:26:28",
			"listingAgent": "Eugene Korsunsky",
			"listPrice": 2299888,
			"lotSize": 9670,
			"sqftTotal": 3934,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857720",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Intempus Realty",
			"photoCount": 25,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1976,
			"zipCode": "95050",
			"path": "santa-clara-ca/1321-jonathan-st-santa-clara-ca-95050/pid_do4p2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1b04ca2d-bc08-4715-9090-151d62b44cab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-06 00:00:00",
			"createdAt": "2021-08-10 21:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-10 14:26:28",
			"virtualTourLink": "https://my.matterport.com/show/?m=YMpDqg76Mne",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.341887,
				"lng": -121.944889,
				"zipcode": "95050",
				"subPremise": "",
				"address": "1321 Jonathan St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=YMpDqg76Mne"
		},
		{
			"closePrice": 690000,
			"daysOnMovoto": 47,
			"id": "d38eca3e-f34b-4ac7-9e66-babdbfee3983",
			"tnImgPath": "https://pi.movoto.com/p/12/40963625_0_I3bzQ2_p.jpeg",
			"listDate": "2021-08-18 00:00:00",
			"listingAgent": "Bryan VanHeusen",
			"listPrice": 675000,
			"lotSize": 0,
			"sqftTotal": 946,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963625",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "95050",
			"path": "santa-clara-ca/2250-monroe-st-374-santa-clara-ca-95050-100_81454458/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c07baaf9-4d70-41d7-9159-6399a74ea60e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-04 00:00:00",
			"createdAt": "2021-08-18 21:26:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 00:00:00",
			"virtualTourLink": "https://2250MonroeStUnit37445656mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.362009,
				"lng": -121.963789,
				"zipcode": "95050",
				"subPremise": "APT 374",
				"address": "2250 Monroe St #374"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 690000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://2250MonroeStUnit37445656mls.f8re.com/"
		},
		{
			"closePrice": 1270105,
			"daysOnMovoto": 111,
			"id": "709b8870-3d1d-42ae-9cdf-abba2e8e53dd",
			"tnImgPath": "https://pi.movoto.com/p/12/40954129_0_mqzubN_p.jpeg",
			"listDate": "2021-06-12 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1270105,
			"lotSize": 0,
			"sqftTotal": 1537,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954129",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-324-santa-clara-ca-95051-12_40931365/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7dfc1c7f-67c3-4bfa-88d5-edfc6041874d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-06-13 00:31:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 324",
				"address": "3578 Rambla Pl #324"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1270105,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1247888,
			"daysOnMovoto": 228,
			"id": "87b3db9b-761d-4090-b94c-27fea6cfe72a",
			"tnImgPath": "https://pi.movoto.com/p/12/40937787_0_mqzubN_p.jpeg",
			"listDate": "2021-02-14 00:00:00",
			"listingAgent": "Ryan Nguyen",
			"listPrice": 1248888,
			"lotSize": 0,
			"sqftTotal": 1561,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40937787",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "95051",
			"path": "santa-clara-ca/3578-rambla-pl-apt-424-santa-clara-ca-95051-12_40937787/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "39b176ad-f107-4327-9ffe-0e869c2d303c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-02-14 21:01:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.375144,
				"lng": -121.995406,
				"zipcode": "95051",
				"subPremise": "APT 424",
				"address": "3578 Rambla Pl #424"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1247888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 0,
			"id": "c7786ae1-bba0-4e15-bc7f-db5f07a19ff0",
			"tnImgPath": "https://pi.movoto.com/p/102/221110257_0_mMY3Ir_p.jpeg",
			"listDate": null,
			"listingAgent": "Carlos Garcia",
			"listPrice": 1375000,
			"lotSize": 6713,
			"sqftTotal": 1075,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221110257",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Big Block Realty North",
			"photoCount": 11,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "95050",
			"path": "santa-clara-ca/2129-bohannon-dr-santa-clara-ca-95050/pid_9csq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8fb7e67a-3160-4a1b-b1aa-d708986e31f6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-09-02 07:14:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 07:14:31",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Santa Clara",
				"lat": 37.33621,
				"lng": -121.955021,
				"zipcode": "95050",
				"subPremise": "",
				"address": "2129 Bohannon Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		}
	]