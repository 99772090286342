exports.SAN_RAMON_LISTINGS = [
		{
			"closePrice": 450000,
			"daysOnMovoto": 40,
			"id": "0d7039f4-7881-485b-86b5-5c6590c7bcec",
			"tnImgPath": "https://pi.movoto.com/p/12/40974678_0_7YQriY_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Sabine Larsen",
			"listPrice": 429000,
			"lotSize": 0,
			"sqftTotal": 946,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974678",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94583",
			"path": "san-ramon-ca/8975-alcosta-blvd-106-san-ramon-ca-94583/pid_poutbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d3a152e-5684-4801-85b2-e5a4ecbc1886",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-19 21:41:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=KKCufqXjVhK&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.724597,
				"lng": -121.936732,
				"zipcode": "94583",
				"subPremise": "APT 106",
				"address": "8975 Alcosta Blvd #106"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=KKCufqXjVhK&mls=1"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 0,
			"id": "4cbc16f5-9175-45a7-a41e-e2a5881401fc",
			"tnImgPath": "https://pi.movoto.com/p/102/221137253_0_fe6BAN_p.jpeg",
			"listDate": null,
			"listingAgent": "Sharon Ceresa",
			"listPrice": 1500000,
			"lotSize": 10001,
			"sqftTotal": 2391,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221137253",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway HomeServices-Drysdale Properties",
			"photoCount": 57,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1978,
			"zipCode": "94583",
			"path": "san-ramon-ca/12212-toluca-dr-san-ramon-ca-94583/pid_myxtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "daa16419-91a0-4fb9-b910-f573dd286452",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-01 07:14:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 07:14:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.753474,
				"lng": -121.941578,
				"zipcode": "94583",
				"subPremise": "",
				"address": "12212 Toluca Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1160000,
			"daysOnMovoto": 42,
			"id": "f17c2f22-431b-4d28-b062-bf7c1b9d3106",
			"tnImgPath": "https://pi.movoto.com/p/12/40973766_0_Mqe7FZ_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "Karrie Camezon",
			"listPrice": 980000,
			"lotSize": 7600,
			"sqftTotal": 1220,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973766",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Camezon Real Estate Services",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "94583",
			"path": "san-ramon-ca/7439-blue-fox-way-san-ramon-ca-94583/pid_zvstbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c9afff33-8610-40b9-ba73-6bd4ac486f23",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-15 20:26:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.730292,
				"lng": -121.938223,
				"zipcode": "94583",
				"subPremise": "",
				"address": "7439 Blue Fox Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1160000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 43,
			"id": "35443926-bf29-4cf7-bbd5-9dab137377d4",
			"tnImgPath": "https://pi.movoto.com/p/12/40973700_0_qMZanF_p.jpeg",
			"listDate": "2021-11-08 00:00:00",
			"listingAgent": "Roger Prasad",
			"listPrice": 2489000,
			"lotSize": 11088,
			"sqftTotal": 4321,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973700",
			"bath": 5.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "RP Estates",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2009,
			"zipCode": "94582",
			"path": "san-ramon-ca/233-snowdrop-ct-san-ramon-ca-94582/pid_1vfubtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a35bb221-239c-4021-8d87-f6d652cfe268",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-09 03:51:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.770037,
				"lng": -121.917821,
				"zipcode": "94582",
				"subPremise": "",
				"address": "233 Snowdrop Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1390000,
			"daysOnMovoto": 36,
			"id": "16f5ccc9-95fc-487e-a855-4c8a5e8633f6",
			"tnImgPath": "https://pi.movoto.com/p/12/40973975_0_yUeFQn_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Stephanie Tice",
			"listPrice": 1249999,
			"lotSize": 6958,
			"sqftTotal": 1288,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973975",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's Int'l Re",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94583",
			"path": "san-ramon-ca/2973-cheyenne-ave-san-ramon-ca-94583/pid_2xttbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aac0ba1e-02be-43d7-a521-f63fb902ffc9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-11 21:46:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.737182,
				"lng": -121.941645,
				"zipcode": "94583",
				"subPremise": "",
				"address": "2973 Cheyenne Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 27,
			"id": "1fcf2290-01b5-477c-ade4-fda066ae6d91",
			"tnImgPath": "https://pi.movoto.com/p/12/40974474_0_zJVuqJ_p.jpeg",
			"listDate": "2021-11-17 00:00:00",
			"listingAgent": "Paul Zuvella",
			"listPrice": 1698000,
			"lotSize": 6600,
			"sqftTotal": 2804,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974474",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1989,
			"zipCode": "94582",
			"path": "san-ramon-ca/1010-quartermaster-canyon-rd-san-ramon-ca-94582/pid_9q3tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13e62e75-16c9-4148-abc4-69dbcca35206",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-17 21:31:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 00:00:00",
			"virtualTourLink": "https://click.pstmrk.it/2sm/listings.allaccessphoto.com%2Fv2%2F1010-quartermaster-canyon-rd-san-ramon-ca-94582-1341368%2Funbranded/rih83iYN/cUpU/kCObUd4N8w/djJfZGVsaXZlcnk",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.786268,
				"lng": -121.941023,
				"zipcode": "94582",
				"subPremise": "",
				"address": "1010 Quartermaster Canyon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://click.pstmrk.it/2sm/listings.allaccessphoto.com%2Fv2%2F1010-quartermaster-canyon-rd-san-ramon-ca-94582-1341368%2Funbranded/rih83iYN/cUpU/kCObUd4N8w/djJfZGVsaXZlcnk"
		},
		{
			"closePrice": 505000,
			"daysOnMovoto": 33,
			"id": "9d088740-434f-4a3f-8ef4-e90e428d2664",
			"tnImgPath": "https://pi.movoto.com/p/12/40973929_0_uAEa2F_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Jasmine Min",
			"listPrice": 475000,
			"lotSize": 0,
			"sqftTotal": 657,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973929",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1989,
			"zipCode": "94582",
			"path": "san-ramon-ca/450-bollinger-canyon-ln-391-san-ramon-ca-94582/pid_j53tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c5efcec-e703-4240-b77c-a755ba271bdd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-11 17:21:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": "https://450BollingerCanyonLaneUnit391335860mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.772427,
				"lng": -121.943504,
				"zipcode": "94582",
				"subPremise": "APT 391",
				"address": "450 Bollinger Canyon Ln #391"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 505000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://450BollingerCanyonLaneUnit391335860mls.f8re.com/"
		},
		{
			"closePrice": 2240000,
			"daysOnMovoto": 38,
			"id": "95e06ac5-8ac5-4dfe-a355-ec27c7be5a97",
			"tnImgPath": "https://pi.movoto.com/p/12/40972690_0_6aYmaU_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Doug Romano",
			"listPrice": 1999984,
			"lotSize": 6003,
			"sqftTotal": 3828,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972690",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2004,
			"zipCode": "94582",
			"path": "san-ramon-ca/3025-tamburlaine-dr-san-ramon-ca-94582-12_40972690/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2500b9d6-df24-4989-ac93-112fa3285469",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-05 16:01:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.762312,
				"lng": -121.893993,
				"zipcode": "94582",
				"subPremise": "",
				"address": "3025 Tamburlaine Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2240000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 635000,
			"daysOnMovoto": 29,
			"id": "a002647e-ca5c-41ce-8686-656f517d86e9",
			"tnImgPath": "https://pi.movoto.com/p/12/40973825_0_VZebZj_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Josh Haller",
			"listPrice": 619700,
			"lotSize": 0,
			"sqftTotal": 943,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973825",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Brickhouse Real Estate Partner",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94583",
			"path": "san-ramon-ca/200-reflections-dr-21-san-ramon-ca-94583/pid_4m1tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6a27071b-f781-435a-854a-ebcf71299632",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-10 19:51:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": "http://www.viewshoot.com/tour/MLS/200ReflectionsDrive_SanRamon_CA_94583_690_224591.html?preview=616b937295a9f",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.76267,
				"lng": -121.948178,
				"zipcode": "94583",
				"subPremise": "APT 21",
				"address": "200 Reflections Dr #21"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 635000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.viewshoot.com/tour/MLS/200ReflectionsDrive_SanRamon_CA_94583_690_224591.html?preview=616b937295a9f"
		},
		{
			"closePrice": 625000,
			"daysOnMovoto": 55,
			"id": "3eb16ced-d01a-4a61-9e93-514b6b85865f",
			"tnImgPath": "https://pi.movoto.com/p/12/40970707_0_bmQvmY_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Bita Salamat",
			"listPrice": 623000,
			"lotSize": 0,
			"sqftTotal": 949,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970707",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94583",
			"path": "san-ramon-ca/135-reflections-dr-26-san-ramon-ca-94583/pid_4q3tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "22d48821-a340-49ca-b66f-8da563255495",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-13 16:56:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=ZXfZP8xzYwY&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.762599,
				"lng": -121.948854,
				"zipcode": "94583",
				"subPremise": "APT 26",
				"address": "135 Reflections Dr #26"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 625000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=ZXfZP8xzYwY&mls=1"
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 41,
			"id": "3c5e74c3-54a7-4bcd-9de6-2814b25d02c1",
			"tnImgPath": "https://pi.movoto.com/p/12/40971640_0_rey7eV_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Bonnie King",
			"listPrice": 1299000,
			"lotSize": 11000,
			"sqftTotal": 1489,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971640",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "94583",
			"path": "san-ramon-ca/9400-broadmoor-dr-san-ramon-ca-94583-12_11056322/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "29f4bac7-d26a-4651-97dd-152484cc32dd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-24 01:01:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": "https://9400BroadmoorDrive190878mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.730371,
				"lng": -121.931023,
				"zipcode": "94583",
				"subPremise": "",
				"address": "9400 Broadmoor Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://9400BroadmoorDrive190878mls.f8re.com/"
		},
		{
			"closePrice": 990000,
			"daysOnMovoto": 103,
			"id": "918e983c-0d5d-434b-8e82-00b96d23f73e",
			"tnImgPath": "https://pi.movoto.com/p/12/40964205_0_QY6MBi_p.jpeg",
			"listDate": "2021-08-21 00:00:00",
			"listingAgent": "Alison Petersen",
			"listPrice": 979000,
			"lotSize": 0,
			"sqftTotal": 1946,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964205",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2016,
			"zipCode": "94583",
			"path": "san-ramon-ca/457-ryan-ter-san-ramon-ca-94583-12_40964205/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "363b2ede-99e7-4b78-9900-cb3c01da22dd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-08-21 20:51:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-21 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2909551",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.77709,
				"lng": -121.984064,
				"zipcode": "94583",
				"subPremise": "",
				"address": "457 Ryan Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 990000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2909551"
		},
		{
			"closePrice": 1231000,
			"daysOnMovoto": 41,
			"id": "70bbb87d-1aae-44a1-b616-d2c23083fe02",
			"tnImgPath": "https://pi.movoto.com/p/12/40971799_0_f6Z3zA_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Henry Ohlmeyer",
			"listPrice": 999000,
			"lotSize": 7000,
			"sqftTotal": 1508,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971799",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 2,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "94583",
			"path": "san-ramon-ca/7597-interlachen-ave-san-ramon-ca-94583-12_26587543/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "55c0a1cd-868e-4f07-8005-1889a536f9a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-11-05 20:30:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.725144,
				"lng": -121.937201,
				"zipcode": "94583",
				"subPremise": "",
				"address": "7597 Interlachen Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1231000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 41,
			"id": "1833c87b-1034-4263-8f1c-da929f38baea",
			"tnImgPath": "https://pi.movoto.com/p/12/40971859_0_YjBaav_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Michael Ertem",
			"listPrice": 975000,
			"lotSize": 0,
			"sqftTotal": 1946,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971859",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Northern Crest Realty",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2016,
			"zipCode": "94583",
			"path": "san-ramon-ca/255-ryan-ter-san-ramon-ca-94583-12_40971859/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "75ce08e7-b8bf-43d7-9f9b-70951faf0f83",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-21 23:46:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.776863,
				"lng": -121.983696,
				"zipcode": "94583",
				"subPremise": "",
				"address": "255 Ryan Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 895000,
			"daysOnMovoto": 37,
			"id": "3db987b6-bae8-41bb-a274-4776b3c1ea21",
			"tnImgPath": "https://pi.movoto.com/p/12/40971885_0_NUziNi_p.jpeg",
			"listDate": "2021-10-23 00:00:00",
			"listingAgent": "Cara R. Cavanaugh",
			"listPrice": 895000,
			"lotSize": 2184,
			"sqftTotal": 1790,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971885",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1978,
			"zipCode": "94583",
			"path": "san-ramon-ca/3709-mesa-vista-dr-san-ramon-ca-94583-12_40546447/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "14fc14f0-15b4-44a4-8ae1-abf873e02142",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-23 16:06:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 00:00:00",
			"virtualTourLink": "http://www.3709mesavista.com/?mls=",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.764134,
				"lng": -121.976022,
				"zipcode": "94583",
				"subPremise": "",
				"address": "3709 Mesa Vista Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 895000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.3709mesavista.com/?mls="
		},
		{
			"closePrice": 1645000,
			"daysOnMovoto": 38,
			"id": "bf5a4779-5db6-42b3-ac58-be68d47ebfef",
			"tnImgPath": "https://pi.movoto.com/p/12/40970525_0_MUruYU_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Laura Lane",
			"listPrice": 1635000,
			"lotSize": 24360,
			"sqftTotal": 1988,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970525",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Lane & Associates",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1979,
			"zipCode": "94583",
			"path": "san-ramon-ca/166-pebble-pl-san-ramon-ca-94583-12_11155564/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 285000,
			"propertyId": "3445fc51-a6fd-4221-9460-1f69548923a6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-23 00:11:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.737462,
				"lng": -121.934401,
				"zipcode": "94583",
				"subPremise": "",
				"address": "166 Pebble Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1645000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 54,
			"id": "d27ff45c-8007-4db7-81c7-f4c207db4984",
			"tnImgPath": "https://pi.movoto.com/p/12/40969611_0_BNIQer_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Terry Moore",
			"listPrice": 1098000,
			"lotSize": 7000,
			"sqftTotal": 1407,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969611",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94583",
			"path": "san-ramon-ca/2870-calais-dr-san-ramon-ca-94583/pid_awwtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5c2128c9-fe95-4d48-b2b9-55ee0cc93e1f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-06 22:51:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.740853,
				"lng": -121.947,
				"zipcode": "94583",
				"subPremise": "",
				"address": "2870 Calais Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1530000,
			"daysOnMovoto": 34,
			"id": "0b73faa3-483e-458d-aeb3-dc107b406894",
			"tnImgPath": "https://pi.movoto.com/p/12/40971877_0_yeJJve_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Beverly Herrera",
			"listPrice": 1398000,
			"lotSize": 5680,
			"sqftTotal": 2337,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971877",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Elation Real Estate",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1995,
			"zipCode": "94582",
			"path": "san-ramon-ca/2041-bent-creek-dr-san-ramon-ca-94582/pid_q8vtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "43c8196b-00ef-40b2-be27-9202133b5434",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-22 01:26:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/tours/pc1cp6?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.733203,
				"lng": -121.911465,
				"zipcode": "94582",
				"subPremise": "",
				"address": "2041 Bent Creek Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1530000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/tours/pc1cp6?mls"
		},
		{
			"closePrice": 528000,
			"daysOnMovoto": 28,
			"id": "2d6800c1-fb74-49be-8b48-cba5a77a34bf",
			"tnImgPath": "https://pi.movoto.com/p/12/40971957_0_766riu_p.jpeg",
			"listDate": "2021-10-22 00:00:00",
			"listingAgent": "Gagan Singh",
			"listPrice": 499000,
			"lotSize": 0,
			"sqftTotal": 1230,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971957",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94583",
			"path": "san-ramon-ca/8985-alcosta-blvd-178-san-ramon-ca-94583/pid_x6utbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c777691c-01c9-469d-9ffe-b78670f66377",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-22 16:11:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.725619,
				"lng": -121.935724,
				"zipcode": "94583",
				"subPremise": "APT 178",
				"address": "8985 Alcosta Blvd #178"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 528000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 44,
			"id": "3736f6ed-29d3-44af-846a-3f7dba1fc3b6",
			"tnImgPath": "https://pi.movoto.com/p/12/40969574_0_fZJRRJ_p.jpeg",
			"listDate": "2021-10-04 00:00:00",
			"listingAgent": "Diane Shoemaker",
			"listPrice": 699000,
			"lotSize": 0,
			"sqftTotal": 1107,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969574",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 8,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94582",
			"path": "san-ramon-ca/3839-crow-canyon-rd-san-ramon-ca-94583-12_11170682/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "09d759e8-f1d4-4890-a0fd-06fbecc33722",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-04 18:26:53",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.785896,
				"lng": -121.952245,
				"zipcode": "94582",
				"subPremise": "",
				"address": "3839 Crow Canyon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 480000,
			"daysOnMovoto": 39,
			"id": "58bb0ba3-0ba3-48c2-bf20-6c8dda3467b5",
			"tnImgPath": "https://pi.movoto.com/p/12/40969762_0_fu2I23_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Jake Fahnhorst",
			"listPrice": 450000,
			"lotSize": 0,
			"sqftTotal": 1039,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969762",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94583",
			"path": "san-ramon-ca/9085-alcosta-blvd-apt-334-san-ramon-ca-94583-12_40969762/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "718486a4-3b0d-409a-90af-33e91ddd407f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-10-07 14:11:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.727476,
				"lng": -121.93556,
				"zipcode": "94583",
				"subPremise": "APT 334",
				"address": "9085 Alcosta Blvd #334"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2305000,
			"daysOnMovoto": 56,
			"id": "15a5181a-c880-4971-b1cd-b75bb8e1cadc",
			"tnImgPath": "https://pi.movoto.com/p/12/40967856_0_VnIVqQ_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Kim Deol",
			"listPrice": 1989000,
			"lotSize": 6141,
			"sqftTotal": 3649,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967856",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94583",
			"path": "san-ramon-ca/1351-amalfi-ct-san-ramon-ca-94583-12_40967856/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a7d4a3b-0755-4171-abba-b1f69df8aab6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-09-21 00:46:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/3d-matterport/revc1g?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"zipcode": "94583",
				"subPremise": "",
				"address": "1351 Amalfi Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2305000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/3d-matterport/revc1g?mls"
		},
		{
			"closePrice": 665000,
			"daysOnMovoto": 15,
			"id": "16456f38-f1e3-456c-8961-bee0a46db1bd",
			"tnImgPath": "https://pi.movoto.com/p/12/40971833_0_fZMEV6_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Peter Kui",
			"listPrice": 649000,
			"lotSize": 1125,
			"sqftTotal": 1082,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971833",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94583",
			"path": "san-ramon-ca/285-norris-canyon-ter-d-san-ramon-ca-94583/pid_od2tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "06fe7662-db64-49a5-8b0e-3c78346e3c21",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-21 22:17:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=JG2pZ7qxAi2&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.778068,
				"lng": -121.960642,
				"zipcode": "94583",
				"subPremise": "APT D",
				"address": "285 Norris Canyon Ter #D"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 665000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=JG2pZ7qxAi2&brand=0"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 24,
			"id": "808051c9-f14c-42ec-a7e6-aeccecb31f63",
			"tnImgPath": "https://pi.movoto.com/p/12/40970670_0_n7ryvi_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Corinna Paniagua",
			"listPrice": 1175888,
			"lotSize": 8000,
			"sqftTotal": 1518,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970670",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1972,
			"zipCode": "94583",
			"path": "san-ramon-ca/21-yale-ct-san-ramon-ca-94583-12_40567399/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0b4483d5-668e-457a-a198-780b86d80d1f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-13 03:01:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.731016,
				"lng": -121.945161,
				"zipcode": "94583",
				"subPremise": "",
				"address": "21 Yale Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1370000,
			"daysOnMovoto": 36,
			"id": "07df5850-323e-4d09-be9f-fff146aa1564",
			"tnImgPath": "https://pi.movoto.com/p/12/40968738_0_VMarNj_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Shannon Berger",
			"listPrice": 1149000,
			"lotSize": 7300,
			"sqftTotal": 1500,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968738",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens RP",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94583",
			"path": "san-ramon-ca/3362-estero-dr-san-ramon-ca-94583/pid_z2xtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b39cf38b-cf13-43b0-9b1a-7c21d3d13f20",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-29 16:46:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": "http://www.3362esterodr.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.747065,
				"lng": -121.934401,
				"zipcode": "94583",
				"subPremise": "",
				"address": "3362 Estero Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1370000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.3362esterodr.com"
		},
		{
			"closePrice": 1495000,
			"daysOnMovoto": 40,
			"id": "4af706b2-3572-43be-afbf-44d9d9051a0f",
			"tnImgPath": "https://pi.movoto.com/p/12/40968183_0_AYfzBr_p.jpeg",
			"listDate": "2021-09-25 00:00:00",
			"listingAgent": "Karunasagar Kotha",
			"listPrice": 1350000,
			"lotSize": 3024,
			"sqftTotal": 1930,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968183",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Windemere Realty Inc",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2011,
			"zipCode": "94582",
			"path": "san-ramon-ca/4280-astor-hollow-st-san-ramon-ca-94582/pid_hrdubtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1d3b56f3-cbc3-4c45-83e5-7707ed916fc9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-26 02:31:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.760704,
				"lng": -121.914576,
				"zipcode": "94582",
				"subPremise": "",
				"address": "4280 Astor Hollow St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1495000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1362000,
			"daysOnMovoto": 38,
			"id": "ff6e21b2-95a4-43a0-9f02-2830e1116ae1",
			"tnImgPath": "https://pi.movoto.com/p/12/40967670_0_Ijqfqi_p.jpeg",
			"listDate": "2021-09-26 00:00:00",
			"listingAgent": "Meredith Kummell",
			"listPrice": 1148000,
			"lotSize": 7000,
			"sqftTotal": 1288,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967670",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1967,
			"zipCode": "94583",
			"path": "san-ramon-ca/225-riverton-pl-san-ramon-ca-94583-12_11081679/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ed4bbab-1236-4b41-8e50-41db4cff3b84",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-26 15:06:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.737252,
				"lng": -121.939556,
				"zipcode": "94583",
				"subPremise": "",
				"address": "225 Riverton Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1362000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 62,
			"id": "fe8efed4-09cf-43b7-ae54-09d574f46ac4",
			"tnImgPath": "https://pi.movoto.com/p/12/40965581_0_zb7vbr_p.jpeg",
			"listDate": "2021-09-02 00:00:00",
			"listingAgent": "Flavio Amaral",
			"listPrice": 1679000,
			"lotSize": 3143,
			"sqftTotal": 2534,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965581",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2017,
			"zipCode": "94582",
			"path": "san-ramon-ca/404-silvercrown-way-san-ramon-ca-94582-12_40964532/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "27a4a9b9-93e3-464a-a608-b6531d97a0e0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-02 17:11:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.754146,
				"lng": -121.918977,
				"zipcode": "94582",
				"subPremise": "",
				"address": "404 Silvercrown Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 685000,
			"daysOnMovoto": 41,
			"id": "2a476b6a-7b8b-4281-8841-71f57d528de5",
			"tnImgPath": "https://pi.movoto.com/p/12/40967664_0_U2Br3Q_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Sue Schembri",
			"listPrice": 665000,
			"lotSize": 0,
			"sqftTotal": 1007,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967664",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens RP",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94583",
			"path": "san-ramon-ca/624-norris-canyon-ter-san-ramon-ca-94583-12_27233023/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "869786d9-d4ca-47ba-823d-d7ef2455d262",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-22 17:26:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/624-norris-canyon-terrace-san-ramon-ca-94583-1182139/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.779421,
				"lng": -121.960877,
				"zipcode": "94583",
				"subPremise": "",
				"address": "624 Norris Canyon Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 685000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/624-norris-canyon-terrace-san-ramon-ca-94583-1182139/unbranded"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 24,
			"id": "b7892187-3fca-4d60-946f-16e5cb95482a",
			"tnImgPath": "https://pi.movoto.com/p/12/40969297_0_332EnM_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Gerry Price",
			"listPrice": 1250000,
			"lotSize": 3625,
			"sqftTotal": 2097,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969297",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1989,
			"zipCode": "94582",
			"path": "san-ramon-ca/2037-echo-pl-san-ramon-ca-94582/pid_zu6tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b23bfbf1-1584-4e17-ba82-eb2162efb42a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-10-08 21:01:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": "http://www.2037echoplace.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.786012,
				"lng": -121.947866,
				"zipcode": "94582",
				"subPremise": "",
				"address": "2037 Echo Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.2037echoplace.com/?mls"
		},
		{
			"closePrice": 820500,
			"daysOnMovoto": 48,
			"id": "c4e444e4-6a7d-4dcc-9f3f-7241e4931b99",
			"tnImgPath": "https://pi.movoto.com/p/12/40967085_0_IzAuF7_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Lauren Bilich",
			"listPrice": 849999,
			"lotSize": 6300,
			"sqftTotal": 1165,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967085",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Preferred Financial",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94583",
			"path": "san-ramon-ca/16-helix-ct-san-ramon-ca-94583/pid_kjutbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -15001,
			"propertyId": "1523b67a-8ab4-46fd-a2d5-c9a4be805ea9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-09-14 22:51:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.729841,
				"lng": -121.924356,
				"zipcode": "94583",
				"subPremise": "",
				"address": "16 Helix Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 67,
			"id": "6f75b6e2-c654-45e1-a909-c80ee09a6325",
			"tnImgPath": "https://pi.movoto.com/p/12/40964808_0_7maZQI_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Glenn Mendell",
			"listPrice": 1495000,
			"lotSize": 4420,
			"sqftTotal": 2411,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964808",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1995,
			"zipCode": "94582",
			"path": "san-ramon-ca/37-victory-ct-san-ramon-ca-94582/pid_891tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6087556-5f79-49cf-9741-e45717a9874b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-08-26 22:16:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.770929,
				"lng": -121.945133,
				"zipcode": "94582",
				"subPremise": "",
				"address": "37 Victory Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 30,
			"id": "91983a3c-4f8c-4370-a804-7daeb9b0d8ce",
			"tnImgPath": "https://pi.movoto.com/p/12/40967982_0_iyZFR6_p.jpeg",
			"listDate": "2021-09-28 00:00:00",
			"listingAgent": "Donna Shealor",
			"listPrice": 855000,
			"lotSize": 3180,
			"sqftTotal": 1456,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967982",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1978,
			"zipCode": "94583",
			"path": "san-ramon-ca/2607-marsh-dr-san-ramon-ca-94583/pid_7dvtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "82ea2b26-5d58-4457-ac6d-9f105329fa4d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-28 23:01:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.764925,
				"lng": -121.976445,
				"zipcode": "94583",
				"subPremise": "",
				"address": "2607 Marsh Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 517000,
			"daysOnMovoto": 38,
			"id": "da008d34-0ac1-4902-9326-e8b85735a699",
			"tnImgPath": "https://pi.movoto.com/p/12/40967759_0_rmyUQJ_p.jpeg",
			"listDate": "2021-09-19 00:00:00",
			"listingAgent": "Monica Timms",
			"listPrice": 475000,
			"lotSize": 0,
			"sqftTotal": 778,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967759",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94582",
			"path": "san-ramon-ca/274-s-overlook-dr-san-ramon-ca-94582/pid_nz2tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aca9b1cf-62b3-4f75-ada7-55c8583e0e66",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-09-19 18:41:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.768236,
				"lng": -121.946277,
				"zipcode": "94582",
				"subPremise": "",
				"address": "274 S Overlook Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 517000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 535000,
			"daysOnMovoto": 39,
			"id": "00fec3e9-c0a8-4c4b-b027-e2b290972714",
			"tnImgPath": "https://pi.movoto.com/p/12/40967513_0_JQbBn6_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Jon Wood",
			"listPrice": 498000,
			"lotSize": 0,
			"sqftTotal": 778,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967513",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94582",
			"path": "san-ramon-ca/146-copper-ridge-rd-san-ramon-ca-94583-12_40539340/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31c1f749-41b8-478d-9edf-247fc7ffe778",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-17 14:26:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.770187,
				"lng": -121.947021,
				"zipcode": "94582",
				"subPremise": "",
				"address": "146 Copper Ridge Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 535000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1115000,
			"daysOnMovoto": 22,
			"id": "2e34f387-9725-41e5-bf9a-945e46f4f1c4",
			"tnImgPath": "https://pi.movoto.com/p/12/40966849_0_yyBr3u_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Shikha Kapur",
			"listPrice": 1089000,
			"lotSize": 0,
			"sqftTotal": 1839,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966849",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2015,
			"zipCode": "94582",
			"path": "san-ramon-ca/1022-s-monarch-san-ramon-ca-94582-12_40966849/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9123745c-f00f-45de-858e-9bd977a01d20",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-30 15:41:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.764477,
				"lng": -121.910577,
				"zipcode": "94582",
				"subPremise": "",
				"address": "1022 S Monarch"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1115000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 540000,
			"daysOnMovoto": 15,
			"id": "c1a8ab27-ff55-45ce-a2c6-664a1a6d22a0",
			"tnImgPath": "https://pi.movoto.com/p/12/40969813_0_UqVN3n_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Kendra Gilbert",
			"listPrice": 499000,
			"lotSize": 0,
			"sqftTotal": 778,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969813",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94583",
			"path": "san-ramon-ca/258-copper-ridge-rd-san-ramon-ca-94583-12_40424394/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c2f4f1c9-4151-4e93-b378-0b6e3c71a1f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-10-07 00:11:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.768793,
				"lng": -121.945911,
				"zipcode": "94583",
				"subPremise": "",
				"address": "258 Copper Ridge Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 540000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 420000,
			"daysOnMovoto": 34,
			"id": "907d5074-b353-46ac-9bf0-25c1a1d9a188",
			"tnImgPath": "https://pi.movoto.com/p/12/40967161_0_7ybyna_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Mike Kehrig",
			"listPrice": 419900,
			"lotSize": 0,
			"sqftTotal": 474,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967161",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94583",
			"path": "san-ramon-ca/120-reflections-dr-22-san-ramon-ca-94583/pid_d53tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -19100,
			"propertyId": "f32a84de-f52e-406b-bbd2-f779187ebc80",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-17 18:51:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.762704,
				"lng": -121.950091,
				"zipcode": "94583",
				"subPremise": "APT 22",
				"address": "120 Reflections Dr #22"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 420000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 515000,
			"daysOnMovoto": 43,
			"id": "6f834d07-bc49-4fff-8af4-8036336b216e",
			"tnImgPath": "https://pi.movoto.com/p/12/40966340_0_JB3vRY_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Ken Vis",
			"listPrice": 515000,
			"lotSize": 0,
			"sqftTotal": 1230,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966340",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94583",
			"path": "san-ramon-ca/9005-alcosta-blvd-192-san-ramon-ca-94583/pid_ocvtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b8621fc2-1117-4e77-bb38-e674978772b8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-09 02:26:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.726152,
				"lng": -121.935556,
				"zipcode": "94583",
				"subPremise": "APT 192",
				"address": "9005 Alcosta Blvd #192"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 515000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1760000,
			"daysOnMovoto": 42,
			"id": "951b4c88-19fd-4849-9125-e43528894e70",
			"tnImgPath": "https://pi.movoto.com/p/12/40965297_0_jn3brU_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Jing Xue",
			"listPrice": 1650000,
			"lotSize": 4002,
			"sqftTotal": 2784,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965297",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2002,
			"zipCode": "94582",
			"path": "san-ramon-ca/319-langton-ct-san-ramon-ca-94582-12_40175376/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a23b0dd8-0ac8-46d3-bf8b-76ed5fd7443d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-09-08 16:36:55",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/pVHPUB6s94c",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.762279,
				"lng": -121.903774,
				"zipcode": "94582",
				"subPremise": "",
				"address": "319 Langton Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1760000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/pVHPUB6s94c"
		},
		{
			"closePrice": 1635000,
			"daysOnMovoto": 0,
			"id": "ac02f101-daf8-4267-980b-2b018b505bf2",
			"tnImgPath": "https://pi.movoto.com/p/102/221107044_0_qyr7AI_p.jpeg",
			"listDate": null,
			"listingAgent": "Shannon Thompson",
			"listPrice": 1399900,
			"lotSize": 5785,
			"sqftTotal": 1888,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221107044",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1999,
			"zipCode": "94582",
			"path": "san-ramon-ca/5978-lantana-way-san-ramon-ca-94582/pid_xz8tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ffae6665-6218-4828-b824-45caf3fc1948",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-09-16 21:24:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 21:24:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.778202,
				"lng": -121.928956,
				"zipcode": "94582",
				"subPremise": "",
				"address": "5978 Lantana Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1635000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1415000,
			"daysOnMovoto": 40,
			"id": "dc43036f-ffc1-405a-ac07-7cf1e2a4c4f2",
			"tnImgPath": "https://pi.movoto.com/p/12/40966431_0_BJ7erm_p.jpeg",
			"listDate": "2021-09-09 00:00:00",
			"listingAgent": "Liping Zhang",
			"listPrice": 1415000,
			"lotSize": 2200,
			"sqftTotal": 2171,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966431",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94582",
			"path": "san-ramon-ca/5168-rowan-dr-san-ramon-ca-94582-12_40966431/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 117000,
			"propertyId": "6d68359c-01a1-4a8c-b717-feb576b5d516",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-09-09 18:51:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.76132,
				"lng": -121.910586,
				"zipcode": "94582",
				"subPremise": "",
				"address": "5168 Rowan Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1415000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 448000,
			"daysOnMovoto": 35,
			"id": "1e978d3a-be2e-4d0a-b8bc-78c6a1617fb2",
			"tnImgPath": "https://pi.movoto.com/p/12/40966568_0_3AZUb7_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Anita Chan",
			"listPrice": 439000,
			"lotSize": 0,
			"sqftTotal": 624,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966568",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Golden Hills Brokers",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94583",
			"path": "san-ramon-ca/no-address-san-ramon-ca-94583-12_40966568/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4c5a39a9-4ca5-4d2f-8484-7fbda528ed38",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-10 15:36:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 0,
				"lng": 0,
				"zipcode": "94583",
				"subPremise": "APT 24",
				"address": "Undisclosed",
				"address2": "115 Reflections Dr #24"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 448000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 39,
			"id": "2d0adff0-c81d-45a2-8d4d-70e45e353757",
			"tnImgPath": "https://pi.movoto.com/p/12/40966003_0_z7J7uj_p.jpeg",
			"listDate": "2021-09-06 00:00:00",
			"listingAgent": "Trang Dunlap",
			"listPrice": 1698000,
			"lotSize": 5002,
			"sqftTotal": 3059,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966003",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2020,
			"zipCode": "94583",
			"path": "san-ramon-ca/1212-via-messina-san-ramon-ca-94583-12_40966003/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f660b923-1d76-486e-99e4-992e647c1e0b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-06 20:01:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.783243,
				"lng": -121.99022,
				"zipcode": "94583",
				"subPremise": "",
				"address": "1212 Via Messina"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1510000,
			"daysOnMovoto": 32,
			"id": "5f4f59fc-85e7-406e-b8a1-85c243608df7",
			"tnImgPath": "https://pi.movoto.com/p/110/421601828_0_62qIVy_p.jpeg",
			"listDate": "2021-09-12 00:00:00",
			"listingAgent": "Nancy Lim",
			"listPrice": 1349000,
			"lotSize": 4222,
			"sqftTotal": 2183,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421601828",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2016,
			"zipCode": "94582",
			"path": "san-ramon-ca/2005-poinsettia-st-san-ramon-ca-94582-110_421601828/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "04b7ddd6-ab59-4539-b3bc-18b17d42eb8b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-10-07 08:46:08",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.757515,
				"lng": -121.912265,
				"zipcode": "94582",
				"subPremise": "",
				"address": "2005 Poinsettia St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1560000,
			"daysOnMovoto": 96,
			"id": "cef8bc93-9202-4847-97b6-7b951ae0e514",
			"tnImgPath": "https://pi.movoto.com/p/12/40957895_0_yqQUNB_p.jpeg",
			"listDate": "2021-07-10 00:00:00",
			"listingAgent": "Neal Berry",
			"listPrice": 1599000,
			"lotSize": 9000,
			"sqftTotal": 2502,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957895",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94583",
			"path": "san-ramon-ca/9509-belle-meade-dr-san-ramon-ca-94583-12_40085156/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "58ff6cdc-9dbf-4956-bc94-1a8ae12e050b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-07-10 22:46:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-10 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=GbDoZFxKCcd&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.730981,
				"lng": -121.933948,
				"zipcode": "94583",
				"subPremise": "",
				"address": "9509 Belle Meade Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=GbDoZFxKCcd&mls=1"
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 42,
			"id": "94a15255-c913-4530-8c8c-edc29d7498e7",
			"tnImgPath": "https://pi.movoto.com/p/12/40963470_0_nUy6fa_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Laura Lane",
			"listPrice": 1100000,
			"lotSize": 7300,
			"sqftTotal": 1220,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963470",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Lane & Associates",
			"photoCount": 9,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "94583",
			"path": "san-ramon-ca/136-cassandra-san-ramon-ca-94583-12_40963470/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -75000,
			"propertyId": "8b2db0ab-cfec-4c61-ad1f-1e3836611bb6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-13 00:00:00",
			"createdAt": "2021-09-01 15:26:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.727893,
				"lng": -121.939178,
				"zipcode": "94583",
				"subPremise": "",
				"address": "136 Cassandra"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 64,
			"id": "1d3e4604-1a83-4826-872b-c6c2698a32c5",
			"tnImgPath": "https://pi.movoto.com/p/12/40962584_0_U3vjqb_p.jpeg",
			"listDate": "2021-08-10 00:00:00",
			"listingAgent": "Sabine Larsen",
			"listPrice": 2099000,
			"lotSize": 11784,
			"sqftTotal": 3342,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962584",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2001,
			"zipCode": "94583",
			"path": "san-ramon-ca/6021-westside-dr-san-ramon-ca-94583-12_11192120/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "03f2751b-83fa-45d9-a568-0c17e72639f9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-13 00:00:00",
			"createdAt": "2021-08-11 01:01:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-10 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=w214ptekSdt&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.740198,
				"lng": -121.958333,
				"zipcode": "94583",
				"subPremise": "",
				"address": "6021 Westside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=w214ptekSdt&mls=1"
		},
		{
			"closePrice": 1272500,
			"daysOnMovoto": 38,
			"id": "46b3a1cf-7f08-4a64-9d7d-4493bbea27af",
			"tnImgPath": "https://pi.movoto.com/p/12/40965855_0_QZbaZV_p.jpeg",
			"listDate": "2021-09-04 00:00:00",
			"listingAgent": "Bhavana Kulkarni",
			"listPrice": 1244499,
			"lotSize": 3285,
			"sqftTotal": 1772,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965855",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1996,
			"zipCode": "94583",
			"path": "san-ramon-ca/76-elmwood-dr-san-ramon-ca-94583-12_11068487/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9bb2b87-a5f1-4ee7-aeb4-c37f528a1c42",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-04 19:51:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-04 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=6jp2Vj5eUwS&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.726219,
				"lng": -121.931307,
				"zipcode": "94583",
				"subPremise": "",
				"address": "76 Elmwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1272500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=6jp2Vj5eUwS&brand=0"
		},
		{
			"closePrice": 1351000,
			"daysOnMovoto": 4,
			"id": "2d93b900-0535-4511-a961-3657dc38291d",
			"tnImgPath": "https://pi.movoto.com/p/101/321095180_0_qq6fQi_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Dale A Potter",
			"listPrice": 1351000,
			"lotSize": 7200,
			"sqftTotal": 1500,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321095180",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty Co-op Network",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1971,
			"zipCode": "94583",
			"path": "san-ramon-ca/3269-casa-grande-dr-san-ramon-ca-94583-12_27598018/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 252000,
			"propertyId": "9f913c3e-7532-40e4-a7df-c2331ad3513a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-11 00:00:00",
			"createdAt": "2021-10-07 23:43:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Ramon",
				"lat": 37.747401,
				"lng": -121.938489,
				"zipcode": "94583",
				"subPremise": "",
				"address": "3269 Casa Grande Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1351000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]