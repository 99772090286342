exports.REDWOOD_CITY_LISTINGS = [
		{
			"closePrice": 2220000,
			"daysOnMovoto": 89,
			"id": "a56762a0-dc8e-4987-a3c1-23c053e741cf",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867641_0_Je7FA6_p.jpeg",
			"listDate": "2021-10-21 13:31:27",
			"listingAgent": "Don Sung",
			"listPrice": 2290000,
			"lotSize": 5998,
			"sqftTotal": 3174,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867641",
			"bath": null,
			"bed": 11,
			"openHouses": [],
			"officeListName": "Colliers Parrish International",
			"photoCount": 50,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1948,
			"zipCode": "94063",
			"path": "redwood-city-ca/561-4th-ave-redwood-city-ca-94063/pid_lu27w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ac214e23-abe2-4723-a92e-88562194f1f0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-10-21 20:37:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 13:31:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.475544,
				"lng": -122.203936,
				"zipcode": "94063",
				"subPremise": "",
				"address": "561 4th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1625000,
			"daysOnMovoto": 0,
			"id": "ab48e3bc-9179-4819-8543-af874bad699a",
			"tnImgPath": "https://pi.movoto.com/p/102/421599598_0_VnMYzJ_p.jpeg",
			"listDate": null,
			"listingAgent": "Denise Aquila",
			"listPrice": 1600000,
			"lotSize": 6696,
			"sqftTotal": 1390,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421599598",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Folsom",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "94061",
			"path": "redwood-city-ca/1589-alameda-de-las-pulgas-redwood-city-ca-94061/pid_8xf8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2ea92fc4-7410-469b-8bc1-944bca775b53",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-11 01:04:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-11 01:04:25",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.456689,
				"lng": -122.240063,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1589 Alameda De Las Pulgas"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1625000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1285000,
			"daysOnMovoto": 35,
			"id": "717bd914-b3ce-4cc0-b70c-064cb3caa11c",
			"tnImgPath": "https://pi.movoto.com/p/12/40975541_0_jEZMuy_p.jpeg",
			"listDate": "2021-12-03 00:00:00",
			"listingAgent": "Bela Shah",
			"listPrice": 1280000,
			"lotSize": 3920,
			"sqftTotal": 1320,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975541",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "IREB - Bela Realty",
			"photoCount": 27,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1953,
			"zipCode": "94063",
			"path": "redwood-city-ca/660-pine-st-redwood-city-ca-94063/pid_05o7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dc880ff5-35b4-490f-b6ad-0c0be7c12678",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-12-03 22:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.483378,
				"lng": -122.219461,
				"zipcode": "94063",
				"subPremise": "",
				"address": "660 Pine St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1285000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1420000,
			"daysOnMovoto": 205,
			"id": "212118df-4e34-4e25-8af9-2482e76a0da1",
			"tnImgPath": "https://pi.movoto.com/p/12/40953357_0_ZA6n6Y_p.jpeg",
			"listDate": "2021-06-08 00:00:00",
			"listingAgent": "Jeffery Atkinson",
			"listPrice": 1498000,
			"lotSize": 4600,
			"sqftTotal": 1550,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953357",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Everhome Realty, Inc.",
			"photoCount": 29,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1936,
			"zipCode": "94061",
			"path": "redwood-city-ca/154-belmont-ave-redwood-city-ca-94061/pid_dnf8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -174500,
			"propertyId": "10c2c6e8-7378-45e5-9956-068f7cbdabd6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-06-08 22:36:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.455246,
				"lng": -122.227343,
				"zipcode": "94061",
				"subPremise": "",
				"address": "154 Belmont Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1420000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 49,
			"id": "d4f6b747-325e-4feb-baf3-eecb59bd44b2",
			"tnImgPath": "https://pi.movoto.com/p/12/40973854_0_jqZJBy_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Lynn Chin",
			"listPrice": 1299800,
			"lotSize": 829,
			"sqftTotal": 1424,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973854",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94065",
			"path": "redwood-city-ca/300-baltic-cir-330-redwood-city-ca-94065-100_80021031/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c352df99-e863-4ad6-b7a0-ff7cc762823c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-10 22:41:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=P6Q6kxv1u5y&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.539592,
				"lng": -122.24176,
				"zipcode": "94065",
				"subPremise": "APT 330",
				"address": "300 Baltic Cir #330"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=P6Q6kxv1u5y&brand=0"
		},
		{
			"closePrice": 710000,
			"daysOnMovoto": 52,
			"id": "400fd3ad-34c6-404b-bf9b-822af95736d0",
			"tnImgPath": "https://pi.movoto.com/p/12/40972850_0_bEMBn7_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Gary Bernie",
			"listPrice": 699000,
			"lotSize": 816,
			"sqftTotal": 760,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972850",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94065",
			"path": "redwood-city-ca/112-shorebird-cir-apt-23-redwood-city-ca-94065-12_40972850/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a15fe71-a441-4cb8-8edd-180cc311864f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-01 16:31:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.532164,
				"lng": -122.254993,
				"zipcode": "94065",
				"subPremise": "APT 23",
				"address": "112 Shorebird Cir #23"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1645000,
			"daysOnMovoto": 74,
			"id": "9b4b9b87-4eba-45a2-9493-e1bda19868d9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81865795_0_QYvrQa_p.jpeg",
			"listDate": "2021-10-07 08:35:37",
			"listingAgent": "Justin Sieber",
			"listPrice": 1695000,
			"lotSize": 5249,
			"sqftTotal": 1987,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81865795",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Avison Young - Northern California, Ltd.",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "94063",
			"path": "redwood-city-ca/3448-page-st-redwood-city-ca-94063/pid_zqu7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "99e6d5f6-ccad-4a7f-bc99-0ccf0e726f39",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-10-07 15:42:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 08:35:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.481345,
				"lng": -122.19175,
				"zipcode": "94063",
				"subPremise": "",
				"address": "3448 Page St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1645000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2750000,
			"daysOnMovoto": 246,
			"id": "762fd847-7a5e-461d-b84e-6bf5f3b64793",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832072_0_7BJ6MV_p.jpeg",
			"listDate": "2021-04-19 00:00:00",
			"listingAgent": "Bryan Jacobs",
			"listPrice": 2750000,
			"lotSize": 8538,
			"sqftTotal": 3414,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832072",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Realsmart Properties",
			"photoCount": 2,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 2021,
			"zipCode": "94061",
			"path": "redwood-city-ca/1128-1130-mckinley-st-redwood-city-ca-94061-100_ml81832072/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9ad48e31-d367-4401-b6d4-78106efba42f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-04-19 21:31:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.470604,
				"lng": -122.235055,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1128-1130 Mckinley St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1980000,
			"daysOnMovoto": 50,
			"id": "ed13d9a3-0980-4dc7-bb1c-a054971479d4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868275_0_URmI2r_p.jpeg",
			"listDate": "2021-10-27 14:13:23",
			"listingAgent": "Brian Henry",
			"listPrice": 1980000,
			"lotSize": 5001,
			"sqftTotal": 2880,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868275",
			"bath": null,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94061",
			"path": "redwood-city-ca/210-linden-st-redwood-city-ca-94061/pid_4uq7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0e81cd61-13f4-4c5d-9a03-c104a1bca574",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-10-27 21:17:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 14:13:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.473921,
				"lng": -122.220405,
				"zipcode": "94061",
				"subPremise": "",
				"address": "210 Linden St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1980000,
			"daysOnMovoto": 50,
			"id": "d529c01f-e1a5-43ca-8bc5-255f8ede4476",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868274_0_zr7Je3_p.jpeg",
			"listDate": "2021-10-27 14:12:37",
			"listingAgent": "Brian Henry",
			"listPrice": 1980000,
			"lotSize": 5001,
			"sqftTotal": 2880,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868274",
			"bath": null,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 11,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94061",
			"path": "redwood-city-ca/202-linden-st-redwood-city-ca-94061/pid_evq7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d4b8175-8b36-460c-a604-ab36fbb9759a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-10-27 21:17:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 14:12:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.473981,
				"lng": -122.220516,
				"zipcode": "94061",
				"subPremise": "",
				"address": "202 Linden St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 39,
			"id": "6845019d-1f4c-49a3-94c2-612a47feeb97",
			"tnImgPath": "https://pi.movoto.com/p/12/40973485_0_fbRI6U_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Carmen Bara Roque",
			"listPrice": 900000,
			"lotSize": 4831,
			"sqftTotal": 740,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973485",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "United Brokers Real Estate",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1954,
			"zipCode": "94061",
			"path": "redwood-city-ca/1148-clinton-st-redwood-city-ca-94061/pid_5wm7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "83b96cc8-db86-4e2b-8696-e022c3aa3c17",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-05 22:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.476126,
				"lng": -122.227121,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1148 Clinton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1725000,
			"daysOnMovoto": 97,
			"id": "f5b82b31-eb17-4cb6-8408-69689ded16e8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860872_0_NMaaM6_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Nicole Loza",
			"listPrice": 1749000,
			"lotSize": 5998,
			"sqftTotal": 2866,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860872",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Reliance Realty Group",
			"photoCount": 42,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1942,
			"zipCode": "94061",
			"path": "redwood-city-ca/737-valota-rd-redwood-city-ca-94061/pid_5bv7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -139000,
			"propertyId": "97452282-94a1-40d4-806b-c956009a1272",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-09-08 17:42:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.471209,
				"lng": -122.240196,
				"zipcode": "94061",
				"subPremise": "",
				"address": "737 Valota Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1840000,
			"daysOnMovoto": 122,
			"id": "44961a72-742b-4f90-9223-ff9f985322c1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858239_0_7yzY7u_p.jpeg",
			"listDate": "2021-08-13 10:42:57",
			"listingAgent": "Dedy Efendi",
			"listPrice": 1898000,
			"lotSize": 5759,
			"sqftTotal": 2733,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858239",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Action Properties",
			"photoCount": 31,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1958,
			"zipCode": "94061",
			"path": "redwood-city-ca/416-poplar-ave-redwood-city-ca-94061/pid_rgr7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "71d72a84-eb8d-49a3-990f-861affd7e344",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-08-13 17:47:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 10:42:57",
			"virtualTourLink": "https://tours.victorlinphoto.com/1887711",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.473086,
				"lng": -122.225965,
				"zipcode": "94061",
				"subPremise": "",
				"address": "416 Poplar Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1840000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.victorlinphoto.com/1887711"
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 171,
			"id": "91dbbb07-1fea-4131-839f-e952d64b60d1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81850969_0_Jf7neR_p.jpeg",
			"listDate": "2021-06-25 23:05:32",
			"listingAgent": "Gregory Garcia",
			"listPrice": 898000,
			"lotSize": 9296,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81850969",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Sequoia Realty Services",
			"photoCount": 9,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94062",
			"path": "redwood-city-ca/3506-oak-knoll-dr-redwood-city-ca-94062/pid_paw7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "9cb1660c-be96-4595-83cf-1129b2708e8b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-06-26 06:07:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 23:05:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.464959,
				"lng": -122.262428,
				"zipcode": "94062",
				"subPremise": "",
				"address": "3506 Oak Knoll Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3028000,
			"daysOnMovoto": 24,
			"id": "8051e5be-b623-4906-bae5-5a73474a90e9",
			"tnImgPath": "https://pi.movoto.com/p/110/421613415_0_AiR2un_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "Sue Chan",
			"listPrice": 2795000,
			"lotSize": 4478,
			"sqftTotal": 2600,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421613415",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sequoia Real Estate",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1988,
			"zipCode": "94065",
			"path": "redwood-city-ca/93-waterside-circle-redwood-city-ca-94065-100_ml81499124/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9341d737-cc20-49cb-8a28-258e4bfae73e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-12-02 08:16:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": "http://www.93waterside.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.534516,
				"lng": -122.238265,
				"zipcode": "94065",
				"subPremise": "",
				"address": "93 Waterside Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3028000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.93waterside.com"
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 57,
			"id": "c5a7d4d5-3417-4da3-828b-43d0026bd0ef",
			"tnImgPath": "https://pi.movoto.com/p/12/40970774_0_FFQiQj_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Lanette Sukkar",
			"listPrice": 980000,
			"lotSize": 1168,
			"sqftTotal": 1240,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970774",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Reali",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1983,
			"zipCode": "94065",
			"path": "redwood-shores-ca/7-cove-ln-4165-redwood-shores-ca-94065-100_89929280/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -18888,
			"propertyId": "46ba0297-42b4-4144-bc1b-5d4fd2686c3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-13 22:16:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=b3VuUUnxUkK&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.53116,
				"lng": -122.25579,
				"zipcode": "94065",
				"subPremise": "APT 4165",
				"address": "7 Cove Ln #4165"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=b3VuUUnxUkK&mls=1"
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 64,
			"id": "3b09c21c-24c1-41a5-9f0e-9220ccf142e1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81865333_0_jyYeNV_p.jpeg",
			"listDate": "2021-10-04 09:06:20",
			"listingAgent": "Marilyn Koron",
			"listPrice": 1299000,
			"lotSize": 3990,
			"sqftTotal": 1850,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81865333",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 11,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1952,
			"zipCode": "94061",
			"path": "redwood-city-ca/1179-king-st-redwood-city-ca-94061/pid_kmw7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -121000,
			"propertyId": "20a625f5-ff3a-4506-bc89-2afb06bcc224",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-04 16:12:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 09:06:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.471352,
				"lng": -122.232607,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1179 King St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 290000,
			"daysOnMovoto": 0,
			"id": "4afd72a2-e9a7-4b1a-8ae9-6de41105860e",
			"tnImgPath": "https://pi.movoto.com/p/102/421596957_0_e2eFfu_p.jpeg",
			"listDate": null,
			"listingAgent": "Esthela Duran Camacho",
			"listPrice": 285000,
			"lotSize": 0,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421596957",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Midtown",
			"photoCount": 22,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2003,
			"zipCode": "94063",
			"path": "redwood-city-ca/3015-e-bayshore-rd-apt-157-redwood-city-ca-94063-102_421596957/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b14961c2-8d12-4c1e-804e-7aa7b9f83660",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-23 00:54:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:54:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.487563,
				"lng": -122.196175,
				"zipcode": "94063",
				"subPremise": "APT 157",
				"address": "3015 E Bayshore Rd #157"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1630000,
			"daysOnMovoto": 29,
			"id": "624b18d7-4729-4b2b-be5d-9bf874bf621a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867119_0_Qevaev_p.jpeg",
			"listDate": "2021-10-25 00:00:00",
			"listingAgent": "Gerardo Aguilar",
			"listPrice": 1650000,
			"lotSize": 5981,
			"sqftTotal": 1973,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867119",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "1 Team Realtors",
			"photoCount": 43,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1974,
			"zipCode": "94063",
			"path": "redwood-city-ca/3467-michael-dr-redwood-city-ca-94063/pid_1yu7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1cfa46df-10d4-4b3d-9cc9-3482026dd00f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-25 18:22:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=3NV71CRvtAB",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.480121,
				"lng": -122.191541,
				"zipcode": "94063",
				"subPremise": "",
				"address": "3467 Michael Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=3NV71CRvtAB"
		},
		{
			"closePrice": 1615000,
			"daysOnMovoto": 37,
			"id": "1dd26c2d-5dfe-456c-bed8-55d1e128ffa3",
			"tnImgPath": "https://pi.movoto.com/p/12/40970718_0_ANvfqv_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Dale Morris",
			"listPrice": 1529500,
			"lotSize": 7000,
			"sqftTotal": 2043,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970718",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Valley Brokers on Main",
			"photoCount": 28,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1946,
			"zipCode": "94063",
			"path": "redwood-city-ca/919-haven-ave-redwood-city-ca-94063/pid_ygu7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1ac70b23-95b4-43c5-a83b-c786a50676ab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-13 18:01:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.479672,
				"lng": -122.191786,
				"zipcode": "94063",
				"subPremise": "",
				"address": "919 Haven Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1235000,
			"daysOnMovoto": 14,
			"id": "add54161-06cd-4298-9d09-08f63cee460d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868105_0_BqInu3_p.jpeg",
			"listDate": "2021-10-26 11:48:39",
			"listingAgent": "Matthew Thomson",
			"listPrice": 1235385,
			"lotSize": 4224,
			"sqftTotal": 1920,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868105",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Newmark Cornish & Carey",
			"photoCount": 8,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1923,
			"zipCode": "94063",
			"path": "redwood-city-ca/1908-middlefield-rd-redwood-city-ca-94063-100_ml80397653/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4024f127-c0e7-413a-a14a-70273775d8ee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-26 18:52:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 11:48:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.481174,
				"lng": -122.219916,
				"zipcode": "94063",
				"subPremise": "",
				"address": "1908 Middlefield Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1235000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1725000,
			"daysOnMovoto": 33,
			"id": "b397dbde-41c8-43e6-a8f6-55f298b9b0a4",
			"tnImgPath": "https://pi.movoto.com/p/12/40968710_0_EMNi6I_p.jpeg",
			"listDate": "2021-09-26 00:00:00",
			"listingAgent": "Suzanne Rocha",
			"listPrice": 1674000,
			"lotSize": 6600,
			"sqftTotal": 1590,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968710",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Cal Home",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1951,
			"zipCode": "94061",
			"path": "redwood-city-ca/685-upton-st-redwood-city-ca-94061-100_80565467/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c416d8f-eec4-4a5a-a0c1-a84cb4fb0bd0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-26 21:41:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.469111,
				"lng": -122.244628,
				"zipcode": "94061",
				"subPremise": "",
				"address": "685 Upton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 82,
			"id": "73a23102-0eaa-49ee-beca-c53bac9a43e8",
			"tnImgPath": "https://pi.movoto.com/p/12/40961795_0_B72eIR_p.jpeg",
			"listDate": "2021-08-05 00:00:00",
			"listingAgent": "Joe Rosa",
			"listPrice": 1099000,
			"lotSize": 5009,
			"sqftTotal": 1461,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961795",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1942,
			"zipCode": "94063",
			"path": "redwood-city-ca/104-d-st-redwood-city-ca-94063/pid_6nk7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "84ceeb97-87e2-4699-8c74-1c342f1b8f99",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-08-05 05:21:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-05 00:00:00",
			"virtualTourLink": "https://104DSt45121mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.492788,
				"lng": -122.241836,
				"zipcode": "94063",
				"subPremise": "",
				"address": "104 D St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://104DSt45121mls.f8re.com/"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 36,
			"id": "0cb8d9cc-fbca-47a1-bac7-97fbc56f3a75",
			"tnImgPath": "https://pi.movoto.com/p/12/40967363_0_Zfr6aZ_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Kelly Hopkins",
			"listPrice": 1499000,
			"lotSize": 5450,
			"sqftTotal": 1582,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967363",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94063",
			"path": "redwood-city-ca/820-7th-ave-redwood-city-ca-94063/pid_c3p7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "206f7950-70a9-48ce-8418-3becd5b62d2b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-16 20:31:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/606093597",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.479836,
				"lng": -122.19733,
				"zipcode": "94063",
				"subPremise": "",
				"address": "820 7th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/606093597"
		},
		{
			"closePrice": 285000,
			"daysOnMovoto": 53,
			"id": "6ba38287-fba9-426e-b385-9ae27e848795",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860384_0_uI2Q6Z_p.jpeg",
			"listDate": "2021-08-27 20:12:15",
			"listingAgent": "Noemi Ruelas",
			"listPrice": 285000,
			"lotSize": null,
			"sqftTotal": 1250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860384",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 42,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Single Wide",
			"yearBuilt": 2007,
			"zipCode": "94063",
			"path": "redwood-city-ca/2053-e-bayshore-redwood-city-ca-94063-100_ml81860384/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d9a24cec-248e-4c33-916b-2288fb2cb2a7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-08-28 03:17:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 20:12:15",
			"virtualTourLink": "https://tours.victorlinphoto.com/1894908",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.488036,
				"lng": -122.201631,
				"zipcode": "94063",
				"subPremise": "",
				"address": "2053 E Bayshore"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 285000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.victorlinphoto.com/1894908"
		},
		{
			"closePrice": 1950000,
			"daysOnMovoto": 0,
			"id": "01d8e518-6255-4b0b-aa48-0b5b54264893",
			"tnImgPath": "https://pi.movoto.com/p/102/421581238_0_eZvffI_p.jpeg",
			"listDate": null,
			"listingAgent": "Anthony Scarpelli",
			"listPrice": 1999000,
			"lotSize": 12380,
			"sqftTotal": 2250,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421581238",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens RE",
			"photoCount": 65,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2007,
			"zipCode": "94062",
			"path": "redwood-city-ca/619-vista-dr-redwood-city-ca-94062/pid_vcv7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200900,
			"propertyId": "7025dabc-ba59-49f3-95e9-9d56ece836dc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-08-26 16:14:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 16:14:33",
			"virtualTourLink": "https://my.matterport.com/show/?m=6XSThce2CdC",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.467226,
				"lng": -122.261379,
				"zipcode": "94062",
				"subPremise": "",
				"address": "619 Vista Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=6XSThce2CdC"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 28,
			"id": "bb98f5c4-23df-4c77-8bca-57b3daf55f52",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863068_0_uiQzmQ_p.jpeg",
			"listDate": "2021-09-16 16:50:02",
			"listingAgent": "The Patty Dwyer Group",
			"listPrice": 1250000,
			"lotSize": 5001,
			"sqftTotal": 2138,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863068",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 18,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1941,
			"zipCode": "94063",
			"path": "redwood-city-ca/401-standish-st-redwood-city-ca-94063/pid_i3k7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d03d97c5-29fe-486c-9f1a-09a64797b826",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-16 23:52:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 16:50:02",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.492153,
				"lng": -122.235321,
				"zipcode": "94063",
				"subPremise": "",
				"address": "401 Standish St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1770000,
			"daysOnMovoto": 141,
			"id": "5adf6d40-e8ae-41c0-8a0a-7f6f4c96a1ba",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81845921_0_RUjzzY_p.jpeg",
			"listDate": "2021-05-26 14:22:21",
			"listingAgent": "Mitchell Zurich",
			"listPrice": 1800000,
			"lotSize": 5001,
			"sqftTotal": 2350,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81845921",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94063",
			"path": "redwood-city-ca/565-hampshire-ave-redwood-city-ca-94063/pid_27q7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9487fb27-7b9a-4c74-96eb-54855951c2ab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-05-26 21:27:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-26 14:22:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.478124,
				"lng": -122.207882,
				"zipcode": "94063",
				"subPremise": "",
				"address": "565 Hampshire Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1770000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 22,
			"id": "d893f428-a619-4ca9-9c14-c75d0921a167",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862774_0_uEYaYa_p.jpeg",
			"listDate": "2021-09-15 12:44:16",
			"listingAgent": "Matthew Thomson",
			"listPrice": 1500000,
			"lotSize": 5166,
			"sqftTotal": 1950,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862774",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Newmark Cornish & Carey",
			"photoCount": 10,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1956,
			"zipCode": "94061",
			"path": "redwood-city-ca/1815-redwood-ave-redwood-city-ca-94061/pid_p7w7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6844842b-5aa8-46ca-8662-f27c5175af94",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-09-15 19:47:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 12:44:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.465405,
				"lng": -122.236075,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1815 Redwood Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 29,
			"id": "0498dda3-15df-4c84-a276-d6a4e054f821",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861784_0_VEAqVY_p.jpeg",
			"listDate": "2021-09-08 18:01:11",
			"listingAgent": "Brian Musante",
			"listPrice": 1599777,
			"lotSize": 51401,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861784",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94062",
			"path": "redwood-city-ca/651-vista-dr-redwood-city-ca-94062-100_80949732/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "076971ae-157a-4122-bfc2-ee65fa2e1d21",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-09-09 01:07:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 18:01:11",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.46677,
				"lng": -122.259902,
				"zipcode": "94062",
				"subPremise": "",
				"address": "651 Vista Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1625000,
			"daysOnMovoto": 62,
			"id": "4aa41867-54be-47eb-ad2f-c6df0a086c53",
			"tnImgPath": "https://pi.movoto.com/p/12/40962227_0_ab6VUf_p.jpeg",
			"listDate": "2021-08-06 00:00:00",
			"listingAgent": "Steven Wang",
			"listPrice": 1575000,
			"lotSize": 6400,
			"sqftTotal": 1617,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962227",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "CA Golden Properties",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1931,
			"zipCode": "94062",
			"path": "redwood-city-ca/2121-jefferson-ave-redwood-city-ca-94062/pid_z6p7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cab26aa0-1b9a-438d-ad45-ec7d44d3f6cb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-08-07 00:41:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-06 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=9eSR58q7C1u&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.474924,
				"lng": -122.238689,
				"zipcode": "94062",
				"subPremise": "",
				"address": "2121 Jefferson Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1625000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=9eSR58q7C1u&mls=1"
		},
		{
			"closePrice": 1299000,
			"daysOnMovoto": 0,
			"id": "22113ca8-0d63-429d-8740-6975387ef6d9",
			"tnImgPath": "https://pi.movoto.com/p/102/421592731_0_Fqqqur_p.jpeg",
			"listDate": null,
			"listingAgent": "Luis Silva",
			"listPrice": 1299000,
			"lotSize": 9000,
			"sqftTotal": 1150,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421592731",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "NewVision Realty Group",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1931,
			"zipCode": "94062",
			"path": "redwood-city-ca/543-hillside-rd-redwood-city-ca-94062/pid_c7t7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "02323ef1-8035-4055-b942-812f90eae1cd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-09-09 20:59:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 20:59:49",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.471132,
				"lng": -122.261822,
				"zipcode": "94062",
				"subPremise": "",
				"address": "543 Hillside Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1299000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 54,
			"id": "f69296bf-66c2-490f-91ee-9734bd07cadd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857941_0_Q3yRmi_p.jpeg",
			"listDate": "2021-08-11 00:00:00",
			"listingAgent": "Vinicius Brasil",
			"listPrice": 1198000,
			"lotSize": 5998,
			"sqftTotal": 1846,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857941",
			"bath": null,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty - Cupertino",
			"photoCount": 32,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1966,
			"zipCode": "94063",
			"path": "redwood-city-ca/1025-1027-dodge-dr-redwood-city-ca-94063-100_ml81857941/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "64a71bb8-ab8d-466d-aac2-370bd0fca337",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-04 00:00:00",
			"createdAt": "2021-08-12 01:07:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-11 00:00:00",
			"virtualTourLink": "https://1025dodge.thebestlisting.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.483692,
				"lng": -122.196222,
				"zipcode": "94063",
				"subPremise": "",
				"address": "1025-1027 Dodge Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://1025dodge.thebestlisting.com/"
		},
		{
			"closePrice": 2145000,
			"daysOnMovoto": 23,
			"id": "e13fe204-31d1-4831-a2ca-575e368b2b2f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861494_0_eeBAUz_p.jpeg",
			"listDate": "2021-09-07 10:18:42",
			"listingAgent": "Craig Smith",
			"listPrice": 1700000,
			"lotSize": 8102,
			"sqftTotal": 3156,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861494",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 19,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94061",
			"path": "redwood-city-ca/1216-1218-roosevelt-ave-redwood-city-ca-94061-100_ml81861494/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0f66c1cb-e4d8-43cd-95ff-056729d559df",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-09-07 17:22:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-07 10:18:42",
			"virtualTourLink": "https://listing.ewalk.com/bt/1216_Roosevelt_Ave.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.471436,
				"lng": -122.235764,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1216-1218 Roosevelt Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2145000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listing.ewalk.com/bt/1216_Roosevelt_Ave.html"
		},
		{
			"closePrice": 1730625,
			"daysOnMovoto": 78,
			"id": "c0c8adf5-5f43-4905-afb7-3880da9ff622",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853137_0_rj7ibb_p.jpeg",
			"listDate": "2021-07-12 15:11:22",
			"listingAgent": "Brett Barron",
			"listPrice": 1950000,
			"lotSize": 8307,
			"sqftTotal": 2401,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853137",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Capital Realty Group",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1949,
			"zipCode": "94061",
			"path": "redwood-city-ca/42-oakwood-dr-redwood-city-ca-94061/pid_n107w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31dc19c7-b831-4ca1-a8d1-6e549baf0181",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-29 00:00:00",
			"createdAt": "2021-07-12 22:17:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 15:11:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.469964,
				"lng": -122.214543,
				"zipcode": "94061",
				"subPremise": "",
				"address": "42 Oakwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1730625,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 34,
			"id": "fdccd0dd-b3b4-4ccf-92d2-0941e6c25a80",
			"tnImgPath": "https://pi.movoto.com/p/12/40963820_0_ziqMRF_p.jpeg",
			"listDate": "2021-08-21 00:00:00",
			"listingAgent": "Elizabeth Thiel",
			"listPrice": 1175000,
			"lotSize": 373222,
			"sqftTotal": 1200,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963820",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Snyder Real Estate Group",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94061",
			"path": "redwood-city-ca/1103-chelsea-way-apt-47-redwood-city-ca-94061-12_40963820/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4864170-ac28-490d-91ac-70b180b52e58",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-08-21 16:46:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-21 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/590377454",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.460791,
				"lng": -122.222541,
				"zipcode": "94061",
				"subPremise": "APT 47",
				"address": "1103 Chelsea Way #47"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/590377454"
		},
		{
			"closePrice": 1830000,
			"daysOnMovoto": 48,
			"id": "4b59aff6-3994-41e1-92f8-c4e9b1c3dfe5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857156_0_3RFBru_p.jpeg",
			"listDate": "2021-08-06 10:24:38",
			"listingAgent": "A. Elmengard",
			"listPrice": 1798000,
			"lotSize": 5397,
			"sqftTotal": 2151,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857156",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 32,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1946,
			"zipCode": "94061",
			"path": "redwood-city-ca/1217-reese-st-redwood-city-ca-94061/pid_fnp7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ded25162-1ce0-4aaa-9ad5-6d981913bcc3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-08-06 17:27:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-06 10:24:38",
			"virtualTourLink": "https://1217-1219reesest.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.471338,
				"lng": -122.2308,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1217 Reese St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://1217-1219reesest.com/"
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 47,
			"id": "65f694c4-ceb4-47aa-9f22-c6996e9ba167",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81856136_0_IFrJQE_p.jpeg",
			"listDate": "2021-07-30 00:00:00",
			"listingAgent": "Bijan Amini",
			"listPrice": 1498000,
			"lotSize": 4848,
			"sqftTotal": 2690,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81856136",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Quest Real Estate",
			"photoCount": 48,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1947,
			"zipCode": "94063",
			"path": "redwood-city-ca/540-542-laurel-st-redwood-city-ca-94063-100_ml81856136/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ef9c2f76-c2fc-4eb5-849f-8c09920bf66f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-15 00:00:00",
			"createdAt": "2021-07-30 17:42:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-30 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=HUCuswKVxAd",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.481174,
				"lng": -122.217158,
				"zipcode": "94063",
				"subPremise": "",
				"address": "540-542 Laurel St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=HUCuswKVxAd"
		},
		{
			"closePrice": 1810000,
			"daysOnMovoto": 0,
			"id": "978156bd-04cb-48c8-9fcd-fb76915dd9d9",
			"tnImgPath": "https://pi.movoto.com/p/102/421581228_0_ERuFbb_p.jpeg",
			"listDate": null,
			"listingAgent": "Susan Wylie",
			"listPrice": 1725000,
			"lotSize": 10200,
			"sqftTotal": 1540,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421581228",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Savvy Lane, Inc.",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1958,
			"zipCode": "94062",
			"path": "redwood-city-ca/3624-jefferson-ave-redwood-city-ca-94062/pid_odw7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f0f595a7-d50f-4164-a4bc-fe5989c31279",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-08-04 00:54:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-04 00:54:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.46458,
				"lng": -122.251763,
				"zipcode": "94062",
				"subPremise": "",
				"address": "3624 Jefferson Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1270000,
			"daysOnMovoto": 36,
			"id": "a832d019-7ee0-4b01-94c6-a36cfccf068f",
			"tnImgPath": "https://pi.movoto.com/p/12/40961779_0_ej2UvZ_p.jpeg",
			"listDate": "2021-08-05 00:00:00",
			"listingAgent": "Anda Margine",
			"listPrice": 1159000,
			"lotSize": 441876,
			"sqftTotal": 1328,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961779",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Benchmark",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94065",
			"path": "redwood-shores-ca/413-cork-harbour-cir-h-redwood-shores-ca-94065-100_81111983/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "72f5c0a4-9372-43fe-856b-26af3a82c964",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-08-05 20:46:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-05 00:00:00",
			"virtualTourLink": "http://www.413corkharbour.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.531653,
				"lng": -122.258346,
				"zipcode": "94065",
				"subPremise": "APT H",
				"address": "413 Cork Harbour Cir #H"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1270000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.413corkharbour.com/?mls"
		},
		{
			"closePrice": 1523000,
			"daysOnMovoto": 48,
			"id": "4ac8f47b-05fd-411f-b415-5cb5b27e17dc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81855187_0_BVbBYn_p.jpeg",
			"listDate": "2021-07-23 16:46:57",
			"listingAgent": "Pamela Tyson",
			"listPrice": 1500000,
			"lotSize": 5872,
			"sqftTotal": 1756,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81855187",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1948,
			"zipCode": "94061",
			"path": "redwood-city-ca/1188-1190-fay-st-redwood-city-ca-94061-100_ml81855187/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "43826df2-c5e1-4d73-9796-fc2e122a7d11",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-07-23 23:52:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-23 16:46:57",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2898100",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.475048,
				"lng": -122.227078,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1188-1190 Fay St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1523000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2898100"
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 41,
			"id": "3af8eef1-0869-4805-bc30-a2cd1d1ac8f5",
			"tnImgPath": "https://pi.movoto.com/p/12/40961125_0_b7fnNN_p.jpeg",
			"listDate": "2021-07-30 00:00:00",
			"listingAgent": "David Yang",
			"listPrice": 1789000,
			"lotSize": 5100,
			"sqftTotal": 1792,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961125",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94061",
			"path": "redwood-city-ca/246-beresford-ave-redwood-city-ca-94061-100_81453726/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "25f4a9ca-9d07-4393-8d54-02d5b65d020e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-07-30 17:06:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.455105,
				"lng": -122.225836,
				"zipcode": "94061",
				"subPremise": "",
				"address": "246 Beresford Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2248000,
			"daysOnMovoto": 35,
			"id": "34a0abc3-f923-4d6c-910c-fd9500cad589",
			"tnImgPath": "https://pi.movoto.com/p/110/421581288_0_U6qFIz_p.jpeg",
			"listDate": "2021-08-02 00:00:00",
			"listingAgent": "Harold Meacham",
			"listPrice": 2248000,
			"lotSize": 13400,
			"sqftTotal": 2710,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421581288",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Renovation Realty",
			"photoCount": 68,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1989,
			"zipCode": "94062",
			"path": "redwood-city-ca/3238-oak-knoll-dr-redwood-city-ca-94062-12_11127136/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c2c467f9-b00d-4f37-9b13-39cf9f210ea8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-06 00:00:00",
			"createdAt": "2021-08-16 07:21:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-02 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/588944435",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.470191,
				"lng": -122.259852,
				"zipcode": "94062",
				"subPremise": "",
				"address": "3238 Oak Knoll Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2248000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/588944435"
		},
		{
			"closePrice": 1630000,
			"daysOnMovoto": 102,
			"id": "5f0d2f2f-3849-49a5-8608-d595ccac6db4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81844800_0_qYzZy7_p.jpeg",
			"listDate": "2021-05-20 08:03:40",
			"listingAgent": "Matthew Thomson",
			"listPrice": 1629550,
			"lotSize": 5502,
			"sqftTotal": 2306,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81844800",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Newmark Cornish & Carey",
			"photoCount": 15,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1954,
			"zipCode": "94061",
			"path": "redwood-city-ca/201-203-redwood-ave-redwood-city-ca-94061-100_ml81844800/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50450,
			"propertyId": "9a6c2fab-4576-4c8f-9421-8bca7025eb01",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-31 00:00:00",
			"createdAt": "2021-05-20 15:08:06",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-20 08:03:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"zipcode": "94061",
				"subPremise": "",
				"address": "201 203 Redwood Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 48,
			"id": "c777ae8b-7da5-4fb1-976b-fe8980793cc3",
			"tnImgPath": "https://pi.movoto.com/p/101/421572732_0_zRNEqM_p.jpeg",
			"listDate": "2021-07-13 00:00:00",
			"listingAgent": "Faber Real Estate Team",
			"listPrice": 1450000,
			"lotSize": 5500,
			"sqftTotal": 1511,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421572732",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1943,
			"zipCode": "94061",
			"path": "redwood-city-ca/164-poplar-ave-redwood-city-ca-94061/pid_ahn7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e0919fcb-3dfe-41a8-b925-289e69cd566a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-30 00:00:00",
			"createdAt": "2021-07-14 00:18:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=j9mzJDEBhhn",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.475099,
				"lng": -122.223486,
				"zipcode": "94061",
				"subPremise": "",
				"address": "164 Poplar Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=j9mzJDEBhhn"
		},
		{
			"closePrice": 1260000,
			"daysOnMovoto": 63,
			"id": "ab330b0c-622a-4544-8593-5859b4621c56",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81851088_0_2qzvjz_p.jpeg",
			"listDate": "2021-06-27 22:59:33",
			"listingAgent": "James O'Connor",
			"listPrice": 1229111,
			"lotSize": 5998,
			"sqftTotal": 1954,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81851088",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Terrace Associates",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1952,
			"zipCode": "94063",
			"path": "redwood-city-ca/126-128-broadway-st-redwood-city-ca-94063-100_ml81851088/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -120000,
			"propertyId": "87a5669e-0694-4389-992a-80ec2e5cc71d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-30 00:00:00",
			"createdAt": "2021-06-28 06:02:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-27 22:59:33",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2886530",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.484958,
				"lng": -122.198705,
				"zipcode": "94063",
				"subPremise": "",
				"address": "126-128 Broadway St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2886530"
		},
		{
			"closePrice": 1536000,
			"daysOnMovoto": 34,
			"id": "bd3693ad-7bd5-4bef-a784-c7bc8373b403",
			"tnImgPath": "https://pi.movoto.com/p/12/40960363_0_fyJ6IM_p.jpeg",
			"listDate": "2021-07-24 00:00:00",
			"listingAgent": "Evgeny Khaikin",
			"listPrice": 1190000,
			"lotSize": 6800,
			"sqftTotal": 910,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40960363",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "5 Star Realty Partners",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1946,
			"zipCode": "94061",
			"path": "redwood-city-ca/1646-hampton-ave-redwood-city-ca-94061/pid_kfy7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c3096ea-6f16-4961-b4c2-5bba195a8de2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-27 00:00:00",
			"createdAt": "2021-07-24 17:11:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-24 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/578843809",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.463801,
				"lng": -122.229736,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1646 Hampton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1536000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/578843809"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 49,
			"id": "71d91639-0e24-42ce-9516-a3026d46ee6d",
			"tnImgPath": "https://pi.movoto.com/p/12/40958051_0_UQFRM2_p.jpeg",
			"listDate": "2021-07-09 00:00:00",
			"listingAgent": "Gail Boal",
			"listPrice": 919000,
			"lotSize": 0,
			"sqftTotal": 910,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958051",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "94065",
			"path": "redwood-city-ca/735-portwalk-pl-redwood-city-ca-94065-100_81438087/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "df3b15fb-e405-4aa6-9eca-418106978af9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-27 00:00:00",
			"createdAt": "2021-07-09 18:01:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.536604,
				"lng": -122.253372,
				"zipcode": "94065",
				"subPremise": "",
				"address": "735 Portwalk Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1880000,
			"daysOnMovoto": 77,
			"id": "952c1400-0274-4acb-aca8-c7ef5cdca915",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832086_0_uBz3mN_p.jpeg",
			"listDate": "2021-06-08 00:00:00",
			"listingAgent": "Matthew Thomson",
			"listPrice": 1950000,
			"lotSize": 5872,
			"sqftTotal": 2311,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832086",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Newmark Cornish & Carey",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1941,
			"zipCode": "94061",
			"path": "redwood-city-ca/1102-1106-clinton-st-redwood-city-ca-94061-100_ml81832086/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -250000,
			"propertyId": "90bd536c-3807-4311-8014-a3d9e3785010",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-24 00:00:00",
			"createdAt": "2021-06-08 15:52:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.476934,
				"lng": -122.22803,
				"zipcode": "94061",
				"subPremise": "",
				"address": "1102-1106 Clinton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2127000,
			"daysOnMovoto": 0,
			"id": "5dddae92-d522-494f-b49f-f528b0bcb998",
			"tnImgPath": "https://pi.movoto.com/p/102/421573951_0_rYzj6v_p.jpeg",
			"listDate": null,
			"listingAgent": "Daniel Soldano",
			"listPrice": 1750000,
			"lotSize": 5700,
			"sqftTotal": 1750,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421573951",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Anchor Real Estate Company",
			"photoCount": 54,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1925,
			"zipCode": "94062",
			"path": "redwood-city-ca/427-avenue-del-ora-ave-redwood-city-ca-94062-102_421573951/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3d1caa37-e38e-4516-99c0-930b2ce5d355",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-20 00:00:00",
			"createdAt": "2021-07-16 23:44:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-16 23:44:26",
			"virtualTourLink": "https://player.vimeo.com/video/576223627",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Redwood City",
				"lat": 37.475874,
				"lng": -122.242944,
				"zipcode": "94062",
				"subPremise": "",
				"address": "427 Avenue Del Ora Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2127000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/576223627"
		}
	]