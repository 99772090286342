import React from "react"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import Badge from 'react-bootstrap/Badge'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PieChart from "highcharts-react-official";
import SparkLineTable from '../components/chart/SparkLineTable.jsx';
import cities from "../components/cities"
import cities_data from "../utils/city/city"
import school_data from "../utils/school/school"
import listing_map from "../utils/transactions/listing_map"
import high_school_ranks from "../utils/school/high_school_ranks"
import middle_school_ranks from "../utils/school/middle_school_ranks"
import elementary_school_ranks from "../utils/school/elementary_school_ranks"
import {Helmet} from "react-helmet"
import {FaBook} from "react-icons/fa"
import {isMobile} from 'react-device-detect';
import FeatureStyle6 from "../components/Features/FeatureStyle6"
import Ads from "../images/ads.jpg"
import Video from "../components/video"

import {SchoolList} from "../components/SchoolList"
import {SoldList} from "../components/SoldList"
import CallToAction2 from "../components/CallToAction/CallToAction2"
import CallToAction4 from "../components/CallToAction/CallToAction4"
import CallToAction5 from "../components/CallToAction/CallToAction5"
import Sell from "../images/sell2.jpg"
import Buy from "../images/buy2.jpg"
import {FaMailchimp,FaPhoneVolume,FaFacebookMessenger} from "react-icons/fa"
import { Link } from "gatsby"

// https://censusreporter.org/profiles/16000US0673262-south-san-francisco-ca/
// https://www.neighborhoods.com/belwood-los-gatos-ca
// https://www.realtor.com/realestateandhomes-search/San-Ramon_CA/overview
// https://www.realtor.com/local/api/v1/geo-landing/pages-service/overview?geo={"area_type":"city","_id":"city:ca_san-ramon","_score":0.861,"city":"San Ramon","state_code":"CA","country":"USA","centroid":{"lon":-121.9319874,"lat":37.7652582},"slug_id":"San-Ramon_CA","geo_id":"482b9df9-f246-576d-bc24-06daf928ef8c","county_needed_for_uniq":false,"county":"Contra Costa County","parents":[{"geo_type":"county","slug_id":"Contra-Costa-County_CA","name":"Contra Costa"},{"geo_type":"state","slug_id":"California","name":"California"}],"geo_statistics":{"housing_market":{}}}&slugId=San-Ramon_CA&debug_dc=&debug_fcu=&school={"education_level":"elementary","page":1}
// https://www.realtor.com/realestateandhomes-search/Southern-San-Ramon_San-Ramon_CA/overview
// https://www.realtor.com/local/api/v1/geo-landing/pages-service/overview?geo=%7B%22area_type%22%3A%22neighborhood%22%2C%22_id%22%3A%22neigh%3Aca_san-ramon_southern-san-ramon%22%2C%22_score%22%3A0.36%2C%22neighborhood%22%3A%22Southern%20San%20Ramon%22%2C%22city%22%3A%22San%20Ramon%22%2C%22state_code%22%3A%22CA%22%2C%22country%22%3A%22USA%22%2C%22centroid%22%3A%7B%22lon%22%3A-121.94078%2C%22lat%22%3A37.743821%7D%2C%22slug_id%22%3A%22Southern-San-Ramon_San-Ramon_CA%22%2C%22geo_id%22%3A%220d642d59-b947-5e58-9118-e4d3dc4fcf07%22%2C%22county%22%3A%22Contra%20Costa%20County%22%2C%22parents%22%3A%5B%7B%22geo_type%22%3A%22city%22%2C%22slug_id%22%3A%22San-Ramon_CA%22%2C%22name%22%3A%22San%20Ramon%22%7D%2C%7B%22geo_type%22%3A%22county%22%2C%22slug_id%22%3A%22Contra-Costa-County_CA%22%2C%22name%22%3A%22Contra%20Costa%22%7D%2C%7B%22geo_type%22%3A%22state%22%2C%22slug_id%22%3A%22California%22%2C%22name%22%3A%22California%22%7D%5D%2C%22geo_statistics%22%3A%7B%22housing_market%22%3A%7B%22local_hotness_score%22%3A84.84848485%2C%22national_hotness_score%22%3A78.17928%2C%22local_temperature%22%3A%22very_hot%22%2C%22national_temperature%22%3A%22hot%22%2C%22hot_market_badge%22%3A%22Hot%22%2C%22hot_market_rank%22%3A1622%2C%22ratio_of_days_on_market_vs_typical_property_in_county%22%3A-9%2C%22ratio_of_ldp_views_vs_typical_property_in_county%22%3A1.3019%2C%22ratio_of_days_on_market_vs_typical_property_in_us%22%3A-21%2C%22ratio_of_ldp_views_vs_typical_property_in_us%22%3A1.5165%7D%7D%7D&slugId=Southern-San-Ramon_San-Ramon_CA&debug_dc=&debug_fcu=&school=%7B%22education_level%22%3A%22elementary%22%2C%22page%22%3A1%7D&amenity=grocery

const City1page = (props) => {
  const {pathContext} = props
  const city = pathContext.city
  const statics = pathContext.statics
  const district_sales = pathContext.district_sales

  const high_school_ranks_dict = {}
  for (let high_school_rank of high_school_ranks) {
    if ("content" in high_school_rank) {
      const school_name = high_school_rank["content"]["entity"]["name"]
      high_school_ranks_dict[school_name] = "badge" in high_school_rank ? high_school_rank["badge"]["ordinal"] : "N/A"
      if (school_name.includes(" School")) {
        high_school_ranks_dict[school_name.slice(0, -7)] = "badge" in high_school_rank ? high_school_rank["badge"]["ordinal"] : "N/A"
      }
    }
  }

  const middle_school_ranks_dict = {}
  for (let middle_school_rank of middle_school_ranks) {
      if ("content" in middle_school_rank) {
        const school_name = middle_school_rank["content"]["entity"]["name"]
        middle_school_ranks_dict[school_name] = "badge" in middle_school_rank ? middle_school_rank["badge"]["ordinal"] : "N/A"
        if (school_name.includes(" School")) {
          middle_school_ranks_dict[school_name.slice(0, -7)] = "badge" in middle_school_rank ? middle_school_rank["badge"]["ordinal"] : "N/A"
        }
      }
  }

  const elementary_school_ranks_dict = {}
  for (let elementary_school_rank of elementary_school_ranks) {
    if ("content" in elementary_school_rank) {
      const school_name = elementary_school_rank["content"]["entity"]["name"]
      elementary_school_ranks_dict[school_name] = "badge" in elementary_school_rank ? elementary_school_rank["badge"]["ordinal"] : "N/A"
      if (school_name.includes(" School")) {
        elementary_school_ranks_dict[school_name.slice(0, -7)] = "badge" in elementary_school_rank ? elementary_school_rank["badge"]["ordinal"] : "N/A"
      }
    }
  }

  const cityData = cities_data[city]
  let schools = school_data[city]
  if (schools != null) {
    schools = schools.filter(item => ((item.name.includes("Elementary") || item.name.includes("Middle") || item.name.includes("High")) && item.studentsPerTeacher !== undefined && !item.name.includes("Continuation")))
  } else {
    schools = []
  }

  const listings = listing_map[city]
  const processedList = []
  if (listings !== undefined) {
    for (const listing of listings) {
      if (listing["propertyType"] !== "SINGLE_FAMILY_HOUSE" && listing["propertyType"] !== "CONDO") {
        continue
      }
      if (listing["propertyType"] === "SINGLE_FAMILY_HOUSE") {
        listing["propertyType"] = "独立屋"
      } else 
      if (listing["propertyType"] === "CONDO") {
        listing["propertyType"] = "联排别墅"
      }
      processedList.push(listing)
    }
  }

  for (const school of schools) {
    if (!school["name"].includes("School")) {
      school["name"] = school["name"] + " School"
    }
    if (school["name"] in high_school_ranks_dict) {
      school["ranking"] = high_school_ranks_dict[school["name"]]
      school["category"] = "高中"
    }
    if (school["name"] in middle_school_ranks_dict) {
      school["ranking"] = middle_school_ranks_dict[school["name"]]
      school["category"] = "中学"
    }
    if (school["name"] in elementary_school_ranks_dict) {
      school["ranking"] = elementary_school_ranks_dict[school["name"]]
      school["category"] = "小学"
    }
  }

  let options1 = {}
  let options2 = {}
  let options3 = {}
  let options4 = {}
  let tenureOptions = {}
  let educationalOptions = {}
  let ageOptions = {}
  if (cityData !== undefined) {
    const raceInfos = cityData["demographics"]["race"]
    const series = [];
    for (let race in raceInfos) {
      if (race === "metadata") {
        continue
      }
      series.push({ y: raceInfos[race]["values"]["this"], name: raceInfos[race]["name"] })
    }
    options1 = {
      title: {
          text: city + '族裔比例'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };
  
    const householdDistribution = cityData["economics"]["income"]["household_distribution"]
    const series2 = [];
    for (let household in householdDistribution) {
      if (household === "metadata") {
        continue
      }
      series2.push({ y: householdDistribution[household]["values"]["this"], name: householdDistribution[household]["name"] })
    }
    options2 = {
      title: {
          text: city + '家庭年收入分布'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series2
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };
  
    const ownershipDistribution = cityData["housing"]["ownership"]["distribution"]
    const series3 = [];
    for (let ownership in ownershipDistribution) {
      if (ownership === "metadata") {
        continue
      }
      series3.push({ y: ownershipDistribution[ownership]["values"]["this"], name: ownershipDistribution[ownership]["name"] })
    }
    options3 = {
      title: {
          text: city + '出租自住比例'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series3
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };
  
    const valueDistribution = cityData["housing"]["ownership"]["value_distribution"]
    const series4 = [];
    for (let value in valueDistribution) {
      if (value === "metadata") {
        continue
      }
      series4.push({ y: valueDistribution[value]["values"]["this"], name: valueDistribution[value]["name"] })
    }
    options4 = {
      title: {
          text: city + '房屋价格分布'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series4
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };
  
    const tenureDistribution = cityData["housing"]["length_of_tenure"]
    const series5 = [];
    for (let tenure in tenureDistribution) {
      if (tenure === "metadata") {
        continue
      }
      series5.push({ y: tenureDistribution[tenure]["values"]["this"], name: tenureDistribution[tenure]["name"] })
    }
    tenureOptions = {
      title: {
          text: city + '房龄分布'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series5
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };
  
    const educationalDistribution = cityData["social"]["educational_attainment_distribution"]
    const series6 = [];
    for (let educational in educationalDistribution) {
      if (educational === "metadata") {
        continue
      }
      series6.push({ y: educationalDistribution[educational]["values"]["this"], name: educationalDistribution[educational]["name"] })
    }
    educationalOptions = {
      title: {
          text: city + '居民教育程度比例'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series6
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };

    const ageDistribution = cityData["demographics"]["age"]["distribution_by_decade"]["total"]
    const series7 = [];
    for (let age in ageDistribution) {
      if (age === "metadata") {
        continue
      }
      series7.push({ y: ageDistribution[age]["values"]["this"], name: ageDistribution[age]["name"] })
    }
    ageOptions = {
      title: {
          text: city + '居民年龄分布'
      },
      chart: {
        type: "pie"
      },
      series: [
        {
          data: series7
        }
      ],
      plotOptions: {
        pie: {
           allowPointSelect: true,
           cursor: 'pointer',
           dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                 color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              }
           }
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    };  
  }
  const dates = statics.map(function(staticsItem){return staticsItem.date;});
  const prices = statics.map(function(staticsItem){return staticsItem.median_sales_price;});
  const solds = statics.map(function(staticsItem){return staticsItem.home_sold;});
  const new_listings = statics.map(function(staticsItem){return staticsItem.new_listing;});
  const inventory = statics.map(function(staticsItem){return staticsItem.inventory;});
  const days_on_market = statics.map(function(staticsItem){return staticsItem.days_on_market;});
  const average_sale_to_listing = statics.map(function(staticsItem){return staticsItem.average_sale_to_listing;});

  const cityInfo = cities[city]

  const options5 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        scrollPositionX: 1
      }
    },
    title: {
      text: '房屋中位价走势'
    },
    xAxis: {
      categories: dates.slice(-24),
      type: 'datetime',
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      title: {
        text: 'Price'
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
    },
    tooltip: {
      valueSuffix: 'K'
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: '中位价',
        data: prices.slice(-24)
      }
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    },
    credits: {
      text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
    }
  }

  const options6 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        scrollPositionX: 1
      }
    },
    title: {
      text: '售出房屋数量'
    },
    xAxis: {
      categories: dates.slice(-24),
      type: 'datetime',
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      title: {
        text: 'Transaction'
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
    },
    tooltip: {
      valueSuffix: ' transaction'
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: '售出数量',
        data: solds.slice(-24)
      }
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    },
    credits: {
      text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
    }
  }

  const options7 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        scrollPositionX: 1
      }
    },
    title: {
      text: '每月上市房屋数量'
    },
    xAxis: {
      categories: dates.slice(-24),
      type: 'datetime',
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      title: {
        text: 'Listing'
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
    },
    tooltip: {
      valueSuffix: ' listing'
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: '上市房屋数量',
        data: new_listings.slice(-24)
      }
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    },
    credits: {
      text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
    }
  }

  const options8 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        scrollPositionX: 1
      }
    },
    title: {
      text: '每月库存变化'
    },
    xAxis: {
      categories: dates.slice(-24),
      type: 'datetime',
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
    },
    tooltip: {
      valueSuffix: ' transaction'
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: '库存',
        data: inventory.slice(-24)
      }
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    },
    credits: {
      text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
    }
  }

  const options9 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        scrollPositionX: 1
      }
    },
    title: {
      text: '平均售出时间'
    },
    xAxis: {
      categories: dates.slice(-24),
      type: 'datetime',
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      title: {
        text: 'Day'
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
    },
    tooltip: {
      valueSuffix: ' day'
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: '天数',
        data: days_on_market.slice(-24)
      }
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    },
    credits: {
      text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
    }
  }

  const options10 = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        scrollPositionX: 1
      }
    },
    title: {
      text: '最终成交价格/上市价格'
    },
    xAxis: {
      categories: dates.slice(-24),
      type: 'datetime',
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      title: {
        text: 'Ratio'
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
    },
    tooltip: {
      valueSuffix: ' '
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: '百分比',
        data: average_sale_to_listing.slice(-24)
      }
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    },
    credits: {
      text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
    }
  }

  const elements = []
  for (const district in district_sales) {
    const median_sales_prices = []
    const home_solds = []
    const new_listings = []
    const inventories = []
    const days_on_markets = []
    const average_sale_to_listings = []
    for (const district_sales_item of district_sales[district]) {
      if (district_sales_item["median_sales_price"] !== null) {
        median_sales_prices.push(district_sales_item["median_sales_price"]);
      }
      if (district_sales_item["home_sold"] !== null) {
        home_solds.push(district_sales_item["home_sold"]);
      } else {
        home_solds.push(0);
      }
      if (district_sales_item["new_listing"] !== null) {
        new_listings.push(district_sales_item["new_listing"]);
      } else {
        new_listings.push(0);
      }
      if (district_sales_item["inventory"] !== null) {
        inventories.push(district_sales_item["inventory"]);
      } else {
        inventories.push(0);
      }
      if (district_sales_item["days_on_market"] !== null) {
        days_on_markets.push(district_sales_item["days_on_market"]);
      }
      if (district_sales_item["average_sale_to_listing"] !== null) {
        average_sale_to_listings.push(district_sales_item["average_sale_to_listing"] - 1);
      }
    }
    
    if (isMobile) {
      elements.push(
        <tr>
          <th>{district}</th>
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={median_sales_prices.slice(-12).join(', ')} />
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={average_sale_to_listings.slice(-12).join(', ') + " ; column"} />
        </tr>
      )
    } else {
      elements.push(
        <tr>
          <th>{district}</th>
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={median_sales_prices.slice(-12).join(', ')} />
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={home_solds.slice(-12).join(', ')} />
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={new_listings.slice(-12).join(', ')} />
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={inventories.slice(-12).join(', ')} />
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={days_on_markets.slice(-12).join(', ')} />
          <td style={{ padding: "5px", height: "40px" }} data-sparkline={average_sale_to_listings.slice(-12).join(', ') + " ; column"} />
        </tr>
      )
    }
  }

  const basicElements = []
  if (cityInfo.basicInfos) {
    for (const info of cityInfo.basicInfos) {
      if (info.type === 'text') {
        basicElements.push(
          <p key={info.value.length}>
            { info.value }
          </p>
        )
      } else if (info.type === 'img') {
        basicElements.push(
          <p key={info.value.length}>
            <img src={ info.value } alt={ info.desc } />
          </p>
        )
      } else if (info.type === 'link') {
        basicElements.push(
          <a href={info.value}><span><FaBook /></span>{info.desc}</a>
        )
        basicElements.push(
          <br/>
        )
      }
    }
  }

  const listingVideos = []
  if (cityInfo.videos) {
    for (const video of cityInfo.videos) {
      listingVideos.push(
        <div className="col-md-4">
          <Video
            videoSrcURL={video.link}
            videoTitle={video.title}
          />
        </div>
      )
    }
  }

  const schoolElements = []
  if (cityInfo.schoolData) {
    for (const info of cityInfo.schoolData) {
      if (info.type === 'text') {
        schoolElements.push(
          <p key={info.value.length}>
            { info.value }
          </p>
        )
      } else if (info.type === 'img') {
        schoolElements.push(
          <p key={info.value.length}>
            <img src={ info.value } alt={ info.desc } />
          </p>
        )
      } else if (info.type === 'link') {
        schoolElements.push(
          <a href={info.value}><span><FaBook /></span>{info.desc}</a>
        )
        schoolElements.push(
          <br/>
        )
      }
    }
  }

  const relatedElements = []
  if (cityInfo.related) {
    for (const relatedCity of cityInfo.related) {
      const relatedcityInfo = cities[relatedCity]
      if (relatedcityInfo.img === undefined) {
        continue
      }
      relatedElements.push(
        <div className="col-md-4">
          <FeatureStyle6
              image={relatedcityInfo.img}
              title={relatedCity}
              text={relatedcityInfo.title}
              url={relatedCity}
          />
        </div>
      )
    }
    
    relatedElements.push(
      <FeatureStyle6
          image={Ads}
          title="Jing Xue"
          text='湾区Top房产经纪'
          url=""
          hot={true}
      />
    )
  }

  const headers = []
  headers.push(
    <th>区域名称</th>
  )
  headers.push(
    <th>中位价</th>
  )
  if (!isMobile) {
    headers.push(
      <th>售出房产数量</th>
    )
    headers.push(
      <th>上市房产数量</th>
    )
    headers.push(
      <th>库存</th>
    )
    headers.push(
      <th>销售时间(天数)</th>
    )
  }
  headers.push(
    <th>最终成交价格/上市价格</th>
  )

  const title = city + "买房 | " + city + "房价 | " + city +  "简介 | " + cityInfo.title + " | " + city + "学区分析 学校排名 | " + city + " 近期销售分析 | " + city + " 族裔分析 | " + city + "房市分析 | Jing Xue | 三谷湾区置业专家 三谷Top华人房产经纪 | " + city + "房产经纪 | " + city + "房产"
  const description = cityInfo && cityInfo.description.length > 0 ? cityInfo.description[0] : title

  return (
    <>
      <HeaderStyle1 />
      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="Keywords" content={ city + "买房," + city + "房产经纪," + city + "简介," + city + "学区分析," +  city + "房产," + city + "投资房," + city + "房价," + "湾区,三谷,华人房产经纪,南湾,东湾,湾区学校排名,美国买房,美国房产,美国房价"}></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />
          </Helmet>
      </div>
      {/* Start image content Service Section*/}
      <section
        className="pad-t30 pad-b30"
        style={{ backgroundColor: "#f9f9f9", position: "relative" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-3"></div>
            <div className="col-md-8 col-sm-8">
              <h4
                style={{
                  fontSize: "25px",
                  lineHeight: "40px",
                  margin: "0 0 10px",
                  textTransform: "uppercase",
                }}
              >
                { city } <Badge variant="danger">Hot</Badge>
              </h4>
              <h5>
                { cityInfo.title }
              </h5>
              { cityInfo && cityInfo.description.map(desc => (
                <p key={desc.length}>
                  { desc }
                </p>
              )) }
            </div>
          </div>
        </div>
        {!isMobile && <div
          className="left-half width33"
        >
          <iframe
            title={city}
            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDr7gRsDPCn7v3tZ1jlD1_oOyiu9dg9M6s&zoom=12&q=" + city + "+California"}
            width="100%"
            height="600px"
            style={{ border: "0" }}
          ></iframe>
        </div>}
      </section>
      {/* End image content Service Section*/}
      <section>
          <div className="row">
              <div className="col-md-12">
                  <div className="mb-5">
                      <CallToAction4 bgColor="#222" paddingClass="pad50" text={"查看" + city + " neighourhood信息"} city={city}/>
                  </div>
              </div>
          </div>
      </section>
      {/* Start Portfolio Section*/}
      {basicElements.length > 0 && (<section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + " 城市基本信息"} />
            </div>
          </div>
          { basicElements }
        </div>
      </section>)}
      {/* End Portfolio Section*/}

      {/* Start Portfolio Section*/}
      {listingVideos && listingVideos.length > 0 && (<section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + "的Listing"} />
            </div>
          </div>
          <div className="row">
            { listingVideos }
          </div>
        </div>
      </section>)}
      {/* End Portfolio Section*/}
      {/* Start Feature Section */}
      <section
        className="pad-t20 pad-b20 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + "居民信息"} />
            </div>
          </div>
          { cityInfo && cityInfo.residenceInfos && cityInfo.residenceInfos.map(desc => (
            <p key={desc.length}>
              { desc }
            </p>
          )) }
          
          { cityData && <p>根据2019年人口普查，{city}总人口为 {cityData["geography"]["this"]["total_population"]} 人， 
          有{cityData["families"]["households"]["number_of_households"]["values"]["this"]} 户家庭，
          住房总数为{cityData["housing"]["units"]["number"]["values"]["this"]} ，
          白人比例{cityData["demographics"]["race"]["percent_white"]["values"]["this"]}%,
          亚裔比例{cityData["demographics"]["race"]["percent_asian"]["values"]["this"]}%，
          黑人比例{cityData["demographics"]["race"]["percent_black"]["values"]["this"]}%，
          美洲原住民比例{cityData["demographics"]["race"]["percent_native"]["values"]["this"]}%，
          平均年龄{cityData["demographics"]["age"]["median_age"]["total"]["values"]["this"]}岁，
          移民人口比例{cityData["social"]["place_of_birth"]["percent_foreign_born"]["values"]["this"]}%，
          本科以上学历人口比例{cityData["social"]["educational_attainment"]["percent_bachelor_degree_or_higher"]["values"]["this"]}%，
          硕士以上学历人口比例{cityData["social"]["educational_attainment_distribution"]["post_grad_degree"]["values"]["this"]}%，
          家庭年平均收入为{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cityData["economics"]["income"]["median_household_income"]["values"]["this"])}，
          有{cityData["economics"]["poverty"]["percent_below_poverty_line"]["values"]["this"]}% 人口收入位于贫困线以下，
          房屋平均价格为{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cityData["housing"]["ownership"]["median_value"]["values"]["this"])}。</p> }
          <div className="row">
            <div className="col-md-6 text-center">
              <PieChart
                highcharts={Highcharts}
                options={options1}
              />
            </div>
            <div className="col-md-6 text-center">
              <PieChart
                highcharts={Highcharts}
                options={options2}
              />
            </div>
            <div className="col-md-6 text-center">
              <PieChart
                highcharts={Highcharts}
                options={educationalOptions}
              />
            </div>
            <div className="col-md-6 text-center">
              <PieChart
                highcharts={Highcharts}
                options={ageOptions}
              />
            </div>
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {
        cityInfo.estatemap && 
        (<section>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-5">
                        <CallToAction5 bgColor="#222" paddingClass="pad50" text={"Jing Xue湾区房产地图 -- " + city} url={"/" + cityInfo.estatemap}/>
                    </div>
                </div>
            </div>
        </section>)
      }
      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-t30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + "房产基本信息"} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <PieChart
                highcharts={Highcharts}
                options={options3}
              />
            </div>
            <div className="col-md-6 text-center">
              <PieChart
                highcharts={Highcharts}
                options={options4}
              />
            </div>
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      
      {/* Start Latest News Section*/}
      <section
        className="pad-t80 pad-b30"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="latest-news">
              <Link to="/buy">
                <div className="latest-news-img">
                  <img src={Buy} alt="blog-post" />
                  <div>
                    <span>HOT</span>
                  </div>
                </div>
              </Link>
                <ul className="fa-ul">
                  <li>
                  <FaPhoneVolume/>电话: (650) 542 1628
                  </li>
                  <li>
                  <FaMailchimp/>Email:xuesimone@gmail.com
                  </li>
                </ul>
                <p>
                  熟悉{city}居民、neighourhood社区信息，在{city}拥有丰富的经纪人网络，可以帮助客户以最低的价格拿到心仪的房子
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="latest-news">
              <Link to="/sell">
                <div className="latest-news-img">
                  <img src={Sell} alt="blog-post" />
                  <div>
                    <span>HOT</span>
                  </div>
                </div>
              </Link>
                <ul className="fa-ul">
                  <li>
                  <FaPhoneVolume/>电话: (650) 542 1628
                  </li>
                  <li>
                  <FaFacebookMessenger/>微信: topagentjingxue
                  </li>
                </ul>
                <p>
                  在最短的时间内帮您把房子卖到最高的价格是我们的目标。在湾区多个小区都卖出过当时全小区最高价。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Latest News Section*/}

      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + "近24个月房产市场"} />
            </div>
          </div>
          <p>{city}上月上市房屋{new_listings[new_listings.length - 1]}套，平均库存{inventory[inventory.length - 1]}套，售出房屋{solds[solds.length - 1]}套，
          中位价为{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices[prices.length - 1])},
          平均在售天数为{days_on_market[days_on_market.length - 1]}天，
          最终成交价格/上市价格为{new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits:2 }).format(average_sale_to_listing[average_sale_to_listing.length - 1])}。</p>
          <div className="row">
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options5}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options6}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options7}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options8}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options9}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options10}
              />
            </div>
          </div>
        </div>
      </section>
      {/* End Feature Section */}

      <section>
          <div className="row">
              <div className="col-md-12">
                  <div className="mb-5">
                      <CallToAction2 bgColor="#222" paddingClass="pad50" text={"联系Jing获取更多城市、居民、neighourhood信息"}/>
                  </div>
              </div>
          </div>
      </section>
      {/* Start Portfolio Section*/}
      {schools.length > 0 && (
      <div>
        <section className="pad-t30 pad-b30">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <SectionTitle title={city + " 公立学校"} />
              </div>
            </div>
            { cityInfo && cityInfo.schoolInfos && cityInfo.schoolInfos.map(desc => (
              <p key={desc.length}>
                { desc }
              </p>
            )) }
            <p>
                加州公立学测试分数: <a href="https://caaspp-elpac.cde.ca.gov/caaspp/">https://caaspp-elpac.cde.ca.gov/caaspp/</a>
            </p>
            <p>
                加州学校评分: <a href="https://school-ratings.com/citie">https://school-ratings.com/citie</a>
            </p>
            {
              schoolElements.length > 0 && schoolElements
            }
            <div className="row">
                <SchoolList schools={schools} />
            </div>
          </div>
        </section>
                
        <section>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-5">
                        <CallToAction2 bgColor="#222" paddingClass="pad50" text={"联系Jing Xue获取更多" + city + "学区信息"}/>
                    </div>
                </div>
            </div>
        </section>
      </div>
      )}
      {/* End Portfolio Section*/}
      {/* Start Portfolio Section*/}
      {elements.length > 0 && (<section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + "各区域房市"} />
            </div>
          </div>
          <div className="row">
            <SparkLineTable>
              <thead>
                <tr>
                  {headers}
                </tr>
              </thead>
              <tbody id="tbody-sparkline">
                {elements}
              </tbody>
            </SparkLineTable>
          </div>
        </div>
      </section>)}
      {/* End Portfolio Section*/}
      {/* Start Portfolio Section*/}
      {processedList && processedList.length > 0 && (<section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + " 近期交易"} />
            </div>
          </div>
          <div className="row">
              <SoldList listings={processedList} />
          </div>
        </div>
      </section>)}
      {/* End Portfolio Section*/}
      <section className="pad-t30 pad-b30">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                  <SectionTitle title="相关城市" />
                  </div>
              </div>
              <div className="row">
                  {relatedElements}
              </div>
          </div>
      </section>
      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default City1page
