exports.SAN_BRUNO_SCHOOLS = [
		{
			"id": 6937,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "e",
			"lat": 37.624805,
			"lon": -122.416016,
			"name": "Allen (Decima M.) Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "875 Angus Avenue West",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6937-Allen-Decima-M.-Elementary-School/",
				"reviews": "/california/san-bruno/6937-Allen-Decima-M.-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6937-Allen-Decima-M.-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 409,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 59
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6938,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "e",
			"lat": 37.624428,
			"lon": -122.405861,
			"name": "Belle Air Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "450 3rd Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 1,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6938-Belle-Air-Elementary-School/",
				"reviews": "/california/san-bruno/6938-Belle-Air-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6938-Belle-Air-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "70%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 264,
			"parentRating": 2,
			"numReviews": 3,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 1,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 70
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 66
				},
				{
					"label": "Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "White",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 21940,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.617798,
			"lon": -122.423225,
			"name": "Bright Star Pre-School & Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "498 Cunningham Way",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21940-Bright-Star-Pre-School--Child-Care-Center/",
				"reviews": "/california/san-bruno/21940-Bright-Star-Pre-School--Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/san-bruno/21940-Bright-Star-Pre-School--Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21939,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.630489,
			"lon": -122.411087,
			"name": "California Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 341",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21939-California-Montessori-School/",
				"reviews": "/california/san-bruno/21939-California-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/21939-California-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6977,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.609737,
			"lon": -122.406372,
			"name": "Capuchino High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1501 Magnolia Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6977-Capuchino-High-School/",
				"reviews": "/california/san-bruno/6977-Capuchino-High-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6977-Capuchino-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "37%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "81%",
				"state_average": "64%"
			},
			"enrollment": 1187,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 37
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 49
				},
				{
					"label": "Pacific Islander",
					"rating": 6,
					"percentage": 4
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 6
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21938,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.625393,
			"lon": -122.45356,
			"name": "Champions Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 Amador Ave",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21938-Champions-Child-Care-Center/",
				"reviews": "/california/san-bruno/21938-Champions-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/san-bruno/21938-Champions-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28604,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.624817,
			"lon": -122.453392,
			"name": "Champions Day Care-Portola",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 Amador Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/28604-Champions-Day-Care-Portola/",
				"reviews": "/california/san-bruno/28604-Champions-Day-Care-Portola/#Reviews",
				"collegeSuccess": "/california/san-bruno/28604-Champions-Day-Care-Portola/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29101,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.615112,
			"lon": -122.402611,
			"name": "Club Happy Hall Green Hills",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "233 Santa Inez Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/29101-Club-Happy-Hall-Green-Hills/",
				"reviews": "/california/san-bruno/29101-Club-Happy-Hall-Green-Hills/#Reviews",
				"collegeSuccess": "/california/san-bruno/29101-Club-Happy-Hall-Green-Hills/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21943,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.61248,
			"lon": -122.427071,
			"name": "Crayon College Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 Piedmont Avenue #209",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21943-Crayon-College-Pre-School/",
				"reviews": "/california/san-bruno/21943-Crayon-College-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/21943-Crayon-College-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6940,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "e",
			"lat": 37.614471,
			"lon": -122.412971,
			"name": "El Crystal Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "201 North Balboa Way",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6940-El-Crystal-Elementary-School/",
				"reviews": "/california/san-bruno/6940-El-Crystal-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6940-El-Crystal-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "33%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 262,
			"parentRating": 5,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 33
				},
				{
					"label": "Hispanic",
					"percentage": 39
				},
				{
					"label": "White",
					"percentage": 21
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 12372,
			"districtId": 717,
			"districtName": "San Francisco Unified",
			"districtCity": "San Francisco",
			"levelCode": "h",
			"lat": 37.62711,
			"lon": -122.46032,
			"name": "Five Keys Charter (SF Sheriff'S) School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1 Moreland Drive",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/12372-Five-Keys-Charter-SF-SheriffS-School/",
				"reviews": "/california/san-bruno/12372-Five-Keys-Charter-SF-SheriffS-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/12372-Five-Keys-Charter-SF-SheriffS-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "17%",
				"state_average": "64%"
			},
			"enrollment": 348,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 47
				},
				{
					"label": "African American",
					"percentage": 28
				},
				{
					"label": "White",
					"percentage": 18
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13149,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.615112,
			"lon": -122.402611,
			"name": "Happy Hall Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "233 Santa Inez Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/13149-Happy-Hall-Pre-School/",
				"reviews": "/california/san-bruno/13149-Happy-Hall-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/13149-Happy-Hall-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8777,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.623131,
			"lon": -122.447487,
			"name": "Highlands Christian Schools",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1900 Monterey Drive",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/8777-Highlands-Christian-Schools/",
				"reviews": "/california/san-bruno/8777-Highlands-Christian-Schools/#Reviews",
				"collegeSuccess": "/california/san-bruno/8777-Highlands-Christian-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 555,
			"parentRating": 4,
			"numReviews": 24,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 63
				},
				{
					"label": "Hispanic",
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 20495,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.625477,
			"lon": -122.453415,
			"name": "Hoover Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 Amador Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/20495-Hoover-Childrens-Center/",
				"reviews": "/california/san-bruno/20495-Hoover-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/san-bruno/20495-Hoover-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21510,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.624817,
			"lon": -122.453392,
			"name": "Hoover Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 Amador Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21510-Hoover-Childrens-Center/",
				"reviews": "/california/san-bruno/21510-Hoover-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/san-bruno/21510-Hoover-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21937,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.624817,
			"lon": -122.453392,
			"name": "Hoover Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 Amador Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21937-Hoover-Childrens-Center/",
				"reviews": "/california/san-bruno/21937-Hoover-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/san-bruno/21937-Hoover-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21941,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.630489,
			"lon": -122.411087,
			"name": "Hugs Hangout",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "500 Acacia Ave Rm Pc-1",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21941-Hugs-Hangout/",
				"reviews": "/california/san-bruno/21941-Hugs-Hangout/#Reviews",
				"collegeSuccess": "/california/san-bruno/21941-Hugs-Hangout/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6941,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "e",
			"lat": 37.613728,
			"lon": -122.436539,
			"name": "John Muir Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "130 Cambridge Lane",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6941-John-Muir-Elementary-School/",
				"reviews": "/california/san-bruno/6941-John-Muir-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6941-John-Muir-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "17%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 437,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 17
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 31
				},
				{
					"label": "Filipino",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6905,
			"districtId": 792,
			"districtName": "Millbrae Elementary",
			"districtCity": "Millbrae",
			"levelCode": "e",
			"lat": 37.612137,
			"lon": -122.400269,
			"name": "Lomita Park Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "200 Santa Helena Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6905-Lomita-Park-Elementary-School/",
				"reviews": "/california/san-bruno/6905-Lomita-Park-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6905-Lomita-Park-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 309,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 58
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 54
				},
				{
					"label": "Asian",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6990,
			"districtId": 801,
			"districtName": "South San Francisco Unified",
			"districtCity": "South San Francisco",
			"levelCode": "e",
			"lat": 37.636467,
			"lon": -122.45208,
			"name": "Monte Verde Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2551 Saint Cloud Drive",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6990-Monte-Verde-Elementary-School/",
				"reviews": "/california/san-bruno/6990-Monte-Verde-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6990-Monte-Verde-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "23%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 530,
			"parentRating": 5,
			"numReviews": 11,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 23
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22543,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.615311,
			"lon": -122.421478,
			"name": "Muslim Children's Garden",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1999 Niles Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/22543-Muslim-Childrens-Garden/",
				"reviews": "/california/san-bruno/22543-Muslim-Childrens-Garden/#Reviews",
				"collegeSuccess": "/california/san-bruno/22543-Muslim-Childrens-Garden/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6942,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "m",
			"lat": 37.615677,
			"lon": -122.418701,
			"name": "Parkside Intermediate",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1801 Niles Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6942-Parkside-Intermediate-School/",
				"reviews": "/california/san-bruno/6942-Parkside-Intermediate-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6942-Parkside-Intermediate-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "39%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 789,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 39
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 41
				},
				{
					"label": "Pacific Islander",
					"rating": 3,
					"percentage": 7
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 21942,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.620098,
			"lon": -122.440872,
			"name": "Peace Lutheran Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "850 Glenview Dr",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21942-Peace-Lutheran-Childrens-Center/",
				"reviews": "/california/san-bruno/21942-Peace-Lutheran-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/san-bruno/21942-Peace-Lutheran-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31142,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.612354,
			"lon": -122.42823,
			"name": "Peninsula High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "300 Piedmont Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/31142-Peninsula-High/",
				"reviews": "/california/san-bruno/31142-Peninsula-High/#Reviews",
				"collegeSuccess": "/california/san-bruno/31142-Peninsula-High/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 19,
			"parentRating": 3,
			"numReviews": 2,
			"studentsPerTeacher": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 63
				},
				{
					"label": "Hispanic",
					"percentage": 79
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 32726,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.612354,
			"lon": -122.42823,
			"name": "Peninsula High (Continuation)",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "300 Piedmont Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/32726-Peninsula-High-Continuation/",
				"reviews": "/california/san-bruno/32726-Peninsula-High-Continuation/#Reviews",
				"collegeSuccess": "/california/san-bruno/32726-Peninsula-High-Continuation/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "27%",
				"state_average": "64%"
			},
			"enrollment": 186,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 11,
			"subratings": {
				"Test Scores Rating": 1,
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 62
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 67
				},
				{
					"label": "Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "White",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 11860,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "e",
			"lat": 37.625027,
			"lon": -122.452362,
			"name": "Portola Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "300 Amador Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/11860-Portola-Elementary-School/",
				"reviews": "/california/san-bruno/11860-Portola-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/11860-Portola-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "12%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 337,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 27,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 12
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 19
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 20
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21506,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.613796,
			"lon": -122.434731,
			"name": "Rainbow Pre-School & Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2598 Bennington Drive",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21506-Rainbow-Pre-School--Day-Care/",
				"reviews": "/california/san-bruno/21506-Rainbow-Pre-School--Day-Care/#Reviews",
				"collegeSuccess": "/california/san-bruno/21506-Rainbow-Pre-School--Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6943,
			"districtId": 796,
			"districtName": "San Bruno Park Elementary",
			"districtCity": "San Bruno",
			"levelCode": "e",
			"lat": 37.630924,
			"lon": -122.444511,
			"name": "Rollingwood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2500 Cottonwood Drive",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/6943-Rollingwood-Elementary-School/",
				"reviews": "/california/san-bruno/6943-Rollingwood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/6943-Rollingwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 262,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 38
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 27
				},
				{
					"label": "Filipino",
					"percentage": 19
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Pacific Islander",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 21935,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.613083,
			"lon": -122.415741,
			"name": "St Andrew's Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1600 Santa Lucia Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/21935-St-Andrews-Pre-School/",
				"reviews": "/california/san-bruno/21935-St-Andrews-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-bruno/21935-St-Andrews-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15086,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.617264,
			"lon": -122.41571,
			"name": "St. Robert",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "345 Oak Avenue",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/15086-St.-Robert/",
				"reviews": "/california/san-bruno/15086-St.-Robert/#Reviews",
				"collegeSuccess": "/california/san-bruno/15086-St.-Robert/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 314,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "Asian",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 12
				}
			],
			"remediationData": {}
		},
		{
			"id": 27859,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.624912,
			"lon": -122.434616,
			"name": "Stratford School - Crestmoor Canyon Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2322 Crestmoor Drive",
				"street2": null,
				"zip": "94066",
				"city": "San Bruno"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94066",
			"type": "school",
			"links": {
				"profile": "/california/san-bruno/27859-Stratford-School---Crestmoor-Canyon-Preschool/",
				"reviews": "/california/san-bruno/27859-Stratford-School---Crestmoor-Canyon-Preschool/#Reviews",
				"collegeSuccess": "/california/san-bruno/27859-Stratford-School---Crestmoor-Canyon-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]