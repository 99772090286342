exports.TRACY_LISTINGS = [
		{
			"closePrice": 675000,
			"daysOnMovoto": 0,
			"id": "89e08e2a-a62a-41e5-ab9e-8d9237255f14",
			"tnImgPath": "https://pi.movoto.com/p/102/221149208_0_azQY2u_p.jpeg",
			"listDate": null,
			"listingAgent": "Bernadette Whisman",
			"listPrice": 630000,
			"lotSize": 5998,
			"sqftTotal": 1536,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221149208",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "All in One Property Management",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1996,
			"zipCode": "95377",
			"path": "tracy-ca/2091-iroula-way-tracy-ca-95377-102_15022819/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3cd20b79-5590-4dc1-84df-a5023fc48f9f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-12-02 18:34:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 18:34:26",
			"virtualTourLink": "http://youriguide.com/2091_iroula_way_tracy_ca",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.746777,
				"lng": -121.455059,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2091 Iroula Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://youriguide.com/2091_iroula_way_tracy_ca"
		},
		{
			"closePrice": 387500,
			"daysOnMovoto": 0,
			"id": "e9f4dbb0-7ac3-42e9-b80a-e00ce8206b75",
			"tnImgPath": "https://pi.movoto.com/p/102/221140261_0_ffFFFJ_p.jpeg",
			"listDate": null,
			"listingAgent": "Joseph Saar",
			"listPrice": 419000,
			"lotSize": 5001,
			"sqftTotal": 826,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221140261",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Century 21 Select Real Estate",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1928,
			"zipCode": "95376",
			"path": "tracy-ca/219-w-whittier-ave-tracy-ca-95376/pid_iyxqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -41000,
			"propertyId": "08b88230-c770-4dcd-8b00-32e2d1027008",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-11-04 07:14:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 07:14:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.749879,
				"lng": -121.428364,
				"zipcode": "95376",
				"subPremise": "",
				"address": "219 W Whittier Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 387500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 670000,
			"daysOnMovoto": 0,
			"id": "54bd4475-1652-489e-828e-e9467f04459c",
			"tnImgPath": "https://pi.movoto.com/p/102/221151849_0_aBrryN_p.jpeg",
			"listDate": null,
			"listingAgent": "Haseeb Halimi",
			"listPrice": 649950,
			"lotSize": 4251,
			"sqftTotal": 2077,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151849",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty ONE Group Zoom",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1990,
			"zipCode": "95376",
			"path": "tracy-ca/1914-paradise-valley-ct-tracy-ca-95376/pid_vggqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7fcd15e-5cc4-419f-8b39-1c0e3b2294d0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-12-09 21:49:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 21:49:38",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.754462,
				"lng": -121.451131,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1914 Paradise Valley Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 385000,
			"daysOnMovoto": 0,
			"id": "dbc6b354-983f-40ab-928d-54d21233a0c4",
			"tnImgPath": "https://pi.movoto.com/p/102/221147652_0_JEijeY_p.jpeg",
			"listDate": null,
			"listingAgent": "Brenda Singleton",
			"listPrice": 375000,
			"lotSize": 928,
			"sqftTotal": 1026,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147652",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "HomeSmart PV & Associates",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1981,
			"zipCode": "95376",
			"path": "tracy-ca/2303-east-st-tracy-ca-95376-102_15036826/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aa2fa51d-9ac3-4dc0-bbff-b32e9b069caa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-11-24 19:34:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-24 19:34:28",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.753005,
				"lng": -121.421435,
				"zipcode": "95376",
				"subPremise": "",
				"address": "2303 East St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 385000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 300000,
			"daysOnMovoto": 0,
			"id": "77218835-7bb7-4e90-8135-045fcd61ffc6",
			"tnImgPath": "https://pi.movoto.com/p/102/221140023_0_uqNNI6_p.jpeg",
			"listDate": null,
			"listingAgent": "Harbakhsh Randhawa",
			"listPrice": 312950,
			"lotSize": 7687,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221140023",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Great West Property Group",
			"photoCount": 4,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95377",
			"path": "tracy-ca/426-darlene-ln-tracy-ca-95377-102_18062434/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "88832a85-cf92-4713-8f14-e11ba434ff7d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-11-02 22:14:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 22:14:24",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.704159,
				"lng": -121.41948,
				"zipcode": "95377",
				"subPremise": "",
				"address": "426 Darlene Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 0,
			"id": "bfc7d161-2c3b-4b9a-acf0-9c715a2f08ae",
			"tnImgPath": "https://pi.movoto.com/p/102/221135685_0_mYIJBv_p.jpeg",
			"listDate": null,
			"listingAgent": "Susan Wise",
			"listPrice": 750000,
			"lotSize": 43560,
			"sqftTotal": 1416,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221135685",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1942,
			"zipCode": "95304",
			"path": "tracy-ca/24301-s-chrisman-rd-tracy-ca-95304-12_40088394/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bab58b33-ed65-489a-8ed5-ec417116532b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-10-23 03:24:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-23 03:24:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.736908,
				"lng": -121.398605,
				"zipcode": "95304",
				"subPremise": "",
				"address": "24301 S Chrisman Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 725000,
			"daysOnMovoto": 0,
			"id": "b02cd2e4-66df-47ca-b764-f3aa35c60487",
			"tnImgPath": "https://pi.movoto.com/p/102/221150008_0_E7rJfR_p.jpeg",
			"listDate": null,
			"listingAgent": "Julie Williams",
			"listPrice": 705000,
			"lotSize": 4626,
			"sqftTotal": 2176,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221150008",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "HomeSmart PV & Associates",
			"photoCount": 54,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2002,
			"zipCode": "95377",
			"path": "tracy-ca/861-kennedy-place-tracy-ca-95377-102_13058243/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3478ec8b-06b7-4911-9346-5f5cac27fce3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-09 20:34:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 20:34:31",
			"virtualTourLink": "https://my.matterport.com/show/?m=yHwuD1UfAkr",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.736944,
				"lng": -121.47117,
				"zipcode": "95377",
				"subPremise": "",
				"address": "861 Kennedy Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=yHwuD1UfAkr"
		},
		{
			"closePrice": 938000,
			"daysOnMovoto": 0,
			"id": "8f5b4f9a-577f-4fa0-bda5-ddd3bb799486",
			"tnImgPath": "https://pi.movoto.com/p/102/221144428_0_iViVFq_p.jpeg",
			"listDate": null,
			"listingAgent": "Thomas Simpson",
			"listPrice": 975000,
			"lotSize": 9788,
			"sqftTotal": 2453,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221144428",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Got Agent, Inc.",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2006,
			"zipCode": "95377",
			"path": "tracy-ca/368-jason-ct-tracy-ca-95377/pid_k5hrr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "32befcca-fd52-46e8-90de-95cdad4d4d7c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-11-17 19:29:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 19:29:40",
			"virtualTourLink": "http://vtours.k-robbdesigns.com/368-jason-court-tracy/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.699136,
				"lng": -121.419643,
				"zipcode": "95377",
				"subPremise": "",
				"address": "368 Jason Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 938000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.k-robbdesigns.com/368-jason-court-tracy/"
		},
		{
			"closePrice": 750000,
			"daysOnMovoto": 0,
			"id": "a4571b25-aa18-4e46-80e4-177303c0ecfe",
			"tnImgPath": "https://pi.movoto.com/p/102/221125141_0_rvmauE_p.jpeg",
			"listDate": null,
			"listingAgent": "Amber Foster",
			"listPrice": 750000,
			"lotSize": 7327,
			"sqftTotal": 2095,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221125141",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Century 21 MM",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1998,
			"zipCode": "95377",
			"path": "tracy-ca/2293-joseph-damon-tracy-ca-95377-102_221125141/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -35000,
			"propertyId": "7631f855-9c90-4f99-9267-e1f766e90fb8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-09-30 19:14:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 19:14:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.753051,
				"lng": -121.458924,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2293 Joseph Damon"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 755000,
			"daysOnMovoto": 0,
			"id": "44c78880-c668-46df-a555-98fe37e41bfc",
			"tnImgPath": "https://pi.movoto.com/p/102/221152340_0_QMmbaM_p.jpeg",
			"listDate": null,
			"listingAgent": "Jeff Purcell",
			"listPrice": 725000,
			"lotSize": 4596,
			"sqftTotal": 1880,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221152340",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Home Buyers Realty",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1992,
			"zipCode": "95376",
			"path": "tracy-ca/1540-tahoe-cir-tracy-ca-95376/pid_ec9qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bef4dd63-9ca7-44a4-bfef-cab94792c0e9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-12-11 21:24:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-11 21:24:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.720572,
				"lng": -121.43515,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1540 Tahoe Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 755000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 897000,
			"daysOnMovoto": 0,
			"id": "296838a1-8584-4069-8e18-d4ba92ddf46f",
			"tnImgPath": "https://pi.movoto.com/p/102/221150496_0_YfjM6y_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 860000,
			"lotSize": 8019,
			"sqftTotal": 2789,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221150496",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "95377",
			"path": "tracy-ca/35-belize-ct-tracy-ca-95377/pid_z8irr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9b2ae27f-fa77-4173-b6ff-1d97c4523296",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-12-08 15:49:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-08 15:49:24",
			"virtualTourLink": "http://35belize.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.700376,
				"lng": -121.425685,
				"zipcode": "95377",
				"subPremise": "",
				"address": "35 Belize Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 897000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://35belize.com"
		},
		{
			"closePrice": 450000,
			"daysOnMovoto": 0,
			"id": "81f0b89e-7fa5-4a88-a03b-a806625013c1",
			"tnImgPath": "https://pi.movoto.com/p/102/221117720_0_BQARyz_p.jpeg",
			"listDate": null,
			"listingAgent": "Clarence Oliveira",
			"listPrice": 425000,
			"lotSize": 3297,
			"sqftTotal": 1000,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221117720",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Century 21 MM",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1927,
			"zipCode": "95376",
			"path": "tracy-ca/337-w-9th-st-tracy-ca-95376/pid_ub3qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -14900,
			"propertyId": "72d7a5bd-f5e2-4411-aecc-eec452ff63e1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-09-22 23:29:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 23:29:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.737522,
				"lng": -121.431221,
				"zipcode": "95376",
				"subPremise": "",
				"address": "337 W 9th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 670000,
			"daysOnMovoto": 0,
			"id": "1ed2f7d6-78f8-413c-bf0d-82932728be62",
			"tnImgPath": "https://pi.movoto.com/p/102/221154648_0_Eviava_p.jpeg",
			"listDate": null,
			"listingAgent": "Jessica Kuspa",
			"listPrice": 624800,
			"lotSize": 6948,
			"sqftTotal": 1705,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221154648",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty Executives",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1994,
			"zipCode": "95377",
			"path": "tracy-ca/733-glenpine-tracy-ca-95377-102_221154648/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e1469891-1f36-456a-9e60-66e4a77570d1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-27 08:14:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-27 08:14:15",
			"virtualTourLink": "https://youriguide.com/733_glenpine_ct_tracy_ca",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.709721,
				"lng": -121.435819,
				"zipcode": "95377",
				"subPremise": "",
				"address": "733 Glenpine"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://youriguide.com/733_glenpine_ct_tracy_ca"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 0,
			"id": "afffc74c-8a6e-44ae-92d8-8ea2d2a61508",
			"tnImgPath": "https://pi.movoto.com/p/102/221150677_0_bfyMJa_p.jpeg",
			"listDate": null,
			"listingAgent": "Claudette Medeiros",
			"listPrice": 945000,
			"lotSize": 65776,
			"sqftTotal": 2120,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221150677",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Valley Central",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1983,
			"zipCode": "95304",
			"path": "tracy-ca/4396-w-grant-line-rd-tracy-ca-95304/pid_307qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "05cdfb22-eaf5-4e53-afc2-d01a126ebc0c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-07 00:04:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:04:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.753794,
				"lng": -121.360462,
				"zipcode": "95304",
				"subPremise": "",
				"address": "4396 W Grant Line Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1065000,
			"daysOnMovoto": 0,
			"id": "a5b1fcac-82e8-476c-9abb-617b0992bf24",
			"tnImgPath": "https://pi.movoto.com/p/102/221150652_0_7aEvEe_p.jpeg",
			"listDate": null,
			"listingAgent": "Steven Majourau",
			"listPrice": 980000,
			"lotSize": 6098,
			"sqftTotal": 3533,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221150652",
			"bath": 5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Redfin Corporation",
			"photoCount": 45,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2019,
			"zipCode": "95377",
			"path": "tracy-ca/2547-salinger-ave-tracy-ca-95377-102_221150652/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0a0d97af-9c78-41ea-b1e8-2f79d7956f40",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-06 23:49:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 23:49:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.680341,
				"lng": -121.463985,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2547 Salinger Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1065000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 370000,
			"daysOnMovoto": 0,
			"id": "6c2e2158-414c-4c95-80c2-5212f22b8521",
			"tnImgPath": "https://pi.movoto.com/p/102/221128697_0_BfAjEE_p.jpeg",
			"listDate": null,
			"listingAgent": "LeEtta Celestine",
			"listPrice": 360000,
			"lotSize": 1999,
			"sqftTotal": 1095,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221128697",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Leetta Y.Celestine, Broker",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "95376",
			"path": "tracy-ca/656-palm-cir-tracy-ca-95376/pid_mr1qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7cf85a83-2df2-4524-b62c-f32069b7a677",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-11-16 08:14:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 08:14:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.735543,
				"lng": -121.43895,
				"zipcode": "95376",
				"subPremise": "",
				"address": "656 Palm Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 370000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 0,
			"id": "37e4a9fa-c5cf-43af-8b1f-99df23dfa867",
			"tnImgPath": "https://pi.movoto.com/p/102/221151974_0_MUaV3Z_p.jpeg",
			"listDate": null,
			"listingAgent": "Tami Holtz",
			"listPrice": 785000,
			"lotSize": 7375,
			"sqftTotal": 2331,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151974",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Go2Realty Pros, Inc.",
			"photoCount": 71,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2003,
			"zipCode": "95377",
			"path": "tracy-ca/2852-ralston-way-tracy-ca-95377-102_11085334/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eae842e1-518a-4d61-beb3-e82ff4da5963",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-12-10 00:54:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-10 00:54:21",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/2852-ralston-way-tracy-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.73622,
				"lng": -121.468805,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2852 Ralston Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/2852-ralston-way-tracy-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 655000,
			"daysOnMovoto": 0,
			"id": "d7d8d3f2-cacd-4048-9aeb-c58f9ca4ed6f",
			"tnImgPath": "https://pi.movoto.com/p/102/221137464_0_mfnMbZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Hamed Barakzoy",
			"listPrice": 599000,
			"lotSize": 4500,
			"sqftTotal": 1764,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221137464",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty World Acclaim",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1998,
			"zipCode": "95376",
			"path": "tracy-ca/1725-countrywood-ln-tracy-ca-95376/pid_ksxqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d0fb877-6e75-42be-9c0a-3cfb558b5ac2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-10-27 05:49:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 05:49:12",
			"virtualTourLink": "https://my.matterport.com/show/?m=mbqN4rraDET",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.744095,
				"lng": -121.447783,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1725 Countrywood Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 655000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=mbqN4rraDET"
		},
		{
			"closePrice": 825000,
			"daysOnMovoto": 0,
			"id": "309899f3-a9a2-4c83-aeb7-e5367d716307",
			"tnImgPath": "https://pi.movoto.com/p/102/221131727_0_vUjIRB_p.jpeg",
			"listDate": null,
			"listingAgent": "Silvia Bautista",
			"listPrice": 825000,
			"lotSize": 5998,
			"sqftTotal": 2977,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221131727",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Stockton",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2005,
			"zipCode": "95377",
			"path": "tracy-ca/237-glenhaven-dr-tracy-ca-95377-102_80035796/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ddc7f85d-f355-49e8-bde7-438360e336f6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-10-15 03:24:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 03:24:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.697421,
				"lng": -121.422955,
				"zipcode": "95377",
				"subPremise": "",
				"address": "237 Glenhaven Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 0,
			"id": "ab6e5a32-9fd5-4895-811b-b1397b54dbe5",
			"tnImgPath": "https://pi.movoto.com/p/102/221151261_0_FbjUab_p.jpeg",
			"listDate": null,
			"listingAgent": "Linda Pastrikas",
			"listPrice": 829000,
			"lotSize": 4940,
			"sqftTotal": 2662,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151261",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 53,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2000,
			"zipCode": "95376",
			"path": "tracy-ca/2163-photinia-dr-tracy-ca-95376-102_80107436/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d8dec05-a980-4630-a355-6f98206dc0c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-12-29 08:14:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-29 08:14:19",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/2163-photinia-drive-tracy-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.715039,
				"lng": -121.436424,
				"zipcode": "95376",
				"subPremise": "",
				"address": "2163 Photinia Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/2163-photinia-drive-tracy-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 0,
			"id": "1fe52929-eff9-4000-a3a2-ce151106da4e",
			"tnImgPath": "https://pi.movoto.com/p/102/221147336_0_mvvvUJ_p.jpeg",
			"listDate": null,
			"listingAgent": "Gevon Polgar",
			"listPrice": 638500,
			"lotSize": 5001,
			"sqftTotal": 2046,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147336",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Go2Realty Pros, Inc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1989,
			"zipCode": "95376",
			"path": "tracy-ca/490-altamont-dr-tracy-ca-95376-102_80048537/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a734e323-2cac-4980-9b80-ebcb2575a66b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-11-23 18:29:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-23 18:29:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.726502,
				"lng": -121.421156,
				"zipcode": "95376",
				"subPremise": "",
				"address": "490 Altamont Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 815000,
			"daysOnMovoto": 0,
			"id": "fd13a336-4c6f-4ddf-9ef6-4fba1996b185",
			"tnImgPath": "https://pi.movoto.com/p/102/221100076_0_RYzaYy_p.jpeg",
			"listDate": null,
			"listingAgent": "Malalee Qayumi",
			"listPrice": 799888,
			"lotSize": 6042,
			"sqftTotal": 2828,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221100076",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2000,
			"zipCode": "95377",
			"path": "tracy-ca/2348-ogden-sannazor-dr-tracy-ca-95377-102_19055232/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -85112,
			"propertyId": "daaf0345-0f24-4df6-8d33-05ce1bd02aee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-08-12 20:49:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 20:49:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.742846,
				"lng": -121.459282,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2348 Ogden Sannazor Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 815000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 805000,
			"daysOnMovoto": 0,
			"id": "8c46cf25-269e-46b1-a17a-dfd9d9b877e1",
			"tnImgPath": "https://pi.movoto.com/p/102/221149041_0_Z3vJnJ_p.jpeg",
			"listDate": null,
			"listingAgent": "Donny Piwowarski",
			"listPrice": 769950,
			"lotSize": 5301,
			"sqftTotal": 2498,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221149041",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Hero Real Estate",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "95377",
			"path": "tracy-ca/2711-shofield-ln-tracy-ca-95377-12_40134265/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "01abd677-1803-42d8-986a-9a7f1ce3b5a4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-12-03 00:19:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 00:19:42",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.735926,
				"lng": -121.466127,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2711 Shofield Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 805000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 710000,
			"daysOnMovoto": 0,
			"id": "0f66ff1e-0369-42da-ac17-7b76fdca75f9",
			"tnImgPath": "https://pi.movoto.com/p/102/221145643_0_q2fNy7_p.jpeg",
			"listDate": null,
			"listingAgent": "Manpreet Nagi",
			"listPrice": 689000,
			"lotSize": 5898,
			"sqftTotal": 2210,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221145643",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 56,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1990,
			"zipCode": "95376",
			"path": "tracy-ca/1705-sequoia-blvd-tracy-ca-95376-12_11017333/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e31e13b5-18ad-4d64-81c5-01587e7eb123",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-11-19 16:44:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 16:44:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.739064,
				"lng": -121.447426,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1705 Sequoia Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 615000,
			"daysOnMovoto": 0,
			"id": "a5e94018-06c1-49d1-af6b-938114d537bc",
			"tnImgPath": "https://pi.movoto.com/p/102/221133510_0_biV6Aq_p.jpeg",
			"listDate": null,
			"listingAgent": "Laura Nielsen-Wescott",
			"listPrice": 625000,
			"lotSize": 5868,
			"sqftTotal": 1689,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221133510",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Century 21 MM",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1995,
			"zipCode": "95376",
			"path": "tracy-ca/1990-knollcrest-ln-tracy-ca-95376/pid_7i1qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e0d2741c-73f8-427c-8375-d120ff6264e6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-10-18 16:59:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-18 16:59:46",
			"virtualTourLink": "http://1990KNOLLCRESTLANE.C21.COM",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.742158,
				"lng": -121.452425,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1990 Knollcrest Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://1990KNOLLCRESTLANE.C21.COM"
		},
		{
			"closePrice": 595000,
			"daysOnMovoto": 0,
			"id": "87b39f16-5b48-4479-a730-df06c5aab7bd",
			"tnImgPath": "https://pi.movoto.com/p/102/221149796_0_uIzqRr_p.jpeg",
			"listDate": null,
			"listingAgent": "Barkev Andriopoulos",
			"listPrice": 619999,
			"lotSize": 5445,
			"sqftTotal": 1714,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221149796",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1989,
			"zipCode": "95376",
			"path": "tracy-ca/520-sir-lancelot-dr-tracy-ca-95376-102_13058247/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6f7563f3-9131-4f63-974a-bbb9784cb56d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-12-03 02:24:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 02:24:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.729123,
				"lng": -121.417649,
				"zipcode": "95376",
				"subPremise": "",
				"address": "520 Sir Lancelot Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 595000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 710000,
			"daysOnMovoto": 0,
			"id": "88a105ae-2418-4ca4-b8c4-4f31795a1c36",
			"tnImgPath": "https://pi.movoto.com/p/102/221141770_0_FfuIrN_p.jpeg",
			"listDate": null,
			"listingAgent": "Nancy Payne",
			"listPrice": 699000,
			"lotSize": 5541,
			"sqftTotal": 1878,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221141770",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Century 21 MM",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2003,
			"zipCode": "95377",
			"path": "tracy-ca/988-roy-frerichs-ln-tracy-ca-95377/pid_e64qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "48d69f82-c710-4901-9b90-09a5438615b7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-11-11 15:29:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 15:29:39",
			"virtualTourLink": "https://my.matterport.com/show/?m=NKvxCRyyFex&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.721524,
				"lng": -121.464052,
				"zipcode": "95377",
				"subPremise": "",
				"address": "988 Roy Frerichs Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 710000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=NKvxCRyyFex&brand=0"
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 0,
			"id": "cbb3a29d-03cf-472a-afe7-d8a346022967",
			"tnImgPath": "https://pi.movoto.com/p/102/221136616_0_QaIyiV_p.jpeg",
			"listDate": null,
			"listingAgent": "Jennifer Leung",
			"listPrice": 788000,
			"lotSize": 5798,
			"sqftTotal": 2945,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136616",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Selection Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2002,
			"zipCode": "95377",
			"path": "tracy-ca/2912-safford-ave-tracy-ca-95377-102_15038095/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -40000,
			"propertyId": "930eb268-a947-4161-a0f9-1bb30e1d32a3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-10-24 02:09:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-24 02:09:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.733128,
				"lng": -121.469876,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2912 Safford Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 465000,
			"daysOnMovoto": 0,
			"id": "17b5db4a-8ddd-42b2-b50f-ed55835a6eb6",
			"tnImgPath": "https://pi.movoto.com/p/102/221130897_0_An2QBQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Emad Basma",
			"listPrice": 495000,
			"lotSize": 4221,
			"sqftTotal": 1352,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221130897",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway HomeServices-Drysdale Properties",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1916,
			"zipCode": "95376",
			"path": "tracy-ca/110-e-10th-st-tracy-ca-95376/pid_3j1qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "59199d34-4014-4927-ae30-c5332f6b3da3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-10-15 07:14:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 07:14:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"zipcode": "95376",
				"subPremise": "",
				"address": "110 E 10th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 465000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 585000,
			"daysOnMovoto": 0,
			"id": "b349c392-e190-4287-a9e0-edbaea3cb7f7",
			"tnImgPath": "https://pi.movoto.com/p/102/221148864_0_NeYiNn_p.jpeg",
			"listDate": null,
			"listingAgent": "Jeffrey DeHart",
			"listPrice": 525000,
			"lotSize": 7501,
			"sqftTotal": 1602,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221148864",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Range Homes",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1938,
			"zipCode": "95376",
			"path": "tracy-ca/29-w-carlton-way-tracy-ca-95376/pid_xyzqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9dc50a6f-7d48-46eb-9f38-b4001ba55508",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-12-02 05:54:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 05:54:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.746507,
				"lng": -121.426578,
				"zipcode": "95376",
				"subPremise": "",
				"address": "29 W Carlton Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 585000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 820000,
			"daysOnMovoto": 0,
			"id": "e5b4e609-53a1-4dd1-a2bf-31cb4fb5c2ec",
			"tnImgPath": "https://pi.movoto.com/p/102/221140007_0_uMNquF_p.jpeg",
			"listDate": null,
			"listingAgent": "Donny Piwowarski",
			"listPrice": 815000,
			"lotSize": 5998,
			"sqftTotal": 2933,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221140007",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Hero Real Estate",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1998,
			"zipCode": "95377",
			"path": "tracy-ca/2099-lara-ln-tracy-ca-95377-102_10011156/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0d8c823f-a652-48ec-9ebc-a638d3b6a508",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-13 00:09:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 00:09:25",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.743343,
				"lng": -121.45488,
				"zipcode": "95377",
				"subPremise": "",
				"address": "2099 Lara Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 968000,
			"daysOnMovoto": 0,
			"id": "37743caa-a131-483e-8b3f-bea15ee60376",
			"tnImgPath": "https://pi.movoto.com/p/102/221143431_0_EYuUyQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Nita Patel",
			"listPrice": 899999,
			"lotSize": 8159,
			"sqftTotal": 2627,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221143431",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "PropertySourced",
			"photoCount": 75,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2000,
			"zipCode": "95377",
			"path": "tracy-ca/1365-maiden-ct-tracy-ca-95377-12_40076664/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "caf1da27-6b9e-47a2-bc37-ab9be8c732c4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-11 18:29:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 18:29:39",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/1365-maiden-court-tracy-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.717884,
				"lng": -121.471142,
				"zipcode": "95377",
				"subPremise": "",
				"address": "1365 Maiden Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 968000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/1365-maiden-court-tracy-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 0,
			"id": "b4c20fbf-44a5-41ff-b8d7-f3d6ef9a25d2",
			"tnImgPath": "https://pi.movoto.com/p/102/221125816_0_VjbqaA_p.jpeg",
			"listDate": null,
			"listingAgent": "Juana DeMent",
			"listPrice": 899000,
			"lotSize": 49223,
			"sqftTotal": 1830,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221125816",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Dement Real Estate Group",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1981,
			"zipCode": "95304",
			"path": "tracy-ca/23405-rancho-ramon-ct-tracy-ca-95304/pid_nkgqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8d3c5775-828f-4566-a9f2-aa52cde7271f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-10-01 16:59:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 16:59:47",
			"virtualTourLink": "https://www.youtube.com/embed/Ka-Z0x3CzwE",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.745665,
				"lng": -121.503767,
				"zipcode": "95304",
				"subPremise": "",
				"address": "23405 Rancho Ramon Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/Ka-Z0x3CzwE"
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 0,
			"id": "d295ebca-832b-4d1b-95e7-2d38a16a5230",
			"tnImgPath": "https://pi.movoto.com/p/102/221116624_0_Qni7yj_p.jpeg",
			"listDate": null,
			"listingAgent": "Christina Acierto",
			"listPrice": 949900,
			"lotSize": 67518,
			"sqftTotal": 1974,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221116624",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Century 21 Tri-Dam Realty",
			"photoCount": 66,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1982,
			"zipCode": "95304",
			"path": "tracy-ca/16650-von-sosten-rd-tracy-ca-95304/pid_9yhqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bafb51d7-48a9-4e52-a73a-992e2ab927d3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-09-15 01:44:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 01:44:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.750556,
				"lng": -121.515254,
				"zipcode": "95304",
				"subPremise": "",
				"address": "16650 Von Sosten Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 715000,
			"daysOnMovoto": 0,
			"id": "ecbb4aed-15a4-4c8b-b0f2-f3b280748ce6",
			"tnImgPath": "https://pi.movoto.com/p/102/221088163_0_NjyJy3_p.jpeg",
			"listDate": null,
			"listingAgent": "Cinthia Hunsinger-Rueda",
			"listPrice": 788900,
			"lotSize": 28410,
			"sqftTotal": 987,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221088163",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Platinum One Realty & Mortgage",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1965,
			"zipCode": "95304",
			"path": "tracy-ca/5736-w-grant-line-rd-tracy-ca-95304-102_18071744/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ec653f7-b4d8-4896-b0c2-d01d9dd8b5c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-07-21 01:34:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-21 01:34:24",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.754369,
				"lng": -121.375649,
				"zipcode": "95304",
				"subPremise": "",
				"address": "5736 W Grant Line Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 715000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 430000,
			"daysOnMovoto": 0,
			"id": "f3c73f88-ec72-4a0b-8195-5e8c89d5a86a",
			"tnImgPath": "https://pi.movoto.com/p/102/221148094_0_ZQnM2q_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 375000,
			"lotSize": 5001,
			"sqftTotal": 908,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221148094",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1945,
			"zipCode": "95376",
			"path": "tracy-ca/345-w-lowell-ave-tracy-ca-95376/pid_bq0qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8efc0954-96a6-4d7f-89be-d5dc0affde66",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-12-08 15:54:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-08 15:54:22",
			"virtualTourLink": "http://345westlowell.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.74799,
				"lng": -121.430819,
				"zipcode": "95376",
				"subPremise": "",
				"address": "345 W Lowell Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 430000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://345westlowell.com"
		},
		{
			"closePrice": 615000,
			"daysOnMovoto": 0,
			"id": "24fecccd-cc3f-4fa5-b80e-1958978bc7c2",
			"tnImgPath": "https://pi.movoto.com/p/102/221146399_0_2Jfa3F_p.jpeg",
			"listDate": null,
			"listingAgent": "Patrick Bill",
			"listPrice": 549950,
			"lotSize": 6329,
			"sqftTotal": 1342,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221146399",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1993,
			"zipCode": "95376",
			"path": "tracy-ca/522-cecelio-way-tracy-ca-95376-12_11203114/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cda92b93-be85-47e2-80a9-9336b6e543fb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-19 07:19:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 07:19:24",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/522-cecelio-way-tracy-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.726144,
				"lng": -121.433054,
				"zipcode": "95376",
				"subPremise": "",
				"address": "522 Cecelio Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/522-cecelio-way-tracy-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 685000,
			"daysOnMovoto": 0,
			"id": "b516010d-f1f2-458e-8925-1cca10f3e603",
			"tnImgPath": "https://pi.movoto.com/p/102/221140789_0_EEZyv6_p.jpeg",
			"listDate": null,
			"listingAgent": "Sarah Bergen",
			"listPrice": 695000,
			"lotSize": 4051,
			"sqftTotal": 2147,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221140789",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin Corporation",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2001,
			"zipCode": "95377",
			"path": "tracy-ca/4118-heirloom-ln-tracy-ca-95377-102_40000386/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -4000,
			"propertyId": "e9a5af2b-2bf0-4c2f-9a44-521caf039fe1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-05 20:09:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 20:09:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.701654,
				"lng": -121.451905,
				"zipcode": "95377",
				"subPremise": "",
				"address": "4118 Heirloom Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 685000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 0,
			"id": "3a11f16f-26bc-43ff-85d6-306b8ab98d4b",
			"tnImgPath": "https://pi.movoto.com/p/102/221113085_0_ibMbfR_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 1699000,
			"lotSize": 113692,
			"sqftTotal": 2798,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221113085",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 92,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "95304",
			"path": "tracy-ca/3711-w-kenner-rd-tracy-ca-95304-102_221113085/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "71d01fff-6e6f-416d-9649-01ceea83df31",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-09-22 14:39:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 14:39:22",
			"virtualTourLink": "http://3711westkenner.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.645738,
				"lng": -121.351192,
				"zipcode": "95304",
				"subPremise": "",
				"address": "3711 W Kenner Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://3711westkenner.com"
		},
		{
			"closePrice": 1189000,
			"daysOnMovoto": 0,
			"id": "bdb0be94-6fa4-4b69-84aa-b0996186d3e4",
			"tnImgPath": "https://pi.movoto.com/p/102/221143563_0_7ey3zq_p.jpeg",
			"listDate": null,
			"listingAgent": "Steven Majourau",
			"listPrice": 999000,
			"lotSize": 5998,
			"sqftTotal": 3378,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221143563",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin Corporation",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2019,
			"zipCode": "95377",
			"path": "tracy-ca/4553-beaumont-ave-tracy-ca-95377-102_221143563/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d14ae870-f1d3-4348-852b-bcfc235113d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-12 01:34:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 01:34:19",
			"virtualTourLink": "https://www.youtube.com/embed/N-lOWTU1iXU",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.697921,
				"lng": -121.460928,
				"zipcode": "95377",
				"subPremise": "",
				"address": "4553 Beaumont Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1189000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/N-lOWTU1iXU"
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 0,
			"id": "53a560e4-061c-4e3d-833c-753fd99722e8",
			"tnImgPath": "https://pi.movoto.com/p/102/221137000_0_f6q6AV_p.jpeg",
			"listDate": null,
			"listingAgent": "Katrina Dew",
			"listPrice": 795000,
			"lotSize": 7200,
			"sqftTotal": 2300,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221137000",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The 3 Step Realty Group",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2000,
			"zipCode": "95377",
			"path": "tracy-ca/152-hollow-brook-ct-tracy-ca-95377/pid_tlhrr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "780be449-2f5d-4319-bc9f-5c03a1b232e7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-10 19:04:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 19:04:30",
			"virtualTourLink": "https://my.matterport.com/show/?m=YiRv23si2uc",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.707604,
				"lng": -121.424078,
				"zipcode": "95377",
				"subPremise": "",
				"address": "152 Hollow Brook Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=YiRv23si2uc"
		},
		{
			"closePrice": 425000,
			"daysOnMovoto": 0,
			"id": "eba223ce-9f80-404f-bdf0-3cab869d0206",
			"tnImgPath": "https://pi.movoto.com/p/102/221141399_0_VMaIfY_p.jpeg",
			"listDate": null,
			"listingAgent": "Ronald Cedillo",
			"listPrice": 389000,
			"lotSize": 7802,
			"sqftTotal": 993,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221141399",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Home Buyers Realty",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1944,
			"zipCode": "95376",
			"path": "tracy-ca/143-hollywood-ave-tracy-ca-95376/pid_x92qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4a0befbd-2c53-4eb8-9adc-ac8196a2aead",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-06 19:54:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-06 19:54:21",
			"virtualTourLink": "https://prizedimages.hd.pics/143-E-Hollywood-Ave-1/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.744615,
				"lng": -121.423721,
				"zipcode": "95376",
				"subPremise": "",
				"address": "143 Hollywood Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://prizedimages.hd.pics/143-E-Hollywood-Ave-1/idx"
		},
		{
			"closePrice": 420000,
			"daysOnMovoto": 0,
			"id": "be31ae1f-5191-4557-95ed-c595254e169f",
			"tnImgPath": "https://pi.movoto.com/p/102/221136831_0_n7uYmF_p.jpeg",
			"listDate": null,
			"listingAgent": "Yvette Perez",
			"listPrice": 429000,
			"lotSize": 1241,
			"sqftTotal": 1280,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136831",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty1Team",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1985,
			"zipCode": "95376",
			"path": "tracy-ca/80-portola-way-112-tracy-ca-95376-102_10062311/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "016ed3e6-d2ea-4ce7-b148-4fce7ac70356",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-25 21:44:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 21:44:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.754809,
				"lng": -121.424625,
				"zipcode": "95376",
				"subPremise": "APT 112",
				"address": "80 Portola Way #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 420000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 0,
			"id": "089c720f-fdec-4232-a591-bdbec1b10b75",
			"tnImgPath": "https://pi.movoto.com/p/102/221132057_0_RzbNer_p.jpeg",
			"listDate": null,
			"listingAgent": "Ernest Boehm",
			"listPrice": 700000,
			"lotSize": 5001,
			"sqftTotal": 1950,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221132057",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Boehm & Associates",
			"photoCount": 54,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1990,
			"zipCode": "95376",
			"path": "tracy-ca/230-s-3rd-st-tracy-ca-95376/pid_8m4qr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a63ee69c-498b-466d-b39a-a4baaa8e41ee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-14 16:29:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 16:29:40",
			"virtualTourLink": "https://my.matterport.com/show/?m=pxfuFGEfsMR&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.728033,
				"lng": -121.417783,
				"zipcode": "95376",
				"subPremise": "",
				"address": "230 S 3rd St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=pxfuFGEfsMR&mls=1"
		},
		{
			"closePrice": 720000,
			"daysOnMovoto": 0,
			"id": "62ba4070-80dd-48dd-b7b3-c0c3db3499b9",
			"tnImgPath": "https://pi.movoto.com/p/102/221104822_0_rEenjQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Patricia Knapp",
			"listPrice": 729000,
			"lotSize": 6181,
			"sqftTotal": 2732,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221104822",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "California Advantage R.E.",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1990,
			"zipCode": "95376",
			"path": "tracy-ca/1640-foxwood-dr-tracy-ca-95376/pid_q5yqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -30000,
			"propertyId": "2a40a97d-9dd3-4616-b997-738f50409c3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-08-19 19:49:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 19:49:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.737001,
				"lng": -121.445908,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1640 Foxwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 552000,
			"daysOnMovoto": 0,
			"id": "5b4ce8dd-8c2d-43ed-b8c1-7ce9149fce6c",
			"tnImgPath": "https://pi.movoto.com/p/102/221147326_0_E3FZfi_p.jpeg",
			"listDate": null,
			"listingAgent": "Ernest Boehm",
			"listPrice": 500000,
			"lotSize": 26149,
			"sqftTotal": 1519,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147326",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Boehm & Associates",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1920,
			"zipCode": "95376",
			"path": "tracy-ca/1100-w-eaton-ave-tracy-ca-95376/pid_h0xqr7d48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -52000,
			"propertyId": "0a994e4c-74e2-4985-8a08-9e3ad5800dcf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-23 16:04:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-23 16:04:23",
			"virtualTourLink": "https://my.matterport.com/show/?m=UiuspVNePTc",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.743085,
				"lng": -121.439792,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1100 W Eaton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 552000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=UiuspVNePTc"
		},
		{
			"closePrice": 700000,
			"daysOnMovoto": 0,
			"id": "adb4e201-f0cf-4d3f-84ad-94e6aa1ca071",
			"tnImgPath": "https://pi.movoto.com/p/102/221146684_0_jIiBNQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Christina O'Neal",
			"listPrice": 700000,
			"lotSize": 7571,
			"sqftTotal": 2608,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221146684",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Roundabout Real Estate",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1984,
			"zipCode": "95376",
			"path": "tracy-ca/171-forest-hills-dr-tracy-ca-95376-12_11024794/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4f60fbcd-d19b-439f-a522-6409ce973eb1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-20 02:34:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-20 02:34:26",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/171-forest-hills-drive-tracy-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.731459,
				"lng": -121.438409,
				"zipcode": "95376",
				"subPremise": "",
				"address": "171 Forest Hills Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/171-forest-hills-drive-tracy-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 580000,
			"daysOnMovoto": 0,
			"id": "aa186d18-139f-41a2-93ea-ac014778d224",
			"tnImgPath": "https://pi.movoto.com/p/102/221135031_0_qFIZRb_p.jpeg",
			"listDate": null,
			"listingAgent": "Ronald Hyles",
			"listPrice": 565000,
			"lotSize": 4796,
			"sqftTotal": 1127,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221135031",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Executive",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1995,
			"zipCode": "95376",
			"path": "tracy-ca/1346-peppertree-way-tracy-ca-95376-102_15072086/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e62df4b5-d35f-4d93-a7a7-016846fe9663",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-21 00:04:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:04:18",
			"virtualTourLink": "http://vtours.toddsfotos.com/1346peppertreeway/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.742109,
				"lng": -121.450818,
				"zipcode": "95376",
				"subPremise": "",
				"address": "1346 Peppertree Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 580000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.toddsfotos.com/1346peppertreeway/?mls"
		},
		{
			"closePrice": 609500,
			"daysOnMovoto": 0,
			"id": "40deb85e-a408-4ff5-97b5-e119b4468f41",
			"tnImgPath": "https://pi.movoto.com/p/102/221119217_0_AaRneQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Bruce Pflieger",
			"listPrice": 619500,
			"lotSize": 2317,
			"sqftTotal": 2245,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221119217",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Allison James Estates & Homes",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1912,
			"zipCode": "95376",
			"path": "tracy-ca/54-e-8th-st-tracy-ca-95376-102_13036846/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 20000,
			"propertyId": "de2ee1c0-e019-44b2-91a9-a4ea49cca193",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-09-18 03:59:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-18 03:59:19",
			"virtualTourLink": "https://my.matterport.com/show/?m=NSnsxYgnD3Z",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.736428,
				"lng": -121.424525,
				"zipcode": "95376",
				"subPremise": "",
				"address": "54 E 8th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 609500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=NSnsxYgnD3Z"
		},
		{
			"closePrice": 1180000,
			"daysOnMovoto": 0,
			"id": "0a200c20-ff61-4eea-a561-62a32f70b75b",
			"tnImgPath": "https://pi.movoto.com/p/102/221145889_0_yIfJMe_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 999000,
			"lotSize": 8503,
			"sqftTotal": 3854,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221145889",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2016,
			"zipCode": "95376",
			"path": "tracy-ca/2591-coloma-ln-tracy-ca-95376-102_221145889/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a3fcbfc5-ca0a-4aac-a2c4-310c11e1e952",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-12-02 15:34:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 15:34:34",
			"virtualTourLink": "http://2591coloma.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Tracy",
				"lat": 37.71201,
				"lng": -121.452102,
				"zipcode": "95376",
				"subPremise": "",
				"address": "2591 Coloma Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://2591coloma.com"
		}
	]