exports.DALY_CITY_SCHOOLS = [
    {
        "id": 14062,
        "districtId": 787,
        "districtName": "Jefferson Union High",
        "districtCity": "Daly City",
        "levelCode": "e,m,h",
        "lat": 37.668419,
        "lon": -122.477539,
        "name": "Adult Education Division",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "699 Serramonte Boulevard, Suite 111",
            "street2": "Suite 111",
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/14062-Adult-Education-Division/",
            "reviews": "/california/daly-city/14062-Adult-Education-Division/#Reviews",
            "collegeSuccess": "/california/daly-city/14062-Adult-Education-Division/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6835,
        "districtId": 779,
        "districtName": "Bayshore Elementary",
        "districtCity": "Daly City",
        "levelCode": "e,m",
        "lat": 37.705391,
        "lon": -122.413986,
        "name": "Bayshore",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "155 Oriente Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6835-Bayshore-Elementary-School/",
            "reviews": "/california/daly-city/6835-Bayshore-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6835-Bayshore-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "59%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 379,
        "parentRating": 5,
        "numReviews": 4,
        "studentsPerTeacher": 17,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 59
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 42
            },
            {
                "label": "Filipino",
                "rating": 4,
                "percentage": 24
            },
            {
                "label": "Asian",
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Pacific Islander",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "White",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18728,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.702927,
        "lon": -122.412498,
        "name": "Bayshore Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "45 Midway Drive",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18728-Bayshore-Child-Care-Center/",
            "reviews": "/california/daly-city/18728-Bayshore-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/daly-city/18728-Bayshore-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 1,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26308,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.690228,
        "lon": -122.459702,
        "name": "Bridgemont High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "444 East Market Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/26308-Bridgemont-High-School/",
            "reviews": "/california/daly-city/26308-Bridgemont-High-School/#Reviews",
            "collegeSuccess": "/california/daly-city/26308-Bridgemont-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 37,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8969,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.690228,
        "lon": -122.459702,
        "name": "Bridgemont High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "444 East Market Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/8969-Bridgemont-High-School/",
            "reviews": "/california/daly-city/8969-Bridgemont-High-School/#Reviews",
            "collegeSuccess": "/california/daly-city/8969-Bridgemont-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 40,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "African American",
                "percentage": 25
            },
            {
                "label": "Hispanic",
                "percentage": 25
            },
            {
                "label": "Two or more races",
                "percentage": 20
            },
            {
                "label": "White",
                "percentage": 18
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 8
            },
            {
                "label": "Pacific Islander",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28373,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.690208,
        "lon": -122.486641,
        "name": "Cedar Creek Montessori School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "724 Stoneyford Drive",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/28373-Cedar-Creek-Montessori-School/",
            "reviews": "/california/daly-city/28373-Cedar-Creek-Montessori-School/#Reviews",
            "collegeSuccess": "/california/daly-city/28373-Cedar-Creek-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6876,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.67754,
        "lon": -122.477287,
        "name": "Daniel Webster Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "425 El Dorado Drive",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6876-Daniel-Webster-Elementary-School/",
            "reviews": "/california/daly-city/6876-Daniel-Webster-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6876-Daniel-Webster-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "59%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 370,
        "parentRating": 5,
        "numReviews": 3,
        "studentsPerTeacher": 16,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 59
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 34
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 41
            },
            {
                "label": "Asian",
                "percentage": 9
            },
            {
                "label": "White",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18730,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.682663,
        "lon": -122.463875,
        "name": "Early Learning Academy",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "398 F Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18730-Early-Learning-Academy/",
            "reviews": "/california/daly-city/18730-Early-Learning-Academy/#Reviews",
            "collegeSuccess": "/california/daly-city/18730-Early-Learning-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6872,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "m",
        "lat": 37.677475,
        "lon": -122.485977,
        "name": "Fernando Rivera Intermediate",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "1255 S.gate Ave.",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6872-Fernando-Rivera-Intermediate-School/",
            "reviews": "/california/daly-city/6872-Fernando-Rivera-Intermediate-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6872-Fernando-Rivera-Intermediate-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "44%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 513,
        "parentRating": 5,
        "numReviews": 5,
        "studentsPerTeacher": 17,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 44
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 33
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 21
            },
            {
                "label": "Two or more races",
                "rating": 6,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 35
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6873,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e,m",
        "lat": 37.662003,
        "lon": -122.487808,
        "name": "Franklin Delano Roosevelt Elementary",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "1200 Skyline Drive",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6873-Franklin-Delano-Roosevelt-Elementary-School/",
            "reviews": "/california/daly-city/6873-Franklin-Delano-Roosevelt-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6873-Franklin-Delano-Roosevelt-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "45%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 377,
        "parentRating": 4,
        "numReviews": 3,
        "studentsPerTeacher": 14,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 45
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 33
            },
            {
                "label": "Filipino",
                "rating": 6,
                "percentage": 28
            },
            {
                "label": "White",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27250,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.705193,
        "lon": -122.44693,
        "name": "Fun With Mandarin Preschool and Daycare",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "251 FRANKFORT ST",
            "street2": null,
            "zip": "94014",
            "city": "DALY CITY"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/27250-Fun-With-Mandarin-Preschool-And-Daycare/",
            "reviews": "/california/daly-city/27250-Fun-With-Mandarin-Preschool-And-Daycare/#Reviews",
            "collegeSuccess": "/california/daly-city/27250-Fun-With-Mandarin-Preschool-And-Daycare/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6875,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.70734,
        "lon": -122.44606,
        "name": "George Washington Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "251 Whittier Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 3,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6875-George-Washington-Elementary-School/",
            "reviews": "/california/daly-city/6875-George-Washington-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6875-George-Washington-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "65%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 313,
        "parentRating": 4,
        "numReviews": 6,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 3,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 65
            },
            {
                "label": "All students",
                "rating": 3
            },
            {
                "label": "Asian",
                "rating": 4,
                "percentage": 23
            },
            {
                "label": "Hispanic",
                "rating": 2,
                "percentage": 45
            },
            {
                "label": "Filipino",
                "rating": 5,
                "percentage": 25
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8778,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.697861,
        "lon": -122.458206,
        "name": "Hilldale School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "79 Florence Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/8778-Hilldale-School/",
            "reviews": "/california/daly-city/8778-Hilldale-School/#Reviews",
            "collegeSuccess": "/california/daly-city/8778-Hilldale-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 100,
        "parentRating": 5,
        "numReviews": 24,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 49
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "percentage": 19
            },
            {
                "label": "African American",
                "percentage": 12
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18727,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.689713,
        "lon": -122.473427,
        "name": "Hoover Child Care Center-Imagination Station",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "280 92nd Street",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18727-Hoover-Child-Care-Center-Imagination-Station/",
            "reviews": "/california/daly-city/18727-Hoover-Child-Care-Center-Imagination-Station/#Reviews",
            "collegeSuccess": "/california/daly-city/18727-Hoover-Child-Care-Center-Imagination-Station/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 16634,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.683399,
        "lon": -122.4739,
        "name": "Hope Lutheran Elementary School",
        "gradeLevels": "K-3",
        "assigned": null,
        "address": {
            "street1": "55 San Fernando Way",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/16634-Hope-Lutheran-Elementary-School/",
            "reviews": "/california/daly-city/16634-Hope-Lutheran-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/16634-Hope-Lutheran-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6880,
        "districtId": 787,
        "districtName": "Jefferson Union High",
        "districtCity": "Daly City",
        "levelCode": "h",
        "lat": 37.696205,
        "lon": -122.467598,
        "name": "Jefferson High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "6996 Mission Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6880-Jefferson-High-School/",
            "reviews": "/california/daly-city/6880-Jefferson-High-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6880-Jefferson-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "44%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "69%",
            "state_average": "64%"
        },
        "enrollment": 1217,
        "parentRating": 5,
        "numReviews": 5,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 5,
            "College Readiness Rating": 4,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 44
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 41
            },
            {
                "label": "Two or more races",
                "rating": 5,
                "percentage": 5
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 38
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6870,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.695145,
        "lon": -122.454468,
        "name": "John F. Kennedy Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "785 Price Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6870-John-F.-Kennedy-Elementary-School/",
            "reviews": "/california/daly-city/6870-John-F.-Kennedy-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6870-John-F.-Kennedy-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "65%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 407,
        "parentRating": 4,
        "numReviews": 5,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 65
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 14
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 53
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 23
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6987,
        "districtId": 801,
        "districtName": "South San Francisco Unified",
        "districtCity": "South San Francisco",
        "levelCode": "e",
        "lat": 37.660686,
        "lon": -122.469322,
        "name": "Junipero Serra Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "151 Victoria Street",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6987-Junipero-Serra-Elementary-School/",
            "reviews": "/california/daly-city/6987-Junipero-Serra-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6987-Junipero-Serra-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "25%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 314,
        "parentRating": 3,
        "numReviews": 5,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 25
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 24
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 49
            },
            {
                "label": "Asian",
                "percentage": 16
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "White",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28371,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.690228,
        "lon": -122.459702,
        "name": "Kiddie World Day Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "444 East Market Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/28371-Kiddie-World-Day-Center/",
            "reviews": "/california/daly-city/28371-Kiddie-World-Day-Center/#Reviews",
            "collegeSuccess": "/california/daly-city/28371-Kiddie-World-Day-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18721,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.664078,
        "lon": -122.489693,
        "name": "Latchkey Alternative Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "106 Longview Drive",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18721-Latchkey-Alternative-Center/",
            "reviews": "/california/daly-city/18721-Latchkey-Alternative-Center/#Reviews",
            "collegeSuccess": "/california/daly-city/18721-Latchkey-Alternative-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6864,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.685318,
        "lon": -122.476852,
        "name": "Margaret Pauline Brown Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "305 E.moor Ave.",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6864-Margaret-Pauline-Brown-Elementary-School/",
            "reviews": "/california/daly-city/6864-Margaret-Pauline-Brown-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6864-Margaret-Pauline-Brown-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "58%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 314,
        "parentRating": 4,
        "numReviews": 8,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 58
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 39
            },
            {
                "label": "Filipino",
                "rating": 6,
                "percentage": 31
            },
            {
                "label": "Asian",
                "percentage": 13
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6874,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.691048,
        "lon": -122.489899,
        "name": "Marjorie H. Tobias Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "725 S.gate Ave.",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6874-Marjorie-H.-Tobias-Elementary-School/",
            "reviews": "/california/daly-city/6874-Marjorie-H.-Tobias-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6874-Marjorie-H.-Tobias-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "26%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 378,
        "parentRating": 5,
        "numReviews": 9,
        "studentsPerTeacher": 17,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 26
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 48
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 14
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 12
            },
            {
                "label": "Filipino",
                "percentage": 18
            },
            {
                "label": "White",
                "percentage": 7
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 32408,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.690567,
        "lon": -122.467697,
        "name": "Mi Escuelita en EspaÃ±ol",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "155 Werner Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/32408-Mi-Escuelita-En-EspaAOl/",
            "reviews": "/california/daly-city/32408-Mi-Escuelita-En-EspaAOl/#Reviews",
            "collegeSuccess": "/california/daly-city/32408-Mi-Escuelita-En-EspaAOl/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27685,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.68153,
        "lon": -122.478668,
        "name": "Milestone Academy Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "212 Saint Francis Boulevard",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/27685-Milestone-Academy-Pre-School/",
            "reviews": "/california/daly-city/27685-Milestone-Academy-Pre-School/#Reviews",
            "collegeSuccess": "/california/daly-city/27685-Milestone-Academy-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 17365,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.7052,
        "lon": -122.415901,
        "name": "Mt. Vernon Christian Academy",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "310 Ottilia Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/17365-Mt.-Vernon-Christian-Academy/",
            "reviews": "/california/daly-city/17365-Mt.-Vernon-Christian-Academy/#Reviews",
            "collegeSuccess": "/california/daly-city/17365-Mt.-Vernon-Christian-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18726,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.688103,
        "lon": -122.474075,
        "name": "Noah's Ark Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1595 Edgeworth Avenue",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18726-Noahs-Ark-Pre-School/",
            "reviews": "/california/daly-city/18726-Noahs-Ark-Pre-School/#Reviews",
            "collegeSuccess": "/california/daly-city/18726-Noahs-Ark-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8149,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.698528,
        "lon": -122.487778,
        "name": "Our Lady Of Mercy Elementary School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "7 Elmwood Drive",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/8149-Our-Lady-Of-Mercy-Elementary-School/",
            "reviews": "/california/daly-city/8149-Our-Lady-Of-Mercy-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/8149-Our-Lady-Of-Mercy-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 454,
        "parentRating": 4,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 58
            },
            {
                "label": "Two or more races",
                "percentage": 24
            },
            {
                "label": "White",
                "percentage": 11
            },
            {
                "label": "Hispanic",
                "percentage": 7
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8148,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.705006,
        "lon": -122.460823,
        "name": "Our Lady Of Perpetual Help",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "80 Wellington Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/8148-Our-Lady-Of-Perpetual-Help/",
            "reviews": "/california/daly-city/8148-Our-Lady-Of-Perpetual-Help/#Reviews",
            "collegeSuccess": "/california/daly-city/8148-Our-Lady-Of-Perpetual-Help/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 193,
        "parentRating": 5,
        "numReviews": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 42
            },
            {
                "label": "Asian",
                "percentage": 37
            },
            {
                "label": "Hispanic",
                "percentage": 14
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6845,
        "districtId": 781,
        "districtName": "Brisbane Elementary",
        "districtCity": "Brisbane",
        "levelCode": "e",
        "lat": 37.706631,
        "lon": -122.438316,
        "name": "Panorama Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "25 Bellevue Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6845-Panorama-Elementary-School/",
            "reviews": "/california/daly-city/6845-Panorama-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6845-Panorama-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "34%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 136,
        "parentRating": 5,
        "numReviews": 12,
        "studentsPerTeacher": 14,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 34
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Hispanic",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 18
            },
            {
                "label": "Filipino",
                "percentage": 17
            },
            {
                "label": "Asian",
                "percentage": 13
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 4
            }
        ],
        "remediationData": {}
    },
    {
        "id": 20319,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.705391,
        "lon": -122.465401,
        "name": "Pennington's Little Friends",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "Los Banos Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/20319-Penningtons-Little-Friends/",
            "reviews": "/california/daly-city/20319-Penningtons-Little-Friends/#Reviews",
            "collegeSuccess": "/california/daly-city/20319-Penningtons-Little-Friends/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29828,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.688904,
        "lon": -122.469032,
        "name": "Playhouse Child Care Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "58 Hill Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/29828-Playhouse-Child-Care-Center/",
            "reviews": "/california/daly-city/29828-Playhouse-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/daly-city/29828-Playhouse-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6994,
        "districtId": 801,
        "districtName": "South San Francisco Unified",
        "districtCity": "South San Francisco",
        "levelCode": "e",
        "lat": 37.651653,
        "lon": -122.465363,
        "name": "Skyline Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "55 Christen Avenue",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6994-Skyline-Elementary-School/",
            "reviews": "/california/daly-city/6994-Skyline-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6994-Skyline-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "32%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 402,
        "parentRating": 4,
        "numReviews": 7,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 32
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 19
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 19
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 47
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18722,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.675213,
        "lon": -122.476837,
        "name": "Southgate Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1474 Southgate Ave",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18722-Southgate-Pre-School/",
            "reviews": "/california/daly-city/18722-Southgate-Pre-School/#Reviews",
            "collegeSuccess": "/california/daly-city/18722-Southgate-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 30766,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.667839,
        "lon": -122.481339,
        "name": "Spectrum Center - Daly City",
        "gradeLevels": "3-12",
        "assigned": null,
        "address": {
            "street1": "60 Christopher Court",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/30766-Spectrum-Center---Daly-City/",
            "reviews": "/california/daly-city/30766-Spectrum-Center---Daly-City/#Reviews",
            "collegeSuccess": "/california/daly-city/30766-Spectrum-Center---Daly-City/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 48,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 33
            },
            {
                "label": "Asian",
                "percentage": 25
            },
            {
                "label": "Hispanic",
                "percentage": 23
            },
            {
                "label": "African American",
                "percentage": 19
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31109,
        "districtId": 787,
        "districtName": "Jefferson Union High",
        "districtCity": "Daly City",
        "levelCode": "h",
        "lat": 37.668041,
        "lon": -122.478554,
        "name": "Summit Public School: Shasta",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "699 Serramonte Boulevard",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/31109-Summit-Public-School-Shasta/",
            "reviews": "/california/daly-city/31109-Summit-Public-School-Shasta/#Reviews",
            "collegeSuccess": "/california/daly-city/31109-Summit-Public-School-Shasta/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "18%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "80%",
            "state_average": "64%"
        },
        "enrollment": 491,
        "parentRating": 4,
        "numReviews": 22,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 9
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 9,
                "percentage": 18
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 24
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 18
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 16
            },
            {
                "label": "Filipino",
                "percentage": 12
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6863,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.690533,
        "lon": -122.456985,
        "name": "Susan B. Anthony Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "575 Abbot Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6863-Susan-B.-Anthony-Elementary-School/",
            "reviews": "/california/daly-city/6863-Susan-B.-Anthony-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6863-Susan-B.-Anthony-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "60%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 525,
        "parentRating": 2,
        "numReviews": 9,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 60
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 60
            },
            {
                "label": "Filipino",
                "rating": 5,
                "percentage": 24
            },
            {
                "label": "Asian",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6867,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.676537,
        "lon": -122.485039,
        "name": "Thomas Edison Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "1267 S.gate Ave.",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6867-Thomas-Edison-Elementary-School/",
            "reviews": "/california/daly-city/6867-Thomas-Edison-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6867-Thomas-Edison-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "48%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 389,
        "parentRating": 5,
        "numReviews": 10,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 48
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 29
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 18
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6871,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "m",
        "lat": 37.690517,
        "lon": -122.459328,
        "name": "Thomas R. Pollicita Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "550 East Market Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6871-Thomas-R.-Pollicita-Middle-School/",
            "reviews": "/california/daly-city/6871-Thomas-R.-Pollicita-Middle-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6871-Thomas-R.-Pollicita-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "60%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 677,
        "parentRating": 4,
        "numReviews": 12,
        "studentsPerTeacher": 16,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 60
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 13
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 49
            },
            {
                "label": "White",
                "rating": 6,
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "rating": 6,
                "percentage": 3
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 28
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6879,
        "districtId": 787,
        "districtName": "Jefferson Union High",
        "districtCity": "Daly City",
        "levelCode": "h",
        "lat": 37.69133,
        "lon": -122.463776,
        "name": "Thornton High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "115 1st Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6879-Thornton-High-School/",
            "reviews": "/california/daly-city/6879-Thornton-High-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6879-Thornton-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "35%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "42%",
            "state_average": "64%"
        },
        "enrollment": 124,
        "parentRating": 3,
        "numReviews": 2,
        "studentsPerTeacher": 13,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 35
            },
            {
                "label": "Hispanic",
                "percentage": 52
            },
            {
                "label": "White",
                "percentage": 15
            },
            {
                "label": "Filipino",
                "percentage": 15
            },
            {
                "label": "Two or more races",
                "percentage": 9
            },
            {
                "label": "Asian",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18729,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.70211,
        "lon": -122.469292,
        "name": "Village Nursery School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "474 North Parkview Avenue",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18729-Village-Nursery-School/",
            "reviews": "/california/daly-city/18729-Village-Nursery-School/#Reviews",
            "collegeSuccess": "/california/daly-city/18729-Village-Nursery-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6877,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.704922,
        "lon": -122.479454,
        "name": "Westlake Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "80 Fieldcrest Drive",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6877-Westlake-Elementary-School/",
            "reviews": "/california/daly-city/6877-Westlake-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6877-Westlake-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "53%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 377,
        "parentRating": 5,
        "numReviews": 2,
        "studentsPerTeacher": 16,
        "subratings": {
            "Test Scores Rating": 5,
            "Academic Progress Rating": 4,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 53
            },
            {
                "label": "All students",
                "rating": 5
            },
            {
                "label": "Asian",
                "rating": 7,
                "percentage": 20
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 24
            },
            {
                "label": "Filipino",
                "rating": 6,
                "percentage": 34
            },
            {
                "label": "White",
                "percentage": 17
            },
            {
                "label": "Two or more races",
                "percentage": 4
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6883,
        "districtId": 787,
        "districtName": "Jefferson Union High",
        "districtCity": "Daly City",
        "levelCode": "h",
        "lat": 37.682457,
        "lon": -122.483742,
        "name": "Westmoor High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "131 Westmoor Avenue",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6883-Westmoor-High-School/",
            "reviews": "/california/daly-city/6883-Westmoor-High-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6883-Westmoor-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "32%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "78%",
            "state_average": "64%"
        },
        "enrollment": 1526,
        "parentRating": 3,
        "numReviews": 4,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "College Readiness Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 32
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 22
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 22
            },
            {
                "label": "White",
                "rating": 6,
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 45
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6878,
        "districtId": 786,
        "districtName": "Jefferson Elementary",
        "districtCity": "Daly City",
        "levelCode": "e",
        "lat": 37.704407,
        "lon": -122.465263,
        "name": "Woodrow Wilson Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "43 Miriam Street",
            "street2": null,
            "zip": "94014",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94014",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/6878-Woodrow-Wilson-Elementary-School/",
            "reviews": "/california/daly-city/6878-Woodrow-Wilson-Elementary-School/#Reviews",
            "collegeSuccess": "/california/daly-city/6878-Woodrow-Wilson-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "68%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 376,
        "parentRating": 4,
        "numReviews": 4,
        "studentsPerTeacher": 19,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 4,
                "percentage": 68
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Asian",
                "rating": 7,
                "percentage": 16
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 54
            },
            {
                "label": "Filipino",
                "percentage": 19
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18732,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.667961,
        "lon": -122.477234,
        "name": "Young World Learning Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "699 Serramonte Boulevard",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/18732-Young-World-Learning-Center/",
            "reviews": "/california/daly-city/18732-Young-World-Learning-Center/#Reviews",
            "collegeSuccess": "/california/daly-city/18732-Young-World-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10103,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.6693,
        "lon": -122.477699,
        "name": "Young World Preschool and Kindergarten",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "699 Serramonte Boulevard, #233",
            "street2": null,
            "zip": "94015",
            "city": "Daly City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94015",
        "type": "school",
        "links": {
            "profile": "/california/daly-city/10103-Young-World-Preschool-And-Kindergarten/",
            "reviews": "/california/daly-city/10103-Young-World-Preschool-And-Kindergarten/#Reviews",
            "collegeSuccess": "/california/daly-city/10103-Young-World-Preschool-And-Kindergarten/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    }
]