exports.SAN_LEANDRO_LISTINGS = [
		{
			"closePrice": 970000,
			"daysOnMovoto": 45,
			"id": "db148b6e-833f-4d35-9665-4bd8bb7e548d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871946_0_26UQAR_p.jpeg",
			"listDate": "2021-12-06 21:39:56",
			"listingAgent": "Thu Le",
			"listPrice": 948000,
			"lotSize": 10385,
			"sqftTotal": 1683,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871946",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1954,
			"zipCode": "94578",
			"path": "san-leandro-ca/1651-159th-ave-san-leandro-ca-94578/pid_jggbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c3d51eb7-24a9-4159-bd9b-cdd4ac103624",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-21 00:00:00",
			"createdAt": "2021-12-07 05:42:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 21:39:56",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.703428,
				"lng": -122.117772,
				"zipcode": "94578",
				"subPremise": "",
				"address": "1651 E 159th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 970000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 229000,
			"daysOnMovoto": 15,
			"id": "ad73cff0-3c6b-414b-8618-25aeaf8d5031",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81873182_0_3JMAQ7_p.jpeg",
			"listDate": "2021-12-29 14:16:34",
			"listingAgent": "Ben Beveridge",
			"listPrice": 229000,
			"lotSize": null,
			"sqftTotal": 1440,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81873182",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Benjamin Alan Properties",
			"photoCount": 22,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1971,
			"zipCode": "94579",
			"path": "san-leandro-ca/454-santa-monica-san-leandro-ca-94579-100_ml81873182/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4f629e9d-02f9-44ea-a82e-8d84e08570f2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-29 22:22:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-29 14:16:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.681717,
				"lng": -122.160347,
				"zipcode": "94579",
				"subPremise": "",
				"address": "454 Santa Monica"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 229000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 938000,
			"daysOnMovoto": 0,
			"id": "af1ac6db-b572-4f40-b126-ed6cce00de29",
			"tnImgPath": "https://pi.movoto.com/p/102/221153096_0_VmIQeV_p.jpeg",
			"listDate": null,
			"listingAgent": "Yan Hong Yang",
			"listPrice": 950000,
			"lotSize": 5040,
			"sqftTotal": 1416,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221153096",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Grand Realty Group",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1950,
			"zipCode": "94579",
			"path": "san-leandro-ca/15220-edgemoor-st-san-leandro-ca-94579-102_221153096/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0d2314d1-675d-441c-8f92-440d54f26b80",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-12-15 03:49:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-15 03:49:13",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.687284,
				"lng": -122.150145,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15220 Edgemoor St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 938000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 582000,
			"daysOnMovoto": 118,
			"id": "d419740d-4efd-41b9-ae9e-70bbba0f6a03",
			"tnImgPath": "https://pi.movoto.com/p/12/40967126_0_QQuNQ3_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Karen Manuel",
			"listPrice": 599000,
			"lotSize": 3497,
			"sqftTotal": 792,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967126",
			"bath": 2,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 7,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1947,
			"zipCode": "94579",
			"path": "san-leandro-ca/15165-golden-gate-dr-san-leandro-ca-94579/pid_l3obvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31283893-2c85-47a9-9201-97e35b51ee60",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-09-15 05:01:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.688253,
				"lng": -122.141395,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15165 Golden Gate Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 582000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1110000,
			"daysOnMovoto": 26,
			"id": "7cf67db8-a83e-475f-a313-d15b250166b1",
			"tnImgPath": "https://pi.movoto.com/p/12/40976145_0_3iYbMj_p.jpeg",
			"listDate": "2021-12-11 00:00:00",
			"listingAgent": "Sarah Arnold",
			"listPrice": 815000,
			"lotSize": 5000,
			"sqftTotal": 2000,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40976145",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 38,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1892,
			"zipCode": "94577",
			"path": "san-leandro-ca/1364-orchard-ave-san-leandro-ca-94577-12_40976145/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -295000,
			"propertyId": "32e3394b-0a1a-47bb-b402-0355c5d10f7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-12-11 18:41:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.720486,
				"lng": -122.164596,
				"zipcode": "94577",
				"subPremise": "",
				"address": "1364 Orchard Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1110000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 210000,
			"daysOnMovoto": 83,
			"id": "e48ff009-3f2c-4307-b9c7-8f623bcacb03",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81865894_0_yJuf7U_p.jpeg",
			"listDate": "2021-10-07 13:39:37",
			"listingAgent": "Ben Beveridge",
			"listPrice": 209000,
			"lotSize": null,
			"sqftTotal": 1140,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81865894",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Benjamin Alan Properties",
			"photoCount": 27,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1972,
			"zipCode": "94579",
			"path": "san-leandro-ca/109-santa-teresa-san-leandro-ca-94579-100_ml81865894/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3f298b84-dfd5-4579-846f-95f6983bc38f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-10-07 20:42:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 13:39:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.685031,
				"lng": -122.160254,
				"zipcode": "94579",
				"subPremise": "",
				"address": "109 Santa Teresa"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 755000,
			"daysOnMovoto": 0,
			"id": "2bbd76c1-4b49-4245-adee-749efe28cad2",
			"tnImgPath": "https://pi.movoto.com/p/102/221146521_0_yEiEmM_p.jpeg",
			"listDate": null,
			"listingAgent": "Kathryn Knightsby",
			"listPrice": 735555,
			"lotSize": 4674,
			"sqftTotal": 1024,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221146521",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Kathryn Knightsby Real Estate",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1944,
			"zipCode": "94577",
			"path": "san-leandro-ca/2034-laura-san-leandro-ca-94577-102_221146521/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9807bda7-b942-465d-9c5c-079edf26fd7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-19 22:34:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 22:34:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.719386,
				"lng": -122.178973,
				"zipcode": "94577",
				"subPremise": "",
				"address": "2034 Laura"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 755000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 265800,
			"daysOnMovoto": 61,
			"id": "c6d23255-188f-4074-8031-4c40178ff47e",
			"tnImgPath": "https://pi.movoto.com/p/12/40972574_0_aQimEb_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Anna Costa",
			"listPrice": 264000,
			"lotSize": 1510,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972574",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 37,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94579",
			"path": "san-leandro-ca/325-santa-paula-san-leandro-ca-94579-12_40972574/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5cc9688f-2dde-4b44-98ee-33e3933adb8f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-29 00:16:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.68353,
				"lng": -122.160524,
				"zipcode": "94579",
				"subPremise": "",
				"address": "325 Santa Paula"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 265800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 0,
			"id": "488058c3-346a-49d5-a42a-34836465da34",
			"tnImgPath": "https://pi.movoto.com/p/102/221147846_0_NMy6Y3_p.jpeg",
			"listDate": null,
			"listingAgent": "Jesus Lopez",
			"listPrice": 630000,
			"lotSize": 5249,
			"sqftTotal": 818,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147846",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "PMZ Real Estate",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1942,
			"zipCode": "94577",
			"path": "san-leandro-ca/1309-wainwright-ave-san-leandro-ca-94577/pid_6l4avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a7f5726b-df59-4871-b339-aa1926924364",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-25 22:09:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-25 22:09:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.71976,
				"lng": -122.171124,
				"zipcode": "94577",
				"subPremise": "",
				"address": "1309 Wainwright Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 858000,
			"daysOnMovoto": 0,
			"id": "f117b64a-83ee-40fb-b42b-56018e50d3db",
			"tnImgPath": "https://pi.movoto.com/p/102/221127401_0_baN2fq_p.jpeg",
			"listDate": null,
			"listingAgent": "Lindsay Silva",
			"listPrice": 875000,
			"lotSize": 5300,
			"sqftTotal": 1714,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221127401",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "94579",
			"path": "san-leandro-ca/1373-burkhart-san-leandro-ca-94579-102_221127401/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "3ea39950-e9bb-4f85-8c52-26067860e566",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-10-06 07:14:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 07:14:25",
			"virtualTourLink": "https://mls.homejab.com/property/view/1373-burkhart-ave-san-leandro-ca-94579-usa",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.685341,
				"lng": -122.152851,
				"zipcode": "94579",
				"subPremise": "",
				"address": "1373 Burkhart"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 858000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mls.homejab.com/property/view/1373-burkhart-ave-san-leandro-ca-94579-usa"
		},
		{
			"closePrice": 1170000,
			"daysOnMovoto": 42,
			"id": "f98a19bd-5b9f-47c0-b202-5d158e033471",
			"tnImgPath": "https://pi.movoto.com/p/12/40973802_0_vZrBBb_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Tuny Taing",
			"listPrice": 1188000,
			"lotSize": 4314,
			"sqftTotal": 2524,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973802",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Pegasus Realty",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2003,
			"zipCode": "94577",
			"path": "san-leandro-ca/698-biltmore-st-san-leandro-ca-94577/pid_xk0avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 10000,
			"propertyId": "f6c5c97f-53b4-4d4c-b76b-af597132df21",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-10 17:26:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.728387,
				"lng": -122.167177,
				"zipcode": "94577",
				"subPremise": "",
				"address": "698 Biltmore St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1170000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1045000,
			"daysOnMovoto": 32,
			"id": "991eb03c-82c1-44a4-bd02-00f5527ca358",
			"tnImgPath": "https://pi.movoto.com/p/12/40973347_0_yRFerq_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Guy Blume",
			"listPrice": 798000,
			"lotSize": 6175,
			"sqftTotal": 1755,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973347",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94579",
			"path": "san-leandro-ca/15302-inverness-st-san-leandro-ca-94579/pid_0xlbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a7ea8dc-dedc-4910-9578-3c0f9c75e133",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-05 01:46:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 01:46:21",
			"virtualTourLink": "http://www.15302inverness.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.684912,
				"lng": -122.153095,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15302 Inverness St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1045000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.15302inverness.com/?mls"
		},
		{
			"closePrice": 535000,
			"daysOnMovoto": 34,
			"id": "8c9324ad-7ae3-490a-8ee8-fc61fda34fff",
			"tnImgPath": "https://pi.movoto.com/p/12/40973797_0_Naiivf_p.jpeg",
			"listDate": "2021-11-17 00:00:00",
			"listingAgent": "Vivian Miller",
			"listPrice": 498000,
			"lotSize": 0,
			"sqftTotal": 958,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973797",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94578",
			"path": "san-leandro-ca/14835-e-14th-st-26-san-leandro-ca-94578/pid_ff8avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d02a498c-8abb-4f3c-8ebe-d5ddbb5d6107",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-17 23:41:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/14835-e-14th-st-san-leandro-ca-94578-1341382/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.707214,
				"lng": -122.131752,
				"zipcode": "94578",
				"subPremise": "APT 26",
				"address": "14835 E 14th St #26"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 535000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/14835-e-14th-st-san-leandro-ca-94578-1341382/unbranded"
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 46,
			"id": "1686deeb-d0b9-4e76-8886-73bb1bfff0a5",
			"tnImgPath": "https://pi.movoto.com/p/12/40972870_0_vAmfme_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Andrew Crinnion",
			"listPrice": 799000,
			"lotSize": 5670,
			"sqftTotal": 1310,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972870",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens RP",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1924,
			"zipCode": "94577",
			"path": "san-leandro-ca/923-helen-ave-san-leandro-ca-94577/pid_p21avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2c887397-0e01-44ac-a7eb-ef7ca5cf138b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-05 17:56:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=brDfHjsrGgq&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.737009,
				"lng": -122.147306,
				"zipcode": "94577",
				"subPremise": "",
				"address": "923 Helen Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=brDfHjsrGgq&mls=1"
		},
		{
			"closePrice": 475000,
			"daysOnMovoto": 60,
			"id": "3c4e5b20-79f9-4c47-a94e-d50c5203b2f9",
			"tnImgPath": "https://pi.movoto.com/p/12/40971810_0_qVMA6A_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Dallas Hom",
			"listPrice": 489000,
			"lotSize": 89146,
			"sqftTotal": 1193,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971810",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "United Brokers Real Estate",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1983,
			"zipCode": "94577",
			"path": "san-leandro-ca/1400-carpentier-st-apt-438-san-leandro-ca-94577-12_40971810/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a0cb42f3-cea1-4a42-a90b-7fa3ecd264a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-10-21 21:21:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.722349,
				"lng": -122.158204,
				"zipcode": "94577",
				"subPremise": "APT 438",
				"address": "1400 Carpentier St #438"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 870000,
			"daysOnMovoto": 0,
			"id": "0aeb3167-36a3-4a7c-a8f4-0dfd4619c2f7",
			"tnImgPath": "https://pi.movoto.com/p/102/221145128_0_RFyNuN_p.jpeg",
			"listDate": null,
			"listingAgent": "Amy Willbanks Moore",
			"listPrice": 825000,
			"lotSize": 6400,
			"sqftTotal": 1231,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221145128",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Fallavena & Willbanks",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1941,
			"zipCode": "94577",
			"path": "san-leandro-ca/746-elsie-ave-san-leandro-ca-94577/pid_q80avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2697b992-d9a9-4a13-bcd0-c77caf0a8d88",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-17 17:34:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 17:34:20",
			"virtualTourLink": "http://vtours.k-robbdesigns.com/cp/746-elsie-avenue-san-leandro/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.723641,
				"lng": -122.142915,
				"zipcode": "94577",
				"subPremise": "",
				"address": "746 Elsie Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 870000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.k-robbdesigns.com/cp/746-elsie-avenue-san-leandro/"
		},
		{
			"closePrice": 475000,
			"daysOnMovoto": 37,
			"id": "a9f097d1-0c00-47e3-a8b7-d5603b03d02e",
			"tnImgPath": "https://pi.movoto.com/p/12/40973856_0_r7eVEf_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Jeff Clyma",
			"listPrice": 463000,
			"lotSize": 28700,
			"sqftTotal": 1180,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973856",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1983,
			"zipCode": "94577",
			"path": "san-leandro-ca/1132-carpentier-st-403-san-leandro-ca-94577-12_40973856/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b3d69b49-4dc7-4a52-8860-5ec47086bf33",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-10 22:46:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.724886,
				"lng": -122.160242,
				"zipcode": "94577",
				"subPremise": " 403",
				"address": "1132 Carpentier St # 403"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1088888,
			"daysOnMovoto": 55,
			"id": "f6c3be2d-8914-4863-b89f-b13ddeb680a3",
			"tnImgPath": "https://pi.movoto.com/p/12/40971923_0_b3Ne3B_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Rosie Salgado",
			"listPrice": 948888,
			"lotSize": 5656,
			"sqftTotal": 1740,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971923",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94579",
			"path": "san-leandro-ca/15088-andover-st-san-leandro-ca-94579/pid_lwlbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3f4f7edf-368c-49e0-adb6-2480453cf1a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-22 05:31:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.68946,
				"lng": -122.14704,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15088 Andover St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1088888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 875000,
			"daysOnMovoto": 68,
			"id": "5cf3d8d9-03cc-4eb0-9138-d25ad664f0e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40970360_0_YBFu3z_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Paul Hicks",
			"listPrice": 925000,
			"lotSize": 4995,
			"sqftTotal": 2450,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970360",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "MARCUS & MILLICHAP",
			"photoCount": 14,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1956,
			"zipCode": "94577",
			"path": "san-leandro-ca/no-address-san-leandro-ca-94577-12_40970360/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dc363237-1683-41eb-a5df-6183bdc19124",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-09 03:01:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 0,
				"lng": 0,
				"zipcode": "94577",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "994 Karol Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 534950,
			"daysOnMovoto": 70,
			"id": "f7153e80-bbde-4d36-a9ee-00cd430237e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40969856_0_E3AfRN_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Robert Pace",
			"listPrice": 529950,
			"lotSize": 44007,
			"sqftTotal": 1430,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969856",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1976,
			"zipCode": "94577",
			"path": "san-leandro-ca/1550-bancroft-ave-apt-112-san-leandro-ca-94577-12_40969856/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "7fa4e519-0327-49e5-9b43-b4611428e0e5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-06 19:56:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.725051,
				"lng": -122.146476,
				"zipcode": "94577",
				"subPremise": "APT 112",
				"address": "1550 Bancroft Ave #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 534950,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 760000,
			"daysOnMovoto": 57,
			"id": "9c50fdde-98ab-4b3d-b18a-b81066e2d056",
			"tnImgPath": "https://pi.movoto.com/p/12/40971390_0_FNFibe_p.jpeg",
			"listDate": "2021-10-18 00:00:00",
			"listingAgent": "Ryan Dunn",
			"listPrice": 660000,
			"lotSize": 11556,
			"sqftTotal": 1596,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971390",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pacific Coast Real Estate",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "94577",
			"path": "san-leandro-ca/14564-outrigger-dr-san-leandro-ca-94577/pid_cpjbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "766c8c8c-bee8-4cc1-b8bc-f4174e1b0dbd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-10-18 20:21:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-18 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=RyRP6H6x16S&brand=0\"",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.692495,
				"lng": -122.180199,
				"zipcode": "94577",
				"subPremise": "",
				"address": "14564 Outrigger Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 760000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=RyRP6H6x16S&brand=0\""
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 102,
			"id": "7c22d53d-a6aa-4eb3-82db-c1a06e9422f3",
			"tnImgPath": "https://pi.movoto.com/p/12/40965158_0_nMrQVU_p.jpeg",
			"listDate": "2021-09-03 00:00:00",
			"listingAgent": "Dennis Serrao",
			"listPrice": 1375000,
			"lotSize": 13420,
			"sqftTotal": 2944,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965158",
			"bath": 4,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Elation Real Estate",
			"photoCount": 37,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1950,
			"zipCode": "94578",
			"path": "san-leandro-ca/1508-166th-ave-san-leandro-ca-94578/pid_4gebvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "08df85e4-d9ff-46c7-bb56-cd383d7a302e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-09-03 23:31:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 00:00:00",
			"virtualTourLink": "https://1508166thave46271mls.f8re.com/Website/Index",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.694162,
				"lng": -122.108641,
				"zipcode": "94578",
				"subPremise": "",
				"address": "1508 166th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://1508166thave46271mls.f8re.com/Website/Index"
		},
		{
			"closePrice": 435000,
			"daysOnMovoto": 129,
			"id": "9f22bb1d-9ae5-427d-813f-dc4ae5f5db44",
			"tnImgPath": "https://pi.movoto.com/p/12/40962092_0_fQVf6b_p.jpeg",
			"listDate": "2021-08-07 00:00:00",
			"listingAgent": "Nicole Wilhelm",
			"listPrice": 398000,
			"lotSize": 0,
			"sqftTotal": 947,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40962092",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1981,
			"zipCode": "94578",
			"path": "san-leandro-ca/16343-saratoga-st-102e-san-leandro-ca-94578/pid_ppgbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -52000,
			"propertyId": "768440c2-be09-40af-b3ea-65f9e4095033",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-08-07 05:21:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.700694,
				"lng": -122.106726,
				"zipcode": "94578",
				"subPremise": "APT 102E",
				"address": "16343 Saratoga St #102E"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 435000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 1,
			"id": "07adb815-4585-4e68-9de9-a4989ffde908",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-12-08 00:00:00",
			"listingAgent": "Kira Halpern",
			"listPrice": 875000,
			"lotSize": 10000,
			"sqftTotal": 1354,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975856",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "WINKLER REAL ESTATE GROUP",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1926,
			"zipCode": "94577",
			"path": "san-leandro-ca/465-beverly-ave-san-leandro-ca-94577/pid_viyavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b5b27d9b-1342-443b-b145-8a3977bd3396",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-12-08 21:26:35",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-12-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.734434,
				"lng": -122.156183,
				"zipcode": "94577",
				"subPremise": "",
				"address": "465 Beverly Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 722000,
			"daysOnMovoto": 11,
			"id": "84eb5543-d44b-4fc6-ab39-09f04c7dffd4",
			"tnImgPath": "https://pi.movoto.com/p/110/421614161_0_rAIEI6_p.jpeg",
			"listDate": "2021-11-28 00:00:00",
			"listingAgent": "Olivia Lum",
			"listPrice": 679000,
			"lotSize": 5000,
			"sqftTotal": 807,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614161",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Golden Bay Real Estate & Investment",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1942,
			"zipCode": "94577",
			"path": "san-leandro-ca/710-martin-blvd-san-leandro-ca-94577-12_40052733/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0137b0a8-d669-42c2-9a9a-e39604d71000",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-29 23:51:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.724124,
				"lng": -122.178485,
				"zipcode": "94577",
				"subPremise": "",
				"address": "710 Martin Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 722000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 44,
			"id": "ac7dd216-e56c-44d1-a89e-da1587393f2d",
			"tnImgPath": "https://pi.movoto.com/p/12/40972222_0_RaJ7fY_p.jpeg",
			"listDate": "2021-10-25 00:00:00",
			"listingAgent": "Darryl Glass",
			"listPrice": 1100000,
			"lotSize": 5115,
			"sqftTotal": 2144,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972222",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Advent Properties, Inc.",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94578",
			"path": "san-leandro-ca/455-nabor-st-san-leandro-ca-94578/pid_4oabvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5a7e0643-0987-47ad-b603-9ca639390d82",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-26 00:11:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.699387,
				"lng": -122.13453,
				"zipcode": "94578",
				"subPremise": "",
				"address": "455 Nabor St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 53,
			"id": "a341f178-b1fe-4054-8565-82f3b3422d07",
			"tnImgPath": "https://pi.movoto.com/p/101/321097699_0_i2bvMM_p.jpeg",
			"listDate": "2021-10-11 00:00:00",
			"listingAgent": "Debora Graftaas",
			"listPrice": 910000,
			"lotSize": 6050,
			"sqftTotal": 1492,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321097699",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Home 4 All Realty, Inc.",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1953,
			"zipCode": "94577",
			"path": "san-leandro-ca/1368-leonard-dr-san-leandro-ca-94577/pid_096avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "dda3e331-ad5e-4c39-99f7-287860244b00",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-11 20:03:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=xmJv5HW3WjV&ml",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.718236,
				"lng": -122.174538,
				"zipcode": "94577",
				"subPremise": "",
				"address": "1368 Leonard Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=xmJv5HW3WjV&ml"
		},
		{
			"closePrice": 820000,
			"daysOnMovoto": 79,
			"id": "5a71d451-9f3d-44ef-9629-b74c83529aa7",
			"tnImgPath": "https://pi.movoto.com/p/12/40966807_0_6yvMZI_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Melissa Wen",
			"listPrice": 775000,
			"lotSize": 5432,
			"sqftTotal": 1032,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966807",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94578",
			"path": "san-leandro-ca/474-lloyd-san-leandro-ca-94578-12_40966807/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -24000,
			"propertyId": "36784b94-a79b-42cb-ab26-c185d0df65d1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-09-15 18:21:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=LNtPi7hhSPk&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.695042,
				"lng": -122.134575,
				"zipcode": "94578",
				"subPremise": "",
				"address": "474 Lloyd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=LNtPi7hhSPk&mls=1"
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 91,
			"id": "f29a35cb-8fbb-43f7-b840-80b5769493b1",
			"tnImgPath": "https://pi.movoto.com/p/12/40965159_0_fNv2Vy_p.jpeg",
			"listDate": "2021-09-03 00:00:00",
			"listingAgent": "Dennis Serrao",
			"listPrice": 1250000,
			"lotSize": 8800,
			"sqftTotal": 2154,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965159",
			"bath": 2,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Elation Real Estate",
			"photoCount": 37,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94578",
			"path": "san-leandro-ca/1510-166th-ave-san-leandro-ca-94578/pid_0hebvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b9296332-b359-4792-a68a-86c3540d26d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-09-03 23:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 00:00:00",
			"virtualTourLink": "https://1510166thave46272mls.f8re.com/Website/Index",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.694289,
				"lng": -122.10853,
				"zipcode": "94578",
				"subPremise": "",
				"address": "1510 166th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://1510166thave46272mls.f8re.com/Website/Index"
		},
		{
			"closePrice": 984100,
			"daysOnMovoto": 31,
			"id": "3616b329-eb94-4b99-a114-f38b23eca0f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40972864_0_jrUn2A_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Perry Gastis",
			"listPrice": 950000,
			"lotSize": 15846,
			"sqftTotal": 1630,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972864",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Perry Gastis, Broker",
			"photoCount": 3,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1930,
			"zipCode": "94577",
			"path": "san-leandro-ca/12929-neptune-dr-san-leandro-ca-94577/pid_wxdbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e5a64577-5c51-4575-87db-5d4dde692021",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-11-01 19:01:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.70437,
				"lng": -122.190414,
				"zipcode": "94577",
				"subPremise": "",
				"address": "12929 Neptune Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 984100,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 735000,
			"daysOnMovoto": 33,
			"id": "e437383b-5209-40f9-9a6d-dacca3289c4e",
			"tnImgPath": "https://pi.movoto.com/p/12/40972493_0_Nnb6MQ_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Raquel Ramirez",
			"listPrice": 675000,
			"lotSize": 5458,
			"sqftTotal": 969,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972493",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Century 21 RE Alliance",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94578",
			"path": "san-leandro-ca/2082-horne-st-san-leandro-ca-94578/pid_9wgbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "89608d28-7ecf-4afb-93fb-ea0a83487738",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-28 18:06:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/9263181",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.705132,
				"lng": -122.111193,
				"zipcode": "94578",
				"subPremise": "",
				"address": "2082 Horne St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 735000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/9263181"
		},
		{
			"closePrice": 820000,
			"daysOnMovoto": 44,
			"id": "ff263b44-2037-473c-b08c-5f94c3ea1092",
			"tnImgPath": "https://pi.movoto.com/p/12/40971290_0_y6nQjA_p.jpeg",
			"listDate": "2021-10-16 00:00:00",
			"listingAgent": "Paul Litvinchuk",
			"listPrice": 798800,
			"lotSize": 5035,
			"sqftTotal": 1096,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971290",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Litvinchuk Real Estate",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94579",
			"path": "san-leandro-ca/14648-acacia-st-san-leandro-ca-94579-12_40280499/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ca4bcc3f-7759-4f8b-a767-4a957b9096de",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-16 23:36:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.697505,
				"lng": -122.157907,
				"zipcode": "94579",
				"subPremise": "",
				"address": "14648 Acacia St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 760000,
			"daysOnMovoto": 41,
			"id": "fa006873-1fd2-4a5a-9eb7-0f8b882d7fbc",
			"tnImgPath": "https://pi.movoto.com/p/12/40969402_0_VINuQy_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Amanda Lau",
			"listPrice": 699000,
			"lotSize": 1161,
			"sqftTotal": 1400,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969402",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2009,
			"zipCode": "94578",
			"path": "san-leandro-ca/16481-liberty-st-san-leandro-ca-94578-12_40691517/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "64abe345-eaa0-434d-bbbe-06d925a6f50f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-14 18:21:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.697545,
				"lng": -122.107289,
				"zipcode": "94578",
				"subPremise": "",
				"address": "16481 Liberty St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 760000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 830000,
			"daysOnMovoto": 54,
			"id": "87f84f93-19e4-47f9-b995-c19b29200ad1",
			"tnImgPath": "https://pi.movoto.com/p/12/40969252_0_eVAizF_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Karen Knox",
			"listPrice": 845000,
			"lotSize": 5280,
			"sqftTotal": 1602,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969252",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "A.C. Goldt Realty",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94578",
			"path": "san-leandro-ca/404-bradrick-dr-san-leandro-ca-94578/pid_2w8avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6bd2e275-9baf-465a-9f2b-e3180415ec46",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-02 03:06:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.696303,
				"lng": -122.138656,
				"zipcode": "94578",
				"subPremise": "",
				"address": "404 Bradrick Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 825000,
			"daysOnMovoto": 36,
			"id": "2fb31ead-85a5-4c7a-95ad-39f3023fa4c8",
			"tnImgPath": "https://pi.movoto.com/p/12/40970820_0_3qezav_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Tianne Rinetti",
			"listPrice": 799000,
			"lotSize": 5100,
			"sqftTotal": 1640,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970820",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rinetti & Co., REALTORS®",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1940,
			"zipCode": "94577",
			"path": "san-leandro-ca/219-bowling-green-st-san-leandro-ca-94577/pid_v00avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2cb86a1b-317f-414d-b50e-afe6dea1b9c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-14 18:11:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.735381,
				"lng": -122.166511,
				"zipcode": "94577",
				"subPremise": "",
				"address": "219 Bowling Green St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 870000,
			"daysOnMovoto": 45,
			"id": "0440c38e-990a-44b2-9ba8-58a56ac539bd",
			"tnImgPath": "https://pi.movoto.com/p/12/40969704_0_Fffevv_p.jpeg",
			"listDate": "2021-10-05 00:00:00",
			"listingAgent": "Cheryl Keden",
			"listPrice": 818000,
			"lotSize": 5757,
			"sqftTotal": 1029,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969704",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1949,
			"zipCode": "94579",
			"path": "san-leandro-ca/15030-norton-st-san-leandro-ca-94579/pid_sombvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "70692e7f-b17d-43d0-be33-e726ef44967f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-05 19:01:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-05 00:00:00",
			"virtualTourLink": "https://15030nortonst47716mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.690808,
				"lng": -122.14531,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15030 Norton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 870000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://15030nortonst47716mls.f8re.com/"
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 78,
			"id": "bb969663-f062-4856-b538-c2377967f0e2",
			"tnImgPath": "https://pi.movoto.com/p/12/40965324_0_fNafi2_p.jpeg",
			"listDate": "2021-08-31 00:00:00",
			"listingAgent": "Doraliz Reyes Guizar",
			"listPrice": 1600000,
			"lotSize": 14960,
			"sqftTotal": 3209,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965324",
			"bath": 5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Doraliz Guizar, Broker",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1966,
			"zipCode": "94577",
			"path": "san-leandro-ca/2366-w-avenue-133rd-san-leandro-ca-94577-12_40965324/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "c2421d5e-be46-4e1d-a74b-9a6df4683a05",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-08-31 20:51:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.702553,
				"lng": -122.182132,
				"zipcode": "94577",
				"subPremise": "",
				"address": "2366 W Avenue 133rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 449000,
			"daysOnMovoto": 0,
			"id": "fc275890-8980-4bde-9dbb-56fff0b87e13",
			"tnImgPath": "https://pi.movoto.com/p/102/221129228_0_nYUEUm_p.jpeg",
			"listDate": null,
			"listingAgent": "John Acord",
			"listPrice": 449900,
			"lotSize": 14900,
			"sqftTotal": 969,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221129228",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Arda Realty Inc.",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1976,
			"zipCode": "94577",
			"path": "san-leandro-ca/398-parrott-st-apt-102-san-leandro-ca-94577-102_221129228/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "08cd6fce-9a10-4ba5-9ab4-264f6480164c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-08 03:44:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 03:44:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.72155,
				"lng": -122.156533,
				"zipcode": "94577",
				"subPremise": "APT 102",
				"address": "398 Parrott St #102"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 449000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 771000,
			"daysOnMovoto": 38,
			"id": "53384bc1-4d62-4cd5-b2e5-a3aab28a520c",
			"tnImgPath": "https://pi.movoto.com/p/12/40970132_0_UFjQII_p.jpeg",
			"listDate": "2021-10-09 00:00:00",
			"listingAgent": "Louise Lovewell",
			"listPrice": 699000,
			"lotSize": 1000,
			"sqftTotal": 1957,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970132",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1988,
			"zipCode": "94577",
			"path": "san-leandro-ca/13865-seagate-san-leandro-ca-94577-12_40970132/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1a8ffc7-5af3-4a3b-bb43-35237fae2937",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-10-09 15:51:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-09 00:00:00",
			"virtualTourLink": "https://unbranded.youriguide.com/13865_seagate_dr_san_leandro_ca/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.69645,
				"lng": -122.181906,
				"zipcode": "94577",
				"subPremise": "",
				"address": "13865 Seagate"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 771000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://unbranded.youriguide.com/13865_seagate_dr_san_leandro_ca/"
		},
		{
			"closePrice": 615000,
			"daysOnMovoto": 39,
			"id": "36d50fa7-b3db-477c-a295-819b13748376",
			"tnImgPath": "https://pi.movoto.com/p/12/40970304_0_uUMaVv_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Quinn Stone",
			"listPrice": 599000,
			"lotSize": 44007,
			"sqftTotal": 1690,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970304",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Bayside Real Estate",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1976,
			"zipCode": "94577",
			"path": "san-leandro-ca/1550-bancroft-ave-apt-332-san-leandro-ca-94577-12_40970304/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "050279e5-6c46-40ea-bf6d-db0f20f30bf6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-10-08 21:26:56",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.725051,
				"lng": -122.146476,
				"zipcode": "94577",
				"subPremise": "APT 332",
				"address": "1550 Bancroft Ave #332"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 790000,
			"daysOnMovoto": 47,
			"id": "79e7e927-b981-4a77-ad50-4624e29dc6a0",
			"tnImgPath": "https://pi.movoto.com/p/12/40969183_0_aNVqra_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Tessa Drury",
			"listPrice": 699000,
			"lotSize": 5520,
			"sqftTotal": 927,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969183",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94579",
			"path": "san-leandro-ca/15056-swenson-st-san-leandro-ca-94579/pid_r6nbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7e46c6f-3d4c-43c8-8691-473ba1f0e483",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-09-30 22:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.690395,
				"lng": -122.142737,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15056 Swenson St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 790000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 40,
			"id": "ff9b6077-b883-4f77-a5ed-ec9fe763af9a",
			"tnImgPath": "https://pi.movoto.com/p/12/40969274_0_jRnzRY_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Peter Doan",
			"listPrice": 950000,
			"lotSize": 2924,
			"sqftTotal": 2026,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969274",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty Of America Corp.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2002,
			"zipCode": "94577",
			"path": "san-leandro-ca/1292-alder-creek-cir-san-leandro-ca-94577/pid_pszavaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "845d43a7-a89f-4198-8991-39b47d0575ed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-01 13:06:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.727218,
				"lng": -122.17103,
				"zipcode": "94577",
				"subPremise": "",
				"address": "1292 Alder Creek Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 200000,
			"daysOnMovoto": 63,
			"id": "387af82a-3cfa-4eea-9f82-721f4f63cd79",
			"tnImgPath": "https://pi.movoto.com/p/12/40965806_0_EmaFfi_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Greg Novak",
			"listPrice": 249000,
			"lotSize": 6172,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965806",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "RED OAK REALTY",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "94578",
			"path": "san-leandro-ca/placer-dr-san-leandro-ca-94578-12_40965806/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "52c72bfe-ed6e-44b6-8492-9d5a18bf13cf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-08 13:56:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"zipcode": "94578",
				"subPremise": "",
				"address": "Placer Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 848500,
			"daysOnMovoto": 0,
			"id": "b779abb2-4c1f-4294-be56-f6fa004e59c2",
			"tnImgPath": "https://pi.movoto.com/p/102/221124104_0_eQmnnq_p.jpeg",
			"listDate": null,
			"listingAgent": "Jennifer Darling",
			"listPrice": 809868,
			"lotSize": 5798,
			"sqftTotal": 1076,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221124104",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Allison James Estates & Homes",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1948,
			"zipCode": "94579",
			"path": "san-leandro-ca/15137-norton-st-san-leandro-ca-94579/pid_58lbvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "73a400a4-e027-4741-bf11-6ef1f1e6c0c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-01 07:14:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 07:14:21",
			"virtualTourLink": "https://my.matterport.com/show/?m=y85SqdhNMFe",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.689057,
				"lng": -122.145665,
				"zipcode": "94579",
				"subPremise": "",
				"address": "15137 Norton St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 848500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=y85SqdhNMFe"
		},
		{
			"closePrice": 260000,
			"daysOnMovoto": 38,
			"id": "fb9d8df6-a671-4cfd-857f-0e752ab2fe7f",
			"tnImgPath": "https://pi.movoto.com/p/12/40969508_0_VURNzY_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Nasser Haghighi",
			"listPrice": 254950,
			"lotSize": 1568,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969508",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 40,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94579",
			"path": "san-leandro-ca/303-santa-paula-san-leandro-ca-94579-12_40969508/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "617cc1da-307c-487a-9b72-9ad1b118481f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-10-03 01:46:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.684863,
				"lng": -122.161544,
				"zipcode": "94579",
				"subPremise": "",
				"address": "303 Santa Paula"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 865000,
			"daysOnMovoto": 43,
			"id": "a530d51c-5f1c-4460-8bc4-278622ef9344",
			"tnImgPath": "https://pi.movoto.com/p/12/40968760_0_A6BYAe_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Rosario Macalino",
			"listPrice": 849950,
			"lotSize": 6468,
			"sqftTotal": 1073,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968760",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Renrose Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94579",
			"path": "san-leandro-ca/14962-swenson-st-san-leandro-ca-94579-12_40543513/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25050,
			"propertyId": "b3b64455-9207-4efe-a158-3733c782c90c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-09-27 19:11:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": "https://14962SwensonSt47129mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.692155,
				"lng": -122.141983,
				"zipcode": "94579",
				"subPremise": "",
				"address": "14962 Swenson St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 865000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://14962SwensonSt47129mls.f8re.com/"
		},
		{
			"closePrice": 830000,
			"daysOnMovoto": 47,
			"id": "477efc45-5765-4d57-b9a5-71aefdb7deea",
			"tnImgPath": "https://pi.movoto.com/p/12/40968225_0_BRJQzR_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Dennis Ritter",
			"listPrice": 799950,
			"lotSize": 5133,
			"sqftTotal": 1352,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968225",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Home Services",
			"photoCount": 12,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94578",
			"path": "san-leandro-ca/1006-castle-st-san-leandro-ca-94578/pid_vlabvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1678a907-06f2-4261-ab48-9aac7265515e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-09-23 16:31:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.704023,
				"lng": -122.132323,
				"zipcode": "94578",
				"subPremise": "",
				"address": "1006 Castle St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 674000,
			"daysOnMovoto": 0,
			"id": "807be2c8-43ef-4afc-9348-c70bb7e4eaab",
			"tnImgPath": "https://pi.movoto.com/p/12/40973641_0_uFFf6N_p.jpeg",
			"listDate": "2021-11-08 00:00:00",
			"listingAgent": "Philip Kaake",
			"listPrice": 674000,
			"lotSize": 6608,
			"sqftTotal": 1018,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973641",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94578",
			"path": "san-leandro-ca/1719-russ-ave-san-leandro-ca-94578/pid_lbabvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "80997396-12dc-484c-a7a8-4e0bd9e30a92",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-11-08 19:46:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.715226,
				"lng": -122.133022,
				"zipcode": "94578",
				"subPremise": "",
				"address": "1719 Russ Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 674000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 725000,
			"daysOnMovoto": 79,
			"id": "2328ec03-6609-46fd-918a-6bcc63af1add",
			"tnImgPath": "https://pi.movoto.com/p/12/40963482_0_AbqAu2_p.jpeg",
			"listDate": "2021-08-17 00:00:00",
			"listingAgent": "Rick Williams",
			"listPrice": 725000,
			"lotSize": 11811,
			"sqftTotal": 1538,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963482",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sothebys International Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1945,
			"zipCode": "94578",
			"path": "san-leandro-ca/no-address-san-leandro-ca-94578-12_40963482/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "a9e803b6-d9d2-4ddb-9a35-5dfc016ec52d",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-08-17 22:56:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 0,
				"lng": 0,
				"zipcode": "94578",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "14896 Wake Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 725000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 865000,
			"daysOnMovoto": 30,
			"id": "4f4952fe-0ba7-40ba-94d8-60b7863d12d9",
			"tnImgPath": "https://pi.movoto.com/p/12/40968923_0_MfeV6M_p.jpeg",
			"listDate": "2021-10-04 00:00:00",
			"listingAgent": "Binu Engineer",
			"listPrice": 779000,
			"lotSize": 5300,
			"sqftTotal": 1076,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968923",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94579",
			"path": "san-leandro-ca/14528-wiley-st-san-leandro-ca-94579/pid_o04avaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b37c8ba2-7648-4500-92a0-da1ae3367a34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-10-04 16:11:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/5089416",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Leandro",
				"lat": 37.699796,
				"lng": -122.157863,
				"zipcode": "94579",
				"subPremise": "",
				"address": "14528 Wiley St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 865000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/5089416"
		}
	]