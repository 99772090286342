const palo_alto = {
   "geography": {
      "census_release": "ACS 2019 1-year",
      "parents": {
         "county": {
            "full_name": "Santa Clara County, CA",
            "short_name": "Santa Clara County",
            "sumlevel": "050",
            "land_area": 3343890934,
            "full_geoid": "05000US06085",
            "total_population": 1927852
         },
         "CBSA": {
            "full_name": "San Jose-Sunnyvale-Santa Clara, CA Metro Area",
            "short_name": "San Jose-Sunnyvale-Santa Clara, CA",
            "sumlevel": "310",
            "land_area": 6940482016,
            "full_geoid": "31000US41940",
            "total_population": 1990660
         },
         "state": {
            "full_name": "California",
            "short_name": "California",
            "sumlevel": "040",
            "land_area": 403660088482,
            "full_geoid": "04000US06",
            "total_population": 39512223
         },
         "nation": {
            "full_name": "United States",
            "short_name": "United States",
            "sumlevel": "010",
            "land_area": 9160971212108,
            "full_geoid": "01000US",
            "total_population": 328239523
         }
      },
      "this": {
         "full_name": "Palo Alto, CA",
         "short_name": "Palo Alto",
         "sumlevel": "160",
         "land_area": 61779777,
         "full_geoid": "16000US0655282",
         "total_population": 65365,
         "sumlevel_name": "Place",
         "short_geoid": "0655282"
      },
      "comparatives": [
         "CBSA",
         "state"
      ],
      "census_release_year": "19",
      "census_release_level": "1"
   },
   "demographics": {
      "age": {
         "distribution_by_category": {
            "metadata": {
               "table_id": "B01001",
               "universe": "Total population",
               "acs_release": "ACS 2019 1-year"
            },
            "percent_under_18": {
               "name": "Under 18",
               "values": {
                  "this": 24.12,
                  "CBSA": 21.67,
                  "state": 22.5
               },
               "error": {
                  "this": 3.09,
                  "CBSA": 0.3,
                  "state": 0.08
               },
               "numerators": {
                  "this": 15763,
                  "CBSA": 431307,
                  "state": 8891064
               },
               "numerator_errors": {
                  "this": 2017.1,
                  "CBSA": 5887.9,
                  "state": 32008.5
               },
               "index": {
                  "this": 100,
                  "CBSA": 111,
                  "state": 107
               },
               "error_ratio": {
                  "this": 12.8,
                  "CBSA": 1.4,
                  "state": 0.4
               }
            },
            "percent_18_to_64": {
               "name": "18 to 64",
               "values": {
                  "this": 55.34,
                  "CBSA": 64.48,
                  "state": 62.73
               },
               "error": {
                  "this": 4.38,
                  "CBSA": 0.51,
                  "state": 0.13
               },
               "numerators": {
                  "this": 36172,
                  "CBSA": 1283572,
                  "state": 2.478616E7
               },
               "numerator_errors": {
                  "this": 2864.4,
                  "CBSA": 10170.5,
                  "state": 51870.1
               },
               "index": {
                  "this": 100,
                  "CBSA": 86,
                  "state": 88
               },
               "error_ratio": {
                  "this": 7.9,
                  "CBSA": 0.8,
                  "state": 0.2
               }
            },
            "percent_over_65": {
               "name": "65 and over",
               "values": {
                  "this": 20.55,
                  "CBSA": 13.85,
                  "state": 14.77
               },
               "error": {
                  "this": 2.67,
                  "CBSA": 0.33,
                  "state": 0.08
               },
               "numerators": {
                  "this": 13430,
                  "CBSA": 275781,
                  "state": 5834998
               },
               "numerator_errors": {
                  "this": 1748.5,
                  "CBSA": 6572.8,
                  "state": 32031.2
               },
               "index": {
                  "this": 100,
                  "CBSA": 148,
                  "state": 139
               },
               "error_ratio": {
                  "this": 13,
                  "CBSA": 2.4,
                  "state": 0.5
               }
            }
         },
         "distribution_by_decade": {
            "total": {
               "metadata": {
                  "table_id": "B01001",
                  "universe": "Total population",
                  "acs_release": "ACS 2019 1-year"
               },
               "0-9": {
                  "name": "0-9",
                  "values": {
                     "this": 12.17,
                     "CBSA": 11.4,
                     "state": 12.04
                  },
                  "error": {
                     "this": 2.38,
                     "CBSA": 0.21,
                     "state": 0.06
                  },
                  "numerators": {
                     "this": 7953,
                     "CBSA": 226908,
                     "state": 4758601
                  },
                  "numerator_errors": {
                     "this": 1557.5,
                     "CBSA": 4128.5,
                     "state": 23052.8
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 107,
                     "state": 101
                  },
                  "error_ratio": {
                     "this": 19.6,
                     "CBSA": 1.8,
                     "state": 0.5
                  }
               },
               "10-19": {
                  "name": "10-19",
                  "values": {
                     "this": 13.28,
                     "CBSA": 12.62,
                     "state": 13.1
                  },
                  "error": {
                     "this": 2.12,
                     "CBSA": 0.22,
                     "state": 0.06
                  },
                  "numerators": {
                     "this": 8678,
                     "CBSA": 251248,
                     "state": 5176959
                  },
                  "numerator_errors": {
                     "this": 1384,
                     "CBSA": 4326,
                     "state": 23034.2
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 105,
                     "state": 101
                  },
                  "error_ratio": {
                     "this": 16,
                     "CBSA": 1.7,
                     "state": 0.5
                  }
               },
               "20-29": {
                  "name": "20-29",
                  "values": {
                     "this": 10.03,
                     "CBSA": 14.2,
                     "state": 14.49
                  },
                  "error": {
                     "this": 2.29,
                     "CBSA": 0.25,
                     "state": 0.06
                  },
                  "numerators": {
                     "this": 6558,
                     "CBSA": 282582,
                     "state": 5723923
                  },
                  "numerator_errors": {
                     "this": 1497.1,
                     "CBSA": 4901.7,
                     "state": 24219.3
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 71,
                     "state": 69
                  },
                  "error_ratio": {
                     "this": 22.8,
                     "CBSA": 1.8,
                     "state": 0.4
                  }
               },
               "30-39": {
                  "name": "30-39",
                  "values": {
                     "this": 12.49,
                     "CBSA": 15.73,
                     "state": 14.47
                  },
                  "error": {
                     "this": 2.11,
                     "CBSA": 0.23,
                     "state": 0.06
                  },
                  "numerators": {
                     "this": 8165,
                     "CBSA": 313158,
                     "state": 5715837
                  },
                  "numerator_errors": {
                     "this": 1378.7,
                     "CBSA": 4601.1,
                     "state": 22852.8
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 79,
                     "state": 86
                  },
                  "error_ratio": {
                     "this": 16.9,
                     "CBSA": 1.5,
                     "state": 0.4
                  }
               },
               "40-49": {
                  "name": "40-49",
                  "values": {
                     "this": 13.51,
                     "CBSA": 13.7,
                     "state": 12.77
                  },
                  "error": {
                     "this": 2.08,
                     "CBSA": 0.22,
                     "state": 0.06
                  },
                  "numerators": {
                     "this": 8829,
                     "CBSA": 272672,
                     "state": 5046965
                  },
                  "numerator_errors": {
                     "this": 1358.9,
                     "CBSA": 4361.4,
                     "state": 22530.5
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 99,
                     "state": 106
                  },
                  "error_ratio": {
                     "this": 15.4,
                     "CBSA": 1.6,
                     "state": 0.5
                  }
               },
               "50-59": {
                  "name": "50-59",
                  "values": {
                     "this": 13.66,
                     "CBSA": 12.82,
                     "state": 12.46
                  },
                  "error": {
                     "this": 1.85,
                     "CBSA": 0.21,
                     "state": 0.05
                  },
                  "numerators": {
                     "this": 8932,
                     "CBSA": 255250,
                     "state": 4923782
                  },
                  "numerator_errors": {
                     "this": 1206.7,
                     "CBSA": 4138.3,
                     "state": 20800
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 107,
                     "state": 110
                  },
                  "error_ratio": {
                     "this": 13.5,
                     "CBSA": 1.6,
                     "state": 0.4
                  }
               },
               "60-69": {
                  "name": "60-69",
                  "values": {
                     "this": 9.26,
                     "CBSA": 10.03,
                     "state": 10.63
                  },
                  "error": {
                     "this": 1.68,
                     "CBSA": 0.3,
                     "state": 0.08
                  },
                  "numerators": {
                     "this": 6055,
                     "CBSA": 199720,
                     "state": 4200080
                  },
                  "numerator_errors": {
                     "this": 1097.3,
                     "CBSA": 6027.7,
                     "state": 31191.1
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 92,
                     "state": 87
                  },
                  "error_ratio": {
                     "this": 18.1,
                     "CBSA": 3,
                     "state": 0.8
                  }
               },
               "70-79": {
                  "name": "70-79",
                  "values": {
                     "this": 8.5,
                     "CBSA": 5.91,
                     "state": 6.44
                  },
                  "error": {
                     "this": 1.7,
                     "CBSA": 0.19,
                     "state": 0.05
                  },
                  "numerators": {
                     "this": 5553,
                     "CBSA": 117680,
                     "state": 2545061
                  },
                  "numerator_errors": {
                     "this": 1113,
                     "CBSA": 3875.8,
                     "state": 19797.9
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 144,
                     "state": 132
                  },
                  "error_ratio": {
                     "this": 20,
                     "CBSA": 3.2,
                     "state": 0.8
                  }
               },
               "80+": {
                  "name": "80+",
                  "values": {
                     "this": 7.1,
                     "CBSA": 3.59,
                     "state": 3.6
                  },
                  "error": {
                     "this": 1.61,
                     "CBSA": 0.18,
                     "state": 0.04
                  },
                  "numerators": {
                     "this": 4642,
                     "CBSA": 71442,
                     "state": 1421015
                  },
                  "numerator_errors": {
                     "this": 1049.5,
                     "CBSA": 3584.6,
                     "state": 16282.4
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 198,
                     "state": 197
                  },
                  "error_ratio": {
                     "this": 22.7,
                     "CBSA": 5,
                     "state": 1.1
                  }
               }
            },
            "male": {
               "metadata": {
                  "table_id": "B01001",
                  "universe": "Total population",
                  "acs_release": "ACS 2019 1-year"
               },
               "0-9": {
                  "name": "0-9",
                  "values": {
                     "this": 15.13,
                     "CBSA": 11.36,
                     "state": 12.35
                  },
                  "error": {
                     "this": 4.04,
                     "CBSA": 0.27,
                     "state": 0.09
                  },
                  "numerators": {
                     "this": 4736,
                     "CBSA": 114531,
                     "state": 2425066
                  },
                  "numerator_errors": {
                     "this": 1283.9,
                     "CBSA": 2720.7,
                     "state": 17537.1
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 133,
                     "state": 123
                  },
                  "error_ratio": {
                     "this": 26.7,
                     "CBSA": 2.4,
                     "state": 0.7
                  }
               },
               "10-19": {
                  "name": "10-19",
                  "values": {
                     "this": 12.3,
                     "CBSA": 12.91,
                     "state": 13.47
                  },
                  "error": {
                     "this": 2.81,
                     "CBSA": 0.29,
                     "state": 0.09
                  },
                  "numerators": {
                     "this": 3848,
                     "CBSA": 130176,
                     "state": 2646132
                  },
                  "numerator_errors": {
                     "this": 897.3,
                     "CBSA": 2892.4,
                     "state": 17211.8
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 95,
                     "state": 91
                  },
                  "error_ratio": {
                     "this": 22.8,
                     "CBSA": 2.2,
                     "state": 0.7
                  }
               },
               "20-29": {
                  "name": "20-29",
                  "values": {
                     "this": 10.21,
                     "CBSA": 15.04,
                     "state": 15.06
                  },
                  "error": {
                     "this": 3.04,
                     "CBSA": 0.33,
                     "state": 0.08
                  },
                  "numerators": {
                     "this": 3194,
                     "CBSA": 151632,
                     "state": 2956969
                  },
                  "numerator_errors": {
                     "this": 962.8,
                     "CBSA": 3378.7,
                     "state": 16123.7
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 68,
                     "state": 68
                  },
                  "error_ratio": {
                     "this": 29.8,
                     "CBSA": 2.2,
                     "state": 0.5
                  }
               },
               "30-39": {
                  "name": "30-39",
                  "values": {
                     "this": 13.51,
                     "CBSA": 16.55,
                     "state": 14.93
                  },
                  "error": {
                     "this": 3.09,
                     "CBSA": 0.38,
                     "state": 0.09
                  },
                  "numerators": {
                     "this": 4229,
                     "CBSA": 166869,
                     "state": 2932306
                  },
                  "numerator_errors": {
                     "this": 986.9,
                     "CBSA": 3850.8,
                     "state": 17684.1
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 82,
                     "state": 90
                  },
                  "error_ratio": {
                     "this": 22.9,
                     "CBSA": 2.3,
                     "state": 0.6
                  }
               },
               "40-49": {
                  "name": "40-49",
                  "values": {
                     "this": 13.64,
                     "CBSA": 13.52,
                     "state": 12.82
                  },
                  "error": {
                     "this": 2.78,
                     "CBSA": 0.34,
                     "state": 0.09
                  },
                  "numerators": {
                     "this": 4269,
                     "CBSA": 136309,
                     "state": 2518785
                  },
                  "numerator_errors": {
                     "this": 893,
                     "CBSA": 3398.8,
                     "state": 17201
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 101,
                     "state": 106
                  },
                  "error_ratio": {
                     "this": 20.4,
                     "CBSA": 2.5,
                     "state": 0.7
                  }
               },
               "50-59": {
                  "name": "50-59",
                  "values": {
                     "this": 12.96,
                     "CBSA": 12.66,
                     "state": 12.42
                  },
                  "error": {
                     "this": 2.41,
                     "CBSA": 0.3,
                     "state": 0.08
                  },
                  "numerators": {
                     "this": 4057,
                     "CBSA": 127634,
                     "state": 2439055
                  },
                  "numerator_errors": {
                     "this": 778.2,
                     "CBSA": 3031.9,
                     "state": 15253.7
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 102,
                     "state": 104
                  },
                  "error_ratio": {
                     "this": 18.6,
                     "CBSA": 2.4,
                     "state": 0.6
                  }
               },
               "60-69": {
                  "name": "60-69",
                  "values": {
                     "this": 8.61,
                     "CBSA": 9.84,
                     "state": 10.2
                  },
                  "error": {
                     "this": 2.4,
                     "CBSA": 0.45,
                     "state": 0.11
                  },
                  "numerators": {
                     "this": 2695,
                     "CBSA": 99211,
                     "state": 2004301
                  },
                  "numerator_errors": {
                     "this": 760.8,
                     "CBSA": 4513.4,
                     "state": 21480.4
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 88,
                     "state": 84
                  },
                  "error_ratio": {
                     "this": 27.9,
                     "CBSA": 4.6,
                     "state": 1.1
                  }
               },
               "70-79": {
                  "name": "70-79",
                  "values": {
                     "this": 7.26,
                     "CBSA": 5.31,
                     "state": 5.89
                  },
                  "error": {
                     "this": 1.83,
                     "CBSA": 0.27,
                     "state": 0.07
                  },
                  "numerators": {
                     "this": 2272,
                     "CBSA": 53516,
                     "state": 1156395
                  },
                  "numerator_errors": {
                     "this": 582.5,
                     "CBSA": 2722.6,
                     "state": 13944.7
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 137,
                     "state": 123
                  },
                  "error_ratio": {
                     "this": 25.2,
                     "CBSA": 5.1,
                     "state": 1.2
                  }
               },
               "80+": {
                  "name": "80+",
                  "values": {
                     "this": 6.37,
                     "CBSA": 2.8,
                     "state": 2.86
                  },
                  "error": {
                     "this": 2.01,
                     "CBSA": 0.2,
                     "state": 0.05
                  },
                  "numerators": {
                     "this": 1993,
                     "CBSA": 28278,
                     "state": 561785
                  },
                  "numerator_errors": {
                     "this": 634.7,
                     "CBSA": 2064.4,
                     "state": 10571.6
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 228,
                     "state": 223
                  },
                  "error_ratio": {
                     "this": 31.6,
                     "CBSA": 7.1,
                     "state": 1.7
                  }
               }
            },
            "female": {
               "metadata": {
                  "table_id": "B01001",
                  "universe": "Total population",
                  "acs_release": "ACS 2019 1-year"
               },
               "0-9": {
                  "name": "0-9",
                  "values": {
                     "this": 9.44,
                     "CBSA": 11.44,
                     "state": 11.74
                  },
                  "error": {
                     "this": 2.56,
                     "CBSA": 0.32,
                     "state": 0.08
                  },
                  "numerators": {
                     "this": 3217,
                     "CBSA": 112377,
                     "state": 2333535
                  },
                  "numerator_errors": {
                     "this": 881.7,
                     "CBSA": 3105.2,
                     "state": 14962.6
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 83,
                     "state": 80
                  },
                  "error_ratio": {
                     "this": 27.1,
                     "CBSA": 2.8,
                     "state": 0.7
                  }
               },
               "10-19": {
                  "name": "10-19",
                  "values": {
                     "this": 14.18,
                     "CBSA": 12.32,
                     "state": 12.74
                  },
                  "error": {
                     "this": 3.03,
                     "CBSA": 0.33,
                     "state": 0.08
                  },
                  "numerators": {
                     "this": 4830,
                     "CBSA": 121072,
                     "state": 2530827
                  },
                  "numerator_errors": {
                     "this": 1053.7,
                     "CBSA": 3216.8,
                     "state": 15307.8
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 115,
                     "state": 111
                  },
                  "error_ratio": {
                     "this": 21.4,
                     "CBSA": 2.7,
                     "state": 0.6
                  }
               },
               "20-29": {
                  "name": "20-29",
                  "values": {
                     "this": 9.87,
                     "CBSA": 13.33,
                     "state": 13.92
                  },
                  "error": {
                     "this": 3.34,
                     "CBSA": 0.36,
                     "state": 0.09
                  },
                  "numerators": {
                     "this": 3364,
                     "CBSA": 130950,
                     "state": 2766954
                  },
                  "numerator_errors": {
                     "this": 1146.5,
                     "CBSA": 3551.2,
                     "state": 18072.2
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 74,
                     "state": 71
                  },
                  "error_ratio": {
                     "this": 33.8,
                     "CBSA": 2.7,
                     "state": 0.6
                  }
               },
               "30-39": {
                  "name": "30-39",
                  "values": {
                     "this": 11.55,
                     "CBSA": 14.89,
                     "state": 14.01
                  },
                  "error": {
                     "this": 2.78,
                     "CBSA": 0.26,
                     "state": 0.07
                  },
                  "numerators": {
                     "this": 3936,
                     "CBSA": 146289,
                     "state": 2783531
                  },
                  "numerator_errors": {
                     "this": 962.8,
                     "CBSA": 2518.1,
                     "state": 14474.8
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 78,
                     "state": 82
                  },
                  "error_ratio": {
                     "this": 24.1,
                     "CBSA": 1.7,
                     "state": 0.5
                  }
               },
               "40-49": {
                  "name": "40-49",
                  "values": {
                     "this": 13.38,
                     "CBSA": 13.88,
                     "state": 12.72
                  },
                  "error": {
                     "this": 2.95,
                     "CBSA": 0.28,
                     "state": 0.07
                  },
                  "numerators": {
                     "this": 4560,
                     "CBSA": 136363,
                     "state": 2528180
                  },
                  "numerator_errors": {
                     "this": 1024.2,
                     "CBSA": 2733.1,
                     "state": 14551.7
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 96,
                     "state": 105
                  },
                  "error_ratio": {
                     "this": 22,
                     "CBSA": 2,
                     "state": 0.6
                  }
               },
               "50-59": {
                  "name": "50-59",
                  "values": {
                     "this": 14.31,
                     "CBSA": 12.99,
                     "state": 12.5
                  },
                  "error": {
                     "this": 2.64,
                     "CBSA": 0.29,
                     "state": 0.07
                  },
                  "numerators": {
                     "this": 4875,
                     "CBSA": 127616,
                     "state": 2484727
                  },
                  "numerator_errors": {
                     "this": 922.2,
                     "CBSA": 2816.6,
                     "state": 14140.9
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 110,
                     "state": 114
                  },
                  "error_ratio": {
                     "this": 18.4,
                     "CBSA": 2.2,
                     "state": 0.6
                  }
               },
               "60-69": {
                  "name": "60-69",
                  "values": {
                     "this": 9.86,
                     "CBSA": 10.23,
                     "state": 11.05
                  },
                  "error": {
                     "this": 2.28,
                     "CBSA": 0.41,
                     "state": 0.11
                  },
                  "numerators": {
                     "this": 3360,
                     "CBSA": 100509,
                     "state": 2195779
                  },
                  "numerator_errors": {
                     "this": 790.8,
                     "CBSA": 3995.4,
                     "state": 22615.9
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 96,
                     "state": 89
                  },
                  "error_ratio": {
                     "this": 23.1,
                     "CBSA": 4,
                     "state": 1
                  }
               },
               "70-79": {
                  "name": "70-79",
                  "values": {
                     "this": 9.63,
                     "CBSA": 6.53,
                     "state": 6.99
                  },
                  "error": {
                     "this": 2.75,
                     "CBSA": 0.28,
                     "state": 0.07
                  },
                  "numerators": {
                     "this": 3281,
                     "CBSA": 64164,
                     "state": 1388666
                  },
                  "numerator_errors": {
                     "this": 948.5,
                     "CBSA": 2758.5,
                     "state": 14053.7
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 147,
                     "state": 138
                  },
                  "error_ratio": {
                     "this": 28.6,
                     "CBSA": 4.3,
                     "state": 1
                  }
               },
               "80+": {
                  "name": "80+",
                  "values": {
                     "this": 7.77,
                     "CBSA": 4.39,
                     "state": 4.32
                  },
                  "error": {
                     "this": 2.43,
                     "CBSA": 0.3,
                     "state": 0.06
                  },
                  "numerators": {
                     "this": 2649,
                     "CBSA": 43164,
                     "state": 859230
                  },
                  "numerator_errors": {
                     "this": 835.8,
                     "CBSA": 2930.5,
                     "state": 12383.7
                  },
                  "index": {
                     "this": 100,
                     "CBSA": 177,
                     "state": 180
                  },
                  "error_ratio": {
                     "this": 31.3,
                     "CBSA": 6.8,
                     "state": 1.4
                  }
               }
            }
         },
         "median_age": {
            "total": {
               "name": "Median age",
               "values": {
                  "this": 41.3,
                  "CBSA": 37.4,
                  "state": 37
               },
               "error": {
                  "this": 2.5,
                  "CBSA": 0.1,
                  "state": 0.1
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "metadata": {
                  "table_id": "B01002",
                  "universe": "Total population",
                  "acs_release": "ACS 2019 1-year"
               },
               "index": {
                  "this": 100,
                  "CBSA": 110,
                  "state": 112
               },
               "error_ratio": {
                  "this": 6.1,
                  "CBSA": 0.3,
                  "state": 0.3
               }
            },
            "male": {
               "name": "Median age male",
               "values": {
                  "this": 39.2,
                  "CBSA": 36.3,
                  "state": 35.9
               },
               "error": {
                  "this": 2.9,
                  "CBSA": 0.2,
                  "state": 0.1
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "metadata": {
                  "table_id": "B01002",
                  "universe": "Total population",
                  "acs_release": "ACS 2019 1-year"
               },
               "index": {
                  "this": 100,
                  "CBSA": 108,
                  "state": 109
               },
               "error_ratio": {
                  "this": 7.4,
                  "CBSA": 0.6,
                  "state": 0.3
               }
            },
            "female": {
               "name": "Median age female",
               "values": {
                  "this": 44.2,
                  "CBSA": 38.5,
                  "state": 38.2
               },
               "error": {
                  "this": 4,
                  "CBSA": 0.2,
                  "state": 0.1
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "metadata": {
                  "table_id": "B01002",
                  "universe": "Total population",
                  "acs_release": "ACS 2019 1-year"
               },
               "index": {
                  "this": 100,
                  "CBSA": 115,
                  "state": 116
               },
               "error_ratio": {
                  "this": 9,
                  "CBSA": 0.5,
                  "state": 0.3
               }
            }
         }
      },
      "sex": {
         "metadata": {
            "table_id": "B01001",
            "universe": "Total population",
            "acs_release": "ACS 2019 1-year"
         },
         "percent_male": {
            "name": "Male",
            "values": {
               "this": 47.87,
               "CBSA": 50.64,
               "state": 49.71
            },
            "error": {
               "this": 2.21,
               "CBSA": 0.05,
               "state": 0.02
            },
            "numerators": {
               "this": 31293,
               "CBSA": 1008156,
               "state": 1.9640794E7
            },
            "numerator_errors": {
               "this": 1443,
               "CBSA": 1022,
               "state": 6786
            },
            "index": {
               "this": 100,
               "CBSA": 95,
               "state": 96
            },
            "error_ratio": {
               "this": 4.6,
               "CBSA": 0.1,
               "state": null
            }
         },
         "percent_female": {
            "name": "Female",
            "values": {
               "this": 52.13,
               "CBSA": 49.36,
               "state": 50.29
            },
            "error": {
               "this": 2.21,
               "CBSA": 0.05,
               "state": 0.02
            },
            "numerators": {
               "this": 34072,
               "CBSA": 982504,
               "state": 1.9871428E7
            },
            "numerator_errors": {
               "this": 1443,
               "CBSA": 1022,
               "state": 6786
            },
            "index": {
               "this": 100,
               "CBSA": 106,
               "state": 104
            },
            "error_ratio": {
               "this": 4.2,
               "CBSA": 0.1,
               "state": null
            }
         }
      },
      "race": {
         "metadata": {
            "table_id": "B03002",
            "universe": "Total population",
            "acs_release": "ACS 2019 5-year"
         },
         "percent_white": {
            "name": "White",
            "values": {
               "this": 54.95,
               "CBSA": 31.62,
               "state": 37.18
            },
            "error": {
               "this": 1.71,
               "CBSA": 0.04,
               "state": 0.01
            },
            "numerators": {
               "this": 36579,
               "CBSA": 628606,
               "state": 1.4605312E7
            },
            "numerator_errors": {
               "this": 1139,
               "CBSA": 844,
               "state": 3168
            },
            "index": {
               "this": 100,
               "CBSA": 174,
               "state": 148
            },
            "error_ratio": {
               "this": 3.1,
               "CBSA": 0.1,
               "state": null
            }
         },
         "percent_black": {
            "name": "Black",
            "values": {
               "this": 1.76,
               "CBSA": 2.3,
               "state": 5.52
            },
            "error": {
               "this": 0.57,
               "CBSA": 0.05,
               "state": 0.02
            },
            "numerators": {
               "this": 1171,
               "CBSA": 45713,
               "state": 2169155
            },
            "numerator_errors": {
               "this": 378,
               "CBSA": 926,
               "state": 6936
            },
            "index": {
               "this": 100,
               "CBSA": 77,
               "state": 32
            },
            "error_ratio": {
               "this": 32.4,
               "CBSA": 2.2,
               "state": 0.4
            }
         },
         "percent_native": {
            "name": "Native",
            "values": {
               "this": 0.2,
               "CBSA": 0.18,
               "state": 0.36
            },
            "error": {
               "this": 0.15,
               "CBSA": 0.02,
               "state": 0.01
            },
            "numerators": {
               "this": 134,
               "CBSA": 3552,
               "state": 140831
            },
            "numerator_errors": {
               "this": 99,
               "CBSA": 409,
               "state": 2516
            },
            "index": {
               "this": 100,
               "CBSA": 111,
               "state": 56
            },
            "error_ratio": {
               "this": 75,
               "CBSA": 11.1,
               "state": 2.8
            }
         },
         "percent_asian": {
            "name": "Asian",
            "values": {
               "this": 32.39,
               "CBSA": 35.27,
               "state": 14.28
            },
            "error": {
               "this": 1.48,
               "CBSA": 0.1,
               "state": 0.02
            },
            "numerators": {
               "this": 21562,
               "CBSA": 701044,
               "state": 5610931
            },
            "numerator_errors": {
               "this": 986,
               "CBSA": 2065,
               "state": 9199
            },
            "index": {
               "this": 100,
               "CBSA": 92,
               "state": 227
            },
            "error_ratio": {
               "this": 4.6,
               "CBSA": 0.3,
               "state": 0.1
            }
         },
         "percent_islander": {
            "name": "Islander",
            "values": {
               "this": 0.09,
               "CBSA": 0.32,
               "state": 0.36
            },
            "error": {
               "this": 0.09,
               "CBSA": 0.03,
               "state": 0.01
            },
            "numerators": {
               "this": 57,
               "CBSA": 6265,
               "state": 140788
            },
            "numerator_errors": {
               "this": 62,
               "CBSA": 499,
               "state": 2224
            },
            "index": {
               "this": 100,
               "CBSA": 28,
               "state": 25
            },
            "error_ratio": {
               "this": 100,
               "CBSA": 9.4,
               "state": 2.8
            }
         },
         "percent_other": {
            "name": "Other",
            "values": {
               "this": 0.43,
               "CBSA": 0.28,
               "state": 0.25
            },
            "error": {
               "this": 0.23,
               "CBSA": 0.05,
               "state": 0.01
            },
            "numerators": {
               "this": 287,
               "CBSA": 5606,
               "state": 100119
            },
            "numerator_errors": {
               "this": 156,
               "CBSA": 920,
               "state": 3715
            },
            "index": {
               "this": 100,
               "CBSA": 154,
               "state": 172
            },
            "error_ratio": {
               "this": 53.5,
               "CBSA": 17.9,
               "state": 4
            }
         },
         "percent_two_or_more": {
            "name": "Two+",
            "values": {
               "this": 4.64,
               "CBSA": 3.52,
               "state": 3.03
            },
            "error": {
               "this": 0.88,
               "CBSA": 0.11,
               "state": 0.04
            },
            "numerators": {
               "this": 3088,
               "CBSA": 70001,
               "state": 1188673
            },
            "numerator_errors": {
               "this": 588,
               "CBSA": 2135,
               "state": 14463
            },
            "index": {
               "this": 100,
               "CBSA": 132,
               "state": 153
            },
            "error_ratio": {
               "this": 19,
               "CBSA": 3.1,
               "state": 1.3
            }
         },
         "percent_hispanic": {
            "name": "Hispanic",
            "values": {
               "this": 5.55,
               "CBSA": 26.51,
               "state": 39.02
            },
            "error": {
               "this": 0.99,
               "CBSA": 0,
               "state": 0
            },
            "numerators": {
               "this": 3695,
               "CBSA": 527059,
               "state": 1.5327688E7
            },
            "numerator_errors": {
               "this": 661,
               "CBSA": 0,
               "state": 0
            },
            "index": {
               "this": 100,
               "CBSA": 21,
               "state": 14
            },
            "error_ratio": {
               "this": 17.8,
               "CBSA": null,
               "state": null
            }
         }
      }
   },
   "economics": {
      "income": {
         "per_capita_income_in_the_last_12_months": {
            "name": "Per capita income",
            "values": {
               "this": 98284,
               "CBSA": 61400,
               "state": 39393
            },
            "error": {
               "this": 9394,
               "CBSA": 878,
               "state": 177
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "metadata": {
               "table_id": "B19301",
               "universe": "Total population",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 160,
               "state": 249
            },
            "error_ratio": {
               "this": 9.6,
               "CBSA": 1.4,
               "state": 0.4
            }
         },
         "median_household_income": {
            "name": "Median household income",
            "values": {
               "this": 160360,
               "CBSA": 130865,
               "state": 80440
            },
            "error": {
               "this": 16831,
               "CBSA": 2664,
               "state": 313
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "metadata": {
               "table_id": "B19013",
               "universe": "Households",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 123,
               "state": 199
            },
            "error_ratio": {
               "this": 10.5,
               "CBSA": 2,
               "state": 0.4
            }
         },
         "household_distribution": {
            "metadata": {
               "table_id": "B19001",
               "universe": "Households",
               "acs_release": "ACS 2019 1-year"
            },
            "under_50": {
               "name": "Under $50K",
               "values": {
                  "this": 12.4,
                  "CBSA": 18.7,
                  "state": 31.68
               },
               "error": {
                  "this": 3.32,
                  "CBSA": 0.93,
                  "state": 0.26
               },
               "numerators": {
                  "this": 3122,
                  "CBSA": 123983,
                  "state": 4167825
               },
               "numerator_errors": {
                  "this": 851.5,
                  "CBSA": 6232,
                  "state": 34680.6
               },
               "index": {
                  "this": 100,
                  "CBSA": 66,
                  "state": 39
               },
               "error_ratio": {
                  "this": 26.8,
                  "CBSA": 5,
                  "state": 0.8
               }
            },
            "50_to_100": {
               "name": "$50K - $100K",
               "values": {
                  "this": 17.13,
                  "CBSA": 20.64,
                  "state": 27.83
               },
               "error": {
                  "this": 4.05,
                  "CBSA": 0.88,
                  "state": 0.24
               },
               "numerators": {
                  "this": 4311,
                  "CBSA": 136795,
                  "state": 3661397
               },
               "numerator_errors": {
                  "this": 1042.8,
                  "CBSA": 5940.2,
                  "state": 32290.4
               },
               "index": {
                  "this": 100,
                  "CBSA": 83,
                  "state": 62
               },
               "error_ratio": {
                  "this": 23.6,
                  "CBSA": 4.3,
                  "state": 0.9
               }
            },
            "100_to_200": {
               "name": "$100K - $200K",
               "values": {
                  "this": 29.28,
                  "CBSA": 29.6,
                  "state": 26.8
               },
               "error": {
                  "this": 5.25,
                  "CBSA": 0.97,
                  "state": 0.24
               },
               "numerators": {
                  "this": 7369,
                  "CBSA": 196234,
                  "state": 3525910
               },
               "numerator_errors": {
                  "this": 1375.1,
                  "CBSA": 6546.3,
                  "state": 31856.1
               },
               "index": {
                  "this": 100,
                  "CBSA": 99,
                  "state": 109
               },
               "error_ratio": {
                  "this": 17.9,
                  "CBSA": 3.3,
                  "state": 0.9
               }
            },
            "over_200": {
               "name": "Over $200K",
               "values": {
                  "this": 41.19,
                  "CBSA": 31.05,
                  "state": 13.7
               },
               "error": {
                  "this": 3.79,
                  "CBSA": 0.78,
                  "state": 0.14
               },
               "numerators": {
                  "this": 10366,
                  "CBSA": 205830,
                  "state": 1802741
               },
               "numerator_errors": {
                  "this": 1094,
                  "CBSA": 5376,
                  "state": 18330
               },
               "index": {
                  "this": 100,
                  "CBSA": 133,
                  "state": 301
               },
               "error_ratio": {
                  "this": 9.2,
                  "CBSA": 2.5,
                  "state": 1
               }
            }
         }
      },
      "poverty": {
         "percent_below_poverty_line": {
            "name": "Persons below poverty line",
            "values": {
               "this": 3.82,
               "CBSA": 6.32,
               "state": 11.75
            },
            "error": {
               "this": 1.28,
               "CBSA": 0.46,
               "state": 0.18
            },
            "numerators": {
               "this": 2475,
               "CBSA": 123477,
               "state": 4552837
            },
            "numerator_errors": {
               "this": 830,
               "CBSA": 9048,
               "state": 67995
            },
            "metadata": {
               "table_id": "B17001",
               "universe": "Population for whom poverty status is determined",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 60,
               "state": 33
            },
            "error_ratio": {
               "this": 33.5,
               "CBSA": 7.3,
               "state": 1.5
            }
         },
         "children": {
            "metadata": {
               "table_id": "B17001",
               "universe": "Population for whom poverty status is determined",
               "acs_release": "ACS 2019 1-year"
            },
            "Below": {
               "name": "Poverty",
               "values": {
                  "this": 2.27,
                  "CBSA": 5.83,
                  "state": 15.62
               },
               "error": {
                  "this": 4.41,
                  "CBSA": 0.73,
                  "state": 0.27
               },
               "numerators": {
                  "this": 357,
                  "CBSA": 24628,
                  "state": 1363574
               },
               "numerator_errors": {
                  "this": 695.5,
                  "CBSA": 3110.3,
                  "state": 24624.2
               },
               "index": {
                  "this": 100,
                  "CBSA": 39,
                  "state": 15
               },
               "error_ratio": {
                  "this": 194.3,
                  "CBSA": 12.5,
                  "state": 1.7
               }
            },
            "above": {
               "name": "Non-poverty",
               "values": {
                  "this": 97.73,
                  "CBSA": 94.17,
                  "state": 84.38
               },
               "error": {
                  "this": 18.32,
                  "CBSA": 2.51,
                  "state": 0.61
               },
               "numerators": {
                  "this": 15385,
                  "CBSA": 398137,
                  "state": 7365259
               },
               "numerator_errors": {
                  "this": 2004.9,
                  "CBSA": 7421.4,
                  "state": 37404.1
               },
               "index": {
                  "this": 100,
                  "CBSA": 104,
                  "state": 116
               },
               "error_ratio": {
                  "this": 18.7,
                  "CBSA": 2.7,
                  "state": 0.7
               }
            }
         },
         "seniors": {
            "metadata": {
               "table_id": "B17001",
               "universe": "Population for whom poverty status is determined",
               "acs_release": "ACS 2019 1-year"
            },
            "Below": {
               "name": "Poverty",
               "values": {
                  "this": 5.12,
                  "CBSA": 7.93,
                  "state": 10.45
               },
               "error": {
                  "this": 2.46,
                  "CBSA": 0.86,
                  "state": 0.23
               },
               "numerators": {
                  "this": 666,
                  "CBSA": 21528,
                  "state": 599877
               },
               "numerator_errors": {
                  "this": 330.9,
                  "CBSA": 2362.9,
                  "state": 13070.4
               },
               "index": {
                  "this": 100,
                  "CBSA": 65,
                  "state": 49
               },
               "error_ratio": {
                  "this": 48,
                  "CBSA": 10.8,
                  "state": 2.2
               }
            },
            "above": {
               "name": "Non-poverty",
               "values": {
                  "this": 94.88,
                  "CBSA": 92.07,
                  "state": 89.55
               },
               "error": {
                  "this": 2.94,
                  "CBSA": 1.55,
                  "state": 0.39
               },
               "numerators": {
                  "this": 12345,
                  "CBSA": 249964,
                  "state": 5138754
               },
               "numerator_errors": {
                  "this": 1565.5,
                  "CBSA": 2657.4,
                  "state": 14255.5
               },
               "index": {
                  "this": 100,
                  "CBSA": 103,
                  "state": 106
               },
               "error_ratio": {
                  "this": 3.1,
                  "CBSA": 1.7,
                  "state": 0.4
               }
            }
         }
      },
      "employment": {
         "mean_travel_time": {
            "name": "Mean travel time to work",
            "values": {
               "this": 26.11,
               "CBSA": 30.26,
               "state": 30.68
            },
            "error": {
               "this": 1.75,
               "CBSA": 0.43,
               "state": 0.1
            },
            "numerators": {
               "this": 723895,
               "CBSA": 2.9507464E7,
               "state": 5.3813811E8
            },
            "numerator_errors": {
               "this": 75296,
               "CBSA": 532392,
               "state": 2278526
            },
            "metadata": {
               "table_id": "B08006, B08013",
               "universe": "Workers 16 years and over who did not work at home",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 86,
               "state": 85
            },
            "error_ratio": {
               "this": 6.7,
               "CBSA": 1.4,
               "state": 0.3
            }
         },
         "transportation_distribution": {
            "metadata": {
               "table_id": "B08006",
               "universe": "Workers 16 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "drove_alone": {
               "name": "Drove alone",
               "values": {
                  "this": 61.2,
                  "CBSA": 74.68,
                  "state": 73.51
               },
               "error": {
                  "this": 4.85,
                  "CBSA": 1.01,
                  "state": 0.23
               },
               "numerators": {
                  "this": 18787,
                  "CBSA": 764930,
                  "state": 1.3767903E7
               },
               "numerator_errors": {
                  "this": 1948,
                  "CBSA": 12803,
                  "state": 52769
               },
               "index": {
                  "this": 100,
                  "CBSA": 82,
                  "state": 83
               },
               "error_ratio": {
                  "this": 7.9,
                  "CBSA": 1.4,
                  "state": 0.3
               }
            },
            "carpooled": {
               "name": "Carpooled",
               "values": {
                  "this": 5.18,
                  "CBSA": 10.52,
                  "state": 9.83
               },
               "error": {
                  "this": 2.68,
                  "CBSA": 0.63,
                  "state": 0.15
               },
               "numerators": {
                  "this": 1589,
                  "CBSA": 107743,
                  "state": 1841273
               },
               "numerator_errors": {
                  "this": 831,
                  "CBSA": 6587,
                  "state": 29274
               },
               "index": {
                  "this": 100,
                  "CBSA": 49,
                  "state": 53
               },
               "error_ratio": {
                  "this": 51.7,
                  "CBSA": 6,
                  "state": 1.5
               }
            },
            "public_transit": {
               "name": "Public transit",
               "values": {
                  "this": 7.96,
                  "CBSA": 4.66,
                  "state": 5.18
               },
               "error": {
                  "this": 2.34,
                  "CBSA": 0.38,
                  "state": 0.08
               },
               "numerators": {
                  "this": 2445,
                  "CBSA": 47745,
                  "state": 970901
               },
               "numerator_errors": {
                  "this": 736,
                  "CBSA": 3896,
                  "state": 15634
               },
               "index": {
                  "this": 100,
                  "CBSA": 171,
                  "state": 154
               },
               "error_ratio": {
                  "this": 29.4,
                  "CBSA": 8.2,
                  "state": 1.5
               }
            },
            "Bicycle": {
               "name": "Bicycle",
               "values": {
                  "this": 10.03,
                  "CBSA": 1.69,
                  "state": 0.86
               },
               "error": {
                  "this": 2.86,
                  "CBSA": 0.23,
                  "state": 0.04
               },
               "numerators": {
                  "this": 3079,
                  "CBSA": 17336,
                  "state": 160661
               },
               "numerator_errors": {
                  "this": 901,
                  "CBSA": 2347,
                  "state": 7943
               },
               "index": {
                  "this": 100,
                  "CBSA": 593,
                  "state": 1166
               },
               "error_ratio": {
                  "this": 28.5,
                  "CBSA": 13.6,
                  "state": 4.7
               }
            },
            "walked": {
               "name": "Walked",
               "values": {
                  "this": 3.86,
                  "CBSA": 2.29,
                  "state": 2.56
               },
               "error": {
                  "this": 1.53,
                  "CBSA": 0.29,
                  "state": 0.08
               },
               "numerators": {
                  "this": 1184,
                  "CBSA": 23453,
                  "state": 479751
               },
               "numerator_errors": {
                  "this": 475,
                  "CBSA": 2957,
                  "state": 15458
               },
               "index": {
                  "this": 100,
                  "CBSA": 169,
                  "state": 151
               },
               "error_ratio": {
                  "this": 39.6,
                  "CBSA": 12.7,
                  "state": 3.1
               }
            },
            "other": {
               "name": "Other",
               "values": {
                  "this": 2.09,
                  "CBSA": 1.36,
                  "state": 1.72
               },
               "error": {
                  "this": 1.18,
                  "CBSA": 0.19,
                  "state": 0.06
               },
               "numerators": {
                  "this": 642,
                  "CBSA": 13892,
                  "state": 321375
               },
               "numerator_errors": {
                  "this": 366,
                  "CBSA": 1961,
                  "state": 11717
               },
               "index": {
                  "this": 100,
                  "CBSA": 154,
                  "state": 122
               },
               "error_ratio": {
                  "this": 56.5,
                  "CBSA": 14,
                  "state": 3.5
               }
            },
            "worked_at_home": {
               "name": "Worked at home",
               "values": {
                  "this": 9.68,
                  "CBSA": 4.8,
                  "state": 6.34
               },
               "error": {
                  "this": 2.53,
                  "CBSA": 0.38,
                  "state": 0.12
               },
               "numerators": {
                  "this": 2973,
                  "CBSA": 49187,
                  "state": 1188387
               },
               "numerator_errors": {
                  "this": 803,
                  "CBSA": 3909,
                  "state": 22217
               },
               "index": {
                  "this": 100,
                  "CBSA": 202,
                  "state": 153
               },
               "error_ratio": {
                  "this": 26.1,
                  "CBSA": 7.9,
                  "state": 1.9
               }
            }
         }
      }
   },
   "families": {
      "marital_status": {
         "metadata": {
            "table_id": "B12001",
            "universe": "Population 15 years and over",
            "acs_release": "ACS 2019 1-year"
         },
         "married": {
            "name": "Married",
            "values": {
               "this": 60,
               "CBSA": 53.35,
               "state": 48.44
            },
            "error": {
               "this": 2.22,
               "CBSA": 0.66,
               "state": 0.14
            },
            "numerators": {
               "this": 31480,
               "CBSA": 871204,
               "state": 1.5561514E7
            },
            "numerator_errors": {
               "this": 1508.9,
               "CBSA": 10731.2,
               "state": 46249.3
            },
            "index": {
               "this": 100,
               "CBSA": 112,
               "state": 124
            },
            "error_ratio": {
               "this": 3.7,
               "CBSA": 1.2,
               "state": 0.3
            }
         },
         "single": {
            "name": "Single",
            "values": {
               "this": 40,
               "CBSA": 46.65,
               "state": 51.56
            },
            "error": {
               "this": 4.21,
               "CBSA": 0.65,
               "state": 0.16
            },
            "numerators": {
               "this": 20987,
               "CBSA": 761698,
               "state": 1.6562598E7
            },
            "numerator_errors": {
               "this": 2298.9,
               "CBSA": 10660.5,
               "state": 51752.6
            },
            "index": {
               "this": 100,
               "CBSA": 86,
               "state": 78
            },
            "error_ratio": {
               "this": 10.5,
               "CBSA": 1.4,
               "state": 0.3
            }
         }
      },
      "marital_status_grouped": {
         "metadata": {
            "table_id": "B12001",
            "universe": "Population 15 years and over",
            "acs_release": "ACS 2019 1-year"
         },
         "never_married": {
            "acs_release": "ACS 2019 1-year",
            "metadata": {
               "universe": "Population 15 years and over",
               "table_id": "B12001",
               "name": "Never married"
            },
            "male": {
               "name": "Male",
               "values": {
                  "this": 27.61,
                  "CBSA": 38.63,
                  "state": 40.83
               },
               "error": {
                  "this": 4.69,
                  "CBSA": 0.73,
                  "state": 0.17
               },
               "numerators": {
                  "this": 6661,
                  "CBSA": 318683,
                  "state": 6478460
               },
               "numerator_errors": {
                  "this": 1194,
                  "CBSA": 5996,
                  "state": 27866
               },
               "index": {
                  "this": 100,
                  "CBSA": 71,
                  "state": 68
               },
               "error_ratio": {
                  "this": 17,
                  "CBSA": 1.9,
                  "state": 0.4
               }
            },
            "female": {
               "name": "Female",
               "values": {
                  "this": 27.41,
                  "CBSA": 31.2,
                  "state": 33.96
               },
               "error": {
                  "this": 5.08,
                  "CBSA": 0.68,
                  "state": 0.15
               },
               "numerators": {
                  "this": 7770,
                  "CBSA": 252117,
                  "state": 5521285
               },
               "numerator_errors": {
                  "this": 1498,
                  "CBSA": 5538,
                  "state": 24532
               },
               "index": {
                  "this": 100,
                  "CBSA": 88,
                  "state": 81
               },
               "error_ratio": {
                  "this": 18.5,
                  "CBSA": 2.2,
                  "state": 0.4
               }
            }
         },
         "married": {
            "acs_release": "ACS 2019 1-year",
            "metadata": {
               "universe": "Population 15 years and over",
               "table_id": "B12001",
               "name": "Now married"
            },
            "male": {
               "name": "Male",
               "values": {
                  "this": 65.91,
                  "CBSA": 53.67,
                  "state": 49.37
               },
               "error": {
                  "this": 2.07,
                  "CBSA": 0.91,
                  "state": 0.2
               },
               "numerators": {
                  "this": 15897,
                  "CBSA": 442711,
                  "state": 7832740
               },
               "numerator_errors": {
                  "this": 1035,
                  "CBSA": 7542,
                  "state": 32087
               },
               "index": {
                  "this": 100,
                  "CBSA": 123,
                  "state": 134
               },
               "error_ratio": {
                  "this": 3.1,
                  "CBSA": 1.7,
                  "state": 0.4
               }
            },
            "female": {
               "name": "Female",
               "values": {
                  "this": 54.97,
                  "CBSA": 53.03,
                  "state": 47.54
               },
               "error": {
                  "this": 2.54,
                  "CBSA": 0.94,
                  "state": 0.2
               },
               "numerators": {
                  "this": 15583,
                  "CBSA": 428493,
                  "state": 7728774
               },
               "numerator_errors": {
                  "this": 1098,
                  "CBSA": 7634,
                  "state": 33308
               },
               "index": {
                  "this": 100,
                  "CBSA": 104,
                  "state": 116
               },
               "error_ratio": {
                  "this": 4.6,
                  "CBSA": 1.8,
                  "state": 0.4
               }
            }
         },
         "divorced": {
            "acs_release": "ACS 2019 1-year",
            "metadata": {
               "universe": "Population 15 years and over",
               "table_id": "B12001",
               "name": "Divorced"
            },
            "male": {
               "name": "Male",
               "values": {
                  "this": 3.88,
                  "CBSA": 5.98,
                  "state": 7.69
               },
               "error": {
                  "this": 2.19,
                  "CBSA": 0.44,
                  "state": 0.11
               },
               "numerators": {
                  "this": 937,
                  "CBSA": 49331,
                  "state": 1219434
               },
               "numerator_errors": {
                  "this": 530,
                  "CBSA": 3616,
                  "state": 17626
               },
               "index": {
                  "this": 100,
                  "CBSA": 65,
                  "state": 50
               },
               "error_ratio": {
                  "this": 56.4,
                  "CBSA": 7.4,
                  "state": 1.4
               }
            },
            "female": {
               "name": "Female",
               "values": {
                  "this": 9.2,
                  "CBSA": 9.09,
                  "state": 10.83
               },
               "error": {
                  "this": 2.61,
                  "CBSA": 0.52,
                  "state": 0.16
               },
               "numerators": {
                  "this": 2608,
                  "CBSA": 73422,
                  "state": 1760791
               },
               "numerator_errors": {
                  "this": 754,
                  "CBSA": 4173,
                  "state": 25259
               },
               "index": {
                  "this": 100,
                  "CBSA": 101,
                  "state": 85
               },
               "error_ratio": {
                  "this": 28.4,
                  "CBSA": 5.7,
                  "state": 1.5
               }
            }
         },
         "widowed": {
            "acs_release": "ACS 2019 1-year",
            "metadata": {
               "universe": "Population 15 years and over",
               "table_id": "B12001",
               "name": "Widowed"
            },
            "male": {
               "name": "Male",
               "values": {
                  "this": 2.6,
                  "CBSA": 1.72,
                  "state": 2.11
               },
               "error": {
                  "this": 1.31,
                  "CBSA": 0.23,
                  "state": 0.05
               },
               "numerators": {
                  "this": 626,
                  "CBSA": 14188,
                  "state": 334453
               },
               "numerator_errors": {
                  "this": 319,
                  "CBSA": 1932,
                  "state": 8393
               },
               "index": {
                  "this": 100,
                  "CBSA": 151,
                  "state": 123
               },
               "error_ratio": {
                  "this": 50.4,
                  "CBSA": 13.4,
                  "state": 2.4
               }
            },
            "female": {
               "name": "Female",
               "values": {
                  "this": 8.41,
                  "CBSA": 6.68,
                  "state": 7.68
               },
               "error": {
                  "this": 2.84,
                  "CBSA": 0.44,
                  "state": 0.1
               },
               "numerators": {
                  "this": 2385,
                  "CBSA": 53957,
                  "state": 1248175
               },
               "numerator_errors": {
                  "this": 815,
                  "CBSA": 3578,
                  "state": 16759
               },
               "index": {
                  "this": 100,
                  "CBSA": 126,
                  "state": 110
               },
               "error_ratio": {
                  "this": 33.8,
                  "CBSA": 6.6,
                  "state": 1.3
               }
            }
         }
      },
      "family_types": {
         "children": {
            "metadata": {
               "table_id": "B09002",
               "universe": "Own children under 18 years",
               "acs_release": "ACS 2019 5-year"
            },
            "married_couple": {
               "name": "Married couple",
               "values": {
                  "this": 84.69,
                  "CBSA": 81.4,
                  "state": 72.23
               },
               "error": {
                  "this": 1.98,
                  "CBSA": 0.63,
                  "state": 0.27
               },
               "numerators": {
                  "this": 12618,
                  "CBSA": 319520,
                  "state": 5567122
               },
               "numerator_errors": {
                  "this": 592,
                  "CBSA": 2844,
                  "state": 22183
               },
               "index": {
                  "this": 100,
                  "CBSA": 104,
                  "state": 117
               },
               "error_ratio": {
                  "this": 2.3,
                  "CBSA": 0.8,
                  "state": 0.4
               }
            },
            "male_householder": {
               "name": "Male householder",
               "values": {
                  "this": 5.21,
                  "CBSA": 5.63,
                  "state": 7.67
               },
               "error": {
                  "this": 1.71,
                  "CBSA": 0.34,
                  "state": 0.11
               },
               "numerators": {
                  "this": 776,
                  "CBSA": 22081,
                  "state": 591232
               },
               "numerator_errors": {
                  "this": 256,
                  "CBSA": 1348,
                  "state": 8769
               },
               "index": {
                  "this": 100,
                  "CBSA": 93,
                  "state": 68
               },
               "error_ratio": {
                  "this": 32.8,
                  "CBSA": 6,
                  "state": 1.4
               }
            },
            "female_householder": {
               "name": "Female householder",
               "values": {
                  "this": 10.1,
                  "CBSA": 12.98,
                  "state": 20.1
               },
               "error": {
                  "this": 2.13,
                  "CBSA": 0.54,
                  "state": 0.21
               },
               "numerators": {
                  "this": 1505,
                  "CBSA": 50947,
                  "state": 1549424
               },
               "numerator_errors": {
                  "this": 323,
                  "CBSA": 2129,
                  "state": 16177
               },
               "index": {
                  "this": 100,
                  "CBSA": 78,
                  "state": 50
               },
               "error_ratio": {
                  "this": 21.1,
                  "CBSA": 4.2,
                  "state": 1
               }
            }
         }
      },
      "fertility": {
         "total": {
            "name": "Women 15-50 who gave birth during past year",
            "values": {
               "this": 4.57,
               "CBSA": 4.43,
               "state": 4.69
            },
            "error": {
               "this": 2.41,
               "CBSA": 0.46,
               "state": 0.11
            },
            "numerators": {
               "this": 679,
               "CBSA": 21601,
               "state": 450971
            },
            "numerator_errors": {
               "this": 364,
               "CBSA": 2240,
               "state": 10955
            },
            "metadata": {
               "table_id": "B13016",
               "universe": "Women 15 to 50 years",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 103,
               "state": 97
            },
            "error_ratio": {
               "this": 52.7,
               "CBSA": 10.4,
               "state": 2.3
            }
         },
         "by_age": {
            "metadata": {
               "table_id": "B13016",
               "universe": "Women 15 to 50 years",
               "acs_release": "ACS 2019 1-year"
            },
            "15_to_19": {
               "name": "15-19",
               "values": {
                  "this": 0,
                  "CBSA": 0.08,
                  "state": 0.87
               },
               "error": {
                  "this": 0,
                  "CBSA": 0.11,
                  "state": 0.16
               },
               "numerators": {
                  "this": 0,
                  "CBSA": 45,
                  "state": 10882
               },
               "numerator_errors": {
                  "this": 216,
                  "CBSA": 67,
                  "state": 2046
               },
               "index": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "error_ratio": {
                  "this": null,
                  "CBSA": 137.5,
                  "state": 18.4
               }
            },
            "20_to_24": {
               "name": "20-24",
               "values": {
                  "this": 0,
                  "CBSA": 2.02,
                  "state": 4.44
               },
               "error": {
                  "this": 0,
                  "CBSA": 1.31,
                  "state": 0.41
               },
               "numerators": {
                  "this": 0,
                  "CBSA": 1156,
                  "state": 56709
               },
               "numerator_errors": {
                  "this": 216,
                  "CBSA": 753,
                  "state": 5217
               },
               "index": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "error_ratio": {
                  "this": null,
                  "CBSA": 64.9,
                  "state": 9.2
               }
            },
            "25_to_29": {
               "name": "25-29",
               "values": {
                  "this": 0.35,
                  "CBSA": 4.43,
                  "state": 6.85
               },
               "error": {
                  "this": 0.87,
                  "CBSA": 1.31,
                  "state": 0.37
               },
               "numerators": {
                  "this": 6,
                  "CBSA": 3265,
                  "state": 101991
               },
               "numerator_errors": {
                  "this": 15,
                  "CBSA": 965,
                  "state": 5591
               },
               "index": {
                  "this": 100,
                  "CBSA": 8,
                  "state": 5
               },
               "error_ratio": {
                  "this": 248.6,
                  "CBSA": 29.6,
                  "state": 5.4
               }
            },
            "30_to_34": {
               "name": "30-35",
               "values": {
                  "this": 12.28,
                  "CBSA": 11.69,
                  "state": 9.73
               },
               "error": {
                  "this": 9.61,
                  "CBSA": 1.9,
                  "state": 0.51
               },
               "numerators": {
                  "this": 269,
                  "CBSA": 8763,
                  "state": 139246
               },
               "numerator_errors": {
                  "this": 232,
                  "CBSA": 1442,
                  "state": 7329
               },
               "index": {
                  "this": 100,
                  "CBSA": 105,
                  "state": 126
               },
               "error_ratio": {
                  "this": 78.3,
                  "CBSA": 16.3,
                  "state": 5.2
               }
            },
            "35_to_39": {
               "name": "35-39",
               "values": {
                  "this": 12.09,
                  "CBSA": 8.49,
                  "state": 6.95
               },
               "error": {
                  "this": 8.51,
                  "CBSA": 1.33,
                  "state": 0.33
               },
               "numerators": {
                  "this": 211,
                  "CBSA": 6052,
                  "state": 93949
               },
               "numerator_errors": {
                  "this": 164,
                  "CBSA": 976,
                  "state": 4611
               },
               "index": {
                  "this": 100,
                  "CBSA": 142,
                  "state": 174
               },
               "error_ratio": {
                  "this": 70.4,
                  "CBSA": 15.7,
                  "state": 4.7
               }
            },
            "40_to_44": {
               "name": "40-44",
               "values": {
                  "this": 7.87,
                  "CBSA": 2.09,
                  "state": 2.64
               },
               "error": {
                  "this": 6.3,
                  "CBSA": 0.9,
                  "state": 0.21
               },
               "numerators": {
                  "this": 167,
                  "CBSA": 1431,
                  "state": 33377
               },
               "numerator_errors": {
                  "this": 142,
                  "CBSA": 618,
                  "state": 2743
               },
               "index": {
                  "this": 100,
                  "CBSA": 377,
                  "state": 298
               },
               "error_ratio": {
                  "this": 80.1,
                  "CBSA": 43.1,
                  "state": 8
               }
            },
            "45_to_50": {
               "name": "45-50",
               "values": {
                  "this": 0.83,
                  "CBSA": 1.08,
                  "state": 0.96
               },
               "error": {
                  "this": 1.36,
                  "CBSA": 0.44,
                  "state": 0.14
               },
               "numerators": {
                  "this": 26,
                  "CBSA": 889,
                  "state": 14817
               },
               "numerator_errors": {
                  "this": 43,
                  "CBSA": 364,
                  "state": 2211
               },
               "index": {
                  "this": 100,
                  "CBSA": 77,
                  "state": 86
               },
               "error_ratio": {
                  "this": 163.9,
                  "CBSA": 40.7,
                  "state": 14.6
               }
            }
         }
      },
      "households": {
         "number_of_households": {
            "name": "Number of households",
            "values": {
               "this": 25168,
               "CBSA": 662842,
               "state": 1.3157873E7
            },
            "error": {
               "this": 1304,
               "CBSA": 4591,
               "state": 23844
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "metadata": {
               "table_id": "B11001",
               "universe": "Households",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 4,
               "state": null
            },
            "error_ratio": {
               "this": 5.2,
               "CBSA": 0.7,
               "state": 0.2
            }
         },
         "persons_per_household": {
            "name": "Persons per household",
            "values": {
               "this": 2.57,
               "CBSA": 2.95,
               "state": 2.94
            },
            "error": {
               "this": 0.13,
               "CBSA": 0.02,
               "state": 0.01
            },
            "numerators": {
               "this": 64775,
               "CBSA": 1953094,
               "state": 3.8685704E7
            },
            "numerator_errors": {
               "this": 256,
               "CBSA": 2672,
               "state": 0
            },
            "metadata": {
               "table_id": "B11001,b11002",
               "universe": "Households",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 87,
               "state": 87
            },
            "error_ratio": {
               "this": 5.1,
               "CBSA": 0.7,
               "state": 0.3
            }
         },
         "distribution": {
            "metadata": {
               "table_id": "B11002",
               "universe": "People in Households",
               "acs_release": "ACS 2019 1-year"
            },
            "married_couples": {
               "name": "Married couples",
               "values": {
                  "this": 71.53,
                  "CBSA": 65.4,
                  "state": 60.69
               },
               "error": {
                  "this": 4.12,
                  "CBSA": 1.15,
                  "state": 0.22
               },
               "numerators": {
                  "this": 46333,
                  "CBSA": 1277354,
                  "state": 2.3477092E7
               },
               "numerator_errors": {
                  "this": 2675,
                  "CBSA": 22550,
                  "state": 84642
               },
               "index": {
                  "this": 100,
                  "CBSA": 109,
                  "state": 118
               },
               "error_ratio": {
                  "this": 5.8,
                  "CBSA": 1.8,
                  "state": 0.4
               }
            },
            "male_householder": {
               "name": "Male householder",
               "values": {
                  "this": 4.1,
                  "CBSA": 6.79,
                  "state": 7.74
               },
               "error": {
                  "this": 3.02,
                  "CBSA": 0.64,
                  "state": 0.16
               },
               "numerators": {
                  "this": 2658,
                  "CBSA": 132540,
                  "state": 2996042
               },
               "numerator_errors": {
                  "this": 1955,
                  "CBSA": 12462,
                  "state": 63406
               },
               "index": {
                  "this": 100,
                  "CBSA": 60,
                  "state": 53
               },
               "error_ratio": {
                  "this": 73.7,
                  "CBSA": 9.4,
                  "state": 2.1
               }
            },
            "female_householder": {
               "name": "Female householder",
               "values": {
                  "this": 8.32,
                  "CBSA": 12.85,
                  "state": 16.47
               },
               "error": {
                  "this": 2.79,
                  "CBSA": 0.91,
                  "state": 0.23
               },
               "numerators": {
                  "this": 5389,
                  "CBSA": 251009,
                  "state": 6371949
               },
               "numerator_errors": {
                  "this": 1808,
                  "CBSA": 17743,
                  "state": 87738
               },
               "index": {
                  "this": 100,
                  "CBSA": 65,
                  "state": 51
               },
               "error_ratio": {
                  "this": 33.5,
                  "CBSA": 7.1,
                  "state": 1.4
               }
            },
            "nonfamily": {
               "name": "Non-family",
               "values": {
                  "this": 16.05,
                  "CBSA": 14.96,
                  "state": 15.1
               },
               "error": {
                  "this": 2.57,
                  "CBSA": 0.63,
                  "state": 0.1
               },
               "numerators": {
                  "this": 10395,
                  "CBSA": 292191,
                  "state": 5840619
               },
               "numerator_errors": {
                  "this": 1664,
                  "CBSA": 12288,
                  "state": 37146
               },
               "index": {
                  "this": 100,
                  "CBSA": 107,
                  "state": 106
               },
               "error_ratio": {
                  "this": 16,
                  "CBSA": 4.2,
                  "state": 0.7
               }
            }
         }
      }
   },
   "housing": {
      "units": {
         "number": {
            "name": "Number of housing units",
            "values": {
               "this": 28541,
               "CBSA": 706285,
               "state": 1.4367012E7
            },
            "error": {
               "this": 1792,
               "CBSA": 923,
               "state": 1286
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "metadata": {
               "table_id": "B25002",
               "universe": "Housing units",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 4,
               "state": null
            },
            "error_ratio": {
               "this": 6.3,
               "CBSA": 0.1,
               "state": null
            }
         },
         "occupancy_distribution": {
            "metadata": {
               "table_id": "B25002",
               "universe": "Housing units",
               "acs_release": "ACS 2019 1-year"
            },
            "occupied": {
               "name": "Occupied",
               "values": {
                  "this": 88.18,
                  "CBSA": 93.85,
                  "state": 91.58
               },
               "error": {
                  "this": 7.18,
                  "CBSA": 0.64,
                  "state": 0.17
               },
               "numerators": {
                  "this": 25168,
                  "CBSA": 662842,
                  "state": 1.3157873E7
               },
               "numerator_errors": {
                  "this": 1304,
                  "CBSA": 4591,
                  "state": 23844
               },
               "index": {
                  "this": 100,
                  "CBSA": 94,
                  "state": 96
               },
               "error_ratio": {
                  "this": 8.1,
                  "CBSA": 0.7,
                  "state": 0.2
               }
            },
            "vacant": {
               "name": "Vacant",
               "values": {
                  "this": 11.82,
                  "CBSA": 6.15,
                  "state": 8.42
               },
               "error": {
                  "this": 3.94,
                  "CBSA": 0.62,
                  "state": 0.17
               },
               "numerators": {
                  "this": 3373,
                  "CBSA": 43443,
                  "state": 1209139
               },
               "numerator_errors": {
                  "this": 1144,
                  "CBSA": 4388,
                  "state": 24297
               },
               "index": {
                  "this": 100,
                  "CBSA": 192,
                  "state": 140
               },
               "error_ratio": {
                  "this": 33.3,
                  "CBSA": 10.1,
                  "state": 2
               }
            }
         },
         "structure_distribution": {
            "metadata": {
               "table_id": "B25024",
               "universe": "Housing units",
               "acs_release": "ACS 2019 1-year"
            },
            "single_unit": {
               "name": "Single unit",
               "values": {
                  "this": 56.44,
                  "CBSA": 61.51,
                  "state": 63.96
               },
               "error": {
                  "this": 3.75,
                  "CBSA": 0.98,
                  "state": 0.22
               },
               "numerators": {
                  "this": 16108,
                  "CBSA": 434409,
                  "state": 9189272
               },
               "numerator_errors": {
                  "this": 1473.3,
                  "CBSA": 6930.2,
                  "state": 31423.3
               },
               "index": {
                  "this": 100,
                  "CBSA": 92,
                  "state": 88
               },
               "error_ratio": {
                  "this": 6.6,
                  "CBSA": 1.6,
                  "state": 0.3
               }
            },
            "multi_unit": {
               "name": "Multi-unit",
               "values": {
                  "this": 42.65,
                  "CBSA": 35.54,
                  "state": 32.18
               },
               "error": {
                  "this": 5.8,
                  "CBSA": 1.19,
                  "state": 0.27
               },
               "numerators": {
                  "this": 12173,
                  "CBSA": 251035,
                  "state": 4622614
               },
               "numerator_errors": {
                  "this": 1823.8,
                  "CBSA": 8419.5,
                  "state": 38673.9
               },
               "index": {
                  "this": 100,
                  "CBSA": 120,
                  "state": 133
               },
               "error_ratio": {
                  "this": 13.6,
                  "CBSA": 3.3,
                  "state": 0.8
               }
            },
            "mobile_home": {
               "name": "Mobile home",
               "values": {
                  "this": 0.91,
                  "CBSA": 2.89,
                  "state": 3.74
               },
               "error": {
                  "this": 1.2,
                  "CBSA": 0.25,
                  "state": 0.08
               },
               "numerators": {
                  "this": 260,
                  "CBSA": 20422,
                  "state": 537931
               },
               "numerator_errors": {
                  "this": 343,
                  "CBSA": 1750,
                  "state": 11591
               },
               "index": {
                  "this": 100,
                  "CBSA": 31,
                  "state": 24
               },
               "error_ratio": {
                  "this": 131.9,
                  "CBSA": 8.7,
                  "state": 2.1
               }
            },
            "vehicle": {
               "name": "Boat, RV, van, etc.",
               "values": {
                  "this": 0,
                  "CBSA": 0.06,
                  "state": 0.12
               },
               "error": {
                  "this": 0,
                  "CBSA": 0.06,
                  "state": 0.01
               },
               "numerators": {
                  "this": 0,
                  "CBSA": 419,
                  "state": 17195
               },
               "numerator_errors": {
                  "this": 216,
                  "CBSA": 400,
                  "state": 2102
               },
               "index": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "error_ratio": {
                  "this": null,
                  "CBSA": 100,
                  "state": 8.3
               }
            }
         }
      },
      "ownership": {
         "distribution": {
            "metadata": {
               "table_id": "B25003",
               "universe": "Occupied housing units",
               "acs_release": "ACS 2019 1-year"
            },
            "owner": {
               "name": "Owner occupied",
               "values": {
                  "this": 55.43,
                  "CBSA": 55.21,
                  "state": 54.86
               },
               "error": {
                  "this": 4.32,
                  "CBSA": 0.77,
                  "state": 0.22
               },
               "numerators": {
                  "this": 13950,
                  "CBSA": 365949,
                  "state": 7218742
               },
               "numerator_errors": {
                  "this": 1305,
                  "CBSA": 5710,
                  "state": 32100
               },
               "index": {
                  "this": 100,
                  "CBSA": 100,
                  "state": 101
               },
               "error_ratio": {
                  "this": 7.8,
                  "CBSA": 1.4,
                  "state": 0.4
               }
            },
            "renter": {
               "name": "Renter occupied",
               "values": {
                  "this": 44.57,
                  "CBSA": 44.79,
                  "state": 45.14
               },
               "error": {
                  "this": 4.6,
                  "CBSA": 0.84,
                  "state": 0.24
               },
               "numerators": {
                  "this": 11218,
                  "CBSA": 296893,
                  "state": 5939131
               },
               "numerator_errors": {
                  "this": 1296,
                  "CBSA": 5964,
                  "state": 32807
               },
               "index": {
                  "this": 100,
                  "CBSA": 100,
                  "state": 99
               },
               "error_ratio": {
                  "this": 10.3,
                  "CBSA": 1.9,
                  "state": 0.5
               }
            }
         },
         "median_value": {
            "name": "Median value of owner-occupied housing units",
            "values": {
               "this": 2000001,
               "CBSA": 968800,
               "state": 505000
            },
            "error": {
               "this": 0,
               "CBSA": 5412,
               "state": 1147
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "metadata": {
               "table_id": "B25077",
               "universe": "Owner-occupied housing units",
               "acs_release": "ACS 2019 5-year"
            },
            "index": {
               "this": 100,
               "CBSA": 206,
               "state": 396
            },
            "error_ratio": {
               "this": null,
               "CBSA": 0.6,
               "state": 0.2
            }
         },
         "value_distribution": {
            "metadata": {
               "table_id": "B25075",
               "universe": "Owner-occupied housing units",
               "acs_release": "ACS 2019 5-year"
            },
            "under_100": {
               "name": "Under $100K",
               "values": {
                  "this": 1.39,
                  "CBSA": 2.37,
                  "state": 5.03
               },
               "error": {
                  "this": 0.89,
                  "CBSA": 0.15,
                  "state": 0.05
               },
               "numerators": {
                  "this": 198,
                  "CBSA": 8828,
                  "state": 359961
               },
               "numerator_errors": {
                  "this": 127.8,
                  "CBSA": 548.4,
                  "state": 3940.4
               },
               "index": {
                  "this": 100,
                  "CBSA": 59,
                  "state": 28
               },
               "error_ratio": {
                  "this": 64,
                  "CBSA": 6.3,
                  "state": 1
               }
            },
            "100_to_200": {
               "name": "$100K - $200K",
               "values": {
                  "this": 1.41,
                  "CBSA": 2.07,
                  "state": 6.63
               },
               "error": {
                  "this": 0.58,
                  "CBSA": 0.15,
                  "state": 0.06
               },
               "numerators": {
                  "this": 201,
                  "CBSA": 7732,
                  "state": 474527
               },
               "numerator_errors": {
                  "this": 83.6,
                  "CBSA": 568.6,
                  "state": 4768.2
               },
               "index": {
                  "this": 100,
                  "CBSA": 68,
                  "state": 21
               },
               "error_ratio": {
                  "this": 41.1,
                  "CBSA": 7.2,
                  "state": 0.9
               }
            },
            "200_to_300": {
               "name": "$200K - $300K",
               "values": {
                  "this": 0.9,
                  "CBSA": 1.76,
                  "state": 11.06
               },
               "error": {
                  "this": 0.46,
                  "CBSA": 0.13,
                  "state": 0.06
               },
               "numerators": {
                  "this": 128,
                  "CBSA": 6570,
                  "state": 791435
               },
               "numerator_errors": {
                  "this": 65.2,
                  "CBSA": 504.3,
                  "state": 6253.7
               },
               "index": {
                  "this": 100,
                  "CBSA": 51,
                  "state": 8
               },
               "error_ratio": {
                  "this": 51.1,
                  "CBSA": 7.4,
                  "state": 0.5
               }
            },
            "300_to_400": {
               "name": "$300K - $400K",
               "values": {
                  "this": 0.58,
                  "CBSA": 2.37,
                  "state": 13.6
               },
               "error": {
                  "this": 0.36,
                  "CBSA": 0.16,
                  "state": 0.09
               },
               "numerators": {
                  "this": 83,
                  "CBSA": 8838,
                  "state": 973196
               },
               "numerator_errors": {
                  "this": 51,
                  "CBSA": 599,
                  "state": 7992
               },
               "index": {
                  "this": 100,
                  "CBSA": 24,
                  "state": 4
               },
               "error_ratio": {
                  "this": 62.1,
                  "CBSA": 6.8,
                  "state": 0.7
               }
            },
            "400_to_500": {
               "name": "$400K - $500K",
               "values": {
                  "this": 0.06,
                  "CBSA": 3.99,
                  "state": 13.21
               },
               "error": {
                  "this": 0.1,
                  "CBSA": 0.22,
                  "state": 0.08
               },
               "numerators": {
                  "this": 9,
                  "CBSA": 14882,
                  "state": 945276
               },
               "numerator_errors": {
                  "this": 14,
                  "CBSA": 831,
                  "state": 7694
               },
               "index": {
                  "this": 100,
                  "CBSA": 2,
                  "state": null
               },
               "error_ratio": {
                  "this": 166.7,
                  "CBSA": 5.5,
                  "state": 0.6
               }
            },
            "500_to_1000000": {
               "name": "$500K - $1M",
               "values": {
                  "this": 5.23,
                  "CBSA": 40.13,
                  "state": 35.28
               },
               "error": {
                  "this": 1.25,
                  "CBSA": 0.54,
                  "state": 0.26
               },
               "numerators": {
                  "this": 747,
                  "CBSA": 149566,
                  "state": 2523951
               },
               "numerator_errors": {
                  "this": 179.7,
                  "CBSA": 2309.5,
                  "state": 12891.8
               },
               "index": {
                  "this": 100,
                  "CBSA": 13,
                  "state": 15
               },
               "error_ratio": {
                  "this": 23.9,
                  "CBSA": 1.3,
                  "state": 0.7
               }
            },
            "over_1000000": {
               "name": "Over $1M",
               "values": {
                  "this": 12.01,
                  "CBSA": 22.29,
                  "state": 7.93
               },
               "error": {
                  "this": 1.51,
                  "CBSA": 0.41,
                  "state": 0.04
               },
               "numerators": {
                  "this": 1714,
                  "CBSA": 83053,
                  "state": 567462
               },
               "numerator_errors": {
                  "this": 221,
                  "CBSA": 1652,
                  "state": 4302
               },
               "index": {
                  "this": 100,
                  "CBSA": 54,
                  "state": 151
               },
               "error_ratio": {
                  "this": 12.6,
                  "CBSA": 1.8,
                  "state": 0.5
               }
            }
         },
         "total_value": {
            "name": "Total value of owner-occupied housing units",
            "values": {
               "this": 14277,
               "CBSA": 372659,
               "state": 7154580
            },
            "error": {
               "this": 415,
               "CBSA": 2785,
               "state": 37872
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "index": {
               "this": 100,
               "CBSA": 4,
               "state": null
            },
            "error_ratio": {
               "this": 2.9,
               "CBSA": 0.7,
               "state": 0.5
            }
         }
      },
      "length_of_tenure": {
         "metadata": {
            "table_id": "B25026",
            "universe": "Total population in occupied housing units",
            "acs_release": "ACS 2019 1-year"
         },
         "Before_1990": {
            "name": "Before 1990",
            "values": {
               "this": 10.78,
               "CBSA": 9.06,
               "state": 8.86
            },
            "error": {
               "this": 2.46,
               "CBSA": 0.47,
               "state": 0.13
            },
            "numerators": {
               "this": 6985,
               "CBSA": 176854,
               "state": 3427072
            },
            "numerator_errors": {
               "this": 1593.8,
               "CBSA": 9233.8,
               "state": 50836.5
            },
            "index": {
               "this": 100,
               "CBSA": 119,
               "state": 122
            },
            "error_ratio": {
               "this": 22.8,
               "CBSA": 5.2,
               "state": 1.5
            }
         },
         "1990s": {
            "name": "1990s",
            "values": {
               "this": 10.85,
               "CBSA": 11.24,
               "state": 11.17
            },
            "error": {
               "this": 2.94,
               "CBSA": 0.63,
               "state": 0.16
            },
            "numerators": {
               "this": 7028,
               "CBSA": 219614,
               "state": 4320604
            },
            "numerator_errors": {
               "this": 1903,
               "CBSA": 12268.4,
               "state": 63360.7
            },
            "index": {
               "this": 100,
               "CBSA": 97,
               "state": 97
            },
            "error_ratio": {
               "this": 27.1,
               "CBSA": 5.6,
               "state": 1.4
            }
         },
         "2000s": {
            "name": "2000s",
            "values": {
               "this": 24.06,
               "CBSA": 23.18,
               "state": 21.9
            },
            "error": {
               "this": 4.36,
               "CBSA": 0.98,
               "state": 0.25
            },
            "numerators": {
               "this": 15585,
               "CBSA": 452689,
               "state": 8473932
            },
            "numerator_errors": {
               "this": 2826.6,
               "CBSA": 19244.3,
               "state": 98624.3
            },
            "index": {
               "this": 100,
               "CBSA": 104,
               "state": 110
            },
            "error_ratio": {
               "this": 18.1,
               "CBSA": 4.2,
               "state": 1.1
            }
         },
         "2010_to_2014": {
            "name": "2010-2014",
            "values": {
               "this": 13.53,
               "CBSA": 19.06,
               "state": 20.05
            },
            "error": {
               "this": 3.5,
               "CBSA": 0.99,
               "state": 0.24
            },
            "numerators": {
               "this": 8765,
               "CBSA": 372274,
               "state": 7754580
            },
            "numerator_errors": {
               "this": 2269.4,
               "CBSA": 19357.5,
               "state": 91602.6
            },
            "index": {
               "this": 100,
               "CBSA": 71,
               "state": 67
            },
            "error_ratio": {
               "this": 25.9,
               "CBSA": 5.2,
               "state": 1.2
            }
         },
         "2015_to_2016": {
            "name": "2015-2016",
            "values": {
               "this": 7.24,
               "CBSA": 11.32,
               "state": 13.11
            },
            "error": {
               "this": 2.78,
               "CBSA": 0.76,
               "state": 0.21
            },
            "numerators": {
               "this": 4688,
               "CBSA": 221176,
               "state": 5072685
            },
            "numerator_errors": {
               "this": 1803,
               "CBSA": 14873.9,
               "state": 79541.6
            },
            "index": {
               "this": 100,
               "CBSA": 64,
               "state": 55
            },
            "error_ratio": {
               "this": 38.4,
               "CBSA": 6.7,
               "state": 1.6
            }
         },
         "since_2017": {
            "name": "Since 2017",
            "values": {
               "this": 33.54,
               "CBSA": 26.14,
               "state": 24.91
            },
            "error": {
               "this": 5.87,
               "CBSA": 1.01,
               "state": 0.26
            },
            "numerators": {
               "this": 21724,
               "CBSA": 510487,
               "state": 9636829
            },
            "numerator_errors": {
               "this": 3804.1,
               "CBSA": 19807.6,
               "state": 101788.8
            },
            "index": {
               "this": 100,
               "CBSA": 128,
               "state": 135
            },
            "error_ratio": {
               "this": 17.5,
               "CBSA": 3.9,
               "state": 1
            }
         }
      },
      "migration": {
         "moved_since_previous_year": {
            "name": "Moved since previous year",
            "values": {
               "this": 16.25,
               "CBSA": 13.82,
               "state": 12
            },
            "error": {
               "this": 3.43,
               "CBSA": 0.67,
               "state": 0.16
            },
            "numerators": {
               "this": 10414,
               "CBSA": 272064,
               "state": 4689828
            },
            "numerator_errors": {
               "this": 2204.7,
               "CBSA": 13126.5,
               "state": 63097.7
            },
            "metadata": {
               "table_id": "B07003",
               "universe": "Population 1 year and over in the United States",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 118,
               "state": 135
            },
            "error_ratio": {
               "this": 21.1,
               "CBSA": 4.8,
               "state": 1.3
            }
         }
      },
      "migration_distribution": {
         "metadata": {
            "table_id": "B07003",
            "universe": "Population 1 year and over in the United States",
            "acs_release": "ACS 2019 1-year"
         },
         "same_house_year_ago": {
            "name": "Same house year ago",
            "values": {
               "this": 83.75,
               "CBSA": 86.18,
               "state": 88
            },
            "error": {
               "this": 3.2,
               "CBSA": 0.64,
               "state": 0.17
            },
            "numerators": {
               "this": 53682,
               "CBSA": 1696672,
               "state": 3.439422E7
            },
            "numerator_errors": {
               "this": 2141,
               "CBSA": 12845,
               "state": 68574
            },
            "index": {
               "this": 100,
               "CBSA": 97,
               "state": 95
            },
            "error_ratio": {
               "this": 3.8,
               "CBSA": 0.7,
               "state": 0.2
            }
         },
         "moved_same_county": {
            "name": "From same county",
            "values": {
               "this": 8.23,
               "CBSA": 8.29,
               "state": 7.37
            },
            "error": {
               "this": 2.32,
               "CBSA": 0.55,
               "state": 0.13
            },
            "numerators": {
               "this": 5278,
               "CBSA": 163270,
               "state": 2881883
            },
            "numerator_errors": {
               "this": 1486,
               "CBSA": 10772,
               "state": 52031
            },
            "index": {
               "this": 100,
               "CBSA": 99,
               "state": 112
            },
            "error_ratio": {
               "this": 28.2,
               "CBSA": 6.6,
               "state": 1.8
            }
         },
         "moved_different_county": {
            "name": "From different county",
            "values": {
               "this": 3.27,
               "CBSA": 2.44,
               "state": 2.72
            },
            "error": {
               "this": 1.46,
               "CBSA": 0.23,
               "state": 0.07
            },
            "numerators": {
               "this": 2093,
               "CBSA": 48036,
               "state": 1061256
            },
            "numerator_errors": {
               "this": 938,
               "CBSA": 4546,
               "state": 27761
            },
            "index": {
               "this": 100,
               "CBSA": 134,
               "state": 120
            },
            "error_ratio": {
               "this": 44.6,
               "CBSA": 9.4,
               "state": 2.6
            }
         },
         "moved_different_state": {
            "name": "From different state",
            "values": {
               "this": 1.45,
               "CBSA": 1.67,
               "state": 1.23
            },
            "error": {
               "this": 0.91,
               "CBSA": 0.24,
               "state": 0.05
            },
            "numerators": {
               "this": 929,
               "CBSA": 32975,
               "state": 480204
            },
            "numerator_errors": {
               "this": 581,
               "CBSA": 4629,
               "state": 18764
            },
            "index": {
               "this": 100,
               "CBSA": 87,
               "state": 118
            },
            "error_ratio": {
               "this": 62.8,
               "CBSA": 14.4,
               "state": 4.1
            }
         },
         "moved_from_abroad": {
            "name": "From abroad",
            "values": {
               "this": 3.3,
               "CBSA": 1.41,
               "state": 0.68
            },
            "error": {
               "this": 1.87,
               "CBSA": 0.19,
               "state": 0.03
            },
            "numerators": {
               "this": 2114,
               "CBSA": 27783,
               "state": 266485
            },
            "numerator_errors": {
               "this": 1198,
               "CBSA": 3765,
               "state": 12302
            },
            "index": {
               "this": 100,
               "CBSA": 234,
               "state": 485
            },
            "error_ratio": {
               "this": 56.7,
               "CBSA": 13.5,
               "state": 4.4
            }
         }
      }
   },
   "social": {
      "educational_attainment": {
         "percent_high_school_grad_or_higher": {
            "name": "High school grad or higher",
            "values": {
               "this": 96.99,
               "CBSA": 88.65,
               "state": 84.03
            },
            "error": {
               "this": 4.79,
               "CBSA": 1.3,
               "state": 0.29
            },
            "numerators": {
               "this": 44367,
               "CBSA": 1234572,
               "state": 2.263636E7
            },
            "numerator_errors": {
               "this": 2936.8,
               "CBSA": 18089.6,
               "state": 78234.5
            },
            "metadata": {
               "table_id": "B15002",
               "universe": "Population 25 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 109,
               "state": 115
            },
            "error_ratio": {
               "this": 4.9,
               "CBSA": 1.5,
               "state": 0.3
            }
         },
         "percent_bachelor_degree_or_higher": {
            "name": "Bachelor's degree or higher",
            "values": {
               "this": 84.63,
               "CBSA": 52.66,
               "state": 35
            },
            "error": {
               "this": 4.62,
               "CBSA": 0.92,
               "state": 0.17
            },
            "numerators": {
               "this": 38713,
               "CBSA": 733354,
               "state": 9428484
            },
            "numerator_errors": {
               "this": 2715.6,
               "CBSA": 12834.6,
               "state": 44753.9
            },
            "metadata": {
               "table_id": "B15002",
               "universe": "Population 25 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 161,
               "state": 242
            },
            "error_ratio": {
               "this": 5.5,
               "CBSA": 1.7,
               "state": 0.5
            }
         }
      },
      "educational_attainment_distribution": {
         "metadata": {
            "table_id": "B15002",
            "universe": "Population 25 years and over",
            "acs_release": "ACS 2019 1-year"
         },
         "non_high_school_grad": {
            "name": "No degree",
            "values": {
               "this": 3.01,
               "CBSA": 11.35,
               "state": 15.97
            },
            "error": {
               "this": 1.29,
               "CBSA": 0.52,
               "state": 0.14
            },
            "numerators": {
               "this": 1375,
               "CBSA": 158083,
               "state": 4301513
            },
            "numerator_errors": {
               "this": 595.1,
               "CBSA": 7253,
               "state": 37669.1
            },
            "index": {
               "this": 100,
               "CBSA": 27,
               "state": 19
            },
            "error_ratio": {
               "this": 42.9,
               "CBSA": 4.6,
               "state": 0.9
            }
         },
         "high_school_grad": {
            "name": "High school",
            "values": {
               "this": 3.05,
               "CBSA": 14.07,
               "state": 20.59
            },
            "error": {
               "this": 1.44,
               "CBSA": 0.6,
               "state": 0.16
            },
            "numerators": {
               "this": 1396,
               "CBSA": 195956,
               "state": 5546711
            },
            "numerator_errors": {
               "this": 662.8,
               "CBSA": 8397.9,
               "state": 42454
            },
            "index": {
               "this": 100,
               "CBSA": 22,
               "state": 15
            },
            "error_ratio": {
               "this": 47.2,
               "CBSA": 4.3,
               "state": 0.8
            }
         },
         "some_college": {
            "name": "Some college",
            "values": {
               "this": 9.31,
               "CBSA": 21.92,
               "state": 28.44
            },
            "error": {
               "this": 1.93,
               "CBSA": 0.69,
               "state": 0.18
            },
            "numerators": {
               "this": 4258,
               "CBSA": 305262,
               "state": 7661164
            },
            "numerator_errors": {
               "this": 900.5,
               "CBSA": 9590.8,
               "state": 48118.5
            },
            "index": {
               "this": 100,
               "CBSA": 42,
               "state": 33
            },
            "error_ratio": {
               "this": 20.7,
               "CBSA": 3.1,
               "state": 0.6
            }
         },
         "Bachelor_degree": {
            "name": "Bachelor's",
            "values": {
               "this": 30.86,
               "CBSA": 27.6,
               "state": 21.86
            },
            "error": {
               "this": 3.69,
               "CBSA": 0.7,
               "state": 0.13
            },
            "numerators": {
               "this": 14118,
               "CBSA": 384399,
               "state": 5889724
            },
            "numerator_errors": {
               "this": 1800.9,
               "CBSA": 9703.6,
               "state": 34784.9
            },
            "index": {
               "this": 100,
               "CBSA": 112,
               "state": 141
            },
            "error_ratio": {
               "this": 12,
               "CBSA": 2.5,
               "state": 0.6
            }
         },
         "post_grad_degree": {
            "name": "Post-grad",
            "values": {
               "this": 53.77,
               "CBSA": 25.06,
               "state": 13.14
            },
            "error": {
               "this": 3.76,
               "CBSA": 0.6,
               "state": 0.1
            },
            "numerators": {
               "this": 24595,
               "CBSA": 348955,
               "state": 3538760
            },
            "numerator_errors": {
               "this": 2032.6,
               "CBSA": 8400.4,
               "state": 28159
            },
            "index": {
               "this": 100,
               "CBSA": 215,
               "state": 409
            },
            "error_ratio": {
               "this": 7,
               "CBSA": 2.4,
               "state": 0.8
            }
         }
      },
      "place_of_birth": {
         "percent_foreign_born": {
            "name": "Foreign-born population",
            "values": {
               "this": 37.92,
               "CBSA": 39.69,
               "state": 26.74
            },
            "error": {
               "this": 4.42,
               "CBSA": 0.69,
               "state": 0.14
            },
            "numerators": {
               "this": 24789,
               "CBSA": 790026,
               "state": 1.056422E7
            },
            "numerator_errors": {
               "this": 2886,
               "CBSA": 13650,
               "state": 55357
            },
            "metadata": {
               "table_id": "B05002",
               "universe": "Total population",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 96,
               "state": 142
            },
            "error_ratio": {
               "this": 11.7,
               "CBSA": 1.7,
               "state": 0.5
            }
         },
         "distribution": {
            "metadata": {
               "table_id": "B05006",
               "universe": "Foreign-born population",
               "acs_release": "ACS 2019 5-year"
            },
            "europe": {
               "name": "Europe",
               "values": {
                  "this": 23.53,
                  "CBSA": 7.91,
                  "state": 6.42
               },
               "error": {
                  "this": 2.62,
                  "CBSA": 0.3,
                  "state": 0.07
               },
               "numerators": {
                  "this": 5525,
                  "CBSA": 60639,
                  "state": 677261
               },
               "numerator_errors": {
                  "this": 655,
                  "CBSA": 2354,
                  "state": 7344
               },
               "index": {
                  "this": 100,
                  "CBSA": 297,
                  "state": 367
               },
               "error_ratio": {
                  "this": 11.1,
                  "CBSA": 3.8,
                  "state": 1.1
               }
            },
            "asia": {
               "name": "Asia",
               "values": {
                  "this": 63.42,
                  "CBSA": 65.91,
                  "state": 39.42
               },
               "error": {
                  "this": 2.84,
                  "CBSA": 0.62,
                  "state": 0.09
               },
               "numerators": {
                  "this": 14889,
                  "CBSA": 505503,
                  "state": 4157181
               },
               "numerator_errors": {
                  "this": 904,
                  "CBSA": 3277,
                  "state": 17026
               },
               "index": {
                  "this": 100,
                  "CBSA": 96,
                  "state": 161
               },
               "error_ratio": {
                  "this": 4.5,
                  "CBSA": 0.9,
                  "state": 0.2
               }
            },
            "africa": {
               "name": "Africa",
               "values": {
                  "this": 2.45,
                  "CBSA": 1.7,
                  "state": 1.84
               },
               "error": {
                  "this": 0.84,
                  "CBSA": 0.14,
                  "state": 0.05
               },
               "numerators": {
                  "this": 575,
                  "CBSA": 13034,
                  "state": 193567
               },
               "numerator_errors": {
                  "this": 198,
                  "CBSA": 1055,
                  "state": 4869
               },
               "index": {
                  "this": 100,
                  "CBSA": 144,
                  "state": 133
               },
               "error_ratio": {
                  "this": 34.3,
                  "CBSA": 8.2,
                  "state": 2.7
               }
            },
            "oceania": {
               "name": "Oceania",
               "values": {
                  "this": 0.67,
                  "CBSA": 0.53,
                  "state": 0.82
               },
               "error": {
                  "this": 0.38,
                  "CBSA": 0.09,
                  "state": 0.03
               },
               "numerators": {
                  "this": 158,
                  "CBSA": 4060,
                  "state": 86276
               },
               "numerator_errors": {
                  "this": 90,
                  "CBSA": 662,
                  "state": 2767
               },
               "index": {
                  "this": 100,
                  "CBSA": 126,
                  "state": 82
               },
               "error_ratio": {
                  "this": 56.7,
                  "CBSA": 17,
                  "state": 3.7
               }
            },
            "latin_america": {
               "name": "Latin America",
               "values": {
                  "this": 6.64,
                  "CBSA": 22.67,
                  "state": 50.28
               },
               "error": {
                  "this": 1.21,
                  "CBSA": 0.42,
                  "state": 0.15
               },
               "numerators": {
                  "this": 1558,
                  "CBSA": 173901,
                  "state": 5302761
               },
               "numerator_errors": {
                  "this": 291,
                  "CBSA": 3417,
                  "state": 24213
               },
               "index": {
                  "this": 100,
                  "CBSA": 29,
                  "state": 13
               },
               "error_ratio": {
                  "this": 18.2,
                  "CBSA": 1.9,
                  "state": 0.3
               }
            },
            "north_america": {
               "name": "North America",
               "values": {
                  "this": 3.29,
                  "CBSA": 1.29,
                  "state": 1.23
               },
               "error": {
                  "this": 1.12,
                  "CBSA": 0.09,
                  "state": 0.03
               },
               "numerators": {
                  "this": 772,
                  "CBSA": 9858,
                  "state": 130093
               },
               "numerator_errors": {
                  "this": 266,
                  "CBSA": 722,
                  "state": 3038
               },
               "index": {
                  "this": 100,
                  "CBSA": 255,
                  "state": 267
               },
               "error_ratio": {
                  "this": 34,
                  "CBSA": 7,
                  "state": 2.4
               }
            }
         }
      },
      "language": {
         "percent_non_english_at_home": {
            "name": "Persons with language other than English spoken at home",
            "values": {
               "this": null,
               "CBSA": null,
               "state": 44.23
            },
            "error": {
               "this": null,
               "CBSA": null,
               "state": 0.14
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": 1.6292017E7
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": 52781.5
            },
            "metadata": {
               "table_id": "B16001",
               "universe": "Population 5 years and over",
               "acs_release": "ACS 2019 5-year"
            },
            "index": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "error_ratio": {
               "this": null,
               "CBSA": null,
               "state": 0.3
            }
         },
         "children": {
            "metadata": {
               "table_id": "B16007",
               "universe": "Population 5 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "english": {
               "name": "English only",
               "values": {
                  "this": 58.96,
                  "CBSA": 51.8,
                  "state": 56.97
               },
               "error": {
                  "this": 6.49,
                  "CBSA": 2.05,
                  "state": 0.51
               },
               "numerators": {
                  "this": 6567,
                  "CBSA": 163621,
                  "state": 3710631
               },
               "numerator_errors": {
                  "this": 1182,
                  "CBSA": 6463,
                  "state": 33084
               },
               "index": {
                  "this": 100,
                  "CBSA": 114,
                  "state": 103
               },
               "error_ratio": {
                  "this": 11,
                  "CBSA": 4,
                  "state": 0.9
               }
            },
            "spanish": {
               "name": "Spanish",
               "values": {
                  "this": 4.44,
                  "CBSA": 22.79,
                  "state": 33.31
               },
               "error": {
                  "this": 4.43,
                  "CBSA": 1.47,
                  "state": 0.42
               },
               "numerators": {
                  "this": 494,
                  "CBSA": 71974,
                  "state": 2169717
               },
               "numerator_errors": {
                  "this": 498,
                  "CBSA": 4659,
                  "state": 27349
               },
               "index": {
                  "this": 100,
                  "CBSA": 19,
                  "state": 13
               },
               "error_ratio": {
                  "this": 99.8,
                  "CBSA": 6.5,
                  "state": 1.3
               }
            },
            "indoeuropean": {
               "name": "Indo-European",
               "values": {
                  "this": 10.04,
                  "CBSA": 5.87,
                  "state": 3.07
               },
               "error": {
                  "this": 5.02,
                  "CBSA": 0.76,
                  "state": 0.15
               },
               "numerators": {
                  "this": 1118,
                  "CBSA": 18550,
                  "state": 200078
               },
               "numerator_errors": {
                  "this": 581,
                  "CBSA": 2397,
                  "state": 9887
               },
               "index": {
                  "this": 100,
                  "CBSA": 171,
                  "state": 327
               },
               "error_ratio": {
                  "this": 50,
                  "CBSA": 12.9,
                  "state": 4.9
               }
            },
            "asian_islander": {
               "name": "Asian/Islander",
               "values": {
                  "this": 23.75,
                  "CBSA": 18.01,
                  "state": 5.66
               },
               "error": {
                  "this": 7.6,
                  "CBSA": 1.04,
                  "state": 0.17
               },
               "numerators": {
                  "this": 2645,
                  "CBSA": 56897,
                  "state": 368935
               },
               "numerator_errors": {
                  "this": 926,
                  "CBSA": 3297,
                  "state": 11144
               },
               "index": {
                  "this": 100,
                  "CBSA": 132,
                  "state": 420
               },
               "error_ratio": {
                  "this": 32,
                  "CBSA": 5.8,
                  "state": 3
               }
            },
            "other": {
               "name": "Other",
               "values": {
                  "this": 2.82,
                  "CBSA": 1.53,
                  "state": 0.98
               },
               "error": {
                  "this": 4.54,
                  "CBSA": 0.5,
                  "state": 0.11
               },
               "numerators": {
                  "this": 314,
                  "CBSA": 4839,
                  "state": 63864
               },
               "numerator_errors": {
                  "this": 508,
                  "CBSA": 1573,
                  "state": 6999
               },
               "index": {
                  "this": 100,
                  "CBSA": 184,
                  "state": 288
               },
               "error_ratio": {
                  "this": 161,
                  "CBSA": 32.7,
                  "state": 11.2
               }
            }
         },
         "adults": {
            "metadata": {
               "table_id": "B16007",
               "universe": "Population 5 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "english": {
               "name": "English only",
               "values": {
                  "this": 60.64,
                  "CBSA": 45.1,
                  "state": 55.21
               },
               "error": {
                  "this": 4.53,
                  "CBSA": 0.87,
                  "state": 0.18
               },
               "numerators": {
                  "this": 30077,
                  "CBSA": 703298,
                  "state": 1.6905948E7
               },
               "numerator_errors": {
                  "this": 2819.3,
                  "CBSA": 13577.1,
                  "state": 55036
               },
               "index": {
                  "this": 100,
                  "CBSA": 134,
                  "state": 110
               },
               "error_ratio": {
                  "this": 7.5,
                  "CBSA": 1.9,
                  "state": 0.3
               }
            },
            "spanish": {
               "name": "Spanish",
               "values": {
                  "this": 3.35,
                  "CBSA": 17.23,
                  "state": 27.8
               },
               "error": {
                  "this": 1.67,
                  "CBSA": 0.46,
                  "state": 0.12
               },
               "numerators": {
                  "this": 1661,
                  "CBSA": 268660,
                  "state": 8513944
               },
               "numerator_errors": {
                  "this": 834.2,
                  "CBSA": 7123.5,
                  "state": 37016.3
               },
               "index": {
                  "this": 100,
                  "CBSA": 19,
                  "state": 12
               },
               "error_ratio": {
                  "this": 49.9,
                  "CBSA": 2.7,
                  "state": 0.4
               }
            },
            "indoeuropean": {
               "name": "Indo-European",
               "values": {
                  "this": 14.84,
                  "CBSA": 9.75,
                  "state": 4.88
               },
               "error": {
                  "this": 2.52,
                  "CBSA": 0.58,
                  "state": 0.09
               },
               "numerators": {
                  "this": 7363,
                  "CBSA": 152112,
                  "state": 1494004
               },
               "numerator_errors": {
                  "this": 1319.9,
                  "CBSA": 8991,
                  "state": 28577.5
               },
               "index": {
                  "this": 100,
                  "CBSA": 152,
                  "state": 304
               },
               "error_ratio": {
                  "this": 17,
                  "CBSA": 5.9,
                  "state": 1.8
               }
            },
            "asian_islander": {
               "name": "Asian/Islander",
               "values": {
                  "this": 19.69,
                  "CBSA": 26.48,
                  "state": 10.99
               },
               "error": {
                  "this": 3.99,
                  "CBSA": 0.55,
                  "state": 0.08
               },
               "numerators": {
                  "this": 9767,
                  "CBSA": 412940,
                  "state": 3364089
               },
               "numerator_errors": {
                  "this": 2057.4,
                  "CBSA": 8583,
                  "state": 24688.2
               },
               "index": {
                  "this": 100,
                  "CBSA": 74,
                  "state": 179
               },
               "error_ratio": {
                  "this": 20.3,
                  "CBSA": 2.1,
                  "state": 0.7
               }
            },
            "other": {
               "name": "Other",
               "values": {
                  "this": 1.48,
                  "CBSA": 1.43,
                  "state": 1.12
               },
               "error": {
                  "this": 1.06,
                  "CBSA": 0.22,
                  "state": 0.05
               },
               "numerators": {
                  "this": 734,
                  "CBSA": 22343,
                  "state": 343173
               },
               "numerator_errors": {
                  "this": 526.9,
                  "CBSA": 3488.1,
                  "state": 14221.4
               },
               "index": {
                  "this": 100,
                  "CBSA": 103,
                  "state": 132
               },
               "error_ratio": {
                  "this": 71.6,
                  "CBSA": 15.4,
                  "state": 4.5
               }
            }
         }
      },
      "veterans": {
         "wartime_service": {
            "metadata": {
               "table_id": "B21002",
               "universe": "Civilian veterans 18 years and over",
               "acs_release": "ACS 2019 5-year"
            },
            "wwii": {
               "name": "WWII",
               "values": {
                  "this": 193,
                  "CBSA": 2442,
                  "state": 66574
               },
               "error": {
                  "this": 84.3,
                  "CBSA": 319.93,
                  "state": 1628.6
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "index": {
                  "this": 100,
                  "CBSA": 8,
                  "state": null
               },
               "error_ratio": {
                  "this": 43.7,
                  "CBSA": 13.1,
                  "state": 2.4
               }
            },
            "korea": {
               "name": "Korea",
               "values": {
                  "this": 322,
                  "CBSA": 6382,
                  "state": 145536
               },
               "error": {
                  "this": 99.98,
                  "CBSA": 405.23,
                  "state": 2399.66
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "index": {
                  "this": 100,
                  "CBSA": 5,
                  "state": null
               },
               "error_ratio": {
                  "this": 31,
                  "CBSA": 6.3,
                  "state": 1.6
               }
            },
            "vietnam": {
               "name": "Vietnam",
               "values": {
                  "this": 568,
                  "CBSA": 19651,
                  "state": 561083
               },
               "error": {
                  "this": 144.58,
                  "CBSA": 863.14,
                  "state": 4350.77
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "index": {
                  "this": 100,
                  "CBSA": 3,
                  "state": null
               },
               "error_ratio": {
                  "this": 25.5,
                  "CBSA": 4.4,
                  "state": 0.8
               }
            },
            "gulf_1990s": {
               "name": "Gulf (1990s)",
               "values": {
                  "this": 157,
                  "CBSA": 7981,
                  "state": 284290
               },
               "error": {
                  "this": 81.89,
                  "CBSA": 586.46,
                  "state": 3865.66
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "index": {
                  "this": 100,
                  "CBSA": 2,
                  "state": null
               },
               "error_ratio": {
                  "this": 52.2,
                  "CBSA": 7.3,
                  "state": 1.4
               }
            },
            "gulf_2001": {
               "name": "Gulf (2001-)",
               "values": {
                  "this": 186,
                  "CBSA": 7720,
                  "state": 309021
               },
               "error": {
                  "this": 100.6,
                  "CBSA": 544.05,
                  "state": 4425.46
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "index": {
                  "this": 100,
                  "CBSA": 2,
                  "state": null
               },
               "error_ratio": {
                  "this": 54.1,
                  "CBSA": 7,
                  "state": 1.4
               }
            }
         },
         "sex": {
            "male": {
               "name": "Male",
               "values": {
                  "this": 1305,
                  "CBSA": 47264,
                  "state": 1351006
               },
               "error": {
                  "this": 569,
                  "CBSA": 3067,
                  "state": 19299
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "metadata": {
                  "table_id": "B21001",
                  "universe": "Civilian population 18 years and over",
                  "acs_release": "ACS 2019 1-year"
               },
               "index": {
                  "this": 100,
                  "CBSA": 3,
                  "state": null
               },
               "error_ratio": {
                  "this": 43.6,
                  "CBSA": 6.5,
                  "state": 1.4
               }
            },
            "female": {
               "name": "Female",
               "values": {
                  "this": 176,
                  "CBSA": 3888,
                  "state": 120461
               },
               "error": {
                  "this": 150,
                  "CBSA": 937,
                  "state": 5618
               },
               "numerators": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "numerator_errors": {
                  "this": null,
                  "CBSA": null,
                  "state": null
               },
               "metadata": {
                  "table_id": "B21001",
                  "universe": "Civilian population 18 years and over",
                  "acs_release": "ACS 2019 1-year"
               },
               "index": {
                  "this": 100,
                  "CBSA": 5,
                  "state": null
               },
               "error_ratio": {
                  "this": 85.2,
                  "CBSA": 24.1,
                  "state": 4.7
               }
            }
         },
         "number": {
            "name": "Total veterans",
            "values": {
               "this": 1481,
               "CBSA": 51152,
               "state": 1471467
            },
            "error": {
               "this": 583,
               "CBSA": 3444,
               "state": 19838
            },
            "numerators": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "numerator_errors": {
               "this": null,
               "CBSA": null,
               "state": null
            },
            "metadata": {
               "table_id": "B21001",
               "universe": "Civilian population 18 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 3,
               "state": null
            },
            "error_ratio": {
               "this": 39.4,
               "CBSA": 6.7,
               "state": 1.3
            }
         },
         "percentage": {
            "name": "Population with veteran status",
            "values": {
               "this": 2.99,
               "CBSA": 3.28,
               "state": 4.83
            },
            "error": {
               "this": 1.17,
               "CBSA": 0.22,
               "state": 0.07
            },
            "numerators": {
               "this": 1481,
               "CBSA": 51152,
               "state": 1471467
            },
            "numerator_errors": {
               "this": 583,
               "CBSA": 3444,
               "state": 19838
            },
            "metadata": {
               "table_id": "B21001",
               "universe": "Civilian population 18 years and over",
               "acs_release": "ACS 2019 1-year"
            },
            "index": {
               "this": 100,
               "CBSA": 91,
               "state": 62
            },
            "error_ratio": {
               "this": 39.1,
               "CBSA": 6.7,
               "state": 1.4
            }
         }
      }
   },
   "geo_metadata": {
      "aland": 61779777,
      "awater": 4940290,
      "display_name": "Palo Alto, CA",
      "full_geoid": "16000US0655282",
      "population": 67019,
      "simple_name": "Palo Alto",
      "sumlevel": "160",
      "square_miles": 23.9,
      "population_density": 2740.3
   }
}

export default palo_alto