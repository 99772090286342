exports.UNION_CITY_LISTINGS = [
		{
			"closePrice": 675000,
			"daysOnMovoto": 0,
			"id": "096275d4-fe45-477c-8667-6d070e123f6d",
			"tnImgPath": "https://pi.movoto.com/p/102/221136472_0_FNruJ3_p.jpeg",
			"listDate": null,
			"listingAgent": "Jayesh Karamchandani",
			"listPrice": 660000,
			"lotSize": 1470,
			"sqftTotal": 1214,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136472",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 47,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1974,
			"zipCode": "94587",
			"path": "union-city-ca/4164-polaris-ave-union-city-ca-94587-12_11083761/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -15000,
			"propertyId": "b00d3b31-8d1d-44a1-9cbd-ec018ceb7f19",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-21 00:00:00",
			"createdAt": "2021-11-03 07:14:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 07:14:18",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/4164-polaris-avenue-union-city-ca/fullscreen/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.584631,
				"lng": -122.063692,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4164 Polaris Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/4164-polaris-avenue-union-city-ca/fullscreen/"
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 0,
			"id": "5b4c7173-e639-42fd-92c4-40987ab6edaf",
			"tnImgPath": "https://pi.movoto.com/p/102/221149249_0_ZvuV2u_p.jpeg",
			"listDate": null,
			"listingAgent": "Victor Demchuk",
			"listPrice": 989000,
			"lotSize": 3886,
			"sqftTotal": 1432,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221149249",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Capitol City Real Estate",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1978,
			"zipCode": "94587",
			"path": "union-city-ca/31219-fredi-st-union-city-ca-94587-12_28522098/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5bf6bf0d-4cb2-4716-a1c6-88b2bc9305a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-02 00:39:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-02 00:39:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.593124,
				"lng": -122.077933,
				"zipcode": "94587",
				"subPremise": "",
				"address": "31219 Fredi St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1383000,
			"daysOnMovoto": 0,
			"id": "bab297c0-208a-4ec4-83ed-c3276ea4768f",
			"tnImgPath": "https://pi.movoto.com/p/102/221151494_0_aAUJqm_p.jpeg",
			"listDate": null,
			"listingAgent": "Si Nguyen",
			"listPrice": 1188800,
			"lotSize": 6000,
			"sqftTotal": 1566,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151494",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1971,
			"zipCode": "94587",
			"path": "union-city-ca/4537-alice-way-union-city-ca-94587/pid_ehbevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "efe1abce-c1dd-469f-a843-dd57d10c7bfe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-09 15:44:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 15:44:21",
			"virtualTourLink": "https://my.matterport.com/show/?m=2H4oXRQtXWQ",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.582519,
				"lng": -122.074401,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4537 Alice Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1383000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=2H4oXRQtXWQ"
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 0,
			"id": "b320a7a9-627f-42a0-9103-f9c966c5a83c",
			"tnImgPath": "https://pi.movoto.com/p/102/221145489_0_ubqvBE_p.jpeg",
			"listDate": null,
			"listingAgent": "Lodivina Alvarez",
			"listPrice": 1499999,
			"lotSize": 4527,
			"sqftTotal": 2255,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221145489",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2005,
			"zipCode": "94587",
			"path": "union-city-ca/35986-copper-st-union-city-ca-94587/pid_ml7bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba2be996-cb6f-473c-ac4e-5422557ce08e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-11-17 08:09:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 08:09:16",
			"virtualTourLink": "https://my.matterport.com/show/?m=JQc8R1mMcsH&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.58512,
				"lng": -122.007044,
				"zipcode": "94587",
				"subPremise": "",
				"address": "35986 Copper St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=JQc8R1mMcsH&mls=1"
		},
		{
			"closePrice": 1005000,
			"daysOnMovoto": 121,
			"id": "b829108b-4047-4f90-9810-5821f1086b9b",
			"tnImgPath": "https://pi.movoto.com/p/12/40965553_0_UrRzN6_p.jpeg",
			"listDate": "2021-09-01 00:00:00",
			"listingAgent": "Ksai Liang",
			"listPrice": 999899,
			"lotSize": 5000,
			"sqftTotal": 1817,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965553",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "K&J Investment",
			"photoCount": 6,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1981,
			"zipCode": "94587",
			"path": "union-city-ca/33344-7th-st-union-city-ca-94587/pid_0bcevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "61276047-a140-4807-a73d-500a5e2341d2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-09-02 06:26:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.60324,
				"lng": -122.02361,
				"zipcode": "94587",
				"subPremise": "",
				"address": "33344 7th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1005000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1030000,
			"daysOnMovoto": 28,
			"id": "782cb79f-7c82-45f0-bf65-9e5d74bfd599",
			"tnImgPath": "https://pi.movoto.com/p/12/40975303_0_2BVeYb_p.jpeg",
			"listDate": "2021-12-01 00:00:00",
			"listingAgent": "David Vigil",
			"listPrice": 849950,
			"lotSize": 14340,
			"sqftTotal": 1285,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975303",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "94587",
			"path": "union-city-ca/337-whipple-rd-union-city-ca-94587/pid_xu5bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "00ad7f52-5450-404d-aab5-5a09447df966",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-12-02 01:01:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.606249,
				"lng": -122.022322,
				"zipcode": "94587",
				"subPremise": "",
				"address": "337 Whipple Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1030000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1015000,
			"daysOnMovoto": 39,
			"id": "d2ae15ff-ddcd-4368-bfab-980680fbef26",
			"tnImgPath": "https://pi.movoto.com/p/202/CV21244092_0_vEjZYE_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "ENRIQUE VASQUEZ-PLAZA",
			"listPrice": 1015000,
			"lotSize": 10459,
			"sqftTotal": null,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "CV21244092",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "CENTURY 21 MASTERS",
			"photoCount": 21,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "94587",
			"path": "union-city-ca/1442-h-st-union-city-ca-94587/pid_ophevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 35100,
			"propertyId": "ac521a60-acee-4ca3-9911-2c1f2161e7ea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-18 17:57:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.594328,
				"lng": -122.025119,
				"zipcode": "94587",
				"subPremise": "",
				"address": "1442 H St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1015000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 550000,
			"daysOnMovoto": 55,
			"id": "43cd37a0-12b1-46b8-a3ee-966417b661cb",
			"tnImgPath": "https://pi.movoto.com/p/12/40973055_0_7ijFJi_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Stephanie Wedge",
			"listPrice": 529800,
			"lotSize": 33401,
			"sqftTotal": 908,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973055",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Rockford Realty",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1986,
			"zipCode": "94587",
			"path": "union-city-ca/copa-del-oro-dr-union-city-ca-94587-12_40121670/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1755f146-3798-455c-ad9e-976998e74254",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-03 04:46:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": "https://2623CopaDelOroDrive.com/1926776?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.589632,
				"lng": -122.037805,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2623 Copa Del Oro Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://2623CopaDelOroDrive.com/1926776?idx=1"
		},
		{
			"closePrice": 1603000,
			"daysOnMovoto": 39,
			"id": "eb7b007b-e273-42b4-b5cb-9681fb5b5f6e",
			"tnImgPath": "https://pi.movoto.com/p/12/40974024_0_unQNib_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Navi Dhillon",
			"listPrice": 1498000,
			"lotSize": 7524,
			"sqftTotal": 2632,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974024",
			"bath": 2.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94587",
			"path": "union-city-ca/32234-olney-ct-union-city-ca-94587/pid_niaevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "02526d55-cf9d-4723-b5dc-e58da823289e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-12 05:06:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": "https://32234Olney.com/1931403?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.593743,
				"lng": -122.053892,
				"zipcode": "94587",
				"subPremise": "",
				"address": "32234 Olney Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1603000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://32234Olney.com/1931403?idx=1"
		},
		{
			"closePrice": 1330000,
			"daysOnMovoto": 37,
			"id": "07d85839-2b27-4b05-930b-8e3c5bc4133f",
			"tnImgPath": "https://pi.movoto.com/p/12/40973752_0_YMamE7_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Hamed Barakzoy",
			"listPrice": 1099000,
			"lotSize": 6300,
			"sqftTotal": 1401,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973752",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty World Acclaim",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94587",
			"path": "union-city-ca/1996-hartnell-st-union-city-ca-94587/pid_30hevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "32f90d37-8458-4266-b433-2f057b58df5e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-09 22:46:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.591986,
				"lng": -122.029027,
				"zipcode": "94587",
				"subPremise": "",
				"address": "1996 Hartnell St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1330000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1292000,
			"daysOnMovoto": 42,
			"id": "0864bd6c-d416-4b72-bd1a-eb8b14902a83",
			"tnImgPath": "https://pi.movoto.com/p/12/40973358_0_NBjJif_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Ron Vallerga",
			"listPrice": 999950,
			"lotSize": 7659,
			"sqftTotal": 1566,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973358",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Executive Brokers, sm",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1975,
			"zipCode": "94587",
			"path": "union-city-ca/4864-delores-dr-union-city-ca-94587/pid_picevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5e97f5f-c0f7-4e3c-8de1-f13dd1e67297",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-05 02:36:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://click.pstmrk.it/2sm/listings.allaccessphoto.com%2Fv2%2F4864-delores-dr-union-city-ca-94587-1309161%2Funbranded/ftRhgCYN/cUpU/8MU-D_jX--/djJfZGVsaXZlcnk",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.570834,
				"lng": -122.076665,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4864 Delores Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1292000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://click.pstmrk.it/2sm/listings.allaccessphoto.com%2Fv2%2F4864-delores-dr-union-city-ca-94587-1309161%2Funbranded/ftRhgCYN/cUpU/8MU-D_jX--/djJfZGVsaXZlcnk"
		},
		{
			"closePrice": 1615000,
			"daysOnMovoto": 50,
			"id": "9cb44421-fc64-43e1-a037-338f59ff7645",
			"tnImgPath": "https://pi.movoto.com/p/12/40972382_0_Urjfu2_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Imran (Ron) Nasir",
			"listPrice": 1288888,
			"lotSize": 6360,
			"sqftTotal": 2032,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972382",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Benchmark",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1972,
			"zipCode": "94587",
			"path": "union-city-ca/4545-laura-way-union-city-ca-94587-12_28506215/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "61ec2d56-0f79-41a4-8220-87cfa882094d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-10-27 20:46:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://4545lauraway305121mls.f8re.com/Website/Index",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.580134,
				"lng": -122.07042,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4545 Laura Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://4545lauraway305121mls.f8re.com/Website/Index"
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 40,
			"id": "70bcb51a-a11b-4674-8b73-7514b43bae77",
			"tnImgPath": "https://pi.movoto.com/p/12/40973277_0_eNRjzA_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Valerie Tavares",
			"listPrice": 1050000,
			"lotSize": 5950,
			"sqftTotal": 1880,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973277",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Tavares Realty",
			"photoCount": 15,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1977,
			"zipCode": "94587",
			"path": "union-city-ca/4565-darcelle-dr-union-city-ca-94587/pid_e2bevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "da002467-ccd9-48d9-882e-57940c97477b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-04 20:26:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.584134,
				"lng": -122.079816,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4565 Darcelle Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1060000,
			"daysOnMovoto": 45,
			"id": "e1a0a8f0-b12a-4c9b-a251-94f725a5341e",
			"tnImgPath": "https://pi.movoto.com/p/12/40972662_0_FnrFYE_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Steve Rodriguez",
			"listPrice": 999950,
			"lotSize": 6225,
			"sqftTotal": 1202,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972662",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Stone Castle Realty",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94587",
			"path": "union-city-ca/2228-greer-ct-union-city-ca-94587/pid_8c4bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d2ad46fb-b4ec-4c4d-a0d4-595c9d29a4f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-29 19:01:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.581377,
				"lng": -122.016459,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2228 Greer Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1060000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 167500,
			"daysOnMovoto": 42,
			"id": "5dfc1c75-5736-49dd-a84e-e683728cf4df",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868368_0_bUUiRJ_p.jpeg",
			"listDate": "2021-10-28 10:18:59",
			"listingAgent": "Ben Beveridge",
			"listPrice": 169000,
			"lotSize": null,
			"sqftTotal": 768,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868368",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Benjamin Alan Properties",
			"photoCount": 17,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Single Wide",
			"yearBuilt": 1968,
			"zipCode": "94587",
			"path": "union-city-ca/189-hawaii-cir-union-city-ca-94587-100_ml81868368/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "33514223-6697-4372-862d-3497d9245afe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-28 17:27:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 10:18:59",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.58832,
				"lng": -122.049774,
				"zipcode": "94587",
				"subPremise": "",
				"address": "189 Hawaii Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 167500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 21,
			"id": "4fe89af6-296a-434e-bf5f-b3cc983f10a6",
			"tnImgPath": "https://pi.movoto.com/p/12/40974333_0_2iENER_p.jpeg",
			"listDate": "2021-11-16 00:00:00",
			"listingAgent": "Mike Daoudi",
			"listPrice": 1228888,
			"lotSize": 5800,
			"sqftTotal": 1675,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974333",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Excel Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1970,
			"zipCode": "94587",
			"path": "union-city-ca/31391-santa-ana-way-union-city-ca-94587/pid_eh9dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cc02a4cd-ecb2-4297-aaf4-5f7c4ee2f70f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-16 23:36:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.592059,
				"lng": -122.061306,
				"zipcode": "94587",
				"subPremise": "",
				"address": "31391 Santa Ana Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 24,
			"id": "2ebf53c7-1506-4d73-b81e-0525ace0bb56",
			"tnImgPath": "https://pi.movoto.com/p/12/40973619_0_nMzrAf_p.jpeg",
			"listDate": "2021-11-13 00:00:00",
			"listingAgent": "Patricia Austria",
			"listPrice": 1499900,
			"lotSize": 6637,
			"sqftTotal": 2442,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973619",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1991,
			"zipCode": "94587",
			"path": "union-city-ca/2955-sorrento-way-union-city-ca-94587/pid_da7dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d21af28d-cf20-4ee6-b7aa-0edea5cddbd5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-13 21:51:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/YnlXjhc9KCI?rel=0&showinfo=0&enablejsapi=1&wmode=transparent",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.605214,
				"lng": -122.076044,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2955 Sorrento Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/YnlXjhc9KCI?rel=0&showinfo=0&enablejsapi=1&wmode=transparent"
		},
		{
			"closePrice": 655000,
			"daysOnMovoto": 48,
			"id": "ff79b8b1-70a3-4358-8bcf-3288de36c6c3",
			"tnImgPath": "https://pi.movoto.com/p/12/40971554_0_jRUAaz_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Tracy Nelson",
			"listPrice": 680000,
			"lotSize": 1518,
			"sqftTotal": 1137,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971554",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "94587",
			"path": "union-city-ca/4319-planet-cir-union-city-ca-94587-12_40858489/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8d5983de-a2b3-477f-b8c7-08a2cbe59c2f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-20 16:21:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/631602041",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.585246,
				"lng": -122.072315,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4319 Planet Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 655000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/631602041"
		},
		{
			"closePrice": 495000,
			"daysOnMovoto": 56,
			"id": "4961670a-abd1-40ba-a21e-b79d6377d217",
			"tnImgPath": "https://pi.movoto.com/p/12/40970577_0_V2Ejaa_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Anny Cheng",
			"listPrice": 498000,
			"lotSize": 7478,
			"sqftTotal": 798,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970577",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "New Horizon Real Estate",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94587",
			"path": "union-city-ca/34725-alvarado-niles-rd-apt-4-union-city-ca-94587-12_40970577/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -70000,
			"propertyId": "1e41d502-99ba-4cfc-be71-2397a9454690",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-12 15:46:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=zeQcggbTNvq&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.58597,
				"lng": -122.0181,
				"zipcode": "94587",
				"subPremise": "APT 4",
				"address": "34725 Alvarado Niles Rd #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 495000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=zeQcggbTNvq&brand=0"
		},
		{
			"closePrice": 860000,
			"daysOnMovoto": 0,
			"id": "07c40c96-c036-44e4-802e-531dfc48872c",
			"tnImgPath": "https://pi.movoto.com/p/102/221136979_0_FuNZM2_p.jpeg",
			"listDate": null,
			"listingAgent": "Ziyu Chen",
			"listPrice": 788000,
			"lotSize": 0,
			"sqftTotal": 1387,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221136979",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Grand Realty Group",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1982,
			"zipCode": "94587",
			"path": "union-city-ca/paloma-ct-union-city-ca-94587-12_40620190/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f131813-da30-47d9-b176-1d9b7b0efc43",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-28 07:59:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 07:59:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.586444,
				"lng": -122.070751,
				"zipcode": "94587",
				"subPremise": "",
				"address": "32004 Paloma Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1724000,
			"daysOnMovoto": 39,
			"id": "0b0c4a15-0503-44e3-b824-8e5ff5292430",
			"tnImgPath": "https://pi.movoto.com/p/12/40972513_0_mRAnbm_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Michelle Lee",
			"listPrice": 1468000,
			"lotSize": 4500,
			"sqftTotal": 2283,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972513",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1998,
			"zipCode": "94587",
			"path": "union-city-ca/32740-s-belami-loop-union-city-ca-94587/pid_2r8dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e66d7340-30fd-49a0-ad3c-e0895b51f15d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-28 19:26:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.597353,
				"lng": -122.048453,
				"zipcode": "94587",
				"subPremise": "",
				"address": "32740 S Belami Loop"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1724000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 935000,
			"daysOnMovoto": 40,
			"id": "5af90ed4-da0e-46cd-b995-8de9ff939518",
			"tnImgPath": "https://pi.movoto.com/p/12/40972329_0_vRuMFq_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Armando Rangel",
			"listPrice": 899995,
			"lotSize": 6324,
			"sqftTotal": 1489,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972329",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94587",
			"path": "union-city-ca/33344-university-dr-union-city-ca-94587-12_40972329/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5b158b4c-a526-464e-aacb-709ad7b51a23",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-27 15:21:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.599336,
				"lng": -122.02885,
				"zipcode": "94587",
				"subPremise": "",
				"address": "33344 University Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 935000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 170000,
			"daysOnMovoto": 69,
			"id": "a6a3c81d-fa8a-4da2-8698-0d97a734b829",
			"tnImgPath": "https://pi.movoto.com/p/12/40968317_0_uaAaZu_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Ernest Costa",
			"listPrice": 154900,
			"lotSize": 1020,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968317",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 27,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94587",
			"path": "union-city-ca/2-palm-union-city-ca-94587-12_40968317/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d2f1506a-b5c2-46d8-bdbc-764a017caa5f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-09-23 22:01:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.589539,
				"lng": -122.049363,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2 Palm"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 170000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1340000,
			"daysOnMovoto": 0,
			"id": "5381879a-0fe0-4e7b-a025-60fa01cb88d8",
			"tnImgPath": "https://pi.movoto.com/p/102/221122809_0_JQEY2f_p.jpeg",
			"listDate": null,
			"listingAgent": "Phetsamai Sanghera",
			"listPrice": 1150000,
			"lotSize": 5457,
			"sqftTotal": 1374,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221122809",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1978,
			"zipCode": "94587",
			"path": "union-city-ca/2223-grouse-way-union-city-ca-94587/pid_o64bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "002e4872-4587-4f0e-a763-5805b30592ac",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-20 07:14:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 07:14:23",
			"virtualTourLink": "https://my.matterport.com/show/?m=S1V5qQQDrBY",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.583571,
				"lng": -122.014772,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2223 Grouse Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1340000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=S1V5qQQDrBY"
		},
		{
			"closePrice": 770000,
			"daysOnMovoto": 40,
			"id": "5f9a94c0-6d95-4f8b-a1cd-dd930217cdcb",
			"tnImgPath": "https://pi.movoto.com/p/12/40971571_0_MIInMm_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Anna May",
			"listPrice": 699000,
			"lotSize": 1520,
			"sqftTotal": 1193,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971571",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's Int'l",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1972,
			"zipCode": "94587",
			"path": "union-city-ca/4434-bolivar-pl-union-city-ca-94587/pid_hz9dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6ddb13bc-b26b-4dc9-a405-110cfc547be0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-21 06:26:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/2357887",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.589995,
				"lng": -122.080439,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4434 Bolivar Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 770000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/2357887"
		},
		{
			"closePrice": 1160000,
			"daysOnMovoto": 42,
			"id": "ae01bd65-eb6d-41dd-ac02-dfb87753d9d0",
			"tnImgPath": "https://pi.movoto.com/p/12/40971372_0_z72Zuv_p.jpeg",
			"listDate": "2021-10-19 00:00:00",
			"listingAgent": "Meena Jhaj",
			"listPrice": 999000,
			"lotSize": 6500,
			"sqftTotal": 1550,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971372",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty One Group AMR",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94587",
			"path": "union-city-ca/2679-cherry-blossom-way-union-city-ca-94587/pid_gzeevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a67024e-92bc-4b16-aa30-9bc3f111b247",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-19 20:11:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.581516,
				"lng": -122.024542,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2679 Cherry Blossom Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1160000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 234000,
			"daysOnMovoto": 58,
			"id": "e824c80b-50cf-4b4e-bfd4-e6c703fbcc64",
			"tnImgPath": "https://pi.movoto.com/p/12/40969512_0_fB7BQE_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Jennifer Costa",
			"listPrice": 224900,
			"lotSize": 1440,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969512",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 40,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94587",
			"path": "union-city-ca/116-madrone-way-union-city-ca-94587-12_40969512/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "031a2b00-8937-4609-a5e4-ea9f9006581b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-03 03:26:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.5912,
				"lng": -122.05438,
				"zipcode": "94587",
				"subPremise": "",
				"address": "116 Madrone Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 234000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1610000,
			"daysOnMovoto": 34,
			"id": "abf7f93b-e61d-4ce7-93e6-890e78460dc9",
			"tnImgPath": "https://pi.movoto.com/p/12/40971318_0_neev3i_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Adrienne Realiza",
			"listPrice": 1380000,
			"lotSize": 4938,
			"sqftTotal": 1976,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971318",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1980,
			"zipCode": "94587",
			"path": "union-city-ca/4862-mendota-st-union-city-ca-94587/pid_z2pfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f6ce2e89-fa59-4680-9700-75af89903ba9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-21 20:01:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "http://www.4862mendota.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.570344,
				"lng": -122.064813,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4862 Mendota St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.4862mendota.com/?mls"
		},
		{
			"closePrice": 1370000,
			"daysOnMovoto": 41,
			"id": "ca8b8304-47ef-497e-a715-0b80da52e59f",
			"tnImgPath": "https://pi.movoto.com/p/12/40970921_0_FnE6in_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Christopher Jacquez",
			"listPrice": 1125000,
			"lotSize": 13992,
			"sqftTotal": 1382,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970921",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1972,
			"zipCode": "94587",
			"path": "union-city-ca/2813-petunia-ct-union-city-ca-94587/pid_u62bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "66fecbad-7814-49ce-83f8-343528fe9860",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-14 19:41:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://www.cbhometour.com/2813-Petunia-Court-Union-City-CA-94587/mlsindex.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.576587,
				"lng": -122.0211,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2813 Petunia Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1370000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.cbhometour.com/2813-Petunia-Court-Union-City-CA-94587/mlsindex.html"
		},
		{
			"closePrice": 790000,
			"daysOnMovoto": 55,
			"id": "08a45781-b1cb-4e26-82bb-a5b8c48596b7",
			"tnImgPath": "https://pi.movoto.com/p/12/40969092_0_u6urjR_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Kacey Alamzai",
			"listPrice": 750000,
			"lotSize": 1560,
			"sqftTotal": 1050,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969092",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1997,
			"zipCode": "94587",
			"path": "union-city-ca/34862-rumford-ter-union-city-ca-94587-12_40701390/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b49df89f-f953-491a-853a-7ea7c619366e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-30 16:16:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.586278,
				"lng": -122.016227,
				"zipcode": "94587",
				"subPremise": "",
				"address": "34862 Rumford Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 790000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 565000,
			"daysOnMovoto": 62,
			"id": "3bc662ec-9312-4c8e-a949-708df0e48e40",
			"tnImgPath": "https://pi.movoto.com/p/12/40968082_0_NenZqJ_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Nancy T. Ching",
			"listPrice": 580000,
			"lotSize": 8310,
			"sqftTotal": 1006,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968082",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2001,
			"zipCode": "94587",
			"path": "union-city-ca/35550-monterra-ter-apt-201-union-city-ca-94587-12_40968082/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "20ab9f04-c775-46bc-aaf8-0271012772bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-23 22:26:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://www.betterhomestudio.com/35550monterraterapt201",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.58578,
				"lng": -122.011732,
				"zipcode": "94587",
				"subPremise": "APT 201",
				"address": "35550 Monterra Ter #201"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 565000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.betterhomestudio.com/35550monterraterapt201"
		},
		{
			"closePrice": 219000,
			"daysOnMovoto": 60,
			"id": "07acd085-4be8-4a1d-a60a-ea3e060c1e18",
			"tnImgPath": "https://pi.movoto.com/p/12/40968560_0_72FfRU_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Helena Gigi Ying",
			"listPrice": 229000,
			"lotSize": 1440,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968560",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 37,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94587",
			"path": "union-city-ca/162-kona-union-city-ca-94587-12_40968560/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "84e2b713-cf4f-4ed5-b922-8fff39f625e6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-09-24 21:56:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "http://www.360guru.com/index.html?hid=8507",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.589295,
				"lng": -122.049353,
				"zipcode": "94587",
				"subPremise": "",
				"address": "162 Kona"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 219000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.360guru.com/index.html?hid=8507"
		},
		{
			"closePrice": 840000,
			"daysOnMovoto": 55,
			"id": "33cf8503-a9ff-40ad-9736-730d7de670d3",
			"tnImgPath": "https://pi.movoto.com/p/12/40968607_0_6Z33yE_p.jpeg",
			"listDate": "2021-09-28 00:00:00",
			"listingAgent": "Sanjiv Nayyar",
			"listPrice": 825000,
			"lotSize": 447263,
			"sqftTotal": 1203,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968607",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "94587",
			"path": "union-city-ca/1050-moonstone-ter-union-city-ca-94587-12_40968607/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a1010dc-f05e-476c-8f54-847b7a808d9c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-09-28 17:36:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=R4qPeK3eztP&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.589401,
				"lng": -122.011551,
				"zipcode": "94587",
				"subPremise": "",
				"address": "1050 Moonstone Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 840000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=R4qPeK3eztP&mls=1"
		},
		{
			"closePrice": 510000,
			"daysOnMovoto": 65,
			"id": "787e54c4-156e-48e4-97d5-407b2db15ae5",
			"tnImgPath": "https://pi.movoto.com/p/110/421595323_0_ZMVaQy_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Jimmy Lam",
			"listPrice": 525000,
			"lotSize": 0,
			"sqftTotal": 946,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595323",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Infiniti Group",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94587",
			"path": "union-city-ca/4906-bridgepointe-pl-union-city-ca-94587-12_40407132/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d0d0f5b8-addf-4791-877a-a6f77dc9b506",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-09-16 17:36:52",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.56708,
				"lng": -122.068402,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4906 Bridgepointe Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 882900,
			"daysOnMovoto": 47,
			"id": "b7eb5711-8f35-43b2-80a8-403552808a1e",
			"tnImgPath": "https://pi.movoto.com/p/12/40969173_0_3RjaYq_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Kanwal Gill",
			"listPrice": 869500,
			"lotSize": 0,
			"sqftTotal": 1344,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969173",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1997,
			"zipCode": "94587",
			"path": "union-city-ca/1043-la-sierra-ter-union-city-ca-94587-12_11056829/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -29500,
			"propertyId": "358e63b7-a71c-4bcd-be13-7a1fcaa0d51d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-09-30 21:26:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.604434,
				"lng": -122.029908,
				"zipcode": "94587",
				"subPremise": "",
				"address": "1043 La Sierra Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 882900,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 77,
			"id": "bdab7859-e06d-4ff0-93eb-f688ae288611",
			"tnImgPath": "https://pi.movoto.com/p/12/40965305_0_7u2ynU_p.jpeg",
			"listDate": "2021-08-31 00:00:00",
			"listingAgent": "Yolanda Espinoza-Munoz",
			"listPrice": 1177000,
			"lotSize": 5457,
			"sqftTotal": 3136,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965305",
			"bath": 4,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Home America Realty",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1988,
			"zipCode": "94587",
			"path": "union-city-ca/33522-depot-rd-union-city-ca-94587-12_40965305/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -116523000,
			"propertyId": "2cd46861-e566-46bc-ac49-b724dedc8444",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-08-31 19:21:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.600181,
				"lng": -122.023952,
				"zipcode": "94587",
				"subPremise": "",
				"address": "33522 Depot Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 0,
			"id": "9046a343-fc98-4394-aa74-2bb51ed04e5a",
			"tnImgPath": "https://pi.movoto.com/p/102/221102774_0_vVeqjj_p.jpeg",
			"listDate": null,
			"listingAgent": "Leslie Stirewalt",
			"listPrice": 925000,
			"lotSize": 4792,
			"sqftTotal": 1863,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221102774",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1954,
			"zipCode": "94587",
			"path": "union-city-ca/33821-6th-st-union-city-ca-94587/pid_fwfevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -13888,
			"propertyId": "2eb67402-e46a-46b8-a616-da15103df74f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-08-17 21:09:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 21:09:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.599052,
				"lng": -122.01828,
				"zipcode": "94587",
				"subPremise": "",
				"address": "33821 6th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 999000,
			"daysOnMovoto": 18,
			"id": "21e4b6bb-6df5-41d2-b8c5-d97c84f9a4d7",
			"tnImgPath": "https://pi.movoto.com/p/12/40972204_0_y7eYzY_p.jpeg",
			"listDate": "2021-10-25 00:00:00",
			"listingAgent": "Michael Pinheiro",
			"listPrice": 999000,
			"lotSize": 9768,
			"sqftTotal": 1763,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972204",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "94587",
			"path": "union-city-ca/585-jonathan-way-union-city-ca-94587/pid_cn8bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "49a91405-5cad-44da-924d-5b5d12bf73d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-29 20:30:55",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.609218,
				"lng": -122.020545,
				"zipcode": "94587",
				"subPremise": "",
				"address": "585 Jonathan Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 999000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1340000,
			"daysOnMovoto": 25,
			"id": "8e3194d8-46f0-48ed-ab6e-d4cc89f012d6",
			"tnImgPath": "https://pi.movoto.com/p/12/40971130_0_iVeYIU_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Nicole Causey",
			"listPrice": 1150000,
			"lotSize": 5590,
			"sqftTotal": 1378,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971130",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1978,
			"zipCode": "94587",
			"path": "union-city-ca/2216-tanger-ct-union-city-ca-94587-12_40694001/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "26637480-a62d-4e95-b144-830dd90f3082",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-10-15 19:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=AfsdsRRdwFK&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.58364,
				"lng": -122.01566,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2216 Tanger Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1340000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=AfsdsRRdwFK&brand=0"
		},
		{
			"closePrice": 965000,
			"daysOnMovoto": 42,
			"id": "34161e35-a862-404c-a701-819a0100e842",
			"tnImgPath": "https://pi.movoto.com/p/12/40968512_0_2eiiFJ_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Nicole Causey",
			"listPrice": 850000,
			"lotSize": 5000,
			"sqftTotal": 1360,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968512",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94587",
			"path": "union-city-ca/33317-5th-st-union-city-ca-94587/pid_tfcevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "84f59da2-dfc8-4045-a947-16b6db2302e3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-24 19:16:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=TXyBfoWSFDr&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.604309,
				"lng": -122.023232,
				"zipcode": "94587",
				"subPremise": "",
				"address": "33317 5th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 965000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=TXyBfoWSFDr&brand=0"
		},
		{
			"closePrice": 1275000,
			"daysOnMovoto": 0,
			"id": "5f2d8c6e-1a36-429a-9246-05f3bf8cde86",
			"tnImgPath": "https://pi.movoto.com/p/102/221090416_0_36iYFz_p.jpeg",
			"listDate": null,
			"listingAgent": "John Diaz",
			"listPrice": 1350000,
			"lotSize": 5056,
			"sqftTotal": 1946,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221090416",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Fathom Realty Group, Inc.",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1965,
			"zipCode": "94587",
			"path": "union-city-ca/363-monte-carlo-way-union-city-ca-94587-102_221090416/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50000,
			"propertyId": "9fc85ba3-e0d7-4c55-8e47-1b1078ad1383",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-07-23 22:14:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-23 22:14:23",
			"virtualTourLink": "https://player.vimeo.com/external/614652169.hd.mp4?s=5d7ed2e36514140ff6cdf00734a9a36b6a185b56&profile_id=174",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.595391,
				"lng": -122.000426,
				"zipcode": "94587",
				"subPremise": "",
				"address": "363 Monte Carlo Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/external/614652169.hd.mp4?s=5d7ed2e36514140ff6cdf00734a9a36b6a185b56&profile_id=174"
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 30,
			"id": "bb394e6d-4ad6-4f1b-823d-535368503722",
			"tnImgPath": "https://pi.movoto.com/p/12/40969568_0_mBvuae_p.jpeg",
			"listDate": "2021-10-05 00:00:00",
			"listingAgent": "Raymond Maestas",
			"listPrice": 1099000,
			"lotSize": 7068,
			"sqftTotal": 1565,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969568",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1968,
			"zipCode": "94587",
			"path": "union-city-ca/32250-devonshire-st-union-city-ca-94587-100_80632903/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cab443f9-fedd-4d56-8147-6552ac236b05",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-06 00:26:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.603035,
				"lng": -122.056067,
				"zipcode": "94587",
				"subPremise": "",
				"address": "32250 Devonshire St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 41,
			"id": "5dcf489a-d81a-4d09-9d4a-7b807f97c5a6",
			"tnImgPath": "https://pi.movoto.com/p/12/40968543_0_jI2ZmR_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Sandy Hermerding",
			"listPrice": 1195000,
			"lotSize": 6222,
			"sqftTotal": 1382,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968543",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 2,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1975,
			"zipCode": "94587",
			"path": "union-city-ca/2812-pelican-dr-union-city-ca-94587/pid_xy6dvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "932e1bba-59ce-4736-a1d6-421ea087080e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-24 20:26:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "http://www.360guru.com/index.html?hid=8509",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.589523,
				"lng": -122.042615,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2812 Pelican Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.360guru.com/index.html?hid=8509"
		},
		{
			"closePrice": 435000,
			"daysOnMovoto": 43,
			"id": "6465345a-f91f-4d6a-a68a-4df39e871117",
			"tnImgPath": "https://pi.movoto.com/p/12/40968133_0_i6IUnb_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Tina Kaur-Campbell",
			"listPrice": 469000,
			"lotSize": 8310,
			"sqftTotal": 695,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968133",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2001,
			"zipCode": "94587",
			"path": "union-city-ca/35530-monterra-ter-303-union-city-ca-94587/pid_pi6bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8af673dc-2f39-456a-8122-032cd75be4db",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-23 02:56:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.58535,
				"lng": -122.011662,
				"zipcode": "94587",
				"subPremise": "APT 303",
				"address": "35530 Monterra Ter #303"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 435000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1220000,
			"daysOnMovoto": 31,
			"id": "21b3182a-8d71-4a04-bd72-1a66aee00825",
			"tnImgPath": "https://pi.movoto.com/p/12/40969311_0_ybu6fn_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Joseph Sabeh Jr",
			"listPrice": 1098950,
			"lotSize": 1109,
			"sqftTotal": 1976,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969311",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Venture Sotheby's Intl Rlty",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2019,
			"zipCode": "94587",
			"path": "union-city-ca/33403-soares-ranch-ter-union-city-ca-94587-100_ml81758695/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f01fcb5e-e43d-40a7-87aa-6a6425199dd8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-10-01 17:36:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=ES6zpHAcLjr",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.590923,
				"lng": -122.036626,
				"zipcode": "94587",
				"subPremise": "",
				"address": "33403 Soares Ranch Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=ES6zpHAcLjr"
		},
		{
			"closePrice": 1520000,
			"daysOnMovoto": 26,
			"id": "3c2abea6-ec78-44a0-aa4c-ecd2cbc0c19d",
			"tnImgPath": "https://pi.movoto.com/p/12/40969464_0_RyzvvR_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Angelica Ojeda",
			"listPrice": 1299000,
			"lotSize": 12432,
			"sqftTotal": 2192,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969464",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Radius Agent Realty",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1977,
			"zipCode": "94587",
			"path": "union-city-ca/2915-daisy-ct-union-city-ca-94587/pid_6o6bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50296cf0-61e2-47af-80ea-3f4cd55c2616",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-10-02 08:01:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/619612151",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.578892,
				"lng": -122.024187,
				"zipcode": "94587",
				"subPremise": "",
				"address": "2915 Daisy Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1520000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/619612151"
		},
		{
			"closePrice": 775000,
			"daysOnMovoto": 42,
			"id": "34c134f1-9a44-49d5-b598-7fb75e46619c",
			"tnImgPath": "https://pi.movoto.com/p/12/40967473_0_NRNZjq_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Karyn Henson",
			"listPrice": 775000,
			"lotSize": 1520,
			"sqftTotal": 1155,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967473",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "ROCK & ASSOCIATES INC",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1970,
			"zipCode": "94587",
			"path": "union-city-ca/4545-reyes-union-city-ca-94587-12_40967473/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 25000,
			"propertyId": "60d5a2d3-cf01-47bf-b608-420ac4388a03",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-17 02:46:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.588211,
				"lng": -122.080231,
				"zipcode": "94587",
				"subPremise": "",
				"address": "4545 Reyes"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 775000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 550000,
			"daysOnMovoto": 76,
			"id": "f5238989-8501-4a9a-8edf-2975de4cbd3d",
			"tnImgPath": "https://pi.movoto.com/p/12/40963184_0_FrERUE_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Ron Melvin",
			"listPrice": 550000,
			"lotSize": 1650,
			"sqftTotal": 856,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963184",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "94587",
			"path": "union-city-ca/32208-mercury-way-union-city-ca-94587/pid_txaevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "5fbc7302-a7ba-435a-8443-97aa36b8d964",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-08-14 00:56:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": "https://32208MercuryWay45406mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.585919,
				"lng": -122.072311,
				"zipcode": "94587",
				"subPremise": "",
				"address": "32208 Mercury Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://32208MercuryWay45406mls.f8re.com/"
		},
		{
			"closePrice": 195000,
			"daysOnMovoto": 14,
			"id": "1e696b33-4777-4dad-b65d-157cdd5ced3b",
			"tnImgPath": "https://pi.movoto.com/p/12/40970835_0_ReEyyr_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Jane Cresswell",
			"listPrice": 199000,
			"lotSize": 1440,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970835",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty World Pinnacle",
			"photoCount": 12,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94587",
			"path": "union-city-ca/143-polynesia-way-apt-143-union-city-ca-94587-12_40970835/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "858e5467-f0e8-41b5-a197-e50da448e588",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-10-14 05:11:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.592404,
				"lng": -122.055224,
				"zipcode": "94587",
				"subPremise": "APT 143",
				"address": "143 Polynesia Way #143"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 195000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 876000,
			"daysOnMovoto": 25,
			"id": "5bcef951-5b1e-4405-a49c-eeb26f9e23d0",
			"tnImgPath": "https://pi.movoto.com/p/12/40969358_0_ejVRVU_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Thomas Connolly",
			"listPrice": 799000,
			"lotSize": 54033,
			"sqftTotal": 1675,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969358",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Connolly Real Estate Team",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1981,
			"zipCode": "94587",
			"path": "union-city-ca/3804-shinglewood-ct-3-union-city-ca-94587/pid_ajcevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "599fad24-b5ac-4aad-ae4d-ec0014c91fe2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-10-01 20:16:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Union City",
				"lat": 37.595996,
				"lng": -122.077397,
				"zipcode": "94587",
				"subPremise": "APT 3",
				"address": "3804 Shinglewood Ct #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 876000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]