exports.CAMPBELL_LISTINGS = [
		{
			"closePrice": 616000,
			"daysOnMovoto": 43,
			"id": "038f2998-1897-4302-9b5f-f39acd7781b2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871466_0_aYZiYM_p.jpeg",
			"listDate": "2021-12-01 15:21:52",
			"listingAgent": "Ray Conti",
			"listPrice": 649000,
			"lotSize": null,
			"sqftTotal": 810,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871466",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "95008",
			"path": "campbell-ca/323-n-2nd-st-1-campbell-ca-95008-100_81336187/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a538de38-f465-424c-8734-68d2a3cedf24",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-01 23:27:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-01 15:21:52",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.291523,
				"lng": -121.946176,
				"zipcode": "95008",
				"subPremise": "APT 1",
				"address": "323 N 2nd St #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 616000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 882000,
			"daysOnMovoto": 50,
			"id": "b054dca9-6007-4210-a1af-923e3fd80222",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870079_0_miQzAN_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "John V. Pinto",
			"listPrice": 877000,
			"lotSize": 1093,
			"sqftTotal": 1464,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870079",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "John V. Pinto, Broker",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1979,
			"zipCode": "95008",
			"path": "campbell-ca/810-s-san-tomas-aquino-rd-campbell-ca-95008-100_80381787/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -2000,
			"propertyId": "8168a42b-8eb2-4974-aee2-cb793b0d3a50",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-11-18 18:32:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": "https://listing.ewalk.com/bt/810_S_San_Tomas_Aquino_Rd.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.275392,
				"lng": -121.970947,
				"zipcode": "95008",
				"subPremise": "",
				"address": "810 S San Tomas Aquino Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 882000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listing.ewalk.com/bt/810_S_San_Tomas_Aquino_Rd.html"
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 87,
			"id": "6acf71ce-fb54-4f98-bd50-86c4230997f0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866203_0_JAiJIb_p.jpeg",
			"listDate": "2021-10-09 10:15:51",
			"listingAgent": "Arvin Paredes",
			"listPrice": 865000,
			"lotSize": 867,
			"sqftTotal": 1152,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866203",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1978,
			"zipCode": "95008",
			"path": "campbell-ca/670-w-sunnyoaks-ave-campbell-ca-95008-100_ml81866203/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -34000,
			"propertyId": "f1f13b99-3e06-48b3-8319-51a8e0ca352c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-10-09 17:22:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-09 10:15:51",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.270896,
				"lng": -121.960753,
				"zipcode": "95008",
				"subPremise": "",
				"address": "670 W Sunnyoaks Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 720000,
			"daysOnMovoto": 25,
			"id": "2aa8d7c8-861b-4a6d-9380-46f5695f8b01",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872151_0_iNQrI2_p.jpeg",
			"listDate": "2021-12-09 09:23:40",
			"listingAgent": "Arvin Paredes",
			"listPrice": 650000,
			"lotSize": null,
			"sqftTotal": 1081,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872151",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 41,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "95008",
			"path": "san-jose-ca/2785-s-bascom-ave-1-san-jose-ca-95008-100_80011274/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b39786be-f14d-4e5c-a37e-64f1b4ff494e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-12-09 17:27:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 09:23:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.271226,
				"lng": -121.937566,
				"zipcode": "95008",
				"subPremise": "APT 1",
				"address": "2785 S Bascom Ave #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2028000,
			"daysOnMovoto": 59,
			"id": "20d20624-36c4-45d9-b0b9-0cd992c1461f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869377_0_2zymjY_p.jpeg",
			"listDate": "2021-11-05 15:30:45",
			"listingAgent": "Michael Shields",
			"listPrice": 2075000,
			"lotSize": 9627,
			"sqftTotal": 3608,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869377",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "SiliconValley MultiFamilyGroup",
			"photoCount": 13,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1961,
			"zipCode": "95008",
			"path": "campbell-ca/429-dunster-dr-campbell-ca-95008/pid_38qq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "95e0df52-e13f-4428-98ca-19ad8351040f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-11-05 22:32:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 15:30:45",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.293305,
				"lng": -121.952421,
				"zipcode": "95008",
				"subPremise": "",
				"address": "429 Dunster Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2028000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 245000,
			"daysOnMovoto": 79,
			"id": "6479a2da-e837-44ae-a3b6-f3beed91129f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866515_0_j7Ennr_p.jpeg",
			"listDate": "2021-10-12 16:49:21",
			"listingAgent": "Victor Nemechek",
			"listPrice": 245000,
			"lotSize": null,
			"sqftTotal": 912,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866515",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 27,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Single Wide",
			"yearBuilt": 1975,
			"zipCode": "95008",
			"path": "campbell-ca/9-timber-cove-dr-campbell-ca-95008-100_ml81866515/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee8d7e9b-b508-4cdb-90c1-d0af2100bc96",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-31 00:00:00",
			"createdAt": "2021-10-12 23:52:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 16:49:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.275975,
				"lng": -121.947605,
				"zipcode": "95008",
				"subPremise": "",
				"address": "9 Timber Cove Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 245000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 915000,
			"daysOnMovoto": 127,
			"id": "dd0358d8-725d-4919-a41f-a9eec7705941",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859737_0_YrmZFI_p.jpeg",
			"listDate": "2021-08-24 12:54:09",
			"listingAgent": "Daniel Rumer",
			"listPrice": 935000,
			"lotSize": 1777,
			"sqftTotal": 1368,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859737",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1982,
			"zipCode": "95008",
			"path": "campbell-ca/506-w-sunnyoaks-ave-campbell-ca-95008/pid_1f4r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -64000,
			"propertyId": "61fa1e8c-6761-4570-9cc2-3ea6caff3a3d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-08-24 19:57:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-24 12:54:09",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.271887,
				"lng": -121.959152,
				"zipcode": "95008",
				"subPremise": "",
				"address": "506 W Sunnyoaks Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 915000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 910000,
			"daysOnMovoto": 0,
			"id": "996da249-5c3a-42ff-bdd2-e01d6162e119",
			"tnImgPath": "https://pi.movoto.com/p/102/221147527_0_eIZ3E7_p.jpeg",
			"listDate": null,
			"listingAgent": "Ric Cheng",
			"listPrice": 875000,
			"lotSize": 714,
			"sqftTotal": 1188,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147527",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Gold",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1984,
			"zipCode": "95008",
			"path": "campbell-ca/monte-villa-ct-campbell-ca-95008-100_80035482/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "483b17fb-ba9b-4c1f-94d8-0baa50078fba",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-24 01:14:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-24 01:14:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.260586,
				"lng": -121.948384,
				"zipcode": "95008",
				"subPremise": "",
				"address": "155 Monte Villa Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 910000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 631000,
			"daysOnMovoto": 18,
			"id": "0fe2c585-a64a-4f3c-adde-2d587829cf03",
			"tnImgPath": "https://pi.movoto.com/p/110/421613477_0_zIYzVn_p.jpeg",
			"listDate": "2021-11-22 00:00:00",
			"listingAgent": "Pauline Ha",
			"listPrice": 599000,
			"lotSize": 449,
			"sqftTotal": 903,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421613477",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "95008",
			"path": "campbell-ca/231-watson-dr-2-campbell-ca-95008-100_80519415/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7de5527e-6fe4-425f-9757-1fcaaa80cdcf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-12-02 03:36:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-22 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?brand=0&m=KZDEjSES3uy",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.292901,
				"lng": -121.946341,
				"zipcode": "95008",
				"subPremise": "APT 2",
				"address": "231 Watson Dr #2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 631000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?brand=0&m=KZDEjSES3uy"
		},
		{
			"closePrice": 1565000,
			"daysOnMovoto": 37,
			"id": "b1453637-e08f-4753-ad39-74c9043b0d96",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868871_0_rmUBYa_p.jpeg",
			"listDate": "2021-11-02 08:54:32",
			"listingAgent": "Greg Hales",
			"listPrice": 1399000,
			"lotSize": 7810,
			"sqftTotal": 1919,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868871",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 15,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1958,
			"zipCode": "95008",
			"path": "campbell-ca/700-monica-ln-campbell-ca-95008/pid_879y2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "690217a0-7c7b-4c14-8c66-c25d5d27fd6b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-02 15:57:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 08:54:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.2971,
				"lng": -121.944263,
				"zipcode": "95008",
				"subPremise": "",
				"address": "700 Monica Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1565000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1625000,
			"daysOnMovoto": 16,
			"id": "65f0ea77-2237-4416-90d9-996ba29a1158",
			"tnImgPath": "https://pi.movoto.com/p/12/40974386_0_A772NE_p.jpeg",
			"listDate": "2021-11-16 00:00:00",
			"listingAgent": "Lester Belliveau",
			"listPrice": 1188800,
			"lotSize": 8040,
			"sqftTotal": 1112,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974386",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "95008",
			"path": "campbell-ca/1147-fewtrell-dr-campbell-ca-95008/pid_291q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "306c322e-d1e1-4270-8e3e-42235a8764da",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-11-16 22:41:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=hQeJZC717w5&brand=0\"",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.288059,
				"lng": -121.929156,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1147 Fewtrell Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1625000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=hQeJZC717w5&brand=0\""
		},
		{
			"closePrice": 279000,
			"daysOnMovoto": 46,
			"id": "2c6f4c9c-107e-4177-8230-c4224a8b1cef",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866791_0_nZzE2E_p.jpeg",
			"listDate": "2021-10-14 12:39:10",
			"listingAgent": "Todd Su",
			"listPrice": 279000,
			"lotSize": null,
			"sqftTotal": 1440,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866791",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 17,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1975,
			"zipCode": "95008",
			"path": "campbell-ca/30-timber-cove-dr-campbell-ca-95008-100_ml81866791/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "90ce36ea-0840-4dd5-980a-9266a9bb78ea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-14 19:47:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 12:39:10",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.276512,
				"lng": -121.947402,
				"zipcode": "95008",
				"subPremise": "",
				"address": "30 Timber Cove Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 279000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 0,
			"id": "de679e23-d4e5-4a21-b46b-fdacf1c5461a",
			"tnImgPath": "https://pi.movoto.com/p/208/21-3843_0_ENbir3_p.jpeg",
			"listDate": null,
			"listingAgent": "Curtis L Swan II",
			"listPrice": 1399000,
			"lotSize": 11326,
			"sqftTotal": 1358,
			"mlsDbNumber": 208,
			"mls": {
				"dateHidden": null,
				"id": 208
			},
			"mlsNumber": "21-3843",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Village Properties",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "95008",
			"path": "campbell-ca/853-briarwood-way-campbell-ca-95008/pid_43as2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "727e9048-3147-496a-a5d8-9439145d2380",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-18 02:43:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-18 02:43:12",
			"virtualTourLink": "https://tours.databasedads.com:443/4144296/853-Briarwood-Way-Out-Of-Area-CA?u=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.275006,
				"lng": -121.936245,
				"zipcode": "95008",
				"subPremise": "",
				"address": "853 Briarwood Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.databasedads.com:443/4144296/853-Briarwood-Way-Out-Of-Area-CA?u=1"
		},
		{
			"closePrice": 1860000,
			"daysOnMovoto": 0,
			"id": "b753de5f-d0f4-462b-8694-16b0759c5336",
			"tnImgPath": "https://pi.movoto.com/p/102/221130599_0_uUJB6b_p.jpeg",
			"listDate": null,
			"listingAgent": "Jeffrey Brooks",
			"listPrice": 1595000,
			"lotSize": 7967,
			"sqftTotal": 1607,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221130599",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1977,
			"zipCode": "95008",
			"path": "campbell-ca/1567-mccoy-ave-campbell-ca-95008/pid_aa1r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c018f78e-4da9-49f3-ae82-0e04a917b6bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-14 19:29:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 19:29:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.276804,
				"lng": -121.977726,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1567 Mccoy Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 665000,
			"daysOnMovoto": 32,
			"id": "cad5ec07-2cfa-4112-94a9-a9bdfb3833ff",
			"tnImgPath": "https://pi.movoto.com/p/110/421600809_0_r6rfjJ_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Nelson Lam",
			"listPrice": 650000,
			"lotSize": 449,
			"sqftTotal": 903,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421600809",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Manderley Realty, Inc.",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "95008",
			"path": "campbell-ca/237-watson-dr-2-campbell-ca-95008-100_80349628/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "39423a30-2d57-4c01-86c5-d18e5e0fc5d0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-10-07 23:16:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.293109,
				"lng": -121.945719,
				"zipcode": "95008",
				"subPremise": "APT 2",
				"address": "237 Watson Dr #2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 665000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 83,
			"id": "4269c8c9-b238-4c2e-a524-d2bf534ae068",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81856914_0_VFMiuq_p.jpeg",
			"listDate": "2021-08-05 08:25:53",
			"listingAgent": "Suke Zhao",
			"listPrice": 1999900,
			"lotSize": 7836,
			"sqftTotal": 3328,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81856914",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Bayview Realty & Financial",
			"photoCount": 15,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1961,
			"zipCode": "95008",
			"path": "campbell-ca/781-sharmon-palms-ln-campbell-ca-95008/pid_wn6r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6369e93-6abf-4871-9b96-3901243f67d7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-08-05 15:32:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-05 08:25:53",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.275296,
				"lng": -121.961864,
				"zipcode": "95008",
				"subPremise": "",
				"address": "781 Sharmon Palms Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 30,
			"id": "d14b6ce8-f9e0-4191-ad6d-fd9d6c6f7e68",
			"tnImgPath": "https://pi.movoto.com/p/110/421598958_0_bQiqyZ_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Dennis Stavropoulos",
			"listPrice": 2200000,
			"lotSize": 8239,
			"sqftTotal": 3747,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421598958",
			"bath": 5,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Sterling Homes",
			"photoCount": 51,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95008",
			"path": "campbell-ca/162-wilton-dr-campbell-ca-95008/pid_ujuq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 75000,
			"propertyId": "14c2a104-ae20-42bd-bc35-0ab3fb0be9c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-09-28 19:36:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.279664,
				"lng": -121.952777,
				"zipcode": "95008",
				"subPremise": "",
				"address": "162 Wilton Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 295000,
			"daysOnMovoto": 183,
			"id": "9f3d5d94-8b21-41da-9772-48f8506154d1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81839613_0_jQEvYa_p.jpeg",
			"listDate": "2021-04-26 00:00:00",
			"listingAgent": "Becky Guillett",
			"listPrice": 324900,
			"lotSize": null,
			"sqftTotal": 1040,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81839613",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 23,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1965,
			"zipCode": "95008",
			"path": "campbell-ca/80-la-paz-campbell-ca-95008-100_ml81839613/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -15000,
			"propertyId": "4ba2fc34-f8aa-4f37-b9c8-d2ae027083b9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-04-26 15:52:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-26 00:00:00",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2859702",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.28207,
				"lng": -121.940315,
				"zipcode": "95008",
				"subPremise": "",
				"address": "80 La Paz"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 295000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2859702"
		},
		{
			"closePrice": 2249000,
			"daysOnMovoto": 39,
			"id": "65928bbb-10fe-4c3c-bb8a-83a9fe1577e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40967365_0_yBARqR_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Heman Galzie",
			"listPrice": 1999000,
			"lotSize": 6800,
			"sqftTotal": 2959,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967365",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "New Cal Realty",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1998,
			"zipCode": "95008",
			"path": "campbell-ca/380-hatcher-ct-campbell-ca-95008-100_81430715/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a75e2c76-60f4-4702-82f1-0e37db2cfa7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-16 20:41:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": "https://380Hatcher.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.26032,
				"lng": -121.948029,
				"zipcode": "95008",
				"subPremise": "",
				"address": "380 Hatcher Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2249000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://380Hatcher.com/idx"
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 41,
			"id": "7b7896c0-2555-4723-80d5-cc56d720d96c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861165_0_Im7uFn_p.jpeg",
			"listDate": "2021-09-03 10:41:23",
			"listingAgent": "Faris-Taylor Team",
			"listPrice": 1849000,
			"lotSize": 8080,
			"sqftTotal": 3422,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861165",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 18,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "95008",
			"path": "campbell-ca/201-w-hamilton-ave-campbell-ca-95008/pid_h2bz2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a6a5c8a-b840-47e1-a260-dded45f8f224",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-03 17:47:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 10:41:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.294579,
				"lng": -121.953686,
				"zipcode": "95008",
				"subPremise": "",
				"address": "201 W Hamilton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2150000,
			"daysOnMovoto": 43,
			"id": "d0e35532-758e-4a9c-aad6-c2c52ab1a148",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860898_0_eiBuiZ_p.jpeg",
			"listDate": "2021-09-01 17:30:33",
			"listingAgent": "Russ Sadykhov",
			"listPrice": 2099000,
			"lotSize": 8028,
			"sqftTotal": 3990,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860898",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 64,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1972,
			"zipCode": "95008",
			"path": "campbell-ca/705-w-valley-dr-campbell-ca-95008/pid_wdas2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b2995584-a957-4b83-8791-a57dbfb2a11b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-02 00:32:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-01 17:30:33",
			"virtualTourLink": "http://www.705wvalleydr.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.280488,
				"lng": -121.938023,
				"zipcode": "95008",
				"subPremise": "",
				"address": "705 W Valley Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.705wvalleydr.com"
		},
		{
			"closePrice": 1534000,
			"daysOnMovoto": 0,
			"id": "644011b6-1665-4fe6-9e09-911a5c02197f",
			"tnImgPath": "https://pi.movoto.com/p/102/221106150_0_ymY63f_p.jpeg",
			"listDate": null,
			"listingAgent": "Stacey Groth",
			"listPrice": 1550000,
			"lotSize": 3842,
			"sqftTotal": 1526,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221106150",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Natomas",
			"photoCount": 53,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1987,
			"zipCode": "95008",
			"path": "campbell-ca/901-kara-way-campbell-ca-95008/pid_yb6r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f9e4a26b-0337-44a0-91dd-b2485fecbbce",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-08-23 22:09:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-23 22:09:20",
			"virtualTourLink": "https://player.vimeo.com/video/590405007",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.273912,
				"lng": -121.970217,
				"zipcode": "95008",
				"subPremise": "",
				"address": "901 Kara Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1534000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/590405007"
		},
		{
			"closePrice": 329000,
			"daysOnMovoto": 184,
			"id": "cd2be8ec-24d8-4a5d-ba8e-ef0b2984175f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836548_0_IyRJyi_p.jpeg",
			"listDate": "2021-03-30 13:43:05",
			"listingAgent": "Alex Ching",
			"listPrice": 329000,
			"lotSize": null,
			"sqftTotal": 1280,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836548",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Manufactured Homes Inc",
			"photoCount": 35,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2019,
			"zipCode": "95008",
			"path": "campbell-ca/91-timber-cove-dr-campbell-ca-95008-100_ml81783866/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af4797d9-43ef-4b89-b7af-6877a4e63075",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-03-30 20:47:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-30 13:43:05",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.279534,
				"lng": -121.946572,
				"zipcode": "95008",
				"subPremise": "",
				"address": "91 Timber Cove Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 329000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1306500,
			"daysOnMovoto": 32,
			"id": "f18800b8-8ad9-402e-8ed1-c611bb2b23f8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859387_0_iuB6Er_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Julie Wyss",
			"listPrice": 1299000,
			"lotSize": 6412,
			"sqftTotal": 1669,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859387",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1956,
			"zipCode": "95008",
			"path": "campbell-ca/372-kuehnis-dr-campbell-ca-95008/pid_ld7y2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "67afde51-3463-4c8f-97fb-07f90fda53b1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-27 00:00:00",
			"createdAt": "2021-08-26 22:01:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.292281,
				"lng": -121.929301,
				"zipcode": "95008",
				"subPremise": "",
				"address": "372 Kuehnis Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1306500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 0,
			"id": "98cb40a2-eaad-46ca-864e-e687267d24a1",
			"tnImgPath": "https://pi.movoto.com/p/102/221080421_0_MZRfM2_p.jpeg",
			"listDate": null,
			"listingAgent": "Colleen Malcomson",
			"listPrice": 1685000,
			"lotSize": 6920,
			"sqftTotal": 1516,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221080421",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty1Team",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "95008",
			"path": "campbell-ca/1785-palo-santo-drive-campbell-ca-95008-100_ml89938505/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "113fb135-c4ba-4c6e-89a1-5f144b901f87",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-08-18 18:49:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 18:49:31",
			"virtualTourLink": "https://www.aryeo.com/v2/8d47af6c-427b-4e2b-9e31-c381436e94d7/videos/42036",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.28907,
				"lng": -121.98063,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1785 Palo Santo Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.aryeo.com/v2/8d47af6c-427b-4e2b-9e31-c381436e94d7/videos/42036"
		},
		{
			"closePrice": 1305000,
			"daysOnMovoto": 83,
			"id": "fea70f39-34de-4c2e-87ef-eef8559e3f5b",
			"tnImgPath": "https://pi.movoto.com/p/12/40956804_0_biBvrA_p.jpeg",
			"listDate": "2021-07-01 00:00:00",
			"listingAgent": "Elle Xu",
			"listPrice": 1349900,
			"lotSize": 1245,
			"sqftTotal": 1947,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956804",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2016,
			"zipCode": "95008",
			"path": "campbell-ca/67-braxton-terrace-campbell-ca-95008-100_ml81562259/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "54941f79-2cae-4d23-ba04-4100b6b72605",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-22 00:00:00",
			"createdAt": "2021-07-01 07:46:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-01 00:00:00",
			"virtualTourLink": "https://67BraxtonTer42651mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.282261,
				"lng": -121.948808,
				"zipcode": "95008",
				"subPremise": "",
				"address": "67 Braxton Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1305000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://67BraxtonTer42651mls.f8re.com/"
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 35,
			"id": "3cf414e3-fa21-47c6-b241-baedf18afebe",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858533_0_VEIBFi_p.jpeg",
			"listDate": "2021-08-16 00:02:10",
			"listingAgent": "Ken Yeung",
			"listPrice": 1190000,
			"lotSize": 1429,
			"sqftTotal": 1500,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858533",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty Pro",
			"photoCount": 61,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1995,
			"zipCode": "95008",
			"path": "campbell-ca/220-chalet-woods-pl-campbell-ca-95008-100_81242736/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ef4a77f0-0ab3-4110-a61b-2c25b00b6314",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-21 00:00:00",
			"createdAt": "2021-08-16 07:07:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-16 00:02:10",
			"virtualTourLink": "https://tours.victorlinphoto.com/1889014",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.281784,
				"lng": -121.934655,
				"zipcode": "95008",
				"subPremise": "",
				"address": "220 Chalet Woods Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.victorlinphoto.com/1889014"
		},
		{
			"closePrice": 1930000,
			"daysOnMovoto": 29,
			"id": "7ed3cc64-6998-466f-8295-dd5243a72574",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858940_0_MN3Ffy_p.jpeg",
			"listDate": "2021-08-18 14:37:04",
			"listingAgent": "Zaid Hanna",
			"listPrice": 1950000,
			"lotSize": 9361,
			"sqftTotal": 3244,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858940",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Real Estate 38",
			"photoCount": 29,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "95008",
			"path": "campbell-ca/938-sobrato-dr-campbell-ca-95008/pid_k74r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d8b6a5a9-d4e7-4d01-a79f-dcb02549ac18",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-17 00:00:00",
			"createdAt": "2021-08-18 21:42:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 14:37:04",
			"virtualTourLink": "https://my.matterport.com/show/?m=4eNHXgHchqW",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.273289,
				"lng": -121.959976,
				"zipcode": "95008",
				"subPremise": "",
				"address": "938 Sobrato Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=4eNHXgHchqW"
		},
		{
			"closePrice": 2175000,
			"daysOnMovoto": 32,
			"id": "20defd73-c3ad-4a3f-8b76-5cd29ac242c9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81856811_0_uMvjV2_p.jpeg",
			"listDate": "2021-08-09 00:00:00",
			"listingAgent": "Chad Hester",
			"listPrice": 2150000,
			"lotSize": 6595,
			"sqftTotal": 2651,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81856811",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1990,
			"zipCode": "95008",
			"path": "campbell-ca/683-budd-ct-campbell-ca-95008-100_80147027/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b50fae3f-aaa0-45f8-aa82-4b3a23bc7423",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-10 00:00:00",
			"createdAt": "2021-08-09 22:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-09 00:00:00",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2893436",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.278915,
				"lng": -121.962626,
				"zipcode": "95008",
				"subPremise": "",
				"address": "683 Budd Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2893436"
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 57,
			"id": "ba10f0c1-20c1-4bc7-aeba-3186e92a71ed",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853133_0_myVVNm_p.jpeg",
			"listDate": "2021-07-12 14:53:08",
			"listingAgent": "Ramiro Del Valle",
			"listPrice": 1250000,
			"lotSize": 9300,
			"sqftTotal": 1358,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853133",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Del Valle Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "95008",
			"path": "campbell-ca/440-e-mcglincy-ln-campbell-ca-95008/pid_xzas2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3e42d794-8af9-4cb1-b3dd-7d33610972ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-08 00:00:00",
			"createdAt": "2021-07-12 21:57:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 14:53:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.276819,
				"lng": -121.941912,
				"zipcode": "95008",
				"subPremise": "",
				"address": "440 E Mcglincy Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 52,
			"id": "5aca2fe8-745a-489a-a333-d1df21f44e14",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853880_0_UbyZRz_p.jpeg",
			"listDate": "2021-07-16 04:57:05",
			"listingAgent": "J. W. Lee",
			"listPrice": 1950000,
			"lotSize": 7998,
			"sqftTotal": 2808,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853880",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Cupertino",
			"photoCount": 45,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1958,
			"zipCode": "95008",
			"path": "campbell-ca/1146-w-hamilton-ave-campbell-ca-95008/pid_1kxq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c50eb599-af51-479b-b4bb-702a4f2b1e9c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-07 00:00:00",
			"createdAt": "2021-07-16 12:07:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-16 04:57:05",
			"virtualTourLink": "http://www.1146whamiltonave.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.293814,
				"lng": -121.97004,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1146 W Hamilton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.1146whamiltonave.com/"
		},
		{
			"closePrice": 1325000,
			"daysOnMovoto": 0,
			"id": "5c666344-1245-4fa0-a78a-791f728e31fd",
			"tnImgPath": "https://pi.movoto.com/p/102/221096194_0_VIfJ3Z_p.jpeg",
			"listDate": null,
			"listingAgent": "Juan Nieves",
			"listPrice": 1200000,
			"lotSize": 8468,
			"sqftTotal": 1191,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221096194",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "NewVision Realty Group",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "95008",
			"path": "campbell-ca/1115-holmes-ave-campbell-ca-95008-100_81208357/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "91f69bc0-f3ac-48b6-bb07-9a982fd405fa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-07 00:00:00",
			"createdAt": "2021-08-12 07:49:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 07:49:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.259982,
				"lng": -121.9502,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1115 Holmes Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 48,
			"id": "140222db-2699-4d8a-856b-8d1a0bc335a1",
			"tnImgPath": "https://pi.movoto.com/p/12/40958793_0_uMUMMI_p.jpeg",
			"listDate": "2021-07-15 00:00:00",
			"listingAgent": "Matthew Hutchens",
			"listPrice": 1595000,
			"lotSize": 8580,
			"sqftTotal": 1856,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958793",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1968,
			"zipCode": "95008",
			"path": "campbell-ca/2214-central-park-dr-campbell-ca-95008-12_40958793/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2de0161e-2e4f-459c-8f46-1823c7193e8f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-01 00:00:00",
			"createdAt": "2021-07-15 05:56:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.2765,
				"lng": -121.936778,
				"zipcode": "95008",
				"subPremise": "",
				"address": "2214 Central Park Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1428000,
			"daysOnMovoto": 49,
			"id": "7e377b1b-6506-40d0-a08c-73c2f4ee2f9c",
			"tnImgPath": "https://pi.movoto.com/p/12/40958003_0_yVfiRv_p.jpeg",
			"listDate": "2021-07-09 00:00:00",
			"listingAgent": "Terry Sallee",
			"listPrice": 1428000,
			"lotSize": 7509,
			"sqftTotal": 1655,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958003",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1957,
			"zipCode": "95008",
			"path": "campbell-ca/457-nottingham-way-campbell-ca-95008/pid_yzdq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d8aa3184-d99c-41b6-8559-a6aadca6f0ff",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-27 00:00:00",
			"createdAt": "2021-07-09 15:31:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-09 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=os6Bf8kaxjj&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.293593,
				"lng": -121.924689,
				"zipcode": "95008",
				"subPremise": "",
				"address": "457 Nottingham Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1428000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=os6Bf8kaxjj&mls=1"
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 16,
			"id": "b45cdb79-a58b-4d4f-be8c-6c52304f4771",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81856553_0_VQfviu_p.jpeg",
			"listDate": "2021-08-03 00:00:00",
			"listingAgent": "Nick Kehriotis",
			"listPrice": 2000000,
			"lotSize": 7906,
			"sqftTotal": 3578,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81856553",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "JMK Investments",
			"photoCount": 5,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1959,
			"zipCode": "95008",
			"path": "campbell-ca/1017-w-hamilton-ave-campbell-ca-95008/pid_irwq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "199e9354-0262-4cc4-8a82-c2fc8c78c540",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-19 00:00:00",
			"createdAt": "2021-08-03 16:42:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.294421,
				"lng": -121.967707,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1017 W Hamilton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 315000,
			"daysOnMovoto": 138,
			"id": "a4ad2d71-3ca7-4848-ac14-ed0086b9e895",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836561_0_afnUNj_p.jpeg",
			"listDate": "2021-03-30 15:01:03",
			"listingAgent": "Todd Su",
			"listPrice": 329000,
			"lotSize": null,
			"sqftTotal": 1593,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836561",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 11,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2001,
			"zipCode": "95008",
			"path": "campbell-ca/870-camden-ave-campbell-ca-95008-202_ml81584376/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10900,
			"propertyId": "fc88062c-8596-4a31-b8c8-58ed60fed086",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-16 00:00:00",
			"createdAt": "2021-03-30 22:02:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-30 15:01:03",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.272808,
				"lng": -121.949203,
				"zipcode": "95008",
				"subPremise": "",
				"address": "870 Camden Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 315000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 685000,
			"daysOnMovoto": 77,
			"id": "c56f2232-aaa2-43e0-a780-bb6c0a981b77",
			"tnImgPath": "https://pi.movoto.com/p/12/40951758_0_3uerf6_p.jpeg",
			"listDate": "2021-05-27 00:00:00",
			"listingAgent": "David Azimi",
			"listPrice": 649900,
			"lotSize": 902,
			"sqftTotal": 841,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951758",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "95008",
			"path": "campbell-ca/2863-s-bascom-ave-812-campbell-ca-95008-100_80136772/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a4f6d275-cab7-4d02-8a01-01eaae9852b3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-12 00:00:00",
			"createdAt": "2021-05-27 20:01:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.27075,
				"lng": -121.938645,
				"zipcode": "95008",
				"subPremise": "APT 812",
				"address": "2863 S Bascom Ave #812"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 685000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 42,
			"id": "91ed43f5-3916-49bc-a555-6a80658985a3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81850495_0_be7JMr_p.jpeg",
			"listDate": "2021-06-23 20:26:19",
			"listingAgent": "David Lillo",
			"listPrice": 1498000,
			"lotSize": 7841,
			"sqftTotal": 1905,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81850495",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "DPL Real Estate",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1955,
			"zipCode": "95008",
			"path": "campbell-ca/381-n-leigh-ave-campbell-ca-95008/pid_sb7y2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6be59c9f-168a-41c9-8fbe-1393fffd3700",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-05 00:00:00",
			"createdAt": "2021-06-24 03:32:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-23 20:26:19",
			"virtualTourLink": "https://www.tourfactory.com/2885671",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.29256,
				"lng": -121.922933,
				"zipcode": "95008",
				"subPremise": "",
				"address": "381 N Leigh Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2885671"
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 57,
			"id": "c54549f6-3c31-464a-8260-5ada65e833a9",
			"tnImgPath": "https://pi.movoto.com/p/12/40952382_0_RYbnFq_p.jpeg",
			"listDate": "2021-06-02 00:00:00",
			"listingAgent": "Basil Yaqub",
			"listPrice": 2395000,
			"lotSize": 8712,
			"sqftTotal": 2892,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40952382",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1944,
			"zipCode": "95008",
			"path": "campbell-ca/1280-burrows-rd-campbell-ca-95008/pid_qe6r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "69d4fd32-374c-4a78-a4d7-5e1b596a4946",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-29 00:00:00",
			"createdAt": "2021-06-02 05:21:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.268375,
				"lng": -121.972128,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1280 Burrows Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1668000,
			"daysOnMovoto": 38,
			"id": "037cdc8e-83de-46e4-97d9-e4e93e5a7b40",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81849302_0_JBBzn7_p.jpeg",
			"listDate": "2021-06-17 01:35:29",
			"listingAgent": "Phebe Hsu",
			"listPrice": 1559999,
			"lotSize": 5920,
			"sqftTotal": 2533,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81849302",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Polaris Enterprise Services Inc",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1968,
			"zipCode": "95008",
			"path": "campbell-ca/890-892-gale-dr-campbell-ca-95008-100_ml81849302/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1ce591a4-bfd1-4bc4-9215-d38ef1ab80f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-26 00:00:00",
			"createdAt": "2021-06-17 08:37:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 01:35:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.292109,
				"lng": -121.965033,
				"zipcode": "95008",
				"subPremise": "",
				"address": "890-892 Gale Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1668000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1380000,
			"daysOnMovoto": 46,
			"id": "df59ec51-218a-4b6a-913c-2dabfb3c669f",
			"tnImgPath": "https://pi.movoto.com/p/12/40953752_0_zfb6a6_p.jpeg",
			"listDate": "2021-06-10 00:00:00",
			"listingAgent": "Kevin Vandermate",
			"listPrice": 1398888,
			"lotSize": 3558,
			"sqftTotal": 2002,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953752",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1990,
			"zipCode": "95008",
			"path": "campbell-ca/235-shelley-ave-campbell-ca-95008/pid_hzcs2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6a5a944b-77f1-436d-885d-d4a8d9f3ed2d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-26 00:00:00",
			"createdAt": "2021-06-10 23:46:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2880583",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.264081,
				"lng": -121.944363,
				"zipcode": "95008",
				"subPremise": "",
				"address": "235 Shelley Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1380000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2880583"
		},
		{
			"closePrice": 1765000,
			"daysOnMovoto": 37,
			"id": "5ea2bd97-6247-40c6-9490-6a2d7d1ab37b",
			"tnImgPath": "https://pi.movoto.com/p/12/40954006_0_y3zimI_p.jpeg",
			"listDate": "2021-06-14 00:00:00",
			"listingAgent": "Erin Gonzalez",
			"listPrice": 1595000,
			"lotSize": 6000,
			"sqftTotal": 1558,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954006",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "95008",
			"path": "campbell-ca/2133-tankit-dr-campbell-ca-95008/pid_zn2r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "935ba292-7a57-4ff0-8738-ae3c2cb7b55d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-21 00:00:00",
			"createdAt": "2021-06-14 16:26:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-14 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=VMV3rJoPsy5",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.283112,
				"lng": -121.972128,
				"zipcode": "95008",
				"subPremise": "",
				"address": "2133 Tankit Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1765000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=VMV3rJoPsy5"
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 70,
			"id": "9a8a8f3c-a5d5-4768-aa92-4e1213bd9a73",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81842490_0_73mvmv_p.jpeg",
			"listDate": "2021-05-06 09:54:49",
			"listingAgent": "Mitchell Zurich",
			"listPrice": 2200000,
			"lotSize": 7884,
			"sqftTotal": 3348,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81842490",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 6,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1962,
			"zipCode": "95008",
			"path": "campbell-ca/1710-whitwood-ln-campbell-ca-95008/pid_j50r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "e9f3dcdc-9353-4353-9efe-ff07fb8c8072",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-16 00:00:00",
			"createdAt": "2021-05-06 16:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-06 09:54:49",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.284188,
				"lng": -121.979991,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1710 Whitwood Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1925000,
			"daysOnMovoto": 43,
			"id": "88c743f4-ad8e-4476-b9da-ac4137d6077d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81843377_0_bni7Uv_p.jpeg",
			"listDate": "2021-05-12 06:31:31",
			"listingAgent": "Leily Kazemi",
			"listPrice": 1899950,
			"lotSize": 8093,
			"sqftTotal": 3858,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81843377",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1967,
			"zipCode": "95008",
			"path": "campbell-ca/671-nello-dr-campbell-ca-95008/pid_gbvq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5ffdd115-f01e-4bfd-8752-d27dd5a0e8e3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-25 00:00:00",
			"createdAt": "2021-05-12 14:12:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-12 06:31:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.27717,
				"lng": -121.954821,
				"zipcode": "95008",
				"subPremise": "",
				"address": "671 Nello Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1260000,
			"daysOnMovoto": 45,
			"id": "a5950ff4-1d30-4b7d-9826-cb75240734b9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81842744_0_mMfFj7_p.jpeg",
			"listDate": "2021-05-07 10:48:14",
			"listingAgent": "Lindsay Trolan",
			"listPrice": 1240000,
			"lotSize": 7026,
			"sqftTotal": 1536,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81842744",
			"bath": null,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1952,
			"zipCode": "95008",
			"path": "campbell-ca/405-n-central-ave-campbell-ca-95008/pid_0i4y2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c55a952-fc7e-4555-877b-685545b99d78",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-22 00:00:00",
			"createdAt": "2021-05-07 17:52:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-07 10:48:14",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2864120",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.292963,
				"lng": -121.943867,
				"zipcode": "95008",
				"subPremise": "",
				"address": "405 N Central Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1260000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2864120"
		},
		{
			"closePrice": 325000,
			"daysOnMovoto": 0,
			"id": "5874f296-fad2-49e2-9231-25a302ca0a52",
			"tnImgPath": "https://pi.movoto.com/p/102/221041936_0_Ir7rEB_p.jpeg",
			"listDate": null,
			"listingAgent": "Mary Ann Kifer",
			"listPrice": 325000,
			"lotSize": 0,
			"sqftTotal": null,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221041936",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Century 21 Select Real Estate",
			"photoCount": 44,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2001,
			"zipCode": "95008",
			"path": "campbell-ca/51-timber-cv-apt-51-campbell-ca-95008-102_221041936/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b2f2227f-fbba-433a-9039-c2ae811980b8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-21 00:00:00",
			"createdAt": "2021-05-14 16:34:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-14 16:34:28",
			"virtualTourLink": "https://player.vimeo.com/video/548559772",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.27694,
				"lng": -121.94722,
				"zipcode": "95008",
				"subPremise": "APT 51",
				"address": "51 Timber Cv #51"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/548559772"
		},
		{
			"closePrice": 1525000,
			"daysOnMovoto": 0,
			"id": "fab1820f-8557-4dd0-9821-34b08ffcffad",
			"tnImgPath": "https://pi.movoto.com/p/102/221044047_0_BUnnyN_p.jpeg",
			"listDate": null,
			"listingAgent": "Susan Wylie",
			"listPrice": 1580000,
			"lotSize": 6547,
			"sqftTotal": 1322,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221044047",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Savvy Lane, Inc.",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "95008",
			"path": "campbell-ca/1715-ebbetts-dr-campbell-ca-95008/pid_ur5r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3f91b842-3c77-4964-a227-f2de95efda26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-11 00:00:00",
			"createdAt": "2021-04-30 18:49:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-30 18:49:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.273431,
				"lng": -121.980502,
				"zipcode": "95008",
				"subPremise": "",
				"address": "1715 Ebbetts Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1538000,
			"daysOnMovoto": 67,
			"id": "87ea03c5-68a3-47e6-83ba-e693ec4d158e",
			"tnImgPath": "https://pi.movoto.com/p/110/421534234_0_z7AZJI_p.jpeg",
			"listDate": "2021-04-01 00:00:00",
			"listingAgent": "Jinying Su",
			"listPrice": 1595000,
			"lotSize": 10400,
			"sqftTotal": 1404,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421534234",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens Real Estate Thrive",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "95008",
			"path": "campbell-ca/440-milton-campbell-ca-95008-110_421534234/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 100000,
			"propertyId": "26779eb4-6fe5-4235-9c75-35b7fd12806f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-07 00:00:00",
			"createdAt": "2021-04-01 21:16:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.293437,
				"lng": -121.954732,
				"zipcode": "95008",
				"subPremise": "",
				"address": "440 Milton"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1538000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1950000,
			"daysOnMovoto": 74,
			"id": "d0555ce5-1dc3-454b-bcf9-57e9765e7ee7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835124_0_fNm2qE_p.jpeg",
			"listDate": "2021-03-20 10:59:31",
			"listingAgent": "Andy Tse",
			"listPrice": 1850000,
			"lotSize": 14192,
			"sqftTotal": 3328,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835124",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 19,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1962,
			"zipCode": "95008",
			"path": "campbell-ca/925-ravenscourt-ave-campbell-ca-95008/pid_rpaq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -2150000,
			"propertyId": "b8c706ae-41f7-4a2f-9b6c-bf38a8a61f6f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-03 00:00:00",
			"createdAt": "2021-03-20 18:02:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-20 10:59:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"zipcode": "95008",
				"subPremise": "",
				"address": "925 Ravenscourt Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1910000,
			"daysOnMovoto": 41,
			"id": "649a9fbf-77a8-4382-86e8-70de80543b04",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81839318_0_A6VnjA_p.jpeg",
			"listDate": "2021-04-16 08:46:29",
			"listingAgent": "George Montanari",
			"listPrice": 1998000,
			"lotSize": 9409,
			"sqftTotal": 3837,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81839318",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 5,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1963,
			"zipCode": "95008",
			"path": "campbell-ca/117-adler-ave-campbell-ca-95008/pid_cluq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cd678614-e9e0-4b5f-974d-3be8a31d6f90",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-28 00:00:00",
			"createdAt": "2021-04-16 15:52:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-16 08:46:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Campbell",
				"lat": 37.279254,
				"lng": -121.951888,
				"zipcode": "95008",
				"subPremise": "",
				"address": "117 Adler Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1910000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]