exports.CUPERTINO_LISTINGS = [
		{
			"closePrice": 1000000,
			"daysOnMovoto": 36,
			"id": "7c790872-59f8-4689-8fe4-d4fbc78ebccf",
			"tnImgPath": "https://pi.movoto.com/p/12/40974778_0_Qy7JjR_p.jpeg",
			"listDate": "2021-11-21 00:00:00",
			"listingAgent": "Tom Hart",
			"listPrice": 945000,
			"lotSize": 1500,
			"sqftTotal": 1206,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974778",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "95014",
			"path": "cupertino-ca/20644-celeste-cir-cupertino-ca-95014/pid_qf9q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dc98f8ca-9e09-4140-a9a2-54df7c5e9b5a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-21 23:31:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.335514,
				"lng": -122.03612,
				"zipcode": "95014",
				"subPremise": "",
				"address": "20644 Celeste Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 78,
			"id": "05565524-5e45-4cb2-9f14-507c7f33dcec",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863710_0_Qu2vIi_p.jpeg",
			"listDate": "2021-09-22 10:38:45",
			"listingAgent": "Mitchell Zurich",
			"listPrice": 4250000,
			"lotSize": 10698,
			"sqftTotal": 5806,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863710",
			"bath": null,
			"bed": 11,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 24,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1969,
			"zipCode": "95014",
			"path": "cupertino-ca/10341-alpine-dr-cupertino-ca-95014-100_ml81863710/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9edf130-f7ca-4a32-ac65-a4ad3e5e07bb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-09-22 17:42:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 10:38:45",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.327677,
				"lng": -122.067987,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10341 Alpine Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4650000,
			"daysOnMovoto": 27,
			"id": "615a7806-c6a0-49ca-8e88-355a46ec10d4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869556_0_AvJ66e_p.jpeg",
			"listDate": "2021-11-08 13:05:48",
			"listingAgent": "Robert Johnston",
			"listPrice": 4598000,
			"lotSize": 8891,
			"sqftTotal": 4910,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869556",
			"bath": null,
			"bed": 10,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 39,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95014",
			"path": "cupertino-ca/19479-rosemarie-pl-cupertino-ca-95014/pid_81mr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "43112b32-90c9-46ac-9abb-48b1762a260f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-11-08 21:12:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 13:05:48",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.321198,
				"lng": -122.013484,
				"zipcode": "95014",
				"subPremise": "",
				"address": "19479 Rosemarie Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 7300000,
			"daysOnMovoto": 896,
			"id": "8617c818-88f7-4660-ba7c-c68fe1a111d0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81750465_0_AERQbM_p.jpeg",
			"listDate": "2019-05-06 12:08:29",
			"listingAgent": "Wendell Whitfield",
			"listPrice": 8200000,
			"lotSize": 55186,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81750465",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Great Western Realty",
			"photoCount": 6,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95014",
			"path": "cupertino-ca/20860-mcclellan-rd-cupertino-ca-95014/pid_72jr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -800000,
			"propertyId": "11a5dcc7-3c33-4f51-9059-ca5ec9b28ccd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2019-05-06 19:17:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2019-05-06 12:08:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.314792,
				"lng": -122.038774,
				"zipcode": "95014",
				"subPremise": "",
				"address": "20860 Mcclellan Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 3000000,
			"daysOnMovoto": 0,
			"id": "bb467c8d-775a-4af1-870a-950e464df46e",
			"tnImgPath": "https://pi.movoto.com/p/102/221102968_0_6B72YE_p.jpeg",
			"listDate": null,
			"listingAgent": "Sterling Ellison",
			"listPrice": 2999000,
			"lotSize": 8207,
			"sqftTotal": 2115,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221102968",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Excel Realty Inc.",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1968,
			"zipCode": "95014",
			"path": "cupertino-ca/no-address-cupertino-ca-95014-102_221102968/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7c56f9f7-0c6b-4308-aa17-06cf05480df7",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-10-12 00:00:00",
			"createdAt": "2021-09-10 03:24:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 03:24:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 0,
				"lng": 0,
				"zipcode": "95014",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "21909 Rucker Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1760000,
			"daysOnMovoto": 51,
			"id": "7495513d-9e80-4871-90af-e39e8d042b1f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858445_0_ZUAi6z_p.jpeg",
			"listDate": "2021-08-14 13:36:35",
			"listingAgent": "Annie Ho",
			"listPrice": 1789000,
			"lotSize": 4779,
			"sqftTotal": 1650,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858445",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Polaris Enterprise Services Inc",
			"photoCount": 14,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "95014",
			"path": "cupertino-ca/10235-university-way-cupertino-ca-95014/pid_cc9q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 30000,
			"propertyId": "79dd6624-ed18-47ee-9d0e-43097786c274",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-08-14 20:42:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 13:36:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.326177,
				"lng": -122.051868,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10235 University Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1760000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 34,
			"id": "ef791f2e-d760-470f-92ac-68adc7439ca1",
			"tnImgPath": "https://pi.movoto.com/p/12/40965039_0_iVIaZV_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Janny Rocha",
			"listPrice": 1899800,
			"lotSize": 6600,
			"sqftTotal": 1635,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965039",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rocha Realty",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "95014",
			"path": "cupertino-ca/7733-lilac-way-cupertino-ca-95014/pid_lber2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50e9d3de-a964-4bac-8b4a-157949d9fe59",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-08-28 00:26:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.312959,
				"lng": -122.042171,
				"zipcode": "95014",
				"subPremise": "",
				"address": "7733 Lilac Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2500875,
			"daysOnMovoto": 46,
			"id": "aefb13d7-8e3b-453a-8cb5-5b488305ebe8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858385_0_FiB7mZ_p.jpeg",
			"listDate": "2021-08-13 21:06:12",
			"listingAgent": "Michael Chae",
			"listPrice": 2399800,
			"lotSize": 8999,
			"sqftTotal": 3660,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858385",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "US Realty",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/10262-terry-way-cupertino-ca-95014/pid_ljgr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ae9cbd99-bd2a-436d-9336-f9baf8198a7a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-29 00:00:00",
			"createdAt": "2021-08-14 04:12:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 21:06:12",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.318989,
				"lng": -122.033512,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10262 Terry Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500875,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2610000,
			"daysOnMovoto": 57,
			"id": "4ccf3e8a-046b-4d9b-a1cf-49aa50e3f75d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81854293_0_vUBmEu_p.jpeg",
			"listDate": "2021-07-19 11:47:50",
			"listingAgent": "Roberta Murai",
			"listPrice": 2500000,
			"lotSize": 9013,
			"sqftTotal": 2190,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81854293",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 56,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "95014",
			"path": "cupertino-ca/22442-walnut-cir-cupertino-ca-95014-100_80723710/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a4200643-9562-4ec1-bc0d-6a7c4870ccd7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-15 00:00:00",
			"createdAt": "2021-07-19 18:52:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-19 11:47:50",
			"virtualTourLink": "https://tours.victorlinphoto.com/1873575",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.317577,
				"lng": -122.067802,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22442 Walnut Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.victorlinphoto.com/1873575"
		},
		{
			"closePrice": 2950000,
			"daysOnMovoto": 9,
			"id": "f38dab1c-c64d-428c-8d93-345243367a02",
			"tnImgPath": "https://pi.movoto.com/p/110/421582738_0_VF2UFn_p.jpeg",
			"listDate": "2021-08-09 00:00:00",
			"listingAgent": "Anita Wong",
			"listPrice": 2688000,
			"lotSize": 6931,
			"sqftTotal": 2238,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421582738",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1966,
			"zipCode": "95014",
			"path": "cupertino-ca/1138-stafford-dr-cupertino-ca-95014/pid_xydr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "db1a732c-e5ff-42ea-acec-6e35b7968eb3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-18 00:00:00",
			"createdAt": "2021-08-12 07:21:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-09 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=5XaWSm4YPr5",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.30509,
				"lng": -122.048209,
				"zipcode": "95014",
				"subPremise": "",
				"address": "1138 Stafford Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=5XaWSm4YPr5"
		},
		{
			"closePrice": 2470000,
			"daysOnMovoto": 16,
			"id": "83e2664b-620c-46a3-a673-722af0faead9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81852992_0_QijauI_p.jpeg",
			"listDate": "2021-07-14 00:00:00",
			"listingAgent": "Minhua Jin",
			"listPrice": 2298000,
			"lotSize": 8189,
			"sqftTotal": 2190,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81852992",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1964,
			"zipCode": "95014",
			"path": "cupertino-ca/s-foothill-blvd-cupertino-ca-95014-100_81441467/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "92b038e7-850a-479d-aa8c-0be0e35f3790",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-30 00:00:00",
			"createdAt": "2021-07-14 17:52:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/2706127",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.319365,
				"lng": -122.068265,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10250 S Foothill Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2470000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2706127"
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 111,
			"id": "d66759e6-43b9-48ce-9c7c-736628a2fcd2",
			"tnImgPath": "https://pi.movoto.com/p/12/40943033_0_fQZqUu_p.jpeg",
			"listDate": "2021-03-26 00:00:00",
			"listingAgent": "Eric Hui",
			"listPrice": 1100000,
			"lotSize": 1232,
			"sqftTotal": 1382,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40943033",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Outstanding Investment Co.",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "95014",
			"path": "cupertino-ca/10090-pasadena-ave-a3-cupertino-ca-95014/pid_0sgr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6186e2ad-5d75-4282-8384-2ac706f9cd29",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-15 00:00:00",
			"createdAt": "2021-03-26 23:01:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-26 00:00:00",
			"virtualTourLink": "Https://blueskypixs.hd.pics/10090-Pasadena-Ave-A3/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.321496,
				"lng": -122.054136,
				"zipcode": "95014",
				"subPremise": "APT A3",
				"address": "10090 Pasadena Ave #A3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://blueskypixs.hd.pics/10090-Pasadena-Ave-A3/idx"
		},
		{
			"closePrice": 2430000,
			"daysOnMovoto": 40,
			"id": "e9ca99dd-94f0-44d8-b284-50cf1c242085",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81847033_0_i3iu2F_p.jpeg",
			"listDate": "2021-06-03 08:25:22",
			"listingAgent": "Keith Suddjian",
			"listPrice": 2380000,
			"lotSize": 8856,
			"sqftTotal": 2647,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81847033",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1971,
			"zipCode": "95014",
			"path": "cupertino-ca/10335-el-prado-way-cupertino-ca-95014/pid_drbr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e2848ba6-0dc4-4310-a75a-f24d91099418",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-14 00:00:00",
			"createdAt": "2021-06-03 15:27:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-03 08:25:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.318402,
				"lng": -122.07513,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10335 El Prado Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2430000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2990000,
			"daysOnMovoto": 0,
			"id": "6fc38b8f-dcd4-4750-9049-eeba42f899a5",
			"tnImgPath": "https://pi.movoto.com/p/102/221038439_0_eIEafF_p.jpeg",
			"listDate": null,
			"listingAgent": "Susan Wylie",
			"listPrice": 2990000,
			"lotSize": 11374,
			"sqftTotal": 1920,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221038439",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Savvy Lane, Inc.",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1948,
			"zipCode": "95014",
			"path": "cupertino-ca/19028-tilson-ave-cupertino-ca-95014/pid_nwnr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 740000,
			"propertyId": "01a3f624-0bbc-4638-be11-c344e38569db",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-09 00:00:00",
			"createdAt": "2021-04-21 00:44:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-21 00:44:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.316206,
				"lng": -122.004934,
				"zipcode": "95014",
				"subPremise": "",
				"address": "19028 Tilson Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2990000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 30,
			"id": "4f22cff0-d8f9-4253-8d2c-b04f66bbaede",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81845515_0_7eIuqZ_p.jpeg",
			"listDate": "2021-05-24 12:41:45",
			"listingAgent": "Kevin Tierney",
			"listPrice": 2295000,
			"lotSize": 11186,
			"sqftTotal": 3800,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81845515",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Tierney & Company",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1963,
			"zipCode": "95014",
			"path": "cupertino-ca/19461-greenwood-dr-cupertino-ca-95014/pid_5bnr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ab90a463-6230-4fe3-a6f1-316b677ee69c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-24 00:00:00",
			"createdAt": "2021-05-24 20:00:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-24 12:41:45",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.319035,
				"lng": -122.013306,
				"zipcode": "95014",
				"subPremise": "",
				"address": "19461 Greenwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 57,
			"id": "2b641a45-6143-48df-be87-57b8e43d345a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81840911_0_qBbzMv_p.jpeg",
			"listDate": "2021-04-26 17:20:14",
			"listingAgent": "Bernadette Durand",
			"listPrice": 2200000,
			"lotSize": 10001,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81840911",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 11,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95014",
			"path": "cupertino-ca/0-bellevue-ave-cupertino-ca-95014-100_ml81840911/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b2556d67-46f2-43a6-827d-922359a2a1c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-23 00:00:00",
			"createdAt": "2021-04-27 00:22:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-26 17:20:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"zipcode": "95014",
				"subPremise": "",
				"address": "0 Bellevue Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 0,
			"id": "6549ad19-e3ee-4787-b2d9-e7042dc820cd",
			"tnImgPath": "https://pi.movoto.com/p/102/221037226_0_zeVvFR_p.jpeg",
			"listDate": null,
			"listingAgent": "Daniel Soldano",
			"listPrice": 949000,
			"lotSize": 1599,
			"sqftTotal": 1062,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221037226",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Anchor Real Estate Company",
			"photoCount": 60,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1973,
			"zipCode": "95014",
			"path": "cupertino-ca/10929-northshore-cupertino-ca-95014-102_221037226/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "43d2dd0c-d8bb-4563-96f1-16edaac0305f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-07 00:00:00",
			"createdAt": "2021-04-18 19:29:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-18 19:29:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.336852,
				"lng": -122.025619,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10929 Northshore"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 55,
			"id": "9b0e57f6-d014-41f1-a7a0-e626db9744fe",
			"tnImgPath": "https://pi.movoto.com/p/12/40944766_0_qUEBnb_p.jpeg",
			"listDate": "2021-04-08 00:00:00",
			"listingAgent": "Bob Jamison",
			"listPrice": 1200000,
			"lotSize": 1590,
			"sqftTotal": 1413,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40944766",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Tuscana Properties",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1972,
			"zipCode": "95014",
			"path": "cupertino-ca/22686-royal-oak-way-cupertino-ca-95014-100_80642838/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -299000,
			"propertyId": "b1927dbe-872a-49e3-b83a-2e691dd2be05",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-02 00:00:00",
			"createdAt": "2021-04-09 00:01:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-08 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=1AES24P1jiF&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.323574,
				"lng": -122.071869,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22686 Royal Oak Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=1AES24P1jiF&mls=1"
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 71,
			"id": "1e60853e-5a7b-4ee0-b8c9-deae17c25672",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834306_0_2UYRqu_p.jpeg",
			"listDate": "2021-03-16 10:33:18",
			"listingAgent": "Kraig Constantino",
			"listPrice": 1974800,
			"lotSize": 8442,
			"sqftTotal": 2255,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834306",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1965,
			"zipCode": "95014",
			"path": "cupertino-ca/21115-gardena-dr-7-cupertino-ca-95014-100_ml81834306/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "479fd0cc-3910-4150-9d6a-4122c7d0b5a6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-27 00:00:00",
			"createdAt": "2021-03-16 18:12:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-16 10:33:18",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.333728,
				"lng": -122.043794,
				"zipcode": "95014",
				"subPremise": "# -7",
				"address": "21115 Gardena Dr # -7"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1610000,
			"daysOnMovoto": 0,
			"id": "e243c4d0-8d03-48b0-99f5-bf9a34d52534",
			"tnImgPath": "https://pi.movoto.com/p/102/20080835_0_RVjRyB_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Minerick",
			"listPrice": 1698000,
			"lotSize": 1350,
			"sqftTotal": 1709,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "20080835",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "HomeCoin.com",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2003,
			"zipCode": "95014",
			"path": "cupertino-ca/10156-imperial-ave-cupertino-ca-95014-100_ml81717823/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d3a26aa8-dc92-4a92-8c96-3b4d2c83e8d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-26 00:00:00",
			"createdAt": "2021-01-26 16:39:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-26 16:39:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.320244,
				"lng": -122.052232,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10156 Imperial Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3425000,
			"daysOnMovoto": 28,
			"id": "ad671e97-af0e-43a4-8f27-9f68ce7689ba",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-20 00:00:00",
			"listingAgent": "Mary Tan",
			"listPrice": 2888000,
			"lotSize": 7721,
			"sqftTotal": 2614,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81839857",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/11053-bel-aire-ct-cupertino-ca-95014/pid_7fer2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "71f94550-09e3-415b-9521-5409cec4eee0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-04-23 07:49:46",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-20 00:00:00",
			"virtualTourLink": "Http://mtrthome.com/11053belaire-unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.308052,
				"lng": -122.057932,
				"zipcode": "95014",
				"subPremise": "",
				"address": "11053 Bel Aire Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://mtrthome.com/11053belaire-unbranded"
		},
		{
			"closePrice": 1220000,
			"daysOnMovoto": 39,
			"id": "d98c72db-b1c5-407e-92bc-c748a1d7652d",
			"tnImgPath": "https://pi.movoto.com/p/12/ML81838243_0_U2Rirm_p.jpeg",
			"listDate": "2021-04-09 00:00:00",
			"listingAgent": "Alex Liu",
			"listPrice": 1138000,
			"lotSize": null,
			"sqftTotal": 1331,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81838243",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "H&T Realty Inc",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2008,
			"zipCode": "95014",
			"path": "cupertino-ca/10745-n-de-anza-blvd-324-cupertino-ca-95014/pid_bf5q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "febbb3b7-63a3-478b-8714-f63b712f686c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-05-19 12:03:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.333271,
				"lng": -122.03313,
				"zipcode": "95014",
				"subPremise": "APT 324",
				"address": "10745 N De Anza Blvd #324"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2401000,
			"daysOnMovoto": 33,
			"id": "23b4ab65-655c-47ff-9ab6-790917091741",
			"tnImgPath": "https://pi.movoto.com/p/12/ML81838905_0_aM3u3A_p.jpeg",
			"listDate": "2021-04-14 00:00:00",
			"listingAgent": "Mary Tan",
			"listPrice": 1998000,
			"lotSize": 7625,
			"sqftTotal": 1518,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81838905",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/10410-n-blaney-ave-cupertino-ca-95014-100_80012359/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8d85e83a-9f56-479c-8e5f-c7716018cc49",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-04-23 07:48:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-14 00:00:00",
			"virtualTourLink": "Http://mtrthome.com/10410blaney-unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.328836,
				"lng": -122.023052,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10410 N Blaney Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2401000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://mtrthome.com/10410blaney-unbranded"
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 40,
			"id": "ba0f725d-2ca9-4f3c-a96e-fe8e6006b717",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-07 00:00:00",
			"listingAgent": "Rabia Alizai",
			"listPrice": 1999000,
			"lotSize": 6152,
			"sqftTotal": 1552,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837775",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/7894-fiesta-ln-cupertino-ca-95014/pid_begr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e92d3aa5-3233-4df4-93f0-703143215aa0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-19 05:00:20",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-07 00:00:00",
			"virtualTourLink": "Http://www.7894fiestaln.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.310782,
				"lng": -122.046522,
				"zipcode": "95014",
				"subPremise": "",
				"address": "7894 Fiesta Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.7894fiestaln.com/?mls"
		},
		{
			"closePrice": 2275000,
			"daysOnMovoto": 101,
			"id": "c53951cf-bb9d-4c68-8be6-ead20e31ffd0",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-02-05 00:00:00",
			"listingAgent": "Katherine Chen",
			"listPrice": 2450000,
			"lotSize": 8411,
			"sqftTotal": 2131,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81828558",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Advante Real Estate",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "95014",
			"path": "cupertino-ca/20395-clifden-way-cupertino-ca-95014-100_80318595/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dbece0b8-48fc-4260-8376-a1c0221eece9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-02 04:30:13",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-02-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.312345,
				"lng": -122.030897,
				"zipcode": "95014",
				"subPremise": "",
				"address": "20395 Clifden Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1346400,
			"daysOnMovoto": 85,
			"id": "3c201a26-2789-4d80-b17e-c5c230f6cee6",
			"tnImgPath": "https://pi.movoto.com/p/12/40938031_0_uYI3bB_p.jpeg",
			"listDate": "2021-02-17 00:00:00",
			"listingAgent": "Emon Komeily",
			"listPrice": 1398000,
			"lotSize": 1294,
			"sqftTotal": 1515,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938031",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2008,
			"zipCode": "95014",
			"path": "cupertino-ca/10745-n-de-anza-blvd-323-cupertino-ca-95014/pid_1e5q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -42000,
			"propertyId": "66187c48-083a-4482-abe0-377820cd23c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-13 00:00:00",
			"createdAt": "2021-02-17 13:11:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.333199,
				"lng": -122.032805,
				"zipcode": "95014",
				"subPremise": "APT 323",
				"address": "10745 N De Anza Blvd #323"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1346400,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3005000,
			"daysOnMovoto": 0,
			"id": "91214b35-e67e-456a-8900-baa57971f306",
			"tnImgPath": "https://pi.movoto.com/p/102/221024603_0_yfA232_p.jpeg",
			"listDate": null,
			"listingAgent": "Flaviu Budean",
			"listPrice": 2888000,
			"lotSize": 12529,
			"sqftTotal": 2133,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221024603",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "FAB Realty",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1979,
			"zipCode": "95014",
			"path": "cupertino-ca/22433-santa-paula-ave-cupertino-ca-95014/pid_ytjr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "249ccc93-4ba8-4c4f-9505-d34b9b22173c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-10 00:00:00",
			"createdAt": "2021-03-26 16:59:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-26 16:59:41",
			"virtualTourLink": "Https://travis-m-turner-photography.seehouseat.com/1802399?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.316074,
				"lng": -122.067365,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22433 Santa Paula Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3005000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://travis-m-turner-photography.seehouseat.com/1802399?idx=1"
		},
		{
			"closePrice": 2385000,
			"daysOnMovoto": 41,
			"id": "98d99349-0593-4979-9c2d-5d44a7daca0e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835283_0_QJRaAa_p.jpeg",
			"listDate": "2021-03-22 12:35:34",
			"listingAgent": "Manu Changotra",
			"listPrice": 2350000,
			"lotSize": 8878,
			"sqftTotal": 2573,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835283",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 49,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1963,
			"zipCode": "95014",
			"path": "cupertino-ca/22606-voss-ave-cupertino-ca-95014/pid_75ar2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1ab43ca8-5ae9-4f97-be6e-776156bb129e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-03 00:00:00",
			"createdAt": "2021-03-22 19:42:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-22 12:35:34",
			"virtualTourLink": "Http://www.22606voss.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.31765,
				"lng": -122.070539,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22606 Voss Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2385000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.22606voss.com"
		},
		{
			"closePrice": 3888000,
			"daysOnMovoto": 15,
			"id": "9948282b-a852-4c78-b04f-aa67c7c1215a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81838534_0_Jj7Rnb_p.jpeg",
			"listDate": "2021-04-12 08:07:00",
			"listingAgent": "Steve Zirelli",
			"listPrice": 3798000,
			"lotSize": 13112,
			"sqftTotal": 5865,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81838534",
			"bath": null,
			"bed": 9,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 14,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1978,
			"zipCode": "95014",
			"path": "cupertino-ca/10270-alpine-dr-cupertino-ca-95014/pid_l44q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b782efd8-fbaa-434e-892d-f0b4ef777720",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-28 00:00:00",
			"createdAt": "2021-04-12 15:12:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-12 08:07:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.327111,
				"lng": -122.066457,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10270 Alpine Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3888000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 62,
			"id": "4eace21c-14da-4cdf-bbcd-84525fc56875",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830942_0_aY6mBv_p.jpeg",
			"listDate": "2021-02-23 07:07:29",
			"listingAgent": "Patrick Conkin",
			"listPrice": 1899000,
			"lotSize": 6991,
			"sqftTotal": 2082,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830942",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/959-miller-ave-cupertino-ca-95014/pid_jrsz2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea9e3c0a-a222-4ab0-9097-ae87e015ff7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-27 00:00:00",
			"createdAt": "2021-02-23 15:12:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 07:07:29",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2832238",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.311287,
				"lng": -122.013839,
				"zipcode": "95014",
				"subPremise": "",
				"address": "959 Miller Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2832238"
		},
		{
			"closePrice": 1230000,
			"daysOnMovoto": 0,
			"id": "2ef4f85d-66a3-47db-8530-bd9d26abf4b6",
			"tnImgPath": "https://pi.movoto.com/p/102/221016794_0_Vn27vb_p.jpeg",
			"listDate": null,
			"listingAgent": "Victoria Timpe",
			"listPrice": 1199888,
			"lotSize": 2108,
			"sqftTotal": 1330,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221016794",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Realty One Group Complete",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1977,
			"zipCode": "95014",
			"path": "cupertino-ca/10134-congress-pl-cupertino-ca-95014/pid_6p7q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6d653793-151c-4c62-8d84-583ab38f5458",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-20 00:00:00",
			"createdAt": "2021-03-12 08:14:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-12 08:14:45",
			"virtualTourLink": "Https://www.365virtualtour.com/10134-congress-pl",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.324923,
				"lng": -122.043592,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10134 Congress Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1230000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.365virtualtour.com/10134-congress-pl"
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 18,
			"id": "6b84ee46-079c-443c-a1bb-abbaf1b4a603",
			"tnImgPath": "https://pi.movoto.com/p/110/421534315_0_iZbYBV_p.jpeg",
			"listDate": "2021-04-01 00:00:00",
			"listingAgent": "Andrew de Vries",
			"listPrice": 1998000,
			"lotSize": 7058,
			"sqftTotal": 1728,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421534315",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway-Franciscan",
			"photoCount": 54,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "95014",
			"path": "cupertino-ca/908-sage-ct-cupertino-ca-95014/pid_cjfr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d84a66ca-e127-4158-9a03-7f3391c0237b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-19 00:00:00",
			"createdAt": "2021-04-02 00:01:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-01 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=YrA311UYd6W",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.312184,
				"lng": -122.042126,
				"zipcode": "95014",
				"subPremise": "",
				"address": "908 Sage Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=YrA311UYd6W"
		},
		{
			"closePrice": 820000,
			"daysOnMovoto": 57,
			"id": "f853ac70-ce66-4a03-acf6-f4bff30b8f47",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830203_0_22bJ6n_p.jpeg",
			"listDate": "2021-02-17 14:13:23",
			"listingAgent": "Vanessa Bermudez",
			"listPrice": 849000,
			"lotSize": 1198,
			"sqftTotal": 871,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830203",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "95014",
			"path": "cupertino-ca/20675-celeste-cir-cupertino-ca-95014/pid_3w9q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81147e21-ecb1-4f9b-aabb-08f95d3e60da",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-02-17 22:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 14:13:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.335455,
				"lng": -122.034991,
				"zipcode": "95014",
				"subPremise": "",
				"address": "20675 Celeste Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 841000,
			"daysOnMovoto": 50,
			"id": "d5a5a2ab-a0c1-4ffb-a185-7f7cbb943e61",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830774_0_2MRN6z_p.jpeg",
			"listDate": "2021-02-21 17:27:35",
			"listingAgent": "Roberta Murai",
			"listPrice": 838000,
			"lotSize": null,
			"sqftTotal": 850,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830774",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2003,
			"zipCode": "95014",
			"path": "cupertino-ca/20488-stevens-creek-blvd-1809-cupertino-ca-95014/pid_ljkr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5ad47ed7-421f-4ffa-b39d-464d799eaaaa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-02-22 01:32:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-21 17:27:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.322594,
				"lng": -122.030892,
				"zipcode": "95014",
				"subPremise": "APT 1809",
				"address": "20488 Stevens Creek Blvd #1809"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 841000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 29,
			"id": "772e5e4b-b471-4cff-9316-69c4d4320d98",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833478_0_Z62jqi_p.jpeg",
			"listDate": "2021-03-10 00:00:00",
			"listingAgent": "Scott Raley",
			"listPrice": 2485000,
			"lotSize": 8999,
			"sqftTotal": 2492,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833478",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1968,
			"zipCode": "95014",
			"path": "cupertino-ca/10173-riedel-pl-cupertino-ca-95014/pid_w32q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3875b0b3-7d33-4e3e-b240-4596830b6482",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-11 00:17:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-10 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2847132",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.325355,
				"lng": -122.021744,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10173 Riedel Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2847132"
		},
		{
			"closePrice": 3930000,
			"daysOnMovoto": 33,
			"id": "18c1dd40-2516-4e8d-9fb8-fd65a5e5c149",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832637_0_ErfQar_p.jpeg",
			"listDate": "2021-03-05 05:13:48",
			"listingAgent": "Coco Tan",
			"listPrice": 3499888,
			"lotSize": 17459,
			"sqftTotal": 3247,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832637",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty-Silicon Valley",
			"photoCount": 83,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2014,
			"zipCode": "95014",
			"path": "cupertino-ca/10067-byrne-ave-cupertino-ca-95014-100_81226212/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f160b9cf-3ea4-45d7-a019-9ae07092107e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-05 13:17:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 05:13:48",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.321834,
				"lng": -122.05888,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10067 Byrne Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2780000,
			"daysOnMovoto": 47,
			"id": "3af22796-6724-489a-ae68-ac42390a318f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830470_0_vueRRz_p.jpeg",
			"listDate": "2021-02-19 00:15:18",
			"listingAgent": "Yu He",
			"listPrice": 2495000,
			"lotSize": 11617,
			"sqftTotal": 1688,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830470",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Safebay Inc.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "95014",
			"path": "cupertino-ca/10250-lockwood-dr-cupertino-ca-95014-100_80911044/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "20c9da3f-81fc-4ed7-b3cf-83f73d55493d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-02-19 08:17:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 00:15:18",
			"virtualTourLink": "Http://www.edigitaltours.com/21/02/27458/unbranded.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.319713,
				"lng": -122.071956,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10250 Lockwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.edigitaltours.com/21/02/27458/unbranded.html"
		},
		{
			"closePrice": 1220000,
			"daysOnMovoto": 116,
			"id": "ed50443e-2df8-4ca3-b3a2-013de27b9671",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81821634_0_7qfeQN_p.jpeg",
			"listDate": "2020-12-11 00:00:00",
			"listingAgent": "Yitzchak Ehrlich",
			"listPrice": 1288000,
			"lotSize": null,
			"sqftTotal": 1116,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81821634",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Le Real Estate",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95014",
			"path": "cupertino-ca/20500-town-center-ln-197-cupertino-ca-95014/pid_dwkr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b50f5bb4-bdee-4fd8-b724-904cf79e0f9d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2020-12-11 11:32:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.31796,
				"lng": -122.03145,
				"zipcode": "95014",
				"subPremise": "APT 197",
				"address": "20500 Town Center Ln #197"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 44,
			"id": "13930d72-bfeb-4c28-974d-be328ba40593",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830535_0_qn3JFV_p.jpeg",
			"listDate": "2021-02-19 10:55:42",
			"listingAgent": "Steven Zhou",
			"listPrice": 1398000,
			"lotSize": 1490,
			"sqftTotal": 1445,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830535",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "BQ Group Inc",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1977,
			"zipCode": "95014",
			"path": "cupertino-ca/11097-flowering-pear-dr-cupertino-ca-95014-100_81463035/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af829d78-32b0-4723-9752-a9b1d5b06bfa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-02-19 20:42:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 10:55:42",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.338994,
				"lng": -122.034632,
				"zipcode": "95014",
				"subPremise": "",
				"address": "11097 Flowering Pear Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2655000,
			"daysOnMovoto": 49,
			"id": "8d5e0038-4aad-45ca-995a-b82f03108d3f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829483_0_Iab3Mz_p.jpeg",
			"listDate": "2021-02-11 15:57:31",
			"listingAgent": "Aaron Derbacher",
			"listPrice": 1988000,
			"lotSize": 16880,
			"sqftTotal": 2241,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829483",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1967,
			"zipCode": "95014",
			"path": "cupertino-ca/22315-carta-blanca-st-cupertino-ca-95014/pid_jp8q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -810000,
			"propertyId": "2735f071-9b65-4fe0-bb91-9e10c472e28e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-12 00:07:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 15:57:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.329795,
				"lng": -122.065945,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22315 Carta Blanca St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2655000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3007000,
			"daysOnMovoto": 26,
			"id": "789e21ce-a20a-4308-913e-e9f1c5856487",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832412_0_rrmv37_p.jpeg",
			"listDate": "2021-03-03 22:10:34",
			"listingAgent": "Moe Baniani",
			"listPrice": 2448000,
			"lotSize": 7588,
			"sqftTotal": 2070,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832412",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/19866-pear-tree-ln-cupertino-ca-95014/pid_gozq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "16be2fdb-16e3-4706-bcd6-08c46b9b41d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-30 00:00:00",
			"createdAt": "2021-03-04 06:17:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 22:10:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.328006,
				"lng": -122.020815,
				"zipcode": "95014",
				"subPremise": "",
				"address": "19866 Pear Tree Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3007000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2560000,
			"daysOnMovoto": 29,
			"id": "63df0133-6f50-4ebf-ae84-7d91bf59c05e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831797_0_ARqUZJ_p.jpeg",
			"listDate": "2021-02-28 12:35:51",
			"listingAgent": "Jeffrey Larson",
			"listPrice": 1899888,
			"lotSize": 7383,
			"sqftTotal": 1374,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831797",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1959,
			"zipCode": "95014",
			"path": "cupertino-ca/10264-richwood-dr-cupertino-ca-95014/pid_jpir2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "00aa0176-d18f-4542-92ea-c6528ebcc28a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-30 00:00:00",
			"createdAt": "2021-02-28 20:42:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-28 12:35:51",
			"virtualTourLink": "https://player.vimeo.com/video/517796328",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.31959,
				"lng": -122.016948,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10264 Richwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/517796328"
		},
		{
			"closePrice": 2180000,
			"daysOnMovoto": 56,
			"id": "a92789e1-cd34-4d0a-93b2-402bc3685867",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828063_0_2Vvfiy_p.jpeg",
			"listDate": "2021-02-01 17:37:07",
			"listingAgent": "Coco Tan",
			"listPrice": 1799888,
			"lotSize": 10045,
			"sqftTotal": 1400,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828063",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty-Silicon Valley",
			"photoCount": 34,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1958,
			"zipCode": "95014",
			"path": "cupertino-ca/22632-alcalde-rd-cupertino-ca-95014-100_80393294/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "25c03814-9bf0-4ff2-8aff-089cf79e9138",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-30 00:00:00",
			"createdAt": "2021-02-02 01:42:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 17:37:07",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.31543,
				"lng": -122.070639,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22632 Alcalde Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2105000,
			"daysOnMovoto": 0,
			"id": "6f91f9cd-7c56-4c6e-b113-b3aa290199bc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836478_0_yzMA2y_p.jpeg",
			"listDate": "2021-03-29 21:56:24",
			"listingAgent": "Anson Ip",
			"listPrice": 1890000,
			"lotSize": 13595,
			"sqftTotal": 1544,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836478",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1949,
			"zipCode": "95014",
			"path": "cupertino-ca/19160-cozette-ln-cupertino-ca-95014/pid_ejnr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cb413ba1-b087-4579-ba9b-58cfb556d0da",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-29 00:00:00",
			"createdAt": "2021-03-30 05:02:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-29 21:56:24",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.320322,
				"lng": -122.007666,
				"zipcode": "95014",
				"subPremise": "",
				"address": "19160 Cozette Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2105000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2781000,
			"daysOnMovoto": 37,
			"id": "015dcd07-4bc7-4a0c-ac94-e053169a0569",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829957_0_jV7Ynr_p.jpeg",
			"listDate": "2021-02-16 00:11:03",
			"listingAgent": "David Lillo",
			"listPrice": 2498000,
			"lotSize": 7139,
			"sqftTotal": 1910,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829957",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "DPL Real Estate",
			"photoCount": 45,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1962,
			"zipCode": "95014",
			"path": "cupertino-ca/22434-saint-andrews-ave-cupertino-ca-95014/pid_5pbr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "931317e7-1a46-400a-b49e-fdef4971b215",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2021-02-16 08:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-16 00:11:03",
			"virtualTourLink": "https://www.tourfactory.com/2839818",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.312863,
				"lng": -122.067743,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22434 Saint Andrews Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2781000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2839818"
		},
		{
			"closePrice": 973000,
			"daysOnMovoto": 50,
			"id": "9a53db53-eff8-4760-ac17-2c4a5b3a9069",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828244_0_q2fmfq_p.jpeg",
			"listDate": "2021-02-03 00:02:37",
			"listingAgent": "David Lillo",
			"listPrice": 980000,
			"lotSize": 771,
			"sqftTotal": 1003,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828244",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "DPL Real Estate",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "95014",
			"path": "cupertino-ca/21063-red-fir-ct-cupertino-ca-95014-100_80139499/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8711e35b-fdc7-4752-afdf-9c1a1f875e35",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-26 00:00:00",
			"createdAt": "2021-02-03 08:07:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-03 00:02:37",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2835114",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.315395,
				"lng": -122.042485,
				"zipcode": "95014",
				"subPremise": "",
				"address": "21063 Red Fir Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 973000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2835114"
		},
		{
			"closePrice": 4250000,
			"daysOnMovoto": 16,
			"id": "5d07a1bc-e2d7-42e5-b748-ad0b908b6cf3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832661_0_rua3zf_p.jpeg",
			"listDate": "2021-03-05 09:28:13",
			"listingAgent": "Edward Graziani",
			"listPrice": 4199000,
			"lotSize": 38285,
			"sqftTotal": 4290,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832661",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1999,
			"zipCode": "95014",
			"path": "cupertino-ca/22209-hammond-way-cupertino-ca-95014-100_80518387/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4f84fa0c-575f-44de-bd03-ac6e9ab7d2ec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-03-05 17:32:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 09:28:13",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2836738",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.331509,
				"lng": -122.080401,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22209 Hammond Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2836738"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 30,
			"id": "19fe0b8a-8861-41e2-a7e2-80096b5efa06",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830471_0_qJaabq_p.jpeg",
			"listDate": "2021-02-19 00:20:57",
			"listingAgent": "Masako Takemura",
			"listPrice": 1249000,
			"lotSize": 2069,
			"sqftTotal": 1199,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830471",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1990,
			"zipCode": "95014",
			"path": "cupertino-ca/10140-pasadena-ave-cupertino-ca-95014/pid_u4gr2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cb5b7ddf-42c5-42af-a40d-c7ce5738dd36",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-02-19 08:27:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 00:20:57",
			"virtualTourLink": "Https://www.cbhometour.com/10140-Pasadena-Avenue-Cupertino-CA-95014/mlsindex.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.320766,
				"lng": -122.054293,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10140 Pasadena Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.cbhometour.com/10140-Pasadena-Avenue-Cupertino-CA-95014/mlsindex.html"
		},
		{
			"closePrice": 3900000,
			"daysOnMovoto": 36,
			"id": "b6b93d73-05c9-4b5c-a9d2-3357f6991f6b",
			"tnImgPath": "https://pi.movoto.com/p/202/FR21028699_0_FYi77B_p.jpeg",
			"listDate": "2021-02-11 00:00:00",
			"listingAgent": "Brandon Yager",
			"listPrice": 3888000,
			"lotSize": 18892,
			"sqftTotal": 3786,
			"mlsDbNumber": 202,
			"mls": {
				"dateHidden": null,
				"id": 202
			},
			"mlsNumber": "FR21028699",
			"bath": 5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Yager Realty Of California",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family Residential",
			"yearBuilt": 2001,
			"zipCode": "95014",
			"path": "cupertino-ca/10547-manzanita-ct-cupertino-ca-95014-100_80134846/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "de903670-89c9-4213-9f18-7293483d1754",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-11 21:17:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=JiThLHqRe1H",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.330725,
				"lng": -122.075591,
				"zipcode": "95014",
				"subPremise": "",
				"address": "10547 Manzanita Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=JiThLHqRe1H"
		},
		{
			"closePrice": 1830000,
			"daysOnMovoto": 36,
			"id": "39422304-50bf-4e3f-94e5-7282be5f8779",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829219_0_YUBe2B_p.jpeg",
			"listDate": "2021-02-10 11:17:28",
			"listingAgent": "Mary Clark",
			"listPrice": 1698000,
			"lotSize": 1721,
			"sqftTotal": 1727,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829219",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 68,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1973,
			"zipCode": "95014",
			"path": "cupertino-ca/22806-poplar-grove-sq-cupertino-ca-95014-100_80537352/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a2ff68c9-4359-44ab-ab6a-0f5af2afdd6c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-10 19:22:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-10 11:17:28",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2831364",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Cupertino",
				"lat": 37.320787,
				"lng": -122.074528,
				"zipcode": "95014",
				"subPremise": "",
				"address": "22806 Poplar Grove Sq"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2831364"
		}
	]