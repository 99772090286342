exports.SAN_FRANCISCO_SCHOOLS = [{'address': {'city': 'San Francisco',
                                      'street1': '117 Diamond Street',
                                      'street2': 'Bldgs. 4&8',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 25492,
                          'lat': 37.760246,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25492-German-International-School-Of-Silicon-Valley---San-Francisco-Campus/#College_success',
                              'profile': '/california/san-francisco/25492-German-International-School-Of-Silicon-Valley---San-Francisco-Campus/',
                              'reviews': '/california/san-francisco/25492-German-International-School-Of-Silicon-Valley---San-Francisco-Campus/#Reviews'},
                          'lon': -122.436905,
                          'name': 'German International School of Silicon Valley - San Francisco Campus',
                          'numReviews': 63,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1270 Sanchez Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27243,
                          'lat': 37.748898,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27243-Moldovan-Academy/#College_success',
                              'profile': '/california/san-francisco/27243-Moldovan-Academy/',
                              'reviews': '/california/san-francisco/27243-Moldovan-Academy/#Reviews'},
                          'lon': -122.42968,
                          'name': 'Moldovan Academy',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4150 Clement Street, Bldg 32',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27256,
                          'lat': 37.782185,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27256-Cheryl-Andersen-Sorensen-Childcare-Center/#College_success',
                              'profile': '/california/san-francisco/27256-Cheryl-Andersen-Sorensen-Childcare-Center/',
                              'reviews': '/california/san-francisco/27256-Cheryl-Andersen-Sorensen-Childcare-Center/#Reviews'},
                          'lon': -122.504211,
                          'name': 'Cheryl Andersen-Sorensen Childcare Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '660 Filbert Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27263,
                          'lat': 37.80135,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27263-The-Laura-Vicuna-Pre-Kindergarten-Of-Sts-Peter-And-Paul-School/#College_success',
                              'profile': '/california/san-francisco/27263-The-Laura-Vicuna-Pre-Kindergarten-Of-Sts-Peter-And-Paul-School/',
                              'reviews': '/california/san-francisco/27263-The-Laura-Vicuna-Pre-Kindergarten-Of-Sts-Peter-And-Paul-School/#Reviews'},
                          'lon': -122.410362,
                          'name': 'The Laura Vicuna Pre-Kindergarten of Sts Peter and Paul School',
                          'numReviews': 4,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '200 Kansas Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27274,
                          'lat': 37.766731,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27274-Bright-Horizons-At-Kansas-Street/#College_success',
                              'profile': '/california/san-francisco/27274-Bright-Horizons-At-Kansas-Street/',
                              'reviews': '/california/san-francisco/27274-Bright-Horizons-At-Kansas-Street/#Reviews'},
                          'lon': -122.404213,
                          'name': 'Bright Horizons at Kansas Street',
                          'numReviews': 7,
                          'parentRating': 2,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1195 Hudson Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '79%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 403,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 94,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'African American',
                                             'percentage': 25,
                                             'rating': 10},
                                            {'label': 'Hispanic',
                                             'percentage': 66,
                                             'rating': 9},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 2},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Two or more races', 'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 27281,
                          'lat': 37.732376,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27281-KIPP-San-Francisco-College-Preparatory/#College_success',
                              'profile': '/california/san-francisco/27281-KIPP-San-Francisco-College-Preparatory/',
                              'reviews': '/california/san-francisco/27281-KIPP-San-Francisco-College-Preparatory/#Reviews'},
                          'lon': -122.382347,
                          'name': 'KIPP San Francisco College Preparatory',
                          'numReviews': 5,
                          'parentRating': 5,
                          'percentLowIncome': '94%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'College Readiness Rating': 4,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1101 Masonic Ave',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27283,
                          'lat': 37.770889,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/27283-FACES-SF/#College_success',
                                    'profile': '/california/san-francisco/27283-FACES-SF/',
                                    'reviews': '/california/san-francisco/27283-FACES-SF/#Reviews'},
                          'lon': -122.445869,
                          'name': 'FACES SF',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '100 Whitney Young Circle',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27284,
                          'lat': 37.734062,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27284-FACES-SF-Bayview/#College_success',
                              'profile': '/california/san-francisco/27284-FACES-SF-Bayview/',
                              'reviews': '/california/san-francisco/27284-FACES-SF-Bayview/#Reviews'},
                          'lon': -122.38414,
                          'name': 'FACES SF Bayview',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1245 Folsom Street',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-9',
                          'highlighted': false,
                          'id': 27287,
                          'lat': 37.774181,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27287-AltSchool---SOMA/#College_success',
                              'profile': '/california/san-francisco/27287-AltSchool---SOMA/',
                              'reviews': '/california/san-francisco/27287-AltSchool---SOMA/#Reviews'},
                          'lon': -122.410484,
                          'name': 'AltSchool - SOMA',
                          'numReviews': 8,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '855 27th ave',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 31695,
                          'lat': 37.764324,
                          'levelCode': 'm',
                          'links': {'collegeSuccess': '/california/san-francisco/31695-My-City-School/#College_success',
                                    'profile': '/california/san-francisco/31695-My-City-School/',
                                    'reviews': '/california/san-francisco/31695-My-City-School/#Reviews'},
                          'lon': -122.438408,
                          'name': 'My City School',
                          'numReviews': 15,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1227 Hampshire Street #43',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27415,
                          'lat': 37.752342,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27415-Valdivia-Family-Day-Care/#College_success',
                              'profile': '/california/san-francisco/27415-Valdivia-Family-Day-Care/',
                              'reviews': '/california/san-francisco/27415-Valdivia-Family-Day-Care/#Reviews'},
                          'lon': -122.407234,
                          'name': 'Valdivia Family Day Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '20 Cook St Fl S',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27444,
                          'lat': 37.782288,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27444-Kape-Kennedy-Age-Chlrdns-Center/#College_success',
                              'profile': '/california/san-francisco/27444-Kape-Kennedy-Age-Chlrdns-Center/',
                              'reviews': '/california/san-francisco/27444-Kape-Kennedy-Age-Chlrdns-Center/#Reviews'},
                          'lon': -122.463707,
                          'name': 'Kape Kennedy Age Chlrdns Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1325 18th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 27445,
                          'lat': 37.76305,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27445-Jefferson-School-Age/#College_success',
                              'profile': '/california/san-francisco/27445-Jefferson-School-Age/',
                              'reviews': '/california/san-francisco/27445-Jefferson-School-Age/#Reviews'},
                          'lon': -122.476051,
                          'name': 'Jefferson School Age',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3125 Cesar Chavez Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27446,
                          'lat': 37.748234,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27446-Leonard-Flynn-Chldrns-Center/#College_success',
                              'profile': '/california/san-francisco/27446-Leonard-Flynn-Chldrns-Center/',
                              'reviews': '/california/san-francisco/27446-Leonard-Flynn-Chldrns-Center/#Reviews'},
                          'lon': -122.412025,
                          'name': "Leonard Flynn Chldrn's Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '175 Omar Way',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27447,
                          'lat': 37.738777,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27447-Miraloma-School/#College_success',
                              'profile': '/california/san-francisco/27447-Miraloma-School/',
                              'reviews': '/california/san-francisco/27447-Miraloma-School/#Reviews'},
                          'lon': -122.450188,
                          'name': 'Miraloma School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '235 12th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 27448,
                          'lat': 37.783627,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27448-Sutro-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/27448-Sutro-Childrens-Center/',
                              'reviews': '/california/san-francisco/27448-Sutro-Childrens-Center/#Reviews'},
                          'lon': -122.47094,
                          'name': "Sutro Children's Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '633 Taraval Street #103',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 27449,
                          'lat': 37.742935,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27449-Growth-And-Learning-Opertunity/#College_success',
                              'profile': '/california/san-francisco/27449-Growth-And-Learning-Opertunity/',
                              'reviews': '/california/san-francisco/27449-Growth-And-Learning-Opertunity/#Reviews'},
                          'lon': -122.473122,
                          'name': 'Growth And Learning Opertunity',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4150 Clement Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27517,
                          'lat': 37.782185,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27517-Cheryl-Andersen-Sorensen-Cc/#College_success',
                              'profile': '/california/san-francisco/27517-Cheryl-Andersen-Sorensen-Cc/',
                              'reviews': '/california/san-francisco/27517-Cheryl-Andersen-Sorensen-Cc/#Reviews'},
                          'lon': -122.504211,
                          'name': 'Cheryl Andersen Sorensen Cc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '165 Grattan Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27518,
                          'lat': 37.76363,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27518-Grattan-After-School-Program/#College_success',
                              'profile': '/california/san-francisco/27518-Grattan-After-School-Program/',
                              'reviews': '/california/san-francisco/27518-Grattan-After-School-Program/#Reviews'},
                          'lon': -122.450478,
                          'name': 'Grattan After School Program',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 320189',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27625,
                          'lat': 37.71814,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27625-Ulloa-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/27625-Ulloa-Child-Care-Center/',
                              'reviews': '/california/san-francisco/27625-Ulloa-Child-Care-Center/#Reviews'},
                          'lon': -122.486351,
                          'name': 'Ulloa Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2426 California Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27687,
                          'lat': 37.789009,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27687-Golden-Gate-Guppies-Pre-School/#College_success',
                              'profile': '/california/san-francisco/27687-Golden-Gate-Guppies-Pre-School/',
                              'reviews': '/california/san-francisco/27687-Golden-Gate-Guppies-Pre-School/#Reviews'},
                          'lon': -122.434525,
                          'name': 'Golden Gate Guppies Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '647 Chenery Street',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27735,
                          'lat': 37.734531,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27735-Glen-Park-Montessori-School/#College_success',
                              'profile': '/california/san-francisco/27735-Glen-Park-Montessori-School/',
                              'reviews': '/california/san-francisco/27735-Glen-Park-Montessori-School/#Reviews'},
                          'lon': -122.432846,
                          'name': 'Glen Park Montessori School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '888 Clay Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27747,
                          'lat': 37.79417,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27747-Lok-Yuen-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/27747-Lok-Yuen-Child-Development-Center/',
                              'reviews': '/california/san-francisco/27747-Lok-Yuen-Child-Development-Center/#Reviews'},
                          'lon': -122.4077,
                          'name': 'Lok Yuen Child Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2031 Bush Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27769,
                          'lat': 37.78709,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27769-Nihonmachi-Little-Friends/#College_success',
                              'profile': '/california/san-francisco/27769-Nihonmachi-Little-Friends/',
                              'reviews': '/california/san-francisco/27769-Nihonmachi-Little-Friends/#Reviews'},
                          'lon': -122.430588,
                          'name': 'Nihonmachi Little Friends',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1700 Sutter Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 27801,
                          'lat': 37.786919,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27801-Nihonmachi-Little-Friends-Asp/#College_success',
                              'profile': '/california/san-francisco/27801-Nihonmachi-Little-Friends-Asp/',
                              'reviews': '/california/san-francisco/27801-Nihonmachi-Little-Friends-Asp/#Reviews'},
                          'lon': -122.428688,
                          'name': "Nihonmachi Little Friend's Asp",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '500 Clarendon Avenue',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 27936,
                          'lat': 37.753986,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27936-Second-Community-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/27936-Second-Community-Child-Care-Center/',
                              'reviews': '/california/san-francisco/27936-Second-Community-Child-Care-Center/#Reviews'},
                          'lon': -122.455986,
                          'name': 'Second Community Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '207 Skyline Boulevard',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 27937,
                          'lat': 37.730404,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/27937-Janet-Pomeroy-Center/#College_success',
                              'profile': '/california/san-francisco/27937-Janet-Pomeroy-Center/',
                              'reviews': '/california/san-francisco/27937-Janet-Pomeroy-Center/#Reviews'},
                          'lon': -122.500694,
                          'name': 'Janet Pomeroy Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2412 24th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28051,
                          'lat': 37.753353,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28051-Buena-Vista-Child-Care/#College_success',
                              'profile': '/california/san-francisco/28051-Buena-Vista-Child-Care/',
                              'reviews': '/california/san-francisco/28051-Buena-Vista-Child-Care/#Reviews'},
                          'lon': -122.403786,
                          'name': 'Buena Vista Child Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3960 Sacramento Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28052,
                          'lat': 37.786789,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28052-Claire-Lilienthal-Aft-School-Pgrm/#College_success',
                              'profile': '/california/san-francisco/28052-Claire-Lilienthal-Aft-School-Pgrm/',
                              'reviews': '/california/san-francisco/28052-Claire-Lilienthal-Aft-School-Pgrm/#Reviews'},
                          'lon': -122.45813,
                          'name': 'Claire Lilienthal Aft School Pgrm',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 320189',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28053,
                          'lat': 37.71814,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28053-Ulloa-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/28053-Ulloa-Childrens-Center/',
                              'reviews': '/california/san-francisco/28053-Ulloa-Childrens-Center/#Reviews'},
                          'lon': -122.486351,
                          'name': "Ulloa Children's Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '584 Castro Street #264',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28054,
                          'lat': 37.75959,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28054-Childrens-After-School-Arts/#College_success',
                              'profile': '/california/san-francisco/28054-Childrens-After-School-Arts/',
                              'reviews': '/california/san-francisco/28054-Childrens-After-School-Arts/#Reviews'},
                          'lon': -122.435242,
                          'name': "Children's After School Arts",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '111 Page Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28181,
                          'lat': 37.773743,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28181-Clara-House-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/28181-Clara-House-Child-Care-Center/',
                              'reviews': '/california/san-francisco/28181-Clara-House-Child-Care-Center/#Reviews'},
                          'lon': -122.423042,
                          'name': 'Clara House Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2701 Noriega Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28214,
                          'lat': 37.753532,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28214-Ark-Of-Hope-Pre-School/#College_success',
                              'profile': '/california/san-francisco/28214-Ark-Of-Hope-Pre-School/',
                              'reviews': '/california/san-francisco/28214-Ark-Of-Hope-Pre-School/#Reviews'},
                          'lon': -122.492561,
                          'name': 'Ark Of Hope Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '155 Belvedere Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 28241,
                          'lat': 37.767662,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28241-Belvedere-Montessori-School/#College_success',
                              'profile': '/california/san-francisco/28241-Belvedere-Montessori-School/',
                              'reviews': '/california/san-francisco/28241-Belvedere-Montessori-School/#Reviews'},
                          'lon': -122.449562,
                          'name': 'Belvedere Montessori School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1100 California Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28324,
                          'lat': 37.791847,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28324-Community-Pre-Sch-Grace-Cath/#College_success',
                              'profile': '/california/san-francisco/28324-Community-Pre-Sch-Grace-Cath/',
                              'reviews': '/california/san-francisco/28324-Community-Pre-Sch-Grace-Cath/#Reviews'},
                          'lon': -122.412888,
                          'name': 'Community Pre-Sch-Grace Cath',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '303 2nd Street #250',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28335,
                          'lat': 37.785519,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28335-Bright-Horizons-Ps-Second-St/#College_success',
                              'profile': '/california/san-francisco/28335-Bright-Horizons-Ps-Second-St/',
                              'reviews': '/california/san-francisco/28335-Bright-Horizons-Ps-Second-St/#Reviews'},
                          'lon': -122.396339,
                          'name': 'Bright Horizons Ps-Second St',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1664 11th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28351,
                          'lat': 37.757168,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28351-Urban-Sprouts-Day-School/#College_success',
                              'profile': '/california/san-francisco/28351-Urban-Sprouts-Day-School/',
                              'reviews': '/california/san-francisco/28351-Urban-Sprouts-Day-School/#Reviews'},
                          'lon': -122.46788,
                          'name': 'Urban Sprouts Day School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '20 Woodside Avenue',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28421,
                          'lat': 37.746628,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28421-Scandinavian-Ps-San-Francisco/#College_success',
                              'profile': '/california/san-francisco/28421-Scandinavian-Ps-San-Francisco/',
                              'reviews': '/california/san-francisco/28421-Scandinavian-Ps-San-Francisco/#Reviews'},
                          'lon': -122.457993,
                          'name': 'Scandinavian Ps-San Francisco',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1294 Potrero Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28510,
                          'lat': 37.751923,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28510-Good-Samaritan-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/28510-Good-Samaritan-Child-Care-Center/',
                              'reviews': '/california/san-francisco/28510-Good-Samaritan-Child-Care-Center/#Reviews'},
                          'lon': -122.406555,
                          'name': 'Good Samaritan Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1600 Holloway Avenue',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28630,
                          'lat': 37.72197,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28630-Childrens-Campus/#College_success',
                              'profile': '/california/san-francisco/28630-Childrens-Campus/',
                              'reviews': '/california/san-francisco/28630-Childrens-Campus/#Reviews'},
                          'lon': -122.479401,
                          'name': "Children's Campus",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1 Dr Carlton B Goodlett Place #68',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28651,
                          'lat': 37.779354,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28651-Marin-Day-School--Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/28651-Marin-Day-School--Child-Care-Center/',
                              'reviews': '/california/san-francisco/28651-Marin-Day-School--Child-Care-Center/#Reviews'},
                          'lon': -122.418739,
                          'name': 'Marin Day School & Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2800 Taraval Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28832,
                          'lat': 37.742313,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28832-Sunset-Head-Start/#College_success',
                              'profile': '/california/san-francisco/28832-Sunset-Head-Start/',
                              'reviews': '/california/san-francisco/28832-Sunset-Head-Start/#Reviews'},
                          'lon': -122.496201,
                          'name': 'Sunset Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '362 Capp Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28833,
                          'lat': 37.760941,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28833-Valencia-Gardens-Head-Start/#College_success',
                              'profile': '/california/san-francisco/28833-Valencia-Gardens-Head-Start/',
                              'reviews': '/california/san-francisco/28833-Valencia-Gardens-Head-Start/#Reviews'},
                          'lon': -122.418228,
                          'name': 'Valencia Gardens Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3543 18th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28834,
                          'lat': 37.761421,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28834-Womens-Bldg-Head-Start/#College_success',
                              'profile': '/california/san-francisco/28834-Womens-Bldg-Head-Start/',
                              'reviews': '/california/san-francisco/28834-Womens-Bldg-Head-Start/#Reviews'},
                          'lon': -122.422623,
                          'name': 'Womens Bldg Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3141 26th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28835,
                          'lat': 37.749298,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28835-Bernal-Dwellings-Head-Start/#College_success',
                              'profile': '/california/san-francisco/28835-Bernal-Dwellings-Head-Start/',
                              'reviews': '/california/san-francisco/28835-Bernal-Dwellings-Head-Start/#Reviews'},
                          'lon': -122.4123,
                          'name': 'Bernal Dwellings Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '205 13th Street #3280',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28836,
                          'lat': 37.769688,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28836-Alemany-Head-Start/#College_success',
                              'profile': '/california/san-francisco/28836-Alemany-Head-Start/',
                              'reviews': '/california/san-francisco/28836-Alemany-Head-Start/#Reviews'},
                          'lon': -122.419464,
                          'name': 'Alemany Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '130 Stevenson Street',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28837,
                          'lat': 37.788479,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28837-Stevenson-Head-Start/#College_success',
                              'profile': '/california/san-francisco/28837-Stevenson-Head-Start/',
                              'reviews': '/california/san-francisco/28837-Stevenson-Head-Start/#Reviews'},
                          'lon': -122.401329,
                          'name': 'Stevenson Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '434 Ellis Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28838,
                          'lat': 37.785042,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28838-Glide-Family-Youth--Childca/#College_success',
                              'profile': '/california/san-francisco/28838-Glide-Family-Youth--Childca/',
                              'reviews': '/california/san-francisco/28838-Glide-Family-Youth--Childca/#Reviews'},
                          'lon': -122.413452,
                          'name': 'Glide Family Youth & Childca',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '750 26th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 28961,
                          'lat': 37.775291,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/28961-Starlight-Christian-Pre-School/#College_success',
                              'profile': '/california/san-francisco/28961-Starlight-Christian-Pre-School/',
                              'reviews': '/california/san-francisco/28961-Starlight-Christian-Pre-School/#Reviews'},
                          'lon': -122.485329,
                          'name': 'Starlight Christian Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 15366',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 29109,
                          'lat': 37.78775,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29109-Ccfc-Turk-Sch-Age-Center/#College_success',
                              'profile': '/california/san-francisco/29109-Ccfc-Turk-Sch-Age-Center/',
                              'reviews': '/california/san-francisco/29109-Ccfc-Turk-Sch-Age-Center/#Reviews'},
                          'lon': -122.438232,
                          'name': 'Ccfc Turk Sch/Age Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4080 Mission St',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29168,
                          'lat': 37.732536,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29168-Ymca-Of-Sf-Richmond-Branch-Arg/#College_success',
                              'profile': '/california/san-francisco/29168-Ymca-Of-Sf-Richmond-Branch-Arg/',
                              'reviews': '/california/san-francisco/29168-Ymca-Of-Sf-Richmond-Branch-Arg/#Reviews'},
                          'lon': -122.427895,
                          'name': 'Ymca Of Sf Richmond Branch Arg',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4545 Anza Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29169,
                          'lat': 37.777473,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29169-Ymca-Of-San-Fransisco-Richmon/#College_success',
                              'profile': '/california/san-francisco/29169-Ymca-Of-San-Fransisco-Richmon/',
                              'reviews': '/california/san-francisco/29169-Ymca-Of-San-Fransisco-Richmon/#Reviews'},
                          'lon': -122.497223,
                          'name': 'Ymca Of San Fransisco Richmon',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 31202',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29327,
                          'lat': 37.740105,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29327-Glenridge-Coop-Nursery/#College_success',
                              'profile': '/california/san-francisco/29327-Glenridge-Coop-Nursery/',
                              'reviews': '/california/san-francisco/29327-Glenridge-Coop-Nursery/#Reviews'},
                          'lon': -122.438232,
                          'name': 'Glenridge Coop Nursery',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '177 Golden Gate Avenue',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29328,
                          'lat': 37.78194,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29328-Wu-Yee-Childrens-Pre-School/#College_success',
                              'profile': '/california/san-francisco/29328-Wu-Yee-Childrens-Pre-School/',
                              'reviews': '/california/san-francisco/29328-Wu-Yee-Childrens-Pre-School/#Reviews'},
                          'lon': -122.413506,
                          'name': 'Wu Yee Childrens Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3013 24th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29471,
                          'lat': 37.752602,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29471-24th-Street-Head-Start/#College_success',
                              'profile': '/california/san-francisco/29471-24th-Street-Head-Start/',
                              'reviews': '/california/san-francisco/29471-24th-Street-Head-Start/#Reviews'},
                          'lon': -122.412193,
                          'name': '24th Street Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2638 44th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29562,
                          'lat': 37.737396,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/29562-Moy-Song-Fcc/#College_success',
                                    'profile': '/california/san-francisco/29562-Moy-Song-Fcc/',
                                    'reviews': '/california/san-francisco/29562-Moy-Song-Fcc/#Reviews'},
                          'lon': -122.501831,
                          'name': 'Moy Song Fcc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1157 Wisconsin Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29685,
                          'lat': 37.752815,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29685-Merry-Go-Round-Day-Care/#College_success',
                              'profile': '/california/san-francisco/29685-Merry-Go-Round-Day-Care/',
                              'reviews': '/california/san-francisco/29685-Merry-Go-Round-Day-Care/#Reviews'},
                          'lon': -122.398201,
                          'name': 'Merry Go Round Day Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '474 11th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29698,
                          'lat': 37.779354,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29698-Little-People-Pre-School/#College_success',
                              'profile': '/california/san-francisco/29698-Little-People-Pre-School/',
                              'reviews': '/california/san-francisco/29698-Little-People-Pre-School/#Reviews'},
                          'lon': -122.4692,
                          'name': 'Little People Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '850 Avenue D',
                                      'street2': '',
                                      'zip': '94130'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29699,
                          'lat': 37.824677,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29699-Cyo-Treasure-Island-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/29699-Cyo-Treasure-Island-Child-Care-Center/',
                              'reviews': '/california/san-francisco/29699-Cyo-Treasure-Island-Child-Care-Center/#Reviews'},
                          'lon': -122.373062,
                          'name': 'Cyo-Treasure Island Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1634 Fell Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29840,
                          'lat': 37.773422,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29840-Our-Victorian-School-House-Ps/#College_success',
                              'profile': '/california/san-francisco/29840-Our-Victorian-School-House-Ps/',
                              'reviews': '/california/san-francisco/29840-Our-Victorian-School-House-Ps/#Reviews'},
                          'lon': -122.444885,
                          'name': 'Our Victorian School House Ps',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '13th Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29855,
                          'lat': 37.769688,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29855-Hunters-Point-Head-Start/#College_success',
                              'profile': '/california/san-francisco/29855-Hunters-Point-Head-Start/',
                              'reviews': '/california/san-francisco/29855-Hunters-Point-Head-Start/#Reviews'},
                          'lon': -122.41555,
                          'name': "Hunter's Point Head Start",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '125 West Point Road',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29903,
                          'lat': 37.736214,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29903-Hunters-View-Head-Start/#College_success',
                              'profile': '/california/san-francisco/29903-Hunters-View-Head-Start/',
                              'reviews': '/california/san-francisco/29903-Hunters-View-Head-Start/#Reviews'},
                          'lon': -122.381142,
                          'name': "Hunter's View Head Start",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1330 Stevenson Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29935,
                          'lat': 37.771152,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29935-Stevenson-Head-Start/#College_success',
                              'profile': '/california/san-francisco/29935-Stevenson-Head-Start/',
                              'reviews': '/california/san-francisco/29935-Stevenson-Head-Start/#Reviews'},
                          'lon': -122.422058,
                          'name': 'Stevenson Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4245 Lawton Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 29987,
                          'lat': 37.756413,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/29987-Sunset-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/29987-Sunset-Nursery-School/',
                              'reviews': '/california/san-francisco/29987-Sunset-Nursery-School/#Reviews'},
                          'lon': -122.508621,
                          'name': 'Sunset Nursery School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2004 Lawton Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30013,
                          'lat': 37.757648,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30013-Kangaroos-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30013-Kangaroos-Pre-School/',
                              'reviews': '/california/san-francisco/30013-Kangaroos-Pre-School/#Reviews'},
                          'lon': -122.484375,
                          'name': 'Kangaroos Pre-School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1215 22nd Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30018,
                          'lat': 37.764927,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30018-My-Little-Learning-Tree-Day-Care/#College_success',
                              'profile': '/california/san-francisco/30018-My-Little-Learning-Tree-Day-Care/',
                              'reviews': '/california/san-francisco/30018-My-Little-Learning-Tree-Day-Care/#Reviews'},
                          'lon': -122.480705,
                          'name': 'My Little Learning Tree Day Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '710 40th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30019,
                          'lat': 37.77523,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30019-Stthomas-The-Apostle-Pre-K-Lc/#College_success',
                              'profile': '/california/san-francisco/30019-Stthomas-The-Apostle-Pre-K-Lc/',
                              'reviews': '/california/san-francisco/30019-Stthomas-The-Apostle-Pre-K-Lc/#Reviews'},
                          'lon': -122.50029,
                          'name': 'Stthomas The Apostle Pre-K Lc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1 Trenton Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30028,
                          'lat': 37.794865,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30028-Sfusd-Commodore-Stockton-Cdc/#College_success',
                              'profile': '/california/san-francisco/30028-Sfusd-Commodore-Stockton-Cdc/',
                              'reviews': '/california/san-francisco/30028-Sfusd-Commodore-Stockton-Cdc/#Reviews'},
                          'lon': -122.408928,
                          'name': 'Sfusd-Commodore Stockton Cdc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2235 Judah Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30115,
                          'lat': 37.761131,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30115-Sunrise-Christian-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30115-Sunrise-Christian-Pre-School/',
                              'reviews': '/california/san-francisco/30115-Sunrise-Christian-Pre-School/#Reviews'},
                          'lon': -122.486206,
                          'name': 'Sunrise Christian Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '221 21st Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30158,
                          'lat': 37.783607,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30158-Golden-Gate-Kids-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/30158-Golden-Gate-Kids-Child-Care-Center/',
                              'reviews': '/california/san-francisco/30158-Golden-Gate-Kids-Child-Care-Center/#Reviews'},
                          'lon': -122.481018,
                          'name': 'Golden Gate Kids Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2730 17th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30168,
                          'lat': 37.764515,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30168-Sweet-Peas-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30168-Sweet-Peas-Pre-School/',
                              'reviews': '/california/san-francisco/30168-Sweet-Peas-Pre-School/#Reviews'},
                          'lon': -122.411102,
                          'name': 'Sweet Peas Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3161 Mission Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30187,
                          'lat': 37.74638,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30187-Little-Tree-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30187-Little-Tree-Pre-School/',
                              'reviews': '/california/san-francisco/30187-Little-Tree-Pre-School/#Reviews'},
                          'lon': -122.418945,
                          'name': 'Little Tree Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4228 Geary Blvd, San Francisco, CA 94118',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30194,
                          'lat': 37.805576,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30194-Peekadoodle-Academy/#College_success',
                              'profile': '/california/san-francisco/30194-Peekadoodle-Academy/',
                              'reviews': '/california/san-francisco/30194-Peekadoodle-Academy/#Reviews'},
                          'lon': -122.422951,
                          'name': 'Peekadoodle Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2201 Vicente Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30230,
                          'lat': 37.738415,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30230-Little-Footprints-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30230-Little-Footprints-Pre-School/',
                              'reviews': '/california/san-francisco/30230-Little-Footprints-Pre-School/#Reviews'},
                          'lon': -122.4907,
                          'name': 'Little Footprints Pre-School',
                          'numReviews': 7,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '298 Monterey Boulevard',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30240,
                          'lat': 37.731812,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30240-Kangaroos-Pre-School-2/#College_success',
                              'profile': '/california/san-francisco/30240-Kangaroos-Pre-School-2/',
                              'reviews': '/california/san-francisco/30240-Kangaroos-Pre-School-2/#Reviews'},
                          'lon': -122.441833,
                          'name': 'Kangaroos Pre-School 2',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '143 Clement Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30256,
                          'lat': 37.782967,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30256-Presidio-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30256-Presidio-Pre-School/',
                              'reviews': '/california/san-francisco/30256-Presidio-Pre-School/#Reviews'},
                          'lon': -122.460838,
                          'name': 'Presidio Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2509 Bush Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30266,
                          'lat': 37.786171,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30266-Stretch-The-Imagination-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/30266-Stretch-The-Imagination-Child-Care-Center/',
                              'reviews': '/california/san-francisco/30266-Stretch-The-Imagination-Child-Care-Center/#Reviews'},
                          'lon': -122.438904,
                          'name': 'Stretch The Imagination Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3155 Vicente Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30342,
                          'lat': 37.738182,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30342-Starlight-Two-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/30342-Starlight-Two-Child-Care-Center/',
                              'reviews': '/california/san-francisco/30342-Starlight-Two-Child-Care-Center/#Reviews'},
                          'lon': -122.500969,
                          'name': 'Starlight Two Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2012 Pine Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30399,
                          'lat': 37.788742,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30399-Chibi-Chan-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30399-Chibi-Chan-Pre-School/',
                              'reviews': '/california/san-francisco/30399-Chibi-Chan-Pre-School/#Reviews'},
                          'lon': -122.429367,
                          'name': 'Chibi-Chan Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2140 Pierce Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30441,
                          'lat': 37.790199,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30441-Alta-Plaza-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30441-Alta-Plaza-Pre-School/',
                              'reviews': '/california/san-francisco/30441-Alta-Plaza-Pre-School/#Reviews'},
                          'lon': -122.437195,
                          'name': 'Alta Plaza Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '728 Pacific Avenue #706',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30464,
                          'lat': 37.79707,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30464-Teaching-Tots-Pre-School/#College_success',
                              'profile': '/california/san-francisco/30464-Teaching-Tots-Pre-School/',
                              'reviews': '/california/san-francisco/30464-Teaching-Tots-Pre-School/#Reviews'},
                          'lon': -122.407516,
                          'name': 'Teaching Tots Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2730 Bryant Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30520,
                          'lat': 37.750454,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30520-Hilltop-Child-Development-Center-Fsa/#College_success',
                              'profile': '/california/san-francisco/30520-Hilltop-Child-Development-Center-Fsa/',
                              'reviews': '/california/san-francisco/30520-Hilltop-Child-Development-Center-Fsa/#Reviews'},
                          'lon': -122.409157,
                          'name': 'Hilltop Child Development Center-Fsa',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '207 Congo St',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 30529,
                          'lat': 37.730915,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/30529-Rinas-Little-Angels-Nursery/#College_success',
                              'profile': '/california/san-francisco/30529-Rinas-Little-Angels-Nursery/',
                              'reviews': '/california/san-francisco/30529-Rinas-Little-Angels-Nursery/#Reviews'},
                          'lon': -122.442284,
                          'name': "Rina's Little Angels Nursery",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '470 West Portal Ave.',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 481,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 92},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Hispanic', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 2}],
                          'gradeLevels': '9-12 & Ungraded',
                          'highlighted': false,
                          'id': 31018,
                          'lat': 37.735855,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31018-San-Francisco-Waldorf-High-School/#College_success',
                              'profile': '/california/san-francisco/31018-San-Francisco-Waldorf-High-School/',
                              'reviews': '/california/san-francisco/31018-San-Francisco-Waldorf-High-School/#Reviews'},
                          'lon': -122.471588,
                          'name': 'San Francisco Waldorf High School',
                          'numReviews': 13,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2265 3rd Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 11,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 31532,
                          'lat': 37.760956,
                          'levelCode': 'e',
                          'links': {'collegeSuccess': '/california/san-francisco/31532-Altschool-Inc./#College_success',
                                    'profile': '/california/san-francisco/31532-Altschool-Inc./',
                                    'reviews': '/california/san-francisco/31532-Altschool-Inc./#Reviews'},
                          'lon': -122.388298,
                          'name': 'Altschool, Inc.',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '117 Diamond Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 140,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 68},
                                            {'label': 'Pacific Islander', 'percentage': 14},
                                            {'label': 'Asian', 'percentage': 7},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 31536,
                          'lat': 37.760155,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31536-Spanish-Infusion-School/#College_success',
                              'profile': '/california/san-francisco/31536-Spanish-Infusion-School/',
                              'reviews': '/california/san-francisco/31536-Spanish-Infusion-School/#Reviews'},
                          'lon': -122.436867,
                          'name': 'Spanish Infusión School',
                          'numReviews': 4,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '59 Caine Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 11,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 31537,
                          'lat': 37.71714,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31537-Mt.-Vernon-Christian-Academy/#College_success',
                              'profile': '/california/san-francisco/31537-Mt.-Vernon-Christian-Academy/',
                              'reviews': '/california/san-francisco/31537-Mt.-Vernon-Christian-Academy/#Reviews'},
                          'lon': -122.450356,
                          'name': 'Mt. Vernon Christian Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2960 Broadway Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 8,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '3-8',
                          'highlighted': false,
                          'id': 31539,
                          'lat': 37.793186,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31539-San-Francisco-Expeditionary-School/#College_success',
                              'profile': '/california/san-francisco/31539-San-Francisco-Expeditionary-School/',
                              'reviews': '/california/san-francisco/31539-San-Francisco-Expeditionary-School/#Reviews'},
                          'lon': -122.445892,
                          'name': 'San Francisco Expeditionary School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3301 Balboa Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 71,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 87},
                                            {'label': 'Two or more races', 'percentage': 7},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Hispanic', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 31540,
                          'lat': 37.775639,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31540-San-Francisco-Pacific-Academy/#College_success',
                              'profile': '/california/san-francisco/31540-San-Francisco-Pacific-Academy/',
                              'reviews': '/california/san-francisco/31540-San-Francisco-Pacific-Academy/#Reviews'},
                          'lon': -122.494286,
                          'name': 'San Francisco Pacific Academy',
                          'numReviews': 13,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '862 28th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 30,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-7',
                          'highlighted': false,
                          'id': 31542,
                          'lat': 37.773132,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31542-Shalom-School-Dba-Bais-Menacehm-Yeshiva-Day-School/#College_success',
                              'profile': '/california/san-francisco/31542-Shalom-School-Dba-Bais-Menacehm-Yeshiva-Day-School/',
                              'reviews': '/california/san-francisco/31542-Shalom-School-Dba-Bais-Menacehm-Yeshiva-Day-School/#Reviews'},
                          'lon': -122.487122,
                          'name': 'Shalom School Dba Bais Menacehm Yeshiva Day School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4 Embarcadero Center',
                                      'street2': '',
                                      'zip': '94111'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 6,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '5-12',
                          'highlighted': false,
                          'id': 31543,
                          'lat': 37.794956,
                          'levelCode': 'e,m,h',
                          'links': {'collegeSuccess': '/california/san-francisco/31543-Tahour-Academy/#College_success',
                                    'profile': '/california/san-francisco/31543-Tahour-Academy/',
                                    'reviews': '/california/san-francisco/31543-Tahour-Academy/#Reviews'},
                          'lon': -122.397285,
                          'name': 'Tahour Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '315 Rome Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 6,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 31544,
                          'lat': 37.715858,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31544-Teknion-Peri-Sophia-Academy/#College_success',
                              'profile': '/california/san-francisco/31544-Teknion-Peri-Sophia-Academy/',
                              'reviews': '/california/san-francisco/31544-Teknion-Peri-Sophia-Academy/#Reviews'},
                          'lon': -122.44799,
                          'name': 'Teknion Peri Sophia Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4620 California St.',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 31669,
                          'lat': 37.784939,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31669-St.-James-Preschool/#College_success',
                              'profile': '/california/san-francisco/31669-St.-James-Preschool/',
                              'reviews': '/california/san-francisco/31669-St.-James-Preschool/#Reviews'},
                          'lon': -122.467194,
                          'name': 'St. James Preschool',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3627 Broderick Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '1-6',
                          'highlighted': false,
                          'id': 31671,
                          'lat': 37.803875,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31671-Amici-World-School/#College_success',
                              'profile': '/california/san-francisco/31671-Amici-World-School/',
                              'reviews': '/california/san-francisco/31671-Amici-World-School/#Reviews'},
                          'lon': -122.445709,
                          'name': 'Amici World School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '100 Pine St Suite 1250',
                                      'street2': '',
                                      'zip': '94111'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 31679,
                          'lat': 37.792534,
                          'levelCode': 'e,m,h',
                          'links': {'collegeSuccess': '/california/san-francisco/31679-Gavin-Academy/#College_success',
                                    'profile': '/california/san-francisco/31679-Gavin-Academy/',
                                    'reviews': '/california/san-francisco/31679-Gavin-Academy/#Reviews'},
                          'lon': -122.398895,
                          'name': 'Gavin Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1320 7th Ave',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-1',
                          'highlighted': false,
                          'id': 31698,
                          'lat': 37.763756,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31698-Sunset-Progressive-School-Will-Open-Fall-2015/#College_success',
                              'profile': '/california/san-francisco/31698-Sunset-Progressive-School-Will-Open-Fall-2015/',
                              'reviews': '/california/san-francisco/31698-Sunset-Progressive-School-Will-Open-Fall-2015/#Reviews'},
                          'lon': -122.46389,
                          'name': 'Sunset Progressive School (Will open: Fall 2015)',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3741 Buchanan St',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 31700,
                          'lat': 37.804562,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31700-AltSchool-Fort-Mason/#College_success',
                              'profile': '/california/san-francisco/31700-AltSchool-Fort-Mason/',
                              'reviews': '/california/san-francisco/31700-AltSchool-Fort-Mason/#Reviews'},
                          'lon': -122.433907,
                          'name': 'AltSchool Fort Mason',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '735 Fell St',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-5',
                          'highlighted': false,
                          'id': 31701,
                          'lat': 37.7747,
                          'levelCode': 'p,e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31701-AltSchool-Alamo-Square/#College_success',
                              'profile': '/california/san-francisco/31701-AltSchool-Alamo-Square/',
                              'reviews': '/california/san-francisco/31701-AltSchool-Alamo-Square/#Reviews'},
                          'lon': -122.430153,
                          'name': 'AltSchool Alamo Square',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1245 folsom st',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 31702,
                          'lat': 37.774181,
                          'levelCode': 'm',
                          'links': {'collegeSuccess': '/california/san-francisco/31702-AltSchool-SOMA/#College_success',
                                    'profile': '/california/san-francisco/31702-AltSchool-SOMA/',
                                    'reviews': '/california/san-francisco/31702-AltSchool-SOMA/#Reviews'},
                          'lon': -122.410484,
                          'name': 'AltSchool SOMA',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '300 4th St',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 31718,
                          'lat': 37.781761,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31718-AltSchool-Yerba-Buena/#College_success',
                              'profile': '/california/san-francisco/31718-AltSchool-Yerba-Buena/',
                              'reviews': '/california/san-francisco/31718-AltSchool-Yerba-Buena/#Reviews'},
                          'lon': -122.401199,
                          'name': 'AltSchool Yerba Buena',
                          'numReviews': 14,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2026 Divisadero St',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 31747,
                          'lat': 37.788563,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31747-Russian-Hill-School/#College_success',
                              'profile': '/california/san-francisco/31747-Russian-Hill-School/',
                              'reviews': '/california/san-francisco/31747-Russian-Hill-School/#Reviews'},
                          'lon': -122.440285,
                          'name': 'Russian Hill School',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '655 De Haro St',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 1661,
                          'districtName': 'Sbe - The New School Of San Francisco School District',
                          'enrollment': 235,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 11},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 21,
                                             'rating': 6},
                                            {'label': 'White', 'percentage': 53, 'rating': 10},
                                            {'label': 'Asian', 'percentage': 14},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 31959,
                          'lat': 37.761719,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31959-The-New-School-Of-San-Francisco/#College_success',
                              'profile': '/california/san-francisco/31959-The-New-School-Of-San-Francisco/',
                              'reviews': '/california/san-francisco/31959-The-New-School-Of-San-Francisco/#Reviews'},
                          'lon': -122.40062,
                          'name': 'The New School of San Francisco',
                          'numReviews': 151,
                          'parentRating': 5,
                          'percentLowIncome': '11%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 7,
                          'subratings': {'Equity Overview Rating': 5, 'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2055 Silver Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 226,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 79,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American',
                                             'percentage': 37,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 35,
                                             'rating': 1},
                                            {'label': 'Filipino', 'percentage': 8},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 32187,
                          'lat': 37.735912,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32187-Brown-Jr.-Willie-L-Middle/#College_success',
                              'profile': '/california/san-francisco/32187-Brown-Jr.-Willie-L-Middle/',
                              'reviews': '/california/san-francisco/32187-Brown-Jr.-Willie-L-Middle/#Reviews'},
                          'lon': -122.399689,
                          'name': 'Brown Jr. (Willie L) Middle',
                          'numReviews': 12,
                          'parentRating': 5,
                          'percentLowIncome': '79%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '948 Hollister Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 1660,
                          'districtName': 'Sbe - Onepurpose School District',
                          'enrollment': 149,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 74,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American', 'percentage': 44},
                                            {'label': 'Hispanic', 'percentage': 44},
                                            {'label': 'Pacific Islander', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 32188,
                          'lat': 37.71833,
                          'levelCode': 'e',
                          'links': {'collegeSuccess': '/california/san-francisco/32188-One-Purpose/#College_success',
                                    'profile': '/california/san-francisco/32188-One-Purpose/',
                                    'reviews': '/california/san-francisco/32188-One-Purpose/#Reviews'},
                          'lon': -122.389877,
                          'name': 'One Purpose',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '74%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '655 Brotherhood Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 32358,
                          'lat': 37.713284,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32358-Brotherhood-Way-Preschool/#College_success',
                              'profile': '/california/san-francisco/32358-Brotherhood-Way-Preschool/',
                              'reviews': '/california/san-francisco/32358-Brotherhood-Way-Preschool/#Reviews'},
                          'lon': -122.475601,
                          'name': 'Brotherhood Way Preschool',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '325 Arguello Boulevard',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 32359,
                          'lat': 37.784599,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32359-Rosenberg-Early-Childhood-Center/#College_success',
                              'profile': '/california/san-francisco/32359-Rosenberg-Early-Childhood-Center/',
                              'reviews': '/california/san-francisco/32359-Rosenberg-Early-Childhood-Center/#Reviews'},
                          'lon': -122.45948,
                          'name': 'Rosenberg Early Childhood Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '75 Francis St',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8096,
                          'lat': 37.727032,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8096-Corpus-Christi-School/#College_success',
                              'profile': '/california/san-francisco/8096-Corpus-Christi-School/',
                              'reviews': '/california/san-francisco/8096-Corpus-Christi-School/#Reviews'},
                          'lon': -122.435257,
                          'name': 'Corpus Christi School',
                          'numReviews': 5,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '659 Pine Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 301,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 60},
                                            {'label': 'Two or more races', 'percentage': 18},
                                            {'label': 'Hispanic', 'percentage': 13},
                                            {'label': 'Asian', 'percentage': 8}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8097,
                          'lat': 37.791241,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8097-Ecole-Notre-Dame-Des-Victoires/#College_success',
                              'profile': '/california/san-francisco/8097-Ecole-Notre-Dame-Des-Victoires/',
                              'reviews': '/california/san-francisco/8097-Ecole-Notre-Dame-Des-Victoires/#Reviews'},
                          'lon': -122.40667,
                          'name': 'Ecole Notre Dame Des Victoires',
                          'numReviews': 74,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '600 Italy Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 400,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 40},
                                            {'label': 'Asian', 'percentage': 40},
                                            {'label': 'Two or more races', 'percentage': 15},
                                            {'label': 'White', 'percentage': 5}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8098,
                          'lat': 37.71587,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8098-Epiphany-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8098-Epiphany-Elementary-School/',
                              'reviews': '/california/san-francisco/8098-Epiphany-Elementary-School/#Reviews'},
                          'lon': -122.434692,
                          'name': 'Epiphany Elementary School',
                          'numReviews': 4,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1560 40th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 375,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 52},
                                            {'label': 'Two or more races', 'percentage': 22},
                                            {'label': 'White', 'percentage': 16},
                                            {'label': 'Hispanic', 'percentage': 10}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8099,
                          'lat': 37.757755,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8099-Holy-Name-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8099-Holy-Name-Elementary-School/',
                              'reviews': '/california/san-francisco/8099-Holy-Name-Elementary-School/#Reviews'},
                          'lon': -122.499046,
                          'name': 'Holy Name Elementary School',
                          'numReviews': 76,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3625 24th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 324,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 58},
                                            {'label': 'Asian', 'percentage': 18},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'African American', 'percentage': 8},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8100,
                          'lat': 37.751858,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8100-Immaculate-Conception-Academy/#College_success',
                              'profile': '/california/san-francisco/8100-Immaculate-Conception-Academy/',
                              'reviews': '/california/san-francisco/8100-Immaculate-Conception-Academy/#Reviews'},
                          'lon': -122.423523,
                          'name': 'Immaculate Conception Academy',
                          'numReviews': 24,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '925 Chenery Street',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 228,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 29},
                                            {'label': 'Two or more races', 'percentage': 25},
                                            {'label': 'White', 'percentage': 20},
                                            {'label': 'African American', 'percentage': 14},
                                            {'label': 'Pacific Islander', 'percentage': 8},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'Ungraded',
                          'highlighted': false,
                          'id': 8102,
                          'lat': 37.735073,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8102-St-John-S-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8102-St-John-S-Elementary-School/',
                              'reviews': '/california/san-francisco/8102-St-John-S-Elementary-School/#Reviews'},
                          'lon': -122.437866,
                          'name': 'St John S Elementary School',
                          'numReviews': 29,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3250 19th Avenue',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 395,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 31},
                                            {'label': 'Hispanic', 'percentage': 24},
                                            {'label': 'Two or more races', 'percentage': 19},
                                            {'label': 'White', 'percentage': 17},
                                            {'label': 'African American', 'percentage': 7},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8103,
                          'lat': 37.729416,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8103-Mercy-High-School/#College_success',
                              'profile': '/california/san-francisco/8103-Mercy-High-School/',
                              'reviews': '/california/san-francisco/8103-Mercy-High-School/#Reviews'},
                          'lon': -122.473869,
                          'name': 'Mercy High School',
                          'numReviews': 49,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3371 16th Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 259,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 43},
                                            {'label': 'African American', 'percentage': 22},
                                            {'label': 'Asian', 'percentage': 21},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'Pacific Islander', 'percentage': 2},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8104,
                          'lat': 37.764198,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8104-Mission-Dolores-Academy/#College_success',
                              'profile': '/california/san-francisco/8104-Mission-Dolores-Academy/',
                              'reviews': '/california/san-francisco/8104-Mission-Dolores-Academy/#Reviews'},
                          'lon': -122.428429,
                          'name': 'Mission Dolores Academy',
                          'numReviews': 15,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '785 Sunnydale Avenue',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 258,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 42},
                                            {'label': 'Hispanic', 'percentage': 33},
                                            {'label': 'Two or more races', 'percentage': 14},
                                            {'label': 'Pacific Islander', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8105,
                          'lat': 37.7094,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8105-Our-Lady-Of-The-Visitacion-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8105-Our-Lady-Of-The-Visitacion-Elementary-School/',
                              'reviews': '/california/san-francisco/8105-Our-Lady-Of-The-Visitacion-Elementary-School/#Reviews'},
                          'lon': -122.409157,
                          'name': 'Our Lady Of The Visitacion Elementary School',
                          'numReviews': 14,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '175 Phelan Avenue, San Francisco, CA 94112',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 691,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 25},
                                            {'label': 'White', 'percentage': 25},
                                            {'label': 'Asian', 'percentage': 23},
                                            {'label': 'Two or more races', 'percentage': 18},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8106,
                          'lat': 37.728203,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8106-Archbishop-Riordan-High-School/#College_success',
                              'profile': '/california/san-francisco/8106-Archbishop-Riordan-High-School/',
                              'reviews': '/california/san-francisco/8106-Archbishop-Riordan-High-School/#Reviews'},
                          'lon': -122.453636,
                          'name': 'Archbishop Riordan High School',
                          'numReviews': 142,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1055 Ellis Street',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 1297,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 40},
                                            {'label': 'White', 'percentage': 23},
                                            {'label': 'Hispanic', 'percentage': 18},
                                            {'label': 'Two or more races', 'percentage': 15},
                                            {'label': 'African American', 'percentage': 4}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8108,
                          'lat': 37.78326,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8108-Sacred-Heart-Cathedral-Preparatory/#College_success',
                              'profile': '/california/san-francisco/8108-Sacred-Heart-Cathedral-Preparatory/',
                              'reviews': '/california/san-francisco/8108-Sacred-Heart-Cathedral-Preparatory/#Reviews'},
                          'lon': -122.423515,
                          'name': 'Sacred Heart Cathedral Preparatory',
                          'numReviews': 21,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1320 14th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 347,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 71},
                                            {'label': 'Two or more races', 'percentage': 23},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Hispanic', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8109,
                          'lat': 37.763378,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8109-Saint-Anne-School/#College_success',
                              'profile': '/california/san-francisco/8109-Saint-Anne-School/',
                              'reviews': '/california/san-francisco/8109-Saint-Anne-School/#Reviews'},
                          'lon': -122.471443,
                          'name': 'Saint Anne School',
                          'numReviews': 65,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '299 Precita Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 113,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 80},
                                            {'label': 'Asian', 'percentage': 8},
                                            {'label': 'White', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8110,
                          'lat': 37.747505,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8110-St.-Anthony-Immaculate-Conception/#College_success',
                              'profile': '/california/san-francisco/8110-St.-Anthony-Immaculate-Conception/',
                              'reviews': '/california/san-francisco/8110-St.-Anthony-Immaculate-Conception/#Reviews'},
                          'lon': -122.413872,
                          'name': 'St. Anthony Immaculate Conception',
                          'numReviews': 15,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '940 Laguna Honda Boulevard',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 311,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 62},
                                            {'label': 'Two or more races', 'percentage': 30},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Hispanic', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8111,
                          'lat': 37.742802,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8111-St.-Brendan-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8111-St.-Brendan-Elementary-School/',
                              'reviews': '/california/san-francisco/8111-St.-Brendan-Elementary-School/#Reviews'},
                          'lon': -122.45649,
                          'name': 'St. Brendan Elementary School',
                          'numReviews': 40,
                          'parentRating': 2,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2250 Franklin Street, San Francisco, CA 94109',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 254,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 43},
                                            {'label': 'Two or more races', 'percentage': 21},
                                            {'label': 'White', 'percentage': 19},
                                            {'label': 'African American', 'percentage': 9},
                                            {'label': 'Hispanic', 'percentage': 9}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8112,
                          'lat': 37.795361,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8112-St.-Brigid-School/#College_success',
                              'profile': '/california/san-francisco/8112-St.-Brigid-School/',
                              'reviews': '/california/san-francisco/8112-St.-Brigid-School/#Reviews'},
                          'lon': -122.42485,
                          'name': 'St. Brigid School',
                          'numReviews': 39,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '660 Vicente Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 593,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 58},
                                            {'label': 'Asian', 'percentage': 30},
                                            {'label': 'Hispanic', 'percentage': 12}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8113,
                          'lat': 37.739811,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8113-St.-Cecilia-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8113-St.-Cecilia-Elementary-School/',
                              'reviews': '/california/san-francisco/8113-St.-Cecilia-Elementary-School/#Reviews'},
                          'lon': -122.474167,
                          'name': 'St. Cecilia Elementary School',
                          'numReviews': 67,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3250 18th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 102,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 90},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Asian', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8114,
                          'lat': 37.762245,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8114-St.-Charles-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8114-St.-Charles-Elementary-School/',
                              'reviews': '/california/san-francisco/8114-St.-Charles-Elementary-School/#Reviews'},
                          'lon': -122.416428,
                          'name': 'St. Charles Elementary School',
                          'numReviews': 4,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '450 Somerset St',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8116,
                          'lat': 37.725956,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8116-St.-Elizabeth-School/#College_success',
                              'profile': '/california/san-francisco/8116-St.-Elizabeth-School/',
                              'reviews': '/california/san-francisco/8116-St.-Elizabeth-School/#Reviews'},
                          'lon': -122.407318,
                          'name': 'St. Elizabeth School',
                          'numReviews': 1,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '419 Hearst Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 239,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 33},
                                            {'label': 'Two or more races', 'percentage': 30},
                                            {'label': 'White', 'percentage': 19},
                                            {'label': 'Asian', 'percentage': 14},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8118,
                          'lat': 37.730377,
                          'levelCode': 'e,m',
                          'links': {'collegeSuccess': '/california/san-francisco/8118-St.-Finn-Barr/#College_success',
                                    'profile': '/california/san-francisco/8118-St.-Finn-Barr/',
                                    'reviews': '/california/san-francisco/8118-St.-Finn-Barr/#Reviews'},
                          'lon': -122.44751,
                          'name': 'St. Finn Barr',
                          'numReviews': 50,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2550 41st Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 467,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 55},
                                            {'label': 'Two or more races', 'percentage': 24},
                                            {'label': 'Asian', 'percentage': 19},
                                            {'label': 'Hispanic', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8119,
                          'lat': 37.739498,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8119-St.-Gabriel-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8119-St.-Gabriel-Elementary-School/',
                              'reviews': '/california/san-francisco/8119-St.-Gabriel-Elementary-School/#Reviews'},
                          'lon': -122.498878,
                          'name': 'St. Gabriel Elementary School',
                          'numReviews': 9,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2001 37th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 1478,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 60},
                                            {'label': 'Asian', 'percentage': 17},
                                            {'label': 'Two or more races', 'percentage': 14},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 3}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8120,
                          'lat': 37.74807,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8120-St.-Ignatius-College-Preparatory-School/#College_success',
                              'profile': '/california/san-francisco/8120-St.-Ignatius-College-Preparatory-School/',
                              'reviews': '/california/san-francisco/8120-St.-Ignatius-College-Preparatory-School/#Reviews'},
                          'lon': -122.495934,
                          'name': 'St. Ignatius College Preparatory School',
                          'numReviews': 18,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '321 Fair Oaks Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 168,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 73},
                                            {'label': 'Two or more races', 'percentage': 15},
                                            {'label': 'Asian', 'percentage': 7},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8121,
                          'lat': 37.75132,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8121-St.-James-School/#College_success',
                              'profile': '/california/san-francisco/8121-St.-James-School/',
                              'reviews': '/california/san-francisco/8121-St.-James-School/#Reviews'},
                          'lon': -122.42395,
                          'name': 'St. James School',
                          'numReviews': 11,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '838 Kearny Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 80,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 73},
                                            {'label': 'Two or more races', 'percentage': 15},
                                            {'label': 'White', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 5}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 8122,
                          'lat': 37.795891,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8122-St.-Marys-School/#College_success',
                              'profile': '/california/san-francisco/8122-St.-Marys-School/',
                              'reviews': '/california/san-francisco/8122-St.-Marys-School/#Reviews'},
                          'lon': -122.404861,
                          'name': "St. Mary's School",
                          'numReviews': 16,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1690 Church Street',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 207,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 39},
                                            {'label': 'White', 'percentage': 33},
                                            {'label': 'Two or more races', 'percentage': 18},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 5},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 3},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8123,
                          'lat': 37.744064,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8123-St.-Pauls-School/#College_success',
                              'profile': '/california/san-francisco/8123-St.-Pauls-School/',
                              'reviews': '/california/san-francisco/8123-St.-Pauls-School/#Reviews'},
                          'lon': -122.426987,
                          'name': "St. Paul's School",
                          'numReviews': 97,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1266 Florida Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 281,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 96},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8125,
                          'lat': 37.751671,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8125-St.-Peters-School/#College_success',
                              'profile': '/california/san-francisco/8125-St.-Peters-School/',
                              'reviews': '/california/san-francisco/8125-St.-Peters-School/#Reviews'},
                          'lon': -122.409996,
                          'name': "St. Peter's School",
                          'numReviews': 60,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '660 Filbert Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 225,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 59},
                                            {'label': 'Two or more races', 'percentage': 21},
                                            {'label': 'Asian', 'percentage': 16},
                                            {'label': 'Hispanic', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-9',
                          'highlighted': false,
                          'id': 8126,
                          'lat': 37.801437,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8126-Sts.-Peter-And-Paul-K-8/#College_success',
                              'profile': '/california/san-francisco/8126-Sts.-Peter-And-Paul-K-8/',
                              'reviews': '/california/san-francisco/8126-Sts.-Peter-And-Paul-K-8/#Reviews'},
                          'lon': -122.410454,
                          'name': 'Sts. Peter And Paul K-8',
                          'numReviews': 47,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '665 Elizabeth Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 259,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 69},
                                            {'label': 'Two or more races', 'percentage': 19},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8127,
                          'lat': 37.751846,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8127-St.-Philip-School/#College_success',
                              'profile': '/california/san-francisco/8127-St.-Philip-School/',
                              'reviews': '/california/san-francisco/8127-St.-Philip-School/#Reviews'},
                          'lon': -122.435501,
                          'name': 'St. Philip School',
                          'numReviews': 187,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '401 Eucalyptus Drive',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 306,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 58},
                                            {'label': 'Two or more races', 'percentage': 24},
                                            {'label': 'Asian', 'percentage': 13},
                                            {'label': 'Hispanic', 'percentage': 6}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8128,
                          'lat': 37.730804,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8128-St.-Stephen-School/#College_success',
                              'profile': '/california/san-francisco/8128-St.-Stephen-School/',
                              'reviews': '/california/san-francisco/8128-St.-Stephen-School/#Reviews'},
                          'lon': -122.47728,
                          'name': 'St. Stephen School',
                          'numReviews': 67,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3801 Balboa Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 292,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 53},
                                            {'label': 'White', 'percentage': 20},
                                            {'label': 'Two or more races', 'percentage': 18},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8129,
                          'lat': 37.775269,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8129-St.-Thomas-The-Apostle-School/#College_success',
                              'profile': '/california/san-francisco/8129-St.-Thomas-The-Apostle-School/',
                              'reviews': '/california/san-francisco/8129-St.-Thomas-The-Apostle-School/#Reviews'},
                          'lon': -122.499664,
                          'name': 'St. Thomas The Apostle School',
                          'numReviews': 70,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Thomas More Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 311,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 42},
                                            {'label': 'Two or more races', 'percentage': 29},
                                            {'label': 'White', 'percentage': 22},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8130,
                          'lat': 37.712734,
                          'levelCode': 'e,m',
                          'links': {'collegeSuccess': '/california/san-francisco/8130-St.-Thomas-More/#College_success',
                                    'profile': '/california/san-francisco/8130-St.-Thomas-More/',
                                    'reviews': '/california/san-francisco/8130-St.-Thomas-More/#Reviews'},
                          'lon': -122.473557,
                          'name': 'St. Thomas More',
                          'numReviews': 58,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2350 Green Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 270,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 86},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8131,
                          'lat': 37.795864,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8131-Saint-Vincent-De-Paul/#College_success',
                              'profile': '/california/san-francisco/8131-Saint-Vincent-De-Paul/',
                              'reviews': '/california/san-francisco/8131-Saint-Vincent-De-Paul/#Reviews'},
                          'lon': -122.437836,
                          'name': 'Saint Vincent De Paul',
                          'numReviews': 23,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '360 9th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 262,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 47},
                                            {'label': 'Asian', 'percentage': 24},
                                            {'label': 'Two or more races', 'percentage': 20},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8132,
                          'lat': 37.781837,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8132-Star-Of-The-Sea-School/#College_success',
                              'profile': '/california/san-francisco/8132-Star-Of-The-Sea-School/',
                              'reviews': '/california/san-francisco/8132-Star-Of-The-Sea-School/#Reviews'},
                          'lon': -122.467552,
                          'name': 'Star Of The Sea School',
                          'numReviews': 41,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '5950 Geary Blvd',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 209,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 38},
                                            {'label': 'Asian', 'percentage': 34},
                                            {'label': 'Two or more races', 'percentage': 25},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8157,
                          'lat': 37.780235,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8157-St.-Monica-School/#College_success',
                              'profile': '/california/san-francisco/8157-St.-Monica-School/',
                              'reviews': '/california/san-francisco/8157-St.-Monica-School/#Reviews'},
                          'lon': -122.483299,
                          'name': 'St. Monica School',
                          'numReviews': 65,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1275 Sacramento Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 261,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 74},
                                            {'label': 'Asian', 'percentage': 10},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 4}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8443,
                          'lat': 37.792393,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8443-Cathedral-School-For-Boys/#College_success',
                              'profile': '/california/san-francisco/8443-Cathedral-School-For-Boys/',
                              'reviews': '/california/san-francisco/8443-Cathedral-School-For-Boys/#Reviews'},
                          'lon': -122.413895,
                          'name': 'Cathedral School For Boys',
                          'numReviews': 29,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '240 Industrial Street',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 95,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 12741,
                          'lat': 37.738651,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12741-Big-City-Montessori-School/#College_success',
                              'profile': '/california/san-francisco/12741-Big-City-Montessori-School/',
                              'reviews': '/california/san-francisco/12741-Big-City-Montessori-School/#Reviews'},
                          'lon': -122.405312,
                          'name': 'Big City Montessori School',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1550 Treat Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 18,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 61},
                                            {'label': 'Hispanic', 'percentage': 33},
                                            {'label': 'White', 'percentage': 6}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8470,
                          'lat': 37.74593,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8470-Oakes-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/8470-Oakes-Childrens-Center/',
                              'reviews': '/california/san-francisco/8470-Oakes-Childrens-Center/#Reviews'},
                          'lon': -122.412682,
                          'name': "Oakes Children's Center",
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1555 Mariposa Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 311,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 61},
                                            {'label': 'Two or more races', 'percentage': 24},
                                            {'label': 'Asian', 'percentage': 6},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8489,
                          'lat': 37.763538,
                          'levelCode': 'e,m',
                          'links': {'collegeSuccess': '/california/san-francisco/8489-Live-Oak-School/#College_success',
                                    'profile': '/california/san-francisco/8489-Live-Oak-School/',
                                    'reviews': '/california/san-francisco/8489-Live-Oak-School/#Reviews'},
                          'lon': -122.399063,
                          'name': 'Live Oak School',
                          'numReviews': 154,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '300 Gaven Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 276,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 50},
                                            {'label': 'Asian', 'percentage': 31},
                                            {'label': 'African American', 'percentage': 11},
                                            {'label': 'Hispanic', 'percentage': 9}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8490,
                          'lat': 37.73278,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8490-San-Francisco-School-The/#College_success',
                              'profile': '/california/san-francisco/8490-San-Francisco-School-The/',
                              'reviews': '/california/san-francisco/8490-San-Francisco-School-The/#Reviews'},
                          'lon': -122.411697,
                          'name': 'San Francisco School, The',
                          'numReviews': 38,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2750 Jackson Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 408,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 68},
                                            {'label': 'Asian', 'percentage': 18},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'Hispanic', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8545,
                          'lat': 37.791977,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8545-Town-School-For-Boys/#College_success',
                              'profile': '/california/san-francisco/8545-Town-School-For-Boys/',
                              'reviews': '/california/san-francisco/8545-Town-School-For-Boys/#Reviews'},
                          'lon': -122.439903,
                          'name': 'Town School For Boys',
                          'numReviews': 24,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '924 Balboa Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '7-12',
                          'highlighted': false,
                          'id': 8576,
                          'lat': 37.777,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8576-Challenge-To-Learning/#College_success',
                              'profile': '/california/san-francisco/8576-Challenge-To-Learning/',
                              'reviews': '/california/san-francisco/8576-Challenge-To-Learning/#Reviews'},
                          'lon': -122.468597,
                          'name': 'Challenge To Learning',
                          'numReviews': 1,
                          'parentRating': 2,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '200 Sloat Boulevard',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 517,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 86},
                                            {'label': 'White', 'percentage': 7},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8619,
                          'lat': 37.735237,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8619-West-Portal-Lutheran-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/8619-West-Portal-Lutheran-Elementary-School/',
                              'reviews': '/california/san-francisco/8619-West-Portal-Lutheran-Elementary-School/#Reviews'},
                          'lon': -122.473732,
                          'name': 'West Portal Lutheran Elementary School',
                          'numReviews': 63,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '495 9th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 141,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 57},
                                            {'label': 'Two or more races', 'percentage': 21},
                                            {'label': 'White', 'percentage': 13},
                                            {'label': 'African American', 'percentage': 9},
                                            {'label': 'Hispanic', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8620,
                          'lat': 37.77924,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8620-Zion-Lutheran-School/#College_success',
                              'profile': '/california/san-francisco/8620-Zion-Lutheran-School/',
                              'reviews': '/california/san-francisco/8620-Zion-Lutheran-School/#Reviews'},
                          'lon': -122.467636,
                          'name': 'Zion Lutheran School',
                          'numReviews': 26,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '25 Whittier Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 215,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 49},
                                            {'label': 'Asian', 'percentage': 40},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'White', 'percentage': 6}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 8668,
                          'lat': 37.710068,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8668-San-Francisco-Christian-School/#College_success',
                              'profile': '/california/san-francisco/8668-San-Francisco-Christian-School/',
                              'reviews': '/california/san-francisco/8668-San-Francisco-Christian-School/#Reviews'},
                          'lon': -122.447731,
                          'name': 'San Francisco Christian School',
                          'numReviews': 14,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '7070 California Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 400,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 65},
                                            {'label': 'Asian', 'percentage': 23},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8684,
                          'lat': 37.783684,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8684-Katherine-Delmar-Burke-School/#College_success',
                              'profile': '/california/san-francisco/8684-Katherine-Delmar-Burke-School/',
                              'reviews': '/california/san-francisco/8684-Katherine-Delmar-Burke-School/#Reviews'},
                          'lon': -122.493019,
                          'name': 'Katherine Delmar Burke School',
                          'numReviews': 20,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3065 Jackson Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 400,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 58},
                                            {'label': 'Asian', 'percentage': 16},
                                            {'label': 'Two or more races', 'percentage': 16},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8685,
                          'lat': 37.790936,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8685-San-Francisco-University-High-School/#College_success',
                              'profile': '/california/san-francisco/8685-San-Francisco-University-High-School/',
                              'reviews': '/california/san-francisco/8685-San-Francisco-University-High-School/#Reviews'},
                          'lon': -122.445419,
                          'name': 'San Francisco University High School',
                          'numReviews': 8,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2120 Broadway Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 410,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 58},
                                            {'label': 'Two or more races', 'percentage': 24},
                                            {'label': 'Asian', 'percentage': 10},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Hispanic', 'percentage': 4}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8688,
                          'lat': 37.794891,
                          'levelCode': 'e,m',
                          'links': {'collegeSuccess': '/california/san-francisco/8688-Hamlin-The/#College_success',
                                    'profile': '/california/san-francisco/8688-Hamlin-The/',
                                    'reviews': '/california/san-francisco/8688-Hamlin-The/#Reviews'},
                          'lon': -122.432426,
                          'name': 'Hamlin, The',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '755 Ocean Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 490,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 57},
                                            {'label': 'Asian', 'percentage': 21},
                                            {'label': 'African American', 'percentage': 11},
                                            {'label': 'Hispanic', 'percentage': 10},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8690,
                          'lat': 37.722351,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8690-Lick-Wilmerding-High-School/#College_success',
                              'profile': '/california/san-francisco/8690-Lick-Wilmerding-High-School/',
                              'reviews': '/california/san-francisco/8690-Lick-Wilmerding-High-School/#Reviews'},
                          'lon': -122.448921,
                          'name': 'Lick-Wilmerding High School',
                          'numReviews': 31,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '940 Grove St',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '1, 3-7',
                          'highlighted': false,
                          'id': 8714,
                          'lat': 37.776878,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8714-Burt-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/8714-Burt-Childrens-Center/',
                              'reviews': '/california/san-francisco/8714-Burt-Childrens-Center/#Reviews'},
                          'lon': -122.432755,
                          'name': "Burt Children's Center",
                          'numReviews': 1,
                          'parentRating': 1,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1570 Greenwich Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 8,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 50},
                                            {'label': 'Asian', 'percentage': 25},
                                            {'label': 'Hispanic', 'percentage': 25}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 8774,
                          'lat': 37.800369,
                          'levelCode': 'e,m,h',
                          'links': {'collegeSuccess': '/california/san-francisco/8774-Hergl/#College_success',
                                    'profile': '/california/san-francisco/8774-Hergl/',
                                    'reviews': '/california/san-francisco/8774-Hergl/#Reviews'},
                          'lon': -122.425774,
                          'name': 'Hergl',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2355 Lake Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 86,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 81},
                                            {'label': 'Asian', 'percentage': 15},
                                            {'label': 'Hispanic', 'percentage': 3}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8793,
                          'lat': 37.785507,
                          'levelCode': 'e,m',
                          'links': {'collegeSuccess': '/california/san-francisco/8793-Kittredge-Inc./#College_success',
                                    'profile': '/california/san-francisco/8793-Kittredge-Inc./',
                                    'reviews': '/california/san-francisco/8793-Kittredge-Inc./#Reviews'},
                          'lon': -122.484955,
                          'name': 'Kittredge, Inc.',
                          'numReviews': 18,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 9th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 64,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 84},
                                            {'label': 'Asian', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 2},
                                            {'label': 'Hispanic', 'percentage': 2}],
                          'gradeLevels': '1-8',
                          'highlighted': false,
                          'id': 8797,
                          'lat': 37.781921,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8797-The-Laurel-School/#College_success',
                              'profile': '/california/san-francisco/8797-The-Laurel-School/',
                              'reviews': '/california/san-francisco/8797-The-Laurel-School/#Reviews'},
                          'lon': -122.466759,
                          'name': 'The Laurel School',
                          'numReviews': 66,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1801 Vicente Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 44,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 34},
                                            {'label': 'African American', 'percentage': 32},
                                            {'label': 'Hispanic', 'percentage': 18},
                                            {'label': 'Asian', 'percentage': 11},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': '2-12',
                          'highlighted': false,
                          'id': 8806,
                          'lat': 37.737984,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8806-Edgewood-Center-For-Children--Families/#College_success',
                              'profile': '/california/san-francisco/8806-Edgewood-Center-For-Children--Families/',
                              'reviews': '/california/san-francisco/8806-Edgewood-Center-For-Children--Families/#Reviews'},
                          'lon': -122.486259,
                          'name': 'Edgewood Center For Children & Families',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '678 Portola Dr',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-5, 8',
                          'highlighted': false,
                          'id': 8809,
                          'lat': 37.74501,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8809-Maria-Montessori-School/#College_success',
                              'profile': '/california/san-francisco/8809-Maria-Montessori-School/',
                              'reviews': '/california/san-francisco/8809-Maria-Montessori-School/#Reviews'},
                          'lon': -122.45343,
                          'name': 'Maria Montessori School',
                          'numReviews': 12,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3839 Washington St',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 210,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 56},
                                            {'label': 'Two or more races', 'percentage': 22},
                                            {'label': 'Asian', 'percentage': 10},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 3}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 8857,
                          'lat': 37.788845,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8857-Presidio-Hill-School/#College_success',
                              'profile': '/california/san-francisco/8857-Presidio-Hill-School/',
                              'reviews': '/california/san-francisco/8857-Presidio-Hill-School/#Reviews'},
                          'lon': -122.456238,
                          'name': 'Presidio Hill School',
                          'numReviews': 60,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1234 Divisadero Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 10,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 60},
                                            {'label': 'Hispanic', 'percentage': 20},
                                            {'label': 'African American', 'percentage': 20}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 8872,
                          'lat': 37.781174,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8872-Hearing--Speech-Center-Of-Northern-California/#College_success',
                              'profile': '/california/san-francisco/8872-Hearing--Speech-Center-Of-Northern-California/',
                              'reviews': '/california/san-francisco/8872-Hearing--Speech-Center-Of-Northern-California/#Reviews'},
                          'lon': -122.438728,
                          'name': 'Hearing & Speech Center Of Northern California',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '838 Kearny Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 144,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 70},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'Asian', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Hispanic', 'percentage': 5}],
                          'gradeLevels': '5-12',
                          'highlighted': false,
                          'id': 8885,
                          'lat': 37.792,
                          'levelCode': 'e,m,h',
                          'links': {'collegeSuccess': '/california/san-francisco/8885-Sterne-School/#College_success',
                                    'profile': '/california/san-francisco/8885-Sterne-School/',
                                    'reviews': '/california/san-francisco/8885-Sterne-School/#Reviews'},
                          'lon': -122.439392,
                          'name': 'Sterne School',
                          'numReviews': 38,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1387 Valencia Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 194,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 52},
                                            {'label': 'Two or more races', 'percentage': 29},
                                            {'label': 'African American', 'percentage': 8},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'Asian', 'percentage': 5}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8889,
                          'lat': 37.7509,
                          'levelCode': 'e,m',
                          'links': {'collegeSuccess': '/california/san-francisco/8889-Synergy-School/#College_success',
                                    'profile': '/california/san-francisco/8889-Synergy-School/',
                                    'reviews': '/california/san-francisco/8889-Synergy-School/#Reviews'},
                          'lon': -122.420403,
                          'name': 'Synergy School',
                          'numReviews': 52,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1563 Page Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 378,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Two or more races', 'percentage': 50},
                                            {'label': 'White', 'percentage': 33},
                                            {'label': 'Pacific Islander', 'percentage': 7},
                                            {'label': 'Asian', 'percentage': 5},
                                            {'label': 'Hispanic', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 8900,
                          'lat': 37.770737,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8900-Urban-School-Of-San-Francisco/#College_success',
                              'profile': '/california/san-francisco/8900-Urban-School-Of-San-Francisco/',
                              'reviews': '/california/san-francisco/8900-Urban-School-Of-San-Francisco/#Reviews'},
                          'lon': -122.446457,
                          'name': 'Urban School Of San Francisco',
                          'numReviews': 13,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2521 Scott Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 29,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 63},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 20},
                                            {'label': 'Hispanic', 'percentage': 10},
                                            {'label': 'African American', 'percentage': 8}],
                          'gradeLevels': '1-8',
                          'highlighted': false,
                          'id': 8936,
                          'lat': 37.793324,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8936-Hillwood-Academic-Day-School/#College_success',
                              'profile': '/california/san-francisco/8936-Hillwood-Academic-Day-School/',
                              'reviews': '/california/san-francisco/8936-Hillwood-Academic-Day-School/#Reviews'},
                          'lon': -122.440117,
                          'name': 'Hillwood Academic Day School',
                          'numReviews': 3,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '66 Geneva Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 33,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 30},
                                            {'label': 'Asian', 'percentage': 30},
                                            {'label': 'Hispanic', 'percentage': 30},
                                            {'label': 'White', 'percentage': 9}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 9049,
                          'lat': 37.721748,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9049-San-Francisco-Adventist/#College_success',
                              'profile': '/california/san-francisco/9049-San-Francisco-Adventist/',
                              'reviews': '/california/san-francisco/9049-San-Francisco-Adventist/#Reviews'},
                          'lon': -122.451118,
                          'name': 'San Francisco Adventist',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '201 Eucalyptus Drive',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 140,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 67},
                                            {'label': 'Hispanic', 'percentage': 17},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 17}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 13163,
                          'lat': 37.73074,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13163-Lakeside-Presbyterian-Children-Center/#College_success',
                              'profile': '/california/san-francisco/13163-Lakeside-Presbyterian-Children-Center/',
                              'reviews': '/california/san-francisco/13163-Lakeside-Presbyterian-Children-Center/#Reviews'},
                          'lon': -122.475441,
                          'name': 'Lakeside Presbyterian Children Center',
                          'numReviews': 8,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '150 Oak Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 958,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 9121,
                          'lat': 37.775341,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9121-French-American-International-School/#College_success',
                              'profile': '/california/san-francisco/9121-French-American-International-School/',
                              'reviews': '/california/san-francisco/9121-French-American-International-School/#Reviews'},
                          'lon': -122.421753,
                          'name': 'French American International School',
                          'numReviews': 18,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2252 Broadway Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 370,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 68},
                                            {'label': 'Two or more races', 'percentage': 14},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Asian', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 26817,
                          'lat': 37.794376,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26817-Stuart-Hall-For-Boys/#College_success',
                              'profile': '/california/san-francisco/26817-Stuart-Hall-For-Boys/',
                              'reviews': '/california/san-francisco/26817-Stuart-Hall-For-Boys/#Reviews'},
                          'lon': -122.434319,
                          'name': 'Stuart Hall For Boys',
                          'numReviews': 7,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2938 Washington Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 472,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 66},
                                            {'label': 'Asian', 'percentage': 15},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 4}],
                          'gradeLevels': 'PK-8 & Ungraded',
                          'highlighted': false,
                          'id': 9265,
                          'lat': 37.790688,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9265-San-Francisco-Waldorf-School/#College_success',
                              'profile': '/california/san-francisco/9265-San-Francisco-Waldorf-School/',
                              'reviews': '/california/san-francisco/9265-San-Francisco-Waldorf-School/#Reviews'},
                          'lon': -122.441681,
                          'name': 'San Francisco Waldorf School',
                          'numReviews': 24,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1970 Ocean Avenue',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 60,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 83},
                                            {'label': 'African American', 'percentage': 10},
                                            {'label': 'White', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 1}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 12735,
                          'lat': 37.726357,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12735-Voice-Of-Pentecost-Academy/#College_success',
                              'profile': '/california/san-francisco/12735-Voice-Of-Pentecost-Academy/',
                              'reviews': '/california/san-francisco/12735-Voice-Of-Pentecost-Academy/#Reviews'},
                          'lon': -122.464035,
                          'name': 'Voice Of Pentecost Academy',
                          'numReviews': 5,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1325 Evans Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 7,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 9436,
                          'lat': 37.775558,
                          'levelCode': 'm,h',
                          'links': {'collegeSuccess': '/california/san-francisco/9436-Erikson-School/#College_success',
                                    'profile': '/california/san-francisco/9436-Erikson-School/',
                                    'reviews': '/california/san-francisco/9436-Erikson-School/#Reviews'},
                          'lon': -122.484276,
                          'name': 'Erikson School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '825 Brotherhood Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 138,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 95},
                                            {'label': 'Two or more races', 'percentage': 5}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 9437,
                          'lat': 37.713943,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9437-Kzv-Armenian-School/#College_success',
                              'profile': '/california/san-francisco/9437-Kzv-Armenian-School/',
                              'reviews': '/california/san-francisco/9437-Kzv-Armenian-School/#Reviews'},
                          'lon': -122.47831,
                          'name': 'Kzv Armenian School',
                          'numReviews': 11,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '801 Silver Avenue',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 698,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 93},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'Hispanic', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 9438,
                          'lat': 37.729637,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9438-Cornerstone-Academy-Silver-Campus/#College_success',
                              'profile': '/california/san-francisco/9438-Cornerstone-Academy-Silver-Campus/',
                              'reviews': '/california/san-francisco/9438-Cornerstone-Academy-Silver-Campus/#Reviews'},
                          'lon': -122.418808,
                          'name': 'Cornerstone Academy-Silver Campus',
                          'numReviews': 23,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2901 California Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 280,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 60},
                                            {'label': 'Two or more races', 'percentage': 16},
                                            {'label': 'Asian', 'percentage': 10},
                                            {'label': 'Hispanic', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 9538,
                          'lat': 37.787601,
                          'levelCode': 'h',
                          'links': {'collegeSuccess': '/california/san-francisco/9538-Drew-School/#College_success',
                                    'profile': '/california/san-francisco/9538-Drew-School/',
                                    'reviews': '/california/san-francisco/9538-Drew-School/#Reviews'},
                          'lon': -122.442398,
                          'name': 'Drew School',
                          'numReviews': 53,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '180 Fair Oaks Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 124,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 66},
                                            {'label': 'Two or more races', 'percentage': 17},
                                            {'label': 'Hispanic', 'percentage': 11},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 9563,
                          'lat': 37.7537,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9563-Adda-Clevenger-School/#College_success',
                              'profile': '/california/san-francisco/9563-Adda-Clevenger-School/',
                              'reviews': '/california/san-francisco/9563-Adda-Clevenger-School/#Reviews'},
                          'lon': -122.424522,
                          'name': 'Adda Clevenger School',
                          'numReviews': 68,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2222 Broadway Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 222,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 61},
                                            {'label': 'Hispanic', 'percentage': 14},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'Asian', 'percentage': 11},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 9643,
                          'lat': 37.794796,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9643-Convent-Of-The-Sacred-Heart-High-School/#College_success',
                              'profile': '/california/san-francisco/9643-Convent-Of-The-Sacred-Heart-High-School/',
                              'reviews': '/california/san-francisco/9643-Convent-Of-The-Sacred-Heart-High-School/#Reviews'},
                          'lon': -122.434135,
                          'name': 'Convent Of The Sacred Heart High School',
                          'numReviews': 8,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '655 Brotherhood Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 384,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 91},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'Hispanic', 'percentage': 1},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 8968,
                          'lat': 37.713284,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/8968-The-Brandeis-School-Of-San-Francisco/#College_success',
                              'profile': '/california/san-francisco/8968-The-Brandeis-School-Of-San-Francisco/',
                              'reviews': '/california/san-francisco/8968-The-Brandeis-School-Of-San-Francisco/#Reviews'},
                          'lon': -122.475601,
                          'name': 'The Brandeis School of San Francisco',
                          'numReviews': 325,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1499 Potrero Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 19,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 79},
                                            {'label': 'Two or more races', 'percentage': 21}],
                          'gradeLevels': 'K-6',
                          'highlighted': false,
                          'id': 9764,
                          'lat': 37.749603,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9764-Meadows-Livingstone-School/#College_success',
                              'profile': '/california/san-francisco/9764-Meadows-Livingstone-School/',
                              'reviews': '/california/san-francisco/9764-Meadows-Livingstone-School/#Reviews'},
                          'lon': -122.40506,
                          'name': 'Meadows-Livingstone School',
                          'numReviews': 12,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1760 Cesar Chavez Street, Suites Rs',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 34,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Two or more races', 'percentage': 41},
                                            {'label': 'African American', 'percentage': 29},
                                            {'label': 'Hispanic', 'percentage': 18},
                                            {'label': 'White', 'percentage': 9},
                                            {'label': 'Pacific Islander', 'percentage': 3}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 9866,
                          'lat': 37.750465,
                          'levelCode': 'e,m,h',
                          'links': {'collegeSuccess': '/california/san-francisco/9866-Rise-Institute/#College_success',
                                    'profile': '/california/san-francisco/9866-Rise-Institute/',
                                    'reviews': '/california/san-francisco/9866-Rise-Institute/#Reviews'},
                          'lon': -122.394951,
                          'name': 'Rise Institute',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 Masonic Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 352,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 61},
                                            {'label': 'Two or more races', 'percentage': 21},
                                            {'label': 'Asian', 'percentage': 11},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Hispanic', 'percentage': 3}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 9871,
                          'lat': 37.778042,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/9871-San-Francisco-Day-School/#College_success',
                              'profile': '/california/san-francisco/9871-San-Francisco-Day-School/',
                              'reviews': '/california/san-francisco/9871-San-Francisco-Day-School/#Reviews'},
                          'lon': -122.446281,
                          'name': 'San Francisco Day School',
                          'numReviews': 6,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '214 Haight Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '8-12',
                          'highlighted': false,
                          'id': 9935,
                          'lat': 37.772701,
                          'levelCode': 'm,h',
                          'links': {'collegeSuccess': '/california/san-francisco/9935-Walden-Academy/#College_success',
                                    'profile': '/california/san-francisco/9935-Walden-Academy/',
                                    'reviews': '/california/san-francisco/9935-Walden-Academy/#Reviews'},
                          'lon': -122.425797,
                          'name': 'Walden Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3801 3rd Street',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 13,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 92},
                                            {'label': 'Pacific Islander', 'percentage': 8}],
                          'gradeLevels': '7-12',
                          'highlighted': false,
                          'id': 10089,
                          'lat': 37.741543,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/10089-Joshua-Marie-Cameron-Academy/#College_success',
                              'profile': '/california/san-francisco/10089-Joshua-Marie-Cameron-Academy/',
                              'reviews': '/california/san-francisco/10089-Joshua-Marie-Cameron-Academy/#Reviews'},
                          'lon': -122.38726,
                          'name': 'Joshua Marie Cameron Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1555 Irving Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 51,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 73},
                                            {'label': 'White', 'percentage': 16},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 10102,
                          'lat': 37.763432,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/10102-Woodside-International-School/#College_success',
                              'profile': '/california/san-francisco/10102-Woodside-International-School/',
                              'reviews': '/california/san-francisco/10102-Woodside-International-School/#Reviews'},
                          'lon': -122.474747,
                          'name': 'Woodside International School',
                          'numReviews': 16,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'P.O. Box 347215',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 10119,
                          'lat': 37.720203,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/10119-Senior-Martin-College-Preparatory-School/#College_success',
                              'profile': '/california/san-francisco/10119-Senior-Martin-College-Preparatory-School/',
                              'reviews': '/california/san-francisco/10119-Senior-Martin-College-Preparatory-School/#Reviews'},
                          'lon': -122.409912,
                          'name': 'Senior Martin College Preparatory School',
                          'numReviews': 8,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '150 Oak Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 516,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 68},
                                            {'label': 'White', 'percentage': 30},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 10205,
                          'lat': 37.775341,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/10205-Chinese-American-International-School/#College_success',
                              'profile': '/california/san-francisco/10205-Chinese-American-International-School/',
                              'reviews': '/california/san-francisco/10205-Chinese-American-International-School/#Reviews'},
                          'lon': -122.421753,
                          'name': 'Chinese American International School',
                          'numReviews': 44,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1925 Lawton Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 17,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-2',
                          'highlighted': false,
                          'id': 13189,
                          'lat': 37.75742,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13189-Cornerstone-Academy-Lawton-Campus/#College_success',
                              'profile': '/california/san-francisco/13189-Cornerstone-Academy-Lawton-Campus/',
                              'reviews': '/california/san-francisco/13189-Cornerstone-Academy-Lawton-Campus/#Reviews'},
                          'lon': -122.483665,
                          'name': 'Cornerstone Academy-Lawton Campus',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '333 Dolores Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 441,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 50},
                                            {'label': 'Two or more races', 'percentage': 30},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Asian', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 10396,
                          'lat': 37.763901,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/10396-Childrens-Day-School/#College_success',
                              'profile': '/california/san-francisco/10396-Childrens-Day-School/',
                              'reviews': '/california/san-francisco/10396-Childrens-Day-School/#Reviews'},
                          'lon': -122.425117,
                          'name': "Children's Day School",
                          'numReviews': 98,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '150 Parker Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 13157,
                          'lat': 37.782818,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13157-One-Fifty-Parker-School/#College_success',
                              'profile': '/california/san-francisco/13157-One-Fifty-Parker-School/',
                              'reviews': '/california/san-francisco/13157-One-Fifty-Parker-School/#Reviews'},
                          'lon': -122.454102,
                          'name': 'One Fifty Parker School',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3200 California Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 32357,
                          'lat': 37.78735,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32357-Helen-Diller-Family-Preschool/#College_success',
                              'profile': '/california/san-francisco/32357-Helen-Diller-Family-Preschool/',
                              'reviews': '/california/san-francisco/32357-Helen-Diller-Family-Preschool/#Reviews'},
                          'lon': -122.447197,
                          'name': 'Helen Diller Family Preschool',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '645 14th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 37,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 100}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 10622,
                          'lat': 37.776123,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/10622-Lisa-Kampner-Hebrew-Academy/#College_success',
                              'profile': '/california/san-francisco/10622-Lisa-Kampner-Hebrew-Academy/',
                              'reviews': '/california/san-francisco/10622-Lisa-Kampner-Hebrew-Academy/#Reviews'},
                          'lon': -122.472862,
                          'name': 'Lisa Kampner Hebrew Academy',
                          'numReviews': 2,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1325 Florida St',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '50%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 1073,
                          'districtName': 'San Francisco County Office Of Education School District',
                          'enrollment': 41,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 95},
                                            {'label': 'Hispanic', 'percentage': 80},
                                            {'label': 'African American', 'percentage': 15}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 11135,
                          'lat': 37.75074,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11135-S.F.-County-Opportunity-Hilltop-School/#College_success',
                              'profile': '/california/san-francisco/11135-S.F.-County-Opportunity-Hilltop-School/',
                              'reviews': '/california/san-francisco/11135-S.F.-County-Opportunity-Hilltop-School/#Reviews'},
                          'lon': -122.409798,
                          'name': 'S.F. County Opportunity (Hilltop) School',
                          'numReviews': 1,
                          'parentRating': 1,
                          'percentLowIncome': '95%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 26,
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1350 7th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '58%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 186,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 53},
                                            {'label': 'Hispanic', 'percentage': 39},
                                            {'label': 'White', 'percentage': 23},
                                            {'label': 'African American', 'percentage': 10},
                                            {'label': 'Asian', 'percentage': 9},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 11136,
                          'lat': 37.763245,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11136-Independence-High-School/#College_success',
                              'profile': '/california/san-francisco/11136-Independence-High-School/',
                              'reviews': '/california/san-francisco/11136-Independence-High-School/#Reviews'},
                          'lon': -122.463821,
                          'name': 'Independence High School',
                          'numReviews': 2,
                          'parentRating': 4,
                          'percentLowIncome': '53%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'College Readiness Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '334 28th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 8,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 50},
                                            {'label': 'Asian', 'percentage': 25},
                                            {'label': 'Hispanic', 'percentage': 25}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 11256,
                          'lat': 37.783024,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11256-Mother-Goose-School/#College_success',
                              'profile': '/california/san-francisco/11256-Mother-Goose-School/',
                              'reviews': '/california/san-francisco/11256-Mother-Goose-School/#Reviews'},
                          'lon': -122.487869,
                          'name': 'Mother Goose School',
                          'numReviews': 15,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1187 Franklin Street',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 38,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 38},
                                            {'label': 'Hispanic', 'percentage': 25},
                                            {'label': 'African American', 'percentage': 13},
                                            {'label': 'Asian', 'percentage': 13},
                                            {'label': 'Two or more races', 'percentage': 13}],
                          'gradeLevels': 'K-1',
                          'highlighted': false,
                          'id': 12920,
                          'lat': 37.78529,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12920-Montessori-House-Of-Children-School/#College_success',
                              'profile': '/california/san-francisco/12920-Montessori-House-Of-Children-School/',
                              'reviews': '/california/san-francisco/12920-Montessori-House-Of-Children-School/#Reviews'},
                          'lon': -122.423286,
                          'name': 'Montessori House of Children School',
                          'numReviews': 46,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1209 Geneva Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 11441,
                          'lat': 37.714199,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11441-Living-Hope-Christian/#College_success',
                              'profile': '/california/san-francisco/11441-Living-Hope-Christian/',
                              'reviews': '/california/san-francisco/11441-Living-Hope-Christian/#Reviews'},
                          'lon': -122.435997,
                          'name': 'Living Hope Christian',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '230 Jones Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 118,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 51},
                                            {'label': 'Asian', 'percentage': 27},
                                            {'label': 'African American', 'percentage': 16},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 11493,
                          'lat': 37.783497,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11493-San-Francisco-City-Academy/#College_success',
                              'profile': '/california/san-francisco/11493-San-Francisco-City-Academy/',
                              'reviews': '/california/san-francisco/11493-San-Francisco-City-Academy/#Reviews'},
                          'lon': -122.412407,
                          'name': 'San Francisco City Academy',
                          'numReviews': 1,
                          'parentRating': 2,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '887 Potrero Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 19,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 53},
                                            {'label': 'Hispanic', 'percentage': 21},
                                            {'label': 'White', 'percentage': 16},
                                            {'label': 'Two or more races', 'percentage': 11}],
                          'gradeLevels': '5-12',
                          'highlighted': false,
                          'id': 11496,
                          'lat': 37.758465,
                          'levelCode': 'e,m,h',
                          'links': {'collegeSuccess': '/california/san-francisco/11496-Seneca-Center/#College_success',
                                    'profile': '/california/san-francisco/11496-Seneca-Center/',
                                    'reviews': '/california/san-francisco/11496-Seneca-Center/#Reviews'},
                          'lon': -122.406525,
                          'name': 'Seneca Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '6210 Geary Boulevard',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 49,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 98},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 11503,
                          'lat': 37.780224,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11503-St.-John-Of-San-Francisco-Orthodox-Acade/#College_success',
                              'profile': '/california/san-francisco/11503-St.-John-Of-San-Francisco-Orthodox-Acade/',
                              'reviews': '/california/san-francisco/11503-St.-John-Of-San-Francisco-Orthodox-Acade/#Reviews'},
                          'lon': -122.485947,
                          'name': 'St. John Of San Francisco Orthodox Acade',
                          'numReviews': 9,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '169 Steuart Street',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 36,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 50},
                                            {'label': 'African American', 'percentage': 43},
                                            {'label': 'Two or more races', 'percentage': 7}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 11536,
                          'lat': 37.792747,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11536-Youth-Chance-High-School/#College_success',
                              'profile': '/california/san-francisco/11536-Youth-Chance-High-School/',
                              'reviews': '/california/san-francisco/11536-Youth-Chance-High-School/#Reviews'},
                          'lon': -122.391945,
                          'name': 'Youth Chance High School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1335 Guerrero Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 84,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 77},
                                            {'label': 'Hispanic', 'percentage': 13},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'Asian', 'percentage': 4}],
                          'gradeLevels': 'PK-5',
                          'highlighted': false,
                          'id': 11542,
                          'lat': 37.74984,
                          'levelCode': 'p,e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/11542-Katherine-Michiels-School/#College_success',
                              'profile': '/california/san-francisco/11542-Katherine-Michiels-School/',
                              'reviews': '/california/san-francisco/11542-Katherine-Michiels-School/#Reviews'},
                          'lon': -122.422401,
                          'name': 'Katherine Michiels School',
                          'numReviews': 35,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '950 Clement Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 8,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-11',
                          'highlighted': false,
                          'id': 13053,
                          'lat': 37.782726,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13053-Olympia-Institute/#College_success',
                              'profile': '/california/san-francisco/13053-Olympia-Institute/',
                              'reviews': '/california/san-francisco/13053-Olympia-Institute/#Reviews'},
                          'lon': -122.469505,
                          'name': 'Olympia Institute',
                          'numReviews': 2,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3531 22nd Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 730,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 75,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 82,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'Asian', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 12048,
                          'lat': 37.754936,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12048-Edison-Charter-Academy/#College_success',
                              'profile': '/california/san-francisco/12048-Edison-Charter-Academy/',
                              'reviews': '/california/san-francisco/12048-Edison-Charter-Academy/#Reviews'},
                          'lon': -122.426201,
                          'name': 'Edison Charter Academy',
                          'numReviews': 84,
                          'parentRating': 4,
                          'percentLowIncome': '75%',
                          'pinned': null,
                          'rating': 4,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 21,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3045 Santiago St',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 1073,
                          'districtName': 'San Francisco County Office Of Education School District',
                          'enrollment': 74,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 26}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 12608,
                          'lat': 37.743843,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12608-S.F.-County-Special-Education-School/#College_success',
                              'profile': '/california/san-francisco/12608-S.F.-County-Special-Education-School/',
                              'reviews': '/california/san-francisco/12608-S.F.-County-Special-Education-School/#Reviews'},
                          'lon': -122.499893,
                          'name': 'S.F. County Special Education School',
                          'numReviews': 1,
                          'parentRating': 0,
                          'percentLowIncome': '26%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {'College Readiness Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1060 Key Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 312,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 84,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'African American',
                                             'percentage': 39,
                                             'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 47,
                                             'rating': 5},
                                            {'label': 'Pacific Islander', 'percentage': 4},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '5-8',
                          'highlighted': false,
                          'id': 12638,
                          'lat': 37.719028,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12638-KIPP-Bayview-Academy/#College_success',
                              'profile': '/california/san-francisco/12638-KIPP-Bayview-Academy/',
                              'reviews': '/california/san-francisco/12638-KIPP-Bayview-Academy/#Reviews'},
                          'lon': -122.396111,
                          'name': 'KIPP Bayview Academy',
                          'numReviews': 7,
                          'parentRating': 4,
                          'percentLowIncome': '84%',
                          'pinned': null,
                          'rating': 4,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1430 Scott Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 369,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 85,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 5},
                                            {'label': 'African American',
                                             'percentage': 18,
                                             'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 67,
                                             'rating': 5},
                                            {'label': 'White', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '5-8',
                          'highlighted': false,
                          'id': 12639,
                          'lat': 37.783131,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12639-KIPP-San-Francisco-Bay-Academy/#College_success',
                              'profile': '/california/san-francisco/12639-KIPP-San-Francisco-Bay-Academy/',
                              'reviews': '/california/san-francisco/12639-KIPP-San-Francisco-Bay-Academy/#Reviews'},
                          'lon': -122.437729,
                          'name': 'KIPP San Francisco Bay Academy',
                          'numReviews': 10,
                          'parentRating': 4,
                          'percentLowIncome': '85%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 5},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '464 Diamond Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 11,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 94},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 6}],
                          'gradeLevels': 'K',
                          'highlighted': false,
                          'id': 13372,
                          'lat': 37.754986,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13372-Eureka-Learning-Center/#College_success',
                              'profile': '/california/san-francisco/13372-Eureka-Learning-Center/',
                              'reviews': '/california/san-francisco/13372-Eureka-Learning-Center/#Reviews'},
                          'lon': -122.436852,
                          'name': 'Eureka Learning Center',
                          'numReviews': 17,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '675 Minna Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 13467,
                          'lat': 37.777393,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13467-Holy-Family-Day-Home/#College_success',
                              'profile': '/california/san-francisco/13467-Holy-Family-Day-Home/',
                              'reviews': '/california/san-francisco/13467-Holy-Family-Day-Home/#Reviews'},
                          'lon': -122.412056,
                          'name': 'Holy Family Day Home',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1715 Octavia Street',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 203,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 53},
                                            {'label': 'Hispanic', 'percentage': 16},
                                            {'label': 'Asian', 'percentage': 13},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'African American', 'percentage': 6}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 13500,
                          'lat': 37.788631,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13500-Stuart-Hall-High-School/#College_success',
                              'profile': '/california/san-francisco/13500-Stuart-Hall-High-School/',
                              'reviews': '/california/san-francisco/13500-Stuart-Hall-High-School/#Reviews'},
                          'lon': -122.427277,
                          'name': 'Stuart Hall High School',
                          'numReviews': 11,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '250 Valencia Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 436,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 51},
                                            {'label': 'Two or more races', 'percentage': 31},
                                            {'label': 'Asian', 'percentage': 9},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 13615,
                          'lat': 37.768978,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13615-San-Francisco-Friends-School/#College_success',
                              'profile': '/california/san-francisco/13615-San-Francisco-Friends-School/',
                              'reviews': '/california/san-francisco/13615-San-Francisco-Friends-School/#Reviews'},
                          'lon': -122.422905,
                          'name': 'San Francisco Friends School',
                          'numReviews': 56,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '325 La Grande Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '74%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 276,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 76,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'African American',
                                             'percentage': 16,
                                             'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 64,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 7},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 13756,
                          'lat': 37.720005,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13756-City-Arts-And-Tech-High-School/#College_success',
                              'profile': '/california/san-francisco/13756-City-Arts-And-Tech-High-School/',
                              'reviews': '/california/san-francisco/13756-City-Arts-And-Tech-High-School/#Reviews'},
                          'lon': -122.425529,
                          'name': 'City Arts And Tech High School',
                          'numReviews': 10,
                          'parentRating': 4,
                          'percentLowIncome': '76%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'College Readiness Rating': 2,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '727 Golden Gate Avenue',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '11%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 1073,
                          'districtName': 'San Francisco County Office Of Education School District',
                          'enrollment': 73,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 74},
                                            {'label': 'African American', 'percentage': 42},
                                            {'label': 'Hispanic', 'percentage': 32},
                                            {'label': 'Two or more races', 'percentage': 10},
                                            {'label': 'Pacific Islander', 'percentage': 10},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 13785,
                          'lat': 37.780689,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/13785-S.F.-County-Civic-Center-Secondary/#College_success',
                              'profile': '/california/san-francisco/13785-S.F.-County-Civic-Center-Secondary/',
                              'reviews': '/california/san-francisco/13785-S.F.-County-Civic-Center-Secondary/#Reviews'},
                          'lon': -122.42308,
                          'name': 'S.F. County Civic Center Secondary',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '74%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '750 25th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 1262,
                          'districtName': 'San Francisco County Rop School District',
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '10-12',
                          'highlighted': false,
                          'id': 14999,
                          'lat': 37.775303,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/14999-San-Francisco-County-Rop-School/#College_success',
                              'profile': '/california/san-francisco/14999-San-Francisco-County-Rop-School/',
                              'reviews': '/california/san-francisco/14999-San-Francisco-County-Rop-School/#Reviews'},
                          'lon': -122.48436,
                          'name': 'San Francisco County Rop School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '495 Cambridge St',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 15117,
                          'lat': 37.725723,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/15117-Fellowship-Academy--Pre-School/#College_success',
                              'profile': '/california/san-francisco/15117-Fellowship-Academy--Pre-School/',
                              'reviews': '/california/san-francisco/15117-Fellowship-Academy--Pre-School/#Reviews'},
                          'lon': -122.417313,
                          'name': 'Fellowship Academy & Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '175 Golden Gate Avenue',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 118,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Hispanic', 'percentage': 68},
                                            {'label': 'Pacific Islander', 'percentage': 19},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 3}],
                          'gradeLevels': '4-8',
                          'highlighted': false,
                          'id': 15193,
                          'lat': 37.781658,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/15193-De-Marillac-Academy/#College_success',
                              'profile': '/california/san-francisco/15193-De-Marillac-Academy/',
                              'reviews': '/california/san-francisco/15193-De-Marillac-Academy/#Reviews'},
                          'lon': -122.413345,
                          'name': 'De Marillac Academy',
                          'numReviews': 19,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3301 Balboa Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 48,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 95},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 15297,
                          'lat': 37.775639,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/15297-Russian-American-International-School/#College_success',
                              'profile': '/california/san-francisco/15297-Russian-American-International-School/',
                              'reviews': '/california/san-francisco/15297-Russian-American-International-School/#Reviews'},
                          'lon': -122.494286,
                          'name': 'Russian American International School',
                          'numReviews': 5,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1218 South Van Ness Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 30,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 47},
                                            {'label': 'Hispanic', 'percentage': 43},
                                            {'label': 'White', 'percentage': 7},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 3}],
                          'gradeLevels': '8-12',
                          'highlighted': false,
                          'id': 15298,
                          'lat': 37.753468,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/15298-Sand-Paths-Academy/#College_success',
                              'profile': '/california/san-francisco/15298-Sand-Paths-Academy/',
                              'reviews': '/california/san-francisco/15298-Sand-Paths-Academy/#Reviews'},
                          'lon': -122.416718,
                          'name': 'Sand Paths Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '834 28th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 43,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 100}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 16584,
                          'lat': 37.773602,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16584-Bais-Menachem-Yeshiva-Day-School/#College_success',
                              'profile': '/california/san-francisco/16584-Bais-Menachem-Yeshiva-Day-School/',
                              'reviews': '/california/san-francisco/16584-Bais-Menachem-Yeshiva-Day-School/#Reviews'},
                          'lon': -122.48719,
                          'name': 'Bais Menachem Yeshiva Day School',
                          'numReviews': 2,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '35 Keyes Avenue',
                                      'street2': '',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 353,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 70},
                                            {'label': 'Two or more races', 'percentage': 16},
                                            {'label': 'Asian', 'percentage': 8},
                                            {'label': 'Hispanic', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 16585,
                          'lat': 37.800777,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16585-The-Bay-School-Of-San-Francisco/#College_success',
                              'profile': '/california/san-francisco/16585-The-Bay-School-Of-San-Francisco/',
                              'reviews': '/california/san-francisco/16585-The-Bay-School-Of-San-Francisco/#Reviews'},
                          'lon': -122.455643,
                          'name': 'The Bay School Of San Francisco',
                          'numReviews': 30,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '5655 Mission Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '1-12',
                          'highlighted': false,
                          'id': 16586,
                          'lat': 37.710278,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16586-Calvary-Baptist-Academy/#College_success',
                              'profile': '/california/san-francisco/16586-Calvary-Baptist-Academy/',
                              'reviews': '/california/san-francisco/16586-Calvary-Baptist-Academy/#Reviews'},
                          'lon': -122.447685,
                          'name': 'Calvary Baptist Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2222 Broadway Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 355,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 16588,
                          'lat': 37.794796,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16588-Convent-Of-The-Sacred-Heart-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/16588-Convent-Of-The-Sacred-Heart-Elementary-School/',
                              'reviews': '/california/san-francisco/16588-Convent-Of-The-Sacred-Heart-Elementary-School/#Reviews'},
                          'lon': -122.434135,
                          'name': 'Convent Of The Sacred Heart Elementary School',
                          'numReviews': 19,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2521 Scott Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 8,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K',
                          'highlighted': false,
                          'id': 16589,
                          'lat': 37.793324,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16589-Cow-Hollow-Kindergarten/#College_success',
                              'profile': '/california/san-francisco/16589-Cow-Hollow-Kindergarten/',
                              'reviews': '/california/san-francisco/16589-Cow-Hollow-Kindergarten/#Reviews'},
                          'lon': -122.440117,
                          'name': 'Cow Hollow Kindergarten',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '95 Gough Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 54,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 57},
                                            {'label': 'White', 'percentage': 26},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 7}],
                          'gradeLevels': '7-12',
                          'highlighted': false,
                          'id': 16593,
                          'lat': 37.773998,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16593-Spectrum-Center-Inc/#College_success',
                              'profile': '/california/san-francisco/16593-Spectrum-Center-Inc/',
                              'reviews': '/california/san-francisco/16593-Spectrum-Center-Inc/#Reviews'},
                          'lon': -122.422401,
                          'name': 'Spectrum Center, Inc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1600 Holloway Ave Rls Department',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '10-12',
                          'highlighted': false,
                          'id': 16596,
                          'lat': 37.720936,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16596-Tall-Ship-Education-Academy/#College_success',
                              'profile': '/california/san-francisco/16596-Tall-Ship-Education-Academy/',
                              'reviews': '/california/san-francisco/16596-Tall-Ship-Education-Academy/#Reviews'},
                          'lon': -122.477234,
                          'name': 'Tall Ship Education Academy',
                          'numReviews': 1,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '259 Judson Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 8,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Two or more races', 'percentage': 100}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 16597,
                          'lat': 37.72802,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16597-Wen-Jian-Ying-School/#College_success',
                              'profile': '/california/san-francisco/16597-Wen-Jian-Ying-School/',
                              'reviews': '/california/san-francisco/16597-Wen-Jian-Ying-School/#Reviews'},
                          'lon': -122.447571,
                          'name': 'Wen Jian Ying School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2550 25th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 502,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 24,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 29, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 13,
                                             'rating': 8},
                                            {'label': 'White', 'percentage': 34, 'rating': 9},
                                            {'label': 'Two or more races', 'percentage': 11},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 16863,
                          'lat': 37.739964,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/16863-Feinstein-Dianne-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/16863-Feinstein-Dianne-Elementary-School/',
                              'reviews': '/california/san-francisco/16863-Feinstein-Dianne-Elementary-School/#Reviews'},
                          'lon': -122.48185,
                          'name': 'Feinstein (Dianne) Elementary School',
                          'numReviews': 65,
                          'parentRating': 4,
                          'percentLowIncome': '24%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 8,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '5048 Third Street',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 17359,
                          'lat': 37.732788,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/17359-Muhammad-University-Of-Islam/#College_success',
                              'profile': '/california/san-francisco/17359-Muhammad-University-Of-Islam/',
                              'reviews': '/california/san-francisco/17359-Muhammad-University-Of-Islam/#Reviews'},
                          'lon': -122.391777,
                          'name': 'Muhammad University of Islam',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Fell Street',
                                      'street2': null,
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 32559,
                          'lat': 37.776821,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32559-LePort-Montessori-San-Francisco-Mid-Market/#College_success',
                              'profile': '/california/san-francisco/32559-LePort-Montessori-San-Francisco-Mid-Market/',
                              'reviews': '/california/san-francisco/32559-LePort-Montessori-San-Francisco-Mid-Market/#Reviews'},
                          'lon': -122.418724,
                          'name': 'LePort Montessori San Francisco Mid-Market',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '450 Somerset Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 212,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 89},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Hispanic', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'Two or more races', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 25495,
                          'lat': 37.725716,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25495-Alta-Vista-School/#College_success',
                              'profile': '/california/san-francisco/25495-Alta-Vista-School/',
                              'reviews': '/california/san-francisco/25495-Alta-Vista-School/#Reviews'},
                          'lon': -122.407822,
                          'name': 'Alta Vista School',
                          'numReviews': 74,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '722 South Van Ness Avenue',
                                      'street2': null,
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 32424,
                          'lat': 37.761311,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32424-Petite-Explorer-Preschool/#College_success',
                              'profile': '/california/san-francisco/32424-Petite-Explorer-Preschool/',
                              'reviews': '/california/san-francisco/32424-Petite-Explorer-Preschool/#Reviews'},
                          'lon': -122.417389,
                          'name': 'Petite Explorer Preschool',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1566 32nd Ave',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 31647,
                          'lat': 37.757992,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31647-San-Francisco-Montessori-Academy/#College_success',
                              'profile': '/california/san-francisco/31647-San-Francisco-Montessori-Academy/',
                              'reviews': '/california/san-francisco/31647-San-Francisco-Montessori-Academy/#Reviews'},
                          'lon': -122.490379,
                          'name': 'San Francisco Montessori Academy',
                          'numReviews': 20,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '301 De Montfort Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 325,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 41},
                                            {'label': 'Asian', 'percentage': 35},
                                            {'label': 'Hispanic', 'percentage': 12},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 4}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 24975,
                          'lat': 37.723511,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24975-Stratford-School/#College_success',
                              'profile': '/california/san-francisco/24975-Stratford-School/',
                              'reviews': '/california/san-francisco/24975-Stratford-School/#Reviews'},
                          'lon': -122.461494,
                          'name': 'Stratford School',
                          'numReviews': 48,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1 Carlton B Goodlett Pl',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22195,
                          'lat': 37.778557,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22195-S-F-City-Hall-Child-Care-Center--Preschool-Marin/#College_success',
                              'profile': '/california/san-francisco/22195-S-F-City-Hall-Child-Care-Center--Preschool-Marin/',
                              'reviews': '/california/san-francisco/22195-S-F-City-Hall-Child-Care-Center--Preschool-Marin/#Reviews'},
                          'lon': -122.418327,
                          'name': 'S F City Hall Child Care Center & Preschool-Marin',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22196,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22196-Eoc-Sojourner-Truth-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22196-Eoc-Sojourner-Truth-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22196-Eoc-Sojourner-Truth-Child-Care-Center/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Eoc-Sojourner Truth Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1 Carlton B Goodlett Pl',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22197,
                          'lat': 37.778847,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22197-Marin-Day-School/#College_success',
                              'profile': '/california/san-francisco/22197-Marin-Day-School/',
                              'reviews': '/california/san-francisco/22197-Marin-Day-School/#Reviews'},
                          'lon': -122.419029,
                          'name': 'Marin Day School',
                          'numReviews': 10,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '100 Whitney Young Circle',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22198,
                          'lat': 37.734062,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22198-Fcs-Whitney-Young-Chld-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22198-Fcs-Whitney-Young-Chld-Development-Center/',
                              'reviews': '/california/san-francisco/22198-Fcs-Whitney-Young-Chld-Development-Center/#Reviews'},
                          'lon': -122.38414,
                          'name': 'Fcs Whitney Young Chld Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '831 Broadway Second Floor',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22199,
                          'lat': 37.805988,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22199-Wu-Yee-Children-Services/#College_success',
                              'profile': '/california/san-francisco/22199-Wu-Yee-Children-Services/',
                              'reviews': '/california/san-francisco/22199-Wu-Yee-Children-Services/#Reviews'},
                          'lon': -122.409912,
                          'name': 'Wu Yee Children Services',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1011 Garfield Street',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22200,
                          'lat': 37.719376,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22200-Our-Savior-Lutheran-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22200-Our-Savior-Lutheran-Pre-School/',
                              'reviews': '/california/san-francisco/22200-Our-Savior-Lutheran-Pre-School/#Reviews'},
                          'lon': -122.472099,
                          'name': 'Our Savior Lutheran Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '102 San Jose Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22201,
                          'lat': 37.753269,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22201-The-Pre-School/#College_success',
                                    'profile': '/california/san-francisco/22201-The-Pre-School/',
                                    'reviews': '/california/san-francisco/22201-The-Pre-School/#Reviews'},
                          'lon': -122.421783,
                          'name': 'The Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 31128',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22202,
                          'lat': 37.740105,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22202-Noe-Valley-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22202-Noe-Valley-Nursery-School/',
                              'reviews': '/california/san-francisco/22202-Noe-Valley-Nursery-School/#Reviews'},
                          'lon': -122.438232,
                          'name': 'Noe Valley Nursery School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1042 Jamestown Ave',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22203,
                          'lat': 37.719162,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22203-Martha-Hills-Learning-Center/#College_success',
                              'profile': '/california/san-francisco/22203-Martha-Hills-Learning-Center/',
                              'reviews': '/california/san-francisco/22203-Martha-Hills-Learning-Center/#Reviews'},
                          'lon': -122.394241,
                          'name': 'Martha Hills Learning Center',
                          'numReviews': 2,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1050 Goettingen Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22204,
                          'lat': 37.718948,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22204-Creative-Child-Care/#College_success',
                              'profile': '/california/san-francisco/22204-Creative-Child-Care/',
                              'reviews': '/california/san-francisco/22204-Creative-Child-Care/#Reviews'},
                          'lon': -122.403618,
                          'name': 'Creative Child Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1050 Kirkham Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22205,
                          'lat': 37.760242,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22205-Learning-Bridge-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22205-Learning-Bridge-Pre-School/',
                              'reviews': '/california/san-francisco/22205-Learning-Bridge-Pre-School/#Reviews'},
                          'lon': -122.472206,
                          'name': 'Learning Bridge Pre-School',
                          'numReviews': 16,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '205 13th Street #3280',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22206,
                          'lat': 37.769688,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22206-Sfsu-Ella-Hill-Hutch-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22206-Sfsu-Ella-Hill-Hutch-Head-Start/',
                              'reviews': '/california/san-francisco/22206-Sfsu-Ella-Hill-Hutch-Head-Start/#Reviews'},
                          'lon': -122.419464,
                          'name': 'Sfsu-Ella Hill Hutch Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1050 S Van Ness Ave',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22207,
                          'lat': 37.756462,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22207-Mandala-Early-Learning-Preschool/#College_success',
                              'profile': '/california/san-francisco/22207-Mandala-Early-Learning-Preschool/',
                              'reviews': '/california/san-francisco/22207-Mandala-Early-Learning-Preschool/#Reviews'},
                          'lon': -122.41703,
                          'name': 'Mandala Early Learning Preschool',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2641 25th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22208,
                          'lat': 37.755417,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22208-Bryan-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22208-Bryan-Childrens-Center/',
                              'reviews': '/california/san-francisco/22208-Bryan-Childrens-Center/#Reviews'},
                          'lon': -122.408577,
                          'name': 'Bryan Childrens Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 27342',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22209,
                          'lat': 37.734646,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22209-Happy-Times-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22209-Happy-Times-Nursery-School/',
                              'reviews': '/california/san-francisco/22209-Happy-Times-Nursery-School/#Reviews'},
                          'lon': -122.463707,
                          'name': 'Happy Times Nursery School',
                          'numReviews': 3,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1145 Cabrillo Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22210,
                          'lat': 37.774891,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22210-Grace-Fountain-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22210-Grace-Fountain-Pre-School/',
                              'reviews': '/california/san-francisco/22210-Grace-Fountain-Pre-School/#Reviews'},
                          'lon': -122.471085,
                          'name': 'Grace Fountain Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 170238',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22211,
                          'lat': 37.771717,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22211-Haight-Ashbury-Co-Op-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22211-Haight-Ashbury-Co-Op-Nursery-School/',
                              'reviews': '/california/san-francisco/22211-Haight-Ashbury-Co-Op-Nursery-School/#Reviews'},
                          'lon': -122.443893,
                          'name': 'Haight Ashbury Co-Op Nursery School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1060 Tennessee Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22212,
                          'lat': 37.758533,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22212-Friends-Of-Potrero-Hill-Nursery/#College_success',
                              'profile': '/california/san-francisco/22212-Friends-Of-Potrero-Hill-Nursery/',
                              'reviews': '/california/san-francisco/22212-Friends-Of-Potrero-Hill-Nursery/#Reviews'},
                          'lon': -122.389328,
                          'name': 'Friends Of Potrero Hill Nursery',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1223 Webster Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22213,
                          'lat': 37.782291,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22213-Little-Childrens-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22213-Little-Childrens-Development-Center/',
                              'reviews': '/california/san-francisco/22213-Little-Childrens-Development-Center/#Reviews'},
                          'lon': -122.431091,
                          'name': "Little Children's Development Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 Harbor Road',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22214,
                          'lat': 37.733875,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22214-Sfsu-Malcolm-X-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22214-Sfsu-Malcolm-X-Head-Start/',
                              'reviews': '/california/san-francisco/22214-Sfsu-Malcolm-X-Head-Start/#Reviews'},
                          'lon': -122.380943,
                          'name': 'Sfsu-Malcolm X Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1294 Potrero Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22215,
                          'lat': 37.751923,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22215-Good-Samaritan-Family-Res-Center-Cdc/#College_success',
                              'profile': '/california/san-francisco/22215-Good-Samaritan-Family-Res-Center-Cdc/',
                              'reviews': '/california/san-francisco/22215-Good-Samaritan-Family-Res-Center-Cdc/#Reviews'},
                          'lon': -122.406555,
                          'name': 'Good Samaritan Family Res Center Cdc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1300 Phelps Street',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22216,
                          'lat': 37.737293,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22216-South-East-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22216-South-East-Head-Start/',
                              'reviews': '/california/san-francisco/22216-South-East-Head-Start/#Reviews'},
                          'lon': -122.393822,
                          'name': 'South East Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '301 14th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22217,
                          'lat': 37.782536,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22217-Congregation-Beth-Sholom/#College_success',
                              'profile': '/california/san-francisco/22217-Congregation-Beth-Sholom/',
                              'reviews': '/california/san-francisco/22217-Congregation-Beth-Sholom/#Reviews'},
                          'lon': -122.473022,
                          'name': 'Congregation Beth Sholom',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1315 Ellis Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22218,
                          'lat': 37.782681,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22218-Phoebe-A-Hearst-Pre-School-Learning/#College_success',
                              'profile': '/california/san-francisco/22218-Phoebe-A-Hearst-Pre-School-Learning/',
                              'reviews': '/california/san-francisco/22218-Phoebe-A-Hearst-Pre-School-Learning/#Reviews'},
                          'lon': -122.427948,
                          'name': 'Phoebe A Hearst Pre-School Learning',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1347 Pierce',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22220,
                          'lat': 37.782688,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22220-Cross-Cultural-Family-Center/#College_success',
                              'profile': '/california/san-francisco/22220-Cross-Cultural-Family-Center/',
                              'reviews': '/california/san-francisco/22220-Cross-Cultural-Family-Center/#Reviews'},
                          'lon': -122.435966,
                          'name': 'Cross Cultural Family Center',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': "1901 O'Farrell Street",
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22221,
                          'lat': 37.782715,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22221-Ccfc-Ofarrel-Center/#College_success',
                              'profile': '/california/san-francisco/22221-Ccfc-Ofarrel-Center/',
                              'reviews': '/california/san-francisco/22221-Ccfc-Ofarrel-Center/#Reviews'},
                          'lon': -122.436234,
                          'name': "Ccfc-O'farrel Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1350 25th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22222,
                          'lat': 37.762329,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22222-Jefferson-Nursery/#College_success',
                              'profile': '/california/san-francisco/22222-Jefferson-Nursery/',
                              'reviews': '/california/san-francisco/22222-Jefferson-Nursery/#Reviews'},
                          'lon': -122.483452,
                          'name': 'Jefferson Nursery',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1400 Judah Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22223,
                          'lat': 37.761868,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22223-Wah-Mei-School/#College_success',
                                    'profile': '/california/san-francisco/22223-Wah-Mei-School/',
                                    'reviews': '/california/san-francisco/22223-Wah-Mei-School/#Reviews'},
                          'lon': -122.477242,
                          'name': 'Wah Mei School',
                          'numReviews': 9,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1419 Howard Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22224,
                          'lat': 37.77335,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22224-Love--Learn-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22224-Love--Learn-Nursery-School/',
                              'reviews': '/california/san-francisco/22224-Love--Learn-Nursery-School/#Reviews'},
                          'lon': -122.414795,
                          'name': 'Love & Learn Nursery School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '49 Powell Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22225,
                          'lat': 37.785206,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22225-Compass-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22225-Compass-Childrens-Center/',
                              'reviews': '/california/san-francisco/22225-Compass-Childrens-Center/#Reviews'},
                          'lon': -122.408073,
                          'name': 'Compass Childrens Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22226,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22226-Mission-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22226-Mission-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22226-Mission-Child-Care-Center/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Mission Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1500 Grove Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22227,
                          'lat': 37.775631,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22227-Pacific-Primary-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22227-Pacific-Primary-Pre-School/',
                              'reviews': '/california/san-francisco/22227-Pacific-Primary-Pre-School/#Reviews'},
                          'lon': -122.441696,
                          'name': 'Pacific Primary Pre-School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': "1501 O'farrell Street",
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 2,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22228,
                          'lat': 37.783512,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22228-Weill-Raphael-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22228-Weill-Raphael-Children-Center/',
                              'reviews': '/california/san-francisco/22228-Weill-Raphael-Children-Center/#Reviews'},
                          'lon': -122.43013,
                          'name': 'Weill (Raphael) Children Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '0%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '152 Berry Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22229,
                          'lat': 37.776787,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22229-Mission-Head-Start-Mission-Bay/#College_success',
                              'profile': '/california/san-francisco/22229-Mission-Head-Start-Mission-Bay/',
                              'reviews': '/california/san-francisco/22229-Mission-Head-Start-Mission-Bay/#Reviews'},
                          'lon': -122.392204,
                          'name': 'Mission Head Start-Mission Bay',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1520 Lyon Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22230,
                          'lat': 37.785206,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22230-The-Little-School/#College_success',
                              'profile': '/california/san-francisco/22230-The-Little-School/',
                              'reviews': '/california/san-francisco/22230-The-Little-School/#Reviews'},
                          'lon': -122.44455,
                          'name': 'The Little School',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '20 Cook Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22231,
                          'lat': 37.783344,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22231-Sfusd-Burnett-Cdc-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22231-Sfusd-Burnett-Cdc-Pre-School/',
                              'reviews': '/california/san-francisco/22231-Sfusd-Burnett-Cdc-Pre-School/#Reviews'},
                          'lon': -122.452415,
                          'name': 'Sfusd-Burnett Cdc Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 15025',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22232,
                          'lat': 37.78775,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22232-Montessori-School-Of-The-Bay-Area/#College_success',
                              'profile': '/california/san-francisco/22232-Montessori-School-Of-The-Bay-Area/',
                              'reviews': '/california/san-francisco/22232-Montessori-School-Of-The-Bay-Area/#Reviews'},
                          'lon': -122.438232,
                          'name': 'Montessori School Of The Bay Area',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1555 6th Street',
                                      'street2': '',
                                      'zip': '94158'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22233,
                          'lat': 37.769909,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22233-Ucsf-Child-Care-Mission-Bay/#College_success',
                              'profile': '/california/san-francisco/22233-Ucsf-Child-Care-Mission-Bay/',
                              'reviews': '/california/san-francisco/22233-Ucsf-Child-Care-Mission-Bay/#Reviews'},
                          'lon': -122.394806,
                          'name': 'Ucsf Child Care-Mission Bay',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '859 Prague Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22234,
                          'lat': 37.71019,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22234-Excelsior-Chld-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22234-Excelsior-Chld-Development-Center/',
                              'reviews': '/california/san-francisco/22234-Excelsior-Chld-Development-Center/#Reviews'},
                          'lon': -122.434563,
                          'name': 'Excelsior Chld Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '165 Grattan St',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22235,
                          'lat': 37.763905,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22235-Grattan-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22235-Grattan-Childrens-Center/',
                              'reviews': '/california/san-francisco/22235-Grattan-Childrens-Center/#Reviews'},
                          'lon': -122.450584,
                          'name': "Grattan Children's Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1672 Eddy Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22236,
                          'lat': 37.781078,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22236-Cross-Cultural-Family-Center/#College_success',
                              'profile': '/california/san-francisco/22236-Cross-Cultural-Family-Center/',
                              'reviews': '/california/san-francisco/22236-Cross-Cultural-Family-Center/#Reviews'},
                          'lon': -122.435173,
                          'name': 'Cross-Cultural Family Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1700 Sutter St',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22237,
                          'lat': 37.786919,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22237-Nihonmachi-Little-Friends/#College_success',
                              'profile': '/california/san-francisco/22237-Nihonmachi-Little-Friends/',
                              'reviews': '/california/san-francisco/22237-Nihonmachi-Little-Friends/#Reviews'},
                          'lon': -122.428688,
                          'name': 'Nihonmachi Little Friends',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1755 Clay Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22238,
                          'lat': 37.792122,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22238-St-Lukes-Parish-School/#College_success',
                              'profile': '/california/san-francisco/22238-St-Lukes-Parish-School/',
                              'reviews': '/california/san-francisco/22238-St-Lukes-Parish-School/#Reviews'},
                          'lon': -122.422348,
                          'name': "St Luke's Parish School",
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '20 Cook St 2nd Fl',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22239,
                          'lat': 37.782288,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22239-Sfusd-Noriega-Chld-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22239-Sfusd-Noriega-Chld-Development-Center/',
                              'reviews': '/california/san-francisco/22239-Sfusd-Noriega-Chld-Development-Center/#Reviews'},
                          'lon': -122.463707,
                          'name': 'Sfusd-Noriega Chld Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1806 Belles Street',
                                      'street2': '',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22240,
                          'lat': 37.788696,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22240-Lone-Mountain-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22240-Lone-Mountain-Childrens-Center/',
                              'reviews': '/california/san-francisco/22240-Lone-Mountain-Childrens-Center/#Reviews'},
                          'lon': -122.473663,
                          'name': "Lone Mountain Children's Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4635 Cabrillo Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22241,
                          'lat': 37.773094,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22241-Angelinas-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22241-Angelinas-Pre-School/',
                              'reviews': '/california/san-francisco/22241-Angelinas-Pre-School/#Reviews'},
                          'lon': -122.508415,
                          'name': "Angelina's Pre-School",
                          'numReviews': 8,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1925 Lawton Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22242,
                          'lat': 37.75742,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22242-Cornerstone-Academy/#College_success',
                              'profile': '/california/san-francisco/22242-Cornerstone-Academy/',
                              'reviews': '/california/san-francisco/22242-Cornerstone-Academy/#Reviews'},
                          'lon': -122.483665,
                          'name': 'Cornerstone Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2 Harrison Street #150',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22243,
                          'lat': 37.78952,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22243-Marin-Day-Pre-Sch-Hills-Plaza/#College_success',
                              'profile': '/california/san-francisco/22243-Marin-Day-Pre-Sch-Hills-Plaza/',
                              'reviews': '/california/san-francisco/22243-Marin-Day-Pre-Sch-Hills-Plaza/#Reviews'},
                          'lon': -122.389038,
                          'name': 'Marin Day Pre-Sch-Hills Plaza',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2 Lake Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22244,
                          'lat': 37.786926,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22244-Temple-Emanuel-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22244-Temple-Emanuel-Pre-School/',
                              'reviews': '/california/san-francisco/22244-Temple-Emanuel-Pre-School/#Reviews'},
                          'lon': -122.45929,
                          'name': 'Temple Emanuel Pre-School',
                          'numReviews': 1,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22245,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22245-Martin-Luther-King-Child-Care/#College_success',
                              'profile': '/california/san-francisco/22245-Martin-Luther-King-Child-Care/',
                              'reviews': '/california/san-francisco/22245-Martin-Luther-King-Child-Care/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Martin Luther King Child Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22247,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22247-Omi-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22247-Omi-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22247-Omi-Child-Care-Center/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Omi Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1830 Sutter Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22248,
                          'lat': 37.78669,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22248-Nithonmachi-Little-Friends/#College_success',
                              'profile': '/california/san-francisco/22248-Nithonmachi-Little-Friends/',
                              'reviews': '/california/san-francisco/22248-Nithonmachi-Little-Friends/#Reviews'},
                          'lon': -122.430618,
                          'name': 'Nithonmachi Little Friends',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '205 13th Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22249,
                          'lat': 37.769688,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22249-Head-Start/#College_success',
                                    'profile': '/california/san-francisco/22249-Head-Start/',
                                    'reviews': '/california/san-francisco/22249-Head-Start/#Reviews'},
                          'lon': -122.419464,
                          'name': 'Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2055 Sunnydale Avenue',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 22,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 59},
                                            {'label': 'Asian', 'percentage': 32},
                                            {'label': 'African American', 'percentage': 23},
                                            {'label': 'Hispanic', 'percentage': 14},
                                            {'label': 'Filipino', 'percentage': 14},
                                            {'label': 'White', 'percentage': 9},
                                            {'label': 'Two or more races', 'percentage': 5}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22250,
                          'lat': 37.713558,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22250-Mclaren-John-Children-Centers/#College_success',
                              'profile': '/california/san-francisco/22250-Mclaren-John-Children-Centers/',
                              'reviews': '/california/san-francisco/22250-Mclaren-John-Children-Centers/#Reviews'},
                          'lon': -122.421913,
                          'name': 'Mclaren (John) Children Centers',
                          'numReviews': 1,
                          'parentRating': 4,
                          'percentLowIncome': '59%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2110 Greenwich Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22251,
                          'lat': 37.799103,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22251-Tuie-Elkpark-Early-Education/#College_success',
                              'profile': '/california/san-francisco/22251-Tuie-Elkpark-Early-Education/',
                              'reviews': '/california/san-francisco/22251-Tuie-Elkpark-Early-Education/#Reviews'},
                          'lon': -122.434937,
                          'name': 'Tuie Elkpark Early Education',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '221 Main St',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22253,
                          'lat': 37.790348,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22253-Spear-St.-Campus-Preschool-Marin-Day/#College_success',
                              'profile': '/california/san-francisco/22253-Spear-St.-Campus-Preschool-Marin-Day/',
                              'reviews': '/california/san-francisco/22253-Spear-St.-Campus-Preschool-Marin-Day/#Reviews'},
                          'lon': -122.392242,
                          'name': 'Spear St. Campus Preschool-Marin Day',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '221 Valley St Ste X',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22254,
                          'lat': 37.74419,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22254-St.-Pauls-Church-LTTLST-Angel/#College_success',
                              'profile': '/california/san-francisco/22254-St.-Pauls-Church-LTTLST-Angel/',
                              'reviews': '/california/san-francisco/22254-St.-Pauls-Church-LTTLST-Angel/#Reviews'},
                          'lon': -122.427742,
                          'name': "St. Paul's Church-LTTLST Angel",
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2266 California Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22255,
                          'lat': 37.789482,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22255-Marin-Day-School-Sherith-Israel/#College_success',
                              'profile': '/california/san-francisco/22255-Marin-Day-School-Sherith-Israel/',
                              'reviews': '/california/san-francisco/22255-Marin-Day-School-Sherith-Israel/#Reviews'},
                          'lon': -122.431824,
                          'name': 'Marin Day School-Sherith Israel',
                          'numReviews': 1,
                          'parentRating': 1,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '227 Balboa Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22256,
                          'lat': 37.777161,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22256-Peters-Place-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22256-Peters-Place-Nursery-School/',
                              'reviews': '/california/san-francisco/22256-Peters-Place-Nursery-School/#Reviews'},
                          'lon': -122.461365,
                          'name': "Peter's Place Nursery School",
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '65 Funston Avenue',
                                      'street2': '',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22257,
                          'lat': 37.799049,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22257-Cow-Hollow-School/#College_success',
                              'profile': '/california/san-francisco/22257-Cow-Hollow-School/',
                              'reviews': '/california/san-francisco/22257-Cow-Hollow-School/#Reviews'},
                          'lon': -122.455559,
                          'name': 'Cow Hollow School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2340 42nd Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22258,
                          'lat': 37.743038,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22258-Playmates-Co-Op-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22258-Playmates-Co-Op-Nursery-School/',
                              'reviews': '/california/san-francisco/22258-Playmates-Co-Op-Nursery-School/#Reviews'},
                          'lon': -122.500389,
                          'name': 'Playmates Co-Op Nursery School',
                          'numReviews': 8,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2358 24th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22259,
                          'lat': 37.743599,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22259-Rainbow-Mont-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22259-Rainbow-Mont-Pre-School/',
                              'reviews': '/california/san-francisco/22259-Rainbow-Mont-Pre-School/#Reviews'},
                          'lon': -122.48111,
                          'name': 'Rainbow Mont Pre-School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '240 Leland Ave',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22260,
                          'lat': 37.713024,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22260-3-N-1-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22260-3-N-1-Pre-School/',
                              'reviews': '/california/san-francisco/22260-3-N-1-Pre-School/#Reviews'},
                          'lon': -122.408241,
                          'name': '3 N 1 Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2400 Post Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22261,
                          'lat': 37.784119,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22261-Sfsu-Westside-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22261-Sfsu-Westside-Head-Start/',
                              'reviews': '/california/san-francisco/22261-Sfsu-Westside-Head-Start/#Reviews'},
                          'lon': -122.441368,
                          'name': 'Sfsu-Westside Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2425 19th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 153,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian or Pacific Islander',
                                             'percentage': 100}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22262,
                          'lat': 37.742458,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22262-Parkside-Preschool--Kindergarten/#College_success',
                              'profile': '/california/san-francisco/22262-Parkside-Preschool--Kindergarten/',
                              'reviews': '/california/san-francisco/22262-Parkside-Preschool--Kindergarten/#Reviews'},
                          'lon': -122.4757,
                          'name': 'Parkside Preschool & Kindergarten',
                          'numReviews': 7,
                          'parentRating': 1,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2475 15th Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22263,
                          'lat': 37.765972,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22263-Rocky-Mountain-Co-Op-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22263-Rocky-Mountain-Co-Op-Nursery-School/',
                              'reviews': '/california/san-francisco/22263-Rocky-Mountain-Co-Op-Nursery-School/#Reviews'},
                          'lon': -122.437843,
                          'name': 'Rocky Mountain Co-Op Nursery School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '25 Lake Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22264,
                          'lat': 37.786758,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22264-Montessori-Childrens-House/#College_success',
                              'profile': '/california/san-francisco/22264-Montessori-Childrens-House/',
                              'reviews': '/california/san-francisco/22264-Montessori-Childrens-House/#Reviews'},
                          'lon': -122.459923,
                          'name': "Montessori Children's House",
                          'numReviews': 22,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2515 Fillmore Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22265,
                          'lat': 37.792713,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22265-Calvary-Presbyterian-Ch-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22265-Calvary-Presbyterian-Ch-Nursery-School/',
                              'reviews': '/california/san-francisco/22265-Calvary-Presbyterian-Ch-Nursery-School/#Reviews'},
                          'lon': -122.435089,
                          'name': 'Calvary Presbyterian Ch Nursery School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1500 40th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22266,
                          'lat': 37.758606,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22266-Little-Star-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22266-Little-Star-Pre-School/',
                              'reviews': '/california/san-francisco/22266-Little-Star-Pre-School/#Reviews'},
                          'lon': -122.499184,
                          'name': 'Little Star Pre-School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2340 Jackson Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22267,
                          'lat': 37.792645,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22267-San-Francisco-Public-Montessori-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22267-San-Francisco-Public-Montessori-Child-Development-Center/',
                              'reviews': '/california/san-francisco/22267-San-Francisco-Public-Montessori-Child-Development-Center/#Reviews'},
                          'lon': -122.433723,
                          'name': 'San Francisco Public Montessori Child Development Center',
                          'numReviews': 2,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '283 Farallones St',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22268,
                          'lat': 37.713779,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22268-Candlelight-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22268-Candlelight-Child-Development-Center/',
                              'reviews': '/california/san-francisco/22268-Candlelight-Child-Development-Center/#Reviews'},
                          'lon': -122.461884,
                          'name': 'Candlelight Child Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '300 Moultrie St',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22269,
                          'lat': 37.74033,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22269-Trevor-Martin-Montessori-School/#College_success',
                              'profile': '/california/san-francisco/22269-Trevor-Martin-Montessori-School/',
                              'reviews': '/california/san-francisco/22269-Trevor-Martin-Montessori-School/#Reviews'},
                          'lon': -122.415985,
                          'name': 'Trevor Martin Montessori School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '300 Seneca Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 129,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22270,
                          'lat': 37.72121,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22270-San-Miguel-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22270-San-Miguel-Children-Center/',
                              'reviews': '/california/san-francisco/22270-San-Miguel-Children-Center/#Reviews'},
                          'lon': -122.444748,
                          'name': 'San Miguel Children Center',
                          'numReviews': 1,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '305 Buchanan Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22271,
                          'lat': 37.772705,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22271-Florence-Crittenton-Services/#College_success',
                              'profile': '/california/san-francisco/22271-Florence-Crittenton-Services/',
                              'reviews': '/california/san-francisco/22271-Florence-Crittenton-Services/#Reviews'},
                          'lon': -122.427361,
                          'name': 'Florence Crittenton Services',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3101 Mission Street #101',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22272,
                          'lat': 37.747742,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22272-Family-School/#College_success',
                                    'profile': '/california/san-francisco/22272-Family-School/',
                                    'reviews': '/california/san-francisco/22272-Family-School/#Reviews'},
                          'lon': -122.417999,
                          'name': 'Family School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3141 Vicente Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22273,
                          'lat': 37.737865,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22273-Ark-Christian-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22273-Ark-Christian-Pre-School/',
                              'reviews': '/california/san-francisco/22273-Ark-Christian-Pre-School/#Reviews'},
                          'lon': -122.500862,
                          'name': 'Ark Christian Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3149 Steiner Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22274,
                          'lat': 37.79845,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22274-Little-Gators/#College_success',
                                    'profile': '/california/san-francisco/22274-Little-Gators/',
                                    'reviews': '/california/san-francisco/22274-Little-Gators/#Reviews'},
                          'lon': -122.437683,
                          'name': 'Little Gators',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '316 Leavenworth Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22275,
                          'lat': 37.783974,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22275-Cadillac-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22275-Cadillac-Head-Start/',
                              'reviews': '/california/san-francisco/22275-Cadillac-Head-Start/#Reviews'},
                          'lon': -122.41433,
                          'name': 'Cadillac Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3200 California Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22276,
                          'lat': 37.787308,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22276-Jcc-California-Street-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22276-Jcc-California-Street-Pre-School/',
                              'reviews': '/california/san-francisco/22276-Jcc-California-Street-Pre-School/#Reviews'},
                          'lon': -122.447411,
                          'name': 'Jcc California Street Pre-School',
                          'numReviews': 1,
                          'parentRating': 2,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3201 Ulloa Street',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22277,
                          'lat': 37.740261,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22277-Grace-Infant-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22277-Grace-Infant-Care-Center/',
                              'reviews': '/california/san-francisco/22277-Grace-Infant-Care-Center/#Reviews'},
                          'lon': -122.490852,
                          'name': 'Grace Infant Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3221 Laguna Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22278,
                          'lat': 37.800789,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22278-Marina-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22278-Marina-Children-Center/',
                              'reviews': '/california/san-francisco/22278-Marina-Children-Center/#Reviews'},
                          'lon': -122.431297,
                          'name': 'Marina Children Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '325 Leland Ave',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22279,
                          'lat': 37.713223,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22279-Family-School/#College_success',
                                    'profile': '/california/san-francisco/22279-Family-School/',
                                    'reviews': '/california/san-francisco/22279-Family-School/#Reviews'},
                          'lon': -122.409866,
                          'name': 'Family School',
                          'numReviews': 1,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 210120',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22280,
                          'lat': 37.781345,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22280-Multicultural-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22280-Multicultural-Pre-School/',
                              'reviews': '/california/san-francisco/22280-Multicultural-Pre-School/#Reviews'},
                          'lon': -122.497665,
                          'name': 'Multicultural Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3300 Balboa St',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22281,
                          'lat': 37.7761,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22281-Multi-Cultural-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22281-Multi-Cultural-Pre-School/',
                              'reviews': '/california/san-francisco/22281-Multi-Cultural-Pre-School/#Reviews'},
                          'lon': -122.494301,
                          'name': 'Multi Cultural Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3401 Mission St',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22282,
                          'lat': 37.741955,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22282-Alfa-Day-Develop-Center/#College_success',
                              'profile': '/california/san-francisco/22282-Alfa-Day-Develop-Center/',
                              'reviews': '/california/san-francisco/22282-Alfa-Day-Develop-Center/#Reviews'},
                          'lon': -122.421906,
                          'name': 'Alfa Day Develop Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '342 Howard Street',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22283,
                          'lat': 37.789619,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22283-Marin-Day-Pre-Sch-Fremont/#College_success',
                              'profile': '/california/san-francisco/22283-Marin-Day-Pre-Sch-Fremont/',
                              'reviews': '/california/san-francisco/22283-Marin-Day-Pre-Sch-Fremont/#Reviews'},
                          'lon': -122.394821,
                          'name': 'Marin Day Pre-Sch-Fremont',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3535 Balboa St',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22284,
                          'lat': 37.775482,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22284-Sonshine-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22284-Sonshine-Pre-School/',
                              'reviews': '/california/san-francisco/22284-Sonshine-Pre-School/#Reviews'},
                          'lon': -122.496979,
                          'name': 'Sonshine Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1337 Evans Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22285,
                          'lat': 37.740017,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22285-Mission-Head-Start-Center/#College_success',
                              'profile': '/california/san-francisco/22285-Mission-Head-Start-Center/',
                              'reviews': '/california/san-francisco/22285-Mission-Head-Start-Center/#Reviews'},
                          'lon': -122.383827,
                          'name': 'Mission Head Start Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '685 Natoma Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22286,
                          'lat': 37.776981,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22286-South-Of-Market-Child-Care-Center-On-Natoma/#College_success',
                              'profile': '/california/san-francisco/22286-South-Of-Market-Child-Care-Center-On-Natoma/',
                              'reviews': '/california/san-francisco/22286-South-Of-Market-Child-Care-Center-On-Natoma/#Reviews'},
                          'lon': -122.411789,
                          'name': 'South Of Market Child Care Center On Natoma',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '372 7th Ave',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22287,
                          'lat': 37.781605,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22287-Happy-Kids-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22287-Happy-Kids-Pre-School/',
                              'reviews': '/california/san-francisco/22287-Happy-Kids-Pre-School/#Reviews'},
                          'lon': -122.465073,
                          'name': 'Happy Kids Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '65 Ocean Averms 1 - 5 & 7-9',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22288,
                          'lat': 37.722549,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22288-Little-Bear-School/#College_success',
                              'profile': '/california/san-francisco/22288-Little-Bear-School/',
                              'reviews': '/california/san-francisco/22288-Little-Bear-School/#Reviews'},
                          'lon': -122.441063,
                          'name': 'Little Bear School',
                          'numReviews': 4,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3737 26th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22289,
                          'lat': 37.748695,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22289-Centro-Las-Olas-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22289-Centro-Las-Olas-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22289-Centro-Las-Olas-Child-Care-Center/#Reviews'},
                          'lon': -122.423676,
                          'name': 'Centro Las Olas Child Care Center',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2106 18th Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22290,
                          'lat': 37.762383,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22290-Room-To-Grow-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22290-Room-To-Grow-Pre-School/',
                              'reviews': '/california/san-francisco/22290-Room-To-Grow-Pre-School/#Reviews'},
                          'lon': -122.40358,
                          'name': 'Room To Grow Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '380th A-21st Ave # A-',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22291,
                          'lat': 37.77483,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22291-Muticultural-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22291-Muticultural-Pre-School/',
                              'reviews': '/california/san-francisco/22291-Muticultural-Pre-School/#Reviews'},
                          'lon': -122.496178,
                          'name': 'Muticultural Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3939 Lawton Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22292,
                          'lat': 37.756458,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22292-Kids-Kollege/#College_success',
                                    'profile': '/california/san-francisco/22292-Kids-Kollege/',
                                    'reviews': '/california/san-francisco/22292-Kids-Kollege/#Reviews'},
                          'lon': -122.505234,
                          'name': "Kid's Kollege",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '399 San Fernando Way',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22293,
                          'lat': 37.730392,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22293-Stonestown-Family-Ymca--Pre-School/#College_success',
                              'profile': '/california/san-francisco/22293-Stonestown-Family-Ymca--Pre-School/',
                              'reviews': '/california/san-francisco/22293-Stonestown-Family-Ymca--Pre-School/#Reviews'},
                          'lon': -122.469734,
                          'name': 'Stonestown Family Ymca & Pre-School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '401 Euclid Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22294,
                          'lat': 37.78389,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22294-Laurel-Hill-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22294-Laurel-Hill-Nursery-School/',
                              'reviews': '/california/san-francisco/22294-Laurel-Hill-Nursery-School/#Reviews'},
                          'lon': -122.45269,
                          'name': 'Laurel Hill Nursery School',
                          'numReviews': 14,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4080 Mission Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22295,
                          'lat': 37.732536,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22295-Ymca-Mission-District-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22295-Ymca-Mission-District-Pre-School/',
                              'reviews': '/california/san-francisco/22295-Ymca-Mission-District-Pre-School/#Reviews'},
                          'lon': -122.427895,
                          'name': 'Ymca-Mission District Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '421 Bartlett Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22296,
                          'lat': 37.750141,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22296-Zaida-Rodriguiz-Early-Ed-School/#College_success',
                              'profile': '/california/san-francisco/22296-Zaida-Rodriguiz-Early-Ed-School/',
                              'reviews': '/california/san-francisco/22296-Zaida-Rodriguiz-Early-Ed-School/#Reviews'},
                          'lon': -122.41935,
                          'name': 'Zaida Rodriguiz Early Ed School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '999 Brotherhood Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22297,
                          'lat': 37.714035,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22297-Sunset-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22297-Sunset-Nursery-School/',
                              'reviews': '/california/san-francisco/22297-Sunset-Nursery-School/#Reviews'},
                          'lon': -122.482979,
                          'name': 'Sunset Nursery School',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '426 33rd Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22298,
                          'lat': 37.781052,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22298-Abc-Pre-School/#College_success',
                                    'profile': '/california/san-francisco/22298-Abc-Pre-School/',
                                    'reviews': '/california/san-francisco/22298-Abc-Pre-School/#Reviews'},
                          'lon': -122.493195,
                          'name': 'Abc Pre-School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '434 Ellis St',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22299,
                          'lat': 37.785042,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22299-Cecil-Williams-Glide-Community-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22299-Cecil-Williams-Glide-Community-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22299-Cecil-Williams-Glide-Community-Child-Care-Center/#Reviews'},
                          'lon': -122.413452,
                          'name': 'Cecil Williams Glide Community Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '330 Ellis Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22300,
                          'lat': 37.785152,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22300-Glide-Child-Care--Family-Center/#College_success',
                              'profile': '/california/san-francisco/22300-Glide-Child-Care--Family-Center/',
                              'reviews': '/california/san-francisco/22300-Glide-Child-Care--Family-Center/#Reviews'},
                          'lon': -122.411499,
                          'name': 'Glide Child Care & Family Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '443 Foerster Street',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22301,
                          'lat': 37.732201,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22301-Miraloma-Co-Op-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22301-Miraloma-Co-Op-Nursery-School/',
                              'reviews': '/california/san-francisco/22301-Miraloma-Co-Op-Nursery-School/#Reviews'},
                          'lon': -122.449097,
                          'name': 'Miraloma Co-Op Nursery School',
                          'numReviews': 16,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '45 Cleveland St',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22302,
                          'lat': 37.776527,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22302-Yoet-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22302-Yoet-Child-Development-Center/',
                              'reviews': '/california/san-francisco/22302-Yoet-Child-Development-Center/#Reviews'},
                          'lon': -122.406387,
                          'name': 'Yoet Child Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '475 the Embarcadero',
                                      'street2': '',
                                      'zip': '94111'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22304,
                          'lat': 37.798855,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22304-Kinderhaven-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22304-Kinderhaven-Childrens-Center/',
                              'reviews': '/california/san-francisco/22304-Kinderhaven-Childrens-Center/#Reviews'},
                          'lon': -122.397507,
                          'name': "Kinderhaven Children's Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4750 Mission Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22305,
                          'lat': 37.722256,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22305-Mission-Child-Care-Consortium/#College_success',
                              'profile': '/california/san-francisco/22305-Mission-Child-Care-Consortium/',
                              'reviews': '/california/san-francisco/22305-Mission-Child-Care-Consortium/#Reviews'},
                          'lon': -122.437279,
                          'name': 'Mission Child Care Consortium',
                          'numReviews': 2,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Belcher Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22306,
                          'lat': 37.768517,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22306-Friends-Of-St-Francis-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22306-Friends-Of-St-Francis-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22306-Friends-Of-St-Francis-Child-Care-Center/#Reviews'},
                          'lon': -122.430489,
                          'name': 'Friends Of St Francis Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Pomona Street',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22307,
                          'lat': 37.731743,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22307-Early-Education-School/#College_success',
                              'profile': '/california/san-francisco/22307-Early-Education-School/',
                              'reviews': '/california/san-francisco/22307-Early-Education-School/#Reviews'},
                          'lon': -122.393471,
                          'name': 'Early Education School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '500 Raymond Ave',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22308,
                          'lat': 37.715012,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22308-Visitation-Valley-Community-Center/#College_success',
                              'profile': '/california/san-francisco/22308-Visitation-Valley-Community-Center/',
                              'reviews': '/california/san-francisco/22308-Visitation-Valley-Community-Center/#Reviews'},
                          'lon': -122.413651,
                          'name': 'Visitation Valley Community Center',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '525 Golden Gate Avenue',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22309,
                          'lat': 37.780956,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22309-C-5-Children-School/#College_success',
                              'profile': '/california/san-francisco/22309-C-5-Children-School/',
                              'reviews': '/california/san-francisco/22309-C-5-Children-School/#Reviews'},
                          'lon': -122.419189,
                          'name': 'C 5 Children School',
                          'numReviews': 7,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '5200 Diamond Heights Boulevard',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22310,
                          'lat': 37.744846,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22310-St-Nicholas-Daycare-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22310-St-Nicholas-Daycare-Pre-School/',
                              'reviews': '/california/san-francisco/22310-St-Nicholas-Daycare-Pre-School/#Reviews'},
                          'lon': -122.440384,
                          'name': 'St Nicholas Daycare Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '521 Balboa Street',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22311,
                          'lat': 37.777016,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22311-Balboa-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22311-Balboa-Pre-School/',
                              'reviews': '/california/san-francisco/22311-Balboa-Pre-School/#Reviews'},
                          'lon': -122.464485,
                          'name': 'Balboa Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '534 Lyon St',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22312,
                          'lat': 37.775795,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22312-Parkview-Place-Infant-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22312-Parkview-Place-Infant-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22312-Parkview-Place-Infant-Child-Care-Center/#Reviews'},
                          'lon': -122.443047,
                          'name': 'Parkview Place Infant Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '464 Diamond Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22313,
                          'lat': 37.754967,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22313-Eureka-Learning-Center/#College_success',
                              'profile': '/california/san-francisco/22313-Eureka-Learning-Center/',
                              'reviews': '/california/san-francisco/22313-Eureka-Learning-Center/#Reviews'},
                          'lon': -122.436852,
                          'name': 'Eureka Learning Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '555 California St',
                                      'street2': '',
                                      'zip': '94104'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22314,
                          'lat': 37.792091,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22314-Bright-Horizons-Fmly-Solutions/#College_success',
                              'profile': '/california/san-francisco/22314-Bright-Horizons-Fmly-Solutions/',
                              'reviews': '/california/san-francisco/22314-Bright-Horizons-Fmly-Solutions/#Reviews'},
                          'lon': -122.403679,
                          'name': 'Bright Horizons Fmly Solutions',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '922 Jackson',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22315,
                          'lat': 37.795704,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22315-Telegraph-Hill-Co-Op-Nursery-School/#College_success',
                              'profile': '/california/san-francisco/22315-Telegraph-Hill-Co-Op-Nursery-School/',
                              'reviews': '/california/san-francisco/22315-Telegraph-Hill-Co-Op-Nursery-School/#Reviews'},
                          'lon': -122.410385,
                          'name': 'Telegraph Hill Co-Op Nursery School',
                          'numReviews': 4,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '555 Franklin Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22316,
                          'lat': 37.779312,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22316-Presidio-Of-San-Francisco-Ecc/#College_success',
                              'profile': '/california/san-francisco/22316-Presidio-Of-San-Francisco-Ecc/',
                              'reviews': '/california/san-francisco/22316-Presidio-Of-San-Francisco-Ecc/#Reviews'},
                          'lon': -122.422073,
                          'name': 'Presidio Of San Francisco Ecc',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '58 Danton St',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22317,
                          'lat': 37.731403,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22317-Munchkinland-Day-Care/#College_success',
                              'profile': '/california/san-francisco/22317-Munchkinland-Day-Care/',
                              'reviews': '/california/san-francisco/22317-Munchkinland-Day-Care/#Reviews'},
                          'lon': -122.43277,
                          'name': 'Munchkinland Day Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '589 Guerrero Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22318,
                          'lat': 37.761932,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22318-Buen-Dia-Family-School/#College_success',
                              'profile': '/california/san-francisco/22318-Buen-Dia-Family-School/',
                              'reviews': '/california/san-francisco/22318-Buen-Dia-Family-School/#Reviews'},
                          'lon': -122.423454,
                          'name': 'Buen Dia Family School',
                          'numReviews': 2,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '60 Gould St',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22319,
                          'lat': 37.721813,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22319-Kellys-Day-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22319-Kellys-Day-Care-Center/',
                              'reviews': '/california/san-francisco/22319-Kellys-Day-Care-Center/#Reviews'},
                          'lon': -122.398239,
                          'name': "Kelly's Day Care Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '637 Peralta Ave',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22320,
                          'lat': 37.742588,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22320-Kidde-Place/#College_success',
                                    'profile': '/california/san-francisco/22320-Kidde-Place/',
                                    'reviews': '/california/san-francisco/22320-Kidde-Place/#Reviews'},
                          'lon': -122.408569,
                          'name': 'Kidde Place',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '65 Ocean Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22321,
                          'lat': 37.723457,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22321-Crayon-Box-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22321-Crayon-Box-Pre-School/',
                              'reviews': '/california/san-francisco/22321-Crayon-Box-Pre-School/#Reviews'},
                          'lon': -122.438545,
                          'name': 'Crayon Box Pre-School',
                          'numReviews': 4,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '651 6th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22322,
                          'lat': 37.776287,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22322-Frank-Mccoppin-Cdc/#College_success',
                              'profile': '/california/san-francisco/22322-Frank-Mccoppin-Cdc/',
                              'reviews': '/california/san-francisco/22322-Frank-Mccoppin-Cdc/#Reviews'},
                          'lon': -122.463966,
                          'name': 'Frank Mccoppin Cdc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '630 Brussels Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22323,
                          'lat': 37.724201,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22323-Abcs-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22323-Abcs-Child-Development-Center/',
                              'reviews': '/california/san-francisco/22323-Abcs-Child-Development-Center/#Reviews'},
                          'lon': -122.404701,
                          'name': 'Abcs Child Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '362 Capp Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22324,
                          'lat': 37.760941,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22324-Mission-Neighborhood-High-School-Ctrs/#College_success',
                              'profile': '/california/san-francisco/22324-Mission-Neighborhood-High-School-Ctrs/',
                              'reviews': '/california/san-francisco/22324-Mission-Neighborhood-High-School-Ctrs/#Reviews'},
                          'lon': -122.418228,
                          'name': 'Mission Neighborhood High School Ctrs',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '7 Funston Avenue',
                                      'street2': '',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22325,
                          'lat': 37.799297,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22325-Serra-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22325-Serra-Pre-School/',
                              'reviews': '/california/san-francisco/22325-Serra-Pre-School/#Reviews'},
                          'lon': -122.4561,
                          'name': 'Serra Pre-School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '700 Velasco Avenue',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22326,
                          'lat': 37.709774,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22326-Wu-Yee-Child-Svcs-Sunnydale/#College_success',
                              'profile': '/california/san-francisco/22326-Wu-Yee-Child-Svcs-Sunnydale/',
                              'reviews': '/california/san-francisco/22326-Wu-Yee-Child-Svcs-Sunnydale/#Reviews'},
                          'lon': -122.418304,
                          'name': 'Wu Yee Child Svcs-Sunnydale',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '706 Mission Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22327,
                          'lat': 37.786011,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22327-Wu-Yee-Child-Care/#College_success',
                              'profile': '/california/san-francisco/22327-Wu-Yee-Child-Care/',
                              'reviews': '/california/san-francisco/22327-Wu-Yee-Child-Care/#Reviews'},
                          'lon': -122.402542,
                          'name': 'Wu Yee Child Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22328,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22328-Cleo-Wallace-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/22328-Cleo-Wallace-Child-Development-Center/',
                              'reviews': '/california/san-francisco/22328-Cleo-Wallace-Child-Development-Center/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Cleo Wallace Child Development Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '713 Monterey Boulevard',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22329,
                          'lat': 37.731373,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22329-Wind-In-The-Willows-Learning-Center/#College_success',
                              'profile': '/california/san-francisco/22329-Wind-In-The-Willows-Learning-Center/',
                              'reviews': '/california/san-francisco/22329-Wind-In-The-Willows-Learning-Center/#Reviews'},
                          'lon': -122.451447,
                          'name': 'Wind In The Willows Learning Center',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22330,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22330-Chinatown-N-Beach-Child-Center/#College_success',
                              'profile': '/california/san-francisco/22330-Chinatown-N-Beach-Child-Center/',
                              'reviews': '/california/san-francisco/22330-Chinatown-N-Beach-Child-Center/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Chinatown N Beach Child Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 15366',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22331,
                          'lat': 37.78775,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22331-Ccfc-30th-Ave/#College_success',
                                    'profile': '/california/san-francisco/22331-Ccfc-30th-Ave/',
                                    'reviews': '/california/san-francisco/22331-Ccfc-30th-Ave/#Reviews'},
                          'lon': -122.438232,
                          'name': 'Ccfc-30th Ave',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 15366',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22332,
                          'lat': 37.78775,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22332-Ccfc-31st-Ave/#College_success',
                                    'profile': '/california/san-francisco/22332-Ccfc-31st-Ave/',
                                    'reviews': '/california/san-francisco/22332-Ccfc-31st-Ave/#Reviews'},
                          'lon': -122.438232,
                          'name': 'Ccfc-31st Ave',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '76 Topeka Ave',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22334,
                          'lat': 37.734047,
                          'levelCode': 'p',
                          'links': {'collegeSuccess': '/california/san-francisco/22334-Faith-Hope/#College_success',
                                    'profile': '/california/san-francisco/22334-Faith-Hope/',
                                    'reviews': '/california/san-francisco/22334-Faith-Hope/#Reviews'},
                          'lon': -122.400002,
                          'name': 'Faith Hope',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '77 Beale St Fl Mc-B2a',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22335,
                          'lat': 37.789017,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22335-Pacific-Gas--Electric-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22335-Pacific-Gas--Electric-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22335-Pacific-Gas--Electric-Child-Care-Center/#Reviews'},
                          'lon': -122.391502,
                          'name': 'Pacific Gas & Electric Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '775 7th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22336,
                          'lat': 37.773998,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22336-Angelas-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22336-Angelas-Children-Center/',
                              'reviews': '/california/san-francisco/22336-Angelas-Children-Center/#Reviews'},
                          'lon': -122.465179,
                          'name': "Angela's Children Center",
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '777 Stockton Street #201',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22337,
                          'lat': 37.792976,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22337-True-Sunshine-Pre-School-Center/#College_success',
                              'profile': '/california/san-francisco/22337-True-Sunshine-Pre-School-Center/',
                              'reviews': '/california/san-francisco/22337-True-Sunshine-Pre-School-Center/#Reviews'},
                          'lon': -122.407791,
                          'name': 'True Sunshine Pre-School Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '790 Folsom Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22338,
                          'lat': 37.782375,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22338-South-Market-Daycare-Center/#College_success',
                              'profile': '/california/san-francisco/22338-South-Market-Daycare-Center/',
                              'reviews': '/california/san-francisco/22338-South-Market-Daycare-Center/#Reviews'},
                          'lon': -122.400787,
                          'name': 'South Market Daycare Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1426 Fillmore Street #301',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22339,
                          'lat': 37.782825,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22339-Rainbow-Pre-School--Infant-Care/#College_success',
                              'profile': '/california/san-francisco/22339-Rainbow-Pre-School--Infant-Care/',
                              'reviews': '/california/san-francisco/22339-Rainbow-Pre-School--Infant-Care/#Reviews'},
                          'lon': -122.432365,
                          'name': 'Rainbow Pre-School & Infant Care',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '80 Juan Bautista Circle',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 24,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 100}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22340,
                          'lat': 37.718285,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22340-Montessori-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22340-Montessori-Childrens-Center/',
                              'reviews': '/california/san-francisco/22340-Montessori-Childrens-Center/#Reviews'},
                          'lon': -122.479141,
                          'name': "Montessori Children's Center",
                          'numReviews': 14,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '801 Treat Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 243,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22341,
                          'lat': 37.757347,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22341-Las-Americas-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22341-Las-Americas-Children-Center/',
                              'reviews': '/california/san-francisco/22341-Las-Americas-Children-Center/#Reviews'},
                          'lon': -122.413429,
                          'name': 'Las Americas Children Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2166 28th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22342,
                          'lat': 37.746998,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22342-Happy-Day-Pre-School/#College_success',
                              'profile': '/california/san-francisco/22342-Happy-Day-Pre-School/',
                              'reviews': '/california/san-francisco/22342-Happy-Day-Pre-School/#Reviews'},
                          'lon': -122.485313,
                          'name': 'Happy Day Pre-School',
                          'numReviews': 1,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '824 Carolina Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22343,
                          'lat': 37.758266,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22343-Potrero-Hill-Site-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22343-Potrero-Hill-Site-Head-Start/',
                              'reviews': '/california/san-francisco/22343-Potrero-Hill-Site-Head-Start/#Reviews'},
                          'lon': -122.400307,
                          'name': 'Potrero Hill Site Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1101 Masonic Avenue',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22344,
                          'lat': 37.770889,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22344-Faces-San-Francisco-Infant-Dcc/#College_success',
                              'profile': '/california/san-francisco/22344-Faces-San-Francisco-Infant-Dcc/',
                              'reviews': '/california/san-francisco/22344-Faces-San-Francisco-Infant-Dcc/#Reviews'},
                          'lon': -122.445869,
                          'name': 'Faces San Francisco Infant Dcc',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '820 Thachery St',
                                      'street2': '',
                                      'zip': '94111'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22345,
                          'lat': 37.795937,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22345-Kaiming-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22345-Kaiming-Head-Start/',
                              'reviews': '/california/san-francisco/22345-Kaiming-Head-Start/#Reviews'},
                          'lon': -122.400002,
                          'name': 'Kaiming Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '875 Ofarrell St',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22346,
                          'lat': 37.784771,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22346-Sfsu-Olive-Street-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22346-Sfsu-Olive-Street-Head-Start/',
                              'reviews': '/california/san-francisco/22346-Sfsu-Olive-Street-Head-Start/#Reviews'},
                          'lon': -122.419014,
                          'name': 'Sfsu-Olive Street Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '940 Filbert Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 95,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22347,
                          'lat': 37.80072,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22347-Cooper-Sarah-B.-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22347-Cooper-Sarah-B.-Children-Center/',
                              'reviews': '/california/san-francisco/22347-Cooper-Sarah-B.-Children-Center/#Reviews'},
                          'lon': -122.414841,
                          'name': 'Cooper (Sarah B.) Children Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '949 Washington Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 141,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22348,
                          'lat': 37.79483,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22348-Stockton-Commodore-Children-Center/#College_success',
                              'profile': '/california/san-francisco/22348-Stockton-Commodore-Children-Center/',
                              'reviews': '/california/san-francisco/22348-Stockton-Commodore-Children-Center/#Reviews'},
                          'lon': -122.40905,
                          'name': 'Stockton (Commodore) Children Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '95 Hawthorne Street',
                                      'street2': '',
                                      'zip': '94105'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22349,
                          'lat': 37.785046,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22349-Healthy-Environments-Cdc/#College_success',
                              'profile': '/california/san-francisco/22349-Healthy-Environments-Cdc/',
                              'reviews': '/california/san-francisco/22349-Healthy-Environments-Cdc/#Reviews'},
                          'lon': -122.397926,
                          'name': 'Healthy Environments Cdc',
                          'numReviews': 1,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '950 Hollister Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-K',
                          'highlighted': false,
                          'id': 22350,
                          'lat': 37.718361,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22350-Bret-Hart-Early-Education-School/#College_success',
                              'profile': '/california/san-francisco/22350-Bret-Hart-Early-Education-School/',
                              'reviews': '/california/san-francisco/22350-Bret-Hart-Early-Education-School/#Reviews'},
                          'lon': -122.389496,
                          'name': 'Bret Hart Early Education School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '950 Powell St',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22351,
                          'lat': 37.793659,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22351-Kai-Ming-Head-Start-Center/#College_success',
                              'profile': '/california/san-francisco/22351-Kai-Ming-Head-Start-Center/',
                              'reviews': '/california/san-francisco/22351-Kai-Ming-Head-Start-Center/#Reviews'},
                          'lon': -122.409508,
                          'name': 'Kai Ming Head Start Center',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '950 Powell Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22352,
                          'lat': 37.793648,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22352-Kai-Ming-Head-Start/#College_success',
                              'profile': '/california/san-francisco/22352-Kai-Ming-Head-Start/',
                              'reviews': '/california/san-francisco/22352-Kai-Ming-Head-Start/#Reviews'},
                          'lon': -122.409378,
                          'name': 'Kai Ming Head Start',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '979 Clay Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22353,
                          'lat': 37.793652,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22353-Chinatown-Community-Childrens-Center/#College_success',
                              'profile': '/california/san-francisco/22353-Chinatown-Community-Childrens-Center/',
                              'reviews': '/california/san-francisco/22353-Chinatown-Community-Childrens-Center/#Reviews'},
                          'lon': -122.408928,
                          'name': "Chinatown Community Children's Center",
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1 Letterman Drive',
                                      'street2': '',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 22354,
                          'lat': 37.798492,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/22354-Letterman-Digital-Arts-Child-Care-Center/#College_success',
                              'profile': '/california/san-francisco/22354-Letterman-Digital-Arts-Child-Care-Center/',
                              'reviews': '/california/san-francisco/22354-Letterman-Digital-Arts-Child-Care-Center/#Reviews'},
                          'lon': -122.449837,
                          'name': 'Letterman Digital Arts Child Care Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Po Box 320026',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 22355,
                          'lat': 37.71814,
                          'levelCode': 'e',
                          'links': {'collegeSuccess': '/california/san-francisco/22355-Everyday-Magic/#College_success',
                                    'profile': '/california/san-francisco/22355-Everyday-Magic/',
                                    'reviews': '/california/san-francisco/22355-Everyday-Magic/#Reviews'},
                          'lon': -122.486351,
                          'name': 'Everyday Magic',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1950 Page Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 37,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 25488,
                          'lat': 37.767311,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25488-Xian-Yun-Academy-Of-The-Arts-California/#College_success',
                              'profile': '/california/san-francisco/25488-Xian-Yun-Academy-Of-The-Arts-California/',
                              'reviews': '/california/san-francisco/25488-Xian-Yun-Academy-Of-The-Arts-California/#Reviews'},
                          'lon': -122.402199,
                          'name': 'Xian Yun Academy of the Arts California',
                          'numReviews': 48,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3333 California Street #220',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 23364,
                          'lat': 37.786652,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/23364-Marin-Day-Pre-School-Ucsf/#College_success',
                              'profile': '/california/san-francisco/23364-Marin-Day-Pre-School-Ucsf/',
                              'reviews': '/california/san-francisco/23364-Marin-Day-Pre-School-Ucsf/#Reviews'},
                          'lon': -122.449707,
                          'name': 'Marin Day Pre-School-Ucsf',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '10 Kirkham St',
                                      'street2': '',
                                      'zip': '94143'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 23365,
                          'lat': 37.760677,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/23365-Ucsf-Kirkham-Child-Development-Center/#College_success',
                              'profile': '/california/san-francisco/23365-Ucsf-Kirkham-Child-Development-Center/',
                              'reviews': '/california/san-francisco/23365-Ucsf-Kirkham-Child-Development-Center/#Reviews'},
                          'lon': -122.460815,
                          'name': 'Ucsf Kirkham Child Development Center',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '501 Cambridge Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 221,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 94},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1},
                                            {'label': 'Hispanic', 'percentage': 1}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 24003,
                          'lat': 37.724918,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24003-Cornerstone-Academy---Cambridge-Campus/#College_success',
                              'profile': '/california/san-francisco/24003-Cornerstone-Academy---Cambridge-Campus/',
                              'reviews': '/california/san-francisco/24003-Cornerstone-Academy---Cambridge-Campus/#Reviews'},
                          'lon': -122.417244,
                          'name': 'Cornerstone Academy - Cambridge Campus',
                          'numReviews': 24,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2222 Broadway Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 377,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 74},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'Asian', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 24219,
                          'lat': 37.79443,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24219-Convent-Of-The-Sacred-Heart-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/24219-Convent-Of-The-Sacred-Heart-Elementary-School/',
                              'reviews': '/california/san-francisco/24219-Convent-Of-The-Sacred-Heart-Elementary-School/#Reviews'},
                          'lon': -122.433929,
                          'name': 'Convent Of The Sacred Heart Elementary School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1835 Ellis Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 157,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 94},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 1},
                                            {'label': 'Asian', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 24308,
                          'lat': 37.781666,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24308-Jewish-Community-High-School-Of-The-Bay/#College_success',
                              'profile': '/california/san-francisco/24308-Jewish-Community-High-School-Of-The-Bay/',
                              'reviews': '/california/san-francisco/24308-Jewish-Community-High-School-Of-The-Bay/#Reviews'},
                          'lon': -122.436447,
                          'name': 'Jewish Community High School Of The Bay',
                          'numReviews': 21,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1775 44th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 42,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 64},
                                            {'label': 'Asian', 'percentage': 71},
                                            {'label': 'White', 'percentage': 12},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24740,
                          'lat': 37.753468,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24740-Noriega-Children-Center/#College_success',
                              'profile': '/california/san-francisco/24740-Noriega-Children-Center/',
                              'reviews': '/california/san-francisco/24740-Noriega-Children-Center/#Reviews'},
                          'lon': -122.50338,
                          'name': 'Noriega Children Center',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '64%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '387 Moraga Avenue',
                                      'street2': '',
                                      'zip': '94129'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 22,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 9},
                                            {'label': 'Asian', 'percentage': 36},
                                            {'label': 'White', 'percentage': 36},
                                            {'label': 'Hispanic', 'percentage': 14},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'Filipino', 'percentage': 5}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24742,
                          'lat': 37.797684,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24742-Presidio-Children-Center/#College_success',
                              'profile': '/california/san-francisco/24742-Presidio-Children-Center/',
                              'reviews': '/california/san-francisco/24742-Presidio-Children-Center/#Reviews'},
                          'lon': -122.461067,
                          'name': 'Presidio Children Center',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '9%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2110 Greenwich Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 56,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 23}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24745,
                          'lat': 37.79908,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24745-Tule-Elk-Park-Children-Center/#College_success',
                              'profile': '/california/san-francisco/24745-Tule-Elk-Park-Children-Center/',
                              'reviews': '/california/san-francisco/24745-Tule-Elk-Park-Children-Center/#Reviews'},
                          'lon': -122.434807,
                          'name': 'Tule Elk Park Children Center',
                          'numReviews': 36,
                          'parentRating': 5,
                          'percentLowIncome': '23%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1520 Oakdale Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 173,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24747,
                          'lat': 37.733772,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24747-Havard-Leona-M-Early-Education-School/#College_success',
                              'profile': '/california/san-francisco/24747-Havard-Leona-M-Early-Education-School/',
                              'reviews': '/california/san-francisco/24747-Havard-Leona-M-Early-Education-School/#Reviews'},
                          'lon': -122.38887,
                          'name': 'Havard (Leona M) Early Education School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '990 Church Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 100}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24748,
                          'lat': 37.755547,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24748-Mahler-Theresa-S.-Children-Center/#College_success',
                              'profile': '/california/san-francisco/24748-Mahler-Theresa-S.-Children-Center/',
                              'reviews': '/california/san-francisco/24748-Mahler-Theresa-S.-Children-Center/#Reviews'},
                          'lon': -122.428001,
                          'name': 'Mahler (Theresa S.) Children Center',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '0%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '70 Oak Grove',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '25%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 109,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 45},
                                            {'label': 'White', 'percentage': 21},
                                            {'label': 'Hispanic', 'percentage': 17},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'Pacific Islander', 'percentage': 5},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 24793,
                          'lat': 37.777161,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24793-Five-Keys-Adult-School-Sf-Sheriffs/#College_success',
                              'profile': '/california/san-francisco/24793-Five-Keys-Adult-School-Sf-Sheriffs/',
                              'reviews': '/california/san-francisco/24793-Five-Keys-Adult-School-Sf-Sheriffs/#Reviews'},
                          'lon': -122.402153,
                          'name': "Five Keys Adult School (Sf Sheriff's)",
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '2%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '70 Oak Grove',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '16%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 3417,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 13,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'African American',
                                             'percentage': 21,
                                             'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 62,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 9},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 24794,
                          'lat': 37.777168,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24794-Five-Keys-Independence-High-School-Sf-Sheriffs/#College_success',
                              'profile': '/california/san-francisco/24794-Five-Keys-Independence-High-School-Sf-Sheriffs/',
                              'reviews': '/california/san-francisco/24794-Five-Keys-Independence-High-School-Sf-Sheriffs/#Reviews'},
                          'lon': -122.402138,
                          'name': "Five Keys Independence High School (Sf Sheriff's)",
                          'numReviews': 7,
                          'parentRating': 5,
                          'percentLowIncome': '13%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'Equity Overview Rating': 2, 'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1600 Holloway Ave.',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24987,
                          'lat': 37.721237,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24987-Childrens-Campus-At-San-Francisco-State-University/#College_success',
                              'profile': '/california/san-francisco/24987-Childrens-Campus-At-San-Francisco-State-University/',
                              'reviews': '/california/san-francisco/24987-Childrens-Campus-At-San-Francisco-State-University/#Reviews'},
                          'lon': -122.479469,
                          'name': "Children's Campus at San Francisco State University",
                          'numReviews': 2,
                          'parentRating': 4,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1050 York Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '60%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 297,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 73,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Asian', 'percentage': 19, 'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 64,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 8},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 25018,
                          'lat': 37.755211,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25018-S.F.-International-High-School/#College_success',
                              'profile': '/california/san-francisco/25018-S.F.-International-High-School/',
                              'reviews': '/california/san-francisco/25018-S.F.-International-High-School/#Reviews'},
                          'lon': -122.40844,
                          'name': 'S.F. International High School',
                          'numReviews': 12,
                          'parentRating': 4,
                          'percentLowIncome': '73%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 9,
                          'subratings': {'College Readiness Rating': 1,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '555 Portola Drive',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '67%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 358,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 55,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'Asian', 'percentage': 19, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 16,
                                             'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 35,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 12},
                                            {'label': 'Filipino', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 25079,
                          'lat': 37.745872,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25079-Academy-Of-Arts-And-Sciences/#College_success',
                              'profile': '/california/san-francisco/25079-Academy-Of-Arts-And-Sciences/',
                              'reviews': '/california/san-francisco/25079-Academy-Of-Arts-And-Sciences/#Reviews'},
                          'lon': -122.449982,
                          'name': 'Academy Of Arts And Sciences',
                          'numReviews': 8,
                          'parentRating': 3,
                          'percentLowIncome': '55%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'College Readiness Rating': 6,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1250 Waller Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 387,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 26,
                                             'rating': 10},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 67, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 12,
                                             'rating': 10},
                                            {'label': 'White', 'percentage': 9},
                                            {'label': 'Hispanic', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 25096,
                          'lat': 37.769463,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25096-Chinese-Immersion-School-At-Deavila/#College_success',
                              'profile': '/california/san-francisco/25096-Chinese-Immersion-School-At-Deavila/',
                              'reviews': '/california/san-francisco/25096-Chinese-Immersion-School-At-Deavila/#Reviews'},
                          'lon': -122.44429,
                          'name': 'Chinese Immersion School At Deavila',
                          'numReviews': 14,
                          'parentRating': 5,
                          'percentLowIncome': '26%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 21,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1601 10th Ave.',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 26921,
                          'lat': 37.758152,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26921-Gates-And-Bridges-Preschool/#College_success',
                              'profile': '/california/san-francisco/26921-Gates-And-Bridges-Preschool/',
                              'reviews': '/california/san-francisco/26921-Gates-And-Bridges-Preschool/#Reviews'},
                          'lon': -122.467163,
                          'name': 'Gates and Bridges Preschool',
                          'numReviews': 4,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '465 Missouri St.',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 25201,
                          'lat': 37.760616,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25201-Potrero-Kids-At-Daniel-Webster-PKDW-Preschool/#College_success',
                              'profile': '/california/san-francisco/25201-Potrero-Kids-At-Daniel-Webster-PKDW-Preschool/',
                              'reviews': '/california/san-francisco/25201-Potrero-Kids-At-Daniel-Webster-PKDW-Preschool/#Reviews'},
                          'lon': -122.396225,
                          'name': 'Potrero Kids at Daniel Webster (PKDW) Preschool',
                          'numReviews': 7,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2340 Jackson Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 173,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 35,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'White', 'rating': 8}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 25336,
                          'lat': 37.792618,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25336-San-Francisco-Public-Montessori-School/#College_success',
                              'profile': '/california/san-francisco/25336-San-Francisco-Public-Montessori-School/',
                              'reviews': '/california/san-francisco/25336-San-Francisco-Public-Montessori-School/#Reviews'},
                          'lon': -122.43354,
                          'name': 'San Francisco Public Montessori School',
                          'numReviews': 84,
                          'parentRating': 4,
                          'percentLowIncome': '35%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 22,
                          'subratings': {'Equity Overview Rating': 3, 'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '301 14th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 30,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 25498,
                          'lat': 37.782169,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25498-San-Francisco-Schoolhouse/#College_success',
                              'profile': '/california/san-francisco/25498-San-Francisco-Schoolhouse/',
                              'reviews': '/california/san-francisco/25498-San-Francisco-Schoolhouse/#Reviews'},
                          'lon': -122.473213,
                          'name': 'San Francisco Schoolhouse',
                          'numReviews': 52,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '250 10th Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 254,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 35},
                                            {'label': 'Two or more races', 'percentage': 33},
                                            {'label': 'White', 'percentage': 26},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'PK-7',
                          'highlighted': false,
                          'id': 25505,
                          'lat': 37.772945,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25505-Presidio-Knolls-School/#College_success',
                              'profile': '/california/san-francisco/25505-Presidio-Knolls-School/',
                              'reviews': '/california/san-francisco/25505-Presidio-Knolls-School/#Reviews'},
                          'lon': -122.41362,
                          'name': 'Presidio Knolls School',
                          'numReviews': 52,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '360 9th Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 63,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 70},
                                            {'label': 'Two or more races', 'percentage': 24},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'Hispanic', 'percentage': 2}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 25509,
                          'lat': 37.762192,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25509-San-Francisco-Brightworks/#College_success',
                              'profile': '/california/san-francisco/25509-San-Francisco-Brightworks/',
                              'reviews': '/california/san-francisco/25509-San-Francisco-Brightworks/#Reviews'},
                          'lon': -122.410194,
                          'name': 'San Francisco Brightworks',
                          'numReviews': 85,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '117 Diamond Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 25528,
                          'lat': 37.76022,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25528-Marin-Preparatory-School/#College_success',
                              'profile': '/california/san-francisco/25528-Marin-Preparatory-School/',
                              'reviews': '/california/san-francisco/25528-Marin-Preparatory-School/#Reviews'},
                          'lon': -122.436897,
                          'name': 'Marin Preparatory School',
                          'numReviews': 37,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': 'Preschool Campus: 728 20th St;   K-8 Campus 735 Fell Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 21,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-8',
                          'highlighted': false,
                          'id': 25534,
                          'lat': 37.760647,
                          'levelCode': 'p,e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25534-La-Scuola-International-School-/#College_success',
                              'profile': '/california/san-francisco/25534-La-Scuola-International-School-/',
                              'reviews': '/california/san-francisco/25534-La-Scuola-International-School-/#Reviews'},
                          'lon': -122.389214,
                          'name': 'La Scuola International School ',
                          'numReviews': 106,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1442 Fulton Street',
                                      'street2': 'Up the Outside Stairs to Second Floor',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 9,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'African American', 'percentage': 44},
                                            {'label': 'Hispanic', 'percentage': 44},
                                            {'label': 'White', 'percentage': 11}],
                          'gradeLevels': 'PK-12',
                          'highlighted': false,
                          'id': 25564,
                          'lat': 37.776836,
                          'levelCode': 'p,e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25564-Discovery-Center-School/#College_success',
                              'profile': '/california/san-francisco/25564-Discovery-Center-School/',
                              'reviews': '/california/san-francisco/25564-Discovery-Center-School/#Reviews'},
                          'lon': -122.440605,
                          'name': 'Discovery Center School',
                          'numReviews': 8,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1512 Golden Gate Avenue',
                                      'street2': 'Fourth Floor',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 309,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 46,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 11, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 21,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 31,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 21, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 13,
                                             'rating': 8},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 25633,
                          'lat': 37.779438,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25633-Gateway-Middle-School/#College_success',
                              'profile': '/california/san-francisco/25633-Gateway-Middle-School/',
                              'reviews': '/california/san-francisco/25633-Gateway-Middle-School/#Reviews'},
                          'lon': -122.435997,
                          'name': 'Gateway Middle School',
                          'numReviews': 13,
                          'parentRating': 5,
                          'percentLowIncome': '46%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1050 York Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 385,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 82,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 81,
                                             'rating': 9},
                                            {'label': 'Asian', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 25813,
                          'lat': 37.75544,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/25813-Mission-Preparatory-School/#College_success',
                              'profile': '/california/san-francisco/25813-Mission-Preparatory-School/',
                              'reviews': '/california/san-francisco/25813-Mission-Preparatory-School/#Reviews'},
                          'lon': -122.408569,
                          'name': 'Mission Preparatory School',
                          'numReviews': 34,
                          'parentRating': 4,
                          'percentLowIncome': '82%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1763 Newcomb Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 21,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-12',
                          'highlighted': false,
                          'id': 26288,
                          'lat': 37.736774,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26288-Coming-Of-Age-Christian-Academy/#College_success',
                              'profile': '/california/san-francisco/26288-Coming-Of-Age-Christian-Academy/',
                              'reviews': '/california/san-francisco/26288-Coming-Of-Age-Christian-Academy/#Reviews'},
                          'lon': -122.392998,
                          'name': 'Coming Of Age Christian Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '--',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 41,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'Asian', 'percentage': 85},
                                            {'label': 'Two or more races', 'percentage': 15}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 26289,
                          'lat': 37.770531,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26289-San-Francisco-High-School-Of-The-Arts-/#College_success',
                              'profile': '/california/san-francisco/26289-San-Francisco-High-School-Of-The-Arts-/',
                              'reviews': '/california/san-francisco/26289-San-Francisco-High-School-Of-The-Arts-/#Reviews'},
                          'lon': -122.452827,
                          'name': 'San Francisco High School of the Arts ',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1160 Battery Street',
                                      'street2': 'Floor 3',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 55,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 65},
                                            {'label': 'Two or more races', 'percentage': 13},
                                            {'label': 'Asian', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': '6-12',
                          'highlighted': false,
                          'id': 26290,
                          'lat': 37.797112,
                          'levelCode': 'm,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26290-Fusion-Academy-San-Francisco/#College_success',
                              'profile': '/california/san-francisco/26290-Fusion-Academy-San-Francisco/',
                              'reviews': '/california/san-francisco/26290-Fusion-Academy-San-Francisco/#Reviews'},
                          'lon': -122.40416,
                          'name': 'Fusion Academy San Francisco',
                          'numReviews': 24,
                          'parentRating': 3,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '503 Cambridge St. ',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 58,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                            {'label': 'White', 'percentage': 74},
                                            {'label': 'Asian', 'percentage': 8},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'Hispanic', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 3}],
                          'gradeLevels': 'K',
                          'highlighted': false,
                          'id': 31533,
                          'lat': 37.723125,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/31533-Golden-Bridges-School/#College_success',
                              'profile': '/california/san-francisco/31533-Golden-Bridges-School/',
                              'reviews': '/california/san-francisco/31533-Golden-Bridges-School/#Reviews'},
                          'lon': -122.438095,
                          'name': 'Golden Bridges School',
                          'numReviews': 48,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '825 Brotherhood Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 95,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 26291,
                          'lat': 37.713943,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26291-Krouzian-Zekarian-Armenian-School/#College_success',
                              'profile': '/california/san-francisco/26291-Krouzian-Zekarian-Armenian-School/',
                              'reviews': '/california/san-francisco/26291-Krouzian-Zekarian-Armenian-School/#Reviews'},
                          'lon': -122.47831,
                          'name': 'Krouzian Zekarian Armenian School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '135 Evelyn Way',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': '3-12',
                          'highlighted': false,
                          'id': 26292,
                          'lat': 37.743107,
                          'levelCode': 'e,m,h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26292-Oaks-Christian-Academy/#College_success',
                              'profile': '/california/san-francisco/26292-Oaks-Christian-Academy/',
                              'reviews': '/california/san-francisco/26292-Oaks-Christian-Academy/#Reviews'},
                          'lon': -122.452568,
                          'name': 'Oaks Christian Academy',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '150 Parker Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K',
                          'highlighted': false,
                          'id': 26293,
                          'lat': 37.782818,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26293-One-Fifty-Parker-Avenue-School/#College_success',
                              'profile': '/california/san-francisco/26293-One-Fifty-Parker-Avenue-School/',
                              'reviews': '/california/san-francisco/26293-One-Fifty-Parker-Avenue-School/#Reviews'},
                          'lon': -122.454102,
                          'name': 'One Fifty Parker Avenue School',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '925 Chenery Street',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 250,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 26294,
                          'lat': 37.735073,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26294-St.-John-The-Evangelist-School/#College_success',
                              'profile': '/california/san-francisco/26294-St.-John-The-Evangelist-School/',
                              'reviews': '/california/san-francisco/26294-St.-John-The-Evangelist-School/#Reviews'},
                          'lon': -122.437866,
                          'name': 'St. John the Evangelist School',
                          'numReviews': 12,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2426 California Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 26458,
                          'lat': 37.788963,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26458-Golden-Gate-Guppies-Preschool/#College_success',
                              'profile': '/california/san-francisco/26458-Golden-Gate-Guppies-Preschool/',
                              'reviews': '/california/san-francisco/26458-Golden-Gate-Guppies-Preschool/#Reviews'},
                          'lon': -122.434502,
                          'name': 'Golden Gate Guppies Preschool',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2265 3rd Street and 610 20th Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK-1',
                          'highlighted': false,
                          'id': 26947,
                          'lat': 37.760956,
                          'levelCode': 'p,e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/26947-AltSchool---Dogpatch/#College_success',
                              'profile': '/california/san-francisco/26947-AltSchool---Dogpatch/',
                              'reviews': '/california/san-francisco/26947-AltSchool---Dogpatch/#Reviews'},
                          'lon': -122.388298,
                          'name': 'AltSchool - Dogpatch',
                          'numReviews': 2,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '325 La Grande Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '70%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 233,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 73,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 64,
                                             'rating': 1},
                                            {'label': 'African American', 'percentage': 21},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 12283,
                          'lat': 37.719997,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/12283-Jordan-June-School-For-Equity/#College_success',
                              'profile': '/california/san-francisco/12283-Jordan-June-School-For-Equity/',
                              'reviews': '/california/san-francisco/12283-Jordan-June-School-For-Equity/#Reviews'},
                          'lon': -122.425499,
                          'name': 'Jordan (June) School For Equity',
                          'numReviews': 6,
                          'parentRating': 3,
                          'percentLowIncome': '73%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 9,
                          'subratings': {'College Readiness Rating': 1,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Thomas More Way',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 32354,
                          'lat': 37.712734,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/32354-Utopia-Preschool/#College_success',
                              'profile': '/california/san-francisco/32354-Utopia-Preschool/',
                              'reviews': '/california/san-francisco/32354-Utopia-Preschool/#Reviews'},
                          'lon': -122.473557,
                          'name': 'Utopia Preschool',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '250 23rd Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 529,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 37,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 43, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 10,
                                             'rating': 8},
                                            {'label': 'White', 'percentage': 25, 'rating': 8},
                                            {'label': 'Two or more races',
                                             'percentage': 11,
                                             'rating': 10},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6335,
                          'lat': 37.783123,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6335-Alamo-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6335-Alamo-Elementary-School/',
                              'reviews': '/california/san-francisco/6335-Alamo-Elementary-School/#Reviews'},
                          'lon': -122.482193,
                          'name': 'Alamo Elementary School',
                          'numReviews': 34,
                          'parentRating': 5,
                          'percentLowIncome': '37%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '625 Douglas Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 515,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 26,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 42,
                                             'rating': 6},
                                            {'label': 'White', 'percentage': 40, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6336,
                          'lat': 37.753674,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6336-Alvarado-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6336-Alvarado-Elementary-School/',
                              'reviews': '/california/san-francisco/6336-Alvarado-Elementary-School/#Reviews'},
                          'lon': -122.438728,
                          'name': 'Alvarado Elementary School',
                          'numReviews': 223,
                          'parentRating': 4,
                          'percentLowIncome': '26%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '105 Aptos Avenue',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 976,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 55,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 41, 'rating': 9},
                                            {'label': 'African American',
                                             'percentage': 10,
                                             'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 21,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 16, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 5,
                                             'rating': 8},
                                            {'label': 'Filipino',
                                             'percentage': 4,
                                             'rating': 5},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6337,
                          'lat': 37.730232,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6337-Aptos-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6337-Aptos-Middle-School/',
                              'reviews': '/california/san-francisco/6337-Aptos-Middle-School/#Reviews'},
                          'lon': -122.466232,
                          'name': 'Aptos Middle School',
                          'numReviews': 37,
                          'parentRating': 4,
                          'percentLowIncome': '55%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '680 18th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 433,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 25,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 30, 'rating': 9},
                                            {'label': 'White', 'percentage': 31, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 17,
                                             'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 10},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6338,
                          'lat': 37.77515,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6338-Argonne-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6338-Argonne-Elementary-School/',
                              'reviews': '/california/san-francisco/6338-Argonne-Elementary-School/#Reviews'},
                          'lon': -122.476791,
                          'name': 'Argonne Elementary School',
                          'numReviews': 38,
                          'parentRating': 5,
                          'percentLowIncome': '25%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '555 Portola Drive',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '82%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 795,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 19,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 16, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 8,
                                             'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 16,
                                             'rating': 7},
                                            {'label': 'White', 'percentage': 39, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 8,
                                             'rating': 7},
                                            {'label': 'Filipino', 'percentage': 5}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6339,
                          'lat': 37.745872,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6339-Asawa-Ruth-San-Francisco-School-Of-The-Arts-A-Public-School./#College_success',
                              'profile': '/california/san-francisco/6339-Asawa-Ruth-San-Francisco-School-Of-The-Arts-A-Public-School./',
                              'reviews': '/california/san-francisco/6339-Asawa-Ruth-San-Francisco-School-Of-The-Arts-A-Public-School./#Reviews'},
                          'lon': -122.449982,
                          'name': 'Asawa (Ruth) San Francisco School Of The Arts, A Public School.',
                          'numReviews': 42,
                          'parentRating': 5,
                          'percentLowIncome': '19%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'College Readiness Rating': 10,
                                         'Equity Overview Rating': 8,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1000 Cayuga Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '77%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1217,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 69,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'Asian', 'percentage': 40, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 34,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 3, 'rating': 7},
                                            {'label': 'Filipino',
                                             'percentage': 8,
                                             'rating': 5},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6340,
                          'lat': 37.722073,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6340-Balboa-High-School/#College_success',
                              'profile': '/california/san-francisco/6340-Balboa-High-School/',
                              'reviews': '/california/san-francisco/6340-Balboa-High-School/#Reviews'},
                          'lon': -122.43985,
                          'name': 'Balboa High School',
                          'numReviews': 44,
                          'parentRating': 4,
                          'percentLowIncome': '69%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'College Readiness Rating': 7,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2641 25th Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 223,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 80,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 83,
                                             'rating': 2},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6341,
                          'lat': 37.751629,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6341-Bryant-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6341-Bryant-Elementary-School/',
                              'reviews': '/california/san-francisco/6341-Bryant-Elementary-School/#Reviews'},
                          'lon': -122.4049,
                          'name': 'Bryant Elementary School',
                          'numReviews': 5,
                          'parentRating': 0,
                          'percentLowIncome': '80%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '400 Mansell Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '70%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1092,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 67,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 35, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 9,
                                             'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 30,
                                             'rating': 3},
                                            {'label': 'Filipino',
                                             'percentage': 15,
                                             'rating': 5},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6344,
                          'lat': 37.720699,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6344-Burton-Phillip-And-Sala-Academic-High-School/#College_success',
                              'profile': '/california/san-francisco/6344-Burton-Phillip-And-Sala-Academic-High-School/',
                              'reviews': '/california/san-francisco/6344-Burton-Phillip-And-Sala-Academic-High-School/#Reviews'},
                          'lon': -122.405067,
                          'name': 'Burton (Phillip And Sala) Academic High School',
                          'numReviews': 24,
                          'parentRating': 4,
                          'percentLowIncome': '67%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'College Readiness Rating': 6,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '375 Seventh Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 625,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 77,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 27,
                                             'rating': 2},
                                            {'label': 'Filipino',
                                             'percentage': 30,
                                             'rating': 5},
                                            {'label': 'African American', 'percentage': 12},
                                            {'label': 'Asian', 'percentage': 9},
                                            {'label': 'White', 'percentage': 8},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6346,
                          'lat': 37.775856,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6346-Carmichael-Bessie-Fec/#College_success',
                              'profile': '/california/san-francisco/6346-Carmichael-Bessie-Fec/',
                              'reviews': '/california/san-francisco/6346-Carmichael-Bessie-Fec/#Reviews'},
                          'lon': -122.406738,
                          'name': 'Carmichael (Bessie)/Fec',
                          'numReviews': 24,
                          'parentRating': 4,
                          'percentLowIncome': '77%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1360 Oakdale Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 151,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 87,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American', 'rating': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6347,
                          'lat': 37.732403,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6347-Carver-George-Washington-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6347-Carver-George-Washington-Elementary-School/',
                              'reviews': '/california/san-francisco/6347-Carver-George-Washington-Elementary-School/#Reviews'},
                          'lon': -122.386383,
                          'name': 'Carver (George Washington) Elementary School',
                          'numReviews': 1,
                          'parentRating': 1,
                          'percentLowIncome': '87%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '825 Shotwell Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 434,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 76,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 85,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6348,
                          'lat': 37.754879,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6348-Chavez-Cesar-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6348-Chavez-Cesar-Elementary-School/',
                              'reviews': '/california/san-francisco/6348-Chavez-Cesar-Elementary-School/#Reviews'},
                          'lon': -122.415413,
                          'name': 'Chavez (Cesar) Elementary School',
                          'numReviews': 6,
                          'parentRating': 3,
                          'percentLowIncome': '76%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 Broadway Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 266,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 74,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 83, 'rating': 10},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'Hispanic', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6349,
                          'lat': 37.798309,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6349-Chin-John-Yehall-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6349-Chin-John-Yehall-Elementary-School/',
                              'reviews': '/california/san-francisco/6349-Chin-John-Yehall-Elementary-School/#Reviews'},
                          'lon': -122.403168,
                          'name': 'Chin (John Yehall) Elementary School',
                          'numReviews': 46,
                          'parentRating': 3,
                          'percentLowIncome': '74%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 23,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '657 Merchant Street',
                                      'street2': '',
                                      'zip': '94111'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 50,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 92},
                                            {'label': 'Asian', 'percentage': 88}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6350,
                          'lat': 37.794907,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6350-Chinese-Education-Center/#College_success',
                              'profile': '/california/san-francisco/6350-Chinese-Education-Center/',
                              'reviews': '/california/san-francisco/6350-Chinese-Education-Center/#Reviews'},
                          'lon': -122.404137,
                          'name': 'Chinese Education Center',
                          'numReviews': 3,
                          'parentRating': 5,
                          'percentLowIncome': '92%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '500 Clarendon Avenue',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 555,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 22,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 22, 'rating': 10},
                                            {'label': 'Hispanic',
                                             'percentage': 14,
                                             'rating': 7},
                                            {'label': 'White', 'percentage': 34, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 17,
                                             'rating': 10},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6351,
                          'lat': 37.752991,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6351-Clarendon-Alternative-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6351-Clarendon-Alternative-Elementary-School/',
                              'reviews': '/california/san-francisco/6351-Clarendon-Alternative-Elementary-School/#Reviews'},
                          'lon': -122.456108,
                          'name': 'Clarendon Alternative Elementary School',
                          'numReviews': 27,
                          'parentRating': 5,
                          'percentLowIncome': '22%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 7,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '455 Athens Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 346,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 78,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 80,
                                             'rating': 1},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'White', 'percentage': 1},
                                            {'label': 'Asian', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6352,
                          'lat': 37.720734,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6352-Cleveland-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6352-Cleveland-Elementary-School/',
                              'reviews': '/california/san-francisco/6352-Cleveland-Elementary-School/#Reviews'},
                          'lon': -122.429039,
                          'name': 'Cleveland Elementary School',
                          'numReviews': 9,
                          'parentRating': 4,
                          'percentLowIncome': '78%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2725 California Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 152,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 75,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6353,
                          'lat': 37.7882,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6353-Cobb-William-L.-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6353-Cobb-William-L.-Elementary-School/',
                              'reviews': '/california/san-francisco/6353-Cobb-William-L.-Elementary-School/#Reviews'},
                          'lon': -122.439194,
                          'name': 'Cobb (William L.) Elementary School',
                          'numReviews': 25,
                          'parentRating': 5,
                          'percentLowIncome': '75%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Equity Overview Rating': 2, 'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1601 Turk Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 431,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 20,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'White', 'percentage': 56, 'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 16},
                                            {'label': 'Two or more races', 'percentage': 11},
                                            {'label': 'African American', 'percentage': 8},
                                            {'label': 'Asian', 'percentage': 6},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6355,
                          'lat': 37.780029,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6355-Creative-Arts-Charter-School/#College_success',
                              'profile': '/california/san-francisco/6355-Creative-Arts-Charter-School/',
                              'reviews': '/california/san-francisco/6355-Creative-Arts-Charter-School/#Reviews'},
                          'lon': -122.435432,
                          'name': 'Creative Arts Charter School',
                          'numReviews': 144,
                          'parentRating': 4,
                          'percentLowIncome': '20%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '241 Oneida Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 835,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 64,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 5},
                                            {'label': 'Asian', 'percentage': 21, 'rating': 9},
                                            {'label': 'African American',
                                             'percentage': 8,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 39,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 12, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 3,
                                             'rating': 2},
                                            {'label': 'Filipino',
                                             'percentage': 11,
                                             'rating': 5},
                                            {'label': 'Pacific Islander', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6358,
                          'lat': 37.721992,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6358-Denman-James-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6358-Denman-James-Middle-School/',
                              'reviews': '/california/san-francisco/6358-Denman-James-Middle-School/#Reviews'},
                          'lon': -122.443314,
                          'name': 'Denman (James) Middle School',
                          'numReviews': 64,
                          'parentRating': 4,
                          'percentLowIncome': '64%',
                          'pinned': null,
                          'rating': 4,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 5},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '693 Vermont Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '37%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 171,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 72},
                                            {'label': 'Hispanic', 'percentage': 50},
                                            {'label': 'African American', 'percentage': 27},
                                            {'label': 'Pacific Islander', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6359,
                          'lat': 37.760941,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6359-Downtown-High-School/#College_success',
                              'profile': '/california/san-francisco/6359-Downtown-High-School/',
                              'reviews': '/california/san-francisco/6359-Downtown-High-School/#Reviews'},
                          'lon': -122.404068,
                          'name': 'Downtown High School',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '72%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Pomona Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 199,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 75,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American', 'rating': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6360,
                          'lat': 37.731777,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6360-Drew-Charles-College-Preparatory-Academy/#College_success',
                              'profile': '/california/san-francisco/6360-Drew-Charles-College-Preparatory-Academy/',
                              'reviews': '/california/san-francisco/6360-Drew-Charles-College-Preparatory-Academy/#Reviews'},
                          'lon': -122.393547,
                          'name': 'Drew (Charles) College Preparatory Academy',
                          'numReviews': 6,
                          'parentRating': 4,
                          'percentLowIncome': '75%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '70 Delta Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 169,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 78,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American',
                                             'percentage': 22,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 39,
                                             'rating': 1},
                                            {'label': 'Two or more races', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 9},
                                            {'label': 'Asian', 'percentage': 7},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6362,
                          'lat': 37.719265,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6362-El-Dorado-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6362-El-Dorado-Elementary-School/',
                              'reviews': '/california/san-francisco/6362-El-Dorado-Elementary-School/#Reviews'},
                          'lon': -122.406731,
                          'name': 'El Dorado Elementary School',
                          'numReviews': 3,
                          'parentRating': 4,
                          'percentLowIncome': '78%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 13,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '450 Church Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 694,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 60,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'African American',
                                             'percentage': 9,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 61,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 17, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 4,
                                             'rating': 6},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6363,
                          'lat': 37.763668,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6363-Everett-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6363-Everett-Middle-School/',
                              'reviews': '/california/san-francisco/6363-Everett-Middle-School/#Reviews'},
                          'lon': -122.428581,
                          'name': 'Everett Middle School',
                          'numReviews': 47,
                          'parentRating': 3,
                          'percentLowIncome': '60%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 13,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '65 Chenery Street',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 366,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 61,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 72,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 11},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6364,
                          'lat': 37.740658,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6364-Fairmount-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6364-Fairmount-Elementary-School/',
                              'reviews': '/california/san-francisco/6364-Fairmount-Elementary-School/#Reviews'},
                          'lon': -122.425591,
                          'name': 'Fairmount Elementary School',
                          'numReviews': 39,
                          'parentRating': 4,
                          'percentLowIncome': '61%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 3,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3125 Cesar Chavez Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 432,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 64,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American',
                                             'percentage': 12,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 60,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 12},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6366,
                          'lat': 37.748192,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6366-Flynn-Leonard-R.-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6366-Flynn-Leonard-R.-Elementary-School/',
                              'reviews': '/california/san-francisco/6366-Flynn-Leonard-R.-Elementary-School/#Reviews'},
                          'lon': -122.412033,
                          'name': 'Flynn (Leonard R.) Elementary School',
                          'numReviews': 79,
                          'parentRating': 4,
                          'percentLowIncome': '64%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 13,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2190 Powell Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 560,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 70,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'Asian', 'percentage': 65, 'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 16,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 8, 'rating': 6},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6367,
                          'lat': 37.804817,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6367-Francisco-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6367-Francisco-Middle-School/',
                              'reviews': '/california/san-francisco/6367-Francisco-Middle-School/#Reviews'},
                          'lon': -122.411682,
                          'name': 'Francisco Middle School',
                          'numReviews': 39,
                          'parentRating': 4,
                          'percentLowIncome': '70%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1150 Francisco Street',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '78%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1816,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 67,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 56, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 5,
                                             'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 19,
                                             'rating': 5},
                                            {'label': 'White', 'percentage': 7, 'rating': 8},
                                            {'label': 'Filipino',
                                             'percentage': 6,
                                             'rating': 7},
                                            {'label': 'Two or more races', 'percentage': 2}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6369,
                          'lat': 37.803402,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6369-Galileo-High-School/#College_success',
                              'profile': '/california/san-francisco/6369-Galileo-High-School/',
                              'reviews': '/california/san-francisco/6369-Galileo-High-School/#Reviews'},
                          'lon': -122.42408,
                          'name': 'Galileo High School',
                          'numReviews': 40,
                          'parentRating': 5,
                          'percentLowIncome': '67%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'College Readiness Rating': 7,
                                         'Equity Overview Rating': 6,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '420 Filbert Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 228,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 53,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 46, 'rating': 7},
                                            {'label': 'Hispanic', 'percentage': 14},
                                            {'label': 'White', 'percentage': 14},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 3}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6370,
                          'lat': 37.801769,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6370-Garfield-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6370-Garfield-Elementary-School/',
                              'reviews': '/california/san-francisco/6370-Garfield-Elementary-School/#Reviews'},
                          'lon': -122.406731,
                          'name': 'Garfield Elementary School',
                          'numReviews': 62,
                          'parentRating': 5,
                          'percentLowIncome': '53%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 6,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1430 Scott Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '85%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 479,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 42,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'Asian', 'percentage': 17, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 13,
                                             'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 35,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 21, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 10,
                                             'rating': 7},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6371,
                          'lat': 37.783222,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6371-Gateway-High-School/#College_success',
                              'profile': '/california/san-francisco/6371-Gateway-High-School/',
                              'reviews': '/california/san-francisco/6371-Gateway-High-School/#Reviews'},
                          'lon': -122.437019,
                          'name': 'Gateway High School',
                          'numReviews': 43,
                          'parentRating': 4,
                          'percentLowIncome': '42%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'College Readiness Rating': 6,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3151 Ortega Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1188,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 37,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 57, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 4,
                                             'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 8,
                                             'rating': 8},
                                            {'label': 'White', 'percentage': 20, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 6,
                                             'rating': 10},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6372,
                          'lat': 37.751427,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6372-Giannini-A.P.-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6372-Giannini-A.P.-Middle-School/',
                              'reviews': '/california/san-francisco/6372-Giannini-A.P.-Middle-School/#Reviews'},
                          'lon': -122.497017,
                          'name': 'Giannini (A.P.) Middle School',
                          'numReviews': 26,
                          'parentRating': 4,
                          'percentLowIncome': '37%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '151 Lippard Avenue',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 363,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 52,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 48,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 24, 'rating': 10},
                                            {'label': 'African American', 'percentage': 10},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 4}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6373,
                          'lat': 37.733109,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6373-Glen-Park-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6373-Glen-Park-Elementary-School/',
                              'reviews': '/california/san-francisco/6373-Glen-Park-Elementary-School/#Reviews'},
                          'lon': -122.436081,
                          'name': 'Glen Park Elementary School',
                          'numReviews': 55,
                          'parentRating': 4,
                          'percentLowIncome': '52%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '165 Grattan Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 387,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 16,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'White', 'percentage': 57, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 14,
                                             'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 11},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6375,
                          'lat': 37.763859,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6375-Grattan-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6375-Grattan-Elementary-School/',
                              'reviews': '/california/san-francisco/6375-Grattan-Elementary-School/#Reviews'},
                          'lon': -122.450523,
                          'name': 'Grattan Elementary School',
                          'numReviews': 27,
                          'parentRating': 5,
                          'percentLowIncome': '16%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '859 Prague Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 387,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 76,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'Asian', 'percentage': 17, 'rating': 7},
                                            {'label': 'Hispanic',
                                             'percentage': 54,
                                             'rating': 2},
                                            {'label': 'Filipino',
                                             'percentage': 12,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'Two or more races', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6376,
                          'lat': 37.710438,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6376-Guadalupe-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6376-Guadalupe-Elementary-School/',
                              'reviews': '/california/san-francisco/6376-Guadalupe-Elementary-School/#Reviews'},
                          'lon': -122.43441,
                          'name': 'Guadalupe Elementary School',
                          'numReviews': 7,
                          'parentRating': 4,
                          'percentLowIncome': '76%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1035 Gilman Avenue',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 186,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 91,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 47,
                                             'rating': 1},
                                            {'label': 'African American', 'percentage': 25},
                                            {'label': 'Two or more races', 'percentage': 7},
                                            {'label': 'Pacific Islander', 'percentage': 6},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6377,
                          'lat': 37.718609,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6377-Harte-Bret-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6377-Harte-Bret-Elementary-School/',
                              'reviews': '/california/san-francisco/6377-Harte-Bret-Elementary-School/#Reviews'},
                          'lon': -122.388893,
                          'name': 'Harte (Bret) Elementary School',
                          'numReviews': 13,
                          'parentRating': 3,
                          'percentLowIncome': '91%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2290 14th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 971,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 60,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 56, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 27,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 9, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 2,
                                             'rating': 8},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6378,
                          'lat': 37.74535,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6378-Hoover-Herbert-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6378-Hoover-Herbert-Middle-School/',
                              'reviews': '/california/san-francisco/6378-Hoover-Herbert-Middle-School/#Reviews'},
                          'lon': -122.470306,
                          'name': 'Hoover (Herbert) Middle School',
                          'numReviews': 44,
                          'parentRating': 3,
                          'percentLowIncome': '60%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '810 Silver Avenue',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 420,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 85,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 5},
                                            {'label': 'Asian', 'percentage': 30, 'rating': 6},
                                            {'label': 'Hispanic',
                                             'percentage': 42,
                                             'rating': 4},
                                            {'label': 'African American', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 7},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6379,
                          'lat': 37.729027,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6379-Hillcrest-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6379-Hillcrest-Elementary-School/',
                              'reviews': '/california/san-francisco/6379-Hillcrest-Elementary-School/#Reviews'},
                          'lon': -122.419243,
                          'name': 'Hillcrest Elementary School',
                          'numReviews': 36,
                          'parentRating': 3,
                          'percentLowIncome': '85%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 5},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1725 Irving Street',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 490,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 31,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 37, 'rating': 9},
                                            {'label': 'White', 'percentage': 30, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 11,
                                             'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 11},
                                            {'label': 'Filipino', 'percentage': 3}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6384,
                          'lat': 37.763554,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6384-Jefferson-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6384-Jefferson-Elementary-School/',
                              'reviews': '/california/san-francisco/6384-Jefferson-Elementary-School/#Reviews'},
                          'lon': -122.476349,
                          'name': 'Jefferson Elementary School',
                          'numReviews': 21,
                          'parentRating': 5,
                          'percentLowIncome': '31%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 9,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1530 43rd Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 551,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 36,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 47, 'rating': 9},
                                            {'label': 'White', 'percentage': 22, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 11,
                                             'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6385,
                          'lat': 37.758156,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6385-Key-Francis-Scott-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6385-Key-Francis-Scott-Elementary-School/',
                              'reviews': '/california/san-francisco/6385-Key-Francis-Scott-Elementary-School/#Reviews'},
                          'lon': -122.502487,
                          'name': 'Key (Francis Scott) Elementary School',
                          'numReviews': 38,
                          'parentRating': 5,
                          'percentLowIncome': '36%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 Girard Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 516,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 77,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'Asian', 'percentage': 39, 'rating': 7},
                                            {'label': 'African American',
                                             'percentage': 12,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 30,
                                             'rating': 1},
                                            {'label': 'Filipino',
                                             'percentage': 7,
                                             'rating': 6},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6386,
                          'lat': 37.727947,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6386-King-Jr.-Martin-Luther-Academic-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6386-King-Jr.-Martin-Luther-Academic-Middle-School/',
                              'reviews': '/california/san-francisco/6386-King-Jr.-Martin-Luther-Academic-Middle-School/#Reviews'},
                          'lon': -122.404907,
                          'name': 'King Jr. (Martin Luther) Academic Middle School',
                          'numReviews': 16,
                          'parentRating': 3,
                          'percentLowIncome': '77%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4545 Anza Street',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 540,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 31,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 35, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 15,
                                             'rating': 9},
                                            {'label': 'White', 'percentage': 27, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 9,
                                             'rating': 10},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 2}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6387,
                          'lat': 37.777607,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6387-Lafayette-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6387-Lafayette-Elementary-School/',
                              'reviews': '/california/san-francisco/6387-Lafayette-Elementary-School/#Reviews'},
                          'lon': -122.49688,
                          'name': 'Lafayette Elementary School',
                          'numReviews': 35,
                          'parentRating': 5,
                          'percentLowIncome': '31%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '220 Middlefield Drive',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 484,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 51,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'Asian', 'percentage': 26, 'rating': 6},
                                            {'label': 'African American',
                                             'percentage': 12,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 20,
                                             'rating': 2},
                                            {'label': 'Two or more races',
                                             'percentage': 13,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 17},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6388,
                          'lat': 37.730602,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6388-Lakeshore-Alternative-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6388-Lakeshore-Alternative-Elementary-School/',
                              'reviews': '/california/san-francisco/6388-Lakeshore-Alternative-Elementary-School/#Reviews'},
                          'lon': -122.486153,
                          'name': 'Lakeshore Alternative Elementary School',
                          'numReviews': 111,
                          'parentRating': 4,
                          'percentLowIncome': '51%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '950 Clay Street',
                                      'street2': '',
                                      'zip': '94108'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 695,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 82,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 84, 'rating': 8},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6389,
                          'lat': 37.79398,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6389-Lau-Gordon-J.-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6389-Lau-Gordon-J.-Elementary-School/',
                              'reviews': '/california/san-francisco/6389-Lau-Gordon-J.-Elementary-School/#Reviews'},
                          'lon': -122.408684,
                          'name': 'Lau (Gordon J.) Elementary School',
                          'numReviews': 4,
                          'parentRating': 5,
                          'percentLowIncome': '82%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1570 31st Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 593,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 48,
                                             'rating': 10},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 72, 'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'White', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6390,
                          'lat': 37.757954,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6390-Lawton-Alternative-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6390-Lawton-Alternative-Elementary-School/',
                              'reviews': '/california/san-francisco/6390-Lawton-Alternative-Elementary-School/#Reviews'},
                          'lon': -122.489578,
                          'name': 'Lawton Alternative Elementary School',
                          'numReviews': 20,
                          'parentRating': 4,
                          'percentLowIncome': '48%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 Seneca Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '74%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 324,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 64,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'African American',
                                             'percentage': 13,
                                             'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 73,
                                             'rating': 3},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 2},
                                            {'label': 'White', 'percentage': 1},
                                            {'label': 'Asian', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6391,
                          'lat': 37.721527,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6391-Leadership-High-School/#College_success',
                              'profile': '/california/san-francisco/6391-Leadership-High-School/',
                              'reviews': '/california/san-francisco/6391-Leadership-High-School/#Reviews'},
                          'lon': -122.445572,
                          'name': 'Leadership High School',
                          'numReviews': 35,
                          'parentRating': 4,
                          'percentLowIncome': '64%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'College Readiness Rating': 4,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1220 Noe Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 568,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 71,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'African American',
                                             'percentage': 7,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 73,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 10, 'rating': 8},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6392,
                          'lat': 37.749542,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6392-Lick-James-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6392-Lick-James-Middle-School/',
                              'reviews': '/california/san-francisco/6392-Lick-James-Middle-School/#Reviews'},
                          'lon': -122.431747,
                          'name': 'Lick (James) Middle School',
                          'numReviews': 24,
                          'parentRating': 3,
                          'percentLowIncome': '71%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '651 Eighth Street, Building 229 Treasure Island',
                                      'street2': 'Building 229 Treasure Island',
                                      'zip': '94130'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 30,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 87,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'African American', 'percentage': 60},
                                            {'label': 'Hispanic', 'percentage': 20},
                                            {'label': 'White', 'percentage': 17},
                                            {'label': 'Asian', 'percentage': 3}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6393,
                          'lat': 37.770908,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6393-Life-Learning-Academy-Charter/#College_success',
                              'profile': '/california/san-francisco/6393-Life-Learning-Academy-Charter/',
                              'reviews': '/california/san-francisco/6393-Life-Learning-Academy-Charter/#Reviews'},
                          'lon': -122.404793,
                          'name': 'Life Learning Academy Charter',
                          'numReviews': 5,
                          'parentRating': 3,
                          'percentLowIncome': '87%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'charter',
                          'state': 'CA',
                          'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3630 Divisadero Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 697,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 17,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 29, 'rating': 10},
                                            {'label': 'Hispanic',
                                             'percentage': 11,
                                             'rating': 8},
                                            {'label': 'White', 'percentage': 30, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 13,
                                             'rating': 9},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6394,
                          'lat': 37.803192,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6394-Lilienthal-Claire-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6394-Lilienthal-Claire-Elementary-School/',
                              'reviews': '/california/san-francisco/6394-Lilienthal-Claire-Elementary-School/#Reviews'},
                          'lon': -122.443497,
                          'name': 'Lilienthal (Claire) Elementary School',
                          'numReviews': 36,
                          'parentRating': 4,
                          'percentLowIncome': '17%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2162 24th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '76%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 2070,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 49,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 48, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 5,
                                             'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 25,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 10, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 3,
                                             'rating': 7},
                                            {'label': 'Filipino',
                                             'percentage': 4,
                                             'rating': 9}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6395,
                          'lat': 37.747257,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6395-Lincoln-Abraham-High-School/#College_success',
                              'profile': '/california/san-francisco/6395-Lincoln-Abraham-High-School/',
                              'reviews': '/california/san-francisco/6395-Lincoln-Abraham-High-School/#Reviews'},
                          'lon': -122.481323,
                          'name': 'Lincoln (Abraham) High School',
                          'numReviews': 19,
                          'parentRating': 5,
                          'percentLowIncome': '49%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'College Readiness Rating': 8,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '755 Morse Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 520,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 67,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'Asian', 'percentage': 17, 'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 42,
                                             'rating': 1},
                                            {'label': 'Filipino',
                                             'percentage': 24,
                                             'rating': 4},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'White', 'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6396,
                          'lat': 37.710461,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6396-Longfellow-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6396-Longfellow-Elementary-School/',
                              'reviews': '/california/san-francisco/6396-Longfellow-Elementary-School/#Reviews'},
                          'lon': -122.447693,
                          'name': 'Longfellow Elementary School',
                          'numReviews': 25,
                          'parentRating': 4,
                          'percentLowIncome': '67%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 3,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1101 Eucalyptus Drive',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '89%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 2774,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 35,
                                             'rating': 10},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 52, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 2,
                                             'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 10,
                                             'rating': 10},
                                            {'label': 'White', 'percentage': 18, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 4,
                                             'rating': 10},
                                            {'label': 'Filipino',
                                             'percentage': 7,
                                             'rating': 10}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6397,
                          'lat': 37.731045,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6397-Lowell-High-School/#College_success',
                              'profile': '/california/san-francisco/6397-Lowell-High-School/',
                              'reviews': '/california/san-francisco/6397-Lowell-High-School/#Reviews'},
                          'lon': -122.483551,
                          'name': 'Lowell High School',
                          'numReviews': 28,
                          'parentRating': 4,
                          'percentLowIncome': '35%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'College Readiness Rating': 10,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '350 Harbor Road',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 108,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 89,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'African American',
                                             'percentage': 50,
                                             'rating': 3},
                                            {'label': 'Pacific Islander', 'percentage': 15},
                                            {'label': 'Two or more races', 'percentage': 11},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 3}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6398,
                          'lat': 37.733734,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6398-Malcolm-X-Academy/#College_success',
                              'profile': '/california/san-francisco/6398-Malcolm-X-Academy/',
                              'reviews': '/california/san-francisco/6398-Malcolm-X-Academy/#Reviews'},
                          'lon': -122.380608,
                          'name': 'Malcolm X Academy',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '89%',
                          'pinned': null,
                          'rating': 4,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 10,
                          'subratings': {'Equity Overview Rating': 4, 'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3351 23rd Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 589,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 64,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 81,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 9},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6399,
                          'lat': 37.753716,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6399-Buena-Vista--Horace-Mann-K-8/#College_success',
                              'profile': '/california/san-francisco/6399-Buena-Vista--Horace-Mann-K-8/',
                              'reviews': '/california/san-francisco/6399-Buena-Vista--Horace-Mann-K-8/#Reviews'},
                          'lon': -122.419739,
                          'name': 'Buena Vista/ Horace Mann K-8',
                          'numReviews': 94,
                          'parentRating': 4,
                          'percentLowIncome': '64%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3500 Fillmore Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 790,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 71,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'Asian', 'percentage': 60, 'rating': 8},
                                            {'label': 'African American',
                                             'percentage': 6,
                                             'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 16,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 9, 'rating': 8},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6400,
                          'lat': 37.80167,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6400-Marina-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6400-Marina-Middle-School/',
                              'reviews': '/california/san-francisco/6400-Marina-Middle-School/#Reviews'},
                          'lon': -122.436363,
                          'name': 'Marina Middle School',
                          'numReviews': 43,
                          'parentRating': 4,
                          'percentLowIncome': '71%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1575 15th Street',
                                      'street2': '',
                                      'zip': '94103'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 247,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 68,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 80,
                                             'rating': 2},
                                            {'label': 'White', 'percentage': 13},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6403,
                          'lat': 37.766678,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6403-Marshall-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6403-Marshall-Elementary-School/',
                              'reviews': '/california/san-francisco/6403-Marshall-Elementary-School/#Reviews'},
                          'lon': -122.41909,
                          'name': 'Marshall Elementary School',
                          'numReviews': 27,
                          'parentRating': 5,
                          'percentLowIncome': '68%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '45 Conkling Street',
                                      'street2': '',
                                      'zip': '94124'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '56%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 452,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 62,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'Asian', 'percentage': 20, 'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 54,
                                             'rating': 1},
                                            {'label': 'African American', 'percentage': 10},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 2},
                                            {'label': 'Two or more races', 'percentage': 1},
                                            {'label': 'White', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6404,
                          'lat': 37.735718,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6404-Marshall-Thurgood-High-School/#College_success',
                              'profile': '/california/san-francisco/6404-Marshall-Thurgood-High-School/',
                              'reviews': '/california/san-francisco/6404-Marshall-Thurgood-High-School/#Reviews'},
                          'lon': -122.401878,
                          'name': 'Marshall (Thurgood) High School',
                          'numReviews': 11,
                          'parentRating': 3,
                          'percentLowIncome': '62%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 10,
                          'subratings': {'College Readiness Rating': 1,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '651 Sixth Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 220,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 53,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 49, 'rating': 9},
                                            {'label': 'White', 'percentage': 17},
                                            {'label': 'Hispanic', 'percentage': 13},
                                            {'label': 'Two or more races', 'percentage': 5},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6406,
                          'lat': 37.776283,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6406-Mccoppin-Frank-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6406-Mccoppin-Frank-Elementary-School/',
                              'reviews': '/california/san-francisco/6406-Mccoppin-Frank-Elementary-School/#Reviews'},
                          'lon': -122.464012,
                          'name': 'Mccoppin (Frank) Elementary School',
                          'numReviews': 26,
                          'parentRating': 4,
                          'percentLowIncome': '53%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 8,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1025 14th Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 383,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 21,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 16,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 50, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 10},
                                            {'label': 'African American', 'percentage': 8},
                                            {'label': 'Asian', 'percentage': 7},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6407,
                          'lat': 37.767227,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6407-McKinley-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6407-McKinley-Elementary-School/',
                              'reviews': '/california/san-francisco/6407-McKinley-Elementary-School/#Reviews'},
                          'lon': -122.436592,
                          'name': 'McKinley Elementary School',
                          'numReviews': 58,
                          'parentRating': 4,
                          'percentLowIncome': '21%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '4235 19th Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 221,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 31,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'White', 'percentage': 43, 'rating': 9},
                                            {'label': 'Hispanic', 'percentage': 17},
                                            {'label': 'African American', 'percentage': 13},
                                            {'label': 'Two or more races', 'percentage': 10},
                                            {'label': 'Asian', 'percentage': 5},
                                            {'label': 'Pacific Islander', 'percentage': 2}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6408,
                          'lat': 37.759148,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6408-Milk-Harvey-Civil-Rights-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6408-Milk-Harvey-Civil-Rights-Elementary-School/',
                              'reviews': '/california/san-francisco/6408-Milk-Harvey-Civil-Rights-Elementary-School/#Reviews'},
                          'lon': -122.43634,
                          'name': 'Milk (Harvey) Civil Rights Elementary School',
                          'numReviews': 83,
                          'parentRating': 4,
                          'percentLowIncome': '31%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '175 Omar Way',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 391,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 15},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 14, 'rating': 10},
                                            {'label': 'Hispanic',
                                             'percentage': 16,
                                             'rating': 6},
                                            {'label': 'White', 'percentage': 46, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6409,
                          'lat': 37.739307,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6409-Miraloma-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6409-Miraloma-Elementary-School/',
                              'reviews': '/california/san-francisco/6409-Miraloma-Elementary-School/#Reviews'},
                          'lon': -122.450211,
                          'name': 'Miraloma Elementary School',
                          'numReviews': 69,
                          'parentRating': 5,
                          'percentLowIncome': '15%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1670 Noe Street',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 105,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 63},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 78,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 7},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 3},
                                            {'label': 'Asian', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6410,
                          'lat': 37.742283,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6410-Mission-Education-Center/#College_success',
                              'profile': '/california/san-francisco/6410-Mission-Education-Center/',
                              'reviews': '/california/san-francisco/6410-Mission-Education-Center/#Reviews'},
                          'lon': -122.431061,
                          'name': 'Mission Education Center',
                          'numReviews': 0,
                          'parentRating': 0,
                          'percentLowIncome': '63%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '3750 18th Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '59%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1099,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 59,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'Asian', 'percentage': 9, 'rating': 5},
                                            {'label': 'African American',
                                             'percentage': 15,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 52,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 11, 'rating': 5},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6411,
                          'lat': 37.761402,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6411-Mission-High-School/#College_success',
                              'profile': '/california/san-francisco/6411-Mission-High-School/',
                              'reviews': '/california/san-francisco/6411-Mission-High-School/#Reviews'},
                          'lon': -122.427277,
                          'name': 'Mission High School',
                          'numReviews': 40,
                          'parentRating': 4,
                          'percentLowIncome': '59%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 13,
                          'subratings': {'College Readiness Rating': 2,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '260 Madrid Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 528,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 73,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 35, 'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 48,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6412,
                          'lat': 37.725224,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6412-Monroe-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6412-Monroe-Elementary-School/',
                              'reviews': '/california/san-francisco/6412-Monroe-Elementary-School/#Reviews'},
                          'lon': -122.430122,
                          'name': 'Monroe Elementary School',
                          'numReviews': 21,
                          'parentRating': 4,
                          'percentLowIncome': '73%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2576 Harrison Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 381,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 80,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 35, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 55,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6413,
                          'lat': 37.756241,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6413-Moscone-George-R.-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6413-Moscone-George-R.-Elementary-School/',
                              'reviews': '/california/san-francisco/6413-Moscone-George-R.-Elementary-School/#Reviews'},
                          'lon': -122.4123,
                          'name': 'Moscone (George R.) Elementary School',
                          'numReviews': 8,
                          'parentRating': 3,
                          'percentLowIncome': '80%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '380 Webster Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 225,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 84,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'African American', 'rating': 9},
                                            {'label': 'Hispanic', 'rating': 6}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6414,
                          'lat': 37.773689,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6414-Muir-John-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6414-Muir-John-Elementary-School/',
                              'reviews': '/california/san-francisco/6414-Muir-John-Elementary-School/#Reviews'},
                          'lon': -122.429092,
                          'name': 'Muir (John) Elementary School',
                          'numReviews': 19,
                          'parentRating': 4,
                          'percentLowIncome': '84%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Equity Overview Rating': 7, 'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2049 Grove Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 246,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 22},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'White', 'rating': 9}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6415,
                          'lat': 37.774311,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6415-New-Traditions-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6415-New-Traditions-Elementary-School/',
                              'reviews': '/california/san-francisco/6415-New-Traditions-Elementary-School/#Reviews'},
                          'lon': -122.450317,
                          'name': 'New Traditions Elementary School',
                          'numReviews': 37,
                          'parentRating': 5,
                          'percentLowIncome': '22%',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2355 Folsom Street',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '46%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 480,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 67,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'African American',
                                             'percentage': 15,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 52,
                                             'rating': 2},
                                            {'label': 'Asian', 'percentage': 10},
                                            {'label': 'Filipino', 'percentage': 8},
                                            {'label': 'White', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6417,
                          'lat': 37.759632,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6417-Oconnell-John-High-School/#College_success',
                              'profile': '/california/san-francisco/6417-Oconnell-John-High-School/',
                              'reviews': '/california/san-francisco/6417-Oconnell-John-High-School/#Reviews'},
                          'lon': -122.41468,
                          'name': "O'connell (John) High School",
                          'numReviews': 10,
                          'parentRating': 4,
                          'percentLowIncome': '67%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 9,
                          'subratings': {'College Readiness Rating': 2,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '400 Sargent Street',
                                      'street2': '',
                                      'zip': '94132'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 399,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 49,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 46, 'rating': 9},
                                            {'label': 'White', 'percentage': 12, 'rating': 9},
                                            {'label': 'Hispanic', 'percentage': 12},
                                            {'label': 'Two or more races', 'percentage': 12},
                                            {'label': 'Filipino', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 6}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6418,
                          'lat': 37.716167,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6418-Ortega-Jose-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6418-Ortega-Jose-Elementary-School/',
                              'reviews': '/california/san-francisco/6418-Ortega-Jose-Elementary-School/#Reviews'},
                          'lon': -122.466248,
                          'name': 'Ortega (Jose) Elementary School',
                          'numReviews': 77,
                          'parentRating': 4,
                          'percentLowIncome': '49%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 7,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '840 Broadway Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 227,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 89,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 73, 'rating': 7},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6419,
                          'lat': 37.797321,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6419-Parker-Jean-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6419-Parker-Jean-Elementary-School/',
                              'reviews': '/california/san-francisco/6419-Parker-Jean-Elementary-School/#Reviews'},
                          'lon': -122.411102,
                          'name': 'Parker (Jean) Elementary School',
                          'numReviews': 10,
                          'parentRating': 4,
                          'percentLowIncome': '89%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 6,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': "1501 O'farrell Street",
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 476,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 50,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 5},
                                            {'label': 'Asian', 'percentage': 17, 'rating': 8},
                                            {'label': 'African American',
                                             'percentage': 17,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 18,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 15, 'rating': 8},
                                            {'label': 'Two or more races',
                                             'percentage': 17,
                                             'rating': 6},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6420,
                          'lat': 37.78368,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6420-Parks-Rosa-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6420-Parks-Rosa-Elementary-School/',
                              'reviews': '/california/san-francisco/6420-Parks-Rosa-Elementary-School/#Reviews'},
                          'lon': -122.429451,
                          'name': 'Parks (Rosa) Elementary School',
                          'numReviews': 165,
                          'parentRating': 5,
                          'percentLowIncome': '50%',
                          'pinned': null,
                          'rating': 4,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 5},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '251 Sixth Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 271,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 21,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'White', 'percentage': 44, 'rating': 10},
                                            {'label': 'Asian', 'percentage': 17},
                                            {'label': 'Two or more races', 'percentage': 16},
                                            {'label': 'Hispanic', 'percentage': 13},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6421,
                          'lat': 37.783947,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6421-Peabody-George-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6421-Peabody-George-Elementary-School/',
                              'reviews': '/california/san-francisco/6421-Peabody-George-Elementary-School/#Reviews'},
                          'lon': -122.464561,
                          'name': 'Peabody (George) Elementary School',
                          'numReviews': 42,
                          'parentRating': 5,
                          'percentLowIncome': '21%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '450 30th Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1060,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 37,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 46, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 4,
                                             'rating': 5},
                                            {'label': 'Hispanic',
                                             'percentage': 13,
                                             'rating': 7},
                                            {'label': 'White', 'percentage': 25, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 7,
                                             'rating': 9},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6423,
                          'lat': 37.78083,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6423-Presidio-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6423-Presidio-Middle-School/',
                              'reviews': '/california/san-francisco/6423-Presidio-Middle-School/#Reviews'},
                          'lon': -122.490097,
                          'name': 'Presidio Middle School',
                          'numReviews': 62,
                          'parentRating': 3,
                          'percentLowIncome': '37%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 7,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1421 Pine Street',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 240,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 70,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 23, 'rating': 8},
                                            {'label': 'Hispanic',
                                             'percentage': 22,
                                             'rating': 7},
                                            {'label': 'White', 'percentage': 22, 'rating': 5},
                                            {'label': 'Two or more races', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 7},
                                            {'label': 'African American', 'percentage': 7},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6424,
                          'lat': 37.789726,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6424-Redding-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6424-Redding-Elementary-School/',
                              'reviews': '/california/san-francisco/6424-Redding-Elementary-School/#Reviews'},
                          'lon': -122.419212,
                          'name': 'Redding Elementary School',
                          'numReviews': 52,
                          'parentRating': 5,
                          'percentLowIncome': '70%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Equity Overview Rating': 7, 'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '555 Tompkins Avenue',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 477,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 83,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 71,
                                             'rating': 1},
                                            {'label': 'African American', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Asian', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6425,
                          'lat': 37.737141,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6425-Revere-Paul-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6425-Revere-Paul-Elementary-School/',
                              'reviews': '/california/san-francisco/6425-Revere-Paul-Elementary-School/#Reviews'},
                          'lon': -122.41317,
                          'name': 'Revere (Paul) Elementary School',
                          'numReviews': 29,
                          'parentRating': 4,
                          'percentLowIncome': '83%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 14,
                          'subratings': {'Academic Progress Rating': 3,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '443 Burnett Avenue',
                                      'street2': '',
                                      'zip': '94131'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 568,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 30,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 9, 'rating': 9},
                                            {'label': 'African American',
                                             'percentage': 11,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 21,
                                             'rating': 5},
                                            {'label': 'White', 'percentage': 37, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 11},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6426,
                          'lat': 37.754417,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6426-Rooftop-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6426-Rooftop-Elementary-School/',
                              'reviews': '/california/san-francisco/6426-Rooftop-Elementary-School/#Reviews'},
                          'lon': -122.443878,
                          'name': 'Rooftop Elementary School',
                          'numReviews': 51,
                          'parentRating': 5,
                          'percentLowIncome': '30%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '460 Arguello Boulevard',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 694,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 43,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 49, 'rating': 10},
                                            {'label': 'African American',
                                             'percentage': 4,
                                             'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 12,
                                             'rating': 8},
                                            {'label': 'White', 'percentage': 23, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 7,
                                             'rating': 10},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6427,
                          'lat': 37.782249,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6427-Roosevelt-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6427-Roosevelt-Middle-School/',
                              'reviews': '/california/san-francisco/6427-Roosevelt-Middle-School/#Reviews'},
                          'lon': -122.458946,
                          'name': 'Roosevelt Middle School',
                          'numReviews': 19,
                          'parentRating': 5,
                          'percentLowIncome': '43%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '325 Sanchez Street',
                                      'street2': '',
                                      'zip': '94114'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 271,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 78,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 80,
                                             'rating': 1},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Asian', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'Two or more races', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6428,
                          'lat': 37.763962,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6428-Sanchez-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6428-Sanchez-Elementary-School/',
                              'reviews': '/california/san-francisco/6428-Sanchez-Elementary-School/#Reviews'},
                          'lon': -122.430801,
                          'name': 'Sanchez Elementary School',
                          'numReviews': 19,
                          'parentRating': 4,
                          'percentLowIncome': '78%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 12,
                          'subratings': {'Academic Progress Rating': 3,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '625 Holly Park Circle',
                                      'street2': '',
                                      'zip': '94110'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 286,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 76,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 3},
                                            {'label': 'Hispanic',
                                             'percentage': 67,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 8},
                                            {'label': 'Asian', 'percentage': 6},
                                            {'label': 'African American', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6429,
                          'lat': 37.736732,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6429-Serra-Junipero-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6429-Serra-Junipero-Elementary-School/',
                              'reviews': '/california/san-francisco/6429-Serra-Junipero-Elementary-School/#Reviews'},
                          'lon': -122.421127,
                          'name': 'Serra (Junipero) Elementary School',
                          'numReviews': 31,
                          'parentRating': 5,
                          'percentLowIncome': '76%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'Academic Progress Rating': 5,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 3},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '125 Excelsior Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 280,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 57,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 4},
                                            {'label': 'Hispanic',
                                             'percentage': 40,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 19, 'rating': 9},
                                            {'label': 'African American', 'percentage': 13},
                                            {'label': 'Filipino', 'percentage': 9},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'Asian', 'percentage': 6},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6430,
                          'lat': 37.725815,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6430-San-Francisco-Community-Alternative-School/#College_success',
                              'profile': '/california/san-francisco/6430-San-Francisco-Community-Alternative-School/',
                              'reviews': '/california/san-francisco/6430-San-Francisco-Community-Alternative-School/#Reviews'},
                          'lon': -122.432617,
                          'name': 'San Francisco Community Alternative School',
                          'numReviews': 92,
                          'parentRating': 4,
                          'percentLowIncome': '57%',
                          'pinned': null,
                          'rating': 3,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 4},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '431 Capitol Avenue',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 229,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 67,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'Hispanic',
                                             'percentage': 26,
                                             'rating': 1},
                                            {'label': 'Asian', 'percentage': 20},
                                            {'label': 'African American', 'percentage': 15},
                                            {'label': 'Filipino', 'percentage': 14},
                                            {'label': 'Two or more races', 'percentage': 13},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6431,
                          'lat': 37.714333,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6431-Sheridan-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6431-Sheridan-Elementary-School/',
                              'reviews': '/california/san-francisco/6431-Sheridan-Elementary-School/#Reviews'},
                          'lon': -122.459129,
                          'name': 'Sheridan Elementary School',
                          'numReviews': 28,
                          'parentRating': 4,
                          'percentLowIncome': '67%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'Academic Progress Rating': 3,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1651 Union Street',
                                      'street2': '',
                                      'zip': '94123'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 387,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 34,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'rating': 9},
                                            {'label': 'African American', 'rating': 5},
                                            {'label': 'Hispanic', 'rating': 5},
                                            {'label': 'White', 'rating': 9}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6432,
                          'lat': 37.798271,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6432-Sherman-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6432-Sherman-Elementary-School/',
                              'reviews': '/california/san-francisco/6432-Sherman-Elementary-School/#Reviews'},
                          'lon': -122.426201,
                          'name': 'Sherman Elementary School',
                          'numReviews': 107,
                          'parentRating': 4,
                          'percentLowIncome': '34%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '50 Darien Way',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 390,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 33,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 37, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 12,
                                             'rating': 7},
                                            {'label': 'White', 'percentage': 31, 'rating': 9},
                                            {'label': 'Two or more races', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6433,
                          'lat': 37.73188,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6433-Sloat-Commodore-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6433-Sloat-Commodore-Elementary-School/',
                              'reviews': '/california/san-francisco/6433-Sloat-Commodore-Elementary-School/#Reviews'},
                          'lon': -122.470963,
                          'name': 'Sloat (Commodore) Elementary School',
                          'numReviews': 80,
                          'parentRating': 4,
                          'percentLowIncome': '33%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 21,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 8,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1451 Jackson Street',
                                      'street2': '',
                                      'zip': '94109'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 327,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 79,
                                             'rating': 6},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Asian', 'percentage': 39, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 41,
                                             'rating': 3},
                                            {'label': 'Two or more races', 'percentage': 6},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 1},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6434,
                          'lat': 37.794403,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6434-Spring-Valley-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6434-Spring-Valley-Elementary-School/',
                              'reviews': '/california/san-francisco/6434-Spring-Valley-Elementary-School/#Reviews'},
                          'lon': -122.418991,
                          'name': 'Spring Valley Elementary School',
                          'numReviews': 9,
                          'parentRating': 5,
                          'percentLowIncome': '79%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1215 Carolina Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 331,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 44,
                                             'rating': 4},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 28, 'rating': 9},
                                            {'label': 'African American',
                                             'percentage': 13,
                                             'rating': 1},
                                            {'label': 'Two or more races',
                                             'percentage': 18,
                                             'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 13},
                                            {'label': 'White', 'percentage': 12},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 3}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6435,
                          'lat': 37.752766,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6435-King-Starr-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6435-King-Starr-Elementary-School/',
                              'reviews': '/california/san-francisco/6435-King-Starr-Elementary-School/#Reviews'},
                          'lon': -122.399353,
                          'name': 'King (Starr) Elementary School',
                          'numReviews': 89,
                          'parentRating': 4,
                          'percentLowIncome': '44%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2051 34th Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 489,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 48,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 74, 'rating': 9},
                                            {'label': 'Hispanic', 'percentage': 5},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'White', 'percentage': 3},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6436,
                          'lat': 37.748856,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6436-Stevenson-Robert-Louis-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6436-Stevenson-Robert-Louis-Elementary-School/',
                              'reviews': '/california/san-francisco/6436-Stevenson-Robert-Louis-Elementary-School/#Reviews'},
                          'lon': -122.492302,
                          'name': 'Stevenson (Robert Louis) Elementary School',
                          'numReviews': 38,
                          'parentRating': 4,
                          'percentLowIncome': '48%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '250 Foerster Street',
                                      'street2': '',
                                      'zip': '94112'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 383,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 25,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 18,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 46, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 15,
                                             'rating': 10},
                                            {'label': 'Asian', 'percentage': 7},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6437,
                          'lat': 37.730309,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6437-Sunnyside-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6437-Sunnyside-Elementary-School/',
                              'reviews': '/california/san-francisco/6437-Sunnyside-Elementary-School/#Reviews'},
                          'lon': -122.448792,
                          'name': 'Sunnyside Elementary School',
                          'numReviews': 86,
                          'parentRating': 5,
                          'percentLowIncome': '25%',
                          'pinned': null,
                          'rating': 7,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 18,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 3,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1920 41st Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 407,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 24,
                                             'rating': 10},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 48, 'rating': 10},
                                            {'label': 'White', 'percentage': 22, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 13},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'Hispanic', 'percentage': 5}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6438,
                          'lat': 37.750744,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6438-Sunset-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6438-Sunset-Elementary-School/',
                              'reviews': '/california/san-francisco/6438-Sunset-Elementary-School/#Reviews'},
                          'lon': -122.499786,
                          'name': 'Sunset Elementary School',
                          'numReviews': 31,
                          'parentRating': 5,
                          'percentLowIncome': '24%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '235 12th Avenue',
                                      'street2': '',
                                      'zip': '94118'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 262,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 64,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 70, 'rating': 8},
                                            {'label': 'Two or more races', 'percentage': 7},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'White', 'percentage': 6},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6439,
                          'lat': 37.783932,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6439-Sutro-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6439-Sutro-Elementary-School/',
                              'reviews': '/california/san-francisco/6439-Sutro-Elementary-School/#Reviews'},
                          'lon': -122.471024,
                          'name': 'Sutro Elementary School',
                          'numReviews': 23,
                          'parentRating': 4,
                          'percentLowIncome': '64%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 16,
                          'subratings': {'Academic Progress Rating': 8,
                                         'Equity Overview Rating': 8,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '423 Burrows Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 646,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 82,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 7},
                                            {'label': 'Asian', 'percentage': 55, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 29,
                                             'rating': 3},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'African American', 'percentage': 4},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6441,
                          'lat': 37.728001,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6441-Taylor-Edward-R.-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6441-Taylor-Edward-R.-Elementary-School/',
                              'reviews': '/california/san-francisco/6441-Taylor-Edward-R.-Elementary-School/#Reviews'},
                          'lon': -122.407333,
                          'name': 'Taylor (Edward R.) Elementary School',
                          'numReviews': 11,
                          'parentRating': 3,
                          'percentLowIncome': '82%',
                          'pinned': null,
                          'rating': 6,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 4,
                                         'Test Scores Rating': 7},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '627 Turk Street',
                                      'street2': '',
                                      'zip': '94102'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 314,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 86,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'Asian', 'percentage': 17, 'rating': 9},
                                            {'label': 'Hispanic',
                                             'percentage': 40,
                                             'rating': 1},
                                            {'label': 'White', 'percentage': 13},
                                            {'label': 'African American', 'percentage': 11},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'Two or more races', 'percentage': 3}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6442,
                          'lat': 37.782063,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6442-Tenderloin-Community/#College_success',
                              'profile': '/california/san-francisco/6442-Tenderloin-Community/',
                              'reviews': '/california/san-francisco/6442-Tenderloin-Community/#Reviews'},
                          'lon': -122.419792,
                          'name': 'Tenderloin Community',
                          'numReviews': 22,
                          'parentRating': 4,
                          'percentLowIncome': '86%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 15,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2650 42nd Avenue',
                                      'street2': '',
                                      'zip': '94116'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 528,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 55,
                                             'rating': 9},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 78, 'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 6},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6445,
                          'lat': 37.737301,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6445-Ulloa-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6445-Ulloa-Elementary-School/',
                              'reviews': '/california/san-francisco/6445-Ulloa-Elementary-School/#Reviews'},
                          'lon': -122.499947,
                          'name': 'Ulloa Elementary School',
                          'numReviews': 41,
                          'parentRating': 3,
                          'percentLowIncome': '55%',
                          'pinned': null,
                          'rating': 10,
                          'ratingScale': 'Top rated',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 10,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '55 Schwerin Street',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 338,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 82,
                                             'rating': 5},
                                            {'label': 'All students', 'rating': 5},
                                            {'label': 'Asian', 'percentage': 41, 'rating': 8},
                                            {'label': 'Hispanic', 'percentage': 19},
                                            {'label': 'African American', 'percentage': 12},
                                            {'label': 'Pacific Islander', 'percentage': 8},
                                            {'label': 'Filipino', 'percentage': 7},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'White', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6446,
                          'lat': 37.712681,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6446-Visitacion-Valley-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6446-Visitacion-Valley-Elementary-School/',
                              'reviews': '/california/san-francisco/6446-Visitacion-Valley-Elementary-School/#Reviews'},
                          'lon': -122.410362,
                          'name': 'Visitacion Valley Elementary School',
                          'numReviews': 3,
                          'parentRating': 4,
                          'percentLowIncome': '82%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 5},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '450 Raymond Avenue',
                                      'street2': '',
                                      'zip': '94134'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 375,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 73,
                                             'rating': 2},
                                            {'label': 'All students', 'rating': 2},
                                            {'label': 'Asian', 'percentage': 20, 'rating': 7},
                                            {'label': 'African American',
                                             'percentage': 10,
                                             'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 51,
                                             'rating': 1},
                                            {'label': 'Filipino',
                                             'percentage': 8,
                                             'rating': 7},
                                            {'label': 'Two or more races', 'percentage': 3},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'White', 'percentage': 2}],
                          'gradeLevels': '6-8',
                          'highlighted': false,
                          'id': 6447,
                          'lat': 37.714771,
                          'levelCode': 'm',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6447-Visitacion-Valley-Middle-School/#College_success',
                              'profile': '/california/san-francisco/6447-Visitacion-Valley-Middle-School/',
                              'reviews': '/california/san-francisco/6447-Visitacion-Valley-Middle-School/#Reviews'},
                          'lon': -122.412491,
                          'name': 'Visitacion Valley Middle School',
                          'numReviews': 4,
                          'parentRating': 4,
                          'percentLowIncome': '73%',
                          'pinned': null,
                          'rating': 2,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 11,
                          'subratings': {'Academic Progress Rating': 4,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 2},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '5 Lenox Way',
                                      'street2': '',
                                      'zip': '94127'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 590,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 31,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 55, 'rating': 8},
                                            {'label': 'White', 'percentage': 18, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 10,
                                             'rating': 10},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 2},
                                            {'label': 'African American', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6448,
                          'lat': 37.743572,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6448-West-Portal-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6448-West-Portal-Elementary-School/',
                              'reviews': '/california/san-francisco/6448-West-Portal-Elementary-School/#Reviews'},
                          'lon': -122.464684,
                          'name': 'West Portal Elementary School',
                          'numReviews': 60,
                          'parentRating': 5,
                          'percentLowIncome': '31%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 20,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 8,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '40 Vega Street',
                                      'street2': '',
                                      'zip': '94115'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '83%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 626,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 52,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 48, 'rating': 10},
                                            {'label': 'Hispanic',
                                             'percentage': 15,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 13, 'rating': 8},
                                            {'label': 'African American', 'percentage': 9},
                                            {'label': 'Filipino', 'percentage': 4},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6449,
                          'lat': 37.78017,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6449-Wallenberg-Raoul-Traditional-High-School/#College_success',
                              'profile': '/california/san-francisco/6449-Wallenberg-Raoul-Traditional-High-School/',
                              'reviews': '/california/san-francisco/6449-Wallenberg-Raoul-Traditional-High-School/#Reviews'},
                          'lon': -122.445747,
                          'name': 'Wallenberg (Raoul) Traditional High School',
                          'numReviews': 65,
                          'parentRating': 3,
                          'percentLowIncome': '52%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 13,
                          'subratings': {'College Readiness Rating': 9,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '600 32nd Avenue',
                                      'street2': '',
                                      'zip': '94121'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '79%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 1995,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 49,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 8},
                                            {'label': 'Asian', 'percentage': 56, 'rating': 9},
                                            {'label': 'African American',
                                             'percentage': 4,
                                             'rating': 5},
                                            {'label': 'Hispanic',
                                             'percentage': 18,
                                             'rating': 3},
                                            {'label': 'White', 'percentage': 10, 'rating': 9},
                                            {'label': 'Two or more races',
                                             'percentage': 2,
                                             'rating': 9},
                                            {'label': 'Filipino',
                                             'percentage': 5,
                                             'rating': 6}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6450,
                          'lat': 37.777782,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6450-Washington-George-High-School/#College_success',
                              'profile': '/california/san-francisco/6450-Washington-George-High-School/',
                              'reviews': '/california/san-francisco/6450-Washington-George-High-School/#Reviews'},
                          'lon': -122.492027,
                          'name': 'Washington (George) High School',
                          'numReviews': 15,
                          'parentRating': 5,
                          'percentLowIncome': '49%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'College Readiness Rating': 8,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 8},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '465 Missouri Street',
                                      'street2': '',
                                      'zip': '94107'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 326,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 44,
                                             'rating': 3},
                                            {'label': 'All students', 'rating': 6},
                                            {'label': 'Hispanic',
                                             'percentage': 44,
                                             'rating': 4},
                                            {'label': 'White', 'percentage': 30, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 8},
                                            {'label': 'African American', 'percentage': 6},
                                            {'label': 'Asian', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 1},
                                            {'label': 'Filipino', 'percentage': 1}],
                          'gradeLevels': 'K-5',
                          'highlighted': false,
                          'id': 6451,
                          'lat': 37.76049,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6451-Daniel-Webster-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6451-Daniel-Webster-Elementary-School/',
                              'reviews': '/california/san-francisco/6451-Daniel-Webster-Elementary-School/#Reviews'},
                          'lon': -122.396233,
                          'name': 'Daniel Webster Elementary School',
                          'numReviews': 109,
                          'parentRating': 5,
                          'percentLowIncome': '44%',
                          'pinned': null,
                          'rating': 5,
                          'ratingScale': 'Average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 13,
                          'subratings': {'Academic Progress Rating': 6,
                                         'Equity Overview Rating': 2,
                                         'Test Scores Rating': 6},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1099 Hayes Street',
                                      'street2': '',
                                      'zip': '94117'},
                          'assigned': null,
                          'collegeEnrollmentData': {'school_value': '46%',
                                                    'state_average': '64%'},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 165,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 65,
                                             'rating': 1},
                                            {'label': 'All students', 'rating': 1},
                                            {'label': 'Hispanic',
                                             'percentage': 45,
                                             'rating': 1},
                                            {'label': 'African American', 'percentage': 21},
                                            {'label': 'Asian', 'percentage': 13},
                                            {'label': 'Filipino', 'percentage': 5},
                                            {'label': 'Two or more races', 'percentage': 4},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'Pacific Islander', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': '9-12',
                          'highlighted': false,
                          'id': 6452,
                          'lat': 37.775391,
                          'levelCode': 'h',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6452-Wells-Ida-B.-High-School/#College_success',
                              'profile': '/california/san-francisco/6452-Wells-Ida-B.-High-School/',
                              'reviews': '/california/san-francisco/6452-Wells-Ida-B.-High-School/#Reviews'},
                          'lon': -122.434464,
                          'name': 'Wells (Ida B.) High School',
                          'numReviews': 1,
                          'parentRating': 4,
                          'percentLowIncome': '65%',
                          'pinned': null,
                          'rating': 1,
                          'ratingScale': 'Below average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 9,
                          'subratings': {'College Readiness Rating': 2,
                                         'Equity Overview Rating': 1,
                                         'Test Scores Rating': 1},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '2245 Jones Street',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 239,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 37,
                                             'rating': 7},
                                            {'label': 'All students', 'rating': 10},
                                            {'label': 'Asian', 'percentage': 30, 'rating': 10},
                                            {'label': 'White', 'percentage': 36, 'rating': 10},
                                            {'label': 'Two or more races', 'percentage': 10},
                                            {'label': 'Hispanic', 'percentage': 9},
                                            {'label': 'African American', 'percentage': 2},
                                            {'label': 'Filipino', 'percentage': 2}],
                          'gradeLevels': '1-5',
                          'highlighted': false,
                          'id': 6454,
                          'lat': 37.802227,
                          'levelCode': 'e',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6454-Yick-Wo-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6454-Yick-Wo-Elementary-School/',
                              'reviews': '/california/san-francisco/6454-Yick-Wo-Elementary-School/#Reviews'},
                          'lon': -122.416428,
                          'name': 'Yick Wo Elementary School',
                          'numReviews': 59,
                          'parentRating': 5,
                          'percentLowIncome': '37%',
                          'pinned': null,
                          'rating': 8,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 17,
                          'subratings': {'Academic Progress Rating': 9,
                                         'Equity Overview Rating': 5,
                                         'Test Scores Rating': 10},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '1541 12th Avenue',
                                      'street2': '',
                                      'zip': '94122'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': 'San Francisco',
                          'districtId': 717,
                          'districtName': 'San Francisco Unified School District',
                          'enrollment': 590,
                          'ethnicityInfo': [{'label': 'Low-income',
                                             'percentage': 34,
                                             'rating': 8},
                                            {'label': 'All students', 'rating': 9},
                                            {'label': 'Asian', 'percentage': 61, 'rating': 10},
                                            {'label': 'Two or more races',
                                             'percentage': 10,
                                             'rating': 8},
                                            {'label': 'African American', 'percentage': 7},
                                            {'label': 'Hispanic', 'percentage': 7},
                                            {'label': 'White', 'percentage': 4},
                                            {'label': 'Filipino', 'percentage': 3},
                                            {'label': 'American Indian/Alaska Native',
                                             'percentage': 1}],
                          'gradeLevels': 'K-8',
                          'highlighted': false,
                          'id': 6455,
                          'lat': 37.759403,
                          'levelCode': 'e,m',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/6455-Yu-Alice-Fong-Elementary-School/#College_success',
                              'profile': '/california/san-francisco/6455-Yu-Alice-Fong-Elementary-School/',
                              'reviews': '/california/san-francisco/6455-Yu-Alice-Fong-Elementary-School/#Reviews'},
                          'lon': -122.469307,
                          'name': 'Yu (Alice Fong) Elementary School',
                          'numReviews': 30,
                          'parentRating': 4,
                          'percentLowIncome': '34%',
                          'pinned': null,
                          'rating': 9,
                          'ratingScale': 'Above average',
                          'remediationData': [],
                          'schoolType': 'public',
                          'state': 'CA',
                          'studentsPerTeacher': 19,
                          'subratings': {'Academic Progress Rating': 7,
                                         'Equity Overview Rating': 9,
                                         'Test Scores Rating': 9},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'},
                         {'address': {'city': 'San Francisco',
                                      'street1': '660 Lombard St.',
                                      'street2': '',
                                      'zip': '94133'},
                          'assigned': null,
                          'collegeEnrollmentData': {},
                          'collegePersistentData': [],
                          'csaAwardYears': [],
                          'districtCity': null,
                          'districtId': 0,
                          'districtName': null,
                          'enrollment': 0,
                          'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                          'gradeLevels': 'PK',
                          'highlighted': false,
                          'id': 24069,
                          'lat': 37.802948,
                          'levelCode': 'p',
                          'links': {
                              'collegeSuccess': '/california/san-francisco/24069-Telegraph-Hill-Child-Development-Preschool-Program/#College_success',
                              'profile': '/california/san-francisco/24069-Telegraph-Hill-Child-Development-Preschool-Program/',
                              'reviews': '/california/san-francisco/24069-Telegraph-Hill-Child-Development-Preschool-Program/#Reviews'},
                          'lon': -122.412384,
                          'name': 'Telegraph Hill Child Development Preschool Program',
                          'numReviews': 1,
                          'parentRating': 5,
                          'percentLowIncome': '?',
                          'pinned': null,
                          'rating': null,
                          'ratingScale': null,
                          'remediationData': [],
                          'schoolType': 'private',
                          'state': 'CA',
                          'subratings': {},
                          'testScoreRatingForEthnicity': null,
                          'type': 'school'}]
