const san_jose = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Santa Clara County, CA",
             "short_name": "Santa Clara County",
             "sumlevel": "050",
             "land_area": 3343890934,
             "full_geoid": "05000US06085",
             "total_population": 1927852
          },
          "CBSA": {
             "full_name": "San Jose-Sunnyvale-Santa Clara, CA Metro Area",
             "short_name": "San Jose-Sunnyvale-Santa Clara, CA",
             "sumlevel": "310",
             "land_area": 6940482016,
             "full_geoid": "31000US41940",
             "total_population": 1990660
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39512223
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "San Jose, CA",
          "short_name": "San Jose",
          "sumlevel": "160",
          "land_area": 460521077,
          "full_geoid": "16000US0668000",
          "total_population": 1021786,
          "sumlevel_name": "Place",
          "short_geoid": "0668000"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 21.97,
                   "CBSA": 21.67,
                   "state": 22.5
                },
                "error": {
                   "this": 0.64,
                   "CBSA": 0.3,
                   "state": 0.08
                },
                "numerators": {
                   "this": 224486,
                   "CBSA": 431307,
                   "state": 8891064
                },
                "numerator_errors": {
                   "this": 6540.8,
                   "CBSA": 5887.9,
                   "state": 32008.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 98
                },
                "error_ratio": {
                   "this": 2.9,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 64.48,
                   "CBSA": 64.48,
                   "state": 62.73
                },
                "error": {
                   "this": 1.09,
                   "CBSA": 0.51,
                   "state": 0.13
                },
                "numerators": {
                   "this": 658803,
                   "CBSA": 1283572,
                   "state": 2.478616E7
                },
                "numerator_errors": {
                   "this": 11163.7,
                   "CBSA": 10170.5,
                   "state": 51870.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 103
                },
                "error_ratio": {
                   "this": 1.7,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 13.55,
                   "CBSA": 13.85,
                   "state": 14.77
                },
                "error": {
                   "this": 0.52,
                   "CBSA": 0.33,
                   "state": 0.08
                },
                "numerators": {
                   "this": 138497,
                   "CBSA": 275781,
                   "state": 5834998
                },
                "numerator_errors": {
                   "this": 5319.5,
                   "CBSA": 6572.8,
                   "state": 32031.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 92
                },
                "error_ratio": {
                   "this": 3.8,
                   "CBSA": 2.4,
                   "state": 0.5
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.24,
                      "CBSA": 11.4,
                      "state": 12.04
                   },
                   "error": {
                      "this": 0.46,
                      "CBSA": 0.21,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 114837,
                      "CBSA": 226908,
                      "state": 4758601
                   },
                   "numerator_errors": {
                      "this": 4731.1,
                      "CBSA": 4128.5,
                      "state": 23052.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 93
                   },
                   "error_ratio": {
                      "this": 4.1,
                      "CBSA": 1.8,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 12.93,
                      "CBSA": 12.62,
                      "state": 13.1
                   },
                   "error": {
                      "this": 0.48,
                      "CBSA": 0.22,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 132125,
                      "CBSA": 251248,
                      "state": 5176959
                   },
                   "numerator_errors": {
                      "this": 4889.5,
                      "CBSA": 4326,
                      "state": 23034.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 3.7,
                      "CBSA": 1.7,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 14.31,
                      "CBSA": 14.2,
                      "state": 14.49
                   },
                   "error": {
                      "this": 0.59,
                      "CBSA": 0.25,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 146227,
                      "CBSA": 282582,
                      "state": 5723923
                   },
                   "numerator_errors": {
                      "this": 5999.2,
                      "CBSA": 4901.7,
                      "state": 24219.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 4.1,
                      "CBSA": 1.8,
                      "state": 0.4
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 15.39,
                      "CBSA": 15.73,
                      "state": 14.47
                   },
                   "error": {
                      "this": 0.54,
                      "CBSA": 0.23,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 157254,
                      "CBSA": 313158,
                      "state": 5715837
                   },
                   "numerator_errors": {
                      "this": 5467.3,
                      "CBSA": 4601.1,
                      "state": 22852.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 106
                   },
                   "error_ratio": {
                      "this": 3.5,
                      "CBSA": 1.5,
                      "state": 0.4
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.91,
                      "CBSA": 13.7,
                      "state": 12.77
                   },
                   "error": {
                      "this": 0.47,
                      "CBSA": 0.22,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 142130,
                      "CBSA": 272672,
                      "state": 5046965
                   },
                   "numerator_errors": {
                      "this": 4853.2,
                      "CBSA": 4361.4,
                      "state": 22530.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 3.4,
                      "CBSA": 1.6,
                      "state": 0.5
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.14,
                      "CBSA": 12.82,
                      "state": 12.46
                   },
                   "error": {
                      "this": 0.44,
                      "CBSA": 0.21,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 134297,
                      "CBSA": 255250,
                      "state": 4923782
                   },
                   "numerator_errors": {
                      "this": 4460.2,
                      "CBSA": 4138.3,
                      "state": 20800
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 105
                   },
                   "error_ratio": {
                      "this": 3.3,
                      "CBSA": 1.6,
                      "state": 0.4
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.08,
                      "CBSA": 10.03,
                      "state": 10.63
                   },
                   "error": {
                      "this": 0.45,
                      "CBSA": 0.3,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 102985,
                      "CBSA": 199720,
                      "state": 4200080
                   },
                   "numerator_errors": {
                      "this": 4629.2,
                      "CBSA": 6027.7,
                      "state": 31191.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 95
                   },
                   "error_ratio": {
                      "this": 4.5,
                      "CBSA": 3,
                      "state": 0.8
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 5.63,
                      "CBSA": 5.91,
                      "state": 6.44
                   },
                   "error": {
                      "this": 0.32,
                      "CBSA": 0.19,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 57538,
                      "CBSA": 117680,
                      "state": 2545061
                   },
                   "numerator_errors": {
                      "this": 3281.3,
                      "CBSA": 3875.8,
                      "state": 19797.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 95,
                      "state": 87
                   },
                   "error_ratio": {
                      "this": 5.7,
                      "CBSA": 3.2,
                      "state": 0.8
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.37,
                      "CBSA": 3.59,
                      "state": 3.6
                   },
                   "error": {
                      "this": 0.27,
                      "CBSA": 0.18,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 34393,
                      "CBSA": 71442,
                      "state": 1421015
                   },
                   "numerator_errors": {
                      "this": 2809.1,
                      "CBSA": 3584.6,
                      "state": 16282.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 94,
                      "state": 94
                   },
                   "error_ratio": {
                      "this": 8,
                      "CBSA": 5,
                      "state": 1.1
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.05,
                      "CBSA": 11.36,
                      "state": 12.35
                   },
                   "error": {
                      "this": 0.65,
                      "CBSA": 0.27,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 57074,
                      "CBSA": 114531,
                      "state": 2425066
                   },
                   "numerator_errors": {
                      "this": 3418.7,
                      "CBSA": 2720.7,
                      "state": 17537.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 5.9,
                      "CBSA": 2.4,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 13.27,
                      "CBSA": 12.91,
                      "state": 13.47
                   },
                   "error": {
                      "this": 0.66,
                      "CBSA": 0.29,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 68566,
                      "CBSA": 130176,
                      "state": 2646132
                   },
                   "numerator_errors": {
                      "this": 3483.1,
                      "CBSA": 2892.4,
                      "state": 17211.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 5,
                      "CBSA": 2.2,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 15.11,
                      "CBSA": 15.04,
                      "state": 15.06
                   },
                   "error": {
                      "this": 0.87,
                      "CBSA": 0.33,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 78055,
                      "CBSA": 151632,
                      "state": 2956969
                   },
                   "numerator_errors": {
                      "this": 4545.8,
                      "CBSA": 3378.7,
                      "state": 16123.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 5.8,
                      "CBSA": 2.2,
                      "state": 0.5
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 16.18,
                      "CBSA": 16.55,
                      "state": 14.93
                   },
                   "error": {
                      "this": 0.78,
                      "CBSA": 0.38,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 83590,
                      "CBSA": 166869,
                      "state": 2932306
                   },
                   "numerator_errors": {
                      "this": 4103.1,
                      "CBSA": 3850.8,
                      "state": 17684.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 4.8,
                      "CBSA": 2.3,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.92,
                      "CBSA": 13.52,
                      "state": 12.82
                   },
                   "error": {
                      "this": 0.69,
                      "CBSA": 0.34,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 71902,
                      "CBSA": 136309,
                      "state": 2518785
                   },
                   "numerator_errors": {
                      "this": 3624.2,
                      "CBSA": 3398.8,
                      "state": 17201
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 5,
                      "CBSA": 2.5,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.13,
                      "CBSA": 12.66,
                      "state": 12.42
                   },
                   "error": {
                      "this": 0.58,
                      "CBSA": 0.3,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 67821,
                      "CBSA": 127634,
                      "state": 2439055
                   },
                   "numerator_errors": {
                      "this": 3082.3,
                      "CBSA": 3031.9,
                      "state": 15253.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 106
                   },
                   "error_ratio": {
                      "this": 4.4,
                      "CBSA": 2.4,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.96,
                      "CBSA": 9.84,
                      "state": 10.2
                   },
                   "error": {
                      "this": 0.64,
                      "CBSA": 0.45,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 51444,
                      "CBSA": 99211,
                      "state": 2004301
                   },
                   "numerator_errors": {
                      "this": 3330,
                      "CBSA": 4513.4,
                      "state": 21480.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 98
                   },
                   "error_ratio": {
                      "this": 6.4,
                      "CBSA": 4.6,
                      "state": 1.1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.96,
                      "CBSA": 5.31,
                      "state": 5.89
                   },
                   "error": {
                      "this": 0.45,
                      "CBSA": 0.27,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 25647,
                      "CBSA": 53516,
                      "state": 1156395
                   },
                   "numerator_errors": {
                      "this": 2316.1,
                      "CBSA": 2722.6,
                      "state": 13944.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 93,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 9.1,
                      "CBSA": 5.1,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.41,
                      "CBSA": 2.8,
                      "state": 2.86
                   },
                   "error": {
                      "this": 0.29,
                      "CBSA": 0.2,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 12460,
                      "CBSA": 28278,
                      "state": 561785
                   },
                   "numerator_errors": {
                      "this": 1515.8,
                      "CBSA": 2064.4,
                      "state": 10571.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 86,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 12,
                      "CBSA": 7.1,
                      "state": 1.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.43,
                      "CBSA": 11.44,
                      "state": 11.74
                   },
                   "error": {
                      "this": 0.64,
                      "CBSA": 0.32,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 57763,
                      "CBSA": 112377,
                      "state": 2333535
                   },
                   "numerator_errors": {
                      "this": 3270.4,
                      "CBSA": 3105.2,
                      "state": 14962.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 5.6,
                      "CBSA": 2.8,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 12.58,
                      "CBSA": 12.32,
                      "state": 12.74
                   },
                   "error": {
                      "this": 0.67,
                      "CBSA": 0.33,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 63559,
                      "CBSA": 121072,
                      "state": 2530827
                   },
                   "numerator_errors": {
                      "this": 3431.6,
                      "CBSA": 3216.8,
                      "state": 15307.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 5.3,
                      "CBSA": 2.7,
                      "state": 0.6
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 13.49,
                      "CBSA": 13.33,
                      "state": 13.92
                   },
                   "error": {
                      "this": 0.76,
                      "CBSA": 0.36,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 68172,
                      "CBSA": 130950,
                      "state": 2766954
                   },
                   "numerator_errors": {
                      "this": 3914.9,
                      "CBSA": 3551.2,
                      "state": 18072.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 5.6,
                      "CBSA": 2.7,
                      "state": 0.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.58,
                      "CBSA": 14.89,
                      "state": 14.01
                   },
                   "error": {
                      "this": 0.7,
                      "CBSA": 0.26,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 73664,
                      "CBSA": 146289,
                      "state": 2783531
                   },
                   "numerator_errors": {
                      "this": 3613.3,
                      "CBSA": 2518.1,
                      "state": 14474.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 104
                   },
                   "error_ratio": {
                      "this": 4.8,
                      "CBSA": 1.7,
                      "state": 0.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.9,
                      "CBSA": 13.88,
                      "state": 12.72
                   },
                   "error": {
                      "this": 0.62,
                      "CBSA": 0.28,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 70228,
                      "CBSA": 136363,
                      "state": 2528180
                   },
                   "numerator_errors": {
                      "this": 3227.9,
                      "CBSA": 2733.1,
                      "state": 14551.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 4.5,
                      "CBSA": 2,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.16,
                      "CBSA": 12.99,
                      "state": 12.5
                   },
                   "error": {
                      "this": 0.63,
                      "CBSA": 0.29,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 66476,
                      "CBSA": 127616,
                      "state": 2484727
                   },
                   "numerator_errors": {
                      "this": 3223.8,
                      "CBSA": 2816.6,
                      "state": 14140.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 105
                   },
                   "error_ratio": {
                      "this": 4.8,
                      "CBSA": 2.2,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.2,
                      "CBSA": 10.23,
                      "state": 11.05
                   },
                   "error": {
                      "this": 0.63,
                      "CBSA": 0.41,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 51541,
                      "CBSA": 100509,
                      "state": 2195779
                   },
                   "numerator_errors": {
                      "this": 3215.6,
                      "CBSA": 3995.4,
                      "state": 22615.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 92
                   },
                   "error_ratio": {
                      "this": 6.2,
                      "CBSA": 4,
                      "state": 1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.31,
                      "CBSA": 6.53,
                      "state": 6.99
                   },
                   "error": {
                      "this": 0.46,
                      "CBSA": 0.28,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 31891,
                      "CBSA": 64164,
                      "state": 1388666
                   },
                   "numerator_errors": {
                      "this": 2324.4,
                      "CBSA": 2758.5,
                      "state": 14053.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 90
                   },
                   "error_ratio": {
                      "this": 7.3,
                      "CBSA": 4.3,
                      "state": 1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.34,
                      "CBSA": 4.39,
                      "state": 4.32
                   },
                   "error": {
                      "this": 0.47,
                      "CBSA": 0.3,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 21933,
                      "CBSA": 43164,
                      "state": 859230
                   },
                   "numerator_errors": {
                      "this": 2365,
                      "CBSA": 2930.5,
                      "state": 12383.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 10.8,
                      "CBSA": 6.8,
                      "state": 1.4
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 37.4,
                   "CBSA": 37.4,
                   "state": 37
                },
                "error": {
                   "this": 0.4,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 101
                },
                "error_ratio": {
                   "this": 1.1,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 36.5,
                   "CBSA": 36.3,
                   "state": 35.9
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 102
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 38.4,
                   "CBSA": 38.5,
                   "state": 38.2
                },
                "error": {
                   "this": 0.6,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 101
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 50.55,
                "CBSA": 50.64,
                "state": 49.71
             },
             "error": {
                "this": 0.48,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 516559,
                "CBSA": 1008156,
                "state": 1.9640794E7
             },
             "numerator_errors": {
                "this": 4936,
                "CBSA": 1022,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 102
             },
             "error_ratio": {
                "this": 0.9,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 49.45,
                "CBSA": 49.36,
                "state": 50.29
             },
             "error": {
                "this": 0.48,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 505227,
                "CBSA": 982504,
                "state": 1.9871428E7
             },
             "numerator_errors": {
                "this": 4932,
                "CBSA": 1022,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 98
             },
             "error_ratio": {
                "this": 1,
                "CBSA": 0.1,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 25.03,
                "CBSA": 30.49,
                "state": 36.33
             },
             "error": {
                "this": 0.75,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 255744,
                "CBSA": 607034,
                "state": 1.4356081E7
             },
             "numerator_errors": {
                "this": 7673,
                "CBSA": 1202,
                "state": 6353
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 69
             },
             "error_ratio": {
                "this": 3,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 2.76,
                "CBSA": 2.34,
                "state": 5.5
             },
             "error": {
                "this": 0.38,
                "CBSA": 0.13,
                "state": 0.04
             },
             "numerators": {
                "this": 28181,
                "CBSA": 46665,
                "state": 2171989
             },
             "numerator_errors": {
                "this": 3892,
                "CBSA": 2598,
                "state": 16630
             },
             "index": {
                "this": 100,
                "CBSA": 118,
                "state": 50
             },
             "error_ratio": {
                "this": 13.8,
                "CBSA": 5.6,
                "state": 0.7
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.13,
                "CBSA": 0.18,
                "state": 0.38
             },
             "error": {
                "this": 0.07,
                "CBSA": 0.06,
                "state": 0.01
             },
             "numerators": {
                "this": 1370,
                "CBSA": 3657,
                "state": 149063
             },
             "numerator_errors": {
                "this": 758,
                "CBSA": 1167,
                "state": 5527
             },
             "index": {
                "this": 100,
                "CBSA": 72,
                "state": 34
             },
             "error_ratio": {
                "this": 53.8,
                "CBSA": 33.3,
                "state": 2.6
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 37.57,
                "CBSA": 36.48,
                "state": 14.65
             },
             "error": {
                "this": 0.95,
                "CBSA": 0.26,
                "state": 0.05
             },
             "numerators": {
                "this": 383895,
                "CBSA": 726115,
                "state": 5786711
             },
             "numerator_errors": {
                "this": 9731,
                "CBSA": 5105,
                "state": 19627
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 256
             },
             "error_ratio": {
                "this": 2.5,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.37,
                "CBSA": 0.34,
                "state": 0.36
             },
             "error": {
                "this": 0.2,
                "CBSA": 0.05,
                "state": 0.01
             },
             "numerators": {
                "this": 3735,
                "CBSA": 6763,
                "state": 141846
             },
             "numerator_errors": {
                "this": 2009,
                "CBSA": 962,
                "state": 4748
             },
             "index": {
                "this": 100,
                "CBSA": 109,
                "state": 103
             },
             "error_ratio": {
                "this": 54.1,
                "CBSA": 14.7,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.39,
                "CBSA": 0.34,
                "state": 0.27
             },
             "error": {
                "this": 0.2,
                "CBSA": 0.11,
                "state": 0.03
             },
             "numerators": {
                "this": 4024,
                "CBSA": 6711,
                "state": 107538
             },
             "numerator_errors": {
                "this": 2003,
                "CBSA": 2126,
                "state": 10686
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 144
             },
             "error_ratio": {
                "this": 51.3,
                "CBSA": 32.4,
                "state": 11.1
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 3.14,
                "CBSA": 3.68,
                "state": 3.1
             },
             "error": {
                "this": 0.41,
                "CBSA": 0.3,
                "state": 0.07
             },
             "numerators": {
                "this": 32075,
                "CBSA": 73203,
                "state": 1224113
             },
             "numerator_errors": {
                "this": 4152,
                "CBSA": 6054,
                "state": 26997
             },
             "index": {
                "this": 100,
                "CBSA": 85,
                "state": 101
             },
             "error_ratio": {
                "this": 13.1,
                "CBSA": 8.2,
                "state": 2.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 30.61,
                "CBSA": 26.15,
                "state": 39.42
             },
             "error": {
                "this": 0.98,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 312762,
                "CBSA": 520512,
                "state": 1.5574882E7
             },
             "numerator_errors": {
                "this": 9971,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 117,
                "state": 78
             },
             "error_ratio": {
                "this": 3.2,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 51310,
                "CBSA": 61400,
                "state": 39393
             },
             "error": {
                "this": 1017,
                "CBSA": 878,
                "state": 177
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 84,
                "state": 130
             },
             "error_ratio": {
                "this": 2,
                "CBSA": 1.4,
                "state": 0.4
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 115893,
                "CBSA": 130865,
                "state": 80440
             },
             "error": {
                "this": 4636,
                "CBSA": 2664,
                "state": 313
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 89,
                "state": 144
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 2,
                "state": 0.4
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 21.87,
                   "CBSA": 18.7,
                   "state": 31.68
                },
                "error": {
                   "this": 1.48,
                   "CBSA": 0.93,
                   "state": 0.26
                },
                "numerators": {
                   "this": 71719,
                   "CBSA": 123983,
                   "state": 4167825
                },
                "numerator_errors": {
                   "this": 4977.8,
                   "CBSA": 6232,
                   "state": 34680.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 69
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 5,
                   "state": 0.8
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 22.17,
                   "CBSA": 20.64,
                   "state": 27.83
                },
                "error": {
                   "this": 1.24,
                   "CBSA": 0.88,
                   "state": 0.24
                },
                "numerators": {
                   "this": 72694,
                   "CBSA": 136795,
                   "state": 3661397
                },
                "numerator_errors": {
                   "this": 4212.8,
                   "CBSA": 5940.2,
                   "state": 32290.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 80
                },
                "error_ratio": {
                   "this": 5.6,
                   "CBSA": 4.3,
                   "state": 0.9
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 30.11,
                   "CBSA": 29.6,
                   "state": 26.8
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 0.97,
                   "state": 0.24
                },
                "numerators": {
                   "this": 98713,
                   "CBSA": 196234,
                   "state": 3525910
                },
                "numerator_errors": {
                   "this": 4713.6,
                   "CBSA": 6546.3,
                   "state": 31856.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 112
                },
                "error_ratio": {
                   "this": 4.5,
                   "CBSA": 3.3,
                   "state": 0.9
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 25.85,
                   "CBSA": 31.05,
                   "state": 13.7
                },
                "error": {
                   "this": 0.98,
                   "CBSA": 0.78,
                   "state": 0.14
                },
                "numerators": {
                   "this": 84768,
                   "CBSA": 205830,
                   "state": 1802741
                },
                "numerator_errors": {
                   "this": 3470,
                   "CBSA": 5376,
                   "state": 18330
                },
                "index": {
                   "this": 100,
                   "CBSA": 83,
                   "state": 189
                },
                "error_ratio": {
                   "this": 3.8,
                   "CBSA": 2.5,
                   "state": 1
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 7.12,
                "CBSA": 6.32,
                "state": 11.75
             },
             "error": {
                "this": 0.74,
                "CBSA": 0.46,
                "state": 0.18
             },
             "numerators": {
                "this": 71931,
                "CBSA": 123477,
                "state": 4552837
             },
             "numerator_errors": {
                "this": 7430,
                "CBSA": 9048,
                "state": 67995
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 113,
                "state": 61
             },
             "error_ratio": {
                "this": 10.4,
                "CBSA": 7.3,
                "state": 1.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 6.31,
                   "CBSA": 5.83,
                   "state": 15.62
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.73,
                   "state": 0.27
                },
                "numerators": {
                   "this": 13900,
                   "CBSA": 24628,
                   "state": 1363574
                },
                "numerator_errors": {
                   "this": 2460.5,
                   "CBSA": 3110.3,
                   "state": 24624.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 40
                },
                "error_ratio": {
                   "this": 17.4,
                   "CBSA": 12.5,
                   "state": 1.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 93.69,
                   "CBSA": 94.17,
                   "state": 84.38
                },
                "error": {
                   "this": 0.36,
                   "CBSA": 2.51,
                   "state": 0.61
                },
                "numerators": {
                   "this": 206457,
                   "CBSA": 398137,
                   "state": 7365259
                },
                "numerator_errors": {
                   "this": 6982.1,
                   "CBSA": 7421.4,
                   "state": 37404.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 111
                },
                "error_ratio": {
                   "this": 0.4,
                   "CBSA": 2.7,
                   "state": 0.7
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 9.37,
                   "CBSA": 7.93,
                   "state": 10.45
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 0.86,
                   "state": 0.23
                },
                "numerators": {
                   "this": 12801,
                   "CBSA": 21528,
                   "state": 599877
                },
                "numerator_errors": {
                   "this": 1880.5,
                   "CBSA": 2362.9,
                   "state": 13070.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 90
                },
                "error_ratio": {
                   "this": 14.3,
                   "CBSA": 10.8,
                   "state": 2.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 90.63,
                   "CBSA": 92.07,
                   "state": 89.55
                },
                "error": {
                   "this": 4.06,
                   "CBSA": 1.55,
                   "state": 0.39
                },
                "numerators": {
                   "this": 123856,
                   "CBSA": 249964,
                   "state": 5138754
                },
                "numerator_errors": {
                   "this": 3910.4,
                   "CBSA": 2657.4,
                   "state": 14255.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 101
                },
                "error_ratio": {
                   "this": 4.5,
                   "CBSA": 1.7,
                   "state": 0.4
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 31.66,
                "CBSA": 30.26,
                "state": 30.68
             },
             "error": {
                "this": 0.43,
                "CBSA": 0.43,
                "state": 0.1
             },
             "numerators": {
                "this": 1.5960715E7,
                "CBSA": 2.9507464E7,
                "state": 5.3813811E8
             },
             "numerator_errors": {
                "this": 338902,
                "CBSA": 532392,
                "state": 2278526
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 103
             },
             "error_ratio": {
                "this": 1.4,
                "CBSA": 1.4,
                "state": 0.3
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 75.27,
                   "CBSA": 74.68,
                   "state": 73.51
                },
                "error": {
                   "this": 1.45,
                   "CBSA": 1.01,
                   "state": 0.23
                },
                "numerators": {
                   "this": 395929,
                   "CBSA": 764930,
                   "state": 1.3767903E7
                },
                "numerator_errors": {
                   "this": 9648,
                   "CBSA": 12803,
                   "state": 52769
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 102
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 1.4,
                   "state": 0.3
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 11.53,
                   "CBSA": 10.52,
                   "state": 9.83
                },
                "error": {
                   "this": 0.9,
                   "CBSA": 0.63,
                   "state": 0.15
                },
                "numerators": {
                   "this": 60648,
                   "CBSA": 107743,
                   "state": 1841273
                },
                "numerator_errors": {
                   "this": 4834,
                   "CBSA": 6587,
                   "state": 29274
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 117
                },
                "error_ratio": {
                   "this": 7.8,
                   "CBSA": 6,
                   "state": 1.5
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 4.96,
                   "CBSA": 4.66,
                   "state": 5.18
                },
                "error": {
                   "this": 0.5,
                   "CBSA": 0.38,
                   "state": 0.08
                },
                "numerators": {
                   "this": 26089,
                   "CBSA": 47745,
                   "state": 970901
                },
                "numerator_errors": {
                   "this": 2650,
                   "CBSA": 3896,
                   "state": 15634
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 96
                },
                "error_ratio": {
                   "this": 10.1,
                   "CBSA": 8.2,
                   "state": 1.5
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.71,
                   "CBSA": 1.69,
                   "state": 0.86
                },
                "error": {
                   "this": 0.21,
                   "CBSA": 0.23,
                   "state": 0.04
                },
                "numerators": {
                   "this": 3739,
                   "CBSA": 17336,
                   "state": 160661
                },
                "numerator_errors": {
                   "this": 1124,
                   "CBSA": 2347,
                   "state": 7943
                },
                "index": {
                   "this": 100,
                   "CBSA": 42,
                   "state": 83
                },
                "error_ratio": {
                   "this": 29.6,
                   "CBSA": 13.6,
                   "state": 4.7
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 2.06,
                   "CBSA": 2.29,
                   "state": 2.56
                },
                "error": {
                   "this": 0.39,
                   "CBSA": 0.29,
                   "state": 0.08
                },
                "numerators": {
                   "this": 10849,
                   "CBSA": 23453,
                   "state": 479751
                },
                "numerator_errors": {
                   "this": 2052,
                   "CBSA": 2957,
                   "state": 15458
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 80
                },
                "error_ratio": {
                   "this": 18.9,
                   "CBSA": 12.7,
                   "state": 3.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.3,
                   "CBSA": 1.36,
                   "state": 1.72
                },
                "error": {
                   "this": 0.25,
                   "CBSA": 0.19,
                   "state": 0.06
                },
                "numerators": {
                   "this": 6828,
                   "CBSA": 13892,
                   "state": 321375
                },
                "numerator_errors": {
                   "this": 1315,
                   "CBSA": 1961,
                   "state": 11717
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 76
                },
                "error_ratio": {
                   "this": 19.2,
                   "CBSA": 14,
                   "state": 3.5
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 4.16,
                   "CBSA": 4.8,
                   "state": 6.34
                },
                "error": {
                   "this": 0.5,
                   "CBSA": 0.38,
                   "state": 0.12
                },
                "numerators": {
                   "this": 21896,
                   "CBSA": 49187,
                   "state": 1188387
                },
                "numerator_errors": {
                   "this": 2632,
                   "CBSA": 3909,
                   "state": 22217
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 66
                },
                "error_ratio": {
                   "this": 12,
                   "CBSA": 7.9,
                   "state": 1.9
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 51.12,
                "CBSA": 53.35,
                "state": 48.44
             },
             "error": {
                "this": 0.84,
                "CBSA": 0.66,
                "state": 0.14
             },
             "numerators": {
                "this": 427756,
                "CBSA": 871204,
                "state": 1.5561514E7
             },
             "numerator_errors": {
                "this": 7498.3,
                "CBSA": 10731.2,
                "state": 46249.3
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 106
             },
             "error_ratio": {
                "this": 1.6,
                "CBSA": 1.2,
                "state": 0.3
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 48.88,
                "CBSA": 46.65,
                "state": 51.56
             },
             "error": {
                "this": 1.1,
                "CBSA": 0.65,
                "state": 0.16
             },
             "numerators": {
                "this": 408941,
                "CBSA": 761698,
                "state": 1.6562598E7
             },
             "numerator_errors": {
                "this": 9513.1,
                "CBSA": 10660.5,
                "state": 51752.6
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 95
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 1.4,
                "state": 0.3
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 40.21,
                   "CBSA": 38.63,
                   "state": 40.83
                },
                "error": {
                   "this": 1.44,
                   "CBSA": 0.73,
                   "state": 0.17
                },
                "numerators": {
                   "this": 169873,
                   "CBSA": 318683,
                   "state": 6478460
                },
                "numerator_errors": {
                   "this": 6418,
                   "CBSA": 5996,
                   "state": 27866
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 98
                },
                "error_ratio": {
                   "this": 3.6,
                   "CBSA": 1.9,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 32.34,
                   "CBSA": 31.2,
                   "state": 33.96
                },
                "error": {
                   "this": 1.11,
                   "CBSA": 0.68,
                   "state": 0.15
                },
                "numerators": {
                   "this": 133984,
                   "CBSA": 252117,
                   "state": 5521285
                },
                "numerator_errors": {
                   "this": 4808,
                   "CBSA": 5538,
                   "state": 24532
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 95
                },
                "error_ratio": {
                   "this": 3.4,
                   "CBSA": 2.2,
                   "state": 0.4
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 51.62,
                   "CBSA": 53.67,
                   "state": 49.37
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.91,
                   "state": 0.2
                },
                "numerators": {
                   "this": 218072,
                   "CBSA": 442711,
                   "state": 7832740
                },
                "numerator_errors": {
                   "this": 5339,
                   "CBSA": 7542,
                   "state": 32087
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 105
                },
                "error_ratio": {
                   "this": 2.1,
                   "CBSA": 1.7,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 50.61,
                   "CBSA": 53.03,
                   "state": 47.54
                },
                "error": {
                   "this": 1.16,
                   "CBSA": 0.94,
                   "state": 0.2
                },
                "numerators": {
                   "this": 209684,
                   "CBSA": 428493,
                   "state": 7728774
                },
                "numerator_errors": {
                   "this": 5265,
                   "CBSA": 7634,
                   "state": 33308
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 106
                },
                "error_ratio": {
                   "this": 2.3,
                   "CBSA": 1.8,
                   "state": 0.4
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 6.58,
                   "CBSA": 5.98,
                   "state": 7.69
                },
                "error": {
                   "this": 0.68,
                   "CBSA": 0.44,
                   "state": 0.11
                },
                "numerators": {
                   "this": 27808,
                   "CBSA": 49331,
                   "state": 1219434
                },
                "numerator_errors": {
                   "this": 2911,
                   "CBSA": 3616,
                   "state": 17626
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 86
                },
                "error_ratio": {
                   "this": 10.3,
                   "CBSA": 7.4,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 10.16,
                   "CBSA": 9.09,
                   "state": 10.83
                },
                "error": {
                   "this": 0.71,
                   "CBSA": 0.52,
                   "state": 0.16
                },
                "numerators": {
                   "this": 42098,
                   "CBSA": 73422,
                   "state": 1760791
                },
                "numerator_errors": {
                   "this": 2986,
                   "CBSA": 4173,
                   "state": 25259
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 94
                },
                "error_ratio": {
                   "this": 7,
                   "CBSA": 5.7,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.58,
                   "CBSA": 1.72,
                   "state": 2.11
                },
                "error": {
                   "this": 0.29,
                   "CBSA": 0.23,
                   "state": 0.05
                },
                "numerators": {
                   "this": 6671,
                   "CBSA": 14188,
                   "state": 334453
                },
                "numerator_errors": {
                   "this": 1240,
                   "CBSA": 1932,
                   "state": 8393
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 75
                },
                "error_ratio": {
                   "this": 18.4,
                   "CBSA": 13.4,
                   "state": 2.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 6.88,
                   "CBSA": 6.68,
                   "state": 7.68
                },
                "error": {
                   "this": 0.65,
                   "CBSA": 0.44,
                   "state": 0.1
                },
                "numerators": {
                   "this": 28507,
                   "CBSA": 53957,
                   "state": 1248175
                },
                "numerator_errors": {
                   "this": 2695,
                   "CBSA": 3578,
                   "state": 16759
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 90
                },
                "error_ratio": {
                   "this": 9.4,
                   "CBSA": 6.6,
                   "state": 1.3
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 79.84,
                   "CBSA": 81.33,
                   "state": 72.24
                },
                "error": {
                   "this": 2.49,
                   "CBSA": 1.63,
                   "state": 0.46
                },
                "numerators": {
                   "this": 153381,
                   "CBSA": 308825,
                   "state": 5449645
                },
                "numerator_errors": {
                   "this": 7441,
                   "CBSA": 7574,
                   "state": 40502
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 111
                },
                "error_ratio": {
                   "this": 3.1,
                   "CBSA": 2,
                   "state": 0.6
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 6.38,
                   "CBSA": 6.16,
                   "state": 7.66
                },
                "error": {
                   "this": 1.63,
                   "CBSA": 1.13,
                   "state": 0.26
                },
                "numerators": {
                   "this": 12249,
                   "CBSA": 23405,
                   "state": 578128
                },
                "numerator_errors": {
                   "this": 3162,
                   "CBSA": 4322,
                   "state": 19525
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 83
                },
                "error_ratio": {
                   "this": 25.5,
                   "CBSA": 18.3,
                   "state": 3.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 13.78,
                   "CBSA": 12.51,
                   "state": 20.1
                },
                "error": {
                   "this": 1.92,
                   "CBSA": 1.19,
                   "state": 0.43
                },
                "numerators": {
                   "this": 26477,
                   "CBSA": 47496,
                   "state": 1516238
                },
                "numerator_errors": {
                   "this": 3826,
                   "CBSA": 4570,
                   "state": 32886
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 69
                },
                "error_ratio": {
                   "this": 13.9,
                   "CBSA": 9.5,
                   "state": 2.1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 4.6,
                "CBSA": 4.43,
                "state": 4.69
             },
             "error": {
                "this": 0.67,
                "CBSA": 0.46,
                "state": 0.11
             },
             "numerators": {
                "this": 11522,
                "CBSA": 21601,
                "state": 450971
             },
             "numerator_errors": {
                "this": 1675,
                "CBSA": 2240,
                "state": 10955
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 98
             },
             "error_ratio": {
                "this": 14.6,
                "CBSA": 10.4,
                "state": 2.3
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0.15,
                   "CBSA": 0.08,
                   "state": 0.87
                },
                "error": {
                   "this": 0.22,
                   "CBSA": 0.11,
                   "state": 0.16
                },
                "numerators": {
                   "this": 45,
                   "CBSA": 45,
                   "state": 10882
                },
                "numerator_errors": {
                   "this": 67,
                   "CBSA": 67,
                   "state": 2046
                },
                "index": {
                   "this": 100,
                   "CBSA": 188,
                   "state": 17
                },
                "error_ratio": {
                   "this": 146.7,
                   "CBSA": 137.5,
                   "state": 18.4
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 1.92,
                   "CBSA": 2.02,
                   "state": 4.44
                },
                "error": {
                   "this": 1.36,
                   "CBSA": 1.31,
                   "state": 0.41
                },
                "numerators": {
                   "this": 612,
                   "CBSA": 1156,
                   "state": 56709
                },
                "numerator_errors": {
                   "this": 435,
                   "CBSA": 753,
                   "state": 5217
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 43
                },
                "error_ratio": {
                   "this": 70.8,
                   "CBSA": 64.9,
                   "state": 9.2
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 5.55,
                   "CBSA": 4.43,
                   "state": 6.85
                },
                "error": {
                   "this": 2.02,
                   "CBSA": 1.31,
                   "state": 0.37
                },
                "numerators": {
                   "this": 2017,
                   "CBSA": 3265,
                   "state": 101991
                },
                "numerator_errors": {
                   "this": 748,
                   "CBSA": 965,
                   "state": 5591
                },
                "index": {
                   "this": 100,
                   "CBSA": 125,
                   "state": 81
                },
                "error_ratio": {
                   "this": 36.4,
                   "CBSA": 29.6,
                   "state": 5.4
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 12.4,
                   "CBSA": 11.69,
                   "state": 9.73
                },
                "error": {
                   "this": 3.17,
                   "CBSA": 1.9,
                   "state": 0.51
                },
                "numerators": {
                   "this": 4617,
                   "CBSA": 8763,
                   "state": 139246
                },
                "numerator_errors": {
                   "this": 1232,
                   "CBSA": 1442,
                   "state": 7329
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 127
                },
                "error_ratio": {
                   "this": 25.6,
                   "CBSA": 16.3,
                   "state": 5.2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 8.17,
                   "CBSA": 8.49,
                   "state": 6.95
                },
                "error": {
                   "this": 1.71,
                   "CBSA": 1.33,
                   "state": 0.33
                },
                "numerators": {
                   "this": 2978,
                   "CBSA": 6052,
                   "state": 93949
                },
                "numerator_errors": {
                   "this": 660,
                   "CBSA": 976,
                   "state": 4611
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 118
                },
                "error_ratio": {
                   "this": 20.9,
                   "CBSA": 15.7,
                   "state": 4.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 2,
                   "CBSA": 2.09,
                   "state": 2.64
                },
                "error": {
                   "this": 1.05,
                   "CBSA": 0.9,
                   "state": 0.21
                },
                "numerators": {
                   "this": 732,
                   "CBSA": 1431,
                   "state": 33377
                },
                "numerator_errors": {
                   "this": 388,
                   "CBSA": 618,
                   "state": 2743
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 76
                },
                "error_ratio": {
                   "this": 52.5,
                   "CBSA": 43.1,
                   "state": 8
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 1.25,
                   "CBSA": 1.08,
                   "state": 0.96
                },
                "error": {
                   "this": 0.65,
                   "CBSA": 0.44,
                   "state": 0.14
                },
                "numerators": {
                   "this": 521,
                   "CBSA": 889,
                   "state": 14817
                },
                "numerator_errors": {
                   "this": 271,
                   "CBSA": 364,
                   "state": 2211
                },
                "index": {
                   "this": 100,
                   "CBSA": 116,
                   "state": 130
                },
                "error_ratio": {
                   "this": 52,
                   "CBSA": 40.7,
                   "state": 14.6
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 327894,
                "CBSA": 662842,
                "state": 1.3157873E7
             },
             "error": {
                "this": 5001,
                "CBSA": 4591,
                "state": 23844
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 49,
                "state": 2
             },
             "error_ratio": {
                "this": 1.5,
                "CBSA": 0.7,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 3.07,
                "CBSA": 2.95,
                "state": 2.94
             },
             "error": {
                "this": 0.05,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 1007614,
                "CBSA": 1953094,
                "state": 3.8685704E7
             },
             "numerator_errors": {
                "this": 2249,
                "CBSA": 2672,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 104
             },
             "error_ratio": {
                "this": 1.6,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 63.93,
                   "CBSA": 65.4,
                   "state": 60.69
                },
                "error": {
                   "this": 1.7,
                   "CBSA": 1.15,
                   "state": 0.22
                },
                "numerators": {
                   "this": 644122,
                   "CBSA": 1277354,
                   "state": 2.3477092E7
                },
                "numerator_errors": {
                   "this": 17198,
                   "CBSA": 22550,
                   "state": 84642
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 105
                },
                "error_ratio": {
                   "this": 2.7,
                   "CBSA": 1.8,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 7.86,
                   "CBSA": 6.79,
                   "state": 7.74
                },
                "error": {
                   "this": 0.9,
                   "CBSA": 0.64,
                   "state": 0.16
                },
                "numerators": {
                   "this": 79199,
                   "CBSA": 132540,
                   "state": 2996042
                },
                "numerator_errors": {
                   "this": 9095,
                   "CBSA": 12462,
                   "state": 63406
                },
                "index": {
                   "this": 100,
                   "CBSA": 116,
                   "state": 102
                },
                "error_ratio": {
                   "this": 11.5,
                   "CBSA": 9.4,
                   "state": 2.1
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 14.06,
                   "CBSA": 12.85,
                   "state": 16.47
                },
                "error": {
                   "this": 1.39,
                   "CBSA": 0.91,
                   "state": 0.23
                },
                "numerators": {
                   "this": 141691,
                   "CBSA": 251009,
                   "state": 6371949
                },
                "numerator_errors": {
                   "this": 14012,
                   "CBSA": 17743,
                   "state": 87738
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 85
                },
                "error_ratio": {
                   "this": 9.9,
                   "CBSA": 7.1,
                   "state": 1.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 14.15,
                   "CBSA": 14.96,
                   "state": 15.1
                },
                "error": {
                   "this": 0.87,
                   "CBSA": 0.63,
                   "state": 0.1
                },
                "numerators": {
                   "this": 142602,
                   "CBSA": 292191,
                   "state": 5840619
                },
                "numerator_errors": {
                   "this": 8788,
                   "CBSA": 12288,
                   "state": 37146
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 94
                },
                "error_ratio": {
                   "this": 6.1,
                   "CBSA": 4.2,
                   "state": 0.7
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 343234,
                "CBSA": 706285,
                "state": 1.4367012E7
             },
             "error": {
                "this": 4703,
                "CBSA": 923,
                "state": 1286
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 49,
                "state": 2
             },
             "error_ratio": {
                "this": 1.4,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 95.53,
                   "CBSA": 93.85,
                   "state": 91.58
                },
                "error": {
                   "this": 0.64,
                   "CBSA": 0.64,
                   "state": 0.17
                },
                "numerators": {
                   "this": 327894,
                   "CBSA": 662842,
                   "state": 1.3157873E7
                },
                "numerator_errors": {
                   "this": 5001,
                   "CBSA": 4591,
                   "state": 23844
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 104
                },
                "error_ratio": {
                   "this": 0.7,
                   "CBSA": 0.7,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 4.47,
                   "CBSA": 6.15,
                   "state": 8.42
                },
                "error": {
                   "this": 0.8,
                   "CBSA": 0.62,
                   "state": 0.17
                },
                "numerators": {
                   "this": 15340,
                   "CBSA": 43443,
                   "state": 1209139
                },
                "numerator_errors": {
                   "this": 2765,
                   "CBSA": 4388,
                   "state": 24297
                },
                "index": {
                   "this": 100,
                   "CBSA": 73,
                   "state": 53
                },
                "error_ratio": {
                   "this": 17.9,
                   "CBSA": 10.1,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 61.27,
                   "CBSA": 61.51,
                   "state": 63.96
                },
                "error": {
                   "this": 1.25,
                   "CBSA": 0.98,
                   "state": 0.22
                },
                "numerators": {
                   "this": 210299,
                   "CBSA": 434409,
                   "state": 9189272
                },
                "numerator_errors": {
                   "this": 5172,
                   "CBSA": 6930.2,
                   "state": 31423.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 96
                },
                "error_ratio": {
                   "this": 2,
                   "CBSA": 1.6,
                   "state": 0.3
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 34.96,
                   "CBSA": 35.54,
                   "state": 32.18
                },
                "error": {
                   "this": 1.63,
                   "CBSA": 1.19,
                   "state": 0.27
                },
                "numerators": {
                   "this": 120011,
                   "CBSA": 251035,
                   "state": 4622614
                },
                "numerator_errors": {
                   "this": 5836.6,
                   "CBSA": 8419.5,
                   "state": 38673.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 109
                },
                "error_ratio": {
                   "this": 4.7,
                   "CBSA": 3.3,
                   "state": 0.8
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 3.67,
                   "CBSA": 2.89,
                   "state": 3.74
                },
                "error": {
                   "this": 0.43,
                   "CBSA": 0.25,
                   "state": 0.08
                },
                "numerators": {
                   "this": 12594,
                   "CBSA": 20422,
                   "state": 537931
                },
                "numerator_errors": {
                   "this": 1496,
                   "CBSA": 1750,
                   "state": 11591
                },
                "index": {
                   "this": 100,
                   "CBSA": 127,
                   "state": 98
                },
                "error_ratio": {
                   "this": 11.7,
                   "CBSA": 8.7,
                   "state": 2.1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0.1,
                   "CBSA": 0.06,
                   "state": 0.12
                },
                "error": {
                   "this": 0.11,
                   "CBSA": 0.06,
                   "state": 0.01
                },
                "numerators": {
                   "this": 330,
                   "CBSA": 419,
                   "state": 17195
                },
                "numerator_errors": {
                   "this": 381,
                   "CBSA": 400,
                   "state": 2102
                },
                "index": {
                   "this": 100,
                   "CBSA": 167,
                   "state": 83
                },
                "error_ratio": {
                   "this": 110,
                   "CBSA": 100,
                   "state": 8.3
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 55.2,
                   "CBSA": 55.21,
                   "state": 54.86
                },
                "error": {
                   "this": 1.18,
                   "CBSA": 0.77,
                   "state": 0.22
                },
                "numerators": {
                   "this": 180993,
                   "CBSA": 365949,
                   "state": 7218742
                },
                "numerator_errors": {
                   "this": 4764,
                   "CBSA": 5710,
                   "state": 32100
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 101
                },
                "error_ratio": {
                   "this": 2.1,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 44.8,
                   "CBSA": 44.79,
                   "state": 45.14
                },
                "error": {
                   "this": 1.39,
                   "CBSA": 0.84,
                   "state": 0.24
                },
                "numerators": {
                   "this": 146901,
                   "CBSA": 296893,
                   "state": 5939131
                },
                "numerator_errors": {
                   "this": 5091,
                   "CBSA": 5964,
                   "state": 32807
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 99
                },
                "error_ratio": {
                   "this": 3.1,
                   "CBSA": 1.9,
                   "state": 0.5
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 999900,
                "CBSA": 1116400,
                "state": 568500
             },
             "error": {
                "this": 14735,
                "CBSA": 12380,
                "state": 2502
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 90,
                "state": 176
             },
             "error_ratio": {
                "this": 1.5,
                "CBSA": 1.1,
                "state": 0.4
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 2.27,
                   "CBSA": 1.91,
                   "state": 4.33
                },
                "error": {
                   "this": 0.5,
                   "CBSA": 0.33,
                   "state": 0.13
                },
                "numerators": {
                   "this": 4112,
                   "CBSA": 6972,
                   "state": 312598
                },
                "numerator_errors": {
                   "this": 913.4,
                   "CBSA": 1195.8,
                   "state": 9160.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 52
                },
                "error_ratio": {
                   "this": 22,
                   "CBSA": 17.3,
                   "state": 3
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 1.74,
                   "CBSA": 1.57,
                   "state": 4.61
                },
                "error": {
                   "this": 0.47,
                   "CBSA": 0.3,
                   "state": 0.13
                },
                "numerators": {
                   "this": 3150,
                   "CBSA": 5731,
                   "state": 332471
                },
                "numerator_errors": {
                   "this": 848,
                   "CBSA": 1115.3,
                   "state": 9287.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 38
                },
                "error_ratio": {
                   "this": 27,
                   "CBSA": 19.1,
                   "state": 2.8
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 2.82,
                   "CBSA": 2.13,
                   "state": 9.21
                },
                "error": {
                   "this": 0.69,
                   "CBSA": 0.43,
                   "state": 0.21
                },
                "numerators": {
                   "this": 5103,
                   "CBSA": 7784,
                   "state": 664558
                },
                "numerator_errors": {
                   "this": 1262.7,
                   "CBSA": 1589.7,
                   "state": 15361.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 132,
                   "state": 31
                },
                "error_ratio": {
                   "this": 24.5,
                   "CBSA": 20.2,
                   "state": 2.3
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 1.75,
                   "CBSA": 1.63,
                   "state": 12.14
                },
                "error": {
                   "this": 0.41,
                   "CBSA": 0.32,
                   "state": 0.21
                },
                "numerators": {
                   "this": 3164,
                   "CBSA": 5950,
                   "state": 876689
                },
                "numerator_errors": {
                   "this": 746,
                   "CBSA": 1158,
                   "state": 15663
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 14
                },
                "error_ratio": {
                   "this": 23.4,
                   "CBSA": 19.6,
                   "state": 1.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 2.32,
                   "CBSA": 2.22,
                   "state": 12.79
                },
                "error": {
                   "this": 0.55,
                   "CBSA": 0.35,
                   "state": 0.19
                },
                "numerators": {
                   "this": 4201,
                   "CBSA": 8127,
                   "state": 923496
                },
                "numerator_errors": {
                   "this": 1001,
                   "CBSA": 1280,
                   "state": 14009
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 18
                },
                "error_ratio": {
                   "this": 23.7,
                   "CBSA": 15.8,
                   "state": 1.5
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 39.1,
                   "CBSA": 31.59,
                   "state": 38.68
                },
                "error": {
                   "this": 1.61,
                   "CBSA": 1.13,
                   "state": 0.28
                },
                "numerators": {
                   "this": 70777,
                   "CBSA": 115590,
                   "state": 2792052
                },
                "numerator_errors": {
                   "this": 3464.1,
                   "CBSA": 4513.7,
                   "state": 23443.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 101
                },
                "error_ratio": {
                   "this": 4.1,
                   "CBSA": 3.6,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 32.54,
                   "CBSA": 27.23,
                   "state": 9.58
                },
                "error": {
                   "this": 1.44,
                   "CBSA": 0.99,
                   "state": 0.16
                },
                "numerators": {
                   "this": 58890,
                   "CBSA": 99634,
                   "state": 691446
                },
                "numerator_errors": {
                   "this": 3033,
                   "CBSA": 3954,
                   "state": 11798
                },
                "index": {
                   "this": 100,
                   "CBSA": 120,
                   "state": 340
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 3.6,
                   "state": 1.7
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 180993,
                "CBSA": 365949,
                "state": 7218742
             },
             "error": {
                "this": 4764,
                "CBSA": 5710,
                "state": 32100
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 49,
                "state": 3
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 1.6,
                "state": 0.4
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 9.18,
                "CBSA": 9.06,
                "state": 8.86
             },
             "error": {
                "this": 0.77,
                "CBSA": 0.47,
                "state": 0.13
             },
             "numerators": {
                "this": 92484,
                "CBSA": 176854,
                "state": 3427072
             },
             "numerator_errors": {
                "this": 7766.1,
                "CBSA": 9233.8,
                "state": 50836.5
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 104
             },
             "error_ratio": {
                "this": 8.4,
                "CBSA": 5.2,
                "state": 1.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 11.73,
                "CBSA": 11.24,
                "state": 11.17
             },
             "error": {
                "this": 0.89,
                "CBSA": 0.63,
                "state": 0.16
             },
             "numerators": {
                "this": 118225,
                "CBSA": 219614,
                "state": 4320604
             },
             "numerator_errors": {
                "this": 8982.5,
                "CBSA": 12268.4,
                "state": 63360.7
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 105
             },
             "error_ratio": {
                "this": 7.6,
                "CBSA": 5.6,
                "state": 1.4
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 23.57,
                "CBSA": 23.18,
                "state": 21.9
             },
             "error": {
                "this": 1.35,
                "CBSA": 0.98,
                "state": 0.25
             },
             "numerators": {
                "this": 237493,
                "CBSA": 452689,
                "state": 8473932
             },
             "numerator_errors": {
                "this": 13605.5,
                "CBSA": 19244.3,
                "state": 98624.3
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 108
             },
             "error_ratio": {
                "this": 5.7,
                "CBSA": 4.2,
                "state": 1.1
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 20.52,
                "CBSA": 19.06,
                "state": 20.05
             },
             "error": {
                "this": 1.57,
                "CBSA": 0.99,
                "state": 0.24
             },
             "numerators": {
                "this": 206747,
                "CBSA": 372274,
                "state": 7754580
             },
             "numerator_errors": {
                "this": 15851.1,
                "CBSA": 19357.5,
                "state": 91602.6
             },
             "index": {
                "this": 100,
                "CBSA": 108,
                "state": 102
             },
             "error_ratio": {
                "this": 7.7,
                "CBSA": 5.2,
                "state": 1.2
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 11.84,
                "CBSA": 11.32,
                "state": 13.11
             },
             "error": {
                "this": 1.18,
                "CBSA": 0.76,
                "state": 0.21
             },
             "numerators": {
                "this": 119280,
                "CBSA": 221176,
                "state": 5072685
             },
             "numerator_errors": {
                "this": 11913.5,
                "CBSA": 14873.9,
                "state": 79541.6
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 90
             },
             "error_ratio": {
                "this": 10,
                "CBSA": 6.7,
                "state": 1.6
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 23.16,
                "CBSA": 26.14,
                "state": 24.91
             },
             "error": {
                "this": 1.38,
                "CBSA": 1.01,
                "state": 0.26
             },
             "numerators": {
                "this": 233385,
                "CBSA": 510487,
                "state": 9636829
             },
             "numerator_errors": {
                "this": 13953.4,
                "CBSA": 19807.6,
                "state": 101788.8
             },
             "index": {
                "this": 100,
                "CBSA": 89,
                "state": 93
             },
             "error_ratio": {
                "this": 6,
                "CBSA": 3.9,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 11.43,
                "CBSA": 13.82,
                "state": 12
             },
             "error": {
                "this": 0.87,
                "CBSA": 0.67,
                "state": 0.16
             },
             "numerators": {
                "this": 115546,
                "CBSA": 272064,
                "state": 4689828
             },
             "numerator_errors": {
                "this": 8834.7,
                "CBSA": 13126.5,
                "state": 63097.7
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 83,
                "state": 95
             },
             "error_ratio": {
                "this": 7.6,
                "CBSA": 4.8,
                "state": 1.3
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 88.57,
                "CBSA": 86.18,
                "state": 88
             },
             "error": {
                "this": 0.88,
                "CBSA": 0.64,
                "state": 0.17
             },
             "numerators": {
                "this": 895536,
                "CBSA": 1696672,
                "state": 3.439422E7
             },
             "numerator_errors": {
                "this": 9045,
                "CBSA": 12845,
                "state": 68574
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 101
             },
             "error_ratio": {
                "this": 1,
                "CBSA": 0.7,
                "state": 0.2
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 7.24,
                "CBSA": 8.29,
                "state": 7.37
             },
             "error": {
                "this": 0.74,
                "CBSA": 0.55,
                "state": 0.13
             },
             "numerators": {
                "this": 73210,
                "CBSA": 163270,
                "state": 2881883
             },
             "numerator_errors": {
                "this": 7527,
                "CBSA": 10772,
                "state": 52031
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 98
             },
             "error_ratio": {
                "this": 10.2,
                "CBSA": 6.6,
                "state": 1.8
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 1.95,
                "CBSA": 2.44,
                "state": 2.72
             },
             "error": {
                "this": 0.34,
                "CBSA": 0.23,
                "state": 0.07
             },
             "numerators": {
                "this": 19681,
                "CBSA": 48036,
                "state": 1061256
             },
             "numerator_errors": {
                "this": 3402,
                "CBSA": 4546,
                "state": 27761
             },
             "index": {
                "this": 100,
                "CBSA": 80,
                "state": 72
             },
             "error_ratio": {
                "this": 17.4,
                "CBSA": 9.4,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 1.05,
                "CBSA": 1.67,
                "state": 1.23
             },
             "error": {
                "this": 0.22,
                "CBSA": 0.24,
                "state": 0.05
             },
             "numerators": {
                "this": 10576,
                "CBSA": 32975,
                "state": 480204
             },
             "numerator_errors": {
                "this": 2237,
                "CBSA": 4629,
                "state": 18764
             },
             "index": {
                "this": 100,
                "CBSA": 63,
                "state": 85
             },
             "error_ratio": {
                "this": 21,
                "CBSA": 14.4,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 1.19,
                "CBSA": 1.41,
                "state": 0.68
             },
             "error": {
                "this": 0.22,
                "CBSA": 0.19,
                "state": 0.03
             },
             "numerators": {
                "this": 12079,
                "CBSA": 27783,
                "state": 266485
             },
             "numerator_errors": {
                "this": 2195,
                "CBSA": 3765,
                "state": 12302
             },
             "index": {
                "this": 100,
                "CBSA": 84,
                "state": 175
             },
             "error_ratio": {
                "this": 18.5,
                "CBSA": 13.5,
                "state": 4.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 85.43,
                "CBSA": 88.65,
                "state": 84.03
             },
             "error": {
                "this": 1.74,
                "CBSA": 1.3,
                "state": 0.29
             },
             "numerators": {
                "this": 607458,
                "CBSA": 1234572,
                "state": 2.263636E7
             },
             "numerator_errors": {
                "this": 13192.5,
                "CBSA": 18089.6,
                "state": 78234.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 102
             },
             "error_ratio": {
                "this": 2,
                "CBSA": 1.5,
                "state": 0.3
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 44.97,
                "CBSA": 52.66,
                "state": 35
             },
             "error": {
                "this": 1.17,
                "CBSA": 0.92,
                "state": 0.17
             },
             "numerators": {
                "this": 319769,
                "CBSA": 733354,
                "state": 9428484
             },
             "numerator_errors": {
                "this": 8670.6,
                "CBSA": 12834.6,
                "state": 44753.9
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 85,
                "state": 128
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 1.7,
                "state": 0.5
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 14.57,
                "CBSA": 11.35,
                "state": 15.97
             },
             "error": {
                "this": 0.78,
                "CBSA": 0.52,
                "state": 0.14
             },
             "numerators": {
                "this": 103582,
                "CBSA": 158083,
                "state": 4301513
             },
             "numerator_errors": {
                "this": 5576.6,
                "CBSA": 7253,
                "state": 37669.1
             },
             "index": {
                "this": 100,
                "CBSA": 128,
                "state": 91
             },
             "error_ratio": {
                "this": 5.4,
                "CBSA": 4.6,
                "state": 0.9
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 16.51,
                "CBSA": 14.07,
                "state": 20.59
             },
             "error": {
                "this": 0.99,
                "CBSA": 0.6,
                "state": 0.16
             },
             "numerators": {
                "this": 117419,
                "CBSA": 195956,
                "state": 5546711
             },
             "numerator_errors": {
                "this": 7110.7,
                "CBSA": 8397.9,
                "state": 42454
             },
             "index": {
                "this": 100,
                "CBSA": 117,
                "state": 80
             },
             "error_ratio": {
                "this": 6,
                "CBSA": 4.3,
                "state": 0.8
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 23.95,
                "CBSA": 21.92,
                "state": 28.44
             },
             "error": {
                "this": 0.96,
                "CBSA": 0.69,
                "state": 0.18
             },
             "numerators": {
                "this": 170270,
                "CBSA": 305262,
                "state": 7661164
             },
             "numerator_errors": {
                "this": 6949.9,
                "CBSA": 9590.8,
                "state": 48118.5
             },
             "index": {
                "this": 100,
                "CBSA": 109,
                "state": 84
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 3.1,
                "state": 0.6
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 25.77,
                "CBSA": 27.6,
                "state": 21.86
             },
             "error": {
                "this": 0.93,
                "CBSA": 0.7,
                "state": 0.13
             },
             "numerators": {
                "this": 183254,
                "CBSA": 384399,
                "state": 5889724
             },
             "numerator_errors": {
                "this": 6725,
                "CBSA": 9703.6,
                "state": 34784.9
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 118
             },
             "error_ratio": {
                "this": 3.6,
                "CBSA": 2.5,
                "state": 0.6
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 19.2,
                "CBSA": 25.06,
                "state": 13.14
             },
             "error": {
                "this": 0.76,
                "CBSA": 0.6,
                "state": 0.1
             },
             "numerators": {
                "this": 136515,
                "CBSA": 348955,
                "state": 3538760
             },
             "numerator_errors": {
                "this": 5473,
                "CBSA": 8400.4,
                "state": 28159
             },
             "index": {
                "this": 100,
                "CBSA": 77,
                "state": 146
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 2.4,
                "state": 0.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 41.1,
                "CBSA": 39.69,
                "state": 26.74
             },
             "error": {
                "this": 0.93,
                "CBSA": 0.69,
                "state": 0.14
             },
             "numerators": {
                "this": 419921,
                "CBSA": 790026,
                "state": 1.056422E7
             },
             "numerator_errors": {
                "this": 9516,
                "CBSA": 13650,
                "state": 55357
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 154
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 1.7,
                "state": 0.5
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 5.43,
                   "CBSA": 7.91,
                   "state": 6.42
                },
                "error": {
                   "this": 0.32,
                   "CBSA": 0.3,
                   "state": 0.07
                },
                "numerators": {
                   "this": 22160,
                   "CBSA": 60639,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 1320,
                   "CBSA": 2354,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 69,
                   "state": 85
                },
                "error_ratio": {
                   "this": 5.9,
                   "CBSA": 3.8,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 64.32,
                   "CBSA": 65.91,
                   "state": 39.42
                },
                "error": {
                   "this": 0.37,
                   "CBSA": 0.62,
                   "state": 0.09
                },
                "numerators": {
                   "this": 262674,
                   "CBSA": 505503,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 3007,
                   "CBSA": 3277,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 163
                },
                "error_ratio": {
                   "this": 0.6,
                   "CBSA": 0.9,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.9,
                   "CBSA": 1.7,
                   "state": 1.84
                },
                "error": {
                   "this": 0.2,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 7756,
                   "CBSA": 13034,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 814,
                   "CBSA": 1055,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 103
                },
                "error_ratio": {
                   "this": 10.5,
                   "CBSA": 8.2,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.46,
                   "CBSA": 0.53,
                   "state": 0.82
                },
                "error": {
                   "this": 0.13,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 1885,
                   "CBSA": 4060,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 511,
                   "CBSA": 662,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 56
                },
                "error_ratio": {
                   "this": 28.3,
                   "CBSA": 17,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 26.96,
                   "CBSA": 22.67,
                   "state": 50.28
                },
                "error": {
                   "this": 0.69,
                   "CBSA": 0.42,
                   "state": 0.15
                },
                "numerators": {
                   "this": 110093,
                   "CBSA": 173901,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 3031,
                   "CBSA": 3417,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 54
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 1.9,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 0.94,
                   "CBSA": 1.29,
                   "state": 1.23
                },
                "error": {
                   "this": 0.11,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 3845,
                   "CBSA": 9858,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 453,
                   "CBSA": 722,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 73,
                   "state": 76
                },
                "error_ratio": {
                   "this": 11.7,
                   "CBSA": 7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": 59.12,
                "CBSA": 53.77,
                "state": 44.48
             },
             "error": {
                "this": 1.16,
                "CBSA": 0.93,
                "state": 0.21
             },
             "numerators": {
                "this": 570454,
                "CBSA": 1008315,
                "state": 1.6517804E7
             },
             "numerator_errors": {
                "this": 11350.9,
                "CBSA": 17383,
                "state": 76842.9
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 110,
                "state": 133
             },
             "error_ratio": {
                "this": 2,
                "CBSA": 1.7,
                "state": 0.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 46.37,
                   "CBSA": 51.8,
                   "state": 56.97
                },
                "error": {
                   "this": 2.57,
                   "CBSA": 2.05,
                   "state": 0.51
                },
                "numerators": {
                   "this": 77725,
                   "CBSA": 163621,
                   "state": 3710631
                },
                "numerator_errors": {
                   "this": 4857,
                   "CBSA": 6463,
                   "state": 33084
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 81
                },
                "error_ratio": {
                   "this": 5.5,
                   "CBSA": 4,
                   "state": 0.9
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 27.96,
                   "CBSA": 22.79,
                   "state": 33.31
                },
                "error": {
                   "this": 2.37,
                   "CBSA": 1.47,
                   "state": 0.42
                },
                "numerators": {
                   "this": 46879,
                   "CBSA": 71974,
                   "state": 2169717
                },
                "numerator_errors": {
                   "this": 4203,
                   "CBSA": 4659,
                   "state": 27349
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 84
                },
                "error_ratio": {
                   "this": 8.5,
                   "CBSA": 6.5,
                   "state": 1.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 4.23,
                   "CBSA": 5.87,
                   "state": 3.07
                },
                "error": {
                   "this": 0.96,
                   "CBSA": 0.76,
                   "state": 0.15
                },
                "numerators": {
                   "this": 7084,
                   "CBSA": 18550,
                   "state": 200078
                },
                "numerator_errors": {
                   "this": 1620,
                   "CBSA": 2397,
                   "state": 9887
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 138
                },
                "error_ratio": {
                   "this": 22.7,
                   "CBSA": 12.9,
                   "state": 4.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 19.94,
                   "CBSA": 18.01,
                   "state": 5.66
                },
                "error": {
                   "this": 1.72,
                   "CBSA": 1.04,
                   "state": 0.17
                },
                "numerators": {
                   "this": 33421,
                   "CBSA": 56897,
                   "state": 368935
                },
                "numerator_errors": {
                   "this": 3043,
                   "CBSA": 3297,
                   "state": 11144
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 352
                },
                "error_ratio": {
                   "this": 8.6,
                   "CBSA": 5.8,
                   "state": 3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.51,
                   "CBSA": 1.53,
                   "state": 0.98
                },
                "error": {
                   "this": 0.76,
                   "CBSA": 0.5,
                   "state": 0.11
                },
                "numerators": {
                   "this": 2528,
                   "CBSA": 4839,
                   "state": 63864
                },
                "numerator_errors": {
                   "this": 1270,
                   "CBSA": 1573,
                   "state": 6999
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 154
                },
                "error_ratio": {
                   "this": 50.3,
                   "CBSA": 32.7,
                   "state": 11.2
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 39.73,
                   "CBSA": 45.1,
                   "state": 55.21
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.87,
                   "state": 0.18
                },
                "numerators": {
                   "this": 316758,
                   "CBSA": 703298,
                   "state": 1.6905948E7
                },
                "numerator_errors": {
                   "this": 9287.3,
                   "CBSA": 13577.1,
                   "state": 55036
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 72
                },
                "error_ratio": {
                   "this": 2.8,
                   "CBSA": 1.9,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 21.05,
                   "CBSA": 17.23,
                   "state": 27.8
                },
                "error": {
                   "this": 1.06,
                   "CBSA": 0.46,
                   "state": 0.12
                },
                "numerators": {
                   "this": 167866,
                   "CBSA": 268660,
                   "state": 8513944
                },
                "numerator_errors": {
                   "this": 8633.3,
                   "CBSA": 7123.5,
                   "state": 37016.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 122,
                   "state": 76
                },
                "error_ratio": {
                   "this": 5,
                   "CBSA": 2.7,
                   "state": 0.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 8.42,
                   "CBSA": 9.75,
                   "state": 4.88
                },
                "error": {
                   "this": 0.79,
                   "CBSA": 0.58,
                   "state": 0.09
                },
                "numerators": {
                   "this": 67131,
                   "CBSA": 152112,
                   "state": 1494004
                },
                "numerator_errors": {
                   "this": 6359.2,
                   "CBSA": 8991,
                   "state": 28577.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 173
                },
                "error_ratio": {
                   "this": 9.4,
                   "CBSA": 5.9,
                   "state": 1.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 29.22,
                   "CBSA": 26.48,
                   "state": 10.99
                },
                "error": {
                   "this": 0.81,
                   "CBSA": 0.55,
                   "state": 0.08
                },
                "numerators": {
                   "this": 232996,
                   "CBSA": 412940,
                   "state": 3364089
                },
                "numerator_errors": {
                   "this": 6834.6,
                   "CBSA": 8583,
                   "state": 24688.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 266
                },
                "error_ratio": {
                   "this": 2.8,
                   "CBSA": 2.1,
                   "state": 0.7
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.57,
                   "CBSA": 1.43,
                   "state": 1.12
                },
                "error": {
                   "this": 0.37,
                   "CBSA": 0.22,
                   "state": 0.05
                },
                "numerators": {
                   "this": 12549,
                   "CBSA": 22343,
                   "state": 343173
                },
                "numerator_errors": {
                   "this": 2968.2,
                   "CBSA": 3488.1,
                   "state": 14221.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 140
                },
                "error_ratio": {
                   "this": 23.6,
                   "CBSA": 15.4,
                   "state": 4.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 646,
                   "CBSA": 1253,
                   "state": 40005
                },
                "error": {
                   "this": 402.42,
                   "CBSA": 482.93,
                   "state": 2857.49
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 52,
                   "state": 2
                },
                "error_ratio": {
                   "this": 62.3,
                   "CBSA": 38.5,
                   "state": 7.1
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 1879,
                   "CBSA": 5578,
                   "state": 111163
                },
                "error": {
                   "this": 636.55,
                   "CBSA": 1069.24,
                   "state": 4957.98
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 34,
                   "state": 2
                },
                "error_ratio": {
                   "this": 33.9,
                   "CBSA": 19.2,
                   "state": 4.5
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 9147,
                   "CBSA": 18453,
                   "state": 530839
                },
                "error": {
                   "this": 1489.79,
                   "CBSA": 1927.97,
                   "state": 12477.16
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 50,
                   "state": 2
                },
                "error_ratio": {
                   "this": 16.3,
                   "CBSA": 10.4,
                   "state": 2.4
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 3597,
                   "CBSA": 7225,
                   "state": 280799
                },
                "error": {
                   "this": 990.58,
                   "CBSA": 1279.37,
                   "state": 9726.05
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 50,
                   "state": 1
                },
                "error_ratio": {
                   "this": 27.5,
                   "CBSA": 17.7,
                   "state": 3.5
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 4554,
                   "CBSA": 8538,
                   "state": 320701
                },
                "error": {
                   "this": 1083.02,
                   "CBSA": 1530.44,
                   "state": 8698.31
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 53,
                   "state": 1
                },
                "error_ratio": {
                   "this": 23.8,
                   "CBSA": 17.9,
                   "state": 2.7
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 22050,
                   "CBSA": 47264,
                   "state": 1351006
                },
                "error": {
                   "this": 2492,
                   "CBSA": 3067,
                   "state": 19299
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 47,
                   "state": 2
                },
                "error_ratio": {
                   "this": 11.3,
                   "CBSA": 6.5,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 1845,
                   "CBSA": 3888,
                   "state": 120461
                },
                "error": {
                   "this": 609,
                   "CBSA": 937,
                   "state": 5618
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 47,
                   "state": 2
                },
                "error_ratio": {
                   "this": 33,
                   "CBSA": 24.1,
                   "state": 4.7
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 23895,
                "CBSA": 51152,
                "state": 1471467
             },
             "error": {
                "this": 2605,
                "CBSA": 3444,
                "state": 19838
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 47,
                "state": 2
             },
             "error_ratio": {
                "this": 10.9,
                "CBSA": 6.7,
                "state": 1.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 3,
                "CBSA": 3.28,
                "state": 4.83
             },
             "error": {
                "this": 0.33,
                "CBSA": 0.22,
                "state": 0.07
             },
             "numerators": {
                "this": 23895,
                "CBSA": 51152,
                "state": 1471467
             },
             "numerator_errors": {
                "this": 2605,
                "CBSA": 3444,
                "state": 19838
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 91,
                "state": 62
             },
             "error_ratio": {
                "this": 11,
                "CBSA": 6.7,
                "state": 1.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 460521077,
       "awater": 8129848,
       "display_name": "San Jose, CA",
       "full_geoid": "16000US0668000",
       "population": 1026658,
       "simple_name": "San Jose",
       "sumlevel": "160",
       "square_miles": 177.8,
       "population_density": 5746.6
    }
 }

export default san_jose