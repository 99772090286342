exports.LOS_ALTOS_SCHOOLS = [
		{
			"id": 5514,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "e",
			"lat": 37.384403,
			"lon": -122.10099,
			"name": "Almond Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "550 Almond Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5514-Almond-Elementary-School/",
				"reviews": "/california/los-altos/5514-Almond-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5514-Almond-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "13%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 488,
			"parentRating": 3,
			"numReviews": 16,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 13
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5517,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "m",
			"lat": 37.395504,
			"lon": -122.11676,
			"name": "Ardis G. Egan Junior High",
			"gradeLevels": "7-8",
			"assigned": null,
			"address": {
				"street1": "100 West Portola Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5517-Ardis-G.-Egan-Junior-High-School/",
				"reviews": "/california/los-altos/5517-Ardis-G.-Egan-Junior-High-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5517-Ardis-G.-Egan-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 585,
			"parentRating": 3,
			"numReviews": 17,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				}
			],
			"remediationData": {}
		},
		{
			"id": 30493,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.349483,
			"lon": -122.076942,
			"name": "Baby World Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1715 Grant Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/30493-Baby-World-Day-Care-Center/",
				"reviews": "/california/los-altos/30493-Baby-World-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/los-altos/30493-Baby-World-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14281,
			"districtId": 1116,
			"districtName": "Santa Clara County Office of Education",
			"districtCity": "San Jose",
			"levelCode": "e,m",
			"lat": 37.396835,
			"lon": -122.116463,
			"name": "Bullis Charter School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "102 West Portola Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/14281-Bullis-Charter-School/",
				"reviews": "/california/los-altos/14281-Bullis-Charter-School/#Reviews",
				"collegeSuccess": "/california/los-altos/14281-Bullis-Charter-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "1%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 915,
			"parentRating": 4,
			"numReviews": 46,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 1
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8720,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.383251,
			"lon": -122.097771,
			"name": "Canterbury Christian School",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "101 North El Monte Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/8720-Canterbury-Christian-School/",
				"reviews": "/california/los-altos/8720-Canterbury-Christian-School/#Reviews",
				"collegeSuccess": "/california/los-altos/8720-Canterbury-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 80,
			"parentRating": 4,
			"numReviews": 25,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31769,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.336483,
			"lon": -122.067474,
			"name": "CCLC Preschool in Los Altos",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2310 Homestead Rd.",
				"street2": "Ste. E",
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/31769-CCLC-Preschool-In-Los-Altos/",
				"reviews": "/california/los-altos/31769-CCLC-Preschool-In-Los-Altos/#Reviews",
				"collegeSuccess": "/california/los-altos/31769-CCLC-Preschool-In-Los-Altos/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21145,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.38522,
			"lon": -122.114128,
			"name": "Champion Youth Enrichment School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 3411",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/21145-Champion-Youth-Enrichment-School/",
				"reviews": "/california/los-altos/21145-Champion-Youth-Enrichment-School/#Reviews",
				"collegeSuccess": "/california/los-altos/21145-Champion-Youth-Enrichment-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19970,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.371563,
			"lon": -122.107079,
			"name": "Children's Creative Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "201 Covington Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19970-Childrens-Creative-Learning-Center/",
				"reviews": "/california/los-altos/19970-Childrens-Creative-Learning-Center/#Reviews",
				"collegeSuccess": "/california/los-altos/19970-Childrens-Creative-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19976,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.36142,
			"lon": -122.093414,
			"name": "Children's House of Los Altos",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "770 Berry Ave",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19976-Childrens-House-Of-Los-Altos/",
				"reviews": "/california/los-altos/19976-Childrens-House-Of-Los-Altos/#Reviews",
				"collegeSuccess": "/california/los-altos/19976-Childrens-House-Of-Los-Altos/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19972,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.352604,
			"lon": -122.081955,
			"name": "Childrens House Of Los Altos",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1595 Kensington Circle",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19972-Childrens-House-Of-Los-Altos/",
				"reviews": "/california/los-altos/19972-Childrens-House-Of-Los-Altos/#Reviews",
				"collegeSuccess": "/california/los-altos/19972-Childrens-House-Of-Los-Altos/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19973,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.389999,
			"lon": -122.110001,
			"name": "Clc Foothill Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 991",
				"street2": null,
				"zip": "94023",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94023",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19973-Clc-Foothill-Pre-School/",
				"reviews": "/california/los-altos/19973-Clc-Foothill-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-altos/19973-Clc-Foothill-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28213,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.392624,
			"lon": -122.145409,
			"name": "Congregation Beth Am",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "26790 Arastradero Rd",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/28213-Congregation-Beth-Am/",
				"reviews": "/california/los-altos/28213-Congregation-Beth-Am/#Reviews",
				"collegeSuccess": "/california/los-altos/28213-Congregation-Beth-Am/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12456,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "e",
			"lat": 37.37149,
			"lon": -122.107071,
			"name": "Covington Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "205 Covington Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/12456-Covington-Elementary-School/",
				"reviews": "/california/los-altos/12456-Covington-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/12456-Covington-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 585,
			"parentRating": 5,
			"numReviews": 20,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 44
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 36
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 26346,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.33646,
			"lon": -122.07766,
			"name": "Creative Learning Center",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "2100 Woods Lane",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/26346-Creative-Learning-Center/",
				"reviews": "/california/los-altos/26346-Creative-Learning-Center/#Reviews",
				"collegeSuccess": "/california/los-altos/26346-Creative-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 26,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28941,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.354359,
			"lon": -122.095848,
			"name": "Discovery Childrens House",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1431 Country Club Drive",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/28941-Discovery-Childrens-House/",
				"reviews": "/california/los-altos/28941-Discovery-Childrens-House/#Reviews",
				"collegeSuccess": "/california/los-altos/28941-Discovery-Childrens-House/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5515,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "m",
			"lat": 37.363956,
			"lon": -122.082855,
			"name": "Georgina P. Blach Junior High",
			"gradeLevels": "7-8",
			"assigned": null,
			"address": {
				"street1": "1120 Covington Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5515-Georgina-P.-Blach-Junior-High-School/",
				"reviews": "/california/los-altos/5515-Georgina-P.-Blach-Junior-High-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5515-Georgina-P.-Blach-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 499,
			"parentRating": 5,
			"numReviews": 22,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 32
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 5
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 24960,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.367409,
			"lon": -122.108955,
			"name": "Heritage Academy",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "858 University Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/24960-Heritage-Academy/",
				"reviews": "/california/los-altos/24960-Heritage-Academy/#Reviews",
				"collegeSuccess": "/california/los-altos/24960-Heritage-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 113,
			"parentRating": 5,
			"numReviews": 20,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 66
				},
				{
					"label": "Asian",
					"percentage": 29
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 24840,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "e,m,h",
			"lat": 37.37154,
			"lon": -122.107071,
			"name": "LASD Preschool",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "201 Covington Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/24840-Lasd-Preschool/",
				"reviews": "/california/los-altos/24840-Lasd-Preschool/#Reviews",
				"collegeSuccess": "/california/los-altos/24840-Lasd-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 10,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19974,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.359768,
			"lon": -122.100433,
			"name": "Los Altos Christian Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "625 Magdalena Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19974-Los-Altos-Christian-Pre-School/",
				"reviews": "/california/los-altos/19974-Los-Altos-Christian-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-altos/19974-Los-Altos-Christian-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9277,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.359768,
			"lon": -122.100433,
			"name": "Los Altos Christian Schools",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "625 Magdalena Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/9277-Los-Altos-Christian-Schools/",
				"reviews": "/california/los-altos/9277-Los-Altos-Christian-Schools/#Reviews",
				"collegeSuccess": "/california/los-altos/9277-Los-Altos-Christian-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 174,
			"parentRating": 4,
			"numReviews": 35,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 52
				},
				{
					"label": "Asian",
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5580,
			"districtId": 649,
			"districtName": "Mountain View-Los Altos Union High",
			"districtCity": "Mountain View",
			"levelCode": "h",
			"lat": 37.385628,
			"lon": -122.109444,
			"name": "Los Altos High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "201 Almond Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5580-Los-Altos-High-School/",
				"reviews": "/california/los-altos/5580-Los-Altos-High-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5580-Los-Altos-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "17%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "82%",
				"state_average": "64%"
			},
			"enrollment": 2227,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 17
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 19977,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.379631,
			"lon": -122.112022,
			"name": "Los Altos Mtn View Chldrn's Co",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "97 Hillview Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19977-Los-Altos-Mtn-View-Chldrns-Co/",
				"reviews": "/california/los-altos/19977-Los-Altos-Mtn-View-Chldrns-Co/#Reviews",
				"collegeSuccess": "/california/los-altos/19977-Los-Altos-Mtn-View-Chldrns-Co/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30526,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.371563,
			"lon": -122.107079,
			"name": "Los Altos Parent Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "201 Covington Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/30526-Los-Altos-Parent-Preschool/",
				"reviews": "/california/los-altos/30526-Los-Altos-Parent-Preschool/#Reviews",
				"collegeSuccess": "/california/los-altos/30526-Los-Altos-Parent-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5518,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "e",
			"lat": 37.36105,
			"lon": -122.093628,
			"name": "Loyola Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "770 Berry Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5518-Loyola-Elementary-School/",
				"reviews": "/california/los-altos/5518-Loyola-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5518-Loyola-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 404,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9031,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.361389,
			"lon": -122.080917,
			"name": "Miramonte Christian School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1175 Altamead Drive",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/9031-Miramonte-Christian-School/",
				"reviews": "/california/los-altos/9031-Miramonte-Christian-School/#Reviews",
				"collegeSuccess": "/california/los-altos/9031-Miramonte-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 125,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 30
				},
				{
					"label": "Asian",
					"percentage": 26
				},
				{
					"label": "Hispanic",
					"percentage": 25
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 19971,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.330917,
			"lon": -122.070137,
			"name": "Monarch Christian School-Los Altos",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2420 Foothill Blvd",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19971-Monarch-Christian-School-Los-Altos/",
				"reviews": "/california/los-altos/19971-Monarch-Christian-School-Los-Altos/#Reviews",
				"collegeSuccess": "/california/los-altos/19971-Monarch-Christian-School-Los-Altos/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5443,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.342194,
			"lon": -122.08268,
			"name": "Montclaire Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1160 Saint Joseph Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5443-Montclaire-Elementary-School/",
				"reviews": "/california/los-altos/5443-Montclaire-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5443-Montclaire-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "1%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 428,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 1
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 43
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 19969,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.355656,
			"lon": -122.07859,
			"name": "Montecito Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1468 Grant Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19969-Montecito-Pre-School/",
				"reviews": "/california/los-altos/19969-Montecito-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-altos/19969-Montecito-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5519,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "e",
			"lat": 37.357166,
			"lon": -122.07225,
			"name": "Oak Avenue Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "1501 Oak Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5519-Oak-Avenue-Elementary-School/",
				"reviews": "/california/los-altos/5519-Oak-Avenue-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5519-Oak-Avenue-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 387,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 16682,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.367645,
			"lon": -122.10659,
			"name": "Pinewood Private - Middle Campus",
			"gradeLevels": "3-6",
			"assigned": null,
			"address": {
				"street1": "327 Fremont Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/16682-Pinewood-Private---Middle-Campus/",
				"reviews": "/california/los-altos/16682-Pinewood-Private---Middle-Campus/#Reviews",
				"collegeSuccess": "/california/los-altos/16682-Pinewood-Private---Middle-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 168,
			"parentRating": 4,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15521,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.364998,
			"lon": -122.102478,
			"name": "Pinewood Private-Lower Campus School",
			"gradeLevels": "K-2",
			"assigned": null,
			"address": {
				"street1": "477 Fremont Avenue",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/15521-Pinewood-Private-Lower-Campus-School/",
				"reviews": "/california/los-altos/15521-Pinewood-Private-Lower-Campus-School/#Reviews",
				"collegeSuccess": "/california/los-altos/15521-Pinewood-Private-Lower-Campus-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 122,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28259,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.362534,
			"lon": -122.116203,
			"name": "Saint Nicholas Transitional Kindergarten",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "12816 El Monte Road",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/28259-Saint-Nicholas-Transitional-Kindergarten/",
				"reviews": "/california/los-altos/28259-Saint-Nicholas-Transitional-Kindergarten/#Reviews",
				"collegeSuccess": "/california/los-altos/28259-Saint-Nicholas-Transitional-Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5520,
			"districtId": 637,
			"districtName": "Los Altos Elementary",
			"districtCity": "Los Altos",
			"levelCode": "e",
			"lat": 37.393505,
			"lon": -122.122749,
			"name": "Santa Rita Elementary",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "700 Los Altos Avenue",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/5520-Santa-Rita-Elementary-School/",
				"reviews": "/california/los-altos/5520-Santa-Rita-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/5520-Santa-Rita-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 524,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 32
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 16688,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.399315,
			"lon": -122.113953,
			"name": "School For Independent Learners",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "909 North San Antonio Road",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/16688-School-For-Independent-Learners/",
				"reviews": "/california/los-altos/16688-School-For-Independent-Learners/#Reviews",
				"collegeSuccess": "/california/los-altos/16688-School-For-Independent-Learners/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 13,
			"parentRating": 5,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 85
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 9085,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.362534,
			"lon": -122.116203,
			"name": "St. Nicholas Elementary School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "12816 El Monte Road",
				"street2": "Building 94022",
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/9085-St.-Nicholas-Elementary-School/",
				"reviews": "/california/los-altos/9085-St.-Nicholas-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/9085-St.-Nicholas-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 242,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 73
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9083,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.348202,
			"lon": -122.077858,
			"name": "St. Simon Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1840 Grant Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/9083-St.-Simon-Elementary-School/",
				"reviews": "/california/los-altos/9083-St.-Simon-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-altos/9083-St.-Simon-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 524,
			"parentRating": 4,
			"numReviews": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 68
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 19968,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.36512,
			"lon": -122.080208,
			"name": "Stepping Stones Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "201 Covington Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19968-Stepping-Stones-Pre-School/",
				"reviews": "/california/los-altos/19968-Stepping-Stones-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-altos/19968-Stepping-Stones-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22385,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.394714,
			"lon": -122.112099,
			"name": "Sunshine Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "724 Catalina Way",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/22385-Sunshine-Montessori-School/",
				"reviews": "/california/los-altos/22385-Sunshine-Montessori-School/#Reviews",
				"collegeSuccess": "/california/los-altos/22385-Sunshine-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26370,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.363537,
			"lon": -122.104073,
			"name": "Ventana School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1040 Border Road",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/26370-Ventana-School/",
				"reviews": "/california/los-altos/26370-Ventana-School/#Reviews",
				"collegeSuccess": "/california/los-altos/26370-Ventana-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 152,
			"parentRating": 5,
			"numReviews": 28,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 49
				},
				{
					"label": "White",
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 9539,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.343243,
			"lon": -122.095459,
			"name": "Waldorf School Of The Peninsula",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "11311 Mora Drive",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/9539-Waldorf-School-Of-The-Peninsula/",
				"reviews": "/california/los-altos/9539-Waldorf-School-Of-The-Peninsula/#Reviews",
				"collegeSuccess": "/california/los-altos/9539-Waldorf-School-Of-The-Peninsula/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 331,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 80
				},
				{
					"label": "Asian",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 19975,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.394154,
			"lon": -122.104439,
			"name": "Wonder Years Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "707 Distel Drive",
				"street2": null,
				"zip": "94022",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94022",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/19975-Wonder-Years-Child-Care-Center/",
				"reviews": "/california/los-altos/19975-Wonder-Years-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/los-altos/19975-Wonder-Years-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27199,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.353874,
			"lon": -122.087708,
			"name": "Yikes Tikes! Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1571 Carob Ln.",
				"street2": null,
				"zip": "94024",
				"city": "Los Altos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94024",
			"type": "school",
			"links": {
				"profile": "/california/los-altos/27199-Yikes-Tikes-Preschool/",
				"reviews": "/california/los-altos/27199-Yikes-Tikes-Preschool/#Reviews",
				"collegeSuccess": "/california/los-altos/27199-Yikes-Tikes-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]