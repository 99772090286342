exports.SUNNYVALE_SCHOOLS = [
		{
			"id": 14475,
			"districtId": 635,
			"districtName": "Fremont Union High",
			"districtCity": "Sunnyvale",
			"levelCode": "e,m,h",
			"lat": 37.353874,
			"lon": -122.037697,
			"name": "Adult And Community Education",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "591 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/14475-Adult-And-Community-Education/",
				"reviews": "/california/sunnyvale/14475-Adult-And-Community-Education/#Reviews",
				"collegeSuccess": "/california/sunnyvale/14475-Adult-And-Community-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23145,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345306,
			"lon": -122.056007,
			"name": "Amazing Creations Ps-St Luke",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1025 The Dalles Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23145-Amazing-Creations-Ps-St-Luke/",
				"reviews": "/california/sunnyvale/23145-Amazing-Creations-Ps-St-Luke/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23145-Amazing-Creations-Ps-St-Luke/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26336,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.349735,
			"lon": -122.036873,
			"name": "Amrita Academy",
			"gradeLevels": "5-12",
			"assigned": null,
			"address": {
				"street1": "1367 Los Arboles Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/26336-Amrita-Academy/",
				"reviews": "/california/sunnyvale/26336-Amrita-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/26336-Amrita-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13133,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.346863,
			"lon": -122.003662,
			"name": "Appleseed Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13133-Appleseed-Montessori-School/",
				"reviews": "/california/sunnyvale/13133-Appleseed-Montessori-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13133-Appleseed-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 275,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 26678,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.351761,
			"lon": -122.048035,
			"name": "Appleseed Montessori School - Warner",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1302 Warner Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/26678-Appleseed-Montessori-School---Warner/",
				"reviews": "/california/sunnyvale/26678-Appleseed-Montessori-School---Warner/#Reviews",
				"collegeSuccess": "/california/sunnyvale/26678-Appleseed-Montessori-School---Warner/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 96,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 70
				},
				{
					"label": "Asian",
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Hispanic",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 30239,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.352695,
			"lon": -122.039932,
			"name": "Appletree Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "653 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/30239-Appletree-Montessori-School/",
				"reviews": "/california/sunnyvale/30239-Appletree-Montessori-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/30239-Appletree-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5700,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.387005,
			"lon": -122.024651,
			"name": "Bishop Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "450 North Sunnyvale Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5700-Bishop-Elementary-School/",
				"reviews": "/california/sunnyvale/5700-Bishop-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5700-Bishop-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 475,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 71
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 46
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 20
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5628,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.364506,
			"lon": -122.018478,
			"name": "Braly Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "675 Gail Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5628-Braly-Elementary-School/",
				"reviews": "/california/sunnyvale/5628-Braly-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5628-Braly-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 391,
			"parentRating": 4,
			"numReviews": 24,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 25
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29170,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.369335,
			"lon": -122.023895,
			"name": "California Young World At Elli",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "550 East Olive Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/29170-California-Young-World-At-Elli/",
				"reviews": "/california/sunnyvale/29170-California-Young-World-At-Elli/#Reviews",
				"collegeSuccess": "/california/sunnyvale/29170-California-Young-World-At-Elli/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23150,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.396137,
			"lon": -121.991287,
			"name": "California Young World Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1110 Fairwood Avenue",
				"street2": null,
				"zip": "94089",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94089",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23150-California-Young-World-Center/",
				"reviews": "/california/sunnyvale/23150-California-Young-World-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23150-California-Young-World-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23163,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.352711,
			"lon": -122.039307,
			"name": "Caring Hearts",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "645 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23163-Caring-Hearts/",
				"reviews": "/california/sunnyvale/23163-Caring-Hearts/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23163-Caring-Hearts/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9100,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.380791,
			"lon": -122.050308,
			"name": "Catholic Academy of Sunnyvale",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "195 Leota Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/9100-Catholic-Academy-Of-Sunnyvale/",
				"reviews": "/california/sunnyvale/9100-Catholic-Academy-Of-Sunnyvale/#Reviews",
				"collegeSuccess": "/california/sunnyvale/9100-Catholic-Academy-Of-Sunnyvale/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 137,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"percentage": 26
				},
				{
					"label": "White",
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13099,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.355179,
			"lon": -122.042297,
			"name": "Challenger - Sunnyvale",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1185 Hollenbeck Ave",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13099-Challenger---Sunnyvale/",
				"reviews": "/california/sunnyvale/13099-Challenger---Sunnyvale/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13099-Challenger---Sunnyvale/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 554,
			"parentRating": 2,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31578,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.357944,
			"lon": -122.032936,
			"name": "Champion Kinder International School",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "1055 Sunnyvale Saratoga Road",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/31578-Champion-Kinder-International-School/",
				"reviews": "/california/sunnyvale/31578-Champion-Kinder-International-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/31578-Champion-Kinder-International-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 128,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 83
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 5701,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.366467,
			"lon": -122.056625,
			"name": "Cherry Chase Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1138 Heatherstone Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5701-Cherry-Chase-Elementary-School/",
				"reviews": "/california/sunnyvale/5701-Cherry-Chase-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5701-Cherry-Chase-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 835,
			"parentRating": 3,
			"numReviews": 18,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 60
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5445,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.347538,
			"lon": -122.036156,
			"name": "Chester W. Nimitz Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "545 Cheyenne Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5445-Chester-W.-Nimitz-Elementary-School/",
				"reviews": "/california/sunnyvale/5445-Chester-W.-Nimitz-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5445-Chester-W.-Nimitz-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 628,
			"parentRating": 4,
			"numReviews": 34,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 19
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23170,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.387882,
			"lon": -122.011627,
			"name": "Childrens' Creative Learning C",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "794 East Duane Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23170-Childrens-Creative-Learning-C/",
				"reviews": "/california/sunnyvale/23170-Childrens-Creative-Learning-C/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23170-Childrens-Creative-Learning-C/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22706,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.356209,
			"lon": -122.031853,
			"name": "Choo Choo Train Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1110 Sunnyvale Saratoga Road A1",
				"street2": "Crescent Avenue",
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/22706-Choo-Choo-Train-Pre-School/",
				"reviews": "/california/sunnyvale/22706-Choo-Choo-Train-Pre-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/22706-Choo-Choo-Train-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5702,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "m",
			"lat": 37.394291,
			"lon": -122.020523,
			"name": "Columbia Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "739 Morse Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5702-Columbia-Middle-School/",
				"reviews": "/california/sunnyvale/5702-Columbia-Middle-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5702-Columbia-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "57%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 790,
			"parentRating": 3,
			"numReviews": 21,
			"studentsPerTeacher": 28,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 57
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "American Indian/Alaska Native",
					"rating": 2,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 53
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 14039,
			"districtId": 635,
			"districtName": "Fremont Union High",
			"districtCity": "Sunnyvale",
			"levelCode": "h",
			"lat": 37.352753,
			"lon": -122.037476,
			"name": "Community Day",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "589 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/14039-Community-Day-School/",
				"reviews": "/california/sunnyvale/14039-Community-Day-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/14039-Community-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "50%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 8,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 63
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 13
				}
			],
			"remediationData": {}
		},
		{
			"id": 15557,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.358414,
			"lon": -122.05896,
			"name": "Community First School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1098 West Remington Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/15557-Community-First-School/",
				"reviews": "/california/sunnyvale/15557-Community-First-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/15557-Community-First-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26464,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.359497,
			"lon": -122.05954,
			"name": "Community First School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1098 West Remington Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/26464-Community-First-School/",
				"reviews": "/california/sunnyvale/26464-Community-First-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/26464-Community-First-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5703,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.36676,
			"lon": -122.04335,
			"name": "Cumberland Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "824 Cumberland Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5703-Cumberland-Elementary-School/",
				"reviews": "/california/sunnyvale/5703-Cumberland-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5703-Cumberland-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 806,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 37
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23161,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.349209,
			"lon": -122.0326,
			"name": "Cupertino Co-Op Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 2632",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23161-Cupertino-Co-Op-Nursery-School/",
				"reviews": "/california/sunnyvale/23161-Cupertino-Co-Op-Nursery-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23161-Cupertino-Co-Op-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5430,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "m",
			"lat": 37.339455,
			"lon": -122.059067,
			"name": "Cupertino Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1650 South Bernardo Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5430-Cupertino-Middle-School/",
				"reviews": "/california/sunnyvale/5430-Cupertino-Middle-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5430-Cupertino-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1358,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 9
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 52
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 9
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13151,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.343758,
			"lon": -122.04406,
			"name": "Delor Montessori",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1510 Lewiston Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13151-Delor-Montessori/",
				"reviews": "/california/sunnyvale/13151-Delor-Montessori/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13151-Delor-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23154,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.343758,
			"lon": -122.04406,
			"name": "Early Horizons",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1510 Lewiston Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23154-Early-Horizons/",
				"reviews": "/california/sunnyvale/23154-Early-Horizons/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23154-Early-Horizons/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5704,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.369678,
			"lon": -122.024544,
			"name": "Ellis Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "550 East Olive Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5704-Ellis-Elementary-School/",
				"reviews": "/california/sunnyvale/5704-Ellis-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5704-Ellis-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 787,
			"parentRating": 4,
			"numReviews": 24,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 43
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5705,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.396706,
			"lon": -121.989609,
			"name": "Fairwood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1110 Fairwood Avenue",
				"street2": null,
				"zip": "94089",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94089",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5705-Fairwood-Elementary-School/",
				"reviews": "/california/sunnyvale/5705-Fairwood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5705-Fairwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "27%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 435,
			"parentRating": 4,
			"numReviews": 37,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 27
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 17
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5497,
			"districtId": 635,
			"districtName": "Fremont Union High",
			"districtCity": "Sunnyvale",
			"levelCode": "h",
			"lat": 37.352459,
			"lon": -122.034821,
			"name": "Fremont High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "575 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5497-Fremont-High-School/",
				"reviews": "/california/sunnyvale/5497-Fremont-High-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5497-Fremont-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "31%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "70%",
				"state_average": "64%"
			},
			"enrollment": 2081,
			"parentRating": 4,
			"numReviews": 18,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 31
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17372,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.343948,
			"lon": -122.045113,
			"name": "French American School Of Silicon Valley",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1522 Lewiston Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/17372-French-American-School-Of-Silicon-Valley/",
				"reviews": "/california/sunnyvale/17372-French-American-School-Of-Silicon-Valley/#Reviews",
				"collegeSuccess": "/california/sunnyvale/17372-French-American-School-Of-Silicon-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 121,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10570,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.343948,
			"lon": -122.045113,
			"name": "French American School Of Silicon Valley",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1522 Lewiston Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/10570-French-American-School-Of-Silicon-Valley/",
				"reviews": "/california/sunnyvale/10570-French-American-School-Of-Silicon-Valley/#Reviews",
				"collegeSuccess": "/california/sunnyvale/10570-French-American-School-Of-Silicon-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 168,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 89
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 22718,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.349209,
			"lon": -122.0326,
			"name": "Happy Days Chld Development Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "Po Box 2747",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/22718-Happy-Days-Chld-Development-Center/",
				"reviews": "/california/sunnyvale/22718-Happy-Days-Chld-Development-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/22718-Happy-Days-Chld-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24057,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.36784,
			"lon": -122.026199,
			"name": "Helios School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "597 Central Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/24057-Helios-School/",
				"reviews": "/california/sunnyvale/24057-Helios-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/24057-Helios-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 28,
			"parentRating": 4,
			"numReviews": 30,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 57
				},
				{
					"label": "Two or more races",
					"percentage": 43
				}
			],
			"remediationData": {}
		},
		{
			"id": 23149,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345364,
			"lon": -122.00341,
			"name": "Jamil Islamic DCC-Silicon Valley",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way Bldg C",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23149-Jamil-Islamic-DCC-Silicon-Valley/",
				"reviews": "/california/sunnyvale/23149-Jamil-Islamic-DCC-Silicon-Valley/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23149-Jamil-Islamic-DCC-Silicon-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23164,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.337959,
			"lon": -122.02346,
			"name": "Kids Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "649 East Homestead Road",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23164-Kids-Day-Care/",
				"reviews": "/california/sunnyvale/23164-Kids-Day-Care/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23164-Kids-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18697,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.3409,
			"lon": -122.020531,
			"name": "Kinder Land Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1606 Goldfinch Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/18697-Kinder-Land-Child-Development-Center/",
				"reviews": "/california/sunnyvale/18697-Kinder-Land-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/18697-Kinder-Land-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15786,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.380962,
			"lon": -121.998383,
			"name": "Kindercare",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1155 East Arques Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/15786-Kindercare/",
				"reviews": "/california/sunnyvale/15786-Kindercare/#Reviews",
				"collegeSuccess": "/california/sunnyvale/15786-Kindercare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30137,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.377045,
			"lon": -122.028595,
			"name": "Km2a Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "104 106 S Sunnyvale Ave",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/30137-Km2a-Learning-Center/",
				"reviews": "/california/sunnyvale/30137-Km2a-Learning-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/30137-Km2a-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5706,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.397442,
			"lon": -122.003113,
			"name": "Lakewood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "750 Lakechime Drive",
				"street2": null,
				"zip": "94089",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94089",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5706-Lakewood-Elementary-School/",
				"reviews": "/california/sunnyvale/5706-Lakewood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5706-Lakewood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "55%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 437,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 55
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 35
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 17
				},
				{
					"label": "Asian",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 26354,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.385426,
			"lon": -121.992775,
			"name": "Lamb-O Academy, Incorporated",
			"gradeLevels": "5-12",
			"assigned": null,
			"address": {
				"street1": "542 Lakeside Drive",
				"street2": "Suite 5",
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/26354-Lamb-O-Academy-Incorporated/",
				"reviews": "/california/sunnyvale/26354-Lamb-O-Academy-Incorporated/#Reviews",
				"collegeSuccess": "/california/sunnyvale/26354-Lamb-O-Academy-Incorporated/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12792,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.375515,
			"lon": -122.055267,
			"name": "Little Rascals Child Care Center",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "494 S Bernardo Ave",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/12792-Little-Rascals-Child-Care-Center/",
				"reviews": "/california/sunnyvale/12792-Little-Rascals-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/12792-Little-Rascals-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5450,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.34391,
			"lon": -122.027275,
			"name": "Louis E. Stocklmeir Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "592 Dunholme Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5450-Louis-E.-Stocklmeir-Elementary-School/",
				"reviews": "/california/sunnyvale/5450-Louis-E.-Stocklmeir-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5450-Louis-E.-Stocklmeir-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1106,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 68
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 4
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5639,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "m",
			"lat": 37.348919,
			"lon": -122.008041,
			"name": "Marian A. Peterson Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1380 Rosalia Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5639-Marian-A.-Peterson-Middle-School/",
				"reviews": "/california/sunnyvale/5639-Marian-A.-Peterson-Middle-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5639-Marian-A.-Peterson-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "25%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 908,
			"parentRating": 4,
			"numReviews": 23,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 25
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 42
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 27671,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.354755,
			"lon": -122.060272,
			"name": "Mission San Jose Children's Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1227 Townsend Terrace",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/27671-Mission-San-Jose-Childrens-Dc/",
				"reviews": "/california/sunnyvale/27671-Mission-San-Jose-Childrens-Dc/#Reviews",
				"collegeSuccess": "/california/sunnyvale/27671-Mission-San-Jose-Childrens-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23162,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.344738,
			"lon": -122.027435,
			"name": "Montessori House-Sunnyvale",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "582 Dunholme Waya",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23162-Montessori-House-Sunnyvale/",
				"reviews": "/california/sunnyvale/23162-Montessori-House-Sunnyvale/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23162-Montessori-House-Sunnyvale/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23166,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.351677,
			"lon": -122.042572,
			"name": "Mothers Day Out",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "728 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23166-Mothers-Day-Out/",
				"reviews": "/california/sunnyvale/23166-Mothers-Day-Out/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23166-Mothers-Day-Out/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23153,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.33876,
			"lon": -122.005859,
			"name": "My Dream Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1035 Lorne Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23153-My-Dream-Academy/",
				"reviews": "/california/sunnyvale/23153-My-Dream-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23153-My-Dream-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23167,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.337318,
			"lon": -122.021881,
			"name": "New World Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "730 East Homestead Road",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23167-New-World-Child-Development-Center/",
				"reviews": "/california/sunnyvale/23167-New-World-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23167-New-World-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23160,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.346684,
			"lon": -122.03569,
			"name": "Nimitz School-Age Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "545 E Cheyenne Dr",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23160-Nimitz-School-Age-Child-Development-Center/",
				"reviews": "/california/sunnyvale/23160-Nimitz-School-Age-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23160-Nimitz-School-Age-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25818,
			"districtId": 1279,
			"districtName": "North County Regional Occupational Center Program (ROC P)",
			"districtCity": "Sunnyvale",
			"levelCode": "h",
			"lat": 37.352753,
			"lon": -122.037476,
			"name": "North County Regional Occupational Program",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "589 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/25818-North-County-Regional-Occupational-Program-School/",
				"reviews": "/california/sunnyvale/25818-North-County-Regional-Occupational-Program-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/25818-North-County-Regional-Occupational-Program-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23147,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.404114,
			"lon": -122.029732,
			"name": "Onizuka Air Station FCC",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1080 Innovation Way",
				"street2": null,
				"zip": "94089",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94089",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23147-Onizuka-Air-Station-FCC/",
				"reviews": "/california/sunnyvale/23147-Onizuka-Air-Station-FCC/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23147-Onizuka-Air-Station-FCC/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16680,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.345402,
			"lon": -122.003403,
			"name": "Our Mother of Peace Montessori",
			"gradeLevels": "K-4",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way, Building D-8",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/16680-Our-Mother-Of-Peace-Montessori/",
				"reviews": "/california/sunnyvale/16680-Our-Mother-Of-Peace-Montessori/#Reviews",
				"collegeSuccess": "/california/sunnyvale/16680-Our-Mother-Of-Peace-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5641,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.36211,
			"lon": -122.007965,
			"name": "Ponderosa Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "804 Ponderosa Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5641-Ponderosa-Elementary-School/",
				"reviews": "/california/sunnyvale/5641-Ponderosa-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5641-Ponderosa-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 590,
			"parentRating": 5,
			"numReviews": 20,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 41
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 26
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13152,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.351677,
			"lon": -122.042572,
			"name": "Presbyterian Early Lrnng Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "728 West Fremont Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13152-Presbyterian-Early-Lrnng-Center/",
				"reviews": "/california/sunnyvale/13152-Presbyterian-Early-Lrnng-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13152-Presbyterian-Early-Lrnng-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10633,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.388096,
			"lon": -122.00959,
			"name": "Rainbow Montessori C.D.C.",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "790 East Duane Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/10633-Rainbow-Montessori-C.D.C./",
				"reviews": "/california/sunnyvale/10633-Rainbow-Montessori-C.D.C./#Reviews",
				"collegeSuccess": "/california/sunnyvale/10633-Rainbow-Montessori-C.D.C./#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 898,
			"parentRating": 4,
			"numReviews": 43,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 81
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 13605,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.348598,
			"lon": -122.041901,
			"name": "Resurrection Elementary School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1395 Hollenbeck Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13605-Resurrection-Elementary-School/",
				"reviews": "/california/sunnyvale/13605-Resurrection-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13605-Resurrection-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 221,
			"parentRating": 4,
			"numReviews": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 48
				},
				{
					"label": "Asian",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 5707,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.390369,
			"lon": -122.008331,
			"name": "San Miguel Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "777 San Miguel Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5707-San-Miguel-Elementary-School/",
				"reviews": "/california/sunnyvale/5707-San-Miguel-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5707-San-Miguel-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 403,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 58
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 62
				},
				{
					"label": "Filipino",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13610,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.345402,
			"lon": -122.003403,
			"name": "School of Choice",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13610-School-Of-Choice/",
				"reviews": "/california/sunnyvale/13610-School-Of-Choice/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13610-School-Of-Choice/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15122,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.346863,
			"lon": -122.003662,
			"name": "Silicon Valley Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/15122-Silicon-Valley-Academy/",
				"reviews": "/california/sunnyvale/15122-Silicon-Valley-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/15122-Silicon-Valley-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 144,
			"parentRating": 4,
			"numReviews": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 90
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 26365,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.346863,
			"lon": -122.003662,
			"name": "Silicon Valley Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way",
				"street2": "Building 400",
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/26365-Silicon-Valley-Academy/",
				"reviews": "/california/sunnyvale/26365-Silicon-Valley-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/26365-Silicon-Valley-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 121,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30295,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.346863,
			"lon": -122.003662,
			"name": "Silicon Valley Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1095 Dunford Way #400",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/30295-Silicon-Valley-Academy/",
				"reviews": "/california/sunnyvale/30295-Silicon-Valley-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/30295-Silicon-Valley-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27268,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.392601,
			"lon": -121.998734,
			"name": "Small World Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "878 Lakewood Drive",
				"street2": null,
				"zip": "94089",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94089",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/27268-Small-World-Academy/",
				"reviews": "/california/sunnyvale/27268-Small-World-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/27268-Small-World-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8592,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.349953,
			"lon": -122.05761,
			"name": "South Peninsula Hebrew Day School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1030 Astoria Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/8592-South-Peninsula-Hebrew-Day-School/",
				"reviews": "/california/sunnyvale/8592-South-Peninsula-Hebrew-Day-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/8592-South-Peninsula-Hebrew-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 280,
			"parentRating": 4,
			"numReviews": 11,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 95
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31943,
			"districtId": 1116,
			"districtName": "Santa Clara County Office of Education",
			"districtCity": "San Jose",
			"levelCode": "e,m",
			"lat": 37.393806,
			"lon": -122.019081,
			"name": "Spark Charter",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "739 Morse Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/31943-Spark-Charter/",
				"reviews": "/california/sunnyvale/31943-Spark-Charter/#Reviews",
				"collegeSuccess": "/california/sunnyvale/31943-Spark-Charter/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 247,
			"parentRating": 5,
			"numReviews": 37,
			"studentsPerTeacher": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 42
				},
				{
					"label": "White",
					"percentage": 31
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27889,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.344322,
			"lon": -122.028358,
			"name": "Stocklmeir Child Development Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "592 Dunholme Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/27889-Stocklmeir-Child-Development-Center/",
				"reviews": "/california/sunnyvale/27889-Stocklmeir-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/27889-Stocklmeir-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15371,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.35638,
			"lon": -122.054726,
			"name": "Stratford School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1196 Lime Drive",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/15371-Stratford-School/",
				"reviews": "/california/sunnyvale/15371-Stratford-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/15371-Stratford-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 293,
			"parentRating": 3,
			"numReviews": 24,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 91
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32390,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m",
			"lat": 37.344479,
			"lon": -122.007042,
			"name": "Stratford School - Sunnyvale Raynor Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1500 Partridge Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/32390-Stratford-School---Sunnyvale-Raynor-Middle-School/",
				"reviews": "/california/sunnyvale/32390-Stratford-School---Sunnyvale-Raynor-Middle-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/32390-Stratford-School---Sunnyvale-Raynor-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 2,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27233,
			"districtId": 1116,
			"districtName": "Santa Clara County Office of Education",
			"districtCity": "San Jose",
			"levelCode": "m,h",
			"lat": 37.397255,
			"lon": -122.015495,
			"name": "Summit Public School: Denali",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "539 E Weddell Dr.",
				"street2": null,
				"zip": "94089",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94089",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/27233-Summit-Public-School-Denali/",
				"reviews": "/california/sunnyvale/27233-Summit-Public-School-Denali/#Reviews",
				"collegeSuccess": "/california/sunnyvale/27233-Summit-Public-School-Denali/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 575,
			"parentRating": 4,
			"numReviews": 34,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 30
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9910,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.374088,
			"lon": -122.048485,
			"name": "Sunnyvale Christian",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "445 South Mary Avenue",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/9910-Sunnyvale-Christian/",
				"reviews": "/california/sunnyvale/9910-Sunnyvale-Christian/#Reviews",
				"collegeSuccess": "/california/sunnyvale/9910-Sunnyvale-Christian/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 110,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 32
				},
				{
					"label": "Asian",
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 5708,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "m",
			"lat": 37.361416,
			"lon": -122.052849,
			"name": "Sunnyvale Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1080 Mango Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5708-Sunnyvale-Middle-School/",
				"reviews": "/california/sunnyvale/5708-Sunnyvale-Middle-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5708-Sunnyvale-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "25%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1211,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 25
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 31
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 9
				},
				{
					"label": "Filipino",
					"rating": 7,
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23156,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.344852,
			"lon": -122.008614,
			"name": "Sunnyvale Parent Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1515 Partridge Avenue",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23156-Sunnyvale-Parent-Pre-School/",
				"reviews": "/california/sunnyvale/23156-Sunnyvale-Parent-Pre-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23156-Sunnyvale-Parent-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23168,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.393776,
			"lon": -122.019028,
			"name": "Sunnyvale School District P S",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "739 Morse Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23168-Sunnyvale-School-District-P-S/",
				"reviews": "/california/sunnyvale/23168-Sunnyvale-School-District-P-S/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23168-Sunnyvale-School-District-P-S/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9970,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.386543,
			"lon": -122.011887,
			"name": "The King's Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "562 North Britton Avenue",
				"street2": null,
				"zip": "94085",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94085",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/9970-The-Kings-Academy/",
				"reviews": "/california/sunnyvale/9970-The-Kings-Academy/#Reviews",
				"collegeSuccess": "/california/sunnyvale/9970-The-Kings-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1127,
			"parentRating": 4,
			"numReviews": 22,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 47
				},
				{
					"label": "Asian",
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 27598,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.338787,
			"lon": -122.041512,
			"name": "Top Educational Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1669 Hollenbeck Avenue #234",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/27598-Top-Educational-Child-Care-Center/",
				"reviews": "/california/sunnyvale/27598-Top-Educational-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/27598-Top-Educational-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23157,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.361752,
			"lon": -122.022919,
			"name": "Triumphant Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "686 Foxtail Drive",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/23157-Triumphant-Learning-Center/",
				"reviews": "/california/sunnyvale/23157-Triumphant-Learning-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/23157-Triumphant-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13180,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.371742,
			"lon": -122.029144,
			"name": "Triumphant Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "420 Carroll Street",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/13180-Triumphant-Learning-Center/",
				"reviews": "/california/sunnyvale/13180-Triumphant-Learning-Center/#Reviews",
				"collegeSuccess": "/california/sunnyvale/13180-Triumphant-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 45,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 86
				},
				{
					"label": "Two or more races",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 5709,
			"districtId": 656,
			"districtName": "Sunnyvale",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.381458,
			"lon": -122.048851,
			"name": "Vargas Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1054 Carson Drive",
				"street2": null,
				"zip": "94086",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94086",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5709-Vargas-Elementary-School/",
				"reviews": "/california/sunnyvale/5709-Vargas-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5709-Vargas-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "51%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 484,
			"parentRating": 4,
			"numReviews": 37,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 51
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5451,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.341602,
			"lon": -122.061218,
			"name": "West Valley Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1635 Belleville Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/5451-West-Valley-Elementary-School/",
				"reviews": "/california/sunnyvale/5451-West-Valley-Elementary-School/#Reviews",
				"collegeSuccess": "/california/sunnyvale/5451-West-Valley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 554,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 63
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 27890,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.340935,
			"lon": -122.061386,
			"name": "West Valley Ymca",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1635 Belleville Way",
				"street2": null,
				"zip": "94087",
				"city": "Sunnyvale"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94087",
			"type": "school",
			"links": {
				"profile": "/california/sunnyvale/27890-West-Valley-Ymca/",
				"reviews": "/california/sunnyvale/27890-West-Valley-Ymca/#Reviews",
				"collegeSuccess": "/california/sunnyvale/27890-West-Valley-Ymca/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]