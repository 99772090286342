import React from 'react';
import {Table} from 'reactstrap';

const Listing = (listing) => (
    <tr>
        <td className="align-middle text-nowrap">
            {listing.mlsNumber}
        </td>
        <td className="align-middle text-nowrap">
            {listing.soldDate.substring(0,10)}
        </td>
        <td className="align-middle text-nowrap">
            {listing.geo.address + ", " + listing.geo.city + " " + listing.geo.zipcode}
        </td>
        <td className="align-middle text-nowrap">
            {listing.propertyType}
        </td>
        <td className="align-middle text-nowrap">
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(listing.listPrice)}
        </td>
        <td className="align-middle text-nowrap">
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(listing.closePrice)}
        </td>
    </tr>
  )

const SoldList = ({ listings }) => (
    <Table responsive striped className="mb-0">
        <thead>
            <tr>
                <th className="bt-0">MLS</th>
                <th className="bt-0">成交日期</th>
                <th className="bt-0">地址</th>
                <th className="bt-0">类型</th>
                <th className="bt-0">上市价格</th>
                <th className="bt-0">成交价格</th>
            </tr>
        </thead>
        <tbody>
            {listings.map(listing => (
                <Listing key={listing.name} {...listing} />
            ))}
        </tbody>
    </Table>
)

export { SoldList };
