exports.WOODSIDE_SCHOOLS = [
		{
			"id": 6857,
			"districtId": 783,
			"districtName": "Cabrillo Unified",
			"districtCity": "Half Moon Bay",
			"levelCode": "e",
			"lat": 37.420177,
			"lon": -122.323837,
			"name": "Kings Mountain Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "211 Swett Road",
				"street2": null,
				"zip": "94062",
				"city": "Woodside"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/woodside/6857-Kings-Mountain-Elementary-School/",
				"reviews": "/california/woodside/6857-Kings-Mountain-Elementary-School/#Reviews",
				"collegeSuccess": "/california/woodside/6857-Kings-Mountain-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 57,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 68
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 13148,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.429939,
			"lon": -122.253853,
			"name": "Thumbelina Nursery School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "Po Box 620398",
				"street2": null,
				"zip": "94061",
				"city": "Woodside"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94061",
			"type": "school",
			"links": {
				"profile": "/california/woodside/13148-Thumbelina-Nursery-School/",
				"reviews": "/california/woodside/13148-Thumbelina-Nursery-School/#Reviews",
				"collegeSuccess": "/california/woodside/13148-Thumbelina-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6998,
			"districtId": 802,
			"districtName": "Woodside Elementary",
			"districtCity": "Woodside",
			"levelCode": "e,m",
			"lat": 37.42754,
			"lon": -122.25901,
			"name": "Woodside Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3195 Woodside Road",
				"street2": null,
				"zip": "94062",
				"city": "Woodside"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/woodside/6998-Woodside-Elementary-School/",
				"reviews": "/california/woodside/6998-Woodside-Elementary-School/#Reviews",
				"collegeSuccess": "/california/woodside/6998-Woodside-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 408,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 10,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 9
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 68
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6954,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.44339,
			"lon": -122.23214,
			"name": "Woodside High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "199 Churchill Avenue",
				"street2": null,
				"zip": "94062",
				"city": "Woodside"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/woodside/6954-Woodside-High-School/",
				"reviews": "/california/woodside/6954-Woodside-High-School/#Reviews",
				"collegeSuccess": "/california/woodside/6954-Woodside-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "77%",
				"state_average": "64%"
			},
			"enrollment": 1964,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 8,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 38
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 49
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21512,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.428776,
			"lon": -122.258362,
			"name": "Woodside Parents Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3154 Woodside Road",
				"street2": null,
				"zip": "94062",
				"city": "Woodside"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94062",
			"type": "school",
			"links": {
				"profile": "/california/woodside/21512-Woodside-Parents-Nursery-School/",
				"reviews": "/california/woodside/21512-Woodside-Parents-Nursery-School/#Reviews",
				"collegeSuccess": "/california/woodside/21512-Woodside-Parents-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]