const portola_valley = {
    "geography":{
       "census_release":"ACS 2019 5-year",
       "parents":{
          "county":{
             "full_name":"San Mateo County, CA",
             "short_name":"San Mateo County",
             "sumlevel":"050",
             "land_area":1161957020,
             "full_geoid":"05000US06081",
             "total_population":767423
          },
          "CBSA":{
             "full_name":"San Francisco-Oakland-Berkeley, CA Metro Area",
             "short_name":"San Francisco-Oakland-Berkeley, CA",
             "sumlevel":"310",
             "land_area":6398229497,
             "full_geoid":"31000US41860",
             "total_population":4701332
          },
          "state":{
             "full_name":"California",
             "short_name":"California",
             "sumlevel":"040",
             "land_area":403660088482,
             "full_geoid":"04000US06",
             "total_population":39283497
          },
          "nation":{
             "full_name":"United States",
             "short_name":"United States",
             "sumlevel":"010",
             "land_area":9160971212108,
             "full_geoid":"01000US",
             "total_population":324697795
          }
       },
       "this":{
          "full_name":"Portola Valley, CA",
          "short_name":"Portola Valley",
          "sumlevel":"160",
          "land_area":23563540,
          "full_geoid":"16000US0658380",
          "total_population":4592,
          "sumlevel_name":"Place",
          "short_geoid":"0658380"
       },
       "comparatives":[
          "CBSA",
          "state"
       ],
       "census_release_year":"19",
       "census_release_level":"5"
    },
    "demographics":{
       "age":{
          "distribution_by_category":{
             "metadata":{
                "table_id":"B01001",
                "universe":"Total population",
                "acs_release":"ACS 2019 5-year"
             },
             "percent_under_18":{
                "name":"Under 18",
                "values":{
                   "this":22.87,
                   "CBSA":19.89,
                   "state":22.97
                },
                "error":{
                   "this":4.7,
                   "CBSA":0.08,
                   "state":0.03
                },
                "numerators":{
                   "this":1050.0,
                   "CBSA":934913.0,
                   "state":9022146.0
                },
                "numerator_errors":{
                   "this":215.9,
                   "CBSA":3660.2,
                   "state":12886.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":115.0,
                   "state":100.0
                },
                "error_ratio":{
                   "this":20.6,
                   "CBSA":0.4,
                   "state":0.1
                }
             },
             "percent_18_to_64":{
                "name":"18 to 64",
                "values":{
                   "this":48.67,
                   "CBSA":64.97,
                   "state":63.07
                },
                "error":{
                   "this":6.6,
                   "CBSA":0.14,
                   "state":0.05
                },
                "numerators":{
                   "this":2235.0,
                   "CBSA":3054483.0,
                   "state":24775310.0
                },
                "numerator_errors":{
                   "this":303.0,
                   "CBSA":6616.6,
                   "state":20108.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":75.0,
                   "state":77.0
                },
                "error_ratio":{
                   "this":13.6,
                   "CBSA":0.2,
                   "state":0.1
                }
             },
             "percent_over_65":{
                "name":"65 and over",
                "values":{
                   "this":28.46,
                   "CBSA":15.14,
                   "state":13.97
                },
                "error":{
                   "this":5.12,
                   "CBSA":0.1,
                   "state":0.03
                },
                "numerators":{
                   "this":1307.0,
                   "CBSA":711936.0,
                   "state":5486041.0
                },
                "numerator_errors":{
                   "this":235.1,
                   "CBSA":4598.4,
                   "state":12678.7
                },
                "index":{
                   "this":100.0,
                   "CBSA":188.0,
                   "state":204.0
                },
                "error_ratio":{
                   "this":18.0,
                   "CBSA":0.7,
                   "state":0.2
                }
             }
          },
          "distribution_by_decade":{
             "total":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":8.69,
                      "CBSA":10.98,
                      "state":12.52
                   },
                   "error":{
                      "this":3.04,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":399.0,
                      "CBSA":516235.0,
                      "state":4919754.0
                   },
                   "numerator_errors":{
                      "this":139.6,
                      "CBSA":2590.6,
                      "state":9132.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":79.0,
                      "state":69.0
                   },
                   "error_ratio":{
                      "this":35.0,
                      "CBSA":0.5,
                      "state":0.2
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":17.27,
                      "CBSA":11.01,
                      "state":13.09
                   },
                   "error":{
                      "this":4.13,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":793.0,
                      "CBSA":517483.0,
                      "state":5140633.0
                   },
                   "numerator_errors":{
                      "this":189.7,
                      "CBSA":2602.5,
                      "state":9167.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":157.0,
                      "state":132.0
                   },
                   "error_ratio":{
                      "this":23.9,
                      "CBSA":0.5,
                      "state":0.2
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":2.77,
                      "CBSA":13.76,
                      "state":14.84
                   },
                   "error":{
                      "this":2.0,
                      "CBSA":0.06,
                      "state":0.03
                   },
                   "numerators":{
                      "this":127.0,
                      "CBSA":647077.0,
                      "state":5830059.0
                   },
                   "numerator_errors":{
                      "this":91.9,
                      "CBSA":3018.4,
                      "state":10871.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":20.0,
                      "state":19.0
                   },
                   "error_ratio":{
                      "this":72.2,
                      "CBSA":0.4,
                      "state":0.2
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":5.53,
                      "CBSA":15.72,
                      "state":14.18
                   },
                   "error":{
                      "this":2.63,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":254.0,
                      "CBSA":738889.0,
                      "state":5570748.0
                   },
                   "numerator_errors":{
                      "this":120.9,
                      "CBSA":2958.8,
                      "state":8798.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":35.0,
                      "state":39.0
                   },
                   "error_ratio":{
                      "this":47.6,
                      "CBSA":0.4,
                      "state":0.1
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":11.3,
                      "CBSA":13.98,
                      "state":12.95
                   },
                   "error":{
                      "this":2.93,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":519.0,
                      "CBSA":657446.0,
                      "state":5088145.0
                   },
                   "numerator_errors":{
                      "this":134.4,
                      "CBSA":2972.2,
                      "state":8746.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":81.0,
                      "state":87.0
                   },
                   "error_ratio":{
                      "this":25.9,
                      "CBSA":0.4,
                      "state":0.2
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":18.88,
                      "CBSA":13.41,
                      "state":12.76
                   },
                   "error":{
                      "this":3.55,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":867.0,
                      "CBSA":630235.0,
                      "state":5011016.0
                   },
                   "numerator_errors":{
                      "this":163.1,
                      "CBSA":2628.2,
                      "state":6867.0
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":141.0,
                      "state":148.0
                   },
                   "error_ratio":{
                      "this":18.8,
                      "CBSA":0.4,
                      "state":0.2
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":12.57,
                      "CBSA":11.01,
                      "state":10.34
                   },
                   "error":{
                      "this":3.51,
                      "CBSA":0.09,
                      "state":0.03
                   },
                   "numerators":{
                      "this":577.0,
                      "CBSA":517687.0,
                      "state":4062419.0
                   },
                   "numerator_errors":{
                      "this":161.0,
                      "CBSA":4180.8,
                      "state":11748.6
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":114.0,
                      "state":122.0
                   },
                   "error_ratio":{
                      "this":27.9,
                      "CBSA":0.8,
                      "state":0.3
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":10.3,
                      "CBSA":6.25,
                      "state":5.82
                   },
                   "error":{
                      "this":3.28,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":473.0,
                      "CBSA":293719.0,
                      "state":2285972.0
                   },
                   "numerator_errors":{
                      "this":150.7,
                      "CBSA":2768.5,
                      "state":7838.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":165.0,
                      "state":177.0
                   },
                   "error_ratio":{
                      "this":31.8,
                      "CBSA":1.0,
                      "state":0.3
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":12.7,
                      "CBSA":3.88,
                      "state":3.5
                   },
                   "error":{
                      "this":3.22,
                      "CBSA":0.05,
                      "state":0.02
                   },
                   "numerators":{
                      "this":583.0,
                      "CBSA":182561.0,
                      "state":1374751.0
                   },
                   "numerator_errors":{
                      "this":148.0,
                      "CBSA":2467.2,
                      "state":6707.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":327.0,
                      "state":363.0
                   },
                   "error_ratio":{
                      "this":25.4,
                      "CBSA":1.3,
                      "state":0.6
                   }
                }
             },
             "male":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":8.7,
                      "CBSA":11.35,
                      "state":12.87
                   },
                   "error":{
                      "this":3.96,
                      "CBSA":0.08,
                      "state":0.03
                   },
                   "numerators":{
                      "this":179.0,
                      "CBSA":264024.0,
                      "state":2512581.0
                   },
                   "numerator_errors":{
                      "this":82.5,
                      "CBSA":1745.7,
                      "state":6146.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":77.0,
                      "state":68.0
                   },
                   "error_ratio":{
                      "this":45.5,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":19.0,
                      "CBSA":11.33,
                      "state":13.46
                   },
                   "error":{
                      "this":6.21,
                      "CBSA":0.08,
                      "state":0.03
                   },
                   "numerators":{
                      "this":391.0,
                      "CBSA":263377.0,
                      "state":2628152.0
                   },
                   "numerator_errors":{
                      "this":130.8,
                      "CBSA":1746.6,
                      "state":6239.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":168.0,
                      "state":141.0
                   },
                   "error_ratio":{
                      "this":32.7,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":3.6,
                      "CBSA":14.15,
                      "state":15.42
                   },
                   "error":{
                      "this":3.31,
                      "CBSA":0.09,
                      "state":0.04
                   },
                   "numerators":{
                      "this":74.0,
                      "CBSA":328994.0,
                      "state":3011788.0
                   },
                   "numerator_errors":{
                      "this":68.3,
                      "CBSA":2157.1,
                      "state":8042.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":25.0,
                      "state":23.0
                   },
                   "error_ratio":{
                      "this":91.9,
                      "CBSA":0.6,
                      "state":0.3
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":5.34,
                      "CBSA":16.15,
                      "state":14.57
                   },
                   "error":{
                      "this":3.56,
                      "CBSA":0.09,
                      "state":0.03
                   },
                   "numerators":{
                      "this":110.0,
                      "CBSA":375656.0,
                      "state":2844329.0
                   },
                   "numerator_errors":{
                      "this":73.8,
                      "CBSA":2007.2,
                      "state":6637.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":33.0,
                      "state":37.0
                   },
                   "error_ratio":{
                      "this":66.7,
                      "CBSA":0.6,
                      "state":0.2
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":11.61,
                      "CBSA":14.18,
                      "state":13.02
                   },
                   "error":{
                      "this":4.46,
                      "CBSA":0.09,
                      "state":0.03
                   },
                   "numerators":{
                      "this":239.0,
                      "CBSA":329655.0,
                      "state":2541977.0
                   },
                   "numerator_errors":{
                      "this":93.3,
                      "CBSA":2029.9,
                      "state":6536.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":82.0,
                      "state":89.0
                   },
                   "error_ratio":{
                      "this":38.4,
                      "CBSA":0.6,
                      "state":0.2
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":19.92,
                      "CBSA":13.45,
                      "state":12.65
                   },
                   "error":{
                      "this":5.25,
                      "CBSA":0.08,
                      "state":0.02
                   },
                   "numerators":{
                      "this":410.0,
                      "CBSA":312888.0,
                      "state":2470900.0
                   },
                   "numerator_errors":{
                      "this":111.9,
                      "CBSA":1902.7,
                      "state":4877.9
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":148.0,
                      "state":157.0
                   },
                   "error_ratio":{
                      "this":26.4,
                      "CBSA":0.6,
                      "state":0.2
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":13.75,
                      "CBSA":10.64,
                      "state":9.92
                   },
                   "error":{
                      "this":5.28,
                      "CBSA":0.13,
                      "state":0.04
                   },
                   "numerators":{
                      "this":283.0,
                      "CBSA":247481.0,
                      "state":1937575.0
                   },
                   "numerator_errors":{
                      "this":110.6,
                      "CBSA":2962.3,
                      "state":8018.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":129.0,
                      "state":139.0
                   },
                   "error_ratio":{
                      "this":38.4,
                      "CBSA":1.2,
                      "state":0.4
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":9.09,
                      "CBSA":5.76,
                      "state":5.32
                   },
                   "error":{
                      "this":4.13,
                      "CBSA":0.08,
                      "state":0.03
                   },
                   "numerators":{
                      "this":187.0,
                      "CBSA":133857.0,
                      "state":1038517.0
                   },
                   "numerator_errors":{
                      "this":86.0,
                      "CBSA":1835.1,
                      "state":5256.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":158.0,
                      "state":171.0
                   },
                   "error_ratio":{
                      "this":45.4,
                      "CBSA":1.4,
                      "state":0.6
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":8.99,
                      "CBSA":3.0,
                      "state":2.77
                   },
                   "error":{
                      "this":3.29,
                      "CBSA":0.07,
                      "state":0.02
                   },
                   "numerators":{
                      "this":185.0,
                      "CBSA":69655.0,
                      "state":540479.0
                   },
                   "numerator_errors":{
                      "this":69.1,
                      "CBSA":1594.6,
                      "state":4503.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":300.0,
                      "state":325.0
                   },
                   "error_ratio":{
                      "this":36.6,
                      "CBSA":2.3,
                      "state":0.7
                   }
                }
             },
             "female":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":8.68,
                      "CBSA":10.62,
                      "state":12.18
                   },
                   "error":{
                      "this":4.42,
                      "CBSA":0.08,
                      "state":0.03
                   },
                   "numerators":{
                      "this":220.0,
                      "CBSA":252211.0,
                      "state":2407173.0
                   },
                   "numerator_errors":{
                      "this":112.6,
                      "CBSA":1914.1,
                      "state":6754.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":82.0,
                      "state":71.0
                   },
                   "error_ratio":{
                      "this":50.9,
                      "CBSA":0.8,
                      "state":0.2
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":15.86,
                      "CBSA":10.7,
                      "state":12.72
                   },
                   "error":{
                      "this":5.34,
                      "CBSA":0.08,
                      "state":0.03
                   },
                   "numerators":{
                      "this":402.0,
                      "CBSA":254106.0,
                      "state":2512481.0
                   },
                   "numerator_errors":{
                      "this":137.5,
                      "CBSA":1929.4,
                      "state":6716.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":148.0,
                      "state":125.0
                   },
                   "error_ratio":{
                      "this":33.7,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":2.09,
                      "CBSA":13.39,
                      "state":14.26
                   },
                   "error":{
                      "this":2.42,
                      "CBSA":0.09,
                      "state":0.04
                   },
                   "numerators":{
                      "this":53.0,
                      "CBSA":318083.0,
                      "state":2818271.0
                   },
                   "numerator_errors":{
                      "this":61.4,
                      "CBSA":2111.3,
                      "state":7314.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":16.0,
                      "state":15.0
                   },
                   "error_ratio":{
                      "this":115.8,
                      "CBSA":0.7,
                      "state":0.3
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":5.68,
                      "CBSA":15.29,
                      "state":13.8
                   },
                   "error":{
                      "this":3.76,
                      "CBSA":0.09,
                      "state":0.03
                   },
                   "numerators":{
                      "this":144.0,
                      "CBSA":363233.0,
                      "state":2726419.0
                   },
                   "numerator_errors":{
                      "this":95.8,
                      "CBSA":2173.9,
                      "state":5775.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":37.0,
                      "state":41.0
                   },
                   "error_ratio":{
                      "this":66.2,
                      "CBSA":0.6,
                      "state":0.2
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":11.05,
                      "CBSA":13.8,
                      "state":12.89
                   },
                   "error":{
                      "this":3.76,
                      "CBSA":0.09,
                      "state":0.03
                   },
                   "numerators":{
                      "this":280.0,
                      "CBSA":327791.0,
                      "state":2546168.0
                   },
                   "numerator_errors":{
                      "this":96.7,
                      "CBSA":2171.1,
                      "state":5811.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":80.0,
                      "state":86.0
                   },
                   "error_ratio":{
                      "this":34.0,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":18.03,
                      "CBSA":13.36,
                      "state":12.86
                   },
                   "error":{
                      "this":4.56,
                      "CBSA":0.08,
                      "state":0.02
                   },
                   "numerators":{
                      "this":457.0,
                      "CBSA":317347.0,
                      "state":2540116.0
                   },
                   "numerator_errors":{
                      "this":118.6,
                      "CBSA":1813.0,
                      "state":4833.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":135.0,
                      "state":140.0
                   },
                   "error_ratio":{
                      "this":25.3,
                      "CBSA":0.6,
                      "state":0.2
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":11.6,
                      "CBSA":11.37,
                      "state":10.75
                   },
                   "error":{
                      "this":4.57,
                      "CBSA":0.12,
                      "state":0.04
                   },
                   "numerators":{
                      "this":294.0,
                      "CBSA":270206.0,
                      "state":2124844.0
                   },
                   "numerator_errors":{
                      "this":117.0,
                      "CBSA":2950.1,
                      "state":8586.6
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":102.0,
                      "state":108.0
                   },
                   "error_ratio":{
                      "this":39.4,
                      "CBSA":1.1,
                      "state":0.4
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":11.29,
                      "CBSA":6.73,
                      "state":6.31
                   },
                   "error":{
                      "this":4.84,
                      "CBSA":0.09,
                      "state":0.03
                   },
                   "numerators":{
                      "this":286.0,
                      "CBSA":159862.0,
                      "state":1247455.0
                   },
                   "numerator_errors":{
                      "this":123.7,
                      "CBSA":2073.0,
                      "state":5815.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":168.0,
                      "state":179.0
                   },
                   "error_ratio":{
                      "this":42.9,
                      "CBSA":1.3,
                      "state":0.5
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":15.71,
                      "CBSA":4.75,
                      "state":4.22
                   },
                   "error":{
                      "this":5.08,
                      "CBSA":0.08,
                      "state":0.03
                   },
                   "numerators":{
                      "this":398.0,
                      "CBSA":112906.0,
                      "state":834272.0
                   },
                   "numerator_errors":{
                      "this":130.9,
                      "CBSA":1882.7,
                      "state":4970.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":331.0,
                      "state":372.0
                   },
                   "error_ratio":{
                      "this":32.3,
                      "CBSA":1.7,
                      "state":0.7
                   }
                }
             }
          },
          "median_age":{
             "total":{
                "name":"Median age",
                "values":{
                   "this":52.8,
                   "CBSA":39.0,
                   "state":36.5
                },
                "error":{
                   "this":3.0,
                   "CBSA":0.2,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":135.0,
                   "state":145.0
                },
                "error_ratio":{
                   "this":5.7,
                   "CBSA":0.5,
                   "state":0.3
                }
             },
             "male":{
                "name":"Median age male",
                "values":{
                   "this":51.1,
                   "CBSA":38.0,
                   "state":35.4
                },
                "error":{
                   "this":3.0,
                   "CBSA":0.1,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":134.0,
                   "state":144.0
                },
                "error_ratio":{
                   "this":5.9,
                   "CBSA":0.3,
                   "state":0.3
                }
             },
             "female":{
                "name":"Median age female",
                "values":{
                   "this":54.2,
                   "CBSA":40.0,
                   "state":37.7
                },
                "error":{
                   "this":2.0,
                   "CBSA":0.1,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":135.0,
                   "state":144.0
                },
                "error_ratio":{
                   "this":3.7,
                   "CBSA":0.3,
                   "state":0.3
                }
             }
          }
       },
       "sex":{
          "metadata":{
             "table_id":"B01001",
             "universe":"Total population",
             "acs_release":"ACS 2019 5-year"
          },
          "percent_male":{
             "name":"Male",
             "values":{
                "this":44.82,
                "CBSA":49.47,
                "state":49.71
             },
             "error":{
                "this":3.22,
                "CBSA":0.01,
                "state":0.0
             },
             "numerators":{
                "this":2058.0,
                "CBSA":2325587.0,
                "state":19526298.0
             },
             "numerator_errors":{
                "this":148.0,
                "CBSA":307.0,
                "state":1141.0
             },
             "index":{
                "this":100.0,
                "CBSA":91.0,
                "state":90.0
             },
             "error_ratio":{
                "this":7.2,
                "CBSA":null,
                "state":null
             }
          },
          "percent_female":{
             "name":"Female",
             "values":{
                "this":55.18,
                "CBSA":50.53,
                "state":50.29
             },
             "error":{
                "this":3.26,
                "CBSA":0.01,
                "state":0.0
             },
             "numerators":{
                "this":2534.0,
                "CBSA":2375745.0,
                "state":19757199.0
             },
             "numerator_errors":{
                "this":150.0,
                "CBSA":307.0,
                "state":1141.0
             },
             "index":{
                "this":100.0,
                "CBSA":109.0,
                "state":110.0
             },
             "error_ratio":{
                "this":5.9,
                "CBSA":null,
                "state":null
             }
          }
       },
       "race":{
          "metadata":{
             "table_id":"B03002",
             "universe":"Total population",
             "acs_release":"ACS 2019 5-year"
          },
          "percent_white":{
             "name":"White",
             "values":{
                "this":82.25,
                "CBSA":39.6,
                "state":37.18
             },
             "error":{
                "this":5.67,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":3777.0,
                "CBSA":1861545.0,
                "state":14605312.0
             },
             "numerator_errors":{
                "this":261.0,
                "CBSA":971.0,
                "state":3168.0
             },
             "index":{
                "this":100.0,
                "CBSA":208.0,
                "state":221.0
             },
             "error_ratio":{
                "this":6.9,
                "CBSA":0.1,
                "state":null
             }
          },
          "percent_black":{
             "name":"Black",
             "values":{
                "this":0.37,
                "CBSA":7.08,
                "state":5.52
             },
             "error":{
                "this":0.59,
                "CBSA":0.05,
                "state":0.02
             },
             "numerators":{
                "this":17.0,
                "CBSA":333087.0,
                "state":2169155.0
             },
             "numerator_errors":{
                "this":27.0,
                "CBSA":2460.0,
                "state":6936.0
             },
             "index":{
                "this":100.0,
                "CBSA":5.0,
                "state":7.0
             },
             "error_ratio":{
                "this":159.5,
                "CBSA":0.7,
                "state":0.4
             }
          },
          "percent_native":{
             "name":"Native",
             "values":{
                "this":0.07,
                "CBSA":0.23,
                "state":0.36
             },
             "error":{
                "this":0.17,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":3.0,
                "CBSA":10718.0,
                "state":140831.0
             },
             "numerator_errors":{
                "this":8.0,
                "CBSA":712.0,
                "state":2516.0
             },
             "index":{
                "this":100.0,
                "CBSA":30.0,
                "state":19.0
             },
             "error_ratio":{
                "this":242.9,
                "CBSA":8.7,
                "state":2.8
             }
          },
          "percent_asian":{
             "name":"Asian",
             "values":{
                "this":6.53,
                "CBSA":25.84,
                "state":14.28
             },
             "error":{
                "this":2.98,
                "CBSA":0.07,
                "state":0.02
             },
             "numerators":{
                "this":300.0,
                "CBSA":1214748.0,
                "state":5610931.0
             },
             "numerator_errors":{
                "this":137.0,
                "CBSA":3362.0,
                "state":9199.0
             },
             "index":{
                "this":100.0,
                "CBSA":25.0,
                "state":46.0
             },
             "error_ratio":{
                "this":45.6,
                "CBSA":0.3,
                "state":0.1
             }
          },
          "percent_islander":{
             "name":"Islander",
             "values":{
                "this":0,
                "CBSA":0.68,
                "state":0.36
             },
             "error":{
                "this":0,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":0.0,
                "CBSA":31849.0,
                "state":140788.0
             },
             "numerator_errors":{
                "this":12.0,
                "CBSA":816.0,
                "state":2224.0
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":2.9,
                "state":2.8
             }
          },
          "percent_other":{
             "name":"Other",
             "values":{
                "this":0,
                "CBSA":0.38,
                "state":0.25
             },
             "error":{
                "this":0,
                "CBSA":0.03,
                "state":0.01
             },
             "numerators":{
                "this":0.0,
                "CBSA":18047.0,
                "state":100119.0
             },
             "numerator_errors":{
                "this":12.0,
                "CBSA":1457.0,
                "state":3715.0
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":7.9,
                "state":4.0
             }
          },
          "percent_two_or_more":{
             "name":"Two+",
             "values":{
                "this":4.12,
                "CBSA":4.38,
                "state":3.03
             },
             "error":{
                "this":2.4,
                "CBSA":0.09,
                "state":0.04
             },
             "numerators":{
                "this":189.0,
                "CBSA":205932.0,
                "state":1188673.0
             },
             "numerator_errors":{
                "this":110.0,
                "CBSA":4007.0,
                "state":14463.0
             },
             "index":{
                "this":100.0,
                "CBSA":94.0,
                "state":136.0
             },
             "error_ratio":{
                "this":58.3,
                "CBSA":2.1,
                "state":1.3
             }
          },
          "percent_hispanic":{
             "name":"Hispanic",
             "values":{
                "this":6.66,
                "CBSA":21.81,
                "state":39.02
             },
             "error":{
                "this":4.14,
                "CBSA":0,
                "state":0
             },
             "numerators":{
                "this":306.0,
                "CBSA":1025406.0,
                "state":15327688.0
             },
             "numerator_errors":{
                "this":190.0,
                "CBSA":0.0,
                "state":0.0
             },
             "index":{
                "this":100.0,
                "CBSA":31.0,
                "state":17.0
             },
             "error_ratio":{
                "this":62.2,
                "CBSA":null,
                "state":null
             }
          }
       }
    },
    "economics":{
       "income":{
          "per_capita_income_in_the_last_12_months":{
             "name":"Per capita income",
             "values":{
                "this":124281.0,
                "CBSA":55252.0,
                "state":36955.0
             },
             "error":{
                "this":18965.0,
                "CBSA":296.0,
                "state":129.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B19301",
                "universe":"Total population",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":225.0,
                "state":336.0
             },
             "error_ratio":{
                "this":15.3,
                "CBSA":0.5,
                "state":0.3
             }
          },
          "median_household_income":{
             "name":"Median household income",
             "values":{
                "this":224554.0,
                "CBSA":106025.0,
                "state":75235.0
             },
             "error":{
                "this":58850.0,
                "CBSA":531.0,
                "state":232.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B19013",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":212.0,
                "state":298.0
             },
             "error_ratio":{
                "this":26.2,
                "CBSA":0.5,
                "state":0.3
             }
          },
          "household_distribution":{
             "metadata":{
                "table_id":"B19001",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "under_50":{
                "name":"Under $50K",
                "values":{
                   "this":11.1,
                   "CBSA":24.78,
                   "state":34.35
                },
                "error":{
                   "this":5.89,
                   "CBSA":0.27,
                   "state":0.1
                },
                "numerators":{
                   "this":187.0,
                   "CBSA":422081.0,
                   "state":4481083.0
                },
                "numerator_errors":{
                   "this":100.5,
                   "CBSA":4784.6,
                   "state":15330.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":45.0,
                   "state":32.0
                },
                "error_ratio":{
                   "this":53.1,
                   "CBSA":1.1,
                   "state":0.3
                }
             },
             "50_to_100":{
                "name":"$50K - $100K",
                "values":{
                   "this":14.42,
                   "CBSA":22.69,
                   "state":27.93
                },
                "error":{
                   "this":7.24,
                   "CBSA":0.23,
                   "state":0.09
                },
                "numerators":{
                   "this":243.0,
                   "CBSA":386399.0,
                   "state":3643284.0
                },
                "numerator_errors":{
                   "this":123.7,
                   "CBSA":3975.4,
                   "state":12935.8
                },
                "index":{
                   "this":100.0,
                   "CBSA":64.0,
                   "state":52.0
                },
                "error_ratio":{
                   "this":50.2,
                   "CBSA":1.0,
                   "state":0.3
                }
             },
             "100_to_200":{
                "name":"$100K - $200K",
                "values":{
                   "this":18.69,
                   "CBSA":29.57,
                   "state":25.56
                },
                "error":{
                   "this":6.79,
                   "CBSA":0.26,
                   "state":0.1
                },
                "numerators":{
                   "this":315.0,
                   "CBSA":503605.0,
                   "state":3333848.0
                },
                "numerator_errors":{
                   "this":117.6,
                   "CBSA":4649.1,
                   "state":14018.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":63.0,
                   "state":73.0
                },
                "error_ratio":{
                   "this":36.3,
                   "CBSA":0.9,
                   "state":0.4
                }
             },
             "over_200":{
                "name":"Over $200K",
                "values":{
                   "this":55.79,
                   "CBSA":22.97,
                   "state":12.16
                },
                "error":{
                   "this":7.59,
                   "CBSA":0.22,
                   "state":0.08
                },
                "numerators":{
                   "this":940.0,
                   "CBSA":391190.0,
                   "state":1586051.0
                },
                "numerator_errors":{
                   "this":151.0,
                   "CBSA":3780.0,
                   "state":10523.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":243.0,
                   "state":459.0
                },
                "error_ratio":{
                   "this":13.6,
                   "CBSA":1.0,
                   "state":0.7
                }
             }
          }
       },
       "poverty":{
          "percent_below_poverty_line":{
             "name":"Persons below poverty line",
             "values":{
                "this":1.85,
                "CBSA":9.02,
                "state":13.36
             },
             "error":{
                "this":1.52,
                "CBSA":0.16,
                "state":0.1
             },
             "numerators":{
                "this":84.0,
                "CBSA":418640.0,
                "state":5149742.0
             },
             "numerator_errors":{
                "this":69.0,
                "CBSA":7321.0,
                "state":38666.0
             },
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":21.0,
                "state":14.0
             },
             "error_ratio":{
                "this":82.2,
                "CBSA":1.8,
                "state":0.7
             }
          },
          "children":{
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 5-year"
             },
             "Below":{
                "name":"Poverty",
                "values":{
                   "this":0,
                   "CBSA":10.18,
                   "state":18.13
                },
                "error":{
                   "this":0,
                   "CBSA":0.27,
                   "state":0.12
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":93859.0,
                   "state":1610923.0
                },
                "numerator_errors":{
                   "this":41.6,
                   "CBSA":2557.7,
                   "state":10973.3
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":2.7,
                   "state":0.7
                }
             },
             "above":{
                "name":"Non-poverty",
                "values":{
                   "this":100.0,
                   "CBSA":89.82,
                   "state":81.87
                },
                "error":{
                   "this":31.74,
                   "CBSA":0.7,
                   "state":0.0
                },
                "numerators":{
                   "this":1035.0,
                   "CBSA":828299.0,
                   "state":7275254.0
                },
                "numerator_errors":{
                   "this":230.4,
                   "CBSA":4500.8,
                   "state":15647.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":111.0,
                   "state":122.0
                },
                "error_ratio":{
                   "this":31.7,
                   "CBSA":0.8,
                   "state":null
                }
             }
          },
          "seniors":{
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 5-year"
             },
             "Below":{
                "name":"Poverty",
                "values":{
                   "this":1.33,
                   "CBSA":8.7,
                   "state":10.23
                },
                "error":{
                   "this":2.66,
                   "CBSA":0.24,
                   "state":0.1
                },
                "numerators":{
                   "this":17.0,
                   "CBSA":60893.0,
                   "state":551219.0
                },
                "numerator_errors":{
                   "this":34.1,
                   "CBSA":1659.5,
                   "state":5245.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":15.0,
                   "state":13.0
                },
                "error_ratio":{
                   "this":200.0,
                   "CBSA":2.8,
                   "state":1.0
                }
             },
             "above":{
                "name":"Non-poverty",
                "values":{
                   "this":98.67,
                   "CBSA":91.3,
                   "state":89.77
                },
                "error":{
                   "this":0.4,
                   "CBSA":0.42,
                   "state":0.16
                },
                "numerators":{
                   "this":1259.0,
                   "CBSA":639117.0,
                   "state":4839112.0
                },
                "numerator_errors":{
                   "this":209.0,
                   "CBSA":1861.2,
                   "state":5421.1
                },
                "index":{
                   "this":100.0,
                   "CBSA":108.0,
                   "state":110.0
                },
                "error_ratio":{
                   "this":0.4,
                   "CBSA":0.5,
                   "state":0.2
                }
             }
          }
       },
       "employment":{
          "mean_travel_time":{
             "name":"Mean travel time to work",
             "values":{
                "this":28.28,
                "CBSA":34.26,
                "state":29.79
             },
             "error":{
                "this":2.42,
                "CBSA":0.14,
                "state":0.07
             },
             "numerators":{
                "this":37240.0,
                "CBSA":75895430.0,
                "state":509970415.0
             },
             "numerator_errors":{
                "this":7222.0,
                "CBSA":426975.0,
                "state":1354876.0
             },
             "metadata":{
                "table_id":"B08006, B08013",
                "universe":"Workers 16 years and over who did not work at home",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":83.0,
                "state":95.0
             },
             "error_ratio":{
                "this":8.6,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "transportation_distribution":{
             "metadata":{
                "table_id":"B08006",
                "universe":"Workers 16 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "drove_alone":{
                "name":"Drove alone",
                "values":{
                   "this":67.0,
                   "CBSA":57.56,
                   "state":73.72
                },
                "error":{
                   "this":9.01,
                   "CBSA":0.27,
                   "state":0.04
                },
                "numerators":{
                   "this":1147.0,
                   "CBSA":1365800.0,
                   "state":13411041.0
                },
                "numerator_errors":{
                   "this":197.0,
                   "CBSA":7921.0,
                   "state":17348.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":116.0,
                   "state":91.0
                },
                "error_ratio":{
                   "this":13.4,
                   "CBSA":0.5,
                   "state":0.1
                }
             },
             "carpooled":{
                "name":"Carpooled",
                "values":{
                   "this":1.52,
                   "CBSA":9.53,
                   "state":10.12
                },
                "error":{
                   "this":2.27,
                   "CBSA":0.17,
                   "state":0.09
                },
                "numerators":{
                   "this":26.0,
                   "CBSA":226167.0,
                   "state":1841632.0
                },
                "numerator_errors":{
                   "this":39.0,
                   "CBSA":4187.0,
                   "state":16674.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":16.0,
                   "state":15.0
                },
                "error_ratio":{
                   "this":149.3,
                   "CBSA":1.8,
                   "state":0.9
                }
             },
             "public_transit":{
                "name":"Public transit",
                "values":{
                   "this":0,
                   "CBSA":17.61,
                   "state":5.08
                },
                "error":{
                   "this":0,
                   "CBSA":0.19,
                   "state":0.04
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":417810.0,
                   "state":923834.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":4652.0,
                   "state":7563.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":1.1,
                   "state":0.8
                }
             },
             "Bicycle":{
                "name":"Bicycle",
                "values":{
                   "this":3.91,
                   "CBSA":1.91,
                   "state":0.95
                },
                "error":{
                   "this":2.83,
                   "CBSA":0.07,
                   "state":0.02
                },
                "numerators":{
                   "this":67.0,
                   "CBSA":45243.0,
                   "state":173081.0
                },
                "numerator_errors":{
                   "this":49.0,
                   "CBSA":1647.0,
                   "state":3177.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":205.0,
                   "state":412.0
                },
                "error_ratio":{
                   "this":72.4,
                   "CBSA":3.7,
                   "state":2.1
                }
             },
             "walked":{
                "name":"Walked",
                "values":{
                   "this":2.98,
                   "CBSA":4.69,
                   "state":2.62
                },
                "error":{
                   "this":3.02,
                   "CBSA":0.13,
                   "state":0.03
                },
                "numerators":{
                   "this":51.0,
                   "CBSA":111365.0,
                   "state":476291.0
                },
                "numerator_errors":{
                   "this":52.0,
                   "CBSA":3026.0,
                   "state":5234.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":64.0,
                   "state":114.0
                },
                "error_ratio":{
                   "this":101.3,
                   "CBSA":2.8,
                   "state":1.1
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":1.52,
                   "CBSA":2.06,
                   "state":1.6
                },
                "error":{
                   "this":1.8,
                   "CBSA":0.08,
                   "state":0.02
                },
                "numerators":{
                   "this":26.0,
                   "CBSA":48944.0,
                   "state":291710.0
                },
                "numerator_errors":{
                   "this":31.0,
                   "CBSA":2007.0,
                   "state":4364.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":74.0,
                   "state":95.0
                },
                "error_ratio":{
                   "this":118.4,
                   "CBSA":3.9,
                   "state":1.2
                }
             },
             "worked_at_home":{
                "name":"Worked at home",
                "values":{
                   "this":23.07,
                   "CBSA":6.65,
                   "state":5.9
                },
                "error":{
                   "this":7.67,
                   "CBSA":0.12,
                   "state":0.05
                },
                "numerators":{
                   "this":395.0,
                   "CBSA":157692.0,
                   "state":1073966.0
                },
                "numerator_errors":{
                   "this":138.0,
                   "CBSA":2857.0,
                   "state":8330.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":347.0,
                   "state":391.0
                },
                "error_ratio":{
                   "this":33.2,
                   "CBSA":1.8,
                   "state":0.8
                }
             }
          }
       }
    },
    "families":{
       "marital_status":{
          "metadata":{
             "table_id":"B12001",
             "universe":"Population 15 years and over",
             "acs_release":"ACS 2019 5-year"
          },
          "married":{
             "name":"Married",
             "values":{
                "this":63.03,
                "CBSA":50.25,
                "state":48.86
             },
             "error":{
                "this":4.03,
                "CBSA":0.19,
                "state":0.11
             },
             "numerators":{
                "this":2438.0,
                "CBSA":1970200.0,
                "state":15532639.0
             },
             "numerator_errors":{
                "this":187.1,
                "CBSA":7366.2,
                "state":35143.9
             },
             "index":{
                "this":100.0,
                "CBSA":125.0,
                "state":129.0
             },
             "error_ratio":{
                "this":6.4,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "single":{
             "name":"Single",
             "values":{
                "this":36.97,
                "CBSA":49.75,
                "state":51.14
             },
             "error":{
                "this":6.24,
                "CBSA":0.2,
                "state":0.1
             },
             "numerators":{
                "this":1430.0,
                "CBSA":1950940.0,
                "state":16255641.0
             },
             "numerator_errors":{
                "this":248.8,
                "CBSA":7807.4,
                "state":32012.1
             },
             "index":{
                "this":100.0,
                "CBSA":74.0,
                "state":72.0
             },
             "error_ratio":{
                "this":16.9,
                "CBSA":0.4,
                "state":0.2
             }
          }
       },
       "marital_status_grouped":{
          "metadata":{
             "table_id":"B12001",
             "universe":"Population 15 years and over",
             "acs_release":"ACS 2019 5-year"
          },
          "never_married":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Never married"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":21.39,
                   "CBSA":39.35,
                   "state":40.42
                },
                "error":{
                   "this":7.22,
                   "CBSA":0.23,
                   "state":0.14
                },
                "numerators":{
                   "this":362.0,
                   "CBSA":758354.0,
                   "state":6343459.0
                },
                "numerator_errors":{
                   "this":127.0,
                   "CBSA":4434.0,
                   "state":22441.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":54.0,
                   "state":53.0
                },
                "error_ratio":{
                   "this":33.8,
                   "CBSA":0.6,
                   "state":0.3
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":19.12,
                   "CBSA":33.31,
                   "state":33.49
                },
                "error":{
                   "this":5.77,
                   "CBSA":0.23,
                   "state":0.11
                },
                "numerators":{
                   "this":416.0,
                   "CBSA":664257.0,
                   "state":5389443.0
                },
                "numerator_errors":{
                   "this":129.0,
                   "CBSA":4586.0,
                   "state":17597.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":57.0,
                   "state":57.0
                },
                "error_ratio":{
                   "this":30.2,
                   "CBSA":0.7,
                   "state":0.3
                }
             }
          },
          "married":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Now married"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":71.87,
                   "CBSA":51.69,
                   "state":49.8
                },
                "error":{
                   "this":5.21,
                   "CBSA":0.28,
                   "state":0.17
                },
                "numerators":{
                   "this":1216.0,
                   "CBSA":996209.0,
                   "state":7816684.0
                },
                "numerator_errors":{
                   "this":146.0,
                   "CBSA":5387.0,
                   "state":27451.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":139.0,
                   "state":144.0
                },
                "error_ratio":{
                   "this":7.2,
                   "CBSA":0.5,
                   "state":0.3
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":56.16,
                   "CBSA":48.85,
                   "state":47.95
                },
                "error":{
                   "this":3.59,
                   "CBSA":0.25,
                   "state":0.14
                },
                "numerators":{
                   "this":1222.0,
                   "CBSA":973991.0,
                   "state":7715955.0
                },
                "numerator_errors":{
                   "this":117.0,
                   "CBSA":5024.0,
                   "state":21944.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":115.0,
                   "state":117.0
                },
                "error_ratio":{
                   "this":6.4,
                   "CBSA":0.5,
                   "state":0.3
                }
             }
          },
          "divorced":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Divorced"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":4.49,
                   "CBSA":7.02,
                   "state":7.65
                },
                "error":{
                   "this":3.58,
                   "CBSA":0.13,
                   "state":0.06
                },
                "numerators":{
                   "this":76.0,
                   "CBSA":135241.0,
                   "state":1200982.0
                },
                "numerator_errors":{
                   "this":61.0,
                   "CBSA":2521.0,
                   "state":9397.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":64.0,
                   "state":59.0
                },
                "error_ratio":{
                   "this":79.7,
                   "CBSA":1.9,
                   "state":0.8
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":7.4,
                   "CBSA":10.43,
                   "state":10.85
                },
                "error":{
                   "this":3.73,
                   "CBSA":0.14,
                   "state":0.05
                },
                "numerators":{
                   "this":161.0,
                   "CBSA":207906.0,
                   "state":1746282.0
                },
                "numerator_errors":{
                   "this":82.0,
                   "CBSA":2835.0,
                   "state":8613.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":71.0,
                   "state":68.0
                },
                "error_ratio":{
                   "this":50.4,
                   "CBSA":1.3,
                   "state":0.5
                }
             }
          },
          "widowed":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Widowed"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":2.25,
                   "CBSA":1.94,
                   "state":2.13
                },
                "error":{
                   "this":2.47,
                   "CBSA":0.06,
                   "state":0.02
                },
                "numerators":{
                   "this":38.0,
                   "CBSA":37321.0,
                   "state":334237.0
                },
                "numerator_errors":{
                   "this":42.0,
                   "CBSA":1155.0,
                   "state":3638.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":116.0,
                   "state":106.0
                },
                "error_ratio":{
                   "this":109.8,
                   "CBSA":3.1,
                   "state":0.9
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":17.33,
                   "CBSA":7.42,
                   "state":7.71
                },
                "error":{
                   "this":5.85,
                   "CBSA":0.11,
                   "state":0.04
                },
                "numerators":{
                   "this":377.0,
                   "CBSA":147861.0,
                   "state":1241238.0
                },
                "numerator_errors":{
                   "this":130.0,
                   "CBSA":2130.0,
                   "state":5983.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":234.0,
                   "state":225.0
                },
                "error_ratio":{
                   "this":33.8,
                   "CBSA":1.5,
                   "state":0.5
                }
             }
          }
       },
       "family_types":{
          "children":{
             "metadata":{
                "table_id":"B09002",
                "universe":"Own children under 18 years",
                "acs_release":"ACS 2019 5-year"
             },
             "married_couple":{
                "name":"Married couple",
                "values":{
                   "this":86.57,
                   "CBSA":78.76,
                   "state":72.23
                },
                "error":{
                   "this":8.53,
                   "CBSA":0.42,
                   "state":0.27
                },
                "numerators":{
                   "this":793.0,
                   "CBSA":652636.0,
                   "state":5567122.0
                },
                "numerator_errors":{
                   "this":172.0,
                   "CBSA":4069.0,
                   "state":22183.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":110.0,
                   "state":120.0
                },
                "error_ratio":{
                   "this":9.9,
                   "CBSA":0.5,
                   "state":0.4
                }
             },
             "male_householder":{
                "name":"Male householder",
                "values":{
                   "this":5.68,
                   "CBSA":5.35,
                   "state":7.67
                },
                "error":{
                   "this":8.99,
                   "CBSA":0.26,
                   "state":0.11
                },
                "numerators":{
                   "this":52.0,
                   "CBSA":44369.0,
                   "state":591232.0
                },
                "numerator_errors":{
                   "this":83.0,
                   "CBSA":2181.0,
                   "state":8769.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":106.0,
                   "state":74.0
                },
                "error_ratio":{
                   "this":158.3,
                   "CBSA":4.9,
                   "state":1.4
                }
             },
             "female_householder":{
                "name":"Female householder",
                "values":{
                   "this":7.75,
                   "CBSA":15.88,
                   "state":20.1
                },
                "error":{
                   "this":11.91,
                   "CBSA":0.36,
                   "state":0.21
                },
                "numerators":{
                   "this":71.0,
                   "CBSA":131584.0,
                   "state":1549424.0
                },
                "numerator_errors":{
                   "this":110.0,
                   "CBSA":3049.0,
                   "state":16177.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":49.0,
                   "state":39.0
                },
                "error_ratio":{
                   "this":153.7,
                   "CBSA":2.3,
                   "state":1.0
                }
             }
          }
       },
       "fertility":{
          "total":{
             "name":"Women 15-50 who gave birth during past year",
             "values":{
                "this":0,
                "CBSA":4.44,
                "state":4.88
             },
             "error":{
                "this":0,
                "CBSA":0.13,
                "state":0.06
             },
             "numerators":{
                "this":0.0,
                "CBSA":51849.0,
                "state":469884.0
             },
             "numerator_errors":{
                "this":12.0,
                "CBSA":1524.0,
                "state":5597.0
             },
             "metadata":{
                "table_id":"B13016",
                "universe":"Women 15 to 50 years",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":2.9,
                "state":1.2
             }
          },
          "by_age":{
             "metadata":{
                "table_id":"B13016",
                "universe":"Women 15 to 50 years",
                "acs_release":"ACS 2019 5-year"
             },
             "15_to_19":{
                "name":"15-19",
                "values":{
                   "this":0,
                   "CBSA":0.34,
                   "state":1.01
                },
                "error":{
                   "this":0,
                   "CBSA":0.11,
                   "state":0.07
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":422.0,
                   "state":12727.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":135.0,
                   "state":844.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":32.4,
                   "state":6.9
                }
             },
             "20_to_24":{
                "name":"20-24",
                "values":{
                   "this":0,
                   "CBSA":3.03,
                   "state":4.98
                },
                "error":{
                   "this":0,
                   "CBSA":0.38,
                   "state":0.17
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":4063.0,
                   "state":66276.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":516.0,
                   "state":2263.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":12.5,
                   "state":3.4
                }
             },
             "25_to_29":{
                "name":"25-29",
                "values":{
                   "this":0,
                   "CBSA":5.17,
                   "state":7.77
                },
                "error":{
                   "this":0,
                   "CBSA":0.37,
                   "state":0.16
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":9502.0,
                   "state":115600.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":690.0,
                   "state":2442.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":7.2,
                   "state":2.1
                }
             },
             "30_to_34":{
                "name":"30-35",
                "values":{
                   "this":0,
                   "CBSA":9.21,
                   "state":9.88
                },
                "error":{
                   "this":0,
                   "CBSA":0.44,
                   "state":0.2
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":17478.0,
                   "state":138843.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":847.0,
                   "state":2879.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":4.8,
                   "state":2.0
                }
             },
             "35_to_39":{
                "name":"35-39",
                "values":{
                   "this":0,
                   "CBSA":7.97,
                   "state":7.09
                },
                "error":{
                   "this":0,
                   "CBSA":0.47,
                   "state":0.16
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":13817.0,
                   "state":93691.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":835.0,
                   "state":2137.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":5.9,
                   "state":2.3
                }
             },
             "40_to_44":{
                "name":"40-44",
                "values":{
                   "this":0,
                   "CBSA":2.95,
                   "state":2.43
                },
                "error":{
                   "this":0,
                   "CBSA":0.28,
                   "state":0.1
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":4846.0,
                   "state":30695.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":463.0,
                   "state":1315.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":9.5,
                   "state":4.1
                }
             },
             "45_to_50":{
                "name":"45-50",
                "values":{
                   "this":0,
                   "CBSA":0.87,
                   "state":0.77
                },
                "error":{
                   "this":0,
                   "CBSA":0.12,
                   "state":0.06
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":1721.0,
                   "state":12052.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":239.0,
                   "state":861.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":13.8,
                   "state":7.8
                }
             }
          }
       },
       "households":{
          "number_of_households":{
             "name":"Number of households",
             "values":{
                "this":1685.0,
                "CBSA":1703275.0,
                "state":13044266.0
             },
             "error":{
                "this":144.0,
                "CBSA":3959.0,
                "state":20333.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B11001",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":8.5,
                "CBSA":0.2,
                "state":0.2
             }
          },
          "persons_per_household":{
             "name":"Persons per household",
             "values":{
                "this":2.7,
                "CBSA":2.71,
                "state":2.95
             },
             "error":{
                "this":0.23,
                "CBSA":0.01,
                "state":0.0
             },
             "numerators":{
                "this":4546.0,
                "CBSA":4621986.0,
                "state":38462235.0
             },
             "numerator_errors":{
                "this":52.0,
                "CBSA":2408.0,
                "state":0.0
             },
             "metadata":{
                "table_id":"B11001,b11002",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":100.0,
                "state":92.0
             },
             "error_ratio":{
                "this":8.5,
                "CBSA":0.4,
                "state":null
             }
          },
          "distribution":{
             "metadata":{
                "table_id":"B11002",
                "universe":"People in Households",
                "acs_release":"ACS 2019 5-year"
             },
             "married_couples":{
                "name":"Married couples",
                "values":{
                   "this":72.61,
                   "CBSA":61.62,
                   "state":61.04
                },
                "error":{
                   "this":9.22,
                   "CBSA":0.31,
                   "state":0.21
                },
                "numerators":{
                   "this":3301.0,
                   "CBSA":2848170.0,
                   "state":23478016.0
                },
                "numerator_errors":{
                   "this":421.0,
                   "CBSA":14203.0,
                   "state":81110.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":118.0,
                   "state":119.0
                },
                "error_ratio":{
                   "this":12.7,
                   "CBSA":0.5,
                   "state":0.3
                }
             },
             "male_householder":{
                "name":"Male householder",
                "values":{
                   "this":4.51,
                   "CBSA":5.86,
                   "state":7.59
                },
                "error":{
                   "this":5.15,
                   "CBSA":0.16,
                   "state":0.09
                },
                "numerators":{
                   "this":205.0,
                   "CBSA":270774.0,
                   "state":2919408.0
                },
                "numerator_errors":{
                   "this":234.0,
                   "CBSA":7230.0,
                   "state":33493.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":77.0,
                   "state":59.0
                },
                "error_ratio":{
                   "this":114.2,
                   "CBSA":2.7,
                   "state":1.2
                }
             },
             "female_householder":{
                "name":"Female householder",
                "values":{
                   "this":10.51,
                   "CBSA":13.44,
                   "state":16.57
                },
                "error":{
                   "this":7.96,
                   "CBSA":0.23,
                   "state":0.12
                },
                "numerators":{
                   "this":478.0,
                   "CBSA":621104.0,
                   "state":6373007.0
                },
                "numerator_errors":{
                   "this":362.0,
                   "CBSA":10609.0,
                   "state":46993.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":78.0,
                   "state":63.0
                },
                "error_ratio":{
                   "this":75.7,
                   "CBSA":1.7,
                   "state":0.7
                }
             },
             "nonfamily":{
                "name":"Non-family",
                "values":{
                   "this":12.36,
                   "CBSA":19.08,
                   "state":14.8
                },
                "error":{
                   "this":3.65,
                   "CBSA":0.17,
                   "state":0.07
                },
                "numerators":{
                   "this":562.0,
                   "CBSA":881938.0,
                   "state":5691804.0
                },
                "numerator_errors":{
                   "this":166.0,
                   "CBSA":7855.0,
                   "state":27482.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":65.0,
                   "state":84.0
                },
                "error_ratio":{
                   "this":29.5,
                   "CBSA":0.9,
                   "state":0.5
                }
             }
          }
       }
    },
    "housing":{
       "units":{
          "number":{
             "name":"Number of housing units",
             "values":{
                "this":1826.0,
                "CBSA":1810484.0,
                "state":14175976.0
             },
             "error":{
                "this":148.0,
                "CBSA":1013.0,
                "state":1184.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B25002",
                "universe":"Housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":8.1,
                "CBSA":0.1,
                "state":null
             }
          },
          "occupancy_distribution":{
             "metadata":{
                "table_id":"B25002",
                "universe":"Housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "occupied":{
                "name":"Occupied",
                "values":{
                   "this":92.28,
                   "CBSA":94.08,
                   "state":92.02
                },
                "error":{
                   "this":2.5,
                   "CBSA":0.21,
                   "state":0.14
                },
                "numerators":{
                   "this":1685.0,
                   "CBSA":1703275.0,
                   "state":13044266.0
                },
                "numerator_errors":{
                   "this":144.0,
                   "CBSA":3959.0,
                   "state":20333.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":98.0,
                   "state":100.0
                },
                "error_ratio":{
                   "this":2.7,
                   "CBSA":0.2,
                   "state":0.2
                }
             },
             "vacant":{
                "name":"Vacant",
                "values":{
                   "this":7.72,
                   "CBSA":5.92,
                   "state":7.98
                },
                "error":{
                   "this":4.94,
                   "CBSA":0.22,
                   "state":0.15
                },
                "numerators":{
                   "this":141.0,
                   "CBSA":107209.0,
                   "state":1131710.0
                },
                "numerator_errors":{
                   "this":91.0,
                   "CBSA":3933.0,
                   "state":20951.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":130.0,
                   "state":97.0
                },
                "error_ratio":{
                   "this":64.0,
                   "CBSA":3.7,
                   "state":1.9
                }
             }
          },
          "structure_distribution":{
             "metadata":{
                "table_id":"B25024",
                "universe":"Housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "single_unit":{
                "name":"Single unit",
                "values":{
                   "this":89.59,
                   "CBSA":58.77,
                   "state":64.78
                },
                "error":{
                   "this":3.13,
                   "CBSA":0.22,
                   "state":0.09
                },
                "numerators":{
                   "this":1636.0,
                   "CBSA":1064031.0,
                   "state":9183849.0
                },
                "numerator_errors":{
                   "this":144.4,
                   "CBSA":3984.4,
                   "state":13345.6
                },
                "index":{
                   "this":100.0,
                   "CBSA":152.0,
                   "state":138.0
                },
                "error_ratio":{
                   "this":3.5,
                   "CBSA":0.4,
                   "state":0.1
                }
             },
             "multi_unit":{
                "name":"Multi-unit",
                "values":{
                   "this":10.41,
                   "CBSA":40.07,
                   "state":31.43
                },
                "error":{
                   "this":4.87,
                   "CBSA":0.3,
                   "state":0.11
                },
                "numerators":{
                   "this":190.0,
                   "CBSA":725513.0,
                   "state":4455831.0
                },
                "numerator_errors":{
                   "this":90.2,
                   "CBSA":5384.7,
                   "state":15037.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":26.0,
                   "state":33.0
                },
                "error_ratio":{
                   "this":46.8,
                   "CBSA":0.7,
                   "state":0.3
                }
             },
             "mobile_home":{
                "name":"Mobile home",
                "values":{
                   "this":0,
                   "CBSA":1.07,
                   "state":3.68
                },
                "error":{
                   "this":0,
                   "CBSA":0.05,
                   "state":0.03
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":19396.0,
                   "state":521135.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":908.0,
                   "state":4740.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":4.7,
                   "state":0.8
                }
             },
             "vehicle":{
                "name":"Boat, RV, van, etc.",
                "values":{
                   "this":0,
                   "CBSA":0.09,
                   "state":0.11
                },
                "error":{
                   "this":0,
                   "CBSA":0.02,
                   "state":0.01
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":1544.0,
                   "state":15161.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":274.0,
                   "state":862.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":22.2,
                   "state":9.1
                }
             }
          }
       },
       "ownership":{
          "distribution":{
             "metadata":{
                "table_id":"B25003",
                "universe":"Occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "owner":{
                "name":"Owner occupied",
                "values":{
                   "this":77.39,
                   "CBSA":54.66,
                   "state":54.85
                },
                "error":{
                   "this":7.93,
                   "CBSA":0.3,
                   "state":0.28
                },
                "numerators":{
                   "this":1304.0,
                   "CBSA":931032.0,
                   "state":7154580.0
                },
                "numerator_errors":{
                   "this":174.0,
                   "CBSA":5500.0,
                   "state":37872.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":142.0,
                   "state":141.0
                },
                "error_ratio":{
                   "this":10.2,
                   "CBSA":0.5,
                   "state":0.5
                }
             },
             "renter":{
                "name":"Renter occupied",
                "values":{
                   "this":22.61,
                   "CBSA":45.34,
                   "state":45.15
                },
                "error":{
                   "this":6.79,
                   "CBSA":0.23,
                   "state":0.15
                },
                "numerators":{
                   "this":381.0,
                   "CBSA":772243.0,
                   "state":5889686.0
                },
                "numerator_errors":{
                   "this":119.0,
                   "CBSA":4238.0,
                   "state":21311.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":50.0,
                   "state":50.0
                },
                "error_ratio":{
                   "this":30.0,
                   "CBSA":0.5,
                   "state":0.3
                }
             }
          },
          "median_value":{
             "name":"Median value of owner-occupied housing units",
             "values":{
                "this":2000001.0,
                "CBSA":840600.0,
                "state":505000.0
             },
             "error":{
                "this":0,
                "CBSA":3101.0,
                "state":1147.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B25077",
                "universe":"Owner-occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":238.0,
                "state":396.0
             },
             "error_ratio":{
                "this":null,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "value_distribution":{
             "metadata":{
                "table_id":"B25075",
                "universe":"Owner-occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "under_100":{
                "name":"Under $100K",
                "values":{
                   "this":1.15,
                   "CBSA":2.16,
                   "state":5.03
                },
                "error":{
                   "this":3.76,
                   "CBSA":0.1,
                   "state":0.05
                },
                "numerators":{
                   "this":15.0,
                   "CBSA":20156.0,
                   "state":359961.0
                },
                "numerator_errors":{
                   "this":49.0,
                   "CBSA":908.4,
                   "state":3940.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":53.0,
                   "state":23.0
                },
                "error_ratio":{
                   "this":327.0,
                   "CBSA":4.6,
                   "state":1.0
                }
             },
             "100_to_200":{
                "name":"$100K - $200K",
                "values":{
                   "this":0,
                   "CBSA":1.61,
                   "state":6.63
                },
                "error":{
                   "this":0,
                   "CBSA":0.09,
                   "state":0.06
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":15036.0,
                   "state":474527.0
                },
                "numerator_errors":{
                   "this":24.0,
                   "CBSA":818.1,
                   "state":4768.2
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":5.6,
                   "state":0.9
                }
             },
             "200_to_300":{
                "name":"$200K - $300K",
                "values":{
                   "this":1.23,
                   "CBSA":2.81,
                   "state":11.06
                },
                "error":{
                   "this":2.19,
                   "CBSA":0.11,
                   "state":0.06
                },
                "numerators":{
                   "this":16.0,
                   "CBSA":26184.0,
                   "state":791435.0
                },
                "numerator_errors":{
                   "this":28.6,
                   "CBSA":1029.9,
                   "state":6253.7
                },
                "index":{
                   "this":100.0,
                   "CBSA":44.0,
                   "state":11.0
                },
                "error_ratio":{
                   "this":178.0,
                   "CBSA":3.9,
                   "state":0.5
                }
             },
             "300_to_400":{
                "name":"$300K - $400K",
                "values":{
                   "this":1.3,
                   "CBSA":5.66,
                   "state":13.6
                },
                "error":{
                   "this":2.06,
                   "CBSA":0.14,
                   "state":0.09
                },
                "numerators":{
                   "this":17.0,
                   "CBSA":52681.0,
                   "state":973196.0
                },
                "numerator_errors":{
                   "this":27.0,
                   "CBSA":1347.0,
                   "state":7992.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":23.0,
                   "state":10.0
                },
                "error_ratio":{
                   "this":158.5,
                   "CBSA":2.5,
                   "state":0.7
                }
             },
             "400_to_500":{
                "name":"$400K - $500K",
                "values":{
                   "this":0,
                   "CBSA":7.56,
                   "state":13.21
                },
                "error":{
                   "this":0,
                   "CBSA":0.19,
                   "state":0.08
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":70432.0,
                   "state":945276.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":1773.0,
                   "state":7694.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":2.5,
                   "state":0.6
                }
             },
             "500_to_1000000":{
                "name":"$500K - $1M",
                "values":{
                   "this":3.83,
                   "CBSA":43.94,
                   "state":35.28
                },
                "error":{
                   "this":3.61,
                   "CBSA":0.29,
                   "state":0.26
                },
                "numerators":{
                   "this":50.0,
                   "CBSA":409063.0,
                   "state":2523951.0
                },
                "numerator_errors":{
                   "this":47.5,
                   "CBSA":3594.1,
                   "state":12891.8
                },
                "index":{
                   "this":100.0,
                   "CBSA":9.0,
                   "state":11.0
                },
                "error_ratio":{
                   "this":94.3,
                   "CBSA":0.7,
                   "state":0.7
                }
             },
             "over_1000000":{
                "name":"Over $1M",
                "values":{
                   "this":3.07,
                   "CBSA":19.26,
                   "state":7.93
                },
                "error":{
                   "this":3.04,
                   "CBSA":0.23,
                   "state":0.04
                },
                "numerators":{
                   "this":40.0,
                   "CBSA":179279.0,
                   "state":567462.0
                },
                "numerator_errors":{
                   "this":40.0,
                   "CBSA":2390.0,
                   "state":4302.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":16.0,
                   "state":39.0
                },
                "error_ratio":{
                   "this":99.0,
                   "CBSA":1.2,
                   "state":0.5
                }
             }
          },
          "total_value":{
             "name":"Total value of owner-occupied housing units",
             "values":{
                "this":1304.0,
                "CBSA":931032.0,
                "state":7154580.0
             },
             "error":{
                "this":174.0,
                "CBSA":5500.0,
                "state":37872.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "index":{
                "this":100.0,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":13.3,
                "CBSA":0.6,
                "state":0.5
             }
          }
       },
       "length_of_tenure":{
          "metadata":{
             "table_id":"B25026",
             "universe":"Total population in occupied housing units",
             "acs_release":"ACS 2019 5-year"
          },
          "Before_1990":{
             "name":"Before 1990",
             "values":{
                "this":28.68,
                "CBSA":11.62,
                "state":9.72
             },
             "error":{
                "this":9.75,
                "CBSA":0.17,
                "state":0.06
             },
             "numerators":{
                "this":1304.0,
                "CBSA":536918.0,
                "state":3739275.0
             },
             "numerator_errors":{
                "this":443.6,
                "CBSA":7928.2,
                "state":21932.9
             },
             "index":{
                "this":100.0,
                "CBSA":247.0,
                "state":295.0
             },
             "error_ratio":{
                "this":34.0,
                "CBSA":1.5,
                "state":0.6
             }
          },
          "1990s":{
             "name":"1990s",
             "values":{
                "this":14.34,
                "CBSA":12.28,
                "state":12.25
             },
             "error":{
                "this":6.43,
                "CBSA":0.19,
                "state":0.08
             },
             "numerators":{
                "this":652.0,
                "CBSA":567596.0,
                "state":4711644.0
             },
             "numerator_errors":{
                "this":292.5,
                "CBSA":8970.0,
                "state":30793.4
             },
             "index":{
                "this":100.0,
                "CBSA":117.0,
                "state":117.0
             },
             "error_ratio":{
                "this":44.8,
                "CBSA":1.5,
                "state":0.7
             }
          },
          "2000s":{
             "name":"2000s",
             "values":{
                "this":22.64,
                "CBSA":25.39,
                "state":25.7
             },
             "error":{
                "this":7.36,
                "CBSA":0.28,
                "state":0.13
             },
             "numerators":{
                "this":1029.0,
                "CBSA":1173309.0,
                "state":9884339.0
             },
             "numerator_errors":{
                "this":334.7,
                "CBSA":12859.2,
                "state":50780.8
             },
             "index":{
                "this":100.0,
                "CBSA":89.0,
                "state":88.0
             },
             "error_ratio":{
                "this":32.5,
                "CBSA":1.1,
                "state":0.5
             }
          },
          "2010_to_2014":{
             "name":"2010-2014",
             "values":{
                "this":19.62,
                "CBSA":28.34,
                "state":28.78
             },
             "error":{
                "this":6.81,
                "CBSA":0.31,
                "state":0.16
             },
             "numerators":{
                "this":892.0,
                "CBSA":1309646.0,
                "state":11067761.0
             },
             "numerator_errors":{
                "this":309.6,
                "CBSA":14501.5,
                "state":60057.7
             },
             "index":{
                "this":100.0,
                "CBSA":69.0,
                "state":68.0
             },
             "error_ratio":{
                "this":34.7,
                "CBSA":1.1,
                "state":0.6
             }
          },
          "2015_to_2016":{
             "name":"2015-2016",
             "values":{
                "this":4.73,
                "CBSA":13.43,
                "state":14.48
             },
             "error":{
                "this":4.13,
                "CBSA":0.24,
                "state":0.11
             },
             "numerators":{
                "this":215.0,
                "CBSA":620882.0,
                "state":5568721.0
             },
             "numerator_errors":{
                "this":187.7,
                "CBSA":11050.1,
                "state":43280.9
             },
             "index":{
                "this":100.0,
                "CBSA":35.0,
                "state":33.0
             },
             "error_ratio":{
                "this":87.3,
                "CBSA":1.8,
                "state":0.8
             }
          },
          "since_2017":{
             "name":"Since 2017",
             "values":{
                "this":9.99,
                "CBSA":8.95,
                "state":9.08
             },
             "error":{
                "this":6.93,
                "CBSA":0.16,
                "state":0.09
             },
             "numerators":{
                "this":454.0,
                "CBSA":413635.0,
                "state":3490495.0
             },
             "numerator_errors":{
                "this":314.9,
                "CBSA":7594.6,
                "state":33565.8
             },
             "index":{
                "this":100.0,
                "CBSA":112.0,
                "state":110.0
             },
             "error_ratio":{
                "this":69.4,
                "CBSA":1.8,
                "state":1.0
             }
          }
       },
       "migration":{
          "moved_since_previous_year":{
             "name":"Moved since previous year",
             "values":{
                "this":9.15,
                "CBSA":12.92,
                "state":12.86
             },
             "error":{
                "this":6.19,
                "CBSA":0.17,
                "state":0.08
             },
             "numerators":{
                "this":420.0,
                "CBSA":601348.0,
                "state":4993305.0
             },
             "numerator_errors":{
                "this":284.3,
                "CBSA":7904.4,
                "state":29689.5
             },
             "metadata":{
                "table_id":"B07003",
                "universe":"Population 1 year and over in the United States",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":71.0,
                "state":71.0
             },
             "error_ratio":{
                "this":67.7,
                "CBSA":1.3,
                "state":0.6
             }
          }
       },
       "migration_distribution":{
          "metadata":{
             "table_id":"B07003",
             "universe":"Population 1 year and over in the United States",
             "acs_release":"ACS 2019 5-year"
          },
          "same_house_year_ago":{
             "name":"Same house year ago",
             "values":{
                "this":90.85,
                "CBSA":87.08,
                "state":87.14
             },
             "error":{
                "this":6.08,
                "CBSA":0.18,
                "state":0.07
             },
             "numerators":{
                "this":4172.0,
                "CBSA":4051437.0,
                "state":33839689.0
             },
             "numerator_errors":{
                "this":280.0,
                "CBSA":8662.0,
                "state":27490.0
             },
             "index":{
                "this":100.0,
                "CBSA":104.0,
                "state":104.0
             },
             "error_ratio":{
                "this":6.7,
                "CBSA":0.2,
                "state":0.1
             }
          },
          "moved_same_county":{
             "name":"From same county",
             "values":{
                "this":5.57,
                "CBSA":6.33,
                "state":8.01
             },
             "error":{
                "this":5.42,
                "CBSA":0.12,
                "state":0.07
             },
             "numerators":{
                "this":256.0,
                "CBSA":294732.0,
                "state":3108823.0
             },
             "numerator_errors":{
                "this":249.0,
                "CBSA":5689.0,
                "state":25604.0
             },
             "index":{
                "this":100.0,
                "CBSA":88.0,
                "state":70.0
             },
             "error_ratio":{
                "this":97.3,
                "CBSA":1.9,
                "state":0.9
             }
          },
          "moved_different_county":{
             "name":"From different county",
             "values":{
                "this":2.09,
                "CBSA":3.92,
                "state":2.75
             },
             "error":{
                "this":2.68,
                "CBSA":0.09,
                "state":0.03
             },
             "numerators":{
                "this":96.0,
                "CBSA":182221.0,
                "state":1069429.0
             },
             "numerator_errors":{
                "this":123.0,
                "CBSA":3976.0,
                "state":11456.0
             },
             "index":{
                "this":100.0,
                "CBSA":53.0,
                "state":76.0
             },
             "error_ratio":{
                "this":128.2,
                "CBSA":2.3,
                "state":1.1
             }
          },
          "moved_different_state":{
             "name":"From different state",
             "values":{
                "this":0.85,
                "CBSA":1.5,
                "state":1.3
             },
             "error":{
                "this":0.91,
                "CBSA":0.06,
                "state":0.02
             },
             "numerators":{
                "this":39.0,
                "CBSA":69611.0,
                "state":503560.0
             },
             "numerator_errors":{
                "this":42.0,
                "CBSA":2727.0,
                "state":8001.0
             },
             "index":{
                "this":100.0,
                "CBSA":57.0,
                "state":65.0
             },
             "error_ratio":{
                "this":107.1,
                "CBSA":4.0,
                "state":1.5
             }
          },
          "moved_from_abroad":{
             "name":"From abroad",
             "values":{
                "this":0.63,
                "CBSA":1.18,
                "state":0.8
             },
             "error":{
                "this":0.96,
                "CBSA":0.06,
                "state":0.01
             },
             "numerators":{
                "this":29.0,
                "CBSA":54784.0,
                "state":311493.0
             },
             "numerator_errors":{
                "this":44.0,
                "CBSA":2621.0,
                "state":5536.0
             },
             "index":{
                "this":100.0,
                "CBSA":53.0,
                "state":79.0
             },
             "error_ratio":{
                "this":152.4,
                "CBSA":5.1,
                "state":1.2
             }
          }
       }
    },
    "social":{
       "educational_attainment":{
          "percent_high_school_grad_or_higher":{
             "name":"High school grad or higher",
             "values":{
                "this":97.4,
                "CBSA":89.14,
                "state":83.31
             },
             "error":{
                "this":9.29,
                "CBSA":0.33,
                "state":0.15
             },
             "numerators":{
                "this":3225.0,
                "CBSA":3028069.0,
                "state":22052868.0
             },
             "numerator_errors":{
                "this":367.8,
                "CBSA":11285.7,
                "state":39734.1
             },
             "metadata":{
                "table_id":"B15002",
                "universe":"Population 25 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":109.0,
                "state":117.0
             },
             "error_ratio":{
                "this":9.5,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "percent_bachelor_degree_or_higher":{
             "name":"Bachelor's degree or higher",
             "values":{
                "this":77.08,
                "CBSA":49.68,
                "state":33.93
             },
             "error":{
                "this":8.64,
                "CBSA":0.22,
                "state":0.09
             },
             "numerators":{
                "this":2552.0,
                "CBSA":1687542.0,
                "state":8980726.0
             },
             "numerator_errors":{
                "this":327.7,
                "CBSA":7543.2,
                "state":23065.1
             },
             "metadata":{
                "table_id":"B15002",
                "universe":"Population 25 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":155.0,
                "state":227.0
             },
             "error_ratio":{
                "this":11.2,
                "CBSA":0.4,
                "state":0.3
             }
          }
       },
       "educational_attainment_distribution":{
          "metadata":{
             "table_id":"B15002",
             "universe":"Population 25 years and over",
             "acs_release":"ACS 2019 5-year"
          },
          "non_high_school_grad":{
             "name":"No degree",
             "values":{
                "this":2.6,
                "CBSA":10.86,
                "state":16.69
             },
             "error":{
                "this":2.19,
                "CBSA":0.14,
                "state":0.07
             },
             "numerators":{
                "this":86.0,
                "CBSA":368794.0,
                "state":4418675.0
             },
             "numerator_errors":{
                "this":72.8,
                "CBSA":4809.6,
                "state":18934.1
             },
             "index":{
                "this":100.0,
                "CBSA":24.0,
                "state":16.0
             },
             "error_ratio":{
                "this":84.2,
                "CBSA":1.3,
                "state":0.4
             }
          },
          "high_school_grad":{
             "name":"High school",
             "values":{
                "this":6.58,
                "CBSA":15.53,
                "state":20.49
             },
             "error":{
                "this":2.72,
                "CBSA":0.16,
                "state":0.08
             },
             "numerators":{
                "this":218.0,
                "CBSA":527527.0,
                "state":5423462.0
             },
             "numerator_errors":{
                "this":91.2,
                "CBSA":5290.9,
                "state":22237.1
             },
             "index":{
                "this":100.0,
                "CBSA":42.0,
                "state":32.0
             },
             "error_ratio":{
                "this":41.3,
                "CBSA":1.0,
                "state":0.4
             }
          },
          "some_college":{
             "name":"Some college",
             "values":{
                "this":13.74,
                "CBSA":23.93,
                "state":28.89
             },
             "error":{
                "this":4.14,
                "CBSA":0.19,
                "state":0.09
             },
             "numerators":{
                "this":455.0,
                "CBSA":813000.0,
                "state":7648680.0
             },
             "numerator_errors":{
                "this":140.0,
                "CBSA":6517.2,
                "state":23501.3
             },
             "index":{
                "this":100.0,
                "CBSA":57.0,
                "state":48.0
             },
             "error_ratio":{
                "this":30.1,
                "CBSA":0.8,
                "state":0.3
             }
          },
          "Bachelor_degree":{
             "name":"Bachelor's",
             "values":{
                "this":25.79,
                "CBSA":29.25,
                "state":21.17
             },
             "error":{
                "this":6.06,
                "CBSA":0.17,
                "state":0.07
             },
             "numerators":{
                "this":854.0,
                "CBSA":993618.0,
                "state":5603047.0
             },
             "numerator_errors":{
                "this":207.6,
                "CBSA":5612.3,
                "state":17216.6
             },
             "index":{
                "this":100.0,
                "CBSA":88.0,
                "state":122.0
             },
             "error_ratio":{
                "this":23.5,
                "CBSA":0.6,
                "state":0.3
             }
          },
          "post_grad_degree":{
             "name":"Post-grad",
             "values":{
                "this":51.28,
                "CBSA":20.43,
                "state":12.76
             },
             "error":{
                "this":6.96,
                "CBSA":0.15,
                "state":0.06
             },
             "numerators":{
                "this":1698.0,
                "CBSA":693924.0,
                "state":3377679.0
             },
             "numerator_errors":{
                "this":253.6,
                "CBSA":5040.0,
                "state":15348.8
             },
             "index":{
                "this":100.0,
                "CBSA":251.0,
                "state":402.0
             },
             "error_ratio":{
                "this":13.6,
                "CBSA":0.7,
                "state":0.5
             }
          }
       },
       "place_of_birth":{
          "percent_foreign_born":{
             "name":"Foreign-born population",
             "values":{
                "this":13.98,
                "CBSA":30.7,
                "state":26.85
             },
             "error":{
                "this":4.64,
                "CBSA":0.18,
                "state":0.09
             },
             "numerators":{
                "this":642.0,
                "CBSA":1443541.0,
                "state":10547210.0
             },
             "numerator_errors":{
                "this":213.0,
                "CBSA":8570.0,
                "state":36592.0
             },
             "metadata":{
                "table_id":"B05002",
                "universe":"Total population",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":46.0,
                "state":52.0
             },
             "error_ratio":{
                "this":33.2,
                "CBSA":0.6,
                "state":0.3
             }
          },
          "distribution":{
             "metadata":{
                "table_id":"B05006",
                "universe":"Foreign-born population",
                "acs_release":"ACS 2019 5-year"
             },
             "europe":{
                "name":"Europe",
                "values":{
                   "this":49.53,
                   "CBSA":9.42,
                   "state":6.42
                },
                "error":{
                   "this":12.36,
                   "CBSA":0.19,
                   "state":0.07
                },
                "numerators":{
                   "this":318.0,
                   "CBSA":135944.0,
                   "state":677261.0
                },
                "numerator_errors":{
                   "this":132.0,
                   "CBSA":2807.0,
                   "state":7344.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":526.0,
                   "state":771.0
                },
                "error_ratio":{
                   "this":25.0,
                   "CBSA":2.0,
                   "state":1.1
                }
             },
             "asia":{
                "name":"Asia",
                "values":{
                   "this":34.42,
                   "CBSA":57.07,
                   "state":39.42
                },
                "error":{
                   "this":15.58,
                   "CBSA":0.46,
                   "state":0.09
                },
                "numerators":{
                   "this":221.0,
                   "CBSA":823767.0,
                   "state":4157181.0
                },
                "numerator_errors":{
                   "this":124.0,
                   "CBSA":4566.0,
                   "state":17026.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":60.0,
                   "state":87.0
                },
                "error_ratio":{
                   "this":45.3,
                   "CBSA":0.8,
                   "state":0.2
                }
             },
             "africa":{
                "name":"Africa",
                "values":{
                   "this":0,
                   "CBSA":2.41,
                   "state":1.84
                },
                "error":{
                   "this":0,
                   "CBSA":0.12,
                   "state":0.05
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":34802.0,
                   "state":193567.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":1747.0,
                   "state":4869.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":5.0,
                   "state":2.7
                }
             },
             "oceania":{
                "name":"Oceania",
                "values":{
                   "this":0,
                   "CBSA":1.66,
                   "state":0.82
                },
                "error":{
                   "this":0,
                   "CBSA":0.11,
                   "state":0.03
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":23950.0,
                   "state":86276.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":1560.0,
                   "state":2767.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":6.6,
                   "state":3.7
                }
             },
             "latin_america":{
                "name":"Latin America",
                "values":{
                   "this":8.72,
                   "CBSA":27.95,
                   "state":50.28
                },
                "error":{
                   "this":9.21,
                   "CBSA":0.31,
                   "state":0.15
                },
                "numerators":{
                   "this":56.0,
                   "CBSA":403440.0,
                   "state":5302761.0
                },
                "numerator_errors":{
                   "this":62.0,
                   "CBSA":5038.0,
                   "state":24213.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":31.0,
                   "state":17.0
                },
                "error_ratio":{
                   "this":105.6,
                   "CBSA":1.1,
                   "state":0.3
                }
             },
             "north_america":{
                "name":"North America",
                "values":{
                   "this":7.32,
                   "CBSA":1.5,
                   "state":1.23
                },
                "error":{
                   "this":8.22,
                   "CBSA":0.07,
                   "state":0.03
                },
                "numerators":{
                   "this":47.0,
                   "CBSA":21628.0,
                   "state":130093.0
                },
                "numerator_errors":{
                   "this":55.0,
                   "CBSA":1082.0,
                   "state":3038.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":488.0,
                   "state":595.0
                },
                "error_ratio":{
                   "this":112.3,
                   "CBSA":4.7,
                   "state":2.4
                }
             }
          }
       },
       "language":{
          "percent_non_english_at_home":{
             "name":"Persons with language other than English spoken at home",
             "values":{
                "this":null,
                "CBSA":null,
                "state":44.23
             },
             "error":{
                "this":null,
                "CBSA":null,
                "state":0.14
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":16292017.0
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":52781.5
             },
             "metadata":{
                "table_id":"B16001",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":null,
                "state":0.3
             }
          },
          "children":{
             "metadata":{
                "table_id":"B16007",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "english":{
                "name":"English only",
                "values":{
                   "this":90.17,
                   "CBSA":61.01,
                   "state":56.46
                },
                "error":{
                   "this":10.28,
                   "CBSA":0.59,
                   "state":0.24
                },
                "numerators":{
                   "this":853.0,
                   "CBSA":412994.0,
                   "state":3709800.0
                },
                "numerator_errors":{
                   "this":177.0,
                   "CBSA":3979.0,
                   "state":15797.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":148.0,
                   "state":160.0
                },
                "error_ratio":{
                   "this":11.4,
                   "CBSA":1.0,
                   "state":0.4
                }
             },
             "spanish":{
                "name":"Spanish",
                "values":{
                   "this":1.59,
                   "CBSA":21.04,
                   "state":33.98
                },
                "error":{
                   "this":2.52,
                   "CBSA":0.4,
                   "state":0.2
                },
                "numerators":{
                   "this":15.0,
                   "CBSA":142403.0,
                   "state":2232947.0
                },
                "numerator_errors":{
                   "this":24.0,
                   "CBSA":2700.0,
                   "state":13243.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":8.0,
                   "state":5.0
                },
                "error_ratio":{
                   "this":158.5,
                   "CBSA":1.9,
                   "state":0.6
                }
             },
             "indoeuropean":{
                "name":"Indo-European",
                "values":{
                   "this":8.25,
                   "CBSA":5.49,
                   "state":2.92
                },
                "error":{
                   "this":8.44,
                   "CBSA":0.28,
                   "state":0.07
                },
                "numerators":{
                   "this":78.0,
                   "CBSA":37137.0,
                   "state":191982.0
                },
                "numerator_errors":{
                   "this":81.0,
                   "CBSA":1876.0,
                   "state":4345.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":150.0,
                   "state":283.0
                },
                "error_ratio":{
                   "this":102.3,
                   "CBSA":5.1,
                   "state":2.4
                }
             },
             "asian_islander":{
                "name":"Asian/Islander",
                "values":{
                   "this":0,
                   "CBSA":11.16,
                   "state":5.73
                },
                "error":{
                   "this":0,
                   "CBSA":0.29,
                   "state":0.06
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":75535.0,
                   "state":376241.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":1954.0,
                   "state":4139.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":2.6,
                   "state":1.0
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":0,
                   "CBSA":1.32,
                   "state":0.91
                },
                "error":{
                   "this":0,
                   "CBSA":0.12,
                   "state":0.04
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":8906.0,
                   "state":59648.0
                },
                "numerator_errors":{
                   "this":12.0,
                   "CBSA":809.0,
                   "state":2823.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":9.1,
                   "state":4.4
                }
             }
          },
          "adults":{
             "metadata":{
                "table_id":"B16007",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "english":{
                "name":"English only",
                "values":{
                   "this":86.59,
                   "CBSA":58.17,
                   "state":55.62
                },
                "error":{
                   "this":4.59,
                   "CBSA":0.19,
                   "state":0.11
                },
                "numerators":{
                   "this":3067.0,
                   "CBSA":2191040.0,
                   "state":16830152.0
                },
                "numerator_errors":{
                   "this":289.2,
                   "CBSA":7329.1,
                   "state":34792.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":149.0,
                   "state":156.0
                },
                "error_ratio":{
                   "this":5.3,
                   "CBSA":0.3,
                   "state":0.2
                }
             },
             "spanish":{
                "name":"Spanish",
                "values":{
                   "this":2.54,
                   "CBSA":14.93,
                   "state":27.58
                },
                "error":{
                   "this":2.05,
                   "CBSA":0.12,
                   "state":0.08
                },
                "numerators":{
                   "this":90.0,
                   "CBSA":562235.0,
                   "state":8345569.0
                },
                "numerator_errors":{
                   "this":72.8,
                   "CBSA":4401.6,
                   "state":23054.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":17.0,
                   "state":9.0
                },
                "error_ratio":{
                   "this":80.7,
                   "CBSA":0.8,
                   "state":0.3
                }
             },
             "indoeuropean":{
                "name":"Indo-European",
                "values":{
                   "this":8.16,
                   "CBSA":7.18,
                   "state":4.85
                },
                "error":{
                   "this":4.0,
                   "CBSA":0.13,
                   "state":0.04
                },
                "numerators":{
                   "this":289.0,
                   "CBSA":270336.0,
                   "state":1468932.0
                },
                "numerator_errors":{
                   "this":143.6,
                   "CBSA":4894.0,
                   "state":12705.6
                },
                "index":{
                   "this":100.0,
                   "CBSA":114.0,
                   "state":168.0
                },
                "error_ratio":{
                   "this":49.0,
                   "CBSA":1.8,
                   "state":0.8
                }
             },
             "asian_islander":{
                "name":"Asian/Islander",
                "values":{
                   "this":2.71,
                   "CBSA":18.48,
                   "state":10.88
                },
                "error":{
                   "this":2.07,
                   "CBSA":0.13,
                   "state":0.03
                },
                "numerators":{
                   "this":96.0,
                   "CBSA":695965.0,
                   "state":3293073.0
                },
                "numerator_errors":{
                   "this":73.8,
                   "CBSA":4916.4,
                   "state":10307.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":15.0,
                   "state":25.0
                },
                "error_ratio":{
                   "this":76.4,
                   "CBSA":0.7,
                   "state":0.3
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":0,
                   "CBSA":1.24,
                   "state":1.07
                },
                "error":{
                   "this":0,
                   "CBSA":0.05,
                   "state":0.02
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":46843.0,
                   "state":323625.0
                },
                "numerator_errors":{
                   "this":17.0,
                   "CBSA":2045.7,
                   "state":7069.6
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":4.0,
                   "state":1.9
                }
             }
          }
       },
       "veterans":{
          "wartime_service":{
             "metadata":{
                "table_id":"B21002",
                "universe":"Civilian veterans 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "wwii":{
                "name":"WWII",
                "values":{
                   "this":29.0,
                   "CBSA":8551.0,
                   "state":66574.0
                },
                "error":{
                   "this":32.03,
                   "CBSA":587.6,
                   "state":1628.6
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":110.4,
                   "CBSA":6.9,
                   "state":2.4
                }
             },
             "korea":{
                "name":"Korea",
                "values":{
                   "this":38.0,
                   "CBSA":15732.0,
                   "state":145536.0
                },
                "error":{
                   "this":38.9,
                   "CBSA":775.97,
                   "state":2399.66
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":102.4,
                   "CBSA":4.9,
                   "state":1.6
                }
             },
             "vietnam":{
                "name":"Vietnam",
                "values":{
                   "this":115.0,
                   "CBSA":55782.0,
                   "state":561083.0
                },
                "error":{
                   "this":77.79,
                   "CBSA":1426.49,
                   "state":4350.77
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":67.6,
                   "CBSA":2.6,
                   "state":0.8
                }
             },
             "gulf_1990s":{
                "name":"Gulf (1990s)",
                "values":{
                   "this":25.0,
                   "CBSA":22897.0,
                   "state":284290.0
                },
                "error":{
                   "this":34.83,
                   "CBSA":1005.17,
                   "state":3865.66
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":139.3,
                   "CBSA":4.4,
                   "state":1.4
                }
             },
             "gulf_2001":{
                "name":"Gulf (2001-)",
                "values":{
                   "this":30.0,
                   "CBSA":22022.0,
                   "state":309021.0
                },
                "error":{
                   "this":37.0,
                   "CBSA":1007.87,
                   "state":4425.46
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":123.3,
                   "CBSA":4.6,
                   "state":1.4
                }
             }
          },
          "sex":{
             "male":{
                "name":"Male",
                "values":{
                   "this":260.0,
                   "CBSA":143204.0,
                   "state":1446385.0
                },
                "error":{
                   "this":88.0,
                   "CBSA":2400.0,
                   "state":8117.0
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B21001",
                   "universe":"Civilian population 18 years and over",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":33.8,
                   "CBSA":1.7,
                   "state":0.6
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":0.0,
                   "CBSA":11528.0,
                   "state":128146.0
                },
                "error":{
                   "this":12.0,
                   "CBSA":751.0,
                   "state":2994.0
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B21001",
                   "universe":"Civilian population 18 years and over",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":6.5,
                   "state":2.3
                }
             }
          },
          "number":{
             "name":"Total veterans",
             "values":{
                "this":260.0,
                "CBSA":154732.0,
                "state":1574531.0
             },
             "error":{
                "this":88.0,
                "CBSA":2583.0,
                "state":8842.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B21001",
                "universe":"Civilian population 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":33.8,
                "CBSA":1.7,
                "state":0.6
             }
          },
          "percentage":{
             "name":"Population with veteran status",
             "values":{
                "this":7.34,
                "CBSA":4.11,
                "state":5.23
             },
             "error":{
                "this":2.46,
                "CBSA":0.07,
                "state":0.03
             },
             "numerators":{
                "this":260.0,
                "CBSA":154732.0,
                "state":1574531.0
             },
             "numerator_errors":{
                "this":88.0,
                "CBSA":2583.0,
                "state":8842.0
             },
             "metadata":{
                "table_id":"B21001",
                "universe":"Civilian population 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":179.0,
                "state":140.0
             },
             "error_ratio":{
                "this":33.5,
                "CBSA":1.7,
                "state":0.6
             }
          }
       }
    },
    "geo_metadata":{
       "aland":23563540,
       "awater":3741,
       "display_name":"Portola Valley, CA",
       "full_geoid":"16000US0658380",
       "population":4594,
       "simple_name":"Portola Valley",
       "sumlevel":"160",
       "square_miles":9.1,
       "population_density":504.7
    }
 }

 export default portola_valley