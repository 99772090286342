exports.PLEASANTON_SCHOOLS = [{'address': {'city': 'Pleasanton',
                                   'street1': '1454 Santa Rita Road',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 644,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 8,
                                          'rating': 4},
                                         {'label': 'All students', 'rating': 9},
                                         {'label': 'Asian', 'percentage': 40, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 11,
                                          'rating': 4},
                                         {'label': 'White', 'percentage': 39, 'rating': 9},
                                         {'label': 'Two or more races',
                                          'percentage': 6,
                                          'rating': 10},
                                         {'label': 'Filipino', 'percentage': 2},
                                         {'label': 'African American', 'percentage': 1}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 278,
                       'lat': 37.672367,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/278-Alisal-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/278-Alisal-Elementary-School/',
                           'reviews': '/california/pleasanton/278-Alisal-Elementary-School/#Reviews'},
                       'lon': -121.87207,
                       'name': 'Alisal Elementary School',
                       'numReviews': 51,
                       'parentRating': 4,
                       'percentLowIncome': '8%',
                       'pinned': null,
                       'rating': 7,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 24,
                       'subratings': {'Academic Progress Rating': 7,
                                      'Equity Overview Rating': 3,
                                      'Test Scores Rating': 9},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '1155 Santa Rita Road',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {'school_value': '88%',
                                                 'state_average': '64%'},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 2713,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 7,
                                          'rating': 6},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 40, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 8,
                                          'rating': 6},
                                         {'label': 'White', 'percentage': 45, 'rating': 10},
                                         {'label': 'Two or more races',
                                          'percentage': 4,
                                          'rating': 10},
                                         {'label': 'Filipino',
                                          'percentage': 2,
                                          'rating': 9},
                                         {'label': 'African American', 'percentage': 1}],
                       'gradeLevels': '9-12',
                       'highlighted': false,
                       'id': 279,
                       'lat': 37.668297,
                       'levelCode': 'h',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/279-Amador-Valley-High-School/#College_success',
                           'profile': '/california/pleasanton/279-Amador-Valley-High-School/',
                           'reviews': '/california/pleasanton/279-Amador-Valley-High-School/#Reviews'},
                       'lon': -121.873268,
                       'name': 'Amador Valley High School',
                       'numReviews': 20,
                       'parentRating': 4,
                       'percentLowIncome': '7%',
                       'pinned': null,
                       'rating': 9,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 24,
                       'subratings': {'College Readiness Rating': 10,
                                      'Equity Overview Rating': 5,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4150 Dorman Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 758,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 7,
                                          'rating': 5},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 55, 'rating': 10},
                                         {'label': 'White', 'percentage': 28, 'rating': 9},
                                         {'label': 'Two or more races',
                                          'percentage': 7,
                                          'rating': 9},
                                         {'label': 'Hispanic', 'percentage': 6},
                                         {'label': 'Filipino', 'percentage': 3},
                                         {'label': 'African American', 'percentage': 1}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 280,
                       'lat': 37.684113,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/280-Donlon-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/280-Donlon-Elementary-School/',
                           'reviews': '/california/pleasanton/280-Donlon-Elementary-School/#Reviews'},
                       'lon': -121.909081,
                       'name': 'Donlon Elementary School',
                       'numReviews': 43,
                       'parentRating': 4,
                       'percentLowIncome': '7%',
                       'pinned': null,
                       'rating': 8,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 26,
                       'subratings': {'Academic Progress Rating': 6,
                                      'Equity Overview Rating': 4,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4151 West Las Positas Boulevard',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 767,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 5},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 69, 'rating': 10},
                                         {'label': 'White', 'percentage': 21, 'rating': 10},
                                         {'label': 'Hispanic', 'percentage': 5},
                                         {'label': 'Two or more races', 'percentage': 3},
                                         {'label': 'Filipino', 'percentage': 2}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 281,
                       'lat': 37.6926,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/281-Fairlands-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/281-Fairlands-Elementary-School/',
                           'reviews': '/california/pleasanton/281-Fairlands-Elementary-School/#Reviews'},
                       'lon': -121.874329,
                       'name': 'Fairlands Elementary School',
                       'numReviews': 29,
                       'parentRating': 4,
                       'percentLowIncome': '5%',
                       'pinned': null,
                       'rating': 9,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 27,
                       'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4375 Foothill Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {'school_value': '86%',
                                                 'state_average': '64%'},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 2178,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 10,
                                          'rating': 7},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 39, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 10,
                                          'rating': 9},
                                         {'label': 'White', 'percentage': 39, 'rating': 10},
                                         {'label': 'Two or more races',
                                          'percentage': 5,
                                          'rating': 10},
                                         {'label': 'Filipino', 'percentage': 3},
                                         {'label': 'African American', 'percentage': 2}],
                       'gradeLevels': '9-12',
                       'highlighted': false,
                       'id': 282,
                       'lat': 37.671577,
                       'levelCode': 'h',
                       'links': {'collegeSuccess': '/california/pleasanton/282-Foothill-High-School/#College_success',
                                 'profile': '/california/pleasanton/282-Foothill-High-School/',
                                 'reviews': '/california/pleasanton/282-Foothill-High-School/#Reviews'},
                       'lon': -121.919937,
                       'name': 'Foothill High School',
                       'numReviews': 25,
                       'parentRating': 4,
                       'percentLowIncome': '10%',
                       'pinned': null,
                       'rating': 10,
                       'ratingScale': 'Top rated',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 23,
                       'subratings': {'College Readiness Rating': 10,
                                      'Equity Overview Rating': 7,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4900 Valley Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 1223,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 7,
                                          'rating': 6},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 49, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 6,
                                          'rating': 7},
                                         {'label': 'White', 'percentage': 37, 'rating': 9},
                                         {'label': 'Two or more races',
                                          'percentage': 4,
                                          'rating': 9},
                                         {'label': 'Filipino', 'percentage': 2},
                                         {'label': 'African American', 'percentage': 1}],
                       'gradeLevels': '6-8',
                       'highlighted': false,
                       'id': 283,
                       'lat': 37.675556,
                       'levelCode': 'm',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/283-Harvest-Park-Middle-School/#College_success',
                           'profile': '/california/pleasanton/283-Harvest-Park-Middle-School/',
                           'reviews': '/california/pleasanton/283-Harvest-Park-Middle-School/#Reviews'},
                       'lon': -121.882683,
                       'name': 'Harvest Park Middle School',
                       'numReviews': 34,
                       'parentRating': 4,
                       'percentLowIncome': '7%',
                       'pinned': null,
                       'rating': 8,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 23,
                       'subratings': {'Academic Progress Rating': 7,
                                      'Equity Overview Rating': 6,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '7700 Highland Oaks Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 666,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 8,
                                          'rating': 3},
                                         {'label': 'All students', 'rating': 9},
                                         {'label': 'Asian', 'percentage': 45, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 7,
                                          'rating': 8},
                                         {'label': 'White', 'percentage': 37, 'rating': 9},
                                         {'label': 'Two or more races', 'percentage': 6},
                                         {'label': 'Filipino', 'percentage': 3},
                                         {'label': 'African American', 'percentage': 2}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 284,
                       'lat': 37.680988,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/284-Lydiksen-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/284-Lydiksen-Elementary-School/',
                           'reviews': '/california/pleasanton/284-Lydiksen-Elementary-School/#Reviews'},
                       'lon': -121.9216,
                       'name': 'Lydiksen Elementary School',
                       'numReviews': 41,
                       'parentRating': 4,
                       'percentLowIncome': '8%',
                       'pinned': null,
                       'rating': 7,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 24,
                       'subratings': {'Academic Progress Rating': 7,
                                      'Equity Overview Rating': 5,
                                      'Test Scores Rating': 9},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3300 Dennis Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 683,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 6},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 72, 'rating': 10},
                                         {'label': 'White', 'percentage': 15, 'rating': 10},
                                         {'label': 'Hispanic', 'percentage': 5},
                                         {'label': 'Two or more races', 'percentage': 3},
                                         {'label': 'Filipino', 'percentage': 2},
                                         {'label': 'African American', 'percentage': 2}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 285,
                       'lat': 37.692501,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/285-Henry-P.-Mohr-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/285-Henry-P.-Mohr-Elementary-School/',
                           'reviews': '/california/pleasanton/285-Henry-P.-Mohr-Elementary-School/#Reviews'},
                       'lon': -121.860107,
                       'name': 'Henry P. Mohr Elementary School',
                       'numReviews': 25,
                       'parentRating': 3,
                       'percentLowIncome': '6%',
                       'pinned': null,
                       'rating': 9,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 27,
                       'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '5001 Case Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 1215,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 13,
                                          'rating': 6},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 33, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 15,
                                          'rating': 7},
                                         {'label': 'White', 'percentage': 41, 'rating': 9},
                                         {'label': 'Two or more races',
                                          'percentage': 7,
                                          'rating': 10},
                                         {'label': 'African American', 'percentage': 1},
                                         {'label': 'Filipino', 'percentage': 1}],
                       'gradeLevels': '6-8',
                       'highlighted': false,
                       'id': 286,
                       'lat': 37.65696,
                       'levelCode': 'm',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/286-Pleasanton-Middle-School/#College_success',
                           'profile': '/california/pleasanton/286-Pleasanton-Middle-School/',
                           'reviews': '/california/pleasanton/286-Pleasanton-Middle-School/#Reviews'},
                       'lon': -121.881561,
                       'name': 'Pleasanton Middle School',
                       'numReviews': 42,
                       'parentRating': 3,
                       'percentLowIncome': '13%',
                       'pinned': null,
                       'rating': 8,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 23,
                       'subratings': {'Academic Progress Rating': 7,
                                      'Equity Overview Rating': 6,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '480 Adams Way',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 651,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 25,
                                          'rating': 4},
                                         {'label': 'All students', 'rating': 8},
                                         {'label': 'Asian', 'percentage': 13, 'rating': 9},
                                         {'label': 'Hispanic',
                                          'percentage': 37,
                                          'rating': 5},
                                         {'label': 'White', 'percentage': 41, 'rating': 9},
                                         {'label': 'Two or more races', 'percentage': 7},
                                         {'label': 'African American', 'percentage': 1}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 287,
                       'lat': 37.659866,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/287-Valley-View-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/287-Valley-View-Elementary-School/',
                           'reviews': '/california/pleasanton/287-Valley-View-Elementary-School/#Reviews'},
                       'lon': -121.863617,
                       'name': 'Valley View Elementary School',
                       'numReviews': 19,
                       'parentRating': 4,
                       'percentLowIncome': '25%',
                       'pinned': null,
                       'rating': 6,
                       'ratingScale': 'Average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 23,
                       'subratings': {'Academic Progress Rating': 7,
                                      'Equity Overview Rating': 3,
                                      'Test Scores Rating': 8},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4645 Bernal Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {'school_value': '36%',
                                                 'state_average': '64%'},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 113,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 32},
                                         {'label': 'White', 'percentage': 56},
                                         {'label': 'Hispanic', 'percentage': 27},
                                         {'label': 'Asian', 'percentage': 7},
                                         {'label': 'Two or more races', 'percentage': 5},
                                         {'label': 'African American', 'percentage': 4},
                                         {'label': 'Filipino', 'percentage': 1}],
                       'gradeLevels': '9-12',
                       'highlighted': false,
                       'id': 288,
                       'lat': 37.65519,
                       'levelCode': 'h',
                       'links': {'collegeSuccess': '/california/pleasanton/288-Village-High-School/#College_success',
                                 'profile': '/california/pleasanton/288-Village-High-School/',
                                 'reviews': '/california/pleasanton/288-Village-High-School/#Reviews'},
                       'lon': -121.875137,
                       'name': 'Village High School',
                       'numReviews': 6,
                       'parentRating': 5,
                       'percentLowIncome': '32%',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 9,
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '1125 Concord Street',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 629,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 7},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 38, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 8,
                                          'rating': 8},
                                         {'label': 'White', 'percentage': 44, 'rating': 10},
                                         {'label': 'Two or more races', 'percentage': 7},
                                         {'label': 'African American', 'percentage': 2},
                                         {'label': 'Filipino', 'percentage': 2}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 289,
                       'lat': 37.655903,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/289-Vintage-Hills-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/289-Vintage-Hills-Elementary-School/',
                           'reviews': '/california/pleasanton/289-Vintage-Hills-Elementary-School/#Reviews'},
                       'lon': -121.855011,
                       'name': 'Vintage Hills Elementary School',
                       'numReviews': 35,
                       'parentRating': 4,
                       'percentLowIncome': '7%',
                       'pinned': null,
                       'rating': 9,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 25,
                       'subratings': {'Academic Progress Rating': 8,
                                      'Equity Overview Rating': 8,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '1999 Harvest Road',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 749,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 4},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 39, 'rating': 10},
                                         {'label': 'White', 'percentage': 49, 'rating': 9},
                                         {'label': 'Two or more races',
                                          'percentage': 6,
                                          'rating': 6},
                                         {'label': 'Hispanic', 'percentage': 4},
                                         {'label': 'African American', 'percentage': 1},
                                         {'label': 'Filipino', 'percentage': 1}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 290,
                       'lat': 37.673218,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/290-Walnut-Grove-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/290-Walnut-Grove-Elementary-School/',
                           'reviews': '/california/pleasanton/290-Walnut-Grove-Elementary-School/#Reviews'},
                       'lon': -121.885368,
                       'name': 'Walnut Grove Elementary School',
                       'numReviews': 19,
                       'parentRating': 5,
                       'percentLowIncome': '4%',
                       'pinned': null,
                       'rating': 9,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 26,
                       'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3410 Cornerstone Court',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 28348,
                       'lat': 37.676586,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/28348-Centerpointe-Christian-Pre-School/#College_success',
                           'profile': '/california/pleasanton/28348-Centerpointe-Christian-Pre-School/',
                           'reviews': '/california/pleasanton/28348-Centerpointe-Christian-Pre-School/#Reviews'},
                       'lon': -121.862419,
                       'name': 'Centerpointe Christian Pre-School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3707 Mohr Avenue',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 28621,
                       'lat': 37.682354,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/28621-Extended-Day-Care-Murray/#College_success',
                           'profile': '/california/pleasanton/28621-Extended-Day-Care-Murray/',
                           'reviews': '/california/pleasanton/28621-Extended-Day-Care-Murray/#Reviews'},
                       'lon': -121.864311,
                       'name': 'Extended Day Care-Murray',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '883 Rose Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 29320,
                       'lat': 37.664696,
                       'levelCode': 'p',
                       'links': {'collegeSuccess': '/california/pleasanton/29320-Child-Day-School/#College_success',
                                 'profile': '/california/pleasanton/29320-Child-Day-School/',
                                 'reviews': '/california/pleasanton/29320-Child-Day-School/#Reviews'},
                       'lon': -121.882935,
                       'name': 'Child Day School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4300 Mirador Drive',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 29707,
                       'lat': 37.657501,
                       'levelCode': 'p',
                       'links': {'collegeSuccess': '/california/pleasanton/29707-Busy-Bees-Pre-School/#College_success',
                                 'profile': '/california/pleasanton/29707-Busy-Bees-Pre-School/',
                                 'reviews': '/california/pleasanton/29707-Busy-Bees-Pre-School/#Reviews'},
                       'lon': -121.863754,
                       'name': 'Busy Bees Pre-School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '7817 Knollbrook Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 30125,
                       'lat': 37.687336,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/30125-Best-Friends-Enrichment-Child-Care-Center/#College_success',
                           'profile': '/california/pleasanton/30125-Best-Friends-Enrichment-Child-Care-Center/',
                           'reviews': '/california/pleasanton/30125-Best-Friends-Enrichment-Child-Care-Center/#Reviews'},
                       'lon': -121.926796,
                       'name': 'Best Friends Enrichment Child Care Center',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4160 Hacienda Drive #200',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 30166,
                       'lat': 37.686539,
                       'levelCode': 'p',
                       'links': {'collegeSuccess': '/california/pleasanton/30166-Yang-Fan-Pre-School/#College_success',
                                 'profile': '/california/pleasanton/30166-Yang-Fan-Pre-School/',
                                 'reviews': '/california/pleasanton/30166-Yang-Fan-Pre-School/#Reviews'},
                       'lon': -121.895424,
                       'name': 'Yang Fan Pre-School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '1225 Hopyard Road',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 30454,
                       'lat': 37.667858,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/30454-Sonshine-Enrch-Pre-School/#College_success',
                           'profile': '/california/pleasanton/30454-Sonshine-Enrch-Pre-School/',
                           'reviews': '/california/pleasanton/30454-Sonshine-Enrch-Pre-School/#Reviews'},
                       'lon': -121.884155,
                       'name': 'Sonshine Enrch Pre-School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '11533 Dublin Canyon Rd',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-K',
                       'highlighted': false,
                       'id': 31029,
                       'lat': 37.695869,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/31029-Little-Flowers-Montessori/#College_success',
                           'profile': '/california/pleasanton/31029-Little-Flowers-Montessori/',
                           'reviews': '/california/pleasanton/31029-Little-Flowers-Montessori/#Reviews'},
                       'lon': -121.937057,
                       'name': 'Little Flowers Montessori',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3550 Bernal Ave #120',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-K',
                       'highlighted': false,
                       'id': 31030,
                       'lat': 37.662006,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/31030-Little-Flowers-Montessori/#College_success',
                           'profile': '/california/pleasanton/31030-Little-Flowers-Montessori/',
                           'reviews': '/california/pleasanton/31030-Little-Flowers-Montessori/#Reviews'},
                       'lon': -121.855881,
                       'name': 'Little Flowers Montessori',
                       'numReviews': 7,
                       'parentRating': 1,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3550 Bernal Ave., Ste. 100B',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-6',
                       'highlighted': false,
                       'id': 32363,
                       'lat': 37.662102,
                       'levelCode': 'p,e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/32363-Genius-Kids-Pleasanton-Bernal/#College_success',
                           'profile': '/california/pleasanton/32363-Genius-Kids-Pleasanton-Bernal/',
                           'reviews': '/california/pleasanton/32363-Genius-Kids-Pleasanton-Bernal/#Reviews'},
                       'lon': -121.855896,
                       'name': 'Genius Kids-Pleasanton Bernal',
                       'numReviews': 7,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '118 Neal Street',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 23,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                         {'label': 'Pacific Islander', 'percentage': 57},
                                         {'label': 'Asian', 'percentage': 17},
                                         {'label': 'White', 'percentage': 13},
                                         {'label': 'Hispanic', 'percentage': 9},
                                         {'label': 'Two or more races', 'percentage': 4}],
                       'gradeLevels': 'K-12',
                       'highlighted': false,
                       'id': 15826,
                       'lat': 37.659492,
                       'levelCode': 'e,m,h',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/15826-Lighthouse-Baptist-School/#College_success',
                           'profile': '/california/pleasanton/15826-Lighthouse-Baptist-School/',
                           'reviews': '/california/pleasanton/15826-Lighthouse-Baptist-School/#Reviews'},
                       'lon': -121.873009,
                       'name': 'Lighthouse Baptist School',
                       'numReviews': 9,
                       'parentRating': 4,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4301 Hacienda Dr. Ste. 120 ',
                                   'street2': null,
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': '6-12',
                       'highlighted': false,
                       'id': 32760,
                       'lat': 37.692513,
                       'levelCode': 'm,h',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/32760-Futures-Academy---Pleasanton/#College_success',
                           'profile': '/california/pleasanton/32760-Futures-Academy---Pleasanton/',
                           'reviews': '/california/pleasanton/32760-Futures-Academy---Pleasanton/#Reviews'},
                       'lon': -121.893005,
                       'name': 'Futures Academy - Pleasanton',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3750 Boulder Street',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 15829,
                       'lat': 37.672359,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/15829-The-Quarry-Lane-Preschool/#College_success',
                           'profile': '/california/pleasanton/15829-The-Quarry-Lane-Preschool/',
                           'reviews': '/california/pleasanton/15829-The-Quarry-Lane-Preschool/#Reviews'},
                       'lon': -121.865067,
                       'name': 'The Quarry Lane Preschool',
                       'numReviews': 4,
                       'parentRating': 3,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4433 Willow Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 1201,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 8,
                                          'rating': 8},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 49, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 7,
                                          'rating': 8},
                                         {'label': 'White', 'percentage': 33, 'rating': 9},
                                         {'label': 'Two or more races',
                                          'percentage': 6,
                                          'rating': 10},
                                         {'label': 'Filipino',
                                          'percentage': 2,
                                          'rating': 10},
                                         {'label': 'African American', 'percentage': 2}],
                       'gradeLevels': '6-8',
                       'highlighted': false,
                       'id': 11687,
                       'lat': 37.685425,
                       'levelCode': 'm',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/11687-Thomas-S.-Hart-Middle-School/#College_success',
                           'profile': '/california/pleasanton/11687-Thomas-S.-Hart-Middle-School/',
                           'reviews': '/california/pleasanton/11687-Thomas-S.-Hart-Middle-School/#Reviews'},
                       'lon': -121.898277,
                       'name': 'Thomas S. Hart Middle School',
                       'numReviews': 47,
                       'parentRating': 3,
                       'percentLowIncome': '8%',
                       'pinned': null,
                       'rating': 9,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 24,
                       'subratings': {'Academic Progress Rating': 8,
                                      'Equity Overview Rating': 8,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '5301 Case Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 705,
                       'ethnicityInfo': [{'label': 'Low-income',
                                          'percentage': 8,
                                          'rating': 6},
                                         {'label': 'All students', 'rating': 10},
                                         {'label': 'Asian', 'percentage': 55, 'rating': 10},
                                         {'label': 'Hispanic',
                                          'percentage': 9,
                                          'rating': 6},
                                         {'label': 'White', 'percentage': 27, 'rating': 9},
                                         {'label': 'Two or more races', 'percentage': 5},
                                         {'label': 'African American', 'percentage': 3}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 11688,
                       'lat': 37.652451,
                       'levelCode': 'e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/11688-Phoebe-Apperson-Hearst-Elementary-School/#College_success',
                           'profile': '/california/pleasanton/11688-Phoebe-Apperson-Hearst-Elementary-School/',
                           'reviews': '/california/pleasanton/11688-Phoebe-Apperson-Hearst-Elementary-School/#Reviews'},
                       'lon': -121.884323,
                       'name': 'Phoebe Apperson Hearst Elementary School',
                       'numReviews': 45,
                       'parentRating': 4,
                       'percentLowIncome': '8%',
                       'pinned': null,
                       'rating': 8,
                       'ratingScale': 'Above average',
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'studentsPerTeacher': 26,
                       'subratings': {'Academic Progress Rating': 8,
                                      'Equity Overview Rating': 5,
                                      'Test Scores Rating': 10},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3800 Stoneridge Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 64,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                         {'label': 'White', 'percentage': 34},
                                         {'label': 'Asian', 'percentage': 31},
                                         {'label': 'Two or more races', 'percentage': 25},
                                         {'label': 'Hispanic', 'percentage': 9}],
                       'gradeLevels': '1-8',
                       'highlighted': false,
                       'id': 12132,
                       'lat': 37.691589,
                       'levelCode': 'e,m',
                       'links': {'collegeSuccess': '/california/pleasanton/12132-Hacienda-School/#College_success',
                                 'profile': '/california/pleasanton/12132-Hacienda-School/',
                                 'reviews': '/california/pleasanton/12132-Hacienda-School/#Reviews'},
                       'lon': -121.86821,
                       'name': 'Hacienda School',
                       'numReviews': 21,
                       'parentRating': 4,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '1251 Hopyard Road',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 13369,
                       'lat': 37.668705,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/13369-Early-Years-Childrens-Center/#College_success',
                           'profile': '/california/pleasanton/13369-Early-Years-Childrens-Center/',
                           'reviews': '/california/pleasanton/13369-Early-Years-Childrens-Center/#Reviews'},
                       'lon': -121.884827,
                       'name': "Early Years Children's Center",
                       'numReviews': 5,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4665 Bernal Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'Ungraded',
                       'highlighted': false,
                       'id': 14232,
                       'lat': 37.656147,
                       'levelCode': 'e,m,h',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/14232-Pleasanton-Adult-And-Career-Education/#College_success',
                           'profile': '/california/pleasanton/14232-Pleasanton-Adult-And-Career-Education/',
                           'reviews': '/california/pleasanton/14232-Pleasanton-Adult-And-Career-Education/#Reviews'},
                       'lon': -121.875168,
                       'name': 'Pleasanton Adult And Career Education',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4671 Chabot Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 84,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                         {'label': 'Asian', 'percentage': 50},
                                         {'label': 'White', 'percentage': 17},
                                         {'label': 'Pacific Islander', 'percentage': 17},
                                         {'label': 'African American', 'percentage': 8},
                                         {'label': 'Hispanic', 'percentage': 8}],
                       'gradeLevels': 'PK-K',
                       'highlighted': false,
                       'id': 15774,
                       'lat': 37.693722,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/15774-Hacienda-Child-Development-Center/#College_success',
                           'profile': '/california/pleasanton/15774-Hacienda-Child-Development-Center/',
                           'reviews': '/california/pleasanton/15774-Hacienda-Child-Development-Center/#Reviews'},
                       'lon': -121.900627,
                       'name': 'Hacienda Child Development Center',
                       'numReviews': 3,
                       'parentRating': 4,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '7110 Koll Center Pkwy',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-K',
                       'highlighted': false,
                       'id': 31655,
                       'lat': 37.663479,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/31655-Primrose-School-Of-Pleasanton/#College_success',
                           'profile': '/california/pleasanton/31655-Primrose-School-Of-Pleasanton/',
                           'reviews': '/california/pleasanton/31655-Primrose-School-Of-Pleasanton/#Reviews'},
                       'lon': -121.898926,
                       'name': 'Primrose School of Pleasanton',
                       'numReviews': 1,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3200 Hopyard Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21306,
                       'lat': 37.676903,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21306-Adventures-In-Learning/#College_success',
                           'profile': '/california/pleasanton/21306-Adventures-In-Learning/',
                           'reviews': '/california/pleasanton/21306-Adventures-In-Learning/#Reviews'},
                       'lon': -121.899971,
                       'name': 'Adventures In Learning',
                       'numReviews': 8,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3350 Hopyard Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21307,
                       'lat': 37.677261,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21307-St-Clares-Christian-Pre-School/#College_success',
                           'profile': '/california/pleasanton/21307-St-Clares-Christian-Pre-School/',
                           'reviews': '/california/pleasanton/21307-St-Clares-Christian-Pre-School/#Reviews'},
                       'lon': -121.90136,
                       'name': "St Clare's Christian Pre-School",
                       'numReviews': 1,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3400 Nevada Street',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21308,
                       'lat': 37.667171,
                       'levelCode': 'p',
                       'links': {'collegeSuccess': '/california/pleasanton/21308-Beth-Emek-Pre-School/#College_success',
                                 'profile': '/california/pleasanton/21308-Beth-Emek-Pre-School/',
                                 'reviews': '/california/pleasanton/21308-Beth-Emek-Pre-School/#Reviews'},
                       'lon': -121.855896,
                       'name': 'Beth Emek Pre-School',
                       'numReviews': 6,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3760 Brockton Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 57,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                         {'label': 'White', 'percentage': 36},
                                         {'label': 'Two or more races', 'percentage': 27},
                                         {'label': 'Asian', 'percentage': 21},
                                         {'label': 'Hispanic', 'percentage': 9},
                                         {'label': 'African American', 'percentage': 6}],
                       'gradeLevels': 'PK-K',
                       'highlighted': false,
                       'id': 21310,
                       'lat': 37.69989,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21310-Kindercare-Learning-Center/#College_success',
                           'profile': '/california/pleasanton/21310-Kindercare-Learning-Center/',
                           'reviews': '/california/pleasanton/21310-Kindercare-Learning-Center/#Reviews'},
                       'lon': -121.868446,
                       'name': 'Kindercare Learning Center',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4150 Dorman Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21311,
                       'lat': 37.684044,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21311-Hilln-Dale-Pre-School/#College_success',
                           'profile': '/california/pleasanton/21311-Hilln-Dale-Pre-School/',
                           'reviews': '/california/pleasanton/21311-Hilln-Dale-Pre-School/#Reviews'},
                       'lon': -121.907707,
                       'name': "Hill'n Dale Pre-School",
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4300 Mirador Dr',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21312,
                       'lat': 37.657372,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21312-Kinderkirk-Pre-School/#College_success',
                           'profile': '/california/pleasanton/21312-Kinderkirk-Pre-School/',
                           'reviews': '/california/pleasanton/21312-Kinderkirk-Pre-School/#Reviews'},
                       'lon': -121.863739,
                       'name': 'Kinderkirk Pre-School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4455 Del Valle Parkway',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21313,
                       'lat': 37.66708,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21313-Shining-Light-Pre-School/#College_success',
                           'profile': '/california/pleasanton/21313-Shining-Light-Pre-School/',
                           'reviews': '/california/pleasanton/21313-Shining-Light-Pre-School/#Reviews'},
                       'lon': -121.877876,
                       'name': 'Shining Light Pre-School',
                       'numReviews': 4,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '5525 Old School Rd',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21314,
                       'lat': 37.801334,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21314-Creative-Playschool-DC/#College_success',
                           'profile': '/california/pleasanton/21314-Creative-Playschool-DC/',
                           'reviews': '/california/pleasanton/21314-Creative-Playschool-DC/#Reviews'},
                       'lon': -121.857307,
                       'name': 'Creative Playschool DC',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '5725 Valley Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21315,
                       'lat': 37.677441,
                       'levelCode': 'p',
                       'links': {'collegeSuccess': '/california/pleasanton/21315-La-Petite-Academy/#College_success',
                                 'profile': '/california/pleasanton/21315-La-Petite-Academy/',
                                 'reviews': '/california/pleasanton/21315-La-Petite-Academy/#Reviews'},
                       'lon': -121.895607,
                       'name': 'La Petite Academy',
                       'numReviews': 2,
                       'parentRating': 5,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '7106 Johnson Drive',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 21316,
                       'lat': 37.698673,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/21316-Love-And-Care-Pre-School/#College_success',
                           'profile': '/california/pleasanton/21316-Love-And-Care-Pre-School/',
                           'reviews': '/california/pleasanton/21316-Love-And-Care-Pre-School/#Reviews'},
                       'lon': -121.919945,
                       'name': 'Love And Care Pre-School',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4900 Valley Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': 'Pleasanton',
                       'districtId': 16,
                       'districtName': 'Pleasanton Unified School District',
                       'enrollment': 40,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 25228,
                       'lat': 37.675556,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/25228-Harvest-Park-Preschool-Center/#College_success',
                           'profile': '/california/pleasanton/25228-Harvest-Park-Preschool-Center/',
                           'reviews': '/california/pleasanton/25228-Harvest-Park-Preschool-Center/#Reviews'},
                       'lon': -121.882683,
                       'name': 'Harvest Park Preschool Center',
                       'numReviews': 2,
                       'parentRating': 3,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'public',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '4576 Willow Road',
                                   'street2': '',
                                   'zip': '94588'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 248,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'K-5',
                       'highlighted': false,
                       'id': 25559,
                       'lat': 37.689651,
                       'levelCode': 'e',
                       'links': {'collegeSuccess': '/california/pleasanton/25559-Stratford-School/#College_success',
                                 'profile': '/california/pleasanton/25559-Stratford-School/',
                                 'reviews': '/california/pleasanton/25559-Stratford-School/#Reviews'},
                       'lon': -121.899147,
                       'name': 'Stratford School',
                       'numReviews': 33,
                       'parentRating': 4,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '5492 Greenfield Way',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 9,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': '1-12',
                       'highlighted': false,
                       'id': 25844,
                       'lat': 37.67366,
                       'levelCode': 'e,m,h',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/25844-Hillview-Christian-Academy/#College_success',
                           'profile': '/california/pleasanton/25844-Hillview-Christian-Academy/',
                           'reviews': '/california/pleasanton/25844-Hillview-Christian-Academy/#Reviews'},
                       'lon': -121.890259,
                       'name': 'Hillview Christian Academy',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '883 Rose Ave.',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-5',
                       'highlighted': false,
                       'id': 27227,
                       'lat': 37.664696,
                       'levelCode': 'p,e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/27227-The-Child-Day-Schools-Pleasanton/#College_success',
                           'profile': '/california/pleasanton/27227-The-Child-Day-Schools-Pleasanton/',
                           'reviews': '/california/pleasanton/27227-The-Child-Day-Schools-Pleasanton/#Reviews'},
                       'lon': -121.882935,
                       'name': 'The Child Day Schools, Pleasanton',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '5199 Black Avenue',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK',
                       'highlighted': false,
                       'id': 27626,
                       'lat': 37.672375,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/27626-Extended-Day-Care-Walnut-Grove/#College_success',
                           'profile': '/california/pleasanton/27626-Extended-Day-Care-Walnut-Grove/',
                           'reviews': '/california/pleasanton/27626-Extended-Day-Care-Walnut-Grove/#Reviews'},
                       'lon': -121.885338,
                       'name': 'Extended Day Care-Walnut Grove',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '6767 Alisal Street',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-K',
                       'highlighted': false,
                       'id': 27982,
                       'lat': 37.633228,
                       'levelCode': 'p',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/27982-Aim-High-Child-Care-Center/#College_success',
                           'profile': '/california/pleasanton/27982-Aim-High-Child-Care-Center/',
                           'reviews': '/california/pleasanton/27982-Aim-High-Child-Care-Center/#Reviews'},
                       'lon': -121.869987,
                       'name': 'Aim-High Child Care Center',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'},
                      {'address': {'city': 'Pleasanton',
                                   'street1': '3410 Cornerstone Court',
                                   'street2': '',
                                   'zip': '94566'},
                       'assigned': null,
                       'collegeEnrollmentData': {},
                       'collegePersistentData': [],
                       'csaAwardYears': [],
                       'districtCity': null,
                       'districtId': 0,
                       'districtName': null,
                       'enrollment': 0,
                       'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                       'gradeLevels': 'PK-6',
                       'highlighted': false,
                       'id': 32403,
                       'lat': 37.676563,
                       'levelCode': 'p,e',
                       'links': {
                           'collegeSuccess': '/california/pleasanton/32403-Montessori-School-Of-Pleasanton/#College_success',
                           'profile': '/california/pleasanton/32403-Montessori-School-Of-Pleasanton/',
                           'reviews': '/california/pleasanton/32403-Montessori-School-Of-Pleasanton/#Reviews'},
                       'lon': -121.862358,
                       'name': 'Montessori School of Pleasanton',
                       'numReviews': 0,
                       'parentRating': 0,
                       'percentLowIncome': '?',
                       'pinned': null,
                       'rating': null,
                       'ratingScale': null,
                       'remediationData': [],
                       'schoolType': 'private',
                       'state': 'CA',
                       'subratings': {},
                       'testScoreRatingForEthnicity': null,
                       'type': 'school'}]
