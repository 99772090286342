const pleasanton = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Alameda County, CA",
             "short_name": "Alameda County",
             "sumlevel": "050",
             "land_area": 1909614756,
             "full_geoid": "05000US06001",
             "total_population": 1671329
          },
          "CBSA": {
             "full_name": "San Francisco-Oakland-Berkeley, CA Metro Area",
             "short_name": "San Francisco-Oakland-Berkeley, CA",
             "sumlevel": "310",
             "land_area": 6398229497,
             "full_geoid": "31000US41860",
             "total_population": 4731803
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39512223
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Pleasanton, CA",
          "short_name": "Pleasanton",
          "sumlevel": "160",
          "land_area": 62540093,
          "full_geoid": "16000US0657792",
          "total_population": 81794,
          "sumlevel_name": "Place",
          "short_geoid": "0657792"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 23.87,
                   "CBSA": 19.53,
                   "state": 22.5
                },
                "error": {
                   "this": 2.55,
                   "CBSA": 0.2,
                   "state": 0.08
                },
                "numerators": {
                   "this": 19521,
                   "CBSA": 924120,
                   "state": 8891064
                },
                "numerator_errors": {
                   "this": 2088,
                   "CBSA": 9455,
                   "state": 32008.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 122,
                   "state": 106
                },
                "error_ratio": {
                   "this": 10.7,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 59.74,
                   "CBSA": 64.56,
                   "state": 62.73
                },
                "error": {
                   "this": 3.83,
                   "CBSA": 0.37,
                   "state": 0.13
                },
                "numerators": {
                   "this": 48860,
                   "CBSA": 3054854,
                   "state": 2.478616E7
                },
                "numerator_errors": {
                   "this": 3133.4,
                   "CBSA": 17723.5,
                   "state": 51870.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 95
                },
                "error_ratio": {
                   "this": 6.4,
                   "CBSA": 0.6,
                   "state": 0.2
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 16.4,
                   "CBSA": 15.91,
                   "state": 14.77
                },
                "error": {
                   "this": 2.32,
                   "CBSA": 0.24,
                   "state": 0.08
                },
                "numerators": {
                   "this": 13413,
                   "CBSA": 752829,
                   "state": 5834998
                },
                "numerator_errors": {
                   "this": 1895.4,
                   "CBSA": 11568.6,
                   "state": 32031.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 111
                },
                "error_ratio": {
                   "this": 14.1,
                   "CBSA": 1.5,
                   "state": 0.5
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 10.08,
                      "CBSA": 10.64,
                      "state": 12.04
                   },
                   "error": {
                      "this": 1.6,
                      "CBSA": 0.15,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 8248,
                      "CBSA": 503528,
                      "state": 4758601
                   },
                   "numerator_errors": {
                      "this": 1309.2,
                      "CBSA": 6865.4,
                      "state": 23052.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 95,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 15.9,
                      "CBSA": 1.4,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 15.69,
                      "CBSA": 11.03,
                      "state": 13.1
                   },
                   "error": {
                      "this": 2.12,
                      "CBSA": 0.14,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 12831,
                      "CBSA": 521896,
                      "state": 5176959
                   },
                   "numerator_errors": {
                      "this": 1732.9,
                      "CBSA": 6799.8,
                      "state": 23034.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 142,
                      "state": 120
                   },
                   "error_ratio": {
                      "this": 13.5,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 7.35,
                      "CBSA": 13.42,
                      "state": 14.49
                   },
                   "error": {
                      "this": 1.53,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 6010,
                      "CBSA": 634875,
                      "state": 5723923
                   },
                   "numerator_errors": {
                      "this": 1250.3,
                      "CBSA": 7995.9,
                      "state": 24219.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 55,
                      "state": 51
                   },
                   "error_ratio": {
                      "this": 20.8,
                      "CBSA": 1.3,
                      "state": 0.4
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 13.38,
                      "CBSA": 16.12,
                      "state": 14.47
                   },
                   "error": {
                      "this": 1.76,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 10947,
                      "CBSA": 762863,
                      "state": 5715837
                   },
                   "numerator_errors": {
                      "this": 1441.4,
                      "CBSA": 8035.9,
                      "state": 22852.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 83,
                      "state": 92
                   },
                   "error_ratio": {
                      "this": 13.2,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.29,
                      "CBSA": 13.71,
                      "state": 12.77
                   },
                   "error": {
                      "this": 1.75,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 11692,
                      "CBSA": 648707,
                      "state": 5046965
                   },
                   "numerator_errors": {
                      "this": 1428.4,
                      "CBSA": 8025.4,
                      "state": 22530.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 112
                   },
                   "error_ratio": {
                      "this": 12.2,
                      "CBSA": 1.2,
                      "state": 0.5
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 15.16,
                      "CBSA": 13.12,
                      "state": 12.46
                   },
                   "error": {
                      "this": 1.84,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 12400,
                      "CBSA": 620802,
                      "state": 4923782
                   },
                   "numerator_errors": {
                      "this": 1501.7,
                      "CBSA": 7116.2,
                      "state": 20800
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 116,
                      "state": 122
                   },
                   "error_ratio": {
                      "this": 12.1,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 12.38,
                      "CBSA": 10.91,
                      "state": 10.63
                   },
                   "error": {
                      "this": 1.95,
                      "CBSA": 0.23,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 10126,
                      "CBSA": 516448,
                      "state": 4200080
                   },
                   "numerator_errors": {
                      "this": 1593.9,
                      "CBSA": 10661.7,
                      "state": 31191.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 113,
                      "state": 116
                   },
                   "error_ratio": {
                      "this": 15.8,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 7.49,
                      "CBSA": 7.02,
                      "state": 6.44
                   },
                   "error": {
                      "this": 1.53,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 6129,
                      "CBSA": 332170,
                      "state": 2545061
                   },
                   "numerator_errors": {
                      "this": 1253.9,
                      "CBSA": 7111.4,
                      "state": 19797.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 116
                   },
                   "error_ratio": {
                      "this": 20.4,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.17,
                      "CBSA": 4.03,
                      "state": 3.6
                   },
                   "error": {
                      "this": 1.23,
                      "CBSA": 0.13,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 3411,
                      "CBSA": 190514,
                      "state": 1421015
                   },
                   "numerator_errors": {
                      "this": 1002.4,
                      "CBSA": 6014.8,
                      "state": 16282.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 116
                   },
                   "error_ratio": {
                      "this": 29.5,
                      "CBSA": 3.2,
                      "state": 1.1
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 10.77,
                      "CBSA": 11.01,
                      "state": 12.35
                   },
                   "error": {
                      "this": 2.28,
                      "CBSA": 0.22,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 4355,
                      "CBSA": 257668,
                      "state": 2425066
                   },
                   "numerator_errors": {
                      "this": 935.6,
                      "CBSA": 5167.5,
                      "state": 17537.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 87
                   },
                   "error_ratio": {
                      "this": 21.2,
                      "CBSA": 2,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 15.5,
                      "CBSA": 11.23,
                      "state": 13.47
                   },
                   "error": {
                      "this": 3.08,
                      "CBSA": 0.22,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6266,
                      "CBSA": 262840,
                      "state": 2646132
                   },
                   "numerator_errors": {
                      "this": 1267.3,
                      "CBSA": 5150.6,
                      "state": 17211.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 138,
                      "state": 115
                   },
                   "error_ratio": {
                      "this": 19.9,
                      "CBSA": 2,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 8.62,
                      "CBSA": 13.8,
                      "state": 15.06
                   },
                   "error": {
                      "this": 2.52,
                      "CBSA": 0.22,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 3483,
                      "CBSA": 322820,
                      "state": 2956969
                   },
                   "numerator_errors": {
                      "this": 1027.7,
                      "CBSA": 5263.7,
                      "state": 16123.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 62,
                      "state": 57
                   },
                   "error_ratio": {
                      "this": 29.2,
                      "CBSA": 1.6,
                      "state": 0.5
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 13.33,
                      "CBSA": 16.65,
                      "state": 14.93
                   },
                   "error": {
                      "this": 2.63,
                      "CBSA": 0.27,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 5387,
                      "CBSA": 389458,
                      "state": 2932306
                   },
                   "numerator_errors": {
                      "this": 1079.8,
                      "CBSA": 6428.5,
                      "state": 17684.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 80,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 19.7,
                      "CBSA": 1.6,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.7,
                      "CBSA": 13.86,
                      "state": 12.82
                   },
                   "error": {
                      "this": 2.54,
                      "CBSA": 0.26,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 5537,
                      "CBSA": 324176,
                      "state": 2518785
                   },
                   "numerator_errors": {
                      "this": 1047.6,
                      "CBSA": 6099,
                      "state": 17201
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 107
                   },
                   "error_ratio": {
                      "this": 18.5,
                      "CBSA": 1.9,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 16.76,
                      "CBSA": 13.33,
                      "state": 12.42
                   },
                   "error": {
                      "this": 2.69,
                      "CBSA": 0.24,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 6775,
                      "CBSA": 311977,
                      "state": 2439055
                   },
                   "numerator_errors": {
                      "this": 1117.1,
                      "CBSA": 5512.8,
                      "state": 15253.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 126,
                      "state": 135
                   },
                   "error_ratio": {
                      "this": 16.1,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 12.21,
                      "CBSA": 10.47,
                      "state": 10.2
                   },
                   "error": {
                      "this": 2.7,
                      "CBSA": 0.33,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 4936,
                      "CBSA": 244988,
                      "state": 2004301
                   },
                   "numerator_errors": {
                      "this": 1104.6,
                      "CBSA": 7814.5,
                      "state": 21480.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 117,
                      "state": 120
                   },
                   "error_ratio": {
                      "this": 22.1,
                      "CBSA": 3.2,
                      "state": 1.1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 5.42,
                      "CBSA": 6.41,
                      "state": 5.89
                   },
                   "error": {
                      "this": 1.54,
                      "CBSA": 0.21,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 2189,
                      "CBSA": 150043,
                      "state": 1156395
                   },
                   "numerator_errors": {
                      "this": 628.4,
                      "CBSA": 4926.3,
                      "state": 13944.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 85,
                      "state": 92
                   },
                   "error_ratio": {
                      "this": 28.4,
                      "CBSA": 3.3,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.69,
                      "CBSA": 3.24,
                      "state": 2.86
                   },
                   "error": {
                      "this": 1.43,
                      "CBSA": 0.16,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 1493,
                      "CBSA": 75746,
                      "state": 561785
                   },
                   "numerator_errors": {
                      "this": 578.8,
                      "CBSA": 3743.5,
                      "state": 10571.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 114,
                      "state": 129
                   },
                   "error_ratio": {
                      "this": 38.8,
                      "CBSA": 4.9,
                      "state": 1.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 9.41,
                      "CBSA": 10.28,
                      "state": 11.74
                   },
                   "error": {
                      "this": 2.19,
                      "CBSA": 0.19,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 3893,
                      "CBSA": 245860,
                      "state": 2333535
                   },
                   "numerator_errors": {
                      "this": 915.8,
                      "CBSA": 4520.1,
                      "state": 14962.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 92,
                      "state": 80
                   },
                   "error_ratio": {
                      "this": 23.3,
                      "CBSA": 1.8,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 15.87,
                      "CBSA": 10.83,
                      "state": 12.74
                   },
                   "error": {
                      "this": 2.8,
                      "CBSA": 0.19,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 6565,
                      "CBSA": 259056,
                      "state": 2530827
                   },
                   "numerator_errors": {
                      "this": 1181.9,
                      "CBSA": 4439.4,
                      "state": 15307.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 147,
                      "state": 125
                   },
                   "error_ratio": {
                      "this": 17.6,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.11,
                      "CBSA": 13.05,
                      "state": 13.92
                   },
                   "error": {
                      "this": 1.71,
                      "CBSA": 0.25,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 2527,
                      "CBSA": 312055,
                      "state": 2766954
                   },
                   "numerator_errors": {
                      "this": 712.1,
                      "CBSA": 6019,
                      "state": 18072.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 47,
                      "state": 44
                   },
                   "error_ratio": {
                      "this": 28,
                      "CBSA": 1.9,
                      "state": 0.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 13.44,
                      "CBSA": 15.61,
                      "state": 14.01
                   },
                   "error": {
                      "this": 2.26,
                      "CBSA": 0.2,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 5560,
                      "CBSA": 373405,
                      "state": 2783531
                   },
                   "numerator_errors": {
                      "this": 954.8,
                      "CBSA": 4821.8,
                      "state": 14474.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 86,
                      "state": 96
                   },
                   "error_ratio": {
                      "this": 16.8,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.88,
                      "CBSA": 13.57,
                      "state": 12.72
                   },
                   "error": {
                      "this": 2.29,
                      "CBSA": 0.22,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6155,
                      "CBSA": 324531,
                      "state": 2528180
                   },
                   "numerator_errors": {
                      "this": 971.1,
                      "CBSA": 5216.2,
                      "state": 14551.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 110,
                      "state": 117
                   },
                   "error_ratio": {
                      "this": 15.4,
                      "CBSA": 1.6,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.6,
                      "CBSA": 12.91,
                      "state": 12.5
                   },
                   "error": {
                      "this": 2.38,
                      "CBSA": 0.19,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 5625,
                      "CBSA": 308825,
                      "state": 2484727
                   },
                   "numerator_errors": {
                      "this": 1003.5,
                      "CBSA": 4500,
                      "state": 14140.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 17.5,
                      "CBSA": 1.5,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 12.54,
                      "CBSA": 11.35,
                      "state": 11.05
                   },
                   "error": {
                      "this": 2.74,
                      "CBSA": 0.3,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 5190,
                      "CBSA": 271460,
                      "state": 2195779
                   },
                   "numerator_errors": {
                      "this": 1149.1,
                      "CBSA": 7253,
                      "state": 22615.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 110,
                      "state": 113
                   },
                   "error_ratio": {
                      "this": 21.9,
                      "CBSA": 2.6,
                      "state": 1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 9.52,
                      "CBSA": 7.61,
                      "state": 6.99
                   },
                   "error": {
                      "this": 2.6,
                      "CBSA": 0.21,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 3940,
                      "CBSA": 182127,
                      "state": 1388666
                   },
                   "numerator_errors": {
                      "this": 1085,
                      "CBSA": 5128.7,
                      "state": 14053.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 136
                   },
                   "error_ratio": {
                      "this": 27.3,
                      "CBSA": 2.8,
                      "state": 1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.64,
                      "CBSA": 4.8,
                      "state": 4.32
                   },
                   "error": {
                      "this": 1.97,
                      "CBSA": 0.2,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 1918,
                      "CBSA": 114768,
                      "state": 859230
                   },
                   "numerator_errors": {
                      "this": 818.5,
                      "CBSA": 4708,
                      "state": 12383.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 107
                   },
                   "error_ratio": {
                      "this": 42.5,
                      "CBSA": 4.2,
                      "state": 1.4
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 42.1,
                   "CBSA": 39.2,
                   "state": 37
                },
                "error": {
                   "this": 2.1,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 114
                },
                "error_ratio": {
                   "this": 5,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 41.2,
                   "CBSA": 38.3,
                   "state": 35.9
                },
                "error": {
                   "this": 2,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 115
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 43.2,
                   "CBSA": 40.2,
                   "state": 38.2
                },
                "error": {
                   "this": 2.5,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 113
                },
                "error_ratio": {
                   "this": 5.8,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 49.42,
                "CBSA": 49.45,
                "state": 49.71
             },
             "error": {
                "this": 1.81,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 40421,
                "CBSA": 2339716,
                "state": 1.9640794E7
             },
             "numerator_errors": {
                "this": 1478,
                "CBSA": 2867,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 99
             },
             "error_ratio": {
                "this": 3.7,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 50.58,
                "CBSA": 50.55,
                "state": 50.29
             },
             "error": {
                "this": 1.81,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 41373,
                "CBSA": 2392087,
                "state": 1.9871428E7
             },
             "numerator_errors": {
                "this": 1478,
                "CBSA": 2867,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 101
             },
             "error_ratio": {
                "this": 3.6,
                "CBSA": 0.1,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 50.07,
                "CBSA": 39.6,
                "state": 37.18
             },
             "error": {
                "this": 1.29,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 40917,
                "CBSA": 1861545,
                "state": 1.4605312E7
             },
             "numerator_errors": {
                "this": 1057,
                "CBSA": 971,
                "state": 3168
             },
             "index": {
                "this": 100,
                "CBSA": 126,
                "state": 135
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 1.79,
                "CBSA": 7.08,
                "state": 5.52
             },
             "error": {
                "this": 0.66,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 1466,
                "CBSA": 333087,
                "state": 2169155
             },
             "numerator_errors": {
                "this": 538,
                "CBSA": 2460,
                "state": 6936
             },
             "index": {
                "this": 100,
                "CBSA": 25,
                "state": 32
             },
             "error_ratio": {
                "this": 36.9,
                "CBSA": 0.7,
                "state": 0.4
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.28,
                "CBSA": 0.23,
                "state": 0.36
             },
             "error": {
                "this": 0.11,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 228,
                "CBSA": 10718,
                "state": 140831
             },
             "numerator_errors": {
                "this": 87,
                "CBSA": 712,
                "state": 2516
             },
             "index": {
                "this": 100,
                "CBSA": 122,
                "state": 78
             },
             "error_ratio": {
                "this": 39.3,
                "CBSA": 8.7,
                "state": 2.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 34.15,
                "CBSA": 25.84,
                "state": 14.28
             },
             "error": {
                "this": 1.35,
                "CBSA": 0.07,
                "state": 0.02
             },
             "numerators": {
                "this": 27904,
                "CBSA": 1214748,
                "state": 5610931
             },
             "numerator_errors": {
                "this": 1103,
                "CBSA": 3362,
                "state": 9199
             },
             "index": {
                "this": 100,
                "CBSA": 132,
                "state": 239
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.47,
                "CBSA": 0.68,
                "state": 0.36
             },
             "error": {
                "this": 0.29,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 385,
                "CBSA": 31849,
                "state": 140788
             },
             "numerator_errors": {
                "this": 235,
                "CBSA": 816,
                "state": 2224
             },
             "index": {
                "this": 100,
                "CBSA": 69,
                "state": 131
             },
             "error_ratio": {
                "this": 61.7,
                "CBSA": 2.9,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.17,
                "CBSA": 0.38,
                "state": 0.25
             },
             "error": {
                "this": 0.14,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 138,
                "CBSA": 18047,
                "state": 100119
             },
             "numerator_errors": {
                "this": 115,
                "CBSA": 1457,
                "state": 3715
             },
             "index": {
                "this": 100,
                "CBSA": 45,
                "state": 68
             },
             "error_ratio": {
                "this": 82.4,
                "CBSA": 7.9,
                "state": 4
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 3.61,
                "CBSA": 4.38,
                "state": 3.03
             },
             "error": {
                "this": 0.66,
                "CBSA": 0.09,
                "state": 0.04
             },
             "numerators": {
                "this": 2951,
                "CBSA": 205932,
                "state": 1188673
             },
             "numerator_errors": {
                "this": 541,
                "CBSA": 4007,
                "state": 14463
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 119
             },
             "error_ratio": {
                "this": 18.3,
                "CBSA": 2.1,
                "state": 1.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 9.46,
                "CBSA": 21.81,
                "state": 39.02
             },
             "error": {
                "this": 1.05,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 7728,
                "CBSA": 1025406,
                "state": 1.5327688E7
             },
             "numerator_errors": {
                "this": 862,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 43,
                "state": 24
             },
             "error_ratio": {
                "this": 11.1,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 74729,
                "CBSA": 60223,
                "state": 39393
             },
             "error": {
                "this": 6347,
                "CBSA": 786,
                "state": 177
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 124,
                "state": 190
             },
             "error_ratio": {
                "this": 8.5,
                "CBSA": 1.3,
                "state": 0.4
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 156063,
                "CBSA": 114696,
                "state": 80440
             },
             "error": {
                "this": 13922,
                "CBSA": 1965,
                "state": 313
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 136,
                "state": 194
             },
             "error_ratio": {
                "this": 8.9,
                "CBSA": 1.7,
                "state": 0.4
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 15.35,
                   "CBSA": 22.5,
                   "state": 31.68
                },
                "error": {
                   "this": 4.33,
                   "CBSA": 0.65,
                   "state": 0.26
                },
                "numerators": {
                   "this": 4451,
                   "CBSA": 387393,
                   "state": 4167825
                },
                "numerator_errors": {
                   "this": 1268.1,
                   "CBSA": 11318.1,
                   "state": 34680.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 68,
                   "state": 48
                },
                "error_ratio": {
                   "this": 28.2,
                   "CBSA": 2.9,
                   "state": 0.8
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 16.32,
                   "CBSA": 21.44,
                   "state": 27.83
                },
                "error": {
                   "this": 3.41,
                   "CBSA": 0.57,
                   "state": 0.24
                },
                "numerators": {
                   "this": 4733,
                   "CBSA": 369108,
                   "state": 3661397
                },
                "numerator_errors": {
                   "this": 1004.3,
                   "CBSA": 9959.7,
                   "state": 32290.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 59
                },
                "error_ratio": {
                   "this": 20.9,
                   "CBSA": 2.7,
                   "state": 0.9
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 32.12,
                   "CBSA": 29.91,
                   "state": 26.8
                },
                "error": {
                   "this": 4.68,
                   "CBSA": 0.69,
                   "state": 0.24
                },
                "numerators": {
                   "this": 9314,
                   "CBSA": 514913,
                   "state": 3525910
                },
                "numerator_errors": {
                   "this": 1405.4,
                   "CBSA": 12053.4,
                   "state": 31856.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 120
                },
                "error_ratio": {
                   "this": 14.6,
                   "CBSA": 2.3,
                   "state": 0.9
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 36.2,
                   "CBSA": 26.15,
                   "state": 13.7
                },
                "error": {
                   "this": 3.65,
                   "CBSA": 0.54,
                   "state": 0.14
                },
                "numerators": {
                   "this": 10496,
                   "CBSA": 450162,
                   "state": 1802741
                },
                "numerator_errors": {
                   "this": 1135,
                   "CBSA": 9432,
                   "state": 18330
                },
                "index": {
                   "this": 100,
                   "CBSA": 138,
                   "state": 264
                },
                "error_ratio": {
                   "this": 10.1,
                   "CBSA": 2.1,
                   "state": 1
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 4.85,
                "CBSA": 8.16,
                "state": 11.75
             },
             "error": {
                "this": 2.01,
                "CBSA": 0.42,
                "state": 0.18
             },
             "numerators": {
                "this": 3947,
                "CBSA": 380982,
                "state": 4552837
             },
             "numerator_errors": {
                "this": 1635,
                "CBSA": 19484,
                "state": 67995
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 59,
                "state": 41
             },
             "error_ratio": {
                "this": 41.4,
                "CBSA": 5.1,
                "state": 1.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 5.33,
                   "CBSA": 8.89,
                   "state": 15.62
                },
                "error": {
                   "this": 4.38,
                   "CBSA": 0.65,
                   "state": 0.27
                },
                "numerators": {
                   "this": 1027,
                   "CBSA": 80671,
                   "state": 1363574
                },
                "numerator_errors": {
                   "this": 851.8,
                   "CBSA": 5988.4,
                   "state": 24624.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 60,
                   "state": 34
                },
                "error_ratio": {
                   "this": 82.2,
                   "CBSA": 7.3,
                   "state": 1.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 94.67,
                   "CBSA": 91.11,
                   "state": 84.38
                },
                "error": {
                   "this": 14.67,
                   "CBSA": 1.88,
                   "state": 0.61
                },
                "numerators": {
                   "this": 18231,
                   "CBSA": 827095,
                   "state": 7365259
                },
                "numerator_errors": {
                   "this": 1965.9,
                   "CBSA": 11932.8,
                   "state": 37404.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 112
                },
                "error_ratio": {
                   "this": 15.5,
                   "CBSA": 2.1,
                   "state": 0.7
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 3.73,
                   "CBSA": 8.95,
                   "state": 10.45
                },
                "error": {
                   "this": 2.69,
                   "CBSA": 0.62,
                   "state": 0.23
                },
                "numerators": {
                   "this": 496,
                   "CBSA": 66384,
                   "state": 599877
                },
                "numerator_errors": {
                   "this": 363.6,
                   "CBSA": 4644,
                   "state": 13070.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 42,
                   "state": 36
                },
                "error_ratio": {
                   "this": 72.1,
                   "CBSA": 6.9,
                   "state": 2.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 96.27,
                   "CBSA": 91.05,
                   "state": 89.55
                },
                "error": {
                   "this": 2.4,
                   "CBSA": 1.09,
                   "state": 0.39
                },
                "numerators": {
                   "this": 12784,
                   "CBSA": 675078,
                   "state": 5138754
                },
                "numerator_errors": {
                   "this": 1747.1,
                   "CBSA": 5093.8,
                   "state": 14255.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 108
                },
                "error_ratio": {
                   "this": 2.5,
                   "CBSA": 1.2,
                   "state": 0.4
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 36.08,
                "CBSA": 35.17,
                "state": 30.68
             },
             "error": {
                "this": 1.99,
                "CBSA": 0.3,
                "state": 0.1
             },
             "numerators": {
                "this": 1320585,
                "CBSA": 7.9633688E7,
                "state": 5.3813811E8
             },
             "numerator_errors": {
                "this": 109297,
                "CBSA": 939421,
                "state": 2278526
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 118
             },
             "error_ratio": {
                "this": 5.5,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 66.2,
                   "CBSA": 56.49,
                   "state": 73.51
                },
                "error": {
                   "this": 3.45,
                   "CBSA": 0.56,
                   "state": 0.23
                },
                "numerators": {
                   "this": 26595,
                   "CBSA": 1377616,
                   "state": 1.3767903E7
                },
                "numerator_errors": {
                   "this": 1977,
                   "CBSA": 16525,
                   "state": 52769
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 90
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 1,
                   "state": 0.3
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 5.18,
                   "CBSA": 8.62,
                   "state": 9.83
                },
                "error": {
                   "this": 1.78,
                   "CBSA": 0.33,
                   "state": 0.15
                },
                "numerators": {
                   "this": 2080,
                   "CBSA": 210199,
                   "state": 1841273
                },
                "numerator_errors": {
                   "this": 725,
                   "CBSA": 8119,
                   "state": 29274
                },
                "index": {
                   "this": 100,
                   "CBSA": 60,
                   "state": 53
                },
                "error_ratio": {
                   "this": 34.4,
                   "CBSA": 3.8,
                   "state": 1.5
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 14.87,
                   "CBSA": 18.94,
                   "state": 5.18
                },
                "error": {
                   "this": 3.41,
                   "CBSA": 0.41,
                   "state": 0.08
                },
                "numerators": {
                   "this": 5975,
                   "CBSA": 461832,
                   "state": 970901
                },
                "numerator_errors": {
                   "this": 1407,
                   "CBSA": 10580,
                   "state": 15634
                },
                "index": {
                   "this": 100,
                   "CBSA": 79,
                   "state": 287
                },
                "error_ratio": {
                   "this": 22.9,
                   "CBSA": 2.2,
                   "state": 1.5
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 1.6,
                   "CBSA": 1.83,
                   "state": 0.86
                },
                "error": {
                   "this": 1.09,
                   "CBSA": 0.14,
                   "state": 0.04
                },
                "numerators": {
                   "this": 641,
                   "CBSA": 44520,
                   "state": 160661
                },
                "numerator_errors": {
                   "this": 441,
                   "CBSA": 3413,
                   "state": 7943
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 186
                },
                "error_ratio": {
                   "this": 68.1,
                   "CBSA": 7.7,
                   "state": 4.7
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 2.06,
                   "CBSA": 4.69,
                   "state": 2.56
                },
                "error": {
                   "this": 1.15,
                   "CBSA": 0.36,
                   "state": 0.08
                },
                "numerators": {
                   "this": 828,
                   "CBSA": 114287,
                   "state": 479751
                },
                "numerator_errors": {
                   "this": 466,
                   "CBSA": 8717,
                   "state": 15458
                },
                "index": {
                   "this": 100,
                   "CBSA": 44,
                   "state": 80
                },
                "error_ratio": {
                   "this": 55.8,
                   "CBSA": 7.7,
                   "state": 3.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.2,
                   "CBSA": 2.28,
                   "state": 1.72
                },
                "error": {
                   "this": 1.05,
                   "CBSA": 0.2,
                   "state": 0.06
                },
                "numerators": {
                   "this": 481,
                   "CBSA": 55564,
                   "state": 321375
                },
                "numerator_errors": {
                   "this": 424,
                   "CBSA": 4861,
                   "state": 11717
                },
                "index": {
                   "this": 100,
                   "CBSA": 53,
                   "state": 70
                },
                "error_ratio": {
                   "this": 87.5,
                   "CBSA": 8.8,
                   "state": 3.5
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 8.89,
                   "CBSA": 7.16,
                   "state": 6.34
                },
                "error": {
                   "this": 1.86,
                   "CBSA": 0.34,
                   "state": 0.12
                },
                "numerators": {
                   "this": 3572,
                   "CBSA": 174591,
                   "state": 1188387
                },
                "numerator_errors": {
                   "this": 770,
                   "CBSA": 8386,
                   "state": 22217
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 140
                },
                "error_ratio": {
                   "this": 20.9,
                   "CBSA": 4.7,
                   "state": 1.9
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 67.02,
                "CBSA": 49.88,
                "state": 48.44
             },
             "error": {
                "this": 1.9,
                "CBSA": 0.39,
                "state": 0.14
             },
             "numerators": {
                "this": 44429,
                "CBSA": 1977052,
                "state": 1.5561514E7
             },
             "numerator_errors": {
                "this": 1696.1,
                "CBSA": 15302.6,
                "state": 46249.3
             },
             "index": {
                "this": 100,
                "CBSA": 134,
                "state": 138
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 0.8,
                "state": 0.3
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 32.98,
                "CBSA": 50.12,
                "state": 51.56
             },
             "error": {
                "this": 3.66,
                "CBSA": 0.45,
                "state": 0.16
             },
             "numerators": {
                "this": 21865,
                "CBSA": 1986662,
                "state": 1.6562598E7
             },
             "numerator_errors": {
                "this": 2488.8,
                "CBSA": 17817.2,
                "state": 51752.6
             },
             "index": {
                "this": 100,
                "CBSA": 66,
                "state": 64
             },
             "error_ratio": {
                "this": 11.1,
                "CBSA": 0.9,
                "state": 0.3
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 24.33,
                   "CBSA": 39.77,
                   "state": 40.83
                },
                "error": {
                   "this": 4.37,
                   "CBSA": 0.5,
                   "state": 0.17
                },
                "numerators": {
                   "this": 7916,
                   "CBSA": 775103,
                   "state": 6478460
                },
                "numerator_errors": {
                   "this": 1460,
                   "CBSA": 9861,
                   "state": 27866
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 60
                },
                "error_ratio": {
                   "this": 18,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 18.28,
                   "CBSA": 34.02,
                   "state": 33.96
                },
                "error": {
                   "this": 3.65,
                   "CBSA": 0.46,
                   "state": 0.15
                },
                "numerators": {
                   "this": 6171,
                   "CBSA": 685311,
                   "state": 5521285
                },
                "numerator_errors": {
                   "this": 1264,
                   "CBSA": 9340,
                   "state": 24532
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 54
                },
                "error_ratio": {
                   "this": 20,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 68.41,
                   "CBSA": 51.38,
                   "state": 49.37
                },
                "error": {
                   "this": 2.64,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 22259,
                   "CBSA": 1001374,
                   "state": 7832740
                },
                "numerator_errors": {
                   "this": 1264,
                   "CBSA": 10667,
                   "state": 32087
                },
                "index": {
                   "this": 100,
                   "CBSA": 133,
                   "state": 139
                },
                "error_ratio": {
                   "this": 3.9,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 65.68,
                   "CBSA": 48.43,
                   "state": 47.54
                },
                "error": {
                   "this": 1.32,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 22170,
                   "CBSA": 975678,
                   "state": 7728774
                },
                "numerator_errors": {
                   "this": 1131,
                   "CBSA": 10972,
                   "state": 33308
                },
                "index": {
                   "this": 100,
                   "CBSA": 136,
                   "state": 138
                },
                "error_ratio": {
                   "this": 2,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 6.46,
                   "CBSA": 6.95,
                   "state": 7.69
                },
                "error": {
                   "this": 2.26,
                   "CBSA": 0.34,
                   "state": 0.11
                },
                "numerators": {
                   "this": 2103,
                   "CBSA": 135520,
                   "state": 1219434
                },
                "numerator_errors": {
                   "this": 741,
                   "CBSA": 6608,
                   "state": 17626
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 84
                },
                "error_ratio": {
                   "this": 35,
                   "CBSA": 4.9,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 8.17,
                   "CBSA": 10.37,
                   "state": 10.83
                },
                "error": {
                   "this": 2.74,
                   "CBSA": 0.32,
                   "state": 0.16
                },
                "numerators": {
                   "this": 2759,
                   "CBSA": 208872,
                   "state": 1760791
                },
                "numerator_errors": {
                   "this": 933,
                   "CBSA": 6454,
                   "state": 25259
                },
                "index": {
                   "this": 100,
                   "CBSA": 79,
                   "state": 75
                },
                "error_ratio": {
                   "this": 33.5,
                   "CBSA": 3.1,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 0.8,
                   "CBSA": 1.9,
                   "state": 2.11
                },
                "error": {
                   "this": 0.97,
                   "CBSA": 0.16,
                   "state": 0.05
                },
                "numerators": {
                   "this": 260,
                   "CBSA": 37037,
                   "state": 334453
                },
                "numerator_errors": {
                   "this": 317,
                   "CBSA": 3146,
                   "state": 8393
                },
                "index": {
                   "this": 100,
                   "CBSA": 42,
                   "state": 38
                },
                "error_ratio": {
                   "this": 121.2,
                   "CBSA": 8.4,
                   "state": 2.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 7.87,
                   "CBSA": 7.19,
                   "state": 7.68
                },
                "error": {
                   "this": 2.86,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2656,
                   "CBSA": 144819,
                   "state": 1248175
                },
                "numerator_errors": {
                   "this": 972,
                   "CBSA": 6145,
                   "state": 16759
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 102
                },
                "error_ratio": {
                   "this": 36.3,
                   "CBSA": 4.2,
                   "state": 1.3
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 87.76,
                   "CBSA": 78.76,
                   "state": 72.23
                },
                "error": {
                   "this": 1.38,
                   "CBSA": 0.42,
                   "state": 0.27
                },
                "numerators": {
                   "this": 16850,
                   "CBSA": 652636,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 582,
                   "CBSA": 4069,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 122
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 0.5,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 3.81,
                   "CBSA": 5.35,
                   "state": 7.67
                },
                "error": {
                   "this": 1.23,
                   "CBSA": 0.26,
                   "state": 0.11
                },
                "numerators": {
                   "this": 731,
                   "CBSA": 44369,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 238,
                   "CBSA": 2181,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 71,
                   "state": 50
                },
                "error_ratio": {
                   "this": 32.3,
                   "CBSA": 4.9,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 8.43,
                   "CBSA": 15.88,
                   "state": 20.1
                },
                "error": {
                   "this": 1.91,
                   "CBSA": 0.36,
                   "state": 0.21
                },
                "numerators": {
                   "this": 1619,
                   "CBSA": 131584,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 371,
                   "CBSA": 3049,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 53,
                   "state": 42
                },
                "error_ratio": {
                   "this": 22.7,
                   "CBSA": 2.3,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 4.51,
                "CBSA": 4.22,
                "state": 4.69
             },
             "error": {
                "this": 2.01,
                "CBSA": 0.35,
                "state": 0.11
             },
             "numerators": {
                "this": 796,
                "CBSA": 49498,
                "state": 450971
             },
             "numerator_errors": {
                "this": 361,
                "CBSA": 4126,
                "state": 10955
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 96
             },
             "error_ratio": {
                "this": 44.6,
                "CBSA": 8.3,
                "state": 2.3
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.14,
                   "state": 0.87
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.17,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 175,
                   "state": 10882
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 215,
                   "state": 2046
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 121.4,
                   "state": 18.4
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 3.41,
                   "state": 4.44
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.98,
                   "state": 0.41
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4502,
                   "state": 56709
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 1289,
                   "state": 5217
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 28.7,
                   "state": 9.2
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 10.8,
                   "CBSA": 3.96,
                   "state": 6.85
                },
                "error": {
                   "this": 12.4,
                   "CBSA": 0.79,
                   "state": 0.37
                },
                "numerators": {
                   "this": 149,
                   "CBSA": 7129,
                   "state": 101991
                },
                "numerator_errors": {
                   "this": 180,
                   "CBSA": 1423,
                   "state": 5591
                },
                "index": {
                   "this": 100,
                   "CBSA": 273,
                   "state": 158
                },
                "error_ratio": {
                   "this": 114.8,
                   "CBSA": 19.9,
                   "state": 5.4
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 17.47,
                   "CBSA": 8.76,
                   "state": 9.73
                },
                "error": {
                   "this": 11.55,
                   "CBSA": 1.12,
                   "state": 0.51
                },
                "numerators": {
                   "this": 421,
                   "CBSA": 16867,
                   "state": 139246
                },
                "numerator_errors": {
                   "this": 309,
                   "CBSA": 2170,
                   "state": 7329
                },
                "index": {
                   "this": 100,
                   "CBSA": 199,
                   "state": 180
                },
                "error_ratio": {
                   "this": 66.1,
                   "CBSA": 12.8,
                   "state": 5.2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 7.17,
                   "CBSA": 8.24,
                   "state": 6.95
                },
                "error": {
                   "this": 5.88,
                   "CBSA": 1.17,
                   "state": 0.33
                },
                "numerators": {
                   "this": 226,
                   "CBSA": 14911,
                   "state": 93949
                },
                "numerator_errors": {
                   "this": 192,
                   "CBSA": 2171,
                   "state": 4611
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 103
                },
                "error_ratio": {
                   "this": 82,
                   "CBSA": 14.2,
                   "state": 4.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 0,
                   "CBSA": 2.72,
                   "state": 2.64
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.6,
                   "state": 0.21
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4418,
                   "state": 33377
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 979,
                   "state": 2743
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 22.1,
                   "state": 8
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0,
                   "CBSA": 0.76,
                   "state": 0.96
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.3,
                   "state": 0.14
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1496,
                   "state": 14817
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 597,
                   "state": 2211
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 39.5,
                   "state": 14.6
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 28994,
                "CBSA": 1721576,
                "state": 1.3157873E7
             },
             "error": {
                "this": 1127,
                "CBSA": 7415,
                "state": 23844
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 3.9,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.81,
                "CBSA": 2.7,
                "state": 2.94
             },
             "error": {
                "this": 0.11,
                "CBSA": 0.01,
                "state": 0.01
             },
             "numerators": {
                "this": 81478,
                "CBSA": 4654824,
                "state": 3.8685704E7
             },
             "numerator_errors": {
                "this": 321,
                "CBSA": 3894,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 96
             },
             "error_ratio": {
                "this": 3.9,
                "CBSA": 0.4,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 78.49,
                   "CBSA": 61.45,
                   "state": 60.69
                },
                "error": {
                   "this": 3.75,
                   "CBSA": 0.62,
                   "state": 0.22
                },
                "numerators": {
                   "this": 63950,
                   "CBSA": 2860522,
                   "state": 2.3477092E7
                },
                "numerator_errors": {
                   "this": 3069,
                   "CBSA": 28845,
                   "state": 84642
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 129
                },
                "error_ratio": {
                   "this": 4.8,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 3.29,
                   "CBSA": 5.55,
                   "state": 7.74
                },
                "error": {
                   "this": 1.72,
                   "CBSA": 0.39,
                   "state": 0.16
                },
                "numerators": {
                   "this": 2684,
                   "CBSA": 258173,
                   "state": 2996042
                },
                "numerator_errors": {
                   "this": 1403,
                   "CBSA": 18051,
                   "state": 63406
                },
                "index": {
                   "this": 100,
                   "CBSA": 59,
                   "state": 43
                },
                "error_ratio": {
                   "this": 52.3,
                   "CBSA": 7,
                   "state": 2.1
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 8.73,
                   "CBSA": 12.87,
                   "state": 16.47
                },
                "error": {
                   "this": 3.49,
                   "CBSA": 0.56,
                   "state": 0.23
                },
                "numerators": {
                   "this": 7117,
                   "CBSA": 598953,
                   "state": 6371949
                },
                "numerator_errors": {
                   "this": 2840,
                   "CBSA": 26284,
                   "state": 87738
                },
                "index": {
                   "this": 100,
                   "CBSA": 68,
                   "state": 53
                },
                "error_ratio": {
                   "this": 40,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 9.48,
                   "CBSA": 20.13,
                   "state": 15.1
                },
                "error": {
                   "this": 2.06,
                   "CBSA": 0.41,
                   "state": 0.1
                },
                "numerators": {
                   "this": 7727,
                   "CBSA": 937176,
                   "state": 5840619
                },
                "numerator_errors": {
                   "this": 1678,
                   "CBSA": 18922,
                   "state": 37146
                },
                "index": {
                   "this": 100,
                   "CBSA": 47,
                   "state": 63
                },
                "error_ratio": {
                   "this": 21.7,
                   "CBSA": 2,
                   "state": 0.7
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 30391,
                "CBSA": 1841897,
                "state": 1.4367012E7
             },
             "error": {
                "this": 1550,
                "CBSA": 1707,
                "state": 1286
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 5.1,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 95.4,
                   "CBSA": 93.47,
                   "state": 91.58
                },
                "error": {
                   "this": 6.12,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 28994,
                   "CBSA": 1721576,
                   "state": 1.3157873E7
                },
                "numerator_errors": {
                   "this": 1127,
                   "CBSA": 7415,
                   "state": 23844
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 104
                },
                "error_ratio": {
                   "this": 6.4,
                   "CBSA": 0.4,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 4.6,
                   "CBSA": 6.53,
                   "state": 8.42
                },
                "error": {
                   "this": 2.99,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 1397,
                   "CBSA": 120321,
                   "state": 1209139
                },
                "numerator_errors": {
                   "this": 911,
                   "CBSA": 7194,
                   "state": 24297
                },
                "index": {
                   "this": 100,
                   "CBSA": 70,
                   "state": 55
                },
                "error_ratio": {
                   "this": 65,
                   "CBSA": 6,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 71.12,
                   "CBSA": 57.4,
                   "state": 63.96
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.62,
                   "state": 0.22
                },
                "numerators": {
                   "this": 21613,
                   "CBSA": 1057192,
                   "state": 9189272
                },
                "numerator_errors": {
                   "this": 1289.4,
                   "CBSA": 11515.2,
                   "state": 31423.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 111
                },
                "error_ratio": {
                   "this": 3.1,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 28.04,
                   "CBSA": 41.49,
                   "state": 32.18
                },
                "error": {
                   "this": 5.09,
                   "CBSA": 0.81,
                   "state": 0.27
                },
                "numerators": {
                   "this": 8521,
                   "CBSA": 764126,
                   "state": 4622614
                },
                "numerator_errors": {
                   "this": 1605.7,
                   "CBSA": 14847.1,
                   "state": 38673.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 68,
                   "state": 87
                },
                "error_ratio": {
                   "this": 18.2,
                   "CBSA": 2,
                   "state": 0.8
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.85,
                   "CBSA": 1,
                   "state": 3.74
                },
                "error": {
                   "this": 0.62,
                   "CBSA": 0.11,
                   "state": 0.08
                },
                "numerators": {
                   "this": 257,
                   "CBSA": 18463,
                   "state": 537931
                },
                "numerator_errors": {
                   "this": 190,
                   "CBSA": 1989,
                   "state": 11591
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 23
                },
                "error_ratio": {
                   "this": 72.9,
                   "CBSA": 11,
                   "state": 2.1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.12
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.05,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 2116,
                   "state": 17195
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 951,
                   "state": 2102
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 45.5,
                   "state": 8.3
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 65.35,
                   "CBSA": 54.03,
                   "state": 54.86
                },
                "error": {
                   "this": 3.21,
                   "CBSA": 0.59,
                   "state": 0.22
                },
                "numerators": {
                   "this": 18949,
                   "CBSA": 930250,
                   "state": 7218742
                },
                "numerator_errors": {
                   "this": 1188,
                   "CBSA": 10981,
                   "state": 32100
                },
                "index": {
                   "this": 100,
                   "CBSA": 121,
                   "state": 119
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 34.65,
                   "CBSA": 45.97,
                   "state": 45.14
                },
                "error": {
                   "this": 3.39,
                   "CBSA": 0.65,
                   "state": 0.24
                },
                "numerators": {
                   "this": 10045,
                   "CBSA": 791326,
                   "state": 5939131
                },
                "numerator_errors": {
                   "this": 1057,
                   "CBSA": 11672,
                   "state": 32807
                },
                "index": {
                   "this": 100,
                   "CBSA": 75,
                   "state": 77
                },
                "error_ratio": {
                   "this": 9.8,
                   "CBSA": 1.4,
                   "state": 0.5
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 1087700,
                "CBSA": 940900,
                "state": 568500
             },
             "error": {
                "this": 35387,
                "CBSA": 7982,
                "state": 2502
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 116,
                "state": 191
             },
             "error_ratio": {
                "this": 3.3,
                "CBSA": 0.8,
                "state": 0.4
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 2.07,
                   "CBSA": 2.16,
                   "state": 5.03
                },
                "error": {
                   "this": 0.66,
                   "CBSA": 0.1,
                   "state": 0.05
                },
                "numerators": {
                   "this": 420,
                   "CBSA": 20156,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 133.9,
                   "CBSA": 908.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 41
                },
                "error_ratio": {
                   "this": 31.9,
                   "CBSA": 4.6,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 1.35,
                   "CBSA": 1.61,
                   "state": 6.63
                },
                "error": {
                   "this": 0.48,
                   "CBSA": 0.09,
                   "state": 0.06
                },
                "numerators": {
                   "this": 274,
                   "CBSA": 15036,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 97.9,
                   "CBSA": 818.1,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 20
                },
                "error_ratio": {
                   "this": 35.6,
                   "CBSA": 5.6,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 0.62,
                   "CBSA": 2.81,
                   "state": 11.06
                },
                "error": {
                   "this": 0.29,
                   "CBSA": 0.11,
                   "state": 0.06
                },
                "numerators": {
                   "this": 126,
                   "CBSA": 26184,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 58.4,
                   "CBSA": 1029.9,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 22,
                   "state": 6
                },
                "error_ratio": {
                   "this": 46.8,
                   "CBSA": 3.9,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 1.17,
                   "CBSA": 5.66,
                   "state": 13.6
                },
                "error": {
                   "this": 0.46,
                   "CBSA": 0.14,
                   "state": 0.09
                },
                "numerators": {
                   "this": 238,
                   "CBSA": 52681,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 94,
                   "CBSA": 1347,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 21,
                   "state": 9
                },
                "error_ratio": {
                   "this": 39.3,
                   "CBSA": 2.5,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 2.48,
                   "CBSA": 7.56,
                   "state": 13.21
                },
                "error": {
                   "this": 0.56,
                   "CBSA": 0.19,
                   "state": 0.08
                },
                "numerators": {
                   "this": 504,
                   "CBSA": 70432,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 115,
                   "CBSA": 1773,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 33,
                   "state": 19
                },
                "error_ratio": {
                   "this": 22.6,
                   "CBSA": 2.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 44.06,
                   "CBSA": 43.94,
                   "state": 35.28
                },
                "error": {
                   "this": 2.47,
                   "CBSA": 0.29,
                   "state": 0.26
                },
                "numerators": {
                   "this": 8938,
                   "CBSA": 409063,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 547.9,
                   "CBSA": 3594.1,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 125
                },
                "error_ratio": {
                   "this": 5.6,
                   "CBSA": 0.7,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 32.16,
                   "CBSA": 19.26,
                   "state": 7.93
                },
                "error": {
                   "this": 1.86,
                   "CBSA": 0.23,
                   "state": 0.04
                },
                "numerators": {
                   "this": 6524,
                   "CBSA": 179279,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 410,
                   "CBSA": 2390,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 167,
                   "state": 406
                },
                "error_ratio": {
                   "this": 5.8,
                   "CBSA": 1.2,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 20286,
                "CBSA": 931032,
                "state": 7154580
             },
             "error": {
                "this": 498,
                "CBSA": 5500,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 2.5,
                "CBSA": 0.6,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 8.02,
                "CBSA": 10.5,
                "state": 8.86
             },
             "error": {
                "this": 1.74,
                "CBSA": 0.42,
                "state": 0.13
             },
             "numerators": {
                "this": 6535,
                "CBSA": 488730,
                "state": 3427072
             },
             "numerator_errors": {
                "this": 1416.5,
                "CBSA": 19338.7,
                "state": 50836.5
             },
             "index": {
                "this": 100,
                "CBSA": 76,
                "state": 91
             },
             "error_ratio": {
                "this": 21.7,
                "CBSA": 4,
                "state": 1.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 12.15,
                "CBSA": 10.75,
                "state": 11.17
             },
             "error": {
                "this": 2.64,
                "CBSA": 0.42,
                "state": 0.16
             },
             "numerators": {
                "this": 9899,
                "CBSA": 500178,
                "state": 4320604
             },
             "numerator_errors": {
                "this": 2149.7,
                "CBSA": 19399.4,
                "state": 63360.7
             },
             "index": {
                "this": 100,
                "CBSA": 113,
                "state": 109
             },
             "error_ratio": {
                "this": 21.7,
                "CBSA": 3.9,
                "state": 1.4
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 22.69,
                "CBSA": 21.68,
                "state": 21.9
             },
             "error": {
                "this": 3.87,
                "CBSA": 0.73,
                "state": 0.25
             },
             "numerators": {
                "this": 18487,
                "CBSA": 1008965,
                "state": 8473932
             },
             "numerator_errors": {
                "this": 3152.8,
                "CBSA": 33889.7,
                "state": 98624.3
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 104
             },
             "error_ratio": {
                "this": 17.1,
                "CBSA": 3.4,
                "state": 1.1
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 16.18,
                "CBSA": 20.17,
                "state": 20.05
             },
             "error": {
                "this": 3.56,
                "CBSA": 0.62,
                "state": 0.24
             },
             "numerators": {
                "this": 13187,
                "CBSA": 939060,
                "state": 7754580
             },
             "numerator_errors": {
                "this": 2899.1,
                "CBSA": 29080.9,
                "state": 91602.6
             },
             "index": {
                "this": 100,
                "CBSA": 80,
                "state": 81
             },
             "error_ratio": {
                "this": 22,
                "CBSA": 3.1,
                "state": 1.2
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 12.01,
                "CBSA": 12.37,
                "state": 13.11
             },
             "error": {
                "this": 3.29,
                "CBSA": 0.54,
                "state": 0.21
             },
             "numerators": {
                "this": 9782,
                "CBSA": 575817,
                "state": 5072685
             },
             "numerator_errors": {
                "this": 2684.3,
                "CBSA": 25040.7,
                "state": 79541.6
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 92
             },
             "error_ratio": {
                "this": 27.4,
                "CBSA": 4.4,
                "state": 1.6
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 28.95,
                "CBSA": 24.54,
                "state": 24.91
             },
             "error": {
                "this": 4.05,
                "CBSA": 0.73,
                "state": 0.26
             },
             "numerators": {
                "this": 23588,
                "CBSA": 1142074,
                "state": 9636829
             },
             "numerator_errors": {
                "this": 3299.6,
                "CBSA": 33871.4,
                "state": 101788.8
             },
             "index": {
                "this": 100,
                "CBSA": 118,
                "state": 116
             },
             "error_ratio": {
                "this": 14,
                "CBSA": 3,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 13.57,
                "CBSA": 12.66,
                "state": 12
             },
             "error": {
                "this": 3.12,
                "CBSA": 0.41,
                "state": 0.16
             },
             "numerators": {
                "this": 11015,
                "CBSA": 592840,
                "state": 4689828
             },
             "numerator_errors": {
                "this": 2537.8,
                "CBSA": 19218.9,
                "state": 63097.7
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 113
             },
             "error_ratio": {
                "this": 23,
                "CBSA": 3.2,
                "state": 1.3
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 86.43,
                "CBSA": 87.34,
                "state": 88
             },
             "error": {
                "this": 2.52,
                "CBSA": 0.39,
                "state": 0.17
             },
             "numerators": {
                "this": 70182,
                "CBSA": 4091762,
                "state": 3.439422E7
             },
             "numerator_errors": {
                "this": 2065,
                "CBSA": 18428,
                "state": 68574
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 98
             },
             "error_ratio": {
                "this": 2.9,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 4.72,
                "CBSA": 6.27,
                "state": 7.37
             },
             "error": {
                "this": 2.04,
                "CBSA": 0.29,
                "state": 0.13
             },
             "numerators": {
                "this": 3832,
                "CBSA": 293714,
                "state": 2881883
             },
             "numerator_errors": {
                "this": 1656,
                "CBSA": 13357,
                "state": 52031
             },
             "index": {
                "this": 100,
                "CBSA": 75,
                "state": 64
             },
             "error_ratio": {
                "this": 43.2,
                "CBSA": 4.6,
                "state": 1.8
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 4.29,
                "CBSA": 3.96,
                "state": 2.72
             },
             "error": {
                "this": 1.6,
                "CBSA": 0.25,
                "state": 0.07
             },
             "numerators": {
                "this": 3485,
                "CBSA": 185306,
                "state": 1061256
             },
             "numerator_errors": {
                "this": 1302,
                "CBSA": 11875,
                "state": 27761
             },
             "index": {
                "this": 100,
                "CBSA": 108,
                "state": 158
             },
             "error_ratio": {
                "this": 37.3,
                "CBSA": 6.3,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 2.39,
                "CBSA": 1.39,
                "state": 1.23
             },
             "error": {
                "this": 1.4,
                "CBSA": 0.11,
                "state": 0.05
             },
             "numerators": {
                "this": 1943,
                "CBSA": 65269,
                "state": 480204
             },
             "numerator_errors": {
                "this": 1133,
                "CBSA": 5065,
                "state": 18764
             },
             "index": {
                "this": 100,
                "CBSA": 172,
                "state": 194
             },
             "error_ratio": {
                "this": 58.6,
                "CBSA": 7.9,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 2.16,
                "CBSA": 1.04,
                "state": 0.68
             },
             "error": {
                "this": 1.04,
                "CBSA": 0.11,
                "state": 0.03
             },
             "numerators": {
                "this": 1755,
                "CBSA": 48551,
                "state": 266485
             },
             "numerator_errors": {
                "this": 848,
                "CBSA": 4928,
                "state": 12302
             },
             "index": {
                "this": 100,
                "CBSA": 208,
                "state": 318
             },
             "error_ratio": {
                "this": 48.1,
                "CBSA": 10.6,
                "state": 4.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 95.8,
                "CBSA": 89.55,
                "state": 84.03
             },
             "error": {
                "this": 4.81,
                "CBSA": 0.79,
                "state": 0.29
             },
             "numerators": {
                "this": 55462,
                "CBSA": 3083220,
                "state": 2.263636E7
             },
             "numerator_errors": {
                "this": 3281.6,
                "CBSA": 27313.4,
                "state": 78234.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 114
             },
             "error_ratio": {
                "this": 5,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 66.4,
                "CBSA": 51.35,
                "state": 35
             },
             "error": {
                "this": 4.08,
                "CBSA": 0.57,
                "state": 0.17
             },
             "numerators": {
                "this": 38441,
                "CBSA": 1768174,
                "state": 9428484
             },
             "numerator_errors": {
                "this": 2648.2,
                "CBSA": 19525.3,
                "state": 44753.9
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 129,
                "state": 190
             },
             "error_ratio": {
                "this": 6.1,
                "CBSA": 1.1,
                "state": 0.5
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 4.2,
                "CBSA": 10.45,
                "state": 15.97
             },
             "error": {
                "this": 2.02,
                "CBSA": 0.34,
                "state": 0.14
             },
             "numerators": {
                "this": 2429,
                "CBSA": 359842,
                "state": 4301513
             },
             "numerator_errors": {
                "this": 1172.9,
                "CBSA": 11853.8,
                "state": 37669.1
             },
             "index": {
                "this": 100,
                "CBSA": 40,
                "state": 26
             },
             "error_ratio": {
                "this": 48.1,
                "CBSA": 3.3,
                "state": 0.9
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 9.4,
                "CBSA": 15.67,
                "state": 20.59
             },
             "error": {
                "this": 2.02,
                "CBSA": 0.37,
                "state": 0.16
             },
             "numerators": {
                "this": 5442,
                "CBSA": 539467,
                "state": 5546711
             },
             "numerator_errors": {
                "this": 1180.8,
                "CBSA": 12581,
                "state": 42454
             },
             "index": {
                "this": 100,
                "CBSA": 60,
                "state": 46
             },
             "error_ratio": {
                "this": 21.5,
                "CBSA": 2.4,
                "state": 0.8
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 20,
                "CBSA": 22.53,
                "state": 28.44
             },
             "error": {
                "this": 2.58,
                "CBSA": 0.42,
                "state": 0.18
             },
             "numerators": {
                "this": 11579,
                "CBSA": 775579,
                "state": 7661164
             },
             "numerator_errors": {
                "this": 1536.9,
                "CBSA": 14370.3,
                "state": 48118.5
             },
             "index": {
                "this": 100,
                "CBSA": 89,
                "state": 70
             },
             "error_ratio": {
                "this": 12.9,
                "CBSA": 1.9,
                "state": 0.6
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 36.58,
                "CBSA": 29.97,
                "state": 21.86
             },
             "error": {
                "this": 3.07,
                "CBSA": 0.4,
                "state": 0.13
             },
             "numerators": {
                "this": 21175,
                "CBSA": 1032030,
                "state": 5889724
             },
             "numerator_errors": {
                "this": 1899,
                "CBSA": 13696,
                "state": 34784.9
             },
             "index": {
                "this": 100,
                "CBSA": 122,
                "state": 167
             },
             "error_ratio": {
                "this": 8.4,
                "CBSA": 1.3,
                "state": 0.6
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 29.83,
                "CBSA": 21.38,
                "state": 13.14
             },
             "error": {
                "this": 3.05,
                "CBSA": 0.4,
                "state": 0.1
             },
             "numerators": {
                "this": 17266,
                "CBSA": 736144,
                "state": 3538760
             },
             "numerator_errors": {
                "this": 1845.7,
                "CBSA": 13916.1,
                "state": 28159
             },
             "index": {
                "this": 100,
                "CBSA": 140,
                "state": 227
             },
             "error_ratio": {
                "this": 10.2,
                "CBSA": 1.9,
                "state": 0.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 33.6,
                "CBSA": 30.94,
                "state": 26.74
             },
             "error": {
                "this": 3.04,
                "CBSA": 0.47,
                "state": 0.14
             },
             "numerators": {
                "this": 27483,
                "CBSA": 1464048,
                "state": 1.056422E7
             },
             "numerator_errors": {
                "this": 2489,
                "CBSA": 22110,
                "state": 55357
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 109,
                "state": 126
             },
             "error_ratio": {
                "this": 9,
                "CBSA": 1.5,
                "state": 0.5
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 10.36,
                   "CBSA": 9.42,
                   "state": 6.42
                },
                "error": {
                   "this": 1.79,
                   "CBSA": 0.19,
                   "state": 0.07
                },
                "numerators": {
                   "this": 2710,
                   "CBSA": 135944,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 478,
                   "CBSA": 2807,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 161
                },
                "error_ratio": {
                   "this": 17.3,
                   "CBSA": 2,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 76.53,
                   "CBSA": 57.07,
                   "state": 39.42
                },
                "error": {
                   "this": 2.26,
                   "CBSA": 0.46,
                   "state": 0.09
                },
                "numerators": {
                   "this": 20025,
                   "CBSA": 823767,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 943,
                   "CBSA": 4566,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 194
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.36,
                   "CBSA": 2.41,
                   "state": 1.84
                },
                "error": {
                   "this": 0.66,
                   "CBSA": 0.12,
                   "state": 0.05
                },
                "numerators": {
                   "this": 356,
                   "CBSA": 34802,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 172,
                   "CBSA": 1747,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 74
                },
                "error_ratio": {
                   "this": 48.5,
                   "CBSA": 5,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 1.02,
                   "CBSA": 1.66,
                   "state": 0.82
                },
                "error": {
                   "this": 0.49,
                   "CBSA": 0.11,
                   "state": 0.03
                },
                "numerators": {
                   "this": 268,
                   "CBSA": 23950,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 129,
                   "CBSA": 1560,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 124
                },
                "error_ratio": {
                   "this": 48,
                   "CBSA": 6.6,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 8.21,
                   "CBSA": 27.95,
                   "state": 50.28
                },
                "error": {
                   "this": 1.31,
                   "CBSA": 0.31,
                   "state": 0.15
                },
                "numerators": {
                   "this": 2149,
                   "CBSA": 403440,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 353,
                   "CBSA": 5038,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 29,
                   "state": 16
                },
                "error_ratio": {
                   "this": 16,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 2.51,
                   "CBSA": 1.5,
                   "state": 1.23
                },
                "error": {
                   "this": 0.77,
                   "CBSA": 0.07,
                   "state": 0.03
                },
                "numerators": {
                   "this": 658,
                   "CBSA": 21628,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 204,
                   "CBSA": 1082,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 167,
                   "state": 204
                },
                "error_ratio": {
                   "this": 30.7,
                   "CBSA": 4.7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 63.88,
                   "CBSA": 61.88,
                   "state": 56.97
                },
                "error": {
                   "this": 4.57,
                   "CBSA": 1.17,
                   "state": 0.51
                },
                "numerators": {
                   "this": 9937,
                   "CBSA": 416591,
                   "state": 3710631
                },
                "numerator_errors": {
                   "this": 1380,
                   "CBSA": 7911,
                   "state": 33084
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 112
                },
                "error_ratio": {
                   "this": 7.2,
                   "CBSA": 1.9,
                   "state": 0.9
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 9.64,
                   "CBSA": 19.91,
                   "state": 33.31
                },
                "error": {
                   "this": 6.21,
                   "CBSA": 0.9,
                   "state": 0.42
                },
                "numerators": {
                   "this": 1500,
                   "CBSA": 134015,
                   "state": 2169717
                },
                "numerator_errors": {
                   "this": 982,
                   "CBSA": 6061,
                   "state": 27349
                },
                "index": {
                   "this": 100,
                   "CBSA": 48,
                   "state": 29
                },
                "error_ratio": {
                   "this": 64.4,
                   "CBSA": 4.5,
                   "state": 1.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 11.6,
                   "CBSA": 5.64,
                   "state": 3.07
                },
                "error": {
                   "this": 4.63,
                   "CBSA": 0.56,
                   "state": 0.15
                },
                "numerators": {
                   "this": 1804,
                   "CBSA": 37994,
                   "state": 200078
                },
                "numerator_errors": {
                   "this": 752,
                   "CBSA": 3804,
                   "state": 9887
                },
                "index": {
                   "this": 100,
                   "CBSA": 206,
                   "state": 378
                },
                "error_ratio": {
                   "this": 39.9,
                   "CBSA": 9.9,
                   "state": 4.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 13.87,
                   "CBSA": 11.16,
                   "state": 5.66
                },
                "error": {
                   "this": 3.84,
                   "CBSA": 0.61,
                   "state": 0.17
                },
                "numerators": {
                   "this": 2157,
                   "CBSA": 75163,
                   "state": 368935
                },
                "numerator_errors": {
                   "this": 650,
                   "CBSA": 4124,
                   "state": 11144
                },
                "index": {
                   "this": 100,
                   "CBSA": 124,
                   "state": 245
                },
                "error_ratio": {
                   "this": 27.7,
                   "CBSA": 5.5,
                   "state": 3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.01,
                   "CBSA": 1.4,
                   "state": 0.98
                },
                "error": {
                   "this": 1.19,
                   "CBSA": 0.3,
                   "state": 0.11
                },
                "numerators": {
                   "this": 157,
                   "CBSA": 9442,
                   "state": 63864
                },
                "numerator_errors": {
                   "this": 186,
                   "CBSA": 2040,
                   "state": 6999
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 103
                },
                "error_ratio": {
                   "this": 117.8,
                   "CBSA": 21.4,
                   "state": 11.2
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 59.52,
                   "CBSA": 58.1,
                   "state": 55.21
                },
                "error": {
                   "this": 3.93,
                   "CBSA": 0.48,
                   "state": 0.18
                },
                "numerators": {
                   "this": 37068,
                   "CBSA": 2212141,
                   "state": 1.6905948E7
                },
                "numerator_errors": {
                   "this": 2891.5,
                   "CBSA": 18159.5,
                   "state": 55036
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 108
                },
                "error_ratio": {
                   "this": 6.6,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 6.07,
                   "CBSA": 14.83,
                   "state": 27.8
                },
                "error": {
                   "this": 2.21,
                   "CBSA": 0.28,
                   "state": 0.12
                },
                "numerators": {
                   "this": 3780,
                   "CBSA": 564814,
                   "state": 8513944
                },
                "numerator_errors": {
                   "this": 1383.2,
                   "CBSA": 10565.9,
                   "state": 37016.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 41,
                   "state": 22
                },
                "error_ratio": {
                   "this": 36.4,
                   "CBSA": 1.9,
                   "state": 0.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 13.57,
                   "CBSA": 7.23,
                   "state": 4.88
                },
                "error": {
                   "this": 3.25,
                   "CBSA": 0.33,
                   "state": 0.09
                },
                "numerators": {
                   "this": 8449,
                   "CBSA": 275261,
                   "state": 1494004
                },
                "numerator_errors": {
                   "this": 2053.7,
                   "CBSA": 12540.4,
                   "state": 28577.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 188,
                   "state": 278
                },
                "error_ratio": {
                   "this": 23.9,
                   "CBSA": 4.6,
                   "state": 1.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 20.5,
                   "CBSA": 18.63,
                   "state": 10.99
                },
                "error": {
                   "this": 2.79,
                   "CBSA": 0.3,
                   "state": 0.08
                },
                "numerators": {
                   "this": 12766,
                   "CBSA": 709253,
                   "state": 3364089
                },
                "numerator_errors": {
                   "this": 1813.3,
                   "CBSA": 11259.6,
                   "state": 24688.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 187
                },
                "error_ratio": {
                   "this": 13.6,
                   "CBSA": 1.6,
                   "state": 0.7
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.34,
                   "CBSA": 1.21,
                   "state": 1.12
                },
                "error": {
                   "this": 0.37,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 210,
                   "CBSA": 46214,
                   "state": 343173
                },
                "numerator_errors": {
                   "this": 231.7,
                   "CBSA": 5246.1,
                   "state": 14221.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 28,
                   "state": 30
                },
                "error_ratio": {
                   "this": 108.8,
                   "CBSA": 11.6,
                   "state": 4.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 108,
                   "CBSA": 8551,
                   "state": 66574
                },
                "error": {
                   "this": 66.56,
                   "CBSA": 587.6,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 61.6,
                   "CBSA": 6.9,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 413,
                   "CBSA": 15732,
                   "state": 145536
                },
                "error": {
                   "this": 145.7,
                   "CBSA": 775.97,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 35.3,
                   "CBSA": 4.9,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 931,
                   "CBSA": 55782,
                   "state": 561083
                },
                "error": {
                   "this": 160.87,
                   "CBSA": 1426.49,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 17.3,
                   "CBSA": 2.6,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 367,
                   "CBSA": 22897,
                   "state": 284290
                },
                "error": {
                   "this": 122.09,
                   "CBSA": 1005.17,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 33.3,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 308,
                   "CBSA": 22022,
                   "state": 309021
                },
                "error": {
                   "this": 132.73,
                   "CBSA": 1007.87,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 43.1,
                   "CBSA": 4.6,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 2131,
                   "CBSA": 132185,
                   "state": 1351006
                },
                "error": {
                   "this": 567,
                   "CBSA": 5340,
                   "state": 19299
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 26.6,
                   "CBSA": 4,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 0,
                   "CBSA": 10262,
                   "state": 120461
                },
                "error": {
                   "this": 216,
                   "CBSA": 1944,
                   "state": 5618
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 18.9,
                   "state": 4.7
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 2131,
                "CBSA": 142447,
                "state": 1471467
             },
             "error": {
                "this": 567,
                "CBSA": 6012,
                "state": 19838
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 1,
                "state": null
             },
             "error_ratio": {
                "this": 26.6,
                "CBSA": 4.2,
                "state": 1.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 3.43,
                "CBSA": 3.74,
                "state": 4.83
             },
             "error": {
                "this": 0.91,
                "CBSA": 0.16,
                "state": 0.07
             },
             "numerators": {
                "this": 2131,
                "CBSA": 142447,
                "state": 1471467
             },
             "numerator_errors": {
                "this": 567,
                "CBSA": 6012,
                "state": 19838
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 92,
                "state": 71
             },
             "error_ratio": {
                "this": 26.5,
                "CBSA": 4.3,
                "state": 1.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 62540093,
       "awater": 380830,
       "display_name": "Pleasanton, CA",
       "full_geoid": "16000US0657792",
       "population": 80847,
       "simple_name": "Pleasanton",
       "sumlevel": "160",
       "square_miles": 24.1,
       "population_density": 3387.4
    }
 }

export default pleasanton