const MIDDLE_SCHOOL_RANKS = [
    {
        "guid": "280eb8bb-aa58-4545-81fa-e9ce6b956329",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 1,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.422002,
                "lon": -122.118835
            },
            "entity": {
                "abbreviation": "Jane Lathrop Stanford Middle School",
                "alternates": {
                    "nces": "062961004598"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "280eb8bb-aa58-4545-81fa-e9ce6b956329",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Jane Lathrop Stanford Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "19f5aca2-1171-4d7e-b74a-93c3e4d99c32",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jane Lathrop Stanford Middle School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "jane-lathrop-stanford-middle-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1137
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Learning Targets and Evidence Based grading have changed the world. Can't wait to see it all on Schoology.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-01-08T13:11:59.609042Z",
                "guid": "5f3a7193-21ad-4cf5-a9e4-c5232e307101",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.118,37.4205,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.118,37.4205,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "8941299f-cbea-49ef-9fb2-4f13f8af66a3",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 2,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.405071,
                "lon": -122.126909
            },
            "entity": {
                "abbreviation": "Fletcher Middle School",
                "alternates": {
                    "nces": "062961011454"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "8941299f-cbea-49ef-9fb2-4f13f8af66a3",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Fletcher Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a53599ea-6a9e-441a-b7e0-cf790689f1e5",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Fletcher Middle School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "fletcher-middle-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 668
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 15
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "My daughter was graduated from Fletcher Middle School days ago. She enjoyed the study, friendship, and tennis team activities very much. I was working on the school PTA board and had a chance to know more parents. The parents are warmhearted and willing to spend time on all kinds of students activities. \nFletcher is the smallest one in the three Palo Alto middle schools. Fletcher has about 240 graduate students every year, and each of the other two schools JLS/Greene has a double number of the graduate student.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-05T23:01:22.739258Z",
                "guid": "a517eb68-77e8-4fd0-9cf2-1cdcc6f595fa",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.126,37.4024,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.126,37.4024,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "00b2cf61-7a5c-43b3-acf1-d86d19fbe6ce",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 3,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.438267,
                "lon": -122.135661
            },
            "entity": {
                "abbreviation": "David Starr Jordan Middle School",
                "alternates": {
                    "nces": "062961010323"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "00b2cf61-7a5c-43b3-acf1-d86d19fbe6ce",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Frank S. Greene Jr. Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b51a1c20-d220-46da-a238-39bac813dcb6",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "e91bfc0c-bca1-45d4-909a-25b104b8c881"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jordan",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "frank-s-greene-jr-middle-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1050
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I am a student at Greene myself , and I love it there! Even though the school campus is pretty small, they have so much things going on every single day! I expecially love band.  Our band director is a very nice and interesting person that always gives us good tips and makes the whole class much more fun than  others. I always enjoy being in this class and in this school. ( Yet I sometimes wish we had more music competitions like marching band stuff going on more often) So far I have not seen any problems (bullying, violence, etc.) in my school and if there was, our teachers and administrators always helps us in a helpful way. There are also many places you can  go to after school with your friends near by, so whenever we have a minimum day at school and we wan't to go somewhere, we wouldn't need to bike somewhere far away to go have fun! I absolutely love this school, yet I believe there could be some improvements on some parts.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-23T01:40:44.156762Z",
                "guid": "6561d394-a874-4263-a516-389b34dd12f9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.135,37.4383,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.135,37.4383,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.7058823529411766,
                "count": 17
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94303"
            }
        ]
    },
    {
        "guid": "dbb02eef-0c09-44d7-9433-265d2f2998e0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 4,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.847159,
                "lon": -122.283151
            },
            "entity": {
                "abbreviation": "Yu Ming Charter School",
                "alternates": {
                    "nces": "060201312901"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "dbb02eef-0c09-44d7-9433-265d2f2998e0",
                "isClaimed": true,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Yu Ming Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "9b0e24bf-e5f4-4fab-becb-f9156b7b0d68"
                },
                "premium": false,
                "shortName": "Yu Ming Charter School",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "yu-ming-charter-school-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 445
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Great school, I go there and I love it so much! There are a lot of different things you can do but I love how it also teaches me mandarin and it also is super strong on math and English even though its half Chinese!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-03-03T04:28:24.131612Z",
                "guid": "b5a86495-e0eb-483f-8d1e-bec1651e0051",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.284,37.8474,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.284,37.8474,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94608"
            }
        ]
    },
    {
        "guid": "abae148b-bd17-4927-983e-2eab83aafa68",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 5,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.428036,
                "lon": -122.258475
            },
            "entity": {
                "abbreviation": "Woodside Elementary School",
                "alternates": {
                    "nces": "064314007013"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "abae148b-bd17-4927-983e-2eab83aafa68",
                "isClaimed": false,
                "isPremium": false,
                "location": "Woodside, CA",
                "name": "Woodside Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3fd9c449-6e9c-4b39-af8c-45c1109e0846",
                    "zipCode": "7d773137-7242-4cc9-91c9-d3770c1b9f33"
                },
                "premium": false,
                "shortName": "Woodside Elementary School",
                "tagline": [
                    "Public School",
                    "Woodside, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "woodside-elementary-school-woodside-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 408
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 11
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Great Teachers! Smart and competitive students. My main critique is about diversity, the school works hard on it but it is really not possible. Also if you are not very left-wing you will be destroyed by unsupportive peers and some teachers. There is literally borderline propaganda on the walls that includes fighting against those who think differently, defunding government organizations, and fighting the government. Some are supportive but if you don't think like others don't say anything.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-14T06:28:53.396883Z",
                "guid": "8d6c19b4-ef2c-4ea5-97f5-743d27625bd1",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.259,37.4281,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.259,37.4281,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.2,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "33dd65ad-0f72-45c5-a0fd-24785ecc8c19",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 6,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.823036,
                "lon": -122.234225
            },
            "entity": {
                "abbreviation": "Piedmont Middle School",
                "alternates": {
                    "nces": "063033004732"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "33dd65ad-0f72-45c5-a0fd-24785ecc8c19",
                "isClaimed": false,
                "isPremium": false,
                "location": "Piedmont, CA",
                "name": "Piedmont Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "schoolDistrict": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "56749142-fea1-470f-b296-912a61cb1169",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                        "location": "Piedmont, CA",
                        "name": "Piedmont City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "56749142-fea1-470f-b296-912a61cb1169",
                            "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                        },
                        "premium": false,
                        "shortName": "Piedmont City Unified School District",
                        "tagline": [
                            "School District",
                            "Piedmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "piedmont-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Piedmont Middle School",
                "tagline": [
                    "Piedmont City Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "piedmont-middle-school-piedmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 651
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.232,37.8232,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.232,37.8232,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "7afb89df-4791-476c-a5c9-f1efa70a68b4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 7,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.593009,
                "lon": -121.884927
            },
            "entity": {
                "abbreviation": "Sunol Glen Elementary School",
                "alternates": {
                    "nces": "060002109291"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7afb89df-4791-476c-a5c9-f1efa70a68b4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Sunol, CA",
                "name": "Sunol Glen Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3ce775b9-7775-4383-8562-f4da43b60186",
                    "zipCode": "c683e79d-d4b8-4e7f-91eb-6dc9de40b44b"
                },
                "premium": false,
                "shortName": "Sunol Glen Elementary School",
                "tagline": [
                    "Public School",
                    "Sunol, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "sunol-glen-elementary-school-sunol-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 297
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Works Here",
                "body": "Academics will puxh your kids to new levels. Each teacher is different in their style but all are good in their unique way.  There is no obvious drama and eveyone their genuinely cares for each and every one of those kids.  The quint and warm vibe is supported by our parent community.   The worst part of the school are the spring time allergies.  Guaranteed every year.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-03-06T06:32:29.123749Z",
                "guid": "6ed1e6f0-5d21-4218-83bc-ebdab7b16a66",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.5926,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.5926,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94586"
            }
        ]
    },
    {
        "guid": "b46a9aa2-44b9-4372-a55c-0f15fac98d13",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 8,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.685168,
                "lon": -121.898313
            },
            "entity": {
                "abbreviation": "Thomas S. Hart Middle School",
                "alternates": {
                    "nces": "060002008444"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "b46a9aa2-44b9-4372-a55c-0f15fac98d13",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Thomas S. Hart Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "4000a419-f29e-4c0c-b086-d53791b6f956"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hart Middle",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "thomas-s-hart-middle-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1201
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Amazing school. Teachers are kind and successfully prepare people for college/high school, and they're concerned about their students. Provides pathways in math so that people with different levels of mathematical knowledge could learn new things.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-05-10T19:32:13.902176Z",
                "guid": "70793dac-9bae-48e1-a69b-98d186b3bf8d",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.896,37.6853,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.896,37.6853,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94588"
            }
        ]
    },
    {
        "guid": "86e0fe7e-849e-473c-ad74-706f92ae6c8a",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 9,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.302757,
                "lon": -121.973539
            },
            "entity": {
                "abbreviation": "Discovery Charter School",
                "alternates": {
                    "nces": "060159111792"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "86e0fe7e-849e-473c-ad74-706f92ae6c8a",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Discovery Charter School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "schoolNetwork": "11465407-60e3-4192-8083-e8f010b6f06f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "326a03e0-bc81-42e5-87ef-3cd92b018bc4"
                },
                "premium": false,
                "shortName": "Discovery Charter School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "discovery-charter-school-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 566
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I got the benefit of going to this school because all my older brothers were already going there. I went to DCS all the way from kindergarten to eighth grade. There are so many things that this school offers which you will never find anywhere else. One of the things that might either seem like a pro or a con is that DCS is very reliant on parent participation. This participation often makes school a lot easier as a young child. Though if you worry that as a parent you wouldn't be able to participate whether it be due to a job or something, don't worry about it! DCS is very understanding and only encourages parent participation if possible. Another great thing about DCS is that they really focus on building the skills you will need to succeed late in life. They might not give you as much homework and so it might seem that it is easier, but realistically they are just trying to get away from the idea of working for the grade rather than the gaining of knowledge and understanding.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-28T05:31:04.87704Z",
                "guid": "3d4940b9-6bd2-4cc9-95c2-2881c01c8e62",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 12
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95117"
            }
        ]
    },
    {
        "guid": "52181871-59b3-485f-96cd-42219de4be4e",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 10,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.657066,
                "lon": -121.881453
            },
            "entity": {
                "abbreviation": "Pleasanton Middle School",
                "alternates": {
                    "nces": "060002009682"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "52181871-59b3-485f-96cd-42219de4be4e",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Pleasanton Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pleasanton Middle School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "pleasanton-middle-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1215
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "There are some really great teachers, but there are also some whose classes seem more of a chore than an exciting experience. Great variety of clubs and extracurricular activities.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-07-25T16:13:40.792236Z",
                "guid": "0e0962d7-5985-4f9e-9741-6d2b29ccb1a6",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.884,37.6545,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.884,37.6545,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 1.75,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "ab4e6307-58ab-460f-9896-4bc148d4863f",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 11,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.676809,
                "lon": -121.885219
            },
            "entity": {
                "abbreviation": "Harvest Park Middle School",
                "alternates": {
                    "nces": "060002009289"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "ab4e6307-58ab-460f-9896-4bc148d4863f",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Harvest Park Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "698cfca7-9f3c-46bb-8c0f-2bf0af62f108",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Harvest Park Middle School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "harvest-park-middle-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1223
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Just amazing. Teachers are nice. Library is beautiful dn new. School is great!!!\nThere are plenty of extracurricular activities and lots of fun activities, zooms, get-togethers, and more! The academics is perfect, not too much and not to less. Everyone is really nice and encouraging.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-25T20:22:41.023842Z",
                "guid": "a3a2ca7b-3a1c-4307-9cc8-644a65e8eef3",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.883,37.6756,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.883,37.6756,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8,
                "count": 10
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "513ad7ff-a16d-4f72-aaf3-f03cf0caa8ef",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 12,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.36959,
                "lon": -122.21144
            },
            "entity": {
                "abbreviation": "Corte Madera",
                "alternates": {
                    "nces": "063147004881"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "513ad7ff-a16d-4f72-aaf3-f03cf0caa8ef",
                "isClaimed": false,
                "isPremium": false,
                "location": "Portola Valley, CA",
                "name": "Corte Madera",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2a38bec5-e570-4b44-a915-cb47120424cd",
                    "schoolDistrict": "2a38bec5-e570-4b44-a915-cb47120424cd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cb45ddc0-9411-42ef-a6cd-bce00ea8d969",
                    "zipCode": "4cc8743e-d0d7-450b-805a-b54edc5bdbf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Portola Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2a38bec5-e570-4b44-a915-cb47120424cd",
                        "location": "Portola Valley, CA",
                        "name": "Portola Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cb45ddc0-9411-42ef-a6cd-bce00ea8d969",
                            "zipCode": "4cc8743e-d0d7-450b-805a-b54edc5bdbf5"
                        },
                        "premium": false,
                        "shortName": "Portola Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "Portola Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "portola-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Corte Madera",
                "tagline": [
                    "Portola Valley Elementary School District, CA",
                    "4-8"
                ],
                "type": "School",
                "url": "corte-madera-portola-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 309
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Corte Madera has low teacher to student ratios and good academic achievement scores. Many elective and after school options (sports, clubs etc). The campus is safe and beautiful. Excellent school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-10-04T16:00:59.46246Z",
                "guid": "df1baed7-dda1-4912-bf8b-8021c9d858ee",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.212,37.368,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.212,37.368,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94028"
            }
        ]
    },
    {
        "guid": "68a839fb-d04e-4301-ac88-8a7398a97f75",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 13,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.396738,
                "lon": -122.117957
            },
            "entity": {
                "abbreviation": "BCS",
                "alternates": {
                    "nces": "060241511442"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "68a839fb-d04e-4301-ac88-8a7398a97f75",
                "isClaimed": true,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Bullis Charter School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                },
                "premium": false,
                "shortName": "Bullis Charter",
                "tagline": [
                    "Public School",
                    "Los Altos, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bullis-charter-school-los-altos-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 915
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "BCS has very strong emphasis on both STEM and the arts which meant that I felt very well rounded when I left after 6th grade. They certainly were a little untraditional at times, especially with such a heavy arts presence (which I loved) and a focus on project based learning (\"PBL\"), but I think this lack of traditional learning was what made BCS so fun as an elementary student. I experienced a few iffy teachers, but the other teachers made up for it by far.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-11T05:23:57.266696Z",
                "guid": "7e3c2bda-d520-4633-b429-ec926c2f1812",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.116471,37.39581,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.116471,37.39581,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.9166666666666665,
                "count": 12
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94022"
            }
        ]
    },
    {
        "guid": "54b377f4-6351-451e-857b-a0c985c02e04",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 14,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.566076,
                "lon": -122.362813
            },
            "entity": {
                "abbreviation": "Crocker Middle School",
                "alternates": {
                    "nces": "061719002172"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "54b377f4-6351-451e-857b-a0c985c02e04",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hillsborough, CA",
                "name": "Crocker Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "schoolDistrict": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "HiIlsborough City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc25cc08-bb54-4104-88fe-58456acabf26",
                        "location": "Hillsborough, CA",
                        "name": "HiIlsborough City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "HiIlsborough City School District",
                        "tagline": [
                            "School District",
                            "Hillsborough, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "hiilsborough-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Crocker Middle School",
                "tagline": [
                    "HiIlsborough City School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "crocker-middle-school-hillsborough-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 465
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "It was basically a free private school a few years ago, but has slipped a little. All of the good teachers have started to leave and advanced courses have been pulled. Math and Science are neglected courses. But, the kids who attend are excellent!  They really make your overall crocker experience unforgettable. I would recommend this school of you're looking for a pretty good public middle school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-10-18T04:29:41.495276Z",
                "guid": "deeea1da-505f-4f5d-afe3-978636e80bd7",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.363,37.5665,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.363,37.5665,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "231e9b85-30b7-4bca-ad3a-4cf42b9b3d7f",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 15,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.799732,
                "lon": -121.898794
            },
            "entity": {
                "abbreviation": "Diablo Vista Middle School",
                "alternates": {
                    "nces": "063513008460"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "231e9b85-30b7-4bca-ad3a-4cf42b9b3d7f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Diablo Vista Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "3e1ec33e-a148-4e8a-9b40-fb03abc83c61"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Diablo Vista Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "diablo-vista-middle-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 986
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "At Diablo Vista I met all my current friends and had a really great time. The hardness of the classes vary in the same subject, meaning that you may have a really easy teacher, but your friend has to work super hard to keep an A.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-05-25T00:30:05.330787Z",
                "guid": "a49ecd5d-64f2-48c8-966b-3aa0aee746a9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.898,37.7991,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.898,37.7991,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94506"
            }
        ]
    },
    {
        "guid": "5177d15d-aa28-496f-83c0-b86738dc0620",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 16,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.962187,
                "lon": -122.556897
            },
            "entity": {
                "abbreviation": "Ross Elementary School",
                "alternates": {
                    "nces": "063366005192"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5177d15d-aa28-496f-83c0-b86738dc0620",
                "isClaimed": false,
                "isPremium": false,
                "location": "Ross, CA",
                "name": "Ross Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e7373d6f-098a-470c-b7c5-fea4c43fe457",
                    "zipCode": "af1937e2-b1ea-48c6-b5cc-ff1e59c2c43e"
                },
                "premium": false,
                "shortName": "Ross Elementary School",
                "tagline": [
                    "Public School",
                    "Ross, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "ross-elementary-school-ross-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 394
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 15
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.558,37.9613,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.558,37.9613,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94957"
            }
        ]
    },
    {
        "guid": "e6b5da28-1040-4e17-86f3-e281e353c514",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 17,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.42864,
                "lon": -122.200505
            },
            "entity": {
                "abbreviation": "La Entrada Middle School",
                "alternates": {
                    "nces": "062238002674"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e6b5da28-1040-4e17-86f3-e281e353c514",
                "isClaimed": false,
                "isPremium": false,
                "location": "Menlo Park, CA",
                "name": "La Entrada Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7b0de5ef-e84c-42d7-b2b1-c1fe3f6e8861",
                    "schoolDistrict": "7b0de5ef-e84c-42d7-b2b1-c1fe3f6e8861",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "22bb5284-8004-4b40-8e2f-7523a5c756cf",
                    "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Las Lomitas Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7b0de5ef-e84c-42d7-b2b1-c1fe3f6e8861",
                        "location": "Menlo Park, CA",
                        "name": "Las Lomitas Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "22bb5284-8004-4b40-8e2f-7523a5c756cf",
                            "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                        },
                        "premium": false,
                        "shortName": "Las Lomitas Elementary School District",
                        "tagline": [
                            "School District",
                            "Menlo Park, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "las-lomitas-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "La Entrada Middle School",
                "tagline": [
                    "Las Lomitas Elementary School District, CA",
                    "4-8"
                ],
                "type": "School",
                "url": "la-entrada-middle-school-menlo-park-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 745
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I have had a great experience at La Entrada. The teachers are always trying their best to help their students and you can get extra help easily if needed. There are always new students coming in from different countries making it a diverse school. La Entrada has prepared me for high school amazingly, if you are reading this deciding if you want to come here, do it!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-21T20:07:06.480097Z",
                "guid": "2c4cd077-21d4-4648-a3a0-107a09038ca2",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.202,37.4279,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.202,37.4279,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.666666666666667,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94025"
            }
        ]
    },
    {
        "guid": "274fa25a-a3f7-4a42-8d05-2fe50dd23721",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 18,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.364629,
                "lon": -122.08285
            },
            "entity": {
                "abbreviation": "Georgina P. Blach Junior High School",
                "alternates": {
                    "nces": "062265002783"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "274fa25a-a3f7-4a42-8d05-2fe50dd23721",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Georgina P. Blach Junior High School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Georgina P. Blach Junior High School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "georgina-p-blach-junior-high-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 499
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The school is one of the best and my 3 kids went to 3 different middle schools. The Principal and staff understand the needs of this age group so well and the environment is kind and student friendly. I wish my child go there for longer than 2 years!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-02-25T21:01:27.88608Z",
                "guid": "8d6a3adf-e41d-47c8-9881-59ea6866e5c9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.082,37.3638,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.082,37.3638,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94024"
            }
        ]
    },
    {
        "guid": "78acbb60-dee7-47f1-b0ba-1af03a977384",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 19,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.765766,
                "lon": -121.951909
            },
            "entity": {
                "abbreviation": "Iron Horse Middle School",
                "alternates": {
                    "nces": "063513005729"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "78acbb60-dee7-47f1-b0ba-1af03a977384",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Iron Horse Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f22524cd-34a7-482a-818a-4e11ae58abea",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Iron Horse Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "iron-horse-middle-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1069
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "What I like about Iron Horse is that people are friendly there. There are several electives that you can choose from. From the electives, you could have 2 year longs, 4 semester long, or 1 year long and 2 semester long electives. One of my favorite thing is  called \"tutorial.\" Tutorial is a 30 minutes on Tuesday and Thursday where you can pick an activity\n There is diversity. What I would like that would change is that people would be nicer.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-19T20:01:41.642306Z",
                "guid": "ce787535-e731-4e57-abd4-0a2b5ee77757",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.958,37.7697,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.958,37.7697,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.25,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "45d95fc4-9766-4d0e-99c6-5e1331bcd09c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 20,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.739521,
                "lon": -121.941287
            },
            "entity": {
                "abbreviation": "Pine Valley Middle School",
                "alternates": {
                    "nces": "063513005955"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "45d95fc4-9766-4d0e-99c6-5e1331bcd09c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Pine Valley Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "54c77220-1c02-48f7-a584-28d7571918f4",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pine Valley Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "pine-valley-middle-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1049
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "I went to Pine Valley Middle School almost 4 years ago and I had a great experience there. It taught me high school readiness and taught me social and presentations skills.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-03-20T00:30:50.206504Z",
                "guid": "6976ab8e-63c2-4641-833c-165fd1533750",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.7379,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.7379,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "6d252787-0476-4506-acb7-31158816fa89",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 21,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.7551729,
                "lon": -122.4087206
            },
            "entity": {
                "abbreviation": "Mission Preparatory",
                "alternates": {
                    "nces": "060223612768"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6d252787-0476-4506-acb7-31158816fa89",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Mission Preparatory",
                "parentGUIDs": {
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0cac67cc-9c02-4a4c-8cd6-5714b7fda8b7",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "c568e3a2-4c27-4503-a9f7-85276a6ec461"
                },
                "premium": false,
                "shortName": "Mission Preparatory",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "PK, K-8"
                ],
                "type": "School",
                "url": "mission-preparatory-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 385
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I'm a current student in Mission Preparatory and it's great. Since it's a new school, I think it should have more clubs and sports, but academics are great. Teachers and staff are very supportive and encouraging.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-22T20:13:49.288019Z",
                "guid": "8e14ea06-bd76-4724-a975-02fc4b09c0b9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4087206,37.7551729,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4087206,37.7551729,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94112"
            }
        ]
    },
    {
        "guid": "4e8f8144-54a2-421d-aee4-58af31988700",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 22,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.856256,
                "lon": -122.018895
            },
            "entity": {
                "abbreviation": "Stone Valley Middle School",
                "alternates": {
                    "nces": "063513005958"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4e8f8144-54a2-421d-aee4-58af31988700",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alamo, CA",
                "name": "Stone Valley Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6cd2db24-61d9-4c4b-9dba-1b8592c736a6",
                    "zipCode": "70fa1630-e849-423c-9be9-dcec4af8d465"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Stone Valley Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "stone-valley-middle-school-alamo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 591
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "I would choose this school again.  In fact, I had 3 daughters go to this school. I find that the education they received, prepared them to do well in high school.  There are many ways to get involved, many clubs to join, athletics and academics, leadership etc.  Most teachers are reputable and well liked.  There are volunteer opportunities for the parents, however, the school is not overly pushy so you don't feel obligated or guilty if you are not able to volunteer.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2015-07-23T16:20:34Z",
                "guid": "371a9d11-31cb-4e97-9043-8611fa69b8c0",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.021,37.8568,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.021,37.8568,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94507"
            }
        ]
    },
    {
        "guid": "5d0c58bb-222a-44ad-8bf1-1ffb416710b3",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 23,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.911484,
                "lon": -122.00116
            },
            "entity": {
                "abbreviation": "Windemere Ranch Middle School",
                "alternates": {
                    "nces": "063513010759"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5d0c58bb-222a-44ad-8bf1-1ffb416710b3",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Windemere Ranch Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Windemere Ranch Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "windemere-ranch-middle-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1355
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "It is a safe and clean school. The teachers care about their students and make an effort to help and encourage them to do their best and feel like they belong. The academic and behavioral expectations are set high, but teachers guide you to achieve a high standard and prepare students for the work given in high school. More emphasis on balance in a student's life is important, and although Windemere is a good school, it needs to remind students that GPA, homework, and test grades alone do not determine how smart or valuable a student is.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T07:32:02.930126Z",
                "guid": "65df5787-adde-4d81-a358-a3abfcbff985",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.905,37.752,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.905,37.752,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.166666666666667,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "864ffbba-77d6-4438-9737-930b1b9757bc",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 24,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.897835,
                "lon": -122.295575
            },
            "entity": {
                "abbreviation": "Albany Middle School",
                "alternates": {
                    "nces": "060186000060"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "864ffbba-77d6-4438-9737-930b1b9757bc",
                "isClaimed": false,
                "isPremium": false,
                "location": "Albany, CA",
                "name": "Albany Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "schoolDistrict": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f3a34422-94be-42dd-b22b-9ec647ebed62",
                    "zipCode": "b0469f0b-b037-4ab0-9a8e-d888278d5110"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Albany City Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                        "location": "Berkeley, CA",
                        "name": "Albany City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "39b2df86-684a-467f-9c95-5540b32110f5"
                        },
                        "premium": false,
                        "shortName": "Albany City Unified School District",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "albany-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Albany Middle",
                "tagline": [
                    "Albany City Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "albany-middle-school-albany-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 862
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Its very diverse there but the education could be better. And the living cost is way too high. Good luck.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-04-30T21:36:30.005801Z",
                "guid": "080097b4-4a3f-42f5-89fd-0a553ce81afb",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.295,37.8979,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.295,37.8979,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.3333333333333335,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94706"
            }
        ]
    },
    {
        "guid": "950f478f-b776-4430-8392-db6d7f381fdf",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 25,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.943449,
                "lon": -121.955254
            },
            "entity": {
                "abbreviation": "Gale Ranch Middle School",
                "alternates": {
                    "nces": "063513012300"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "950f478f-b776-4430-8392-db6d7f381fdf",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Gale Ranch Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "64ec8361-307f-4c60-8518-5e08e7cc3cb0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Gale Ranch Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "gale-ranch-middle-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1262
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "I had a pretty great three years here. The teachers were amazing and supportive. I made a great group of friends, and people were friendly(but there were always THOSE people but you can find them anywhere). In this area, diversity isn't very strong. The school's mostly Asians, so seeing other ethnic groups are a small minority. I remember in 6th grade I had just moved from a predominantly white area. At lunch, I was amazed at the number of heads with dark brown/black hair like me! Basically the whole cafeteria. The diversity didn't really affect my life either way though, and people were just people. Overall, it was pretty much just happiness for me. Yeah until 7th grade where I started crying over a boy. Then 8th grade was kinda boring and corona hit.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-09-15T07:25:18.361143Z",
                "guid": "25a7bd83-abad-4e23-9853-c0bcbfb586bd",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.91,37.7683,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.91,37.7683,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.333333333333333,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "62d01778-edc4-48b8-ae0b-f46308ed56f4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 26,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.920743,
                "lon": -122.095982
            },
            "entity": {
                "abbreviation": "Eleanor Murray Fallon",
                "alternates": {
                    "nces": "060001913169"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "62d01778-edc4-48b8-ae0b-f46308ed56f4",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "Eleanor Murray Fallon",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Eleanor Murray Fallon",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "eleanor-murray-fallon-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1557
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "High School Freshman",
                "body": "Fallon Middle school is very unique for its rigorous PE program, which helps to build a strong foundation for students' well-being. The curriculum includes a diverse variety of sports, including less commonly offered sports such as Archery, Cricket, Pickle Ball, and Dance. A key feature of Fallon's PE program is the weekly run day. Depending on the week of the month, students will run a 2-mile run, PACER, Fallon Fitness Run, Off-Campus Run, or a mile run. These runs push students to their limits, enabling them to grow stronger physically and mentally.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-02-01T23:52:48Z",
                "guid": "888dc542-eaaa-4532-b543-46dc0fa3f519",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.866,37.7157,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.866,37.7157,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "59cb4dcd-c101-46d0-8243-36df71884b03",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 27,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.396813,
                "lon": -122.117935
            },
            "entity": {
                "abbreviation": "Ardis G. Egan Junior High School",
                "alternates": {
                    "nces": "062265002786"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "59cb4dcd-c101-46d0-8243-36df71884b03",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Ardis G. Egan Junior High School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ardis G. Egan Junior High School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "ardis-g-egan-junior-high-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 585
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "The teachers are very good, however there is a significantly unfair push for higher math classes in lower grades. Overall a great school!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-05T02:23:30.857074Z",
                "guid": "6657ca15-2fcb-497b-b339-b3dda6072741",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.116,37.3958,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.116,37.3958,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8,
                "count": 10
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94022"
            }
        ]
    },
    {
        "guid": "c9844176-3b21-44fb-9743-53d327b68e2d",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 28,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.826959,
                "lon": -122.130467
            },
            "entity": {
                "abbreviation": "Joaquin Moraga Intermediate",
                "alternates": {
                    "nces": "062574003851"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c9844176-3b21-44fb-9743-53d327b68e2d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Moraga, CA",
                "name": "Joaquin Moraga Intermediate School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9992c13f-d382-4666-b608-06b81ea52d9e",
                    "schoolDistrict": "9992c13f-d382-4666-b608-06b81ea52d9e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                    "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moraga Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9992c13f-d382-4666-b608-06b81ea52d9e",
                        "location": "Moraga, CA",
                        "name": "Moraga Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                            "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                        },
                        "premium": false,
                        "shortName": "Moraga Elementary School District",
                        "tagline": [
                            "School District",
                            "Moraga, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moraga-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Joaquin Moraga Intermediate",
                "tagline": [
                    "Moraga Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "joaquin-moraga-intermediate-school-moraga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 655
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.131,37.8253,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.131,37.8253,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94556"
            }
        ]
    },
    {
        "guid": "40e78a49-f8d1-4e38-8bc0-edd692a4fe93",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 29,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.507837,
                "lon": -122.285014
            },
            "entity": {
                "abbreviation": "San Carlos Charter Learning Center",
                "alternates": {
                    "nces": "060184204029"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "40e78a49-f8d1-4e38-8bc0-edd692a4fe93",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "San Carlos Charter Learning Center",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "premium": false,
                "shortName": "San Carlos Charter Learning Center",
                "tagline": [
                    "Public School",
                    "San Carlos, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "san-carlos-charter-learning-center-san-carlos-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 385
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Charter is a very good school with lots of group projects and involvement.  They have lots of special activities and programs for children with learning difficulties. Parents are super involved and teachers are committed and ready to provide 1:1 guidance.  For children who need advanced studies, the teachers will provide differential learning. The students are super friendly and ready to learn. There is a lot of focus on social emotional learning and teamwork.  The  administration is kind and helpful.  The school applies Project Based Learning  and hand-on activities. Students get lots of choice in what they do.\n\nMy one problem though, is that in the new campus made of shipping containers, whenever there is motion on the top floor,  it makes a really loud sound on the bottom floor. This becomes a real difficulty for students k-2 and in the  library. But  they  are  working it out in different ways including doing a fun art project with egg cartons.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-30T02:36:52.536777Z",
                "guid": "f45a6bfe-6b14-496e-93d4-de754867fb85",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.286,37.5089,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.286,37.5089,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "b112361c-0c74-4e7e-bad7-82fccc1e35de",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 30,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.507837,
                "lon": -122.285014
            },
            "entity": {
                "abbreviation": "Tierra Linda Middle School",
                "alternates": {
                    "nces": "063429008627"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "b112361c-0c74-4e7e-bad7-82fccc1e35de",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "Tierra Linda Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Tierra Linda Middle School",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "tierra-linda-middle-school-san-carlos-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 701
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The school and school district have been very helpful during the distance learning transition.  Very transparent on what's happening and how for parents to help their students.  \n\nSchool resources and teaching quality are good.  Just like any school, how much you get out of it depends on how much you put in.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-06T18:59:15.11258Z",
                "guid": "953007ae-3b1a-4439-ba26-a27bce192aca",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.286,37.5089,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.286,37.5089,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "02997e29-d144-4861-b4c7-511c0aaa5b1c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 31,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.438479,
                "lon": -122.195462
            },
            "entity": {
                "abbreviation": "Hillview Middle School",
                "alternates": {
                    "nces": "062457003683"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "02997e29-d144-4861-b4c7-511c0aaa5b1c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Menlo Park, CA",
                "name": "Hillview Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "schoolDistrict": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "22bb5284-8004-4b40-8e2f-7523a5c756cf",
                    "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Menlo Park City Elementary School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                        "location": "Atherton, CA",
                        "name": "Menlo Park City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                            "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                        },
                        "premium": false,
                        "shortName": "Menlo Park City Elementary School District",
                        "tagline": [
                            "School District",
                            "Atherton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "menlo-park-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Hillview Middle School",
                "tagline": [
                    "Menlo Park City Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "hillview-middle-school-menlo-park-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 968
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "This school is absolutely amazing! We have so many great teachers. We also have 1:1 iPads. Additionally we have a wonderful variety of electives from drama to art to robotics. There is also a wonderful library with an amazing librarian.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-29T14:56:10.010442Z",
                "guid": "86306428-16a4-4663-94db-91cabf99cc24",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.195,37.4399,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.195,37.4399,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94025"
            }
        ]
    },
    {
        "guid": "26d839df-5c4b-49d0-bd17-17e35e3cdb17",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 32,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.068041,
                "lon": -122.703112
            },
            "entity": {
                "abbreviation": "Nicasio",
                "alternates": {
                    "nces": "062730004135"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "26d839df-5c4b-49d0-bd17-17e35e3cdb17",
                "isClaimed": false,
                "isPremium": false,
                "location": "Nicasio, CA",
                "name": "Nicasio",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "8ca68bc1-015b-4525-9626-5f2e2f9421f3"
                },
                "premium": false,
                "shortName": "Nicasio",
                "tagline": [
                    "Public School",
                    "Nicasio, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "nicasio-nicasio-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 39
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.704,38.0684,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.704,38.0684,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94946"
            }
        ]
    },
    {
        "guid": "22c725a4-b539-408c-a145-426f494835db",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 33,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.838091,
                "lon": -121.982781
            },
            "entity": {
                "abbreviation": "Los Cerros Middle School",
                "alternates": {
                    "nces": "063513005950"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "22c725a4-b539-408c-a145-426f494835db",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Los Cerros Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Los Cerros Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "los-cerros-middle-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 645
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.981,37.8391,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.981,37.8391,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "531dc4eb-ea97-414d-92b5-88a989fc38e4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 34,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.950821,
                "lon": -122.549734
            },
            "entity": {
                "abbreviation": "Adaline E. Kent Middle School",
                "alternates": {
                    "nces": "061938002329"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "531dc4eb-ea97-414d-92b5-88a989fc38e4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Kentfield, CA",
                "name": "Adaline E. Kent Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "8de7e676-eed5-4a72-95d9-91bf80c34b7c",
                    "schoolDistrict": "8de7e676-eed5-4a72-95d9-91bf80c34b7c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f147eb0c-7f22-44ef-8ab8-f959183ec46c",
                    "zipCode": "3e3caa89-6ba8-4a2f-b748-8dc0f34bf648"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Kentfield Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8de7e676-eed5-4a72-95d9-91bf80c34b7c",
                        "location": "Kentfield, CA",
                        "name": "Kentfield School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "f147eb0c-7f22-44ef-8ab8-f959183ec46c",
                            "zipCode": "3e3caa89-6ba8-4a2f-b748-8dc0f34bf648"
                        },
                        "premium": false,
                        "shortName": "Kentfield Elementary School District",
                        "tagline": [
                            "School District",
                            "Kentfield, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "kentfield-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Adaline E. Kent Middle School",
                "tagline": [
                    "Kentfield School District, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "adaline-e-kent-middle-school-kentfield-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 587
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "featuredReview": {
                "author": "Sophomore",
                "body": "I absolutely love this school.  I graduated a few years ago.  I have met my best friends here.  The academics are excellent, but not stressful.  The teachers really care and want you to do well.  The downside of this school is that if you have bad people in your grade you are basically screwed because you will be miserable, but I lucked out and I loved my grade.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-14T01:01:21.786524Z",
                "guid": "17596a6b-4274-4227-9b98-7d783caf5ec0",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.547,37.9533,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.547,37.9533,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94904"
            }
        ]
    },
    {
        "guid": "91380efb-8639-46b8-bc38-4cfc24c6f3e2",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 35,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.779548,
                "lon": -122.240325
            },
            "entity": {
                "abbreviation": "Downtown Charter Academy",
                "alternates": {
                    "nces": "060149313752"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "91380efb-8639-46b8-bc38-4cfc24c6f3e2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Downtown Charter Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "13143c54-a9b4-4958-8aec-fb1609695702"
                },
                "premium": false,
                "shortName": "Downtown Charter Academy",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "downtown-charter-academy-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 305
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Downtown Charter Academy has taught me not to judge people too quickly based on just one thing. Instead, I was taught to give the person a chance before I judge them. This school has great staff members and will treat you well if you treat them just as well!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-06-11T06:26:09.271314Z",
                "guid": "d2d83b46-c5b1-459e-b5dc-8b3c93861cda",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.242,37.7796,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.242,37.7796,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.75,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94606"
            }
        ]
    },
    {
        "guid": "bdfbd876-16ce-4e36-9ef5-96da0c54691f",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 36,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.33914,
                "lon": -122.05939
            },
            "entity": {
                "abbreviation": "Cupertino Middle School",
                "alternates": {
                    "nces": "061029001127"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bdfbd876-16ce-4e36-9ef5-96da0c54691f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Cupertino Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cupertino Middle School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "cupertino-middle-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1358
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Cupertino had very rigorous classes and had amazing teachers. When I was a student, I found math to be a very challenging subject, but since I had a very awesome math teacher, I thrived! Cupertino also had a very awesome P.E. department with sports ranging from soccer, to field hockey, to lacrosse, to track and field. Overall, my stay at Cupertino was well lived. What I'd like to see change is how children actively participate in the brunch and lunch activities. When I was attending school here, that was not happening much. -CMS Graduate Class of 2015",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-03-26T21:36:15.959489Z",
                "guid": "d11ff40f-c311-44c6-9a0d-9d1df892e862",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.059,37.339,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.059,37.339,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94087"
            }
        ]
    },
    {
        "guid": "8a298fb6-cb6f-4fe6-8756-d0090ca8d9d1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 37,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.800725,
                "lon": -121.97707
            },
            "entity": {
                "abbreviation": "Charlotte Wood Middle School",
                "alternates": {
                    "nces": "063513005944"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8a298fb6-cb6f-4fe6-8756-d0090ca8d9d1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Charlotte Wood Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Charlotte Wood Middle School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "charlotte-wood-middle-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 978
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.802,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.802,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "245acc48-5a15-4289-84b2-5f60d4102396",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 38,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.267458,
                "lon": -122.013955
            },
            "entity": {
                "abbreviation": "Redwood Middle School",
                "alternates": {
                    "nces": "063591006150"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "245acc48-5a15-4289-84b2-5f60d4102396",
                "isClaimed": false,
                "isPremium": false,
                "location": "Saratoga, CA",
                "name": "Redwood Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "47e72105-df42-4803-af88-d3e2e6e78efd",
                    "schoolDistrict": "47e72105-df42-4803-af88-d3e2e6e78efd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Saratoga Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "47e72105-df42-4803-af88-d3e2e6e78efd",
                        "location": "Saratoga, CA",
                        "name": "Saratoga Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                            "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                        },
                        "premium": false,
                        "shortName": "Saratoga Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Saratoga, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "saratoga-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Redwood Middle School",
                "tagline": [
                    "Saratoga Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "redwood-middle-school-saratoga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 761
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I had an overall great time here during my middle school years that eventually prepared me for one of the top public high schools in the nation. I am grateful for my teachers for my experience.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-06-04T18:48:46.354539Z",
                "guid": "c3cf4e53-2c5d-429e-a45e-955ad11a1874",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.015,37.2675,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.015,37.2675,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.375,
                "count": 8
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95070"
            }
        ]
    },
    {
        "guid": "b9968535-646e-4633-b053-b364d0a9cede",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 39,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.500943,
                "lon": -122.262103
            },
            "entity": {
                "abbreviation": "Central Middle School",
                "alternates": {
                    "nces": "063429005402"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b9968535-646e-4633-b053-b364d0a9cede",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "Central Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Central Middle School",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "central-middle-school-san-carlos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 518
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Central was pretty good, I had a good time there. Felt like I knew just about everything I needed to know when entering high school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T02:40:00.673385Z",
                "guid": "1d3af0b2-b0fb-47a4-b792-b4d9ff026635",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.261,37.5006,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.261,37.5006,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "6c7d11ce-9182-44fb-8547-dc5f54c8f956",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 40,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.6745,
                "lon": -122.15
            },
            "entity": {
                "abbreviation": "KIPP Summit Academy",
                "alternates": {
                    "nces": "060245711159"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6c7d11ce-9182-44fb-8547-dc5f54c8f956",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Lorenzo, CA",
                "name": "KIPP Summit Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e97f4f73-e7d3-41d6-a288-8114432efadc",
                    "zipCode": "5820f130-05d4-461d-b769-af76b2295d66"
                },
                "premium": false,
                "shortName": "KIPP Summit Academy",
                "tagline": [
                    "Public School",
                    "San Lorenzo, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "kipp-summit-academy-san-lorenzo-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 413
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Sophomore",
                "body": "The teachers of KIPP: Summit were some of the most supportive, caring people I've had the honor to meet. The care they put into each student really set each and every one of us up for high school. Expectations were high, and teachers set up students to meet the challenge. While the school was fantastic academically and supportively, I would have liked to see more experience outside the classroom. Funding for the school is limited, and is therefore put less into field trips and activities. However, the amount of time and care each teacher puts into the school is unmatched anywhere else, and outweighs the lack of funding for field trips and extra-curricular's.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-24T02:47:29.547449Z",
                "guid": "ebb7acce-f159-4947-af84-84a7be1e50f3",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.15,37.6745,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.15,37.6745,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5454545454545454,
                "count": 11
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94580"
            }
        ]
    },
    {
        "guid": "f9d997d8-6c3b-4906-b725-071886ece108",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 41,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.753405,
                "lon": -122.23162
            },
            "entity": {
                "abbreviation": "Lincoln Middle School",
                "alternates": {
                    "nces": "060177000049"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f9d997d8-6c3b-4906-b725-071886ece108",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Lincoln Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lincoln Middle School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "lincoln-middle-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 872
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Great school with students and teachers who truly care about academics. Students have a weekly running day in PE. There's a lot of club and activities.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-07T04:15:17.463573Z",
                "guid": "92e99e26-31d8-41bf-87e8-7c9edd577930",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.232,37.753,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.232,37.753,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "a929f97c-1088-4b96-bc6e-2caf5f1aca10",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 42,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.746372,
                "lon": -122.251594
            },
            "entity": {
                "abbreviation": "Bay Farm School",
                "alternates": {
                    "nces": "060177000006"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a929f97c-1088-4b96-bc6e-2caf5f1aca10",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Bay Farm School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "7195f3ef-44f9-4ce1-8865-b2ce025097c8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bay Farm School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bay-farm-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 610
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.7441,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.7441,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94502"
            }
        ]
    },
    {
        "guid": "b9fbfdd2-2035-4d43-acdd-d7efedbf7524",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 43,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.537956,
                "lon": -121.991933
            },
            "entity": {
                "abbreviation": "Circle of Independent Learning Charter School",
                "alternates": {
                    "ceeb": "051061",
                    "nces": "061440007479"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "b9fbfdd2-2035-4d43-acdd-d7efedbf7524",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Circle of Independent Learning Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Circle of Independent Learning Charter School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "circle-of-independent-learning-charter-school-fremont-ca",
                "variation": 52
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 368
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The pandemic year was a perfect time for us to try  COIL. My son had begged me to homeschool him for over a year, and being in the tradition public school classroom was not working for him. I wish I had listened to him earlier. Within one semester at COIL, my son was able to rehabilitate his relationship with learning while building the confidence and the skills to do independent learning. It is amazing thing to see your child thrive again after years of a downward trend. He is actually getting A’s B’s and have favorite subjects that are not recess again. COIL has been a wonderful resource for kids who don’t fit well in a traditional public school setting for various reasons, from being able to advance at one’s own pace at math, extracurricular pursuits, athletics, to medical health reasons. It has helped me to understand my learners and work with them and try different things that would not be possible in a traditional public school setting.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-06-04T05:25:15.63439Z",
                "guid": "26ace910-1314-4eca-8f2d-d7a77f13d327",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.991,37.5383,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.991,37.5383,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.275862068965517,
                "count": 29
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "c0abb383-e214-414f-8405-b9fc432355c5",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 44,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.900669,
                "lon": -122.112327
            },
            "entity": {
                "abbreviation": "M. H. Stanley Middle School",
                "alternates": {
                    "nces": "062031002449"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c0abb383-e214-414f-8405-b9fc432355c5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Lafayette, CA",
                "name": "M.H. Stanley Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                    "schoolDistrict": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                    "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Lafayette Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                        "location": "Lafayette, CA",
                        "name": "Lafayette Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                            "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                        },
                        "premium": false,
                        "shortName": "Lafayette Elementary School District",
                        "tagline": [
                            "School District",
                            "Lafayette, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "lafayette-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "M. H. Stanley Middle School",
                "tagline": [
                    "Lafayette Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "mh-stanley-middle-school-lafayette-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1227
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I attended Stanley Middle School for two years from 7th to 8th. I really enjoyed the educational environment there and my friends and faculty members were extremely supportive of my academics. I wish I could return to my middle school life as a student at Stanley had I ever had a chance to do so.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-10-18T15:21:29.993487Z",
                "guid": "833376b6-9140-4df3-b280-c4eadfa62ff8",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.114,37.8871,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.114,37.8871,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94549"
            }
        ]
    },
    {
        "guid": "b195d911-37ea-4854-acaa-fc7d53b3c4b0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 45,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.712626,
                "lon": -121.918681
            },
            "entity": {
                "abbreviation": "Wells Middle School",
                "alternates": {
                    "nces": "060001909278"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "b195d911-37ea-4854-acaa-fc7d53b3c4b0",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "Wells Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Wells Middle School",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "wells-middle-school-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 996
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.919,37.7118,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.919,37.7118,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "363dcee4-d2c0-4d10-9256-de245a66b8b2",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 46,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.779598,
                "lon": -122.28794
            },
            "entity": {
                "abbreviation": "ACLC",
                "alternates": {
                    "ceeb": "050011",
                    "nces": "060166408673"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "363dcee4-d2c0-4d10-9256-de245a66b8b2",
                "isClaimed": true,
                "isPremium": true,
                "location": "Alameda, CA",
                "name": "Alameda Community Learning Center",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "premium": true,
                "shortName": "Alameda Community Learning Center",
                "tagline": [
                    "Public School",
                    "Alameda, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "alameda-community-learning-center-alameda-ca",
                "variation": 1056
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 351
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "The class sizes at my school are small, so teachers can individually help every student. The school population is also small. More people leave than those who apply so all of us are pretty close. The low school population is mostly due to the size of our campus, and our general lack of regular high school things like sports games, band, and theatre. The main upside of our school from an academic standpoint is that students are required to take classes at the nearby community college, and the school pays for the classes. In my sophomore year, I took Psychology 101 and Beginners Ceramics. These college classes gave me the opportunity to explore my interests, meet people in different fields, and get college credits (which will make college cheaper for me). Another upside of my school is that the teachers that stay care about us. My digital music teacher is like a father to many, and my humanities teachers feel like mentors. I feel that I made a good choice, attending ACLC",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-06T02:08:56.112281Z",
                "guid": "603c70af-417a-4f91-b4bb-20aff085c433",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "default": {
                    "crops": {
                        "DesktopHeader": "https://s3.amazonaws.com/photos.niche.com/c30dcd6b87a3f97dff16f77d956ed5daea803106",
                        "MobileHeader": "https://s3.amazonaws.com/photos.niche.com/72293086ff014ecbc765a920d147d65b42a33d63",
                        "Thumbnail": "https://s3.amazonaws.com/photos.niche.com/c0c27db8a0b8376ba22fb6c6eb2db8238aad4aff"
                    },
                    "licenseName": "UNLICENSED"
                },
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.287,37.7792,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.287,37.7792,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.625,
                "count": 24
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "4e31d517-7d83-476c-bbe2-7d5b07b725a0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 47,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.231592,
                "lon": -121.967471
            },
            "entity": {
                "abbreviation": "Raymond J. Fisher Middle School",
                "alternates": {
                    "nces": "062283003495"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4e31d517-7d83-476c-bbe2-7d5b07b725a0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "Raymond J. Fisher Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2657e046-c2e2-4cea-822c-45bceb563d45",
                    "schoolDistrict": "2657e046-c2e2-4cea-822c-45bceb563d45",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Gatos Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2657e046-c2e2-4cea-822c-45bceb563d45",
                        "location": "Los Gatos, CA",
                        "name": "Los Gatos Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                            "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                        },
                        "premium": false,
                        "shortName": "Los Gatos Union School District",
                        "tagline": [
                            "School District",
                            "Los Gatos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-gatos-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Raymond J. Fisher Middle School",
                "tagline": [
                    "Los Gatos Union School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "raymond-j-fisher-middle-school-los-gatos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1269
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Great teachers. Great kids. Great town. Nothing amazing, but solid. Typical middle school experience with cliques, nerds, and bullying. I enjoyed Fisher. It was challenging enough to prepare me for high school, but not too hard. Some drug problems in recent years, but not while I was there.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-13T21:08:55.207794Z",
                "guid": "b6a143c6-aaab-4c47-9f06-a47d123fff5a",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.969,37.2329,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.969,37.2329,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95032"
            }
        ]
    },
    {
        "guid": "07444d8e-d4aa-47c4-884b-0fcdfbd6da85",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 48,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.859673,
                "lon": -122.258783
            },
            "entity": {
                "abbreviation": "Willard Middle School",
                "alternates": {
                    "nces": "060474007267"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "07444d8e-d4aa-47c4-884b-0fcdfbd6da85",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Willard Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "6f1cd314-8bca-4f9f-b431-9c4c14bb56a8",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "b9dd7946-c2a7-4ae6-8e94-d5fea0a9aa3d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Willard Middle School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "willard-middle-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 667
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.258,37.8606,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.258,37.8606,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94705"
            }
        ]
    },
    {
        "guid": "0a8d1a62-8c42-48d4-9714-c0365aa048bc",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 49,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.751599,
                "lon": -122.157405
            },
            "entity": {
                "abbreviation": "Francophone Charter School of Oakland",
                "alternates": {
                    "nces": "060183913942"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "0a8d1a62-8c42-48d4-9714-c0365aa048bc",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Francophone Charter School of Oakland",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "3816271d-bf9c-431c-9e92-8f63ddf53940"
                },
                "premium": false,
                "shortName": "Francophone Charter School of Oakland",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "francophone-charter-school-of-oakland-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 226
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We've been here for a few years now.  I have been extremely impressed with the teaching.  We attended a private French immersion school before this, and I think that academically speaking, this is very similar in terms of the teaching and curriculum, only it's free!  I'd also say the student/family community is very warm and diverse in every sense of the word.  So we're very glad we made the switch.  It is still a growing school and sometimes the systems and processes are a bit messy and disorganized, that I would say is the main downside.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-06T21:40:31.87193Z",
                "guid": "ecd4813a-ee29-44d0-ad17-7813011a8b17",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.157,37.7514,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.157,37.7514,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94605"
            }
        ]
    },
    {
        "guid": "2efefa1f-5a44-464b-beb2-75295de34de7",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 50,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.322789,
                "lon": -122.05206
            },
            "entity": {
                "abbreviation": "John F. Kennedy Middle School",
                "alternates": {
                    "nces": "061029001138"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2efefa1f-5a44-464b-beb2-75295de34de7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "John F. Kennedy Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "John F. Kennedy Middle School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "john-f-kennedy-middle-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1198
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I miss the supportive community and the caring teachers. If I could, I would love to relive my days here. The environment is fresh and exciting, yet if you look hard enough, you can find bubbles of peace and quiet. Though students have their own personal problems, finding teachers to talk to is incredibly easy. My teachers were always extremely understanding of all of my personal issues, and I am eternally grateful for the strong foundation they gave me to continue to learn and grow.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-09T05:34:06.441965Z",
                "guid": "e7327bef-d9f6-45f6-af02-b061d8379b73",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.3118,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.3118,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.111111111111111,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "54c862c0-76ad-4cdf-a69d-29add2d46d16",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 51,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.292858,
                "lon": -122.009658
            },
            "entity": {
                "abbreviation": "Christa McAuliffe Elementary School",
                "alternates": {
                    "nces": "061029009356"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "54c862c0-76ad-4cdf-a69d-29add2d46d16",
                "isClaimed": false,
                "isPremium": false,
                "location": "Saratoga, CA",
                "name": "Christa McAuliffe Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Christa McAuliffe Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "christa-mcauliffe-elementary-school-saratoga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 493
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.01,37.2921,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.01,37.2921,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95070"
            }
        ]
    },
    {
        "guid": "9f18abab-aff5-4dcd-adb0-40d8313bfbcf",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 52,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.328612,
                "lon": -122.026629
            },
            "entity": {
                "abbreviation": "Sam H. Lawson Middle School",
                "alternates": {
                    "nces": "061029011448"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9f18abab-aff5-4dcd-adb0-40d8313bfbcf",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "Sam H. Lawson Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sam H. Lawson Middle School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "sam-h-lawson-middle-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1138
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Works Here",
                "body": "I have been a teacher for over 30 years, but this is my second year teaching at Sam H. Lawson Middle School.  One category that is missing, is how happy everyone is at Lawson.  Teachers engage with students throughout the school day, while their efforts are supported by the administration.  We have numerous clubs on campus, where every student's needs are met - from A Game Club to Harry Potter Club to Karaoke Club and so many more.  When teachers care to give their best; students take that and run with it!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-08-13T08:32:21.862414Z",
                "guid": "3adf33e5-5614-4e3e-b5b0-36f37bda27e6",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.027,37.3288,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.027,37.3288,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.75,
                "count": 8
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "22af9d4a-31ef-4c64-a42a-21c524a33377",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 53,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.546838,
                "lon": -121.942919
            },
            "entity": {
                "abbreviation": "William Hopkins Junior High School",
                "alternates": {
                    "nces": "061440001669"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "22af9d4a-31ef-4c64-a42a-21c524a33377",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "William Hopkins Junior High School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "William Hopkins Junior High School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "william-hopkins-junior-high-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1060
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Hopkins is amazing! There are so many electives and the online transition was amazing. I came from private school and this is so much better because they have live sessions for each period. I have made so many friends and the year went by so quickly. All the teachers help you personally if you need it and you can email them through schoolloop. You can also keep track of everything through there. You can even see your final grades and improve them if you need to. I love this school, and they also have many clubs and the electives are very nice. In leadership we get to do so many things. We also get to participate in competitions. I am currently doing their online school. We also organize the socials and events. I have not had good experiences with public school and school overall but this school made me feel so happy and it is honestly amazing. Honestly, I think it is the best school I have ever been to. If you are thinking about going here, you definitely should. I love it!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-07T18:33:05.275827Z",
                "guid": "ef451ae8-4127-49af-85f2-13227f4ab69a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.941,37.546,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.941,37.546,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.25,
                "count": 8
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94539"
            }
        ]
    },
    {
        "guid": "0e319d6b-9639-4a96-9e26-3d4d25379ef5",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 54,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.588416,
                "lon": -122.385263
            },
            "entity": {
                "abbreviation": "Burlingame Intermediate",
                "alternates": {
                    "nces": "060648000581"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0e319d6b-9639-4a96-9e26-3d4d25379ef5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Burlingame, CA",
                "name": "Burlingame Intermediate School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Burlingame Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                        "location": "Burlingame, CA",
                        "name": "Burlingame Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "Burlingame Elementary School District",
                        "tagline": [
                            "School District",
                            "Burlingame, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "burlingame-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Burlingame Intermediate",
                "tagline": [
                    "Burlingame Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "burlingame-intermediate-school-burlingame-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1081
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I had fun at BIS! Especially the schoolympics and the teachers were really kind! The teachers all made sure you were getting the help you needed and always seemed to find a way to make a fun and exciting new project happen.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-23T20:52:35.125105Z",
                "guid": "94c8b887-514b-4126-ac1b-72f4d3ec4d9f",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.387,37.5884,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.387,37.5884,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.5,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "7597841a-e495-4a76-89fb-f6f2e13df383",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 55,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.301237,
                "lon": -122.011805
            },
            "entity": {
                "abbreviation": "Joaquin Miller Middle School",
                "alternates": {
                    "nces": "061029001142"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7597841a-e495-4a76-89fb-f6f2e13df383",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Joaquin Miller Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Joaquin Miller Middle School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "joaquin-miller-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1191
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Miller Middle School has definitely made me improve in all my subjects. The quality of the students at Miller is unlike most other schools, and I have been to another Middle School In CUSD too, so I can easily say Miller is much better than most schools.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-10-24T06:54:20.039956Z",
                "guid": "8a50602c-f0dd-49f6-8a89-df1657d43422",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.009,37.3002,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.009,37.3002,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.2727272727272725,
                "count": 11
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95129"
            }
        ]
    },
    {
        "guid": "580655c4-3a44-4198-b13e-7a8dbf544885",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 56,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.892761,
                "lon": -122.482313
            },
            "entity": {
                "abbreviation": "Del Mar Middle School",
                "alternates": {
                    "nces": "063216004977"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "580655c4-3a44-4198-b13e-7a8dbf544885",
                "isClaimed": false,
                "isPremium": false,
                "location": "Tiburon, CA",
                "name": "Del Mar Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6c40224d-6d11-4b9a-8d5b-18325326110d",
                    "schoolDistrict": "6c40224d-6d11-4b9a-8d5b-18325326110d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "90907f23-d6a7-4db0-ba2b-2e8f545b1d88",
                    "zipCode": "1d3dcee8-86cc-475e-976d-9cb9a69814a7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Reed Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6c40224d-6d11-4b9a-8d5b-18325326110d",
                        "location": "Tiburon, CA",
                        "name": "Reed Union Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "90907f23-d6a7-4db0-ba2b-2e8f545b1d88",
                            "zipCode": "1d3dcee8-86cc-475e-976d-9cb9a69814a7"
                        },
                        "premium": false,
                        "shortName": "Reed Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Tiburon, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "reed-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Del Mar Middle School",
                "tagline": [
                    "Reed Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "del-mar-middle-school-tiburon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 540
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I could not recommend Del Mar Middle School more highly. The campus is beautiful, the teachers are incredible, and the students are welcoming. Whoever said that middle school was the “worst part of life” clearly did not attend Del Mar.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-11-25T19:19:18.156863Z",
                "guid": "5b07ee60-b166-43aa-a29c-fe825970aa83",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.8934,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.8934,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.375,
                "count": 8
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94920"
            }
        ]
    },
    {
        "guid": "b9be3451-66ca-47a8-b7a8-c46659817b19",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 57,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.881118,
                "lon": -122.277118
            },
            "entity": {
                "abbreviation": "Martin Luther King Middle School",
                "alternates": {
                    "nces": "060474000443"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b9be3451-66ca-47a8-b7a8-c46659817b19",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Martin Luther King Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bfbf22a7-f6d0-4d73-8b0d-10ea85a96a4c",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "ea9f890c-9568-4762-a2f4-771a4f369e28"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Martin Luther King Middle School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "martin-luther-king-middle-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 989
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I am a current 8th grader, and I love King. I would say 85% of the teachers are really great. It’s a really good mix of average/poor and advanced students. All teachers care about the students. The principal is okay I guess, however current 8th grade VP is terrible. He does not do anything and has befriended the constant trouble makers and therefore doesn’t give them consequences, making the school unsafe for teachers and students. I think that compared to all other public middle schools in Berkeley, Kind is the very best option. I’ve gotta say though, the school lunch is disgusting. Thanks Alice Waters! Just kidding, she really has done a lot for my school! Kitchen/garden program has taught me and my peers a lot. The school lunch truly is disgusting though. I think it’s getting better though! Well, that’s optimistic... :)",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-10T04:22:39.469193Z",
                "guid": "82459e9e-7aa9-4326-a8a1-db3111332689",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.277,37.8817,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.277,37.8817,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94703"
            }
        ]
    },
    {
        "guid": "41e0955a-522d-4f6b-80ef-ab686b6cee3d",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 58,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.030045,
                "lon": -122.545314
            },
            "entity": {
                "abbreviation": "Miller Creek Middle School",
                "alternates": {
                    "nces": "061122001247"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "41e0955a-522d-4f6b-80ef-ab686b6cee3d",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Miller Creek Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "617c6d11-21a1-4f7e-bc3b-139235de285c",
                    "parent": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "schoolDistrict": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Dixie Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                        "location": "San Rafael, CA",
                        "name": "Dixie Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "Dixie Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dixie-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Miller Creek Middle School",
                "tagline": [
                    "Dixie Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "miller-creek-middle-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 647
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "It was a good school, I learned a lot. I think it could benefit from being more diverse and a better P.E. Program.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-13T21:09:12.068027Z",
                "guid": "5a3d9558-a552-4916-82c0-7236ccd69a91",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.548,38.0301,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.548,38.0301,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94903"
            }
        ]
    },
    {
        "guid": "e6f05707-4005-4300-92f5-e0c018192da3",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 59,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.807816,
                "lon": -122.271085
            },
            "entity": {
                "abbreviation": "Oakland School for the Arts",
                "alternates": {
                    "ceeb": "052410",
                    "nces": "060245810461"
                },
                "character": "Public, Magnet, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "e6f05707-4005-4300-92f5-e0c018192da3",
                "isClaimed": true,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Oakland School for the Arts",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "b42a540d-fcaa-4215-99d6-2fe916c20c44"
                },
                "premium": false,
                "shortName": "Oakland School for the Arts",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "oakland-school-for-the-arts-oakland-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 749
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Oakland School for the Arts is an amazing community for young artists, and one I'm super grateful to have been a part of. It's definitely made for artsy kids though, rather than those looking for a lot of academic rigor. There is a lot of academic teacher turnover, but the arts teachers for the most part stay consistent and are truly the glue holding the whole operation together! Despite sort of average stats, I give OSA a high rating because of everything I gained from such an arts-friendly and supportive learning environment. The shift to online arts classes seems to be doing alright.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-12-05T20:30:35.123431Z",
                "guid": "dd81f011-bccd-4c3a-92c5-a046d9cb75cf",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.271,37.8078,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.271,37.8078,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.7777777777777777,
                "count": 144
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94612"
            }
        ]
    },
    {
        "guid": "5f2cb371-df97-44f5-917c-7b4dc7bb743b",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 60,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.851808,
                "lon": -122.145438
            },
            "entity": {
                "abbreviation": "Orinda Intermediate",
                "alternates": {
                    "nces": "062886004472"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5f2cb371-df97-44f5-917c-7b4dc7bb743b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Orinda, CA",
                "name": "Orinda Intermediate School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                    "schoolDistrict": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                    "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Orinda Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                        "location": "Orinda, CA",
                        "name": "Orinda Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                            "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                        },
                        "premium": false,
                        "shortName": "Orinda Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Orinda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "orinda-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Orinda Intermediate",
                "tagline": [
                    "Orinda Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "orinda-intermediate-school-orinda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 898
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Most of my teachers were great, though there are some bad ones and some creepy ones. Not much diversity at this school. Just like any school there are plenty of kids here that suck or aren’t nice but I say we had it pretty good.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-02T05:34:03.832335Z",
                "guid": "d8535ad5-e6fc-4788-9eef-948457f8c2b3",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.147,37.8513,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.147,37.8513,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.2,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94563"
            }
        ]
    },
    {
        "guid": "b687ecd9-27a2-452d-b494-c19e3101e38e",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 61,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.243329,
                "lon": -121.935594
            },
            "entity": {
                "abbreviation": "UMS",
                "alternates": {
                    "nces": "064032006680"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "b687ecd9-27a2-452d-b494-c19e3101e38e",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Union Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                        "location": "San Jose, CA",
                        "name": "Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Union",
                "tagline": [
                    "Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "union-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1053
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I've been at union for like 8 months, its decent, the academics are pretty challenging, but teachers, CALM DOWN! I have like, 216 assignments that I just did. (NO JOKE, If I could send a screenshot, I would.) Also, the teachers should encourage making friends more..",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-04-20T17:11:30.132098Z",
                "guid": "906c2772-652d-44ba-9e01-b4fcb99fb4df",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.935,37.2418,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.935,37.2418,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.9375,
                "count": 16
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "a3cf7e9a-5a72-4055-b45b-b9c10aacccf0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 62,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.530113,
                "lon": -121.963246
            },
            "entity": {
                "abbreviation": "John M. Horner Junior High School",
                "alternates": {
                    "nces": "061440001670"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a3cf7e9a-5a72-4055-b45b-b9c10aacccf0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "John M. Horner Junior High School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John M. Horner Junior High School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "john-m-horner-junior-high-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1245
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.965,37.5296,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.965,37.5296,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "7334f6bb-eca8-4eba-a211-12663a0ae1ff",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 63,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.896599,
                "lon": -122.529146
            },
            "entity": {
                "abbreviation": "Mill Valley Middle School",
                "alternates": {
                    "nces": "062487003712"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7334f6bb-eca8-4eba-a211-12663a0ae1ff",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mill Valley, CA",
                "name": "Mill Valley Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                    "schoolDistrict": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                    "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mill Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                        "location": "Mill Valley, CA",
                        "name": "Mill Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                            "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                        },
                        "premium": false,
                        "shortName": "Mill Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "Mill Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mill-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Mill Valley Middle School",
                "tagline": [
                    "Mill Valley Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "mill-valley-middle-school-mill-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1039
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.528,37.8973,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.528,37.8973,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94941"
            }
        ]
    },
    {
        "guid": "60e7966b-35aa-44c7-9d1e-73cb67009618",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 64,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.236119,
                "lon": -121.84849
            },
            "entity": {
                "abbreviation": "Discovery Charter II",
                "alternates": {
                    "nces": "060241013640"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "60e7966b-35aa-44c7-9d1e-73cb67009618",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Discovery Charter II",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "schoolNetwork": "11465407-60e3-4192-8083-e8f010b6f06f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "premium": false,
                "shortName": "Discovery Charter II",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "discovery-charter-ii-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 584
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "My family has attended Discovery Charter School 2 Falcon Campus for the last 5 years.\n\nTeachers are able to follow the childrens’ lead for curriculum, and present opportunities to engage in hands-on activities including many field trips. One concern I had about specific academic needs not being met was swiflty addressed and adaptions were made, with the support of teachers and the director.\n\nSocial and emotional needs are supported at the school, and everyone is on the same page, responding to the children using Positive Discipline techniques. \n\nDiscovery provides the opportunity to stay with teachers for multiple years (looping). \n\nMost of the parents regularly volunteer, and you can really create a village here.\n\nMy children are excited to go to school. Learning is fun and curiosity is encouraged. I am excited my children are enjoying their education, as their experiences there will likely result in life-long, engaged learners. I highly recommend you check D2 out!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-29T23:16:36.137922Z",
                "guid": "16fc8468-e1cd-43d2-96b6-146c324e3c60",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "83044971-989c-41cb-8f8f-28d51ff55440",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 65,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.535083,
                "lon": -122.237566
            },
            "entity": {
                "abbreviation": "Sandpiper Elementary School",
                "alternates": {
                    "nces": "060453007417"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "83044971-989c-41cb-8f8f-28d51ff55440",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Sandpiper Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "d9fcdec8-decf-4863-b3f9-67748b4dc339",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "80b04c41-59c1-4587-8f7c-24efc88eaf9a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sandpiper Elementary School",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "K-7"
                ],
                "type": "School",
                "url": "sandpiper-elementary-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 617
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "The teachers and staff are amazing, they care about their students and all the students like their teachers.  Sandpiper does an amazing job of making everyone feel welcome and they have renovating the school recently making it even nicer than it was before.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-03-10T04:13:31.825829Z",
                "guid": "e244de61-0074-43f4-ad6b-9ab9fe0a9df6",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.5378,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.5378,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94065"
            }
        ]
    },
    {
        "guid": "51d95813-6e66-406c-8d85-dfaeaab920de",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 66,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.309968,
                "lon": -122.023235
            },
            "entity": {
                "abbreviation": "Warren E. Hyde Middle School",
                "alternates": {
                    "nces": "061029001136"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "51d95813-6e66-406c-8d85-dfaeaab920de",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "Warren E. Hyde Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Warren E. Hyde Middle School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "warren-e-hyde-middle-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 998
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "A truly great school to go to. I am gonna finish 8th grade this year (2017) and it is a magnificent experience so far. This school provides good education, sports, and amazing clubs. Clubs like wood shop and metal shop are rarely available these days and learning how to make DIY projects is really fun. In terms of education it is first class with awesome teaches and administration it makes learning fun.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-11-24T02:58:40.388381Z",
                "guid": "8a01214e-e0ef-4376-992b-074f6b89fd13",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.011,37.3111,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.011,37.3111,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.7142857142857144,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "d56421fd-dbe3-4a36-b3a7-a224e25a9ce4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 67,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.424927,
                "lon": -121.872234
            },
            "entity": {
                "abbreviation": "Rancho Milpitas Middle School",
                "alternates": {
                    "nces": "062450003670"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d56421fd-dbe3-4a36-b3a7-a224e25a9ce4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Rancho Milpitas Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rancho Milpitas Middle School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "rancho-milpitas-middle-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 717
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "Decent experience overall. Changed a lot as a person while going through middle school. Gave an open and accepting environment to everyone who attended and welcomed diversity. Prepared students for high school fairly well.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-16T08:23:46.335211Z",
                "guid": "4e7282dd-b0e2-4913-8081-523b1271c7f2",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.872,37.424,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.872,37.424,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "3d1472d9-a49f-48ab-bb3c-d9da68d60a21",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 68,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.239798,
                "lon": -121.896656
            },
            "entity": {
                "abbreviation": "Dartmouth Middle School",
                "alternates": {
                    "nces": "064032006671"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3d1472d9-a49f-48ab-bb3c-d9da68d60a21",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Dartmouth Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "cfe32ecb-646c-4ac4-8146-97a3f348c00e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                        "location": "San Jose, CA",
                        "name": "Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Dartmouth Middle School",
                "tagline": [
                    "Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "dartmouth-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 994
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "At Dartmouth, your attitude about learning is I think the most important factor in how your experience goes. For me, it was great! Being interested in STEM, they provided many electives, clubs, and in-school activities for us to explore what we're interested in and to pursueit. The more you put yourself out there the more you achieve, and the teachers are all mostly very nice and helpful in education, and they want to see you succeed. The campus is nice, with a good quad area for lunches, the only downside is, careful with who you choose to spend time with. I've built myself a supportive and caring friend group but you want to avoid hanging out with certain people here. Overall very good :)",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-12-30T02:59:44.128616Z",
                "guid": "39bf0868-4fbc-4ea2-815d-e99133a9e7ce",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.898,37.2397,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.898,37.2397,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95118"
            }
        ]
    },
    {
        "guid": "0d0fb0e0-30cc-488a-b45c-d0faf1bb5348",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 69,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.555652,
                "lon": -122.002475
            },
            "entity": {
                "abbreviation": "Centerville Junior High School",
                "alternates": {
                    "nces": "061440001660"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0d0fb0e0-30cc-488a-b45c-d0faf1bb5348",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Centerville Junior High School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Centerville Junior High School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "centerville-junior-high-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 972
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Centerville Junior High School is a great school. My sister currently studies there. Having recently shifted from India, we were worried about being able to cope with the system here. Fortunately, all the teachers were very friendly and accepting. Centerville has a diverse group of students and my sister felt super included. The infrastructure and other facilities on campus are excellent. My sister loves going to school. The only thing I feel that could be improved is the cafeteria system; I heard that children have to wait in long lines to buy food.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-31T00:29:35.281046Z",
                "guid": "52a7d07e-a200-46ce-b649-fe97cdf5e183",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122,37.5568,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122,37.5568,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "5c4631a3-c99b-4af1-b64f-e5995d68bb80",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 70,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.555968,
                "lon": -122.015227
            },
            "entity": {
                "abbreviation": "Thornton Junior High School",
                "alternates": {
                    "nces": "061440001687"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5c4631a3-c99b-4af1-b64f-e5995d68bb80",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Thornton Junior High School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Thornton Junior High School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "thornton-junior-high-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1297
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.017,37.5571,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.017,37.5571,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "59d92ebe-5d21-416d-a3fb-bf77100f36d7",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 71,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.888899,
                "lon": -122.066783
            },
            "entity": {
                "abbreviation": "Tice Creek",
                "alternates": {
                    "nces": "064125013866"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "59d92ebe-5d21-416d-a3fb-bf77100f36d7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Tice Creek",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "549b68b5-65f5-4e14-8c39-f3e25edc69a0",
                    "zipCode": "0f5bfe3e-c4e2-4b41-afd1-7f0c717be19f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Tice Creek",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "tice-creek-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 427
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.8891,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.8891,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94595"
            }
        ]
    },
    {
        "guid": "c5e79df4-d1b8-4054-a1e2-9b1f2ee48558",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 72,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.803071,
                "lon": -122.443386
            },
            "entity": {
                "abbreviation": "Claire Lilienthal Elementary",
                "alternates": {
                    "nces": "063441007841"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c5e79df4-d1b8-4054-a1e2-9b1f2ee48558",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Claire Lilienthal Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "fd7d4388-deaa-4a97-b3a1-ea5c44d25269",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b1d5d1b3-1a17-4487-9231-69eb48dcc12a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Claire Lilienthal Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "claire-lilienthal-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 697
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We have three children at Claire Lilienthal. We count our blessings every day - the school is amazing. Our kids have very different personalities and learning styles, and two are African American and one is White, and all have found tremendous academic enrichment, fabulous arts and sports programs and truly excellent academics. I have come to appreciate that teachers have “feet” and chose to work in schools where they have ample family and administrative support. Our teachers have been hands- down phenomenal. To top it off, the school has terrific community and many very involved families. I am proud to have my kids be in such a diverse and academically and socially successful school. We can do this, America! Urban public schools can be amazing places of academic achievement and cultural and racial integration. Thank you Claire Lilienthal.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-05T02:52:08.050741Z",
                "guid": "1f375a50-4f35-4198-ac1e-bf0cf72109d0",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.443,37.8033,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.443,37.8033,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94123"
            }
        ]
    },
    {
        "guid": "770867a8-e5da-45f7-b167-a417e153e60e",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 73,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.267313,
                "lon": -121.919286
            },
            "entity": {
                "abbreviation": "Steindorf Steam K-8 Magnet",
                "alternates": {
                    "nces": "060714013993"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "770867a8-e5da-45f7-b167-a417e153e60e",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Steindorf Steam K-8 Magnet",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Steindorf Steam K-8 Magnet",
                "tagline": [
                    "Cambrian School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "steindorf-steam-k-8-magnet-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 502
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "ecd6ead7-f331-4f40-9b33-71a3103b0883",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 74,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.453631,
                "lon": -121.901662
            },
            "entity": {
                "abbreviation": "Thomas Russell Middle School",
                "alternates": {
                    "nces": "062450009401"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "ecd6ead7-f331-4f40-9b33-71a3103b0883",
                "isClaimed": true,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Thomas Russell Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Thomas Russell Middle School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "thomas-russell-middle-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 825
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Sophomore",
                "body": "I loved the teachers here! Ms. Woodhams and Ms. Troxell were the BEST! I loved learning in these classes. Unfortunately, back when I was in 8th grade, Russell switched their platform to PLP. I blasted through those lessons quickly and found myself bored much before the school year ended. It wasn't the same as an interactive traditional classroom setting. I learned things for a few days then forgot with PLP.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-05-31T04:37:41.886904Z",
                "guid": "32cfd319-d19b-41c8-b026-af20e1bb883b",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.901,37.4546,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.901,37.4546,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "95d684f8-d2b4-4c6f-a4f5-c776398d12e0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 75,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.938418,
                "lon": -122.52531
            },
            "entity": {
                "abbreviation": "Hall Middle School",
                "alternates": {
                    "nces": "062091002515"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "95d684f8-d2b4-4c6f-a4f5-c776398d12e0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Larkspur, CA",
                "name": "Hall Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                    "schoolDistrict": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "db131da3-37dc-42cf-aad2-15024d364e4d",
                    "zipCode": "f981dbb9-3c01-40c1-97a3-f476d8291c9a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Larkspur-Corta Madera School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                        "location": "Larkspur, CA",
                        "name": "Larkspur-Corta Madera School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "db131da3-37dc-42cf-aad2-15024d364e4d",
                            "zipCode": "f981dbb9-3c01-40c1-97a3-f476d8291c9a"
                        },
                        "premium": false,
                        "shortName": "Larkspur-Corta Madera School District",
                        "tagline": [
                            "School District",
                            "Larkspur, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "larkspur-corta-madera-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Hall Middle School",
                "tagline": [
                    "Larkspur-Corta Madera School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "hall-middle-school-larkspur-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 520
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I liked the teachers and the education in general. There were several opportunities where felt classes could have been more controlled.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-09-01T19:41:26.421588Z",
                "guid": "6fa065c4-0e21-40e2-a6c0-f1762ff70e8c",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.531,37.939,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.531,37.939,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94939"
            }
        ]
    },
    {
        "guid": "f518ecc0-9964-4922-8558-08927e0f0159",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 76,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.303426,
                "lon": -121.986455
            },
            "entity": {
                "abbreviation": "Easterbrook Discovery",
                "alternates": {
                    "nces": "062577003854"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f518ecc0-9964-4922-8558-08927e0f0159",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Easterbrook Discovery",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Easterbrook Discovery",
                "tagline": [
                    "Moreland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "easterbrook-discovery-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 950
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Enthusiastic parents make this school thrive, exposing kids from very broad backgrounds to technology, arts, music in a collaboration with a wonderful staff.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-01-31T12:06:01Z",
                "guid": "645a2c70-2539-4339-bf47-8e67f7afcf6e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.985,37.3062,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.985,37.3062,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95129"
            }
        ]
    },
    {
        "guid": "6abb1126-3eaf-46e4-b314-12550b428e96",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 77,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.383618,
                "lon": -122.085113
            },
            "entity": {
                "abbreviation": "Isaac Newton Graham Middle School",
                "alternates": {
                    "nces": "062628003924"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6abb1126-3eaf-46e4-b314-12550b428e96",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Isaac Newton Graham Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "e8a55ab4-9bff-4942-821c-369c0c98966c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Isaac Newton Graham Middle School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "isaac-newton-graham-middle-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 865
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "Very thorough curriculum and very ahead of grade level math. The math program at our school is very very thorough.\n\nI would rate this an excellent school just for it's programs but does have a slight vaping problem among the children",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-09T19:55:29.044576Z",
                "guid": "fb082ffd-4b13-47b9-82bc-36a96bcb2057",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.085,37.3835,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.085,37.3835,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94040"
            }
        ]
    },
    {
        "guid": "3928bb5c-65cd-4d3e-9666-d1e4ae3feb09",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 78,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.800265,
                "lon": -122.2654
            },
            "entity": {
                "abbreviation": "American Indian Public Charter School",
                "alternates": {
                    "nces": "060147905673"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "3928bb5c-65cd-4d3e-9666-d1e4ae3feb09",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "American Indian Public Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                },
                "premium": false,
                "shortName": "American Indian Public Charter School",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "american-indian-public-charter-school-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 161
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "High School Junior",
                "body": "This school really effected my life. It made me go through the struggles that I actually needed to go through to improve. It made me think nothing is too hard to achieve ! You just end to try and you will get it. Never give up. I really liked how the school was close like a family. It's hard to find that in middle schools nowadays. I would happily choose thri school again.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-01-27T01:23:50Z",
                "guid": "131d96d9-6699-4961-aaaf-d81e5b9b5395",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.2,37.7934,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.2,37.7934,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94607"
            }
        ]
    },
    {
        "guid": "293ea18c-d61b-4bc2-b20a-5e096b13140d",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 79,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.12639,
                "lon": -121.94196
            },
            "entity": {
                "abbreviation": "C. T. English Middle School",
                "alternates": {
                    "nces": "062235002672"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "293ea18c-d61b-4bc2-b20a-5e096b13140d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "C.T. English Middle School",
                "parentGUIDs": {
                    "county": "49997ec1-1e60-4274-84cb-b2c7ecfe9f49",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "91f9426e-72bf-44ae-ac51-69c7c5c0ae5c",
                    "schoolDistrict": "91f9426e-72bf-44ae-ac51-69c7c5c0ae5c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "98637fb0-4b0f-4318-8807-b2944c7d92a8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Loma Prieta Joint Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "91f9426e-72bf-44ae-ac51-69c7c5c0ae5c",
                        "location": "Los Gatos, CA",
                        "name": "Loma Prieta Joint Union Elementary School District",
                        "parentGUIDs": {
                            "county": "49997ec1-1e60-4274-84cb-b2c7ecfe9f49",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "zipCode": "98637fb0-4b0f-4318-8807-b2944c7d92a8"
                        },
                        "premium": false,
                        "shortName": "Loma Prieta Joint Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Gatos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "loma-prieta-joint-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "C. T. English Middle School",
                "tagline": [
                    "Loma Prieta Joint Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "ct-english-middle-school-los-gatos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 232
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Amazing school, everyone is nice and helpful and very welcoming, Very enjoyable and the school was also very safe.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-11-16T04:59:22.981886Z",
                "guid": "6595b46a-1d5d-4b3f-b5cc-f02710b7d465",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.1256,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.1256,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95033"
            }
        ]
    },
    {
        "guid": "753325f6-316d-4e75-936b-13b227792dab",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 80,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.776247,
                "lon": -122.285862
            },
            "entity": {
                "abbreviation": "The Academy of Alameda Middle School",
                "alternates": {
                    "nces": "060164200042"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "753325f6-316d-4e75-936b-13b227792dab",
                "isClaimed": true,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "The Academy of Alameda Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "schoolNetwork": "df7f3446-23a0-48b4-a505-1eb98f7c6afd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "premium": false,
                "shortName": "The Academy of Alameda Middle School",
                "tagline": [
                    "Public School",
                    "Alameda, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "the-academy-of-alameda-middle-school-alameda-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 494
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Well designed, thoughtful, program that includes core academics, music, and extracurricular activities.  Teachers are engaged and available to parents when needed. My child graduated from 8th grade in June 2019 and her experience was more than I could have hoped for - they embed diversity and respect, and have simple steps to ensure the teachers and others have an eye on the development of each child (soon to be adult).",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-29T05:10:15.022708Z",
                "guid": "0882ec4d-2f02-4b99-9c33-00fe9a4a42f1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.7774,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.7774,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.75,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "8e7cbc8f-0a75-4ec9-afd1-bf6c67c58f59",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 81,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.70618,
                "lon": -122.054223
            },
            "entity": {
                "abbreviation": "Canyon Middle School",
                "alternates": {
                    "nces": "060780000740"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8e7cbc8f-0a75-4ec9-afd1-bf6c67c58f59",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Canyon Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "5a343bc9-dc79-4997-8588-69d2feb04764"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Canyon Middle School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "canyon-middle-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1391
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I like that canyon middle school is changing for the better and adding new lockers, textbooks, and computers but I would like to see the staff paying more attention to the students more. an example is that every student has to meet with the counselor at least 2 times every year. Students there seem to be getting more and more irresponsible and reckless.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-03T00:37:18.92064Z",
                "guid": "e6f20328-0cc5-4183-a719-d1e83532c40e",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.7059,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.7059,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.857142857142857,
                "count": 14
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94552"
            }
        ]
    },
    {
        "guid": "a94e4abc-8b3b-4736-85f5-1c6d466b9743",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 82,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.560442,
                "lon": -122.2504
            },
            "entity": {
                "abbreviation": "Bowditch Middle School",
                "alternates": {
                    "nces": "063492005902"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a94e4abc-8b3b-4736-85f5-1c6d466b9743",
                "isClaimed": false,
                "isPremium": false,
                "location": "Foster City, CA",
                "name": "Bowditch Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                    "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bowditch Middle School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "bowditch-middle-school-foster-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1047
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I love the school.  The teachers are amazing and every class is an adventure! The students are friendly and you can try tons of new things there! I think Bowditch really prepared me for the next school years of my life.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-08-14T20:44:45.268793Z",
                "guid": "ad1a6176-6aff-4ef8-b71c-efd99bcdd91a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.249,37.5591,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.249,37.5591,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.6666666666666665,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94404"
            }
        ]
    },
    {
        "guid": "8d4ebc67-a796-4b30-b411-b71322b9c3b4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 83,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.209561,
                "lon": -121.838131
            },
            "entity": {
                "abbreviation": "Bret Harte Middle School",
                "alternates": {
                    "nces": "063459005703"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8d4ebc67-a796-4b30-b411-b71322b9c3b4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Bret Harte Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bret Harte Middle School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "bret-harte-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1189
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "A pretty good school overall. I've heard that there were teachers that don't even teach anything and just gives the students worksheets. But in my experience, I have never had a bad teacher and had good teachers from 6th grade to 8th grade. It won't be hard to fit in and everyone there is really fun and friendly. But I have to say that there will be big groups of cliques but there isn't any bullying.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-21T18:05:05.40651Z",
                "guid": "07d34de0-3bdd-4e4b-98de-a4b72abae774",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.213,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.213,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.7142857142857144,
                "count": 14
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95120"
            }
        ]
    },
    {
        "guid": "73b3db70-bb65-4b82-8385-9e9ba6e5cf74",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 84,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.780791,
                "lon": -122.490102
            },
            "entity": {
                "abbreviation": "Presidio Middle School",
                "alternates": {
                    "nces": "063441005657"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "73b3db70-bb65-4b82-8385-9e9ba6e5cf74",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Presidio Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "17498cb5-11d4-482c-8bb3-a9dad794c27e",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "095d8179-e190-4e1d-a747-e79c35d38d3c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Presidio Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "presidio-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1060
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Parent here with two kids - one who went to Presidio, one who is there now. The teachers are excellent overall and so are the administrators. The new schoolyard looks fantastic!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-03T16:45:31.741389Z",
                "guid": "c62ef0cc-2525-4e0d-b9f7-a7da24e158ab",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.489,37.7812,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.489,37.7812,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94121"
            }
        ]
    },
    {
        "guid": "53e24741-413c-488a-9ecb-3081a4f130c9",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 85,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.699981,
                "lon": -122.061186
            },
            "entity": {
                "abbreviation": "Creekside Middle School",
                "alternates": {
                    "nces": "060780006909"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "53e24741-413c-488a-9ecb-3081a4f130c9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Creekside Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Creekside Middle School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "creekside-middle-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 781
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "High School Senior",
                "body": "Overall, I  enjoyed going to this school. In my opinion the teachers are what make this school unique, they are helpful and nice, and enjoy teaching.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2014-12-29T17:13:03Z",
                "guid": "64109dfa-06b8-456a-a95a-57a4df52d71b",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.061,37.6992,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.061,37.6992,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.2666666666666666,
                "count": 15
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "4affd58f-a122-4788-9c48-613cf5a6c5ed",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 86,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.773183,
                "lon": -122.289619
            },
            "entity": {
                "abbreviation": "Encinal High School",
                "alternates": {
                    "ceeb": "050015",
                    "nces": "060177000045"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4affd58f-a122-4788-9c48-613cf5a6c5ed",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Encinal Junior/Senior High School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Encinal High School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "encinal-junior-senior-high-school-alameda-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1261
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Even though Encinal High School is located in a predominantly white city, the learning community is extremely diverse. Due to this I was not only able to obtain my education, but as well as learn the social struggles minorities and other communities go through. My classmates and friends have taught me many life lessons such as being more aware about ones privilege, the injustices that occur towards minorities, and how to better educate myself on topics that include ones race. My teachers have helped me overcome a number of social challenges that many high school students aren't able to obtain. Because of the clubs I attend I have discovered my passion of political science, in order to help communities who struggle with economic and social challenges due to the color of their skin, religion, or what they have chosen to identify as. I want to make a change and thanks to Encinal High School I have the dedication and determination to do so.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-12T17:43:40.168864Z",
                "guid": "8c7be176-fa58-48a4-9511-8d5683389dc1",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.289,37.7716,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.289,37.7716,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.708791208791209,
                "count": 182
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "0b423c01-f422-4a22-a16a-853c8db7fdb8",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 87,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.350178,
                "lon": -122.008944
            },
            "entity": {
                "abbreviation": "Marian A. Peterson Middle School",
                "alternates": {
                    "nces": "063543007867"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0b423c01-f422-4a22-a16a-853c8db7fdb8",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Marian A. Peterson Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Marian A. Peterson Middle School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "marian-a-peterson-middle-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 908
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I think Peterson Middle School is a pretty awesome school.  The teachers and staff are really supportive and easy to talk to.  A lot of the students are really nice, and this school is really fun!  The only problem I have is the school schedule, which starts at 8 A.M.; which, in my opinion, is too early.  Some of my classmates aren't fully awake yet and half of 1st period is just wasted, since  students are too tired to listen.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-12T01:17:08.551139Z",
                "guid": "5f7e9972-cc89-43f6-94de-e5531b42fd44",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.007,37.3492,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.007,37.3492,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.75,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94087"
            }
        ]
    },
    {
        "guid": "42d7e0f2-214b-4ea9-905f-7ed4c069c361",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 88,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.607622,
                "lon": -122.095416
            },
            "entity": {
                "abbreviation": "Venture (Alternative)",
                "alternates": {
                    "ceeb": "053224",
                    "nces": "063513008976"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "42d7e0f2-214b-4ea9-905f-7ed4c069c361",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Venture (Alternative)",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                    "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Venture (Alternative)",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "venture-alternative-san-ramon-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 154
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "I have had a very good experience with Venture. The independent study works very well for me. At the same time, teachers are always there with support and helpful answers whenever I need it.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-02-21T20:14:55.996067Z",
                "guid": "db820dc2-3471-4b80-be61-c191153dca8d",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.905,37.7674,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.905,37.7674,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.1875,
                "count": 16
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "8b227c5d-7871-468d-8ba1-19fc9989d184",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 89,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.273454,
                "lon": -121.926886
            },
            "entity": {
                "abbreviation": "Price Charter Middle School",
                "alternates": {
                    "nces": "060714000654"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "8b227c5d-7871-468d-8ba1-19fc9989d184",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Price Charter Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Price Charter Middle School",
                "tagline": [
                    "Cambrian School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "price-charter-middle-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 962
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Now that I transferred to a different school, I genuinely miss my experiences at Price. All its interesting people, teachers, traditions. I didn't realize how much I value that school until I moved away. It's primarily because of the friend circle I built over my two years there, but the environment played a role as well. \n\nI liked most of the teachers. The campus could use some upgrades, yet it rarely bothered me (except the oddly placed music room and \"speedwalking\" from one far side of the school to the other). The academic spectrum slagged; of course, as do all standard American schools. However, a fair amount of diversity balanced it out. I never joined any clubs because there was honestly nothing of interest, but I participated in concert/marching band, yearbook, art, cross country, track & field, and basketball, so I got a feel of the activities offered. \n\nOverall, Price would be an average school. Though, it is rated 4-stars by me because of the fond memories I created there.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-03-06T09:30:55.776813Z",
                "guid": "2380815d-76cd-4d40-b909-5e7ca7e53f39",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.926,37.2733,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.926,37.2733,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5714285714285716,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "7bc08ae2-2fac-4397-8fe3-ecf7a69a6ff4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 90,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.751375,
                "lon": -122.497128
            },
            "entity": {
                "abbreviation": "A.P. Giannini Middle School",
                "alternates": {
                    "nces": "063441005580"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7bc08ae2-2fac-4397-8fe3-ecf7a69a6ff4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "A.P. Giannini Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "47575dee-023f-4a2f-8c52-61e4471bda57",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "dfff3cae-3a03-46a7-b060-62db0e2af199"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "A.P. Giannini Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "ap-giannini-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1188
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "Much of the school's students and staff were friendly, and many people seemed dedicated / wanting to be there. Although the school grades a tad bit more lightly than other middle schools in the area, the teachers don't just hand out A's to everyone. They'd prompt us to learn through discussion and such, making many of the subjects I despised easier to understand and handle. <3 to all the teachers there.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-11-07T23:34:05.631034Z",
                "guid": "bd2efa7c-2df3-43be-836d-616674d963c7",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.497,37.7508,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.497,37.7508,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 15
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "7491f1de-9c1a-4ff9-8f02-278b05781b09",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 91,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.098507,
                "lon": -122.576736
            },
            "entity": {
                "abbreviation": "Nova Education Center",
                "alternates": {
                    "ceeb": "052171",
                    "nces": "062772004199"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7491f1de-9c1a-4ff9-8f02-278b05781b09",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Nova Education Center",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Nova Education Center",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "nova-education-center-novato-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 39
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Attending this school made my final two years of high school considerably more worthwhile.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-07-12T23:15:17Z",
                "guid": "33730697-0de2-49b1-ae8e-a5c497b6d815",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.576,38.0983,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.576,38.0983,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.714285714285714,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "889792a6-cd36-492d-8ffa-df4aa7d609ec",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 92,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.51235,
                "lon": -122.312502
            },
            "entity": {
                "abbreviation": "Ralston Intermediate",
                "alternates": {
                    "nces": "060453000419"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "889792a6-cd36-492d-8ffa-df4aa7d609ec",
                "isClaimed": false,
                "isPremium": false,
                "location": "Belmont, CA",
                "name": "Ralston Intermediate School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                    "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ralston Intermediate",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "ralston-intermediate-school-belmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1150
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Classes and teachers were mostly experienced and helpful.  The only downfall was the P.E. department.  Ralston is known for athleticism and have a very one sided way of testing students.  It is not geared towards individualism but a baseline for all.  No one can be graded on one scale as everyone's body type is different as well as puberty.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T16:26:43.401927Z",
                "guid": "a44e5cc0-36e7-4b83-a0b4-f7cb434ab17f",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.312,37.5115,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.312,37.5115,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.2222222222222223,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94002"
            }
        ]
    },
    {
        "guid": "7eaaed62-68e3-4a6c-a7d1-1e2a0b6e2c92",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 93,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.757937,
                "lon": -122.489573
            },
            "entity": {
                "abbreviation": "Lawton Alternative Elementary School",
                "alternates": {
                    "nces": "063441005639"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7eaaed62-68e3-4a6c-a7d1-1e2a0b6e2c92",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Lawton Alternative Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "47575dee-023f-4a2f-8c52-61e4471bda57",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b4e89c98-14e0-415a-81a3-67446f01feef"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lawton Alternative Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "lawton-alternative-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 593
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.489,37.758,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.489,37.758,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "9fea79fe-e7f1-47c8-8740-9c5f1e984282",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 94,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.760758,
                "lon": -122.263327
            },
            "entity": {
                "abbreviation": "Will C. Wood Middle School",
                "alternates": {
                    "nces": "060177000057"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9fea79fe-e7f1-47c8-8740-9c5f1e984282",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Will C. Wood Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Will C. Wood Middle School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "will-c-wood-middle-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 575
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Wood Middle School has been an excellent experience for both of my children. The variety of subjects, professionalism of the administration and teachers, and the support of the students and their families has provided for a well-rounded adventure that has suitably prepared High School students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-10-25T18:13:17.293581Z",
                "guid": "d318a8ed-6159-4749-8e64-203f50888729",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.262,37.7609,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.262,37.7609,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.2,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "4c239d15-1427-4daa-8265-2982c244abf7",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 95,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.05762,
                "lon": -122.525978
            },
            "entity": {
                "abbreviation": "Novato Charter School",
                "alternates": {
                    "nces": "060193606035"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "4c239d15-1427-4daa-8265-2982c244abf7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Novato Charter School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "890ee59a-23bb-434c-820a-d0eb9aa9b666"
                },
                "premium": false,
                "shortName": "Novato Charter School",
                "tagline": [
                    "Public School",
                    "Novato, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "novato-charter-school-novato-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 271
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.526,38.0563,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.526,38.0563,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94949"
            }
        ]
    },
    {
        "guid": "2fec6c04-151f-4a06-8a0e-1958e48b0e3c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 96,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.324635,
                "lon": -121.8522
            },
            "entity": {
                "abbreviation": "CAPS",
                "alternates": {
                    "nces": "060167112639"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "2fec6c04-151f-4a06-8a0e-1958e48b0e3c",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Alpha: Cornerstone Academy Preparatory",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "schoolNetwork": "32061b3b-37e5-4624-a58b-532086d3e22f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "premium": false,
                "shortName": "Cornerstone Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "alpha--cornerstone-academy-preparatory-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 543
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.852,37.3248,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.852,37.3248,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "fe10850c-e616-464e-a4e1-89fcef4ff4c7",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 97,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.337401,
                "lon": -121.84058
            },
            "entity": {
                "abbreviation": "KIPP Heartwood Academy",
                "alternates": {
                    "nces": "060161911444"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "fe10850c-e616-464e-a4e1-89fcef4ff4c7",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "KIPP Heartwood Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "premium": false,
                "shortName": "KIPP Heartwood Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "kipp-heartwood-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 415
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "If I am being completely honest, I believe KIPP Heartwood Academy was an outstanding school with an even more outstanding education system.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-17T21:30:08.571458Z",
                "guid": "0058f148-dda4-480a-a02a-25a85730f872",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.84,37.3379,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.84,37.3379,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "5372c25e-314e-4d16-a8ee-7ad4a6d70bdd",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 98,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.662709,
                "lon": -121.792301
            },
            "entity": {
                "abbreviation": "William Mendenhall Middle School",
                "alternates": {
                    "nces": "062211002636"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "5372c25e-314e-4d16-a8ee-7ad4a6d70bdd",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "William Mendenhall Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "William Mendenhall Middle School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "william-mendenhall-middle-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 965
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I love this school! I have went to it for the past year, and the staff and teachers are super kind and friendly. The teacher’s  teaching techniques are not only fun, but effective as well.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-07T22:39:26.43606Z",
                "guid": "e7022c90-a2bd-4bd0-9379-8725162abfce",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.794,37.6631,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.794,37.6631,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.666666666666667,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "e83811bb-865c-4638-a1c4-f5021dfe04e5",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 99,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.525729,
                "lon": -122.274405
            },
            "entity": {
                "abbreviation": "Nesbit Elementary School",
                "alternates": {
                    "nces": "060453000418"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e83811bb-865c-4638-a1c4-f5021dfe04e5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Belmont, CA",
                "name": "Nesbit Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                    "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Nesbit Elementary School",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "nesbit-elementary-school-belmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 670
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.276,37.5251,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.276,37.5251,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94002"
            }
        ]
    },
    {
        "guid": "aa2d2b92-38f5-41f3-a69c-e706f0de905a",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 100,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.39836,
                "lon": -122.014546
            },
            "entity": {
                "abbreviation": "Summit Denali",
                "alternates": {
                    "nces": "060216913485"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "aa2d2b92-38f5-41f3-a69c-e706f0de905a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Summit Public School - Denali Campus",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "schoolNetwork": "2ed1eb64-2cd2-4b6a-8f49-20b86f2fe1ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "dfa40734-09f2-436c-874c-ac28a0c26bdd"
                },
                "premium": false,
                "shortName": "Summit Denali",
                "tagline": [
                    "Public School",
                    "Sunnyvale, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "summit-public-school---denali-campus-sunnyvale-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 575
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Denali is a good school to choose if you’re looking for a support structure from teachers. However the students are relatively cliquey and it could be hard to make friends. In my experience Denali caters mostly to future STEM majors who plan on enrolling in a 4 year university and has little to no liberal, visual, or music arts focus. Denali also doesn’t provide sufficient resources for students who choose to take a less traditional career/after high school path, though this may change, as they’ve only had two senior classes so far. The teachers are passionate about their students and actually do care, but teacher retention is not Denali’s strong suit. Despite all this, it’s still a million times more preferable than traditional public school as there is far more support available from teachers, the school is flexible, classes are smaller, resources are easier to come by, and they teach you much more about adult life than a public school would.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-04-28T05:21:28.597487Z",
                "guid": "40e42dcf-7a0d-42fb-a3b1-f64168571ced",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.992,37.3829,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.992,37.3829,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8666666666666667,
                "count": 15
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94089"
            }
        ]
    },
    {
        "guid": "5882b1f6-b88c-4c09-97e9-ce340edbf059",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 101,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.780077,
                "lon": -122.435425
            },
            "entity": {
                "abbreviation": "Creative Arts Charter School",
                "alternates": {
                    "nces": "060215503982"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5882b1f6-b88c-4c09-97e9-ce340edbf059",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Creative Arts Charter School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9ae6d1c0-ee34-4266-84ab-3a6632674608",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "9fbdd391-c59a-4ee3-a774-a928ff2f0905"
                },
                "premium": false,
                "shortName": "Creative Arts Charter School",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "creative-arts-charter-school-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 431
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "CACS has a robust but manageable workload that prepares students for the curriculum ahead in high school. It offers a sense of community and diversity that simply isn't present at most other schools.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-05T19:16:18.138018Z",
                "guid": "3e462fd8-e8ce-4fdd-b88e-cf975bf47830",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7798,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7798,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.25,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94115"
            }
        ]
    },
    {
        "guid": "2e06eb0a-6ed3-430e-b3c7-2e242e590cd3",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 102,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.289885,
                "lon": -121.983743
            },
            "entity": {
                "abbreviation": "Moreland Middle School",
                "alternates": {
                    "nces": "062577003855"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2e06eb0a-6ed3-430e-b3c7-2e242e590cd3",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Moreland Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Moreland Middle School",
                "tagline": [
                    "Moreland, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "moreland-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 944
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.984,37.2893,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.984,37.2893,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95130"
            }
        ]
    },
    {
        "guid": "37feb4c6-3823-4848-8ae9-875f950d67d5",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 103,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.646436,
                "lon": -122.119949
            },
            "entity": {
                "abbreviation": "Adams (J. Douglas) Middle School",
                "alternates": {
                    "nces": "060591011574"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "37feb4c6-3823-4848-8ae9-875f950d67d5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "J. Douglas Adams Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "c7e723f0-78fc-4ba9-aa90-a97d95040134",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                    "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Adams (J. Douglas) Middle School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "j-douglas-adams-middle-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1129
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "High School Freshman",
                "body": "This school may be considered a long six to seven hours for most students but to me, this school was a great experience. My experience at this school was amazing, we had a lot of spectacular events like school dances, water park field trips, movie nights for honor roll students, and so much more. This school has remarkable teachers and staffs that are always on top of everything. I would absolutely choose this school again if I could do it all over again because when you have amazing teachers and friends to experience all of the same things as you why wouldn't you do it again?",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-07-20T01:50:28Z",
                "guid": "1e74836e-cf49-4ff7-b0e4-4f8f55ebc946",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.759,37.9196,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.759,37.9196,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "f14a8f92-6775-4704-b8bd-6042cfb0c16e",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 104,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.597375,
                "lon": -122.400937
            },
            "entity": {
                "abbreviation": "Taylor Middle School",
                "alternates": {
                    "nces": "062490003722"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f14a8f92-6775-4704-b8bd-6042cfb0c16e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Millbrae, CA",
                "name": "Taylor Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "schoolDistrict": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                    "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Millbrae Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                        "location": "Millbrae, CA",
                        "name": "Millbrae Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                            "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                        },
                        "premium": false,
                        "shortName": "Millbrae Elementary School District",
                        "tagline": [
                            "School District",
                            "Millbrae, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "millbrae-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Taylor Middle School",
                "tagline": [
                    "Millbrae Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "taylor-middle-school-millbrae-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 825
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I’m a sixth grader at Taylor as of 2018. I love my school. There is a strong anti-bullying message and a push for rigorous, above-average academic performance. The teachers are— for the most part— good. Most of them are calm, collected, engaging and in control. My favorite teacher is sixth grade English-Social Studies teacher Matthew Garrett. The students leave you alone as long as you don’t mess with them. There aren’t many clubs or extra curriculars. Taylor is a pretty typical suburban middle school, but be warned the cafeteria food is terrible. Bring your own lunch.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-05-02T01:34:09.870502Z",
                "guid": "af6f3311-e392-4fbc-8648-7d922fc68b8d",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.402,37.5987,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.402,37.5987,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94030"
            }
        ]
    },
    {
        "guid": "696ae3fd-8c46-4ddf-a43b-4a730846966e",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 105,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.90854,
                "lon": -122.054953
            },
            "entity": {
                "abbreviation": "Walnut Creek Intermediate",
                "alternates": {
                    "nces": "064125006831"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "696ae3fd-8c46-4ddf-a43b-4a730846966e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Walnut Creek Intermediate School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Walnut Creek Intermediate",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "walnut-creek-intermediate-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1049
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.056,37.9094,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.056,37.9094,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94597"
            }
        ]
    },
    {
        "guid": "28f57cf5-4367-4074-8f4b-d8fb14ae6d81",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 106,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.759404,
                "lon": -122.469295
            },
            "entity": {
                "abbreviation": "Alice Fong Yu Elementary",
                "alternates": {
                    "nces": "063441005360"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "28f57cf5-4367-4074-8f4b-d8fb14ae6d81",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Alice Fong Yu Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a050bfe7-0851-4a3d-8418-018310efa8a2",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b4e89c98-14e0-415a-81a3-67446f01feef"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alice Fong Yu Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "alice-fong-yu-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 590
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 30
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "As a Chinese Immersion school, AFY is not for every family.  My kids thrived in the small school environment but for others, having the same classmates for 9 years can be stifling and awkward.  The majority of teachers are great - caring yet having high expectations and able to get the best out of their students.  Unfortunately, parent involvement has declined over the years.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-21T02:17:24.14673Z",
                "guid": "e1fe4fd5-a6ca-4cbc-8319-55cc64f4300e",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.47,37.7592,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.47,37.7592,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "ae7480e6-41c1-4c1b-ac00-3e53cf5613be",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 107,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.782253,
                "lon": -122.45887
            },
            "entity": {
                "abbreviation": "Roosevelt Middle School",
                "alternates": {
                    "nces": "063441005662"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ae7480e6-41c1-4c1b-ac00-3e53cf5613be",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Roosevelt Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "faf22f53-7137-4f59-a0bc-8674f26b444c",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "1b2bdc40-30b1-4227-bccb-fd8c4930354e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Roosevelt Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "roosevelt-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 694
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Roosevelt has been a very fun time. There is so much school spirit and I met some very good friends here. Most of the teachers are very fun and I loved classes with most of my teachers. It’s a very good school and I highly recommend it! Some major clubs last awhile but most small and new ones don’t. Sports are pretty good too, as three sports made it to the championships this year and one championship won.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-03-26T03:59:55.20107Z",
                "guid": "6bd5295f-1665-4340-ae92-d0cf7bc0d41b",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.459,37.7822,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.459,37.7822,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94118"
            }
        ]
    },
    {
        "guid": "9ecc31ea-4c82-4ad4-8304-363447ca1ddc",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 108,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.2857163,
                "lon": -121.8317779
            },
            "entity": {
                "abbreviation": "KIPP: Heritage Academy",
                "alternates": {
                    "nces": "060225913763"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "9ecc31ea-4c82-4ad4-8304-363447ca1ddc",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "KIPP Heritage Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                },
                "premium": false,
                "shortName": "KIPP: Heritage Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "kipp-heritage-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 452
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8317779,37.2857163,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8317779,37.2857163,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95111"
            }
        ]
    },
    {
        "guid": "6e71e941-3383-490b-a575-6430f8a8237b",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 109,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.574379,
                "lon": -122.316427
            },
            "entity": {
                "abbreviation": "North Shoreview Montessori Elementary",
                "alternates": {
                    "nces": "063492005914"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "6e71e941-3383-490b-a575-6430f8a8237b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "North Shoreview Montessori Elementary",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "3435a379-835e-44ad-a67d-683bc7ef6fc8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "North Shoreview Montessori Elementary",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "north-shoreview-montessori-elementary-san-mateo-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 328
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.316,37.5753,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.316,37.5753,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94401"
            }
        ]
    },
    {
        "guid": "9b6c9ae8-e1ff-4f1a-af17-ab555f601320",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 110,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.360058,
                "lon": -122.05402
            },
            "entity": {
                "abbreviation": "Sunnyvale Middle School",
                "alternates": {
                    "nces": "063846006465"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "9b6c9ae8-e1ff-4f1a-af17-ab555f601320",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Sunnyvale Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sunnyvale Middle School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "sunnyvale-middle-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1211
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Sunnyvale is a good school with good teachers and a nice community. The teachers care about their students and make classes engaging and fun. The assemblies (or are they called rallies?) are also fun. One thing the school can improve on is the electives system. Students only have one elective for the entire year unless they choose to do a rotation every trimester, which is only available for specific electives. This leads to many students quitting music. Geometry is also a year-long elective.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-06-02T22:16:52.631771Z",
                "guid": "b4122dde-2690-4ef3-9d2b-1ac097331ab0",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.052,37.3609,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.052,37.3609,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94087"
            }
        ]
    },
    {
        "guid": "8c4bf626-481b-4b6d-bc82-bb58c1008426",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 111,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.920507,
                "lon": -122.015274
            },
            "entity": {
                "abbreviation": "Foothill Middle School",
                "alternates": {
                    "nces": "062637003947"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8c4bf626-481b-4b6d-bc82-bb58c1008426",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Foothill Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Foothill Middle School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "foothill-middle-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 974
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Foothill is a very nice, clean, and modern middle school. It is located in the center of a very good neighborhood, with surrounding schools. Foothill Middle School also has a great track field and gym.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-01-05T05:13:20.933203Z",
                "guid": "7631e79a-3da5-4c97-be54-f09209eb87f9",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.016,37.9205,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.016,37.9205,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "6fe988cc-aeaa-4e82-8215-f6e2c6fc7bbb",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 112,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.265283,
                "lon": -121.985825
            },
            "entity": {
                "abbreviation": "Rolling Hills Middle School",
                "alternates": {
                    "nces": "060720000674"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6fe988cc-aeaa-4e82-8215-f6e2c6fc7bbb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "Rolling Hills Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Rolling Hills Middle School",
                "tagline": [
                    "Campbell Union School District, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "rolling-hills-middle-school-los-gatos-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1062
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Rolling Hills Middle School is a great school! It has a variety of opportunities and clubs to help us out in the future. I enjoyed the academics, although I came from a private school. This was one of my favorite schools during my childhood.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-01T05:28:06.201296Z",
                "guid": "e75d766e-4118-4253-9d39-6a364fb883ad",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.987,37.2658,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.987,37.2658,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5454545454545454,
                "count": 11
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95030"
            }
        ]
    },
    {
        "guid": "6350a527-7f8e-4a3f-88fe-f49c302a7156",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 113,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.800265,
                "lon": -122.2654
            },
            "entity": {
                "abbreviation": "American Indian Public Charter School II",
                "alternates": {
                    "nces": "060188012013"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6350a527-7f8e-4a3f-88fe-f49c302a7156",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "American Indian Public Charter School II",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                },
                "premium": false,
                "shortName": "American Indian Public Charter School II",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "american-indian-public-charter-school-ii-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 794
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "American Indian Public High School prepared me for college readiness. The school has high test scores and has a lot of AP courses that students are required to take.  The school needs to improve resources for students because I needed a lot of help in the college process. I was confused and didn't have anyone to guide me. I had to find out things by myself. My school is very small and we only have one counselor. Our senior class is big and for just one counselor to handle us was hard. We need more teachers and counselors to be hired so we could have more resources from them. We are a low-income school and we need a lot of help financially. Although we don't have a lot of money in my school, they still managed to keep the sports team in good shape. We constantly win championships every year with at least one sport. Overall, American Indian is a good school but needs to improve in resources for students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-18T22:16:31.846648Z",
                "guid": "d0580779-b908-4fd6-8b77-94e31a607ce2",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.265,37.8001,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.265,37.8001,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94607"
            }
        ]
    },
    {
        "guid": "4fe4edd4-0c7c-4250-a268-76ff68800ed0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 114,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.825162,
                "lon": -122.159339
            },
            "entity": {
                "abbreviation": "Canyon Elementary School",
                "alternates": {
                    "nces": "060735000680"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4fe4edd4-0c7c-4250-a268-76ff68800ed0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Canyon, CA",
                "name": "Canyon Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "5061a77e-e472-4a2b-ac66-f74645bd3499"
                },
                "premium": false,
                "shortName": "Canyon Elementary School",
                "tagline": [
                    "Public School",
                    "Canyon, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "canyon-elementary-school-canyon-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 67
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 34
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.167,37.8308,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.167,37.8308,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94516"
            }
        ]
    },
    {
        "guid": "baa99c99-64e4-426d-b3b1-cb9de7ca30ed",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 115,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.537613,
                "lon": -121.985917
            },
            "entity": {
                "abbreviation": "G. M. Walters Junior High School",
                "alternates": {
                    "nces": "061440001689"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "baa99c99-64e4-426d-b3b1-cb9de7ca30ed",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "G.M. Walters Junior High School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "G. M. Walters Junior High School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "gm-walters-junior-high-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 727
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "I liked the teachers and the classes. I also liked how we got off early on some days. For my physical education class I enjoyed learning how to perform CPR. I think they can better improve on having committed teachers who want to teach the students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-17T18:42:04.214804Z",
                "guid": "dae29caf-ddc1-4c37-8db0-134bc671fafe",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.983,37.5376,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.983,37.5376,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.7,
                "count": 10
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "86e769e5-d247-401b-9e8d-41ff25071386",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 116,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.726448,
                "lon": -121.728835
            },
            "entity": {
                "abbreviation": "Andrew N. Christensen Middle School",
                "alternates": {
                    "nces": "062211002618"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "86e769e5-d247-401b-9e8d-41ff25071386",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Andrew N. Christensen Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Andrew N. Christensen Middle School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "andrew-n-christensen-middle-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 715
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "My sister go to this school, this place is well rounded, they have honors classes, Band and Orchestra,STEM classes. Staff are friendly and caring .",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-04-27T19:11:04.14856Z",
                "guid": "3afb83d4-942f-4982-b494-e212ad5d9489",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.729,37.7261,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.729,37.7261,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.8,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "b6c633b9-571e-4add-b52f-803ece5f3b4b",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 117,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.004674,
                "lon": -122.604795
            },
            "entity": {
                "abbreviation": "White Hill Middle School",
                "alternates": {
                    "nces": "060000608774"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b6c633b9-571e-4add-b52f-803ece5f3b4b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fairfax, CA",
                "name": "White Hill Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "schoolDistrict": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "d8aac2d4-6266-4e7a-a585-8758fbf27804",
                    "zipCode": "98575946-123b-48d5-8cc4-beebd45ad068"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Ross Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e787e270-2623-42bc-917f-95d6d7ff2026",
                        "location": "San Anselmo, CA",
                        "name": "Ross Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                            "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                        },
                        "premium": false,
                        "shortName": "Ross Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Anselmo, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "ross-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "White Hill Middle School",
                "tagline": [
                    "Ross Valley Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "white-hill-middle-school-fairfax-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 744
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I go here and it's pretty good for a middle school. Most of the teachers are good (some aren't though), the work is hard but like not to an extreme extent, there aren't really bullies (and physical fights are rare), and I think that compared to a ton of schools its very nice. The only bad thing is most kids are upper-middle-class privileged white kids so they don't appreciate the school and havent really experienced much hardship in life, but when you see how bad we could have it with other schools it really outs things into perspective.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-17T01:44:23.759169Z",
                "guid": "735e6372-e926-4917-a0df-b3d57acff7c1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.606,38.0035,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.606,38.0035,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.888888888888889,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94930"
            }
        ]
    },
    {
        "guid": "5fe8e717-a048-4848-bb1e-470a8aaed346",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 118,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.613641,
                "lon": -122.485801
            },
            "entity": {
                "abbreviation": "Vallemar Elementary School",
                "alternates": {
                    "nces": "062046002470"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5fe8e717-a048-4848-bb1e-470a8aaed346",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Vallemar Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Vallemar Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "vallemar-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 514
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Vallemar Elementary School provides a high standard of education in english, social studies, math and science to their students, preparing them sufficiently for any college level courses they may take in high school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T06:52:31.88166Z",
                "guid": "fd8456e6-765a-4215-8a72-5db8b9e8ae03",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.484,37.6143,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.484,37.6143,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "ade511e2-2668-4923-85b7-e524e008c4a5",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 119,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.412104,
                "lon": -122.085087
            },
            "entity": {
                "abbreviation": "Crittenden Middle School",
                "alternates": {
                    "nces": "062628006931"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ade511e2-2668-4923-85b7-e524e008c4a5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Crittenden Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Crittenden Middle School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "crittenden-middle-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 707
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.085,37.4116,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.085,37.4116,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94043"
            }
        ]
    },
    {
        "guid": "94aa9eeb-1adb-4fc8-b330-24bda6068ee1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 120,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.312721,
                "lon": -121.766693
            },
            "entity": {
                "abbreviation": "Chaboya Middle School",
                "alternates": {
                    "nces": "061314010321"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "94aa9eeb-1adb-4fc8-b330-24bda6068ee1",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Chaboya Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "7b9128e4-a557-4f99-aa1e-3d1bcd32a36a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Chaboya Middle School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "chaboya-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1094
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I have great memories at this school. The work was rigorous and most of the teachers were fantastic. There weren't many clubs, but several sports. The food was typical public school hot lunch food, not horrible but not amazing. All in all, my years at Chaboya were some of the best in my life!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-15T22:02:08.451894Z",
                "guid": "243925d3-f8ed-49f6-9375-2a530dbcf339",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.762,37.3109,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.762,37.3109,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8333333333333335,
                "count": 18
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95135"
            }
        ]
    },
    {
        "guid": "9bfd8789-1c12-4cd1-b7d9-90eb299954d9",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 121,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.402951,
                "lon": -121.856209
            },
            "entity": {
                "abbreviation": "Sierramont Middle School",
                "alternates": {
                    "nces": "060480000466"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9bfd8789-1c12-4cd1-b7d9-90eb299954d9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Sierramont Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sierramont Middle School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "sierramont-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 930
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "I went to Sierramont Middle School for all three years from 6th to 8th grade, and it was certainly a very memorable and incredible experience! The teachers truly care about their students and the staff is overall very supportive! The students are friendly and open and overall the school feels like a family! Thank you Sierramont for providing me this excellent Middle School Experience!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-05T00:26:08.281703Z",
                "guid": "50df87f0-fd3f-4612-bd20-c3011552d686",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.857,37.4042,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.857,37.4042,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.142857142857143,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "7f037130-ecb0-451e-a76b-78b0e5a1b6cb",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 122,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.681018,
                "lon": -121.734372
            },
            "entity": {
                "abbreviation": "East Avenue Middle School",
                "alternates": {
                    "nces": "062211002623"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "7f037130-ecb0-451e-a76b-78b0e5a1b6cb",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "East Avenue Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "East Avenue Middle School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "east-avenue-middle-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 649
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.75,37.6784,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.75,37.6784,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "e8d058c2-bc64-4d7e-9bd0-a6000a63bf36",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 123,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.278863,
                "lon": -121.972258
            },
            "entity": {
                "abbreviation": "Southside Elementary School",
                "alternates": {
                    "nces": "063765006379"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e8d058c2-bc64-4d7e-9bd0-a6000a63bf36",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hollister, CA",
                "name": "Southside Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                },
                "premium": false,
                "shortName": "Southside Elementary School",
                "tagline": [
                    "Public School",
                    "Hollister, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "southside-elementary-school-hollister-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 229
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.365,36.8007,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.365,36.8007,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95023"
            }
        ]
    },
    {
        "guid": "ca3b4420-08ee-4a07-8f99-b9148e9d5929",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 124,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.935376,
                "lon": -122.070431
            },
            "entity": {
                "abbreviation": "Horizons Alternative School",
                "alternates": {
                    "nces": "062637009946"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ca3b4420-08ee-4a07-8f99-b9148e9d5929",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Horizons School: Independent Study",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Horizons Alternative School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "horizons-school--independent-study-pleasant-hill-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 155
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 11
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "Horizons Independent Study School provides outstanding opportunities for students who (1) can't go to regular school because of other commitments (e.g., sports); (2) want to finish school faster; or (3) don't like going to regular school. Each student at Horizons gets an individual advisor who helps you through the year. Advisors are wonderful: very knowledgeable and caring! You get free help for math! Plus you can complete courses at the local community college earning college and high school credits at the same time.  Love it.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-01-25T16:41:20.608285Z",
                "guid": "5c7b17ee-c9df-42c6-8c91-d49882e6fa12",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.068,37.9336,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.068,37.9336,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.388888888888889,
                "count": 18
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "bc56bf05-0fea-4772-b27b-e27d2668dec2",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 125,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.38722,
                "lon": -121.903159
            },
            "entity": {
                "abbreviation": "Orchard Elementary School",
                "alternates": {
                    "nces": "062868004460"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bc56bf05-0fea-4772-b27b-e27d2668dec2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Orchard Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "782e20d7-90eb-4994-919f-95fc800744f4"
                },
                "premium": false,
                "shortName": "Orchard Elementary School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "orchard-elementary-school-san-jose-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 843
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "the teachers and staff were really nice and helped my student feel better about the school online. when they moved to in lass sessions it was a good transition.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-31T21:30:23.451988Z",
                "guid": "c5108551-149a-4f09-97a7-01f7404dae2d",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.903,37.3875,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.903,37.3875,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95131"
            }
        ]
    },
    {
        "guid": "88dbd9c4-e2a7-4bb6-a913-e61c4bf023b1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 126,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482126,
                "lon": -122.236002
            },
            "entity": {
                "abbreviation": "North Star Academy",
                "alternates": {
                    "nces": "063213007422"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "88dbd9c4-e2a7-4bb6-a913-e61c4bf023b1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "North Star Academy",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a5dafe95-b00a-4d53-8912-9559cbb65dd9",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "7d773137-7242-4cc9-91c9-d3770c1b9f33"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "North Star Academy",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "3-8"
                ],
                "type": "School",
                "url": "north-star-academy-redwood-city-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 533
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "North Star is a great school! The material being taught isn't perfect, but its better than a lot of other schools, as it offers more of a challenge and sometimes we are being taught in interesting and engaging ways. The school isn't especially diverse, nor are all the teachers very good (most of them are, but some aren't very fair or nice). There is lots of parent involvement, and the principle and most of the staff is very nice. I have a gluten allergy, and the people working at the cafeteria make special (delicious) meals, which is very nice of them. Overall, this school is great!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-02T18:37:07.162219Z",
                "guid": "78563a33-92c6-4efe-b861-4da0c327fa07",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.4808,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.4808,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.888888888888889,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "907b79b9-227a-450e-ab66-45313ce18d1f",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 127,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.397249,
                "lon": -121.94383
            },
            "entity": {
                "abbreviation": "Don Callejon",
                "alternates": {
                    "nces": "063543011797"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "907b79b9-227a-450e-ab66-45313ce18d1f",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Don Callejon",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "5e59747f-8fae-4054-a7a1-457bbce26323"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Don Callejon",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "don-callejon-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 912
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "It a  great school. our son is going for 2 years. he loves the school and teachers. its a racially diversified school and we love the way teachers and students interact with each other. Wish it had more land and space so that children could have better play areas and more rooms for classes too. We wish this school upgrades to a high school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-01-26T19:06:35.440258Z",
                "guid": "93b609fc-8e58-4437-a73d-4f5490b18dcd",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.947,37.3986,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.947,37.3986,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95054"
            }
        ]
    },
    {
        "guid": "f9c0c324-2d6d-41aa-a6a8-bc0b4e423e90",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 128,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.550277,
                "lon": -122.322581
            },
            "entity": {
                "abbreviation": "Borel Middle School",
                "alternates": {
                    "nces": "063492005901"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f9c0c324-2d6d-41aa-a6a8-bc0b4e423e90",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Borel Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "062f4806-af97-4d46-8d31-2a26304d0e2f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Borel Middle School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "borel-middle-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1062
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "It gave me an awesome experience of Middle School. The teachers are really nice. The office staff are really kind. The music program is great.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-09-29T20:27:18.950912Z",
                "guid": "14c15f46-b752-49e7-ae63-d8fa8bffc964",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.321,37.5496,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.321,37.5496,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.333333333333333,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94402"
            }
        ]
    },
    {
        "guid": "f61778db-167f-4654-80b7-9dd7e813b039",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 129,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.291216,
                "lon": -121.977975
            },
            "entity": {
                "abbreviation": "Latimer School",
                "alternates": {
                    "nces": "062577013806"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f61778db-167f-4654-80b7-9dd7e813b039",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Latimer Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Latimer School",
                "tagline": [
                    "Moreland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "latimer-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 554
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.2924,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.2924,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95130"
            }
        ]
    },
    {
        "guid": "bdb6bf2a-d16c-46b9-94c2-897031b17690",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 130,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.239332,
                "lon": -121.839746
            },
            "entity": {
                "abbreviation": "Herman (Leonard) Intermediate",
                "alternates": {
                    "nces": "062781004216"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bdb6bf2a-d16c-46b9-94c2-897031b17690",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Leonard Herman Intermediate School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Herman (Leonard) Intermediate",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "leonard-herman-intermediate-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 871
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "High School Sophomore",
                "body": "I like the programs that they offer at Herman because they are offering some of the courses that the high schools around are offering but at a higher level. They defiantly prepare you for what is to come in high school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2015-01-23T01:39:47Z",
                "guid": "f5714ef1-094c-48d6-94ad-8788d7971ba8",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.839,37.2394,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.839,37.2394,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "b9446450-a98e-41f1-9df4-4c1a3be44281",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 131,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.730214,
                "lon": -122.466213
            },
            "entity": {
                "abbreviation": "Aptos Middle School",
                "alternates": {
                    "nces": "063441005585"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b9446450-a98e-41f1-9df4-4c1a3be44281",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Aptos Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "3541273e-c42b-45f7-9a8f-3f1a2d367781",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "50a82c65-04cd-4b00-bb26-c5f015b369e3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Aptos Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "aptos-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 976
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.466,37.7296,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.466,37.7296,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94127"
            }
        ]
    },
    {
        "guid": "c3320f00-323f-4fe7-a973-45fc1cfb0551",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 132,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.745315,
                "lon": -122.470324
            },
            "entity": {
                "abbreviation": "Herbert Hoover Middle",
                "alternates": {
                    "nces": "063441005624"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c3320f00-323f-4fe7-a973-45fc1cfb0551",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Herbert Hoover Middle",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "1ff6fd3c-2bb4-41a9-865c-4242ed48fe0f",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "dfff3cae-3a03-46a7-b060-62db0e2af199"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Herbert Hoover Middle",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "herbert-hoover-middle-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 960
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "It was a good school, but there were some teachers that were not that good. I made many good friends there and I will miss them very much next year.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-06-16T05:16:08.314452Z",
                "guid": "a5f7707e-3539-4bf8-98b9-3409d75d0d12",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.469,37.7454,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.469,37.7454,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.8333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94116"
            }
        ]
    },
    {
        "guid": "5c4dbaf5-722e-4942-b619-a7a470c9a1ba",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 133,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.651605,
                "lon": -122.489855
            },
            "entity": {
                "abbreviation": "Ocean Shore Elementary School",
                "alternates": {
                    "nces": "062046002469"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5c4dbaf5-722e-4942-b619-a7a470c9a1ba",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Ocean Shore Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ocean Shore Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "ocean-shore-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 432
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.49,37.6509,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.49,37.6509,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "9cb2851b-b929-4a43-9e3e-7ecf45b4f009",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 134,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.324954,
                "lon": -121.783861
            },
            "entity": {
                "abbreviation": "Quimby Oak Middle School",
                "alternates": {
                    "nces": "061314001499"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9cb2851b-b929-4a43-9e3e-7ecf45b4f009",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Quimby Oak Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Quimby Oak Middle School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "quimby-oak-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 980
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "My favorite teacher was Mr.Guerrero.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2015-07-10T03:38:35Z",
                "guid": "6236dc89-0815-4cff-b9aa-14acfd84eacc",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.786,37.324,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.786,37.324,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.3333333333333335,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "6f53edb2-1046-4068-bf15-fd3af3fa7a35",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 135,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.931562,
                "lon": -121.690763
            },
            "entity": {
                "abbreviation": "Edna Hill Middle School",
                "alternates": {
                    "nces": "060591000535"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6f53edb2-1046-4068-bf15-fd3af3fa7a35",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Edna Hill Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Edna Hill Middle School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "edna-hill-middle-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 976
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "It’s pretty good, Most of the teachers are good. I mean, I can’t really complain it’s pretty good, we have good resources, they give us chrome books, and great diversity. The food is nasty tho lol",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-06T01:32:41.053443Z",
                "guid": "e7b086dc-5767-48ba-b4e6-c3455ff1216f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.691,37.9307,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.691,37.9307,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "1f89ba0f-7e97-4535-942d-19337272ee21",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 136,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.754174,
                "lon": -122.444146
            },
            "entity": {
                "abbreviation": "Rooftop Elementary School",
                "alternates": {
                    "nces": "063441005661"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1f89ba0f-7e97-4535-942d-19337272ee21",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Rooftop Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bffbbdb0-b26c-4342-b1b0-6ff6fe27c76d",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "ee205ed4-1f3f-448a-8f17-065ffce55d35"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rooftop Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "rooftop-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 568
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Rooftop offers a smaller educational environment that's safe and nurturing to students’ academic and emotional needs. I like that the school’s emphasis on the inclusion of art to teach various subject matters. My child has attended this school since kindergarten and now  is in the eighth grade.  Rooftop has provided  several, various learning opportunities which have positively contributed to my child’s growth/development. The administration has always been helpful and receptive to our family.  We’ve always viewed the school as a PARTNERSHIP in the endeavor of educating a child. The teachers have well prepared my child for high school. Rooftop is a beautiful and an excellent PUBLIC school in San Francisco.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-16T21:22:50.616968Z",
                "guid": "58f8cb42-bdd8-4f5b-9390-1fd9510e72b1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.444,37.7549,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.444,37.7549,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94131"
            }
        ]
    },
    {
        "guid": "17ab27de-40ed-499f-948e-0566280852c1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 137,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.932501,
                "lon": -121.922997
            },
            "entity": {
                "abbreviation": "Diablo View Middle School",
                "alternates": {
                    "nces": "062637001967"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "17ab27de-40ed-499f-948e-0566280852c1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Clayton, CA",
                "name": "Diablo View Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f0b6dd7a-adbc-460d-a370-82088cf801be",
                    "zipCode": "e1047bb3-fb68-4a8e-b4c7-769c73094f23"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Diablo View Middle School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "diablo-view-middle-school-clayton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 688
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "I really liked the schedule and all the teachers. There was also a lot of involvement of the staff and lots of fun activities going on.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-27T04:40:02.443135Z",
                "guid": "00a94b75-af07-4a81-8e26-39ecae543d07",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.9301,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.9301,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94517"
            }
        ]
    },
    {
        "guid": "2f19009d-389f-4d05-9828-d77ac5ab5a88",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 138,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.863422,
                "lon": -122.500895
            },
            "entity": {
                "abbreviation": "Willow Creek Academy",
                "alternates": {
                    "nces": "060160808732"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "2f19009d-389f-4d05-9828-d77ac5ab5a88",
                "isClaimed": false,
                "isPremium": false,
                "location": "Sausalito, CA",
                "name": "Willow Creek Academy",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e6ebb77a-f04a-4ff1-9349-1d65c07852a2",
                    "zipCode": "98b8b9f2-6679-4526-81fb-59677c56d1b9"
                },
                "premium": false,
                "shortName": "Willow Creek Academy",
                "tagline": [
                    "Public School",
                    "Sausalito, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "willow-creek-academy-sausalito-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 409
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 31
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "There are so many reasons why our family feels remarkably lucky to be at WCA. We were most drawn to the mix of income levels, racial and cultural backgrounds and languages, over any other school in Marin. The beautiful campus overlooks the Bay and has 4 gardens. WCA’s greatest strength is undoubtedly the teachers - a passionate, fun-spirited group who use hands-on, project-based learning methods and are innovative and creative in their approaches. We have found the administration to be personally responsive and accessible and encouraging of parent engagement. It is a joy to see kids playing ukelele, xylophone and percussion in our dynamic music program, as well as the murals, sculptures and paintings from our vibrant art classes. And did I mention the freshly cooked school lunches, with all organic food? It's SO many things!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-30T19:49:46.284798Z",
                "guid": "5123fed3-479a-498b-9c5d-45ad3fb61813",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.503,37.8632,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.503,37.8632,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94965"
            }
        ]
    },
    {
        "guid": "b837b095-a6ff-46c5-a2ca-4731ccf5e2bd",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 139,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.637448,
                "lon": -122.492351
            },
            "entity": {
                "abbreviation": "Ingrid B. Lacy Middle School",
                "alternates": {
                    "nces": "062046010657"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b837b095-a6ff-46c5-a2ca-4731ccf5e2bd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Ingrid B. Lacy Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ingrid B. Lacy Middle School",
                "tagline": [
                    "Pacifica School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "ingrid-b-lacy-middle-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 544
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "As a middle schooler Ingrid B. Lacy was the perfect school to prepare children for high school. The curriculum was challenging but in a way that made me want to work harder so that  I could understand it. The teachers were extremely supportive and it was evident that they wanted all of their students to succeed. The only fault I have with the school is that so many teachers left and were replaced by newer once every year so it was difficult to form long term relationships with these teachers.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-29T21:29:52.937807Z",
                "guid": "f0533c03-2d9c-4f26-9feb-3446284429f2",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.492,37.6385,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.492,37.6385,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "8c5e1bf0-3611-4445-b812-97de02eda03c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 140,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.3594,
                "lon": -121.97236
            },
            "entity": {
                "abbreviation": "Juan Cabrillo Middle School",
                "alternates": {
                    "nces": "063543001599"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "8c5e1bf0-3611-4445-b812-97de02eda03c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Juan Cabrillo Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Juan Cabrillo Middle School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "juan-cabrillo-middle-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 908
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.972,37.3583,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.972,37.3583,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "ca551d45-9bbb-44e4-815a-e43ecfa380b1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 141,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.932175,
                "lon": -121.714601
            },
            "entity": {
                "abbreviation": "William B. Bristow Middle School",
                "alternates": {
                    "nces": "060591004444"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ca551d45-9bbb-44e4-815a-e43ecfa380b1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "William B. Bristow Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "William B. Bristow Middle School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "william-b-bristow-middle-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1193
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "The School was overall nice, but just like all other schools, it had its issues. The main problem is the administration, it is poorly structured and executed.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-05-07T21:31:02.284647Z",
                "guid": "f87bb8e5-eb0b-4b3b-9b6c-a973771f8249",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.716,37.9378,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.716,37.9378,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "48b84c13-6496-4e11-a3d5-6504ab5f0e3d",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 142,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.227205,
                "lon": -121.790889
            },
            "entity": {
                "abbreviation": "Bernal Intermediate",
                "alternates": {
                    "nces": "062781004205"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "48b84c13-6496-4e11-a3d5-6504ab5f0e3d",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Bernal Intermediate School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bernal Intermediate",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "bernal-intermediate-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 742
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "The school is pretty good, considering its environment and teachers. It is a good school with plenty of opportunities for students to be in",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-05-18T00:31:24.657405Z",
                "guid": "31ec5c1b-38e2-4ab5-86c5-7b33fe8e582b",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.789,37.2264,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.789,37.2264,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95119"
            }
        ]
    },
    {
        "guid": "4be4380c-95b2-4fec-aafd-23421a9e40af",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 143,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.684953,
                "lon": -122.404692
            },
            "entity": {
                "abbreviation": "Lipman Middle School",
                "alternates": {
                    "nces": "060606000542"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4be4380c-95b2-4fec-aafd-23421a9e40af",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brisbane, CA",
                "name": "Lipman Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                    "schoolDistrict": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9bc6d672-7a23-47c9-8ec7-b0899a2d1a74",
                    "zipCode": "a3b9a792-eb0b-4455-bc46-9ccde2b7c60e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brisbane Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                        "location": "Brisbane, CA",
                        "name": "Brisbane Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9bc6d672-7a23-47c9-8ec7-b0899a2d1a74",
                            "zipCode": "a3b9a792-eb0b-4455-bc46-9ccde2b7c60e"
                        },
                        "premium": false,
                        "shortName": "Brisbane Elementary School District",
                        "tagline": [
                            "School District",
                            "Brisbane, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brisbane-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Lipman Middle School",
                "tagline": [
                    "Brisbane Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "lipman-middle-school-brisbane-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 139
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I go here. It’s cool.:):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):):",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-04T02:49:25.308068Z",
                "guid": "f8285b88-bf8d-4f78-9cc5-a7c3d67a65b9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.405,37.6852,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.405,37.6852,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94005"
            }
        ]
    },
    {
        "guid": "5827db52-6bca-4de3-baac-f45186a4f670",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 144,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.221059,
                "lon": -121.881788
            },
            "entity": {
                "abbreviation": "Castillero Middle School",
                "alternates": {
                    "nces": "063459005707"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5827db52-6bca-4de3-baac-f45186a4f670",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Castillero Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Castillero Middle School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "castillero-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1133
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.879,37.2202,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.879,37.2202,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95120"
            }
        ]
    },
    {
        "guid": "b039d73d-821f-4bcb-974e-e4e3f478116c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 145,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.458568,
                "lon": -122.256245
            },
            "entity": {
                "abbreviation": "Roy Cloud Elementary School",
                "alternates": {
                    "nces": "063213004963"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b039d73d-821f-4bcb-974e-e4e3f478116c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Roy Cloud Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f16f48a5-122d-4be7-8d77-b5fe1a5cbce3",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "711f94d7-a522-4b45-a751-5a884e5670a6"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Roy Cloud Elementary School",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "roy-cloud-elementary-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 751
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Roy Cloud is by far the best school for miles for education. The teachers are odd, and they could use improvement in disability care, but the quality of education, at least in my opinion far out weighed it's flaws. I went to Roy Cloud for K-8. I didn't necessarily enjoy my time there, which I think is true for many, though I account that more to the faults of schools themselves. The teachers and staff were very nice. I still go back and talk to them. \nThe brilliance of Roy Cloud is difficult to see until you leave. When I went to Woodside High School, I found that many Cloud kids were leagues above students who had been to other schools simply by being at Roy Cloud. Some of the work advanced classes had to stop and review over for other students had been taught to Cloud kids in 3rd or 4th grade. My AS (advanced standing) English class was made up of more than a third of kids from Roy Cloud. One of the smaller middle school feeders out of 26.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-13T09:45:13.018105Z",
                "guid": "d5fb4c8c-d00a-4bf1-9886-f945554d8f8e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.256,37.4597,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.256,37.4597,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94061"
            }
        ]
    },
    {
        "guid": "478220e1-d348-4c60-be12-4893055456b2",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 146,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.669681,
                "lon": -121.783599
            },
            "entity": {
                "abbreviation": "Joe Michell",
                "alternates": {
                    "nces": "062211002628"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "478220e1-d348-4c60-be12-4893055456b2",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Joe Michell",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joe Michell",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "joe-michell-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 819
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.783,37.6685,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.783,37.6685,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "ef9a71ea-8982-480b-b154-615dd9830ca1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 147,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.591463,
                "lon": -122.072789
            },
            "entity": {
                "abbreviation": "Alvarado Middle School",
                "alternates": {
                    "nces": "062691004066"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "ef9a71ea-8982-480b-b154-615dd9830ca1",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Itliong-Vera Cruz",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                    "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alvarado Middle School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "itliong-vera-cruz-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1396
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I went back when it was AMS, I am glad about the renaming for the Filipino figures though! The sciences building is nicest and I had some passionate teachers (although later on, AP sciences in high school were too challenging). My math teachers were quite encouraging, too. 7th grade was the hardest year, as Core (English/Language Arts and History) increased in homework.  The student-led yearbook was great under the direction of the adviser when I was there! I have fond memories of clubs (ecology, media center, etc.) I joined too!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-13T23:15:40.93487Z",
                "guid": "835f5d0e-771d-4837-9c8a-6243f9036276",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.073,37.5914,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.073,37.5914,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "22bb9a91-1834-4b2d-b569-8a3d265d2995",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 148,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.801755,
                "lon": -122.436282
            },
            "entity": {
                "abbreviation": "Marina Middle School",
                "alternates": {
                    "nces": "063441005645"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "22bb9a91-1834-4b2d-b569-8a3d265d2995",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Marina Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "fd7d4388-deaa-4a97-b3a1-ea5c44d25269",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b1d5d1b3-1a17-4487-9231-69eb48dcc12a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Marina Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "marina-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 790
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "High School Junior",
                "body": "The school holds many memories from my childhood.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2014-12-28T00:26:24Z",
                "guid": "4dd2136a-7f40-4568-a366-0004e46d2402",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.8019,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.8019,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94123"
            }
        ]
    },
    {
        "guid": "9604a4ec-c9e0-4018-92a5-b12ddb5708b2",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 149,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.320733,
                "lon": -121.848264
            },
            "entity": {
                "abbreviation": "College Connection Academy",
                "alternates": {
                    "nces": "061437013199"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "9604a4ec-c9e0-4018-92a5-b12ddb5708b2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "College Connection Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "College Connection Academy",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "college-connection-academy-san-jose-ca-061437013199",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 210
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "College Connection Academy is a great school since it helps prepare you for college. You'll be part of a program that during middle school, you can take high school classes since the school is on a high school campus. When you become a high school you'll still be part of the program that you can take college classes during high school. After the program, you'll have some college credit to be prepared in college. The staff is very nice and supportive of students. They can also help you deal with personal problems in life. The food is also good as well. The downside though of this school is that the school is small so it doesn't have many students and that they sell too many unhealthy snacks. Overall, it's a great school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-01-11T03:09:53.798399Z",
                "guid": "da42ba27-428a-458e-9de5-d24ef92fd802",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.849,37.3184,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.849,37.3184,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "b8d0838f-9b46-488e-a105-6013af704776",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 150,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.105903,
                "lon": -122.597124
            },
            "entity": {
                "abbreviation": "Sinaloa Middle School",
                "alternates": {
                    "nces": "062772004198"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b8d0838f-9b46-488e-a105-6013af704776",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Sinaloa Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sinaloa Middle School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "sinaloa-middle-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 813
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Sinaloa Middle School has an amazing music program which makes this school a bit above average, especially around Novato, this school is considered one of the best. This school has some pretty good teachers but some are there just to torture the poor kids I definitely enjoyed my time there because of the music program, but other than that, this school is pretty much average. Another thing I would like to add is that this school is harsh on kids, and it's not just teachers. Let's just say you were late to class by a minute. Guess what, you just earned yourself detention, even if that was your first time being late! I think this is ridiculous. Other than that major complement, this school is okay.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-02-26T02:06:59.832412Z",
                "guid": "35a47895-8103-478e-ab4e-1b5bac0fe832",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.598,38.1043,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.598,38.1043,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.75,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "5e9b3ebc-9c48-4377-b3af-f98476cff526",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 151,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.6441,
                "lon": -122.461
            },
            "entity": {
                "abbreviation": "Westborough Middle School",
                "alternates": {
                    "nces": "063753006356"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5e9b3ebc-9c48-4377-b3af-f98476cff526",
                "isClaimed": false,
                "isPremium": false,
                "location": "South San Francisco, CA",
                "name": "Westborough Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                    "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Westborough Middle School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "westborough-middle-school-south-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 611
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "I like the community of teachers and the recognition given to the Fine Arts and Athletic departments.  The school is on the quieter and more respectful side of the spectrum of high schools.  The food is a bit of a concern, but it is cafeteria food.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-12-12T00:22:21.471955Z",
                "guid": "07917866-f4b6-469d-a3f7-440633fb40c5",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.461,37.6441,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.461,37.6441,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94080"
            }
        ]
    },
    {
        "guid": "424bd5ec-173c-4ebc-8720-9e27e1f75e05",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 152,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.746794,
                "lon": -122.141509
            },
            "entity": {
                "abbreviation": "East Bay Innovation Academy",
                "alternates": {
                    "ceeb": "059801",
                    "nces": "060219213735"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "424bd5ec-173c-4ebc-8720-9e27e1f75e05",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "East Bay Innovation Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "3816271d-bf9c-431c-9e92-8f63ddf53940"
                },
                "premium": false,
                "shortName": "East Bay Innovation Academy",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "east-bay-innovation-academy-oakland-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 562
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "The teachers are incredibly invested in student success. Very small school, so you get close to your classmates and teachers. EBIA is hands-on and STEAM-based and has a college preparatory curriculum. The only reason it's getting a four-star and not a five is because they don't have any sports teams and the school events are limited. However, I played varsity soccer at Skyline Highschool, which was fine!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-21T21:20:05.91294Z",
                "guid": "797ad381-dd55-4e0d-a0c8-77aa8f72feae",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.141,37.7456,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.141,37.7456,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.428571428571429,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94605"
            }
        ]
    },
    {
        "guid": "10cd6a7c-98ad-4fbc-98f9-81a377fcf804",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 153,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.596838,
                "lon": -122.496867
            },
            "entity": {
                "abbreviation": "Cabrillo Elementary School",
                "alternates": {
                    "nces": "062046002458"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "10cd6a7c-98ad-4fbc-98f9-81a377fcf804",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Cabrillo Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cabrillo Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "cabrillo-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 562
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.496,37.598,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.496,37.598,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "fe4acb51-438b-4ad2-b9e6-bb9b42a0c072",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 154,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.779194,
                "lon": -122.435411
            },
            "entity": {
                "abbreviation": "Gateway Middle School",
                "alternates": {
                    "nces": "060186112800"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "fe4acb51-438b-4ad2-b9e6-bb9b42a0c072",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Gateway Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9ae6d1c0-ee34-4266-84ab-3a6632674608",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "9fbdd391-c59a-4ee3-a774-a928ff2f0905"
                },
                "premium": false,
                "shortName": "Gateway Middle School",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "gateway-middle-school-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 309
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7795,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7795,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94115"
            }
        ]
    },
    {
        "guid": "714bf623-3a74-4268-9da9-0279d9abb0d0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 155,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.804831,
                "lon": -122.411641
            },
            "entity": {
                "abbreviation": "Francisco Middle School",
                "alternates": {
                    "nces": "063441005610"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "714bf623-3a74-4268-9da9-0279d9abb0d0",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Francisco Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a6d5c610-2f7d-4aa9-a385-9f3be8d8ba89",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a9ba46dd-c464-469c-b61f-0bb664188fda"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Francisco Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "francisco-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 560
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I had a great time here at Francisco Middle School as an immigrant from Vietnam. This school has a strong ESL program that enables many newcomers to gain confidence in speaking and writing English. The teachers are nice and very understanding.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-06-02T19:17:24.675512Z",
                "guid": "78a7046c-142b-4e0b-8d4c-7bb4a509972b",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.412,37.805,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.412,37.805,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94133"
            }
        ]
    },
    {
        "guid": "fc584726-455c-434b-90cd-2a331aa8adea",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 156,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.34469,
                "lon": -121.943192
            },
            "entity": {
                "abbreviation": "Buchser Middle School",
                "alternates": {
                    "nces": "063543007866"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "fc584726-455c-434b-90cd-2a331aa8adea",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Buchser Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "91f8ca89-1d96-436a-8a3f-5e45a8940ad1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Buchser Middle School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "buchser-middle-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1011
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.3443,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.3443,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95050"
            }
        ]
    },
    {
        "guid": "3bedb133-2ecb-457c-baa4-c75333a73bc3",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 157,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.014211,
                "lon": -122.130376
            },
            "entity": {
                "abbreviation": "Martinez Junior High School",
                "alternates": {
                    "nces": "062403003618"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3bedb133-2ecb-457c-baa4-c75333a73bc3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Martinez, CA",
                "name": "Martinez Junior High School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "schoolDistrict": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                    "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Martinez Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb309258-c416-4193-9d2e-072248ea2ba6",
                        "location": "Martinez, CA",
                        "name": "Martinez Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                            "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                        },
                        "premium": false,
                        "shortName": "Martinez Unified School District",
                        "tagline": [
                            "School District",
                            "Martinez, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "martinez-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Martinez Junior High School",
                "tagline": [
                    "Martinez Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "martinez-junior-high-school-martinez-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 966
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.131,38.013,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.131,38.013,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94553"
            }
        ]
    },
    {
        "guid": "1938b1bd-bbfa-4940-b61c-29bfc31e953c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 158,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.858728,
                "lon": -122.28027
            },
            "entity": {
                "abbreviation": "Longfellow Middle School",
                "alternates": {
                    "nces": "060474000446"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1938b1bd-bbfa-4940-b61c-29bfc31e953c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Longfellow Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4870ba36-d978-42b8-9459-d6834bf05892",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Longfellow Middle School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "longfellow-middle-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 497
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.279,37.8585,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.279,37.8585,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94703"
            }
        ]
    },
    {
        "guid": "57948aa3-d3a1-4f77-b6e2-c290794bbd8c",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 159,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.943483,
                "lon": -122.065776
            },
            "entity": {
                "abbreviation": "Sequoia Middle School",
                "alternates": {
                    "nces": "062637008916"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "57948aa3-d3a1-4f77-b6e2-c290794bbd8c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Sequoia Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sequoia Middle School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "sequoia-middle-school-pleasant-hill-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 931
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.9448,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.9448,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "22762a8b-d27b-456b-8c4f-4d785df186e8",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 160,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.289967,
                "lon": -121.895349
            },
            "entity": {
                "abbreviation": "Willow Glen Middle School",
                "alternates": {
                    "nces": "063459005710"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "22762a8b-d27b-456b-8c4f-4d785df186e8",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Willow Glen Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Willow Glen Middle School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "willow-glen-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1225
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Writing this so they are not at 1 star. your welcome huhuhuhuhuhuhuhuhuhuhhuhuuhuhuhhuuhuhuhuuhuujjj",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-03-30T18:44:35.301798Z",
                "guid": "6dd6e8c7-df68-44e0-98ec-6e5e44258fcd",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.896,37.2895,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.896,37.2895,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "648ee54f-dca9-4454-a8a4-a25357b32ebb",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 161,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482028,
                "lon": -122.262936
            },
            "entity": {
                "abbreviation": "Clifford School",
                "alternates": {
                    "nces": "063213004962"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "648ee54f-dca9-4454-a8a4-a25357b32ebb",
                "isClaimed": true,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Clifford School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Clifford ",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "clifford-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 554
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We're new to the Clifford community, but after being in \"top-rated\" schools in other parts of the country, I can safely say that Clifford is the best school our children have attended. The teachers are phenomenal, the administration truly care about student development, and the parents are active, especially through the PTO. Very impressed so far!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-05T05:48:59.511053Z",
                "guid": "3bdd4f1e-63f7-4979-b8ce-a12b936d8f1f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.264,37.482,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.264,37.482,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "ad62cb00-d3f3-4eb2-9851-91ff99be0a95",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 162,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.0679,
                "lon": -122.172
            },
            "entity": {
                "abbreviation": "Benicia Middle School",
                "alternates": {
                    "nces": "060462000423"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "ad62cb00-d3f3-4eb2-9851-91ff99be0a95",
                "isClaimed": true,
                "isPremium": false,
                "location": "Benicia, CA",
                "name": "Benicia Middle School",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "schoolDistrict": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                    "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Benicia Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7c21191c-eac4-441c-86d9-aee0b22405db",
                        "location": "Benicia, CA",
                        "name": "Benicia Unified School District",
                        "parentGUIDs": {
                            "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                            "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                        },
                        "premium": false,
                        "shortName": "Benicia Unified School District",
                        "tagline": [
                            "School District",
                            "Benicia, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "benicia-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Benicia Middle School",
                "tagline": [
                    "Benicia Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "benicia-middle-school-benicia-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1063
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "A couple unreasonably strict campus rules such as where you can go or eat during lunch, but i had a few amazing teachers and learned a lot. The art and drama classes were the most fun.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-04-03T02:36:17.408286Z",
                "guid": "da14a896-e864-4866-983e-5c7e1db8b599",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.172,38.0679,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.172,38.0679,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.3333333333333335,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94510"
            }
        ]
    },
    {
        "guid": "a3ead270-5214-41b7-9ce6-053e0a113bce",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 163,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.410385,
                "lon": -121.871065
            },
            "entity": {
                "abbreviation": "Morrill Middle School",
                "alternates": {
                    "nces": "060480000461"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a3ead270-5214-41b7-9ce6-053e0a113bce",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Morrill Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Morrill Middle School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "morrill-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 633
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "High School Senior",
                "body": "Majority of the students are very determined and give it their all in academics and athletics. The school provides for every students needs",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-09-26T16:49:10Z",
                "guid": "61ce8b29-e60d-4102-979b-f4eacffee0a4",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.869,37.4109,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.869,37.4109,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.375,
                "count": 8
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "7dff0eb9-3bba-45ed-85e0-5d226c953b0a",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 164,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.987564,
                "lon": -121.826837
            },
            "entity": {
                "abbreviation": "Antioch Charter Academy",
                "alternates": {
                    "nces": "060202707493"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "7dff0eb9-3bba-45ed-85e0-5d226c953b0a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Antioch, CA",
                "name": "Antioch Charter Academy",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                },
                "premium": false,
                "shortName": "Antioch Charter Academy",
                "tagline": [
                    "Public School",
                    "Antioch, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "antioch-charter-academy-antioch-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 197
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.827,37.9883,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.827,37.9883,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94509"
            }
        ]
    },
    {
        "guid": "59044315-e5cf-4864-9b58-7289d649b751",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 165,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.011154,
                "lon": -121.82086
            },
            "entity": {
                "abbreviation": "Antioch Charter Academy II",
                "alternates": {
                    "nces": "060182211984"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "59044315-e5cf-4864-9b58-7289d649b751",
                "isClaimed": false,
                "isPremium": false,
                "location": "Antioch, CA",
                "name": "Antioch Charter Academy II",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                },
                "premium": false,
                "shortName": "Antioch Charter Academy II",
                "tagline": [
                    "Public School",
                    "Antioch, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "antioch-charter-academy-ii-antioch-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 202
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.825,38.0108,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.825,38.0108,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94509"
            }
        ]
    },
    {
        "guid": "2ef247eb-2b37-4101-89fa-e8cae957163b",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 166,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482808,
                "lon": -122.210395
            },
            "entity": {
                "abbreviation": "Fernando Rivera Intermediate",
                "alternates": {
                    "nces": "061887002283"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2ef247eb-2b37-4101-89fa-e8cae957163b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Fernando Rivera Intermediate School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "844b235c-05df-4456-8ac0-5f90a4c285b8",
                    "parent": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "schoolDistrict": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Jefferson Elementary School District (Daly City)",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                        "location": "Daly City, CA",
                        "name": "Jefferson Elementary School District (Daly City)",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "41a99124-6b7c-496d-9635-39a33c683883",
                            "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                        },
                        "premium": false,
                        "shortName": "Jefferson Elementary School District (Daly City)",
                        "tagline": [
                            "School District",
                            "Daly City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "jefferson-elementary-school-district-daly-city-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Fernando Rivera Intermediate",
                "tagline": [
                    "Jefferson Elementary School District (Daly City), CA",
                    "6-8"
                ],
                "type": "School",
                "url": "fernando-rivera-intermediate-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 513
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "When I first entered middle school, I was quite familiar with the campus because it was connected to my elementary school. Fernando Rivera, or to what we called it, FRS, was a memorable two years. Here, at this school, you can expect great teachers who care and work hard everyday to ensure a good education for students. Every teacher has their own unique personality and attitude that allows for a more engaged class. Along with classes, school assemblies were really fun. During one of my years, we had our first rally and I was the mascot. Even then, all the students had a good time and it was well worth ending the day off with a great event. Overall, it was a great experience at FRS and I certainly enjoyed my two memorable years! Class of 2017!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-01T08:32:16.218442Z",
                "guid": "5a0a7ff9-86ec-4229-97f5-0cfecbc18bb7",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.486,37.677,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.486,37.677,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94015"
            }
        ]
    },
    {
        "guid": "9c01bfc4-e51d-40c2-9215-c49e76e4bc73",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 167,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.840502,
                "lon": -122.2324
            },
            "entity": {
                "abbreviation": "Hillcrest Elementary School",
                "alternates": {
                    "nces": "062805004275"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9c01bfc4-e51d-40c2-9215-c49e76e4bc73",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Hillcrest Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "f80b92b8-46fc-430c-9898-99e764ec21f3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hillcrest Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "hillcrest-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 388
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I have been here for almost nine years, and it has been a very good experience. For a public school, it has a lot of resources, comparable to some private schools. Highly recommend for people looking for a small school with only ~50 students per grade.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-04-20T20:08:21.652758Z",
                "guid": "17bc0300-4d3d-4840-80f6-7e7c79f9fd00",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.233,37.8412,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.233,37.8412,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.8,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94618"
            }
        ]
    },
    {
        "guid": "32642d5f-afe4-4720-bdb5-85feb7286143",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 168,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.260975,
                "lon": -121.883892
            },
            "entity": {
                "abbreviation": "John Muir Middle School",
                "alternates": {
                    "nces": "063459005723"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "32642d5f-afe4-4720-bdb5-85feb7286143",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "John Muir Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "cfe32ecb-646c-4ac4-8146-97a3f348c00e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Muir Middle School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "john-muir-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1064
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Amazing school good sports good academics fantastic teachers teach you everything you need. I had a good experience at John Muir better than a lot of middle schools by far.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-07T06:33:38.976945Z",
                "guid": "e691b19b-34fd-498f-83b1-4254c20bb256",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.883,37.2602,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.883,37.2602,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.333333333333333,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95118"
            }
        ]
    },
    {
        "guid": "c255946e-ac81-471c-b16f-252c18e99b15",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 169,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.912486,
                "lon": -122.012242
            },
            "entity": {
                "abbreviation": "Eagle Peak Montessori",
                "alternates": {
                    "nces": "062637008688"
                },
                "character": "Public, Magnet, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "c255946e-ac81-471c-b16f-252c18e99b15",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Eagle Peak Montessori",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Eagle Peak Montessori",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "1-8"
                ],
                "type": "School",
                "url": "eagle-peak-montessori-walnut-creek-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 286
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.011,37.9117,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.011,37.9117,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "dd1bef72-5e01-4310-b587-77e732511f7a",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 170,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.565613,
                "lon": -122.296983
            },
            "entity": {
                "abbreviation": "Bayside STEM Academy",
                "alternates": {
                    "nces": "063492014143"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "dd1bef72-5e01-4310-b587-77e732511f7a",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Bayside Academy",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bayside STEM Academy",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bayside-academy-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 924
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Collaboration with Harvard University to design the new STEM program has built a solid foundation and greatly excelled student performance. Bayside STEM also won the distinguished California School Board Association (CSBA) Golden Bell Award for 2014, which recognizes exemplary programs that support teaching and learning. A $200,000 grant from Gilead Sciences is going towards building a STEM-based science exploration area. Keep up the great work, and keep those awards/recognitions coming!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-10T18:05:58.915125Z",
                "guid": "21ac5c8b-c470-4998-bd2b-da4983e43e13",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.296,37.5663,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.296,37.5663,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "620d65c2-0c54-491d-8c7f-0bb81717a3a1",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 171,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.923442,
                "lon": -122.038629
            },
            "entity": {
                "abbreviation": "Pleasant Hill Middle School",
                "alternates": {
                    "nces": "062637007076"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "620d65c2-0c54-491d-8c7f-0bb81717a3a1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Pleasant Hill Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pleasant Hill Middle School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "pleasant-hill-middle-school-pleasant-hill-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 847
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "PHMS is an amazing school! With conflict solving to helping with extra support in class work. Everyone has been very helpful in making sure my son is safe, and thriving in school. They have an awesome conflict gentleman named Dan that solves conflict issues. The staff from A-Z is a great team. PHMS offers free after school student tutors-tue/thur. I am impressed! \n\nRW\npleasant hill",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-11-04T02:00:33.354213Z",
                "guid": "d6649b6f-21a6-4a88-a10e-34f692fbe789",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.9373,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.9373,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94503"
            }
        ]
    },
    {
        "guid": "1d161fff-75ea-4c63-860d-09d3e4d7376f",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 172,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.966548,
                "lon": -122.066459
            },
            "entity": {
                "abbreviation": "Valley View Middle School",
                "alternates": {
                    "nces": "062637003979"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1d161fff-75ea-4c63-860d-09d3e4d7376f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Valley View Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Valley View Middle School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "valley-view-middle-school-pleasant-hill-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 815
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.9648,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.9648,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "22018693-f206-46a8-a6f4-1787ca017fbf",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 173,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.39429,
                "lon": -121.84437
            },
            "entity": {
                "abbreviation": "Piedmont Middle School",
                "alternates": {
                    "nces": "060480000464"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "22018693-f206-46a8-a6f4-1787ca017fbf",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Piedmont Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Piedmont Middle School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "piedmont-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 819
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "It had super great lunch food, and a lot of the teachers cared about the students. There were also tons of awesome programs available for students to participate in if they really wanted to, and these programs were often enriching for a young student.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-03-03T02:21:35.616682Z",
                "guid": "13ff736c-9f5c-46a9-990a-4cbba8749c0d",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.3927,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.3927,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.3333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "284977e6-a0d7-4090-a95d-1b4e5f17344b",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 174,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.779598,
                "lon": -122.28794
            },
            "entity": {
                "abbreviation": "Nea",
                "alternates": {
                    "ceeb": "054529",
                    "nces": "060188712399"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "284977e6-a0d7-4090-a95d-1b4e5f17344b",
                "isClaimed": true,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Nea Community Learning Center",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "premium": false,
                "shortName": "Nea",
                "tagline": [
                    "Public School",
                    "Alameda, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "nea-community-learning-center-alameda-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 579
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Nea Community Learning Center, is a good school. I love this school and it is diverse. It does not matter where you come from, the school's main goal is to ensure that you feel welcome. I have been in this school for five years and I have meet new friends and teachers. There are no hate crimes that happen in the school. Although it is not a big school as everyone's dream high school is, I can guarantee you that it is the best school you could dream off. There are no AP classes or sports but the school gives you an opportunity to branch out to colleges to complete the requirements.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-23T23:35:17.527134Z",
                "guid": "8a173f1d-ac7e-4f50-9194-7061df9b2c94",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.287,37.7792,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.287,37.7792,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.75,
                "count": 40
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "545c70e2-5019-40ab-a0e8-c6fd0e1ff3ed",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 175,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.07569,
                "lon": -122.568177
            },
            "entity": {
                "abbreviation": "San Jose Intermediate",
                "alternates": {
                    "nces": "062772012874"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "545c70e2-5019-40ab-a0e8-c6fd0e1ff3ed",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "San Jose Intermediate School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "San Jose Intermediate",
                "tagline": [
                    "Novato Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "san-jose-intermediate-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 672
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.571,38.0766,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.571,38.0766,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94949"
            }
        ]
    },
    {
        "guid": "83b3d459-b7ae-4333-9f32-acdc094dda70",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 176,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.941243,
                "lon": -121.952175
            },
            "entity": {
                "abbreviation": "Pine Hollow Middle School",
                "alternates": {
                    "nces": "062637003965"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "83b3d459-b7ae-4333-9f32-acdc094dda70",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Pine Hollow Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "64ec8361-307f-4c60-8518-5e08e7cc3cb0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pine Hollow Middle School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "pine-hollow-middle-school-concord-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 569
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Pine Hollow Middle School did an outstanding job welcoming my 6th grader to middle school. The teachers put on special 6th grade days in Fall and Spring, as well as taking the students to Alcatraz for a field trip while reading a book set on Alcatraz. My student not only learned the curriculum, he also learned organization and personal responsibility for his education.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-05-25T23:36:24.243517Z",
                "guid": "aa6a8ad4-0d01-43b1-b134-e1a46aa49352",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.955,37.94,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.955,37.94,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94521"
            }
        ]
    },
    {
        "guid": "6907253f-9fbf-4b65-ba6b-fd2da783806e",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 177,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.879676,
                "lon": -121.641491
            },
            "entity": {
                "abbreviation": "Excelsior Middle School",
                "alternates": {
                    "nces": "060675000595"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6907253f-9fbf-4b65-ba6b-fd2da783806e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Byron, CA",
                "name": "Excelsior Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "77c39175-7d61-4abc-ba26-79ea992e5079",
                    "schoolDistrict": "77c39175-7d61-4abc-ba26-79ea992e5079",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3f2c06a3-6dc5-4b8b-ae42-25d409d4ae34",
                    "zipCode": "4c4ae928-08ae-4b39-a92f-d9350bc71e2f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Byron Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "77c39175-7d61-4abc-ba26-79ea992e5079",
                        "location": "Byron, CA",
                        "name": "Byron Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3f2c06a3-6dc5-4b8b-ae42-25d409d4ae34",
                            "zipCode": "4c4ae928-08ae-4b39-a92f-d9350bc71e2f"
                        },
                        "premium": false,
                        "shortName": "Byron Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Byron, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "byron-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Excelsior Middle School",
                "tagline": [
                    "Byron Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "excelsior-middle-school-byron-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 569
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.641,37.8777,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.641,37.8777,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94514"
            }
        ]
    },
    {
        "guid": "5da3b1e9-f4f0-48fc-b242-94c16cdf5523",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 178,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.929293,
                "lon": -122.358993
            },
            "entity": {
                "abbreviation": "Richmond College Preparatory",
                "alternates": {
                    "nces": "060172211576"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5da3b1e9-f4f0-48fc-b242-94c16cdf5523",
                "isClaimed": false,
                "isPremium": false,
                "location": "Richmond, CA",
                "name": "Richmond College Preparatory",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                    "zipCode": "ce8bebad-32ac-45ac-bd87-076119b2c0fa"
                },
                "premium": false,
                "shortName": "Richmond College Preparatory",
                "tagline": [
                    "Public School",
                    "Richmond, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "richmond-college-preparatory-richmond-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 542
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.359,37.9292,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.359,37.9292,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94804"
            }
        ]
    },
    {
        "guid": "464b00e8-aa1e-464b-b472-861384c46ccb",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 179,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.464128,
                "lon": -122.434262
            },
            "entity": {
                "abbreviation": "Manuel F. Cunha Intermediate",
                "alternates": {
                    "nces": "060678000601"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "464b00e8-aa1e-464b-b472-861384c46ccb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Half Moon Bay, CA",
                "name": "Manuel F. Cunha Intermediate School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "schoolDistrict": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                    "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cabrillo Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                        "location": "Half Moon Bay, CA",
                        "name": "Cabrillo Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                            "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                        },
                        "premium": false,
                        "shortName": "Cabrillo Unified School District",
                        "tagline": [
                            "School District",
                            "Half Moon Bay, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cabrillo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Manuel F. Cunha Intermediate",
                "tagline": [
                    "Cabrillo Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "manuel-f-cunha-intermediate-school-half-moon-bay-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 765
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.435,37.4642,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.435,37.4642,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94019"
            }
        ]
    },
    {
        "guid": "e664c7cc-2a1d-4b76-88fc-3b747955adab",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 180,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.782998,
                "lon": -122.437611
            },
            "entity": {
                "abbreviation": "KIPP San Francisco Bay Academy",
                "alternates": {
                    "nces": "060162611255"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "e664c7cc-2a1d-4b76-88fc-3b747955adab",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "KIPP San Francisco Bay Academy",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9ae6d1c0-ee34-4266-84ab-3a6632674608",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "9fbdd391-c59a-4ee3-a774-a928ff2f0905"
                },
                "premium": false,
                "shortName": "KIPP San Francisco Bay Academy",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "kipp-san-francisco-bay-academy-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 369
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "KIPP took my kids to the next level. Unlike Creative Arts, they actually take minority kids that live across the street and help them excel.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-09-26T20:39:43.893284Z",
                "guid": "ae8b52db-1b36-4b59-9d78-506b8efc599d",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.438,37.7831,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.438,37.7831,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94115"
            }
        ]
    },
    {
        "guid": "65716c45-0fdd-4b9c-9ed7-d0e054f63ea4",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 181,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.247532,
                "lon": -121.83682
            },
            "entity": {
                "abbreviation": "Frost (Earl) Elementary School",
                "alternates": {
                    "nces": "062781004213"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "65716c45-0fdd-4b9c-9ed7-d0e054f63ea4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Earl Frost Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Frost (Earl) Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "earl-frost-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 638
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.836,37.2465,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.836,37.2465,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "d27c098d-b7e3-4242-bd9f-59d08a32cce5",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 182,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.312122,
                "lon": -121.899298
            },
            "entity": {
                "abbreviation": "River Glen",
                "alternates": {
                    "nces": "063459004084"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "d27c098d-b7e3-4242-bd9f-59d08a32cce5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "River Glen",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "River Glen",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "river-glen-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 520
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I graduated River Glen in 2018 and had gone there for all 9 years, K-8. I loved my time at River Glen. It may have been a little different then many other schools around the areas, however that's what I loved about it. We were a bilingual school that taught kids English and Spanish in an area were that was a bit unusual. At the time I went there, I did not appreciate the gift that it had given me. If I had not gone to this school I would not be able to speak two languages fluently. I owe my greatest skill to this small, strange, amazing, quirky school. I do admit it has had its problems, but what school doesn't. This may not be the school for everyone, it may be just another public school but to me and all my classmates I had, I can say that I would never have chosen to spend my time of education in any other place. Additionally, the community is incredible. My family and I have made life long friends that have become family. River Glen was my home and I will cherish it forever.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-06-03T16:23:45.105821Z",
                "guid": "e36294c8-0b37-46fd-9e9a-3bf7c70445f9",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.902,37.3107,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.902,37.3107,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "8aa7ee8c-7bc7-4832-b05e-e2d482c19108",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 183,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.660522,
                "lon": -122.450661
            },
            "entity": {
                "abbreviation": "Alta Loma Middle School",
                "alternates": {
                    "nces": "063753006339"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8aa7ee8c-7bc7-4832-b05e-e2d482c19108",
                "isClaimed": false,
                "isPremium": false,
                "location": "South San Francisco, CA",
                "name": "Alta Loma Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                    "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alta Loma Middle School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "alta-loma-middle-school-south-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 700
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The curriculum is very up to date and the teachers do an exceptional job of keeping their students educated. The staff could be more understanding with certain situations.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-03-16T00:01:47.48533Z",
                "guid": "64842f46-5edd-4dd2-b288-2696b8c822b4",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.45,37.662,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.45,37.662,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94080"
            }
        ]
    },
    {
        "guid": "66a9bae7-f4ee-4820-a313-7fc48dbe4ce0",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 184,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.529598,
                "lon": -122.308651
            },
            "entity": {
                "abbreviation": "Abbott Middle School",
                "alternates": {
                    "nces": "063492005896"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "66a9bae7-f4ee-4820-a313-7fc48dbe4ce0",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Abbott Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Abbott Middle School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "abbott-middle-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 813
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "When I went to Abbott Middle School it was a pretty good middle school experience. I was surrounded by amazing, smart students, as well as great teachers! All the teachers were great support and they made sure we were all always safe. All the admin took things very seriously and made sure we were all striving to do great things. Bullying wasn't really seen but if it was it was taken care of right away with the right consequences. Now, though, the school has seemed to change a little bit. Both the students and staff has changed a lot and I honestly don't know how good of a school they are anymore. If I were to change anything it would be making sure that the staff and administration is taking good cares of the kids making sure they aren't getting into any trouble as well as making sure they do their best.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T16:22:48.34446Z",
                "guid": "282fac3c-72f5-45fb-a37a-d3521ab16dac",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.309,37.5302,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.309,37.5302,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "00a9c71c-0b4a-49d2-b59d-41e7be3c6b58",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 185,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.616431,
                "lon": -122.41994
            },
            "entity": {
                "abbreviation": "Parkside Intermediate",
                "alternates": {
                    "nces": "063423005398"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "00a9c71c-0b4a-49d2-b59d-41e7be3c6b58",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Bruno, CA",
                "name": "Parkside Intermediate School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "schoolDistrict": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                    "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Bruno Park Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "af247174-605a-4c45-96da-8e4b1aad03f4",
                        "location": "San Bruno, CA",
                        "name": "San Bruno Park Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                            "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                        },
                        "premium": false,
                        "shortName": "San Bruno Park Elementary School District",
                        "tagline": [
                            "School District",
                            "San Bruno, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-bruno-park-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Parkside Intermediate",
                "tagline": [
                    "San Bruno Park Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "parkside-intermediate-school-san-bruno-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 789
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.6158,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.6158,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 1,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94066"
            }
        ]
    },
    {
        "guid": "3b9fc2a4-8a91-4df2-9310-0df1a1244739",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 186,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.687969,
                "lon": -122.069646
            },
            "entity": {
                "abbreviation": "Golden Oak Montessori of Hayward",
                "alternates": {
                    "nces": "060165512426"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "3b9fc2a4-8a91-4df2-9310-0df1a1244739",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Golden Oak Montessori of Hayward",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "premium": false,
                "shortName": "Golden Oak Montessori of Hayward",
                "tagline": [
                    "Public School",
                    "Castro Valley, CA",
                    "1-8"
                ],
                "type": "School",
                "url": "golden-oak-montessori-of-hayward-castro-valley-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 249
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 249
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I graduated from here in 2017. The community and staff are great, but since it's Montessori, only the students whom have an interest in doing well, benefit from this kind of teaching. Also, since it is a charter school, we did not have many extracurricular or other non-academic programs.\nThe middle school is 7th and 8th grade. In the small community, I was able to make connections and build strong bonds with my peers and teachers. The middle school is very good at preparing their students for high school. So well, actually, that the students generally have less of a struggle in high school.  Unfortunately, the work load is a lot, which causes a decent amount of stress among the students, particularly when concerning group work. There is a group assignment every week, which gets stressful. Fortunately the teachers and staff are willing to aid the students in developing healthy stress relief habits and developing a strong work ethic.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-15T20:05:39.522233Z",
                "guid": "1866a9ca-43e7-41ba-8b31-554a049ddbba",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.07,37.6878,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.07,37.6878,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "2f3d0c5c-f262-49d4-a968-150a3118d604",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 187,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.337401,
                "lon": -121.84058
            },
            "entity": {
                "abbreviation": "KIPP Prize Preparatory Academy",
                "alternates": {
                    "nces": "060224013778"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "2f3d0c5c-f262-49d4-a968-150a3118d604",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "KIPP Prize Preparatory Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "premium": false,
                "shortName": "KIPP Prize Preparatory Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "kipp-prize-preparatory-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 416
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 31
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.841,37.338,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.841,37.338,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "42e898c6-8884-4b11-a430-7c2e3f408c32",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 188,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.284462,
                "lon": -121.819654
            },
            "entity": {
                "abbreviation": "Voices College-Bound Language Academy",
                "alternates": {
                    "nces": "060199312031"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "42e898c6-8884-4b11-a430-7c2e3f408c32",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Voices College-Bound Language Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                    "schoolNetwork": "d277ce97-405c-42a8-ae1e-e8069eea6a1c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                },
                "premium": false,
                "shortName": "Voices College-Bound Language Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "voices-college-bound-language-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 481
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.82,37.2845,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.82,37.2845,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95111"
            }
        ]
    },
    {
        "guid": "b3f59705-150f-403d-8af3-a6b5129f1350",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 189,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.935671,
                "lon": -121.620505
            },
            "entity": {
                "abbreviation": "Old River Elementary School",
                "alternates": {
                    "nces": "062004014006"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b3f59705-150f-403d-8af3-a6b5129f1350",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Old River Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                    "schoolDistrict": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Knightsen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                        "location": "Knightsen, CA",
                        "name": "Knightsen Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "dd92bf0e-5067-4c84-a7d4-9d4d873c429b",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Knightsen Elementary School District",
                        "tagline": [
                            "School District",
                            "Knightsen, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "knightsen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Old River Elementary School",
                "tagline": [
                    "Knightsen Elementary School District, CA",
                    "K-7"
                ],
                "type": "School",
                "url": "old-river-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 268
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.620505,37.935671,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.620505,37.935671,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "0957e184-d713-4eab-bfa3-10e8b1b97074",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 190,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.686943,
                "lon": -121.767125
            },
            "entity": {
                "abbreviation": "Junction K-8",
                "alternates": {
                    "nces": "062211002622"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0957e184-d713-4eab-bfa3-10e8b1b97074",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Junction K-8",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Junction K-8",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "junction-k-8-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 934
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.765,37.6881,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.765,37.6881,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "335a3d77-9230-4f67-9eab-bf91bfbdaabd",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 191,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.926507,
                "lon": -122.024646
            },
            "entity": {
                "abbreviation": "Contra Costa School of Performing Arts",
                "alternates": {
                    "nces": "060233614059"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "335a3d77-9230-4f67-9eab-bf91bfbdaabd",
                "isClaimed": true,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Contra Costa School of Performing Arts",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "premium": false,
                "shortName": "Contra Costa School of Performing Arts",
                "tagline": [
                    "Public School",
                    "Walnut Creek, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "contra-costa-school-of-performing-arts-walnut-creek-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 471
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "I have been at this school since the beginning, and the growth of it has been immense. I am endlessly thankful for the independence I learned at this school to complete my own work, and the in depth art conservatory training I have gained, and would not have been able to gain anywhere else.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-26T21:39:00.060377Z",
                "guid": "cb403b24-e944-4894-b5bb-54122e8fe31c",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "5743fc19-3019-4d06-a972-ca6e5698bcae",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 192,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.478913,
                "lon": -122.206062
            },
            "entity": {
                "abbreviation": "KECP",
                "alternates": {
                    "nces": "060203013874"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5743fc19-3019-4d06-a972-ca6e5698bcae",
                "isClaimed": true,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "KIPP Excelencia Community Preparatory",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "844b235c-05df-4456-8ac0-5f90a4c285b8",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "eb3f69c3-e6db-4239-8979-66e40ebafe12",
                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                },
                "premium": false,
                "shortName": "KIPP Excelencia",
                "tagline": [
                    "Public School",
                    "Redwood City, CA",
                    "PK, K-8"
                ],
                "type": "School",
                "url": "kipp-excelencia-community-preparatory-redwood-city-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 711
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.206062,37.478913,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.206062,37.478913,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94063"
            }
        ]
    },
    {
        "guid": "95ff96b8-8a1c-4c4f-b105-af7d5a99ec52",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 193,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.315614,
                "lon": -121.853473
            },
            "entity": {
                "abbreviation": "George Shirakawa Sr. Elementary School",
                "alternates": {
                    "nces": "061437008352"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "95ff96b8-8a1c-4c4f-b105-af7d5a99ec52",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "George Shirakawa Sr. Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "George Shirakawa Sr. Elementary School",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "george-shirakawa-sr-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 759
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "It is fine. Not much better than other schools, but still decent enough for children to be enrolled in.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-06-06T02:54:22.074114Z",
                "guid": "4777946b-3ca0-4d6a-a8f9-b54e833f5973",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.849,37.3151,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.849,37.3151,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.3333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95112"
            }
        ]
    },
    {
        "guid": "3a2f472d-6861-430c-87fb-7e39d539c29b",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 194,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.721785,
                "lon": -122.442703
            },
            "entity": {
                "abbreviation": "James Denman Middle",
                "alternates": {
                    "nces": "063441005628"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3a2f472d-6861-430c-87fb-7e39d539c29b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "James Denman Middle",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "95c0370c-58af-428a-98dc-478e22f3237b",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "61413703-2cce-42b9-a718-b28b79a1680f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "James Denman Middle",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "james-denman-middle-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 835
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.443,37.7216,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.443,37.7216,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94112"
            }
        ]
    },
    {
        "guid": "f0122f67-0d46-4742-a0c9-9af7e2f7c2d7",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 195,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.1117869,
                "lon": -122.2446967
            },
            "entity": {
                "abbreviation": "Caliber: Changemakers Academy",
                "alternates": {
                    "nces": "060158214030"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "f0122f67-0d46-4742-a0c9-9af7e2f7c2d7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Vallejo, CA",
                "name": "Caliber: Changemakers Academy",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6"
                },
                "premium": false,
                "shortName": "Caliber: Changemakers Academy",
                "tagline": [
                    "Public School",
                    "Vallejo, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "caliber--changemakers-academy-vallejo-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 708
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94590"
            }
        ]
    },
    {
        "guid": "f66414aa-8666-4042-b201-81eeec6721cb",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 196,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.916509,
                "lon": -122.692127
            },
            "entity": {
                "abbreviation": "Bolinas-Stinson Elementary School",
                "alternates": {
                    "nces": "060552000501"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f66414aa-8666-4042-b201-81eeec6721cb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Bolinas, CA",
                "name": "Bolinas-Stinson Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "c8181d71-636f-4aaa-9164-d53eedaf19b9",
                    "zipCode": "28b5376b-b6a0-4e79-9c18-f2513fd69f3e"
                },
                "premium": false,
                "shortName": "Bolinas-Stinson Elementary School",
                "tagline": [
                    "Public School",
                    "Bolinas, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bolinas-stinson-elementary-school-bolinas-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 90
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.693,37.9173,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.693,37.9173,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94924"
            }
        ]
    },
    {
        "guid": "4b9ff5ab-7055-41d1-b4c0-3cc13f5a2f8d",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 197,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.763576,
                "lon": -122.428575
            },
            "entity": {
                "abbreviation": "Everett Middle School",
                "alternates": {
                    "nces": "063441007842"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4b9ff5ab-7055-41d1-b4c0-3cc13f5a2f8d",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Everett Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "534e0d5b-5645-4a30-91fb-0228f30fbf0a",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a3ca5eac-7152-427f-be6e-9e3727c508ed"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Everett Middle School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "everett-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 694
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.429,37.7637,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.429,37.7637,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94114"
            }
        ]
    },
    {
        "guid": "03ad624d-e368-45cd-a934-1475a0341b71",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 198,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.631327,
                "lon": -122.102475
            },
            "entity": {
                "abbreviation": "Impact Academy of Arts & Technology",
                "alternates": {
                    "ceeb": "054255",
                    "nces": "060164711849"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "03ad624d-e368-45cd-a934-1475a0341b71",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hayward, CA",
                "name": "Impact Academy of Arts & Technology",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "723769d9-71d5-4272-8024-340ffcff7fcc",
                    "schoolNetwork": "f55e8d73-7e39-4bee-8c3f-7e814462499a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                    "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                },
                "premium": false,
                "shortName": "Impact Academy of Arts & Technology",
                "tagline": [
                    "Public School",
                    "Hayward, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "impact-academy-of-arts--and--technology-hayward-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 842
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Impact Academy is very community-centric! They really prepare their students for college-level critical thinking and problem-solving. However, their science and math classes often suffer because of their issues with teacher retention. Their teachers and faculty support the students and ensure every student feels recognized and uplifted.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-19T09:32:52.880309Z",
                "guid": "beb830c9-cb86-4aee-8f39-4058a4b8765a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.102,37.6313,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.102,37.6313,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5317073170731708,
                "count": 205
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94545"
            }
        ]
    },
    {
        "guid": "a57760de-c7e2-46bc-9eb0-c2e017d16984",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 199,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.308986,
                "lon": -121.943557
            },
            "entity": {
                "abbreviation": "Monroe Middle School",
                "alternates": {
                    "nces": "060720000672"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "a57760de-c7e2-46bc-9eb0-c2e017d16984",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Monroe Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "bf537caa-7876-4936-b0a8-98add2dd3610"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Monroe Middle School",
                "tagline": [
                    "Campbell Union School District, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "monroe-middle-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1118
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.3079,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.3079,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95128"
            }
        ]
    },
    {
        "guid": "623c6256-0121-4540-bfe4-f4d03558e3b9",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 200,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.350826,
                "lon": -121.844118
            },
            "entity": {
                "abbreviation": "Renaissance at Mathson",
                "alternates": {
                    "nces": "060231013126"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "623c6256-0121-4540-bfe4-f4d03558e3b9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Renaissance at Mathson",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "parent": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "schoolDistrict": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "953f02c6-35fb-44cc-a96d-584c48aceaab"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Alum Rock Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7be9062e-b348-4a13-93fa-470342dbb551",
                        "location": "San Jose, CA",
                        "name": "Alum Rock Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                        },
                        "premium": false,
                        "shortName": "Alum Rock Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alum-rock-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Renaissance at Mathson",
                "tagline": [
                    "Alum Rock Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "renaissance-at-mathson-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 278
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "My experience at Renaissance has been amazing. I recently graduated as salutatorian, and I am immensely glad that I chose to attend this school. \n\nThe small size allows for one-on-one instruction, and even though there are no honors or advanced classes--everyone takes the same Math, Art, Science, ELA, and Social Studies classes--the material taught is generally a grade ahead. I'm able to skip Math 1 in high school and move to Math 2 in high school due to this.\n\nThe teachers are loving, caring, and genuinely invested in their students; shoutout to all of them. It's a great place, especially for the ambitious pupil, but everyone can thrive here.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-05T05:50:55.511059Z",
                "guid": "e0aafeef-36df-4125-9d9a-4078a73aafde",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.841,37.3505,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.841,37.3505,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95116"
            }
        ]
    },
    {
        "guid": "33aaee67-b68b-4389-8816-175445f2cbce",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 201,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.266343,
                "lon": -121.811867
            },
            "entity": {
                "abbreviation": "Davis (Caroline) Intermediate",
                "alternates": {
                    "nces": "062781004209"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "33aaee67-b68b-4389-8816-175445f2cbce",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Caroline Davis Intermediate School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Davis (Caroline) Intermediate",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "caroline-davis-intermediate-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 596
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "High School Freshman",
                "body": "Curriculum was not very challenging for me because I consider myself a reasonably successful student. Band class was my favorite class because mostly everyone there shared a similar passion with me. I could be myself and truly immerse myself in that class. My history teacher was a chill, lively, open, quirky, humorous, and awesome guy who could entertain us and teach us at the same time.",
                "categories": [
                    "Academics"
                ],
                "created": "2014-07-17T20:37:51Z",
                "guid": "8f41f7c7-28d3-47cb-8611-4a8705808ddc",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.813,37.2677,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.813,37.2677,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5833333333333335,
                "count": 12
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95111"
            }
        ]
    },
    {
        "guid": "559e1097-e6b6-4157-9e25-f4bdc8536423",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 202,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.968592,
                "lon": -122.325832
            },
            "entity": {
                "abbreviation": "Vista High (Alternative)",
                "alternates": {
                    "ceeb": "052632",
                    "nces": "063255010198"
                },
                "character": "Public, Alternative",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "559e1097-e6b6-4157-9e25-f4bdc8536423",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Pablo, CA",
                "name": "Vista High (Alternative)",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                    "zipCode": "a08c6674-b67b-4d2b-976e-ac88b0619d80"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "WCCUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                        "location": "Richmond, CA",
                        "name": "West Contra Costa Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                        },
                        "premium": false,
                        "shortName": "West Contra Costa Unified School District",
                        "tagline": [
                            "School District",
                            "Richmond, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "west-contra-costa-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Vista High (Alternative)",
                "tagline": [
                    "West Contra Costa Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "vista-high-alternative-san-pablo-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 253
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 14
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "My son starting attending in his sophomore year because he was having health issues and was missing too much school and falling behind at his regular high school.  Vista's one on one program was great for him.  He thrived at Vista and was even able to do concurrent classes at the local community college.  He enjoyed his supervising teacher who was the same for all three years.  He graduated ahead of schedule and was just accepted to UC Berkeley!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-22T03:21:15.105996Z",
                "guid": "036baa71-5b61-41fb-9ec6-822742576312",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.325,37.9686,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.325,37.9686,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.285714285714286,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94806"
            }
        ]
    },
    {
        "guid": "555ba691-64f8-4029-b9c6-70797e9b0743",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 203,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.326436,
                "lon": -121.834562
            },
            "entity": {
                "abbreviation": "Alpha: Blanca Alvarado Middle School",
                "alternates": {
                    "nces": "060222112989"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "555ba691-64f8-4029-b9c6-70797e9b0743",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Alpha: Blanca Alvarado Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "schoolNetwork": "32061b3b-37e5-4624-a58b-532086d3e22f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "premium": false,
                "shortName": "Alpha: Blanca Alvarado Middle School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "alpha--blanca-alvarado-middle-school-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 290
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.834,37.3265,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.834,37.3265,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "26d0d0f5-c4bc-4988-a576-ed56e9adeeed",
        "badge": {
            "display": "Best Public Middle Schools in San Francisco Bay Area",
            "ordinal": 204,
            "total": 369,
            "vanityURL": "best-public-middle-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.694057,
                "lon": -122.482138
            },
            "entity": {
                "abbreviation": "Benjamin Franklin Intermediate",
                "alternates": {
                    "nces": "061887002278"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "26d0d0f5-c4bc-4988-a576-ed56e9adeeed",
                "isClaimed": false,
                "isPremium": false,
                "location": "Colma, CA",
                "name": "Benjamin Franklin Intermediate School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "schoolDistrict": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2d77c6a7-b145-49fe-8056-7be8e38c9241",
                    "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Jefferson Elementary School District (Daly City)",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                        "location": "Daly City, CA",
                        "name": "Jefferson Elementary School District (Daly City)",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "41a99124-6b7c-496d-9635-39a33c683883",
                            "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                        },
                        "premium": false,
                        "shortName": "Jefferson Elementary School District (Daly City)",
                        "tagline": [
                            "School District",
                            "Daly City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "jefferson-elementary-school-district-daly-city-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Benjamin Franklin Intermediate",
                "tagline": [
                    "Jefferson Elementary School District (Daly City), CA",
                    "6-8"
                ],
                "type": "School",
                "url": "benjamin-franklin-intermediate-school-colma-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 691
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "The teachers really care about your academic success, but emotional support and counseling could be improved (as it was hard being a hormonal middle schooler dealing with bullying and drama). Otherwise, this was an excellent school with many extracurricular options and academically supportive staff!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-07-21T01:51:45.871869Z",
                "guid": "0c318cfc-3190-44fe-844e-76a5bf6ec0dd",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.6954,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.6954,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.333333333333333,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94015"
            }
        ]
    },
    {
        "guid": "4cfd308b-24e2-4048-9019-b101553073b7",
        "content": {
            "centroid": {
                "lat": 35.613231,
                "lon": -118.402218
            },
            "entity": {
                "abbreviation": "John F. Kennedy Middle School",
                "alternates": {
                    "nces": "063213004970"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4cfd308b-24e2-4048-9019-b101553073b7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "John F. Kennedy Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "7f5567b2-1554-4e0b-8639-c595fe7a7329"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "John F. Kennedy Middle School",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "john-f-kennedy-middle-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 667
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.239,37.4601,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.239,37.4601,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/93239"
            }
        ]
    },
    {
        "guid": "eeb355b8-8cc6-4ba1-99ee-75384aa2cc68",
        "content": {
            "centroid": {
                "lat": 37.803753,
                "lon": -122.229687
            },
            "entity": {
                "abbreviation": "Edna Brewer Middle School",
                "alternates": {
                    "nces": "062805004299"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "eeb355b8-8cc6-4ba1-99ee-75384aa2cc68",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Edna Brewer Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "151103a6-ab35-4483-a39b-6a34c254b9bd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Edna Brewer Middle School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "edna-brewer-middle-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 808
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Edna Brewer is one of the top middle schools in the OUSD district. This schools provides the best education. With supporting teachers and administration, most of the students graduate.  At the 8th grade, teachers devote themselves to push their students to be high school ready with tests and assignments. Which is shown in Brewer's overall test scores.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-10-01T02:46:59.78737Z",
                "guid": "e5e96384-e110-4cae-bd14-1b5499ef73da",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.229,37.8035,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.229,37.8035,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.25,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94610"
            }
        ]
    },
    {
        "guid": "d5193d06-6e59-4d84-b627-f0bb01e5a1d0",
        "content": {
            "centroid": {
                "lat": 37.778572,
                "lon": -122.19038
            },
            "entity": {
                "abbreviation": "Urban Montessori Charter School",
                "alternates": {
                    "nces": "060181613008"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "d5193d06-6e59-4d84-b627-f0bb01e5a1d0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Urban Montessori Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "b96fa3ea-8713-4581-93cb-036864769ed5"
                },
                "premium": false,
                "shortName": "Urban Montessori Charter School",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "urban-montessori-charter-school-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 432
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.19,37.7784,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.19,37.7784,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94619"
            }
        ]
    },
    {
        "guid": "1d077690-b12e-4c52-8464-b3b87076362a",
        "content": {
            "centroid": {
                "lat": 38.052091,
                "lon": -122.530544
            },
            "entity": {
                "abbreviation": "Hamilton Meadow Park",
                "alternates": {
                    "nces": "062772004185"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1d077690-b12e-4c52-8464-b3b87076362a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Hamilton Meadow Park",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hamilton Meadow Park",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "hamilton-meadow-park-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 589
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.529,38.0549,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.529,38.0549,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94949"
            }
        ]
    },
    {
        "guid": "fa0119f1-856a-4af0-9aab-b6027d594fee",
        "content": {
            "centroid": {
                "lat": 37.392341,
                "lon": -122.019138
            },
            "entity": {
                "abbreviation": "Columbia Middle School",
                "alternates": {
                    "nces": "063846006464"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "fa0119f1-856a-4af0-9aab-b6027d594fee",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Columbia Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "7a81297b-02c5-4674-a68b-e516dd895b65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Columbia Middle School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "columbia-middle-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 790
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.02,37.3934,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.02,37.3934,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94085"
            }
        ]
    },
    {
        "guid": "cea163bb-d8e4-4bef-b2b8-ea47a24885de",
        "content": {
            "centroid": {
                "lat": 37.314186,
                "lon": -121.8124
            },
            "entity": {
                "abbreviation": "George V. Leyva Intermediate",
                "alternates": {
                    "nces": "061314001493"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "cea163bb-d8e4-4bef-b2b8-ea47a24885de",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "George V. Leyva Intermediate School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "George V. Leyva Intermediate",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "george-v-leyva-intermediate-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 733
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Recent Alumnus",
                "body": "Bring a student myself, I found it difficult to fit in when shifting from middle school to high school, but my high school did their best to make the students feel comfortable and welcomed. Many clubs and organizations help guide the students through high school, so that they have a good expirence.  Clubs like key club and red Cross help students to actively engage in the community and become important leaders. Sports are very important to the studends as well as the community. Friends and family come out to the sporting events to support out school and raise money. Overall River Valley High School is great and I believe they try their best to benefit their students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-08-13T15:00:57Z",
                "guid": "f9130334-ba31-4784-a708-a7efa0d34bb9",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.812,37.3156,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.812,37.3156,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "65d60ef1-d65b-4ccd-b521-d442e53573bf",
        "content": {
            "centroid": {
                "lat": 37.727938,
                "lon": -122.404962
            },
            "entity": {
                "abbreviation": "Martin Luther King Jr. Academic Middle",
                "alternates": {
                    "nces": "063441005655"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "65d60ef1-d65b-4ccd-b521-d442e53573bf",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Martin Luther King Jr. Academic Middle",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f03b3dc2-87ff-47a3-a436-170bc3a6609a",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "67339067-3b07-488a-a8f9-c097bd4fcad2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Martin Luther King Jr. Academic Middle",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "martin-luther-king-jr-academic-middle-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 516
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 33
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.405,37.7277,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.405,37.7277,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94134"
            }
        ]
    },
    {
        "guid": "3eabf466-8a62-4946-b3cb-354178370318",
        "content": {
            "centroid": {
                "lat": 37.30623,
                "lon": -121.824242
            },
            "entity": {
                "abbreviation": "Windmill Springs Elementary School",
                "alternates": {
                    "nces": "061437008840"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3eabf466-8a62-4946-b3cb-354178370318",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Windmill Springs Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Windmill Springs Elementary School",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "windmill-springs-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 440
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.823,37.3069,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.823,37.3069,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "4734c4ae-137f-4b60-9bd3-6a0c4c103e28",
        "content": {
            "centroid": {
                "lat": 37.832791,
                "lon": -122.276242
            },
            "entity": {
                "abbreviation": "North Oakland Community Charter School",
                "alternates": {
                    "nces": "060174708442"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "4734c4ae-137f-4b60-9bd3-6a0c4c103e28",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "North Oakland Community Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "9b0e24bf-e5f4-4fab-becb-f9156b7b0d68"
                },
                "premium": false,
                "shortName": "North Oakland Community Charter School",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "north-oakland-community-charter-school-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 172
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.277,37.8332,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.277,37.8332,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94608"
            }
        ]
    },
    {
        "guid": "830717d7-eed3-463c-8d96-23c9f1c481df",
        "content": {
            "centroid": {
                "lat": 37.847115,
                "lon": -122.25211
            },
            "entity": {
                "abbreviation": "Claremont Middle School",
                "alternates": {
                    "nces": "062805004250"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "830717d7-eed3-463c-8d96-23c9f1c481df",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Claremont Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "f80b92b8-46fc-430c-9898-99e764ec21f3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Claremont Middle School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "claremont-middle-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 485
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "perfect! teachers really push you. even though i did get bullied the entire year...the teachers just wouldnt help. they frankly didnt care. it was really hard and to be honest they only listened bc its their job",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-23T04:52:02.332931Z",
                "guid": "6f26d7ac-e181-4a90-a650-b15890f555d0",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.8459,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.8459,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.4545454545454546,
                "count": 11
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94618"
            }
        ]
    },
    {
        "guid": "fc43f201-670d-4424-a0f1-6ed29f4d94f2",
        "content": {
            "centroid": {
                "lat": 37.965927,
                "lon": -122.526922
            },
            "entity": {
                "abbreviation": "James B. Davidson Middle School",
                "alternates": {
                    "nces": "063509005936"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "fc43f201-670d-4424-a0f1-6ed29f4d94f2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "James B. Davidson Middle School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b90df6a7-4def-44a4-a2e8-dca683052a4f",
                    "parent": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "schoolDistrict": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                    "zipCode": "218cd2f0-f326-4a8e-a957-02117d51d241"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Rafael City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                        "location": "San Rafael, CA",
                        "name": "San Rafael City Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "San Rafael City Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-rafael-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "James B. Davidson Middle School",
                "tagline": [
                    "San Rafael City Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "james-b-davidson-middle-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1204
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Davidson teachers and faculty are outstanding! I have a 7th grader at DMS and I have been impressed with the focus the teachers  place on helping every student achieve their potential - both academically and emotionally. This school gets an unnecessary bad wrap - it is a fantastic place for kids to grow, experience the real world and make lifelong connections.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-16T15:05:09.250149Z",
                "guid": "0dc22f7f-1193-4c68-b426-32a90862cefc",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.526,37.9662,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.526,37.9662,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94901"
            }
        ]
    },
    {
        "guid": "d96ab95e-c598-4f97-b97b-b8c1eda1b618",
        "content": {
            "centroid": {
                "lat": 38.052385,
                "lon": -122.220917
            },
            "entity": {
                "abbreviation": "Carquinez Middle School",
                "alternates": {
                    "nces": "061899002299"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d96ab95e-c598-4f97-b97b-b8c1eda1b618",
                "isClaimed": false,
                "isPremium": false,
                "location": "Crockett, CA",
                "name": "Carquinez Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "8dd379c5-017b-4b7f-8485-4543a3c9761d",
                    "schoolDistrict": "8dd379c5-017b-4b7f-8485-4543a3c9761d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f0624f6f-b23a-41f4-b431-0766074a6e92",
                    "zipCode": "e3c1c7a6-1f75-4afc-98bd-08e6b48fe731"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "John Swett Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8dd379c5-017b-4b7f-8485-4543a3c9761d",
                        "location": "Rodeo, CA",
                        "name": "John Swett Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "362b3980-b744-46dc-a22d-af772d04f66f",
                            "zipCode": "b246fcf7-0c1e-4866-8773-4baa7b3add25"
                        },
                        "premium": false,
                        "shortName": "John Swett Unified School District",
                        "tagline": [
                            "School District",
                            "Rodeo, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "john-swett-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Carquinez Middle School",
                "tagline": [
                    "John Swett Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "carquinez-middle-school-crockett-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 294
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.222,38.051,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.222,38.051,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94525"
            }
        ]
    },
    {
        "guid": "bbd77c11-c973-421f-9324-fea98282f2d1",
        "content": {
            "centroid": {
                "lat": 37.76856,
                "lon": -122.154338
            },
            "entity": {
                "abbreviation": "BayTech",
                "alternates": {
                    "ceeb": "054301",
                    "nces": "060182710720"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "bbd77c11-c973-421f-9324-fea98282f2d1",
                "isClaimed": true,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Bay Area Technology School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "3816271d-bf9c-431c-9e92-8f63ddf53940"
                },
                "premium": false,
                "shortName": "BayTech School",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "bay-area-technology-school-oakland-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 299
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I attended Baytech from year 2014-2017. In those few years I had soo many opportunities presented to me! I felt safe and that my knowledge was actually being used around the school! I was a student athlete and created relationships with the entire school! Then comes a day where I felt I wa seeing surrounded by family not just students and teachers.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-17T18:49:04.103933Z",
                "guid": "176b7c2b-d620-432f-8be4-5b802e29c102",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.154,37.7662,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.154,37.7662,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6875,
                "count": 16
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour",
                    "value": "https://youtu.be/BogOrJye7Hw"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94605"
            },
            {
                "label": "Virtual Tour",
                "type": "virtualTour",
                "url": "https://youtu.be/BogOrJye7Hw"
            }
        ]
    },
    {
        "guid": "4ba5cb5e-1966-40ba-ba9b-1b4d958437ee",
        "content": {
            "centroid": {
                "lat": 37.336686,
                "lon": -121.830795
            },
            "entity": {
                "abbreviation": "Renaissance Academy",
                "alternates": {
                    "nces": "060231011446"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4ba5cb5e-1966-40ba-ba9b-1b4d958437ee",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Renaissance Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "schoolDistrict": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Alum Rock Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7be9062e-b348-4a13-93fa-470342dbb551",
                        "location": "San Jose, CA",
                        "name": "Alum Rock Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                        },
                        "premium": false,
                        "shortName": "Alum Rock Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alum-rock-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Renaissance Academy",
                "tagline": [
                    "Alum Rock Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "renaissance-academy-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 308
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Some teachers there actually make an effort to making learning education fun, while others just hand you a piece of paper and tell us to work silently. The food there is awful 90% of the time, there are some days where the food is good. With this place, it's just on and off. Somethings it's good, sometimes it's bad. ",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-10-06T03:33:52.546519Z",
                "guid": "286189f4-7365-41f4-a758-e516f3769d85",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.83,37.3363,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.83,37.3363,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "d7655a8c-78f7-4efc-a1ed-5112de380bc4",
        "content": {
            "centroid": {
                "lat": 37.4682466,
                "lon": -122.1278977
            },
            "entity": {
                "abbreviation": "Aspire East Palo Alto Charter School",
                "alternates": {
                    "ceeb": "054257",
                    "nces": "060160414051"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "d7655a8c-78f7-4efc-a1ed-5112de380bc4",
                "isClaimed": false,
                "isPremium": false,
                "location": "East Palo Alto, CA",
                "name": "Aspire East Palo Alto Charter School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "schoolNetwork": "6d55fb3e-0771-4fa6-bff7-2753f0a42065",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "bc4a42c3-a2f5-452a-9b9e-175d4f05cb82",
                    "zipCode": "e91bfc0c-bca1-45d4-909a-25b104b8c881"
                },
                "premium": false,
                "shortName": "Aspire East Palo Alto Charter School",
                "tagline": [
                    "Public School",
                    "East Palo Alto, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "aspire-east-palo-alto-charter-school-east-palo-alto-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 648
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.1278977,37.4682466,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.1278977,37.4682466,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.8,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94303"
            }
        ]
    },
    {
        "guid": "6c0f7333-85fd-4cbb-9f2e-91b0ada0fd71",
        "content": {
            "centroid": {
                "lat": 37.690547,
                "lon": -122.459803
            },
            "entity": {
                "abbreviation": "Thomas R. Pollicita Middle School",
                "alternates": {
                    "nces": "061887002281"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6c0f7333-85fd-4cbb-9f2e-91b0ada0fd71",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Thomas R. Pollicita Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "schoolDistrict": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "41a99124-6b7c-496d-9635-39a33c683883",
                    "zipCode": "73a39e4a-bcc0-4688-99ae-a5dc425c236e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Jefferson Elementary School District (Daly City)",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                        "location": "Daly City, CA",
                        "name": "Jefferson Elementary School District (Daly City)",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "41a99124-6b7c-496d-9635-39a33c683883",
                            "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                        },
                        "premium": false,
                        "shortName": "Jefferson Elementary School District (Daly City)",
                        "tagline": [
                            "School District",
                            "Daly City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "jefferson-elementary-school-district-daly-city-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Thomas R. Pollicita Middle School",
                "tagline": [
                    "Jefferson Elementary School District (Daly City), CA",
                    "6-8"
                ],
                "type": "School",
                "url": "thomas-r-pollicita-middle-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 677
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I really loved my experience at Thomas R. Pollicita Middle School! The majority of my teachers always had something special about them that made learning and growing as a student and an individual much more memorable. Teachers were very supportive of me throughout the challenges that I have faced in middle school. The variety of electives is strong, especially the dance program. One thing that I would like to see change is how lunch periods are split up amongst students. This prevents kids from seeing other friends who may not have classes together.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-22T10:59:30.094202Z",
                "guid": "25ce3ae6-7e79-4fb8-b854-4667da4dbc3e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.459,37.6903,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.459,37.6903,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94014"
            }
        ]
    },
    {
        "guid": "b47a0a61-fe74-4931-9946-d26241c1358b",
        "content": {
            "centroid": {
                "lat": 37.593505,
                "lon": -122.047355
            },
            "entity": {
                "abbreviation": "Cesar Chavez Middle School",
                "alternates": {
                    "nces": "062691004073"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "b47a0a61-fe74-4931-9946-d26241c1358b",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Cesar Chavez Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                    "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Cesar Chavez Middle School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "cesar-chavez-middle-school-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1210
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.047,37.5908,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.047,37.5908,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "5da65cd5-23be-479c-86cd-4f6ef4e643b7",
        "content": {
            "centroid": {
                "lat": 37.351026,
                "lon": -121.871118
            },
            "entity": {
                "abbreviation": "Sunrise Middle School",
                "alternates": {
                    "nces": "060216612865"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5da65cd5-23be-479c-86cd-4f6ef4e643b7",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Sunrise Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "953f02c6-35fb-44cc-a96d-584c48aceaab"
                },
                "premium": false,
                "shortName": "Sunrise Middle School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "sunrise-middle-school-san-jose-ca-060216612865",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 243
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.872,37.3508,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.872,37.3508,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95116"
            }
        ]
    },
    {
        "guid": "e1b1a785-031f-4c52-a9c3-3a554e00e9e8",
        "content": {
            "centroid": {
                "lat": 37.719474,
                "lon": -122.396621
            },
            "entity": {
                "abbreviation": "KIPP Bayview Academy",
                "alternates": {
                    "nces": "060217211256"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "e1b1a785-031f-4c52-a9c3-3a554e00e9e8",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "KIPP Bayview Academy",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "cabfdde5-0786-4900-944e-dc4700ec7df2",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "6d9673ef-a1db-480d-bcce-79e87b94f91c"
                },
                "premium": false,
                "shortName": "KIPP Bayview Academy",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "5-8"
                ],
                "type": "School",
                "url": "kipp-bayview-academy-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 312
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.397,37.7196,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.397,37.7196,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94124"
            }
        ]
    },
    {
        "guid": "2518598e-85bc-4de5-a0ee-7883839f612d",
        "content": {
            "centroid": {
                "lat": 37.308146,
                "lon": -121.83679
            },
            "entity": {
                "abbreviation": "Stonegate Elementary School",
                "alternates": {
                    "nces": "061437001652"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2518598e-85bc-4de5-a0ee-7883839f612d",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Stonegate Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Stonegate Elementary School",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "stonegate-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 681
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I had a great experience. The teachers were patient and encouraged creativity among the students. The students were taught to be close and friendly. the fence around the school makes me feel comfortable knowing the children are safe.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-11-08T20:20:13.370684Z",
                "guid": "aaa48bc5-0985-4cbf-904d-27b54f0935fc",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.838,37.3087,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.838,37.3087,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "85e72c23-eb05-4e4d-8cad-bab3309efa19",
        "content": {
            "centroid": {
                "lat": 37.879676,
                "lon": -121.641491
            },
            "entity": {
                "abbreviation": "Vista Oaks Charter School",
                "alternates": {
                    "ceeb": "054791",
                    "nces": "060177913821"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "85e72c23-eb05-4e4d-8cad-bab3309efa19",
                "isClaimed": false,
                "isPremium": false,
                "location": "Lodi, CA",
                "name": "Vista Oaks Charter School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3f2c06a3-6dc5-4b8b-ae42-25d409d4ae34",
                    "zipCode": "4c4ae928-08ae-4b39-a92f-d9350bc71e2f"
                },
                "premium": false,
                "shortName": "Vista Oaks Charter School",
                "tagline": [
                    "Public School",
                    "Lodi, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "vista-oaks-charter-school-lodi-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 802
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "Vista Oaks Charter School is a really good school. The classes are well taught. The teachers are very great! If you're looking to start homeschooling your children, Vista Oaks is the place to go.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-05-11T14:42:54.057174Z",
                "guid": "496d693c-5bdd-4882-8eb9-98920e743612",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.639,37.8779,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.639,37.8779,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95242"
            }
        ]
    },
    {
        "entities": [
            {
                "guid": "ea460029-700a-453f-849e-695816cd8b06",
                "content": {
                    "centroid": {
                        "lat": 37.332329,
                        "lon": -121.922204
                    },
                    "entity": {
                        "abbreviation": "Herbert Hoover Middle School",
                        "alternates": {
                            "nces": "063459005720"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ea460029-700a-453f-849e-695816cd8b06",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Herbert Hoover Middle School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                            "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "SJUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                                "location": "San Jose, CA",
                                "name": "San Jose Unified School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                                },
                                "premium": false,
                                "shortName": "San Jose Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-jose-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Herbert Hoover Middle School",
                        "tagline": [
                            "San Jose Unified School District, CA",
                            "6-8"
                        ],
                        "type": "School",
                        "url": "herbert-hoover-middle-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 1082
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "featuredReview": {
                        "author": "Middle School Student",
                        "body": "The school is great the teachers and student at the school are fantastic you can not think of it any other way hoover I a great middle school to put you children in it’s amazing the school 🥰💕💕💕",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-05-25T21:26:15.29158Z",
                        "guid": "0ed6e4f5-4c9f-4980-8a36-ced78acc1f3e",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.924,37.332,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.924,37.332,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95126"
                    }
                ]
            },
            {
                "guid": "7275b5ec-77ce-4d32-8303-f4703f204629",
                "content": {
                    "centroid": {
                        "lat": 37.466868,
                        "lon": -122.24467
                    },
                    "entity": {
                        "abbreviation": "Roosevelt Elementary School",
                        "alternates": {
                            "nces": "063213004972"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "7275b5ec-77ce-4d32-8303-f4703f204629",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Redwood City, CA",
                        "name": "Roosevelt Elementary School",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "93ca0bc4-efc6-4993-869c-180a6cc2286e",
                            "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                            "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "711f94d7-a522-4b45-a751-5a884e5670a6"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Redwood City Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                                "location": "Redwood City, CA",
                                "name": "Redwood City Elementary School District",
                                "parentGUIDs": {
                                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                                },
                                "premium": false,
                                "shortName": "Redwood City Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Redwood City, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "redwood-city-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Roosevelt Elementary School",
                        "tagline": [
                            "Redwood City Elementary School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "roosevelt-elementary-school-redwood-city-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 555
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.244,37.4657,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.244,37.4657,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94061"
                    }
                ]
            },
            {
                "guid": "58ddd63d-cdcd-4326-a4ea-39da219f431b",
                "content": {
                    "centroid": {
                        "lat": 37.916106,
                        "lon": -122.299734
                    },
                    "entity": {
                        "abbreviation": "Fred T. Korematsu Middle School",
                        "alternates": {
                            "nces": "063255005053"
                        },
                        "character": "Public",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "58ddd63d-cdcd-4326-a4ea-39da219f431b",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "El Cerrito, CA",
                        "name": "Fred T. Korematsu Middle School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                            "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "f343dc53-cb24-4991-838e-fd06781600a8",
                            "zipCode": "5c0d439b-7e68-4d4e-ac7b-5c3008345353"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "WCCUSD",
                                "claimed": true,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                                "location": "Richmond, CA",
                                "name": "West Contra Costa Unified School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                                    "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                                },
                                "premium": false,
                                "shortName": "West Contra Costa Unified School District",
                                "tagline": [
                                    "School District",
                                    "Richmond, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "west-contra-costa-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Fred T. Korematsu Middle School",
                        "tagline": [
                            "West Contra Costa Unified School District, CA",
                            "7-8"
                        ],
                        "type": "School",
                        "url": "fred-t-korematsu-middle-school-el-cerrito-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 696
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "I believe that Korematsu has a unified atmosphere. The togetherness and pride that we all share and take in our school has really inspired me to do great things in the future.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-04-11T19:15:17.918503Z",
                        "guid": "36be939f-4121-42bc-94a7-2085e865c572",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.301,37.9154,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.301,37.9154,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.5,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94530"
                    }
                ]
            },
            {
                "guid": "c5f0f60f-808f-4dfa-838c-23972dc93ad3",
                "content": {
                    "centroid": {
                        "lat": 37.288028,
                        "lon": -121.822334
                    },
                    "entity": {
                        "abbreviation": "Sylvandale Middle School",
                        "alternates": {
                            "nces": "061437001653"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "c5f0f60f-808f-4dfa-838c-23972dc93ad3",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Sylvandale Middle School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                            "parent": "37987e01-5343-4216-b264-0535f5366805",
                            "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Franklin-McKinley Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "37987e01-5343-4216-b264-0535f5366805",
                                "location": "San Jose, CA",
                                "name": "Franklin-McKinley Elementary School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                                },
                                "premium": false,
                                "shortName": "Franklin-McKinley Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "franklin-mckinley-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Sylvandale Middle School",
                        "tagline": [
                            "Franklin-McKinley Elementary School District, CA",
                            "7-8"
                        ],
                        "type": "School",
                        "url": "sylvandale-middle-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 574
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.824,37.289,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.824,37.289,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95111"
                    }
                ]
            },
            {
                "guid": "c77e41e8-4ac4-4013-9b6c-6325b5acb4d7",
                "content": {
                    "centroid": {
                        "lat": 37.725841,
                        "lon": -122.432663
                    },
                    "entity": {
                        "abbreviation": "San Francisco Community Alternative School",
                        "alternates": {
                            "nces": "063441005663"
                        },
                        "character": "Public, Alternative",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "c77e41e8-4ac4-4013-9b6c-6325b5acb4d7",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Francisco, CA",
                        "name": "San Francisco Community Alternative School",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a36158f8-7bdf-419a-9e51-680d947f62d9",
                            "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "61413703-2cce-42b9-a718-b28b79a1680f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "SFUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                                "location": "San Francisco, CA",
                                "name": "San Francisco Unified School District",
                                "parentGUIDs": {
                                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                                    "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                                },
                                "premium": false,
                                "shortName": "San Francisco Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Francisco, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-francisco-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "San Francisco Community Alternative School",
                        "tagline": [
                            "San Francisco Unified School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "san-francisco-community-alternative-school-san-francisco-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 280
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "San Francisco Community school was a school with many great experiences throughout my childhood. It had the unique part of having students practice the virtues and powerful ways of thinking. Awards would be handed out to students who practiced these virtues and powerful ways of thinking. This helped people develop the ability to respect themselves and others more.\n\nThe teachers emphasized these virtues for all the grade levels. The teachers also held community gatherings for all the grades. These would include whole school picnics and whole school games. \n\nThe other unique part of this school was the space it created for conflicts between others to be resolved through the virtues. Teachers would step in between students to help guide them to resolving problems. This gave a chance for people to learn from their behavioral mistakes when they were young and innocent.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2017-01-06T02:30:05.15995Z",
                        "guid": "cfb615f9-a7c3-49d0-bd47-9d77c3b96090",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.432,37.7262,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.432,37.7262,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94112"
                    }
                ]
            },
            {
                "guid": "4ad2c77f-449f-45e6-9a16-0b65c4fceda5",
                "content": {
                    "centroid": {
                        "lat": 37.688862,
                        "lon": -122.149624
                    },
                    "entity": {
                        "abbreviation": "Washington Manor Middle School",
                        "alternates": {
                            "nces": "063471008973"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "4ad2c77f-449f-45e6-9a16-0b65c4fceda5",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Leandro, CA",
                        "name": "Washington Manor Middle School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "08653e79-c54d-495b-b7b6-ea2eda20fcd8",
                            "parent": "e54d426f-208e-46bc-9c03-0fc37e4b218c",
                            "schoolDistrict": "e54d426f-208e-46bc-9c03-0fc37e4b218c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                            "zipCode": "2f1b031c-d987-4e47-a043-a587b9a42bee"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "San Lorenzo Unified School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "e54d426f-208e-46bc-9c03-0fc37e4b218c",
                                "location": "San Lorenzo, CA",
                                "name": "San Lorenzo Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "b04852e8-80bc-429e-bdfb-748588649add",
                                    "zipCode": "5820f130-05d4-461d-b769-af76b2295d66"
                                },
                                "premium": false,
                                "shortName": "San Lorenzo Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Lorenzo, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-lorenzo-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Washington Manor Middle School",
                        "tagline": [
                            "San Lorenzo Unified School District, CA",
                            "6-8"
                        ],
                        "type": "School",
                        "url": "washington-manor-middle-school-san-leandro-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 760
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.15,37.6881,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.15,37.6881,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94579"
                    }
                ]
            },
            {
                "guid": "1cd7d2da-ae1d-44b4-84ce-fdd015d70d24",
                "content": {
                    "centroid": {
                        "lat": 38.01836,
                        "lon": -122.673925
                    },
                    "entity": {
                        "abbreviation": "Lagunitas Elementary School",
                        "alternates": {
                            "nces": "062052002473"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "1cd7d2da-ae1d-44b4-84ce-fdd015d70d24",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Geronimo, CA",
                        "name": "Lagunitas Elementary School",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "8e18a515-a402-4911-87d7-8881636f04e5",
                            "schoolDistrict": "8e18a515-a402-4911-87d7-8881636f04e5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "zipCode": "264b06cc-28ea-4de2-ab68-9cde0f959562"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Lagunitas Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "8e18a515-a402-4911-87d7-8881636f04e5",
                                "location": "San Geronimo, CA",
                                "name": "Lagunitas Elementary School District",
                                "parentGUIDs": {
                                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "zipCode": "264b06cc-28ea-4de2-ab68-9cde0f959562"
                                },
                                "premium": false,
                                "shortName": "Lagunitas Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Geronimo, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "lagunitas-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Lagunitas Elementary School",
                        "tagline": [
                            "Lagunitas Elementary School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "lagunitas-elementary-school-san-geronimo-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 152
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.675,38.0175,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.675,38.0175,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94963"
                    }
                ]
            },
            {
                "guid": "b820a4a4-0a52-4f28-b20a-5c08df6d4076",
                "content": {
                    "centroid": {
                        "lat": 37.541761,
                        "lon": -122.038982
                    },
                    "entity": {
                        "abbreviation": "Newark Junior High School",
                        "alternates": {
                            "nces": "062706007796"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "b820a4a4-0a52-4f28-b20a-5c08df6d4076",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Newark, CA",
                        "name": "Newark Junior High School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                            "schoolDistrict": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                            "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Newark Unified School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                                "location": "Newark, CA",
                                "name": "Newark Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                                    "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                                },
                                "premium": false,
                                "shortName": "Newark Unified School District",
                                "tagline": [
                                    "School District",
                                    "Newark, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "newark-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Newark Junior High School",
                        "tagline": [
                            "Newark Unified School District, CA",
                            "7-8"
                        ],
                        "type": "School",
                        "url": "newark-junior-high-school-newark-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 889
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 36
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.039,37.5418,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.039,37.5418,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.1666666666666665,
                        "count": 6
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94560"
                    }
                ]
            },
            {
                "guid": "140aba27-2e56-4aa5-93b3-970d0f3a9f58",
                "content": {
                    "centroid": {
                        "lat": 37.664338,
                        "lon": -122.486612
                    },
                    "entity": {
                        "abbreviation": "Franklin Delano Roosevelt Elementary School",
                        "alternates": {
                            "nces": "061887002284"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "140aba27-2e56-4aa5-93b3-970d0f3a9f58",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Daly City, CA",
                        "name": "Franklin Delano Roosevelt Elementary School",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                            "schoolDistrict": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "41a99124-6b7c-496d-9635-39a33c683883",
                            "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Jefferson Elementary School District (Daly City)",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                                "location": "Daly City, CA",
                                "name": "Jefferson Elementary School District (Daly City)",
                                "parentGUIDs": {
                                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "41a99124-6b7c-496d-9635-39a33c683883",
                                    "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                                },
                                "premium": false,
                                "shortName": "Jefferson Elementary School District (Daly City)",
                                "tagline": [
                                    "School District",
                                    "Daly City, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "jefferson-elementary-school-district-daly-city-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Franklin Delano Roosevelt Elementary School",
                        "tagline": [
                            "Jefferson Elementary School District (Daly City), CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "franklin-delano-roosevelt-elementary-school-daly-city-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 377
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 15
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.488,37.663,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.488,37.663,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94015"
                    }
                ]
            },
            {
                "guid": "331339a1-5aae-481d-b8bf-4d4ea2b953de",
                "content": {
                    "centroid": {
                        "lat": 37.990819,
                        "lon": -122.332794
                    },
                    "entity": {
                        "abbreviation": "Making Waves Academy",
                        "alternates": {
                            "ceeb": "054553",
                            "nces": "060150212043"
                        },
                        "character": "Public, Charter",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Charter School",
                        "guid": "331339a1-5aae-481d-b8bf-4d4ea2b953de",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Richmond, CA",
                        "name": "Making Waves Academy",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "a08c6674-b67b-4d2b-976e-ac88b0619d80"
                        },
                        "premium": false,
                        "shortName": "Making Waves Academy",
                        "tagline": [
                            "Public School",
                            "Richmond, CA",
                            "5-12"
                        ],
                        "type": "School",
                        "url": "making-waves-academy-richmond-ca",
                        "variation": 56
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 940
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 20
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "My experience as a student at making waves academy was pretty good. There were several aspects of the school that contributed to those positive feelings. Firstly,  I like that Making Waves was a small school that allowed teachers to help students effectively. The teachers and administration were very involved with there students which made it comfortable for people to approach teachers when we needed help and assistance. Secondly, the fact that parent meetings happen pretty often was good because parents are able to be up to date with school events and activities. Overall I enjoyed my years at Making Waves Academy.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-02-15T02:37:26.513893Z",
                        "guid": "22266c23-80d1-4191-9c91-d9b9b683fde3",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.333,37.9888,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.333,37.9888,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.9130434782608696,
                        "count": 92
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94806"
                    }
                ]
            },
            {
                "guid": "1179aa6c-9884-4f9a-8c29-0207635c8a22",
                "content": {
                    "centroid": {
                        "lat": 37.714178,
                        "lon": -122.168145
                    },
                    "entity": {
                        "abbreviation": "John Muir Middle School",
                        "alternates": {
                            "nces": "063468005839"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "1179aa6c-9884-4f9a-8c29-0207635c8a22",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Leandro, CA",
                        "name": "John Muir Middle School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "7ccd5262-b2f0-4712-a655-d3f2c8f2bda9",
                            "parent": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                            "schoolDistrict": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                            "zipCode": "0418db05-538d-429e-a8b8-8b6441797a1d"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "San Leandro Unified School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                                "location": "San Leandro, CA",
                                "name": "San Leandro Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "a2d1b6e7-6b04-4cd6-a4bf-7eadb1067cb7",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                                    "zipCode": "0418db05-538d-429e-a8b8-8b6441797a1d"
                                },
                                "premium": false,
                                "shortName": "San Leandro Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Leandro, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-leandro-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "John Muir Middle School",
                        "tagline": [
                            "San Leandro Unified School District, CA",
                            "6-8"
                        ],
                        "type": "School",
                        "url": "john-muir-middle-school-san-leandro-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 1003
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "featuredReview": {
                        "author": "Niche User",
                        "body": "It depends on the teachers but everyone is friendly. Just be nice and they'll be nice to you back",
                        "categories": [
                            "Academics"
                        ],
                        "created": "2014-08-14T20:07:40Z",
                        "guid": "bcaa482d-f469-4e88-a6a5-75a843cf4b0d",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.168,37.7152,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.168,37.7152,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 2.9,
                        "count": 10
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94577"
                    }
                ]
            },
            {
                "guid": "d59bc277-7580-492d-9bc6-cc221951bace",
                "content": {
                    "centroid": {
                        "lat": 38.1506,
                        "lon": -122.245
                    },
                    "entity": {
                        "abbreviation": "MITA",
                        "alternates": {
                            "nces": "060178308398"
                        },
                        "character": "Public, Charter",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Charter School",
                        "guid": "d59bc277-7580-492d-9bc6-cc221951bace",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "Vallejo, CA",
                        "name": "Mare Island Technology Academy",
                        "parentGUIDs": {
                            "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd45ba4f-972c-4537-bd0a-adc5060daf7c",
                            "zipCode": "2a6799d3-4951-4fc5-9600-781d2e0f8833"
                        },
                        "premium": false,
                        "shortName": "MIT Academy",
                        "tagline": [
                            "Public School",
                            "Vallejo, CA",
                            "6-8"
                        ],
                        "type": "School",
                        "url": "mare-island-technology-academy-vallejo-ca-064074008398",
                        "variation": 57
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 432
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 27
                        }
                    ],
                    "featuredReview": {
                        "author": "Junior",
                        "body": "I like that they give students a chance to be involved in internships, community services and college classes. And that they provide courses that completes UCs requirements such as Statistics and college English.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-12-30T18:17:13.585263Z",
                        "guid": "a55e18d5-fe18-4e7e-a365-07de9016f4d5",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.245,38.1506,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.245,38.1506,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.557377049180328,
                        "count": 61
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94589"
                    }
                ]
            },
            {
                "guid": "4881005d-7ac6-4a2b-b03d-85aba46d3c2c",
                "content": {
                    "centroid": {
                        "lat": 37.968809,
                        "lon": -121.663191
                    },
                    "entity": {
                        "abbreviation": "Knightsen Elementary School",
                        "alternates": {
                            "nces": "062004002397"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "4881005d-7ac6-4a2b-b03d-85aba46d3c2c",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Knightsen, CA",
                        "name": "Knightsen Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                            "schoolDistrict": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "dd92bf0e-5067-4c84-a7d4-9d4d873c429b"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Knightsen Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                                "location": "Knightsen, CA",
                                "name": "Knightsen Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "dd92bf0e-5067-4c84-a7d4-9d4d873c429b",
                                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                                },
                                "premium": false,
                                "shortName": "Knightsen Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Knightsen, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "knightsen-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Knightsen Elementary School",
                        "tagline": [
                            "Knightsen Elementary School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "knightsen-elementary-school-knightsen-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 364
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.661,37.9688,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.661,37.9688,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94548"
                    }
                ]
            },
            {
                "guid": "328b5698-dd4d-4786-bf92-06028efe8152",
                "content": {
                    "centroid": {
                        "lat": 37.76856,
                        "lon": -122.154338
                    },
                    "entity": {
                        "abbreviation": "Sojourner Truth Independent Study",
                        "alternates": {
                            "nces": "062805005701"
                        },
                        "character": "Public, Alternative",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "328b5698-dd4d-4786-bf92-06028efe8152",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Oakland, CA",
                        "name": "Sojourner Truth Independent Study",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                            "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "3816271d-bf9c-431c-9e92-8f63ddf53940"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "OUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                                "location": "Oakland, CA",
                                "name": "Oakland Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                                    "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                                },
                                "premium": false,
                                "shortName": "Oakland Unified School District",
                                "tagline": [
                                    "School District",
                                    "Oakland, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "oakland-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Sojourner Truth Independent Study",
                        "tagline": [
                            "Oakland Unified School District, CA",
                            "K-12"
                        ],
                        "type": "School",
                        "url": "sojourner-truth-independent-study-oakland-ca",
                        "variation": 44
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 166
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 13
                        }
                    ],
                    "featuredReview": {
                        "author": "Senior",
                        "body": "Sojourner truth independent study is a public school that offers a second chance for individuals that want to pursue a higher education but with a different learning environment. Honestly, I never knew about this public school until I was trying to find another school to fit in with. It wasn’t a new feeling, but it felt weird. It’s a new school, new people. However, the staff was welcoming and accepting of students that messed up or wanted to study/learn by themselves. The school is limited in resources, which is understandable. I feel the school needs more recognition and respect because the school excels with what it has and it can propel to further success if the school got a positive reputation rather than a negative one. Thank you for giving me this opportunity for applying to this scholarship.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2017-10-30T18:41:52.449763Z",
                        "guid": "25e26e4d-ef99-484a-bc22-ae9724bacdda",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.154,37.7662,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.154,37.7662,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4.4,
                        "count": 5
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94605"
                    }
                ]
            },
            {
                "guid": "37c2e729-8f7b-4463-bfdb-589f5f3d8d79",
                "content": {
                    "centroid": {
                        "lat": 37.996663,
                        "lon": -121.689727
                    },
                    "entity": {
                        "abbreviation": "Delta Vista Middle School",
                        "alternates": {
                            "nces": "062808008689"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "37c2e729-8f7b-4463-bfdb-589f5f3d8d79",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Oakley, CA",
                        "name": "Delta Vista Middle School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                            "schoolDistrict": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6ee6365f-3e69-4aad-a8df-5cd72ae38991",
                            "zipCode": "4e62bbb5-5683-41cb-a3ca-4f5f14325035"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Oakley Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                                "location": "Oakley, CA",
                                "name": "Oakley Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "6ee6365f-3e69-4aad-a8df-5cd72ae38991",
                                    "zipCode": "4e62bbb5-5683-41cb-a3ca-4f5f14325035"
                                },
                                "premium": false,
                                "shortName": "Oakley Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Oakley, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "oakley-union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Delta Vista Middle School",
                        "tagline": [
                            "Oakley Union Elementary School District, CA",
                            "6-8"
                        ],
                        "type": "School",
                        "url": "delta-vista-middle-school-oakley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 904
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.69,37.9935,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.69,37.9935,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 2.75,
                        "count": 4
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94561"
                    }
                ]
            },
            {
                "guid": "6d02465c-4d1e-4af8-9127-f53432a112eb",
                "content": {
                    "centroid": {
                        "lat": 37.634991,
                        "lon": -122.092641
                    },
                    "entity": {
                        "abbreviation": "Dr. Martin Luther King Jr. Middle School",
                        "alternates": {
                            "nces": "061674002107"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "6d02465c-4d1e-4af8-9127-f53432a112eb",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Hayward, CA",
                        "name": "Dr. Martin Luther King Jr. Middle School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "f12097a1-3ac1-4f2b-b578-9852dbf14c95",
                            "parent": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                            "schoolDistrict": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                            "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "HUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                                "location": "Hayward, CA",
                                "name": "Hayward Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "3ee6b10e-9c9c-43c9-bee6-1755097895e1",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                                    "zipCode": "72f14b46-7398-463c-a7b1-84d94dfe3a97"
                                },
                                "premium": false,
                                "shortName": "Hayward Unified School District",
                                "tagline": [
                                    "School District",
                                    "Hayward, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "hayward-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Dr. Martin Luther King Jr. Middle School",
                        "tagline": [
                            "Hayward Unified School District, CA",
                            "7-8"
                        ],
                        "type": "School",
                        "url": "dr-martin-luther-king-jr-middle-school-hayward-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 757
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "featuredReview": {
                        "author": "Middle School Student",
                        "body": "This newly renovated school has a lot of opportunities, with sports and Physical Education. There are advanced math classes, such as Algebra and Geometry, and a variety of after-school clubs. In the eighth grade, there are many electives available, from Art, to Technology (really computer science) and Spanish.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2018-02-14T18:24:08.10975Z",
                        "guid": "d6e3d2b3-608d-4281-993b-13f54e08f0ac",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.092,37.6349,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.092,37.6349,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.8333333333333335,
                        "count": 6
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94545"
                    }
                ]
            },
            {
                "guid": "1fe05094-a745-480d-bdb6-b587ed93cf5b",
                "content": {
                    "centroid": {
                        "lat": 37.762132,
                        "lon": -122.193598
                    },
                    "entity": {
                        "abbreviation": "Coliseum College Prep Academy",
                        "alternates": {
                            "ceeb": "054412",
                            "nces": "062805011920"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "1fe05094-a745-480d-bdb6-b587ed93cf5b",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Oakland, CA",
                        "name": "Coliseum College Prep Academy",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                            "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "e5836a73-f350-41af-a5c5-da313cd26efb"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "OUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                                "location": "Oakland, CA",
                                "name": "Oakland Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                                    "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                                },
                                "premium": false,
                                "shortName": "Oakland Unified School District",
                                "tagline": [
                                    "School District",
                                    "Oakland, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "oakland-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Coliseum College Prep Academy",
                        "tagline": [
                            "Oakland Unified School District, CA",
                            "6-12"
                        ],
                        "type": "School",
                        "url": "coliseum-college-prep-academy-oakland-ca",
                        "variation": 44
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 486
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 15
                        }
                    ],
                    "featuredReview": {
                        "author": "Senior",
                        "body": "The teachers are very engaging, committed, and accommodating to the students and in the classroom. The office hours and checking in with students about their education and health were the best because they also answered any questions I had about the work or class lessons. The school also always tried to celebrate holidays or celebrations together. I also like how it's mostly people of minorities since we can connect to each other in some way. Some things I would like changed or more clubs to keep people more engaged and connected. Another thing I would like to see changed is more variety of sports or a self-defense class to help students and keep them in shape and healthy.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2021-02-26T06:16:52.806033Z",
                        "guid": "04b78e53-4cef-44d7-8378-acc9c3c761ed",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.193,37.7617,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.193,37.7617,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.588235294117647,
                        "count": 51
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94621"
                    }
                ]
            },
            {
                "guid": "8ca09ac6-0219-4cad-a04f-7c6b624eab7e",
                "content": {
                    "centroid": {
                        "lat": 37.754912,
                        "lon": -122.426033
                    },
                    "entity": {
                        "abbreviation": "TECA",
                        "alternates": {
                            "nces": "060205410388"
                        },
                        "character": "Public, Charter",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Charter School",
                        "guid": "8ca09ac6-0219-4cad-a04f-7c6b624eab7e",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "San Francisco, CA",
                        "name": "Thomas Edison Charter Academy",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "3ff4be8a-4cc3-4e5c-9dc8-4eb4db5b7c82",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a3ca5eac-7152-427f-be6e-9e3727c508ed"
                        },
                        "premium": false,
                        "shortName": "Thomas Edison Charter Academy",
                        "tagline": [
                            "Public School",
                            "San Francisco, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "thomas-edison-charter-academy-san-francisco-ca",
                        "variation": 57
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 730
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 20
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "My kids have made lots of friends here and have felt welcomed. The school feels safe and friendly. The parents are friendly and down to earth. The staff and teachers are professional and nice.  Our kids have learned Spanish as well as an appreciation for people who speak Spanish. The kids take art, dance, and music classes from TK-8th grades. We will look back at this school very fondly when we leave in the future. The school has been through some ups and downs in the 10+ years that it has operated as a community-based charter school, but I think it's finding a happy resting place. I don't feel that the school's most recent test scores really reflect the strength of the kids. We had a rough year in terms of teacher turnover the last time we did testing (2019) and our scores dropped quite a bit from before. However, almost all of our teachers came back this year so we're on the right track. I would recommend it.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-10-08T17:18:01.384085Z",
                        "guid": "62849541-bbca-4934-b03e-16c5bd7f80ca",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.426,37.7548,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.426,37.7548,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.7142857142857144,
                        "count": 7
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94114"
                    }
                ]
            },
            {
                "guid": "edfea8b7-7bf5-4974-9719-da8ab6b2e7f1",
                "content": {
                    "centroid": {
                        "lat": 37.78423,
                        "lon": -122.216797
                    },
                    "entity": {
                        "abbreviation": "Life Academy",
                        "alternates": {
                            "ceeb": "052212",
                            "nces": "062805008676"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "edfea8b7-7bf5-4974-9719-da8ab6b2e7f1",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Oakland, CA",
                        "name": "Life Academy",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                            "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "90731d14-c187-4264-ac2c-4b3485508684"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "OUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                                "location": "Oakland, CA",
                                "name": "Oakland Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                                    "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                                },
                                "premium": false,
                                "shortName": "Oakland Unified School District",
                                "tagline": [
                                    "School District",
                                    "Oakland, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "oakland-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Life Academy",
                        "tagline": [
                            "Oakland Unified School District, CA",
                            "6-12"
                        ],
                        "type": "School",
                        "url": "life-academy-oakland-ca",
                        "variation": 44
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 463
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 15
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "Life Academy is smaller than most high schools and therefore teachers are more involved with each student. They have enough supplies for class sizes and enough staff to go on field trips and after school activities. The school could use more diversity in sport teams but it provides all the needs to prepare for college applications, tutoring and mentoring.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-02-10T20:25:34.79363Z",
                        "guid": "d34bf8e6-9806-40af-a815-4a172be4208a",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.217,37.7853,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.217,37.7853,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.627450980392157,
                        "count": 51
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94601"
                    }
                ]
            },
            {
                "guid": "ca2b4df0-ded5-4fca-a703-4c0f7b624900",
                "content": {
                    "centroid": {
                        "lat": 37.638835,
                        "lon": -122.109188
                    },
                    "entity": {
                        "abbreviation": "Anthony W. Ochoa Middle School",
                        "alternates": {
                            "nces": "061674009847"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ca2b4df0-ded5-4fca-a703-4c0f7b624900",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Hayward, CA",
                        "name": "Anthony W. Ochoa Middle School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "c7e723f0-78fc-4ba9-aa90-a97d95040134",
                            "parent": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                            "schoolDistrict": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                            "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "HUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                                "location": "Hayward, CA",
                                "name": "Hayward Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "3ee6b10e-9c9c-43c9-bee6-1755097895e1",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                                    "zipCode": "72f14b46-7398-463c-a7b1-84d94dfe3a97"
                                },
                                "premium": false,
                                "shortName": "Hayward Unified School District",
                                "tagline": [
                                    "School District",
                                    "Hayward, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "hayward-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Anthony W. Ochoa Middle School",
                        "tagline": [
                            "Hayward Unified School District, CA",
                            "7-8"
                        ],
                        "type": "School",
                        "url": "anthony-w-ochoa-middle-school-hayward-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 588
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "featuredReview": {
                        "author": "Senior",
                        "body": "I liked the environment and the spirit that my middle school had, i was also in the leadership program which i had abchance to make the school more fun involving teachers and student . So i had a very good time in middle school snd wish i can go back and do it again. I got to pick our dance themss then decorate the place ,m. I gotto come up with lunch time activities like 3 v3 vasketball at lunch with students and staff , lits if kids joined that. They also had this academy that i was in called LEAD academy which had only black and latino make students in 3 of the first class periods. The academy was made because people say that those teo groups of people are the main to do bad in life and we wantedto make a change in that belief. We did lost of things like going on field trips, cleaning up the hayward shoreline, and cookig with eachother to interact and it was really fun for me",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2018-02-14T21:17:51.102548Z",
                        "guid": "f148d636-1d4f-4412-a896-542de31ab19f",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.109,37.6412,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.109,37.6412,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4.333333333333333,
                        "count": 3
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94545"
                    }
                ]
            },
            {
                "guid": "079e2607-0144-4237-ac02-1c9465502d75",
                "content": {
                    "centroid": {
                        "lat": 38.000028,
                        "lon": -121.741482
                    },
                    "entity": {
                        "abbreviation": "Orchard Park",
                        "alternates": {
                            "nces": "060285011904"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "079e2607-0144-4237-ac02-1c9465502d75",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Oakley, CA",
                        "name": "Orchard Park",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "007aef6d-07b5-49c0-aac8-9cadec204a65",
                            "schoolDistrict": "007aef6d-07b5-49c0-aac8-9cadec204a65",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6ee6365f-3e69-4aad-a8df-5cd72ae38991",
                            "zipCode": "4e62bbb5-5683-41cb-a3ca-4f5f14325035"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Antioch Unified School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "007aef6d-07b5-49c0-aac8-9cadec204a65",
                                "location": "Antioch, CA",
                                "name": "Antioch Unified School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                                },
                                "premium": false,
                                "shortName": "Antioch Unified School District",
                                "tagline": [
                                    "School District",
                                    "Antioch, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "antioch-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Orchard Park",
                        "tagline": [
                            "Antioch Unified School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "orchard-park-oakley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 724
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "featuredReview": {
                        "author": "High School Freshman",
                        "body": "I've had some of the best years of my life at this school. Everyone is just so kind and the atmosphere makes you smile. Two things make this school unique: the fact that it's K-8 and its a small school and because its small everyone know everybody. I would definitely choose this school again because I have made so many friends, been given opportunity after opportunity, and have been changed. This school really has changed me, it has made me a better person and I'm pretty sure not all schools can do that to a person.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2014-08-11T01:23:27Z",
                        "guid": "d842d7b3-b702-416c-b6d9-fedfdb43314b",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.74,38.0002,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.74,38.0002,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.9,
                        "count": 10
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94561"
                    }
                ]
            },
            {
                "guid": "a52ac815-331f-4ea6-8397-75c85c127c52",
                "content": {
                    "centroid": {
                        "lat": 37.775863,
                        "lon": -122.406746
                    },
                    "entity": {
                        "abbreviation": "Bessie Carmichael/FEC",
                        "alternates": {
                            "nces": "063441005590"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "a52ac815-331f-4ea6-8397-75c85c127c52",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Francisco, CA",
                        "name": "Bessie Carmichael/FEC",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "cb7bbf0b-a5ba-41c0-97b4-93adedb95bdd",
                            "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "61c492e2-8672-4092-ad94-cc98d0a7cc3d"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "SFUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                                "location": "San Francisco, CA",
                                "name": "San Francisco Unified School District",
                                "parentGUIDs": {
                                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                                    "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                                },
                                "premium": false,
                                "shortName": "San Francisco Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Francisco, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-francisco-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Bessie Carmichael/FEC",
                        "tagline": [
                            "San Francisco Unified School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "bessie-carmichael-fec-san-francisco-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 625
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 30
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.66
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.406,37.777,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.406,37.777,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94103"
                    }
                ]
            },
            {
                "guid": "5d78f238-d465-4dd9-94c7-3102f93fcccf",
                "content": {
                    "centroid": {
                        "lat": 37.753769,
                        "lon": -122.419705
                    },
                    "entity": {
                        "abbreviation": "Buena Vista Horace Mann K-8",
                        "alternates": {
                            "nces": "063441005626"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "5d78f238-d465-4dd9-94c7-3102f93fcccf",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Francisco, CA",
                        "name": "Buena Vista Horace Mann K-8",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0cac67cc-9c02-4a4c-8cd6-5714b7fda8b7",
                            "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "c568e3a2-4c27-4503-a9f7-85276a6ec461"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "SFUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                                "location": "San Francisco, CA",
                                "name": "San Francisco Unified School District",
                                "parentGUIDs": {
                                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                                    "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                                },
                                "premium": false,
                                "shortName": "San Francisco Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Francisco, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-francisco-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Buena Vista Horace Mann K-8",
                        "tagline": [
                            "San Francisco Unified School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "buena-vista-horace-mann-k-8-san-francisco-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 589
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 19
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "Have any of you listened to the podcast Nice White Parents? I can probably name the 5 Karens who write these poor reviews this site and all the others. And what do these arbitrary grades even mean when our fluent Spanish speakers (of many raceses btw) are being test and assessed in English when their courses are primarily in Spanish. Fact is they have no way of adequately and gauging how well our students do. These are the women who freaked out and left when the school decided to shelter our unhoused students. Something that has absolutely no bearing on the day to day operations of the school. If you didn't know the shelter was there  in the evenings was chances are you'd never know. Fact is our teaches are absolutely devoted. We have a high level of teacher retention and if a teacher has to leave it is generally because they can no longer afford to live in SF. My son has had 826 Valencia Writing program for the last 5 years. The music, dance and art programs are stellar.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2021-02-08T01:46:09.282045Z",
                        "guid": "237a77dd-6352-44e1-bd94-050d7627bdfc",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.33
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.42,37.7532,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.42,37.7532,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.4285714285714284,
                        "count": 7
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94110"
                    }
                ]
            },
            {
                "guid": "262e2cfd-da8d-4236-87f4-c0bfc37ef1db",
                "content": {
                    "centroid": {
                        "lat": 38.001207,
                        "lon": -122.522986
                    },
                    "entity": {
                        "abbreviation": "Venetia Valley Elementary School",
                        "alternates": {
                            "nces": "063509005934"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "262e2cfd-da8d-4236-87f4-c0bfc37ef1db",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Rafael, CA",
                        "name": "Venetia Valley K-8 School",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b37781ad-d4d4-4163-a91b-b46c47374ad5",
                            "parent": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                            "schoolDistrict": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "f861ceba-a037-4328-b235-a9ef2a1be19f",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "San Rafael City Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                                "location": "San Rafael, CA",
                                "name": "San Rafael City Elementary School District",
                                "parentGUIDs": {
                                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                                    "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                                },
                                "premium": false,
                                "shortName": "San Rafael City Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Rafael, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-rafael-city-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Venetia Valley Elementary School",
                        "tagline": [
                            "San Rafael City Elementary School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "venetia-valley-k-8-school-san-rafael-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 677
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 19
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.33
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.525,38.0011,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.525,38.0011,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 2,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94903"
                    }
                ]
            },
            {
                "guid": "40885145-2cc8-46ed-bce6-e774b2ad706f",
                "content": {
                    "centroid": {
                        "lat": 37.279387,
                        "lon": -121.816761
                    },
                    "entity": {
                        "abbreviation": "Christopher Elementary School",
                        "alternates": {
                            "nces": "062781004208"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "40885145-2cc8-46ed-bce6-e774b2ad706f",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Christopher Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                            "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                            "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Oak Grove Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                                "location": "San Jose, CA",
                                "name": "Oak Grove Elementary School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                                },
                                "premium": false,
                                "shortName": "Oak Grove Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "oak-grove-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Christopher Elementary School",
                        "tagline": [
                            "Oak Grove Elementary School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "christopher-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 375
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 27
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 2.33
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.818,37.28,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.818,37.28,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95111"
                    }
                ]
            }
        ]
    },
    {
        "guid": "64c25d5f-dcb6-437e-ac35-88c5454c0c2b",
        "content": {
            "centroid": {
                "lat": 37.458024,
                "lon": -122.219112
            },
            "entity": {
                "abbreviation": "Selby Lane Elementary School",
                "alternates": {
                    "nces": "063213004973"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "64c25d5f-dcb6-437e-ac35-88c5454c0c2b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Atherton, CA",
                "name": "Selby Lane Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                    "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Selby Lane Elementary School",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "selby-lane-elementary-school-atherton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 730
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I loved my time at Selby Lane, I loved the teachers, classes and lessons I have learned there - Let's go class of 2019!!!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-11-10T03:04:02.94466Z",
                "guid": "9927494c-3182-4c9c-b676-d8d2116c5807",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.221,37.4582,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.221,37.4582,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94027"
            }
        ]
    },
    {
        "guid": "c7281b0f-1983-4c87-96d0-1902b2a6d4b3",
        "content": {
            "centroid": {
                "lat": 37.845641,
                "lon": -122.284685
            },
            "entity": {
                "abbreviation": "Aspire Berkley Maynard Academy",
                "alternates": {
                    "nces": "060204610727"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "c7281b0f-1983-4c87-96d0-1902b2a6d4b3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Aspire Berkley Maynard Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "schoolNetwork": "6d55fb3e-0771-4fa6-bff7-2753f0a42065",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "9b0e24bf-e5f4-4fab-becb-f9156b7b0d68"
                },
                "premium": false,
                "shortName": "Aspire Berkley Maynard Academy",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "aspire-berkley-maynard-academy-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 587
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.283,37.8458,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.283,37.8458,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94608"
            }
        ]
    },
    {
        "guid": "4390afdd-a639-4c05-81f3-99b92a1bd39e",
        "content": {
            "centroid": {
                "lat": 37.829637,
                "lon": -122.272189
            },
            "entity": {
                "abbreviation": "Oakland Military Institute - College Preparatory Academy",
                "alternates": {
                    "ceeb": "053972",
                    "nces": "060197110729"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "4390afdd-a639-4c05-81f3-99b92a1bd39e",
                "isClaimed": true,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Oakland Military Institute - College Preparatory Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "9b0e24bf-e5f4-4fab-becb-f9156b7b0d68"
                },
                "premium": false,
                "shortName": "OMI",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "oakland-military-institute---college-preparatory-academy-oakland-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 743
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Sophomore",
                "body": "Oakland Military Institute is a very good school.  It ranges from the begging of junior high(6thgrade) to the end of high school(12th grade). The disciplinary and educational structure are way above average and teach the students about life and get them ready for the world.The programs and student activities are outstanding and teachers make learning fun.Unlike many Oakland schools OMI was founded by the previous Governor,  Jerry Brown who wanted to make a difference in the lives of Oakland students and e are very thankful for that selflessness and respect from him.  OMI has been funded by Gov. Jerry Brown,  but since he is no longer in office funds have been lower and resources are looking down. OMI has claimed to be sitting down in a few years,  with the last of the graduating class being in 2030, or some years before that with no funds.It is sad but, \n Overall My personal experience has been great and I would recommend anyone to go to this school if in the Bay Area if California.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-01T01:35:21.225894Z",
                "guid": "3ebe8bda-872b-40a4-a04e-3ebf65edc063",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.272737,37.829535,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.272737,37.829535,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.589041095890411,
                "count": 73
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94608"
            }
        ]
    },
    {
        "guid": "f3157fb8-2595-41be-ba40-9a99eb823c47",
        "content": {
            "centroid": {
                "lat": 37.36741,
                "lon": -121.858435
            },
            "entity": {
                "abbreviation": "Downtown College Prep - Alum Rock",
                "alternates": {
                    "ceeb": "054750",
                    "nces": "060165612827"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "f3157fb8-2595-41be-ba40-9a99eb823c47",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "DCP Alum Rock High School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e3304317-8ec0-4ec2-a735-ae02c9650a81"
                },
                "premium": false,
                "shortName": "Downtown College Prep - Alum Rock",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "dcp-alum-rock-high-school-san-jose-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 668
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Downtown College Prep Alum Rock High School is a small school in East side San Jose that foster the learning and academic competitiveness of its students. It grants amazing support to its first generation students and prides itself with multiple accomplishments in the community, the state and even nationwide. DCP Alum Rock High School focuses, strongly, on preparing students who want to pursue STEM based majors in college and through their careers. As a small school, DCP teachers and staff are able to connect with their students and provide various forms of support through clubs, sports and academic endeavor. This school is definitely underrated and should receive more acknowledgement for constantly preparing first generation, students of color to pursue and succeed in higher level institutions to further expand their academic experiences.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-16T21:53:30.540721Z",
                "guid": "347098ea-26da-43f5-8852-d7b6e3c90871",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.858435,37.36741,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.858435,37.36741,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.357142857142857,
                "count": 14
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95133"
            }
        ]
    },
    {
        "guid": "d63f67a3-c6f9-4993-8608-4f4ca4e86c54",
        "content": {
            "centroid": {
                "lat": 37.92285,
                "lon": -122.329191
            },
            "entity": {
                "abbreviation": "Caliber: Beta Academy",
                "alternates": {
                    "nces": "060172613746"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "d63f67a3-c6f9-4993-8608-4f4ca4e86c54",
                "isClaimed": false,
                "isPremium": false,
                "location": "Richmond, CA",
                "name": "Caliber: Beta Academy",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                    "zipCode": "ce8bebad-32ac-45ac-bd87-076119b2c0fa"
                },
                "premium": false,
                "shortName": "Caliber: Beta Academy",
                "tagline": [
                    "Public School",
                    "Richmond, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "caliber--beta-academy-richmond-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 802
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.329,37.9228,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.329,37.9228,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94804"
            }
        ]
    },
    {
        "guid": "25075ef6-c441-4dbc-bacb-c9dfd1e71c9c",
        "content": {
            "centroid": {
                "lat": 37.700898,
                "lon": -122.413912
            },
            "entity": {
                "abbreviation": "Bayshore Elementary School",
                "alternates": {
                    "nces": "060420000379"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "25075ef6-c441-4dbc-bacb-c9dfd1e71c9c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Bayshore Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "41a99124-6b7c-496d-9635-39a33c683883",
                    "zipCode": "73a39e4a-bcc0-4688-99ae-a5dc425c236e"
                },
                "premium": false,
                "shortName": "Bayshore Elementary School",
                "tagline": [
                    "Public School",
                    "Daly City, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bayshore-elementary-school-daly-city-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 379
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.414,37.7054,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.414,37.7054,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94014"
            }
        ]
    },
    {
        "guid": "0dbf10e1-8e99-4355-888d-99fd83e9db4d",
        "content": {
            "centroid": {
                "lat": 37.819225,
                "lon": -122.201342
            },
            "entity": {
                "abbreviation": "Montera Middle School",
                "alternates": {
                    "nces": "062805004303"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0dbf10e1-8e99-4355-888d-99fd83e9db4d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Montera Middle School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Montera Middle School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "montera-middle-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 727
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Montera is a pretty average middle school. While there are pluses, there are a few cons, but none are too major. The girls basketball team got the boys team hand-me-downs, and lots of teachers have left. The workload is pretty light, almost too light, considering we only had homework in a few classes on special occasions, leaving the students behind in high school. On the plus side, Montera has a woodshop course that not many middle schools offer, and the teacher is phenomenal. The library is awesome, and lots of the teachers really have a passion for teaching. The campus is large, and typically pretty clean. The food could be better, but its free, so everyone can get it. There are many field trips that you can go on, such as the 6th grade camping trip, museum trips, and the 8th grade DC trip.  Overall, I would recommend Montera!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-22T19:03:36.469811Z",
                "guid": "22cf2864-ba81-442e-b5a0-88134a11c5cc",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.203,37.8203,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.203,37.8203,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "506c1ff7-ceac-43ac-9ded-f34685b86cb6",
        "content": {
            "centroid": {
                "lat": 37.661752,
                "lon": -122.433706
            },
            "entity": {
                "abbreviation": "Parkway Heights Middle School",
                "alternates": {
                    "nces": "063753006348"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "506c1ff7-ceac-43ac-9ded-f34685b86cb6",
                "isClaimed": false,
                "isPremium": false,
                "location": "South San Francisco, CA",
                "name": "Parkway Heights Middle School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                    "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Parkway Heights Middle School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "parkway-heights-middle-school-south-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 614
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.428,37.6641,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.428,37.6641,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94080"
            }
        ]
    },
    {
        "guid": "116d8ea6-91ff-4ab9-b09b-cfd1c49b4168",
        "content": {
            "centroid": {
                "lat": 38.000018,
                "lon": -122.25256
            },
            "entity": {
                "abbreviation": "Hercules Middle School",
                "alternates": {
                    "nces": "063255010757"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "116d8ea6-91ff-4ab9-b09b-cfd1c49b4168",
                "isClaimed": true,
                "isPremium": false,
                "location": "Hercules, CA",
                "name": "Hercules Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3a20a2dd-d499-4eba-bdbc-675718ad1219",
                    "zipCode": "2a72918e-7bd7-4ca7-8e12-b2ea6b32d398"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "WCCUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                        "location": "Richmond, CA",
                        "name": "West Contra Costa Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                        },
                        "premium": false,
                        "shortName": "West Contra Costa Unified School District",
                        "tagline": [
                            "School District",
                            "Richmond, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "west-contra-costa-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hercules Middle School",
                "tagline": [
                    "West Contra Costa Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "hercules-middle-school-hercules-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 603
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The principal Ms. Lama is very good.  She cares about student success!  She tries to think of fun themes and activities for kids.  Some of the teachers are really good, some should not be teaching. ",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-10-05T00:03:26.222176Z",
                "guid": "8a0c99c5-9a27-4e6f-99db-27ce23dfdb15",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.253,37.9994,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.253,37.9994,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94547"
            }
        ]
    },
    {
        "guid": "d67c2b07-eefc-4ad7-a75c-6c76592fec58",
        "content": {
            "centroid": {
                "lat": 37.482126,
                "lon": -122.236002
            },
            "entity": {
                "abbreviation": "McKinley Institute Of Technology",
                "alternates": {
                    "nces": "063213004971"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "d67c2b07-eefc-4ad7-a75c-6c76592fec58",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "McKinley Institute Of Technology",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a5dafe95-b00a-4d53-8912-9559cbb65dd9",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "7d773137-7242-4cc9-91c9-d3770c1b9f33"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "McKinley Institute Of Technology",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "mckinley-institute-of-technology-redwood-city-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 384
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Teachers care about the students. I get phone calls directly from a teacher when my student does well. My daughter has built confidence in math since attending. This is a really nice place to learn.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-21T02:40:43.833557Z",
                "guid": "9f010030-c153-4ece-86ad-a2189e15cb1e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.4808,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.4808,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "e1331afe-98b1-4e56-b1c6-8c2e7abc1348",
        "content": {
            "centroid": {
                "lat": 37.985511,
                "lon": -122.002948
            },
            "entity": {
                "abbreviation": "Sunrise (Special Education)",
                "alternates": {
                    "nces": "062637003966"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e1331afe-98b1-4e56-b1c6-8c2e7abc1348",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Sunrise (Special Education)",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sunrise (Special Education)",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "sunrise-special-education-concord-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 30
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 6
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.003,37.9853,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.003,37.9853,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94519"
            }
        ]
    },
    {
        "guid": "447c6958-1a2c-4bfc-bfdc-e62e5689e129",
        "content": {
            "centroid": {
                "lat": 37.352865,
                "lon": -121.901384
            },
            "entity": {
                "abbreviation": "Peter Burnett Middle School",
                "alternates": {
                    "nces": "063459005731"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "447c6958-1a2c-4bfc-bfdc-e62e5689e129",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Peter Burnett Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Peter Burnett Middle School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "peter-burnett-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 687
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "It was a big change for me going to a completely new middle school with new students. Campus is big, but everyone was kind. I never had any problem with anyone and as every school that I've been to, each class level stayed with their class level. All the teacher had passion in teaching. I was given opportunities that I am grateful for having then, now. There were many programs, school events, and just having teachers that actually want to teach.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-04-28T07:29:23.897708Z",
                "guid": "420a5132-ea13-4df5-bf0d-8f5837a50e41",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.9,37.3533,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.9,37.3533,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.444444444444444,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95112"
            }
        ]
    },
    {
        "guid": "370a11e5-cbb9-41a4-a2a5-c09e1455d1f3",
        "content": {
            "centroid": {
                "lat": 37.939257,
                "lon": -122.315129
            },
            "entity": {
                "abbreviation": "Mira Vista Elementary School",
                "alternates": {
                    "nces": "063255005044"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "370a11e5-cbb9-41a4-a2a5-c09e1455d1f3",
                "isClaimed": true,
                "isPremium": false,
                "location": "Richmond, CA",
                "name": "Mira Vista Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa3f9a4f-3dbd-435e-95a3-5ab9cf4b4cdc",
                    "zipCode": "8a71e4b9-2a9d-40de-af8a-2e1b3084ed90"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "WCCUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                        "location": "Richmond, CA",
                        "name": "West Contra Costa Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                        },
                        "premium": false,
                        "shortName": "West Contra Costa Unified School District",
                        "tagline": [
                            "School District",
                            "Richmond, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "west-contra-costa-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Mira Vista Elementary School",
                "tagline": [
                    "West Contra Costa Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "mira-vista-elementary-school-richmond-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 566
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Mira vista was my school growing up and my siblings and cousins school, so being in my neighborhood made it feel like a safe place to be. Now my kids attend that school and it is a good school with amazing teachers. The one thing I didn’t like was the addition of middle school classes to the elementary school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-16T10:04:43.528633Z",
                "guid": "1a49e56c-f144-491c-92d1-c448f3bff5e8",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.314,37.9409,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.314,37.9409,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94805"
            }
        ]
    },
    {
        "guid": "d020a7ed-5adc-49d7-adf3-a8340de7e584",
        "content": {
            "centroid": {
                "lat": 38.01339,
                "lon": -122.284903
            },
            "entity": {
                "abbreviation": "O'Hara Park Middle School",
                "alternates": {
                    "nces": "062808009966"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d020a7ed-5adc-49d7-adf3-a8340de7e584",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakley, CA",
                "name": "O'Hara Park Middle School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                    "schoolDistrict": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3a20a2dd-d499-4eba-bdbc-675718ad1219",
                    "zipCode": "2a72918e-7bd7-4ca7-8e12-b2ea6b32d398"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oakley Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                        "location": "Oakley, CA",
                        "name": "Oakley Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6ee6365f-3e69-4aad-a8df-5cd72ae38991",
                            "zipCode": "4e62bbb5-5683-41cb-a3ca-4f5f14325035"
                        },
                        "premium": false,
                        "shortName": "Oakley Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Oakley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakley-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "O'Hara Park Middle School",
                "tagline": [
                    "Oakley Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "ohara-park-middle-school-oakley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 813
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.712,37.9885,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.712,37.9885,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94561"
            }
        ]
    },
    {
        "guid": "e854f6f2-5c7e-4922-b976-5732c5b54446",
        "content": {
            "centroid": {
                "lat": 37.32589,
                "lon": -121.84688
            },
            "entity": {
                "abbreviation": "Bridges Academy",
                "alternates": {
                    "nces": "061437001643"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "e854f6f2-5c7e-4922-b976-5732c5b54446",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Bridges Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bridges Academy",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "7-8"
                ],
                "type": "School",
                "url": "bridges-academy-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 345
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.3264,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.3264,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "0d44f0dd-c6a2-4f8b-8122-e281c02ee8b1",
        "content": {
            "centroid": {
                "lat": 37.344361,
                "lon": -121.812838
            },
            "entity": {
                "abbreviation": "Adelante Dual Language Academy",
                "alternates": {
                    "nces": "060231011793"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0d44f0dd-c6a2-4f8b-8122-e281c02ee8b1",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Adelante Dual Language Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "schoolDistrict": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Alum Rock Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7be9062e-b348-4a13-93fa-470342dbb551",
                        "location": "San Jose, CA",
                        "name": "Alum Rock Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                        },
                        "premium": false,
                        "shortName": "Alum Rock Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alum-rock-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Adelante Dual Language Academy",
                "tagline": [
                    "Alum Rock Union Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "adelante-dual-language-academy-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 528
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I love the small scool atmosphere.  Everyone generally gets along.  We feel like a family in a small community.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-08-17T17:45:11Z",
                "guid": "3d92bb39-dc44-466a-be7c-913fd0ddd775",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.813,37.3455,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.813,37.3455,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.25,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95127"
            }
        ]
    },
    {
        "guid": "42fd2048-f5b3-41e2-9b17-5f767e05e985",
        "content": {
            "centroid": {
                "lat": 37.2615515,
                "lon": -121.8653065
            },
            "entity": {
                "abbreviation": "Liberty High (Alternative)",
                "alternates": {
                    "nces": "063459006645"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "42fd2048-f5b3-41e2-9b17-5f767e05e985",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Liberty High (Alternative)",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "bcda0e04-4247-440c-ae7f-42454876c867"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Liberty High (Alternative)",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "liberty-high-alternative-san-jose-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 334
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8653065,37.2615515,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8653065,37.2615515,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95136"
            }
        ]
    },
    {
        "guid": "ff13626f-1c78-4445-a37a-1f04543de623",
        "content": {
            "centroid": {
                "lat": 37.346373,
                "lon": -121.800369
            },
            "entity": {
                "abbreviation": "August Boeger Middle School",
                "alternates": {
                    "nces": "062640003987"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ff13626f-1c78-4445-a37a-1f04543de623",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "August Boeger Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "38cfcf1b-a32e-456f-b4cf-1cf2085f28b4",
                    "schoolDistrict": "38cfcf1b-a32e-456f-b4cf-1cf2085f28b4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mt. Pleasant Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "38cfcf1b-a32e-456f-b4cf-1cf2085f28b4",
                        "location": "San Jose, CA",
                        "name": "Mount Pleasant Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Mt. Pleasant Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mount-pleasant-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "August Boeger Middle School",
                "tagline": [
                    "Mount Pleasant Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "august-boeger-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 556
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8,37.3477,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8,37.3477,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "333ec710-12a7-4095-8a6d-d4f9bd10a2c7",
        "content": {
            "centroid": {
                "lat": 37.372687,
                "lon": -121.841846
            },
            "entity": {
                "abbreviation": "William Sheppard Middle School",
                "alternates": {
                    "nces": "060231000122"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "333ec710-12a7-4095-8a6d-d4f9bd10a2c7",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "William Sheppard Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                    "parent": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "schoolDistrict": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e3304317-8ec0-4ec2-a735-ae02c9650a81"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Alum Rock Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7be9062e-b348-4a13-93fa-470342dbb551",
                        "location": "San Jose, CA",
                        "name": "Alum Rock Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                        },
                        "premium": false,
                        "shortName": "Alum Rock Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alum-rock-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "William Sheppard Middle School",
                "tagline": [
                    "Alum Rock Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "william-sheppard-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 601
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "William Sheppard Middle School is a great middle school filled with lots of caring staff. The school offers a citizens schools program which help both parents and students in many ways.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-01T18:37:26.508555Z",
                "guid": "aaa1c709-1fe6-4e31-b4a7-78b040f60da3",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.843,37.3745,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.843,37.3745,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95133"
            }
        ]
    },
    {
        "guid": "9acd1902-33aa-45c3-9fc5-d2243a797c1a",
        "content": {
            "centroid": {
                "lat": 37.775689,
                "lon": -122.212961
            },
            "entity": {
                "abbreviation": "Oakland Charter Academy",
                "alternates": {
                    "nces": "060220802893"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "9acd1902-33aa-45c3-9fc5-d2243a797c1a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Oakland Charter Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "90731d14-c187-4264-ac2c-4b3485508684"
                },
                "premium": false,
                "shortName": "Oakland Charter Academy",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "oakland-charter-academy-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 235
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.213,37.7751,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.213,37.7751,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94601"
            }
        ]
    },
    {
        "guid": "6821ce06-4d68-4667-9eec-880a309e9e18",
        "content": {
            "centroid": {
                "lat": 37.341896,
                "lon": -121.816887
            },
            "entity": {
                "abbreviation": "Ocala Middle School",
                "alternates": {
                    "nces": "060231000116"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6821ce06-4d68-4667-9eec-880a309e9e18",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Ocala Middle School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "schoolDistrict": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Alum Rock Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7be9062e-b348-4a13-93fa-470342dbb551",
                        "location": "San Jose, CA",
                        "name": "Alum Rock Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                        },
                        "premium": false,
                        "shortName": "Alum Rock Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alum-rock-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ocala Middle School",
                "tagline": [
                    "Alum Rock Union Elementary School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "ocala-middle-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 449
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.814,37.3414,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.814,37.3414,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "1da8786e-b93e-43d6-a9be-bc7c0b10dfed",
        "content": {
            "centroid": {
                "lat": 37.735877,
                "lon": -122.400007
            },
            "entity": {
                "abbreviation": "Willie L. Brown Jr. Elementary School",
                "alternates": {
                    "nces": "063441002774"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1da8786e-b93e-43d6-a9be-bc7c0b10dfed",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Willie L. Brown Jr. Middle School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "757dac71-31a9-4872-b87c-a3225dec3630",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "6d9673ef-a1db-480d-bcce-79e87b94f91c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Willie L. Brown Jr. Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "6-8"
                ],
                "type": "School",
                "url": "willie-l-brown-jr-middle-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 226
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Willie Brown was a great school that gave everyone a chance. The teachers deal with a lot because they love their students and genuinely care about them.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-21T18:29:09.825535Z",
                "guid": "975d3a1b-7c7a-4959-8eee-47e8c71809e8",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4,37.736,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4,37.736,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "84a69895-7853-4957-be2d-f51cfc357955",
        "content": {
            "centroid": {
                "lat": 38.016987,
                "lon": -121.816125
            },
            "entity": {
                "abbreviation": "Prospects High (Alternative)",
                "alternates": {
                    "ceeb": "050112",
                    "nces": "060285009517"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "84a69895-7853-4957-be2d-f51cfc357955",
                "isClaimed": false,
                "isPremium": false,
                "location": "Antioch, CA",
                "name": "Prospects High (Alternative)",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "007aef6d-07b5-49c0-aac8-9cadec204a65",
                    "schoolDistrict": "007aef6d-07b5-49c0-aac8-9cadec204a65",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Antioch Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "007aef6d-07b5-49c0-aac8-9cadec204a65",
                        "location": "Antioch, CA",
                        "name": "Antioch Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                            "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                        },
                        "premium": false,
                        "shortName": "Antioch Unified School District",
                        "tagline": [
                            "School District",
                            "Antioch, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "antioch-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Prospects High (Alternative)",
                "tagline": [
                    "Antioch Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "prospects-high-alternative-antioch-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 348
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I have mental Health issues which prevented me from going to a regular school. By only going to school for once a week, I was able to graduate high school. They were really understanding and they helped me with anything I needed.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-08T20:45:32.868424Z",
                "guid": "5d2bc8a1-95e1-4cea-86fc-660bd9537b6f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.816,38.0171,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.816,38.0171,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6,
                "count": 10
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94509"
            }
        ]
    },
    {
        "guid": "93db9ac2-6690-4132-9506-c73c68c795cb",
        "content": {
            "centroid": {
                "lat": 37.345855,
                "lon": -121.799645
            },
            "entity": {
                "abbreviation": "Ida Jew Academies",
                "alternates": {
                    "nces": "062640001486"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "93db9ac2-6690-4132-9506-c73c68c795cb",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Ida Jew Academies",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "38cfcf1b-a32e-456f-b4cf-1cf2085f28b4",
                    "schoolDistrict": "38cfcf1b-a32e-456f-b4cf-1cf2085f28b4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mt. Pleasant Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "38cfcf1b-a32e-456f-b4cf-1cf2085f28b4",
                        "location": "San Jose, CA",
                        "name": "Mount Pleasant Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Mt. Pleasant Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mount-pleasant-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ida Jew Academies",
                "tagline": [
                    "Mount Pleasant Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "ida-jew-academies-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 612
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "My experience with Ida Jew has been a very pleasant one. The teachers for the most part are passionate about what they do and try their best to help out students. There is not that much school spirit though.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T04:32:03.357238Z",
                "guid": "1560c3b3-da58-4630-a18b-887c30b4b6f7",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.799,37.346,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.799,37.346,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "ba5711e3-6fc6-4a07-9d03-c384a1bdfcfe",
        "content": {
            "centroid": {
                "lat": 37.727742,
                "lon": -122.179277
            },
            "entity": {
                "abbreviation": "Madison Park Business & Art Academy",
                "alternates": {
                    "ceeb": "054768",
                    "nces": "062805004278"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ba5711e3-6fc6-4a07-9d03-c384a1bdfcfe",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Madison Park Business & Art Academy",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "2668905c-77a3-478f-a15e-7d1156714023"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Madison Park Business & Art Academy",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "6-12"
                ],
                "type": "School",
                "url": "madison-park-business--and--art-academy-oakland-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 774
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Great school, the teachers really care.  I wish they had more afterschool classes that I was interested in OK. Me and Neighborhood is not good at all and the food is bad but the teachers and the staff there are great. It makes it worth while showing up to school every day because I know people care about me. They take the time to come in on weekends and once school is out on vacation to help me catch up on homework our capstone. They got me interested in things that I would normally not be interested in like protesting and watching sports games after school. Normally I would not be interested in things like that. Also this year I made three friends that I hang out with them every day. The kids want to show up at school every day because they like it here. I wish I would’ve found out about it sooner. I just found out about it last year and now I’m graduating this year. It would’ve been a great experience to be able to have spent all the way from kindergarten until now here.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-03-28T03:56:15.733391Z",
                "guid": "2f9cbc7a-2ebc-4612-a4d5-0cd5b3154815",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.179,37.7272,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.179,37.7272,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.3846153846153846,
                "count": 26
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94603"
            }
        ]
    }
]

export default MIDDLE_SCHOOL_RANKS