exports.MENLO_PARK_LISTINGS = [
		{
			"closePrice": 2035000,
			"daysOnMovoto": 13,
			"id": "897f2d79-66c9-47af-95ff-9a1f4ed6dd27",
			"tnImgPath": "https://pi.movoto.com/p/101/421615683_0_emyayN_p.jpeg",
			"listDate": "2021-12-07 00:00:00",
			"listingAgent": "Jeanne &John DeLario",
			"listPrice": 1900000,
			"lotSize": 2480,
			"sqftTotal": 2310,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421615683",
			"bath": 3,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 43,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1976,
			"zipCode": "94025",
			"path": "menlo-park-ca/1214-sharon-park-dr-menlo-park-ca-94025/pid_bom8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "71886955-3ca8-47b5-a895-0e2d48441424",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-12-07 17:23:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.42548,
				"lng": -122.21748,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1214 Sharon Park Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2035000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 60,
			"id": "eec00ad1-b581-4d33-8c1c-791cdb928881",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867423_0_quuANy_p.jpeg",
			"listDate": "2021-10-20 09:18:23",
			"listingAgent": "Ray Rodriguez",
			"listPrice": 2495000,
			"lotSize": 7100,
			"sqftTotal": 3062,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867423",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 16,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1954,
			"zipCode": "94025",
			"path": "menlo-park-ca/360-waverley-st-menlo-park-ca-94025/pid_6s47w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f5c74c2-cb25-4b87-91ef-96013be8f7c2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-10-20 16:22:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 09:18:23",
			"virtualTourLink": "https://www.youtube.com/embed/94GO5XCU7b8",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.453317,
				"lng": -122.173208,
				"zipcode": "94025",
				"subPremise": "",
				"address": "360 Waverley St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/94GO5XCU7b8"
		},
		{
			"closePrice": 1505000,
			"daysOnMovoto": 54,
			"id": "f0e84afc-8b00-447a-98e9-346a25dec162",
			"tnImgPath": "https://pi.movoto.com/p/12/40971828_0_F6Yzqr_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Sherri McInroy",
			"listPrice": 1499000,
			"lotSize": 1369,
			"sqftTotal": 1318,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971828",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1989,
			"zipCode": "94025",
			"path": "menlo-park-ca/2139-avy-ave-menlo-park-ca-94025/pid_8vk9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1487ba4-7e6c-49a7-ba39-029c6fdd7a2e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-10-21 22:17:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2923136",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.430959,
				"lng": -122.201645,
				"zipcode": "94025",
				"subPremise": "",
				"address": "2139 Avy Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1505000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2923136"
		},
		{
			"closePrice": 3300000,
			"daysOnMovoto": 13,
			"id": "383e760b-8d2d-4757-9e78-ab5cd88214d2",
			"tnImgPath": "https://pi.movoto.com/p/110/421609221_0_IvFrqu_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Scott Rose",
			"listPrice": 2798000,
			"lotSize": 7800,
			"sqftTotal": 1530,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421609221",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 53,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "94025",
			"path": "menlo-park-ca/35-willow-rd-menlo-park-ca-94025-100_80214611/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b36100db-934d-48f3-8b2b-85b0ca923c53",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-11-05 16:51:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=hNioWQqTbE3&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.450747,
				"lng": -122.171166,
				"zipcode": "94025",
				"subPremise": "",
				"address": "35 Willow Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=hNioWQqTbE3&mls=1"
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 50,
			"id": "3e542193-f7f0-4993-ad0a-2b90ca252473",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863493_0_ajqFy6_p.jpeg",
			"listDate": "2021-09-20 16:49:54",
			"listingAgent": "Adam S. Levin",
			"listPrice": 2280000,
			"lotSize": 6316,
			"sqftTotal": 2000,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863493",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1950,
			"zipCode": "94025",
			"path": "menlo-park-ca/575-gilbert-ave-menlo-park-ca-94025/pid_ui67w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "15ed1582-323e-4f19-aaed-07fc1127bc09",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-20 23:52:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 16:49:54",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.459885,
				"lng": -122.160905,
				"zipcode": "94025",
				"subPremise": "",
				"address": "575 Gilbert Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2580000,
			"daysOnMovoto": 14,
			"id": "7eb2c5fc-7ae5-4e78-9e16-6bf1cda24715",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81865351_0_mBN6vj_p.jpeg",
			"listDate": "2021-10-04 10:19:37",
			"listingAgent": "Keri Nicholas",
			"listPrice": 2498000,
			"lotSize": 5624,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81865351",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Parc Agency Corporation",
			"photoCount": 5,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94025",
			"path": "menlo-park-ca/1995-palo-alto-way-menlo-park-ca-94025/pid_beh8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "48466d5b-a897-4e18-9c11-73b3a272dc7c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-19 00:00:00",
			"createdAt": "2021-10-04 17:22:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 10:19:37",
			"virtualTourLink": "https://www.tourfactory.com/2919200",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.428324,
				"lng": -122.192494,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1995 Palo Alto Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2580000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2919200"
		},
		{
			"closePrice": 2800000,
			"daysOnMovoto": 28,
			"id": "bf2cbdf4-c49b-4961-ad7e-ac232311c970",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862979_0_qmn3Qy_p.jpeg",
			"listDate": "2021-09-16 11:36:58",
			"listingAgent": "Robert Taylor",
			"listPrice": 3000000,
			"lotSize": 8198,
			"sqftTotal": 4698,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862979",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Taylor Properties",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94025",
			"path": "menlo-park-ca/1330-mills-st-menlo-park-ca-94025/pid_rr27w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d76404de-d280-4430-974f-c27a25076c0a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-16 18:42:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 11:36:58",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.457575,
				"lng": -122.18496,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1330 Mills St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1100000,
			"daysOnMovoto": 0,
			"id": "09259195-481a-40a0-a57f-3eb6616633e4",
			"tnImgPath": "https://pi.movoto.com/p/102/421575623_0_VmEJZY_p.jpeg",
			"listDate": null,
			"listingAgent": "Asher Bell",
			"listPrice": 1250000,
			"lotSize": 5640,
			"sqftTotal": 1660,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421575623",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty ONE Group Zoom",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1951,
			"zipCode": "94025",
			"path": "menlo-park-ca/608-ivy-dr-menlo-park-ca-94025/pid_ar27w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "dd4cf80a-e3ee-4b06-a29d-76faa8305206",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-07-19 23:04:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-19 23:04:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.475692,
				"lng": -122.158351,
				"zipcode": "94025",
				"subPremise": "",
				"address": "608 Ivy Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4745000,
			"daysOnMovoto": 95,
			"id": "e4901eb0-eab3-49be-bd2d-55cdd30fd9b5",
			"tnImgPath": "https://pi.movoto.com/p/110/421590538_0_qJFeJN_p.jpeg",
			"listDate": "2021-07-04 00:00:00",
			"listingAgent": "Olga Milan-Howells",
			"listPrice": 4739000,
			"lotSize": 0,
			"sqftTotal": 3290,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421590538",
			"bath": 4,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": null,
			"zipCode": "94025",
			"path": "menlo-park-ca/1029-hermosa-way-menlo-park-ca-94025/pid_rfl8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3713ab91-0341-4f53-863c-642afd9d5619",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-09-20 00:06:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-04 00:00:00",
			"virtualTourLink": "https://1029hermosaway.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.442616,
				"lng": -122.1913,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1029 Hermosa Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4745000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://1029hermosaway.com/"
		},
		{
			"closePrice": 2650000,
			"daysOnMovoto": 75,
			"id": "6fdc75a5-5eb1-4ff4-8be8-69aa2a8e53d0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853632_0_FjY6BB_p.jpeg",
			"listDate": "2021-07-15 00:00:00",
			"listingAgent": "Brett Jennings Group",
			"listPrice": 2588888,
			"lotSize": 7501,
			"sqftTotal": 4949,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853632",
			"bath": null,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Real Estate Experts",
			"photoCount": 40,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1902,
			"zipCode": "94025",
			"path": "menlo-park-ca/1106-pine-st-menlo-park-ca-94025/pid_r017w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -210000,
			"propertyId": "4016d9b7-14ce-48c2-bde7-c89ea9375301",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-28 00:00:00",
			"createdAt": "2021-07-15 09:22:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 00:00:00",
			"virtualTourLink": "https://www.aryeo.com/v2/1106-pine-st-menlo-park-ca-94025-1051505/branded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.457543,
				"lng": -122.180259,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1106 Pine St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.aryeo.com/v2/1106-pine-st-menlo-park-ca-94025-1051505/branded"
		},
		{
			"closePrice": 2950000,
			"daysOnMovoto": 25,
			"id": "b174ba01-0730-401b-aa86-6f2cfda48681",
			"tnImgPath": "https://pi.movoto.com/p/12/40963707_0_jEq32j_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Melynda Yun Li",
			"listPrice": 2450000,
			"lotSize": 7000,
			"sqftTotal": 1561,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963707",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Garth Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94025",
			"path": "menlo-park-ca/122-walnut-st-menlo-park-ca-94025-100_80408508/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4da98c48-a7f5-4a11-8966-8aa67fad17bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-08-19 05:16:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.46183,
				"lng": -122.152939,
				"zipcode": "94025",
				"subPremise": "",
				"address": "122 Walnut St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3800000,
			"daysOnMovoto": 26,
			"id": "cc578a5e-523a-498b-9881-6a8ca8f57d12",
			"tnImgPath": "https://pi.movoto.com/p/101/421585828_0_eEV7n3_p.jpeg",
			"listDate": "2021-08-17 00:00:00",
			"listingAgent": "Marshall P Ward",
			"listPrice": 3750000,
			"lotSize": 11892,
			"sqftTotal": 7800,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421585828",
			"bath": 12,
			"bed": 12,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 22,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1987,
			"zipCode": "94025",
			"path": "menlo-park-ca/495-willow-rd-menlo-park-ca-94025/pid_2j47w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "42077ed3-de98-4cea-b963-d629f3f46c34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-12 00:00:00",
			"createdAt": "2021-08-17 18:43:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.461267,
				"lng": -122.160413,
				"zipcode": "94025",
				"subPremise": "",
				"address": "495 Willow Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 0,
			"id": "ee67a41f-c554-4a4e-a15e-7acf116a7dae",
			"tnImgPath": "https://pi.movoto.com/p/102/421581576_0_yer7Em_p.jpeg",
			"listDate": null,
			"listingAgent": "Jonathan Minerick",
			"listPrice": 2297000,
			"lotSize": 4900,
			"sqftTotal": 1820,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421581576",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "HomeCoin.com",
			"photoCount": 60,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1988,
			"zipCode": "94025",
			"path": "menlo-park-ca/1195-laurel-st-menlo-park-ca-94025-100_80813365/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "69324f1a-fb1a-404f-b803-0b453eb302e5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-08 00:00:00",
			"createdAt": "2021-08-04 18:34:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-04 18:34:28",
			"virtualTourLink": "http://www.1195laurelst.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.456873,
				"lng": -122.182057,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1195 Laurel St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.1195laurelst.com/?mls"
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 13,
			"id": "16744513-68cc-405b-a8a2-d6a8490930b5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859059_0_mFzV6r_p.jpeg",
			"listDate": "2021-08-19 08:12:18",
			"listingAgent": "Judy Citron",
			"listPrice": 1689000,
			"lotSize": 6251,
			"sqftTotal": 2273,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859059",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1957,
			"zipCode": "94025",
			"path": "menlo-park-ca/4321-fair-oaks-ave-menlo-park-ca-94025-100_ml81859059/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e3e1b4c4-5e64-4af1-a95c-851a0463d28f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-02 00:00:00",
			"createdAt": "2021-08-19 15:17:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 08:12:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.473943,
				"lng": -122.189857,
				"zipcode": "94025",
				"subPremise": "",
				"address": "4321 Fair Oaks Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2837500,
			"daysOnMovoto": 41,
			"id": "88b73353-c32b-4aee-99bf-57d97763a3c1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81854278_0_2BEBIe_p.jpeg",
			"listDate": "2021-07-19 10:16:15",
			"listingAgent": "Steve Pierce",
			"listPrice": 2988000,
			"lotSize": 6120,
			"sqftTotal": 2975,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81854278",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Zane MacGregor",
			"photoCount": 37,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1948,
			"zipCode": "94025",
			"path": "menlo-park-ca/900-university-dr-menlo-park-ca-94025/pid_c0l8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "34acb0f5-63f4-4f90-80f3-909d9c6a7643",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-30 00:00:00",
			"createdAt": "2021-07-19 17:22:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-19 10:16:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.448439,
				"lng": -122.184207,
				"zipcode": "94025",
				"subPremise": "",
				"address": "900 University Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2837500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 6500000,
			"daysOnMovoto": 62,
			"id": "1292960f-c8d6-4f06-9bdc-3dd4189427ac",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81850830_0_7ziZfY_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Caitlin Darke",
			"listPrice": 4950000,
			"lotSize": 10999,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81850830",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94025",
			"path": "menlo-park-ca/481-middle-ct-menlo-park-ca-94025/pid_iuj8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "45753269-59f3-4931-bb24-3d11e9578187",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-26 00:00:00",
			"createdAt": "2021-06-25 20:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.436591,
				"lng": -122.188817,
				"zipcode": "94025",
				"subPremise": "",
				"address": "481 Middle Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 992000,
			"daysOnMovoto": 98,
			"id": "dc656b8d-3c79-45f1-a010-97c01e8d23eb",
			"tnImgPath": "https://pi.movoto.com/p/12/40950580_0_ZeE7nE_p.jpeg",
			"listDate": "2021-05-19 00:00:00",
			"listingAgent": "Carl Medford",
			"listPrice": 995000,
			"lotSize": 232738,
			"sqftTotal": 1020,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40950580",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Benchmark",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1968,
			"zipCode": "94025",
			"path": "menlo-park-ca/675-sharon-park-dr-138-menlo-park-ca-94025/pid_lpf9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -30000,
			"propertyId": "f7fe7bb7-a0d0-4463-a46f-0372c1b0042b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-25 00:00:00",
			"createdAt": "2021-05-19 23:14:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-19 00:00:00",
			"virtualTourLink": "http://www.675sharonparkdr138.com/?mls=",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.422492,
				"lng": -122.201054,
				"zipcode": "94025",
				"subPremise": "APT 138",
				"address": "675 Sharon Park Dr #138"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 992000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.675sharonparkdr138.com/?mls="
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 117,
			"id": "e89bac50-e36d-4ecf-bd87-fefa40ee187d",
			"tnImgPath": "https://pi.movoto.com/p/110/421542519_0_BqU6YF_p.jpeg",
			"listDate": "2021-04-24 00:00:00",
			"listingAgent": "Scott Graham",
			"listPrice": 1995000,
			"lotSize": 5634,
			"sqftTotal": 1520,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421542519",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Venture Properties",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1961,
			"zipCode": "94025",
			"path": "menlo-park-ca/524-9th-ave-menlo-park-ca-94025/pid_rn37w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -105000,
			"propertyId": "329a3748-dafa-4e7e-8fd1-58e493aa0c92",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-19 00:00:00",
			"createdAt": "2021-05-01 07:21:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-24 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=bkxT4s9kMsT",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.473701,
				"lng": -122.198793,
				"zipcode": "94025",
				"subPremise": "",
				"address": "524 9th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=bkxT4s9kMsT"
		},
		{
			"closePrice": 3010000,
			"daysOnMovoto": 64,
			"id": "2e2384a5-c902-4cd7-b9f6-1c5a4b09a4f2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81847816_0_JaRybr_p.jpeg",
			"listDate": "2021-06-08 10:37:32",
			"listingAgent": "Robert Taylor",
			"listPrice": 3250000,
			"lotSize": 7453,
			"sqftTotal": 4340,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81847816",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Taylor Properties",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1955,
			"zipCode": "94025",
			"path": "menlo-park-ca/783-roble-ave-menlo-park-ca-94025/pid_j8i8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "594cf12c-2452-436d-b5e2-df07364a6ec1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-12 00:00:00",
			"createdAt": "2021-06-08 17:42:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-08 10:37:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.44945,
				"lng": -122.181501,
				"zipcode": "94025",
				"subPremise": "",
				"address": "783 Roble Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3010000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1805000,
			"daysOnMovoto": 65,
			"id": "5e0c60f8-b363-4e2f-b214-75a99b0461e5",
			"tnImgPath": "https://pi.movoto.com/p/12/40951233_0_AueByb_p.jpeg",
			"listDate": "2021-05-23 00:00:00",
			"listingAgent": "Trang Dunlap",
			"listPrice": 1850000,
			"lotSize": 1820,
			"sqftTotal": 2210,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951233",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1972,
			"zipCode": "94025",
			"path": "menlo-park-ca/226-sand-hill-cir-menlo-park-ca-94025/pid_6bl8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f3b6ad27-8408-482c-b79a-d4d4057b3e4d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-27 00:00:00",
			"createdAt": "2021-05-23 05:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-23 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=nYakQz3sxKx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.425152,
				"lng": -122.221552,
				"zipcode": "94025",
				"subPremise": "",
				"address": "226 Sand Hill Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1805000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=nYakQz3sxKx"
		},
		{
			"closePrice": 7600000,
			"daysOnMovoto": 18,
			"id": "57182556-3c32-45f8-a56a-ca8cb0085de8",
			"tnImgPath": "https://pi.movoto.com/p/110/421569374_0_vjuy2j_p.jpeg",
			"listDate": "2021-07-01 00:00:00",
			"listingAgent": "Brandon Yager",
			"listPrice": 6900000,
			"lotSize": 23435,
			"sqftTotal": 4000,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421569374",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Yager Realty of California",
			"photoCount": 53,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "94025",
			"path": "menlo-park-ca/1585-bay-laurel-dr-menlo-park-ca-94025/pid_yxk8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "175d0f7e-5568-4eeb-9e5f-ff29dcbb996d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-19 00:00:00",
			"createdAt": "2021-07-01 19:01:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-01 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/568666594",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.435473,
				"lng": -122.18405,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1585 Bay Laurel Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/568666594"
		},
		{
			"closePrice": 1175000,
			"daysOnMovoto": 0,
			"id": "7dbbe1a2-c623-47a0-b90c-119a58604ac7",
			"tnImgPath": "https://pi.movoto.com/p/102/20055326_0_vIqrQV_p.jpeg",
			"listDate": null,
			"listingAgent": "Consuelo Espinosa",
			"listPrice": 1190000,
			"lotSize": 5640,
			"sqftTotal": 1010,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "20055326",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Grupe Gold",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94025",
			"path": "menlo-park-ca/380-6th-ave-menlo-park-ca-94025/pid_o317w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -110000,
			"propertyId": "f4608c70-6d51-4ffb-9947-91c38dd7436e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-12 00:00:00",
			"createdAt": "2020-09-15 21:24:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-15 21:24:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.470993,
				"lng": -122.203759,
				"zipcode": "94025",
				"subPremise": "",
				"address": "380 6th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2780000,
			"daysOnMovoto": 37,
			"id": "41f49948-dbfb-4f4d-afd1-416a6fe20343",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81846697_0_6bazJN_p.jpeg",
			"listDate": "2021-06-01 14:06:55",
			"listingAgent": "Alex H. Wang",
			"listPrice": 2598000,
			"lotSize": 16862,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81846697",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 12,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94025",
			"path": "menlo-park-ca/1866-valparaiso-ave-menlo-park-ca-94025/pid_2gi8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7a3a94e-abd6-4f77-9cad-29b7cb3c321c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-09 00:00:00",
			"createdAt": "2021-06-01 21:21:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-01 14:06:55",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.438094,
				"lng": -122.203385,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1866 Valparaiso Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 7,
			"id": "f7ee7d73-d293-4177-bc3e-f45d3a79b592",
			"tnImgPath": "https://pi.movoto.com/p/110/421560122_0_3Nme6i_p.jpeg",
			"listDate": "2021-06-04 00:00:00",
			"listingAgent": "Brandon Yager",
			"listPrice": 995000,
			"lotSize": 6555,
			"sqftTotal": 910,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421560122",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Yager Realty of California",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1954,
			"zipCode": "94025",
			"path": "menlo-park-ca/1107-sevier-ave-menlo-park-ca-94025-100_81030017/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "618e24ab-b329-4f02-936e-37b6f8dd84f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-11 00:00:00",
			"createdAt": "2021-06-04 22:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.471834,
				"lng": -122.158858,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1107 Sevier Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1120000,
			"daysOnMovoto": 41,
			"id": "abca4adb-c2dd-47ea-aa60-ba102d59cb3b",
			"tnImgPath": "https://pi.movoto.com/p/101/421537080_0_3eq7fI_p.jpeg",
			"listDate": "2021-04-09 00:00:00",
			"listingAgent": "Chelsea Ialeggio",
			"listPrice": 1095000,
			"lotSize": 2675,
			"sqftTotal": 760,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421537080",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Aalto, Inc",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1957,
			"zipCode": "94025",
			"path": "menlo-park-ca/763-san-benito-ave-menlo-park-ca-94025-100_81310146/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "2966f4e8-4ae7-418d-81bd-c5260776dbb9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-20 00:00:00",
			"createdAt": "2021-04-09 22:18:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.476451,
				"lng": -122.193468,
				"zipcode": "94025",
				"subPremise": "",
				"address": "763 San Benito Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1120000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1728000,
			"daysOnMovoto": 34,
			"id": "4d5cb612-5958-4f57-bb28-175f65397dc9",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-13 00:00:00",
			"listingAgent": "Tom Stynes, ABR, CRS",
			"listPrice": 1595000,
			"lotSize": 6955,
			"sqftTotal": 1396,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81838805",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty-Silicon Valley",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1950,
			"zipCode": "94025",
			"path": "menlo-park-ca/804-14th-ave-menlo-park-ca-94025/pid_nxr7w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "86e3d8e7-240c-4b83-89b1-1f5d1e6da661",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-17 18:30:29",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-13 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2854024",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.476416,
				"lng": -122.191832,
				"zipcode": "94025",
				"subPremise": "",
				"address": "804 14th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1728000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2854024"
		},
		{
			"closePrice": 7100000,
			"daysOnMovoto": 49,
			"id": "09778573-74f8-4b73-8638-c7ea4c1a1231",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-29 00:00:00",
			"listingAgent": "DeLeon Team",
			"listPrice": 6495000,
			"lotSize": 20400,
			"sqftTotal": 6788,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836361",
			"bath": 5.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2005,
			"zipCode": "94025",
			"path": "menlo-park-ca/601-menlo-oaks-drive-menlo-park-ca-94025-100_ml89904584/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4081a621-e922-4fb1-b72d-0c8a556cbb29",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-18 02:30:34",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.466147,
				"lng": -122.168756,
				"zipcode": "94025",
				"subPremise": "",
				"address": "601 Menlo Oaks Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2675000,
			"daysOnMovoto": 60,
			"id": "ecf4a16d-6a2c-4da8-8be5-7a328eee38af",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-18 00:00:00",
			"listingAgent": "Mia Banks",
			"listPrice": 2699000,
			"lotSize": 6700,
			"sqftTotal": 3250,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81834718",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1979,
			"zipCode": "94025",
			"path": "menlo-park-ca/980-lassen-dr-menlo-park-ca-94025/pid_q9h8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -151000,
			"propertyId": "8d918647-a775-4764-8e55-7625fc8eb4bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-04-28 18:46:36",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.427473,
				"lng": -122.21136,
				"zipcode": "94025",
				"subPremise": "",
				"address": "980 Lassen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3211000,
			"daysOnMovoto": 10,
			"id": "8af420c5-1563-42a6-83ed-3aca40242bf4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81842004_0_IZNBz3_p.jpeg",
			"listDate": "2021-05-03 14:36:35",
			"listingAgent": "Patrick Conkin",
			"listPrice": 3000000,
			"lotSize": 10576,
			"sqftTotal": 2774,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81842004",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 27,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1932,
			"zipCode": "94025",
			"path": "menlo-park-ca/985-santa-cruz-ave-menlo-park-ca-94025-100_ml81842004/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6b32c27-36da-47f1-8443-a189a0001eaf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-14 00:00:00",
			"createdAt": "2021-05-03 21:42:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-03 14:36:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.448521,
				"lng": -122.186778,
				"zipcode": "94025",
				"subPremise": "",
				"address": "985 Santa Cruz Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3211000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1440000,
			"daysOnMovoto": 38,
			"id": "ef8288b4-b302-4508-971f-8ec25805dc4a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81837379_0_Nrr3MB_p.jpeg",
			"listDate": "2021-04-05 00:06:07",
			"listingAgent": "Michael Johnston",
			"listPrice": 1499000,
			"lotSize": 6708,
			"sqftTotal": 1909,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81837379",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1958,
			"zipCode": "94025",
			"path": "menlo-park-ca/815-bay-rd-menlo-park-ca-94025/pid_gc47w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "75d3efce-7f96-4452-84f5-3467560849f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-14 00:00:00",
			"createdAt": "2021-04-05 07:12:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-05 00:06:07",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.469747,
				"lng": -122.157764,
				"zipcode": "94025",
				"subPremise": "",
				"address": "815 Bay Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1440000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 143,
			"id": "9ed2c8e3-db88-48da-b313-e8e801ac9980",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81822523_0_7iMn7j_p.jpeg",
			"listDate": "2020-12-07 08:49:20",
			"listingAgent": "Keith Suddjian",
			"listPrice": 2495000,
			"lotSize": 7501,
			"sqftTotal": 3570,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81822523",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 34,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Triplex",
			"yearBuilt": 1948,
			"zipCode": "94025",
			"path": "menlo-park-ca/1161-1163-noel-dr-menlo-park-ca-94025-100_ml81822523/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1a8cdef3-224f-4bd8-9c52-71c3a4935dbd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-30 00:00:00",
			"createdAt": "2020-12-07 16:52:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-07 08:49:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.456361,
				"lng": -122.181767,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1161-1163 Noel Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 52,
			"id": "8f6ba3ef-207d-469d-8b26-ece1cc40c0f3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831395_0_Fi66Ab_p.jpeg",
			"listDate": "2021-02-25 12:13:12",
			"listingAgent": "Adam S. Levin",
			"listPrice": 1800000,
			"lotSize": 6316,
			"sqftTotal": 2000,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831395",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1950,
			"zipCode": "94025",
			"path": "menlo-park-ca/575-577-gilbert-ave-menlo-park-ca-94025-100_ml81831395/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "df30e2da-4b48-481c-b793-2043a2ea887f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-19 00:00:00",
			"createdAt": "2021-02-25 20:17:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 12:13:12",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.459885,
				"lng": -122.160905,
				"zipcode": "94025",
				"subPremise": "",
				"address": "575 577 Gilbert Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4350000,
			"daysOnMovoto": 34,
			"id": "ab161357-4063-4649-b2e0-4c53a6dbf50b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833917_0_vnmv6E_p.jpeg",
			"listDate": "2021-03-12 17:40:15",
			"listingAgent": "Hossein Jalali",
			"listPrice": 4375000,
			"lotSize": 5998,
			"sqftTotal": 2661,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833917",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2021,
			"zipCode": "94025",
			"path": "menlo-park-ca/2140-mills-ave-menlo-park-ca-94025/pid_47l8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6024dbc1-1a5e-4d43-8325-6de5c06a0fc9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-03-13 01:42:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-12 17:40:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.435832,
				"lng": -122.212138,
				"zipcode": "94025",
				"subPremise": "",
				"address": "2140 Mills Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 5850000,
			"daysOnMovoto": 43,
			"id": "42e21976-c76d-4f47-8691-9909965ffef9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832280_0_RQ7jJb_p.jpeg",
			"listDate": "2021-03-03 13:10:50",
			"listingAgent": "Granoski,Weil Assoc.",
			"listPrice": 5798000,
			"lotSize": 7501,
			"sqftTotal": 4086,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832280",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2013,
			"zipCode": "94025",
			"path": "menlo-park-ca/princeton-rd-menlo-park-ca-94025-100_946317/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4cc0ea0b-90ee-4a0e-83b5-54063126ed8e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-03-03 21:17:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 13:10:50",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.443446,
				"lng": -122.17909,
				"zipcode": "94025",
				"subPremise": "",
				"address": "240 Princeton Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3200000,
			"daysOnMovoto": 65,
			"id": "8cb93656-b870-4247-9b67-5b9e8f390789",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829150_0_UZFunu_p.jpeg",
			"listDate": "2021-02-09 19:52:51",
			"listingAgent": "Mini Kalkat",
			"listPrice": 3288000,
			"lotSize": 6743,
			"sqftTotal": 2700,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829150",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2015,
			"zipCode": "94025",
			"path": "menlo-park-ca/2098-oakley-ave-menlo-park-ca-94025-100_80231795/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -210000,
			"propertyId": "acf6e24c-57df-4a76-af11-aae2231a5f5b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-02-12 17:52:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-09 19:52:51",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.433428,
				"lng": -122.204119,
				"zipcode": "94025",
				"subPremise": "",
				"address": "2098 Oakley Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 39,
			"id": "a0a3dbd6-fad7-462b-9025-31067fd14e21",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832863_0_ry2MrJ_p.jpeg",
			"listDate": "2021-03-06 09:35:40",
			"listingAgent": "Art Sharif",
			"listPrice": 2995000,
			"lotSize": 6421,
			"sqftTotal": 2100,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832863",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Sothebys Intl Rlty",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94025",
			"path": "menlo-park-ca/2111-harkins-ave-menlo-park-ca-94025/pid_s7k8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1d3ce848-ffe5-4011-8949-6c61fe3bab3f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-08 20:37:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-06 09:35:40",
			"virtualTourLink": "Https://my.matterport.com/show/?m=BkXq9qxTK9N&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.430946,
				"lng": -122.200816,
				"zipcode": "94025",
				"subPremise": "",
				"address": "2111 Harkins Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=BkXq9qxTK9N&mls=1"
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 42,
			"id": "e2572f74-3508-4302-90b2-b091ed9332f0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832296_0_rmaria_p.jpeg",
			"listDate": "2021-03-03 13:59:08",
			"listingAgent": "Julia Laquer",
			"listPrice": 1698000,
			"lotSize": 45917,
			"sqftTotal": 1356,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832296",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1988,
			"zipCode": "94025",
			"path": "menlo-park-ca/973-menlo-ave-apt-15-menlo-park-ca-94025-100_ml81832296/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13392bf9-6efa-4882-94a7-215d3425102c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-05 05:37:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 13:59:08",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2838030",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.447672,
				"lng": -122.185881,
				"zipcode": "94025",
				"subPremise": "APT 15",
				"address": "973 Menlo Ave #15"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2838030"
		},
		{
			"closePrice": 7280000,
			"daysOnMovoto": 13,
			"id": "261d91de-f234-45a1-9562-1f3fd67588d5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833801_0_yjeAnf_p.jpeg",
			"listDate": "2021-04-01 00:00:00",
			"listingAgent": "Matthew Mills",
			"listPrice": 6400000,
			"lotSize": 6271,
			"sqftTotal": 4001,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833801",
			"bath": 6,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2021,
			"zipCode": "94025",
			"path": "menlo-park-ca/308-arbor-rd-menlo-park-ca-94025/pid_0oi8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1d62052a-94b7-432c-b270-30b55a5f9a66",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-04-01 16:17:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.443019,
				"lng": -122.180481,
				"zipcode": "94025",
				"subPremise": "",
				"address": "308 Arbor Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 5100100,
			"daysOnMovoto": 51,
			"id": "11be8eee-bf10-4c01-9f2a-971e2854cb02",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830796_0_QJZJQf_p.jpeg",
			"listDate": "2021-02-22 00:00:00",
			"listingAgent": "Veronica Kogler",
			"listPrice": 4395000,
			"lotSize": 12258,
			"sqftTotal": 3910,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830796",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1948,
			"zipCode": "94025",
			"path": "menlo-park-ca/1880-oakdell-dr-menlo-park-ca-94025/pid_tfk8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ffddd34f-18b9-4a01-aea4-218b114831bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-02-22 08:22:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.432862,
				"lng": -122.194364,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1880 Oakdell Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5100100,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 8750000,
			"daysOnMovoto": 6,
			"id": "d43e34d2-fc27-4c3f-9d31-8346d06e6a09",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81837584_0_Ey6zEn_p.jpeg",
			"listDate": "2021-04-06 07:37:49",
			"listingAgent": "Judy Citron",
			"listPrice": 9249000,
			"lotSize": 21109,
			"sqftTotal": 4300,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81837584",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1997,
			"zipCode": "94025",
			"path": "menlo-park-ca/1275-santa-cruz-ave-menlo-park-ca-94025-100_81407395/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af884dfd-484d-4cf7-b927-c21becfd911b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-04-06 14:42:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-06 07:37:49",
			"virtualTourLink": "Https://spark.adobe.com/page/SbOuV0c5P89lI/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.443205,
				"lng": -122.190956,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1275 Santa Cruz Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 8750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://spark.adobe.com/page/SbOuV0c5P89lI/"
		},
		{
			"closePrice": 5000000,
			"daysOnMovoto": 12,
			"id": "330f00de-1da8-49db-b38a-f4cd2c18da51",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836650_0_JmF3mR_p.jpeg",
			"listDate": "2021-03-31 08:46:57",
			"listingAgent": "James Steele",
			"listPrice": 4500000,
			"lotSize": 12240,
			"sqftTotal": 3663,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836650",
			"bath": 5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1941,
			"zipCode": "94025",
			"path": "menlo-park-ca/2150-sharon-rd-menlo-park-ca-94025/pid_fyk8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "46362c72-1edd-4a36-ac00-0e8633ebd92e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-03-31 15:52:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-31 08:46:57",
			"virtualTourLink": "https://player.vimeo.com/video/529669952",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.429751,
				"lng": -122.199924,
				"zipcode": "94025",
				"subPremise": "",
				"address": "2150 Sharon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/529669952"
		},
		{
			"closePrice": 2400000,
			"daysOnMovoto": 34,
			"id": "e1457ea5-1411-40fa-b219-3a053f063053",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833030_0_RFmNY6_p.jpeg",
			"listDate": "2021-03-08 10:11:25",
			"listingAgent": "Elyse Barca",
			"listPrice": 2199000,
			"lotSize": 5981,
			"sqftTotal": 1725,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833030",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1977,
			"zipCode": "94025",
			"path": "menlo-park-ca/341-oconnor-st-menlo-park-ca-94025-100_81109870/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e343c380-336c-4ff1-97ce-936d9231f147",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-12 00:00:00",
			"createdAt": "2021-03-08 18:17:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-08 10:11:25",
			"virtualTourLink": "Https://spark.adobe.com/page/V7d70ZXpqeEkN/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.461087,
				"lng": -122.147841,
				"zipcode": "94025",
				"subPremise": "",
				"address": "341 Oconnor St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://spark.adobe.com/page/V7d70ZXpqeEkN/"
		},
		{
			"closePrice": 1608750,
			"daysOnMovoto": 20,
			"id": "a0decd74-9213-4947-a2ad-b6f5a4c52ebe",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834928_0_jQIQyE_p.jpeg",
			"listDate": "2021-03-19 10:33:52",
			"listingAgent": "The reSolve Group",
			"listPrice": 1450000,
			"lotSize": 5998,
			"sqftTotal": 1100,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834928",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1922,
			"zipCode": "94025",
			"path": "menlo-park-ca/546-6th-ave-menlo-park-ca-94025-100_81438476/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "507ef3c0-531c-49e2-9a96-8493047b98ab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-19 17:57:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-19 10:33:52",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.475188,
				"lng": -122.20117,
				"zipcode": "94025",
				"subPremise": "",
				"address": "546 6th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1608750,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1815000,
			"daysOnMovoto": 27,
			"id": "12fadba1-eb14-4eda-9dd9-d397df573408",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833921_0_fjbeA2_p.jpeg",
			"listDate": "2021-03-12 18:10:03",
			"listingAgent": "Alex H. Wang",
			"listPrice": 1598000,
			"lotSize": 4064,
			"sqftTotal": 1520,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833921",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1949,
			"zipCode": "94025",
			"path": "menlo-park-ca/603-palmer-ln-menlo-park-ca-94025-12_28264788/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6f65f825-31bc-4635-8494-8ac0b8fff643",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-15 19:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-12 18:10:03",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.473317,
				"lng": -122.193295,
				"zipcode": "94025",
				"subPremise": "",
				"address": "603 Palmer Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1815000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2850000,
			"daysOnMovoto": 38,
			"id": "f9bb18c2-252e-4501-b336-bcdab164e6f9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831865_0_a2uAMI_p.jpeg",
			"listDate": "2021-03-01 10:13:29",
			"listingAgent": "DeLeon Team",
			"listPrice": 2488000,
			"lotSize": 8024,
			"sqftTotal": 3030,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831865",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2007,
			"zipCode": "94025",
			"path": "menlo-park-ca/500-8th-ave-menlo-park-ca-94025-100_81304635/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "887fc42f-f1cd-43c1-91ed-1bfdc7bede7b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-01 18:17:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-01 10:13:29",
			"virtualTourLink": "https://player.vimeo.com/video/517293530",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.473556,
				"lng": -122.199884,
				"zipcode": "94025",
				"subPremise": "",
				"address": "500 8th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/517293530"
		},
		{
			"closePrice": 3675000,
			"daysOnMovoto": 42,
			"id": "7f009651-56cc-4676-a2cd-69f14a1aa6f6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831347_0_zbfQAB_p.jpeg",
			"listDate": "2021-02-25 09:56:07",
			"listingAgent": "Keri Nicholas",
			"listPrice": 3698000,
			"lotSize": 7701,
			"sqftTotal": 2660,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831347",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Parc Agency Corporation",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2002,
			"zipCode": "94025",
			"path": "menlo-park-ca/2130-camino-a-los-cerros-menlo-park-ca-94025-100_81219129/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0333cf4f-2df6-418d-84ae-e000c511e314",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-02-25 18:02:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-25 09:56:07",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.435709,
				"lng": -122.211065,
				"zipcode": "94025",
				"subPremise": "",
				"address": "2130 Camino A Los Cerros"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3550000,
			"daysOnMovoto": 34,
			"id": "cae21604-16e8-4bc4-aa13-939b4d525a70",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832522_0_VMvZe3_p.jpeg",
			"listDate": "2021-03-04 14:56:55",
			"listingAgent": "Annette Smith",
			"listPrice": 3525000,
			"lotSize": 7697,
			"sqftTotal": 2550,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832522",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2008,
			"zipCode": "94025",
			"path": "menlo-park-ca/442-gilbert-ave-menlo-park-ca-94025-100_81147402/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "14624ef2-0f06-4c3c-82f1-39c64e777c0d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-04 23:07:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-04 14:56:55",
			"virtualTourLink": "Https://player.vimeo.com/video/519752631",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.459941,
				"lng": -122.158444,
				"zipcode": "94025",
				"subPremise": "",
				"address": "442 Gilbert Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://player.vimeo.com/video/519752631"
		},
		{
			"closePrice": 5100000,
			"daysOnMovoto": 74,
			"id": "95acd2f4-40ce-455d-b641-2d4acc744fb7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827026_0_jYAnjM_p.jpeg",
			"listDate": "2021-01-23 22:05:38",
			"listingAgent": "Mary Gilles",
			"listPrice": 4950000,
			"lotSize": 7802,
			"sqftTotal": 4210,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827026",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1991,
			"zipCode": "94025",
			"path": "menlo-park-ca/1068-college-ave-menlo-park-ca-94025/pid_n6h8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -439000,
			"propertyId": "de3af825-47a2-4543-acff-e00f53b36860",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-02-02 23:52:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-23 22:05:38",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.443972,
				"lng": -122.181412,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1068 College Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2150000,
			"daysOnMovoto": 43,
			"id": "523bad3a-f4d0-40a6-a233-1c7de5139be5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830815_0_rmmZbj_p.jpeg",
			"listDate": "2021-02-22 09:58:16",
			"listingAgent": "DeLeon Team",
			"listPrice": 2188000,
			"lotSize": 3141,
			"sqftTotal": 1780,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830815",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Deleon Realty",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2006,
			"zipCode": "94025",
			"path": "menlo-park-ca/1958-menalto-ave-menlo-park-ca-94025-100_81415207/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "baad9050-fa99-4c23-98ce-0517f7c05c95",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-02-22 18:02:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-22 09:58:16",
			"virtualTourLink": "https://player.vimeo.com/video/514081153",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.460262,
				"lng": -122.152084,
				"zipcode": "94025",
				"subPremise": "",
				"address": "1958 Menalto Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/514081153"
		},
		{
			"closePrice": 1299000,
			"daysOnMovoto": 54,
			"id": "d444162e-2e0f-43d8-8dc1-6ce7652d643f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829371_0_mAZbnf_p.jpeg",
			"listDate": "2021-02-11 09:46:13",
			"listingAgent": "Cindy Liebsch",
			"listPrice": 1299000,
			"lotSize": null,
			"sqftTotal": 1111,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829371",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94025",
			"path": "menlo-park-ca/20-willow-rd-11-menlo-park-ca-94025/pid_xxl9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d3c67c49-682e-4c5d-a691-6f7e08991d2f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-02-11 17:52:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 09:46:13",
			"virtualTourLink": "Https://www.20willow11.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Menlo Park",
				"lat": 37.44931,
				"lng": -122.172647,
				"zipcode": "94025",
				"subPremise": "APT 11",
				"address": "20 Willow Rd #11"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1299000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.20willow11.com"
		}
	]