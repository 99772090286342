exports.BURLINGAME_SCHOOLS = [
		{
			"id": 32319,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e",
			"lat": 37.576172,
			"lon": -122.380188,
			"name": "Hoover Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2220 Summit Dr.",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/32319-Hoover-Elementary/",
				"reviews": "/california/burlingame/32319-Hoover-Elementary/#Reviews",
				"collegeSuccess": "/california/burlingame/32319-Hoover-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "1%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 224,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 1
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Asian",
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 6976,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.582249,
			"lon": -122.346794,
			"name": "Burlingame High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1 Mangini Way",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6976-Burlingame-High-School/",
				"reviews": "/california/burlingame/6976-Burlingame-High-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6976-Burlingame-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "12%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "87%",
				"state_average": "64%"
			},
			"enrollment": 1492,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 17
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6846,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "m",
			"lat": 37.587349,
			"lon": -122.386902,
			"name": "Burlingame Intermediate",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1715 Quesada Way",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6846-Burlingame-Intermediate-School/",
				"reviews": "/california/burlingame/6846-Burlingame-Intermediate-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6846-Burlingame-Intermediate-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1081,
			"parentRating": 3,
			"numReviews": 22,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 7
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6847,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e",
			"lat": 37.58847,
			"lon": -122.38839,
			"name": "Franklin Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2385 Trousdale Dr.",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6847-Franklin-Elementary-School/",
				"reviews": "/california/burlingame/6847-Franklin-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6847-Franklin-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 466,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 9
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 44
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 6848,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e",
			"lat": 37.587963,
			"lon": -122.378319,
			"name": "Lincoln Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1801 Devereux Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6848-Lincoln-Elementary-School/",
				"reviews": "/california/burlingame/6848-Lincoln-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6848-Lincoln-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 457,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 6849,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e",
			"lat": 37.579468,
			"lon": -122.356934,
			"name": "McKinley Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "701 Paloma Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6849-McKinley-Elementary-School/",
				"reviews": "/california/burlingame/6849-McKinley-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6849-McKinley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 537,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Asian",
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32204,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.598133,
			"lon": -122.379372,
			"name": "Design Tech High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1800 Rollins Road",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/32204-Design-Tech-High/",
				"reviews": "/california/burlingame/32204-Design-Tech-High/#Reviews",
				"collegeSuccess": "/california/burlingame/32204-Design-Tech-High/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "78%",
				"state_average": "64%"
			},
			"enrollment": 555,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 30,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6850,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e",
			"lat": 37.579388,
			"lon": -122.369087,
			"name": "Roosevelt Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1151 Vancouver Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6850-Roosevelt-Elementary-School/",
				"reviews": "/california/burlingame/6850-Roosevelt-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6850-Roosevelt-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 359,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 44
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6851,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e",
			"lat": 37.580364,
			"lon": -122.339912,
			"name": "Washington Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "801 Howard Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/6851-Washington-Elementary-School/",
				"reviews": "/california/burlingame/6851-Washington-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/6851-Washington-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "15%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 382,
			"parentRating": 4,
			"numReviews": 16,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 15
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 15162,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.581524,
			"lon": -122.349899,
			"name": "Burlingame Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "525 California Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/15162-Burlingame-Montessori-School/",
				"reviews": "/california/burlingame/15162-Burlingame-Montessori-School/#Reviews",
				"collegeSuccess": "/california/burlingame/15162-Burlingame-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17363,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.5975,
			"lon": -122.366898,
			"name": "The Avalon Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "818 Mahler Road",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/17363-The-Avalon-Academy/",
				"reviews": "/california/burlingame/17363-The-Avalon-Academy/#Reviews",
				"collegeSuccess": "/california/burlingame/17363-The-Avalon-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 18,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 67
				},
				{
					"label": "Two or more races",
					"percentage": 17
				},
				{
					"label": "Asian",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 17415,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.579056,
			"lon": -122.33699,
			"name": "Bridge Point Academy",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "700 Peninsula Ave",
				"street2": null,
				"zip": "94919",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94919",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/17415-Bridge-Point-Academy/",
				"reviews": "/california/burlingame/17415-Bridge-Point-Academy/#Reviews",
				"collegeSuccess": "/california/burlingame/17415-Bridge-Point-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18233,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.585358,
			"lon": -122.371956,
			"name": "Our Lady of Angels Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1341 Cortez Ave",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18233-Our-Lady-Of-Angels-Pre-School/",
				"reviews": "/california/burlingame/18233-Our-Lady-Of-Angels-Pre-School/#Reviews",
				"collegeSuccess": "/california/burlingame/18233-Our-Lady-Of-Angels-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18234,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.580605,
			"lon": -122.358589,
			"name": "Stepping Stone",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1421 Palm Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18234-Stepping-Stone/",
				"reviews": "/california/burlingame/18234-Stepping-Stone/#Reviews",
				"collegeSuccess": "/california/burlingame/18234-Stepping-Stone/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18235,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.576054,
			"lon": -122.346985,
			"name": "United Methodist Co-Op Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1443 Howard Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18235-United-Methodist-Co-Op-Nursery-School/",
				"reviews": "/california/burlingame/18235-United-Methodist-Co-Op-Nursery-School/#Reviews",
				"collegeSuccess": "/california/burlingame/18235-United-Methodist-Co-Op-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18236,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.58601,
			"lon": -122.370445,
			"name": "First Presbyterian Ch Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1500 Easton Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18236-First-Presbyterian-Ch-Nursery-School/",
				"reviews": "/california/burlingame/18236-First-Presbyterian-Ch-Nursery-School/#Reviews",
				"collegeSuccess": "/california/burlingame/18236-First-Presbyterian-Ch-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18238,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.580292,
			"lon": -122.394035,
			"name": "Peninsula Temple Sholom Ps",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1655 Sebastian Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18238-Peninsula-Temple-Sholom-Ps/",
				"reviews": "/california/burlingame/18238-Peninsula-Temple-Sholom-Ps/#Reviews",
				"collegeSuccess": "/california/burlingame/18238-Peninsula-Temple-Sholom-Ps/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18239,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.581413,
			"lon": -122.383606,
			"name": "Morning Glory Montessori",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2750 Adeline Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18239-Morning-Glory-Montessori/",
				"reviews": "/california/burlingame/18239-Morning-Glory-Montessori/#Reviews",
				"collegeSuccess": "/california/burlingame/18239-Morning-Glory-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18241,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.576851,
			"lon": -122.351814,
			"name": "St Paul's Co-Op Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "405 El Camino Real",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18241-St-Pauls-Co-Op-Nursery-School/",
				"reviews": "/california/burlingame/18241-St-Pauls-Co-Op-Nursery-School/#Reviews",
				"collegeSuccess": "/california/burlingame/18241-St-Pauls-Co-Op-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18242,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.569363,
			"lon": -122.361923,
			"name": "North Hillsborough Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "545 Eucalyptus Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18242-North-Hillsborough-Pre-School/",
				"reviews": "/california/burlingame/18242-North-Hillsborough-Pre-School/#Reviews",
				"collegeSuccess": "/california/burlingame/18242-North-Hillsborough-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18244,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.585396,
			"lon": -122.359093,
			"name": "Palcare Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "945 California Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/18244-Palcare-Child-Care-Center/",
				"reviews": "/california/burlingame/18244-Palcare-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/burlingame/18244-Palcare-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22574,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.580002,
			"lon": -122.370003,
			"name": "Gazelle Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 61",
				"street2": null,
				"zip": "94011",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94011",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/22574-Gazelle-Learning-Center/",
				"reviews": "/california/burlingame/22574-Gazelle-Learning-Center/#Reviews",
				"collegeSuccess": "/california/burlingame/22574-Gazelle-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10416,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.56916,
			"lon": -122.362114,
			"name": "Bridge School, The",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "545 Eucalyptus Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/10416-Bridge-School-The/",
				"reviews": "/california/burlingame/10416-Bridge-School-The/#Reviews",
				"collegeSuccess": "/california/burlingame/10416-Bridge-School-The/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 9,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 11283,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.56916,
			"lon": -122.362114,
			"name": "The Bridge School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "545 Eucalyptus Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/11283-The-Bridge-School/",
				"reviews": "/california/burlingame/11283-The-Bridge-School/#Reviews",
				"collegeSuccess": "/california/burlingame/11283-The-Bridge-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 13,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 73
				},
				{
					"label": "Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 24963,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.579056,
			"lon": -122.33699,
			"name": "Papillon Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "700 Peninsula Ave",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/24963-Papillon-Preschool/",
				"reviews": "/california/burlingame/24963-Papillon-Preschool/#Reviews",
				"collegeSuccess": "/california/burlingame/24963-Papillon-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24968,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.591133,
			"lon": -122.385551,
			"name": "Learning Links Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1764 Marco Polo Way",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/24968-Learning-Links-Pre-School/",
				"reviews": "/california/burlingame/24968-Learning-Links-Pre-School/#Reviews",
				"collegeSuccess": "/california/burlingame/24968-Learning-Links-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25049,
			"districtId": 782,
			"districtName": "Burlingame Elementary",
			"districtCity": "Burlingame",
			"levelCode": "e,m,h",
			"lat": 37.576172,
			"lon": -122.380188,
			"name": "BSD Preschool",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "2220 Summit Dr.",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/25049-Bsd-Preschool/",
				"reviews": "/california/burlingame/25049-Bsd-Preschool/#Reviews",
				"collegeSuccess": "/california/burlingame/25049-Bsd-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Two or more races",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 26306,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.591171,
			"lon": -122.360504,
			"name": "American Advanced Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1338 Old Bayshore Highway",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/26306-American-Advanced-Academy/",
				"reviews": "/california/burlingame/26306-American-Advanced-Academy/#Reviews",
				"collegeSuccess": "/california/burlingame/26306-American-Advanced-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 43,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 58
				},
				{
					"label": "White",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 28588,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.580002,
			"lon": -122.370003,
			"name": "World Learning Academy",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "Po Box 655",
				"street2": null,
				"zip": "94011",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94011",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/28588-World-Learning-Academy/",
				"reviews": "/california/burlingame/28588-World-Learning-Academy/#Reviews",
				"collegeSuccess": "/california/burlingame/28588-World-Learning-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28624,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.591129,
			"lon": -122.349663,
			"name": "Parca Proj Reach Dc-Crestmoor",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "800 Airport Boulevard #320",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/28624-Parca-Proj-Reach-Dc-Crestmoor/",
				"reviews": "/california/burlingame/28624-Parca-Proj-Reach-Dc-Crestmoor/#Reviews",
				"collegeSuccess": "/california/burlingame/28624-Parca-Proj-Reach-Dc-Crestmoor/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29091,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.588081,
			"lon": -122.378471,
			"name": "Childrens Creative Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1801 Devereux Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/29091-Childrens-Creative-Learning-Center/",
				"reviews": "/california/burlingame/29091-Childrens-Creative-Learning-Center/#Reviews",
				"collegeSuccess": "/california/burlingame/29091-Childrens-Creative-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8151,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.575481,
			"lon": -122.345238,
			"name": "St. Catherine Of Siena Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1300 Bayswater Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/8151-St.-Catherine-Of-Siena-Elementary-School/",
				"reviews": "/california/burlingame/8151-St.-Catherine-Of-Siena-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/8151-St.-Catherine-Of-Siena-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 357,
			"parentRating": 4,
			"numReviews": 16,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 56
				},
				{
					"label": "Hispanic",
					"percentage": 18
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8152,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.584877,
			"lon": -122.372574,
			"name": "Our Lady Of Angels Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1328 Cabrillo Avenue",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/8152-Our-Lady-Of-Angels-Elementary-School/",
				"reviews": "/california/burlingame/8152-Our-Lady-Of-Angels-Elementary-School/#Reviews",
				"collegeSuccess": "/california/burlingame/8152-Our-Lady-Of-Angels-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 317,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 91
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Asian",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 8153,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.581505,
			"lon": -122.383415,
			"name": "Mercy High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2750 Adeline Drive",
				"street2": null,
				"zip": "94010",
				"city": "Burlingame"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94010",
			"type": "school",
			"links": {
				"profile": "/california/burlingame/8153-Mercy-High-School/",
				"reviews": "/california/burlingame/8153-Mercy-High-School/#Reviews",
				"collegeSuccess": "/california/burlingame/8153-Mercy-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 362,
			"parentRating": 4,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"percentage": 19
				},
				{
					"label": "Asian",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]