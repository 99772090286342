exports.MOUNTAIN_VIEW_SCHOOLS = [
		{
			"id": 14086,
			"districtId": 649,
			"districtName": "Mountain View-Los Altos Union High School District",
			"districtCity": "Mountain View",
			"levelCode": "e,m,h",
			"lat": 37.398544,
			"lon": -122.075058,
			"name": "Adult Education",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "333 Moffett Boulevard",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/14086-Adult-Education/",
				"reviews": "/california/mountain-view/14086-Adult-Education/#Reviews",
				"collegeSuccess": "/california/mountain-view/14086-Adult-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 5579,
			"districtId": 649,
			"districtName": "Mountain View-Los Altos Union High School District",
			"districtCity": "Mountain View",
			"levelCode": "h",
			"lat": 37.361275,
			"lon": -122.065201,
			"name": "Alta Vista High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1325 Bryant Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5579-Alta-Vista-High-School/",
				"reviews": "/california/mountain-view/5579-Alta-Vista-High-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5579-Alta-Vista-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "46%",
				"state_average": "64%"
			},
			"enrollment": 79,
			"parentRating": 5,
			"numReviews": 9,
			"studentsPerTeacher": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 71
				},
				{
					"label": "Hispanic",
					"percentage": 72
				},
				{
					"label": "White",
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": []
		},
		{
			"id": 31981,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.374702,
			"lon": -122.080536,
			"name": "Baharestan Kids",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1614 Bonita Ave.",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/31981-Baharestan-Kids/",
				"reviews": "/california/mountain-view/31981-Baharestan-Kids/#Reviews",
				"collegeSuccess": "/california/mountain-view/31981-Baharestan-Kids/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 5574,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.379143,
			"lon": -122.081757,
			"name": "Benjamin Bubb Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "525 Hans Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5574-Benjamin-Bubb-Elementary-School/",
				"reviews": "/california/mountain-view/5574-Benjamin-Bubb-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5574-Benjamin-Bubb-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "18%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 575,
			"parentRating": 4,
			"numReviews": 51,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 18
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 37
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 30252,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.393497,
			"lon": -122.095818,
			"name": "Children's Learning Cottage Ps",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "675 Escuela Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/30252-Childrens-Learning-Cottage-Ps/",
				"reviews": "/california/mountain-view/30252-Childrens-Learning-Cottage-Ps/#Reviews",
				"collegeSuccess": "/california/mountain-view/30252-Childrens-Learning-Cottage-Ps/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 12070,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "m",
			"lat": 37.412212,
			"lon": -122.085876,
			"name": "Crittenden Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1701 Rock Street",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/12070-Crittenden-Middle-School/",
				"reviews": "/california/mountain-view/12070-Crittenden-Middle-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/12070-Crittenden-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "42%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 707,
			"parentRating": 4,
			"numReviews": 24,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 42
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 48
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 5577,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.388744,
			"lon": -122.070549,
			"name": "Edith Landels Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "115 West Dana Street",
				"street2": "",
				"zip": "94041",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94041",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5577-Edith-Landels-Elementary-School/",
				"reviews": "/california/mountain-view/5577-Edith-Landels-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5577-Edith-Landels-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "19%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 491,
			"parentRating": 5,
			"numReviews": 51,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 19
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 10862,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.375267,
			"lon": -122.074341,
			"name": "Frank L. Huff Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "253 Martens Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/10862-Frank-L.-Huff-Elementary-School/",
				"reviews": "/california/mountain-view/10862-Frank-L.-Huff-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/10862-Frank-L.-Huff-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 610,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 39
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 31967,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.394897,
			"lon": -122.094139,
			"name": "Gabriela Mistral Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "505 Escuela Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/31967-Gabriela-Mistral-Elementary/",
				"reviews": "/california/mountain-view/31967-Gabriela-Mistral-Elementary/#Reviews",
				"collegeSuccess": "/california/mountain-view/31967-Gabriela-Mistral-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "45%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 373,
			"parentRating": 4,
			"numReviews": 47,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 45
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 60
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 15362,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.39983,
			"lon": -122.067818,
			"name": "German International School of Silicon Valley",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "Mountain View Campus: 310 Easy Street",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/15362-German-International-School-Of-Silicon-Valley/",
				"reviews": "/california/mountain-view/15362-German-International-School-Of-Silicon-Valley/#Reviews",
				"collegeSuccess": "/california/mountain-view/15362-German-International-School-Of-Silicon-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 597,
			"parentRating": 4,
			"numReviews": 41,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 95
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"percentage": 2
				}
			],
			"remediationData": []
		},
		{
			"id": 13363,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m",
			"lat": 37.389999,
			"lon": -122.080002,
			"name": "Girls' Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "Po Box 391000",
				"street2": "",
				"zip": "94039",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94039",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/13363-Girls-Middle-School/",
				"reviews": "/california/mountain-view/13363-Girls-Middle-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/13363-Girls-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 205,
			"parentRating": 4,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 51
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Asian",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 28186,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.422001,
			"lon": -122.083954,
			"name": "Google's Child Care Center-Woods",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1600 Amphitheatre Parkway",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/28186-Googles-Child-Care-Center-Woods/",
				"reviews": "/california/mountain-view/28186-Googles-Child-Care-Center-Woods/#Reviews",
				"collegeSuccess": "/california/mountain-view/28186-Googles-Child-Care-Center-Woods/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20665,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.408573,
			"lon": -122.094971,
			"name": "Hobbledehoy Mont Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2321 Jane Lane",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20665-Hobbledehoy-Mont-Pre-School/",
				"reviews": "/california/mountain-view/20665-Hobbledehoy-Mont-Pre-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/20665-Hobbledehoy-Mont-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 25235,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e,m",
			"lat": 37.409054,
			"lon": -122.098381,
			"name": "Independent Study Program School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "460 Thompson Avenue",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/25235-Independent-Study-Program-School/",
				"reviews": "/california/mountain-view/25235-Independent-Study-Program-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/25235-Independent-Study-Program-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "0%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 86
				},
				{
					"label": "Hispanic",
					"percentage": 14
				}
			],
			"remediationData": []
		},
		{
			"id": 5576,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "m",
			"lat": 37.381794,
			"lon": -122.085999,
			"name": "Isaac Newton Graham Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1175 Castro Street",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5576-Isaac-Newton-Graham-Middle-School/",
				"reviews": "/california/mountain-view/5576-Isaac-Newton-Graham-Middle-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5576-Isaac-Newton-Graham-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "32%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 865,
			"parentRating": 4,
			"numReviews": 69,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 32
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 32356,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.396729,
			"lon": -122.083923,
			"name": "Khan Lab School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1200 Villa Street",
				"street2": "",
				"zip": "94041",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94041",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/32356-Khan-Lab-School/",
				"reviews": "/california/mountain-view/32356-Khan-Lab-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/32356-Khan-Lab-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 48,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20663,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.397087,
			"lon": -122.061432,
			"name": "Kiddie Academy-Mountain View",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "205 East Middlefield Road",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20663-Kiddie-Academy-Mountain-View/",
				"reviews": "/california/mountain-view/20663-Kiddie-Academy-Mountain-View/#Reviews",
				"collegeSuccess": "/california/mountain-view/20663-Kiddie-Academy-Mountain-View/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 27198,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.414646,
			"lon": -122.099297,
			"name": "Let's Play in Spanish",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2483 Old Middlefield Way",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/27198-Lets-Play-In-Spanish/",
				"reviews": "/california/mountain-view/27198-Lets-Play-In-Spanish/#Reviews",
				"collegeSuccess": "/california/mountain-view/27198-Lets-Play-In-Spanish/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20659,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.374428,
			"lon": -122.086121,
			"name": "Little Acorn Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1667 Miramonte Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20659-Little-Acorn-Day-Care/",
				"reviews": "/california/mountain-view/20659-Little-Acorn-Day-Care/#Reviews",
				"collegeSuccess": "/california/mountain-view/20659-Little-Acorn-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 15,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 5575,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.395321,
			"lon": -122.095001,
			"name": "Mariano Castro Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "505 Escuela Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5575-Mariano-Castro-Elementary-School/",
				"reviews": "/california/mountain-view/5575-Mariano-Castro-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5575-Mariano-Castro-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "85%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 268,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 85
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 86
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 12071,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.408195,
			"lon": -122.098068,
			"name": "Monta Loma Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "460 Thompson Avenue",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/12071-Monta-Loma-Elementary-School/",
				"reviews": "/california/mountain-view/12071-Monta-Loma-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/12071-Monta-Loma-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "39%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 425,
			"parentRating": 4,
			"numReviews": 63,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 39
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 9035,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.393814,
			"lon": -122.084717,
			"name": "Mountain View Academy",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "360 South Shoreline Boulevard",
				"street2": "",
				"zip": "94041",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94041",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/9035-Mountain-View-Academy/",
				"reviews": "/california/mountain-view/9035-Mountain-View-Academy/#Reviews",
				"collegeSuccess": "/california/mountain-view/9035-Mountain-View-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 158,
			"parentRating": 5,
			"numReviews": 22,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 44
				},
				{
					"label": "Hispanic",
					"percentage": 32
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": []
		},
		{
			"id": 20669,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.394814,
			"lon": -122.093811,
			"name": "Mountain View Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "505 Escuela Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20669-Mountain-View-Child-Development-Center/",
				"reviews": "/california/mountain-view/20669-Mountain-View-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/20669-Mountain-View-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 5581,
			"districtId": 649,
			"districtName": "Mountain View-Los Altos Union High School District",
			"districtCity": "Mountain View",
			"levelCode": "h",
			"lat": 37.358192,
			"lon": -122.068626,
			"name": "Mountain View High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3535 Truman Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5581-Mountain-View-High-School/",
				"reviews": "/california/mountain-view/5581-Mountain-View-High-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5581-Mountain-View-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "15%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "80%",
				"state_average": "64%"
			},
			"enrollment": 2062,
			"parentRating": 5,
			"numReviews": 27,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": []
		},
		{
			"id": 12669,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.394753,
			"lon": -122.101128,
			"name": "Mountain View Kindercare",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2065 W. El Camino",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/12669-Mountain-View-Kindercare/",
				"reviews": "/california/mountain-view/12669-Mountain-View-Kindercare/#Reviews",
				"collegeSuccess": "/california/mountain-view/12669-Mountain-View-Kindercare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20658,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.359177,
			"lon": -122.067032,
			"name": "Mountain View Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1299 Bryant Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20658-Mountain-View-Nursery-School/",
				"reviews": "/california/mountain-view/20658-Mountain-View-Nursery-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/20658-Mountain-View-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 25236,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e,m",
			"lat": 37.40472,
			"lon": -122.082771,
			"name": "Mvwsd Home & Hospital",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "750 San Pierre Way, Suite A",
				"street2": "Suite A",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/25236-Mvwsd-Home--Hospital/",
				"reviews": "/california/mountain-view/25236-Mvwsd-Home--Hospital/#Reviews",
				"collegeSuccess": "/california/mountain-view/25236-Mvwsd-Home--Hospital/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "0%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 20,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": []
		},
		{
			"id": 9838,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.417103,
			"lon": -122.09819,
			"name": "Palo Alto Preparatory School",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "2462 Wyandotte Street",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/9838-Palo-Alto-Preparatory-School/",
				"reviews": "/california/mountain-view/9838-Palo-Alto-Preparatory-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/9838-Palo-Alto-Preparatory-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 60,
			"parentRating": 4,
			"numReviews": 48,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 97
				},
				{
					"label": "Hispanic",
					"percentage": 3
				}
			],
			"remediationData": []
		},
		{
			"id": 20668,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.369797,
			"lon": -122.073685,
			"name": "Primary Plus",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "333 Eunice Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20668-Primary-Plus/",
				"reviews": "/california/mountain-view/20668-Primary-Plus/#Reviews",
				"collegeSuccess": "/california/mountain-view/20668-Primary-Plus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 31589,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.394753,
			"lon": -122.10112,
			"name": "Quantum Camp",
			"gradeLevels": "1-8",
			"assigned": null,
			"address": {
				"street1": "2065 West El Camino Real",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/31589-Quantum-Camp/",
				"reviews": "/california/mountain-view/31589-Quantum-Camp/#Reviews",
				"collegeSuccess": "/california/mountain-view/31589-Quantum-Camp/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 136,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 27590,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.37999,
			"lon": -122.093719,
			"name": "Sanyu Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1425 South Springer Road",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/27590-Sanyu-Learning-Center/",
				"reviews": "/california/mountain-view/27590-Sanyu-Learning-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/27590-Sanyu-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 30749,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.37999,
			"lon": -122.093719,
			"name": "Sanyu Learning Center",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1425 South Springer Road",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/30749-Sanyu-Learning-Center/",
				"reviews": "/california/mountain-view/30749-Sanyu-Learning-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/30749-Sanyu-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 74,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 84
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 5
				}
			],
			"remediationData": []
		},
		{
			"id": 25222,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "p",
			"lat": 37.393898,
			"lon": -122.061668,
			"name": "Slater Special Education Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "220 North Whisman Road",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/25222-Slater-Special-Education-Preschool/",
				"reviews": "/california/mountain-view/25222-Slater-Special-Education-Preschool/#Reviews",
				"collegeSuccess": "/california/mountain-view/25222-Slater-Special-Education-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 75,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 5521,
			"districtId": 637,
			"districtName": "Los Altos Elementary School District",
			"districtCity": "Los Altos",
			"levelCode": "e",
			"lat": 37.37101,
			"lon": -122.094017,
			"name": "Springer Elementary School",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "1120 Rose Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/5521-Springer-Elementary-School/",
				"reviews": "/california/mountain-view/5521-Springer-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/5521-Springer-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 468,
			"parentRating": 5,
			"numReviews": 38,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 51
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": []
		},
		{
			"id": 15369,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.388817,
			"lon": -122.095467,
			"name": "St Paul Lutheran Child Development Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1075 El Monte Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/15369-St-Paul-Lutheran-Child-Development-Center/",
				"reviews": "/california/mountain-view/15369-St-Paul-Lutheran-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/15369-St-Paul-Lutheran-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 6,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20664,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.372665,
			"lon": -122.078529,
			"name": "St Timothy's Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2094 Grant Road",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20664-St-Timothys-Nursery-School/",
				"reviews": "/california/mountain-view/20664-St-Timothys-Nursery-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/20664-St-Timothys-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 9097,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.368999,
			"lon": -122.084633,
			"name": "St. Francis High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1885 Miramonte Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/9097-St.-Francis-High-School/",
				"reviews": "/california/mountain-view/9097-St.-Francis-High-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/9097-St.-Francis-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1755,
			"parentRating": 5,
			"numReviews": 31,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 49
				},
				{
					"label": "Asian",
					"percentage": 28
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 9095,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.384613,
			"lon": -122.087677,
			"name": "St. Joseph Catholic",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1120 Miramonte Avenue",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/9095-St.-Joseph-Catholic/",
				"reviews": "/california/mountain-view/9095-St.-Joseph-Catholic/#Reviews",
				"collegeSuccess": "/california/mountain-view/9095-St.-Joseph-Catholic/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 200,
			"parentRating": 4,
			"numReviews": 48,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Hispanic",
					"percentage": 23
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": []
		},
		{
			"id": 10537,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.385601,
			"lon": -122.061096,
			"name": "St. Stephen Lutheran School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "320 Moorpark Way",
				"street2": "",
				"zip": "94041",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94041",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/10537-St.-Stephen-Lutheran-School/",
				"reviews": "/california/mountain-view/10537-St.-Stephen-Lutheran-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/10537-St.-Stephen-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 29,
			"parentRating": 5,
			"numReviews": 35,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 72
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"percentage": 3
				}
			],
			"remediationData": []
		},
		{
			"id": 25182,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.404724,
			"lon": -122.082771,
			"name": "Stevenson Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "750b San Pierre Way",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/25182-Stevenson-Elementary-School/",
				"reviews": "/california/mountain-view/25182-Stevenson-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/25182-Stevenson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 427,
			"parentRating": 5,
			"numReviews": 30,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 12072,
			"districtId": 648,
			"districtName": "Mountain View Whisman  School District",
			"districtCity": "Mountain View",
			"levelCode": "e",
			"lat": 37.405888,
			"lon": -122.084969,
			"name": "Theuerkauf Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1625 San Luis Avenue",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/12072-Theuerkauf-Elementary-School/",
				"reviews": "/california/mountain-view/12072-Theuerkauf-Elementary-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/12072-Theuerkauf-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 355,
			"parentRating": 5,
			"numReviews": 36,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 57
				},
				{
					"label": "White",
					"percentage": 17
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": []
		},
		{
			"id": 31592,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.405205,
			"lon": -122.096703,
			"name": "Waldorf School Of The Peninsula",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "180 North Rengstorff Avenue",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/31592-Waldorf-School-Of-The-Peninsula/",
				"reviews": "/california/mountain-view/31592-Waldorf-School-Of-The-Peninsula/#Reviews",
				"collegeSuccess": "/california/mountain-view/31592-Waldorf-School-Of-The-Peninsula/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 140,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20670,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.403046,
			"lon": -122.061142,
			"name": "Walnut Grove Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "84 Murlagan Ave",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20670-Walnut-Grove-Childrens-Center/",
				"reviews": "/california/mountain-view/20670-Walnut-Grove-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/20670-Walnut-Grove-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20671,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.402569,
			"lon": -122.060776,
			"name": "Walnut Grove Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "84 Murlagen Ave",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20671-Walnut-Grove-Childrens-Center/",
				"reviews": "/california/mountain-view/20671-Walnut-Grove-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/20671-Walnut-Grove-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20660,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.373112,
			"lon": -122.087051,
			"name": "Wedgwood Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1710 Miramonte Ave",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20660-Wedgwood-Pre-School/",
				"reviews": "/california/mountain-view/20660-Wedgwood-Pre-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/20660-Wedgwood-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20661,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.373112,
			"lon": -122.087051,
			"name": "Wedgwood School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1710 Miramonte Ave",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20661-Wedgwood-School/",
				"reviews": "/california/mountain-view/20661-Wedgwood-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/20661-Wedgwood-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 12977,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.384914,
			"lon": -122.061974,
			"name": "Western Montessori Day School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "323 Moorpark Way",
				"street2": "",
				"zip": "94041",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94041",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/12977-Western-Montessori-Day-School/",
				"reviews": "/california/mountain-view/12977-Western-Montessori-Day-School/#Reviews",
				"collegeSuccess": "/california/mountain-view/12977-Western-Montessori-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20662,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.395752,
			"lon": -122.09935,
			"name": "Wonder World Pre-School & Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2015 Latham St",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20662-Wonder-World-Pre-School--Child-Care-Center/",
				"reviews": "/california/mountain-view/20662-Wonder-World-Pre-School--Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/mountain-view/20662-Wonder-World-Pre-School--Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 28415,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.395866,
			"lon": -122.099419,
			"name": "Wonder World Pre-School I",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2015 Latham Street",
				"street2": "",
				"zip": "94040",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94040",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/28415-Wonder-World-Pre-School-I/",
				"reviews": "/california/mountain-view/28415-Wonder-World-Pre-School-I/#Reviews",
				"collegeSuccess": "/california/mountain-view/28415-Wonder-World-Pre-School-I/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 30819,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.400013,
			"lon": -122.067764,
			"name": "Yew Chung International School (Sv)",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "310 Easy Street",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/30819-Yew-Chung-International-School-Sv/",
				"reviews": "/california/mountain-view/30819-Yew-Chung-International-School-Sv/#Reviews",
				"collegeSuccess": "/california/mountain-view/30819-Yew-Chung-International-School-Sv/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 233,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 44
				},
				{
					"label": "Two or more races",
					"percentage": 36
				},
				{
					"label": "White",
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 5
				}
			],
			"remediationData": []
		},
		{
			"id": 14920,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.39983,
			"lon": -122.067818,
			"name": "Yew Chung International School - Silicon Valley",
			"gradeLevels": "PK-6",
			"assigned": null,
			"address": {
				"street1": "310 Easy Street",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/14920-Yew-Chung-International-School---Silicon-Valley/",
				"reviews": "/california/mountain-view/14920-Yew-Chung-International-School---Silicon-Valley/#Reviews",
				"collegeSuccess": "/california/mountain-view/14920-Yew-Chung-International-School---Silicon-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 108,
			"parentRating": 4,
			"numReviews": 73,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 27868,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.388374,
			"lon": -122.07132,
			"name": "Ymca Kid's Place-Landels",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "115 West Dana Street",
				"street2": "",
				"zip": "94041",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94041",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/27868-Ymca-Kids-Place-Landels/",
				"reviews": "/california/mountain-view/27868-Ymca-Kids-Place-Landels/#Reviews",
				"collegeSuccess": "/california/mountain-view/27868-Ymca-Kids-Place-Landels/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		},
		{
			"id": 20667,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.393867,
			"lon": -122.061684,
			"name": "YMCA Kid's Place-Slater",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "325 Gladys Ave",
				"street2": "",
				"zip": "94043",
				"city": "Mountain View"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94043",
			"type": "school",
			"links": {
				"profile": "/california/mountain-view/20667-YMCA-Kids-Place-Slater/",
				"reviews": "/california/mountain-view/20667-YMCA-Kids-Place-Slater/#Reviews",
				"collegeSuccess": "/california/mountain-view/20667-YMCA-Kids-Place-Slater/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": []
		}
	]