exports.LAS_VEGAS_LISTINGS = [
	{
		"closePrice": 735000,
		"daysOnMovoto": 67,
		"id": "3b019614-6f5a-497b-89be-f7adb21352d1",
		"tnImgPath": "https://pi.movoto.com/p/321/2313710_0_uznAU7_p.jpeg",
		"listDate": "2021-07-10 00:00:00",
		"listingAgent": "William J Schlaf",
		"listPrice": 720000,
		"lotSize": 10019,
		"sqftTotal": 3049,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2313710",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Signature Real Estate Group",
		"photoCount": 50,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2004,
		"zipCode": "89131",
		"path": "las-vegas-nv/8223-chapelle-ct-las-vegas-nv-89131/pid_hq1oeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "4064b454-b11c-4506-ada1-ff6ecbc77cdc",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 21:09:25",
		"createdAt": "2021-07-10 22:41:16",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-10 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview-elite/las/2313710",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.311242,
			"lng": -115.257411,
			"zipcode": "89131",
			"subPremise": "",
			"address": "8223 Chapelle Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 735000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview-elite/las/2313710"
	},
	{
		"closePrice": 321000,
		"daysOnMovoto": 54,
		"id": "95c9773c-7dd1-47ee-a133-ecfc78b780b3",
		"tnImgPath": "https://pi.movoto.com/p/321/2317311_0_BeFnmm_p.jpeg",
		"listDate": "2021-07-23 00:00:00",
		"listingAgent": "James Dirig",
		"listPrice": 284900,
		"lotSize": 4792,
		"sqftTotal": 1213,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2317311",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Platinum R.E. Professionals",
		"photoCount": 12,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1990,
		"zipCode": "89108",
		"path": "las-vegas-nv/1425-sapphire-springs-cir-las-vegas-nv-89108/pid_kkrreg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "1b6f9515-da1e-4012-9be1-f15b5c3e8e9b",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 21:06:26",
		"createdAt": "2021-07-23 20:46:46",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-23 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2317311",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.18626,
			"lng": -115.232844,
			"zipcode": "89108",
			"subPremise": "",
			"address": "1425 Sapphire Springs Cir"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 321000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2317311"
	},
	{
		"closePrice": 380000,
		"daysOnMovoto": 40,
		"id": "58779af4-ab69-40b7-b7c9-b1ae424bde4c",
		"tnImgPath": "https://pi.movoto.com/p/321/2321661_0_mmrbia_p.jpeg",
		"listDate": "2021-08-06 00:00:00",
		"listingAgent": "Anthony J Knight",
		"listPrice": 374900,
		"lotSize": 4792,
		"sqftTotal": 2316,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2321661",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Platinum R.E. Professionals",
		"photoCount": 30,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2008,
		"zipCode": "89115",
		"path": "las-vegas-nv/4417-mallard-ridge-ave-las-vegas-nv-89115-321_1290492/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "db86b090-138e-4f41-8c5b-70ac04dbc8eb",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 20:51:40",
		"createdAt": "2021-08-07 00:56:28",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-06 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2321661",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.273535,
			"lng": -115.077064,
			"zipcode": "89115",
			"subPremise": "",
			"address": "4417 Mallard Ridge Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 380000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2321661"
	},
	{
		"closePrice": 324900,
		"daysOnMovoto": 1136,
		"id": "3c8b6206-f9f7-47c3-94d6-85d9f55e0427",
		"tnImgPath": "https://pi.movoto.com/p/321/2019895_1_U6yBy7_p.jpeg",
		"listDate": "2018-08-06 00:00:00",
		"listingAgent": "Jamie A Griffin",
		"listPrice": 324900,
		"lotSize": 8712,
		"sqftTotal": 2084,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2019895",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Griffin Group Realty",
		"photoCount": 33,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1969,
		"zipCode": "89142",
		"path": "las-vegas-nv/2434-winterwood-blvd-las-vegas-nv-89142/pid_7gjueg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": 5000,
		"propertyId": "c1df1042-8785-4d5a-86b3-960d9bde1d2f",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 20:42:28",
		"createdAt": "2020-02-07 22:51:23",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2018-08-06 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2019895",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.145761,
			"lng": -115.056839,
			"zipcode": "89142",
			"subPremise": "",
			"address": "2434 Winterwood Blvd"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 324900,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2019895"
	},
	{
		"closePrice": 360000,
		"daysOnMovoto": 40,
		"id": "5a045f47-cbff-471e-872b-2ab0e9a52f3c",
		"tnImgPath": "https://pi.movoto.com/p/321/2321037_0_mIunUu_p.jpeg",
		"listDate": "2021-08-06 00:00:00",
		"listingAgent": "Alejandra Jaime",
		"listPrice": 360000,
		"lotSize": 3049,
		"sqftTotal": 1722,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2321037",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "LIFE Realty District",
		"photoCount": 36,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2016,
		"zipCode": "89122",
		"path": "las-vegas-nv/6401-kellyville-dr-las-vegas-nv-89122-321_2321037/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "f79a8539-3fc8-46f3-a8f9-aa597e25cfe7",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 20:34:03",
		"createdAt": "2021-08-06 17:06:56",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-06 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2321037",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.089378,
			"lng": -115.03286,
			"zipcode": "89122",
			"subPremise": "",
			"address": "6401 Kellyville Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 360000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2321037"
	},
	{
		"closePrice": 340000,
		"daysOnMovoto": 41,
		"id": "2e3886a3-9054-403c-a2c1-b057966b6aa9",
		"tnImgPath": "https://pi.movoto.com/p/321/2321171_0_ZBbmAz_p.jpeg",
		"listDate": "2021-08-05 00:00:00",
		"listingAgent": "Julia A Grambo",
		"listPrice": 325000,
		"lotSize": 7405,
		"sqftTotal": 1472,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2321171",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Cornel Realty LLC",
		"photoCount": 29,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1980,
		"zipCode": "89108",
		"path": "las-vegas-nv/6905-old-castle-dr-las-vegas-nv-89108/pid_ehqqeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "1af44294-7201-43e2-8a03-d049553a9a66",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 20:01:06",
		"createdAt": "2021-08-16 20:14:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-05 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2321171",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.236929,
			"lng": -115.244881,
			"zipcode": "89108",
			"subPremise": "",
			"address": "6905 Old Castle Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 340000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2321171"
	},
	{
		"closePrice": 223000,
		"daysOnMovoto": 23,
		"id": "6feecfac-9278-4572-9c65-c4ebbf432fe4",
		"tnImgPath": "https://pi.movoto.com/p/321/2325002_0_V6iMv7_p.jpeg",
		"listDate": "2021-08-23 00:00:00",
		"listingAgent": "William OKeefe",
		"listPrice": 230000,
		"lotSize": 0,
		"sqftTotal": 1196,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2325002",
		"bath": 2,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Corcoran Global Living",
		"photoCount": 22,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Condominium",
		"yearBuilt": 2006,
		"zipCode": "89131",
		"path": "las-vegas-nv/6868-sky-pointe-dr-1078-las-vegas-nv-89131/pid_ukkpeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "5857edd9-c5b9-4849-bad8-5fa79b376ac2",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:58:28",
		"createdAt": "2021-08-24 13:46:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-23 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2325002",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.286793,
			"lng": -115.273498,
			"zipcode": "89131",
			"subPremise": "APT 1078",
			"address": "6868 Sky Pointe Dr #1078"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 223000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2325002"
	},
	{
		"closePrice": 226000,
		"daysOnMovoto": 115,
		"id": "c306f332-8be3-4f6f-9856-ee83454ee8d0",
		"tnImgPath": "https://pi.movoto.com/p/321/2296343_0_vMEYe2_p.jpeg",
		"listDate": "2021-05-23 00:00:00",
		"listingAgent": "Stephen G Bland",
		"listPrice": 225000,
		"lotSize": 6534,
		"sqftTotal": 1072,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2296343",
		"bath": 1,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Keller Williams VIP",
		"photoCount": 45,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1951,
		"zipCode": "89101",
		"path": "las-vegas-nv/1727-lewis-ave-las-vegas-nv-89101/pid_xbfteg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "6cc72ffc-cebc-4b4d-89b8-7949f2bc16e4",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:54:56",
		"createdAt": "2021-05-25 17:16:38",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-05-23 00:00:00",
		"virtualTourLink": "https://my.matterport.com/show/?m=QFJCiF6p2s1",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.160033,
			"lng": -115.125801,
			"zipcode": "89101",
			"subPremise": "",
			"address": "1727 Lewis Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 226000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://my.matterport.com/show/?m=QFJCiF6p2s1"
	},
	{
		"closePrice": 275000,
		"daysOnMovoto": 45,
		"id": "d366463a-6641-4a26-aa48-08604d230d7f",
		"tnImgPath": "https://pi.movoto.com/p/321/2319888_0_vFfjRJ_p.jpeg",
		"listDate": "2021-08-01 00:00:00",
		"listingAgent": "Karl Workman",
		"listPrice": 260000,
		"lotSize": 1742,
		"sqftTotal": 1318,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319888",
		"bath": 3,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Galindo Group Real Estate",
		"photoCount": 28,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2006,
		"zipCode": "89149",
		"path": "las-vegas-nv/9141-pearl-cotton-ave-las-vegas-nv-89149/pid_7pcpeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "07b1a5b7-c927-429c-89e8-be596acac674",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:54:10",
		"createdAt": "2021-08-01 20:16:35",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-01 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2319888",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.301825,
			"lng": -115.295748,
			"zipcode": "89149",
			"subPremise": "",
			"address": "9141 Pearl Cotton Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 275000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2319888"
	},
	{
		"closePrice": 700000,
		"daysOnMovoto": 70,
		"id": "6502a71c-59eb-4022-a739-644623b60ffa",
		"tnImgPath": "https://pi.movoto.com/p/321/2312579_0_qe6ibF_p.jpeg",
		"listDate": "2021-07-07 00:00:00",
		"listingAgent": "James O'Donnell",
		"listPrice": 700000,
		"lotSize": 21780,
		"sqftTotal": 2392,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2312579",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Keller Williams Market Place",
		"photoCount": 18,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1999,
		"zipCode": "89131",
		"path": "las-vegas-nv/6270-farm-rd-las-vegas-nv-89131/pid_un6oeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -25000,
		"propertyId": "2ac22863-8da0-40a0-b378-3767c0643cda",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:29:11",
		"createdAt": "2021-07-08 04:36:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-07 00:00:00",
		"virtualTourLink": "https://listing.virtuance.com/listing/6270-farm-road-las-vegas-nevada",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.298412,
			"lng": -115.230066,
			"zipcode": "89131",
			"subPremise": "",
			"address": "6270 Farm Rd"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 700000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://listing.virtuance.com/listing/6270-farm-road-las-vegas-nevada"
	},
	{
		"closePrice": 395000,
		"daysOnMovoto": 41,
		"id": "1f9484d8-2096-4556-b165-d9f0bea51f8a",
		"tnImgPath": "https://pi.movoto.com/p/321/2321398_0_6bZ6nN_p.jpeg",
		"listDate": "2021-08-05 00:00:00",
		"listingAgent": "Aristeo J Ramos",
		"listPrice": 395000,
		"lotSize": 3920,
		"sqftTotal": 1677,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2321398",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Evolve Realty",
		"photoCount": 50,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2003,
		"zipCode": "89148",
		"path": "las-vegas-nv/9266-shellmont-ct-las-vegas-nv-89148/pid_reoyeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": 6000,
		"propertyId": "f815022a-aed0-4910-a24d-7c60492fee80",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:28:35",
		"createdAt": "2021-08-06 01:06:30",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-05 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2321398",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.058279,
			"lng": -115.294437,
			"zipcode": "89148",
			"subPremise": "",
			"address": "9266 Shellmont Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 395000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2321398"
	},
	{
		"closePrice": 285000,
		"daysOnMovoto": 25,
		"id": "efcd95fa-ad89-4f3c-a78f-149b48996c93",
		"tnImgPath": "https://pi.movoto.com/p/321/2325963_0_v2mzyJ_p.jpeg",
		"listDate": "2021-08-21 00:00:00",
		"listingAgent": "Maritoni Anz",
		"listPrice": 269900,
		"lotSize": 3485,
		"sqftTotal": 1308,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2325963",
		"bath": 2,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Anz Realty",
		"photoCount": 22,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2005,
		"zipCode": "89122",
		"path": "las-vegas-nv/3309-glacial-lake-st-las-vegas-nv-89122/pid_ocwueg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "e784264c-cf7b-4845-9564-6bb3c46b9f7f",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:25:50",
		"createdAt": "2021-08-21 07:26:09",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-21 00:00:00",
		"virtualTourLink": "https://www.youtube.com/embed/HpPO_0IPkW0",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.125751,
			"lng": -115.034899,
			"zipcode": "89122",
			"subPremise": "",
			"address": "3309 Glacial Lake St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 285000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.youtube.com/embed/HpPO_0IPkW0"
	},
	{
		"closePrice": 357000,
		"daysOnMovoto": 30,
		"id": "623fc563-5f9c-46f4-9580-5ee442a22f36",
		"tnImgPath": "https://pi.movoto.com/p/321/2324530_0_FFBe2z_p.jpeg",
		"listDate": "2021-08-16 00:00:00",
		"listingAgent": "Jazmin A Arely",
		"listPrice": 345000,
		"lotSize": 2614,
		"sqftTotal": 1351,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2324530",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Compass Realty & Management, L",
		"photoCount": 32,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2006,
		"zipCode": "89183",
		"path": "las-vegas-nv/1150-claire-rose-ave-las-vegas-nv-89183/pid_paq0eg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "4ea1d94b-153b-478b-baf8-55332bd0ba11",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:08:43",
		"createdAt": "2021-08-23 07:31:11",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-16 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2324530",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.00177,
			"lng": -115.13823,
			"zipcode": "89183",
			"subPremise": "",
			"address": "1150 Claire Rose Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 357000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2324530"
	},
	{
		"closePrice": 185000,
		"daysOnMovoto": 54,
		"id": "a3e182ba-2059-4f23-96e0-8bd0da58f53a",
		"tnImgPath": "https://pi.movoto.com/p/321/2317713_0_EbJqFB_p.jpeg",
		"listDate": "2021-07-23 00:00:00",
		"listingAgent": "T Zane Weber",
		"listPrice": 185000,
		"lotSize": 0,
		"sqftTotal": 813,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2317713",
		"bath": 1,
		"bed": 1,
		"openHouses": [],
		"officeListName": "BHHS Nevada Properties",
		"photoCount": 22,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Condominium",
		"yearBuilt": 2004,
		"zipCode": "89123",
		"path": "las-vegas-nv/9000-las-vegas-blvd-apt-2256-las-vegas-nv-89123-321_2317713/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -5000,
		"propertyId": "936682ca-48e0-4c47-9ef9-06a6f33d6a21",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:06:34",
		"createdAt": "2021-08-10 07:31:14",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-23 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2317713",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.026362,
			"lng": -115.17066,
			"zipcode": "89123",
			"subPremise": "APT 2256",
			"address": "9000 Las Vegas Blvd #2256"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 185000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2317713"
	},
	{
		"closePrice": 410000,
		"daysOnMovoto": 49,
		"id": "25e1ec17-eca8-4a8c-9a10-5249b6437869",
		"tnImgPath": "https://pi.movoto.com/p/321/2318689_0_ArU7mJ_p.jpeg",
		"listDate": "2021-07-28 00:00:00",
		"listingAgent": "Carmen C Deam",
		"listPrice": 399900,
		"lotSize": 3920,
		"sqftTotal": 2083,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2318689",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Keller Williams Realty Southwe",
		"photoCount": 48,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2007,
		"zipCode": "89131",
		"path": "las-vegas-nv/8028-aztec-basin-ave-las-vegas-nv-89131-321_1340027/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "51e47bd3-0d37-4e7f-b8fb-ae67e18be10d",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:04:55",
		"createdAt": "2021-07-29 07:31:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-28 00:00:00",
		"virtualTourLink": "https://juntolibaophotography.shootproof.com/gallery/15630986/home",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.285383,
			"lng": -115.270863,
			"zipcode": "89131",
			"subPremise": "",
			"address": "8028 Aztec Basin Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 410000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://juntolibaophotography.shootproof.com/gallery/15630986/home"
	},
	{
		"closePrice": 380000,
		"daysOnMovoto": 59,
		"id": "3d0e196e-b7e5-4ec9-af8d-a75c0c4e2fa2",
		"tnImgPath": "https://pi.movoto.com/p/321/2316595_0_aiQVJi_p.jpeg",
		"listDate": "2021-07-18 00:00:00",
		"listingAgent": "Ruth C Petrella",
		"listPrice": 415000,
		"lotSize": 3686,
		"sqftTotal": 1807,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2316595",
		"bath": 2,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Keller Williams Market Place I",
		"photoCount": 36,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Townhouse",
		"yearBuilt": 1996,
		"zipCode": "89134",
		"path": "las-vegas-nv/10025-summer-oak-ln-102-las-vegas-nv-89134/pid_ivcreg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "8e38a877-ff5f-44de-9296-f6480c9f8b2b",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 19:01:34",
		"createdAt": "2021-07-21 19:11:30",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-18 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2316595",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.196146,
			"lng": -115.315567,
			"zipcode": "89134",
			"subPremise": "APT 102",
			"address": "10025 Summer Oak Ln #102"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 380000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2316595"
	},
	{
		"closePrice": 378800,
		"daysOnMovoto": 68,
		"id": "9e4a9568-25da-4b12-9c7b-626b3afe9aed",
		"tnImgPath": "https://pi.movoto.com/p/321/2313741_0_uMJZfI_p.jpeg",
		"listDate": "2021-07-09 00:00:00",
		"listingAgent": "Regalado Ladislao",
		"listPrice": 378000,
		"lotSize": 5227,
		"sqftTotal": 1218,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2313741",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Advent Realty Group LLC",
		"photoCount": 16,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1994,
		"zipCode": "89113",
		"path": "las-vegas-nv/5519-night-swim-ln-las-vegas-nv-89113/pid_qp1xeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "936ea148-82b7-44e2-8028-898bb7cac900",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 18:43:20",
		"createdAt": "2021-07-10 23:36:20",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-09 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2313741",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.088296,
			"lng": -115.267066,
			"zipcode": "89113",
			"subPremise": "",
			"address": "5519 Night Swim Ln"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 378800,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2313741"
	},
	{
		"closePrice": 760000,
		"daysOnMovoto": 47,
		"id": "6a88a6d1-cf17-4a2e-9554-db81b71688c8",
		"tnImgPath": "https://pi.movoto.com/p/321/2319133_0_7MBuJY_p.jpeg",
		"listDate": "2021-07-30 00:00:00",
		"listingAgent": "Joshua S McDougall",
		"listPrice": 740000,
		"lotSize": 10454,
		"sqftTotal": 2979,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319133",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "BHHS Nevada Properties",
		"photoCount": 33,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2015,
		"zipCode": "89179",
		"path": "las-vegas-nv/7774-lovely-squaw-ct-las-vegas-nv-89179-321_2319133/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "4a48b0ce-91a6-40c9-bbe9-6a2dc428965b",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 18:35:35",
		"createdAt": "2021-08-06 07:31:17",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-30 00:00:00",
		"virtualTourLink": "https://my.matterport.com/show/?m=bNviYKow77n&mls=1",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 35.984966,
			"lng": -115.260467,
			"zipcode": "89179",
			"subPremise": "",
			"address": "7774 Lovely Squaw Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 760000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://my.matterport.com/show/?m=bNviYKow77n&mls=1"
	},
	{
		"closePrice": 3010000,
		"daysOnMovoto": 34,
		"id": "2aa59dd4-dccf-4857-a0ae-e40506f49e91",
		"tnImgPath": "https://pi.movoto.com/p/321/2323123_0_VQrMUb_p.jpeg",
		"listDate": "2021-08-12 00:00:00",
		"listingAgent": "Amanda L Romero",
		"listPrice": 2699000,
		"lotSize": 15246,
		"sqftTotal": 3949,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2323123",
		"bath": 4,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Keller Williams Market Place I",
		"photoCount": 50,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2019,
		"zipCode": "89138",
		"path": "las-vegas-nv/11270-sandstone-peak-dr-las-vegas-nv-89138-321_2323123/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "c93c6f39-0c76-4c22-8998-3f87fdde705d",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 18:19:51",
		"createdAt": "2021-08-12 20:56:37",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-12 00:00:00",
		"virtualTourLink": "https://player.vimeo.com/video/84037101",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.217882,
			"lng": -115.343595,
			"zipcode": "89138",
			"subPremise": "",
			"address": "11270 Sandstone Peak Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 3010000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://player.vimeo.com/video/84037101"
	},
	{
		"closePrice": 318000,
		"daysOnMovoto": 33,
		"id": "88ea01af-8410-4c9a-9ddb-89c7334cd7b9",
		"tnImgPath": "https://pi.movoto.com/p/321/2323713_0_2RMrFv_p.jpeg",
		"listDate": "2021-08-13 00:00:00",
		"listingAgent": "Oscar Osorio",
		"listPrice": 310000,
		"lotSize": 3920,
		"sqftTotal": 1134,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2323713",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "SOS Realty Group LLC",
		"photoCount": 30,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1999,
		"zipCode": "89143",
		"path": "las-vegas-nv/8613-grand-pine-ave-las-vegas-nv-89143/pid_tvepeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "3718c18a-dcda-46d8-a58c-7b961f2f820b",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 18:16:30",
		"createdAt": "2021-08-16 17:59:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-13 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2323713",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.305091,
			"lng": -115.283934,
			"zipcode": "89143",
			"subPremise": "",
			"address": "8613 Grand Pine Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 318000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2323713"
	},
	{
		"address": "Undisclosed",
		"closePrice": 525000,
		"daysOnMovoto": 39,
		"id": "ddeec2e4-703a-4134-ac90-15680eb4ff37",
		"tnImgPath": "https://pi.movoto.com/p/321/2322858_0_3aFnnu_p.jpeg",
		"listDate": "2021-08-07 00:00:00",
		"listingAgent": "Michael L Harden",
		"listPrice": 525000,
		"lotSize": 4356,
		"sqftTotal": 3054,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2322858",
		"bath": 4,
		"bed": 5,
		"openHouses": [],
		"officeListName": "eXp Realty",
		"photoCount": 28,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2017,
		"zipCode": "89141",
		"path": "las-vegas-nv/no-address-las-vegas-nv-89141-321_2322858/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "e63553d1-91e9-4ba4-ae75-7c1bb5d30636",
		"visibility": "ALL_ADDRESS_SIGNED_IN",
		"soldDate": "2021-09-15 18:03:47",
		"createdAt": "2021-08-13 04:16:13",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-07 00:00:00",
		"virtualTourLink": "https://tours.virtuance.com/1887454?a=1",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 0,
			"lng": 0,
			"zipcode": "89141",
			"subPremise": "",
			"address": "Undisclosed"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 525000,
		"longitude": 0,
		"latitude": 0,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://tours.virtuance.com/1887454?a=1"
	},
	{
		"closePrice": 622500,
		"daysOnMovoto": 36,
		"id": "bafa4fb2-877a-413a-a237-1c7990399309",
		"tnImgPath": "https://pi.movoto.com/p/321/2322465_0_m6qvQE_p.jpeg",
		"listDate": "2021-08-10 00:00:00",
		"listingAgent": "Marc DiGregorio",
		"listPrice": 649990,
		"lotSize": 6534,
		"sqftTotal": 2934,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2322465",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Keller Williams Market Place I",
		"photoCount": 35,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1997,
		"zipCode": "89128",
		"path": "las-vegas-nv/8404-desert-quail-dr-las-vegas-nv-89128/pid_vnfreg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -9,
		"propertyId": "45bffe49-c20c-49f8-81c4-b23b1d452b63",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:59:43",
		"createdAt": "2021-08-11 07:31:14",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-10 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2322465",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.192069,
			"lng": -115.2776,
			"zipcode": "89128",
			"subPremise": "",
			"address": "8404 Desert Quail Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 622500,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2322465"
	},
	{
		"closePrice": 499500,
		"daysOnMovoto": 41,
		"id": "8e46a96d-fdd3-46ab-ac08-280afa602603",
		"tnImgPath": "https://pi.movoto.com/p/321/2321163_0_jNFFnB_p.jpeg",
		"listDate": "2021-08-05 00:00:00",
		"listingAgent": "Dusty Feazell",
		"listPrice": 519000,
		"lotSize": 6098,
		"sqftTotal": 1794,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2321163",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Alter Luxury",
		"photoCount": 40,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1988,
		"zipCode": "89130",
		"path": "las-vegas-nv/4901-fiesta-lakes-st-las-vegas-nv-89130/pid_8bypeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -4999,
		"propertyId": "33764190-ef9c-49ea-9f95-1df5414eeabb",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:58:44",
		"createdAt": "2021-08-05 23:21:30",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-05 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2321163",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.249058,
			"lng": -115.214783,
			"zipcode": "89130",
			"subPremise": "",
			"address": "4901 Fiesta Lakes St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 499500,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2321163"
	},
	{
		"closePrice": 400000,
		"daysOnMovoto": 33,
		"id": "d052f075-fb37-486f-af81-befb2b4a1975",
		"tnImgPath": "https://pi.movoto.com/p/321/2323894_0_b7ZNQz_p.jpeg",
		"listDate": "2021-08-13 00:00:00",
		"listingAgent": "Leslie Coleman",
		"listPrice": 400000,
		"lotSize": 4356,
		"sqftTotal": 1957,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2323894",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Keller Williams VIP",
		"photoCount": 1,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2000,
		"zipCode": "89117",
		"path": "las-vegas-nv/8061-palace-estate-ave-las-vegas-nv-89117/pid_c42weg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "6c23b636-4d5c-4357-ac49-ee48cf16a0a2",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:56:59",
		"createdAt": "2021-08-16 20:14:03",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-13 00:00:00",
		"virtualTourLink": null,
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.131662,
			"lng": -115.267043,
			"zipcode": "89117",
			"subPremise": "",
			"address": "8061 Palace Estate Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 400000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": ""
	},
	{
		"closePrice": 250000,
		"daysOnMovoto": 44,
		"id": "5b18d13e-5e84-4ea7-9c29-a153dd2900a6",
		"tnImgPath": "https://pi.movoto.com/p/321/2318800_0_FaJyUI_p.jpeg",
		"listDate": "2021-08-02 00:00:00",
		"listingAgent": "Paul S Caruso",
		"listPrice": 263000,
		"lotSize": 3920,
		"sqftTotal": 1192,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2318800",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Keller Williams Market Place I",
		"photoCount": 28,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Townhouse",
		"yearBuilt": 1977,
		"zipCode": "89147",
		"path": "las-vegas-nv/4415-bramblewood-st-las-vegas-nv-89147/pid_12pxeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -6900,
		"propertyId": "3c1d6c7c-9451-494e-834b-d61390d77bba",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:51:55",
		"createdAt": "2021-08-03 07:31:14",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-02 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2318800",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.108639,
			"lng": -115.24391,
			"zipcode": "89147",
			"subPremise": "",
			"address": "4415 Bramblewood St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 250000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2318800"
	},
	{
		"closePrice": 653000,
		"daysOnMovoto": 43,
		"id": "494c748f-34c7-44c3-91e8-7d18be900fc7",
		"tnImgPath": "https://pi.movoto.com/p/321/2319812_0_y32IqN_p.jpeg",
		"listDate": "2021-08-03 00:00:00",
		"listingAgent": "Jessica Johnson",
		"listPrice": 662888,
		"lotSize": 6970,
		"sqftTotal": 4398,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319812",
		"bath": 4,
		"bed": 5,
		"openHouses": [],
		"officeListName": "SellPro Realty",
		"photoCount": 48,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2011,
		"zipCode": "89147",
		"path": "las-vegas-nv/4662-eel-point-st-las-vegas-nv-89147/pid_3omxeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "7d94e251-a106-4465-862b-101315a097d5",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:50:51",
		"createdAt": "2021-08-03 18:41:29",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-03 00:00:00",
		"virtualTourLink": "https://www.tourfactory.com/idxr2902021",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.104114,
			"lng": -115.261483,
			"zipcode": "89147",
			"subPremise": "",
			"address": "4662 Eel Point St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 653000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.tourfactory.com/idxr2902021"
	},
	{
		"closePrice": 830000,
		"daysOnMovoto": 35,
		"id": "eed52168-039c-43ca-ad70-3f69efa812ea",
		"tnImgPath": "https://pi.movoto.com/p/321/2319824_0_3VmEn3_p.jpeg",
		"listDate": "2021-08-11 00:00:00",
		"listingAgent": "Joanne Stucky",
		"listPrice": 799000,
		"lotSize": 20909,
		"sqftTotal": 4037,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319824",
		"bath": 4,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Realty Executives of SNV",
		"photoCount": 49,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2000,
		"zipCode": "89131",
		"path": "las-vegas-nv/7221-shallowford-ave-las-vegas-nv-89131/pid_w98oeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "abcbcf0c-be99-46ab-9730-6cefae9c33fc",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:44:57",
		"createdAt": "2021-08-11 19:46:31",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-11 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2319824",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.297039,
			"lng": -115.252132,
			"zipcode": "89131",
			"subPremise": "",
			"address": "7221 Shallowford Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 830000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2319824"
	},
	{
		"closePrice": 476000,
		"daysOnMovoto": 70,
		"id": "42abf179-0401-4b2e-85d0-e21115051e53",
		"tnImgPath": "https://pi.movoto.com/p/321/2312307_0_ReJ6vM_p.jpeg",
		"listDate": "2021-07-07 00:00:00",
		"listingAgent": "Robert E Jones",
		"listPrice": 474900,
		"lotSize": 3920,
		"sqftTotal": 2178,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2312307",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Offerpad",
		"photoCount": 26,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1999,
		"zipCode": "89129",
		"path": "las-vegas-nv/8537-last-point-ave-las-vegas-nv-89129/pid_rjuqeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "e17b7b99-f057-42a5-8501-e361b886ac33",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:41:43",
		"createdAt": "2021-07-07 19:31:32",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-07 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2312307",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.231765,
			"lng": -115.281096,
			"zipcode": "89129",
			"subPremise": "",
			"address": "8537 Last Point Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 476000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2312307"
	},
	{
		"closePrice": 350000,
		"daysOnMovoto": 31,
		"id": "2f6b11b7-e437-4a46-bfd5-26366a88fc1f",
		"tnImgPath": "https://pi.movoto.com/p/321/2323796_0_J7FEZz_p.jpeg",
		"listDate": "2021-08-15 00:00:00",
		"listingAgent": "Steven D Baird",
		"listPrice": 349900,
		"lotSize": 5227,
		"sqftTotal": 1135,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2323796",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "The Baird Group, LLC",
		"photoCount": 48,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2001,
		"zipCode": "89130",
		"path": "las-vegas-nv/5533-raven-creek-ave-las-vegas-nv-89130/pid_9olpeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "848a134d-bd8f-4c37-b854-1ebe9501ab19",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:36:29",
		"createdAt": "2021-08-16 17:58:13",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-15 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2323796",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.27245,
			"lng": -115.218627,
			"zipcode": "89130",
			"subPremise": "",
			"address": "5533 Raven Creek Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 350000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2323796"
	},
	{
		"closePrice": 249000,
		"daysOnMovoto": 46,
		"id": "7ffac552-ff18-4c8b-82af-f5d4607e2481",
		"tnImgPath": "https://pi.movoto.com/p/321/2319852_0_u2Mrvz_p.jpeg",
		"listDate": "2021-07-31 00:00:00",
		"listingAgent": "Yuliya Nedilko",
		"listPrice": 245000,
		"lotSize": 2178,
		"sqftTotal": 1327,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319852",
		"bath": 2,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Win Win Real Estate",
		"photoCount": 18,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Townhouse",
		"yearBuilt": 1979,
		"zipCode": "89107",
		"path": "las-vegas-nv/6521-sugarpine-ln-las-vegas-nv-89107-321_1357038/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "0d011217-695e-4e2b-a5e1-70a7a68f52ec",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:33:14",
		"createdAt": "2021-08-01 07:26:09",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-31 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2319852",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.180144,
			"lng": -115.236432,
			"zipcode": "89107",
			"subPremise": "",
			"address": "6521 Sugarpine Ln"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 249000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2319852"
	},
	{
		"closePrice": 242500,
		"daysOnMovoto": 181,
		"id": "8ee441d3-69aa-442b-ae97-da0f28091195",
		"tnImgPath": "https://pi.movoto.com/p/321/2279652_0_IJmq3i_p.jpeg",
		"listDate": "2021-03-18 00:00:00",
		"listingAgent": "Yakelin Martinez-Carvajal",
		"listPrice": 250000,
		"lotSize": 7841,
		"sqftTotal": 1008,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2279652",
		"bath": 1,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Executive Realty Services",
		"photoCount": 1,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1972,
		"zipCode": "89121",
		"path": "las-vegas-nv/4864-pancho-villa-dr-las-vegas-nv-89121/pid_8p1ueg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": 20000,
		"propertyId": "1daf0d97-f6e9-4c68-ab55-2bc754eedef3",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:32:35",
		"createdAt": "2021-03-18 16:51:30",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-03-18 00:00:00",
		"virtualTourLink": null,
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.109136,
			"lng": -115.067705,
			"zipcode": "89121",
			"subPremise": "",
			"address": "4864 Pancho Villa Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 242500,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": ""
	},
	{
		"closePrice": 350000,
		"daysOnMovoto": 41,
		"id": "3f3bb9fb-f287-4e9e-b86c-6e604398abf9",
		"tnImgPath": "https://pi.movoto.com/p/321/2320917_0_3JFfQF_p.jpeg",
		"listDate": "2021-08-05 00:00:00",
		"listingAgent": "Imelda Revuelto",
		"listPrice": 355000,
		"lotSize": 5663,
		"sqftTotal": 1782,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2320917",
		"bath": 2,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Rooftop Realty",
		"photoCount": 34,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2019,
		"zipCode": "89115",
		"path": "las-vegas-nv/1876-neoga-st-las-vegas-nv-89115-321_2320917/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "e3c6ef7c-89ea-42e2-9922-b9721c8ef6fe",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:31:08",
		"createdAt": "2021-08-06 07:16:16",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-05 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2320917",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.193441,
			"lng": -115.073369,
			"zipcode": "89115",
			"subPremise": "",
			"address": "1876 Neoga St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 350000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2320917"
	},
	{
		"closePrice": 420000,
		"daysOnMovoto": 27,
		"id": "c70bb88c-1345-440e-8e52-412767228bca",
		"tnImgPath": "https://pi.movoto.com/p/321/2325048_0_j2VfeA_p.jpeg",
		"listDate": "2021-08-19 00:00:00",
		"listingAgent": "Robert W Morganti",
		"listPrice": 429900,
		"lotSize": 3920,
		"sqftTotal": 2311,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2325048",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "LIFE Realty District",
		"photoCount": 36,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2005,
		"zipCode": "89148",
		"path": "las-vegas-nv/9478-w-martin-ave-las-vegas-nv-89148/pid_76ryeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "a67df04d-0846-4124-93ca-da9b8e1e6689",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:07:52",
		"createdAt": "2021-08-19 23:41:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-19 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2325048",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.066806,
			"lng": -115.299717,
			"zipcode": "89148",
			"subPremise": "",
			"address": "9478 W Martin Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 420000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2325048"
	},
	{
		"closePrice": 360000,
		"daysOnMovoto": 53,
		"id": "fe9ae0c7-6a3f-4c02-a244-b78bab915c72",
		"tnImgPath": "https://pi.movoto.com/p/321/2316323_0_yMNVRf_p.jpeg",
		"listDate": "2021-07-24 00:00:00",
		"listingAgent": "Ehren Alessi",
		"listPrice": 349000,
		"lotSize": 2614,
		"sqftTotal": 1649,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2316323",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Resolution Realty",
		"photoCount": 34,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2005,
		"zipCode": "89139",
		"path": "las-vegas-nv/7547-orchard-pine-st-las-vegas-nv-89139/pid_6j4yeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "de329e58-0b87-4cf3-a00d-674f6a3b8dc3",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:02:54",
		"createdAt": "2021-07-24 16:51:22",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-24 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2316323",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.052378,
			"lng": -115.213995,
			"zipcode": "89139",
			"subPremise": "",
			"address": "7547 Orchard Pine St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 360000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2316323"
	},
	{
		"closePrice": 410000,
		"daysOnMovoto": 54,
		"id": "5688e3dc-fe24-4009-b42e-72fecc82d0ea",
		"tnImgPath": "https://pi.movoto.com/p/321/2317454_0_irEumz_p.jpeg",
		"listDate": "2021-07-23 00:00:00",
		"listingAgent": "Christina Ferrer",
		"listPrice": 400000,
		"lotSize": 3485,
		"sqftTotal": 1565,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2317454",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Nationwide Realty LLC",
		"photoCount": 38,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2003,
		"zipCode": "89141",
		"path": "las-vegas-nv/5918-varese-dr-las-vegas-nv-89141/pid_s2qzeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -15000,
		"propertyId": "53a401ef-b9ab-47cb-96d0-c724d9cf5c36",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:01:07",
		"createdAt": "2021-07-23 22:26:38",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-23 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2317454",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 35.986139,
			"lng": -115.223165,
			"zipcode": "89141",
			"subPremise": "",
			"address": "5918 Varese Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 410000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2317454"
	},
	{
		"closePrice": 341000,
		"daysOnMovoto": 51,
		"id": "da338af4-042c-4ff6-a519-7b15a44f1655",
		"tnImgPath": "https://pi.movoto.com/p/321/2318097_0_ZEmzm2_p.jpeg",
		"listDate": "2021-07-26 00:00:00",
		"listingAgent": "Jonathan A Wells",
		"listPrice": 320000,
		"lotSize": 7405,
		"sqftTotal": 1464,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2318097",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "King Realty Group",
		"photoCount": 45,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1973,
		"zipCode": "89120",
		"path": "las-vegas-nv/3969-briarcrest-ct-las-vegas-nv-89120/pid_15gveg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "be360c65-cf29-4e1b-bc10-d756931679a4",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 17:00:16",
		"createdAt": "2021-07-26 19:46:31",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-26 00:00:00",
		"virtualTourLink": null,
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.095691,
			"lng": -115.087675,
			"zipcode": "89120",
			"subPremise": "",
			"address": "3969 Briarcrest Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 341000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": ""
	},
	{
		"closePrice": 245000,
		"daysOnMovoto": 20,
		"id": "2f085e4c-c795-4837-b9d4-ee937a2d86ca",
		"tnImgPath": "https://pi.movoto.com/p/321/2326611_0_QMN6iZ_p.jpeg",
		"listDate": "2021-08-26 00:00:00",
		"listingAgent": "Jennifer L Brockman",
		"listPrice": 245000,
		"lotSize": 0,
		"sqftTotal": 1316,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2326611",
		"bath": 2,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Redfin",
		"photoCount": 40,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Condominium",
		"yearBuilt": 1998,
		"zipCode": "89103",
		"path": "las-vegas-nv/5155-w-tropicana-ave-2075-las-vegas-nv-89103/pid_mrzxeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "45272b90-54bb-4d21-bb1c-061abe26e189",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:56:06",
		"createdAt": "2021-08-26 15:16:16",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-26 00:00:00",
		"virtualTourLink": "https://my.matterport.com/show/?m=T8WhZZtb9LC&mls=1",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.100357,
			"lng": -115.21189,
			"zipcode": "89103",
			"subPremise": "APT 2075",
			"address": "5155 W Tropicana Ave #2075"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 245000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://my.matterport.com/show/?m=T8WhZZtb9LC&mls=1"
	},
	{
		"closePrice": 372000,
		"daysOnMovoto": 47,
		"id": "070406aa-4782-4d49-8a7a-e98d381b9427",
		"tnImgPath": "https://pi.movoto.com/p/321/2318543_0_zVQquJ_p.jpeg",
		"listDate": "2021-07-30 00:00:00",
		"listingAgent": "Steven R Kwasneski",
		"listPrice": 365000,
		"lotSize": 4356,
		"sqftTotal": 1564,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2318543",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Coldwell Banker Premier",
		"photoCount": 18,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2003,
		"zipCode": "89129",
		"path": "las-vegas-nv/3962-crystal-trip-ct-las-vegas-nv-89129-321_1430429/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "889ba1c4-412b-4c18-99e9-17c6746b7e00",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:50:48",
		"createdAt": "2021-07-30 15:26:47",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-30 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2318543",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.233259,
			"lng": -115.284374,
			"zipcode": "89129",
			"subPremise": "",
			"address": "3962 Crystal Trip Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 372000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2318543"
	},
	{
		"closePrice": 335000,
		"daysOnMovoto": 37,
		"id": "af7f2f22-4916-426f-80d3-b1cdee3bddb2",
		"tnImgPath": "https://pi.movoto.com/p/321/2323900_0_yEijUE_p.jpeg",
		"listDate": "2021-08-09 00:00:00",
		"listingAgent": "Charmaine Prospero",
		"listPrice": 324900,
		"lotSize": 2614,
		"sqftTotal": 1431,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2323900",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "BHHS Nevada Properties",
		"photoCount": 24,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2000,
		"zipCode": "89118",
		"path": "las-vegas-nv/5480-overland-express-st-las-vegas-nv-89118/pid_xgwxeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "096f00f3-912c-4ae1-bfc9-1fa72a967b9b",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:44:08",
		"createdAt": "2021-08-16 20:14:03",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-09 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2323900",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.089883,
			"lng": -115.219229,
			"zipcode": "89118",
			"subPremise": "",
			"address": "5480 Overland Express St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 335000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2323900"
	},
	{
		"closePrice": 295000,
		"daysOnMovoto": 90,
		"id": "0efd8e72-8ddd-4806-a1c9-3afde5ad87f4",
		"tnImgPath": "https://pi.movoto.com/p/321/2305946_0_YBj27F_p.jpeg",
		"listDate": "2021-06-17 00:00:00",
		"listingAgent": "Kaitlyn Elo",
		"listPrice": 294989,
		"lotSize": 6534,
		"sqftTotal": 1260,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2305946",
		"bath": 2,
		"bed": 4,
		"openHouses": [],
		"officeListName": "eXp Realty",
		"photoCount": 24,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1971,
		"zipCode": "89115",
		"path": "las-vegas-nv/2584-san-marcos-st-las-vegas-nv-89115/pid_f2oteg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -5000,
		"propertyId": "95bfeb33-e786-46bf-9df6-a42457c8bd1e",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:35:29",
		"createdAt": "2021-06-17 21:51:25",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-06-17 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2305946",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.206381,
			"lng": -115.091844,
			"zipcode": "89115",
			"subPremise": "",
			"address": "2584 San Marcos St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 295000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2305946"
	},
	{
		"closePrice": 363000,
		"daysOnMovoto": 57,
		"id": "46d375a4-6b50-4a87-8667-fdd4be6584db",
		"tnImgPath": "https://pi.movoto.com/p/321/2316504_0_bra7Ar_p.jpeg",
		"listDate": "2021-07-20 00:00:00",
		"listingAgent": "Freddy R Aldana",
		"listPrice": 355000,
		"lotSize": 3920,
		"sqftTotal": 1244,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2316504",
		"bath": 2,
		"bed": 3,
		"openHouses": [],
		"officeListName": "The Collective Real Estate Adv",
		"photoCount": 34,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2000,
		"zipCode": "89117",
		"path": "las-vegas-nv/7881-palace-monaco-ave-las-vegas-nv-89117-321_1288898/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "08039329-70a6-4805-a865-dc076091060d",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:35:21",
		"createdAt": "2021-07-20 19:26:43",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-20 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2316504",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.129506,
			"lng": -115.262574,
			"zipcode": "89117",
			"subPremise": "",
			"address": "7881 Palace Monaco Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 363000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2316504"
	},
	{
		"closePrice": 750000,
		"daysOnMovoto": 83,
		"id": "a72852a3-49a1-424c-a92f-673a3f5d7bcb",
		"tnImgPath": "https://pi.movoto.com/p/321/2306856_0_ZE3y6A_p.jpeg",
		"listDate": "2021-06-24 00:00:00",
		"listingAgent": "Nancy J Cokinda",
		"listPrice": 750000,
		"lotSize": 10019,
		"sqftTotal": 3018,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2306856",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Elite Realty",
		"photoCount": 38,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2019,
		"zipCode": "89131",
		"path": "las-vegas-nv/7035-noble-flair-ct-las-vegas-nv-89131-321_2306856/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "df6b08d3-3994-44d9-8b4b-cd91ab68da4f",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:32:49",
		"createdAt": "2021-06-24 16:01:20",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-06-24 00:00:00",
		"virtualTourLink": "https://www.tourfactory.com/2879991",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.289074,
			"lng": -115.248347,
			"zipcode": "89131",
			"subPremise": "",
			"address": "7035 Noble Flair Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 750000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.tourfactory.com/2879991"
	},
	{
		"closePrice": 420000,
		"daysOnMovoto": 41,
		"id": "5fb38c11-9f9d-4778-9394-a71efe2f0f6d",
		"tnImgPath": "https://pi.movoto.com/p/321/2321257_0_aRenRn_p.jpeg",
		"listDate": "2021-08-05 00:00:00",
		"listingAgent": "Stan Hicks",
		"listPrice": 419900,
		"lotSize": 4356,
		"sqftTotal": 2190,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2321257",
		"bath": 3,
		"bed": 3,
		"openHouses": [],
		"officeListName": "Coldwell Banker Premier",
		"photoCount": 41,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2001,
		"zipCode": "89141",
		"path": "las-vegas-nv/5550-nimes-ave-las-vegas-nv-89141-321_1520725/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "698c5a05-7685-4937-a4d8-a007c7837ab0",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:30:02",
		"createdAt": "2021-08-05 22:41:30",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-05 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2321257",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 35.991191,
			"lng": -115.217145,
			"zipcode": "89141",
			"subPremise": "",
			"address": "5550 Nimes Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 420000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2321257"
	},
	{
		"closePrice": 2850000,
		"daysOnMovoto": 47,
		"id": "6d5b8928-4224-4ae8-ac12-46672558f91a",
		"tnImgPath": "https://pi.movoto.com/p/321/2319186_0_RbqB7M_p.jpeg",
		"listDate": "2021-07-30 00:00:00",
		"listingAgent": "Ivan G Sher",
		"listPrice": 2850000,
		"lotSize": 14375,
		"sqftTotal": 5717,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319186",
		"bath": 7,
		"bed": 6,
		"openHouses": [],
		"officeListName": "BHHS Nevada Properties",
		"photoCount": 37,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1995,
		"zipCode": "89134",
		"path": "las-vegas-nv/9217-white-tail-dr-las-vegas-nv-89134/pid_kjbreg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "f1b083eb-7fdb-4e77-b059-50a6b54a8252",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:23:46",
		"createdAt": "2021-07-30 17:16:47",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-30 00:00:00",
		"virtualTourLink": "https://www.youtube.com/embed/1Y2Sr8z8o6M",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.195555,
			"lng": -115.297048,
			"zipcode": "89134",
			"subPremise": "",
			"address": "9217 White Tail Dr"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 2850000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.youtube.com/embed/1Y2Sr8z8o6M"
	},
	{
		"closePrice": 759500,
		"daysOnMovoto": 92,
		"id": "0ad506a7-f101-4cfd-921e-163a8bf220e3",
		"tnImgPath": "https://pi.movoto.com/p/321/2305464_0_IV2VIE_p.jpeg",
		"listDate": "2021-06-15 00:00:00",
		"listingAgent": "Kevin S Ekus",
		"listPrice": 762900,
		"lotSize": 14810,
		"sqftTotal": 3829,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2305464",
		"bath": 4,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Zahler Properties LLC",
		"photoCount": 49,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2009,
		"zipCode": "89131",
		"path": "las-vegas-nv/10338-hanky-panky-st-las-vegas-nv-89131/pid_8xwoeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -12100,
		"propertyId": "25dc1176-882f-4d76-aedd-570f3ad8fefe",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:15:24",
		"createdAt": "2021-06-16 02:21:13",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-06-15 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2305464",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.332763,
			"lng": -115.276304,
			"zipcode": "89131",
			"subPremise": "",
			"address": "10338 Hanky Panky St"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 759500,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2305464"
	},
	{
		"closePrice": 335000,
		"daysOnMovoto": 64,
		"id": "de4aa716-3dd6-4219-8dd7-1e643af5958b",
		"tnImgPath": "https://pi.movoto.com/p/321/2314639_0_n2m3Ez_p.jpeg",
		"listDate": "2021-07-13 00:00:00",
		"listingAgent": "Kevin Goujon",
		"listPrice": 339994,
		"lotSize": 6098,
		"sqftTotal": 1100,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2314639",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Infinity Brokerage",
		"photoCount": 24,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1942,
		"zipCode": "89104",
		"path": "las-vegas-nv/1107-jessica-ave-las-vegas-nv-89104/pid_qblveg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": -9999,
		"propertyId": "672c4769-121c-4ebd-a334-d18c4f211b5d",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 16:03:08",
		"createdAt": "2021-07-14 04:21:17",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-13 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2314639",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.156744,
			"lng": -115.139098,
			"zipcode": "89104",
			"subPremise": "",
			"address": "1107 Jessica Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 335000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2314639"
	},
	{
		"closePrice": 560000,
		"daysOnMovoto": 56,
		"id": "3997996c-cd7f-41cd-b281-f985bea134ab",
		"tnImgPath": "https://pi.movoto.com/p/321/2317870_0_JZeZry_p.jpeg",
		"listDate": "2021-07-21 00:00:00",
		"listingAgent": "Cecilia Santaniello",
		"listPrice": 550000,
		"lotSize": 7405,
		"sqftTotal": 2659,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2317870",
		"bath": 3,
		"bed": 4,
		"openHouses": [],
		"officeListName": "Luxury Homes of Las Vegas",
		"photoCount": 28,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 1988,
		"zipCode": "89117",
		"path": "las-vegas-nv/2725-tidewater-ct-las-vegas-nv-89117/pid_60zweg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "120312f8-63ab-4609-b59c-d05b2fec7431",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 15:58:26",
		"createdAt": "2021-07-24 21:31:19",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-21 00:00:00",
		"virtualTourLink": "https://cdn-r.virtuance.com/268538/videos/2725_Tidewater_Ct_Las_Vegas_NV_720p.mp4",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.141903,
			"lng": -115.29714,
			"zipcode": "89117",
			"subPremise": "",
			"address": "2725 Tidewater Ct"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 560000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://cdn-r.virtuance.com/268538/videos/2725_Tidewater_Ct_Las_Vegas_NV_720p.mp4"
	},
	{
		"closePrice": 975000,
		"daysOnMovoto": 49,
		"id": "e81606ae-94b0-4e6f-b695-a7cf655d059b",
		"tnImgPath": "https://pi.movoto.com/p/321/2318753_0_fUr7bb_p.jpeg",
		"listDate": "2021-07-28 00:00:00",
		"listingAgent": "Craig Thornton",
		"listPrice": 975000,
		"lotSize": 10019,
		"sqftTotal": 4589,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2318753",
		"bath": 5,
		"bed": 6,
		"openHouses": [],
		"officeListName": "BHHS Nevada Properties",
		"photoCount": 49,
		"propertyType": "SINGLE_FAMILY_HOUSE",
		"propertyTypeDisplayName": "Residential - Single Family",
		"yearBuilt": 2019,
		"zipCode": "89149",
		"path": "las-vegas-nv/9935-mountain-foothills-ave-las-vegas-nv-89149-321_2318753/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "11cacbe5-53e3-46c5-a916-6c598a60bd2f",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 15:50:06",
		"createdAt": "2021-08-06 07:31:26",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-07-28 00:00:00",
		"virtualTourLink": "https://sites.empirehometours.com/9935-Mountain-Foothills-Ave/idx",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.276551,
			"lng": -115.313686,
			"zipcode": "89149",
			"subPremise": "",
			"address": "9935 Mountain Foothills Ave"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 975000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://sites.empirehometours.com/9935-Mountain-Foothills-Ave/idx"
	},
	{
		"closePrice": 175000,
		"daysOnMovoto": 45,
		"id": "1fff985d-efb0-4237-abfe-04cd2f42c0ac",
		"tnImgPath": "https://pi.movoto.com/p/321/2319260_0_rNirJy_p.jpeg",
		"listDate": "2021-08-01 00:00:00",
		"listingAgent": "Warren Chaney",
		"listPrice": 165000,
		"lotSize": 0,
		"sqftTotal": 920,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2319260",
		"bath": 2,
		"bed": 2,
		"openHouses": [],
		"officeListName": "Compass Realty & Management, L",
		"photoCount": 22,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Condominium",
		"yearBuilt": 1989,
		"zipCode": "89118",
		"path": "las-vegas-nv/5166-s-jones-blvd-101-las-vegas-nv-89118/pid_ekvxeg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "acf0ac99-9b3c-47e7-b02a-4c057d1b473c",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 15:46:07",
		"createdAt": "2021-08-02 22:46:24",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-01 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2319260",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.095664,
			"lng": -115.223698,
			"zipcode": "89118",
			"subPremise": "APT 101",
			"address": "5166 S Jones Blvd #101"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 175000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2319260"
	},
	{
		"closePrice": 141000,
		"daysOnMovoto": 26,
		"id": "3d072d4f-7d5d-4dea-913e-1bf1b0145512",
		"tnImgPath": "https://pi.movoto.com/p/321/2325237_0_Ju2Fyv_p.jpeg",
		"listDate": "2021-08-20 00:00:00",
		"listingAgent": "Tom Heuser",
		"listPrice": 150000,
		"lotSize": 1742,
		"sqftTotal": 1716,
		"mlsDbNumber": 321,
		"mls": {
			"dateHidden": null,
			"id": 321
		},
		"mlsNumber": "2325237",
		"bath": 2,
		"bed": 1,
		"openHouses": [],
		"officeListName": "eXp Realty",
		"photoCount": 6,
		"propertyType": "CONDO",
		"propertyTypeDisplayName": "Townhouse",
		"yearBuilt": 1971,
		"zipCode": "89121",
		"path": "las-vegas-nv/271-pecos-way-las-vegas-nv-89121/pid_ilrueg8aih/",
		"status": "Closed",
		"houseRealStatus": "SOLD",
		"priceChange": null,
		"propertyId": "df429d5e-277f-41c7-9405-a82a26a2f873",
		"visibility": "NO_RESTRICTION",
		"soldDate": "2021-09-15 15:45:41",
		"createdAt": "2021-08-20 16:52:19",
		"imageDownloaderStatus": 7,
		"onMarketDateTime": "2021-08-20 00:00:00",
		"virtualTourLink": "https://www.propertypanorama.com/instaview/las/2325237",
		"nhsDetails": null,
		"geo": {
			"state": "NV",
			"city": "Las Vegas",
			"lat": 36.133948,
			"lng": -115.09882,
			"zipcode": "89121",
			"subPremise": "",
			"address": "271 Pecos Way"
		},
		"isNHS": false,
		"isRentals": false,
		"isSold": true,
		"listingByMovoto": false,
		"priceRaw": 141000,
		"isVideoTour": false,
		"is3DTour": false,
		"videoTourLink": "",
		"virtualLink": "https://www.propertypanorama.com/instaview/las/2325237"
	}
]