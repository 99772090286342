exports.SAN_MATEO_SCHOOLS = [
		{
			"id": 6955,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "m",
			"lat": 37.530087,
			"lon": -122.310135,
			"name": "Abbott Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "600 36th Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6955-Abbott-Middle-School/",
				"reviews": "/california/san-mateo/6955-Abbott-Middle-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6955-Abbott-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "47%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 813,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 47
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 55
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"rating": 7,
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31664,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "All About Me Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "San Mateo",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/31664-All-About-Me-Montessori/",
				"reviews": "/california/san-mateo/31664-All-About-Me-Montessori/#Reviews",
				"collegeSuccess": "/california/san-mateo/31664-All-About-Me-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9442,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.523994,
			"lon": -122.304314,
			"name": "Alpha Beacon Christian School CLOSED 06-2014",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "525 42nd Ave",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/9442-Alpha-Beacon-Christian-School-CLOSED-06-2014/",
				"reviews": "/california/san-mateo/9442-Alpha-Beacon-Christian-School-CLOSED-06-2014/#Reviews",
				"collegeSuccess": "/california/san-mateo/9442-Alpha-Beacon-Christian-School-CLOSED-06-2014/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 80,
			"parentRating": 4,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 44
				},
				{
					"label": "White",
					"percentage": 28
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 30228,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.55666,
			"lon": -122.312042,
			"name": "Aplus Immersion Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1501 South B Street",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30228-Aplus-Immersion-Pre-School/",
				"reviews": "/california/san-mateo/30228-Aplus-Immersion-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/30228-Aplus-Immersion-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6975,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.550385,
			"lon": -122.329971,
			"name": "Aragon High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "900 Alameda de las Pulgas",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6975-Aragon-High-School/",
				"reviews": "/california/san-mateo/6975-Aragon-High-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6975-Aragon-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "23%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "84%",
				"state_average": "64%"
			},
			"enrollment": 1675,
			"parentRating": 3,
			"numReviews": 15,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 23
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 27
				},
				{
					"label": "Pacific Islander",
					"rating": 2,
					"percentage": 4
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32725,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e,m",
			"lat": 37.566174,
			"lon": -122.29525,
			"name": "Bayside Academy",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2025 Kehoe Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/32725-Bayside-Academy/",
				"reviews": "/california/san-mateo/32725-Bayside-Academy/#Reviews",
				"collegeSuccess": "/california/san-mateo/32725-Bayside-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "45%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 924,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 45
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 49
				},
				{
					"label": "Pacific Islander",
					"rating": 4,
					"percentage": 7
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6958,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.552624,
			"lon": -122.331879,
			"name": "Baywood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "600 Alameda de las Pulgas",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6958-Baywood-Elementary-School/",
				"reviews": "/california/san-mateo/6958-Baywood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6958-Baywood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "19%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 712,
			"parentRating": 5,
			"numReviews": 20,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 19
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 41
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6959,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.539448,
			"lon": -122.309433,
			"name": "Beresford Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "300 28th Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6959-Beresford-Elementary-School/",
				"reviews": "/california/san-mateo/6959-Beresford-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6959-Beresford-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "49%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 271,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 49
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 52
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "Asian",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22558,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.538059,
			"lon": -122.31456,
			"name": "Beresford Kids Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2720 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22558-Beresford-Kids-Club/",
				"reviews": "/california/san-mateo/22558-Beresford-Kids-Club/#Reviews",
				"collegeSuccess": "/california/san-mateo/22558-Beresford-Kids-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15156,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.553375,
			"lon": -122.31147,
			"name": "Beresford Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1717 Gum St",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/15156-Beresford-Montessori/",
				"reviews": "/california/san-mateo/15156-Beresford-Montessori/#Reviews",
				"collegeSuccess": "/california/san-mateo/15156-Beresford-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6960,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "m",
			"lat": 37.549965,
			"lon": -122.32151,
			"name": "Borel Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "425 Barneson Avenue",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6960-Borel-Middle-School/",
				"reviews": "/california/san-mateo/6960-Borel-Middle-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6960-Borel-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1062,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 36
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 45
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22550,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.580452,
			"lon": -122.314957,
			"name": "Boys & Girls Club Child Care Center-Turnbull",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "200 North Quebec Street",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22550-Boys--Girls-Club-Child-Care-Center-Turnbull/",
				"reviews": "/california/san-mateo/22550-Boys--Girls-Club-Child-Care-Center-Turnbull/#Reviews",
				"collegeSuccess": "/california/san-mateo/22550-Boys--Girls-Club-Child-Care-Center-Turnbull/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 19888,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "Bright Beginnings",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 7030",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/19888-Bright-Beginnings/",
				"reviews": "/california/san-mateo/19888-Bright-Beginnings/#Reviews",
				"collegeSuccess": "/california/san-mateo/19888-Bright-Beginnings/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22559,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.555565,
			"lon": -122.319321,
			"name": "Bright Beginnings Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "30 Hobart Avenue",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22559-Bright-Beginnings-Pre-School/",
				"reviews": "/california/san-mateo/22559-Bright-Beginnings-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22559-Bright-Beginnings-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22721,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "Bright Beginnings Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 7030",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22721-Bright-Beginnings-Pre-School/",
				"reviews": "/california/san-mateo/22721-Bright-Beginnings-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22721-Bright-Beginnings-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22548,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534054,
			"lon": -122.291634,
			"name": "Building Kidz Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "39 East 39th Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22548-Building-Kidz-Day-Care/",
				"reviews": "/california/san-mateo/22548-Building-Kidz-Day-Care/#Reviews",
				"collegeSuccess": "/california/san-mateo/22548-Building-Kidz-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22552,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.521679,
			"lon": -122.342216,
			"name": "Bunker Hill Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2145 Bunker Hill Drive",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22552-Bunker-Hill-Nursery-School/",
				"reviews": "/california/san-mateo/22552-Bunker-Hill-Nursery-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22552-Bunker-Hill-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22557,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.558132,
			"lon": -122.318802,
			"name": "Centennial Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "27 10th Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22557-Centennial-Montessori-School/",
				"reviews": "/california/san-mateo/22557-Centennial-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22557-Centennial-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 72,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 44
				},
				{
					"label": "Asian",
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"percentage": 19
				}
			],
			"remediationData": {}
		},
		{
			"id": 31558,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.523994,
			"lon": -122.304314,
			"name": "Centennial Montessori School",
			"gradeLevels": "1-4",
			"assigned": null,
			"address": {
				"street1": "525 42nd Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/31558-Centennial-Montessori-School/",
				"reviews": "/california/san-mateo/31558-Centennial-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/31558-Centennial-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 11,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17740,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.559574,
			"lon": -122.285332,
			"name": "Challenge School - Foster City Campus",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1661 Fashion Blvd",
				"street2": null,
				"zip": "94404",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94404",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/17740-Challenge-School---Foster-City-Campus/",
				"reviews": "/california/san-mateo/17740-Challenge-School---Foster-City-Campus/#Reviews",
				"collegeSuccess": "/california/san-mateo/17740-Challenge-School---Foster-City-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30026,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.559574,
			"lon": -122.285332,
			"name": "Challenge School Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1661 Fashion Island Boulevard",
				"street2": null,
				"zip": "94404",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94404",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30026-Challenge-School-Pre-School/",
				"reviews": "/california/san-mateo/30026-Challenge-School-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/30026-Challenge-School-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22562,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.531609,
			"lon": -122.30352,
			"name": "Children's School Huc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "303 36th Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22562-Childrens-School-Huc/",
				"reviews": "/california/san-mateo/22562-Childrens-School-Huc/#Reviews",
				"collegeSuccess": "/california/san-mateo/22562-Childrens-School-Huc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6974,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.576775,
			"lon": -122.326996,
			"name": "College Park Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "715A Indian Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6974-College-Park-Elementary-School/",
				"reviews": "/california/san-mateo/6974-College-Park-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6974-College-Park-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 452,
			"parentRating": 4,
			"numReviews": 34,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 66
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 27222,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.54649,
			"lon": -122.312103,
			"name": "Compass High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2040 Pioneer Court",
				"street2": null,
				"zip": "94065",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94065",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/27222-Compass-High-School/",
				"reviews": "/california/san-mateo/27222-Compass-High-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/27222-Compass-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 25,
			"parentRating": 5,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 68
				},
				{
					"label": "Asian",
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 22553,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.549583,
			"lon": -122.318802,
			"name": "Crystal Springs Child Ed Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1611 Borel Place #120",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22553-Crystal-Springs-Child-Ed-Center/",
				"reviews": "/california/san-mateo/22553-Crystal-Springs-Child-Ed-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/22553-Crystal-Springs-Child-Ed-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22539,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.573723,
			"lon": -122.314751,
			"name": "Early Bird Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "120 Lindbergh Street",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22539-Early-Bird-Pre-School/",
				"reviews": "/california/san-mateo/22539-Early-Bird-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22539-Early-Bird-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29859,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.563938,
			"lon": -122.326324,
			"name": "Early Learning Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "24 2nd Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/29859-Early-Learning-Head-Start/",
				"reviews": "/california/san-mateo/29859-Early-Learning-Head-Start/#Reviews",
				"collegeSuccess": "/california/san-mateo/29859-Early-Learning-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22555,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.568871,
			"lon": -122.327812,
			"name": "Family Service Toddler Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "225 Tilton Ave",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22555-Family-Service-Toddler-Center/",
				"reviews": "/california/san-mateo/22555-Family-Service-Toddler-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/22555-Family-Service-Toddler-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6963,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.551517,
			"lon": -122.299675,
			"name": "Fiesta Gardens International Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1001 Bermuda Drive",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6963-Fiesta-Gardens-International-Elementary-School/",
				"reviews": "/california/san-mateo/6963-Fiesta-Gardens-International-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6963-Fiesta-Gardens-International-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 511,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 80
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22564,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.57563,
			"lon": -122.33532,
			"name": "First Step For Family",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "325 Villa Terrace",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22564-First-Step-For-Family/",
				"reviews": "/california/san-mateo/22564-First-Step-For-Family/#Reviews",
				"collegeSuccess": "/california/san-mateo/22564-First-Step-For-Family/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30106,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.563938,
			"lon": -122.326324,
			"name": "Fsa Of Smc Cdc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "24 2nd Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30106-Fsa-Of-Smc-Cdc/",
				"reviews": "/california/san-mateo/30106-Fsa-Of-Smc-Cdc/#Reviews",
				"collegeSuccess": "/california/san-mateo/30106-Fsa-Of-Smc-Cdc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27732,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.563938,
			"lon": -122.326324,
			"name": "Fsa Of Smc Cdc-Haven",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "24 2nd Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/27732-Fsa-Of-Smc-Cdc-Haven/",
				"reviews": "/california/san-mateo/27732-Fsa-Of-Smc-Cdc-Haven/#Reviews",
				"collegeSuccess": "/california/san-mateo/27732-Fsa-Of-Smc-Cdc-Haven/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27611,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.563938,
			"lon": -122.326324,
			"name": "Fsa Of Smc Cdc-Leo J Ryan",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "24 2nd Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/27611-Fsa-Of-Smc-Cdc-Leo-J-Ryan/",
				"reviews": "/california/san-mateo/27611-Fsa-Of-Smc-Cdc-Leo-J-Ryan/#Reviews",
				"collegeSuccess": "/california/san-mateo/27611-Fsa-Of-Smc-Cdc-Leo-J-Ryan/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27741,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.563938,
			"lon": -122.326324,
			"name": "Fsa Of Smc-Plaza Ccd",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "24 2nd Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/27741-Fsa-Of-Smc-Plaza-Ccd/",
				"reviews": "/california/san-mateo/27741-Fsa-Of-Smc-Plaza-Ccd/#Reviews",
				"collegeSuccess": "/california/san-mateo/27741-Fsa-Of-Smc-Plaza-Ccd/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26310,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.544586,
			"lon": -122.321175,
			"name": "Fusion Academy San Mateo",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "2000 Alameda de las Pulgas",
				"street2": "Suite 128",
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/26310-Fusion-Academy-San-Mateo/",
				"reviews": "/california/san-mateo/26310-Fusion-Academy-San-Mateo/#Reviews",
				"collegeSuccess": "/california/san-mateo/26310-Fusion-Academy-San-Mateo/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 67,
			"parentRating": 3,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31734,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.55859,
			"lon": -122.2798,
			"name": "Futures Academy - San Mateo",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "1840 Gateway Dr.",
				"street2": null,
				"zip": "94404",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94404",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/31734-Futures-Academy---San-Mateo/",
				"reviews": "/california/san-mateo/31734-Futures-Academy---San-Mateo/#Reviews",
				"collegeSuccess": "/california/san-mateo/31734-Futures-Academy---San-Mateo/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22546,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.546169,
			"lon": -122.323334,
			"name": "Ganon Early Childhood Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1700 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22546-Ganon-Early-Childhood-Center/",
				"reviews": "/california/san-mateo/22546-Ganon-Early-Childhood-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/22546-Ganon-Early-Childhood-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16983,
			"districtId": 1102,
			"districtName": "San Mateo County Office of Education",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.512787,
			"lon": -122.333405,
			"name": "Gateway Center",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "35 Tower Road",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/16983-Gateway-Center/",
				"reviews": "/california/san-mateo/16983-Gateway-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/16983-Gateway-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "50%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 12,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 92
				},
				{
					"label": "Filipino",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 6965,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.537319,
			"lon": -122.291222,
			"name": "George Hall Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "130 San Miguel Way",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6965-George-Hall-Elementary-School/",
				"reviews": "/california/san-mateo/6965-George-Hall-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6965-George-Hall-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 432,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 36
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 32
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 10600,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.537357,
			"lon": -122.311127,
			"name": "Grace Lutheran School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2825 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/10600-Grace-Lutheran-School/",
				"reviews": "/california/san-mateo/10600-Grace-Lutheran-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/10600-Grace-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 51,
			"parentRating": 3,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 22
				},
				{
					"label": "Asian",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 22568,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.540939,
			"lon": -122.272545,
			"name": "Greenees Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "499 Boothbay Ave",
				"street2": null,
				"zip": "94404",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94404",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22568-Greenees-Learning-Center/",
				"reviews": "/california/san-mateo/22568-Greenees-Learning-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/22568-Greenees-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22544,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.527599,
			"lon": -122.328865,
			"name": "Happy People Daycare Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1639 Overland Drive",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22544-Happy-People-Daycare-Pre-School/",
				"reviews": "/california/san-mateo/22544-Happy-People-Daycare-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22544-Happy-People-Daycare-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24290,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.573566,
			"lon": -122.336792,
			"name": "Highland Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "614 Highland Ave",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/24290-Highland-Montessori/",
				"reviews": "/california/san-mateo/24290-Highland-Montessori/#Reviews",
				"collegeSuccess": "/california/san-mateo/24290-Highland-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6966,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.524071,
			"lon": -122.350342,
			"name": "Highlands Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2320 Newport Street",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6966-Highlands-Elementary-School/",
				"reviews": "/california/san-mateo/6966-Highlands-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6966-Highlands-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 527,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 21
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 29
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 28454,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.519691,
			"lon": -122.346626,
			"name": "Highlands Rec Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1851 Lexington Avenue",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/28454-Highlands-Rec-Day-Care/",
				"reviews": "/california/san-mateo/28454-Highlands-Rec-Day-Care/#Reviews",
				"collegeSuccess": "/california/san-mateo/28454-Highlands-Rec-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13851,
			"districtId": 1102,
			"districtName": "San Mateo County Office of Education",
			"districtCity": "Redwood City",
			"levelCode": "e,m,h",
			"lat": 37.513874,
			"lon": -122.337242,
			"name": "Hillcrest at Youth Services Center",
			"gradeLevels": "5-12",
			"assigned": null,
			"address": {
				"street1": "10 Loop Road",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/13851-Hillcrest-At-Youth-Services-Center/",
				"reviews": "/california/san-mateo/13851-Hillcrest-At-Youth-Services-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/13851-Hillcrest-At-Youth-Services-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "31%",
				"state_average": "64%"
			},
			"enrollment": 37,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 8,
			"subratings": {
				"Test Scores Rating": 1,
				"College Readiness Rating": 6,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 81
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 6978,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.532204,
			"lon": -122.313232,
			"name": "Hillsdale High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3115 Del Monte Street",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6978-Hillsdale-High-School/",
				"reviews": "/california/san-mateo/6978-Hillsdale-High-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6978-Hillsdale-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "84%",
				"state_average": "64%"
			},
			"enrollment": 1569,
			"parentRating": 5,
			"numReviews": 21,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 7
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22570,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.524647,
			"lon": -122.305267,
			"name": "Hope Lutheran Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "600 42nd Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22570-Hope-Lutheran-Pre-School/",
				"reviews": "/california/san-mateo/22570-Hope-Lutheran-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22570-Hope-Lutheran-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22571,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.569,
			"lon": -122.319893,
			"name": "Intercommunal Survival School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "713 2nd Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22571-Intercommunal-Survival-School/",
				"reviews": "/california/san-mateo/22571-Intercommunal-Survival-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22571-Intercommunal-Survival-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8136,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.545761,
			"lon": -122.3172,
			"name": "Junipero Serra High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "451 West 20th Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/8136-Junipero-Serra-High-School/",
				"reviews": "/california/san-mateo/8136-Junipero-Serra-High-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/8136-Junipero-Serra-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 875,
			"parentRating": 4,
			"numReviews": 20,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 62
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 24321,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.571693,
			"lon": -122.329529,
			"name": "Kinder Academy Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "300 East Santa Inez Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/24321-Kinder-Academy-Montessori/",
				"reviews": "/california/san-mateo/24321-Kinder-Academy-Montessori/#Reviews",
				"collegeSuccess": "/california/san-mateo/24321-Kinder-Academy-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 17,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 50
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 50
				}
			],
			"remediationData": {}
		},
		{
			"id": 30338,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.571693,
			"lon": -122.329529,
			"name": "Kinder Academy Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 East Santa Inez Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30338-Kinder-Academy-Montessori-School/",
				"reviews": "/california/san-mateo/30338-Kinder-Academy-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/30338-Kinder-Academy-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22540,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.546738,
			"lon": -122.291039,
			"name": "Kindercare Learning Centers",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1350 Wayne Way",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22540-Kindercare-Learning-Centers/",
				"reviews": "/california/san-mateo/22540-Kindercare-Learning-Centers/#Reviews",
				"collegeSuccess": "/california/san-mateo/22540-Kindercare-Learning-Centers/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 22,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Two or more races",
					"percentage": 20
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"percentage": 10
				}
			],
			"remediationData": {}
		},
		{
			"id": 22551,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.567829,
			"lon": -122.320419,
			"name": "Kindercourt No 2",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "211 South Delaware Street",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22551-Kindercourt-No-2/",
				"reviews": "/california/san-mateo/22551-Kindercourt-No-2/#Reviews",
				"collegeSuccess": "/california/san-mateo/22551-Kindercourt-No-2/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30680,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.552345,
			"lon": -122.289551,
			"name": "Kubo Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2131 South Norfolk Street",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30680-Kubo-Montessori/",
				"reviews": "/california/san-mateo/30680-Kubo-Montessori/#Reviews",
				"collegeSuccess": "/california/san-mateo/30680-Kubo-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 8,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30274,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.548138,
			"lon": -122.312393,
			"name": "La Creche Child Development Center & Ps",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1911 Elkhorn Court",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30274-La-Creche-Child-Development-Center--Ps/",
				"reviews": "/california/san-mateo/30274-La-Creche-Child-Development-Center--Ps/#Reviews",
				"collegeSuccess": "/california/san-mateo/30274-La-Creche-Child-Development-Center--Ps/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31562,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.574528,
			"lon": -122.323387,
			"name": "La Escuelita Christian Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "217 North Grant Street",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/31562-La-Escuelita-Christian-Academy/",
				"reviews": "/california/san-mateo/31562-La-Escuelita-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/san-mateo/31562-La-Escuelita-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 19,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6968,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.531918,
			"lon": -122.304504,
			"name": "Laurel Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "316 36th Avenue",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6968-Laurel-Elementary-School/",
				"reviews": "/california/san-mateo/6968-Laurel-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6968-Laurel-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "44%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 525,
			"parentRating": 5,
			"numReviews": 9,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 44
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 52
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 24
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6967,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.569492,
			"lon": -122.302116,
			"name": "LEAD Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "949 Ocean View Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6967-Horrall-Elementary-School/",
				"reviews": "/california/san-mateo/6967-Horrall-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6967-Horrall-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "67%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 530,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 67
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 63
				},
				{
					"label": "Pacific Islander",
					"percentage": 10
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32419,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.568848,
			"lon": -122.327713,
			"name": "Little Wonders",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "225 Tilton Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/32419-Little-Wonders/",
				"reviews": "/california/san-mateo/32419-Little-Wonders/#Reviews",
				"collegeSuccess": "/california/san-mateo/32419-Little-Wonders/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21118,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "Love-N-Care Christian Ps & Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 7030",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/21118-Love-N-Care-Christian-Ps--Dc/",
				"reviews": "/california/san-mateo/21118-Love-N-Care-Christian-Ps--Dc/#Reviews",
				"collegeSuccess": "/california/san-mateo/21118-Love-N-Care-Christian-Ps--Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31176,
			"districtId": 1102,
			"districtName": "San Mateo County Office of Education",
			"districtCity": "Redwood City",
			"levelCode": "m,h",
			"lat": 37.511272,
			"lon": -122.338631,
			"name": "Margaret J. Kemp",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "400 Paul Scannell Drive",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/31176-Margaret-J.-Kemp/",
				"reviews": "/california/san-mateo/31176-Margaret-J.-Kemp/#Reviews",
				"collegeSuccess": "/california/san-mateo/31176-Margaret-J.-Kemp/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 9,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 89
				},
				{
					"label": "African American",
					"percentage": 11
				}
			],
			"remediationData": {}
		},
		{
			"id": 16637,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.566216,
			"lon": -122.316551,
			"name": "Martha Williams School",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "522 South Fremont Street",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/16637-Martha-Williams-School/",
				"reviews": "/california/san-mateo/16637-Martha-Williams-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/16637-Martha-Williams-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6969,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.538948,
			"lon": -122.316666,
			"name": "Meadow Heights Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2619 Dolores Street",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6969-Meadow-Heights-Elementary-School/",
				"reviews": "/california/san-mateo/6969-Meadow-Heights-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6969-Meadow-Heights-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 339,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 38
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 20497,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.549583,
			"lon": -122.318802,
			"name": "Millbrae Community Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1611 Borel Place #120",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/20497-Millbrae-Community-Pre-School/",
				"reviews": "/california/san-mateo/20497-Millbrae-Community-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/20497-Millbrae-Community-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30713,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.568882,
			"lon": -122.327835,
			"name": "Muslim Children's Garden",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "225 Tilton Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/30713-Muslim-Childrens-Garden/",
				"reviews": "/california/san-mateo/30713-Muslim-Childrens-Garden/#Reviews",
				"collegeSuccess": "/california/san-mateo/30713-Muslim-Childrens-Garden/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 31,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 11668,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.558197,
			"lon": -122.318977,
			"name": "Neighborhood Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "27 10th Ave",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/11668-Neighborhood-Montessori-School/",
				"reviews": "/california/san-mateo/11668-Neighborhood-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/11668-Neighborhood-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22569,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.552296,
			"lon": -122.331459,
			"name": "Newton CCC-Baywood",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "600 Alameda de las Pugas",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22569-Newton-CCC-Baywood/",
				"reviews": "/california/san-mateo/22569-Newton-CCC-Baywood/#Reviews",
				"collegeSuccess": "/california/san-mateo/22569-Newton-CCC-Baywood/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22560,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.539665,
			"lon": -122.309212,
			"name": "Newton CCC-Beresford",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "300 28th Ave",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22560-Newton-CCC-Beresford/",
				"reviews": "/california/san-mateo/22560-Newton-CCC-Beresford/#Reviews",
				"collegeSuccess": "/california/san-mateo/22560-Newton-CCC-Beresford/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22545,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.561401,
			"lon": -122.296669,
			"name": "Newton CCC-Parkside",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1685 Eisenhower St",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22545-Newton-CCC-Parkside/",
				"reviews": "/california/san-mateo/22545-Newton-CCC-Parkside/#Reviews",
				"collegeSuccess": "/california/san-mateo/22545-Newton-CCC-Parkside/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21516,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.566601,
			"lon": -122.272179,
			"name": "Newton Child Care Center-N Star",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1157 Chess Drive #100",
				"street2": null,
				"zip": "94404",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94404",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/21516-Newton-Child-Care-Center-N-Star/",
				"reviews": "/california/san-mateo/21516-Newton-Child-Care-Center-N-Star/#Reviews",
				"collegeSuccess": "/california/san-mateo/21516-Newton-Child-Care-Center-N-Star/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18724,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "Noah's Ark Pre-School & Dcc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 7030",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/18724-Noahs-Ark-Pre-School--Dcc/",
				"reviews": "/california/san-mateo/18724-Noahs-Ark-Pre-School--Dcc/#Reviews",
				"collegeSuccess": "/california/san-mateo/18724-Noahs-Ark-Pre-School--Dcc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18725,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "Noah's Ark Ps & Dcc-Edgeworth",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 7030",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/18725-Noahs-Ark-Ps--Dcc-Edgeworth/",
				"reviews": "/california/san-mateo/18725-Noahs-Ark-Ps--Dcc-Edgeworth/#Reviews",
				"collegeSuccess": "/california/san-mateo/18725-Noahs-Ark-Ps--Dcc-Edgeworth/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6970,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e,m",
			"lat": 37.574947,
			"lon": -122.316292,
			"name": "North Shoreview Montessori",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1301 Cypress Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6970-North-Shoreview-Montessori-Elementary-School/",
				"reviews": "/california/san-mateo/6970-North-Shoreview-Montessori-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6970-North-Shoreview-Montessori-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "20%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 328,
			"parentRating": 5,
			"numReviews": 8,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 20
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 38
				},
				{
					"label": "Asian",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 22566,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.575649,
			"lon": -122.332558,
			"name": "Nurture & Nature Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "426 Grand Blvd",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22566-Nurture--Nature-Day-Care/",
				"reviews": "/california/san-mateo/22566-Nurture--Nature-Day-Care/#Reviews",
				"collegeSuccess": "/california/san-mateo/22566-Nurture--Nature-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 11466,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m",
			"lat": 37.534222,
			"lon": -122.349304,
			"name": "Odyssey School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "201 Polhemus Road",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/11466-Odyssey-School/",
				"reviews": "/california/san-mateo/11466-Odyssey-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/11466-Odyssey-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 45,
			"parentRating": 4,
			"numReviews": 20,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 67
				},
				{
					"label": "Asian",
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 22567,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.579227,
			"lon": -122.335625,
			"name": "Pacific Rim International Dcc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "454 Peninsula Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22567-Pacific-Rim-International-Dcc/",
				"reviews": "/california/san-mateo/22567-Pacific-Rim-International-Dcc/#Reviews",
				"collegeSuccess": "/california/san-mateo/22567-Pacific-Rim-International-Dcc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31563,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.579445,
			"lon": -122.335663,
			"name": "Pacific Rim International School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "454 Peninsula Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/31563-Pacific-Rim-International-School/",
				"reviews": "/california/san-mateo/31563-Pacific-Rim-International-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/31563-Pacific-Rim-International-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 35,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6972,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.561886,
			"lon": -122.296448,
			"name": "Parkside Montessori",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1685 Eisenhower Street",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6972-Parkside-Elementary-School/",
				"reviews": "/california/san-mateo/6972-Parkside-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6972-Parkside-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "18%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 228,
			"parentRating": 4,
			"numReviews": 17,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 18
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 35
				},
				{
					"label": "Asian",
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 22573,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.559574,
			"lon": -122.304634,
			"name": "Parkside Rascals Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "906 Haddon Dr",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22573-Parkside-Rascals-Day-Care/",
				"reviews": "/california/san-mateo/22573-Parkside-Rascals-Day-Care/#Reviews",
				"collegeSuccess": "/california/san-mateo/22573-Parkside-Rascals-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22563,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.570744,
			"lon": -122.334747,
			"name": "Petite Sourbonne Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "401 Highland Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22563-Petite-Sourbonne-Pre-School/",
				"reviews": "/california/san-mateo/22563-Petite-Sourbonne-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22563-Petite-Sourbonne-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9867,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.572525,
			"lon": -122.334587,
			"name": "Russell Bede School (Will close: Spring 2014)",
			"gradeLevels": "1-6",
			"assigned": null,
			"address": {
				"street1": "446 Turner Ter",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/9867-Russell-Bede-School-Will-Close-Spring-2014/",
				"reviews": "/california/san-mateo/9867-Russell-Bede-School-Will-Close-Spring-2014/#Reviews",
				"collegeSuccess": "/california/san-mateo/9867-Russell-Bede-School-Will-Close-Spring-2014/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 18,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 89
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 14763,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "e,m,h",
			"lat": 37.577877,
			"lon": -122.328369,
			"name": "San Mateo Adult",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "789 East Poplar Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/14763-San-Mateo-Adult/",
				"reviews": "/california/san-mateo/14763-San-Mateo-Adult/#Reviews",
				"collegeSuccess": "/california/san-mateo/14763-San-Mateo-Adult/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6981,
			"districtId": 800,
			"districtName": "San Mateo Union High",
			"districtCity": "San Mateo",
			"levelCode": "h",
			"lat": 37.578224,
			"lon": -122.32988,
			"name": "San Mateo High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "506 North Delaware Street",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6981-San-Mateo-High-School/",
				"reviews": "/california/san-mateo/6981-San-Mateo-High-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6981-San-Mateo-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "78%",
				"state_average": "64%"
			},
			"enrollment": 1713,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 36
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 22541,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.555157,
			"lon": -122.316582,
			"name": "San Mateo Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "15 14th Avenue",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22541-San-Mateo-Montessori-School/",
				"reviews": "/california/san-mateo/22541-San-Mateo-Montessori-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22541-San-Mateo-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22547,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.580421,
			"lon": -122.31337,
			"name": "San Mateo Parents Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1732 Monte Diablo Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22547-San-Mateo-Parents-Nursery-School/",
				"reviews": "/california/san-mateo/22547-San-Mateo-Parents-Nursery-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22547-San-Mateo-Parents-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6971,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.569141,
			"lon": -122.342728,
			"name": "San Mateo Park Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "161 Clark Drive",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6971-San-Mateo-Park-Elementary-School/",
				"reviews": "/california/san-mateo/6971-San-Mateo-Park-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6971-San-Mateo-Park-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "70%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 384,
			"parentRating": 4,
			"numReviews": 26,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 70
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 70
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 13429,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.532215,
			"lon": -122.333809,
			"name": "Serendipity Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3172 Clearview Way",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/13429-Serendipity-Pre-School/",
				"reviews": "/california/san-mateo/13429-Serendipity-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/13429-Serendipity-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22554,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.561577,
			"lon": -122.284172,
			"name": "Siebel Children's Preschool-Bridgepnt",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2207 Bridgepointe Pkwy",
				"street2": null,
				"zip": "94404",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94404",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22554-Siebel-Childrens-Preschool-Bridgepnt/",
				"reviews": "/california/san-mateo/22554-Siebel-Childrens-Preschool-Bridgepnt/#Reviews",
				"collegeSuccess": "/california/san-mateo/22554-Siebel-Childrens-Preschool-Bridgepnt/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18723,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.549583,
			"lon": -122.318802,
			"name": "Southgate Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1611 Borel Place #120",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/18723-Southgate-Pre-School/",
				"reviews": "/california/san-mateo/18723-Southgate-Pre-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/18723-Southgate-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22542,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.554081,
			"lon": -122.31543,
			"name": "St. Andrew's Midweek Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1501 S El Camino Real",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22542-St.-Andrews-Midweek-Nursery-School/",
				"reviews": "/california/san-mateo/22542-St.-Andrews-Midweek-Nursery-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/22542-St.-Andrews-Midweek-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8133,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.540623,
			"lon": -122.307632,
			"name": "St. Gregory",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2701 Hacienda Street",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/8133-St.-Gregory/",
				"reviews": "/california/san-mateo/8133-St.-Gregory/#Reviews",
				"collegeSuccess": "/california/san-mateo/8133-St.-Gregory/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 310,
			"parentRating": 3,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 57
				},
				{
					"label": "Two or more races",
					"percentage": 26
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Asian",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 8134,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.55925,
			"lon": -122.322159,
			"name": "St. Matthew Catholic School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "910 South El Camino Real",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/8134-St.-Matthew-Catholic-School/",
				"reviews": "/california/san-mateo/8134-St.-Matthew-Catholic-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/8134-St.-Matthew-Catholic-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 600,
			"parentRating": 4,
			"numReviews": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 64
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8577,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.56559,
			"lon": -122.326927,
			"name": "St. Matthew's Episcopal Day School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "16 Baldwin Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/8577-St.-Matthews-Episcopal-Day-School/",
				"reviews": "/california/san-mateo/8577-St.-Matthews-Episcopal-Day-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/8577-St.-Matthews-Episcopal-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 269,
			"parentRating": 4,
			"numReviews": 29,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8135,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.574329,
			"lon": -122.310982,
			"name": "St. Timothy School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1515 Dolan Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/8135-St.-Timothy-School/",
				"reviews": "/california/san-mateo/8135-St.-Timothy-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/8135-St.-Timothy-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 212,
			"parentRating": 4,
			"numReviews": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 29
				},
				{
					"label": "White",
					"percentage": 25
				},
				{
					"label": "Asian",
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 9901,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.575325,
			"lon": -122.330276,
			"name": "Stanbridge Academy",
			"gradeLevels": "K-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "515 E. Poplar Avenue",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/9901-Stanbridge-Academy/",
				"reviews": "/california/san-mateo/9901-Stanbridge-Academy/#Reviews",
				"collegeSuccess": "/california/san-mateo/9901-Stanbridge-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 108,
			"parentRating": 5,
			"numReviews": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 81
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6973,
			"districtId": 799,
			"districtName": "San Mateo-Foster City",
			"districtCity": "Foster City",
			"levelCode": "e",
			"lat": 37.561703,
			"lon": -122.313057,
			"name": "Sunnybrae Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1031 South Delaware Street",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/6973-Sunnybrae-Elementary-School/",
				"reviews": "/california/san-mateo/6973-Sunnybrae-Elementary-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/6973-Sunnybrae-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 400,
			"parentRating": 5,
			"numReviews": 18,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 69
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 15337,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.544876,
			"lon": -122.319801,
			"name": "The Carey School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2101 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/15337-The-Carey-School/",
				"reviews": "/california/san-mateo/15337-The-Carey-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/15337-The-Carey-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 249,
			"parentRating": 4,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 59
				},
				{
					"label": "Asian",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13165,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.527195,
			"lon": -122.305733,
			"name": "Transfiguration Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3900 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/13165-Transfiguration-Nursery-School/",
				"reviews": "/california/san-mateo/13165-Transfiguration-Nursery-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/13165-Transfiguration-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21872,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.534992,
			"lon": -122.305084,
			"name": "Treehouse Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 7030",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/21872-Treehouse-Learning-Center/",
				"reviews": "/california/san-mateo/21872-Treehouse-Learning-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/21872-Treehouse-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15775,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.553715,
			"lon": -122.336678,
			"name": "Tulane Montessori Children's House",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "315 Tulane Road",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/15775-Tulane-Montessori-Childrens-House/",
				"reviews": "/california/san-mateo/15775-Tulane-Montessori-Childrens-House/#Reviews",
				"collegeSuccess": "/california/san-mateo/15775-Tulane-Montessori-Childrens-House/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 57,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Asian",
					"percentage": 46
				}
			],
			"remediationData": {}
		},
		{
			"id": 22572,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.577129,
			"lon": -122.326881,
			"name": "Turnbull Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "715 Indian Ave",
				"street2": null,
				"zip": "94401",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94401",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22572-Turnbull-Childrens-Center/",
				"reviews": "/california/san-mateo/22572-Turnbull-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/san-mateo/22572-Turnbull-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22556,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.543594,
			"lon": -122.305237,
			"name": "Under the Weather Emer CC",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2612 El Camino Real",
				"street2": null,
				"zip": "94403",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94403",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/22556-Under-The-Weather-Emer-CC/",
				"reviews": "/california/san-mateo/22556-Under-The-Weather-Emer-CC/#Reviews",
				"collegeSuccess": "/california/san-mateo/22556-Under-The-Weather-Emer-CC/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16640,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.5145,
			"lon": -122.335098,
			"name": "Walden School",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "22 Tower Road",
				"street2": null,
				"zip": "94402",
				"city": "San Mateo"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94402",
			"type": "school",
			"links": {
				"profile": "/california/san-mateo/16640-Walden-School/",
				"reviews": "/california/san-mateo/16640-Walden-School/#Reviews",
				"collegeSuccess": "/california/san-mateo/16640-Walden-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]