exports.CUPERTINO_SCHOOLS = [
    {
        "id": 5439,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.315468,
        "lon": -122.05336,
        "name": "Abraham Lincoln Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "21710 Mcclellan Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5439-Abraham-Lincoln-Elementary-School/",
            "reviews": "/california/cupertino/5439-Abraham-Lincoln-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5439-Abraham-Lincoln-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "3%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 576,
        "parentRating": 4,
        "numReviews": 42,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 3
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 80
            },
            {
                "label": "White",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 3
            }
        ],
        "remediationData": []
    },
    {
        "id": 18691,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.320343,
        "lon": -122.006805,
        "name": "Azar Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10175 South Tantau Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18691-Azar-Day-Care/",
            "reviews": "/california/cupertino/18691-Azar-Day-Care/#Reviews",
            "collegeSuccess": "/california/cupertino/18691-Azar-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18695,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.320793,
        "lon": -122.01088,
        "name": "Bethel Lutheran Ch Nursery",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10181 Finch Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18695-Bethel-Lutheran-Ch-Nursery/",
            "reviews": "/california/cupertino/18695-Bethel-Lutheran-Ch-Nursery/#Reviews",
            "collegeSuccess": "/california/cupertino/18695-Bethel-Lutheran-Ch-Nursery/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 9273,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.320793,
        "lon": -122.01088,
        "name": "Bethel Lutheran School",
        "gradeLevels": "PK-5",
        "assigned": null,
        "address": {
            "street1": "10181 Finch Ave",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/9273-Bethel-Lutheran-School/",
            "reviews": "/california/cupertino/9273-Bethel-Lutheran-School/#Reviews",
            "collegeSuccess": "/california/cupertino/9273-Bethel-Lutheran-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 151,
        "parentRating": 5,
        "numReviews": 13,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 74
            },
            {
                "label": "White",
                "percentage": 23
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "Hispanic",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 18696,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.325939,
        "lon": -122.019646,
        "name": "Bright Horizons",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10253 North Portal Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18696-Bright-Horizons/",
            "reviews": "/california/cupertino/18696-Bright-Horizons/#Reviews",
            "collegeSuccess": "/california/cupertino/18696-Bright-Horizons/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 5433,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.31525,
        "lon": -122.026192,
        "name": "C. B. Eaton Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "20220 Suisun Drive",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5433-C.-B.-Eaton-Elementary-School/",
            "reviews": "/california/cupertino/5433-C.-B.-Eaton-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5433-C.-B.-Eaton-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "3%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 497,
        "parentRating": 4,
        "numReviews": 49,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 3
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 80
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 5496,
        "districtId": 635,
        "districtName": "Fremont Union High School District",
        "districtCity": "Sunnyvale",
        "levelCode": "h",
        "lat": 37.319664,
        "lon": -122.010406,
        "name": "Cupertino High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "10100 Finch Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5496-Cupertino-High-School/",
            "reviews": "/california/cupertino/5496-Cupertino-High-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5496-Cupertino-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "9%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "87%",
            "state_average": "64%"
        },
        "enrollment": 2305,
        "parentRating": 4,
        "numReviews": 35,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 10,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 9
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 70
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 9
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 13
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 18705,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.314266,
        "lon": -122.05246,
        "name": "Cupertino House of Montessori",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "8115 Park Villa Cir",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18705-Cupertino-House-Of-Montessori/",
            "reviews": "/california/cupertino/18705-Cupertino-House-Of-Montessori/#Reviews",
            "collegeSuccess": "/california/cupertino/18705-Cupertino-House-Of-Montessori/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 5448,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "p,e",
        "lat": 37.316097,
        "lon": -122.008759,
        "name": "D. J. Sedgwick Elementary School",
        "gradeLevels": "PK-5",
        "assigned": null,
        "address": {
            "street1": "19200 Phil Lane",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5448-D.-J.-Sedgwick-Elementary-School/",
            "reviews": "/california/cupertino/5448-D.-J.-Sedgwick-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5448-D.-J.-Sedgwick-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "7%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 617,
        "parentRating": 3,
        "numReviews": 91,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 7
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 72
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 13
            },
            {
                "label": "Hispanic",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 24930,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.2966,
        "lon": -122.032829,
        "name": "De Anza Blvd. Kindercare",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1515 South De Anza Blvd.",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/24930-De-Anza-Blvd.-Kindercare/",
            "reviews": "/california/cupertino/24930-De-Anza-Blvd.-Kindercare/#Reviews",
            "collegeSuccess": "/california/cupertino/24930-De-Anza-Blvd.-Kindercare/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18704,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.321697,
        "lon": -122.046555,
        "name": "De Anza College Child Development Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "21250 Stevens Creek Boulevard",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18704-De-Anza-College-Child-Development-Center/",
            "reviews": "/california/cupertino/18704-De-Anza-College-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/18704-De-Anza-College-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30311,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.323044,
        "lon": -122.028389,
        "name": "Delight Montessori School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20299 Stevens Creek Boulevard",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/30311-Delight-Montessori-School/",
            "reviews": "/california/cupertino/30311-Delight-Montessori-School/#Reviews",
            "collegeSuccess": "/california/cupertino/30311-Delight-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18701,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.315128,
        "lon": -122.026115,
        "name": "Eaton Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20220 Suisun Drive",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18701-Eaton-Child-Development-Center/",
            "reviews": "/california/cupertino/18701-Eaton-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/18701-Eaton-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 27864,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.320927,
        "lon": -122.039627,
        "name": "Faria Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10155 Barbara Lane",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/27864-Faria-Child-Development-Center/",
            "reviews": "/california/cupertino/27864-Faria-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/27864-Faria-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 31733,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.322613,
        "lon": -122.017838,
        "name": "Futures Academy - Cupertino",
        "gradeLevels": "6-12",
        "assigned": null,
        "address": {
            "street1": "19638 Stevens Creek Blvd.",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/31733-Futures-Academy---Cupertino/",
            "reviews": "/california/cupertino/31733-Futures-Academy---Cupertino/#Reviews",
            "collegeSuccess": "/california/cupertino/31733-Futures-Academy---Cupertino/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 5436,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.329659,
        "lon": -122.045326,
        "name": "Garden Gate Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "10500 Ann Arbor Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5436-Garden-Gate-Elementary-School/",
            "reviews": "/california/cupertino/5436-Garden-Gate-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5436-Garden-Gate-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "3%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 576,
        "parentRating": 4,
        "numReviews": 47,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 3
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 89
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 18700,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.337284,
        "lon": -122.015846,
        "name": "Good Samaritan Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "19624 Homestead Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18700-Good-Samaritan-Pre-School/",
            "reviews": "/california/cupertino/18700-Good-Samaritan-Pre-School/#Reviews",
            "collegeSuccess": "/california/cupertino/18700-Good-Samaritan-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 16668,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.3242,
        "lon": -122.0373,
        "name": "Happy Days CDC",
        "gradeLevels": "K",
        "assigned": null,
        "address": {
            "street1": "10115 Saich Way",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/16668-Happy-Days-CDC/",
            "reviews": "/california/cupertino/16668-Happy-Days-CDC/#Reviews",
            "collegeSuccess": "/california/cupertino/16668-Happy-Days-CDC/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30233,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.310913,
        "lon": -122.012794,
        "name": "Haven Christian Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "6191 Bollinger Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/30233-Haven-Christian-Pre-School/",
            "reviews": "/california/cupertino/30233-Haven-Christian-Pre-School/#Reviews",
            "collegeSuccess": "/california/cupertino/30233-Haven-Christian-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 5498,
        "districtId": 635,
        "districtName": "Fremont Union High School District",
        "districtCity": "Sunnyvale",
        "levelCode": "h",
        "lat": 37.336601,
        "lon": -122.048866,
        "name": "Homestead High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "21370 Homestead Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5498-Homestead-High-School/",
            "reviews": "/california/cupertino/5498-Homestead-High-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5498-Homestead-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "14%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "84%",
            "state_average": "64%"
        },
        "enrollment": 2425,
        "parentRating": 4,
        "numReviews": 31,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 10,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 14
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 43
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 16
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 30
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 5438,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "m",
        "lat": 37.313232,
        "lon": -122.050522,
        "name": "John F. Kennedy Middle School",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "821 Bubb Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5438-John-F.-Kennedy-Middle-School/",
            "reviews": "/california/cupertino/5438-John-F.-Kennedy-Middle-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5438-John-F.-Kennedy-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "4%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1198,
        "parentRating": 4,
        "numReviews": 68,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 80
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 2
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 11
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 5429,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.318573,
        "lon": -122.023079,
        "name": "L. P. Collins Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "10300 Blaney Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5429-L.-P.-Collins-Elementary-School/",
            "reviews": "/california/cupertino/5429-L.-P.-Collins-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5429-L.-P.-Collins-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "3%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 702,
        "parentRating": 4,
        "numReviews": 50,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 3
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 84
            },
            {
                "label": "White",
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "percentage": 3
            },
            {
                "label": "Hispanic",
                "percentage": 3
            }
        ],
        "remediationData": []
    },
    {
        "id": 31584,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "h",
        "lat": 37.315319,
        "lon": -122.041916,
        "name": "Legend College Preparatory",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "21050 McClellan Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/31584-Legend-College-Preparatory/",
            "reviews": "/california/cupertino/31584-Legend-College-Preparatory/#Reviews",
            "collegeSuccess": "/california/cupertino/31584-Legend-College-Preparatory/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 50,
        "parentRating": 4,
        "numReviews": 51,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18702,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.314976,
        "lon": -122.039642,
        "name": "Little People Christian Dc College",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20900 McClellan Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18702-Little-People-Christian-Dc-College/",
            "reviews": "/california/cupertino/18702-Little-People-Christian-Dc-College/#Reviews",
            "collegeSuccess": "/california/cupertino/18702-Little-People-Christian-Dc-College/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30280,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.322292,
        "lon": -122.025169,
        "name": "Little Tree Montessori School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20100 Stevens Creek Boulevard",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/30280-Little-Tree-Montessori-School/",
            "reviews": "/california/cupertino/30280-Little-Tree-Montessori-School/#Reviews",
            "collegeSuccess": "/california/cupertino/30280-Little-Tree-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 5500,
        "districtId": 635,
        "districtName": "Fremont Union High School District",
        "districtCity": "Sunnyvale",
        "levelCode": "h",
        "lat": 37.315468,
        "lon": -122.05719,
        "name": "Monta Vista High School",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "21840 Mcclellan Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 10,
        "ratingScale": "Top rated",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5500-Monta-Vista-High-School/",
            "reviews": "/california/cupertino/5500-Monta-Vista-High-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5500-Monta-Vista-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "5%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "89%",
            "state_average": "64%"
        },
        "enrollment": 2274,
        "parentRating": 3,
        "numReviews": 133,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "College Readiness Rating": 10
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 9,
                "percentage": 5
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 79
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 3
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 12
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 27866,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.325916,
        "lon": -122.037857,
        "name": "Nw Ymca Covington Valley",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20803 Alves Drive",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/27866-Nw-Ymca-Covington-Valley/",
            "reviews": "/california/cupertino/27866-Nw-Ymca-Covington-Valley/#Reviews",
            "collegeSuccess": "/california/cupertino/27866-Nw-Ymca-Covington-Valley/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18692,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.321815,
        "lon": -122.058891,
        "name": "Play And Learn Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10067 Byrne Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18692-Play-And-Learn-Pre-School/",
            "reviews": "/california/cupertino/18692-Play-And-Learn-Pre-School/#Reviews",
            "collegeSuccess": "/california/cupertino/18692-Play-And-Learn-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18698,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.303596,
        "lon": -122.046936,
        "name": "Regnart Child Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1180 Yorkshire Dr",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18698-Regnart-Child-Development-Center/",
            "reviews": "/california/cupertino/18698-Regnart-Child-Development-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/18698-Regnart-Child-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 14794,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "m",
        "lat": 37.328411,
        "lon": -122.026772,
        "name": "Sam H. Lawson Middle School",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "10401 Vista Drive",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/14794-Sam-H.-Lawson-Middle-School/",
            "reviews": "/california/cupertino/14794-Sam-H.-Lawson-Middle-School/#Reviews",
            "collegeSuccess": "/california/cupertino/14794-Sam-H.-Lawson-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "5%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1138,
        "parentRating": 4,
        "numReviews": 74,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 9
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 5
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 85
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 4
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 2
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 28583,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.319901,
        "lon": -122.012711,
        "name": "Spring Source Ed Child Care Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "10200 Miller Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/28583-Spring-Source-Ed-Child-Care-Center/",
            "reviews": "/california/cupertino/28583-Spring-Source-Ed-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/28583-Spring-Source-Ed-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 9094,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.324299,
        "lon": -122.031998,
        "name": "St. Joseph of Cupertino Elementary School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "10120 North De Anza Boulevard",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/9094-St.-Joseph-Of-Cupertino-Elementary-School/",
            "reviews": "/california/cupertino/9094-St.-Joseph-Of-Cupertino-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/9094-St.-Joseph-Of-Cupertino-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 322,
        "parentRating": 4,
        "numReviews": 34,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 34
            },
            {
                "label": "White",
                "percentage": 31
            },
            {
                "label": "Two or more races",
                "percentage": 27
            },
            {
                "label": "Hispanic",
                "percentage": 8
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 5449,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.327309,
        "lon": -122.064301,
        "name": "Stevens Creek Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "10300 Ainsworth Drive",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5449-Stevens-Creek-Elementary-School/",
            "reviews": "/california/cupertino/5449-Stevens-Creek-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5449-Stevens-Creek-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "3%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 582,
        "parentRating": 3,
        "numReviews": 47,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 3
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 71
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 17
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 30172,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.322399,
        "lon": -122.009239,
        "name": "Sunflower Learning Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "19220 Stevens Creek Boulevard",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/30172-Sunflower-Learning-Center/",
            "reviews": "/california/cupertino/30172-Sunflower-Learning-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/30172-Sunflower-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 16654,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "m,h",
        "lat": 37.3144,
        "lon": -122.068703,
        "name": "The Academy for Educational Excellence",
        "gradeLevels": "6-12",
        "assigned": null,
        "address": {
            "street1": "22520 Kinst Court",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/16654-The-Academy-For-Educational-Excellence/",
            "reviews": "/california/cupertino/16654-The-Academy-For-Educational-Excellence/#Reviews",
            "collegeSuccess": "/california/cupertino/16654-The-Academy-For-Educational-Excellence/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 31021,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.322376,
        "lon": -122.00412,
        "name": "TLC of Cupertino",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10038 Bret Ave",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/31021-TLC-Of-Cupertino/",
            "reviews": "/california/cupertino/31021-TLC-Of-Cupertino/#Reviews",
            "collegeSuccess": "/california/cupertino/31021-TLC-Of-Cupertino/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18694,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.326859,
        "lon": -122.015015,
        "name": "Vallco Children's Development Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10123 North Wolfe Road #1093",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18694-Vallco-Childrens-Development-Center/",
            "reviews": "/california/cupertino/18694-Vallco-Childrens-Development-Center/#Reviews",
            "collegeSuccess": "/california/cupertino/18694-Vallco-Childrens-Development-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 13433,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.32,
        "lon": -122.029999,
        "name": "Villa Montessori",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "Po Box 15",
            "street2": "",
            "zip": "95015",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95015",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/13433-Villa-Montessori/",
            "reviews": "/california/cupertino/13433-Villa-Montessori/#Reviews",
            "collegeSuccess": "/california/cupertino/13433-Villa-Montessori/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 58,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 67
            },
            {
                "label": "Two or more races",
                "percentage": 33
            }
        ],
        "remediationData": []
    },
    {
        "id": 18703,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.32,
        "lon": -122.029999,
        "name": "Villa Montessori School #1",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "Po Box 15",
            "street2": "",
            "zip": "95015",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95015",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18703-Villa-Montessori-School-1/",
            "reviews": "/california/cupertino/18703-Villa-Montessori-School-1/#Reviews",
            "collegeSuccess": "/california/cupertino/18703-Villa-Montessori-School-1/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 18693,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.324451,
        "lon": -122.040199,
        "name": "Village Little Preschool",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10100 North Stelling Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/18693-Village-Little-Preschool/",
            "reviews": "/california/cupertino/18693-Village-Little-Preschool/#Reviews",
            "collegeSuccess": "/california/cupertino/18693-Village-Little-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 6,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 5437,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "m",
        "lat": 37.310551,
        "lon": -122.011719,
        "name": "Warren E. Hyde Middle School",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "19325 Bollinger Road",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5437-Warren-E.-Hyde-Middle-School/",
            "reviews": "/california/cupertino/5437-Warren-E.-Hyde-Middle-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5437-Warren-E.-Hyde-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "12%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 998,
        "parentRating": 5,
        "numReviews": 42,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 12
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 67
            },
            {
                "label": "African American",
                "rating": 3,
                "percentage": 2
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 11
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 15
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 3
            },
            {
                "label": "Filipino",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 5435,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.320667,
        "lon": -122.03875,
        "name": "William Faria Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "10155 Barbara Lane",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5435-William-Faria-Elementary-School/",
            "reviews": "/california/cupertino/5435-William-Faria-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5435-William-Faria-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "1%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 695,
        "parentRating": 4,
        "numReviews": 32,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 1
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 97
            },
            {
                "label": "White",
                "percentage": 1
            },
            {
                "label": "Two or more races",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 5447,
        "districtId": 631,
        "districtName": "Cupertino Union School District",
        "districtCity": "Sunnyvale",
        "levelCode": "e",
        "lat": 37.303967,
        "lon": -122.047997,
        "name": "William Regnart Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "1170 Yorkshire Drive",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/5447-William-Regnart-Elementary-School/",
            "reviews": "/california/cupertino/5447-William-Regnart-Elementary-School/#Reviews",
            "collegeSuccess": "/california/cupertino/5447-William-Regnart-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "4%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 452,
        "parentRating": 4,
        "numReviews": 38,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 4
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 74
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 14
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 30482,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.336266,
        "lon": -122.060562,
        "name": "Wonderland Chinese Pe-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "10931 Maxine Avenue",
            "street2": "",
            "zip": "95014",
            "city": "Cupertino"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "95014",
        "type": "school",
        "links": {
            "profile": "/california/cupertino/30482-Wonderland-Chinese-Pe-School/",
            "reviews": "/california/cupertino/30482-Wonderland-Chinese-Pe-School/#Reviews",
            "collegeSuccess": "/california/cupertino/30482-Wonderland-Chinese-Pe-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    }
]