exports.SAN_RAMON_SCHOOLS = [{'address': {'city': 'San Ramon',
                                  'street1': '2849 Calais Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 544,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 5},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 38, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 13,
                                         'rating': 8},
                                        {'label': 'White', 'percentage': 34, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 9,
                                         'rating': 9},
                                        {'label': 'Filipino', 'percentage': 4},
                                        {'label': 'African American', 'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 534,
                      'lat': 37.740612,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/534-Neil-A.-Armstrong-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/534-Neil-A.-Armstrong-Elementary-School/',
                          'reviews': '/california/san-ramon/534-Neil-A.-Armstrong-Elementary-School/#Reviews'},
                      'lon': -121.948196,
                      'name': 'Neil A. Armstrong Elementary School',
                      'numReviews': 45,
                      'parentRating': 4,
                      'percentLowIncome': '5%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 26,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 8,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '2300 Talavera Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 518,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 8},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 42, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 12,
                                         'rating': 9},
                                        {'label': 'White', 'percentage': 29, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 9,
                                         'rating': 10},
                                        {'label': 'Filipino', 'percentage': 4},
                                        {'label': 'African American', 'percentage': 2},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1},
                                        {'label': 'Pacific Islander', 'percentage': 1}],
                      'gradeLevels': 'PK-5',
                      'highlighted': false,
                      'id': 536,
                      'lat': 37.760624,
                      'levelCode': 'p,e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/536-Bollinger-Canyon-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/536-Bollinger-Canyon-Elementary-School/',
                          'reviews': '/california/san-ramon/536-Bollinger-Canyon-Elementary-School/#Reviews'},
                      'lon': -121.970253,
                      'name': 'Bollinger Canyon Elementary School',
                      'numReviews': 26,
                      'parentRating': 5,
                      'percentLowIncome': '8%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 24,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 9,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '9870 Broadmoor Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {'school_value': '85%',
                                                'state_average': '64%'},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 2777,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 6,
                                         'rating': 6},
                                        {'label': 'All students', 'rating': 9},
                                        {'label': 'Asian', 'percentage': 31, 'rating': 10},
                                        {'label': 'African American',
                                         'percentage': 2,
                                         'rating': 6},
                                        {'label': 'Hispanic',
                                         'percentage': 12,
                                         'rating': 9},
                                        {'label': 'White', 'percentage': 44, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 7,
                                         'rating': 10},
                                        {'label': 'Filipino',
                                         'percentage': 4,
                                         'rating': 9}],
                      'gradeLevels': '9-12',
                      'highlighted': false,
                      'id': 537,
                      'lat': 37.747295,
                      'levelCode': 'h',
                      'links': {'collegeSuccess': '/california/san-ramon/537-California-High-School/#College_success',
                                'profile': '/california/san-ramon/537-California-High-School/',
                                'reviews': '/california/san-ramon/537-California-High-School/#Reviews'},
                      'lon': -121.947578,
                      'name': 'California High School',
                      'numReviews': 22,
                      'parentRating': 4,
                      'percentLowIncome': '6%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 24,
                      'subratings': {'College Readiness Rating': 10,
                                     'Equity Overview Rating': 7,
                                     'Test Scores Rating': 9},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '7534 Blue Fox Way',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 552,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 10,
                                         'rating': 6},
                                        {'label': 'All students', 'rating': 9},
                                        {'label': 'Asian', 'percentage': 37, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 14,
                                         'rating': 7},
                                        {'label': 'White', 'percentage': 34, 'rating': 9},
                                        {'label': 'Two or more races', 'percentage': 8},
                                        {'label': 'Filipino', 'percentage': 5},
                                        {'label': 'African American', 'percentage': 2},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 538,
                      'lat': 37.728138,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/538-Country-Club-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/538-Country-Club-Elementary-School/',
                          'reviews': '/california/san-ramon/538-Country-Club-Elementary-School/#Reviews'},
                      'lon': -121.939919,
                      'name': 'Country Club Elementary School',
                      'numReviews': 32,
                      'parentRating': 4,
                      'percentLowIncome': '10%',
                      'pinned': null,
                      'rating': 8,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 23,
                      'subratings': {'Academic Progress Rating': 7,
                                     'Equity Overview Rating': 6,
                                     'Test Scores Rating': 9},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '3250 Pine Valley Road',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 525,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 6},
                                        {'label': 'All students', 'rating': 9},
                                        {'label': 'Asian', 'percentage': 33, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 9,
                                         'rating': 9},
                                        {'label': 'White', 'percentage': 43, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 10,
                                         'rating': 10},
                                        {'label': 'African American', 'percentage': 2},
                                        {'label': 'Filipino', 'percentage': 2},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 540,
                      'lat': 37.741188,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/540-Walt-Disney-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/540-Walt-Disney-Elementary-School/',
                          'reviews': '/california/san-ramon/540-Walt-Disney-Elementary-School/#Reviews'},
                      'lon': -121.936211,
                      'name': 'Walt Disney Elementary School',
                      'numReviews': 8,
                      'parentRating': 5,
                      'percentLowIncome': '6%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 28,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 10,
                                     'Test Scores Rating': 9},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '5025 Canyon Crest Drive',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 668,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 6},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 38, 'rating': 10},
                                        {'label': 'White', 'percentage': 36, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 8,
                                         'rating': 9},
                                        {'label': 'Hispanic', 'percentage': 10},
                                        {'label': 'Filipino', 'percentage': 5},
                                        {'label': 'African American', 'percentage': 2},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 541,
                      'lat': 37.787041,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/541-Golden-View-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/541-Golden-View-Elementary-School/',
                          'reviews': '/california/san-ramon/541-Golden-View-Elementary-School/#Reviews'},
                      'lon': -121.943047,
                      'name': 'Golden View Elementary School',
                      'numReviews': 26,
                      'parentRating': 4,
                      'percentLowIncome': '6%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 24,
                      'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '12601 Alcosta Boulevard',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 1069,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 8},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 39, 'rating': 10},
                                        {'label': 'African American',
                                         'percentage': 2,
                                         'rating': 9},
                                        {'label': 'Hispanic',
                                         'percentage': 12,
                                         'rating': 8},
                                        {'label': 'White', 'percentage': 35, 'rating': 10},
                                        {'label': 'Two or more races',
                                         'percentage': 9,
                                         'rating': 9},
                                        {'label': 'Filipino',
                                         'percentage': 3,
                                         'rating': 10}],
                      'gradeLevels': '6-8',
                      'highlighted': false,
                      'id': 544,
                      'lat': 37.770905,
                      'levelCode': 'm',
                      'links': {'collegeSuccess': '/california/san-ramon/544-Iron-Horse-Middle-School/#College_success',
                                'profile': '/california/san-ramon/544-Iron-Horse-Middle-School/',
                                'reviews': '/california/san-ramon/544-Iron-Horse-Middle-School/#Reviews'},
                      'lon': -121.956879,
                      'name': 'Iron Horse Middle School',
                      'numReviews': 13,
                      'parentRating': 4,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 26,
                      'subratings': {'Academic Progress Rating': 7,
                                     'Equity Overview Rating': 9,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '13000 Broadmoor Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 658,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 7},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 52, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 13,
                                         'rating': 7},
                                        {'label': 'White', 'percentage': 21, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 9,
                                         'rating': 10},
                                        {'label': 'Filipino', 'percentage': 4},
                                        {'label': 'African American', 'percentage': 1},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 548,
                      'lat': 37.754986,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/548-Montevideo-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/548-Montevideo-Elementary-School/',
                          'reviews': '/california/san-ramon/548-Montevideo-Elementary-School/#Reviews'},
                      'lon': -121.951172,
                      'name': 'Montevideo Elementary School',
                      'numReviews': 18,
                      'parentRating': 4,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 26,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 7,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '3000 Pine Valley Road',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 1049,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 7},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 31, 'rating': 10},
                                        {'label': 'African American',
                                         'percentage': 2,
                                         'rating': 5},
                                        {'label': 'Hispanic',
                                         'percentage': 13,
                                         'rating': 9},
                                        {'label': 'White', 'percentage': 41, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 10,
                                         'rating': 9},
                                        {'label': 'Filipino',
                                         'percentage': 4,
                                         'rating': 10}],
                      'gradeLevels': '6-8',
                      'highlighted': false,
                      'id': 549,
                      'lat': 37.738777,
                      'levelCode': 'm',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/549-Pine-Valley-Middle-School/#College_success',
                          'profile': '/california/san-ramon/549-Pine-Valley-Middle-School/',
                          'reviews': '/california/san-ramon/549-Pine-Valley-Middle-School/#Reviews'},
                      'lon': -121.943512,
                      'name': 'Pine Valley Middle School',
                      'numReviews': 32,
                      'parentRating': 4,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 26,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 8,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '2785 Marsh Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 557,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 9},
                                        {'label': 'All students', 'rating': 9},
                                        {'label': 'Asian', 'percentage': 29, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 19,
                                         'rating': 7},
                                        {'label': 'White', 'percentage': 33, 'rating': 8},
                                        {'label': 'Two or more races',
                                         'percentage': 13,
                                         'rating': 9},
                                        {'label': 'Filipino', 'percentage': 5},
                                        {'label': 'African American', 'percentage': 2}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 555,
                      'lat': 37.767609,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/555-Twin-Creeks-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/555-Twin-Creeks-Elementary-School/',
                          'reviews': '/california/san-ramon/555-Twin-Creeks-Elementary-School/#Reviews'},
                      'lon': -121.985909,
                      'name': 'Twin Creeks Elementary School',
                      'numReviews': 34,
                      'parentRating': 4,
                      'percentLowIncome': '9%',
                      'pinned': null,
                      'rating': 8,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 24,
                      'subratings': {'Academic Progress Rating': 6,
                                     'Equity Overview Rating': 7,
                                     'Test Scores Rating': 9},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '74 Elmwood Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 28590,
                      'lat': 37.726116,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/28590-Morninglight-Education-Dc/#College_success',
                          'profile': '/california/san-ramon/28590-Morninglight-Education-Dc/',
                          'reviews': '/california/san-ramon/28590-Morninglight-Education-Dc/#Reviews'},
                      'lon': -121.931213,
                      'name': 'Morninglight Education Dc',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '800 Henley Court',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 30189,
                      'lat': 37.747204,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/30189-Safari-Kid-Child-Care-Center-Little-Hearts/#College_success',
                          'profile': '/california/san-ramon/30189-Safari-Kid-Child-Care-Center-Little-Hearts/',
                          'reviews': '/california/san-ramon/30189-Safari-Kid-Child-Care-Center-Little-Hearts/#Reviews'},
                      'lon': -121.993568,
                      'name': 'Safari Kid Child Care Center-Little Hearts',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '100 Gatekeeper Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 32352,
                      'lat': 37.760365,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/32352-The-Goddard-School---San-Ramon/#College_success',
                          'profile': '/california/san-ramon/32352-The-Goddard-School---San-Ramon/',
                          'reviews': '/california/san-ramon/32352-The-Goddard-School---San-Ramon/#Reviews'},
                      'lon': -121.91291,
                      'name': 'The Goddard School - San Ramon',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '1050 Trumpet Vine Lane',
                                  'street2': null,
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 493,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 14,
                                         'rating': 1},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 69, 'rating': 10},
                                        {'label': 'White', 'percentage': 10, 'rating': 8},
                                        {'label': 'Hispanic', 'percentage': 7},
                                        {'label': 'Two or more races', 'percentage': 6},
                                        {'label': 'Filipino', 'percentage': 3},
                                        {'label': 'African American', 'percentage': 3},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 32481,
                      'lat': 37.779926,
                      'levelCode': 'e',
                      'links': {'collegeSuccess': '/california/san-ramon/32481-Bella-Vista-Elementary/#College_success',
                                'profile': '/california/san-ramon/32481-Bella-Vista-Elementary/',
                                'reviews': '/california/san-ramon/32481-Bella-Vista-Elementary/#Reviews'},
                      'lon': -121.978012,
                      'name': 'Bella Vista Elementary',
                      'numReviews': 7,
                      'parentRating': 5,
                      'percentLowIncome': '14%',
                      'pinned': null,
                      'rating': 7,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'subratings': {'Academic Progress Rating': 7,
                                     'Equity Overview Rating': 1,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '2603 Camino Ramon',
                                  'street2': 'Suite 150',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 32291,
                      'lat': 37.767754,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/32291-Bright-Horizons-At-Bishop-Ranch/#College_success',
                          'profile': '/california/san-ramon/32291-Bright-Horizons-At-Bishop-Ranch/',
                          'reviews': '/california/san-ramon/32291-Bright-Horizons-At-Bishop-Ranch/#Reviews'},
                      'lon': -121.959267,
                      'name': 'Bright Horizons at Bishop Ranch',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': 'One Annabel Lane, San Ramon, CA 94583',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 337,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-8',
                      'highlighted': false,
                      'id': 8934,
                      'lat': 37.772743,
                      'levelCode': 'p,e,m',
                      'links': {'collegeSuccess': '/california/san-ramon/8934-Dorris-Eaton-School-The/#College_success',
                                'profile': '/california/san-ramon/8934-Dorris-Eaton-School-The/',
                                'reviews': '/california/san-ramon/8934-Dorris-Eaton-School-The/#Reviews'},
                      'lon': -121.972099,
                      'name': 'Dorris-Eaton School, The',
                      'numReviews': 33,
                      'parentRating': 4,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '2762 Derby Dr',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-2, 4-5',
                      'highlighted': false,
                      'id': 10419,
                      'lat': 37.755501,
                      'levelCode': 'p,e',
                      'links': {'collegeSuccess': '/california/san-ramon/10419-CA-Christian-Academy/#College_success',
                                'profile': '/california/san-ramon/10419-CA-Christian-Academy/',
                                'reviews': '/california/san-ramon/10419-CA-Christian-Academy/#Reviews'},
                      'lon': -121.973801,
                      'name': 'CA Christian Academy',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '19001 San Ramon Valley Blvd',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'K-6',
                      'highlighted': false,
                      'id': 31666,
                      'lat': 37.749416,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/31666-Heritage-Academy---San-Ramon/#College_success',
                          'profile': '/california/san-ramon/31666-Heritage-Academy---San-Ramon/',
                          'reviews': '/california/san-ramon/31666-Heritage-Academy---San-Ramon/#Reviews'},
                      'lon': -121.960388,
                      'name': 'Heritage Academy - San Ramon',
                      'numReviews': 5,
                      'parentRating': 5,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '18868 Bollinger Canyon Road',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 99,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                        {'label': 'White', 'percentage': 55},
                                        {'label': 'Asian', 'percentage': 45}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 10805,
                      'lat': 37.779568,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/10805-The-Child-Day-School---San-Ramon---Hidden-Canyon/#College_success',
                          'profile': '/california/san-ramon/10805-The-Child-Day-School---San-Ramon---Hidden-Canyon/',
                          'reviews': '/california/san-ramon/10805-The-Child-Day-School---San-Ramon---Hidden-Canyon/#Reviews'},
                      'lon': -121.997665,
                      'name': 'The Child Day School - San Ramon / Hidden Canyon',
                      'numReviews': 1,
                      'parentRating': 4,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '5075 Crow Canyon Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 10814,
                      'lat': 37.787251,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/10814-Acorn-Learning-Center-San-Ramon/#College_success',
                          'profile': '/california/san-ramon/10814-Acorn-Learning-Center-San-Ramon/',
                          'reviews': '/california/san-ramon/10814-Acorn-Learning-Center-San-Ramon/#Reviews'},
                      'lon': -121.933128,
                      'name': 'Acorn Learning Center-San Ramon',
                      'numReviews': 2,
                      'parentRating': 5,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '10540 Albion Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {'school_value': '60%',
                                                'state_average': '64%'},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 154,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 6},
                                        {'label': 'White', 'percentage': 56},
                                        {'label': 'Hispanic', 'percentage': 16},
                                        {'label': 'Asian', 'percentage': 15},
                                        {'label': 'Two or more races', 'percentage': 7},
                                        {'label': 'Filipino', 'percentage': 3},
                                        {'label': 'Pacific Islander', 'percentage': 1},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1},
                                        {'label': 'African American', 'percentage': 1}],
                      'gradeLevels': 'K-12',
                      'highlighted': false,
                      'id': 10889,
                      'lat': 37.767193,
                      'levelCode': 'e,m,h',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/10889-Venture-Alternative-School/#College_success',
                          'profile': '/california/san-ramon/10889-Venture-Alternative-School/',
                          'reviews': '/california/san-ramon/10889-Venture-Alternative-School/#Reviews'},
                      'lon': -121.903831,
                      'name': 'Venture (Alternative) School',
                      'numReviews': 9,
                      'parentRating': 4,
                      'percentLowIncome': '6%',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 15,
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '8700 North Gale Ridge Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 920,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 6,
                                         'rating': 7},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 61, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 6,
                                         'rating': 10},
                                        {'label': 'White', 'percentage': 19, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 9,
                                         'rating': 9},
                                        {'label': 'Filipino', 'percentage': 3},
                                        {'label': 'African American', 'percentage': 2}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 11697,
                      'lat': 37.778118,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/11697-Coyote-Creek-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/11697-Coyote-Creek-Elementary-School/',
                          'reviews': '/california/san-ramon/11697-Coyote-Creek-Elementary-School/#Reviews'},
                      'lon': -121.928062,
                      'name': 'Coyote Creek Elementary School',
                      'numReviews': 77,
                      'parentRating': 3,
                      'percentLowIncome': '6%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 20,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 8,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '50 Creekside Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 13399,
                      'lat': 37.772556,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/13399-Diablo-Hills-Country-School/#College_success',
                          'profile': '/california/san-ramon/13399-Diablo-Hills-Country-School/',
                          'reviews': '/california/san-ramon/13399-Diablo-Hills-Country-School/#Reviews'},
                      'lon': -121.991547,
                      'name': 'Diablo Hills Country School',
                      'numReviews': 2,
                      'parentRating': 3,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '12995 Harcourt Way',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 708,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 8},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 73, 'rating': 10},
                                        {'label': 'White', 'percentage': 8, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 7,
                                         'rating': 10},
                                        {'label': 'Filipino', 'percentage': 5},
                                        {'label': 'Hispanic', 'percentage': 3},
                                        {'label': 'African American', 'percentage': 3}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 13634,
                      'lat': 37.764172,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/13634-Hidden-Hills-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/13634-Hidden-Hills-Elementary-School/',
                          'reviews': '/california/san-ramon/13634-Hidden-Hills-Elementary-School/#Reviews'},
                      'lon': -121.893211,
                      'name': 'Hidden Hills Elementary School',
                      'numReviews': 20,
                      'parentRating': 4,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 27,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 9,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '11611 East Branch Parkway',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 1355,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 6,
                                         'rating': 8},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 74, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 3,
                                         'rating': 9},
                                        {'label': 'White', 'percentage': 8, 'rating': 10},
                                        {'label': 'Two or more races',
                                         'percentage': 6,
                                         'rating': 10},
                                        {'label': 'Filipino',
                                         'percentage': 6,
                                         'rating': 10},
                                        {'label': 'African American', 'percentage': 2}],
                      'gradeLevels': '6-8',
                      'highlighted': false,
                      'id': 13659,
                      'lat': 37.752022,
                      'levelCode': 'm',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/13659-Windemere-Ranch-Middle-School/#College_success',
                          'profile': '/california/san-ramon/13659-Windemere-Ranch-Middle-School/',
                          'reviews': '/california/san-ramon/13659-Windemere-Ranch-Middle-School/#Reviews'},
                      'lon': -121.905663,
                      'name': 'Windemere Ranch Middle School',
                      'numReviews': 22,
                      'parentRating': 4,
                      'percentLowIncome': '6%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 25,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 9,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '4000 Goldenbay Avenue',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 949,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 5},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 73, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 6,
                                         'rating': 10},
                                        {'label': 'White', 'percentage': 7, 'rating': 10},
                                        {'label': 'Filipino',
                                         'percentage': 6,
                                         'rating': 9},
                                        {'label': 'Two or more races', 'percentage': 4},
                                        {'label': 'African American', 'percentage': 3},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 13755,
                      'lat': 37.761467,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/13755-Quail-Run-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/13755-Quail-Run-Elementary-School/',
                          'reviews': '/california/san-ramon/13755-Quail-Run-Elementary-School/#Reviews'},
                      'lon': -121.911659,
                      'name': 'Quail Run Elementary School',
                      'numReviews': 37,
                      'parentRating': 4,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 24,
                      'subratings': {'Academic Progress Rating': 7,
                                     'Equity Overview Rating': 7,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '10550 Albion Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {'school_value': '86%',
                                                'state_average': '64%'},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 3331,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 10},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 69, 'rating': 10},
                                        {'label': 'Hispanic',
                                         'percentage': 5,
                                         'rating': 10},
                                        {'label': 'White', 'percentage': 12, 'rating': 10},
                                        {'label': 'Two or more races',
                                         'percentage': 5,
                                         'rating': 10},
                                        {'label': 'Filipino',
                                         'percentage': 6,
                                         'rating': 10},
                                        {'label': 'African American', 'percentage': 3}],
                      'gradeLevels': '9-12',
                      'highlighted': false,
                      'id': 17434,
                      'lat': 37.767647,
                      'levelCode': 'h',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/17434-Dougherty-Valley-High-School/#College_success',
                          'profile': '/california/san-ramon/17434-Dougherty-Valley-High-School/',
                          'reviews': '/california/san-ramon/17434-Dougherty-Valley-High-School/#Reviews'},
                      'lon': -121.90229,
                      'name': 'Dougherty Valley High School',
                      'numReviews': 73,
                      'parentRating': 3,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 10,
                      'ratingScale': 'Top rated',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 24,
                      'subratings': {'College Readiness Rating': 10,
                                     'Equity Overview Rating': 10,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '5151 Sherwood Way',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 819,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 5,
                                         'rating': 6},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 71, 'rating': 10},
                                        {'label': 'White', 'percentage': 8, 'rating': 10},
                                        {'label': 'Two or more races',
                                         'percentage': 6,
                                         'rating': 10},
                                        {'label': 'Filipino', 'percentage': 8},
                                        {'label': 'Hispanic', 'percentage': 6},
                                        {'label': 'American Indian/Alaska Native',
                                         'percentage': 1},
                                        {'label': 'African American', 'percentage': 1}],
                      'gradeLevels': 'K-5',
                      'highlighted': false,
                      'id': 17639,
                      'lat': 37.753502,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/17639-Live-Oak-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/17639-Live-Oak-Elementary-School/',
                          'reviews': '/california/san-ramon/17639-Live-Oak-Elementary-School/#Reviews'},
                      'lon': -121.896202,
                      'name': 'Live Oak Elementary School',
                      'numReviews': 22,
                      'parentRating': 4,
                      'percentLowIncome': '5%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 26,
                      'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '1001 Market Place',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22620,
                      'lat': 37.761711,
                      'levelCode': 'p',
                      'links': {'collegeSuccess': '/california/san-ramon/22620-La-Petite-Academy-1505/#College_success',
                                'profile': '/california/san-ramon/22620-La-Petite-Academy-1505/',
                                'reviews': '/california/san-ramon/22620-La-Petite-Academy-1505/#Reviews'},
                      'lon': -121.951813,
                      'name': 'La Petite Academy #1505',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '17025 Bollinger Canyon Rd.',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 22622,
                      'lat': 37.754459,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22622-Acorn-Learning-Center-Dougherty/#College_success',
                          'profile': '/california/san-ramon/22622-Acorn-Learning-Center-Dougherty/',
                          'reviews': '/california/san-ramon/22622-Acorn-Learning-Center-Dougherty/#Reviews'},
                      'lon': -121.909187,
                      'name': 'Acorn Learning Center-Dougherty',
                      'numReviews': 7,
                      'parentRating': 5,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '18868 Bollinger Canyon Road',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 22624,
                      'lat': 37.779568,
                      'levelCode': 'p',
                      'links': {'collegeSuccess': '/california/san-ramon/22624-Child-Day-School/#College_success',
                                'profile': '/california/san-ramon/22624-Child-Day-School/',
                                'reviews': '/california/san-ramon/22624-Child-Day-School/#Reviews'},
                      'lon': -121.997665,
                      'name': 'Child Day School',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '19901 San Ramon Valley Boulevard',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22625,
                      'lat': 37.73624,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22625-Joyful-Noise-Pre-School/#College_success',
                          'profile': '/california/san-ramon/22625-Joyful-Noise-Pre-School/',
                          'reviews': '/california/san-ramon/22625-Joyful-Noise-Pre-School/#Reviews'},
                      'lon': -121.951942,
                      'name': 'Joyful Noise Pre-School',
                      'numReviews': 1,
                      'parentRating': 2,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '20801 San Ramon Valley Boulevard',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22626,
                      'lat': 37.732246,
                      'levelCode': 'p',
                      'links': {'collegeSuccess': '/california/san-ramon/22626-Happy-Days-Pre-School/#College_success',
                                'profile': '/california/san-ramon/22626-Happy-Days-Pre-School/',
                                'reviews': '/california/san-ramon/22626-Happy-Days-Pre-School/#Reviews'},
                      'lon': -121.948631,
                      'name': 'Happy Days Pre-School',
                      'numReviews': 1,
                      'parentRating': 5,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '210 Porter Drive #110',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 22627,
                      'lat': 37.773251,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22627-Teddy-Bears-Childrens-Cen/#College_success',
                          'profile': '/california/san-ramon/22627-Teddy-Bears-Childrens-Cen/',
                          'reviews': '/california/san-ramon/22627-Teddy-Bears-Childrens-Cen/#Reviews'},
                      'lon': -121.990967,
                      'name': "Teddy Bears Children's Cen",
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '2691 Crow Canyon Road',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22628,
                      'lat': 37.773746,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22628-Stepping-Stones-Learning-Center/#College_success',
                          'profile': '/california/san-ramon/22628-Stepping-Stones-Learning-Center/',
                          'reviews': '/california/san-ramon/22628-Stepping-Stones-Learning-Center/#Reviews'},
                      'lon': -121.985039,
                      'name': 'Stepping Stones Learning Center',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '790 S Dora St',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22631,
                      'lat': 37.779926,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22631-Yokayo-State-Pre-School/#College_success',
                          'profile': '/california/san-ramon/22631-Yokayo-State-Pre-School/',
                          'reviews': '/california/san-ramon/22631-Yokayo-State-Pre-School/#Reviews'},
                      'lon': -121.978012,
                      'name': 'Yokayo State Pre-School',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '8700 North Gale Ridge Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22632,
                      'lat': 37.778019,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22632-Kids-Cnty-Child-Care-Center-Coyote-Creek/#College_success',
                          'profile': '/california/san-ramon/22632-Kids-Cnty-Child-Care-Center-Coyote-Creek/',
                          'reviews': '/california/san-ramon/22632-Kids-Cnty-Child-Care-Center-Coyote-Creek/#Reviews'},
                      'lon': -121.927361,
                      'name': "Kids' Cnty Child Care Center-Coyote Creek",
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '9015 South Gale Ridge Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 22633,
                      'lat': 37.771893,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/22633-Little-Bridges-Child-Care-Center/#College_success',
                          'profile': '/california/san-ramon/22633-Little-Bridges-Child-Care-Center/',
                          'reviews': '/california/san-ramon/22633-Little-Bridges-Child-Care-Center/#Reviews'},
                      'lon': -121.930908,
                      'name': 'Little Bridges Child Care Center',
                      'numReviews': 1,
                      'parentRating': 5,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '18868 Bollinger Canyon Rd',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'K',
                      'highlighted': false,
                      'id': 24289,
                      'lat': 37.77953,
                      'levelCode': 'e',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/24289-Hidden-Canyon-Elementary-School/#College_success',
                          'profile': '/california/san-ramon/24289-Hidden-Canyon-Elementary-School/',
                          'reviews': '/california/san-ramon/24289-Hidden-Canyon-Elementary-School/#Reviews'},
                      'lon': -121.997322,
                      'name': 'Hidden Canyon Elementary School',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '6400 Main Branch Road',
                                  'street2': '',
                                  'zip': '94582'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': 'Danville',
                      'districtId': 55,
                      'districtName': 'San Ramon Valley Unified School District',
                      'enrollment': 1262,
                      'ethnicityInfo': [{'label': 'Low-income',
                                         'percentage': 7,
                                         'rating': 7},
                                        {'label': 'All students', 'rating': 10},
                                        {'label': 'Asian', 'percentage': 67, 'rating': 10},
                                        {'label': 'African American',
                                         'percentage': 4,
                                         'rating': 6},
                                        {'label': 'Hispanic',
                                         'percentage': 5,
                                         'rating': 8},
                                        {'label': 'White', 'percentage': 13, 'rating': 9},
                                        {'label': 'Two or more races',
                                         'percentage': 5,
                                         'rating': 9},
                                        {'label': 'Filipino',
                                         'percentage': 6,
                                         'rating': 10}],
                      'gradeLevels': '6-8',
                      'highlighted': false,
                      'id': 24656,
                      'lat': 37.769192,
                      'levelCode': 'm',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/24656-Gale-Ranch-Middle-School/#College_success',
                          'profile': '/california/san-ramon/24656-Gale-Ranch-Middle-School/',
                          'reviews': '/california/san-ramon/24656-Gale-Ranch-Middle-School/#Reviews'},
                      'lon': -121.910423,
                      'name': 'Gale Ranch Middle School',
                      'numReviews': 27,
                      'parentRating': 4,
                      'percentLowIncome': '7%',
                      'pinned': null,
                      'rating': 9,
                      'ratingScale': 'Above average',
                      'remediationData': [],
                      'schoolType': 'public',
                      'state': 'CA',
                      'studentsPerTeacher': 26,
                      'subratings': {'Academic Progress Rating': 8,
                                     'Equity Overview Rating': 7,
                                     'Test Scores Rating': 10},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '#100-2410 San Ramon Valley Blvd.',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 27224,
                      'lat': 37.775501,
                      'levelCode': 'p',
                      'links': {'collegeSuccess': '/california/san-ramon/27224-Panache-Enfants/#College_success',
                                'profile': '/california/san-ramon/27224-Panache-Enfants/',
                                'reviews': '/california/san-ramon/27224-Panache-Enfants/#Reviews'},
                      'lon': -121.97625,
                      'name': 'Panache Enfants',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '1021 Market Place',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK',
                      'highlighted': false,
                      'id': 27666,
                      'lat': 37.761971,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/27666-Lil-Genius-Kid-Day-Care/#College_success',
                          'profile': '/california/san-ramon/27666-Lil-Genius-Kid-Day-Care/',
                          'reviews': '/california/san-ramon/27666-Lil-Genius-Kid-Day-Care/#Reviews'},
                      'lon': -121.952202,
                      'name': 'Lil Genius Kid Day Care',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '3250 Pine Valley Road',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 27943,
                      'lat': 37.740879,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/27943-Srvsacca-Kids-Cntry-Wlt-Dsny/#College_success',
                          'profile': '/california/san-ramon/27943-Srvsacca-Kids-Cntry-Wlt-Dsny/',
                          'reviews': '/california/san-ramon/27943-Srvsacca-Kids-Cntry-Wlt-Dsny/#Reviews'},
                      'lon': -121.935989,
                      'name': 'Srvsacca-Kids Cntry Wlt Dsny',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'},
                     {'address': {'city': 'San Ramon',
                                  'street1': '13000 Broadmoor Drive',
                                  'street2': '',
                                  'zip': '94583'},
                      'assigned': null,
                      'collegeEnrollmentData': {},
                      'collegePersistentData': [],
                      'csaAwardYears': [],
                      'districtCity': null,
                      'districtId': 0,
                      'districtName': null,
                      'enrollment': 0,
                      'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                      'gradeLevels': 'PK-K',
                      'highlighted': false,
                      'id': 27975,
                      'lat': 37.755955,
                      'levelCode': 'p',
                      'links': {
                          'collegeSuccess': '/california/san-ramon/27975-Kids-Country-Montevidio/#College_success',
                          'profile': '/california/san-ramon/27975-Kids-Country-Montevidio/',
                          'reviews': '/california/san-ramon/27975-Kids-Country-Montevidio/#Reviews'},
                      'lon': -121.95134,
                      'name': 'Kids Country-Montevidio',
                      'numReviews': 0,
                      'parentRating': 0,
                      'percentLowIncome': '?',
                      'pinned': null,
                      'rating': null,
                      'ratingScale': null,
                      'remediationData': [],
                      'schoolType': 'private',
                      'state': 'CA',
                      'subratings': {},
                      'testScoreRatingForEthnicity': null,
                      'type': 'school'}]
