const livermore = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Alameda County, CA",
             "short_name": "Alameda County",
             "sumlevel": "050",
             "land_area": 1909614756,
             "full_geoid": "05000US06001",
             "total_population": 1671329
          },
          "CBSA": {
             "full_name": "San Francisco-Oakland-Berkeley, CA Metro Area",
             "short_name": "San Francisco-Oakland-Berkeley, CA",
             "sumlevel": "310",
             "land_area": 6398229497,
             "full_geoid": "31000US41860",
             "total_population": 4731803
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39512223
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Livermore, CA",
          "short_name": "Livermore",
          "sumlevel": "160",
          "land_area": 69990213,
          "full_geoid": "16000US0641992",
          "total_population": 90188,
          "sumlevel_name": "Place",
          "short_geoid": "0641992"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 23.96,
                   "CBSA": 19.53,
                   "state": 22.5
                },
                "error": {
                   "this": 2.8,
                   "CBSA": 0.2,
                   "state": 0.08
                },
                "numerators": {
                   "this": 21612,
                   "CBSA": 924120,
                   "state": 8891064
                },
                "numerator_errors": {
                   "this": 2528.4,
                   "CBSA": 9455,
                   "state": 32008.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 106
                },
                "error_ratio": {
                   "this": 11.7,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 59.78,
                   "CBSA": 64.56,
                   "state": 62.73
                },
                "error": {
                   "this": 3.95,
                   "CBSA": 0.37,
                   "state": 0.13
                },
                "numerators": {
                   "this": 53915,
                   "CBSA": 3054854,
                   "state": 2.478616E7
                },
                "numerator_errors": {
                   "this": 3560.9,
                   "CBSA": 17723.5,
                   "state": 51870.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 95
                },
                "error_ratio": {
                   "this": 6.6,
                   "CBSA": 0.6,
                   "state": 0.2
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 16.26,
                   "CBSA": 15.91,
                   "state": 14.77
                },
                "error": {
                   "this": 2.1,
                   "CBSA": 0.24,
                   "state": 0.08
                },
                "numerators": {
                   "this": 14661,
                   "CBSA": 752829,
                   "state": 5834998
                },
                "numerator_errors": {
                   "this": 1892.6,
                   "CBSA": 11568.6,
                   "state": 32031.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 110
                },
                "error_ratio": {
                   "this": 12.9,
                   "CBSA": 1.5,
                   "state": 0.5
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13.32,
                      "CBSA": 10.64,
                      "state": 12.04
                   },
                   "error": {
                      "this": 1.94,
                      "CBSA": 0.15,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 12015,
                      "CBSA": 503528,
                      "state": 4758601
                   },
                   "numerator_errors": {
                      "this": 1753,
                      "CBSA": 6865.4,
                      "state": 23052.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 111
                   },
                   "error_ratio": {
                      "this": 14.6,
                      "CBSA": 1.4,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 12.83,
                      "CBSA": 11.03,
                      "state": 13.1
                   },
                   "error": {
                      "this": 2.24,
                      "CBSA": 0.14,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 11570,
                      "CBSA": 521896,
                      "state": 5176959
                   },
                   "numerator_errors": {
                      "this": 2016.8,
                      "CBSA": 6799.8,
                      "state": 23034.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 116,
                      "state": 98
                   },
                   "error_ratio": {
                      "this": 17.5,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 10.08,
                      "CBSA": 13.42,
                      "state": 14.49
                   },
                   "error": {
                      "this": 1.86,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 9093,
                      "CBSA": 634875,
                      "state": 5723923
                   },
                   "numerator_errors": {
                      "this": 1681.3,
                      "CBSA": 7995.9,
                      "state": 24219.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 75,
                      "state": 70
                   },
                   "error_ratio": {
                      "this": 18.5,
                      "CBSA": 1.3,
                      "state": 0.4
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.26,
                      "CBSA": 16.12,
                      "state": 14.47
                   },
                   "error": {
                      "this": 1.78,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 12864,
                      "CBSA": 762863,
                      "state": 5715837
                   },
                   "numerator_errors": {
                      "this": 1606.8,
                      "CBSA": 8035.9,
                      "state": 22852.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 88,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 12.5,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.96,
                      "CBSA": 13.71,
                      "state": 12.77
                   },
                   "error": {
                      "this": 1.82,
                      "CBSA": 0.17,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 12587,
                      "CBSA": 648707,
                      "state": 5046965
                   },
                   "numerator_errors": {
                      "this": 1638,
                      "CBSA": 8025.4,
                      "state": 22530.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 13,
                      "CBSA": 1.2,
                      "state": 0.5
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 14.98,
                      "CBSA": 13.12,
                      "state": 12.46
                   },
                   "error": {
                      "this": 1.92,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 13513,
                      "CBSA": 620802,
                      "state": 4923782
                   },
                   "numerator_errors": {
                      "this": 1727.8,
                      "CBSA": 7116.2,
                      "state": 20800
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 114,
                      "state": 120
                   },
                   "error_ratio": {
                      "this": 12.8,
                      "CBSA": 1.1,
                      "state": 0.4
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.3,
                      "CBSA": 10.91,
                      "state": 10.63
                   },
                   "error": {
                      "this": 1.72,
                      "CBSA": 0.23,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 9292,
                      "CBSA": 516448,
                      "state": 4200080
                   },
                   "numerator_errors": {
                      "this": 1551,
                      "CBSA": 10661.7,
                      "state": 31191.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 94,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 16.7,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.25,
                      "CBSA": 7.02,
                      "state": 6.44
                   },
                   "error": {
                      "this": 1.24,
                      "CBSA": 0.15,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 5635,
                      "CBSA": 332170,
                      "state": 2545061
                   },
                   "numerator_errors": {
                      "this": 1120.2,
                      "CBSA": 7111.4,
                      "state": 19797.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 89,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 19.8,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.01,
                      "CBSA": 4.03,
                      "state": 3.6
                   },
                   "error": {
                      "this": 0.98,
                      "CBSA": 0.13,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 3619,
                      "CBSA": 190514,
                      "state": 1421015
                   },
                   "numerator_errors": {
                      "this": 881.3,
                      "CBSA": 6014.8,
                      "state": 16282.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 111
                   },
                   "error_ratio": {
                      "this": 24.4,
                      "CBSA": 3.2,
                      "state": 1.1
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 15.49,
                      "CBSA": 11.01,
                      "state": 12.35
                   },
                   "error": {
                      "this": 2.9,
                      "CBSA": 0.22,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6829,
                      "CBSA": 257668,
                      "state": 2425066
                   },
                   "numerator_errors": {
                      "this": 1313.1,
                      "CBSA": 5167.5,
                      "state": 17537.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 141,
                      "state": 125
                   },
                   "error_ratio": {
                      "this": 18.7,
                      "CBSA": 2,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 11.93,
                      "CBSA": 11.23,
                      "state": 13.47
                   },
                   "error": {
                      "this": 3.41,
                      "CBSA": 0.22,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 5257,
                      "CBSA": 262840,
                      "state": 2646132
                   },
                   "numerator_errors": {
                      "this": 1521.1,
                      "CBSA": 5150.6,
                      "state": 17211.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 106,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 28.6,
                      "CBSA": 2,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 9.84,
                      "CBSA": 13.8,
                      "state": 15.06
                   },
                   "error": {
                      "this": 2.44,
                      "CBSA": 0.22,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 4339,
                      "CBSA": 322820,
                      "state": 2956969
                   },
                   "numerator_errors": {
                      "this": 1092.8,
                      "CBSA": 5263.7,
                      "state": 16123.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 71,
                      "state": 65
                   },
                   "error_ratio": {
                      "this": 24.8,
                      "CBSA": 1.6,
                      "state": 0.5
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.52,
                      "CBSA": 16.65,
                      "state": 14.93
                   },
                   "error": {
                      "this": 2.56,
                      "CBSA": 0.27,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6401,
                      "CBSA": 389458,
                      "state": 2932306
                   },
                   "numerator_errors": {
                      "this": 1162.9,
                      "CBSA": 6428.5,
                      "state": 17684.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 87,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 17.6,
                      "CBSA": 1.6,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.22,
                      "CBSA": 13.86,
                      "state": 12.82
                   },
                   "error": {
                      "this": 2.5,
                      "CBSA": 0.26,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 6268,
                      "CBSA": 324176,
                      "state": 2518785
                   },
                   "numerator_errors": {
                      "this": 1134.6,
                      "CBSA": 6099,
                      "state": 17201
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 111
                   },
                   "error_ratio": {
                      "this": 17.6,
                      "CBSA": 1.9,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 15.91,
                      "CBSA": 13.33,
                      "state": 12.42
                   },
                   "error": {
                      "this": 2.84,
                      "CBSA": 0.24,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 7014,
                      "CBSA": 311977,
                      "state": 2439055
                   },
                   "numerator_errors": {
                      "this": 1285.5,
                      "CBSA": 5512.8,
                      "state": 15253.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 119,
                      "state": 128
                   },
                   "error_ratio": {
                      "this": 17.9,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.35,
                      "CBSA": 10.47,
                      "state": 10.2
                   },
                   "error": {
                      "this": 2.08,
                      "CBSA": 0.33,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 3681,
                      "CBSA": 244988,
                      "state": 2004301
                   },
                   "numerator_errors": {
                      "this": 931.2,
                      "CBSA": 7814.5,
                      "state": 21480.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 80,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 24.9,
                      "CBSA": 3.2,
                      "state": 1.1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.11,
                      "CBSA": 6.41,
                      "state": 5.89
                   },
                   "error": {
                      "this": 1.65,
                      "CBSA": 0.21,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 2695,
                      "CBSA": 150043,
                      "state": 1156395
                   },
                   "numerator_errors": {
                      "this": 736.8,
                      "CBSA": 4926.3,
                      "state": 13944.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 95,
                      "state": 104
                   },
                   "error_ratio": {
                      "this": 27,
                      "CBSA": 3.3,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.63,
                      "CBSA": 3.24,
                      "state": 2.86
                   },
                   "error": {
                      "this": 1.39,
                      "CBSA": 0.16,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 1599,
                      "CBSA": 75746,
                      "state": 561785
                   },
                   "numerator_errors": {
                      "this": 616,
                      "CBSA": 3743.5,
                      "state": 10571.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 112,
                      "state": 127
                   },
                   "error_ratio": {
                      "this": 38.3,
                      "CBSA": 4.9,
                      "state": 1.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.25,
                      "CBSA": 10.28,
                      "state": 11.74
                   },
                   "error": {
                      "this": 2.48,
                      "CBSA": 0.19,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 5186,
                      "CBSA": 245860,
                      "state": 2333535
                   },
                   "numerator_errors": {
                      "this": 1161.3,
                      "CBSA": 4520.1,
                      "state": 14962.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 109,
                      "state": 96
                   },
                   "error_ratio": {
                      "this": 22,
                      "CBSA": 1.8,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 13.69,
                      "CBSA": 10.83,
                      "state": 12.74
                   },
                   "error": {
                      "this": 2.82,
                      "CBSA": 0.19,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 6313,
                      "CBSA": 259056,
                      "state": 2530827
                   },
                   "numerator_errors": {
                      "this": 1324.3,
                      "CBSA": 4439.4,
                      "state": 15307.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 126,
                      "state": 107
                   },
                   "error_ratio": {
                      "this": 20.6,
                      "CBSA": 1.8,
                      "state": 0.6
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 10.31,
                      "CBSA": 13.05,
                      "state": 13.92
                   },
                   "error": {
                      "this": 2.74,
                      "CBSA": 0.25,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 4754,
                      "CBSA": 312055,
                      "state": 2766954
                   },
                   "numerator_errors": {
                      "this": 1277.7,
                      "CBSA": 6019,
                      "state": 18072.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 79,
                      "state": 74
                   },
                   "error_ratio": {
                      "this": 26.6,
                      "CBSA": 1.9,
                      "state": 0.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.02,
                      "CBSA": 15.61,
                      "state": 14.01
                   },
                   "error": {
                      "this": 2.34,
                      "CBSA": 0.2,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6463,
                      "CBSA": 373405,
                      "state": 2783531
                   },
                   "numerator_errors": {
                      "this": 1108.7,
                      "CBSA": 4821.8,
                      "state": 14474.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 90,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 16.7,
                      "CBSA": 1.3,
                      "state": 0.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 13.71,
                      "CBSA": 13.57,
                      "state": 12.72
                   },
                   "error": {
                      "this": 2.5,
                      "CBSA": 0.22,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6319,
                      "CBSA": 324531,
                      "state": 2528180
                   },
                   "numerator_errors": {
                      "this": 1181.5,
                      "CBSA": 5216.2,
                      "state": 14551.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 18.2,
                      "CBSA": 1.6,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 14.1,
                      "CBSA": 12.91,
                      "state": 12.5
                   },
                   "error": {
                      "this": 2.44,
                      "CBSA": 0.19,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6499,
                      "CBSA": 308825,
                      "state": 2484727
                   },
                   "numerator_errors": {
                      "this": 1154.4,
                      "CBSA": 4500,
                      "state": 14140.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 109,
                      "state": 113
                   },
                   "error_ratio": {
                      "this": 17.3,
                      "CBSA": 1.5,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 12.17,
                      "CBSA": 11.35,
                      "state": 11.05
                   },
                   "error": {
                      "this": 2.64,
                      "CBSA": 0.3,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 5611,
                      "CBSA": 271460,
                      "state": 2195779
                   },
                   "numerator_errors": {
                      "this": 1240.3,
                      "CBSA": 7253,
                      "state": 22615.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 110
                   },
                   "error_ratio": {
                      "this": 21.7,
                      "CBSA": 2.6,
                      "state": 1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.38,
                      "CBSA": 7.61,
                      "state": 6.99
                   },
                   "error": {
                      "this": 1.81,
                      "CBSA": 0.21,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 2940,
                      "CBSA": 182127,
                      "state": 1388666
                   },
                   "numerator_errors": {
                      "this": 843.8,
                      "CBSA": 5128.7,
                      "state": 14053.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 84,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 28.4,
                      "CBSA": 2.8,
                      "state": 1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.38,
                      "CBSA": 4.8,
                      "state": 4.32
                   },
                   "error": {
                      "this": 1.36,
                      "CBSA": 0.2,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 2020,
                      "CBSA": 114768,
                      "state": 859230
                   },
                   "numerator_errors": {
                      "this": 630.2,
                      "CBSA": 4708,
                      "state": 12383.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 91,
                      "state": 101
                   },
                   "error_ratio": {
                      "this": 31.1,
                      "CBSA": 4.2,
                      "state": 1.4
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 39.6,
                   "CBSA": 39.2,
                   "state": 37
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 107
                },
                "error_ratio": {
                   "this": 5.6,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 38.5,
                   "CBSA": 38.3,
                   "state": 35.9
                },
                "error": {
                   "this": 2,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 107
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 40.4,
                   "CBSA": 40.2,
                   "state": 38.2
                },
                "error": {
                   "this": 2,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 106
                },
                "error_ratio": {
                   "this": 5,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 48.88,
                "CBSA": 49.45,
                "state": 49.71
             },
             "error": {
                "this": 2.09,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 44083,
                "CBSA": 2339716,
                "state": 1.9640794E7
             },
             "numerator_errors": {
                "this": 1885,
                "CBSA": 2867,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 98
             },
             "error_ratio": {
                "this": 4.3,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 51.12,
                "CBSA": 50.55,
                "state": 50.29
             },
             "error": {
                "this": 2.09,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 46105,
                "CBSA": 2392087,
                "state": 1.9871428E7
             },
             "numerator_errors": {
                "this": 1883,
                "CBSA": 2867,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 102
             },
             "error_ratio": {
                "this": 4.1,
                "CBSA": 0.1,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 56.66,
                "CBSA": 38.62,
                "state": 36.33
             },
             "error": {
                "this": 4.47,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 51102,
                "CBSA": 1827248,
                "state": 1.4356081E7
             },
             "numerator_errors": {
                "this": 4034,
                "CBSA": 2941,
                "state": 6353
             },
             "index": {
                "this": 100,
                "CBSA": 147,
                "state": 156
             },
             "error_ratio": {
                "this": 7.9,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 1.89,
                "CBSA": 7.22,
                "state": 5.5
             },
             "error": {
                "this": 1.88,
                "CBSA": 0.09,
                "state": 0.04
             },
             "numerators": {
                "this": 1703,
                "CBSA": 341500,
                "state": 2171989
             },
             "numerator_errors": {
                "this": 1699,
                "CBSA": 4426,
                "state": 16630
             },
             "index": {
                "this": 100,
                "CBSA": 26,
                "state": 34
             },
             "error_ratio": {
                "this": 99.5,
                "CBSA": 1.2,
                "state": 0.7
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.17,
                "CBSA": 0.24,
                "state": 0.38
             },
             "error": {
                "this": 0.25,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 149,
                "CBSA": 11524,
                "state": 149063
             },
             "numerator_errors": {
                "this": 224,
                "CBSA": 1876,
                "state": 5527
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 45
             },
             "error_ratio": {
                "this": 147.1,
                "CBSA": 16.7,
                "state": 2.6
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 12.3,
                "CBSA": 26.82,
                "state": 14.65
             },
             "error": {
                "this": 3.58,
                "CBSA": 0.18,
                "state": 0.05
             },
             "numerators": {
                "this": 11090,
                "CBSA": 1269163,
                "state": 5786711
             },
             "numerator_errors": {
                "this": 3228,
                "CBSA": 8649,
                "state": 19627
             },
             "index": {
                "this": 100,
                "CBSA": 46,
                "state": 84
             },
             "error_ratio": {
                "this": 29.1,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.37,
                "CBSA": 0.63,
                "state": 0.36
             },
             "error": {
                "this": 0.34,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 334,
                "CBSA": 29935,
                "state": 141846
             },
             "numerator_errors": {
                "this": 304,
                "CBSA": 1990,
                "state": 4748
             },
             "index": {
                "this": 100,
                "CBSA": 59,
                "state": 103
             },
             "error_ratio": {
                "this": 91.9,
                "CBSA": 6.3,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.21,
                "CBSA": 0.44,
                "state": 0.27
             },
             "error": {
                "this": 0.25,
                "CBSA": 0.12,
                "state": 0.03
             },
             "numerators": {
                "this": 192,
                "CBSA": 20869,
                "state": 107538
             },
             "numerator_errors": {
                "this": 224,
                "CBSA": 5470,
                "state": 10686
             },
             "index": {
                "this": 100,
                "CBSA": 48,
                "state": 78
             },
             "error_ratio": {
                "this": 119,
                "CBSA": 27.3,
                "state": 11.1
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 5.65,
                "CBSA": 4.18,
                "state": 3.1
             },
             "error": {
                "this": 1.45,
                "CBSA": 0.2,
                "state": 0.07
             },
             "numerators": {
                "this": 5092,
                "CBSA": 197642,
                "state": 1224113
             },
             "numerator_errors": {
                "this": 1304,
                "CBSA": 9563,
                "state": 26997
             },
             "index": {
                "this": 100,
                "CBSA": 135,
                "state": 182
             },
             "error_ratio": {
                "this": 25.7,
                "CBSA": 4.8,
                "state": 2.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 22.76,
                "CBSA": 21.85,
                "state": 39.42
             },
             "error": {
                "this": 4.37,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 20526,
                "CBSA": 1033922,
                "state": 1.5574882E7
             },
             "numerator_errors": {
                "this": 3945,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 58
             },
             "error_ratio": {
                "this": 19.2,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 58476,
                "CBSA": 60223,
                "state": 39393
             },
             "error": {
                "this": 3649,
                "CBSA": 786,
                "state": 177
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 148
             },
             "error_ratio": {
                "this": 6.2,
                "CBSA": 1.3,
                "state": 0.4
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 138225,
                "CBSA": 114696,
                "state": 80440
             },
             "error": {
                "this": 9939,
                "CBSA": 1965,
                "state": 313
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 121,
                "state": 172
             },
             "error_ratio": {
                "this": 7.2,
                "CBSA": 1.7,
                "state": 0.4
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 12.66,
                   "CBSA": 22.5,
                   "state": 31.68
                },
                "error": {
                   "this": 3.57,
                   "CBSA": 0.65,
                   "state": 0.26
                },
                "numerators": {
                   "this": 3866,
                   "CBSA": 387393,
                   "state": 4167825
                },
                "numerator_errors": {
                   "this": 1109.2,
                   "CBSA": 11318.1,
                   "state": 34680.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 40
                },
                "error_ratio": {
                   "this": 28.2,
                   "CBSA": 2.9,
                   "state": 0.8
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 23.1,
                   "CBSA": 21.44,
                   "state": 27.83
                },
                "error": {
                   "this": 4.23,
                   "CBSA": 0.57,
                   "state": 0.24
                },
                "numerators": {
                   "this": 7055,
                   "CBSA": 369108,
                   "state": 3661397
                },
                "numerator_errors": {
                   "this": 1348.8,
                   "CBSA": 9959.7,
                   "state": 32290.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 83
                },
                "error_ratio": {
                   "this": 18.3,
                   "CBSA": 2.7,
                   "state": 0.9
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 36.87,
                   "CBSA": 29.91,
                   "state": 26.8
                },
                "error": {
                   "this": 4.74,
                   "CBSA": 0.69,
                   "state": 0.24
                },
                "numerators": {
                   "this": 11263,
                   "CBSA": 514913,
                   "state": 3525910
                },
                "numerator_errors": {
                   "this": 1572.3,
                   "CBSA": 12053.4,
                   "state": 31856.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 138
                },
                "error_ratio": {
                   "this": 12.9,
                   "CBSA": 2.3,
                   "state": 0.9
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 27.37,
                   "CBSA": 26.15,
                   "state": 13.7
                },
                "error": {
                   "this": 2.72,
                   "CBSA": 0.54,
                   "state": 0.14
                },
                "numerators": {
                   "this": 8361,
                   "CBSA": 450162,
                   "state": 1802741
                },
                "numerator_errors": {
                   "this": 948,
                   "CBSA": 9432,
                   "state": 18330
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 200
                },
                "error_ratio": {
                   "this": 9.9,
                   "CBSA": 2.1,
                   "state": 1
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 4.44,
                "CBSA": 8.16,
                "state": 11.75
             },
             "error": {
                "this": 2.84,
                "CBSA": 0.42,
                "state": 0.18
             },
             "numerators": {
                "this": 3983,
                "CBSA": 380982,
                "state": 4552837
             },
             "numerator_errors": {
                "this": 2552,
                "CBSA": 19484,
                "state": 67995
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 54,
                "state": 38
             },
             "error_ratio": {
                "this": 64,
                "CBSA": 5.1,
                "state": 1.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 7.98,
                   "CBSA": 8.89,
                   "state": 15.62
                },
                "error": {
                   "this": 5.93,
                   "CBSA": 0.65,
                   "state": 0.27
                },
                "numerators": {
                   "this": 1707,
                   "CBSA": 80671,
                   "state": 1363574
                },
                "numerator_errors": {
                   "this": 1285.7,
                   "CBSA": 5988.4,
                   "state": 24624.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 51
                },
                "error_ratio": {
                   "this": 74.3,
                   "CBSA": 7.3,
                   "state": 1.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 92.02,
                   "CBSA": 91.11,
                   "state": 84.38
                },
                "error": {
                   "this": 15.29,
                   "CBSA": 1.88,
                   "state": 0.61
                },
                "numerators": {
                   "this": 19686,
                   "CBSA": 827095,
                   "state": 7365259
                },
                "numerator_errors": {
                   "this": 2244.3,
                   "CBSA": 11932.8,
                   "state": 37404.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 109
                },
                "error_ratio": {
                   "this": 16.6,
                   "CBSA": 2.1,
                   "state": 0.7
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 5.7,
                   "CBSA": 8.95,
                   "state": 10.45
                },
                "error": {
                   "this": 3.93,
                   "CBSA": 0.62,
                   "state": 0.23
                },
                "numerators": {
                   "this": 829,
                   "CBSA": 66384,
                   "state": 599877
                },
                "numerator_errors": {
                   "this": 580.9,
                   "CBSA": 4644,
                   "state": 13070.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 64,
                   "state": 55
                },
                "error_ratio": {
                   "this": 68.9,
                   "CBSA": 6.9,
                   "state": 2.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 94.3,
                   "CBSA": 91.05,
                   "state": 89.55
                },
                "error": {
                   "this": 1.22,
                   "CBSA": 1.09,
                   "state": 0.39
                },
                "numerators": {
                   "this": 13703,
                   "CBSA": 675078,
                   "state": 5138754
                },
                "numerator_errors": {
                   "this": 1729.7,
                   "CBSA": 5093.8,
                   "state": 14255.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 105
                },
                "error_ratio": {
                   "this": 1.3,
                   "CBSA": 1.2,
                   "state": 0.4
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 32.88,
                "CBSA": 34.26,
                "state": 29.79
             },
             "error": {
                "this": 1.15,
                "CBSA": 0.14,
                "state": 0.07
             },
             "numerators": {
                "this": 1452365,
                "CBSA": 7.5895432E7,
                "state": 5.099704E8
             },
             "numerator_errors": {
                "this": 60972,
                "CBSA": 426975,
                "state": 1354876
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 110
             },
             "error_ratio": {
                "this": 3.5,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 77.02,
                   "CBSA": 57.56,
                   "state": 73.72
                },
                "error": {
                   "this": 1.14,
                   "CBSA": 0.27,
                   "state": 0.04
                },
                "numerators": {
                   "this": 36205,
                   "CBSA": 1365800,
                   "state": 1.3411041E7
                },
                "numerator_errors": {
                   "this": 912,
                   "CBSA": 7921,
                   "state": 17348
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 104
                },
                "error_ratio": {
                   "this": 1.5,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 9.25,
                   "CBSA": 9.53,
                   "state": 10.12
                },
                "error": {
                   "this": 1.16,
                   "CBSA": 0.17,
                   "state": 0.09
                },
                "numerators": {
                   "this": 4346,
                   "CBSA": 226167,
                   "state": 1841632
                },
                "numerator_errors": {
                   "this": 552,
                   "CBSA": 4187,
                   "state": 16674
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 91
                },
                "error_ratio": {
                   "this": 12.5,
                   "CBSA": 1.8,
                   "state": 0.9
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 4.29,
                   "CBSA": 17.61,
                   "state": 5.08
                },
                "error": {
                   "this": 0.58,
                   "CBSA": 0.19,
                   "state": 0.04
                },
                "numerators": {
                   "this": 2015,
                   "CBSA": 417810,
                   "state": 923834
                },
                "numerator_errors": {
                   "this": 274,
                   "CBSA": 4652,
                   "state": 7563
                },
                "index": {
                   "this": 100,
                   "CBSA": 24,
                   "state": 84
                },
                "error_ratio": {
                   "this": 13.5,
                   "CBSA": 1.1,
                   "state": 0.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 1.03,
                   "CBSA": 1.91,
                   "state": 0.95
                },
                "error": {
                   "this": 0.35,
                   "CBSA": 0.07,
                   "state": 0.02
                },
                "numerators": {
                   "this": 482,
                   "CBSA": 45243,
                   "state": 173081
                },
                "numerator_errors": {
                   "this": 163,
                   "CBSA": 1647,
                   "state": 3177
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 108
                },
                "error_ratio": {
                   "this": 34,
                   "CBSA": 3.7,
                   "state": 2.1
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 1.18,
                   "CBSA": 4.69,
                   "state": 2.62
                },
                "error": {
                   "this": 0.44,
                   "CBSA": 0.13,
                   "state": 0.03
                },
                "numerators": {
                   "this": 557,
                   "CBSA": 111365,
                   "state": 476291
                },
                "numerator_errors": {
                   "this": 205,
                   "CBSA": 3026,
                   "state": 5234
                },
                "index": {
                   "this": 100,
                   "CBSA": 25,
                   "state": 45
                },
                "error_ratio": {
                   "this": 37.3,
                   "CBSA": 2.8,
                   "state": 1.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.2,
                   "CBSA": 2.06,
                   "state": 1.6
                },
                "error": {
                   "this": 0.36,
                   "CBSA": 0.08,
                   "state": 0.02
                },
                "numerators": {
                   "this": 565,
                   "CBSA": 48944,
                   "state": 291710
                },
                "numerator_errors": {
                   "this": 168,
                   "CBSA": 2007,
                   "state": 4364
                },
                "index": {
                   "this": 100,
                   "CBSA": 58,
                   "state": 75
                },
                "error_ratio": {
                   "this": 30,
                   "CBSA": 3.9,
                   "state": 1.2
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 6.04,
                   "CBSA": 6.65,
                   "state": 5.9
                },
                "error": {
                   "this": 0.81,
                   "CBSA": 0.12,
                   "state": 0.05
                },
                "numerators": {
                   "this": 2838,
                   "CBSA": 157692,
                   "state": 1073966
                },
                "numerator_errors": {
                   "this": 384,
                   "CBSA": 2857,
                   "state": 8330
                },
                "index": {
                   "this": 100,
                   "CBSA": 91,
                   "state": 102
                },
                "error_ratio": {
                   "this": 13.4,
                   "CBSA": 1.8,
                   "state": 0.8
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 59.93,
                "CBSA": 49.88,
                "state": 48.44
             },
             "error": {
                "this": 1.84,
                "CBSA": 0.39,
                "state": 0.14
             },
             "numerators": {
                "this": 43068,
                "CBSA": 1977052,
                "state": 1.5561514E7
             },
             "numerator_errors": {
                "this": 1905.6,
                "CBSA": 15302.6,
                "state": 46249.3
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 124
             },
             "error_ratio": {
                "this": 3.1,
                "CBSA": 0.8,
                "state": 0.3
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 40.07,
                "CBSA": 50.12,
                "state": 51.56
             },
             "error": {
                "this": 3.84,
                "CBSA": 0.45,
                "state": 0.16
             },
             "numerators": {
                "this": 28791,
                "CBSA": 1986662,
                "state": 1.6562598E7
             },
             "numerator_errors": {
                "this": 2909.1,
                "CBSA": 17817.2,
                "state": 51752.6
             },
             "index": {
                "this": 100,
                "CBSA": 80,
                "state": 78
             },
             "error_ratio": {
                "this": 9.6,
                "CBSA": 0.9,
                "state": 0.3
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 28.6,
                   "CBSA": 39.77,
                   "state": 40.83
                },
                "error": {
                   "this": 4.62,
                   "CBSA": 0.5,
                   "state": 0.17
                },
                "numerators": {
                   "this": 9913,
                   "CBSA": 775103,
                   "state": 6478460
                },
                "numerator_errors": {
                   "this": 1705,
                   "CBSA": 9861,
                   "state": 27866
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 70
                },
                "error_ratio": {
                   "this": 16.2,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 25.65,
                   "CBSA": 34.02,
                   "state": 33.96
                },
                "error": {
                   "this": 4.37,
                   "CBSA": 0.46,
                   "state": 0.15
                },
                "numerators": {
                   "this": 9544,
                   "CBSA": 685311,
                   "state": 5521285
                },
                "numerator_errors": {
                   "this": 1708,
                   "CBSA": 9340,
                   "state": 24532
                },
                "index": {
                   "this": 100,
                   "CBSA": 75,
                   "state": 76
                },
                "error_ratio": {
                   "this": 17,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 62.95,
                   "CBSA": 51.38,
                   "state": 49.37
                },
                "error": {
                   "this": 1.6,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 21818,
                   "CBSA": 1001374,
                   "state": 7832740
                },
                "numerator_errors": {
                   "this": 1397,
                   "CBSA": 10667,
                   "state": 32087
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 128
                },
                "error_ratio": {
                   "this": 2.5,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 57.12,
                   "CBSA": 48.43,
                   "state": 47.54
                },
                "error": {
                   "this": 1.56,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 21250,
                   "CBSA": 975678,
                   "state": 7728774
                },
                "numerator_errors": {
                   "this": 1296,
                   "CBSA": 10972,
                   "state": 33308
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 120
                },
                "error_ratio": {
                   "this": 2.7,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 7.32,
                   "CBSA": 6.95,
                   "state": 7.69
                },
                "error": {
                   "this": 2.52,
                   "CBSA": 0.34,
                   "state": 0.11
                },
                "numerators": {
                   "this": 2536,
                   "CBSA": 135520,
                   "state": 1219434
                },
                "numerator_errors": {
                   "this": 887,
                   "CBSA": 6608,
                   "state": 17626
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 95
                },
                "error_ratio": {
                   "this": 34.4,
                   "CBSA": 4.9,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 9.32,
                   "CBSA": 10.37,
                   "state": 10.83
                },
                "error": {
                   "this": 2.18,
                   "CBSA": 0.32,
                   "state": 0.16
                },
                "numerators": {
                   "this": 3468,
                   "CBSA": 208872,
                   "state": 1760791
                },
                "numerator_errors": {
                   "this": 834,
                   "CBSA": 6454,
                   "state": 25259
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 86
                },
                "error_ratio": {
                   "this": 23.4,
                   "CBSA": 3.1,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.13,
                   "CBSA": 1.9,
                   "state": 2.11
                },
                "error": {
                   "this": 0.76,
                   "CBSA": 0.16,
                   "state": 0.05
                },
                "numerators": {
                   "this": 390,
                   "CBSA": 37037,
                   "state": 334453
                },
                "numerator_errors": {
                   "this": 265,
                   "CBSA": 3146,
                   "state": 8393
                },
                "index": {
                   "this": 100,
                   "CBSA": 59,
                   "state": 54
                },
                "error_ratio": {
                   "this": 67.3,
                   "CBSA": 8.4,
                   "state": 2.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 7.9,
                   "CBSA": 7.19,
                   "state": 7.68
                },
                "error": {
                   "this": 2.77,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2940,
                   "CBSA": 144819,
                   "state": 1248175
                },
                "numerator_errors": {
                   "this": 1042,
                   "CBSA": 6145,
                   "state": 16759
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 103
                },
                "error_ratio": {
                   "this": 35.1,
                   "CBSA": 4.2,
                   "state": 1.3
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 86.03,
                   "CBSA": 78.76,
                   "state": 72.23
                },
                "error": {
                   "this": 2.58,
                   "CBSA": 0.42,
                   "state": 0.27
                },
                "numerators": {
                   "this": 16762,
                   "CBSA": 652636,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 888,
                   "CBSA": 4069,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 119
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 0.5,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 4.26,
                   "CBSA": 5.35,
                   "state": 7.67
                },
                "error": {
                   "this": 1.25,
                   "CBSA": 0.26,
                   "state": 0.11
                },
                "numerators": {
                   "this": 830,
                   "CBSA": 44369,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 246,
                   "CBSA": 2181,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 56
                },
                "error_ratio": {
                   "this": 29.3,
                   "CBSA": 4.9,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 9.71,
                   "CBSA": 15.88,
                   "state": 20.1
                },
                "error": {
                   "this": 2.34,
                   "CBSA": 0.36,
                   "state": 0.21
                },
                "numerators": {
                   "this": 1892,
                   "CBSA": 131584,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 463,
                   "CBSA": 3049,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 48
                },
                "error_ratio": {
                   "this": 24.1,
                   "CBSA": 2.3,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 4.62,
                "CBSA": 4.22,
                "state": 4.69
             },
             "error": {
                "this": 1.79,
                "CBSA": 0.35,
                "state": 0.11
             },
             "numerators": {
                "this": 978,
                "CBSA": 49498,
                "state": 450971
             },
             "numerator_errors": {
                "this": 387,
                "CBSA": 4126,
                "state": 10955
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 109,
                "state": 99
             },
             "error_ratio": {
                "this": 38.7,
                "CBSA": 8.3,
                "state": 2.3
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.14,
                   "state": 0.87
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.17,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 175,
                   "state": 10882
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 215,
                   "state": 2046
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 121.4,
                   "state": 18.4
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 3.41,
                   "state": 4.44
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.98,
                   "state": 0.41
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4502,
                   "state": 56709
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 1289,
                   "state": 5217
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 28.7,
                   "state": 9.2
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 11.63,
                   "CBSA": 3.96,
                   "state": 6.85
                },
                "error": {
                   "this": 8.08,
                   "CBSA": 0.79,
                   "state": 0.37
                },
                "numerators": {
                   "this": 355,
                   "CBSA": 7129,
                   "state": 101991
                },
                "numerator_errors": {
                   "this": 278,
                   "CBSA": 1423,
                   "state": 5591
                },
                "index": {
                   "this": 100,
                   "CBSA": 294,
                   "state": 170
                },
                "error_ratio": {
                   "this": 69.5,
                   "CBSA": 19.9,
                   "state": 5.4
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 19.49,
                   "CBSA": 8.76,
                   "state": 9.73
                },
                "error": {
                   "this": 9.25,
                   "CBSA": 1.12,
                   "state": 0.51
                },
                "numerators": {
                   "this": 543,
                   "CBSA": 16867,
                   "state": 139246
                },
                "numerator_errors": {
                   "this": 297,
                   "CBSA": 2170,
                   "state": 7329
                },
                "index": {
                   "this": 100,
                   "CBSA": 222,
                   "state": 200
                },
                "error_ratio": {
                   "this": 47.5,
                   "CBSA": 12.8,
                   "state": 5.2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 1.36,
                   "CBSA": 8.24,
                   "state": 6.95
                },
                "error": {
                   "this": 2.29,
                   "CBSA": 1.17,
                   "state": 0.33
                },
                "numerators": {
                   "this": 50,
                   "CBSA": 14911,
                   "state": 93949
                },
                "numerator_errors": {
                   "this": 85,
                   "CBSA": 2171,
                   "state": 4611
                },
                "index": {
                   "this": 100,
                   "CBSA": 17,
                   "state": 20
                },
                "error_ratio": {
                   "this": 168.4,
                   "CBSA": 14.2,
                   "state": 4.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 0.99,
                   "CBSA": 2.72,
                   "state": 2.64
                },
                "error": {
                   "this": 1.56,
                   "CBSA": 0.6,
                   "state": 0.21
                },
                "numerators": {
                   "this": 30,
                   "CBSA": 4418,
                   "state": 33377
                },
                "numerator_errors": {
                   "this": 48,
                   "CBSA": 979,
                   "state": 2743
                },
                "index": {
                   "this": 100,
                   "CBSA": 36,
                   "state": 38
                },
                "error_ratio": {
                   "this": 157.6,
                   "CBSA": 22.1,
                   "state": 8
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0,
                   "CBSA": 0.76,
                   "state": 0.96
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.3,
                   "state": 0.14
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1496,
                   "state": 14817
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 597,
                   "state": 2211
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 39.5,
                   "state": 14.6
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 30545,
                "CBSA": 1721576,
                "state": 1.3157873E7
             },
             "error": {
                "this": 1666,
                "CBSA": 7415,
                "state": 23844
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 5.5,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.94,
                "CBSA": 2.7,
                "state": 2.94
             },
             "error": {
                "this": 0.16,
                "CBSA": 0.01,
                "state": 0.01
             },
             "numerators": {
                "this": 89740,
                "CBSA": 4654824,
                "state": 3.8685704E7
             },
             "numerator_errors": {
                "this": 161,
                "CBSA": 3894,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 109,
                "state": 100
             },
             "error_ratio": {
                "this": 5.4,
                "CBSA": 0.4,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 75.62,
                   "CBSA": 61.45,
                   "state": 60.69
                },
                "error": {
                   "this": 4.07,
                   "CBSA": 0.62,
                   "state": 0.22
                },
                "numerators": {
                   "this": 67862,
                   "CBSA": 2860522,
                   "state": 2.3477092E7
                },
                "numerator_errors": {
                   "this": 3651,
                   "CBSA": 28845,
                   "state": 84642
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 125
                },
                "error_ratio": {
                   "this": 5.4,
                   "CBSA": 1,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 3.71,
                   "CBSA": 5.55,
                   "state": 7.74
                },
                "error": {
                   "this": 1.85,
                   "CBSA": 0.39,
                   "state": 0.16
                },
                "numerators": {
                   "this": 3332,
                   "CBSA": 258173,
                   "state": 2996042
                },
                "numerator_errors": {
                   "this": 1657,
                   "CBSA": 18051,
                   "state": 63406
                },
                "index": {
                   "this": 100,
                   "CBSA": 67,
                   "state": 48
                },
                "error_ratio": {
                   "this": 49.9,
                   "CBSA": 7,
                   "state": 2.1
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 9.22,
                   "CBSA": 12.87,
                   "state": 16.47
                },
                "error": {
                   "this": 3.7,
                   "CBSA": 0.56,
                   "state": 0.23
                },
                "numerators": {
                   "this": 8273,
                   "CBSA": 598953,
                   "state": 6371949
                },
                "numerator_errors": {
                   "this": 3319,
                   "CBSA": 26284,
                   "state": 87738
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 56
                },
                "error_ratio": {
                   "this": 40.1,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 11.45,
                   "CBSA": 20.13,
                   "state": 15.1
                },
                "error": {
                   "this": 2.33,
                   "CBSA": 0.41,
                   "state": 0.1
                },
                "numerators": {
                   "this": 10273,
                   "CBSA": 937176,
                   "state": 5840619
                },
                "numerator_errors": {
                   "this": 2089,
                   "CBSA": 18922,
                   "state": 37146
                },
                "index": {
                   "this": 100,
                   "CBSA": 57,
                   "state": 76
                },
                "error_ratio": {
                   "this": 20.3,
                   "CBSA": 2,
                   "state": 0.7
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 31367,
                "CBSA": 1841897,
                "state": 1.4367012E7
             },
             "error": {
                "this": 1842,
                "CBSA": 1707,
                "state": 1286
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 5.9,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 97.38,
                   "CBSA": 93.47,
                   "state": 91.58
                },
                "error": {
                   "this": 7.8,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 30545,
                   "CBSA": 1721576,
                   "state": 1.3157873E7
                },
                "numerator_errors": {
                   "this": 1666,
                   "CBSA": 7415,
                   "state": 23844
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 106
                },
                "error_ratio": {
                   "this": 8,
                   "CBSA": 0.4,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 2.62,
                   "CBSA": 6.53,
                   "state": 8.42
                },
                "error": {
                   "this": 2.18,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 822,
                   "CBSA": 120321,
                   "state": 1209139
                },
                "numerator_errors": {
                   "this": 686,
                   "CBSA": 7194,
                   "state": 24297
                },
                "index": {
                   "this": 100,
                   "CBSA": 40,
                   "state": 31
                },
                "error_ratio": {
                   "this": 83.2,
                   "CBSA": 6,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 84.58,
                   "CBSA": 57.4,
                   "state": 63.96
                },
                "error": {
                   "this": 4.85,
                   "CBSA": 0.62,
                   "state": 0.22
                },
                "numerators": {
                   "this": 26531,
                   "CBSA": 1057192,
                   "state": 9189272
                },
                "numerator_errors": {
                   "this": 2178.6,
                   "CBSA": 11515.2,
                   "state": 31423.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 147,
                   "state": 132
                },
                "error_ratio": {
                   "this": 5.7,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 14.85,
                   "CBSA": 41.49,
                   "state": 32.18
                },
                "error": {
                   "this": 3.69,
                   "CBSA": 0.81,
                   "state": 0.27
                },
                "numerators": {
                   "this": 4659,
                   "CBSA": 764126,
                   "state": 4622614
                },
                "numerator_errors": {
                   "this": 1189,
                   "CBSA": 14847.1,
                   "state": 38673.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 36,
                   "state": 46
                },
                "error_ratio": {
                   "this": 24.8,
                   "CBSA": 2,
                   "state": 0.8
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.56,
                   "CBSA": 1,
                   "state": 3.74
                },
                "error": {
                   "this": 0.71,
                   "CBSA": 0.11,
                   "state": 0.08
                },
                "numerators": {
                   "this": 177,
                   "CBSA": 18463,
                   "state": 537931
                },
                "numerator_errors": {
                   "this": 222,
                   "CBSA": 1989,
                   "state": 11591
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 15
                },
                "error_ratio": {
                   "this": 126.8,
                   "CBSA": 11,
                   "state": 2.1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.12
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.05,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 2116,
                   "state": 17195
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 951,
                   "state": 2102
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 45.5,
                   "state": 8.3
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 73.15,
                   "CBSA": 54.03,
                   "state": 54.86
                },
                "error": {
                   "this": 4.59,
                   "CBSA": 0.59,
                   "state": 0.22
                },
                "numerators": {
                   "this": 22344,
                   "CBSA": 930250,
                   "state": 7218742
                },
                "numerator_errors": {
                   "this": 1857,
                   "CBSA": 10981,
                   "state": 32100
                },
                "index": {
                   "this": 100,
                   "CBSA": 135,
                   "state": 133
                },
                "error_ratio": {
                   "this": 6.3,
                   "CBSA": 1.1,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 26.85,
                   "CBSA": 45.97,
                   "state": 45.14
                },
                "error": {
                   "this": 4.12,
                   "CBSA": 0.65,
                   "state": 0.24
                },
                "numerators": {
                   "this": 8201,
                   "CBSA": 791326,
                   "state": 5939131
                },
                "numerator_errors": {
                   "this": 1335,
                   "CBSA": 11672,
                   "state": 32807
                },
                "index": {
                   "this": 100,
                   "CBSA": 58,
                   "state": 59
                },
                "error_ratio": {
                   "this": 15.3,
                   "CBSA": 1.4,
                   "state": 0.5
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 852500,
                "CBSA": 940900,
                "state": 568500
             },
             "error": {
                "this": 23083,
                "CBSA": 7982,
                "state": 2502
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 91,
                "state": 150
             },
             "error_ratio": {
                "this": 2.7,
                "CBSA": 0.8,
                "state": 0.4
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 1.51,
                   "CBSA": 2.16,
                   "state": 5.03
                },
                "error": {
                   "this": 0.6,
                   "CBSA": 0.1,
                   "state": 0.05
                },
                "numerators": {
                   "this": 348,
                   "CBSA": 20156,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 137.9,
                   "CBSA": 908.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 70,
                   "state": 30
                },
                "error_ratio": {
                   "this": 39.7,
                   "CBSA": 4.6,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 1.26,
                   "CBSA": 1.61,
                   "state": 6.63
                },
                "error": {
                   "this": 0.54,
                   "CBSA": 0.09,
                   "state": 0.06
                },
                "numerators": {
                   "this": 289,
                   "CBSA": 15036,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 124.3,
                   "CBSA": 818.1,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 78,
                   "state": 19
                },
                "error_ratio": {
                   "this": 42.9,
                   "CBSA": 5.6,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 1.07,
                   "CBSA": 2.81,
                   "state": 11.06
                },
                "error": {
                   "this": 0.44,
                   "CBSA": 0.11,
                   "state": 0.06
                },
                "numerators": {
                   "this": 245,
                   "CBSA": 26184,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 101.9,
                   "CBSA": 1029.9,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 38,
                   "state": 10
                },
                "error_ratio": {
                   "this": 41.1,
                   "CBSA": 3.9,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 2.81,
                   "CBSA": 5.66,
                   "state": 13.6
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.14,
                   "state": 0.09
                },
                "numerators": {
                   "this": 647,
                   "CBSA": 52681,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 163,
                   "CBSA": 1347,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 50,
                   "state": 21
                },
                "error_ratio": {
                   "this": 24.9,
                   "CBSA": 2.5,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 5.37,
                   "CBSA": 7.56,
                   "state": 13.21
                },
                "error": {
                   "this": 1.08,
                   "CBSA": 0.19,
                   "state": 0.08
                },
                "numerators": {
                   "this": 1235,
                   "CBSA": 70432,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 251,
                   "CBSA": 1773,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 71,
                   "state": 41
                },
                "error_ratio": {
                   "this": 20.1,
                   "CBSA": 2.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 69.44,
                   "CBSA": 43.94,
                   "state": 35.28
                },
                "error": {
                   "this": 2.34,
                   "CBSA": 0.29,
                   "state": 0.26
                },
                "numerators": {
                   "this": 15967,
                   "CBSA": 409063,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 697.3,
                   "CBSA": 3594.1,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 158,
                   "state": 197
                },
                "error_ratio": {
                   "this": 3.4,
                   "CBSA": 0.7,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 14.73,
                   "CBSA": 19.26,
                   "state": 7.93
                },
                "error": {
                   "this": 0.97,
                   "CBSA": 0.23,
                   "state": 0.04
                },
                "numerators": {
                   "this": 3388,
                   "CBSA": 179279,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 241,
                   "CBSA": 2390,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 186
                },
                "error_ratio": {
                   "this": 6.6,
                   "CBSA": 1.2,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 22995,
                "CBSA": 931032,
                "state": 7154580
             },
             "error": {
                "this": 637,
                "CBSA": 5500,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 0.6,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 9.67,
                "CBSA": 10.5,
                "state": 8.86
             },
             "error": {
                "this": 3.6,
                "CBSA": 0.42,
                "state": 0.13
             },
             "numerators": {
                "this": 8682,
                "CBSA": 488730,
                "state": 3427072
             },
             "numerator_errors": {
                "this": 3230.4,
                "CBSA": 19338.7,
                "state": 50836.5
             },
             "index": {
                "this": 100,
                "CBSA": 92,
                "state": 109
             },
             "error_ratio": {
                "this": 37.2,
                "CBSA": 4,
                "state": 1.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 10.68,
                "CBSA": 10.75,
                "state": 11.17
             },
             "error": {
                "this": 2.61,
                "CBSA": 0.42,
                "state": 0.16
             },
             "numerators": {
                "this": 9584,
                "CBSA": 500178,
                "state": 4320604
             },
             "numerator_errors": {
                "this": 2339,
                "CBSA": 19399.4,
                "state": 63360.7
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 96
             },
             "error_ratio": {
                "this": 24.4,
                "CBSA": 3.9,
                "state": 1.4
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 22.9,
                "CBSA": 21.68,
                "state": 21.9
             },
             "error": {
                "this": 5.04,
                "CBSA": 0.73,
                "state": 0.25
             },
             "numerators": {
                "this": 20549,
                "CBSA": 1008965,
                "state": 8473932
             },
             "numerator_errors": {
                "this": 4526.7,
                "CBSA": 33889.7,
                "state": 98624.3
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 105
             },
             "error_ratio": {
                "this": 22,
                "CBSA": 3.4,
                "state": 1.1
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 21.11,
                "CBSA": 20.17,
                "state": 20.05
             },
             "error": {
                "this": 4.54,
                "CBSA": 0.62,
                "state": 0.24
             },
             "numerators": {
                "this": 18948,
                "CBSA": 939060,
                "state": 7754580
             },
             "numerator_errors": {
                "this": 4070.9,
                "CBSA": 29080.9,
                "state": 91602.6
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 105
             },
             "error_ratio": {
                "this": 21.5,
                "CBSA": 3.1,
                "state": 1.2
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 13.08,
                "CBSA": 12.37,
                "state": 13.11
             },
             "error": {
                "this": 3.02,
                "CBSA": 0.54,
                "state": 0.21
             },
             "numerators": {
                "this": 11740,
                "CBSA": 575817,
                "state": 5072685
             },
             "numerator_errors": {
                "this": 2707.1,
                "CBSA": 25040.7,
                "state": 79541.6
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 100
             },
             "error_ratio": {
                "this": 23.1,
                "CBSA": 4.4,
                "state": 1.6
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 22.55,
                "CBSA": 24.54,
                "state": 24.91
             },
             "error": {
                "this": 4.23,
                "CBSA": 0.73,
                "state": 0.26
             },
             "numerators": {
                "this": 20237,
                "CBSA": 1142074,
                "state": 9636829
             },
             "numerator_errors": {
                "this": 3793,
                "CBSA": 33871.4,
                "state": 101788.8
             },
             "index": {
                "this": 100,
                "CBSA": 92,
                "state": 91
             },
             "error_ratio": {
                "this": 18.8,
                "CBSA": 3,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 10.43,
                "CBSA": 12.66,
                "state": 12
             },
             "error": {
                "this": 2.3,
                "CBSA": 0.41,
                "state": 0.16
             },
             "numerators": {
                "this": 9267,
                "CBSA": 592840,
                "state": 4689828
             },
             "numerator_errors": {
                "this": 2048.2,
                "CBSA": 19218.9,
                "state": 63097.7
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 87
             },
             "error_ratio": {
                "this": 22.1,
                "CBSA": 3.2,
                "state": 1.3
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 89.57,
                "CBSA": 87.34,
                "state": 88
             },
             "error": {
                "this": 2.29,
                "CBSA": 0.39,
                "state": 0.17
             },
             "numerators": {
                "this": 79599,
                "CBSA": 4091762,
                "state": 3.439422E7
             },
             "numerator_errors": {
                "this": 2093,
                "CBSA": 18428,
                "state": 68574
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 102
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 6.15,
                "CBSA": 6.27,
                "state": 7.37
             },
             "error": {
                "this": 1.72,
                "CBSA": 0.29,
                "state": 0.13
             },
             "numerators": {
                "this": 5464,
                "CBSA": 293714,
                "state": 2881883
             },
             "numerator_errors": {
                "this": 1533,
                "CBSA": 13357,
                "state": 52031
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 83
             },
             "error_ratio": {
                "this": 28,
                "CBSA": 4.6,
                "state": 1.8
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 3.4,
                "CBSA": 3.96,
                "state": 2.72
             },
             "error": {
                "this": 1.37,
                "CBSA": 0.25,
                "state": 0.07
             },
             "numerators": {
                "this": 3020,
                "CBSA": 185306,
                "state": 1061256
             },
             "numerator_errors": {
                "this": 1218,
                "CBSA": 11875,
                "state": 27761
             },
             "index": {
                "this": 100,
                "CBSA": 86,
                "state": 125
             },
             "error_ratio": {
                "this": 40.3,
                "CBSA": 6.3,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 0.88,
                "CBSA": 1.39,
                "state": 1.23
             },
             "error": {
                "this": 0.63,
                "CBSA": 0.11,
                "state": 0.05
             },
             "numerators": {
                "this": 783,
                "CBSA": 65269,
                "state": 480204
             },
             "numerator_errors": {
                "this": 561,
                "CBSA": 5065,
                "state": 18764
             },
             "index": {
                "this": 100,
                "CBSA": 63,
                "state": 72
             },
             "error_ratio": {
                "this": 71.6,
                "CBSA": 7.9,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 0,
                "CBSA": 1.04,
                "state": 0.68
             },
             "error": {
                "this": 0,
                "CBSA": 0.11,
                "state": 0.03
             },
             "numerators": {
                "this": 0,
                "CBSA": 48551,
                "state": 266485
             },
             "numerator_errors": {
                "this": 216,
                "CBSA": 4928,
                "state": 12302
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 10.6,
                "state": 4.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 95.33,
                "CBSA": 89.55,
                "state": 84.03
             },
             "error": {
                "this": 5.05,
                "CBSA": 0.79,
                "state": 0.29
             },
             "numerators": {
                "this": 59989,
                "CBSA": 3083220,
                "state": 2.263636E7
             },
             "numerator_errors": {
                "this": 3808.5,
                "CBSA": 27313.4,
                "state": 78234.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 113
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 47.05,
                "CBSA": 51.35,
                "state": 35
             },
             "error": {
                "this": 3.67,
                "CBSA": 0.57,
                "state": 0.17
             },
             "numerators": {
                "this": 29606,
                "CBSA": 1768174,
                "state": 9428484
             },
             "numerator_errors": {
                "this": 2531,
                "CBSA": 19525.3,
                "state": 44753.9
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 92,
                "state": 134
             },
             "error_ratio": {
                "this": 7.8,
                "CBSA": 1.1,
                "state": 0.5
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 4.67,
                "CBSA": 10.45,
                "state": 15.97
             },
             "error": {
                "this": 1.74,
                "CBSA": 0.34,
                "state": 0.14
             },
             "numerators": {
                "this": 2940,
                "CBSA": 359842,
                "state": 4301513
             },
             "numerator_errors": {
                "this": 1098.6,
                "CBSA": 11853.8,
                "state": 37669.1
             },
             "index": {
                "this": 100,
                "CBSA": 45,
                "state": 29
             },
             "error_ratio": {
                "this": 37.3,
                "CBSA": 3.3,
                "state": 0.9
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 16.2,
                "CBSA": 15.67,
                "state": 20.59
             },
             "error": {
                "this": 2.57,
                "CBSA": 0.37,
                "state": 0.16
             },
             "numerators": {
                "this": 10194,
                "CBSA": 539467,
                "state": 5546711
             },
             "numerator_errors": {
                "this": 1656.2,
                "CBSA": 12581,
                "state": 42454
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 79
             },
             "error_ratio": {
                "this": 15.9,
                "CBSA": 2.4,
                "state": 0.8
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 32.08,
                "CBSA": 22.53,
                "state": 28.44
             },
             "error": {
                "this": 3.5,
                "CBSA": 0.42,
                "state": 0.18
             },
             "numerators": {
                "this": 20189,
                "CBSA": 775579,
                "state": 7661164
             },
             "numerator_errors": {
                "this": 2314.3,
                "CBSA": 14370.3,
                "state": 48118.5
             },
             "index": {
                "this": 100,
                "CBSA": 142,
                "state": 113
             },
             "error_ratio": {
                "this": 10.9,
                "CBSA": 1.9,
                "state": 0.6
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 29.73,
                "CBSA": 29.97,
                "state": 21.86
             },
             "error": {
                "this": 2.88,
                "CBSA": 0.4,
                "state": 0.13
             },
             "numerators": {
                "this": 18711,
                "CBSA": 1032030,
                "state": 5889724
             },
             "numerator_errors": {
                "this": 1927.4,
                "CBSA": 13696,
                "state": 34784.9
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 136
             },
             "error_ratio": {
                "this": 9.7,
                "CBSA": 1.3,
                "state": 0.6
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 17.31,
                "CBSA": 21.38,
                "state": 13.14
             },
             "error": {
                "this": 2.54,
                "CBSA": 0.4,
                "state": 0.1
             },
             "numerators": {
                "this": 10895,
                "CBSA": 736144,
                "state": 3538760
             },
             "numerator_errors": {
                "this": 1640.5,
                "CBSA": 13916.1,
                "state": 28159
             },
             "index": {
                "this": 100,
                "CBSA": 81,
                "state": 132
             },
             "error_ratio": {
                "this": 14.7,
                "CBSA": 1.9,
                "state": 0.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 18.03,
                "CBSA": 30.94,
                "state": 26.74
             },
             "error": {
                "this": 2.84,
                "CBSA": 0.47,
                "state": 0.14
             },
             "numerators": {
                "this": 16260,
                "CBSA": 1464048,
                "state": 1.056422E7
             },
             "numerator_errors": {
                "this": 2559,
                "CBSA": 22110,
                "state": 55357
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 58,
                "state": 67
             },
             "error_ratio": {
                "this": 15.8,
                "CBSA": 1.5,
                "state": 0.5
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 15.15,
                   "CBSA": 9.42,
                   "state": 6.42
                },
                "error": {
                   "this": 2.52,
                   "CBSA": 0.19,
                   "state": 0.07
                },
                "numerators": {
                   "this": 2137,
                   "CBSA": 135944,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 385,
                   "CBSA": 2807,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 161,
                   "state": 236
                },
                "error_ratio": {
                   "this": 16.6,
                   "CBSA": 2,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 46.87,
                   "CBSA": 57.07,
                   "state": 39.42
                },
                "error": {
                   "this": 2.75,
                   "CBSA": 0.46,
                   "state": 0.09
                },
                "numerators": {
                   "this": 6614,
                   "CBSA": 823767,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 600,
                   "CBSA": 4566,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 119
                },
                "error_ratio": {
                   "this": 5.9,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 2.37,
                   "CBSA": 2.41,
                   "state": 1.84
                },
                "error": {
                   "this": 1.33,
                   "CBSA": 0.12,
                   "state": 0.05
                },
                "numerators": {
                   "this": 335,
                   "CBSA": 34802,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 189,
                   "CBSA": 1747,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 129
                },
                "error_ratio": {
                   "this": 56.1,
                   "CBSA": 5,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 1.01,
                   "CBSA": 1.66,
                   "state": 0.82
                },
                "error": {
                   "this": 0.75,
                   "CBSA": 0.11,
                   "state": 0.03
                },
                "numerators": {
                   "this": 142,
                   "CBSA": 23950,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 106,
                   "CBSA": 1560,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 123
                },
                "error_ratio": {
                   "this": 74.3,
                   "CBSA": 6.6,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 31.58,
                   "CBSA": 27.95,
                   "state": 50.28
                },
                "error": {
                   "this": 5.14,
                   "CBSA": 0.31,
                   "state": 0.15
                },
                "numerators": {
                   "this": 4456,
                   "CBSA": 403440,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 788,
                   "CBSA": 5038,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 113,
                   "state": 63
                },
                "error_ratio": {
                   "this": 16.3,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 3.02,
                   "CBSA": 1.5,
                   "state": 1.23
                },
                "error": {
                   "this": 1.12,
                   "CBSA": 0.07,
                   "state": 0.03
                },
                "numerators": {
                   "this": 426,
                   "CBSA": 21628,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 161,
                   "CBSA": 1082,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 201,
                   "state": 246
                },
                "error_ratio": {
                   "this": 37.1,
                   "CBSA": 4.7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 83.13,
                   "CBSA": 61.88,
                   "state": 56.97
                },
                "error": {
                   "this": 18.72,
                   "CBSA": 1.17,
                   "state": 0.51
                },
                "numerators": {
                   "this": 12315,
                   "CBSA": 416591,
                   "state": 3710631
                },
                "numerator_errors": {
                   "this": 1900,
                   "CBSA": 7911,
                   "state": 33084
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 146
                },
                "error_ratio": {
                   "this": 22.5,
                   "CBSA": 1.9,
                   "state": 0.9
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 10.91,
                   "CBSA": 19.91,
                   "state": 33.31
                },
                "error": {
                   "this": 12.08,
                   "CBSA": 0.9,
                   "state": 0.42
                },
                "numerators": {
                   "this": 1616,
                   "CBSA": 134015,
                   "state": 2169717
                },
                "numerator_errors": {
                   "this": 1809,
                   "CBSA": 6061,
                   "state": 27349
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 33
                },
                "error_ratio": {
                   "this": 110.7,
                   "CBSA": 4.5,
                   "state": 1.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 1.47,
                   "CBSA": 5.64,
                   "state": 3.07
                },
                "error": {
                   "this": 1.04,
                   "CBSA": 0.56,
                   "state": 0.15
                },
                "numerators": {
                   "this": 218,
                   "CBSA": 37994,
                   "state": 200078
                },
                "numerator_errors": {
                   "this": 158,
                   "CBSA": 3804,
                   "state": 9887
                },
                "index": {
                   "this": 100,
                   "CBSA": 26,
                   "state": 48
                },
                "error_ratio": {
                   "this": 70.7,
                   "CBSA": 9.9,
                   "state": 4.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 2.9,
                   "CBSA": 11.16,
                   "state": 5.66
                },
                "error": {
                   "this": 3.63,
                   "CBSA": 0.61,
                   "state": 0.17
                },
                "numerators": {
                   "this": 429,
                   "CBSA": 75163,
                   "state": 368935
                },
                "numerator_errors": {
                   "this": 542,
                   "CBSA": 4124,
                   "state": 11144
                },
                "index": {
                   "this": 100,
                   "CBSA": 26,
                   "state": 51
                },
                "error_ratio": {
                   "this": 125.2,
                   "CBSA": 5.5,
                   "state": 3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.59,
                   "CBSA": 1.4,
                   "state": 0.98
                },
                "error": {
                   "this": 2.57,
                   "CBSA": 0.3,
                   "state": 0.11
                },
                "numerators": {
                   "this": 236,
                   "CBSA": 9442,
                   "state": 63864
                },
                "numerator_errors": {
                   "this": 382,
                   "CBSA": 2040,
                   "state": 6999
                },
                "index": {
                   "this": 100,
                   "CBSA": 114,
                   "state": 162
                },
                "error_ratio": {
                   "this": 161.6,
                   "CBSA": 21.4,
                   "state": 11.2
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 75.59,
                   "CBSA": 58.1,
                   "state": 55.21
                },
                "error": {
                   "this": 4.1,
                   "CBSA": 0.48,
                   "state": 0.18
                },
                "numerators": {
                   "this": 51834,
                   "CBSA": 2212141,
                   "state": 1.6905948E7
                },
                "numerator_errors": {
                   "this": 3841,
                   "CBSA": 18159.5,
                   "state": 55036
                },
                "index": {
                   "this": 100,
                   "CBSA": 130,
                   "state": 137
                },
                "error_ratio": {
                   "this": 5.4,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 11.39,
                   "CBSA": 14.83,
                   "state": 27.8
                },
                "error": {
                   "this": 3.53,
                   "CBSA": 0.28,
                   "state": 0.12
                },
                "numerators": {
                   "this": 7812,
                   "CBSA": 564814,
                   "state": 8513944
                },
                "numerator_errors": {
                   "this": 2450.1,
                   "CBSA": 10565.9,
                   "state": 37016.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 77,
                   "state": 41
                },
                "error_ratio": {
                   "this": 31,
                   "CBSA": 1.9,
                   "state": 0.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 6.04,
                   "CBSA": 7.23,
                   "state": 4.88
                },
                "error": {
                   "this": 1.72,
                   "CBSA": 0.33,
                   "state": 0.09
                },
                "numerators": {
                   "this": 4145,
                   "CBSA": 275261,
                   "state": 1494004
                },
                "numerator_errors": {
                   "this": 1199.2,
                   "CBSA": 12540.4,
                   "state": 28577.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 124
                },
                "error_ratio": {
                   "this": 28.5,
                   "CBSA": 4.6,
                   "state": 1.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 5.95,
                   "CBSA": 18.63,
                   "state": 10.99
                },
                "error": {
                   "this": 2.72,
                   "CBSA": 0.3,
                   "state": 0.08
                },
                "numerators": {
                   "this": 4079,
                   "CBSA": 709253,
                   "state": 3364089
                },
                "numerator_errors": {
                   "this": 1878.8,
                   "CBSA": 11259.6,
                   "state": 24688.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 32,
                   "state": 54
                },
                "error_ratio": {
                   "this": 45.7,
                   "CBSA": 1.6,
                   "state": 0.7
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.03,
                   "CBSA": 1.21,
                   "state": 1.12
                },
                "error": {
                   "this": 1.07,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 706,
                   "CBSA": 46214,
                   "state": 343173
                },
                "numerator_errors": {
                   "this": 735.6,
                   "CBSA": 5246.1,
                   "state": 14221.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 92
                },
                "error_ratio": {
                   "this": 103.9,
                   "CBSA": 11.6,
                   "state": 4.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 122,
                   "CBSA": 8551,
                   "state": 66574
                },
                "error": {
                   "this": 81.99,
                   "CBSA": 587.6,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 67.2,
                   "CBSA": 6.9,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 467,
                   "CBSA": 15732,
                   "state": 145536
                },
                "error": {
                   "this": 157.83,
                   "CBSA": 775.97,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 33.8,
                   "CBSA": 4.9,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 1366,
                   "CBSA": 55782,
                   "state": 561083
                },
                "error": {
                   "this": 222.99,
                   "CBSA": 1426.49,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 16.3,
                   "CBSA": 2.6,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 614,
                   "CBSA": 22897,
                   "state": 284290
                },
                "error": {
                   "this": 175.96,
                   "CBSA": 1005.17,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 28.7,
                   "CBSA": 4.4,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 814,
                   "CBSA": 22022,
                   "state": 309021
                },
                "error": {
                   "this": 198.82,
                   "CBSA": 1007.87,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 4,
                   "state": null
                },
                "error_ratio": {
                   "this": 24.4,
                   "CBSA": 4.6,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 3475,
                   "CBSA": 132185,
                   "state": 1351006
                },
                "error": {
                   "this": 828,
                   "CBSA": 5340,
                   "state": 19299
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 23.8,
                   "CBSA": 4,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 285,
                   "CBSA": 10262,
                   "state": 120461
                },
                "error": {
                   "this": 243,
                   "CBSA": 1944,
                   "state": 5618
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 85.3,
                   "CBSA": 18.9,
                   "state": 4.7
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 3760,
                "CBSA": 142447,
                "state": 1471467
             },
             "error": {
                "this": 904,
                "CBSA": 6012,
                "state": 19838
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 24,
                "CBSA": 4.2,
                "state": 1.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 5.49,
                "CBSA": 3.74,
                "state": 4.83
             },
             "error": {
                "this": 1.31,
                "CBSA": 0.16,
                "state": 0.07
             },
             "numerators": {
                "this": 3760,
                "CBSA": 142447,
                "state": 1471467
             },
             "numerator_errors": {
                "this": 904,
                "CBSA": 6012,
                "state": 19838
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 147,
                "state": 114
             },
             "error_ratio": {
                "this": 23.9,
                "CBSA": 4.3,
                "state": 1.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 69990213,
       "awater": 9862,
       "display_name": "Livermore, CA",
       "full_geoid": "16000US0641992",
       "population": 89027,
       "simple_name": "Livermore",
       "sumlevel": "160",
       "square_miles": 27,
       "population_density": 3337.4
    }
 }

 export default livermore