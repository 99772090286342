import React from 'react'

const CallToAction5 = ({bgColor, btnSTyle, paddingClass, text, url}) => {
    return (
        <section className={`${paddingClass} call-to-action-2`} style={{backgroundColor: `${bgColor}`}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="">
                            <h3 style={{color: "#fff", marginTop: "10px", fontSize: "24px"}}>{text}</h3>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <a href={url} className={`btn btn-primary ${btnSTyle}`}>点击查看</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction5
